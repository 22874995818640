const dev = {
  apis: {
    pravesh: "http://praveshtest.getwork.org",
    // pravesh: "http://127.0.0.1:8000",
    // niyukti: "http://niyuktitest1.getwork.org",
    niyukti: "http://niyuktitest3.getwork.org",
    // niyukti: "http://192.168.1.47:8000",
    // niyukti: "http://127.0.0.1:8001",
    sujhav: "http://sujhavtest.getwork.org",
    getworkFrontEnd: "http://appreacttest.getwork.org",
    sanchalan: "http://sanchalantest2.getwork.org",
    sandesh: "http://sandeshtest.getwork.org",
    info: "http://test.getwork.org",
    prashasan: "http://prashasantest.getwork.org",
    gpt: "http://gpttest.getwork.org",
  },
};

const prod = {
  apis: {
    pravesh: "https://pravesh1.getwork.org",
    niyukti: "https://niyukti.getwork.org",
    sujhav: "https://sujhav.getwork.org",
    getworkFrontEnd: "https://app.getwork.org",
    sanchalan: "https://sanchalan.getwork.org",
    sandesh: "https://sandesh.getwork.org",
    info: "https://getwork.org",
    prashasan: "https://prashasan.getwork.org",
    gpt: "https://gpt.getwork.org",
  },
};

const staging = {
  apis: {
    pravesh: "http://praveshstaing.getwork.org",
    niyukti: "http://niyuktistaging.getwork.org",
    sujhav: "http://sujhavstaging.getwork.org",
    getworkFrontEnd: "http://appstaging.getwork.org",
    sanchalan: "http://sanchalantest.getwork.org",
    sandesh: "http://sandeshtest.getwork.org",
    prashasan: "https://prashasan.getwork.org",
    gpt: "https://gpt.getwork.org",
  },
};

const config =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? prod
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? staging
    : dev;

export default {
  // Add common config values here
  ...config,
};
