import React from 'react';
import {Typography, Box} from '@material-ui/core';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box css={{p:1}}>
                  <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}