import React, {useState} from 'react';
import '../css/ResetPassword.css';
import { apiCall } from '../util/apiCall';
import {Form, Button} from 'react-bootstrap';
import { FailureAlert, SuccessAlert } from '../util/Notifications';

function ResetPassword() {

    const[email, setEmail] = useState('');

    const sendResetLink = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                email: email,
                admin: true
            })
        };

        apiCall('/api/email/generate', requestOptions) 
          .then((res)=> {
              if(res.success) {
                  SuccessAlert(res.data.message)
              }
              else {
                FailureAlert(res.error)
            } 
            setInterval(function(){ window.location.pathname = '/login'; }, 2000);
          })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        sendResetLink();
    }


    return (
        <div>
            <Form className="reset__password__form" onSubmit={(e) => handleSubmit(e)}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control 
                        name="email" 
                        type="email" 
                        placeholder="Email Address" 
                        required 
                        onChange={e=> {setEmail(e.target.value)}} 
                    />
                </Form.Group>
                <Button 
                    id="confirm-button"
                    variant="primary" 
                    type="submit" 
                >
                    Confirm
                </Button>
            </Form>
        </div>
    )
}

export default ResetPassword
