import React from "react";
import { useState } from "react";
import { AppBar, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { DateSelector } from "../../components/common/DateSelector";
import { makeStyles } from "@material-ui/core/styles";
import UseAxiosApi from "../../Api/UseAxiosApi";
import { cellCSS, gifStyling, headerCSS, praveshUrl } from "../../constants";
import TableWithExport from "../../components/TableWithExport";
import gif from "../../assets/loopingcircles-3.gif";
import { useEffect } from "react";
import { useHistory } from "react-router";
import QueryString from "query-string";
import CustomButtonCircularProgress from "../../components/loader";
import { CsvBuilder } from "filefy";
import { Button } from "react-bootstrap";
import moment from "moment-js";

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  pageSection: {
    display: "flex",
    flexDirection: "row-reverse",
  },

  dateSelectorSection: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1rem",
    bottomMargin: ".5rem",
  },
}));

const UTMReports = () => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tab, setTab] = useState(0);
  const [count, setCount] = useState(0);
  const [url, setUrl] = useState("");
  const history = useHistory();
  const [downloadAllFlag, setDownloadAllFlag] = useState(false);

  useEffect(() => {
    // if its initial call
    // and there is nothing in url then startdate and enddate should be today and t-30
    //  else get it from url
    if (!startDate) {
      let initialStartDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      );
      let initialEndDate = new Date();

      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = queryParams.from;
      }
      if (queryParams.to) {
        initialEndDate = queryParams.to;
      }
      setStartDate(new Date(initialStartDate));
      setEndDate(new Date(initialEndDate));
    }
  }, []);

  const handleClick = (row) => {
    let source = row?.utm_source ? `&utm_source=${row?.utm_source}` : "";
    let medium = row?.utm_medium ? `&utm_medium=${row?.utm_medium}` : "";
    let campaign = row?.utm_campaign
      ? `&utm_campaign=${row?.utm_campaign}`
      : "";
    let term = row?.utm_term ? `&utm_term=${row?.utm_term}` : "";
    let context = row?.utm_context ? `&utm_context=${row?.utm_context}` : "";
    let startDateString = moment(startDate).format("yyyy-MM-dd");
    let endDateString = moment(endDate).format("yyyy-MM-dd");
    window.open(
      `/admin/all-students?from=${startDateString}&to=${endDateString}${source}${medium}${campaign}${term}${context}`
    );
  };

  const columns = [
    {
      title: "Campaign Count",
      field: "utm_campaign_count",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.utm_campaign_count ? (
          <div
            style={{ cursor: "pointer", color: "blue", fontSize: "13px" }}
            onClick={() => {
              handleClick(row);
            }}
          >
            {row?.utm_campaign_count}
          </div>
        ) : (
          "NA"
        ),
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Source",
      field: "utm_source",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => row?.utm_source ?? "NA",
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Medium",
      field: "utm_medium",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => row?.utm_medium ?? "NA",
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Campaign",
      field: "utm_campaign",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => row?.utm_campaign ?? "NA",
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Content",
      field: "utm_content",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => row?.utm_content ?? "NA",
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Term",
      field: "utm_term",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => row?.utm_term ?? "NA",
      filtering: true,
      searchable: true,
    },
  ];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const downloadAllData = (data) => {
    if (downloadAllFlag) {
      setDownloadAllFlag(false);

      const firstObj = data?.[0];
      let columns = Object?.keys(firstObj);
      columns.pop();
      const fileName = tab === 0 ? "Candidate_utm.csv" : "Company_utm.csv";
      const builder = new CsvBuilder(fileName + ".csv");

      builder
        .setColumns(columns)
        .addRows(
          data.map((rowData) => columns.map((columnDef) => rowData[columnDef]))
        )
        .exportFile();
    }
  };

  const getDateString = () => {
    if (count === 0 && startDate === "") return;

    let dateString = "";
    if (startDate && endDate) {
      let startDateString = moment(startDate).format("yyyy-MM-dd");
      let endDateString = moment(endDate).format("yyyy-MM-dd");

      history.replace(`?from=${startDateString}&to=${endDateString}`);
      dateString = `start_date=${startDateString}&end_date=${endDateString}`;
    }

    return dateString;
  };

  const {
    data: candidateData,
    loading,
    error,
    apiCall,
  } = UseAxiosApi({ url: url, callBackFn: downloadAllData });

  useEffect(() => {
    if (startDate && endDate) {
      const dateString = getDateString();

      const tempUrl = `${praveshUrl}/api/admin/utm-campaign-list/?user_type=${
        tab === 0 ? "STUDENT" : "COMPANY"
      }&${dateString}`;
      setUrl(tempUrl);
    }

    if (downloadAllFlag) {
      apiCall();
    }
  }, [startDate, endDate, tab, downloadAllFlag]);

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
    }
  };

  const removeDateFilter = () => {
    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 30)
    );
    let initialEndDate = new Date();

    setStartDate(new Date(initialStartDate));
    setEndDate(new Date(initialEndDate));
    setCount(count + 1);
  };

  const handleDownloadAllData = () => {
    setDownloadAllFlag(true);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Candidate Report" {...a11yProps(0)} />
          <Tab label="Company Report" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <section className={classes?.dateSelectorSection}>
        <DateSelector
          dateRange={[startDate, endDate]}
          applyDateFilter={applyDateFilter}
          removeDateFilter={removeDateFilter}
        />
      </section>
      <section className={classes.pageSection}>
        <Button
          style={{
            width: "12rem",
            height: "2.5rem",
          }}
          onClick={() => {
            handleDownloadAllData();
          }}
        >
          {loading && downloadAllFlag ? (
            <CustomButtonCircularProgress />
          ) : (
            " Download All Data"
          )}
        </Button>
      </section>
      {loading && !downloadAllFlag ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        <TableWithExport
          title={tab === 0 ? `Candidate Report` : "Company Report"}
          data={candidateData ?? []}
          columns={columns}
          fName="Selected_data"
          disableTableDownload={true}
          searchPlaceHolder={" Search"}
        />
      )}
    </div>
  );
};

export default UTMReports;
