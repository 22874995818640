import React from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HomeAdmin from "./pages/HomeAdmin";
import ReactNotifications from "react-notifications-component";
import ViewJobPublicPage from "./components/Jobs/ViewJobPublicPage";
import SetNewPassword from "./pages/SetNewPassword";
import SignIn from "./pages/SignIn";
import NotFound from "./pages/NotFound";
import { apiCall } from "./util/apiCall";
import Layout from "./pages/Layout";
import AiCallingBot from "./components/Jobs/AiCallingBot";

function App() {
  const token = localStorage.getItem("token");
  if (token) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    apiCall("/api/admin/page/", requestOptions).then((res) => {
      if (!res.success) {
        localStorage.clear();
        window.location.pathname = "/login";
      } else {
        localStorage.setItem("login", true);
      }
    });
  }

  const loggedIn = localStorage.getItem("login") ? true : false;

  return (
    <div className="app">
      <ReactNotifications />
      <Helmet>
        {process.env.REACT_APP_ENVIRONMENT === "production" ? (
          <title>Prashasan</title>
        ) : (
          <title>Prashasan | Development</title>
        )}
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/">
            {/* { loggedIn ? <Redirect to="/admin" /> : <Redirect to="/login" />} */}
            {loggedIn ? (
              <Redirect to="/admin/new-dashboard" />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/login">
            {/* { loggedIn ? <Redirect to="/admin" /> : <SignIn remember={false} />}  */}
            {loggedIn ? (
              <Redirect to="/admin/new-dashboard" />
            ) : (
              <SignIn remember={false} />
            )}
          </Route>
          <Route exact path="/admin">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/:contentId">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/forgot-password">
            {loggedIn ? <Redirect to="/admin" /> : <SignIn remember={true} />}
          </Route>
          {/* Add first name and last name params*/}
          <Route
            exact
            path="/reset-password/:uid/:token/:first_name/:last_name"
          >
            <SetNewPassword />
          </Route>
          <Route exact path="/admin/college/:content/:clg_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/college/applicants/:clg_id/:job_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route path="/admin/group/college-list/:group_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/job-track/:job_id/:comp_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/active-plan/:company_name/:company_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/active-slot/:company_name/:company_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/public-job-posting/:job_id/:comp_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/3rd-party-job-posting/:job_id/:comp_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/view-blog/:blog_id">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/admin/public/job/:job_id">
            {!loggedIn ? <Redirect to="/login" /> : <ViewJobPublicPage />}
          </Route>
          <Route exact path="/admin/ai-calling-bot/:job_id/:job_title">
            {!loggedIn ? <Redirect to="/login" /> : <Layout />}
          </Route>
          <Route exact path="/not-found">
            <NotFound />
          </Route>
          <Route exact path="*">
            <Redirect to="/not-found" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
