import React, {useState, useEffect} from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/Miscellaneous.css';
import {Form, Button} from 'react-bootstrap';
import { gifStyling, requestPraveshOptions } from '../../constants';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';

const token = localStorage.getItem('token');
function ChangePassword() {

    const [searchResults, setSearchResults] = useState([]);
    const [user, setUser] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/operations/update/password/', requestPraveshOptions)
          .then(json => {
                if(json.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            user_id:  json.data[i].user_id,
                            email:    json.data[i].email,
                        }
                        tmpArray.push(obj)
                        setLoading(false);
                        setSearchResults(tmpArray)
                    }
                }
                else {
                    FailureAlert(json.error)
                }                
            })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if(password===confirmPassword) {
            const requestOptionsPost = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    user_id: user,
                    password: password
                })
            }

            apiCall(`/api/admin/operations/update/password/`, requestOptionsPost)
                .then((res) => {
                    if(res.success) {
                        SuccessAlert(res.data.message);
                        setCount(count+1);
                    }
                    else {
                        FailureAlert(res.error);
                    }
                })
                .then(setUser(null))
        }
        else{
            FailureAlert("Confirm Password doesn't match initial password...")    
        }    
    }

    return (
        !loading
        ?
        <div className="change__password">
            <Autocomplete
                id="select-user"
                options={searchResults}
                getOptionLabel={(item) => item.email}
                style={{ width: '80%', margin: '5%' }}
                onChange={(e, item)=>item ? setUser(item.user_id) : setUser(null)}
                renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
            />
            {
                user
                ?
                <Form className="change__password__form" onSubmit={(e) => handleSubmit(e)}>
                    <h4>Change Password</h4>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Control 
                            name="password" type="password" placeholder="Password" required 
                            onChange={e=> {setPassword(e.target.value)}}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Control 
                            name="confirmPassword" type="password" placeholder="Confirm Password" required 
                            onChange={e=> {setConfirmPassword(e.target.value)}}
                        />
                    </Form.Group>    
                    <Button variant="primary" type="submit" > Set Password </Button>
                </Form>
                :
                null
            }
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default ChangePassword
