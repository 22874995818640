import * as React from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
const CallAnswers = (
  <span style={{ fontSize: "14px", color: "#029E73" }}>Call Answered</span>
);

const temporarilyUnavailable = (
  <span style={{ fontSize: "14px", color: "#6C757D" }}>
    Temporarily unavailable
  </span>
);

const numberBzy = (
  <span style={{ fontSize: "14px", color: "#FF671D" }}>Number busy</span>
);

function getCall(type) {
  if (type === "CallAnswered") {
    return CallAnswers;
  } else if (type === "temporarilyUnavailable") {
    return temporarilyUnavailable;
  } else return numberBzy;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: "#000",
  },
  body: {
    fontSize: 14,
    border: "none",
    borderRight: "1px solid #6C757D",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

function transformData(data) {
  const results = [];

  let dateRange = "";
  if (data.x_axis.length > 0) {
    const startDate = data.x_axis[0];
    const endDate = data.x_axis[data.x_axis.length - 1];
    dateRange = `${startDate} to ${endDate}`;
  }

  data.y_axis.forEach((yAxisItem) => {
    const result = {
      name: yAxisItem.name,
      date: dateRange,
      total: 0,
      history: [],
    };

    data.x_axis.forEach((date, index) => {
      const total = yAxisItem.data[index];
      // if (total > 0) {
      result.history.push({
        name: result.name,
        date: date,
        total: total,
      });
      result.total += total;
      // }
    });

    results.push(result);
  });

  return results;
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow style={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell component="th" scope="row" align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <ArrowForwardIosRoundedIcon />}
          </IconButton>{" "}
          {row.name}
        </StyledTableCell>
        <StyledTableCell align="center" style={{ width: "350px" }}>
          {row.date}
        </StyledTableCell>
        <StyledTableCell align="center" style={{ width: "350px" }}>
          {row.total}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table aria-label="purchases">
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: "#6C757D",
                          border: "none",
                          borderRight: "1px solid #6C757D",
                        }}
                      >
                        <IconButton
                          style={{ visibility: "hidden" }}
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>{" "}
                        {historyRow.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: "#6C757D",
                          border: "none",
                          borderRight: "1px solid #6C757D",
                          width: "350px",
                        }}
                      >
                        {historyRow.date}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: "#6C757D",
                          border: "none",
                          width: "450px",
                        }}
                      >
                        {historyRow.total}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function TeamLeadTable({ color, tableTitle, tableData }) {
  const data = transformData(tableData);
  return (
    <TableContainer component={Paper} style={{ border: "1px solid #6C757D" }}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: "#F6F6F6",
                borderRight: "1px solid #6C757D",
              }}
            >
              Recruiter Name
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                backgroundColor: "#F6F6F6",
                borderRight: "1px solid #6C757D",
              }}
            >
              Date
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ width: "450px", backgroundColor: color, color: "#fff" }}
            >
              {tableTitle}
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
