import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import resumeimg from "../../../../assets/images/view.png";
import {
  praveshAxiosCall,
  praveshAxiosGetReq,
  praveshAxiosPostReq,
} from "../../../../util/apiCall";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { Form } from "react-bootstrap";
import { FailureAlert, SuccessAlert } from "../../../../util/Notifications";
import FileViewer from "react-file-viewer";
import CustomButtonCircularProgress from "../../customLoader";
import { useHistory } from "react-router";
import gif from "../../../../assets/loopingcircles-3.gif";

import { gifStyling } from "../../../../constants";
import {emailRegex} from "../../../../constants";
 

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
    width: "100%",
  },
  previewText: {
    display:"flex",
    justifyContent:"space-around",
    marginTop:"150px"
    
  }
}));

const DialogContent = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    /// padding: theme.spacing(12),
    // width: "500px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ResumeView({
  open,
  handleClickOpen,
  handleClose,
  currentItem,
  fileUrlInitial,
  data,
  setTagsRefreshed,
  toggleCardsData,
  totalResumeCardsData,
  thumbnail,
  setThumbnail,
  mapped,
  resumedata,
}) {
  const classes = useStyles();
  const [maxWidth, setMaxWidth] = useState("md");
  const [resumeData, setResumeData] = useState();
  const [users, setUsers] = useState(["Enter min 3 chars..."]);
  const [selectedStudentID, SetselectedStudentID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [matchData, setMatchData] = useState(null);
  const history = useHistory();
  const [fileUrl, setFileUrl] = useState(resumedata.file);
  const [counter, setcounter] = useState(toggleCardsData?.currentCardIndex);
  const [fileId, setFileID] = useState(data?.id);

  const [allApiSuccess, setAllApiSuccess] = useState({
    status: 0,
    message: "",
  });

  const studentDetails = {
    fullName:null,
    email:null,
    mbNo:null,

  }
const [studentRegister, setStudentRegister] = useState({...studentDetails});


const [resumeLoader, setResumeLoader] = useState(false);

const mappedDetails = {
   name:null,
   email:null,
   phone:null,
}

const [mappedUserDetails, setMappedUserDetails] = useState({...mappedDetails});

  const handleCardChangeNext = () => {

    setFileUrl(totalResumeCardsData[counter + 1]?.file);
    setcounter(counter + 1);
    setFileID(totalResumeCardsData[counter + 1]?.id);
    setMappedUserDetails({...totalResumeCardsData[counter + 1]?.user_detail});
    //  handleClose();
    //  handleClickOpen();
    // setData(totalResumeCardsData[toggleData.currentCardIndex + 1]);
  };

  const handleCardChangePrev = () => {

    setFileUrl(totalResumeCardsData[counter - 1]?.file);
    setFileID(totalResumeCardsData[counter - 1]?.id);
    setMappedUserDetails({...totalResumeCardsData[counter - 1]?.user_detail});

    setcounter(counter - 1);

  };

  useEffect(() => {
    setResumeLoader(true);

    praveshAxiosCall
      .get(`${fileUrl}`, { responseType: "arraybuffer" })
      .then((res) => {
        if (fileUrl.includes("docx") || fileUrl.includes("doc")) {
          const blob = new Blob([res.data], { type: "application/msword" });
          let fileURL = URL.createObjectURL(blob);
          setResumeData(fileURL);
        } else if (fileUrl.includes("pdf")) {
          const blob = new Blob([res.data], { type: "application/pdf" });
          let fileURL = URL.createObjectURL(blob);
          setResumeData(fileURL);
        } else {
          const blob = new Blob([res.data], { type: "image/bmp" });
          let fileURL = URL.createObjectURL(blob);
          setResumeData(fileURL);
        }

        //  window.open(fileURL);
        setResumeLoader(false);

        if (res?.data?.success) {
          // setResumeData(res);
        } else {
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setAllApiSuccess({
          status: -1,
          message: `Open Job List Error : ${err.message}`,
        });
      });
  }, [fileUrl]);

  const mapResumeCall = () => {

    if(selectedStudentID && data?.id){
      mapStudent();
    }else{
      FailureAlert("Select Student");
    };

  };

  const createAndMapCall = () => {

    const validate = () => {

      if (! data?.id) return false;
      if (studentRegister?.fullName?.length < 1) { FailureAlert("Please Enter Name"); return false; };
      if (studentRegister?.mbNo?.length < 10) { FailureAlert("Enter 10 digit mobile No"); return false; };
      if (!studentRegister?.email || !emailRegex.test(studentRegister.email)) { 
      FailureAlert("Please Enter Valid email Address!"); return false; 
      }
     else {return true};

    };

   if(validate()) {

     mapStudent();

   }


  };


  const mapStudent = () => {

    const Idmapped = {
      user_id: selectedStudentID,
      file_id: fileId,
      email:studentRegister.email,
      name:studentRegister.fullName,
      phone:studentRegister.mbNo,

    };    
    

    // if (selectedStudentID && data?.id) {
    if ( data?.id) {


    selectedStudentID ?  setLoading(true) : setLoadingCreate(true) ;

      const mapStudentCall = async () => {
        const endPoint = `/api/admin/bulk/resume/approve/`;

        try {
          const res = await praveshAxiosPostReq(endPoint, Idmapped);
          if (res?.data?.success) {
            SuccessAlert("Student Mapped successfully!");
            setTagsRefreshed(true);
            selectedStudentID ?  setLoading(false) : setLoadingCreate(false) ;

            SetselectedStudentID(null);

            // re-renders all the tags after mapping done.
            setTagsRefreshed(false);
            // history.push("/admin/bulk-resume");
            setStudentRegister({ fullName:'',
                        email:'',
                        mbNo:''
                      });
          } else {
            FailureAlert(`${res?.data?.error}`);
            setLoading(false);
            setLoadingCreate(false)
          }
        } catch (error) {
          setLoading(false);
          setLoadingCreate(false)
          throw error;
        }
      };
      mapStudentCall();
    } else {
      if(selectedStudentID) {

        FailureAlert("Select Student");
      } 
    }
  };

  const searchingStudent = (e) => {
    const findMatch = e.target.value;

    if (e.target.value.length > 3) {
      allStudentsWithKeyMatch(findMatch);
    } else {
      setUsers(["Enter min 3 chars..."]);
    }
  };

  const allStudentsWithKeyMatch = async (findMatch) => {
    const endPoint = `/api/admin/bulk/get/users/?keyword=${findMatch}`;
    try {
      const res = await praveshAxiosGetReq(endPoint);

      if (res?.data?.success) {
        setMatchData(res?.data?.data?.data);

        let temp;
        temp = res?.data?.data?.data?.map((item) => {
          return `${item?.first_name} ${item?.last_name}| ${item?.email}`;
        });

        setUsers(temp);
      } else {
        FailureAlert("Some error has Occurred!");
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {

    setMappedUserDetails({...resumedata.user_detail});
    // allStudents();
    // allStudentsWithKeyMatch();
    // console.log(data?.id,'from resumeView');
  }, []);

  const onSelectingName = (e, item) => {
    const email = (e?.split("| "))[1];
    const matchStudent = matchData?.filter((item) =>
      item?.email?.includes(email)
    );
    SetselectedStudentID(matchStudent[0]?.id);
  };

  const disableNext = () => {

    if (fileId === totalResumeCardsData[totalResumeCardsData.length-1]?.id){
      return true;
    }
    else return false;
  };

  const disablePrev = () => {
     if (fileId === totalResumeCardsData[0]?.id){
      return true;
    }
    else return false;

  }

  const getFileType = () => {  

  if (fileUrl?.includes("docx")) return "docx";  
  if (fileUrl?.includes("doc") ) return "docx";
  if (fileUrl?.includes("jpg") ) return "jpg";
  if (fileUrl?.includes("jpeg") ) return "jpeg";
  if (fileUrl?.includes("png") ) return "png";
   
  };

  const clearCreateForm = () => {
    setStudentRegister({ fullName:'',
                        email:'',
                        mbNo:''
                      });
   
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        //  fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ classes: { root: classes.root1 } }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={8} ref={descriptionElementRef} tabIndex={-1}>
              {resumeLoader ? (
                <img
                  src={gif}
                  alt="Getwork Loading Gif..."
                  style={gifStyling}
                />
              ) : fileUrl?.includes("pdf") ||
                fileUrl?.includes("docx") ||
                fileUrl?.includes("jpg") || fileUrl?.includes("doc") || fileUrl?.includes("jpeg") 
                || fileUrl?.includes("png")
                ? (
                fileUrl?.includes("pdf") ? (
                  <embed
                    style={{ width: "100%", height: 800 }}
                    src={fileUrl?.includes("pdf") ? resumeData : resumeimg}
                  />
                ) : (
                  // fileUrl?.includes("doc") ?
                  // <iframe  frameborder="0" src={`https://docs.google.com/gview?url=${resumeData}&embedded=true`}></iframe>
                  // :
                  <section style={{borderStyle:"ridge"}} >

                    <FileViewer fileType={getFileType()} filePath={resumeData} />
                  </section>

                )
              ) : (
                <>
                  <div style={{ marginBottom: 50 } } >

                    <Typography variant="h4" className={classes.previewText}>

                     No Preview available for the file.
                    </Typography>
                    {/* <img
                      src={resumeimg}
                      style={{
                        width: "100%",
                      }}
                    /> */}
                  </div>
                </>
              )}
            </Grid>
            <Grid item md={4}>
              <div style={{ position: "sticky", top: "1px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  
                  {resumeLoader ? null : 
                  
                  <section >
                    <section style={{display:"flex", alignItems:"center"}}>
                    <Typography variant="h6">
                    {/* {mapped ? `${resumedata?.user_detail?.name}` : "Map Resume"} */}
                    {mapped ? `${mappedUserDetails.name}` : "Map Resume"}
                   </Typography> 
                    <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft:"120px"
                    }}
                  >
                    <IconButton
                      aria-label="close"
                      //   className={classes.closeButton}
                      onClick={handleClose}
                    >
                    <CloseIcon style={{ color: "#000" }} />
                    </IconButton>
                  </div>

                    </section>
                                   

                  <br />
                  <Typography variant="h6">
                    {mapped ? `${mappedUserDetails.email}` : null}
                  </Typography>
                  <br />
                  <Typography variant="h6">
                    {mapped ? `${mappedUserDetails.phone}` : null}
                  </Typography>

                  </section>                  
                  
                  }                
                 
                 
                </div>

                {mapped ? null : (
                  <Form className="select_student_to_map">
                    <Autocomplete
                      id="select-student"
                      options={users}
                      size="small"
                      // getOptionLabel={(item) => item}
                      style={{ width: "100%", marginTop:"-43px" }}
                      onChange={(e, item) =>
                        item ? onSelectingName(item) : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={searchingStudent}
                          label="Select student"
                          variant="outlined"
                        />
                      )}
                    />
                  </Form>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%", marginTop: "20px" }}
                  onClick={mapResumeCall}
                  disabled={mapped}
                >
                  {loading ? <CustomButtonCircularProgress /> : "Map Resume"}
                </Button>
                <section style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      marginRight: "5px",
                    }}
                    onClick={handleCardChangePrev}
                    disabled={disablePrev()}
                  >
                    {"Prev"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "100%", marginTop: "20px" }}
                    onClick={handleCardChangeNext}
                    disabled={disableNext()}
                  >
                    {"Next"}
                  </Button>
                </section>

             {/* create account section for non-mapped data */}

             { mapped ? null : 
             
             
              <section style={{marginTop:"42px"}}>
                   <Typography variant="h7" >
                    { "Create Account"}
                  </Typography>

               <Form.Group >
                <TextField
                  
                  style={{ width: "100%",
                        marginTop:"5px",                
                  }}
                  value={studentRegister.fullName}
                  size="small"
                  variant="outlined"
                  placeholder={"Full Name*"}
                  onChange={(e) => {
                    setStudentRegister({...studentRegister, fullName:  e.target.value});
                    // console.log(e.target.value);                                      
                  }}
                />
               <TextField
                  
                  style={{ width: "100%",marginTop:"5px" }}
                  size="small"
                  value={studentRegister.email}
                  variant="outlined"
                  placeholder={"E-mail*"}
                  type="email"
                  onChange={(e) => {
                    
                    setStudentRegister({...studentRegister, email:  e.target.value});

                    // console.log(e.target.value);                                      
                  }}
                />
                <TextField
                  
                  style={{ width: "100%",marginTop:"5px" }}
                  size="small"
                  variant="outlined"
                  value={studentRegister.mbNo}
                  inputProps={{
                      maxlength: 10
                    }}
                 
                  placeholder={"Mobile Number*"}
                  onChange={(e) => {
                    
                    setStudentRegister({...studentRegister, mbNo:  e.target.value});

                    // console.log(e.target.value);                                      
                  }}
                />
              </Form.Group>

              <section style={{ display: "flex" }}>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      marginRight: "5px",
                    }}
                    onClick={clearCreateForm}
                   
                  >
                    { "Clear"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "100%", marginTop: "20px" }}
                    onClick={createAndMapCall}
                   
                  >
                    { loadingCreate ?  <CustomButtonCircularProgress /> : "Create & Map"}
                  </Button>
                </section>
                </section>             
             }            

              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const data = [
  { title: "Sachin Kapoor | sachine@gmail.com" },
  { title: "Indian Institute of Technology, maharashtra" },
  { title: "jeet Singh | jeet@gmail.com" },
];
