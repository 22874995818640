// import Close from "@material-ui/icons-material/Close";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from 'react';
import { indianCurrencySeparator } from '../../../util/utility';

const useStyles = makeStyles(() => ({
    outerBox: {
        boxShadow: "0px 0px 4px 0px #00000040",
        // padding: "2rem",
        borderRadius: "10px",
        position: "relative",
        height: "170px",
        minWidth: "130px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: 8,
        marginTop: "1.5rem",
        marginBottom: '1rem',
        paddingBottom: '2rem',
    },
    closebutton: {
        position: "absolute", right: 5, top: 2
    },
    iconClose: {
        fontSize: "14px"
    },
    text: {
        fontSize: "14px",
        color: "#979797",
        marginLeft: '1rem',
        marginTop: '1rem',
        // marginBottom: -8,
    },
    jobSegmentName: {
        fontWeight: "600",
        marginLeft: '1rem',
        marginTop: '1rem',
    },
}))

const SelectedPreferenceCards = ({ jobSegment, deleteJob }) => {

    const classes = useStyles();

    return (
        <div className={classes.outerBox}>
            <IconButton
                className={classes.closebutton}
                sx={{ position: "absolute", right: 5, top: 2 }}
                onClick={() => {
                    deleteJob(jobSegment?.id);
                }}
            >
                <CloseIcon className={classes.iconClose} />
            </IconButton>
            <Typography variant="body2" className={classes.jobSegmentName} >
                {jobSegment?.name}

            </Typography>
            <Typography className={classes.text} >
                {`Max Offered Stipend/Month:`}
                <span>
                    {`${indianCurrencySeparator(jobSegment?.max_stipend)}`}
                </span>
            </Typography>
            <Typography className={classes.text} >
                {`Max Offered CTC/Annum:`}
                <span>
                    {`${indianCurrencySeparator(jobSegment?.max_ctc)}`}
                </span>
            </Typography>
        </div>
    )
}

export default SelectedPreferenceCards