import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow: "none",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ClubChip({ chipData, setChipData }) {
  const classes = useStyles();

  const handleDelete = (chipToDelete, index) => {
    if (index !== -1) {
      var arr = [...chipData?.tags];
      arr.splice(index, 1);
      setChipData({ ...chipData, tags: arr });
    }
  };

  return (
    <Paper component="ul" className={classes.root}>
      {chipData?.tags?.map((data, index) => {
        return (
          <li key={data.id}>
            <Chip variant="outlined" label={data.name} onDelete={() => handleDelete(data, index)} className={classes.chip} deleteIcon={<CloseIcon style={{ color: "#000" }} />} />
          </li>
        );
      })}
    </Paper>
  );
}
