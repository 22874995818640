
import { Divider, Grid, IconButton, InputBase, Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CropFreeIcon from "@material-ui/icons/CropFree";
// import JobSharerButton from "../JobSharer/JobSharerButton";
import LinkIcon from "@material-ui/icons/Link";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import rocket from "../../assets/rocket.gif";
import { SuccessAlert } from '../../util/Notifications';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    btn: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "30px",
        paddingLeft: "60px",
        paddingRight: "60px",
    },
    btn2: {
        borderRadius: "30px",
        backgroundColor: "#007bff",
    },
    paper1: {
        padding: "2px 4px",
        marginBottom: "30px",

        display: "flex",
        alignItems: "center",
        width: "auto",
        border: "solid 0.5px rgba(0 0 0 0.12)",
        borderRadius: "12px",
        boxShadow: "rgba(0 0 0 0.25) 0px 54px 55px rgba(0 0 0 0.12) 0px -12px 30px rgba(0 0 0 0.12) 0px 4px 6px rgba(0 0 0 0.17) 0px 12px 13px rgba(0 0 0 0.09) 0px -3px 5px",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const TrackShareSuccess = ({ passCode, generatedLink, handleShareOpen, openShare, setOpenShare, isCopy=false }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = () => {

        setOpenShare(false);
        // setOpen(false);
        if(!isCopy)
            handleShareOpen();
    };
    const classes = useStyles();
    return (
        <div>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: 15,
                        border: "solid 0.5px #e2e6ea",
                        boxShadow: "rgba(149 157 165 0.2) 0px 8px 24px",
                    },
                }}
                fullScreen={fullScreen}
                maxWidth="lg"
                open={openShare}
                onClose={handleClose}
            >
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img src={rocket} width="300px" height="300px" alt="track share"/>

                        <Grid container spacing={3} style={{ marginTop: "-30px" }}>
                            <Grid item xs={8}>
                                {/* Details section start here */}
                                <Typography variant="h6">Link</Typography>
                                <Paper className={classes.paper1}>
                                    <IconButton className={classes.iconButton} aria-label="menu">
                                        <LinkIcon />
                                    </IconButton>
                                    <Divider className={classes.divider} orientation="vertical" />

                                    <InputBase className={classes.input} placeholder="Track Link" value={generatedLink} disabled inputProps={{ "aria-label": "https://www.getwork.org/" }} />

                                    <CopyToClipboard text={generatedLink} onCopy={() => SuccessAlert("Link Copied!")}>
                                        <Button variant="contained" color="primary" style={{borderRadius: "12px" }}>
                                            Copy
                                        </Button>
                                    </CopyToClipboard>
                                </Paper>
                            </Grid>

                            <Grid item xs={2} sm={4}>
                                {/* passcode input section start here */}
                                <Typography variant="h6">Passcode</Typography>
                                <Paper className={classes.paper1}>
                                    <IconButton className={classes.iconButton} aria-label="menu">
                                        <CropFreeIcon />
                                    </IconButton>
                                    <Divider className={classes.divider} orientation="vertical" />
                                    <InputBase
                                        className={classes.input}
                                        value={passCode}
                                        placeholder="Passcode"
                                        disabled
                                        inputProps={{
                                            minLength: 6,
                                            maxLength: 6,
                                        }}
                                    // defaultValue="859306"
                                    />
                                    <CopyToClipboard text={passCode} onCopy={() => SuccessAlert("Passcode Copied!")}>
                                        <Button variant="contained" color="primary" style={{borderRadius: "12px" }}>
                                            Copy
                                        </Button>
                                    </CopyToClipboard>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Typography variant="h5" style={{ textAlign: "center", fontSize: 17, marginBottom: 30 }}>
                            Copy the link and passcode. Securely share it with anyone!
                        </Typography>
                        {/* <JobSharerButton
              detail={{
                url: generatedLink,
                user:
                  localStorage.getItem("user_type") === "Company"
                    ? JSON.parse(localStorage.getItem("company")).company_name
                    : JSON.parse(localStorage.getItem("user_details")).college_name,
                passcode: passCode,
                jobProfile: jobProfileObject,
              }}
              page="SHARE_TRACK"
            /> */}

                        <br />
                        <Button variant="contained" className={classes.btn} onClick={() => handleClose()}>
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TrackShareSuccess