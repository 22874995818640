import React, { useState, useEffect } from 'react';
import {apiNiyuktiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestNiyuktiOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';

function JobType() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiNiyuktiCall('/job/type/', requestNiyuktiOptions)
          .then(json => {
                if(json.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            id:        parseInt(json.data[i].id),
                            job_type:  json.data[i].job_type,
                        }
                        tmpArray.push(obj)
                    }
                    setLoading(false);
                    setData(tmpArray);
                }
                else {
                    FailureAlert(json.error)
                }    
           })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "Job Type ID",    field: "id",        headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job Type Name",  field: "job_type",  headerStyle: headerCSS, cellStyle: cellCSS },
    ]


    return (
        !loading
        ?
        <div className="job__type">
            <TableWithExport 
                title = "Job Type"
                data = {data}
                columns = {columns}
                fName = "Selected Job Types"
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default JobType;