import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import AddLanguageDialog from "./AddLanguageDialog";
import SaveLanguages from "./SaveLanguages";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
}));

export default function Languages({languages, postLanguages}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tempLanguage, setTempLanguage] = useState([]);
  
  useEffect(() => {
    if(languages)
      setTempLanguage(languages);
  }, [languages])
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (index) => {
    setTempLanguage((tempLanguage) =>
    tempLanguage.filter((_, langIndex) => langIndex !== index)
    );
  };

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Languages</Typography>
        <br />
        {tempLanguage && tempLanguage.map((lang, index) => {
          return (
        <SaveLanguages data = {lang} langIndex={index} handleDelete={handleDelete}/>
          )    
      })
      }

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            color="primary"
            onClick={handleClickOpen}
          >
            Add Language
          </Button>

          <Button variant="contained" color="primary" onClick={() => postLanguages(tempLanguage)}>
            Save
          </Button>
        </div>

        <AddLanguageDialog open={open} handleClose={handleClose} setTempLanguage={setTempLanguage} tempLanguage={tempLanguage}/>
      </div>
    </>
  );
}
