import React from "react";
// import Close from "/icons-material/Close";
import CloseIcon from "@material-ui/icons/Close";

import { Box, IconButton, Typography } from "@material-ui/core";
// import { KeyboardDoubleArrowRightIcon } from "@material-ui/icons";
import { KeyboardArrowRightTwoTone } from "@material-ui/icons";
const text = {
  fontSize: "14px",
  color: "#979797",
};

const RoundCard = ({ round, index, totalLength, deleteRound, deletable }) => {
  console.log(round?.name, deletable);
  return (
    <>
      <Box
        style={{
          boxShadow: "0px 0px 4px 0px #00000040",
          padding: "1rem",
          borderRadius: "10px",
          position: "relative",
          height: "116px",
          maxWidth: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          style={{ position: "absolute", right: 5, top: 2 }}
          onClick={() => {
            deleteRound(index, deletable);
          }}
        >
          <CloseIcon style={{ fontSize: "14px" }} />
        </IconButton>
        <Typography variant="body2" style={{ fontWeight: "600" }}>
          {`${round?.name}`}
        </Typography>
        <Typography style={text}>
          {round?.duration ? `Duration: ${round?.duration} minutes` : "NA"}
        </Typography>
        <Typography style={text}>
          {round?.venueType ? `Venue: ${round?.venueType}` : "NA"}
        </Typography>
      </Box>
      <section style={{ display: "flex", alignItems: "center" }}>
        {totalLength - 1 !== index && (
          <>
            <KeyboardArrowRightTwoTone style={{ fontSize: "50px" }} />
          </>
        )}
      </section>
    </>
  );
};

export default RoundCard;
