import { AppBar, Box, Button, Tab, Tabs } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import GWLogoLoader from '../../components/common/Loaders/GWLogoLoader';
import ModelPlanCreation from '../../components/Company/Dialog/ModelPlanCreation';
import TableWithExport from '../../components/TableWithExport';
import { cellCSS, headerCSS } from '../../constants';
import { praveshAxiosGetReq } from '../../util/baseApi';
import { FailureAlert } from '../../util/Notifications';
import { TabPanel } from '../../util/TabPanel';


function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    heading: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '1rem',
        alignItems: 'center'

    },
    headingText: {
        fontWeight: 600,
        font: "Nunito"
    },
    btnAdd: {
        minWidth: "6rem",
        fontSize: 13,
        marginLeft: '3rem',
        backgroundColor: '#303F9F', color: 'white'
    }
}))


const ManagePlans = () => {

    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const classes = useStyles()

    const [planData, setPlanData] = useState([]);
    const [planTableData, setPlanTableData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [open, setOpen] = useState(false);
    const [slotGroup, setSlotGroup] = useState([]);
    const [tableModel, setTableModel] = useState(null);

    const initialPlanModelCreationDetails = {

        modelDetails: '',
        modelName: '',
        planDetails: ' ',
        planName: '',
        planMonthlyPricing: '',
        planAnnualPricing: '',
        selectedModel: null,
        slotGroup: '',
        planJobCount: '',
        planInternshipCount: '',
        planTestPinsCount: '',
        hasKAM: false,
        unlimited: '',
        isEdit: false,
        id: '',
        profileUnlocks: '',
        isCustomModel: true,
        isCustomPlan: true,
    }

    const [planModelCreationDetails, setPlanModelCreationDetails] =
        useState(initialPlanModelCreationDetails);

    const handelResetForm = () => {
        setPlanModelCreationDetails(initialPlanModelCreationDetails)
    }

    const getDetailsString = (details) => {

        let textHtml = '';
        return textHtml;
    }

    const getPlanData = async () => {

        const endPoint = `/api/admin/plan/manage_plan/`;

        setLoading(true);

        try {
            const res = await praveshAxiosGetReq(endPoint);

            if (res?.data?.success) {

                const allData = res?.data?.data;
                let tempArr = [];

                const PlansResponse = allData?.plans;
                const modelsResponse = allData?.models;
                setSlotGroup(allData?.slot_groups);

                for (let i in PlansResponse) {

                    let obj = {};

                    obj = {
                        ...PlansResponse[i],
                        job_count: PlansResponse[i]?.job_count === -1 ? "Unlimited" : PlansResponse[i]?.job_count,
                        details: getDetailsString(PlansResponse[i]?.details),
                        extra_data: JSON.stringify(PlansResponse[i]?.extra_data),
                        create_time: new Date(PlansResponse[i]?.create_time).toLocaleString(),
                        update_time: new Date(PlansResponse[i]?.update_time).toLocaleString(),

                    }
                    tempArr.push(obj);
                }

                setPlanData(tempArr);

                let tempArr2 = [];

                for (let i in modelsResponse) {
                    let obj = {};

                    obj = {
                        ...modelsResponse[i],
                        details: modelsResponse[i]?.details?.data,
                        create_time: new Date(modelsResponse[i]?.create_time).toLocaleString(),
                        update_time: new Date(modelsResponse[i]?.update_time).toLocaleString(),

                    }
                    tempArr2.push(obj);
                }
                setModalData(tempArr2);
            }
            setLoading(false);

        } catch (error) {

            setLoading(false);
            throw (error)

        }
    }

    const getMatchingModel = (id) => {

        const matchingModel = modalData?.filter((item) => (item?.id === id));
        let tempObj = matchingModel[0];

        const tempModal = { id: tempObj?.id, name: tempObj?.name }

        return tempModal
    }

    useEffect(() => {

        if (planData?.length === 0) {
            getPlanData()
        }

    }, []);

    useEffect(() => {
        if (tableModel && Object?.keys(tableModel)?.length > 0) {

            let tempTableArr = planData?.filter((item) => (item?.model_id === tableModel?.id));

            setPlanTableData(tempTableArr);

        } else {
            if (planData?.length > 0) {
                setPlanTableData(planData)
            }
        }

    }, [tableModel, planData]);


    const handleChange = (event, newValue) => { setValue(newValue); }

    const handleEditPlan = (id) => {

        setOpen(true);
        const selectedPlanDataArr = planData?.filter((item) => (item?.id === id));
        let selectedPlanData = selectedPlanDataArr[0];

        let tempData = {
            id: selectedPlanData?.id,
            planDetails: selectedPlanData?.details,
            planName: selectedPlanData?.name,
            planMonthlyPricing: selectedPlanData?.monthly_pricing,
            planAnnualPricing: selectedPlanData?.annual_pricing,
            selectedModel: getMatchingModel(selectedPlanData?.model_id),
            slotGroup: { id: selectedPlanData?.slot_group, name: selectedPlanData?.slot_group },
            planJobCount: selectedPlanData?.job_count,
            planInternshipCount: selectedPlanData?.internship_count,
            planTestPinsCount: selectedPlanData?.test_pins_count,
            hasKAM: selectedPlanData?.is_manager ? "yes" : "no",
            unlimited: selectedPlanData?.job_count === "Unlimited" ? 'yes' : 'no',
            isEdit: true,
            profileUnlocks: selectedPlanData?.profile_unlocks,
            isCustomPlan: selectedPlanData?.is_custom,

        };

        setPlanModelCreationDetails(tempData);


    }

    const handleEditModel = (id) => {

        if (id < 4) {
            FailureAlert('Only Custom Models can be Edited')
            return
        }

        setOpen(true);
        const selectedModelArr = modalData?.filter((item) => (item?.id === id));
        const selectedModel = selectedModelArr[0];

        let temp = {
            modelDetails: selectedModel?.details,
            modelName: selectedModel?.name,
            isEdit: true,
            modelId: selectedModel?.id,
            isCustomModel: selectedModel?.is_custom,
        }

        setPlanModelCreationDetails(temp);
    }

    const planColumns = [

        {
            title: "Model Name",
            field: "model_name",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },

        {
            title: "Plan Name",
            field: "name",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },

        {
            title: "Monthly Pricing",
            field: "monthly_pricing",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Annual Pricing",
            field: "annual_pricing",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Creation Time",
            field: "create_time",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Updation Time",
            field: "update_time",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Is Active",
            field: "is_active",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Job Count",
            field: "job_count",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Slot Group",
            field: "slot_group",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Profile Unlocks",
            field: "profile_unlocks",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Test Pins Count",
            field: "test_pins_count",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Is Custom Plan",
            field: "is_custom",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Extra Data",
            field: "extra_data",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Details",
            field: "details",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) => (row?.details?.replace(/(<([^>]+)>)/ig, ''))

        },

        {
            title: "Edit Plan",
            field: "",
            headerStyle: headerCSS,
            cellStyle: {
                fontSize: "13px",
                padding: "6px 15px",
                height: "16px",
                textAlign: "left",
            },
            filtering: true,
            searchable: true,
            render: row =>
                <div>
                    <Button
                        style={{
                            fontSize: 13,
                            minWidth: '7rem',
                            minHeight: '1rem'
                        }}
                        color="primary"
                        variant='outlined'
                        onClick={() => { handleEditPlan(row?.id) }
                        }
                    >
                        Edit Plan
                    </Button>
                </div>
        },


    ]

    const modelsColumns = [

        {
            title: "Id",
            field: "id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Model Name",
            field: "name",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Is Active",
            field: "is_active",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Details",
            field: "details",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) => (row?.details?.replace(/(<([^>]+)>)/ig, ''))

        },
        {
            title: "Creation Time",
            field: "create_time",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Updation Time",
            field: "update_time",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "Edit Model",
            field: "",
            headerStyle: headerCSS,
            cellStyle: {
                fontSize: "13px",
                padding: "6px 15px",
                height: "16px",
                textAlign: "left",
            },
            filtering: true,
            searchable: true,
            render: row =>
                <div>
                    <Button
                        style={{
                            fontSize: 13,
                            minWidth: '7rem',
                            minHeight: '1rem'
                        }}
                        color="primary"
                        variant='outlined'
                        onClick={() => { handleEditModel(row?.id) }
                        }
                    >
                        Edit Model
                    </Button>
                </div>
        },


    ]

    const getContent = () => {

        switch (value) {
            case 0: return (
                <>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <br />
                        <Box className={classes.heading}>
                            <Autocomplete
                                id="select-channel"
                                options={modalData}
                                value={tableModel}
                                getOptionLabel={(item) => item.name}
                                style={{ minWidth: '16rem' }}
                                onChange={(e, item) => {
                                    const item2 = item || [];
                                    setTableModel(item2);

                                }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Models" required variant="outlined" />}
                            />
                            <Button
                                className={classes.btnAdd}
                                style={{ backgroundColor: '#303F9F', color: 'white' }}
                                // color="primary"
                                variant='contained'
                                onClick={() => {
                                    setOpen(true);
                                    handelResetForm();
                                }}
                            >
                                + New Plan
                            </Button>
                        </Box>

                        <TableWithExport
                            title={'Manage Plans'}
                            data={planTableData}
                            columns={planColumns}
                            fName="selected_plans"
                        />
                    </TabPanel>

                </>
            )
            case 1: return (
                <>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <br />
                        <Box className={classes.heading}>
                            <Button
                                className={classes.btnAdd}

                                // color="primary"
                                variant='contained'
                                onClick={() => {
                                    setOpen(true);
                                    handelResetForm();
                                }}
                            >
                                + New Model
                            </Button>
                        </Box>


                        <TableWithExport
                            title={'Manage Models'}
                            data={modalData}
                            columns={modelsColumns}
                            fName="selected_models"

                        />
                    </TabPanel>

                </>)
        }
    }


    return (
        <section>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Plans" {...a11yProps(0)} />
                    <Tab label="Models" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            {
                loading ? <GWLogoLoader /> :
                    getContent()
            }
            {
                open ?
                    <ModelPlanCreation
                        open={open}
                        setOpen={setOpen}
                        data={planModelCreationDetails}
                        setData={setPlanModelCreationDetails}
                        value={value}
                        handelResetForm={handelResetForm}
                        modalData={modalData}
                        setModalData={setModalData}
                        planData={planData}
                        setPlanData={setPlanData}
                        slotGroup={slotGroup}
                    /> : null
            }

        </section>
    )
}

export default ManagePlans