import { Button, Dialog, TextField, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { praveshAxiosPostReq } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import CustomButtonCircularProgress from "../../Student/Components/loader";

const useStyles = makeStyles((theme) => ({
    root1: {
        borderRadius: "20px",
        border: ".5px solid #707070",
        boxShadow: "none",
        padding: "20px",
    },
    button: {
        display: "flex",
        borderRadius: "30px",
        boxShadow: "none",
        height: "40px",
        paddingLeft: "40px",
        paddingRight: "40px",

    },
    btngrid: {
        paddingRight: "25px",
        display: "flex",
        paddingLeft: "25px",
        justifyContent: "space-around",
        paddingBottom: "20px",
    },
}));

const BlockStudent = ({
    open,
    setOpen,
    blockData,
    allData,
    setAllData,
    isDetail = false
}) => {

    const classes = useStyles();
    // const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [deleteReason, setDeleteReason] = useState(null);
    const [deleteReasonError, setDeleteReasonError] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const blockStudent = async () => {
        if (blockData?.is_active && !deleteReason) {
            FailureAlert("Please enter reason");
            return;
        }
        setLoadingStatus(true);
        // tempArr.push(basicInfo?.userId);
        const endPoint = "/api/admin/students/block/";
        const tempArr = [];
        tempArr.push(blockData?.userId);

        const data = {
            "user_ids": tempArr,
            "is_active": !blockData?.is_active,
            "reason": deleteReason ? deleteReason : "unblock",
        };

        try {

            const res = await praveshAxiosPostReq(endPoint, data);
            if (res?.data?.success) {

                SuccessAlert(res?.data?.data?.message);
                handleClose();
                setDeleteReason(null);

                if (!isDetail) {
                    let tempData = [...allData];
                    let updateData = null;
                    tempData.forEach((item, index) => {

                        if (item?.user_id === blockData?.userId) {
                            updateData = index;
                        };

                    })
                    if (updateData || updateData === 0) {
                        tempData[updateData].is_active = tempData[updateData].is_active ? false : true;
                    }
                    setAllData(tempData);
                } else {

                    setAllData({ ...blockData, is_active: !blockData?.is_active });
                }
                setLoadingStatus(false);
                // getAllStudentsData(true,setLoadingStatus);
                // setLoadingStatus(false); 

            } else {
                FailureAlert("Some Error Occured!");
            }
        }
        catch (error) {
            setLoadingStatus(false);
            throw (error)
        }
    };


    return (
        <>

            <Dialog
                fullScreen={fullScreen}
                maxWidth="md"
                PaperProps={{ classes: { root: classes.root1 } }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <div style={{ width: "400px" }}>
                    <div
                        style={{
                            padding: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h6">

                            Are you sure<br />
                            {`you  want ${blockData?.is_active ? "block" : "unblock"
                                } this student`}
                        </Typography>
                    </div>
                </div>
                {
                    !blockData?.is_active ? null : <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        inputProps={{
                            style: {
                                padding: "16px",
                            },
                        }}
                        multiline
                        rows="2"
                        InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                        }}
                        label={`Why you want to ${blockData?.is_active ? "block" : "unblock"
                            } this student?`}
                        value={deleteReason}
                        onChange={(e) => {
                            setDeleteReason(e.target.value);
                            setDeleteReasonError("");
                        }}
                        style={{ marginBottom: 10 }}
                        error={deleteReasonError ? true : false}
                        helperText={<span>{deleteReasonError}</span>}
                    />


                }


                <br />

                <div className={classes.btngrid}>
                    <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={handleClose}
                        color="blue"
                    >
                        Cancel
                    </Button>

                    <Button
                        autoFocus
                        className={classes.button}
                        variant="contained"
                        style={{
                            // color: `${blockStatus ? 'white' : }`,
                            color: "white",
                            backgroundColor: `${!blockData?.is_active ? 'green' : 'red'}`,
                        }}

                        onClick={() => {
                            blockStudent();
                        }}
                    >
                        {loadingStatus ? <CustomButtonCircularProgress /> : blockData?.is_active ? "Block" : "Unblock"}
                    </Button>
                </div>
                {/* </DialogActions> */}
            </Dialog>

        </>
    )
}

export default BlockStudent