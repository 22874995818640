import {
  Checkbox,
  FormControl, FormControlLabel, FormGroup, FormLabel,
  Grid, Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "5px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  font: {
    fontWeight: "600",
  },

  input: {
    height: 48,
  },
  // OutlinedInput: {
  //   height: 48,
  //   fontFamily: "Nunito",
  // },
  inputbase: {
    padding: "10px 14px",
    fontSize: "14px",
  },
  resize: {
    color: "#7e7e7e",
    padding: "6px",
    fontSize: "14px",
    paddingLeft: "10px",
  },

  MuiInput: {
    height: 48,
  },
  forgot: {
    fontSize: "12px",
    fontWeight: "bold",

    color: "#007bff",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
    marginRight: "10px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  cal: {
    marginBottom: "20px",
  },
  btn: {
    backgroundColor: "#007bff",
    borderRadius: "30px",
    height: "48px",
    boxShadow: "none",
    fontSize: "16px",
  },
  check: {
    display: "flex",
    flexDirection: "row",
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      // className={classes.root}
      disableRipple
      color="primary"
      // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      // icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function LanguageForm({addLang, setAddLang, setProficiency, level, setLevel}) {
  const classes = useStyles();

  const handleLevelCheck = (checkEvent) => {
    if(checkEvent.target.checked){
      setLevel([...level, checkEvent.target.value])
    }
    else
      setLevel((level) =>
      level.filter((item) => item !== checkEvent.target.value)
    );
    }

  return (
    <>
      <div>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className={classes.margin}
                label="Language*"
                variant="outlined"
                size="small"
                id="custom-css-outlined-input"
                onChange={(e) => {setAddLang(e.target.value)}}
                value={addLang}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Typography variant="body2">Proficiency*:</Typography>
                </FormLabel>
                <RadioGroup
                  className={classes.check}
                  defaultValue="female"
                  aria-label="gender"
                  name="customized-radios"
                  onChange = {(e) => {setProficiency(e.target.value)}}
                >
                  <FormControlLabel
                    value="Beginner"
                    control={<StyledRadio />}
                    label={<Typography variant="body2">Beginner</Typography>}
                  />
                  <FormControlLabel
                    value="Intermediate"
                    control={<StyledRadio />}
                    label={
                      <Typography variant="body2">Intermediate</Typography>
                    }
                  />
                  <FormControlLabel
                    value="Expert"
                    control={<StyledRadio />}
                    label={<Typography variant="body2">Expert</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <div>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <Typography variant="body2">Skill Level*:</Typography>

                  <FormGroup className={classes.check}>
                    <FormControlLabel
                      value="Read"
                      control={<Checkbox />}
                      onChange={(e) => {handleLevelCheck(e)}}
                      label={<Typography variant="body2">Read</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="Write"
                      control={<Checkbox />}
                      onChange={(e) => {handleLevelCheck(e)}}
                      label={<Typography variant="body2">Write</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="Speak"
                      control={<Checkbox />}
                      onChange={(e) => {handleLevelCheck(e)}}
                      label={<Typography variant="body2">Speak</Typography>}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
