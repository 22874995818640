import {
  IconButton,

  InputBase, makeStyles,

  Paper, Typography
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import s4 from "../../../assets/Icons/fb.svg";
import s1 from "../../../assets/Icons/linkedin.svg";
import s3 from "../../../assets/Icons/telegram.svg";
import s2 from "../../../assets/Icons/whatsup.svg";
import { SuccessAlert } from "../../../util/Notifications";
import { createGetWorkPublicLink } from "../../../util/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    border: "1px solid #e1e3e4",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function SocialMedia({data}) {
  const classes = useStyles();

  function sharingText(detail) {
    let count = 1;
    return `*${count++}. Company Name:* ${detail.company_name} \n*${count++}. Job Title:* ${detail.job_title} \n*${count++}. Job Type:* ${detail.job_type_name} \n \n\n \n*Note:* _This job is powered by GetWork! When calling the contact person, always tell that you found this job on GetWork._\n`
    }

  const getShareButton = (iconId) => {
    switch(iconId){
      case "WHATSAPP":
        return  (
        <WhatsappShareButton style={{ outline: "none", display:"flex" }} url={createGetWorkPublicLink(data, "share_whatsapp")} title={sharingText(data)} separator='*Job Link:*'>
                        <WhatsappIcon round={true} size={32} />
        </WhatsappShareButton>);
      case "FACEBOOK":
        return  (
          <FacebookShareButton style={{ outline: "none", display:"flex" }} url={createGetWorkPublicLink(data, "share_facebook")} separator='*Job Link:*'>
                          <FacebookIcon round={true} size={32} />
          </FacebookShareButton>);
      case "TELEGRAM":
        return  (
          <TelegramShareButton style={{ outline: "none", display:"flex" }} url={createGetWorkPublicLink(data, "share_telegram")} separator='*Job Link:*'>
                          <TelegramIcon round={true} size={32} />
          </TelegramShareButton>);
      case "LINKEDIN":
        return  (
          <LinkedinShareButton style={{ outline: "none", display:"flex" }} url={createGetWorkPublicLink(data, "share_linkedin")} title={sharingText(data)} separator='*Job Link:*'>
                          <LinkedinIcon round={true} size={32} />
          </LinkedinShareButton>);
    }
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        {SocialIcons.map((item, i) => (
          <div key={i}>
            <div
              style={{
                display: "flex",

                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 50,
                  width: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: item.icon_bg,
                  borderRadius: "50%",
                  padding: 7,
                }}
              >
                {/* <img src={item.icon} alt="icons " /> */}
                {getShareButton(item?.iconId)}
              </div>

              <Typography
                style={{ color: item.color, fontSize: "12px", marginTop: 8 }}
              >
                {item.text}
              </Typography>
            </div>
          </div>
        ))}
      </div>

      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            value={`${createGetWorkPublicLink(data, "share_copy")}`}
            inputProps={{ "aria-label": "link" }}
          />
          <CopyToClipboard
            text={createGetWorkPublicLink(data, "share_copy")}
            onCopy={() => SuccessAlert("Copied!")}
          >
          <IconButton
            color="primary"
            className={classes.iconButton}
            aria-label="directions"
          >
            <FileCopyOutlinedIcon />
          </IconButton>
          </CopyToClipboard>
        </Paper>
      </div>
    </div>
  );
}

export default SocialMedia;

const SocialIcons = [
  {
    icon: s1,
    icon_bg: "#e5f1f7",
    color: "#0076B2",
    text: "LinkedIn",
    link: "#",
    iconId: "LINKEDIN"
  },
  {
    icon: s2,
    icon_bg: "#e9fbf0",
    color: "#25D366",
    text: "WhatsApp",
    link: "#",
    iconId: "WHATSAPP"
  },
  {
    icon: s3,
    icon_bg: "#eaf7fd",
    color: "#2AABEE",
    text: "Telegram",
    link: "#",
    iconId: "TELEGRAM"
  },
  {
    icon: s4,
    icon_bg: "#e8f1fe",
    color: "#1877F2",
    text: "Facebook",
    link: "#",
    iconId: "FACEBOOK"
  }
];
