import {
  faBuildingColumns,
  faCreditCard,
  faCubes,
  faDiagramSuccessor,
  faGears,
  faGraduationCap,
  faLaptop,
  faPeopleGroup,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileCopy, FindInPage } from "@material-ui/icons";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BallotSharpIcon from "@material-ui/icons/BallotSharp";
import BookIcon from "@material-ui/icons/Book";
import BusinessIcon from "@material-ui/icons/Business";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ContactsIcon from "@material-ui/icons/Contacts";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import EditIcon from "@material-ui/icons/Edit";
import GroupsIcon from "@material-ui/icons/Group";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AccessControlsIcon from "@material-ui/icons/LockOpenSharp";
import PersonIcon from "@material-ui/icons/Person";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PublicIcon from "@material-ui/icons/Public";
import SchoolIcon from "@material-ui/icons/School";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import TelegramIcon from "@material-ui/icons/Telegram";
import WorkIcon from "@material-ui/icons/Work";
import MicIcon from "@material-ui/icons/Mic";
import EmailIcon from "@material-ui/icons/Email";

export const IconsCollection = {
  Dashboard: <DashboardIcon />,
  PodCast: <MicIcon />,
  EDIT_DEGREE: <EditIcon />,
  ADD_DEGREE: <AddBoxIcon />,
  ALL_COLLEGES: <SchoolIcon />,
  ADD_COLLEGES: <AddBoxIcon />,
  COLLEGE_EXCEL: <AddBoxIcon />,
  EDIT_COLLEGE: <EditIcon />,
  PAYMENT_DETAILS: <TelegramIcon />,
  ACCOUNT_MANAGER: <PersonIcon />,
  COLLEGE_STUDENTS: <SchoolIcon />,
  COLLEGE_JOBS: <WorkIcon />,
  USER_MANAGEMENT: <PersonIcon />,
  INBOUND_LEADS: <ContactsIcon />,
  "ADD_COMPANIES\n": <AddBoxIcon />,
  EDIT_COMPANIES: <EditIcon />,
  COMPANY_ADD_NEW: <ApartmentIcon />,
  COMPANY_EDIT_NEW: <EditIcon />,
  COMPANY_PLAN_MANAGE: <DynamicFeedIcon />,
  ALL_COMPANIES: <ApartmentIcon />,
  EDIT_GROUPS: <EditIcon />,
  ADD_GROUPS: <AddBoxIcon />,
  ALL_GROUPS: <SchoolIcon />,
  SUB_CATAGORY: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  CATAGORY: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  SEGMENTS: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  OPEN_JOBS: <DescriptionIcon />,
  CLOSED_JOBS: <DescriptionIcon />,
  POST_JOBS: <DescriptionIcon />,
  EDIT_JOBS: <EditIcon />,
  MANAGER_DASH: <DashboardIcon />,
  ALL_STUDNETS: <PersonIcon />,
  ADD_STUDNET: <AddBoxIcon />,
  Sandesh: <TelegramIcon />,
  "Communication Templates": <TelegramIcon />,
  TEMPLATE_VIEW: <DescriptionIcon />,
  TEMPLATE_EDIT: <EditIcon />,
  VAULT_CATAGORY: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  VAULT_SUB_CATAGORY: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  USER_ROLES: <DescriptionIcon />,

  NOTIFICATION: <AddBoxIcon />,

  DEGREE_ADD: <AddBoxIcon />,
  DEGREE_EDIT: <EditIcon />,

  DEGREE_SPEC_EDIT: <EditIcon />,

  COLLEGE_EDIT: <EditIcon />,
  COLLEGE_EXCEL: <AddBoxIcon />,
  COLLEGE_ALL: <SchoolIcon />,
  COLLEGE_ADD: <AddBoxIcon />,
  IN_LEADS: <ContactsIcon />,
  COLLEGE_USER_MAN: <PersonIcon />,

  COLLEGE_JOBS: <WorkIcon />,

  COLLEGE_STUDENTS: <SchoolIcon />,
  COLLEGE_ACC_MAN: <PersonIcon />,

  COLLEGE_PAYMENT: <TelegramIcon />,

  COMPANY_ALL: <ApartmentIcon />,
  COMPANY_ADD: <AddBoxIcon />,

  COMPANY_EDIT: <EditIcon />,

  COMPANY_VERIFY: <EditIcon />,
  ADD_COMP_NEW: <ApartmentIcon />,

  COMPANY_ACC_MAN: <PersonIcon />,
  COMPANY_PROPOSAL: <DescriptionIcon />,
  COMPANY_USER_MAN: <PersonIcon />,
  COMPANY_EXCEL: <AddBoxIcon />,
  COMPANY_PAY: (
    <FontAwesomeIcon icon={faCreditCard} style={{ fontSize: "22px" }} />
  ),
  "TEAM GETWORK": (
    <FontAwesomeIcon
      icon={faPeopleGroup}
      style={{ fontSize: "22px", marginLeft: "-3px" }}
    />
  ),
  PLAN_UNLOCK_HISTORY: <LockOpenIcon />,
  College: (
    <FontAwesomeIcon icon={faBuildingColumns} style={{ fontSize: "22px" }} />
  ),
  "Educational Group": (
    <FontAwesomeIcon
      icon={faGraduationCap}
      style={{ fontSize: "22px", marginLeft: "-2px" }}
    />
  ),
  Companies: <BusinessIcon />,
  Students: (
    <FontAwesomeIcon
      icon={faUserGraduate}
      style={{ fontSize: "21px", marginLeft: "3px" }}
    />
  ),
  "Branches/ Courses": <BookIcon />,
  Jobs: <WorkIcon />,
  "GetWork Credit System": (
    <FontAwesomeIcon icon={faCreditCard} style={{ fontSize: "22px" }} />
  ),
  "App Notification": <SmartphoneIcon />,
  "7_CLUB_LEADS": <ContactsIcon />,
  Miscellaneous: (
    <FontAwesomeIcon icon={faGears} style={{ fontSize: "22px" }} />
  ),
  SaaS: <FontAwesomeIcon icon={faLaptop} style={{ fontSize: "22px" }} />,
  "GetWork Club": (
    <FontAwesomeIcon icon={faCubes} style={{ fontSize: "22px" }} />
  ),
  "Student Vault": (
    <FontAwesomeIcon icon={faDiagramSuccessor} style={{ fontSize: "22px" }} />
  ),
  MAN_DASH: <DashboardIcon />,
  POD_CAST: <DynamicFeedIcon />,
  DASH: <DashboardIcon />,
  TEAM: <ApartmentIcon />,
  GROUP_ALL: <SchoolIcon />,
  GROUP_ADD: <AddBoxIcon />,
  GROUP_EDIT: <EditIcon />,
  STUDENT_EXCEL: <AddBoxIcon />,
  STUDENT_UPLOAD: <AddBoxIcon />,
  STUDENT_3RD_SEARCH: <FindInPage />,
  STUDENT_DETAILS: <PersonIcon />,
  STUDENT_ALL: <PersonIcon />,
  USER_RESUME: <LineStyleIcon />,
  STUDENT_SOURCE: <PersonIcon />,
  RESUME_TEMPLATES: <BallotSharpIcon />,
  STUDENT_BULK_RESUME: <ContactsIcon />,
  USER_BULK_CREATE: <GroupsIcon />,
  STUDENT_ASSIGN_CANDIDATE: <AddBoxIcon />,

  JOB_OPEN: <DescriptionIcon />,

  JOB_CLOSED: <DescriptionIcon />,
  JOB_POST_SALES: <DescriptionIcon />,

  JOB_POST_PUBLIC: <PublicIcon />,
  JOB_POST_3_PARTY: <DescriptionIcon />,

  INTERVIEWS: <DescriptionIcon />,
  VERIFY_JOB: <DescriptionIcon />,

  JOB_POST: <DescriptionIcon />,
  JOB_EDIT: <EditIcon />,
  CLONE_JOB: <FileCopy />,
  JOB_APPLY: <EditIcon />,
  CLOSE_REQUEST: <CloseIcon />,

  CREDIT_ADD: <AddBoxIcon />,
  CREDIT_PAY: <DescriptionIcon />,
  CREDIT_UNLOCK: <DescriptionIcon />,

  PASS_CHANGE: <EditIcon />,
  USER_DETAILS: <EditIcon />,

  BLOG_UPDATE: <DescriptionIcon />,
  BANNER_UPDATE: <EditIcon />,
  IMAGES_UPLOAD: <EditIcon />,

  SHARER_CATEGORY: <EditIcon />,
  SHARER_SUB: <EditIcon />,

  SHARER_SUB_POST: <EditIcon />,
  ACTIVE_YEARS: <EditIcon />,
  LOCATION: <DescriptionIcon />,

  PAGE_TYPE: <DescriptionIcon />,
  SKILL_EDU: <DescriptionIcon />,

  SKILL_TYPE: <DescriptionIcon />,
  SKILL_RATING: <DescriptionIcon />,

  COMPANY_DOMAIN: <DescriptionIcon />,
  INDUSTRY: <DescriptionIcon />,

  UNIVERSITY: <DescriptionIcon />,
  RECHARGE_UPLOAD: <DescriptionIcon />,

  CREDITS_SERVICE: <DescriptionIcon />,

  REFERRAL_CODE: <DescriptionIcon />,

  COWORKING: <DescriptionIcon />,
  JOB_DESC: <DescriptionIcon />,

  JOB_ROUND: <DescriptionIcon />,

  JOB_STATUS: <DescriptionIcon />,
  JOB_TYPE: <DescriptionIcon />,

  JOB_SEGMENT: <DescriptionIcon />,

  JOB_ROLE: <DescriptionIcon />,

  JOB_GROUP: <DescriptionIcon />,
  JOB_EMP_TYPE: <DescriptionIcon />,

  CONTACT_US: <ContactsIcon />,
  Marketing: <ApartmentIcon />,
  MARKETING_LEADS: <DescriptionIcon />,
  CANDIDATE_AND_COMPANIES_LEADS: <DescriptionIcon />,
  SAAS_ACCESS: <AccessControlsIcon />,
  SAAS_PLACE_RULE: <DescriptionIcon />,
  CLUB_ALL: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  CLUB_ADD: <PostAddIcon style={{ fontSize: "18px", marginRight: "5px" }} />,
  CLUB_VENDOR: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  CLUB_VENDOR_ADD: (
    <CreateOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  CLUB_SEGMENTS: (
    <CreateOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  CLUB_CATEGORY: (
    <CreateOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  CLUB_SUB: (
    <CreateOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  CLUB_HISTORY: (
    <PostAddIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  CLUB_BANNER: (
    <CreateOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  CLUB_COUPON: (
    <CreateOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  CLUB_COUPON_ALL: (
    <CreateOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  CLUB_COUPON_ADD: (
    <PostAddIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  VAULT_CATAGORY: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),

  VAULT_SUB: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  Reports: <AssessmentIcon />,
  REPORT: (
    <CategoryOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  Vendors: (
    <GroupAddOutlinedIcon style={{ fontSize: "18px", marginRight: "5px" }} />
  ),
  Firms: <BusinessIcon />,
};
