import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

import { ReactComponent as Vactor } from "../../../assets/svg/customNoSearch.svg";

import UseAxiosApi from "../../../Api/UseAxiosApi";
import { niyuktiUrl, praveshUrl } from "../../../constants";
import ExperienceSlider from "./ExperienceSlider";
import { QuerySource } from "./QuerySource";

const infoitem = {
  display: "flex",
  flexDirection: "row",
  alignContent: "space-around",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: "10px",
};

const info_icon = {
  color: "#6c757d",
  height: "28px",
  marginRight: "10px",
};
const detail = {
  display: "flex",
  flexWrap: "wrap",
  // flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  alignContent: "center",
};

function CustomSource({
  value,
  setShowSourceResult,
  handleSubmitQuery,
  setCustomSourcing,
  customSourcing,
  submitLoading,
}) {
  const theme = useTheme();

  const experience = [
    { title: "Fresher", min: 0, max: 1 },
    { title: "1 - 3 years", min: 1, max: 3 },
    { title: "3 - 5 years", min: 3, max: 5 },
    { title: "5 - 7 years", min: 5, max: 7 },
    { title: "7+ years", min: 7, max: 7 },
    // { title: "> 5 years", val: 5 },
    // { title: "> 6 years", val: 6 },
    // { title: "> 7 years", val: 7 },
    // { title: "> 8 years", val: 8 },
    // { title: "> 9 years", val: 9 },
    // { title: "> 10 years", val: 10 },
    // { title: "10+ years", val: 11 },
  ];
  // const educationLevels = [
  //   { title: "Graduation (UG)", id: 3 },
  //   { title: "Post Graduation (PG)", id: 4 },
  //   { title: "PhD", id: 5 },
  //   { title: "Diploma", id: 6 },
  // ];
  const noticePeriods = [
    { title: "Immediate", id: 7 },
    { title: "15 days", id: 15 },
    { title: "15 days", id: 15 },
    { title: "30 days", id: 30 },
    { title: "45 days", id: 45 },
    { title: "60 days", id: 60 },
    { title: "75 days", id: 75 },
    { title: "90 days", id: 90 },
  ];

  const initialState = {
    jobSegments: [],
    jobRoles: [],
    expLevels: [],
    expCtc: null,
    noticePeriods: [],
    locations: [],
    states: [],
    eduLevels: [],
    degrees: [],
    clgCategories: [],
  };
  /*  const [customSourcing, setCustomSourcing] = useState(null); */
  const [expCtc, setExpCtc] = useState([0, 0]);
  const [jobSegmentsList, setJobSegmentsList] = useState(null);
  const [jobRolesList, setJobRolesList] = useState(null);
  const [educationLevels, setEducationLevels] = useState(null);
  const [locationsList, setLocationsList] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [degreesList, setDegreesList] = useState(null);

  const [specializationList, setSpecializationList] = useState(null);
  const [clgCategoriesList, setClgCategoriesList] = useState(null);
  const [allSkills, setAllSkills] = useState([]);
  // expLevels: [],
  // expCtc: null,
  // noticePeriods: [],
  // eduLevels: [],

  const skillshelper = (data) => {
    setAllSkills(data?.data?.skills);
  };
  UseAxiosApi({
    url: `${praveshUrl}/api/education/skills`,
    setResponse: skillshelper,
  });

  UseAxiosApi({
    url: `${praveshUrl}/api/company/job_segment/`,
    setResponse: setJobSegmentsList,
  });

  UseAxiosApi({
    url: `${niyuktiUrl}/job/role_group/`,
    setResponse: setJobRolesList,
  });

  UseAxiosApi({
    url: `${praveshUrl}/api/location/city_state/`,
    setResponse: setLocationsList,
  });

  UseAxiosApi({
    url: `${praveshUrl}/api/shared/get/all/degree_spec/`,
    setResponse: (data) => {
      setDegreesList(data?.data?.degree);
      setSpecializationList(data?.data?.specialization);
    },
  });

  // useEffect(() => {
  //   console.log("customSourcing", customSourcing);
  // }, [customSourcing]);

  return (
    <>
      <Box style={{ marginTop: 20 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box style={{ marginTop: 8 }}>
              <Box
                style={{
                  padding: 0,
                }}
              >
                <a href=""></a>
                <Box>
                  {" "}
                  <Grid container spacing={2}>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={4}
                        id="tags-outlined"
                        options={
                          jobSegmentsList?.data ? jobSegmentsList?.data : []
                        }
                        getOptionLabel={(option) => option.job_segment_name}
                        value={
                          customSourcing?.jobSegments?.length > 0
                            ? customSourcing?.jobSegments
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["job_segment_id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            jobSegments: uniqueObjArray,
                          });
                          // }
                        }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              label={option.job_segment_name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label="Job Segments"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={1}
                        style={{
                          display: "contents",
                        }}
                        id="tags-outlined"
                        options={
                          jobRolesList?.data?.length > 0
                            ? jobRolesList?.data
                            : []
                        }
                        value={
                          customSourcing?.jobRoles?.length > 0
                            ? customSourcing?.jobRoles
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            jobRoles: uniqueObjArray,
                          });
                          // }
                        }}
                        // getOptionLabel={(option) => option.job_role_name}
                        getOptionLabel={(option) => option?.job_role_group}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              // style={{
                              //   backgroundColor: alpha(
                              //     theme.palette.primary.main,
                              //     0.1
                              //   ),
                              // }}
                              // label={option.job_role_name}
                              label={option?.job_role_group}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        /*  defaultValue={[top100Films[1]]} */
                        // filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label="Job Role/ Profile"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={7}
                        id="multiple-limit-tags"
                        options={allSkills}
                        getOptionLabel={(option) => option?.skill_name}
                        //defaultValue={[top100Films[1], top100Films[2], top100Films[1]]}
                        value={
                          customSourcing?.skills?.length > 0
                            ? customSourcing?.skills
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["skill_id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            skills: uniqueObjArray,
                          });
                          // }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              // style={{
                              //   backgroundColor: alpha(
                              //     theme.palette.primary.main,
                              //     0.1
                              //   ),
                              // }}
                              label={option?.skill_name}
                              deleteIcon={<CloseIcon />}
                              size="small"
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label="Skills"
                            placeholder="Add More Skills"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={experience}
                        //options={experience?.map((option) => option.title)}
                        value={
                          customSourcing?.expLevels?.length > 0
                            ? customSourcing?.expLevels
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["title"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            expLevels: uniqueObjArray,
                          });
                          // }
                        }}
                        getOptionLabel={(option) => option.title}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              // style={{
                              //   backgroundColor: alpha(
                              //     theme.palette.primary.main,
                              //     0.1
                              //   ),
                              // }}
                              label={option.title}
                              //label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        // filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Experience Level"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Typography variant="body1" style={{ fontWeight: "600" }}>
                        Expected CTC (LPA)
                      </Typography>
                      <ExperienceSlider
                        value={customSourcing?.expCtc}
                        setValue={(value) => {
                          setCustomSourcing({
                            ...customSourcing,
                            expCtc: value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Typography variant="body1" style={{ fontWeight: "600" }}>
                        Current CTC (LPA)
                      </Typography>
                      <ExperienceSlider
                        value={customSourcing?.currentCtc}
                        setValue={(value) => {
                          setCustomSourcing({
                            ...customSourcing,
                            currentCtc: value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={1}
                        id="tags-outlined"
                        options={degreesList?.length > 0 ? degreesList : []}
                        value={
                          customSourcing?.degrees?.length > 0
                            ? customSourcing?.degrees
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            degrees: uniqueObjArray,
                          });
                          // }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        //filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Degree"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={1}
                        id="tags-outlined"
                        options={
                          specializationList?.length > 0
                            ? specializationList
                            : []
                        }
                        value={
                          customSourcing?.specializations?.length > 0
                            ? customSourcing?.specializations
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            specializations: uniqueObjArray,
                          });
                          // }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        //filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Specialization"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        // options={noticePeriods}
                        options={noticePeriods?.map((option) => option.title)}
                        value={
                          customSourcing?.noticePeriods?.length > 0
                            ? customSourcing?.noticePeriods
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          var arr = [...new Set(i)];
                          setCustomSourcing({
                            ...customSourcing,
                            noticePeriods: arr,
                          });
                          // }
                        }}
                        // getOptionLabel={(option) => option.title}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              // label={option.title}
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        // filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Notice Period "
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="tags-outlined"
                        value={
                          customSourcing?.locations?.length > 0
                            ? customSourcing?.locations
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["city_id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            locations: uniqueObjArray,
                          });
                          // }
                        }}
                        options={
                          locationsList?.data?.length > 0
                            ? locationsList?.data
                            : []
                        }
                        getOptionLabel={(option) =>
                          `${option.city}, ${option.state}`
                        }
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              label={`${option.city}, ${option.state}`}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        // filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Current Location"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="tags-outlined"
                        value={
                          customSourcing?.preferedLocations?.length > 0
                            ? customSourcing?.preferedLocations
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["city_id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            preferedLocations: uniqueObjArray,
                          });
                          // }
                        }}
                        options={
                          locationsList?.data?.length > 0
                            ? locationsList?.data
                            : []
                        }
                        getOptionLabel={(option) =>
                          `${option.city}, ${option.state}`
                        }
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                              label={`${option.city}, ${option.state}`}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        // filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label="Prefered Location"
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="tags-outlined"
                        value={
                          customSourcing?.states?.length > 0
                            ? customSourcing?.states
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["state_id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            states: uniqueObjArray,
                          });
                          // }
                        }}
                        options={stateList?.length > 0 ? stateList : []}
                        getOptionLabel={(option) => `${option.state}`}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                           
                              label={`${option?.state}`}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        // filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="States"
                          />
                        )}
                      />
                    </Grid>
                    
                    <Grid md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={
                          educationLevels?.length > 0 ? educationLevels : []
                        }
                        //options={educationLevels?.map((option) => option.title)}
                        value={
                          customSourcing?.eduLevels?.length > 0
                            ? customSourcing?.eduLevels
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            eduLevels: uniqueObjArray,
                          });
                          // }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                           
                              label={option.name}
                              //label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        // defaultValue={[top100Films[1]]}
                        //filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Education Level"
                          />
                        )}
                      />
                    </Grid>{" "}  */}

                    {/* <Grid md={12} style={{ display: "grid" }}>
                      <Autocomplete
                        multiple
                        limitTags={7}
                        id="tags-outlined"
                        options={
                          clgCategoriesList?.data ? clgCategoriesList?.data : []
                        }
                        value={
                          customSourcing?.clgCategories?.length > 0
                            ? customSourcing?.clgCategories
                            : []
                        }
                        onChange={(e, i) => {
                          // if (i.length > 0) {
                          //   var arr = [...new Set(i)];
                          let uniqueObjArray = [
                            ...new Map(
                              i?.map((item) => [item["id"], item])
                            ).values(),
                          ];
                          setCustomSourcing({
                            ...customSourcing,
                            clgCategories: uniqueObjArray,
                          });
                        }}
                        getOptionLabel={(option) => option.category}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              color="primary"
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              size="small"
                           
                              label={option.category}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                       
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="College Category "
                          />
                        )}
                      />
                    </Grid> */}
                  </Grid>
                </Box>
              </Box>

              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "8px",
                  height: "40px",
                  boxShadow: "none",
                  marginTop: 16,
                  minWidth: "200px",
                }}
                onClick={() => {
                  handleSubmitQuery(customSourcing, "customQuery");
                }}
              >
                {submitLoading ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Grid>

          {
            /* Object?.keys(customSourcing)?.length > 1 || */
            customSourcing?.jobSegments?.length > 0 ||
            customSourcing?.jobRoles?.length > 0 ||
            customSourcing?.skills?.length > 0 ||
            customSourcing?.expLevels?.length > 0 ||
            customSourcing?.noticePeriods?.length > 0 ||
            customSourcing?.locations?.length > 0 ||
            customSourcing?.preferedLocations?.length > 0 ||
            customSourcing?.states?.length > 0 ||
            customSourcing?.eduLevels?.length > 0 ||
            customSourcing?.degrees?.length > 0 ||
            customSourcing?.specializations?.length > 0 ||
            customSourcing?.clgCategories?.length > 0 ||
            (customSourcing?.expCtc &&
              (customSourcing?.expCtc[0] > 0 ||
                customSourcing?.expCtc[1] > 0)) ||
            (customSourcing?.currentCtc &&
              (customSourcing?.currentCtc[0] > 0 ||
                customSourcing?.currentCtc[1] > 0)) ? (
              <Grid item md={6}>
                <Box
                  style={{
                    boxShadow: "0px 0px 5px 2px #00000017",
                    borderRadius: "8px",
                    padding: 16,
                    marginTop: 16,
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                    Source Query
                  </Typography>
                  <div style={{ marginTop: "10px" }}>
                    <QuerySource data={customSourcing} sourceResults="source" />
                  </div>
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item md={4}>
                  <Box
                    style={{
                      boxShadow: "0px 0px 5px 2px #00000017",
                      borderRadius: "8px",
                      padding: 16,
                      marginTop: 16,
                      marginLeft: 24,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        aligncontent: "center",
                      }}
                    >
                      <Vactor
                        style={{ height: "200px" }}
                        fill={theme.palette.primary.main}
                      />
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          /* color: "#12DC00", */ fontWeight: "600",

                          marginTop: 16,
                        }}
                      >
                        GetWork Talent Pool (1800K+ Profiles)
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          /* color: "#12DC00", */ fontWeight: "600",
                          marginBottom: 16,
                        }}
                      >
                        Hire Your Next Hire The Best
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#6C757D",
                          padding: "0 10px 10px 10px",
                          textAlign: "center",
                        }}
                      >
                        Powered by AI & ML Algorithms calculates the relevance
                        score of the candidates with a 98% of accuracy to ensure
                        the candidate’s profile matches the job criteria and
                        your business needs. We focus on candidate’s expertise &
                        industrial values.{" "}
                        {/* <Link
                          to="group_FAQs/companies-2"
                          sx={{ textDecoration: "none" }}
                        >{` learn more`}</Link> */}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </>
            )
          }
        </Grid>
      </Box>
    </>
  );
}

export default CustomSource;
