import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { niyuktiAxiosCall } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 30,
    position: "relative",
    boxShadow: "0px 1px 4.800000190734863px 0px #0000001A",
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    padding: "5px 0px 5x 5px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    width: 100,
    "&:focus": {
      borderRadius: 30,
      borderColor: "none",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#007bff",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  menuitem: {
    margin: 10,
    width: "fit-content",
    backgroundColor: "#E3E3E3",
    borderRadius: 30,
  },
});

export default function AssignTable({data, onInterviewStatusUpdate, rowIndex}) {
  const classes = useStyles();
  const [loader, setLoader] = React.useState(false);
  
  const handleChange = (event, row, studentIndex) => {
    setLoader(true);
    niyuktiAxiosCall.patch(`/api/manage_jobs/admin/interview/student/`, {
        job_interview_student_id: row.job_interview_student_id,
        interview_stage: event.target.value
    }).then(res => {
      if(res?.data?.success){
        SuccessAlert("Status updated!!");
        onInterviewStatusUpdate(event.target.value, rowIndex, studentIndex);
      }else{
        FailureAlert("Something went wrong!!")
      }
      setLoader(false);
    }).catch(err => {
      FailureAlert("Something went wrong!!");
      setLoader(false);
    })
  };

  return (
    <LoadingOverlay
              active={loader}
              spinner
              text="Please Wait..."
      >
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Full Name</StyledTableCell>
            <StyledTableCell align="center">Phone Number</StyledTableCell>
            <StyledTableCell align="center">Email ID</StyledTableCell>
            <StyledTableCell align="center">Resume</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Update Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <StyledTableRow key={row?.name}>
              <StyledTableCell component="th" scope="row">
                {row?.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row?.phone}</StyledTableCell>
              <StyledTableCell align="center">{row.email}</StyledTableCell>
              <StyledTableCell align="center">
                {row?.resume ? 
                <a
                  href={row.resume}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    fontSize: "14px",
                    color: "#2563EB",
                  }}
                >
                  View Resume
                </a> : 
                "NA"
                }
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  color:
                    row?.status === 0
                      ? "#FFA900"
                      : row?.status === 1
                      ? "#2563EB"
                      : row?.status === 2
                      ? "#0DA06A"
                      : "",
                }}
              >
                {row?.status === 0 ? "PENDING" : row?.status === 1 ? "ACCEPTED" : "REJECTED"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {/* {row.update_status} */}

                <FormControl className={classes.margin}>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={row?.interview_stage}
                    onChange={(event) => handleChange(event, row, index)}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={"SCHEDULED"} className={classes.menuitem}>
                      Scheduled
                    </MenuItem>
                    <MenuItem value={"NOT_JOINED"} className={classes.menuitem}>
                      Not Joined
                    </MenuItem>
                    <MenuItem value={"BACKOUT"} className={classes.menuitem}>
                      Backout
                    </MenuItem>
                    <MenuItem value={"DONE"} className={classes.menuitem}>
                      Done
                    </MenuItem>
                    <MenuItem value={"NO_RESPONSE"} className={classes.menuitem}>
                      No Response
                    </MenuItem>
                  </Select>
                </FormControl>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </LoadingOverlay>
  );
}
