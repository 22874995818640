import {
  Grid,
  Typography,
  TextField,
  Tooltip,
  Link,
  Button,
  InputAdornment,
  Paper,
  Chip,
  useTheme,
  ListItem,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { DataGrid } from "@material-ui/data-grid";
import ScoreDialog from "./ScoreDialog";
import CloseIcon from "@material-ui/icons/Close";
import { emailRegex } from "../../../constants";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import BlockIcon from "@material-ui/icons/Block";
import { niyuktiAxiosPostReq } from "../../../util/apiCall";
import { ContentState, convertFromHTML } from "draft-js";
import moment from "moment";

const JobApplicants = [
  {
    id: 1,
    field: "name",
    type: "text",
    width: 250,
    sortable: false,
    renderHeader: (params) => (
      <strong className="allHeaderCommonColor">Candidate Name</strong>
    ),
    renderCell: (params) => (
      <>
        {/* <Grid container wrap="nowrap" className="SomeColumnLeft ">
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/Job-profile/${params.row.applicant_name}`}
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params.row.applicant_name} placement="right"> */}
        <Typography noWrap variant="body2">
          {params.row.applicant_name}
        </Typography>
        {/* </Tooltip>
            </Link>
          </Grid>
        </Grid> */}
      </>
    ),
  },
  {
    id: 2,
    field: "salary",
    headerAlign: "center",
    // type: "text",
    sortable: false,
    width: 150,
    renderHeader: (params) => (
      <strong className="allHeaderCommonColor" style={{ marginLeft: 5 }}>
        Expected Salary
      </strong>
    ),
    renderCell: (params) => (
      <span className="allColumnTextCenter">
        {params.row.expectedCtc ? params.row.expectedCtc : "NA"}
      </span>
    ),
  },
  {
    id: 3,
    field: "location",
    // type: "text",
    sortable: false,
    headerAlign: "center",
    width: 160,
    renderHeader: (params) => (
      <strong className="allHeaderCommonColor" style={{ marginLeft: 20 }}>
        Location
      </strong>
    ),
    renderCell: (params) => (
      <span className="allColumnTextCenter">
        {params?.row?.applicant_city ? params?.row?.applicant_city : "NA"}
      </span>
    ),
  },

  {
    id: 4,
    field: "experience",
    headerAlign: "center",
    type: "text",
    sortable: false,
    width: 155,
    renderHeader: (params) => (
      <strong className="allHeaderCommonColor" style={{ marginLeft: 20 }}>
        Experience
      </strong>
    ),
    renderCell: (params) => (
      <span className="allColumnTextCenter">
        {params?.row?.work_exp ? `${params?.row?.work_exp} Months` : "NA"}
      </span>
    ),
  },
  // {
  //   id: 5,
  //   field: "relevance_score",
  //   headerName: "",
  //   // type: "button",
  //   sortable: false,
  //   width: 150,
  //   headerAlign: "center",
  //   renderHeader: (params) => (
  //     <strong className="allHeaderCommonColor" style={{ marginLeft: 5 }}>
  //       Relevance Score
  //     </strong>
  //   ),
  //   renderCell: (params) => {
  //     return (
  //       <>
  //         <div style={{ display: "flex", margin: "auto" }}>
  //           <ScoreDialog
  //             score={params?.row?.overall_applicant_score}
  //             scoreData={{ work_exp: params?.row?.work_exp }}
  //           />
  //         </div>
  //       </>
  //     );
  //   },
  // },
  {
    id: 6,
    field: "resume",
    headerAlign: "center",
    // type: "text",
    sortable: false,
    width: 150,
    renderHeader: (params) => (
      <strong className="allHeaderCommonColor" style={{ marginLeft: 20 }}>
        Resume
      </strong>
    ),
    renderCell: (params) => (
      <span style={{ margin: "auto", textAlign: "center" }}>
        {params?.row?.resume ? (
          <Typography
            noWrap
            //   color="primary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={
              params?.row?.resume
                ? () => {
                    window.open(params?.row?.resume, "_blank");
                  }
                : () => {}
            }
          >
            {"View"}
          </Typography>
        ) : (
          <Typography>{"NA"}</Typography>
        )}
      </span>
    ),
  },
];

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
//   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
//   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
//   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
//   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
//   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
// ];

function InterviewForm({
  handleClose,
  studentData,
  title,
  jobId,
  round,
  currRoundId,
  statusName,
  currStatusId,
  setStudentData,
  setstepData,
  step,
  selectedIDs,
}) {
  const [selectedDate, setSelectedDate] = useState(
    new Date("2014-08-18T21:11:54")
  );
  const [loading, setLoading] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const theme = useTheme();
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [email, setEmail] = useState("");

  const jobRound = "xyz";
  const initialState = {
    jobId: null,
    jobTitle: title,
    hiringType: "",

    deadline: new Date(),
    time: new Date(),
    slotInterval: null,
    jobRound: round,
    title: `${title} ${round} - Interview`,
    interviewerEmailList: [],
    type: null,
    address: "",
    interviewMeetingLink: null,
    customLink: "",
    platform: "MEET",

    description: "",
  };

  const [interviewForm, setInterviewForm] = useState(initialState);

  const deleteInterviewerEmail = (index) => {
    if (index !== -1) {
      var arr = [...interviewForm?.interviewerEmailList];
      arr.splice(index, 1);
      setInterviewForm({ ...interviewForm, interviewerEmailList: arr });
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function formatTime(date) {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  }

  const validateData = () => {
    if (!interviewForm?.deadline) {
      FailureAlert("Please Select the Date");
      return;
    }
    if (!interviewForm?.time) {
      FailureAlert("Please Select the Time");
      return;
    }
    if (!interviewForm?.jobRound) {
      FailureAlert("Please Select Job Round");
      return;
    }
    if (!interviewForm?.title) {
      FailureAlert("Please Select Interview Title");
      return;
    }
    // if (!interviewForm?.interviewerEmailList?.length > 0) {
    //   FailureAlert("Please Add Email");
    //   return;
    // }
    if (!interviewForm?.type) {
      FailureAlert("Please select interview type");
      return;
    }
    if (interviewForm?.type === "OFFLINE" && !interviewForm?.address) {
      FailureAlert("Please enter your address");
      return;
    }
    if (
      interviewForm?.type === "ONLINE" &&
      interviewForm?.interviewMeetingLink !== "generate" &&
      !interviewForm?.customLink
    ) {
      FailureAlert("Please enter custom link");
      return;
    }
    if (!interviewForm?.slotInterval) {
      FailureAlert("Please Select the Slot Duration");
      return;
    }
    return true;
  };

  function convertTo12HourFormat(timeString) {
    const [hours, minutes] = timeString.split(":");

    let hours12 = parseInt(hours, 10);
    const ampm = hours12 >= 12 ? "PM" : "AM";
    hours12 %= 12;
    hours12 = hours12 || 12;

    const hoursFormatted = hours12.toString().padStart(2, "0");

    const minutesFormatted = minutes.padStart(2, "0");

    return `${hoursFormatted}:${minutesFormatted} ${ampm}`;
  }
  function convertDateFormat(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  }

  const handleSubmit = async () => {
    try {
      if (validateData()) {
        let startDateTime = new Date(interviewForm.deadline);
        startDateTime.setHours(interviewForm.time.getHours());
        startDateTime.setMinutes(interviewForm.time.getMinutes());
        startDateTime.setSeconds(interviewForm.time.getSeconds());
        let studentDetails = [];
        for (let i = 0; i < studentData.length; i++) {
          let endDateTime = new Date(startDateTime);
          endDateTime.setMinutes(
            endDateTime.getMinutes() + Number(interviewForm.slotInterval.val)
          );

          studentDetails.push({
            id: studentData[i].user_id,
            applicant_id: studentData[i].applicant_id,
            user_id: studentData[i].user_id,
            name: studentData[i].applicant_name,
            expected_salary: studentData[i].expectedCtc,
            location: studentData[i].applicant_city,
            experience: studentData[i].work_exp,
            score: studentData[i].overall_applicant_score,
            preferred_location: null,
            email: studentData[i].applicant_email,
            score_data: null,
            resume: studentData[i].resume,
            start_date: `${formatDate(new Date(startDateTime))} ${formatTime(
              startDateTime
            ).slice(0, 5)}`,
            end_date: `${formatDate(new Date(endDateTime))} ${formatTime(
              endDateTime
            ).slice(0, 5)}`,
            slot_number: i + 1,
          });

          startDateTime = new Date(endDateTime); // Update startDateTime for the next iteration
        }

        const interviewData = {
          job_id: jobId,
          start_date: formatDate(interviewForm.deadline),
          start_time: formatTime(interviewForm.time),
          type: interviewForm.type,
          student_detail: studentDetails,
          job_round: {
            param: "&status_id=Nw==&round_id=MQ==",
            status_id: currStatusId,
            round_id: currRoundId,
            round_name: round,
            status_name: statusName,
            name: round,
          },
          description: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          ),
          // custom_email: interviewForm.interviewerEmailList,
          // interviewer: interviewForm.interviewerEmailList,
          title: interviewForm.title,
          time_slot: interviewForm.slotInterval.val,
          total_slots: studentData.length,
          location: null,
        };

        if (interviewForm.interviewerEmailList.length) {
          interviewData.custom_email = interviewForm.interviewerEmailList;
          interviewData.interviewer = interviewForm.interviewerEmailList;
        }

        if (interviewForm?.type === "OFFLINE") {
          interviewData.address = interviewForm?.address;
        }

        if (interviewForm?.interviewMeetingLink !== "generate") {
          interviewData.custom_link = interviewForm?.customLink;
        }

        setLoading(true);

        const res = await niyuktiAxiosPostReq(
          "/api/manage_jobs/admin/interview/",
          interviewData
        );
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message || "Interview Scheduled");
          setInterviewForm(initialState);
          setstepData((prevStepData) => ({
            ...prevStepData,
            [step]: {
              ...prevStepData[step],
              data: prevStepData[step].data.map((row) => {
                const isSelected = selectedIDs.some(
                  (selected) => selected.applicant_id === row.applicant_id
                );
                if (isSelected) {
                  return {
                    ...row,
                    interview_scheduled:
                      convertDateFormat(interviewData.start_date) +
                      "\n" +
                      convertTo12HourFormat(interviewData.start_time),
                  };
                }
                return row;
              }),
            },
          }));

          setLoading(false);
          handleClose();
          setStudentData([]);
        } else {
          FailureAlert("Error in scheduling interview");
          setLoading(false);
        }
      }
    } catch (err) {
      FailureAlert("Error: ", err);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose();
    setStudentData([]);
  };

  // const [jobProfile, setJobProfile] = useState(null);
  // const getJobProfileList = () => {
  //   fetchApi({
  //     url: `${NIYUKTI_API}job/company/job/list/?company_id=${btoa(
  //       companyId?.toString()
  //     )}`,
  //     setResponse: setJobProfileList,
  //   });
  // };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (s) => {
    setEditorState(s);
    setInterviewForm({
      ...interviewForm,
      description: draftToHtml(convertToRaw(s.getCurrentContent())),
    });
  };

  useEffect(() => {
    if (interviewForm.slotInterval) {
      let studentDetails = [];
      let startDateTime = new Date(interviewForm.deadline);
      startDateTime.setHours(interviewForm.time.getHours());
      startDateTime.setMinutes(interviewForm.time.getMinutes());
      startDateTime.setSeconds(interviewForm.time.getSeconds());
      for (let i = 0; i < studentData.length; i++) {
        let endDateTime = new Date(startDateTime);

        endDateTime.setMinutes(
          endDateTime.getMinutes() + Number(interviewForm.slotInterval.val)
        );

        studentDetails.push({
          id: studentData[i].user_id,
          applicant_id: studentData[i].applicant_id,
          user_id: studentData[i].user_id,
          name: studentData[i].applicant_name,
          expected_salary: studentData[i].expectedCtc,
          location: studentData[i].applicant_city,
          experience: studentData[i].work_exp,
          score: studentData[i].overall_applicant_score,
          preferred_location: null,
          email: studentData[i].applicant_email,
          score_data: null,
          resume: studentData[i].resume,
          // start_date: startDateTime,
          // end_date: endDateTime,
          start_date: `${formatDate(new Date(startDateTime))} ${formatTime(
            startDateTime
          ).slice(0, 5)}`,
          end_date: `${formatDate(new Date(endDateTime))} ${formatTime(
            endDateTime
          ).slice(0, 5)}`,
          slot_number: i + 1,
        });

        startDateTime = new Date(endDateTime);
      }

      let des = "";

      studentDetails?.forEach((item) => {
        des += `<li><b>${item?.name} ${item?.start_date} - ${item?.end_date}</b></li>\n`;
      });

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(des))
        )
      );
    }
  }, [
    interviewForm?.time,
    interviewForm?.deadline,
    interviewForm?.slotInterval,
  ]);

  return (
    <div>
      <Grid container spacing={4} style={{ width: "100%", margin: 0 }}>
        <Grid item md={12}>
          <Typography variant="h5">Schedule New Interview</Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            label="Select Job Profile"
            size="small"
            variant="outlined"
            value={interviewForm?.jobTitle}
            // disabled
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <BlockIcon />
                </InputAdornment>
              ),
              style: {
                cursor: "text",
                color: "inherit",
              },
            }}
          />
        </Grid>

        <Grid item md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              //   variant="inline"
              format="MM/dd/yyyy"
              size="small"
              inputVariant="outlined"
              id="date-picker-inline"
              label="Date picker inline"
              value={interviewForm?.deadline ? interviewForm?.deadline : null}
              onChange={(date) => {
                setInterviewForm({
                  ...interviewForm,
                  deadline: date,
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              size="small"
              id="time-picker"
              label="Time picker"
              value={interviewForm?.time ? interviewForm?.time : null}
              inputVariant="outlined"
              onChange={(time) => {
                setInterviewForm({
                  ...interviewForm,
                  time: time,
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item md={12}>
          <TextField
            label="Select Job Round"
            size="small"
            variant="outlined"
            value={interviewForm?.jobRound}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <BlockIcon />
                </InputAdornment>
              ),
              style: {
                cursor: "text",
                color: "inherit",
              },
            }}
          />
        </Grid>

        <Grid item md={12} style={{ display: "grid" }}>
          <TextField
            label="Interview Title"
            size="small"
            variant="outlined"
            value={interviewForm?.title}
            onChange={(e) => {
              setInterviewForm({
                ...interviewForm,
                title: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant="h6" style={{ marginBottom: 8 }}>
            Job Applicants
          </Typography>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={studentData}
              columns={JobApplicants}
              pageSize={5}
              rowsPerPageOptions={[5]}
              // checkboxSelection
              disableSelectionOnClick
            />
          </div>
        </Grid>

        <Grid item md={12}>
          <div /* className={classes.paper} */>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="email"
              label="Email Id(optional)"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        if (emailRegex.test(email)) {
                          var arr = [...interviewForm?.interviewerEmailList];
                          arr.push(email);
                          setInterviewForm({
                            ...interviewForm,
                            interviewerEmailList: arr,
                          });
                          setEmail("");
                        } else {
                          FailureAlert("Invalid Email");
                        }
                      }}
                    >
                      ADD
                    </Button>
                  </InputAdornment>
                ),
              }}
              //autoFocus
            />
            {/* <br /> */}
          </div>
        </Grid>
        {interviewForm?.interviewerEmailList?.length > 0 && (
          <Grid
            item
            xs={12}
            // style={{ marginTop: "15px", marginBottom: "10px" }}
          >
            <Paper
              style={{
                boxShadow: "none",
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                listStyle: "none",
                padding: 0,
                margin: 0,
              }}
              component="ul"
            >
              {interviewForm?.interviewerEmailList?.map((item, index) => (
                <li key={item}>
                  <Chip
                    style={{
                      borderRadius: 6,
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onDelete={() => deleteInterviewerEmail(index)}
                    deleteIcon={<CloseIcon />}
                    label={item}
                    //className={classes.chip}
                  />
                </li>
              ))}
            </Paper>
          </Grid>
        )}

        <Grid item md={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              style={{ flexDirection: "row", alignItems: "center" }}
              value={interviewForm?.type}
              onChange={(e) => {
                setInterviewForm({
                  ...interviewForm,
                  type: e.target.value,
                });
              }}
            >
              <Typography
                variant="body1"
                style={{ marginRight: 16, width: "120px" }}
              >
                Interview Type
              </Typography>
              <FormControlLabel
                style={{ margin: 0 }}
                control={<Radio size="small" color="primary" />}
                label="Online"
                value="ONLINE"
              />
              <FormControlLabel
                style={{ margin: 0 }}
                control={<Radio size="small" color="primary" />}
                label="Offline"
                value="OFFLINE"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {interviewForm?.type && interviewForm?.type === "OFFLINE" && (
          <>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                InputLabelProps={
                  {
                    //className: classes.floatingLabelFocusStyle,
                  }
                }
                fullWidth
                label="Add your venue"
                value={interviewForm.address}
                onChange={(event) => {
                  setInterviewForm({
                    ...interviewForm,
                    address: event.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}

        {interviewForm?.type && interviewForm?.type === "ONLINE" && (
          <Grid item md={12}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                style={{ flexDirection: "row", alignItems: "center" }}
                value={interviewForm?.interviewMeetingLink}
                onChange={(e) => {
                  setInterviewForm({
                    ...interviewForm,
                    interviewMeetingLink: e.target.value,
                  });
                }}
              >
                <Typography
                  variant="body1"
                  style={{ marginRight: 16, width: "200px" }}
                >
                  Interview Meeting Link
                </Typography>
                <FormControlLabel
                  control={<Radio size="small" color="primary" />}
                  label="Generate"
                  value="generate"
                />
                <FormControlLabel
                  control={<Radio size="small" color="primary" />}
                  label="Custom"
                  value="custom"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {interviewForm?.type === "ONLINE" &&
          interviewForm?.interviewMeetingLink === "custom" && (
            <Grid item md={12} /* className={classes.grid} */>
              <TextField
                variant="outlined"
                size="small"
                InputLabelProps={
                  {
                    //className: classes.floatingLabelFocusStyle,
                  }
                }
                fullWidth
                label="Add Meet Link"
                value={interviewForm?.custom_link}
                onChange={(event) => {
                  setInterviewForm({
                    ...interviewForm,
                    customLink: event.target.value,
                  });
                }}
              />
            </Grid>
          )}
        {interviewForm?.type &&
          interviewForm?.type === "ONLINE" &&
          interviewForm?.interviewMeetingLink === "generate" && (
            <Grid item md={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  style={{ flexDirection: "row", alignItems: "center" }}
                  value={interviewForm?.platform}
                  onChange={(e) => {
                    setInterviewForm({
                      ...interviewForm,
                      platform: e.target.value,
                    });
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ marginRight: 16, width: "200px" }}
                  >
                    Select Platform
                  </Typography>
                  {/*   <FormControlLabel
                    control={<Radio size="small" />}
                    label="Zoom"
                    value="ZOOM"
                  />
                  <FormControlLabel
                    control={<Radio size="small" />}
                    label="Microsoft Team"
                    value="TEAMS"
                  /> */}
                  <FormControlLabel
                    control={<Radio size="small" color="primary" />}
                    label="Google Meet"
                    value="MEET"
                  />
                </RadioGroup>
              </FormControl>
              <Box style={{ marginTop: 8 }}>
                <Typography
                  style={{
                    color: "#6C757D",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                >
                  Interview Meeting Link will be sent on your Email and it will
                  be once the Inview is Scheduled
                </Typography>
              </Box>
            </Grid>
          )}

        <Grid item md={4} style={{ display: "grid" }}>
          <Autocomplete
            id="combo-box-demo"
            popupIcon={<AccessTimeIcon />}
            options={roundtime}
            getOptionLabel={(roundtime) => roundtime.label}
            value={interviewForm?.slotInterval}
            onChange={(e, item) => {
              if (item)
                setInterviewForm({ ...interviewForm, slotInterval: item });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Interview Slot Duration"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item md={12} style={{ display: "grid" }}>
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            editorStyle={{
              border: "1px solid #C0C0C0",
              minHeight: 100,
            }}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </Grid>

        <Grid item md={3} style={{ display: "grid", marginTop: 80 }}>
          <Button
            disabled={loading}
            variant="contained"
            style={{
              boxShadow: "none",
              cursor: "pointer",
              height: 45,
              backgroundColor: "#2563EB",
            }}
            onClick={handleSubmit}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {loading ? (
                <CircularProgress style={{ color: "black" }} size={25} />
              ) : (
                "Schedule Interview"
              )}
            </Typography>
          </Button>
        </Grid>
        <Grid item md={3} style={{ display: "grid", marginTop: 80 }}>
          <Button
            disabled={loading}
            variant="outlined"
            style={{
              boxShadow: "none",
              cursor: "pointer",
              height: 45,
              borderColor: "#2563EB",
            }}
            onClick={handleCancel}
          >
            <Typography variant="body2" style={{ color: "#2563EB" }}>
              {" "}
              Cancel{" "}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default InterviewForm;

const data = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
];

const roundtime = [
  { label: "5 min", val: 5 },
  { label: "10 min", val: 10 },
  { label: "15 min", val: 15 },
  { label: "20 min", val: 20 },
  { label: "25 min", val: 25 },
  { label: "30 min", val: 30 },
  { label: "35 min", val: 35 },
  { label: "40 min", val: 40 },
  { label: "45 min", val: 45 },
  { label: "50 min", val: 50 },
  { label: "55 min", val: 55 },
  { label: "60 min", val: 60 },
];
