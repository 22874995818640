import {
  Button,
  Checkbox,
  FormControl, FormControlLabel,
  FormGroup, Grid, IconButton,
  InputBase,
  makeStyles,
  Menu,
  MenuItem, Paper,
  Tooltip, Typography,
  withStyles
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import React, { useEffect, useState } from "react";
import { praveshAxiosGetReq } from "../../../util/apiCall";
import { FailureAlert } from "../../../util/Notifications";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const num = [
  {
    value: "FLAT",
    label: "₹",
  },
  {
    value: "PERCENT",
    label: "%",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    marginLeft: 10,

    height: "32px",
    position: "relative",
    border: "1px solid #6C757D",
    // padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "150px",
    minWidth: "150px",
  },
  input: {
    marginLeft: 50,
    flex: 1,
    fontSize: "12px",
  },
  iconButton: {
    borderRight: "1px solid #6C757D",
    padding: 5,
    border: "none",
    height: "100%",
    backgroundColor: "#D9D9D9",
    position: "absolute",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
  divider: {
    height: 28,
    margin: 4,
  },

  seacrhroot: {
    boxShadow: "none",
    border: "1px solid #6C757D",
    height: "40px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  searchinput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIcon: {
    padding: 10,
  },
}));

function VendorField({data, vendorData, setVendorData}) {
  const classes = useStyles();

  const [defaultPayment, setDefaultPayment] = useState();
  const [defaultPaymentMode, setDefaultPaymentMode] = useState("PERCENT");
  const [defaultPaymentAdded, setDefaultPaymentAdded] = useState(false); 
  const [defaultPaymentAmount, setDefaultPaymentAmount] = useState(0); 

  const handleChange = (event) => {
    setDefaultPaymentMode(event.target.value);
    setDefaultPayment();
    setDefaultPaymentAdded(false);
    setVendorData([]);
  };


  useEffect(() => {
    if(defaultPaymentAdded){
    praveshAxiosGetReq(`/api/admin/vendor_lists/`).then(res => {
      if(res?.data?.success){
        let tempVendor = [...res?.data?.data];
        res?.data?.data?.forEach((item, index) => {
          tempVendor[index]['paymentMode'] = defaultPaymentMode;
          tempVendor[index]['payment'] = defaultPayment;
          tempVendor[index]['isChecked'] = false;
        })
        setVendorData(tempVendor);
      }else{
        FailureAlert(res?.data?.error || "Error in fetching Data")
      }
    }).catch(err => {
      FailureAlert("Error in fetching Data")
    })

  }
  }, [defaultPaymentAdded])


  const calculatePaymentAmount = (percent) => {
    if(!percent){
      return "percentage amount";
    }
    if(data?.job_type_id === 7){
       return `₹ ${(data?.ctc_min + data?.ctc_max) * percent /200}`;
    } else {
      return `₹ ${data?.stipend * percent /100}`;
    }
  }

  const getCtcText = () => {
    if(data?.job_type_id === 7){
      return `₹ ${data?.ctc_min} - ${data?.ctc_max}`;
   } else {
     return `₹ ${data?.stipend}`;
   }}

  useEffect(() => {
    if(defaultPaymentMode === "PERCENT"){
      setDefaultPaymentAmount(calculatePaymentAmount(defaultPayment));
    }
  }, [defaultPayment])

  const handleChangeVendorType = (e) => {
    const { name, checked } = e.target;
    if (name === "all") {
      let tempVendor = vendorData.map((vendor) => {
        return { ...vendor, isChecked: checked };
      });
      setVendorData(tempVendor);
    } else {
      let tempVendor = vendorData.map((vendor) => {
        const res = (vendor?.vendor_id == name) ? checked : vendor?.isChecked;
        return { ...vendor, isChecked: res};
      });
      setVendorData(tempVendor);
    }
  };

  // const filterVendorDetails = (event) => {
  //   if(event)
  // }

  return (
    <div style={{ marginTop: "10px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography style={{ fontSize: "12px" }}>Default Payment :</Typography>

        <Paper component="form" className={classes.root}>
          <select
            value={defaultPaymentMode}
            onChange={handleChange}
            className={classes.iconButton}
          >
            {num.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <InputBase
            className={classes.input}
            placeholder="5000"
            inputProps={{ "aria-label": "rupees", min: 0 }}
            type="number"
            onChange={(event) => {
                setDefaultPayment(event.target.value);
            }}
          />

          {defaultPaymentMode === "PERCENT" ? (
            <Tooltip title={defaultPaymentAmount}>
              <IconButton style={{ padding: 0, marginRight: 5 }}>
                <InfoIcon style={{ fontSize: "14px", color: "#000" }} />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Paper>

        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: 10,
            height: "32px",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
              if(!defaultPayment){
                FailureAlert("Please enter valid input");
                return;
              } else {
                if(defaultPaymentMode === "PERCENT" && defaultPayment > 100){
                  FailureAlert("Please enter percentage less than 100"); 
                  return;
                }
              setDefaultPaymentAdded(true);
              }
          }}
        >
          <p style={{ fontSize: "12px", color: "#fff", margin: "0px" }}>
            Apply
          </p>
        </Button>
        {<div>
            {getCtcText()}
        </div>}
      </div>
      { defaultPaymentAdded && 
      <div style={{ marginTop: "24px" }}>
        <Grid container spacing={2}>
          {/* <Grid item md={10}>
            <Paper component="form" className={classes.seacrhroot}>
              <IconButton
                className={classes.searchIcon}
                aria-label="directions"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.searchinput}
                placeholder="Search"
                inputProps={{ "aria-label": "link" }}
                // onChange={(event) => {
                //   filterVendorDetails();
                // }}
              />
            </Paper>
          </Grid> */}
          {/* <Grid item md={2}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="outlined"
              onClick={handleClick}
              startIcon={<SortIcon />}
              style={{
                borderColor: "#6C757D",
                height: "40px",
                width: "100%",
                backgroundColor: "#fff",
                boxShadow: "none",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "12px",
                  color: "#6C757D",
                }}
              >
                {" "}
                Sort{" "}
              </p>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleClose}>
                <ListItemText primary="Most active" />
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClose}>
                <ListItemText primary="Most uploaded" />
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClose}>
                <ListItemText primary="latest active" />
              </StyledMenuItem>
            </StyledMenu>
          </Grid> */}

          <Grid item md={12}>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      name="all"
                      checked={
                        vendorData.filter((item) => item?.isChecked !== true)
                          .length < 1
                      }
                      onChange={handleChangeVendorType}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px" }}>
                      Select all({vendorData?.length})
                    </Typography>
                  }
                />

                <div style={{ height: "300px", overflowY: "scroll" }}>
                  {vendorData.map((item, index) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControlLabel
                          style={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              color="primary"
                              size="small"
                              name={item.vendor_id}
                              checked={item?.isChecked || false}
                              onChange={handleChangeVendorType}
                            />
                          }
                          label={
                            <Typography style={{ fontSize: "14px" }}>
                              {item.vendor_name}
                            </Typography>
                          }
                        />

                        <div>
                          <Typography
                            variant="body2"
                            noWrap
                            style={{
                              fontColor: "#222222",
                              width: "200px",
                              textAlign: "center",
                            }}
                          >
                            {item?.email}
                          </Typography>
                        </div>

                        <div>
                          <Paper component="form" className={classes.root}>
                            <select
                              value={item?.paymentMode}
                              onChange={(event) => {
                                let tempData = [...vendorData];
                                tempData[index]['paymentMode'] = event.target.value;
                                tempData[index]['payment'] = 0;
                                setVendorData(tempData);
                              }}
                              disabled={!item?.isChecked}
                              className={classes.iconButton}
                            >
                              {num.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <InputBase
                              className={classes.input}
                              placeholder="5000"
                              inputProps={{ "aria-label": "rupees",  min: 0 }}
                              value={item?.payment}
                              disabled={!item?.isChecked}
                              type="number"
                              onChange={(event) => {
                                  let tempData = [...vendorData];
                                  tempData[index]['payment'] = event.target.value;
                                  setVendorData(tempData);
                              }}
                            />

                            {item?.paymentMode === "PERCENT" ? (
                              <Tooltip title={calculatePaymentAmount(item?.payment)}>
                                <IconButton
                                  style={{ padding: 0, marginRight: 5 }}
                                >
                                  <InfoIcon
                                    style={{ fontSize: "14px", color: "#000" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </Paper>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
       }
    </div>
                           
  );
}

export default VendorField;

