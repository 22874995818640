import {
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import DegreeSpecializationSelect from "../Branch_Courses/DegreeSpecializationSelect";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: "30px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "30px",
    border: "none",
  },
  button: {
    borderRadius: "30px",
    // height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textbutton: {
    padding: "0px",
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    //backgroundColor: pSBC(0.9, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.12)",
    // border: `solid 0.5px ${theme.palette.primary.main}`,
    border: "none",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  btn: {
    borderRadius: "30px",
    boxShadow: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginTop: "30px",
    marginBottom: "30px",
  },
}));

const AddCollegeCourses = ({
  dataSelected,
  setDataSelected,
  specializationInDegreeId,
  setSpecializationInDgegreeId,
  coursesArray,
  pushArray,
  loading,
  setPushArray,
  dataSelectedNewFormat,
  setDataSelectedNewFormat,
  handleDeleteChip,
  idCollegeCourses = null,
}) => {
  const classes = useStyles();
  return (
    <>
      <div style={{ padding: "30px", minHeight: "80vh" }}>
        <h3>Courses</h3>
        <br></br>
        {!idCollegeCourses && (
          <DegreeSpecializationSelect
            data={dataSelected}
            setData={setDataSelected}
            specialization={specializationInDegreeId}
            setSpecialization={setSpecializationInDgegreeId}
            coursesArray={coursesArray}
            pushArray={pushArray}
            setPushArray={setPushArray}
            errorMessage={null}
            loading={loading}
            dataSelectedNewFormat={dataSelectedNewFormat}
            setDataSelectedNewFormat={setDataSelectedNewFormat}
          />
        )}

        <br></br>
        {dataSelectedNewFormat?.map((item, index) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" color="secondary">
                {item.education_name}
              </Typography>
            </Grid>
            {item.degrees.map((item2, index2) => (
              <Grid item xs={6} spacing={3}>
                <Paper className={classes.paper}>
                  <Typography variant="h6">
                    {item2?.degree_duration
                      ? item2.degree_name + " (" + item2?.degree_duration + ")"
                      : item2.degree_name}
                  </Typography>
                  <br />
                  {!item.show_more
                    ? item2.specialization.slice(0, 6).map((item3) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => {
                            handleDeleteChip(
                              item3.specialization_id,
                              item2.specialization,
                              item2.degree_id,
                              item.education_id
                            );
                          }}
                          deleteIcon={<CloseIcon />}
                          label={item3.specialization_name}
                          className={classes.chip}
                          style={{
                            maxWidth: 200,
                            marginBottom: 20,
                            height: 30,
                          }}
                        />
                      ))
                    : item2.specialization.map((item3) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => {
                            handleDeleteChip(
                              item3.specialization_id,
                              item2.specialization,
                              item2.degree_id,
                              item.education_id
                            );
                          }}
                          deleteIcon={<CloseIcon />}
                          label={item3.specialization_name}
                          className={classes.chip}
                          style={{
                            maxWidth: 200,
                            marginBottom: 20,
                            height: 30,
                          }}
                        />
                      ))}
                  <br />
                  {item2.specialization?.length > 7 && (
                    <Button
                      color="primary"
                      className={classes.textbutton}
                      onClick={() => {
                        var arr = [...dataSelectedNewFormat];
                        arr[index].show_more = !arr[index].show_more;
                        setDataSelectedNewFormat(arr);
                      }}
                    >
                      {item.show_more
                        ? "Hide Complete List"
                        : "Show Complete List"}
                    </Button>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
        ))}
      </div>
    </>
  );
};

export default AddCollegeCourses;
