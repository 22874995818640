import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup, Grid, Radio, RadioGroup
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
  },
}));

export default function JobType({jobTypePreference, ppoPreference, wfhPreference, savePreferences}) {
  const classes = useStyles();
  const [tempJobType, setTempJobType] = useState({})

  useEffect(() => {
    setTempJobType({
      jobType: JSON.stringify(jobTypePreference),
      ppo: ppoPreference,
      wfh: wfhPreference
    })
  }, [jobTypePreference, ppoPreference, wfhPreference])

  useEffect(() => {
      if(tempJobType?.jobType === '1')
        setTempJobType({...tempJobType, ppo: false});
  }, [tempJobType?.jobType])

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Job Type</Typography>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "grid" }}>
          <RadioGroup
                      className={classes.check}
                      aria-label="gender"
                      value={tempJobType?.jobType ? tempJobType?.jobType : '0'}
                      onChange={(e) => {
                        setTempJobType({...tempJobType, jobType: e.target.value});
                      }}
                    >
            <FormGroup row style={{ justifyContent: "space-between" }}>
              <FormControlLabel
                color="primary"
                value="7"
                control={<Radio name="jobType" />}
                label="Full-time"
              />
              <FormControlLabel
              value="1"
                control={<Radio name="jobType" />}
                label="Internship"
              />
              <FormControlLabel
                value="0"
                label="Both"
                control={<Radio name="jobType" />}
              />
            </FormGroup>
          </RadioGroup>
          </Grid>
          <Grid item xs={12} style={{ display: "grid" }}>
            <FormGroup row style={{ justifyContent: "space-between" }}>
              <FormControlLabel
                color="primary"
                
                control={<Checkbox name="checkedC" checked={tempJobType.wfh ? true : false}/>}
                
                label="Work From Home"
                onChange= {(e) => {
                  setTempJobType({...tempJobType, wfh: !tempJobType.wfh})
                }}
              />
              {(tempJobType?.jobType == 1 || tempJobType?.jobType == 0) &&
              <FormControlLabel
                color="primary"
                control={<Checkbox name="checkedD" checked={tempJobType.ppo ? true : false}/>}
                label="PPO"
                onChange= {(e) => {
                  setTempJobType({...tempJobType, ppo: !tempJobType.ppo})
                }}
              />
                    }
            </FormGroup>
          </Grid>
        </Grid>

        
      </div>

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" color="primary" onClick = {() => {savePreferences("jobtype", tempJobType)}}>
          Save
        </Button>
      </div>
    </div>
  );
}
