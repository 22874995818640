import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { FailureAlert } from "../../../util/Notifications";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: ".5px solid #B0B6BA",
    boxShadow: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function StudentSearch({email, setEmail}) {
 
  const classes = useStyles();
  const [localEmail, setLocalEmail] = useState(email);
  const queryParams = QueryString.parse(window.location.search);

  useEffect(()=>{
    if(queryParams?.studentEmail)
    {setLocalEmail(queryParams?.studentEmail)
    setEmail(queryParams?.studentEmail)}
  },[])

  const validateEmail = (enmail) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailRegex.test(enmail)) {
        return true;
     }
     else return false;
    };

  const onPressingEnter = (e) => {
    if (e.key === "Enter") {   
      e.preventDefault();
      if(validateEmail(localEmail)){
      setEmail(localEmail);
      // setLocalEmail(e.target.value);
      }
      else FailureAlert('Enter valid email')
    }
  };


  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        type={"email"}
        required
        placeholder="Search Student by Email"
        inputProps={{ "aria-label": "Search Students" }}
        value={localEmail}
        onChange={(event) => {
            setLocalEmail(event.target.value);
        }}
        onKeyPress={onPressingEnter}
      />
      <Divider className={classes.divider} orientation="vertical" />

      <IconButton
        // type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick = {(e) => {
            e.preventDefault();
            if(validateEmail(localEmail))
            setEmail(localEmail);
            else FailureAlert('Enter valid email')
        }
      }
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
