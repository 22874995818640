import {
  Button, Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import gif from '../../assets/loopingcircles-3.gif';
import upload from "../../assets/svg/Upload.svg";
import { gifStyling } from '../../constants';
import { praveshAxiosGetReq } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import UploadBox from "./Components/BulkUploadResume/UploadBox";
import UploadResumes from "./Components/Dialog/UploadResumes";

function BulkResume({setShowUploadDialog}) {

  const [open, setOpen] = React.useState(false);
  const [loading , setLoading] = useState(false);
   const [openProgressBar, setOpenProgressBar] = useState(false);
  const [progress,setProgress] = useState(0);
  const [loadedInfo,setLoadedInfo] = useState({loaded:null, total:null});

  const history = useHistory();

   useEffect(() => {

    // setLoading(true);
    getTagList();

  },[])

  const getTagList = async() => {

    setLoading(true);

    const endPoint = `/api/admin/bulk/get/tags/`;

    try {
        const res = await praveshAxiosGetReq(endPoint);

        if (res?.data?.success){  
          
          setLoading(false);
          if (res?.data?.data.length >0){  

            setShowUploadDialog(false);
            // history.push('/admin/all-resume');
          }else{
            setShowUploadDialog(true);

          };                   
        }
        else{
          FailureAlert("Some Error Occurred!");
          setLoading(false);

        }
    }
    catch (error){
      throw error;

    }

  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const [openDelete, setOpenDelete] = React.useState(false);

  // const handleClickOpenDelete = () => {
  //   setOpenDelete(true);
  // };

  // const handleCloseDelete = () => {
  //   setOpenDelete(false);
  // };

  // const [show, setShow] = useState();

  return (
     loading ? (
      <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />

    ) : (
    <> 
     
      <div style={{ padding: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" style={{ marginBottom: "20px" }}>
            Bulk Resume Uploader
          </Typography>

          {/* <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Upload Resumes
            </Button>
          </div> */}
        </div>

        <div
          style={{
            //position: "absolute",
            // margin: "auto",
            // top: 0,
            // bottom: 0,
            // left: 0,
            // right: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {" "}
          <img
            src={upload}
            height="400px"
            // style={{
            //   position: "absolute",
            //   margin: "auto",
            //   top: 0,
            //   bottom: 0,
            //   left: 0,
            //   right: 0,
            // }}
          />
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Upload Resumes
          </Button>
        </div>
      </div>
      <UploadResumes
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        setProgress={setProgress}
        setOpenProgressBar={setOpenProgressBar}
        setLoadedInfo={setLoadedInfo}
        loadedInfo={loadedInfo}
        fromBulkResume={true}
        setShowUploadDialog={setShowUploadDialog}
      />
      <UploadBox
       open={openProgressBar}
       progress={progress}
       loadedInfo={loadedInfo}
      />
     
            
    </>) 
  );
}

export default BulkResume;
