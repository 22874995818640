import { Box, Paper } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling, requestPraveshOptions } from "../../constants";
import "../../css/Miscellaneous.css";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
const moment = require("moment");

const token = localStorage.getItem("token");
function UserDetails() {
  const [user, setUser] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userStatus, setUserStatus] = useState(null);
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");

  const [phn, setPhn] = useState(null);
  const [dateOfJoin, setDateOfJoin] = useState("");
  const [createDate, setCreateDate] = useState(null);
  const [active, setActive] = useState("");
  const [lastLogin, setLastLogin] = useState(null);
  const [company, setCompany] = useState(null);

  const [rows, setRows] = useState(null);
  const [notificationRows, setNotificationRows] = useState(null);

  const userTypeTable = [
    {
      field: "id",
      headerName: "#ID",
      width: 150,
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 150,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 200,
    },
    {
      field: "company",
      headerName: "Company/Collage Name",
      width: 300,
    },
    {
      field: "activeStatus",
      headerName: "Active Status",
      width: 200,
    },
  ];

  const findUser = (e) => {
    e.preventDefault();
    if (!user) {
      FailureAlert("Please enter an email to continue...");
      return;
    }
    setLoading(true);
    apiCall(
      `/api/admin/operations/user/detail/?email=${user}`,
      requestPraveshOptions
    ).then((json) => {
      let detail = json.data;
      if (json.success) {
        if (Object?.keys(detail)?.length === 0) {
          FailureAlert("user not found");
          setLoading(false);
          return;
        }
        let userCurrentStatus =
          detail.user_status === true ? "ACTIVE" : "INACTIVE";
        setUserStatus(userCurrentStatus);
        setUserName(detail?.name);
        setUserType(detail?.user_type);
        setUserId(detail?.user_id);

        let userTypeRow = detail?.user_type_list?.map((item, index) => ({
          id: item?.entity_id,
          userType: item?.user_type + "/" + item?.sub_user_type,
          createdDate: moment(item?.create_date).format("DD-MMMM-YYYY"),
          company: item?.entity_name.length === 0 ? "N/A" : item?.entity_name,
          activeStatus: item?.is_active ? "Active" : "Inactive",
        }));
        setRows(userTypeRow);
        setNotificationRows(detail?.do_not_disturb);
        setDateOfJoin(moment(detail?.date_joined).format("DD-MMMM-YYYY"));
        setCreateDate(
          moment(detail?.user_type_list[0].create_date).format("DD-MMMM-YYYY")
        );
        setActive(detail?.user_type_list[0]?.is_active ? "Active" : "Inactive");
        setLastLogin(
          detail?.last_login
            ? moment(detail?.last_login).format("DD-MMMM-YYYY")
            : "N/A"
        );
        setCompany(detail?.company);
        setPhn(detail?.phone);
      } else {
        FailureAlert("detail.error");
      }
      setLoading(false);
    });
  };

  const renderUserData = () => {
    if (!userId) {
      FailureAlert("User Not Found");
      return;
    } else {
      return (
        <div style={{ margin: "0px 20px 0px 20px" }}>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              padding: "0px 0px 20px 0px",
            }}
          >
            <h4>User Details</h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 0px 20px 0px",
            }}
          >
            <h5>User ID:{" " + userId}</h5>
            <h5>Name: {" " + userName}</h5>
            <h5>Phone No:{phn ?? "N/A"}</h5>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 0px 20px 0px",
            }}
          >
            <h5>Joining Date:{" " + dateOfJoin}</h5>
            <h5>Last Login: {lastLogin}</h5>
          </div>
        </div>
      );
    }
  };

  const enterhandle = (event) => {
    if (event.key === "Enter") {
      user && findUser(event);
    }
  };

  const enterr = (event) => {
    if (event.key === "Enter") {
      setUser("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      const requestOptionsPost = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          user_id: userId,
          password: password,
        }),
      };

      apiCall(`/api/admin/operations/update/password/`, requestOptionsPost)
        .then((res) => {
          if (res.success) {
            SuccessAlert(res.data.message);
            setCount(count + 1);
          } else {
            FailureAlert(res.error);
          }
        })
        .then(setUser(null));
    } else {
      FailureAlert("Confirm Password doesn't match initial password...");
    }
  };

  return (
    <div className="change__password">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Form style={{ width: "75%" }}>
          <Form.Group controlId="userEmailId" style={{ margin: "6px 10px" }}>
            <Form.Control
              name="userEmailId"
              type="email"
              placeholder="Enter User's Email Id*"
              required
              onChange={(e) => setUser(e.target.value)}
              onKeyDown={enterhandle}
              onKeyUp={enterr}
            />
          </Form.Group>
        </Form>
        <Button
          style={{ width: "20%", marginRight: "20px" }}
          variant="primary"
          onClick={findUser}
        >
          Search User
        </Button>
      </div>

      {!loading ? (
        userId ? (
          <>
            {renderUserData()}

            {/* <Box
              component={Paper}
              style={{
                padding: "10px 10px 45px 10px",
                height: 200,
                width: "100%",
                backgroundColor: "white",
                borderRadius: "5px",
              }}
            >
              <h4>Notification </h4>
              
              <TableContainer style={{border:"1px solid #C1C0C0",
            borderRadius:"5px"}} component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">WhatsAap</TableCell>
                      <TableCell align="center">SMS</TableCell>
                      <TableCell align="center">Notification</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">✔</TableCell>
                      <TableCell align="center">&#128473;</TableCell>
                      <TableCell align="center">&#128473;</TableCell>
                      <TableCell align="center">✔</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box> */}
            <Box
              component={Paper}
              style={{
                padding: "10px 10px 45px 10px",
                height: 350,
                width: "100%",
                backgroundColor: "white",
                borderRadius: "5px",
                marginTop: "15px",
              }}
            >
              <h4>User Type</h4>
              <DataGrid rows={rows} columns={userTypeTable} pageSize={5} />
            </Box>

            {/* Change Password Previous Code */}
            <Form
              className="change__password__form"
              onSubmit={(e) => handleSubmit(e)}
              style={{width:"100%",
              display: "flex",
              justifyContent: "left",
              padding: "0px 0px 20px 0px",
              flexDirection: "column",
              margin: "20px 0px",
            }}
            >
              <h4>Change Password</h4>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  required
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </Form.Group>
              <Button variant="primary" type="submit" style={{width:"10%"}}>
                {" "}
                Set Password{" "}
              </Button>
            </Form>
          </>
        ) : null
      ) : (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      )}
    </div>
  );
}

export default UserDetails;
