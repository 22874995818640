import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
// import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function EducationSkills() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [skillName, setSkillName] = useState('');
    const [type, setType] = useState(null);
    const [skills, setSkills] = useState([]);
    const [skillFilters, setSkillFilters] = useState({});  // For Filter Purpose
    // const [patchType, setPatchType] = useState(null);
    const [loading, setLoading] = useState(false);

    // Skill Type Finding API
    useEffect(() => {
        apiCall('/api/admin/skill_type/', requestPraveshOptions)
        .then((res)=> {
            if(res.success) {
                setSkills(res.data)
                let obj = {}
                res.data.forEach((skill) => { obj[skill.id] = skill.name })
                setSkillFilters(obj)
            }
            else FailureAlert(res.error)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/skill/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        skill_id:     parseInt(json.data[i].skill_id),
                        skill_name:   json.data[i].skill_name,
                        skill_type:   json.data[i].skill_type,
                        type:         parseInt(json.data[i].type),
                        user_created: json.data[i].user_created ? "Yes" : "No"
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else { FailureAlert(json.error) }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const columns = [
        { title: "Skill Name",    field: "skill_name",    headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Skill Type",    field: "type",    headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,      lookup: skillFilters,   filterPlaceholder: 'Select Skill Type', },
        { title: "User Created",  field: "user_created",  headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,      filterPlaceholder: 'Yes/No',         lookup: { 'Yes':'Yes', 'No':'No' },
            //   editComponent: props => (
            //     <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
            //         <MenuItem value="Yes">Yes</MenuItem>
            //         <MenuItem value="No">No</MenuItem>
            //     </Select>
            //   )
        }
    ];
  
    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ 
            name: skillName,
            type: type,
            user_created: false,
        })
    };

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData, oldData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        })
    }

    const updateRow = (newData, oldData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                ...(oldData.skill_name!==newData.skill_name && {name: newData.skill_name}),
                ...(oldData.type!==newData.type && {type: newData.type}),
                ...(oldData.user_created!==newData.user_created && {user_created: newData.user_created==="Yes" ? true : false,}),
                // user_created: newData.user_created==="Yes" ? true : false,
            })
        };
        apiCall(`/api/admin/skill/${newData.skill_id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) SuccessAlert(res.data.message);
            else FailureAlert(res.error);
            setCount(count+1);
        })
    }

    const addNewSkill = (e) => {
        e.preventDefault();
        apiCall('/api/admin/skill/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else FailureAlert(res.error);
        })
        e.target.reset();
    }


    return (
        !loading
        ?
        <div className="education__skils">
            <div className="education__skils__form">
                <h4>Add New Skill</h4>
                <Form onSubmit={e=>addNewSkill(e)}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="skillName">
                            <Form.Label>Skill Name*</Form.Label>
                            <Form.Control 
                                name="skillName" 
                                type="text" 
                                placeholder="Enter Skill Name"
                                onChange={e=> {setSkillName(e.target.value)}}
                                required 
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="skillType">
                            <Form.Label>Skill Type</Form.Label>
                            <Form.Control 
                                as="select"
                                onChange={e=> {setType(JSON.parse(e.target.value).id)} }
                            >
                                <option value={JSON.stringify({"id": null})}>--select--</option>
                            {
                                skills.map((skill, key) => {
                                return (
                                    <option key={key} value={JSON.stringify(skill)} >
                                        {skill.name}
                                    </option>
                                )})
                            }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Button type="submit" variant="info">Add Skill</Button>
                    
                </Form>    
            </div>
            <TableWithExport 
                title = "Education Skills"
                data = {data}
                columns = {columns}
                fName = "Selected Skills"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default EducationSkills;
