import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Autocomplete } from "@material-ui/lab";
import { DateSelector } from "../../common/DateSelector";
import { DASHBOARD_VIEWS } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function FilterDialog({
  open,
  handleClickOpen,
  handleClose,
  AutocompleteLabel,
  initialView,
  setCurrentView,
  value,
  filter,
  setFilter,
  recruitersList,
  teamLeadsList,
  currentView,
  setSkeletonInitialLoader,
  setSkeletonGraphLoader,
}) {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [dateRange, setDateRange] = useState(filter?.dateRange);
  const [selectedOption, setSelectedOption] = useState(filter?.user);
  useEffect(() => console.log("dateRange", dateRange), [dateRange]);

  const handleApplyFilter = () => {
    if (initialView === DASHBOARD_VIEWS.TEAM_LEAD) {
      if (selectedOption) setCurrentView(DASHBOARD_VIEWS.RECRUITER);
      else setCurrentView(initialView);
      setFilter((prev) => ({
        ...prev,
        dateRange: dateRange,
        user: selectedOption,
      }));
      setSkeletonInitialLoader(true);
      setSkeletonGraphLoader(true);
    }
    if (initialView === DASHBOARD_VIEWS.MANAGER) {
      if (value === 0) {
        if (selectedOption) setCurrentView(DASHBOARD_VIEWS.TEAM_LEAD);
        else setCurrentView(initialView);
        setFilter((prev) => ({
          ...prev,
          dateRange: dateRange,
          user: selectedOption,
        }));
        setSkeletonInitialLoader(true);
        setSkeletonGraphLoader(true);
      } else if (value === 1) {
        if (selectedOption) setCurrentView(DASHBOARD_VIEWS.RECRUITER);
        setFilter((prev) => ({
          ...prev,
          dateRange: dateRange,
          user: selectedOption,
        }));
        setSkeletonInitialLoader(true);
        setSkeletonGraphLoader(true);
      }
    }
    handleClose();
  };

  console.log("first", currentView, value);
  const getLabel = () => {
    if (currentView === DASHBOARD_VIEWS.TEAM_LEAD) {
      return "Select Recruiter";
    } else if (currentView === DASHBOARD_VIEWS.MANAGER) {
      return value === 0 ? "Select Team Lead" : "Select Recruiter";
    }
  };

  const getOptions = () => {
    if (currentView === DASHBOARD_VIEWS.TEAM_LEAD) {
      return recruitersList;
    } else if (currentView === DASHBOARD_VIEWS.MANAGER) {
      return value === 0 ? teamLeadsList : recruitersList;
    } else {
      return recruitersList;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div>
          <Grid container spacing={4} style={{ width: "100%", margin: 0 }}>
            <Grid item md={12}>
              <Typography
                variant="h6"
                style={{ textAlign: "left", fontWeight: "600" }}
              >
                Advance Filter
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                id="combo-box-demo"
                value={selectedOption}
                options={getOptions()}
                getOptionLabel={(option) => option.name}
                fullWidth
                onChange={(e, newValue) => setSelectedOption(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getLabel()}
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={currentView === DASHBOARD_VIEWS.RECRUITER}
              />
            </Grid>
            <Grid item md={12}>
              <DateSelector
                dateRange={[]}
                setAdvanceFilterDateValue={setDateRange}
                advanceFilterDateValue={dateRange}
                isAdvanceFilter={true}
                justifyContent="space-between"
                removeDateFilter={() => {
                  setDateRange([null, null]);
                }}
                showRemove={false}
                label={"Date Range"}
              />
            </Grid>
            <Grid item md={12}>
              <Button
                variant="contained"
                color="inherit"
                fullWidth
                style={{ backgroundColor: "#2563EB", boxShadow: "none" }}
                onClick={handleApplyFilter}
              >
                <span style={{ color: "#FFF" }}>Apply filter</span>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

const data = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
];
