import {
  Button,
  Checkbox, FormControl,
  FormControlLabel, FormGroup, Grid,
  TextField, Typography
} from "@material-ui/core";
import Camera from "@material-ui/icons/CameraAlt";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import {
  niyuktiAxiosGetReq,
  niyuktiAxiosPatchReq
} from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import CustomButtonCircularProgress from "../loader";
import { copyToClipboard } from '../../util/copyToClipboard';

function AiCallingBot() {
  const { job_id, job_title } = useParams();

  const [pageLoading, setPageLoading] = useState(true);

  const [companyDetails, setCompanyDetails] = useState({});

  const [aiBotDetails, setAiBotDetails] = useState({
    bot_id: "",
    status: "",
    campaign_id: "",
    assistant_id: "",
    disposition_list: [],
    webhook_data: ""
  });

  const [checkboxQuestionsList, setCheckboxQuestionsList] = useState([]);
  const [fitmentQuestionsList, setFitmentQuestionsList] = useState([]);
  const [botVoices, setBotVoices] = useState([]);
  const [botLanguages, setBotLanguages] = useState([]);

  const WEBHOOK_DATA = [{key: "experience_in_sector", type: "response_data_dropdown", order: 1, filter_name: "Sector Experience"},
  {key: "vehicle_availability", type: "yes_no", order: 2, filter_name: "Do you have vehicle"}]

  useEffect(() => {
    niyuktiAxiosGetReq(
      `/api/ai_job/company/admin_ai_call_detail/?job_id=${job_id}`
    )
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data?.data?.check_box_details)
            setCheckboxQuestionsList(res?.data?.data?.data?.check_box_details);
          if (res?.data?.data?.data?.company_info)
            setCompanyDetails(res?.data?.data?.data?.company_info);
          if (res?.data?.data?.data?.bot_details)
            setAiBotDetails(res?.data?.data?.data?.bot_details);
          if (res?.data?.data?.data?.fitment_questions)
            setFitmentQuestionsList(res?.data?.data?.data?.fitment_questions);
          if (res?.data?.data?.data?.bot_voices)
            setBotVoices(res?.data?.data?.data?.bot_voices);
          if (res?.data?.data?.data?.language_details)
            setBotLanguages(res?.data?.data?.data?.language_details);
        } else {
          FailureAlert(res?.data?.error);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        FailureAlert("Error in fetching bot data");
        setPageLoading(false);
      });
  }, []);

  const [loading, setLoading] = useState(false);

  const updateBotDetails = () => {
    if (!aiBotDetails.bot_id || !aiBotDetails.campaign_id || !aiBotDetails?.disposition_list || aiBotDetails?.webhook_data){
      FailureAlert("Please enter all required fields");
      return;
    }
    setLoading(true);
    const payload = {
      bot_id: aiBotDetails.bot_id,
      status: aiBotDetails.status,
      campaign_id: aiBotDetails.campaign_id,
      check_box_details: checkboxQuestionsList,
      assistant_id: aiBotDetails?.assistant_id,
      disposition_list: aiBotDetails?.disposition_list,
      webhook_data: aiBotDetails?.webhook_data
    };

    niyuktiAxiosPatchReq(
      `/api/ai_job/company/call_criteria/?job_id=${job_id}`,
      payload
    )
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message);
        } else {
          FailureAlert(res?.data?.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        FailureAlert("Error in creating vendor partner");
      });
  };

  const handleCheckboxChange = (fieldName) => {
    setCheckboxQuestionsList((prevList) => {
      return prevList.map((question) => {
        if (question.field_name === fieldName) {
          return {
            ...question,
            value: !question.value,
          };
        }
        return question;
      });
    });
  };

  return (
    <>
      {pageLoading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        <LoadingOverlay active={loading} spinner text="Please Wait...">
          <div style={{ width: "70%", paddingBottom: "20px" }}>
            <div>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <div>
                    <Typography variant="h6">Company-Job Details</Typography>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        position: "relative",
                        border: "4px solid #2563EB",
                        borderRadius: "50%",
                        height: "100px",
                        width: "100px",
                      }}
                    >
                      {companyDetails?.company_logo && (
                        <img
                          alt="img"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={companyDetails?.company_logo}
                        />
                      )}
                      <button
                        style={{
                          backgroundColor: "#D9D9D9",
                          border: "none",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          borderRadius: "50%",
                          padding: "6px",
                          cursor: "auto",
                        }}
                      >
                        <Camera style={{ color: "#2563EB" }} />
                      </button>
                    </div>
                  </div>
                </Grid>
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="Company Name"
                    size="small"
                    value={companyDetails?.company_name}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>{" "}
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="Company Id"
                    size="small"
                    value={companyDetails?.company_id}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="Job Name"
                    size="small"
                    value={job_title}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="Job Id"
                    size="small"
                    value={job_id}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <div>
                    <Typography variant="h6">AI-Calling Bot Details</Typography>
                  </div>
                </Grid>
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="AI-Bot Id"
                    size="small"
                    required
                    value={aiBotDetails?.bot_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={(item) => {
                      if (item.target.value)
                        setAiBotDetails((prev) => ({
                          ...prev,
                          bot_id: item?.target?.value,
                        }));
                    }}
                  />
                </Grid>
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="Campaign Id"
                    size="small"
                    required
                    value={aiBotDetails?.campaign_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={(item) => {
                      if (item.target.value)
                        setAiBotDetails((prev) => ({
                          ...prev,
                          campaign_id: item?.target?.value,
                        }));
                    }}
                  />
                </Grid>
                <Grid item md={12} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="Disposition List"
                    size="small"
                    reuired
                    multiline={true}
                    rows={5}
                    value={aiBotDetails?.disposition_list?.toString()}
                    InputLabelProps={{ shrink: true }}
                    onChange={(item) => {
                      if (item.target.value){
                        let dispo = item?.target?.value?.split(",");
                        setAiBotDetails((prev) => ({
                          ...prev,
                          disposition_list: dispo
                        }));
                      }
                    }}
                  />
                </Grid>
                
                <Grid item md={12} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="Webhook Data"
                    size="small"
                    multiline={true}
                    rows={5}
                    required
                    value={aiBotDetails?.webhook_data?.toString()}
                    InputLabelProps={{ shrink: true }}
                    onChange={(item) => {
                      if (item.target.value){
                        setAiBotDetails((prev) => ({
                          ...prev,
                          webhook_data: item?.target?.value
                        }));
                      }
                    }}
                  />
                  <Typography style={{cursor: "pointer"}} onClick={() => {
                    copyToClipboard(WEBHOOK_DATA);
                  }}> Copy Example Data</Typography>
                </Grid>
                
                <Grid item md={6} style={{ display: "grid" }}>
                  <TextField
                    variant="outlined"
                    label="AI-Call Assist. Id"
                    size="small"
                    value={aiBotDetails?.assistant_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={(item) => {
                      if (item.target.value)
                        setAiBotDetails((prev) => ({
                          ...prev,
                          assistant_id: item?.target?.value,
                        }));
                    }}
                  />
                </Grid>
                
                <Grid item md={6} style={{ display: "grid" }}>
                  <Autocomplete
                    id="current_location"
                    value={aiBotDetails?.status}
                    options={botStatuses}
                    onChange={(e, item) => {
                      setAiBotDetails((prev) => ({
                        ...prev,
                        status: item,
                      }));
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Bot Status"
                        size="small"
                        required
                      />
                    )}
                  />
                </Grid>
              
                <Grid item md={12}>
                  <div>
                    <Typography variant="h6">
                      CheckBox Questions to be asked:
                    </Typography>
                  </div>
                </Grid>
                {checkboxQuestionsList.map((question, index) => (
                  <Grid
                    item
                    md={12}
                    style={{ display: "grid", paddingTop: 0, paddingBottom: 0 }}
                    key={index}
                  >
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          style={{ margin: 0, cursor: "auto" }}
                          control={
                            <Checkbox
                              style={{ paddingLeft: 0, cursor: "auto" }}
                              checked={question?.value}
                              color="primary"
                              name="select all"
                            />
                          }
                          label={question.text_value}
                          labelPlacement="end"
                          disabled
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                ))}
                <Grid item md={12}>
                  <div>
                    <Typography variant="h6">
                      Fitment Questions to be asked:
                    </Typography>
                  </div>
                </Grid>
                {fitmentQuestionsList.length ? (
                  fitmentQuestionsList.map((question, index) => (
                    <Grid
                      item
                      md={12}
                      style={{
                        display: "grid",
                        paddingTop: 0,
                        paddingBottom: "15px",
                      }}
                      key={index}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        value={question?.question}
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <div
                    style={{
                      marginLeft: "1.5rem",
                      fontSize: "1rem",
                      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                      fontWeight: "400",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                      paddingBottom: "15px",
                    }}
                  >
                    No Fitment Question Added
                  </div>
                )}
                <Grid item md={12}>
                  <div>
                    <Typography variant="h6">Bot Voice Seleced:</Typography>
                  </div>
                </Grid>
                <div
                  style={{
                    marginLeft: "1.5rem",
                    fontSize: "1rem",
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                    fontWeight: "400",
                    lineHeight: "1.5",
                    letterSpacing: "0.00938em",
                    paddingBottom: "15px",
                  }}
                >
                  {botVoices.length &&
                  botVoices.find((item) => item.value === true)
                    ? botVoices.find((item) => item.value === true).text_value
                    : "Default"}
                </div>
                <Grid item md={12}>
                  <div>
                    <Typography variant="h6">Bot Language Seleced:</Typography>
                  </div>
                </Grid>
                <div
                  style={{
                    marginLeft: "1.5rem",
                    fontSize: "1rem",
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                    fontWeight: "400",
                    lineHeight: "1.5",
                    letterSpacing: "0.00938em",
                    paddingBottom: "15px",
                  }}
                >
                  {botLanguages.length &&
                  botLanguages.find((item) => item.value === true)
                    ? botLanguages.find((item) => item.value === true)
                        .text_value
                    : "Default"}
                </div>
              </Grid>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#2563EB",
                    width: "180px",
                    height: "50px",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    updateBotDetails();
                  }}
                >
                  {loading ? (
                    <CustomButtonCircularProgress />
                  ) : (
                    "Update Details"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      )}
    </>
  );
}

export default AiCallingBot;

const botStatuses = ["PENDING", "COMPLETED", "CREATED", "FAILED"];
