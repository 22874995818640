import {
  Button, Grid,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { praveshAxiosCall, praveshAxiosGetReq } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import BulkResume from "./BulkResume";
import TagsSection from "./Components/BulkUploadResume/TagsSection";
import UploadBox from "./Components/BulkUploadResume/UploadBox";
import SearchBox from "./Components/Card/SearchBox";
import DeleteTag from "./Components/Dialog/DeleteTag";
import UploadResumes from "./Components/Dialog/UploadResumes";
import CustomButtonCircularProgress from "./customLoader";

function AllResume() {
  const [open, setOpen] = React.useState(false);
  const [tagsName, setTagsName] = React.useState();
  const [loading, setLoading] = useState(false);
  const [showUploadDialog,setShowUploadDialog] = useState(true);
 
  const [pageNo, setPageNo] = useState(2);
  const [removeViewMore, setRemoveViewMore] = useState(false);
  const [tagsRefreshed, setTagsRefreshed] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [checked, setChecked] = React.useState(false);
  const [openProgressBar, setOpenProgressBar] = useState(false);
  const [progress,setProgress] = useState(0);
  const [loadedInfo,setLoadedInfo] = useState({loaded:null, total:null});


  const tagsPagination = async () => {
    const endPoint = `/api/admin/bulk/tags/?page=${pageNo}`;

    try {
      const res = await praveshAxiosGetReq(endPoint);

      if (res?.data?.success) {
        setPageNo(pageNo + 1);
        setTagsName([...tagsName, ...res.data.data?.results]);
        if (res?.data?.data?.next === null) {
          setRemoveViewMore(true);
        }
      } else {
        FailureAlert("Some Error Occurred!");
      }
    } catch (error) {
      FailureAlert("Some Error Occurred!");
      throw error;
    }
  };

  const setTagsApiCall = () => {
    
    // for reversing from the call set to true by search dropdown
    setRemoveViewMore(false);

    praveshAxiosCall
      .get(`/api/admin/bulk/tags/`)
      .then((res) => {
        if (res.data.success) {
          setTagsName(res.data.data?.results);
          if (res?.data?.data?.next === null) {
            setRemoveViewMore(true);
          }
          setPageNo(2);
          // setTagData(true);
          // setTagsCount(res?.data?.data?.count);
        } 
      })
      .catch((err) => {
        throw(err);
      });
  };

  useEffect(() => {
    setTagsApiCall();
  }, []);


  return (
    <>    
      {
        showUploadDialog ? <BulkResume setShowUploadDialog={setShowUploadDialog}/> : 
      <section>      
      <div style={{ padding: "20px" }}>
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >          
          
        </Grid>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" style={{ marginBottom: "20px" }}>
            Bulk Resume Uploader
          </Typography>
          {/* <div>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "40px", boxShadow: "none" }}
              onClick={handleClickOpenDelete}
            >
              Delete All
            </Button>
          </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "60%",
            }}
          >
            <SearchBox
              tagsName={tagsName}
              setTagsName={setTagsName}
              setTagsRefreshed={setTagsRefreshed}
              setTagsApiCall={setTagsApiCall}
              setRemoveViewMore={setRemoveViewMore}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ height: "40px", boxShadow: "none", marginLeft: "20px" }}
              onClick={handleClickOpen}
            >
              Upload Resumes
            </Button>
          </div>
        </div>
        {tagsRefreshed
          ? null
          : tagsName?.map((tag) => {
              // console.log("tagsection explore", tag?.name);
              return (
                <TagsSection
                  tagName={tag}
                  setTagsApiCall={setTagsApiCall}
                  tagsRefreshed={tagsRefreshed}
                  setTagsRefreshed={setTagsRefreshed}
                />
              );
            })}

        {removeViewMore ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ height: "40px", boxShadow: "none", marginLeft: "20px" }}
              onClick={tagsPagination}
            >
              {loading ? <CustomButtonCircularProgress /> : "View More"}
            </Button>
          </div>
        )}
      </div> 
       <UploadResumes
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        setProgress={setProgress}
        setOpenProgressBar={setOpenProgressBar}
        setLoadedInfo={setLoadedInfo}
        loadedInfo={loadedInfo}
        setTagsApiCall={setTagsApiCall}
        fromBulkResume={false}
        setShowUploadDialog={setShowUploadDialog}

        />

      <DeleteTag
        open={openDelete}
        handleClickOpen={handleClickOpenDelete}
        handleClose={handleCloseDelete}
      />
      <UploadBox
        open={openProgressBar}
        progress={progress}
        loadedInfo={loadedInfo}
      />
      </section>  
}    
     
      
    </>
  );
}

export default AllResume;
