import React, { useEffect, useState } from 'react';
import '../../css/PaymentDetails.css';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
// import { Select, MenuItem } from "@material-ui/core";
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function EditSpecializations() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/specialization/all/', requestPraveshOptions)
        .then((json)=> {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        specialization_id:   json.data[i].specialization_id,
                        specialization_name: json.data[i].specialization_name,
                        is_user_created:     json.data[i].is_user_created ? 'Yes' : 'No',
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
        })
    }, [count]);

    const columns = [
        { title: "Specialization_id",    field: "specialization_id",    headerStyle: headerCSS, cellStyle: cellCSS,
          filtering: false,              editable: 'never', },
        { title: "Specialization_name",  field: "specialization_name",  headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "User_Created?",        field: "is_user_created",      headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,             filterPlaceholder: 'Yes/No',   lookup: {'Yes':'Yes', 'No':'No'},
        //   editComponent: props => (
        //     <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
        //         <MenuItem value="Yes">Yes</MenuItem>
        //         <MenuItem value="No">No</MenuItem>
        //     </Select>
        //   )
        },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                    specialization_id:    newData.specialization_id,
                    specialization_name:  newData.specialization_name,
                    user_created:         newData.is_user_created==='Yes'?true:false
            })
        };
        apiCall(`/api/admin/specialization/all/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.error);
            }
        })
    }

    return (
        !loading
        ?
        <div className="specializations__details">
            <TableWithExport
                title = "List of Specializations"
                data = {data}
                columns = {columns}
                fName = "Selected Specializations"
                editableObject = {edit}
            />
        </div>
        :
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
    )
}

export default EditSpecializations;