// import { Button } from "@material-ui/core";
// import React, { useEffect, useState } from "react";
// import { cellCSS, headerCSS } from "../../constants";
import { praveshAxiosGetReq, praveshAxiosPostReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { Button, Modal } from "react-bootstrap";
// import TableWithoutExport from "../TableWithoutExport";
// import AllVendorFilterDialog from "./AllVendorFilterDialog";
// import LoadingOverlay from "react-loading-overlay";

// function AllVendor() {
//   const [open, setOpen] = useState(false);
//   const [data, setData] = useState([]);

// useEffect(() => {
//   praveshAxiosGetReq(`/api/admin/vendor/`).then(res => {
//     if(res?.data?.success){
//       setData(res?.data?.data);
//     }else{
//       FailureAlert(res?.data?.error || "Error in fetching Data")
//     }
//   }).catch(err => {
//     FailureAlert("Error in fetching Data")
//   })
// }, [])

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const activateDeactivate = (row) => {
//     setLoading(true);
//     praveshAxiosPostReq(`/api/admin/vendor/change_vendor_profile_status/`, {
//       vendor_id: row?.vendor_id
//     }).then(res => {
//         if(res?.data?.success){
//           SuccessAlert(res?.data?.data?.message);
//           let tempData = [...data];
//           const ind = tempData.findIndex(item => item?.id === row?.id);
//           if(ind !== -1){
//             tempData[ind].is_active = res?.data?.data?.is_active;
//             setData(tempData);
//           }
//         }else{
//           FailureAlert("Error in activating/deactivating vendor");
//         }
//         setLoading(false);
//     }).catch(err => {
//       FailureAlert("Error in activating/deactivating vendor");
//       setLoading(false);
//     })
//   }

//   const columns = [
//     {
//       title: "Firm ID",
//       field: "vendor_id",
//       headerStyle: headerCSS,
//       cellStyle: cellCSS,
//       cellStyle: {
//         minWidth: 100,
//         maxWidth: 100,
//       },
//       filtering: false,
//     },

//     {
//       title: "Firm name",
//       field: "vendor_name",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "Firm State",
//       field: "state",
//       headerStyle: headerCSS,
//       cellStyle: cellCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "Firm City",
//       field: "city",
//       headerStyle: headerCSS,
//       cellStyle: cellCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "User Name",
//       // field: "full_name",
//       headerStyle: headerCSS,
//       cellStyle: cellCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//       render: (row) =>
//         <div>
//           {row?.first_name} {row?.last_name}
//         </div>

//     },
//     {
//       title: "Email Id",
//       field: "email",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 250,
//         maxWidth: 250,
//       },
//       filtering: false,
//     },
//     {
//       title: "Alt Email",
//       field: "alternate_email",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 250,
//         maxWidth: 250,
//       },
//       filtering: false,
//     },
//     {
//       title: "Contact no",
//       field: "phone",
//       headerStyle: headerCSS,

//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: " Alt contact no",
//       field: "alternate_phone",
//       headerStyle: headerCSS,
//       //cellStyle: cellCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "User City",
//       field: "current_city",
//       headerStyle: headerCSS,
//       //cellStyle: cellCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "User State",
//       field: "current_state",
//       headerStyle: headerCSS,
//       //cellStyle: cellCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "Document",
//       field: "vendor_agreement",
//       headerStyle: headerCSS,
//       cellStyle: cellCSS,
//       render: (row) =>
//         row.vendor_agreement ? (
//           <div>
//             <button
//               style={{
//                 color: "#2563EB",
//                 width: "70px",
//                 height: "32px",
//                 backgroundColor: "#fff",
//                 border: "1px solid #2563EB",
//                 borderRadius: "6px",
//               }}
//               onClick={() => {
//                 window.open(row?.vendor_agreement, "_blank")
//               }}
//             >
//               View
//             </button>
//           </div>
//         ) : null,
//       filtering: false,
//     },
//     {
//       title: "Created by",
//       field: "created_by",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "Created Datetime",
//       field: "create_time",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 250,
//         maxWidth: 250,
//       },
//       filtering: false,
//     },
//     {
//       title: "Projected income",
//       field: "projected_income",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "Net earning",
//       field: "net_earning",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 150,
//         maxWidth: 150,
//       },
//       filtering: false,
//     },
//     {
//       title: "Last login",
//       field: "last_login_date",
//       headerStyle: headerCSS,
//       cellStyle: {
//         minWidth: 250,
//         maxWidth: 250,
//       },
//       filtering: false,
//     },
//     {
//       title: "Activate/Deactivate",
//       // field: "status",
//       headerStyle: headerCSS,
//       cellStyle: cellCSS,
//       render: (row) =>
//           <div>
//             <button
//               style={{
//                 color: "#51BA37",
//                 width: "100px",
//                 height: "32px",
//                 backgroundColor: "#fff",
//                 border: "1px solid #51BA37",
//                 borderRadius: "6px",
//               }}
//               onClick={() => {
//                 activateDeactivate(row);
//               }}
//             >
//               {row.is_active ? "Deactivate" : "Activate"}
//             </button>
//           </div>,
//       filtering: false,
//     },
//   ];

//   const [loading, setLoading] = useState(false);

//   const [filters, setFilters] = useState({
//     selectedVendorList: [],
//     selectedCityList: [],
//     selectedCreatedByList: []
//   })

//   return (
//     <div>
//       <LoadingOverlay
//               active={loading}
//               spinner
//               text="Please Wait..."
//       >
//       {/* <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-end",
//           marginBottom: "20px",
//           marginTop: "5px",
//         }}
//       >
//         <Button
//           variant="contained"
//           color="primary"
//           style={{
//             backgroundColor: "#2563EB",
//             borderRadius: "6px",
//             height: "34px",
//             color: "#fff",
//             marginRight: "10px",
//             boxShadow: "none",
//           }}
//           onClick={handleClickOpen}
//         >
//           Filter
//         </Button>
//       </div> */}
//       <TableWithoutExport
//         title="All Firms"
//         columns={columns}
//         selection={false}
//         data={data}
//         fName={"ALL_VENDOR_PARTNERS"}
//       />

// {open && (
//         <AllVendorFilterDialog open={open} handleClose={() => {setOpen(false)}}
//         filters={filters}
//         setFilters={setFilters}
//         />
//       )}
//       </LoadingOverlay>
//     </div>
//   );
// }

// export default AllVendor;

import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { cellCSS, headerCSS } from "../../constants";
import { niyuktiAxiosPostReq } from "../../util/apiCall";
// import { FailureAlert } from "../../util/Notifications";
import { viewJobDetail } from "../../util/ViewJobFunction";
import TableWithoutExport from "../TableWithoutExport";
import FilterDialog from "./FilterDialog";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Tooltip } from "@material-ui/core";

const textBox = {
  fontSize: "14px",
  color: "#2563EB",
  textAlign: "center",
};

const numberHeading = {
  backgroundColor: "#ebf5fa",
  fontWeight: "bold",
  fontSize: "14px",
  padding: "4px 15px",
  height: "50px",
  textAlign: "center",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

const lengthChecker = (text) => {
  const maxLength = 75;
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

function AllVendor() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState({});

  const [value, setValue] = React.useState(0);
  const [status, setStatus] = useState("ACCEPTED");

  const [data, setData] = useState([]);

  const [forceUpdate, setForceUpdate] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStatus(
      newValue === 0 ? "ACCEPTED" : newValue === 1 ? "PENDING" : "REJECTED"
    );
  };

  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    setLoading(true);
    praveshAxiosGetReq(`/api/admin/vendor/?vendor_profile_status=${status}`)
      .then((res) => {
        if (res?.data?.success) {
          setData(res?.data?.data);
        } else {
          FailureAlert(res?.data?.error || "Error in fetching Data");
        }
        setLoading(false);
      })
      .catch((err) => {
        FailureAlert("Error in Getting Firm Jobs");
        setLoading(false);
      });

    praveshAxiosGetReq(`/api/admin/vendor_count/`)
      .then((res) => {
        if (res?.data?.success) {
          setCount(res?.data?.data);
        } else {
          FailureAlert(res?.data?.error || "Error in fetching Count");
        }
      })
      .catch((err) => {
        FailureAlert("Error in Getting Count");
      });
  }, [status, forceUpdate]);
  useEffect(() => console.log(status), [status]);

  const activateDeactivate = (row) => {
    setLoading(true);
    praveshAxiosPostReq(`/api/admin/vendor/change_vendor_profile_status/`, {
      vendor_id: row?.vendor_id,
    })
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message);
          let tempData = [...data];
          const ind = tempData.findIndex((item) => item?.id === row?.id);
          if (ind !== -1) {
            tempData[ind].is_active = res?.data?.data?.is_active;
            setData(tempData);
          }
        } else {
          FailureAlert("Error in activating/deactivating vendor");
        }
        setLoading(false);
      })
      .catch((err) => {
        FailureAlert("Error in activating/deactivating vendor");
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Firm ID",
      field: "vendor_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      filtering: false,
    },

    {
      title: "Firm name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    // {
    //   title: "Is Job Portal",
    //   field: "is_job_portal",
    //   headerStyle: headerCSS,
    //   cellStyle: {
    //     minWidth: 150,
    //     maxWidth: 150,
    //   },
    //   filtering: false,
    //   render: (row) => <div>{row?.is_job_portal ? "Yes" : "No"}</div>,
    // },
    {
      title: "Job Portal Code",
      field: "job_portal_code",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>{row?.job_portal_code ? row?.job_portal_code : "NA"}</div>
      ),
    },
    {
      title: "Firm State",
      field: "state",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Firm City",
      field: "city",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "User Name",
      // field: "full_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>
          {row?.first_name} {row?.last_name}
        </div>
      ),
    },
    {
      title: "Email Id",
      field: "email",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      render: (row) =>
        row?.email ? (
          <Tooltip title={`${row.email}`} placement="right">
            <div
              style={{
                width: "200px",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              {lengthChecker(row.email)}
            </div>
          </Tooltip>
        ) : (
          "NA"
        ),
      filtering: false,
    },
    {
      title: "Alt Email",
      field: "alternate_email",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      render: (row) =>
        row?.alternate_email ? (
          <Tooltip title={`${row.alternate_email}`} placement="right">
            <div
              style={{
                width: "200px",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              {lengthChecker(row.alternate_email)}
            </div>
          </Tooltip>
        ) : (
          "NA"
        ),
      filtering: false,
    },
    {
      title: "Contact no",
      field: "phone",
      headerStyle: headerCSS,

      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: " Alt contact no",
      field: "alternate_phone",
      headerStyle: headerCSS,
      //cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "User City",
      field: "current_city",
      headerStyle: headerCSS,
      //cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "User State",
      field: "current_state",
      headerStyle: headerCSS,
      //cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Document",
      field: "vendor_agreement",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.vendor_agreement ? (
          <div>
            <button
              style={{
                color: "#2563EB",
                width: "70px",
                height: "32px",
                backgroundColor: "#fff",
                border: "1px solid #2563EB",
                borderRadius: "6px",
              }}
              onClick={() => {
                window.open(row?.vendor_agreement, "_blank");
              }}
            >
              View
            </button>
          </div>
        ) : null,
      filtering: false,
    },
    {
      title: "Created by",
      field: "created_by",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Created Datetime",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Projected income",
      field: "projected_income",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Net earning",
      field: "net_earning",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Last login",
      field: "last_login_date",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Activate/Deactivate",
      // field: "status",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <button
            style={{
              color: "#51BA37",
              width: "100px",
              height: "32px",
              backgroundColor: "#fff",
              border: "1px solid #51BA37",
              borderRadius: "6px",
            }}
            onClick={() => {
              activateDeactivate(row);
            }}
          >
            {row.is_active ? "Deactivate" : "Activate"}
          </button>
        </div>
      ),
      filtering: false,
    },
  ];

  const acceptRejectApi = (isAccepted, row, reason) => {
    return new Promise((resolve, reject) => {
      const payload = {
        vendor_id: row?.vendor_id,
        status: isAccepted ? "ACCEPTED" : "REJECTED",
      };
      if (!isAccepted) {
        payload.reject_reason = reason;
      }

      praveshAxiosPostReq(`/api/admin/vendor/vendor_profile/`, payload)
        .then((res) => {
          if (res?.data?.success) {
            SuccessAlert(
              `${isAccepted ? "Accepted" : "Rejected"} Successfully!!!`
            );
            resolve(res.data); // Resolve with the response data
          } else {
            FailureAlert(res?.data?.error || "Error in Accepting/Rejecting");
            reject(res.data.error || "Error in Accepting/Rejecting"); // Reject with the error
          }
        })
        .catch((err) => {
          FailureAlert("Error in Accepting/Rejecting");
          reject("Error in Accepting/Rejecting"); // Reject with the error
        });
    });
  };

  const pendingJobcolumns = [
    {
      title: "Firm name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Firm Location",
      field: "city",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      render: (row) => <div>{`${row.city}, ${row.state}`}</div>,
      filtering: false,
    },
    // {
    //   title: "Is Job Portal",
    //   field: "is_job_portal",
    //   headerStyle: headerCSS,
    //   cellStyle: {
    //     minWidth: 150,
    //     maxWidth: 150,
    //   },
    //   filtering: false,
    //   render: (row) => <div>{row?.is_job_portal ? "Yes" : "No"}</div>,
    // },
    {
      title: "Job Portal Code",
      field: "job_portal_code",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>{row?.job_portal_code ? row?.job_portal_code : "NA"}</div>
      ),
    },
    {
      title: "User Name",
      field: "admin_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },
    {
      title: "Firm ID",
      field: "vendor_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
    },
    {
      title: "Email",
      field: "email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },
    {
      title: "Number",
      field: "phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },

    {
      title: "Vendor Agreement",
      field: "vendor_agreement",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      render: (row) =>
        row.vendor_agreement ? (
          <div>
            <button
              style={{
                color: "#2563EB",
                width: "70px",
                height: "32px",
                backgroundColor: "#fff",
                border: "1px solid #2563EB",
                borderRadius: "6px",
              }}
              onClick={() => {
                window?.open(row.vendor_agreement, "_blank");
              }}
            >
              View
            </button>
          </div>
        ) : null,
      filtering: false,
    },

    {
      title: "Create date",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Accept/Reject",
      field: "status",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.vendor_status === "PENDING" ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                color: "#fff",
                width: "100px",
                height: "32px",
                backgroundColor: "#fff",
                backgroundColor: "#0DA06A",
                borderRadius: "6px",
                marginRight: "15px",
              }}
              onClick={async () => {
                if (row.vendor_agreement) {
                  await acceptRejectApi(true, row);
                  setStatus("PENDING");
                  setForceUpdate((prevState) => !prevState);
                } else {
                  FailureAlert("Vender Agreement not present");
                }
              }}
            >
              Accept
            </button>
            <button
              style={{
                color: "#EF4444",
                width: "100px",
                height: "32px",
                backgroundColor: "#fff",
                border: "1px solid #EF4444",
                borderRadius: "6px",
              }}
              onClick={() => {
                setViewRejectReasonModal(true);
                setRejectRowData(row);
              }}
            >
              Reject
            </button>
          </div>
        ) : (
          <Typography
            style={{
              fontSize: "14px",
              color: `${
                row.vendor_status === "Accepted" ? "#0DA06A" : "#EF4444"
              }`,
            }}
          >
            {row.vendor_status === "Accepted" ? "Accepted" : "Rejected"}
          </Typography>
        ),
      filtering: false,
    },
  ];

  const rejectedJobcolumns = [
    {
      title: "Firm name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Firm Location",
      field: "city",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      render: (row) => <div>{`${row.city}, ${row.state}`}</div>,

      filtering: false,
    },
    // {
    //   title: "Is Job Portal",
    //   field: "is_job_portal",
    //   headerStyle: headerCSS,
    //   cellStyle: {
    //     minWidth: 150,
    //     maxWidth: 150,
    //   },
    //   filtering: false,
    //   render: (row) => <div>{row?.is_job_portal ? "Yes" : "No"}</div>,
    // },
    {
      title: "Job Portal Code",
      field: "job_portal_code",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>{row?.job_portal_code ? row?.job_portal_code : "NA"}</div>
      ),
    },
    {
      title: "User Name",
      field: "admin_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },
    {
      title: "Number",
      field: "phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },

    {
      title: "Vendor Agreement",
      field: "vendor_agreement",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      render: (row) =>
        row.vendor_agreement ? (
          <div>
            <button
              style={{
                color: "#2563EB",
                width: "70px",
                height: "32px",
                backgroundColor: "#fff",
                border: "1px solid #2563EB",
                borderRadius: "6px",
              }}
              onClick={() => {
                window?.open(row.vendor_agreement, "_blank");
              }}
            >
              View
            </button>
          </div>
        ) : null,
      filtering: false,
    },

    {
      title: "Create date",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Rejectd date",
      field: "update_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Rejected By",
      field: "rejected_by",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
    },
    {
      title: "Rejected Reason",
      field: "reject_reason",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
  ];

  const [rejectReason, setRejectReason] = useState("");
  const [viewRejectReasonModal, setViewRejectReasonModal] = useState(false);
  const [rejectRowData, setRejectRowData] = useState(null);

  const handleClose = () => {
    setViewRejectReasonModal(false);
  };

  return (
    <>
      <div>
        <div className={classes.root}>
          <Box style={{ borderBottom: "2px solid #CBD5E1" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              // style={{ backgroundColor: "#fff" }}
              aria-label="simple tabs example"
            >
              <Tab
                label={`Accepted (${
                  count?.accepted_count ? count?.accepted_count : "0"
                })`}
                {...a11yProps(0)}
              />
              <Tab
                label={`Pending (${
                  count?.pending_count ? count?.pending_count : "0"
                })`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Rejected (${
                  count?.rejected_count ? count?.rejected_count : "0"
                })`}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  color: "#fff",
                  marginRight: "10px",
                  boxShadow: "none",
                }}
                onClick={handleClickOpen}
              >
                Filter
              </Button> */}

              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  boxShadow: "none",
                  color: "#fff",
                }}
              >
                Download Excel Data
              </Button> */}
            </div>
            <TableWithoutExport
              columns={columns}
              title="Accepted Firms"
              selection={false}
              data={data}
              loading={loading}
            />{" "}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  color: "#fff",
                  marginRight: "10px",
                  boxShadow: "none",
                }}
                onClick={handleClickOpen}
              >
                Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  boxShadow: "none",
                  color: "#fff",
                }}
              >
                Download Excel Data
              </Button>
            </div>
             */}
            <TableWithoutExport
              columns={pendingJobcolumns}
              title="Pending Firms"
              selection={false}
              data={data}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  color: "#fff",
                  marginRight: "10px",
                  boxShadow: "none",
                }}
                onClick={handleClickOpen}
              >
                Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  boxShadow: "none",
                  color: "#fff",
                }}
              >
                Download Excel Data
              </Button>
            </div>
             */}
            <TableWithoutExport
              columns={rejectedJobcolumns}
              title="Rejected Firms"
              selection={false}
              data={data}
              loading={loading}
            />
          </TabPanel>
        </div>
        {/* <FilterDialog
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        /> */}
        {viewRejectReasonModal ? (
          <Modal
            show={viewRejectReasonModal}
            onHide={handleClose}
            // scrollable={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Reason For Rejection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ marginTop: "20px" }}>
                <Typography component="legend">Give Reason:</Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Reason"
                  style={{ height: "100px", width: "100%" }}
                  onChange={(event) => {
                    setRejectReason(event.target.value);
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (rejectReason !== "") {
                    acceptRejectApi(false, rejectRowData, rejectReason)
                      .then(() => {
                        console.log("here");
                        setStatus("pENDING");
                        console.log("below");
                        setForceUpdate((prevState) => !prevState);
                        setViewRejectReasonModal(false);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    FailureAlert("Please enter a valid reason");
                  }
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    </>
  );
}

export default AllVendor;
