import React, { useState, useEffect } from 'react';
import { apiCall } from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import { Button } from 'react-bootstrap';
import TableWithExport from '../TableWithExport';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import gif from '../../assets/loopingcircles-3.gif';

function UserManualPayment() {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const userTypeLookup = { 5: 'Company', 7: 'College', }

    const acceptTransaction = (e, row) => {
        e.preventDefault();
        const requestForAccepting = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ 
                user: row.user_id,
                user_type: row.user_type_id,
                reference_id: row.reference_id,
                amount: parseFloat(row.amount),
                status: 'Accepted'
            })
        };
        apiCall(`/api/admin/user_manual_credit/${row.id}/`, requestForAccepting)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message)
                setCount(count+1);
            }
            else FailureAlert(res.error);
        })
    }

    const rejectTransaction = (e, row) => {
        e.preventDefault();
        const requestForAccepting = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ 
                user: row.user_id,
                user_type: row.user_type_id,
                reference_id: row.reference_id,
                amount: parseFloat(row.amount),
                status: 'Rejected'
            })
        };
        apiCall(`/api/admin/user_manual_credit/${row.id}/`, requestForAccepting)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message)
                setCount(count+1);
            }
            else FailureAlert(res.error);
        })
    }


    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/user_manual_credit/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:             parseInt(json.data[i].id),
                        reference_id:   json.data[i].reference_id,
                        user_id:        json.data[i].user_id,
                        user_type_id:   json.data[i].user_type_id,
                        amount:         json.data[i].amount || 0,
                        status:         json.data[i].status,
                        email_id:       json.data[i].email_id,
                        institute_name: json.data[i].institute_name,
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const columns = [
        { title: "ID",              field: "id",             headerStyle: headerCSS, cellStyle: cellCSS, 
          searchable: false, },
        { title: "Reference_ID",    field: "reference_id",   headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Email_ID",        field: "email_id",       headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "User_Type",       field: "user_type_id",   headerStyle: headerCSS, cellStyle: cellCSS,
          lookup: userTypeLookup,   searchable: false,       filterPlaceholder: 'Select',
        },
        { title: "Institute_Name",  field: "institute_name", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Amount",          field: "amount",         headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Status",          field: "status",         headerStyle: headerCSS, cellStyle: cellCSS,
            render: row =>
            <div>
                {
                    row.status==="Pending" 
                    ?
                    <span>{row.status}</span>
                    :
                    row.status==="Accepted"
                    ?
                    <span style={{color:'green'}}><b>{row.status}</b></span>
                    :
                    <span style={{color:'red'}}><b>{row.status}</b></span>
                }
            </div>
        },
        // Accept Button
        { headerStyle: headerCSS,   cellStyle: cellCSS,      export: false,
          render: row => 
          <div> 
            { 
                row.status==='Pending'
                ? 
                <Button variant="outline-success" size="sm" onClick={e=>acceptTransaction(e, row)}> Accept</Button> 
                : 
                <Button variant="outline-secondary" size="sm" disabled style={{cursor:'not-allowed'}}> Accept</Button>
            }
          </div> 
        },
        // Reject Button
        { headerStyle: headerCSS,   cellStyle: cellCSS,      export: false,
          render: row => 
          <div> 
            { 
                row.status==='Pending'
                ? 
                <Button variant="outline-danger" size="sm" onClick={e=>rejectTransaction(e, row)}> Reject</Button> 
                : 
                <Button variant="outline-secondary" size="sm" disabled style={{cursor:'not-allowed'}}> Reject</Button>
            }
          </div> 
        },
    ]

    return (
        !loading
        ?
        <div>
            <TableWithExport
                title = 'User Manual Payment Details'
                data = {data}
                columns = {columns}
                fName = "Selected User Manual Payments"
            />
        </div>
        :
        <img
            src={gif} style={gifStyling}
            alt="Getwork Logo"
        />
    )
}

export default UserManualPayment;