import React, { useState, useEffect } from 'react';
import '../../css/JobDescription.css'
import { apiNiyuktiCall } from '../../util/apiCall';
import TextField from '@material-ui/core/TextField';
import { gifStyling, requestNiyuktiOptions } from '../../constants';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Form, Button } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { SuccessAlert } from '../../util/Notifications';

function JobDescription() {

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(null);

  const [jobTitle, setJobTitle] = useState('');
  const [jobDescId, setJobDescId] = useState(null);
  const [editorDescription, setEditorDescription] = useState(EditorState.createEmpty());
  const [editorWork, setEditorWork] = useState(EditorState.createEmpty());
  const [editorReq, setEditorReq] = useState(EditorState.createEmpty());
  const [editorFullRes, setEditorFullRes] = useState(EditorState.createEmpty());
  const [editorBreif, setEditorBrief] = useState(EditorState.createEmpty());
  const [editorShort, setEditorShort] = useState(EditorState.createEmpty());

  const [hasData, setJobHasData ] = useState(false);
  const [detailObj, setDetailObj] = useState({});

  useEffect(() => {
    setLoading(true)
    apiNiyuktiCall('/job/role/', requestNiyuktiOptions)
      .then(json => {
        if (json.success) {
          let tmpArray = []
          for (var i = 0; i < json.data.length; i++) {
            let obj = {
              id: parseInt(json.data[i].id),
              job_role_name: json.data[i].job_role_name,
            }
            tmpArray.push(obj)
          }
          setJobs(tmpArray);
        }
        else {
          FailureAlert(json.error)
        }
        setLoading(false)
      })
  }, []);

  const getEditorState = (data) => {
    if(data) {
      return EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data)))
    } else {
      return EditorState.createEmpty()
    }
  }
  //fetching job roles
  useEffect(() => {
    if(!jobId) return;
    apiNiyuktiCall(`/job/search/job_description/?job_role_id=${jobId}`, requestNiyuktiOptions)
      .then(json => {
        let details = json.data
        if (json.success) {
          if(Object.keys(details).length === 0){
            setJobHasData(false)
          } else {
            setJobHasData(true);
            setDetailObj(details)
          }

          const { id, description, job_title, work, requirements, full_res, brief, short_res } = details

          job_title    ? setJobTitle(job_title)                            : setJobTitle('');
          id           ? setJobDescId(id)                                  : setJobDescId(null);
          description  ? setEditorDescription(getEditorState(description)) : setEditorDescription(EditorState.createEmpty());
          work         ? setEditorWork(getEditorState(work))               : setEditorWork(EditorState.createEmpty());
          requirements ? setEditorReq(getEditorState(requirements))        : setEditorReq(EditorState.createEmpty());
          brief        ? setEditorBrief(getEditorState(brief))             : setEditorBrief(EditorState.createEmpty());
          full_res     ? setEditorFullRes(getEditorState(full_res))        : setEditorFullRes(EditorState.createEmpty())
          short_res    ? setEditorShort(getEditorState(short_res))         : setEditorShort(EditorState.createEmpty());
         }
        else {
          FailureAlert(json.error)
        }
      })
  }, [jobId]);

  const getRequestBody = (method) => {
    let jobObject = {
      ...(method==='PATCH' && detailObj.job_title!==jobTitle && {'job_title': jobTitle}),
      ...(method==='POST' && {'job_title': jobTitle}),
      'description': draftToHtml(convertToRaw(editorDescription.getCurrentContent())),
      'full_res': draftToHtml(convertToRaw(editorFullRes.getCurrentContent())),
      'brief': draftToHtml(convertToRaw(editorBreif.getCurrentContent())),
      'requirements': draftToHtml(convertToRaw(editorReq.getCurrentContent())),
      'work': draftToHtml(convertToRaw(editorWork.getCurrentContent())),
      'short_res': draftToHtml(convertToRaw(editorShort.getCurrentContent())),
      ...(method==='POST' && {'job_role': parseInt(jobId)}),
    }

    return {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(jobObject),
    }
  }

  const editJob = (e) => {
    e.preventDefault();
    const requestForJobEditing = getRequestBody('PATCH')
    apiNiyuktiCall(`/job/search/job_description/${jobDescId}/`, requestForJobEditing)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          window.location.reload();
        }
        else FailureAlert(res.error);
      })
  }

  const postNewJob = (e) => {
    e.preventDefault();
    const requestForPostingJob = getRequestBody('POST');
    const obj = JSON.parse(requestForPostingJob.body)
    if(!jobTitle) { FailureAlert('Enter Job Title to continue...'); return;}
    if(obj['description'].length <= 8) { FailureAlert('Enter Description...'); return; }
    if(obj['work'].length <= 8) { FailureAlert('Enter Work...'); return; }
    if(obj['requirements'].length <= 8) { FailureAlert('Enter Requirements...'); return; }
    apiNiyuktiCall(`/job/search/job_description/`, requestForPostingJob)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          window.location.reload();
        }
        else FailureAlert(res.error);
      })
  }


  const showPostEditButton = () => {
    const buttonStyle = {
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 4px', marginLeft: '40%',
      width: '190px', height: '36px', marginTop: '15px',
      padding: '0.35rem 1.25rem', paddingBottom: '2.2rem', borderRadius: '4px',
      color: 'rgb(255, 255, 255)', background: '#2b9424',
      fontWeight: 500, fontSize: '20px', border: 'none',

    }
    if (hasData) {
      return (
        <Button type="submit" style={buttonStyle}
          onClick={e=> editJob(e)}
        > Edit Description </Button>
      )
    } else {
      return (
        <Button type="submit" style={buttonStyle}
          onClick={e => postNewJob(e)}
        > Post Description</Button>)
    }

  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });

  return (
    !loading
    ?
    <div className="job__description">
      <h3 style={{ textAlign: 'center' }}> <i>Job Description!</i> </h3>
      <hr /> <br />
      <Form className="post__job__form" style={{ display: 'flex', flexDirection: 'row' }} >
        <Autocomplete
          id="select-job"
          options={jobs}
          filterOptions={filterOptions}
          getOptionLabel={(item) => item.job_role_name}
          style={{ width: '94%', margin: '1% 3% 2% 3%' }}
          onChange={(e, item) => item ? setJobId(item.id) : setJobId(null)}
          renderInput={(params) => <TextField {...params} label="Select Job Role" variant="outlined" />}
        />
      </Form>
      {
        jobId
        ?
        <div id="job__description__form">
          <br />
          <Form>
            <Form.Label>Job title*</Form.Label>
            <TextField
              id="outlined-required"
              required
              label="Job Title"
              style={{ width: '94%', margin: '1% 3% 2% 3%' }}
              value={jobTitle}
              variant="outlined"
              onChange={e => { setJobTitle(e.target.value) }}
            />
          </Form >
          <Form.Label>Job Description *</Form.Label>
          <div className="editor" style={{ width: '94%', margin: '1% 3% 2% 3%' }}>
            <Editor
              editorState={editorDescription}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={s => setEditorDescription(s)}
            />
          </div>

          <Form.Label>Work *</Form.Label>
          <div className="editor" style={{ width: '94%', margin: '1% 3% 2% 3%' }}>
            <Editor
              editorState={editorWork}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={s => setEditorWork(s)}
            />
          </div>

          <Form.Label>Requirements *</Form.Label>
          <div className="editor" style={{ width: '94%', margin: '1% 3% 2% 3%' }}>
            <Editor
              editorState={editorReq}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={s => setEditorReq(s)}
            />
          </div>

          <Form.Label>Brief</Form.Label>
          <div className="editor" style={{ width: '94%', margin: '1% 3% 2% 3%' }}>
            <Editor
              editorState={editorBreif}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={s => setEditorBrief(s)}
            />
          </div>

          <Form.Label>Full Res</Form.Label>
          <div className="editor" style={{ width: '94%', margin: '1% 3% 2% 3%' }}>
            <Editor
              editorState={editorFullRes}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={s => setEditorFullRes(s)}
            />
          </div>

          <Form.Label>Short Res</Form.Label>
          <div className="editor" style={{ width: '94%', margin: '1% 3% 2% 3%' }}>
            <Editor
              editorState={editorShort}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={s => setEditorShort(s)}
            />
          </div>
          {showPostEditButton()}
          <br/>
          <br/>
        </div>
        :
        null
      }
    </div>
    :
    <img 
      src={gif} 
      alt="Getwork Loading Gif..."
      style={gifStyling}
    />
  )
}

export default JobDescription;