import {
  Button, Divider, FormControl,
  Grid, InputLabel,
  Radio, Select,
  TextField, Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import "../../css/Miscellaneous.css";


const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",

    marginBottom: "10px",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    border: "1px solid #b0b6ba",
    //marginBottom: "1%",
    borderRadius: "10px",
    padding: "10px",
  },
  editingtext: {
    color: "#b0b7c3",
    fontStyle: "italic",
  },
  btn: {
    backgroundColor: "#17a2b8",
    boxShadow: "none",
    color: "#fff",
    marginTop: "10px",
  },
}));


function AllCourse() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  return !loading ? (
    <>
      <div className="education__skils">
        <div className="education__skils__form">
          <div className={classes.heading}>
            <Typography variant="h5" style={{ fontWeight: "600" }}>
              {" "}
              All Course
            </Typography>
          </div>
          <div
            style={{
              borderBottom: "3px solid  #008080",
              paddingBottom: "30px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginBottom: "-10px" }}>
                <Typography variant="h6"> Company details </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={{ width: "100%" }}
                  label="Company Name"
                  required
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TextField
                  style={{ width: "100%" }}
                  label="Brand Name"
                  size="small"
                  variant="outlined"
                />{" "}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Company Website"
                  required
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={{ width: "100%" }}
                  label="Facebook link"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={{ width: "100%" }}
                  label="Instagram link"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={{ width: "100%" }}
                  label="LinkedIn link"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider
                  style={{ backgroundColor: "#008080", padding: "1.1px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Title"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Tags
                    </InputLabel>
                    <Select
                      native
                      value={state.age}
                      onChange={handleChange}
                      label="Tags"
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Created By"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Experience"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Durations"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="YouTube link"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Regular Price"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Discount"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Getwork Price"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      What you learn
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      // editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      //onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Carriculam
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      // editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      //onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Description
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      // editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      //onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  style={{ backgroundColor: "#008080", padding: "1.1px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6"> Instructor profile </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Name"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="TagLine"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Tags
                    </InputLabel>
                    <Select
                      native
                      value={state.age}
                      onChange={handleChange}
                      label="Tags"
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="YouTube Link"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      My Course
                    </InputLabel>
                    <Select
                      native
                      value={state.age}
                      onChange={handleChange}
                      label="  My Course"
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      About Us
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      // editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      //onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Upload Instructor Profile
                  </Typography>
                </div>
                <Form.File id="collegeBanner">
                  <Form.File.Input
                  // onChange={(e) => setBanner(e.target.files[0])}
                  />
                  <p style={{ color: "#6c757d", marginBottom: "0px" }}>
                    Recommended Size: 200px*120px
                  </p>
                </Form.File>
              </Grid>
            </Grid>
          </div>

          <div>
            <Button variant="contained" className={classes.btn}>
              Create Product
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default AllCourse;
