import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { apiCall } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";

export default function AddBannerDialog({
  open,
  handleClickOpen,
  handleClose,
  product,
  setProduct,
  addProduct,
  banner,
  setBanner,
  updateProduct,
  currentRow,
  edit,
  setCurrentRow,
  setEdit,
  status,
  setStatus,
  tempImage,
}) {

  const [dataProducts, setDataProducts] = useState([]);
  const getProducts = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/product_service/?list=true&is_active=1`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataProducts(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle id="form-dialog-title">{edit ? "Edit" : "Add"} Category</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates occasionally.</DialogContentText> */}
          <Autocomplete
            options={dataProducts}
            getOptionLabel={(item) => item.title}
            // style={{ width: "50%" }}
            onChange={(e, item) => {
              if (item) {
                setProduct(item);
              } else {
                setProduct(null);
              }
            }}
            value={product}
            renderInput={(params) => <TextField {...params} label="Select Product/Service" variant="outlined" />}
          />
          <br />
          <Grid item xs={12}>
            <div style={{ marginBottom: "10px" }}>
              <Typography variant="body2" style={{ fontWeight: "600" }}>
                Upload Banner
              </Typography>
            </div>
            <div stykle={{ marginBottom: 10 }}>
              {tempImage && !banner && (
                <>
                  <img src={tempImage} alt="" height="200px" />
                </>
              )}
            </div>
            <br />
            <Form.File id="collegeBanner">
              <Form.File.Input onChange={(e) => setBanner(e.target.files[0])} />
              {/* <p style={{ color: "#6c757d", marginBottom: "0px" }}>Recommended Size: 200px*120px</p> */}
            </Form.File>
          </Grid>
          <br />
          {/* <FormControl component="fieldset" style={{ marginTop: 20 }}>
            <FormLabel component="legend">Active/Inactive</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={status} onChange={handleChangeStatus} row>
              <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
              <FormControlLabel value="INACTIVE" control={<Radio />} label="Inactive" />
            </RadioGroup>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!product) {
                FailureAlert("Please Enter A  Product");
                return;
              }
              if (!banner && !tempImage) {
                FailureAlert("Please select a Banner");
                return;
              }
              if (edit) updateProduct(currentRow?.banner_id);
              else addProduct();

              handleClose();
            }}
            color="primary"
          >
            {edit ? "Edit" : "Add"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
