import axios from "axios";
import { praveshUrl,sandeshUrl } from "../constants";

const praveshAxiosGetReq = function (
  url,
  headers1 = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  }
) {
  return axios.get(`${praveshUrl}${url}`, headers1);
};
const praveshAxiosPostReq = function (
  url,
  data,
  headers1 = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  }
) {
  return axios.post(`${praveshUrl}${url}`, data, headers1);
};
const praveshAxiosPatchReq = function (
  url,
  data,
  headers1 = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  }
) {
  return axios.patch(`${praveshUrl}${url}`, data, headers1);
};

const praveshAxiosDeleteReq = function (
  url,
  headers1 = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  }
) {
  return axios.delete(`${praveshUrl}${url}`, headers1);
};
const sandeshAxiosGetReq = function (
  endPoint,
  reqConfigs = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  }
) {
  return axios.get(`${sandeshUrl}${endPoint}`,reqConfigs);
};


const sandeshAxiosPostReq = function (
  endPoint,
  data,
  reqConfigs = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  }
) {
  return axios.post(`${sandeshUrl}${endPoint}`, data, reqConfigs);
};

const sandeshAxiosPatchReq = function (
  endPoint,
  data,
  reqConfigs = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  }
) {
  return axios.patch(`${sandeshUrl}${endPoint}`,data, reqConfigs);
};



export {
  praveshAxiosGetReq,
  praveshAxiosPostReq,
  praveshAxiosPatchReq,
  praveshAxiosDeleteReq,
  sandeshAxiosGetReq,
  sandeshAxiosPostReq,
  sandeshAxiosPatchReq,

};
