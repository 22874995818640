import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import UseAxiosApi from "../../../Api/UseAxiosApi";
import {
  cellCSS,
  headerCSS,
  headerCSSNew,
  praveshUrl
} from "../../../constants";
import TableWithExport from "../../TableWithExport";

const useStyles = makeStyles((theme) => ({
  crossStyle: {
    width: "10px",
    height: "10px",
    cursor: "pointer",
  },
  crossSection: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  headingBox: {
    fontWeight: 600,
  },
  floatingLabelFocusStyle: {
    // color: "#7e7e7e",
    fontSize: "14px",
  },
  textfield: {
    maxHeight: ".3rem",
  },
  rowfield: {
    display: "flex",
    alignItems: "center",
  },

  addTemplatebtnSection: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  btnTemplate: {
    minHeight: "2rem",
    minWidth: "8rem",
  },
}));

const ViewOfferLetter = ({
  open,
  setOpen = () => {},
  data,
  setData = () => {},
  columns,
  title,
  currentJobAndCompanyId,
}) => {
  

  const handleClose = () => {
    setOpen(false);
  };

  const [offerLettersList, setOfferLettersList] = useState([]);

  const helper = (data) => {
    const mainData = [];
    data?.data?.forEach((item) => {
      let obj = { ...item };
      obj["uploaded_by_user_type"] = item?.action_history[0]?.user_type;
      if (item?.offer_status !== "PENDING") {
        const newActionHistory = [];
        for (let i = item?.action_history?.length - 1; i >= 0; i--) {
          if (item?.action_history[i]?.is_approval) {
            newActionHistory?.push(item?.action_history[i]);
            break;
          }
        }
        obj["action_by_name"] =
          newActionHistory[newActionHistory?.length - 1]?.name ?? "NA";
        obj["action_by"] =
          newActionHistory[newActionHistory?.length - 1]?.user_type ?? "NA";
      } else {
        obj["action_by_name"] = "NA";
        obj["action_by"] = "NA";
      }
      mainData?.push(obj);
    });
    setOfferLettersList(mainData);
  };

  const { offerLettersListLoading, offerLettersListError, apiCall } =
    UseAxiosApi({
      method: "get",
      mountingCallFlag: false,
      setResponse: helper,
    });

  const getOfferLetterList = () => {
    apiCall(
      null,
      true,
      `${praveshUrl}/api/common/offer_letter/?company_id=${currentJobAndCompanyId?.companyId}&job_id=${currentJobAndCompanyId?.jobId}`
    );
  };
  useEffect(() => {
    if (
      open &&
      currentJobAndCompanyId?.jobId &&
      currentJobAndCompanyId?.companyId
    ) {
      getOfferLetterList();
    }
  }, [open, currentJobAndCompanyId]);

  const handleClickClose = () => {
    setOfferLettersList([]);
    handleClose();
  };

  const {
    loading: actionloading,
    setActionError,
    apiCall: actionApiCall,
  } = UseAxiosApi({
    method: "patch",
    mountingCallFlag: false,
  });
  const actionHelper = () => {
    getOfferLetterList();
  };
  const handleAcceptReject = (type, offerLetterId) => {
    let payload = {};
    if (type === "ACCEPTED" || type === "REJECTED") {
      payload["offer_status"] = type;
      payload["source_page"] = "Prashasan Job Track";
      const url = `${praveshUrl}/api/common/offer_letter/${offerLetterId}/`;
      actionApiCall(JSON.stringify(payload), true, url, actionHelper);
    }
  };

  //   const [openOfferLetterUpdate, setOpenOfferLettersUpdate] = useState(false);
  //   const [offerLetterdataForUpdate, setOfferLetterdataForUpdate] =
  //     useState(null);
  //   const handleCloseOfferLetterUpdate = () => {
  //     setOpenOfferLettersUpdate(false);
  //   };
  //   const handleOpenOfferLetterUpdate = (data) => {
  //     // console.log("setOfferLetterdataForUpdate", data);
  //     const obj = {
  //       offerLetterId: data?.id,
  //       userId: data?.user_id,
  //       jobId: data?.job_id,
  //       companyId: data?.company_id,
  //       source: "Prashasan Job Track",
  //     };
  //     setOfferLetterdataForUpdate(obj);
  //     setOpenOfferLettersUpdate(true);
  //   };

  const offerLetterColumns = [
    {
      title: "Student Name",
      field: "name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Student College Name",
      field: "college_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Uploaded By",
      field: "uploaded_by_user_type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Person Uploaded",
      field: "uploaded_by",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "View",
      //   field: "name",
      width: "100%",
      headerStyle: headerCSSNew,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <>
          <Button
            variant="outline-info"
            size="sm"
            style={{
              fontSize: 13,
              minWidth: 100,
            }}
            onClick={() => {
              window.open(row?.offer_letter, "_blank");
            }}
          >
            View
          </Button>
        </>
      ),
    },

    {
      title: "Upload Time",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },

    // {
    //   title: "Status",
    //   field: "offer_status",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    // },
    // {
    //   title: "Action By",
    //   field: "name",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    // },
    {
      title: "Action",
      //   field: "name",
      width: "100%",
      headerStyle: headerCSSNew,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <>
          {row?.offer_status === "PENDING" ? (
            <div style={{ display: "flex" }}>
              <Button
                variant="outline-info"
                size="sm"
                style={{
                  fontSize: 13,
                  minWidth: 100,
                  marginLeft: 5,
                }}
                disabled={
                  row?.offer_status === "PENDING" && !actionloading
                    ? false
                    : true
                }
                onClick={() => {
                  handleAcceptReject("ACCEPTED", row?.id);
                }}
              >
                Accept
              </Button>
              <Button
                variant="outline-info"
                size="sm"
                disabled={
                  row?.offer_status === "PENDING" && !actionloading
                    ? false
                    : true
                }
                style={{
                  fontSize: 13,
                  minWidth: 100,
                  marginLeft: 5,
                }}
                onClick={() => {
                  handleAcceptReject("REJECTED", row?.id);
                }}
              >
                Reject
              </Button>
            </div>
          ) : (
            row?.offer_status
          )}
        </>
      ),
    },

    {
      title: "Action By",
      field: "action_by",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Action By Name",
      field: "action_by_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Update Time",
      field: "update_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
  ];

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div style={{ padding: "1rem" }}>
        <TableWithExport
          title={title}
          columns={offerLetterColumns}
          data={offerLettersList ?? []}
          fName={"Offers"}
          disableTableDownload={true}
        />
      </div>
      {/* <UploadOfferLetter
        open={openOfferLetterUpdate}
        handleClose={handleCloseOfferLetterUpdate}
        type="Update"
        data={offerLetterdataForUpdate}
      /> */}
    </Dialog>
  );
};

export default ViewOfferLetter;
