import React, {useState} from 'react';
import {useParams} from 'react-router';
import '../css/Login.css';
import { apiCall } from '../util/apiCall';
import background from '../assets/background-img.jpg';
import logo from '../assets/getwork_new.png';
import {Card} from 'react-bootstrap';
import {Form, Button} from 'react-bootstrap';
import { FailureAlert, SuccessAlert } from '../util/Notifications';


function SetNewPassword() {

    const[password, setPassword] = useState('');
    const[confirmPassword, setConfirmPassword] = useState('');
    const {uid, token} = useParams();

    const resetPassword = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                uid: uid,
                token: token,
                password: password
            })
        };
        
        apiCall('/api/email/reset', requestOptions)
          .then((res)=> {
              if(res.success) {  
                SuccessAlert(res.data.message)  
                window.location.pathname = '/login';
              }
              else {
                FailureAlert("Invalid Token to reset Password")
            } 
          })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(password===confirmPassword)
            resetPassword();
        else
            FailureAlert("Confirm Password doesn't match initial password...")    
    }

    return (
        <div className="login">
            <img 
                src={background}
                alt="Background"
            />
            <div className="login__body">

                    <Card className="login__body__left">
                        <h3>Welcome Admin!</h3><br />
                        <small>Please set new password for your account!</small>
                    </Card>

                <div className="login__body__right">
                    <div className="login__body__right__upper">
                        <img 
                            src={logo}
                            alt="GetWork Logo"
                            height='50vh'
                            width='45vw'
                        />
                    </div>
                    <div className="login__body__form"> 
                      <Form className="reset__form" onSubmit={(e) => handleSubmit(e)}>
                      <Form.Group controlId="formBasicPassword">
                                <Form.Control 
                                    name="password" 
                                    type="password" 
                                    placeholder="Password" 
                                    required 
                                    onChange={e=> {setPassword(e.target.value)}}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Control 
                                    name="confirmPassword" 
                                    type="password"
                                    placeholder="Confirm Password" 
                                    required 
                                    onChange={e=> {setConfirmPassword(e.target.value)}}
                                />
                            </Form.Group>
                            <Button 
                                variant="primary" 
                                type="submit" 
                            >
                                Set Password
                            </Button>
                        </Form>   
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetNewPassword;