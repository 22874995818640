import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { FormControl, FormGroup, FormLabel } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import JobApplicants from "./JobApplicants";
import { QuerySource } from "./QuerySource";
import UseAxiosApi from "../../../Api/UseAxiosApi";
//import JobApplicants from "../common/JobApplicants/JobApplicants";
//import CustomButtonCircularProgress from "../loader";
import { ReactComponent as UploadResume } from "../../../assets/svg/Resumefolder.svg";
import { niyuktiUrl, praveshUrl } from "../../../constants";
import { niyuktiAxiosGetReq } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import SendMail from "../../Student/Components/Dialog/SendMail";

function AllCandidates({
  sourceResultQuery = null,
  setShowSourceResult,
  sourceResultsList,
  setSourceResultsList,
  setValue,
  value,
  clear,
  from,
  payload,
  jobId,
  setSourceResultQuery,
}) {
  const [page, setPage] = useState(0);
  const [allCandidateCount, setAllCandidateCount] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [end, setEnd] = useState(false);

  //   const [loadingShow, setLoadingShow] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const helperGetCandidates = (data) => {
    setAllCandidateCount(data?.data?.count);
    const arr = [...sourceResultsList?.results, ...data?.data?.results];
    setSourceResultsList({ ...data?.data, results: arr });
    if (data?.data?.next) {
      setNextUrl(data?.data?.next);
    } else {
      setEnd(true);
    }
  };
  const { loading, error, apiCall } = UseAxiosApi({
    url: null,
    method: "post",
    mountingCallFlag: false,
    callBackFn: helperGetCandidates,
  });

  const getAllCandidates = () => {
    apiCall(JSON.stringify(payload), true, nextUrl);
  };

  const handleSingleSelect = (item, event) => {
    if (event.target.checked) {
      let arr = selectedCandidates?.length > 0 ? [...selectedCandidates] : [];
      arr.push(item);
      setSelectedCandidates(arr);
    } else {
      let filteredData = selectedCandidates?.filter(
        (item2) => item2?.user_id !== item?.user_id
      );
      setSelectedCandidates(filteredData);
    }
  };

  const generatePayload = (sourceQuery) => {
    let body2 = {};
    // if (sourceQuery?.payload?.job_segment_id?.length > 0)
    //   body2.job_segment_ids = sourceQuery?.payload?.job_segment_id;
    // if (sourceQuery?.payload?.job_role_id?.length > 0)
    //   body2.job_role_ids = sourceQuery?.payload?.job_role_id;

    if (sourceQuery?.payload?.location_ids?.length > 0)
      body2.city_ids = sourceQuery?.payload?.location_ids;
    if (sourceQuery?.payload?.experience_dict)
      body2.experience = sourceQuery?.payload?.experience_dict; /* {
        min: parseInt(sourceQuery?.payload?.experience[0]?.split("-")[0]),
        max: parseInt(
          sourceQuery?.payload?.experience[0]?.split("-")[1]?.split(" ")[0]
        ),
      }; */

    if (sourceQuery?.payload?.ctc_max && sourceQuery?.payload?.ctc_min)
      body2.ctc = {
        min: sourceQuery?.payload?.ctc_max,
        max: sourceQuery?.payload?.ctc_min,
      };

    if (sourceQuery?.payload?.skill?.length > 0) {
      let skillsIds = [];
      sourceQuery?.payload?.skill?.map((item) => {
        skillsIds?.push(item?.skill_id);
      });
      body2.skills = skillsIds;
    }

    return body2;
  };
  const generateQuery = (sourceFromJob) => {
    return {
      jobSegments: sourceFromJob?.job_segment && [
        { job_segment_name: sourceFromJob?.job_segment },
      ],
      jobRoles: sourceFromJob?.job_role && [
        { job_role_group: sourceFromJob?.job_role_group_name },
      ],
      expLevels: sourceFromJob?.experience && [
        { title: sourceFromJob?.experience },
      ],
      expCtc: sourceFromJob?.ctc,
      // expCtc: [
      //   parseInt(sourceFromJob?.ctc?.split("-")[0]?.split(" ")[0]),
      //   parseInt(sourceFromJob?.ctc?.split("-")[1]?.split(" ")[1]),
      // ],
      noticePeriods: sourceFromJob?.notice_period && [
        sourceFromJob?.notice_period,
      ],
      locations: sourceFromJob?.location && [{ city: sourceFromJob?.location }],
      eduLevels: sourceFromJob?.education_level && [
        { name: sourceFromJob?.education_level },
      ],
      degrees: sourceFromJob?.degree,
      skills: sourceFromJob?.skill,
      clgCategories: sourceFromJob?.college_category && [
        { category: sourceFromJob?.college_category },
      ],

      payload: sourceFromJob,
    };
  };

  useEffect(() => {
    setAllCandidateCount(sourceResultsList?.count);

    if (sourceResultsList?.next) {
      setNextUrl(sourceResultsList?.next);
    } else {
      setEnd(true);
    }
  }, []);

  const { loading: loadingJobListForJobId, apiCall: getJobListForJobId } =
    UseAxiosApi({
      url: `${praveshUrl}/api/admin/operations/candidate/source/`,
      method: "post",
      mountingCallFlag: false,
      setResponse: (data) => {
        setSourceResultsList(data?.data);
      },
    });

  const helper = (data) => {
    const sourceFromJob = data?.data?.results[0];
    const query = generateQuery(sourceFromJob);
    setSourceResultQuery(query);
    const payload = generatePayload(query);
    getJobListForJobId(JSON.stringify(payload), true);
  };

  const {
    loadingShow,
    errorlist,
    apiCall: apiCallJobInfoList,
  } = UseAxiosApi({
    method: "get",
    mountingCallFlag: false,
    setResponse: helper,
  });

  useEffect(() => {
    if (jobId && payload === null) {
      apiCallJobInfoList(
        null,
        true,
        `${niyuktiUrl}/job/admin/company/job/info/list/?job_id=${btoa(jobId)}`
      );
    }
  }, [jobId, payload]);

  return (
    <>
      <Box style={{ marginTop: 2 }}>
        <Box
          style={{
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            padding: 10,
            marginTop: 2,
          }}
        >
          <Grid container /* spacing={2} */>
            <Grid md={12}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <div>
                  <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
                    Source Query
                    {` (${sourceResultsList?.count ?? 0} Profiles Matches)`}
                  </Typography>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "8px",
                      fontSize: "15px",
                      height: "40px",
                      marginLeft: "20px",
                      boxShadow: "none",
                      minWidth: "170px",
                    }}
                    onClick={() => {
                      setValue(value);
                      setShowSourceResult(false);
                    }}
                  >
                    Edit Query
                  </Button>
                </div>
              </Box>
            </Grid>
            <QuerySource data={sourceResultQuery} from={from} jobId={jobId} />
          </Grid>
        </Box>
      </Box>

      <Box sx={{ mt: 50 }}>
        {/*   <JobApplicants /> */}
        <CandidatesList
          value={value}
          jobId={jobId}
          allCandidateCount={allCandidateCount}
          page={page}
          setPage={setPage}
          getAllCandidates={getAllCandidates}
          end={end}
          loading={loading}
          sourceResultsList={sourceResultsList}
          setSourceResultsList={setSourceResultsList}
          handleSingleSelect={handleSingleSelect}
          selectedCandidates={selectedCandidates}
          setSelectedCandidates={setSelectedCandidates}
          loadingJobListForJobId={loadingJobListForJobId}
          //   handleUnlockCandidate={handleUnlockCandidate}
          //   loadingUnlockSingle={loadingUnlockSingle}
        />
      </Box>
    </>
  );
}

export default AllCandidates;

const CandidatesList = ({
  value,
  allCandidateCount,
  sourceResultsList,
  setSourceResultsList,
  page,
  setPage,
  getAllCandidates,
  end,
  loading,
  selectedCandidates,
  setSelectedCandidates,
  handleSingleSelect,
  loadingJobListForJobId,
  jobId,
  //   handleUnlockCandidate,
  //   loadingUnlockSingle,
}) => {
  const loaderRef = useRef(null);
  // const [studentIds, setStudentIds] = useState(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && end !== true /* && isApiCall */) {
      // getAllColleges(1);

      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    if (page > 0 && allCandidateCount > 8 && end !== true) {
      getAllCandidates();
    }
  }, [page]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderRef.current) observer.observe(loaderRef.current);
  }, [handleObserver]);

  const theme = useTheme();

  const [selectedStudendsList, setSelectedStudentsList] = useState([]);
  const handleCheckChange = (studentId) => {
    setSelectedStudentsList((prevList) => {
      // Check if studentId exists in the list
      const index = prevList.indexOf(studentId);

      if (index !== -1) {
        // If exists, remove it by creating a new array without the studentId
        return prevList.filter((id) => id !== studentId);
      } else {
        // If not exists, add it by creating a new array with the studentId appended
        return [...prevList, studentId];
      }
    });
  };

  const handleSelectAllCheckChange = (event) => {
    if (event.target.checked) {
      setSelectedStudentsList(
        sourceResultsList?.results?.map((item) => item.user_id)
      );
    } else {
      setSelectedStudentsList([]);
    }
  };

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  useEffect(() => {
    const checkSelectAll = () => {
      const sourceUserIds =
        sourceResultsList?.results?.map((item) => item.user_id) || [];
      return sourceUserIds.every((userId) =>
        selectedStudendsList.includes(userId)
      );
    };
    setIsSelectAllChecked(checkSelectAll());
  }, [selectedStudendsList, sourceResultsList]);

  const [sendMailAll, setSendMailAll] = useState(false);

  const [openSendMailDialog, setOpenSendMailDialog] = useState(false);
  const handleCloseSendMail = () => {
    setOpenSendMailDialog(false);
  };

  const [selectedStudentId, setSelectedStudentId] = useState(null);

  const handleSubmit = () => {
    if (jobId && selectedStudendsList.length > 0) {
      // if (selectedJob) {
      // http://niyuktitest.getwork.org/job/admin/send_email_to_candidate/?job_id=1305&student_id=991
      niyuktiAxiosGetReq(
        `/job/admin/send_email_to_candidate/?job_id=${jobId}&student_id=${selectedStudendsList.join(
          ","
        )}`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert("Mail Sent Successfully!!");
          } else {
            FailureAlert(res.data.error);
          }
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
  };

  return (
    <>
      <Box
        style={{
          // boxShadow: "0px 0px 4px 2px #0000001A",
          //    borderRadius: "8px",
          paddingTop: 20,
          // overflow: "scroll",
          // overflowX: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <Checkbox
                    checked={isSelectAllChecked}
                    color="primary"
                    onChange={handleSelectAllCheckChange}
                    name="select all"
                  />
                }
                label="Select All"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "8px",
              fontSize: "15px",
              width: "180px",
              marginRight: 5,
            }}
            onClick={(event) => {
              if (value === 1) handleSubmit();
              if (value === 0 || value === 2) setOpenSendMailDialog(true);
              else setOpenSendMailDialog(false);
              setSendMailAll(true);
            }}
            disabled={!selectedStudendsList.length}
          >
            <span>Send Mail All</span>
          </Button>
        </div>
        {sourceResultsList?.results?.length > 0 ? (
          sourceResultsList?.results?.map((item) => (
            <JobApplicants
              handleCloseSendMail={handleCloseSendMail}
              openSendMailDialog={openSendMailDialog}
              sendMailAll={sendMailAll}
              setOpenSendMailDialog={setOpenSendMailDialog}
              selectedStudendsList={selectedStudendsList}
              setSendMailAll={setSendMailAll}
              handleCheckChange={handleCheckChange}
              value={value}
              item={item}
              handleSingleSelect={handleSingleSelect}
              selectedCandidates={selectedCandidates}
              setSelectedCandidates={setSelectedCandidates}
              setSourceResultsList={setSourceResultsList}
              sourceResultsList={sourceResultsList}
              //   handleUnlockCandidate={handleUnlockCandidate}
              //   loadingUnlockSingle={loadingUnlockSingle}
              selectedStudentId={selectedStudentId}
              setSelectedStudentId={setSelectedStudentId}
              jobFromSourceJob={jobId}
              // studentIds={studentIds}
              // setStudentIds={setStudentIds}
            />
          ))
        ) : loading || loadingJobListForJobId ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto 30px auto",
            }}
          >
            <CircularProgress /* className={classes.progress} */ />
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UploadResume fill={theme.palette.primary.main} />

            <Typography sx={{ fontSize: "20px", fontWeight: "600", mb: 2 }}>
              No Results Found
            </Typography>

            {/* <Button
                color="primary"
                variant="contained"
                sx={{
                  boxShadow: "none",
               borderRadius: "8px",
                  pl: 3,
                  pr: 3,
                  mt: 4,
                }}
              >
                Let us help you to find ideal talent
              </Button> */}
            {/* <SourceSubmitRequirement
                buttonText={"Let us help you to find ideal talent"}
              /> */}
          </Box>
        )}
        {openSendMailDialog && 
          <SendMail
            // value={value}
            open={openSendMailDialog}
            handleClose={() => { setOpenSendMailDialog(false);}}
            studentIds={sendMailAll ? selectedStudendsList : selectedStudentId}
            // studentIds={studentIds}
            // jobFromSourceJob={jobFromSourceJob}
            // prevStudentIdsRef={prevStudentIdsRef}
          />
          }

        <div
          ref={loaderRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {allCandidateCount > 10 && end !== true ? (
            <CircularProgress />
          ) : (
            allCandidateCount > 10 && (
              <div
                style={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <img
                src={noData}
                alt=""
                style={{ height: "250px", marginTop: "25px" }}
              /> */}
                <Typography
                  color="primary"
                  align="center" /* className={classes.labelSize} */
                >
                  {" "}
                  No More Data. Please Change the Query to get data
                </Typography>
              </div>
            )
          )}
        </div>
      </Box>
    </>
  );
};
