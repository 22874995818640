import React from 'react'
import gif from "../../../assets/loopingcircles-3.gif";
import { gifStyling } from '../../../constants';

const GWLogoLoader = () => {


    return (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
    )
}

export default GWLogoLoader