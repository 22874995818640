import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { gifStyling, requestPraveshOptions } from '../../constants';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Form, Col, Table, Button} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {TabPanel} from '../../util/TabPanel';
import {AppBar, Tabs, Tab} from '@material-ui/core';

    const token = localStorage.getItem('token');
  
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
          id: `wrapped-tab-${index}`,
          'aria-controls': `wrapped-tabpanel-${index}`,
        };
    } 

function UpdateBlog() {

    const [loading, setLoading] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [count, setCount] = useState(0);

    // const [file, setFile] = useState(null);

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [iconImage, setIconImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [body, setBody] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');


    const onEditorStateChange = (s) => {
        setEditorState(s)
        setBody(draftToHtml(convertToRaw(s.getCurrentContent())))
    }

    // Fetching existing Blogs
    useEffect(()=> {
        setLoading(true);
            apiCall(`/api/admin/blog/all`, requestPraveshOptions)
            .then((res)=> {
                if(res.success) { setBlogs(res.data.results) }
                else { FailureAlert(res.error) }
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const deleteBlog = (e, blogId) => {
        const requestOptionsDelete = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        };
        // FailureAlert(`Blog Deleted, ${blogId}`)
        apiCall(`/api/admin/blog/${blogId}/`, requestOptionsDelete)
            .then((res) => {
                if(res.success) {
                    SuccessAlert(res.data.message);
                    setCount(count+1);
                }
                else {
                    FailureAlert(res.error);
                }
            })
    }

    const addBlog = (e) => {
        if(!status) { FailureAlert('Choose Status in order to continue...');return; }
        e.preventDefault();
        setLoading(true);
        const slug = title.split(' ').join('-').toLowerCase();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('slug', slug);
        formData.append('author', JSON.parse(localStorage.getItem('user-data')).id);
        formData.append('status', status);
        youtubeLink && formData.append('youtube_link', youtubeLink);
        iconImage && formData.append('icon_image', iconImage);
        mainImage && formData.append('main_image', mainImage);
        formData.append('body', body);
        const requestOptionsPost = {
            method: 'POST',
            headers: { 'Authorization': `Token ${token}` },
            body: formData
        };
        apiCall('/api/admin/blog/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else FailureAlert(res.error);
        })
        .then(setEditorState(EditorState.createEmpty()))
        .then(setTitle(''))
        .then(setStatus(''))
        .then(setYoutubeLink(''))
        .then(setBody(''))
        .then(setIconImage(null))
        .then(setMainImage(null))
        .then(setLoading(false))
    }

    return (
        <div className="update__blog">
            {
                blogs
                ?
                !loading
                ?
                <div className="update__blog__section" style={{marginLeft:'2%', width:'96%'}}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Existing Blogs" {...a11yProps(0)} />
                            <Tab label="Add a New Blog" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    {/* Existing Blogs */}
                    <TabPanel value={value} index={0} dir={theme.direction}> 
                    {
                        blogs.length>0
                        ?
                        <Table striped bordered hover size="sm">
                            <tbody>
                                    <tr> <th>Blog Title</th> </tr>
                                {
                                    blogs.map((blog, key) => {
                                    return (
                                        <tr key={key}>
                                            <td style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                                <span>{blog.title}</span>
                                                <div>
                                                    <Button
                                                        style={{marginLeft: '3px'}}
                                                        variant="primary"
                                                        onClick={() => window.open(`/admin/view-blog/${blog.id}`)}
                                                    >View
                                                    </Button>
                                                    <Button
                                                        style={{marginLeft: '3px'}}
                                                        variant="danger"
                                                        onClick={e=>deleteBlog(e, blog.id)}
                                                    >Delete
                                                    </Button>    
                                                </div>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </Table>
                        :
                        <p style={{paddingTop: '10%', paddingLeft: '40%'}}>
                            Nothing to Display
                        </p>
                    }
                              
                    </TabPanel>

                    {/* Adding a new Blog */}
                    <TabPanel value={value} index={1} dir={theme.direction} style={{marginLeft:'3%', width:'94%'}}>                           
                        <Form onSubmit={e=>addBlog(e)}>
                            {/* Blog Title and Status */}
                            <Form.Row>
                                <Form.Group as={Col} controlId="title">
                                    <Form.Label>Blog Title*</Form.Label>
                                    <Form.Control 
                                        name="title" type="text" placeholder="Enter Title*" required 
                                        onChange = {e=> {setTitle(e.target.value)}} 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="status">
                                    <Form.Label>Blog Status*</Form.Label>
                                    <Form.Control as="select" onChange = {e=> {setStatus(e.target.value)}} >
                                        <option value="">--select--</option>
                                        <option value="DRAFT">Draft</option>
                                        <option value="PUBLISHED">Published</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            {/* Youtube Link (OPTIONAL) */}
                            <Form.Group controlId="youtubeLink">
                                <Form.Label>Youtube Link (if any)</Form.Label>
                                <Form.Control 
                                    name="youtubeLink" type="text" placeholder="Paste Youtube Video Link here.." 
                                    onChange = {e=> {setYoutubeLink(e.target.value)}} 
                                />
                            </Form.Group>
                            <Form.Label>Blog Body*</Form.Label>
                            <div className="editor">
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </div>
                            {/* ICON Image */}
                            <Form.File id="formcheck-api-custom" custom style={{marginTop:'1%', marginBottom:'2%'}}>
                                <Form.File.Input onChange={e=>setIconImage(e.target.files[0])} isValid />
                                <Form.File.Label data-browse="Upload Icon Image">
                                    (ICON IMAGE) Click to choose file from local directory
                                </Form.File.Label>
                                {
                                    iconImage ? 
                                    <Form.Control.Feedback type="valid">{iconImage.name}</Form.Control.Feedback> 
                                    : null
                                }
                            </Form.File>
                            {/* MAIN Image */}
                            <Form.File id="formcheck-api-custom" custom style={{marginTop:'1%', marginBottom:'2%'}}>
                                <Form.File.Input onChange={e=>setMainImage(e.target.files[0])} isValid />
                                <Form.File.Label data-browse="Upload Main Image">
                                    (MAIN IMAGE) Click to choose file from local directory
                                </Form.File.Label>
                                {
                                    mainImage ? 
                                    <Form.Control.Feedback type="valid">{mainImage.name}</Form.Control.Feedback> 
                                    : null
                                }
                            </Form.File>
                            <Button type='submit'>Upload</Button>            
                        </Form>                
                    </TabPanel>
                </div>
                :
                <img 
                    src={gif} 
                    alt="Getwork Loading Gif..."
                    style={gifStyling}
                />
                :
                null
            }
        </div>
    )
}

export default UpdateBlog;