import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import AddWorkExperienceDialog from "./AddWorkExperienceDialog";
import SaveWorkExperience from "./SaveWorkExperience";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
}));

export default function WorkExperience({companiesList, workExperiences, handleAddWorkEx, handlePatchWorkEx, handleDeleteWorkEx}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tempWorkEx, setTempWorkEx] = useState([]);
  const [editWorkEx, setEditWorkEx] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [isCompanyNotListed, setIsCompanyNotListed] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setOpen(false);
    setEditWorkEx({});
    setIsCompanyNotListed(false);
  };

  useEffect(() => {
    if(workExperiences)
    setTempWorkEx(workExperiences);
  }, [workExperiences])

  const saveWorkEx = () => {
    isEdit ? handlePatchWorkEx(editWorkEx, editWorkEx?.id, editIndex, handleClose) : handleAddWorkEx(editWorkEx, handleClose);
  }

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Work Experience</Typography>
        <br />
        { tempWorkEx && tempWorkEx.map((exp, index) => {
          return(
        <SaveWorkExperience experience={exp} index={index} setEditWorkEx={setEditWorkEx} handleDelete={handleDeleteWorkEx} handleClickOpen={handleClickOpen} setIsEdit={setIsEdit} setEditIndex={setEditIndex}/> 
        )
      })
        }
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            color="primary"
            onClick={handleClickOpen}
          >
            Add Work Experience
          </Button>

        </div>
      </div> 
      <AddWorkExperienceDialog open={open} handleClose={handleClose} companiesList={companiesList} editWorkEx={editWorkEx} setEditWorkEx={setEditWorkEx} saveWorkEx={saveWorkEx} isCompanyNotListed={isCompanyNotListed} setIsCompanyNotListed={setIsCompanyNotListed} />
    </>
  );
}
