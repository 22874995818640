
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { generateCsvNew } from "../../../util/generateCsv";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },


}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        boxShadow: "none",
        // "&:nth-of-type(odd)": {
        //   backgroundColor: theme.palette.action.hover,
        // },

    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});


const History = ({ data, setGeneratedLink, setPassCode, setOpenShare, setIsCopy, fromStudent = false }) => {

    const classes = useStyles();

    const truncateLarge = (input) => (input && input?.length > 5 ? `${input.substring(0, 30)}...` : input);

    const checkActions = (obj) => {
        var arr = [];
        var str = "";

        if (fromStudent) {
            if (obj?.track === true) arr.push("Track");
        }
        else {

            if (obj?.track === true) arr.push("Track");
        };

        if (obj?.download === true) arr.push("Download");
        var str = generateCsvNew(arr);
        return str;
    };

    const checkJobRounds = (obj) => {
        var arr = [];
        var str = "";
        
        obj?.forEach(item => {
            arr.push(item?.round_name);
        })

        var str = generateCsvNew(arr);
        return str;
    };

    // const alert = useAlert();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [shareSuccessData, setShareSuccessData] = useState({
        link: null,
        passcode: null,
    });

    const capitalizeFirstLetter = (newArr) => {
        var new_arr = [];
        for (let day of newArr) {
            day = day?.charAt(0).toUpperCase() + day?.substr(1);
            new_arr.push(day);
        }
        return new_arr;
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell>{'Job Link'}</StyledTableCell>
                            <StyledTableCell align="left">Passcode</StyledTableCell> */}
                            <StyledTableCell align="center">Email</StyledTableCell>
                            <StyledTableCell align="center">Job Rounds</StyledTableCell>
                            <StyledTableCell align="center">Table Columns</StyledTableCell>
                            <StyledTableCell align="center">Actions</StyledTableCell>
                            <StyledTableCell align="center">Shared By</StyledTableCell>
                            <StyledTableCell align="center"> Copy</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <StyledTableRow key={row.job_link}>
                                {/* <Tooltip title={row.job_link} placement="right">
                                    <StyledTableCell component="th"
                                        style={{ borderRight: "1px solid #D3D3D3" }}
                                        scope="row">
                                        {truncateLarge(row.job_link)}
                                    </StyledTableCell>
                                </Tooltip>
                                <StyledTableCell align="center"
                                    style={{ borderRight: "1px solid #D3D3D3" }}
                                >{row?.Passcode}

                                </StyledTableCell> */}

                                <StyledTableCell align="center"
                                    style={{ borderRight: "1px solid #D3D3D3" }}
                                >{generateCsvNew(row?.action_performed?.email) ? generateCsvNew(row?.action_performed?.email) : "NA" }

                                </StyledTableCell>

                                <StyledTableCell align="center"
                                    style={{ borderRight: "1px solid #D3D3D3" }}
                                >{checkJobRounds(row?.action_performed?.job_rounds) ? checkJobRounds(row?.action_performed?.job_rounds) : "NA"}
                                </StyledTableCell>

                                <StyledTableCell align="center"
                                    style={{ borderRight: "1px solid #D3D3D3" }}
                                >{generateCsvNew(row?.action_performed?.table_columns) ? generateCsvNew(row?.action_performed?.table_columns) : "NA" }
                                </StyledTableCell>

                                <StyledTableCell align="center"
                                    style={{ borderRight: "1px solid #D3D3D3" }}
                                >{checkActions(
                                    row?.action_performed?.authority)}
                                </StyledTableCell>
                                <StyledTableCell align="center"
                                    style={{ borderRight: "1px solid #D3D3D3" }}
                                >{row?.shared_by}
                                </StyledTableCell>

                                {fromStudent ? (
                                    
                                        <StyledTableCell
                                            align="center"

                                        >
                                            {/* Copy */}
                                            <Typography
                                                // variant="h6"
                                                color="primary"
                                                style={{
                                                    marginLeft: "-10px",
                                                    fontSize: "12px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => {
                                                    setPassCode(row?.Passcode);
                                                    setGeneratedLink(row?.job_link);
                                                    setIsCopy(true);
                                                    setOpenShare(true);
                                                }}
                                            >
                                                Copy
                                            </Typography>

                                        </StyledTableCell>
                                )
                                    : null
                                }

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default History;