import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Autocomplete } from '@material-ui/lab';
import { apiCall, praveshAxiosPostReq } from '../../util/apiCall';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import { praveshUrl, requestPraveshOptions } from '../../constants';
import { Button, Form } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { headerCSS, cellCSS} from '../../constants';
import TableWithExport from "../TableWithExport";
import { Switch } from "@material-ui/core";
import axios from "axios";


function UserManagement({ isCompany }) {

    const [orgId, setOrgId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [colleges, setColleges] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [emailData,setEmailData] = useState([]);

    // Fetch all Companies Dropdown
    useEffect(() => {
        isCompany
            ?
            apiCall('/api/admin/company/get/', requestPraveshOptions)
                .then((res) => {
                    if (res.success) {
                        setCompanies(res.data)
                    }
                    else {
                        FailureAlert(res.error)
                    }
                })
            :
            //fetch colleges dropdown
            apiCall('/api/admin/college/get/', requestPraveshOptions)
                .then((res) => {
                    if (res.success) {
                        let collegeNames = {}
                        for (var i = 0; i < res.data.length; i++) {
                            let obj = res.data[i]
                            collegeNames[obj.college_id] = obj.college_name;
                        }
                        setColleges(res.data)
                        localStorage.setItem('colleges', JSON.stringify(collegeNames));
                    }
                    else {
                        FailureAlert(res.error)
                    }
                })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let user = isCompany ? "Company" : "College"

    const getManagementData = () => {
        apiCall(`/api/admin/main_user/user_management/?main_user=${user}&id=${orgId}`, requestPraveshOptions)
            .then(json => {
                if (json.success) {
                    let temp = []
                    for (let i = 0; i < json.data.length; i++) {
                        let obj = {
                            name: json.data[i].name || '',
                            email: json.data[i].email || '' ,
                            designation: json.data[i].designation || '',
                            phone: json.data[i].phone || '' ,
                            is_active: json.data[i].is_active ? "YES" : "NO",
                            is_invited: json.data[i].is_invited ? "YES" : "NO",
                            is_verified: json.data[i].is_verified ? "YES" : "NO",
                            is_default_user: json.data[i].is_default_user ,
                        }
                        temp.push(obj);
                    }

                    setOrgData(temp)
                }
                else {
                    FailureAlert(json.error)
                }
                // setLoading(false)
            })
    }

    useEffect(() => {
        if(orgId){
            getManagementData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId])

    const handleClick = useCallback((payload)=>{
     praveshAxiosPostReq("/api/admin/default_user/",payload)
     .then(res=>{
        if(res?.data?.success){
            setOrgData(prevData =>
                prevData.map(row => (row.email === payload.email ? { ...row, is_default_user:payload?.is_default_user } : row))
              );
            SuccessAlert(res?.data?.data?.message);
        } 
        else FailureAlert(res?.data?.error)
     }).catch(err=>{
         FailureAlert("Something went wrong")
     })
    },[])

    const getChecked = (mail) =>{
        const res = emailData?.filter(item=>item?.email === mail);
        return mail[0]?.is_default_user;
    }

    const columns = [
        { title: "Name", field: "name", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Email Id", field: "email", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Phone Number", field: "phone", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Designation", field: "designation",headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Is Active", field: "is_active",headerStyle: headerCSS, cellStyle: cellCSS, searchable: false },
        { title: "Is Verified", field: "is_verified",headerStyle: headerCSS, cellStyle: cellCSS, searchable: false },
        { title: "Default User", field: "is_default_user",headerStyle: headerCSS, cellStyle: cellCSS, searchable: false,render: (row)=>{
            return (
  <Switch
  checked={row.is_default_user}
  onChange={(e,newValue)=>{
   const payload={
    company_id:orgId,
    email:row?.email,
    is_default_user:newValue
   }
  handleClick(payload)
  }}
  />)
    } },

    ]

    return (
        isCompany
            ?
            <div className="post__job">
                <h3 style={{ textAlign: 'center' }}> <i>Company User Management</i> </h3>
                <hr /> <br />
                <Form className="post__job__form" style={{ display: 'flex', flexDirection: 'row' }} >
                    <Autocomplete
                        id="select-company"
                        options={companies}
                        getOptionLabel={(item) => item.company_name}
                        style={{ width: '94%', margin: '1% 3% 2% 3%' }}
                        onChange={(e, item) => item ? setOrgId(item.company_id) : setOrgId(null)}
                        renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                    />
                </Form>
                {
                    orgId
                        ?
                        <div>
                            <TableWithExport
                            title = "Company User Management"
                            data = {orgData}
                            columns = {columns}
                            fName = "abcd"
                            />
                        </div>
                        :
                        null
                        }
            </div>
            :
            <div className="edit__college__details">

                <h3 style={{ textAlign: 'center' }}> <i>College User Management</i> </h3>
                <hr /> <br />
                <Form className="edit__college__details__form" >
                    <Autocomplete
                        id="select-user"
                        options={colleges}
                        getOptionLabel={(item) => item.college_name}
                        style={{ width: '94%', margin: '1% 3% 2% 3%' }}
                        onChange={(e, item) => item ? setOrgId(item.college_id) : setOrgId(null)}
                        renderInput={(params) => <TextField {...params} label="Select College" variant="outlined" />}
                    />
                </Form>
                {
                    orgId
                        ?
                        <div>
                            <TableWithExport
                            title = "College User Management "
                            data = {orgData}
                            columns = {columns}
                            />
                        </div>
                        :
                        null
                }
            </div>
    )
}
export default UserManagement;