import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

function Location() {

    const [data, setData] = useState([]);
    // const [count, setCount] = useState(0);
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stateId, setStateId] = useState(null);
    const [cityName, setCityName] = useState('');
    const [newAdded, setNewAdded] = useState([]);

    // State Finding API
    useEffect(() => {
        apiCall(`/api/location/state/?search=1`, requestPraveshOptions)
        .then((res)=> { setStates(res) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setLoading(true);
        apiCall('/api/location/city/?verbose=required', requestPraveshOptions)
          .then(data => {
                let tmpArray = []
                for (var i = 0; i < data.length; i++) {
                    let obj = {
                        city_id:    parseInt(data[i].city_id),
                        city_name:  data[i].city,
                        state_name: data[i].state,
                        latitude:   data[i].latitude ? parseFloat(data[i].latitude) : '',
                        longitude:  data[i].longitude ? parseFloat(data[i].longitude) : ''
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
           })
    }, []);

    const columns = [
        { title: "City ID",    field: "city_id",      headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never', },
        { title: "State",      field: "state_name",   headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never', },
        { title: "City",       field: "city_name",    headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Latitude",   field: "latitude",     headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Longitude",  field: "longitude",    headerStyle: headerCSS, cellStyle: cellCSS },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ city: newData.city_name, latitude: newData.latitude, longitude: newData.longitude})
        };
        apiCall(`/api/admin/location/${newData.city_id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
              SuccessAlert(res.data.message);
              SuccessAlert('Actual Changes will be reflected after 15 mins since the data is cached on disk...');
              // setCount(count+1);
            }  
            else FailureAlert(res.error);
        })
    }

    const addCity = (e) => {
        e.preventDefault();
        if(!stateId) { FailureAlert('Select State...'); return; }
        const requestOptionsPost = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ 
              city: cityName, 
              state: stateId,  
            })
        };
        apiCall(`/api/admin/location/`, requestOptionsPost)
        .then((res) => {
            if(res.success) {
              SuccessAlert(res.data.message);
              let temp = [...newAdded]
              temp.push(res.data.value)
              setNewAdded(temp)
              SuccessAlert('Actual Changes will be reflected after 15 mins since the data is cached on disk...');
              setStateId(null); setCityName('');
              window.document.getElementById('add-select-state').value='';
              window.document.getElementById('add-city-name').value='';
            }  
            else FailureAlert(res.error);
        })
    }

    return (
        <div className="location">
            <Form className="add__city" onSubmit={e=>addCity(e)} >
                <Form.Row style={{margin: '1% 3% 0% 3%'}}>
                  <Form.Group as={Col}>
                    <Autocomplete
                        id="add-select-state"
                        options={states}
                        getOptionLabel={(item) => item.state}
                        onChange={(e, item)=>item ? setStateId(item.state_id) : setStateId(null)}
                        renderInput={(params) => <TextField {...params} label="Select State" variant="outlined" />}
                    />
                  </Form.Group>
                  <Form.Group as={Col} style={{marginRight:'0px'}}>
                    <TextField 
                      label="Enter City Name"
                      variant="outlined"
                      id="add-city-name"
                      style={{width:'inherit'}}
                      onChange={e=>setCityName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Button 
                  type='onSubmit'
                  style={{display:'block', margin:'auto', marginBottom:'8px'}}  
                 >
                 Add City
                 </Button>
            </Form>
            {
              newAdded.length > 0
              ?
              <>
                <h6>Recently Added Cities:</h6>
                <div className="row my-2" style={{marginLeft:'8px'}}>
                  {newAdded.map( (item, ind) => (
                      <span 
                        className="badge skill-chip mx-2" key={ind} 
                        style={{fontSize: '14px'}}
                      >
                        ID: {item.city_id}, City: {item.city}, State: {item.state}
                      </span>
                  ))}
                </div>
              </>
              :
              null
            }
            {
              !loading 
              ? 
              <TableWithExport 
                title = "Location"
                data = {data}
                columns = {columns}
                fName = "Selected Locations"
                editableObject = {edit}
              />
              :
              <img 
                src={gif} 
                alt="Getwork Loading Gif..."
                style={gifStyling}
              />
            }
        </div>
    )
}

export default Location;