import React, { useState, useEffect } from 'react';
import { apiSanchalanCall } from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import { Form, Col, Button } from 'react-bootstrap';
// import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function AccessControls() {

    const [data, setData] = useState([]);
    const [groupName, setGroupName] = useState(null);
    const [groupKey, setGroupKey] = useState(null);
    const [name, setName] = useState(null);
    const [key, setKey] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiSanchalanCall('/api/user_roles/access_controls/', requestPraveshOptions)
            .then(json => {
                if (json.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            id: json.data[i].id,
                            access_control_id: json.data[i].access_control_id,
                            access_control_name: json.data[i].access_control_name,
                            access_control_key: json.data[i].access_control_key,
                            access_control_group_name: json.data[i].access_control_group_name,
                            access_control_group_key: json.data[i].access_control_group_key,
                        }
                        tmpArray.push(obj)
                    }
                    setLoading(false);
                    setData(tmpArray);
                }
                else { FailureAlert(json.error) }
                setLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "ID", field: "access_control_id", headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        {
            title: "Name", field: "access_control_name", headerStyle: headerCSS, cellStyle: cellCSS,
            //   editComponent: props => (
            //     <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
            //         <MenuItem value="Yes">Yes</MenuItem>
            //         <MenuItem value="No">No</MenuItem>
            //     </Select>
            //   )
        },
        {
            title: "Key", field: "access_control_key", headerStyle: headerCSS, cellStyle: cellCSS, searchable: false,
            //   editComponent: props => (
            //     <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
            //         <MenuItem value="Yes">Yes</MenuItem>
            //         <MenuItem value="No">No</MenuItem>
            //     </Select>
            //   )
        },
        { title: "Group_Name", field: "access_control_group_name", headerStyle: headerCSS, cellStyle: cellCSS },
        {
            title: "Group_Key", field: "access_control_group_key", headerStyle: headerCSS, cellStyle: cellCSS, searchable: false,
        },
        {
            headerStyle: headerCSS, cellStyle: cellCSS, export: false,
            render: row =>
                <div>
                    <Button variant="outline-danger" size="sm" onClick={e => deleteAccessControl(e, row.id)}>Delete</Button>
                </div>
        },
    ];

    const requestOptionsPost = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            access_control_name: name,
            access_control_key: key,
            access_control_group_name: groupName,
            access_control_group_key: groupKey,
        })
    };

    const edit = {
        onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    updateRow(newData, oldData);
                    setData([...dataUpdate]);
                    resolve();
                }, 1000)
            })
    }

    const updateRow = (newData, oldData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({
                ...(oldData.access_control_name !== newData.access_control_name &&
                    { access_control_name: newData.access_control_name }
                ),
                ...(oldData.access_control_key !== newData.access_control_key &&
                    { access_control_key: newData.access_control_key }
                ),
                ...(oldData.access_control_group_name !== newData.access_control_group_name &&
                    { access_control_group_name: newData.access_control_group_name }
                ),
                ...(oldData.access_control_group_key !== newData.access_control_group_key &&
                    { access_control_group_key: newData.access_control_group_key }
                ),
            })
        };
        apiSanchalanCall(`/api/user_roles/access_controls/${oldData.id}`, requestOptionsPatch)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data?.message || "Successfully Updated");
                    setInterval(function () {
                        window.location.reload();
                    }, 1000);
                }
                else FailureAlert(res.error);
            })
    }

    const addNewSkill = (e) => {
        e.preventDefault();
        apiSanchalanCall('/api/user_roles/access_controls/', requestOptionsPost)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data?.message || "Successfully created");
                    setInterval(function () {
                        window.location.reload();
                    }, 1000);
                }
                else FailureAlert(res.error);
            })
        e.target.reset();
    }

    const deleteAccessControl = (e, accessControlId) => {
        apiSanchalanCall(`/api/user_roles/access_controls/${accessControlId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data?.message);
                    setInterval(function () {
                        window.location.reload();
                    }, 1000);
                }
                else FailureAlert(res.error);
            })
    }


    return (
        !loading
            ?
            <div className="education__skils">
                <div className="education__skils__form">
                    <h4>Access Controls</h4>
                    <Form onSubmit={e => addNewSkill(e)}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="accessControlName">
                                <Form.Label>Name*</Form.Label>
                                <Form.Control
                                    name="accessControlName"
                                    type="text"
                                    placeholder="Enter name"
                                    onChange={e => { setName(e.target.value) }}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="accessControlKey">
                                <Form.Label>Key*</Form.Label>
                                <Form.Control
                                    name="accessControlKey"
                                    type="text"
                                    placeholder="Enter key"
                                    onChange={e => { setKey(e.target.value) }}
                                    required
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="accessControlGroupName">
                                <Form.Label>Group name*</Form.Label>
                                <Form.Control
                                    name="accessControlGroupName"
                                    type="text"
                                    placeholder="Enter group name"
                                    onChange={e => { setGroupName(e.target.value) }}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="accessControlGroupKey">
                                <Form.Label>Group Key*</Form.Label>
                                <Form.Control
                                    name="accessControlGroupKey"
                                    type="text"
                                    placeholder="Enter group key"
                                    onChange={e => { setGroupKey(e.target.value) }}
                                    required
                                />
                            </Form.Group>
                        </Form.Row>

                        <Button type="submit" variant="info">Add Access Control</Button>

                    </Form>
                </div>
                <TableWithExport
                    title="Access Controls"
                    data={data?.sort((a, b) => b.access_control_id - a.access_control_id)}
                    columns={columns}
                    fName="Selected Access Controls"
                    editableObject={edit}
                />
            </div>
            :
            <img
                src={gif}
                alt="Getwork Loading Gif..."
                style={gifStyling}
            />
    )
}

export default AccessControls;