import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }
}));

export default function Achievements({achievements, postAchievements}) {

  const [tempAchievements, setTempAchievements] = useState([]);
  const [addedAchievement, setAddedAchievement] = useState("");
  const classes = useStyles();

  const handleDelete = (index) => {
    setTempAchievements((tempAchievements) =>
    tempAchievements.filter((_, achievementIndex) => achievementIndex !== index)
    );
  };

  useEffect(() => {
    setTempAchievements(achievements ? achievements : []);
  }, [achievements])

  const addAchievement = () => {
    setTempAchievements([...tempAchievements, addedAchievement]);
    setAddedAchievement("");
  }

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Achievements</Typography>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={10} style={{ display: "grid" }}>
            <TextField
              id="outlined-basic"
              label="Add Achievements"
              size="small"
              variant="outlined"
              onChange={(e) => {setAddedAchievement(e.target.value)}}
              value={addedAchievement}
            />
          </Grid>
          <Grid item xs={2} style={{ display: "grid" }}>
            <Button variant="contained" disabled={addedAchievement ? false : true} color="primary" onClick={() => {addAchievement()}}>
              Add
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className={classes.root}>
      
      <div style={{ marginTop: "7px" }}>
            <ul style={{ marginLeft: "-22px", color: "#000" }}>
        {tempAchievements && Array.isArray(tempAchievements) && tempAchievements.map((data, index) => {
          return (
            <li>
              <Grid container spacing={2} className={classes.div}>
              <Grid item xs={10} >
                  <Typography
                    variant="body2"
                    key={index}
                    style={{
                      color: "#000",
                    }}
                  >
                    {data}
                  </Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <Button  onClick={() => {
                      handleDelete(index);
                      }}>
                        <DeleteIcon />
                    </Button>
                  </Grid>
                  </Grid>
            </li>
          );
        })}
         </ul>
            </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button color="primary" variant="contained" disabled={tempAchievements && tempAchievements.length > 0  ? false : true} onClick={() => {postAchievements(tempAchievements)}}>
          Save
        </Button>
      </div>
    </div>
  );
}

