import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Tab,
  Tabs,
  AppBar,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import DashboardCard from "./DashboardCard";
import { TeamLeadAllData } from "./rowsData";
import TeamLaadSection from "./TeamLaadSection";
import FilterDialog from "./TeamLead/FilterDialog";
import TableWithExport from "../TableWithExportNew";
import { teamLeadColumns } from "./AllColumn";
import ErrorSection from "./ErrorSection";
import ReportHeading from "./ReportHeading";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5F5",
    width: "100%",
  },
}));

function RecruitersReportTabSection() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const [valueItem, setValueItem] = useState(1);
  function handleChangetab(id) {
    setValueItem(id);
  }

  const [dateFilter, setDateFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var rows = TeamLeadAllData();
  useEffect(() => {
    settableData(rows);
    return () => {};
  }, []);

  const data = [
    {
      id: 1,
      title: {
        color: "#747677",
        grid: 2,
        title: "Total Lineup",
        value: 500,
      },
      tab: (
        <ReportHeading
          color="#747677"
          secondColor="#EF38FF"
          thirdColor="#747677"
          tableTitle="Total Lineup"
        />
      ),
    },
    {
      id: 2,
      title: {
        color: "#E5B905",
        grid: 2,
        title: "Total Footfall",
        value: 25,
      },

      tab: <TeamLaadSection color="#E5B905" tableTitle="Total Footfall" />,
    },
    {
      id: 3,
      title: {
        color: "#2563EB",
        grid: 4,
        title: "Total Selection",
        value: 500,
        revenue: "₹25,000",
      },
      tab: (
        <div>
          <TeamLaadSection color="#2563EB" tableTitle="Total Selection" />{" "}
          <br />
          <TeamLaadSection color="#000" tableTitle="Selection Revenue" />{" "}
        </div>
      ),
    },
    {
      id: 4,
      title: {
        color: "#199E50",
        grid: 4,
        title: "Total Joining",
        value: 15,
        revenue: "₹25,000",
      },
      tab: (
        <div>
          <TeamLaadSection color="#199E50" tableTitle="Total Joining" /> <br />
          <TeamLaadSection color="#000" tableTitle="Joining Revenue" />{" "}
        </div>
      ),
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [loading]);
  return (
    <div style={{ marginTop: 15 }}>
      <Box
        style={{
          backgroundColor: "#fff",
          padding: 15,
          borderRadius: 10,
          boxShadow: "0px 0px 10px 0px #0000001A",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" style={{ color: "#000", fontWeight: "600" }}>
            Suchitra Oswal Report
          </Typography>

          <Button
            variant="contained"
            color="inherit"
            onClick={handleClickOpen}
            style={{ backgroundColor: "#2563EB", boxShadow: "none" }}
          >
            <span style={{ color: "#FFF" }}>Filter</span>
          </Button>
          <FilterDialog
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
        </div>

        <Grid container spacing={2} style={{ marginTop: 15 }}>
          {memoizedData.map((item, id) => (
            <Grid item md={item.title.grid}>
              <Box
                onClick={() => handleChangetab(item.id)}
                style={{
                  border: "1px solid",
                  borderColor:
                    valueItem === item.id ? item?.title?.color : "#DCE0E4",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              >
                <DashboardCard
                  color={item.title.color}
                  title={item.title.title}
                  value={item.title.value}
                  revenue={item.title.revenue}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        {memoizedData.map((item, id) => (
          <div id="myTabContent">
            <div
              style={{
                display: valueItem === item.id ? "block " : "none",
                marginTop: "20px",
              }}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {item.tab}
            </div>
          </div>
        ))}
      </Box>

      <Box style={{ marginTop: 15, position: "relative" }}>
        <div
          style={{
            position: "absolute",
            zIndex: 9,
            right: "20px",
            top: "15px",
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            style={{ backgroundColor: "#2563EB", boxShadow: "none" }}
          >
            <span style={{ color: "#FFF" }}> Download </span>
          </Button>
        </div>
        <TableWithExport
          title="Profile InProcess"
          columns={teamLeadColumns}
          data={tableData}
          // fName="Selected_Companies"
          // showNestedTable={true}
          selection={false}
          sorting={false}
          search={false}
          filtering={false}
        />
      </Box>
      {/* <ErrorSection /> */}
    </div>
  );
}

export default RecruitersReportTabSection;
