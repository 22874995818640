import { Button, Chip, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, {useState, useEffect} from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
  },
}));

export default function Hobbies({hobbies, postHobbies}) {

  const [tempHobbies, setTempHobbies] = useState([]);
  const [addedHobby, setAddedHobby] = useState("");
  const classes = useStyles();

  const handleDelete = (index) => () => {
    setTempHobbies((tempHobbies) =>
    tempHobbies.filter((chip, hobbyIndex) => hobbyIndex !== index)
    );
  };

  useEffect(() => {
      setTempHobbies(hobbies ? hobbies : []);
  }, [hobbies])

  const addHobby = () => {
      setTempHobbies([...tempHobbies, addedHobby]);
      setAddedHobby("");
  }

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Hobbies</Typography>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={10} style={{ display: "grid" }}>
            <TextField
              id="outlined-basic"
              label="Add Hobby"
              size="small"
              variant="outlined"
              onChange={(e) => {setAddedHobby(e.target.value)}}
              value={addedHobby}
            />
          </Grid>
          <Grid item xs={2} style={{ display: "grid" }}>
            <Button variant="contained" disabled={addedHobby ? false : true} color="primary" onClick={() => {addHobby()}}>
              Add
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className={classes.root}>
        {tempHobbies && tempHobbies.map((data, index) => {
          return (
            <li key={index}>
              <Chip
                color="primary"
                deleteIcon={<CloseIcon color="primary" />}
                variant="outlined"
                label={data}
                onDelete={handleDelete(index)}
                className={classes.chip}
                size="small"
              />
            </li>
          );
        })}
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button color="primary" variant="contained" disabled={tempHobbies && tempHobbies.length > 0  ? false : true} onClick={() => {postHobbies(tempHobbies)}}>
          Save
        </Button>
      </div>
    </div>
  );
}
