import { Typography, IconButton } from "@material-ui/core";
import React from "react";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function DashboardCard({ color, title, value, revenue }) {
  return (
    <div
      style={{
        // border: "0.5px solid",
        // borderColor: color,
        padding: 15,
        borderRadius: 10,
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2">{title}</Typography>{" "}
        <IconButton size="small">
          <ArrowForwardIosRoundedIcon />
        </IconButton>{" "}
      </div>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h4" style={{ fontWeight: "600", color: color }}>
            {value}
          </Typography>
          <Typography
            variant="body2"
            style={{ fontWeight: "400", color: "#747677" }}
          >
            Applicants
          </Typography>
        </div>
        {revenue && (
          <div>
            <Typography variant="h5" style={{ fontWeight: "600" }}>
              {revenue}
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: "400", color: "#747677" }}
            >
              Revenue Generate
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardCard;
