import {
  Button,

  Divider, FormControl, Grid,
  InputLabel, MenuItem, Select, TextField, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { praveshAxiosPostReq } from "../../util/baseApi";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import CustomButtonCircularProgress from "../Student/Components/loader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  typo:{
    marginBottom:"20px",
    marginLeft: "20px"
  },

  paper: {
    padding: theme.spacing(2),
    marginLeft:"20px",
    height: "450px",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 20%) 2px 8px 24px",
    // borderRadius: "50px",
  },
  paper2: {
    padding: theme.spacing(2),
    // height: "350px",
    width: "400px",
    marginLeft:"20px",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 20%) 2px 8px 24px",
    
  },

  textField: {
    width: "50%",
  },
}));

export default function ProfileUnlockSection({cmpId,cmpName,creditdata, setLoadApi = () => {} }) {
  const classes = useStyles();
  const [freeCredits, setFreeCredits] = useState();
  const [paidCredits,setPaidCredits] = useState();
  const [creditValue, setCreditValue] = useState();
  const [date, setDate] = useState();
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [updateIsLoading, setUpdateIsLoading] = useState(false);
  const [creditId, setCreditId] = useState();
  const [expiryDate, setExpiryDate] = useState();
  var today = new Date();

  useEffect(() => {
    if(creditdata && creditdata?.length > 0){
      setStatus(creditdata[0]?.credit_status);
      setExpiryDate(moment(creditdata[0]?.expiry_credit, "DD/MM/YY").format("YYYY-MM-DD"));
      setCreditId(creditdata[0]?.id);
    }
  }, [creditdata])

  const validateData = () => {
    if((!freeCredits && !paidCredits) || !date)
      return false
    return true
  }

  const profileunlock = () => {

    if(validateData()){
    const payload = {
        "company_id":cmpId,
        "company_name":cmpName,
        "free_credits":freeCredits,
        "paid_credits":paidCredits,
        // "credit_status":status,
        // "credit_value": null,
        "credit_expiry":date,
    }

    const postUnlocksCreditsData = async() => {

      const endPoint =  `/api/admin/company/add_credit/?company_id=${cmpId}`;

          setIsLoading(true);

      try {

        const res = await praveshAxiosPostReq(endPoint,payload);

        if(res?.data?.success){

           SuccessAlert(res?.data?.data?.message);
          setLoadApi(true);

        }else{

           FailureAlert(res?.data?.error);

        }

      } catch (error) {

         setIsLoading(false);
         FailureAlert("Something Went Wrong", error?.message);
        
        throw(error)
      }
    }

    postUnlocksCreditsData();
    }
    else {
      FailureAlert("Please fill all the details");
    }

  }

  const updateUnlock = () => {


      const payload = {
            "company_id":cmpId,
            "credit_status":status,
            "credit_expiry":expiryDate,
            // "credit_value": null,
            "id": creditId
        }

      const postUnlocksCreditsData = async() => {

      const endPoint =  `/api/admin/company/add_credit/?company_id=${cmpId}`;

      setUpdateIsLoading(true);
      try {

        const res = await praveshAxiosPostReq(endPoint,payload);

        if(res?.data?.success){

          SuccessAlert(res?.data?.data?.message);

        }else{

           FailureAlert(res?.data?.error);
        }
         setUpdateIsLoading(false);

      } catch (error) {

        setUpdateIsLoading(false); 
        FailureAlert("Something Went Wrong", error?.message);       
        throw(error);

      }
    }
    postUnlocksCreditsData();

    }

  return (
    <>
      <div>
        <Grid container spacing={2}>
        <Grid item md={6}>
            <div className={classes.paper}>
            <Grid container spacing={2}>
            <Grid item xs={8} md={6}>
                <Typography variant="h6">Add Profile Unlock Credits</Typography>
            </Grid>
            { 
            <>
            <Grid item xs={12} sm container>
                <Grid
                item
                xs
                container
                direction="column"
                spacing={2}
                >
                <div
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    }}
                >
                    <div style={{ display: "flex" }}>
                    
                    </div>
                    <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "5px" }}
                    >
                    </Typography>

                    <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "5px" }}
                    >
                    
                    </Typography>
                    <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "5px" }}
                    >
                    
                    </Typography>
                    
                    <Typography variant="body2" color="textSecondary">
                    
                    </Typography>
                </div>
                </Grid>
            </Grid>
    </> 
                }
                <Grid item xs={12} style={{display:"grid"}}>
                    <TextField
                    type="number"
                    id="outlined-basic"
                    label="Free Unlocks *"
                    variant="outlined"
                    size="small"
                    InputProps={{ inputProps: { min: 0 } }}
                    
                    onChange={(e) => {
                        setFreeCredits(e.target.value);
                      }}
                    value={freeCredits}
                    />
                    
                </Grid>

                <Grid item xs={12} style={{display:"grid"}}>
                    <TextField
                    type="number"
                    id="outlined-basic"
                    label="Paid Unlocks *"
                    variant="outlined"
                    size="small"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => {
                        setPaidCredits(e.target.value);
                      }}
                    value={paidCredits}
                    />
                    
                </Grid>
                <Grid item xs={12} style={{display:"grid"}}>
                    <TextField
                    id="outlined-basic"
                    label="Expiry Date"
                    variant="outlined"
                    size="small"
                    type= "date"
                    InputLabelProps={{ shrink: true, required: true }}
                    onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      value={date}
                    />
                </Grid>

                <Grid item md={6} xs={12} >
                    <Button variant="outlined" style={{display:"flex"}} color="primary" onClick={(e) => {
                      setPaidCredits(0);
                      setFreeCredits(0);
                      setDate(null);
                    }}>
                        Clear
                    </Button>
                </Grid>
                <Grid item md={6} xs={12} style={{ display: "flex", justifyContent: "right" }}>
                    <Button variant="contained" style={{display:"flex"}} color="primary"  onClick={(e) => {
                        profileunlock();
                    }}>
                        {isLoading ? <CustomButtonCircularProgress /> : "Add"}
                    </Button>
                </Grid>
            
            </Grid>
            </div>
        </Grid>
        <Grid item md={6} style={{display:"grid"}}>
            <div className={classes.paper2}>
                {creditdata?.length === 0 ?
                  <Typography variant="h6">No Unlock Credit Available</Typography>
                  :
                    creditdata?.map((item)=>(
                        <div>
                                <Typography variant="h6" className={classes.typo}>Unlock Credits Details</Typography>
                                <Typography className={classes.typo}><b>Available Unlocks :</b> {item?.available_credit}</Typography>
                                <Typography className={classes.typo}><b>Burned Unlocks :</b> {item?.burned_credit}</Typography>
                                <Typography className={classes.typo}><b>Expired Unlocks :</b> {item?.expired_credit}</Typography>
                                <Typography className={classes.typo}><b>Free Unlocks :</b> {item?.free_credit}</Typography>
                                <Typography className={classes.typo}><b>Paid Unlocks :</b> {item?.paid_credits}</Typography>
                                {/* <Typography className={classes.typo}><b>Added Date :</b> {item?.start_credit}</Typography> */}
                                <Divider style={{marginBottom: "20px"}}/>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "left", marginBottom: "10px"}}>
                                          <FormControl
                                              variant="outlined"
                                              className={classes.textField}
                                              size="small"
                                              >
                                              <InputLabel
                                                  id="demo-simple-select-outlined-label"
                                                  className={classes.floatingLabelFocusStyle}
                                              >
                                                  Status
                                              </InputLabel>
                                              <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  onChange={(e) => {
                                                  setStatus(e.target.value)}}
                                                  // label="Status"
                                                  MenuProps={{
                                                  style: {
                                                      marginTop: "30px",
                                                  },
                                                  }}
                                                  value={status ? status : ""}
                                                  
                                              >
                                                  <MenuItem value={"ACTIVE"} name="active">ACTIVE</MenuItem>
                                                  <MenuItem value={"INACTIVE"} name="inactive">INACTIVE</MenuItem>
                                              </Select>
                                          </FormControl>
                                </div>

                                <Grid item xs={12} style={{display:"grid"}}>
                    <TextField
                    id="outlined-basic"
                    label="Expiry Date"
                    variant="outlined"
                    size="small"
                    type= "date"
                    InputLabelProps={{ shrink: true, required: true }}
                    onChange={(e) => {
                      console.log(e.target.value, expiryDate);
                      setExpiryDate(e.target.value);
                      }}
                      value={expiryDate ? expiryDate: null}
                    />
                </Grid>
                <Button variant="contained" style={{display:"flex", marginTop:"10px", minHeight: "40px"}} color="primary"  onClick={(e) => {

                        updateUnlock();
                    }}>
                        {updateIsLoading ? <CustomButtonCircularProgress /> : "Update"}
                    </Button>
                                {/* <Typography className={classes.typo}><b>Expiry Date :</b> {item?.expiry_credit}</Typography> */}
                        </div>
                    ))}
            </div>
            
        </Grid>
        </Grid>
      </div>
    </>
  );
}
