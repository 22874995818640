import React from "react";
import "../.././css/UnlockHistory.css";
import { DataGrid } from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { praveshAxiosGetReq } from "../../util/baseApi";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import {
  AccountCircleOutlined,
  CallOutlined,
  CheckOutlined,
  DragIndicator,
  Eject,
  Email,
  EmailOutlined,
  FlashOn,
  GroupAdd,
  LockOpenOutlined,
  PersonAddOutlined,
  QueryBuilderOutlined,
  Whatshot,
  WorkOutlineOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

function UnlockHistory({
  showHistory,
  companyHistoryToShow,
  companySelection,
}) {
  const history = useHistory();
  const [row, setRow] = useState([]);
  const [planName, setPlanName] = useState();
  const [profileUnlocks, setProfileUnlocks] = useState();
  const [jobPosting, setJobPosting] = useState();
  const [expireDate, setExpireDate] = useState();
  const [avilableUnlocks, setAvilableUnlocks] = useState();
  const [freeUnlocks, setFreeUnlocks] = useState();
  const [paidUnlocks, setPaidUnlocks] = useState();
  const [bookedSlots, setBookSlots] = useState(0);
  const [burnedSlots, setBurnedSlots] = useState(0);
  const [activeSlots, setActiveSlots] = useState(0);
  const [account, setAccount] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    history.push(
      `/admin/active-plan/${companyHistoryToShow?.company_name}/${companyHistoryToShow?.company_id}`
    );
    companySelection(companyHistoryToShow);
  };
  const handleSlotView = () => {
    history.push(
      `/admin/active-slot/${companyHistoryToShow?.company_name}/${companyHistoryToShow?.company_id}`
    );
    companySelection(companyHistoryToShow);
  };

  useEffect(() => {
    fetchTableData();
    fetchSubscriptionData();
  }, [companyHistoryToShow?.company_id]);

  const fetchTableData = async () => {
    const endPoint = `/api/admin/company/plan_details/purchase_history/?company_id=${companyHistoryToShow?.company_id}`;
    try {
      setLoading(true);
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        let data = res?.data?.data?.results;
        setRow(
          data.map((item) => ({
            id: item?.order_id,
            plan_type: item?.model,
            plan_name: item?.plan,
            action_type: item?.purchase_category,
            amount: item?.final_payment,
            payment_status: item?.status,
            payment_by: item?.payment_by?.name,
            payment_method: item?.payment_method,
            payment_on: item?.payment_on,
            invoice: item?.invoice ? "DOWNLOAD" : "PENDING",
          }))
        );
      } else console.log("If error");
    } catch (e) {
      console.log("error : ", e);
    }
    setLoading(false);
  };

  const fetchSubscriptionData = async () => {
    const endPoint = `/api/admin/company/plan_details/subscriptions/?company_id=${companyHistoryToShow.company_id}`;
    try {
      setLoading(true);
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        let data = res?.data?.data;
        setPlanName(data?.plan?.name);
        setProfileUnlocks(data?.plan?.unlocks);
        setJobPosting(data?.plan?.jobs);
        setExpireDate(data?.plan?.next_billing_data);
        setAvilableUnlocks(data?.profile_unlocks?.available_credits);
        setFreeUnlocks(data?.profile_unlocks?.free_credits);
        setPaidUnlocks(data?.profile_unlocks?.paid_credits);
        setAccount(data?.kam?.name);
        setEmail(data?.kam?.email);
        setPhone(data?.kam?.phone ? data?.kam?.phone : "---");
        setBookSlots(data?.slots?.booked);
        setBurnedSlots(data?.slots?.burned);
        setActiveSlots(data?.slots?.available);
      }
    } catch (error) {
      console.log("error : ", error);
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      width: 150,
    },
    {
      field: "plan_type",
      headerName: "Plan Type",
      width: 150,
    },
    {
      field: "plan_name",
      headerName: "Plan Name",
      width: 150,
    },
    {
      field: "action_type",
      headerName: "Action Type",
      width: 250,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 200,
    },
    {
      field: "payment_by",
      headerName: "Payment By",
      width: 200,
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      width: 200,
    },
    {
      field: "payment_on",
      headerName: "Payment On",
      width: 200,
    },
    {
      field: "invoice",
      headerName: "Invoice",
      width: 200,
    },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     plan_type: "ATS",
  //     plan_name: "Business Plan",
  //     action_type: "Subscription Renewed",
  //     amount: "$51",
  //     payment_status: "Payment Complete",
  //     payment_by: "Prerit 2, Hr Manager",
  //     payment_method: "ONLINE",
  //     payment_on: "02:53:40 PM, 20-Jun-2023",
  //     invoice: "Download",
  //   },
  //   {
  //     id: 2,
  //     plan_type: "ATS",
  //     plan_name: "Business Plan",
  //     action_type: "Subscription Renewed",
  //     amount: "$51",
  //     payment_status: "Payment Complete",
  //     payment_by: "Prerit 2, Hr Manager",
  //     payment_method: "ONLINE",
  //     payment_on: "02:53:40 PM, 20-Jun-2023",
  //     invoice: "Download",
  //   },
  //   {
  //     id: 3,
  //     plan_type: "ATS",
  //     plan_name: "Business Plan",
  //     action_type: "Subscription Renewed",
  //     amount: "$51",
  //     payment_status: "Payment Complete",
  //     payment_by: "Prerit 2, Hr Manager",
  //     payment_method: "ONLINE",
  //     payment_on: "02:53:40 PM, 20-Jun-2023",
  //     invoice: "Download",
  //   },
  //   {
  //     id: 4,
  //     plan_type: "ATS",
  //     plan_name: "Business Plan",
  //     action_type: "Subscription Renewed",
  //     amount: "$51",
  //     payment_status: "Payment Complete",
  //     payment_by: "Prerit 2, Hr Manager",
  //     payment_method: "ONLINE",
  //     payment_on: "02:53:40 PM, 20-Jun-2023",
  //     invoice: "Download",
  //   },
  // ];

  return (
    <>
      {loading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        //showHistory && companyHistoryToShow &&
        <div>
          <div>
            <h2>Subscription Overview</h2>
            <div className="cards">
              <div className="card-item">
                <h4>Current active plan</h4>
                {planName || profileUnlocks || jobPosting || expireDate ? (
                  <div>
                    <div className="sub-menu">
                      <ul className="left">
                        <li>
                          <FlashOn /> Plan name
                        </li>
                        <li>
                          <PersonAddOutlined /> Profile Unlocks
                        </li>
                        <li>
                          <WorkOutlineOutlined /> Job/ Internship Posting
                        </li>
                        <li>
                          <QueryBuilderOutlined /> Expiry Date
                        </li>
                      </ul>
                      <ul className="right">
                        <li>{planName}</li>
                        <li>{profileUnlocks}</li>
                        <li>{jobPosting}</li>
                        <li>{expireDate}</li>
                      </ul>
                    </div>
                    <button className="buttoon" onClick={handleClick}>
                      View Details →
                    </button>
                  </div>
                ) : (
                  <div className="sub-menu">No Current Active Plan</div>
                )}
              </div>

              <div className="card-item">
                <h4>Profile Unlocks</h4>
                {avilableUnlocks || paidUnlocks || freeUnlocks ? (
                  <div>
                    <div className="sub-menu">
                      <ul className="left">
                        <li>
                          <LockOpenOutlined /> Available Unlocks
                        </li>
                        <li>
                          <GroupAdd /> Paid Unlocks
                        </li>
                        <li>
                          <CheckOutlined /> Free Unlocks
                        </li>
                      </ul>
                      <ul className="right">
                        <li>{avilableUnlocks}</li>
                        <li>{paidUnlocks}</li>
                        <li>{freeUnlocks}</li>
                      </ul>
                    </div>

                    <button className="buttoon" onClick={handleClick}>
                      View Details →
                    </button>
                  </div>
                ) : (
                  <div className="sub-menu">No Current Active Plan</div>
                )}
              </div>
            </div>
          </div>

          <div>
            <h2>Booked Slots Overview</h2>
            <div className="cards">
              <div className="card-item">
                <h4>My Slots</h4>
                {bookedSlots || burnedSlots || activeSlots ? (
                  <div>
                    <div className="sub-menu">
                      <ul className="left">
                        <li>
                          <DragIndicator /> Booked Slots
                        </li>
                        <li>
                          <Whatshot /> Burned Slots
                        </li>
                        <li>
                          <Eject /> Active Slots
                        </li>
                      </ul>
                      <ul className="right">
                        <li>{bookedSlots}</li>
                        <li>{burnedSlots}</li>
                        <li>{activeSlots}</li>
                      </ul>
                    </div>
                    <button className="buttoon" onClick={handleSlotView}>
                      View Details →
                    </button>
                  </div>
                ) : (
                  <div className="sub-menu">No Current Active Plan</div>
                )}
              </div>

              <div className="card-item">
                <h4>Key Account Manager</h4>
                {account || email || phone ? (
                  <div className="sub-menu">
                    <ul className="left">
                      <li>
                        <AccountCircleOutlined /> Account
                      </li>
                      <li>
                        <EmailOutlined /> Email
                      </li>
                      <li>
                        <CallOutlined /> Phone
                      </li>
                    </ul>
                    <ul className="right">
                      <li>{account}</li>
                      <li>{email}</li>
                      <li>{phone}</li>
                    </ul>
                  </div>
                ) : (
                  <div className="sub-menu">
                    No Current Active Key Account Manager
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="side-section"></div> */}
          <div className="purchase" style={{ height: "25rem", width: "100%" }}>
            <h2>My Purchase History</h2>
            <DataGrid rows={row} columns={columns} />
          </div>
        </div>
      )}
    </>
  );
}

export default UnlockHistory;
