import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import QueryString from "query-string";
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import { DateSelector } from '../../components/common/DateSelector';
// import { CsvBuilder } from "filefy";
import CustomButtonCircularProgress from "../../components/loader";
import TableWithExport from '../../components/TableWithExport';
import { cellCSS, gifStyling, headerCSS } from '../../constants';
import { praveshAxiosGetReq } from '../../util/apiCall';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
    pageSection: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse'
        // justifyContent: 'space-between',
    },
    tableSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    globalSearch: {
        position: 'relative',

    },
    filterSearch: {
        display: 'flex',
        flexDirection: 'flex-end'

    },


}
));

const ResumeUsers = () => {


    const classes = useStyles();

    const [resumeUsers, setResumeUsers] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingAllData, setloadingAllData] = useState(false);

    const [count, setCount] = useState(0);
    const history = useHistory();
    const [downloadAllFlag, setDownloadAllFlag] = useState(false)
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    const [metaData, setMetaData] = useState({
        totalCount: 0,
        totalPages: 1,
    })

    const [isInitialCall, setInitialCall] = useState(true);

    const applyDateFilter = (valueDate) => {
        if (valueDate.length === 2) {
            setStartDate(valueDate[0]);
            setEndDate(valueDate[1]);
        }
        setSelectedPageNo(1);
    };


    const removeDateFilter = () => {

        let initialStartDate = new Date(
            new Date().setDate(new Date().getDate() - 6)
        );
        let initialEndDate = new Date();

        setStartDate(new Date(initialStartDate));
        setEndDate(new Date(initialEndDate));
        setCount(count + 1);
        setSelectedPageNo(1);

    };

    useEffect(() => {

        // if its initial call
        // and there is nothing in url then startdate and enddate should be today and t-30
        //  else get it from url

        if (isInitialCall) {

            let initialStartDate = new Date(
                new Date().setDate(new Date().getDate() - 6)
            );
            let initialEndDate = new Date();

            const queryParams = QueryString.parse(window.location.search);
            if (queryParams.from) {
                initialStartDate = queryParams.from;
            }
            if (queryParams.to) {
                initialEndDate = queryParams.to;
            }
            setStartDate(new Date(initialStartDate));
            setEndDate(new Date(initialEndDate));
        }

    }, []);

    const resumeUsersColumns = [

        {
            title: "User Id",
            field: "user_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Name",
            field: "name",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 250,
                maxWidth: 300,

            },
            filtering: true,
        },

        {
            title: "Phone",
            field: "phone",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "E-mail",
            field: "email",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "User Created Date",
            field: "user_created_date",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },

        {
            title: "Last Updated",
            field: "resume_updated_date",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },

        // {
        //     title: "User Resume",
        //     field: "",
        //     headerStyle: headerCSS,
        //     cellStyle: cellCSS,
        //     filtering: true,
        //     searchable: true,
        //     render: (row) => (
        //         <Button
        //             onClick={() => {
        //                 if (!loadingUserResume) {
        //                     getUserResume(row?.user_id)
        //                 }
        //             }}
        //             size="sm"
        //             variant="outline-info"
        //             style={{ width: '8rem' }}
        //         >
        //             {currentResumeViewed === row?.user_id ? <CustomButtonCircularProgress isWhite={false} /> : "View Resume"}
        //         </Button>
        //     )

        // },
        {
            title: "Resume Link",
            field: "resume_link",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) => (
                <Button
                    onClick={() => {
                        window.open(row.resume_link)
                    }}
                    size="sm"
                    variant="outline-info"
                    style={{ width: '8rem' }}
                >
                    Open Resume
                </Button>
            )
        },
        {
            title: "UTM Source",
            field: "utm_source",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "UTM Medium",
            field: "utm_medium",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Campaign",
            field: "utm_campaign",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "UTM Content",
            field: "utm_content",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Term",
            field: "utm_term",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },

    ]

    const handleDownloadAllData = () => {

        setDownloadAllFlag(true)
    }

    const getLeadsData = async () => {

        if (count === 0 && startDate === "") return;

        let dateString = ''
        let startDateString = '';

        if (startDate && endDate) {

            let month = startDate.getMonth() + 1;
            month = month < 10 ? `0${month}` : month;
            let day = startDate.getDate();
            day = day < 10 ? `0${day}` : day;
            startDateString = `${startDate.getFullYear()}-${month}-${day}`;
            history.replace(
                `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
            );
            dateString = `?start_date=${startDateString}&end_date=${endDate.toISOString().substring(0, 10)}`;
        };

        if (!downloadAllFlag) {

            setLoading(true);
        } else {
            setloadingAllData(true);
        }

        let endPoint = `/api/admin/student-resume-created-lists/${dateString}&page_no=${selectedPageNo}`;

        if (downloadAllFlag) {
            endPoint = `/api/admin/student-resume-created-lists/${dateString}&is_export=True`;
        }

        try {
            let res;
            res = await praveshAxiosGetReq(endPoint);

            if (res?.data?.success) {

                setInitialCall(false);

                if (!downloadAllFlag) {

                    let data = res?.data?.data?.student_data_list;
                    let count = res?.data?.data?.student_data_list_count;


                    setMetaData({
                        totalCount: count,
                        totalPages: Math.ceil(count / 10),
                    })

                    let tempArr = [];
                    for (let i in data) {

                        let obj = {};
                        obj = {
                            ...data[i],
                        }
                        tempArr.push(obj);
                    }

                    setResumeUsers(tempArr);

                } else {

                    setDownloadAllFlag(false);
                    SuccessAlert("Request received. Please Check your email after 10 mins.")

                }

            } else {
                FailureAlert("Some Error Occured!");
            }

        } catch (error) {

            throw (error)
        } finally {

            setLoading(false);
            setDownloadAllFlag(false);
            setloadingAllData(false);

        }

    }

    useEffect(() => {

        const getData = setTimeout(() => {

            getLeadsData();

        }, 500);

        return () => clearTimeout(getData);


    }, [startDate, count,]);

    useEffect(() => {

        if (selectedPageNo < 1) {
            return
        }

        const getData = setTimeout(() => {

            getLeadsData();

        }, 500);

        return () => clearTimeout(getData);

    }, [selectedPageNo])

    useEffect(() => {

        if (downloadAllFlag) {
            const getData = setTimeout(() => {

                getLeadsData();

            }, 500);

            return () => clearTimeout(getData);
        }
    }, [downloadAllFlag])



    return (
        <>
            <DateSelector
                dateRange={[startDate, endDate]}
                applyDateFilter={applyDateFilter}
                removeDateFilter={removeDateFilter}
            />

            <section>
                <section
                    className={classes.pageSection}
                >

                    <section className={classes.filterSearch}>


                        <Button
                            style={{
                                width: '12rem', height: '2.5rem'
                            }}
                            onClick={() => {
                                handleDownloadAllData()
                            }
                            }
                        >
                            {loadingAllData ? <CustomButtonCircularProgress /> : " Download All Data"}
                        </Button>

                    </section>

                </section>
            </section>

            <section
                className={classes.tableSection}
            >
                <section>
                    {
                        loading ? <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} /> :
                            <>
                                <TableWithExport
                                    title={`Resume Makers (${metaData?.totalCount})`}
                                    data={resumeUsers ?? []}
                                    columns={resumeUsersColumns ?? []}
                                    fName="Selected_data"
                                    hasPaging={false}
                                    disableTableDownload={true}
                                    searchPlaceHolder={" Search Resume Table"}
                                />
                                <section
                                    style={{ float: "right", marginTop: '1rem' }}
                                >
                                    <Pagination
                                        page={selectedPageNo || 1}
                                        onChange={
                                            (e, value) => {
                                                setSelectedPageNo(value);
                                            }
                                        }
                                        count={metaData?.totalPages}
                                        color="primary"
                                    />
                                </section>
                            </>
                    }
                </section>
            </section>
        </>
    )
}

export default ResumeUsers
