import {
  Button,
  Dialog,
  DialogContent, Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { FailureAlert } from "../../../../util/Notifications";
import LanguageForm from "./LanguageForm";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
    //  left: "50%",
    // transform: "translate(-50%, -50%)",
    // [theme.breakpoints.down("xs")]: {
    //   top: "100%",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   top: "110%",
    // },
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
}));

export default function AddLanguageDialog(props) {
  const classes = useStyles();
  const {setTempLanguage, tempLanguage} = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [addLang, setAddLang] = useState("");
  const [proficiency, setProficiency] = useState('Beginner');
  const [level, setLevel] = useState([]);

  // const []

  const clearLanguageForm = () => {
    setAddLang("");
    setProficiency('Beginner');
    setLevel([]);
  }

  const validateNewLangForm = () => {
    if(!(addLang && proficiency && level ))
      return false;
    return true;
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        // maxWidth="sm"
        classes={{
          paper: classes.newDialog,
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle> */}
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>Add Language</b>
          </Typography>
          <LanguageForm addLang={addLang} setAddLang={setAddLang} setProficiency={setProficiency} setLevel={setLevel} level={level}/>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                onClick={props.handleClose}
                color="primary"
                onClick={() => {
                  clearLanguageForm();
                  props.handleClose();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button variant="contained" color="primary" onClick={() => {
                if(validateNewLangForm()){
                setTempLanguage([...tempLanguage, {
                    name: addLang,
                    level: level,
                    proficiency: proficiency
                }]
                )
                clearLanguageForm();
                props.handleClose();
              }
              else{
                FailureAlert("Please fill all details")
              }
              }}>
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
