import { Dialog, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { FileCopyRounded } from '@material-ui/icons';
import React from 'react';
import { Col, Form } from "react-bootstrap";
// import crossIcon from "../../../assets/images/cancel.png";
import crossIcon from "../../../assets/images/cancel.png";
import { copyToClipboard } from '../../../util/copyToClipboard';

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },

    root1: {
        borderRadius: "20px",
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    button: {
        borderRadius: "30px",
        margin: theme.spacing(2),
        boxShadow: "none",
        fontSize: "16px",
        padding: "7px",
        height: "40px",
        paddingLeft: "50px",
        paddingRight: "50px",
        display: "flex",
    },
    dialogFrame: {
        display: 'flex',

    },
    crossStyle: {
        width: "10px",
        fontWeight: "Bold",
        height: "10px",
        cursor: "pointer"
    },
}));

const ShareLink = ({ open, handleClose, link }) => {

    const classes = useStyles();


    return (
        <>
            <Dialog
                PaperProps={{ classes: { root: classes.root1 } }}
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"

            >
                <div style={{ padding: "40px" }}>
                    <section style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                    }}>
                        <img
                            className={classes.crossStyle}
                            src={crossIcon}
                            onClick={() => { handleClose(); }}
                            alt="close Icon" />
                    </section>
                    <Typography style={{ fontSize: "24px", color: 'blueviolet' }}>
                        Proposal link generated successfully
                    </Typography>

                    <section className={classes.dialogFrame} >
                        <Form.Row>

                            <Form.Group as={Col} controlId="amountToBePaid"
                                style={{ maxWidth: 700, minWidth: 500 }}
                            >
                                <Form.Label>Click on the link below to copy</Form.Label>
                                <section style={{ display: "flex", alignItems: "center" }}>
                                    <Form.Control
                                        name="linkToBeCopied"
                                        type='text'
                                        // value={}
                                        required
                                        readOnly
                                        placeholder={link || "Link to be copied"}
                                        onClick={() => copyToClipboard(link)}
                                    />

                                    <FileCopyRounded
                                        onClick={() => copyToClipboard(link)}
                                        style={{ color: 'green' }}
                                    />

                                </section>

                            </Form.Group>
                        </Form.Row>
                        {/* <Typography style={{ fontSize: "16px" }}>
                            http://localhost:3000/admin/edit-company
                        </Typography> */}


                    </section>




                </div>


            </Dialog>
        </>
    )
}

export default ShareLink