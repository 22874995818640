import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { FailureAlert } from "../../util/Notifications";

export default function ProductCouponDialog({
  open,
  handleClickOpen,
  handleClose,

  currentRow,
  edit,
  setCurrentRow,
  setEdit,
  editCouponObject,
  setEditCouponObject,
  updateCoupon,
}) {
  const handleChange = (e) => {
    setEditCouponObject({ ...editCouponObject, status: e.target.value });
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle id="form-dialog-title">{edit ? "Edit" : "Add"} Sub Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="name"
            label="Enter your Sub Category Name"
            fullWidth
            value={editCouponObject?.coupon_code}
            onChange={(e) => {
              setEditCouponObject({ ...editCouponObject, coupon_code: e.target.value });
            }}
          />
          <br />
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="name"
            label="Enter your Tagline"
            fullWidth
            type="date"
            value={editCouponObject?.expiry}
            onChange={(e) => {
              setEditCouponObject({ ...editCouponObject, expiry: e.target.value });
            }}
          />

          <br />
          <FormControl component="fieldset" style={{ marginTop: 20 }}>
            <FormLabel component="legend">Active/Inactive</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={editCouponObject?.status} onChange={handleChange} row>
              <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
              <FormControlLabel value="INACTIVE" control={<Radio />} label="Inactive" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setCurrentRow(null);
              setEdit(false);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!editCouponObject?.coupon_code) {
                FailureAlert("Please Enter A Coupon Code");
                return;
              }
              if (!editCouponObject?.expiry) {
                FailureAlert("Please select an Expiry Date");
                return;
              }
              if (!editCouponObject?.status) {
                FailureAlert("Please select a Status");
                return;
              }
              if (edit) updateCoupon(currentRow?.coupon_id);

              handleClose();
            }}
            color="primary"
          >
            {edit ? "Edit" : "Add"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
