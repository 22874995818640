import { Typography, Box, Grid, Button } from "@material-ui/core";
import React, { useState, useMemo, useEffect } from "react";
import { DateSelector } from "../../components/common/DateSelector";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import ReportHeading from "../../components/Dashboard/ReportHeading";
import TableWithExport from "../../components/TableWithExportNew";
import { RowData } from "../../components/Dashboard/rowsData";
import { commonColumns } from "../../components/Dashboard/AllColumn";
import ErrorSection from "../../components/Dashboard/ErrorSection";
import PageErrorSection from "../../components/Dashboard/PageErrorSection";

function RecruiterReport() {
  const [value, setValue] = useState(1);
  function handleChange(id) {
    setValue(id);
  }

  const [dateFilter, setDateFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState();

  var rows = RowData();
  useEffect(() => {
    settableData(rows);
    return () => {};
  }, []);

  const data = [
    {
      id: 1,
      title: {
        color: "#747677",
        grid: 2,
        title: "Total Lineup",
        value: 500,
      },
      tab: <ReportHeading color="#747677" tableTitle="Total Lineup" />,
    },
    {
      id: 2,
      title: {
        color: "#E5B905",
        grid: 2,
        title: "Total Footfall",
        value: 25,
      },

      tab: <ReportHeading color="#E5B905" tableTitle="Total Footfall" />,
    },
    {
      id: 3,
      title: {
        color: "#2563EB",
        grid: 4,
        title: "Total Selection",
        value: 500,
        revenue: "₹25,000",
      },
      tab: (
        <div>
          <ReportHeading color="#2563EB" tableTitle="Total Selection" /> <br />
          <ReportHeading color="#000" tableTitle="Selection Revenue" />{" "}
        </div>
      ),
    },
    {
      id: 4,
      title: {
        color: "#199E50",
        grid: 4,
        title: "Total Joining",
        value: 15,
        revenue: "₹25,000",
      },
      tab: (
        <div>
          <ReportHeading color="#199E50" tableTitle="Total Joining" /> <br />
          <ReportHeading color="#000" tableTitle="Joining Revenue" />{" "}
        </div>
      ),
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [loading]);
  return (
    <div>
      <Box
        style={{
          backgroundColor: "#fff",
          padding: 15,
          borderRadius: 10,
          boxShadow: "0px 0px 10px 0px #0000001A",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" style={{ color: "#000", fontWeight: "600" }}>
            Preeti Rawat Report
          </Typography>

          <DateSelector
            dateRange={[]}
            setAdvanceFilterDateValue={setDateFilter}
            advanceFilterDateValue={dateFilter}
            isAdvanceFilter={true}
            clearDates={true}
            justifyContent="space-between"
            removeDateFilter={() => {
              setDateFilter([null, null]);
            }}
            label={"Date range:"}
          />
        </div>

        <Grid container spacing={2} style={{ marginTop: 15 }}>
          {memoizedData.map((item, id) => (
            <Grid item md={item.title.grid}>
              <Box
                onClick={() => handleChange(item.id)}
                style={{
                  border: "1px solid",
                  borderColor:
                    value === item.id ? item?.title?.color : "#DCE0E4",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              >
                <DashboardCard
                  color={item.title.color}
                  title={item.title.title}
                  value={item.title.value}
                  revenue={item.title.revenue}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        {memoizedData.map((item, id) => (
          <div id="myTabContent">
            <div
              style={{
                display: value === item.id ? "block " : "none",
                marginTop: "20px",
              }}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {item.tab}
            </div>
          </div>
        ))}
      </Box>

      <Box style={{ marginTop: 15, position: "relative" }}>
        <div
          style={{
            position: "absolute",
            zIndex: 9,
            right: "20px",
            top: "15px",
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            style={{ backgroundColor: "#2563EB", boxShadow: "none" }}
          >
            <span style={{ color: "#FFF" }}> Download </span>
          </Button>
        </div>
        <TableWithExport
          title="Profile InProcess"
          columns={commonColumns}
          data={tableData}
          // fName="Selected_Companies"
          // showNestedTable={true}
          selection={false}
          sorting={false}
          search={false}
          filtering={false}
        />
      </Box>
      {/* <ErrorSection /> */}
      {/* <PageErrorSection /> */}
    </div>
  );
}

export default RecruiterReport;
