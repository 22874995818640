import React, { useState, useEffect } from 'react';
import { apiCall, apiNiyuktiCall } from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, createIconStyling, requestPraveshOptions, requestNiyuktiOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import { Button, Modal, Form, Table, Col } from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { SuccessAlert, FailureAlert } from '../../util/Notifications';
import { printNumber, validateDateTime } from '../../util/printNumber';
import { viewJobDetail } from '../../util/ViewJobFunction';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { TabPanel } from '../../util/TabPanel';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { AppBar, Tabs, Tab, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { uniqueArray } from '../../util/uniqueArray';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const labelStyle = {
    fontSize: '13px',
    marginBottom: '1px',
    color: 'green',
}


function VerifyJobs() {

    const [loading, setLoading] = useState(false);
    const [circularLoading, setCircularLoading] = useState(false);
    const [onCampusData, setOnCampusData] = useState([]);
    const [offCampusData, setOffCampusData] = useState([]);
    const [hasOnCampusData, setHasOnCampusData] = useState(false);
    const [hasOffCampusData, setHasOffCampusData] = useState(false);
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [modalCollegesData, setModalCollegesData] = useState([]);
    const [modalAssignmentsData, setModalAssignmentsData] = useState([]);
    const [modalCloseJobData, setModalCloseJobData] = useState({});
    const [closeReason, setCloseReason] = useState('');
    const [count, setCount] = useState(0);
    const [jobId, setJobId] = useState(null);
    const [jobTitle, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    // const [uploadAssignment, setUploadAssignment] = useState(false);
    const [viewCollegeModal, setViewCollegeModal] = useState(false);
    const [viewAssignmentModal, setViewAssignmentModal] = useState(false);
    const [uploadAssignmentModal, setUploadAssignmentModal] = useState(false);
    const [viewExtraDataModal, setViewExtraDataModal] = useState(false);

    const [extraData, setExtraData] = useState({});
    const [jobStage, setJobStage] = useState('');
    const [followUp1, setFollowUp1] = useState('');
    const [followUp2, setFollowUp2] = useState('');
    const [followUp3, setFollowUp3] = useState('');

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [deadline, setDeadline] = useState('');
    const [url, setUrl] = useState('');
    const [file, setFile] = useState(null);
    const [statusArr, setStatusArr] = useState([]);
    const dateRange = [new Date(2021, 0, 1), new Date()];
    const [value, setValue] = useState(0);


    const statusObj = [
        { name: "Applied Candidates", id: 1, },
        { name: "Reviewed Candidates", id: 8 },
        { name: "Shortlisted Candidates", id: 2 },
        { name: "Interviewed Candidates", id: 7 },
    ];

    const handleChangeDate = (newRange, props) => {
        let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
        let endDate = (newRange && newRange[1]) || new Date();
        props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
    };

    const handleClose = () => {
        setOpen(false);
        // setUploadAssignment(false);
        setViewCollegeModal(false);
        setViewAssignmentModal(false);
        setUploadAssignmentModal(false);
        setViewExtraDataModal(false);
        setJobId(null);
        setJobTitle('');
        setCompanyName('')
        setModalCollegesData([]);
        setModalCloseJobData({});
        setModalAssignmentsData([]);
        setExtraData({});
        setJobStage('');
        setFollowUp1('');
        setFollowUp2('');
        setFollowUp3('');
    }

    const updateList = () => {
        if (value === 0) setHasOnCampusData(false);
        if (value === 1) setHasOffCampusData(false);
        setCount(count + 1);
    }
    const handleOpen = () => setOpen(true);

    const loadCollegesModal = (e, job_id) => {
        setViewCollegeModal(true);
        setCircularLoading(true);
        apiNiyuktiCall(`/job/job_college/?job_id=${job_id}`, requestNiyuktiOptions)
            .then((res) => {
                if (res.success) {
                    setModalCollegesData(res.data)
                    setCircularLoading(false)
                }
                else FailureAlert(res.error)
            })
        handleOpen()
    }

    const loadAssignmentsModal = (e, job_id) => {
        setJobId(job_id);
        setViewAssignmentModal(true);
        setCircularLoading(true);
        apiNiyuktiCall(`/job/job_assignment/?job_id=${job_id}`, requestNiyuktiOptions)
            .then((res) => {
                if (res.success) {
                    setModalAssignmentsData(res.data)
                    setCircularLoading(false)
                }
                else FailureAlert(res.error)
            })
        handleOpen()
    }
    const uploadAssignmentsModal = (e, job_id, company_name, job_title) => {
        setJobId(job_id);
        setJobTitle(job_title);
        setCompanyName(company_name);
        setUploadAssignmentModal(true);
        setCircularLoading(true);
        handleOpen()
    }

    const closeParticularJob = (jobId) => {
        if (!closeReason) { FailureAlert('Specify Reason to Close Job!!'); return; }

        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                job_id: jobId,
                status: 'CLOSE',
                job_close_reason: closeReason
            })
        };

        apiNiyuktiCall('/job/company/job_status/', requestOptionsPost)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message)
                    setCloseReason('');
                }
                else {
                    FailureAlert(res.error)
                }
                updateList()
                handleClose()
            })
    }

    const addAssignment = (e, jobId) => {
        e.preventDefault();
        const assignmentButton = window.document.getElementById('upload-assignment-btn');
        assignmentButton.disabled = true;
        // if (!file) { FailureAlert('Upload file!!'); return; }
        if (statusArr.length === 0) { FailureAlert('Select Candidates Status to assign this assignment!!'); return; }
        const formData = new FormData();
        if (file) {
            const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
            const user_id = JSON.parse(localStorage.getItem('user-data')).id;
            const newName = `${jobTitle}_${user_id}_${jobId}_${Math.floor(100000 + Math.random() * 900000)}.${ext}`;
            // console.log(newName)
            formData.append('file', file, newName);
        }
        formData.append('job_id', jobId);
        formData.append('user_id', JSON.parse(localStorage.getItem('user-data')).id);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('end_date', deadline);
        formData.append('assignment_status', 'OPEN');
        // formData.append('is_posted_by_admin', true);
        formData.append('url', url);
        formData.append('job_title', jobTitle);
        formData.append('company_name', companyName);
        let statusTemp = uniqueArray(statusArr);
        formData.append('status_id', statusTemp.map(e => e.id));
        const requestForAssignmentUploading = {
            method: 'POST',
            headers: { 'Authorization': `Token ${localStorage.getItem('token')}` },
            body: formData
        }
        apiNiyuktiCall('/job/company/assignment/', requestForAssignmentUploading)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message);
                    setTitle(''); setDescription('');
                    setDeadline(''); setUrl('');
                    setFile(null);
                    updateList()
                    handleClose()
                }
                else {
                    FailureAlert(res.error);
                }
                assignmentButton.disabled = false;
            })
        e.target.reset();
    }

    const loadExtraDataModal = (e, job_id, extra_data) => {
        setJobId(job_id);
        setViewExtraDataModal(true);
        setExtraData(extra_data);
        extra_data?.job_stage && setJobStage(extra_data.job_stage);
        extra_data?.follow_up1 && setFollowUp1(extra_data.follow_up1);
        extra_data?.follow_up2 && setFollowUp2(extra_data.follow_up2);
        extra_data?.follow_up3 && setFollowUp3(extra_data.follow_up3);
        handleOpen()
    }

    const editExtraData = (e, jobId) => {
        e.preventDefault();
        if (!jobId) { FailureAlert('Job ID not Selected!!'); return; }
        let temp = { ...extraData };
        temp['job_stage'] = jobStage;
        temp['follow_up1'] = followUp1;
        temp['follow_up2'] = followUp2;
        temp['follow_up3'] = followUp3;
        const requestForExtraDataEdit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ extra_data: temp })
        }
        apiNiyuktiCall(`/job/admin/job_stage/${jobId}/`, requestForExtraDataEdit)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message)
                    updateList()
                    handleClose()
                }
                else FailureAlert(res.error)
            })
    }

    const verifiedUnverified = (e, row) => {
        e.preventDefault();
        const request = requestForVerified;
        apiNiyuktiCall(`/job/admin/job_verify/${row.job_id}/`, request)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message)
                    setCount(count + 1);
                }
                else {
                    FailureAlert(res.error);
                }
            })
    }

    const token = localStorage.getItem('token');

    const requestForVerified = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            'job_status': 'OPEN'
        })
    };

    useEffect(() => {
        let hiring_type = ' '
        if (value === 1) hiring_type = 'OFF CAMPUS';
        if (value === 0) hiring_type = 'ON CAMPUS';
        if (value === 0 && hasOnCampusData) return;
        if (value === 1 && hasOffCampusData) return;


        setLoading(true);

        apiCall(`/api/admin/job/job_verify/?hiring_type=${hiring_type}`, requestPraveshOptions)
            .then(json => {
                if (json.success) {
                    let temp = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            job_id: json.data[i].job_id,
                            job_title: json.data[i].job_title,
                            job_type_name: json.data[i].job_type_name,
                            employment_type: json.data[i].employment_type_name,
                            job_role: json.data[i].job_role,
                            hiring_type: json.data[i].hiring_type,
                            colleges: json.data[i].college,
                            ctc_min: json.data[i].ctc_min,
                            ctc_max: json.data[i].ctc_max,
                            vacancy: json.data[i].vacancy || '',
                            create_time: validateDateTime(json.data[i].create_time),
                            update_time: validateDateTime(json.data[i].update_time),
                            equity_min: json.data[i].equity_min,
                            equity_max: json.data[i].equity_max,
                            applicants: json.data[i].applicants,
                            counts: json.data[i].counts || [],
                            apply_start_date: json.data[i].apply_start_date,
                            apply_end_date: json.data[i].apply_end_date,
                            company_id: json.data[i].company ? json.data[i].company.company_id : null,
                            company_name: json.data[i].company ? json.data[i].company.company_name : null,
                            job_close_reason: json.data[i].job_close_reason,
                            job_assignment: json.data[i].job_assignment,
                            extra_data: json.data[i].extra_data,
                            job_lake: json.data[i].job_lake ? 'Yes' : 'No',
                            job_stage: json.data[i].extra_data && json.data[i].extra_data.job_stage ? json.data[i].extra_data.job_stage : '',
                            follow_up1: json.data[i].extra_data && json.data[i].extra_data.follow_up1 ? json.data[i].extra_data.follow_up1 : '',
                            follow_up2: json.data[i].extra_data && json.data[i].extra_data.follow_up2 ? json.data[i].extra_data.follow_up2 : '',
                            follow_up3: json.data[i].extra_data && json.data[i].extra_data.follow_up3 ? json.data[i].extra_data.follow_up3 : '',
                            acc_manager_name: json.data[i].company && json.data[i].company.account_manager ?
                                (json.data[i].company.account_manager.first_name || '') + ' ' + (json.data[i].company.account_manager.last_name || '')
                                : '',
                            acc_manager_email: json.data[i].company && json.data[i].company.account_manager ? json.data[i].company.account_manager.email : '',
                        }
                        let applied = obj.counts.find(o => o.status === 1)
                        obj['applied'] = applied?.count || 0
                        // let reviewed = obj.counts.find(o => o.status === 8)
                        // obj['reviewed'] = reviewed?.count || 0
                        // let shortlisted = obj.counts.find(o => o.status === 2)
                        // obj['shortlisted'] = shortlisted?.count || 0
                        let interviewed = obj.counts.find(o => o.status === 7)
                        obj['interviewed'] = interviewed?.count || 0
                        let hired = obj.counts.find(o => o.status === 13)
                        let rejected = obj.counts.find(o => o.status === 14)
                        obj['hired-rejected'] = (rejected?.count || 0) + (hired?.count || 0)
                        let offered = obj.counts.find(o => o.status === 17)
                        obj['offered'] = offered?.count || 0
                        let joined = obj.counts.find(o => o.status === 18)
                        obj['joined'] = joined?.count || 0
                        let waitlisted = obj.counts.find(o => o.status === 19)
                        obj['waitlisted'] = waitlisted?.count || 0
                        temp.push(obj)
                    }
                    if (hiring_type === 'ON CAMPUS') {
                        setOnCampusData(temp);
                        setHasOnCampusData(true);
                    }
                    if (hiring_type === 'OFF CAMPUS') {
                        setOffCampusData(temp);
                        setHasOffCampusData(true);
                    }
                    setLoading(false);
                }
                else {
                    FailureAlert(json.error)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, value]);

    const handleChange = (event, newValue) => { setValue(newValue); };

    const columns = [
        { title: "Job_ID", field: "job_id", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Company_Name", field: "company_name", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Account_Manager_Name", field: "acc_manager_name", headerStyle: headerCSS, cellStyle: cellCSS },
        {
            title: "Account_Manager_Email", field: "acc_manager_email", headerStyle: headerCSS, cellStyle: cellCSS,
            render: row => row.acc_manager_email ?
                <div>{row.acc_manager_email}
                    <FileCopyIcon style={createIconStyling} onClick={e => copyToClipboard(row.acc_manager_email)} />
                </div> : null,
        },
        { title: "Employment", field: "employment_type", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job_Title", field: "job_title", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job_Type", field: "job_type_name", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job_Role", field: "job_role", headerStyle: headerCSS, cellStyle: cellCSS },
        {
            title: "View_Job Details", headerStyle: headerCSS, cellStyle: cellCSS, filtering: false,
            render: row =>
                <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => viewJobDetail(row.job_id)}
                >
                    View Job
                </Button>
        },
        ...value === 0 ? [{
            title: "Colleges", headerStyle: headerCSS, cellStyle: cellCSS, filtering: false,
            render: (row) =>
                <Button
                    variant="outline-info"
                    size="sm"
                    onClick={e => loadCollegesModal(e, row.job_id)}
                >
                    Colleges
                </Button>
        }] : [],
        {
            title: "CTC_Range", headerStyle: headerCSS, cellStyle: cellCSS,
            render: row => <div>{
                row.ctc_min && row.ctc_max ? `${printNumber(row.ctc_min)}-${printNumber(row.ctc_max)}` :
                    row.ctc_min ? `${printNumber(row.ctc_min)}+}` :
                        row.ctc_max ? `< ${printNumber(row.ctc_max)}}` : null
            }</div>
        },
        {
            title: "Creation_Date", field: "create_time", headerStyle: headerCSS, cellStyle: cellCSS,
            type: 'datetime', searchable: false,
            filterComponent: (props) =>
                <DateRangePicker
                    rangeDivider="to"
                    value={props.columnDef.tableData.filterValue || dateRange}
                    onChange={e => handleChangeDate(e, props)}
                />,
            customFilterAndSearch: (term, rowData) =>
                Date.parse(term[0]) <= Date.parse(rowData.create_time) && Date.parse(term[1]) >= Date.parse(rowData.create_time)
        },
        {
            title: "Apply_Start_Date", field: 'apply_start_date', headerStyle: headerCSS, cellStyle: cellCSS,
            filterPlaceholder: 'Open Calendar', type: 'date',
        },
        {
            title: "Apply_End_Date", field: 'apply_end_date', headerStyle: headerCSS, cellStyle: cellCSS,
            filterPlaceholder: 'Open Calendar', type: 'date',
        },
        ...value === 0 ? [{
            title: "Posted_On_Job_Lake?", field: 'job_lake', headerStyle: headerCSS, cellStyle: cellCSS
        }] : [],
        { title: "Job_Stage", field: "job_stage", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Follow_Up_1", field: "follow_up1", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Follow_Up_2", field: "follow_up2", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Follow_Up_3", field: "follow_up3", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Vacancies", field: "vacancy", headerStyle: headerCSS, cellStyle: cellCSS },
        {
            title: "Applicants", field: "applicants", headerStyle: headerCSS, cellStyle: cellCSS, filtering: false,
            render: (row) => <div>{
                row.applicants > 0 ?
                    <Button
                        variant="outline-info" size="sm"
                        onClick={() => window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)}
                    >
                        {`View (${row.applicants})`}
                    </Button>
                    :
                    "No Applicants Yet"
            }</div>
        },
        {
            title: "Extra_Data", headerStyle: headerCSS, cellStyle: cellCSS, filtering: false,
            render: (row) =>
                <Button
                    variant="outline-info" size="sm"
                    onClick={e => loadExtraDataModal(e, row.job_id, row.extra_data)}
                >
                    <span style={{ whiteSpace: 'noWrap' }}>Enter Client Response</span>
                </Button>
        },
        {
            title: "Assignments", headerStyle: headerCSS, cellStyle: cellCSS, filtering: false,
            render: (row) =>
                <Button
                    variant="outline-success" size="sm"
                    onClick={e => loadAssignmentsModal(e, row.job_id)}
                >
                    Assignments
                </Button>
        },
        {
            title: "Upload_Assignment", headerStyle: headerCSS, cellStyle: cellCSS, filtering: false,
            render: (row) =>
                <Button
                    variant="outline-success" size="sm"
                    onClick={e => uploadAssignmentsModal(e, row.job_id, row.company_name, row.job_title)}
                >
                    Upload_Assignment
                </Button>
        },
        {
            headerStyle: headerCSS, cellStyle: cellCSS, export: false,
            render: row =>

                <Button variant="outline-success" size="sm" onClick={e => verifiedUnverified(e, row)} >Verify</Button>
        },
    ];


    return (
        <div className="open__close__jobs">
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="ON CAMPUS Jobs" {...a11yProps(0)} />
                    <Tab label="OFF CAMPUS Jobs" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            {
                value === 0
                    ?
                    !loading
                        ?
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <br />
                            <TableWithExport
                                title={"All On Campus Open Jobs"}
                                data={onCampusData}
                                columns={columns}
                                fName={"Selected_On_Campus_Open_Jobs"}
                            />
                        </TabPanel>
                        :
                        <img
                            src={gif}
                            alt="Getwork Loading Gif..."
                            style={gifStyling}
                        />
                    :
                    null
            }

            {
                value === 1
                    ?
                    !loading
                        ?
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <br />
                            <TableWithExport
                                title={"All Off Campus Open Jobs"}
                                data={offCampusData}
                                columns={columns}
                                fName={"Selected_Off_Campus_Open_Jobs"}
                            />
                        </TabPanel>
                        :
                        <img
                            src={gif}
                            alt="Getwork Loading Gif..."
                            style={gifStyling}
                        />
                    :
                    null
            }

            {/* Display Colleges Modal*/}
            {
                viewCollegeModal
                    ?
                    !circularLoading
                        ?
                        modalCollegesData.length > 0
                            ?
                            <Modal show={open} onHide={handleClose} scrollable={true}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Colleges</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>College Name</th>
                                                <th>Apply Start Date</th>
                                                <th>Apply End Date</th>
                                                <th>Approved?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                modalCollegesData.map((m, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{m.college_name}</td>
                                                            <td>{m.apply_start_date}</td>
                                                            <td>{m.apply_end_date}</td>
                                                            <td>{m.is_approved ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Modal.Body>
                            </Modal>
                            :
                            <Modal show={open} onHide={handleClose} scrollable={true}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Colleges</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <span style={{ display: 'block', marginLeft: '30%', fontSize: '18px', color: 'red', fontFamily: 'cursive' }}>
                                        No Colleges Added !!!
                                    </span>
                                </Modal.Body>
                            </Modal>
                        :
                        <Modal show={open} onHide={handleClose} scrollable={true}>
                            <Modal.Header closeButton>
                                <Modal.Title>Colleges</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CircularProgress
                                    style={{ display: 'block', margin: 'auto' }}
                                />
                            </Modal.Body>
                        </Modal>
                    :
                    null
            }

            {/* Assignments Modal*/}
            {
                viewAssignmentModal
                    ?
                    !circularLoading
                        ?
                        modalAssignmentsData.length > 0
                            ?
                            <Modal show={open} onHide={handleClose} scrollable={true} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>Assignment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>URL</th>
                                                <th>Deadline</th>
                                                <th>File</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                modalAssignmentsData.map((m, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{m.title}</td>
                                                            <td style={{ minWidth: '200px' }}>{m.description}</td>
                                                            <td>{m.url}</td>
                                                            <td>{m.end_date}</td>
                                                            {
                                                                m.file
                                                                    ?
                                                                    <td>
                                                                        <p
                                                                            onClick={() => window.open(m.file)}
                                                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                                        >
                                                                            View</p>
                                                                    </td>
                                                                    :
                                                                    <td> <p> No File </p> </td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Modal.Body>
                            </Modal>
                            :
                            <Modal show={open} onHide={handleClose} scrollable={true}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Assignment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <span style={{ display: 'block', marginLeft: '30%', fontSize: '18px', color: 'red', fontFamily: 'cursive' }}>
                                        No Assignment Added !!!
                                    </span>
                                </Modal.Body>
                            </Modal>
                        :
                        <Modal show={open} onHide={handleClose} scrollable={true}>
                            <Modal.Header closeButton>
                                <Modal.Title>Assignment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CircularProgress
                                    style={{ display: 'block', margin: 'auto' }}
                                />
                            </Modal.Body>
                        </Modal>
                    :
                    null
            }

            {/* Assignments Modal*/}
            {
                uploadAssignmentModal
                    ?
                    <Modal show={open} onHide={handleClose} scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>Upload an Assignment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="add__assignment__form" onSubmit={e => addAssignment(e, jobId)} >
                                <Form.Row>
                                    <Form.Group as={Col} controlId="title">
                                        <Form.Label>Assignment Title*</Form.Label>
                                        <Form.Control
                                            name="title" type="text" placeholder='Assignment Title goes here...'
                                            required onChange={e => { setTitle(e.target.value) }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="deadline">
                                        <Form.Label>Assignment Deadline*</Form.Label>
                                        <Form.Control
                                            name="deadline" type="date" placeholder="YYYY-MM-DD"
                                            required onChange={e => { setDeadline(e.target.value) }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group>
                                    <Autocomplete
                                        multiple filterSelectedOptions id="select-candidate-status"
                                        options={statusObj}
                                        getOptionLabel={(item) => item.name}
                                        onChange={(e, item) => item ? setStatusArr(item) : setStatusArr([])}
                                        renderInput={(params) => <TextField {...params} label="Select Candidate Status" variant="outlined" />}
                                    />
                                </Form.Group>
                                <Form.Group controlId="description">
                                    <Form.Control
                                        name="description" as="textarea" rows="2"
                                        placeholder='Assignment Description goes here...'
                                        required onChange={e => { setDescription(e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="url">
                                    <Form.Control
                                        name="url" type="text" placeholder='Assignment URL (if applicable)'
                                        onChange={e => { setUrl(e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.File
                                    id="formcheck-api-custom"
                                    custom style={{ marginBottom: '3%' }}
                                >
                                    <Form.File.Input onChange={e => setFile(e.target.files[0])} isValid />
                                    <Form.File.Label data-browse="Upload File">
                                        Click to choose file from local directory
                                    </Form.File.Label>
                                    {
                                        file ?
                                            <Form.Control.Feedback type="valid">{file.name}</Form.Control.Feedback>
                                            : null
                                    }
                                </Form.File>
                                <Button id="upload-assignment-btn" type='submit'>Upload</Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    :
                    null
            }

            {/* Extra Data Modal*/}
            {
                viewExtraDataModal
                    ?
                    <Modal show={open} onHide={handleClose} scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>Extra Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="add__assignment__form" onSubmit={e => editExtraData(e, jobId)} >
                                <Form.Group controlId="jobStage">
                                    <Form.Label style={labelStyle}>Job Stage: </Form.Label>
                                    <Form.Control
                                        as="select" onChange={e => { setJobStage(e.target.value) }}
                                    >
                                        {
                                            jobStage ?
                                                <option value={jobStage}>--{jobStage}--</option>
                                                :
                                                <option value=''>--Select--</option>
                                        }
                                        <option value='No Action'>No Action</option>
                                        <option value='Follow Up 1'>Follow Up 1</option>
                                        <option value='Follow Up 2'>Follow Up 2</option>
                                        <option value='Follow Up 3'>Follow Up 3</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="followUp1">
                                    <Form.Label style={labelStyle}>Follow Up 1: </Form.Label>
                                    <Form.Control
                                        name="followUp1" type="text" placeholder='Follow Up 1'
                                        value={followUp1} onChange={e => { setFollowUp1(e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="followUp2">
                                    <Form.Label style={labelStyle}>Follow Up 2: </Form.Label>
                                    <Form.Control
                                        name="followUp2" type="text" placeholder='Follow Up 2'
                                        value={followUp2} onChange={e => { setFollowUp2(e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="followUp3">
                                    <Form.Label style={labelStyle}>Follow Up 3: </Form.Label>
                                    <Form.Control
                                        name="followUp3" type="text" placeholder='Follow Up 3'
                                        value={followUp3} onChange={e => { setFollowUp3(e.target.value) }}
                                    />
                                </Form.Group>
                                <Button style={{ display: 'block', margin: 'auto' }} type='submit'>Save Changes</Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    :
                    null
            }

            {/* Close Jobs Modal*/}
            {
                modalCloseJobData.job_id
                    ?
                    <Modal
                        show={open}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{modalCloseJobData.job_title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Are you sure you want to close this job ?
                            </p><br />
                            <Form>
                                <Form.Label>Kindly enter the reason to close this job*</Form.Label>
                                <Form.Group controlId="closeJobReason">
                                    <Form.Control
                                        name="closeJobReason" type="text" placeholder="Reason to Close this job goes here..."
                                        required onChange={e => { setCloseReason(e.target.value) }}
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                            <Button variant="danger" onClick={e => closeParticularJob(modalCloseJobData.job_id)}>Close Job</Button>
                        </Modal.Footer>

                    </Modal>
                    :
                    null
            }
        </div>

    )
}

export default VerifyJobs;
