import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import { validateDateTime } from '../../util/printNumber';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';


function TotalHiredStudents() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall(`/api/admin/job/track/hired/`, requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        applicant_id:   json.data[i].applicant_id,
                        first_name:     json.data[i].applicant?.first_name || '',
                        last_name:      json.data[i].applicant?.last_name || '',
                        email:          json.data[i].applicant?.email || '',
                        phone:          json.data[i].applicant?.phone || '',
                        degree:         json.data[i].applicant?.degree || '',
                        specialization: json.data[i].applicant?.specialization || '',
                        college:        json.data[i].college?.college_name || '',
                        company:        json.data[i].company?.company_name || '',
                        create_time:    validateDateTime(json.data[i].create_time),
                        update_time:    validateDateTime(json.data[i].update_time),
                        job_id:         json.data[i].job_id || '',
                        job_title:      json.data[i].job_title || '',
                    }
                    obj['applicant_name'] = obj.first_name + ' ' + obj.last_name;
                    tmpArray.push(obj)
                }
                setData(tmpArray);
                setLoading(false);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "Applicant_ID",    field: "applicant_id",     headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Applicant_Name",  field: "applicant_name",   headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Job_Title",       field: "job_title",        headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Company_Name",    field: "company",          headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Email",           field: "email",            headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Phone",           field: "phone",            headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "College_Name",    field: "college",          headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Degree",          field: "degree",           headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Specialization",  field: "specialization",   headerStyle: headerCSS,  cellStyle: cellCSS, },
        { title: "Hired_date",      field: "update_time",      headerStyle: headerCSS,  cellStyle: cellCSS, type: 'datetime', },
    ]

    return (
        !loading
        ?
        <div className="all__final__year__students">
            <TableWithExport 
                title = "Hired Students"
                data = {data}
                columns = {columns}
                fName = "Selected Hired Students"
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default TotalHiredStudents;