import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { niyuktiAxiosGetReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { DateSelector } from "../../components/common/DateSelector";

function DownloadReport(props) {
  const [dateFilter, setDateFilter] = useState(
    props?.filters?.advanceFilterDateValue
  );
  const [vendorDate, setVendorDate] = useState(
    props?.filters?.advanceFilterDateValue
  );
  const [selfDate, setSelfDate] = useState(
    props?.filters?.advanceFilterDateValue
  );

  const [loading, setLoading] = useState(false);

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleSubmitVendor = () => {
    if (vendorDate) {
      setLoading(true);
      niyuktiAxiosGetReq(
        `/api/manage_jobs/admin/daily_data_vendor/?start_date=${convert(
          vendorDate[0]
        )}&end_date=${convert(vendorDate[1])}`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert("Mail Sent Successfully!!");
            setVendorDate(false);
          } else {
            FailureAlert(res.data.error);
            setVendorDate(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
      setLoading(false);
    } else {
      FailureAlert("Add Date Range");
    }
  };

  const handleSubmitSelf = () => {
    if (selfDate) {
      setLoading(true);
      niyuktiAxiosGetReq(
        `/api/manage_jobs/admin/daily_data_self_applied/?start_date=${convert(
          selfDate[0]
        )}&end_date=${convert(selfDate[1])}`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert("Mail Sent Successfully!!");
            setSelfDate(false);
          } else {
            FailureAlert(res.data.error);
            setSelfDate(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
      setLoading(false);
    } else {
      FailureAlert("Add Date Range");
    }
  };
  const handleSubmit = () => {
    if (dateFilter) {
      setLoading(true);

      niyuktiAxiosGetReq(
        `/api/manage_jobs/admin/daily_data_recruiter/?start_date=${convert(
          dateFilter[0]
        )}&end_date=${convert(dateFilter[1])}`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert("Mail Sent Successfully!!");
            setDateFilter(false);
          } else {
            FailureAlert(res.data.error);
            setDateFilter(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
      setLoading(false);
    } else {
      FailureAlert("Add Date Range");
    }
  };
  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="h6">Recruiter Report</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <DateSelector
              dateRange={[props?.startDate, props?.endDate]}
              setAdvanceFilterDateValue={setDateFilter}
              advanceFilterDateValue={dateFilter}
              isAdvanceFilter={true}
              clearDates={true}
              justifyContent="space-between"
              removeDateFilter={() => {
                setDateFilter([null, null]);
              }}
              label={"Date Range"}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 10, width: "170px", height: 45 }}
              onClick={handleSubmit}
            >
              {!loading ? (
                "Download"
              ) : (
                <CircularProgress color="#fff" size={20} />
              )}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="h6">Vendor Report</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <DateSelector
              dateRange={[props?.startDate, props?.endDate]}
              setAdvanceFilterDateValue={setVendorDate}
              advanceFilterDateValue={vendorDate}
              isAdvanceFilter={true}
              clearDates={true}
              justifyContent="space-between"
              removeDateFilter={() => {
                setVendorDate([null, null]);
              }}
              label={"Date Range"}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 10, width: "170px", height: 45 }}
              onClick={handleSubmitVendor}
            >
              {!loading ? (
                "Download"
              ) : (
                <CircularProgress color="#fff" size={20} />
              )}
            </Button>
          </Box>
        </Grid>{" "}
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="h6">Self Applied Report</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <DateSelector
              dateRange={[props?.startDate, props?.endDate]}
              setAdvanceFilterDateValue={setSelfDate}
              advanceFilterDateValue={selfDate}
              isAdvanceFilter={true}
              clearDates={true}
              justifyContent="space-between"
              removeDateFilter={() => {
                setSelfDate([null, null]);
              }}
              label={"Date Range"}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 10, width: "170px", height: 45 }}
              onClick={handleSubmitSelf}
            >
              {!loading ? (
                "Download"
              ) : (
                <CircularProgress color="#fff" size={20} />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default DownloadReport;
