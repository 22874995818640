import { Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import UseAxiosApi from "../../../Api/UseAxiosApi";
import { praveshUrl } from "../../../constants";
import AllCandidates from "./AllCandidates";
import CustomSource from "./CustomSource";
import SourceJob from "./SourceJob";
import SourceFromJobComponent from "./SourceFromJobs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const AntTabs = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "transparent",
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    backgroundColor: "transparent",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    marginTop: "10px",
    backgroundColor: "transparent",
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

export default function CustomizedTabs({ jobId }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(2);
  const [customSourcing, setCustomSourcing] = useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showSourceResult, setShowSourceResult] = useState(false);
  const [sourceResultsList, setSourceResultsList] = useState(null);
  const [sourceResultQuery, setSourceResultQuery] = useState(null);
  const [payloadReady, setPayloadReady] = useState(false);

  const [payload, setPayload] = useState(null);
  const [sourceFromJob, setSourceFromJob] = useState(null);
  const [searchSelected, setSearchSelected] = React.useState(null);
  const [selectedJobs, setSelectedJobs] = React.useState([]);

  const clear = () => {
    setSourceFromJob(null);
    setCustomSourcing(null);
    setSearchSelected(null);
    setSourceResultsList([]);
  };

  const helper = (data) => {
    setSourceResultsList(data?.data);
    // setSourceResultQuery(customSourcing);
    setShowSourceResult(true);
  };

  const { loading, error, apiCall } = UseAxiosApi({
    url: `${praveshUrl}/api/admin/operations/candidate/source/`,
    method: "post",
    mountingCallFlag: false,
    callBackFn: helper,
  });

  const handleSubmitQuery = (sourceQuery, type, expCtc) => {
    const sendQuery = (body) => {
      setPayload(body);
      setSourceResultQuery(sourceQuery);
      apiCall(JSON.stringify(body), true);
    };

    if (type === "customQuery") {
      let body = {};
      const jobSegmentIds = [];
      const jobRoleIds = [];
      const skillsIds = [];
      const locationIds = [];
      const preferedLocationsIds = [];
      const stateIds = [];
      const catIds = [];
      const eduLevelIds = [];
      const noticePeriodsArr = [];
      const degreeIds = [];
      const specializationIds = [];
      let expLevelArr = [];

      sourceQuery?.jobSegments?.map((item) => {
        jobSegmentIds?.push(item?.job_segment_id);
      });
      sourceQuery?.jobRoles?.map((item) => {
        jobRoleIds?.push(item?.id);
      });
      sourceQuery?.skills?.map((item) => {
        skillsIds?.push(item?.skill_id);
      });
      sourceQuery?.locations?.map((item) => {
        locationIds?.push(item?.city_id);
      });
      sourceQuery?.preferedLocations?.map((item) => {
        preferedLocationsIds?.push(item?.city_id);
      });
      sourceQuery?.states?.map((item) => {
        stateIds?.push(item?.state_id);
      });

      sourceQuery?.eduLevels?.map((item) => {
        eduLevelIds?.push(item?.id);
      });
      sourceQuery?.clgCategories?.map((item) => {
        catIds?.push(item?.id);
      });
      sourceQuery?.degrees?.map((item) => {
        degreeIds?.push(item?.id);
      });
      sourceQuery?.specializations?.map((item) => {
        specializationIds?.push(item?.id);
      });

      sourceQuery?.noticePeriods?.map((item) => {
        item === "Immediate"
          ? noticePeriodsArr?.push("7")
          : noticePeriodsArr?.push(item?.split(" ")[0]);
      });

      sourceQuery?.expLevels?.map((item) => {
        expLevelArr?.push(item.min);
        expLevelArr?.push(item.max);
      });
      expLevelArr = [...new Set(expLevelArr)];
      if (expLevelArr?.length > 0)
        body.experience = {
          min: Math.min(...expLevelArr),
          max: Math.max(...expLevelArr),
        };

      if (jobSegmentIds?.length > 0) body.job_segment_ids = jobSegmentIds;
      if (jobRoleIds?.length > 0) body.job_role_ids = jobRoleIds;

      if (noticePeriodsArr?.length > 0) body.notice_periods = noticePeriodsArr;
      if (locationIds?.length > 0) body.city_ids = locationIds;

      if (stateIds?.length > 0) body.state_ids = stateIds;
      if (eduLevelIds?.length > 0) body.education_ids = eduLevelIds;
      if (degreeIds?.length > 0) body.degree_ids = degreeIds;
      if (specializationIds?.length > 0)
        body.specialization_ids = specializationIds;
      if (catIds?.length > 0) body.category_ids = catIds;

      if (
        sourceQuery?.expCtc &&
        (sourceQuery?.expCtc[0] > 0 || sourceQuery?.expCtc[1] > 0)
      ) {
        body.ctc = {
          min: sourceQuery?.expCtc[0],
          max: sourceQuery?.expCtc[1],
        };
      }

      /* ##################################################################################################### */
      if (skillsIds?.length > 0) body.skills = skillsIds;
      if (preferedLocationsIds?.length > 0)
        body.preferred_locations = preferedLocationsIds;
      if (
        sourceQuery?.currentCtc &&
        (sourceQuery?.currentCtc[0] > 0 || sourceQuery?.currentCtc[1] > 0)
      ) {
        body.current_ctc = {
          min: sourceQuery?.currentCtc[0],
          max: sourceQuery?.currentCtc[1],
        };
      }
      /* ############################################################################# */

      if (Object?.keys(body)?.length > 0) {
        sendQuery(body);
      }
    } else if (type === "jobQuery") {
      let body2 = {};
      // if (sourceQuery?.payload?.job_segment_id)
      //   body2.job_segment_ids = sourceQuery?.payload?.job_segment_id;
      // if (sourceQuery?.payload?.job_role_id)
      //   body2.job_role_ids = sourceQuery?.payload?.job_role_id;

      if (sourceQuery?.payload?.location_ids?.length > 0)
        body2.city_ids = sourceQuery?.payload?.location_ids;
      if (sourceQuery?.payload?.experience_dict)
        body2.experience = sourceQuery?.payload?.experience_dict; /* {
          min: parseInt(sourceQuery?.payload?.experience[0]?.split("-")[0]),
          max: parseInt(
            sourceQuery?.payload?.experience[0]?.split("-")[1]?.split(" ")[0]
          ),
        }; */
      if (sourceQuery?.payload?.ctc_max && sourceQuery?.payload?.ctc_min)
        body2.ctc = {
          min: sourceQuery?.payload?.ctc_min,
          max: sourceQuery?.payload?.ctc_max,
        };
      // education_ids: [5, 1, 15],
      // degree_ids: [5, 1, 13],
      if (sourceQuery?.payload?.skill?.length > 0) {
        let skillsIds = [];
        sourceQuery?.payload?.skill?.map((item) => {
          skillsIds?.push(item?.skill_id);
        });
        body2.skills = skillsIds;
      }

      if (Object?.keys(body2)?.length > 0) {
        sendQuery(body2);
      }
    } else if (type === "multipleJobs"){
      let jobIds = [];
      selectedJobs?.forEach(item => jobIds.push(item?.job_id));
      sendQuery({job_ids: jobIds});
    }
  };

  useEffect(() => {
    if (jobId) {
      setShowSourceResult(true);
    }
  }, [jobId]);

  const [jobFromSourceJob, setJobFromSourceJob] = useState(null);
  // useEffect(() => {
  // }, [jobFromSourceJob]);

  return (
    <>
      {!showSourceResult ? (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
            >
              <AntTab label="Custom Sourcing" {...a11yProps(0)} />
              <AntTab label="Source for a Job" {...a11yProps(1)} />
              <AntTab label="Source: Existing Jobs Applicants" {...a11yProps(2)} />
              {/* <AntTab label="Tab 2"  wrapped {...a11yProps(1)} /> */}
            </AntTabs>
          </div>
          <TabPanel value={value} index={0}>
            <CustomSource
              value={value}
              setShowSourceResult={setShowSourceResult}
              handleSubmitQuery={handleSubmitQuery}
              setCustomSourcing={setCustomSourcing}
              customSourcing={customSourcing}
              submitLoading={loading}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SourceJob
              value={value}
              setShowSourceResult={setShowSourceResult}
              handleSubmitQuery={handleSubmitQuery}
              setSourceFromJob={setSourceFromJob}
              setJobFromSourceJob={setJobFromSourceJob}
              sourceFromJob={sourceFromJob}
              searchSelected={searchSelected}
              setSearchSelected={setSearchSelected}
              submitLoading={loading}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SourceFromJobComponent
              value={value}
              setShowSourceResult={setShowSourceResult}
              handleSubmitQuery={handleSubmitQuery}
              setSourceFromJob={setSourceFromJob}
              setJobFromSourceJob={setJobFromSourceJob}
              sourceFromJob={sourceFromJob}
              searchSelected={searchSelected}
              setSearchSelected={setSearchSelected}
              submitLoading={loading}
              selectedJobs={selectedJobs}
              setSelectedJobs={setSelectedJobs}
            />
          </TabPanel>
        </div>
      ) : (
        <div style={{ marginTop: 15 }}>
          <AllCandidates
            value={value}
            sourceResultQuery={sourceResultQuery}
            setSourceResultQuery={setSourceResultQuery}
            setShowSourceResult={setShowSourceResult}
            setValue={setValue}
            sourceResultsList={sourceResultsList}
            setSourceResultsList={setSourceResultsList}
            clear={clear}
            from={value === 1 ? "sourceJob" : "Custom"}
            payload={payload}
            jobId={jobFromSourceJob}
          />
        </div>
      )}
    </>
  );
}
