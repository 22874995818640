import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { useEffect } from "react";
import { praveshAxiosCall, praveshAxiosGetReq } from "../../../../util/apiCall";
import { SuccessAlert, FailureAlert } from "../../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
    width: 400,
    boxShadow: "none",
    border: "1px solid #B0B6BA",
    height: "40px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    //padding: 10,
    boxShadow: "none",
    height: "40px",
    marginRight: "-1px",
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SearchBox({
  tagsName,
  setTagsName,
  setTagsRefreshed,
  setTagsApiCall,
  setRemoveViewMore,
}) {
  const classes = useStyles();
  const [tagToSearch, setTagToSearch] = useState(null);
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    const getAllTags = async () => {
      const endPoint = `/api/admin/bulk/get/tags/`;

      try {
        const res = await praveshAxiosGetReq(endPoint);

        if (res?.data?.success) {
          const temp = res?.data?.data.map((item) => {
            return item.name;
          });
          setAllTags(temp);
        } else {
          FailureAlert("Some Error Occurred!");
        }
      } catch (error) {
        FailureAlert("Some Error Occurred!");
        throw error;
      }
    };

    getAllTags();
  }, []);

  const searchForTag = () => {
    if (tagToSearch) {
      setTagsName([]);

      setTagsRefreshed(true);

      let temp = [];
      temp.push(tagToSearch);

      setTagsName(temp);
      setTagsRefreshed(false);
      setRemoveViewMore(true);
    } else {
      // reset for blank search
      setTagsApiCall();
    }
  };

  // so that api calls are made for seleted tag to search and for empty tags.
  useEffect(() => {
    searchForTag();
  }, [tagToSearch]);

  return (
    <>
      <Form
        className="select_job_Tags"
        style={{
          display: "flex",
          width: "60%",
          // marginTop:"10px",
        }}
      >
        <Autocomplete
          id="select-user"
          size="small"
          options={allTags ? allTags : tagsName}
          getOptionLabel={(item) => item}
          style={{
            width: "90%",
            height: "10px",
            marginRight: "20px",
            //  margin: '5%'
          }}
          onChange={(e, item) =>
            item
              ? setTagToSearch(item)
              : setTagsApiCall() && setTagToSearch(null)
          }
          renderInput={(params) => (
            <TextField {...params} label="Select tagName" variant="outlined" />
          )}
        />
        {/* <Button
                    color="primary"
                    className={classes.iconButton}
                    aria-label="directions"
                    variant="contained"
                    onClick={searchForTag}
                  >
                    Search
                </Button> */}
      </Form>
      {/* <Paper 
     component="form" 
     className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <SearchIcon />
      </IconButton>

       
      <InputBase
        className={classes.input}
        placeholder="Search Tags"
        inputProps={{ "aria-label": "Search Tags" }}
      />

      <Button
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        variant="contained"
      >
        Search
      </Button>
    </Paper>
     */}
    </>
  );
}
