import React, { useState, useEffect, useRef } from "react";
import { apiCall, praveshAxiosGetReq } from "../../util/apiCall";
import {
  headerCSS,
  cellCSS,
  gifStyling,
  createIconStyling,
  requestPraveshOptions,
  praveshUrl,
} from "../../constants";
import TableWithExport from "../TableWithExport";
import { Button } from "react-bootstrap";
import gif from "../../assets/loopingcircles-3.gif";
import { validateDateTime } from "../../util/printNumber";
import { SuccessAlert, FailureAlert } from "../../util/Notifications";
import default_logo from "../../assets/default_logo.png";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../util/copyToClipboard";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { DateSelector } from "../common/DateSelector";
import QueryString from "query-string";
import { useHistory, withRouter } from "react-router";
import CustomButtonCircularProgress from "../loader";
import UseAxiosApi from "../../Api/UseAxiosApi";
import Pagination from "@material-ui/lab/Pagination";
import { Typography } from "@material-ui/core";

const token = localStorage.getItem("token");

const requestForBlock = {
  method: "PATCH",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  },
  body: JSON.stringify({
    is_blocked: true,
  }),
};

const requestForUnblock = {
  method: "PATCH",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  },
  body: JSON.stringify({
    is_blocked: false,
  }),
};

function AllColleges(props) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [states, setStates] = useState({}); // For Filter purpose
  const [industries, setIndustries] = useState({}); // For Filter purpose
  const [category, setCategories] = useState({}); // For Filter purpose
  const [coworkingsFilter, setCoworkingsFilter] = useState({}); // For Filter purpose
  const [loading, setLoading] = useState(false);
  const [isInitialCall, setInitialCall] = useState(true);
  const dateRange = [new Date(2021, 0, 1), new Date()];
  //for download all data
  const dateString = useRef("");
  const [canDownload, setCanDownload] = useState(false);

  // for passing to dropdown city list filter
  const [cityList, setCityList] = useState();
  const [companyNameList, setCompanyNameList] = useState();
  const history = useHistory();

  useEffect(() => {
    // if its initial call
    // and there is nothing in url then startdate and enddate should be today and t-30
    //  else get it from url
    if (isInitialCall) {
      let initialStartDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      );
      let initialEndDate = new Date();

      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = queryParams.from;
      }
      if (queryParams.to) {
        initialEndDate = queryParams.to;
      }
      setStartDate(new Date(initialStartDate));
      setEndDate(new Date(initialEndDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blockUnblock = (e, row) => {
    e.preventDefault();
    const request =
      row.is_blocked === "Yes" ? requestForUnblock : requestForBlock;
    apiCall(
      `/api/admin/company/edit/?company_id=${row.company_id}`,
      request
    ).then((res) => {
      if (res.success) {
        SuccessAlert("Action Successful");
        setCount(count + 1);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const [pageNo, setPageNo] = useState(1);
  const [metaData, setMetaData] = useState({
    totalCount: 0,
    totalPages: 1,
  });

  useEffect(() => {
    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      props.history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      dateString.current = `?start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (count === 0 && startDate === "") return;

    let dateString = "";

    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      props.history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      dateString = `start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }

    let endPoint = `/api/admin/company/?page=${pageNo}&${dateString}`;
    setLoading(true);
    setInitialCall(false);

    if (pageNo > 0)
      apiCall(endPoint, requestPraveshOptions).then((json) => {
        if (json.success) {
          let count = json.data?.count;
          setMetaData({
            totalCount: count,
            totalPages: Math.ceil(count / 10),
          });
          let tmpArray = [];
          const data = json.data?.results;
          for (var i = 0; i < data.length; i++) {
            let obj = {
              company_id: data[i].company_id
                ? parseInt(data[i].company_id)
                : "",
              company_name: data[i].company_name,
              gstin: data[i].gstin,
              registeredName: data[i].registered_company_name,
              paymentStatus: data[i].company_name,
              coworking_id: data[i].coworking_id
                ? parseInt(data[i].coworking_id)
                : "",
              coworking_name: data[i].coworking_name || "",
              industry_id: data[i].industry_id
                ? parseInt(data[i].industry_id)
                : "",
              industry_name: data[i].industry_name,
              country: data[i].country,
              state: data[i].state,
              city: data[i].city,
              address: data[i].address?.replace(/<[^>]+>/g, "") || "",
              create_time: validateDateTime(data[i].create_time),
              update_time: validateDateTime(data[i].update_time),
              size: data[i].size,
              user_name: data[i].company_user
                ? data[i].company_user.first_name +
                  " " +
                  data[i].company_user.last_name
                : "",
              user_email: data[i].company_user
                ? data[i].company_user.email
                : "",
              user_phone: data[i].company_user
                ? data[i].company_user.phone
                : "",
              alternate_email: data[i].company_user
                ? data[i].company_user.alternate_email
                : "",
              alternate_phone: data[i].company_user
                ? data[i].company_user.alternate_phone
                : "",
              user_designation: data[i].company_user
                ? data[i].company_user.job_title
                : "",
              last_login:
                data[i].company_user && data[i].company_user.last_login
                  ? validateDateTime(data[i].company_user.last_login)
                  : "",
              logo: data[i].logo,
              is_blocked: data[i].is_blocked ? "Yes" : "No",
              is_onboarded: data[i].is_onboarded ? "Yes" : "No",
              is_admin_created: data[i].is_admin_created ? "Yes" : "No",
              is_onboarded_date: data[i].is_onboarded_date || "",
              website: data[i].website || "",
              hired_count: data[i].hired_count,
              job_count: data[i].job_count,
              job_post_date: data[i].job_post_date || "",
              acc_manager_name: data[i].account_manager
                ? (data[i].account_manager.first_name || "") +
                  " " +
                  (data[i].account_manager.last_name || "")
                : "",
              acc_manager_email: data[i].account_manager
                ? data[i].account_manager.email
                : "",
              sales_person_name: data[i]?.sales_person?.name
                ? data[i]?.sales_person?.name
                : null,
              company_category: data[i]?.category ? data[i]?.category : "",
              domain: data[i]?.domain ? data[i]?.domain : "",
              last_job_posted_Date: data[i]?.job_post_date || "",
              is_brand: data[i]?.is_brand,
            };
            tmpArray.push(obj);

            const citylist1 = [...new Set(tmpArray.map((item) => item.city))];

            const citylist3 = citylist1.reduce(
              (a, v) => ({ ...a, [v]: v }),
              {}
            );

            setCityList(citylist3);

            const companyList1 = [
              ...new Set(tmpArray.map((item) => item.company_name)),
            ];

            const companyList2 = companyList1.reduce(
              (a, v) => ({ ...a, [v]: v }),
              {}
            );

            setCompanyNameList(companyList2);
          }

          setLoading(false);
          setData(tmpArray);
        } else {
          FailureAlert(json.error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate, pageNo]);

  const downloadCallbackFn = (data) => {
    SuccessAlert(data?.message ?? "Link to download data will arrive in mail");
  };

  const endPoint = `${praveshUrl}/api/admin/company/${dateString.current}&is_export=True`;

  const { apiCall: downloadAllDataCSV, loading: loadingAllDataDownload } =
    UseAxiosApi({
      url: endPoint,
      mountingCallFlag: canDownload,
      callBackFn: downloadCallbackFn,
    });

  useEffect(() => {
    if (canDownload) {
      downloadAllDataCSV();
    }
  }, [canDownload]);

  // State Finding API
  useEffect(() => {
    apiCall(`/api/location/state/?search=1`, requestPraveshOptions).then(
      (res) => {
        let obj = {};
        res.forEach((state) => {
          obj[state.state] = state.state;
        });
        setStates(obj);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Industry Finding API
  useEffect(() => {
    apiCall("/api/admin/industry/", requestPraveshOptions).then((json) => {
      if (json.success) {
        let obj = {};
        json.data.forEach((industry) => {
          obj[industry.industry_name] = industry.industry_name;
        });
        setIndustries(obj);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for getting company category list:

  const getCompanyCategoryList = async () => {
    const endPoint = `/api/shared/get/company/type/`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        let obj = {};
        res?.data?.data?.forEach((cat) => {
          obj[cat?.type] = cat?.type;
        });

        setCategories(obj);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (Object?.keys(category)?.length === 0) {
      getCompanyCategoryList();
    }
  }, []);

  // CoWorking Finding API
  useEffect(() => {
    apiCall("/api/admin/coworking/", requestPraveshOptions).then((json) => {
      if (json.success) {
        let obj = {};
        json.data.forEach((coworking) => {
          obj[coworking.name] = coworking.name;
        });
        setCoworkingsFilter(obj);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companySizes = {
    "1-10": "1-10",
    "11-50": "11-50",
    "51-100": "51-100",
    "101-1000": "101-1000",
    "1000+": "1000+",
  };

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  const columns = [
    {
      title: "Company ID",
      field: "company_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
    },
    {
      title: "Company Name",
      field: "company_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      // lookup: companyNameList,
      filterPlaceholder: "Select",
      filterCellStyle: {
        overflow: "hidden",
        minWidth: 200,
        maxWidth: 200,
      },
      render: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            placeItems: "center",
            width: "250px",
            fontSize: "13px",
          }}
        >
          {row.logo ? (
            <img
              src={row.logo}
              alt="logo"
              width="30px"
              height="30px"
              style={{ borderRadius: "50%", marginRight: "3px" }}
            />
          ) : (
            <img
              src={default_logo}
              alt="logo"
              width="30px"
              height="30px"
              style={{ borderRadius: "50%", marginRight: "3px" }}
            />
          )}{" "}
          {`${row.company_name}`}
        </div>
      ),
    },
    {
      title: "Registered Company Name",
      field: "registeredName",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 200,
        fontSize: 13,
      },
      searchable: false,
      // lookup: ,
      filterPlaceholder: "Registered Name",
    },

    {
      title: "GSTIN",
      field: "gstin",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 200,
        fontSize: 13,
      },
      searchable: false,
      filterPlaceholder: "GSTIN",
      // render:((row) =>   row.gstin)
    },
    // {
    //   title: "Payment Status",
    //   field: "paymentStatus",
    //   headerStyle: headerCSS,
    //   cellStyle:  {
    //      minWidth: 180,
    //      maxWidth: 200,
    //      fontSize:13,
    //   },
    //   searchable: false,
    //   filterPlaceholder: "pay status",
    //   lookup:{Pending:'Pending', Done:'Done'},
    //   customFilterAndSearch:(term, rowData) => {

    //     console.log(term,'where is the term');
    //     for(let i in term){
    //       if(term[i] === rowData?.status) {
    //         return true;
    //        }else{
    //        return false
    //        }
    //     };
    //     if(term.length < 1){
    //       return true;
    //     }
    //   },
    //   // render:((row) =>   row.gstin)
    // },

    {
      title: "Industry_Type",
      field: "industry_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: industries,
      filterPlaceholder: "Industry",
    },
    {
      title: "Company Category",
      field: "company_category",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: category,
      filterPlaceholder: "Category",
    },
    {
      title: "Size",
      field: "size",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: companySizes,
      filterPlaceholder: "Size",
    },
    {
      title: "State",
      field: "state",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: states,
      filterPlaceholder: "Select",
    },
    {
      title: "City",
      field: "city",
      headerStyle: headerCSS,
      cellStyle:
        //  cellCSS,
        {
          minWidth: 150,
          maxWidth: 200,
          fontSize: 13,
        },
      searchable: false,
      lookup: cityList,
      filterPlaceholder: "Select",
      filterCellStyle: {
        overflow: "hidden",
        minWidth: 200,
        maxWidth: 200,
      },
    },

    {
      title: "Address",
      field: "address",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 200,
        fontSize: 13,
      },
      render: (row) => (row?.address ? row?.address : null),
      // (<div> No Match found</div>)
      // cellCSS,
    },
    {
      title: "POC_Name",
      field: "user_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "POC_Email",
      field: "user_email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          {row.user_email ? (
            <span>
              {row.user_email}
              <FileCopyIcon
                style={createIconStyling}
                onClick={(e) => copyToClipboard(row.user_email)}
              />
            </span>
          ) : null}
          {row.user_email && row.alternate_email ? "/ " : ""}
          {row.alternate_email ? (
            <span>
              {row.alternate_email}
              <FileCopyIcon
                style={createIconStyling}
                onClick={(e) => copyToClipboard(row.alternate_email)}
              />
            </span>
          ) : null}
        </div>
      ),
    },
    {
      title: "POC_Phone",
      field: "user_phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.user_phone ? (
          <div>
            {row.user_phone}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.user_phone)}
            />
          </div>
        ) : null,
    },
    {
      title: "POC_Designation",
      field: "user_designation",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },

    {
      title: "Last_Login",
      field: "last_login",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
    },

    {
      title: "CoWorking_Name",
      field: "coworking_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: coworkingsFilter,
      filterPlaceholder: "CoWorking",
    },

    {
      title: "Country",
      field: "country",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
    },
    {
      title: "Sales_Person_Name",
      field: "sales_person_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Account_Manager_Name",
      field: "acc_manager_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Account_Manager_Email",
      field: "acc_manager_email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.acc_manager_email ? (
          <div>
            {row.acc_manager_email}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.acc_manager_email)}
            />
          </div>
        ) : null,
    },

    {
      title: "Blocked",
      field: "is_blocked",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterPlaceholder: "Yes/No",
      lookup: { Yes: "Yes", No: "No" },
    },
    {
      title: "Onboarded",
      field: "is_onboarded",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterPlaceholder: "Yes/No",
      lookup: { Yes: "Yes", No: "No" },
    },
    {
      title: "Onboarded Date",
      field: "is_onboarded_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "date",
      searchable: false,
      filterPlaceholder: "Open Calendar",
      customFilterAndSearch: (term, rowData) =>
        new Date(term).toISOString().substring(0, 10) ===
        rowData.is_onboarded_date.substring(0, 10),
    },
    {
      title: "Website URL",
      field: "website",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Domain",
      field: "domain",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Brand",
      field: "is_brand",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <Typography style={{ fontSize: "13px" }}>
          {row.is_brand ? "Yes" : "No"}
        </Typography>
      ),
    },
    {
      title: "Last_Updated",
      field: "update_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
    },

    {
      title: "Creation_Date",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.create_time) &&
        Date.parse(term[1]) >= Date.parse(rowData.create_time),
    },
    {
      title: "Hired",
      field: "hired_count",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      type: "numeric",
    },
    {
      title: "Posted Jobs",
      field: "job_count",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      type: "numeric",
    },
    {
      title: "Last Job Posted Date",
      field: "last_job_posted_Date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "date",
      searchable: false,
      filterPlaceholder: "Open Calendar",
      customFilterAndSearch: (term, rowData) =>
        new Date(term).toISOString().substring(0, 10) ===
        rowData?.last_job_posted_Date?.substring(0, 10),
    },
    {
      title: "Job Posted Date",
      field: "job_post_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "date",
      searchable: false,
      filterPlaceholder: "Open Calendar",
      customFilterAndSearch: (term, rowData) =>
        new Date(term).toISOString().substring(0, 10) ===
        rowData.job_post_date.substring(0, 10),
    },
    {
      title: "Status",
      field: "is_blocked",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      export: false,
      lookup: { No: "Block", Yes: "Unblock" },
      render: (row) => (
        <div>
          {row.is_blocked === "No" ? (
            <Button
              variant="outline-danger"
              size="sm"
              onClick={(e) => blockUnblock(e, row)}
              style={{
                fontSize: 13,
              }}
            >
              Block
            </Button>
          ) : (
            <Button
              variant="outline-success"
              size="sm"
              onClick={(e) => blockUnblock(e, row)}
            >
              Unblock
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Edit Company",
      field: "editCompany",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.company_id ? (
          <div>
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => {
                history.push(
                  `/admin/new-edit-company?companyId=${row?.company_id}`
                );
              }}
            >
              Edit
            </Button>
          </div>
        ) : null,
    },
    {
      title: "Excel Uploaded?",
      field: "is_admin_created",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterPlaceholder: "Yes/No",
      lookup: { Yes: "Yes", No: "No" },
    },
  ];

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
      setPageNo(1);
    }
  };

  const removeDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setCount(count + 1);
    setPageNo(1);
  };

  return !loading ? (
    <div className="all__companies">
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <DateSelector
          dateRange={[startDate, endDate]}
          applyDateFilter={applyDateFilter}
          removeDateFilter={removeDateFilter}
        />
        <Button
          style={{
            width: "12rem",
            height: "2.5rem",
          }}
          onClick={() => {
            setCanDownload(true);
            downloadAllDataCSV();
          }}
        >
          {loadingAllDataDownload ? (
            <CustomButtonCircularProgress />
          ) : (
            " Download All Data"
          )}
        </Button>
      </div>
      <>
        <TableWithExport
          title="All Companies Details"
          data={data}
          columns={columns}
          fName="Selected_Companies"
          hasPaging={false}
        />
        <section style={{ float: "right", marginTop: "1rem" }}>
          <Pagination
            page={pageNo || 1}
            onChange={(e, value) => {
              setPageNo(value);
            }}
            count={metaData?.totalPages}
            color="primary"
          />
        </section>
      </>
    </div>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default withRouter(AllColleges);
