import { AppBar, Button, Grid, Tab, Tabs } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import { DateSelector } from "../common/DateSelector";
import TableWithExport from "../TableWithExport";

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "2px",
    marginBottom: "10px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const token = localStorage.getItem("token");

function GetworkClubCourse(props) {
  const classes = useStyles();
  const [userCreatedColleges, setUserCreatedCollges] = useState(props.userCreated ? 1 : 0);

  const [count, setCount] = useState(0);
  const loading = false;

  const theme = useTheme();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // useEffect(() => console.log(startDate.toISOString()), [startDate]);

  const handleChangeToggle = (event, newValue) => {
    setUserCreatedCollges(newValue);
  };

  const columns = [
    {
      title: "Category",
      field: "category",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Category",
    },

    {
      title: "Brand Name",
      field: "brand_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //    lookup: states,
      filterPlaceholder: "Brand Name",
    },
    {
      title: "Title",
      field: "title",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      //   filtering: false,
      // lookup: primaryFilter,
      filterPlaceholder: "Title",
    },
    {
      title: "Tags",
      field: "tags",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      //lookup: primaryFilter,
      filterPlaceholder: "Tags",
    },
    {
      title: "Created By",
      field: "created_by",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: states,
      filterPlaceholder: "Select",
    },
    {
      title: "Regular Price",
      field: "regular_price",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      // lookup: primaryFilter,
      filterPlaceholder: "Short Title",
    },
    {
      title: "Discount",
      field: "discount",
      headerStyle: headerCSS,
      cellStyle: cellCSS,

      filterPlaceholder: "Price on Getwork",
    },
    {
      title: "Price on Getwork",
      field: "price_getwork",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Price on Getwork",
    },
    {
      title: "What you'll learn",
      field: "learn",
      headerStyle: headerCSS,
      cellStyle: cellCSS,

      // filterPlaceholder: "Price on Getwork",
    },
    {
      title: "Curriculum",
      field: "curriculum",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Curriculum",
    },
    {
      title: "This course includes",
      field: "course_includes",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Course includes",
    },
    {
      title: "Requirement",
      field: "requirement",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Description",
      field: "description",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Instructor Profile",
      field: "instructor_profile",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
  ];

  const [data, setData] = useState([
    {
      category: "Designing",
      brand_name: "Mehmet",
      title: "photoshop",
      tags: "Graphic Design",
      created_by: "User Name",
      regular_price: 299.0,
      discount: "17%",
      price_getwork: 299.0,
      learn: "N/A",
      curriculum: "Lecture",
      course_includes: "Videos  & Exercise File",
      requirement: "Mobile/Laptop",
      description: "It is a Design...",
      instructor_profile: "Instructor Profile",
    },
    {
      category: "Designing",
      brand_name: "Mehmet",
      title: "photoshop",
      tags: "Graphic Design",
      created_by: "User Name",
      regular_price: 299.0,
      discount: "17%",
      price_getwork: 299.0,
      learn: "N/A",
      curriculum: "Lecture",
      course_includes: "Videos  & Exercise File",
      requirement: "Mobile/Laptop",
      description: "It is a Design...",
      instructor_profile: "Instructor Profile",
    },
  ]);

  const seccolumns = [
    {
      title: "Name",
      field: "name",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Name",
    },

    {
      title: "Tagline",
      field: "tag_line",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //    lookup: states,
      filterPlaceholder: "Tag Line",
    },

    {
      title: "Tags",
      field: "tags",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      //lookup: primaryFilter,
      filterPlaceholder: "Tags",
    },
    {
      title: "About Me",
      field: "about_me",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
    },
    {
      title: "Website",
      field: "web",
      headerStyle: headerCSS,
      cellStyle: cellCSS,

      filtering: false,
    },
    {
      title: "Facebook",
      field: "facebook",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "YouTube",
      field: "youtube",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "My Courses",
      field: "my_course",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      // filterPlaceholder: "Price on Getwork",
    },
  ];

  const secdata = [
    {
      name: "Jatin SM",
      tag_line: "learn to Design",
      tags: "Graphic Design",
      about_me: "Jose Marcial Portilla has a BS and MS in",
      web: "Website.in",
      facebook: "m.facebook.com",
      youtube: "Youtube.in",
      my_course: "Adobe Pho...(5)",
    },
    {
      name: "Jatin SM",
      tag_line: "learn to Design",
      tags: "Graphic Design",
      about_me: "Jose Marcial Portilla has a BS and MS in",
      web: "Website.in",
      facebook: "m.facebook.com",
      youtube: "Youtube.in",
      my_course: "Adobe Pho...(5)",
    },
  ];

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
    }
  };

  const removeDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const edit = {
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          updateRow(newData);
          setData([...dataUpdate]);
          resolve();
        }, 1000);
      }),
  };

  const updateRow = (newData) => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        degree_id: newData.degree_id,
        degree_name: newData.degree_name,
        user_created: newData.degree_user_created === "Yes" ? true : false,
      }),
    };
    apiCall(`/api/admin/degree/all/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCount(count + 1);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  return (
    <div className="all__colleges">
      <AppBar position="static" color="default">
        <Tabs value={userCreatedColleges} onChange={handleChangeToggle} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
          <Tab label="ALL COURSES" {...a11yProps(0)} />
          <Tab label="Instructors Profile" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}>
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} />
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" style={{ minWidth: "145px" }} color="primary" startIcon={<AddIcon className={classes.icon} />}>
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>

          <TableWithExport title="Courses/Certification" columns={columns} data={data} fName="Selected_Colleges_Not_User_Created" editableObject={edit} />
        </TabPanel>
      ) : (
        <TabPanel value={userCreatedColleges} index={1} dir={theme.direction}>
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} />
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" color="primary" style={{ minWidth: "145px" }} startIcon={<AddIcon className={classes.icon} />}>
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>
          <TableWithExport title="All Instructor" columns={seccolumns} data={secdata} fName="Selected_Colleges_User_Created" editableObject={edit} />
        </TabPanel>
      )}
    </div>
  );
}

export default withRouter(GetworkClubCourse);
