import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import generateCsv from "../../util/generateCsv";
import { FailureAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "2px",
    marginBottom: "10px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const token = localStorage.getItem("token");

function AllClubVendors(props) {
  const classes = useStyles();
  const [userCreatedColleges, setUserCreatedCollges] = useState(props.userCreated ? 1 : 0);
  const history = useHistory();
  const [loading, setLoading] = useState(false);


  const columns = [
    {
      title: "Vendor Name",
      field: "company_name",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      render: (row) => (
        <div style={{ color: "green", cursor: "pointer" }} onClick={() => history.push(`/admin/add-club-companies/${row?.id}`)}>
          {row.company_name} &nbsp; &nbsp; &nbsp;
          <EditIcon style={{ cursor: "pointer" }} />
        </div>
      ),
      filterPlaceholder: "Vendor",
    },
    {
      title: "Brand Name",
      field: "brand_name",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Brand",
    },
    {
      title: "Website",
      field: "website",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Website",
      filtering: false,
    },
    {
      title: "POC's",
      field: "poc_list",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "POC",
      filtering: false,
    },
  ];

  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/vendors/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            item["poc_list"] = generateCsv(item.poc_list, "name");
          });

          setDataCategory(json);
        } else FailureAlert("Link History was not fetched. Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  return (
    <div className="all__colleges">
      {/* <AppBar position="static" color="default">
        <Tabs value={userCreatedColleges} onChange={handleChangeToggle} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
          <Tab label="ALL COURSES" {...a11yProps(0)} />
          <Tab label="Instructors Profile" {...a11yProps(1)} />
        </Tabs>
      </AppBar> */}

      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <>
          {/* <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}> */}
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{ minWidth: "145px" }}
                  color="primary"
                  onClick={() => {
                    history.push("/admin/add-club-companies");
                  }}
                  startIcon={<AddIcon className={classes.icon} />}
                >
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>
          <TableWithExport title="Vendors/Companies" columns={columns} data={dataCategory} fName="Selected_Colleges_Not_User_Created" />
          {/* </TabPanel> */}
        </>
      ) : (
        <></>
        // <TabPanel value={userCreatedColleges} index={1} dir={theme.direction}>
        //   <Grid container spacing={3} className={classes.action}>
        //     <Grid
        //       item
        //       xs={10}
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} />
        //     </Grid>
        //     <Grid item xs={2}>
        //       <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //         <Button variant="contained" color="primary" style={{ minWidth: "145px" }} startIcon={<AddIcon className={classes.icon} />}>
        //           Add New
        //         </Button>
        //       </div>
        //     </Grid>
        //   </Grid>
        //   <TableWithExport title="All Instructor" columns={seccolumns} data={secdata} fName="Selected_Colleges_User_Created" editableObject={edit} />
        // </TabPanel>
      )}
    </div>
  );
}

export default withRouter(AllClubVendors);
