import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function CompanyDomain() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [domainName, setDomainName] = useState('');
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [patchCompany, setPatchCompany] = useState(null);
    const [loading, setLoading] = useState(false);

    // Company Finding API
    useEffect(() => {
      apiCall('/api/company/company_list/', requestPraveshOptions)
      .then((res)=> {
          setCompanies(res.data.all_companies)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/company_domain/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:            parseInt(json.data[i].id),
                        company_name:  json.data[i].company_name,
                        domain_name:   json.data[i].domain_name,
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ 
            domain_name: domainName,
            company: company
        })
    };

    const columns = [
        { title: "Domain ID",     field: "id",            headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Domain Name",   field: "domain_name",   headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Company Name",  field: "company_name",  headerStyle: headerCSS, cellStyle: cellCSS, 
          editComponent: props => (
            <Select
                onChange={e=> {setPatchCompany(JSON.parse(e.target.value).id)
                    props.onChange(JSON.parse(e.target.value).company)
                } }
            >
            {
                companies.map((c, key) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(c)} >
                        {c.company}
                    </MenuItem>
                )})
            }
            </Select>
          ) 
        },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData,oldData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        })
    }

    const updateRow = (newData, oldData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                ...(oldData.domain_name!==newData.domain_name && {domain_name: newData.domain_name}),
                ...(patchCompany && {company: patchCompany})
            })
        };
        apiCall(`/api/admin/company_domain/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setPatchCompany(null);
            }
            else { FailureAlert(res.error); }
            setCount(count+1);
        })
    }

    const addCompanyDomain = (e) => {
      e.preventDefault();
      apiCall('/api/admin/company_domain/', requestOptionsPost)
      .then((res) => {
          if(res.success) {
              SuccessAlert(res.data.message);
              setCount(count+1);
          }
          else {
              FailureAlert(res.error);
          }
      })
      e.target.reset();
    }

    return (
        !loading
        ?
        <div className="company__domain">
            <div className="company__domain__form">
                <h4>Add New Company Domain</h4>
                <Form onSubmit={e=>addCompanyDomain(e)}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="domainName">
                            <Form.Label>Domain Name*</Form.Label>
                            <Form.Control 
                                name="domainName" 
                                type="text" 
                                placeholder="Enter Domain Name"
                                onChange={e=> {setDomainName(e.target.value)}}
                                required 
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="company">
                            <Form.Label>Company</Form.Label>
                            <Form.Control 
                                as="select"
                                onChange={e=> {setCompany(JSON.parse(e.target.value).id)} }
                            >
                                <option value={JSON.stringify({"id":null})}>--select--</option>
                            {
                                companies.map((company, key) => {
                                return (
                                    <option key={key} value={JSON.stringify(company)} >
                                        {company.company}
                                    </option>
                                )})
                            }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Button type="submit" variant="info">Add Domain</Button>
                    
                </Form>    
            </div>
            <TableWithExport 
                title = "Company Domain"
                data = {data}
                columns = {columns}
                fName = "Selected Company Domain"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default CompanyDomain;