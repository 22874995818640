import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { praveshAxiosGetReq } from "../../util/apiCall";
import { useEffect } from "react";
import { useState } from "react";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import {
  CheckOutlined,
  FlashOn,
  GroupAdd,
  LockOpenOutlined,
  PersonAddOutlined,
  QueryBuilderOutlined,
  WorkOutlineOutlined,
} from "@material-ui/icons";
import { faL } from "@fortawesome/free-solid-svg-icons";
import {
  useHistory,
  useParams,
  useNavigate,
} from "react-router-dom/cjs/react-router-dom";

function MySubscriptions() {
  const history = useHistory();
  const { company_name } = useParams();
  const { company_id } = useParams();
  const [planName, setPlanName] = useState("gdgd");
  const [profileUnlocks, setProfileUnlocks] = useState("");
  const [jobPosting, setJobPosting] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [avilableUnlocks, setAvilableUnlocks] = useState("");
  const [freeUnlocks, setFreeUnlocks] = useState("");
  const [paidUnlocks, setPaidUnlocks] = useState("");
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTableData();
    fetchSubscriptionData();
  }, []);

  const fetchTableData = async () => {
    const endPoint = `/api/admin/company/plan_details/unlock_history/?company_id=${company_id}`;
    try {
      setLoading(true);
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        let data = res?.data?.data?.results;
        setRow(
          data.map((item) => ({
            id: item?.id,
            transaction_type: item?.transaction_type,
            action_type: item?.action_type,
            no_of_unlocks: item?.no_of_unlock,
            available_unlocks: item?.remaining_unlocks,
            action_by: item?.name,
            transaction_date: item?.date,
          }))
        );
      }
    } catch (error) {
      console.log("Error : ", error);
    }
    setLoading(false);
  };

  const fetchSubscriptionData = async () => {
    const endPoint = `/api/admin/company/plan_details/subscriptions/?company_id=${company_id}`;
    try {
      setLoading(true);
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        let data = res?.data?.data;
        setPlanName(data?.plan?.name);
        setProfileUnlocks(data?.plan?.unlocks);
        setJobPosting(data?.plan?.jobs);
        setExpireDate(data?.plan?.next_billing_data);
        setAvilableUnlocks(data?.profile_unlocks?.available_credits);
        setFreeUnlocks(data?.profile_unlocks?.free_credits);
        setPaidUnlocks(data?.profile_unlocks?.paid_credits);
      }
    } catch (error) {
      console.log("error 2");
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 200,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 200,
    },
    {
      field: "action_type",
      headerName: "Action Type",
      width: 250,
    },
    {
      field: "no_of_unlocks",
      headerName: "No. of Unlocks",
      width: 200,
    },
    {
      field: "available_unlocks",
      headerName: "Available Unlocks",
      width: 200,
    },
    {
      field: "action_by",
      headerName: "Action by",
      width: 200,
    },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      width: 200,
    },
  ];
  const rows = [
    {
      id: 1,
      transaction_type: "DEBIT",
      action_type: "Profile Unlocked in Source",
      no_of_unlocks: 280,
      available_unlocks: 140,
      action_by: "Prerit 2",
      transaction_date: "06:32:11 PM, 19-Jun-2023",
    },

    {
      id: 2,
      transaction_type: "DEBIT",
      action_type: "Profile Unlocked in Source",
      no_of_unlocks: 280,
      available_unlocks: 140,
      action_by: "Prerit 2",
      transaction_date: "06:32:11 PM, 19-Jun-2023",
    },
    {
      id: 3,
      transaction_type: "DEBIT",
      action_type: "Profile Unlocked in Source",
      no_of_unlocks: 280,
      available_unlocks: 140,
      action_by: "Prerit 2",
      transaction_date: "06:32:11 PM, 19-Jun-2023",
    },
    {
      id: 4,
      transaction_type: "DEBIT",
      action_type: "Profile Unlocked in Source",
      no_of_unlocks: 280,
      available_unlocks: 140,
      action_by: "Prerit 2",
      transaction_date: "06:32:11 PM, 19-Jun-2023",
    },
  ];

  return (
    <>
      {loading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        <div style={{ lineHeight: "2rem" }}>
          <p>
            <a
              style={{ backgroundColor: "#f8f9fa" }}
              onClick={() =>
                history.push(
                  `/admin/plan-unlock-history/?company_id=${company_id}`
                )
              }
            >
              My Plans
            </a>{" "}
            / <span className="company-name">{company_name}</span>
          </p>
          <h2 style={{ marginBottom: "2rem" }}>My Subscriptions</h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "40%",
                boxShadow: "1px 1px 5px 1px rgb(211, 210, 210)",
                padding: "1rem 2rem 1rem 1rem",
                borderRadius: "0.5rem",
              }}
            >
              <h4>Current active plan</h4>
              {planName || profileUnlocks || jobPosting || expireDate ? (
                <div>
                  <div className="sub-menu">
                    <ul className="left">
                      <li>
                        <FlashOn /> Plan name
                      </li>
                      <li>
                        <PersonAddOutlined /> Profile Unlocks
                      </li>
                      <li>
                        <WorkOutlineOutlined /> Job/ Internship Posting
                      </li>
                      <li>
                        <QueryBuilderOutlined /> Expiry Date
                      </li>
                    </ul>
                    <ul className="right">
                      <li>{planName}</li>
                      <li>{profileUnlocks}</li>
                      <li>{jobPosting}</li>
                      <li>{expireDate}</li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="sub-menu">No Current Active Plan</div>
              )}
            </div>

            <div
              style={{
                width: "40%",
                boxShadow: "1px 1px 5px 1px rgb(211, 210, 210)",
                padding: "1rem 2rem 1rem 1rem",
                borderRadius: "0.5rem",
              }}
            >
              <h4>Profile Unlocks</h4>
              {avilableUnlocks || paidUnlocks || freeUnlocks ? (
                <div>
                  <div className="sub-menu">
                    <ul className="left">
                      <li>
                        <LockOpenOutlined /> Available Unlocks
                      </li>
                      <li>
                        <GroupAdd /> Paid Unlocks
                      </li>
                      <li>
                        <CheckOutlined /> Free Unlocks
                      </li>
                    </ul>
                    <ul className="right">
                      <li>{avilableUnlocks}</li>
                      <li>{paidUnlocks}</li>
                      <li>{freeUnlocks}</li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="sub-menu">No Current Active Plan</div>
              )}
            </div>
          </div>
          <div className="purchase" style={{ height: "25rem", width: "100%" }}>
            <h2 style={{ marginTop: "2rem" }}>Unlock History</h2>
            <DataGrid rows={row} columns={columns} />
          </div>
        </div>
      )}
    </>
  );
}

export default MySubscriptions;
