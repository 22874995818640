import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import AddProjectDialog from "./AddProjectDialog";
import SaveProject from "./SaveProjcet";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
}));

export default function Project({projects, handleAddProject, handlePatchProject, handleDeleteProject}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tempProjects, setTempProjects] = useState([]);
  const [addProject, setAddProject] = useState({type: 'Project'});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    if(projects)
      setTempProjects(projects);
  }, [projects])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setAddProject({});
    setOpen(false);
  }; 

  const saveProject = () => {
    isEdit ? handlePatchProject(addProject, addProject?.id, editIndex, handleClose, "Project") : handleAddProject(addProject, handleClose, "Project");
  }

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Projects</Typography>
        <br />
        {tempProjects && tempProjects.map((project, index) => {
          return (
        <SaveProject data = {project} projectIndex={index} setAddProject={setAddProject} handleDelete={handleDeleteProject} handleOpen={handleClickOpen} setIsEdit={setIsEdit} setEditIndex={setEditIndex}/>
          )
        })
}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            color="primary"
            onClick={handleClickOpen}
          >
            Add Project
          </Button>

          {/* <Button variant="contained" color="primary">
            Save
          </Button> */}
        </div>
      </div>
      <AddProjectDialog open={open} editProject={addProject} setEditProject={setAddProject} handleClose={handleClose} saveProject={saveProject}/>
    </>
  );
}
