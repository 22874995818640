import React from "react";
import Charst from "react-apexcharts";

function getDayFromDate(dateString) {
  if (!dateString) return "";
  const parts = dateString.split("/");
  const day = parseInt(parts[0], 10);
  return day;
}

function GraphWithTableThree({ colors, graphData, tableTitle }) {
  const options = {
    xaxis: {
      title: {
        text: "Dates",
        style: {
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      categories: graphData?.x_axis,
      labels: {
        formatter: function (value, timestamp, opts) {
          return getDayFromDate(value);
        },
      },
    },
    chart: {
      toolbar: {
        export: {
          csv: {
            headerCategory: "vnbn",
          },
        },
      },
    },

    colors: colors,
    markers: {
      size: 4,
      colors: colors,
      hover: {
        size: 4,
      },
    },

    dataLabels: {
      enabled: false,
      offsetY: -20,
      style: {
        fontSize: "10px",
        fontFamily: "Roboto",
        colors: ["#304758"],
      },
    },

    yaxis: {
      title: {
        text: tableTitle,
        style: {
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
    fill: {
      gradient: {
        enabled: false,
        opacityFrom: 0,
        opacityTo: 0,
      },
    },
    plotOptions: {
      area: {
        horizontal: false,
        borderRadius: 8,
        dataLabels: {
          position: "top",
        },
      },
    },
    title: {
      text: "",
      style: {
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 1000,
        cssClass: "apexcharts-yaxis-title",
      },
    },
    stroke: {
      width: 1,
    },
    grid: {
      clipMarkers: true,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],

    tooltip: {
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return graphData?.x_axis[dataPointIndex];
        },
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return value;
        },
      },
    },
  };

  return (
    <div>
      <Charst
        options={options}
        series={graphData?.y_axis || []}
        type="area"
        height={420}
      />

      {/* <div>
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          {[1, 2, 3, 4, 5].map((item, id) => (
            <>
              <div
                style={{
                  width: "20%",
                  paddingLeft: "2%",
                  paddingRight: "1%",
                  borderRight: id === 4 ? "" : "1px solid #E1DFDF",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={6} style={{ position: "relative" }}>
                    <div
                      style={{
                        height: "2px",
                        width: "100%",
                        backgroundColor: color,
                      }}
                    >
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: color,
                          position: "absolute",
                          top: "4px",
                          left: "50%",
                        }}
                      ></div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <Typography style={{ fontSize: "14px" }}>
                      Team Lead {id === 0 ? 1 : id * 4 + 1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={6} style={{ position: "relative" }}>
                    <div
                      style={{
                        height: "2px",
                        width: "100%",
                        backgroundColor: secondColor,
                      }}
                    >
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: secondColor,
                          position: "absolute",
                          top: "4px",
                          left: "50%",
                        }}
                      ></div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <Typography style={{ fontSize: "14px" }}>
                      Team Lead {id === 0 ? 2 : id * 4 + 2}
                    </Typography>
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={6} style={{ position: "relative" }}>
                    <div
                      style={{
                        height: "2px",
                        width: "100%",
                        backgroundColor: thirdColor,
                      }}
                    >
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: thirdColor,
                          position: "absolute",
                          top: "4px",
                          left: "50%",
                        }}
                      ></div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <Typography style={{ fontSize: "14px" }}>
                      Team Lead {id === 0 ? 3 : id * 4 + 3}
                    </Typography>
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={6} style={{ position: "relative" }}>
                    <div
                      style={{
                        height: "2px",
                        width: "100%",
                        backgroundColor: color,
                      }}
                    >
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: color,
                          position: "absolute",
                          top: "4px",
                          left: "50%",
                        }}
                      ></div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <Typography style={{ fontSize: "14px" }}>
                      Team Lead {id === 0 ? 4 : id * 4 + 4}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </>
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default GraphWithTableThree;
