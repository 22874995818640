import React, { useState, useEffect } from 'react';
import { apiCall } from '../../util/apiCall';
import "../../css/Dashboard.css";
import { gifStyling, requestPraveshOptions, manager_URLS } from '../../constants';

import { FailureAlert } from '../../util/Notifications';
import gif from '../../assets/loopingcircles-3.gif';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardActions, CardContent, Button, Typography} from "@material-ui/core";
import { MonthSelector } from '../common/MonthSelector';
import {Link} from 'react-router-dom';
import { checkDateFilter } from '../../util/checkDateFilter';


    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginLeft: '2rem'
        },
        card: {
            height: "240px",
            width: "250px",
            marginBottom: "1.5rem",
            marginRight: "2rem",
        },
        card_title: {
            height: "100px"
        },
        button: {
            marginLeft: "18px",
            width: "200px"
        },
        control: {
            padding: theme.spacing(2)
        }
    }));


function ManagerDashboard(props) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();   

    const [selectedDate, setSelectedDate] = useState(new Date());


    const refineMonth = () =>{
         var month = ("0" + (selectedDate.getMonth() + 1)).slice(-2);
         const year = selectedDate.getFullYear();
         return year + "-" + month;
    }

    const getNewData = (monthValue) => {
        if(monthValue){ 
        setLoading(true)
        apiCall(`/api/admin/manager-dashboard/?year_month=${refineMonth()}`, requestPraveshOptions)
          .then((json) => {
                if(json.success) {
                    setData(json.data); 
                    setLoading(false);
                }         
                else FailureAlert(json.error)
           })
    }
}
 const getManagerUrl = (id, currentDate) => {

    let dateToBeAdded = checkDateFilter(currentDate)
    return manager_URLS[id] + dateToBeAdded
}
 
    // Fetching Dashboard Details
    useEffect(() => {
        getNewData(selectedDate.toISOString().substring(0,7))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const applyDateFilter = () => { 
        getNewData(selectedDate.toISOString().substring(0,7))

    }

    const renderButton = ({ id, url }) => {
        if (url) {
            return (
                <Link to={url}>
                    <Button
                        className={classes.button} variant="contained" color="primary"> View
                          </Button>
                </Link>
            )
        }
        if (id in manager_URLS) {
            return (
                <Link to={getManagerUrl(id, selectedDate)}>
                    <Button
                        className={classes.button} variant="contained" color="primary"> View
                          </Button>
                </Link>
            )
        }

        return (<Button
            style={{ cursor: "not-allowed" }}
            variant="contained" className={classes.button}>
                 View
                </Button>);
    }
    
    return (

        !loading
        ?
        <div className="admin-dashboard"> 
        <MonthSelector 
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            applyDateFilter={applyDateFilter} 
         />
            {data.map((item, k1) => {
                return (
                <div key={k1}>
                    <br />
                    <b><h3>
                        <span
                            style={{ 
                                marginLeft: '4px', background: "#BCBCBC93", text: "#171414", 
                                padding: "10px", borderRadius: "15px",
                            }}  
                        > {item.user}
                        </span>
                    </h3></b>
                    <br />
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                            {item.cards.map((card, value) => (
                                <Grid key={value} item>
                                <Card 
                                    className={classes.card} 
                                    style={{background: '#d1ebf9', boxShadow: "5px 7px 5px #9E9E9E"}}
                                >
                                    <CardContent>
                                    <Typography
                                        className={classes.card_title}
                                        gutterBottom variant="h5"
                                        component="h2" style={{ fontSize: "125%" }}
                                    >
                                        <b>{card.title}</b>
                                    </Typography>
                                    <Typography
                                        align="center" variant="h5"
                                        color="textSecondary" component="h5"
                                    >
                                        {card.count}
                                    </Typography>
                                    </CardContent>
                                    <CardActions style={{ alignItems: "center" }}>
                                        {
                                            renderButton(card)
                                        }
                                    </CardActions>
                                </Card>
                                </Grid>
                            ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                );
            })}
        </div>
        :
        <img 
            src={gif}
            style={gifStyling}
            alt="Getwork Logo"
        />
    );
}

export default ManagerDashboard;