import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import BulkUserCreate from '../../assets/Bulk_User_Create.xlsx';
import gif from '../../assets/loopingcircles-3.gif';
import CustomButtonCircularProgress from '../../components/loader';
import TableWithExport from '../../components/TableWithExport';
import { cellCSS, gifStyling, headerCSS } from '../../constants';
import '../../css/UploadStudentExcel.css';
import { apiCall } from '../../util/apiCall';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function BulkCreate() {    

    const [data, setData] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState(null);
    const [excel, setExcel] = useState(null);
    const [visible, setVisible] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);

    const userType = [{
        id: "STUDENT",
        name: "STUDENT"
    },{
        id: "COLLEGE",
        name: "COLLEGE"
    },{
        id: "COMPANY",
        name: "COMPANY"
    },
];

    // // Getting List of all Colleges
    // useEffect(() => {
    //     setLoading(true);
    //     apiCall('/api/admin/college/get/', requestPraveshOptions)
    //     .then((res)=> {
    //         if(res?.success) {
    //             setColleges(res?.data)
    //         }
    //         else {
    //             FailureAlert(res?.error)
    //         }
    //         setLoading(false);
    //     })
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleChange = () => {
        setChecked(!checked);
      };

    const uploadExcel = (e) => {
        e.preventDefault();
        // if(collegeId===null) { FailureAlert("Please select a college to continue"); return; }
        if(excel===null) { FailureAlert("Upload file..."); return; }
        const ext = excel.name.substring(excel.name.lastIndexOf('.') + 1);
        if(ext!=='xlsx' && ext!=='xls') { FailureAlert('Upload Excel File Only...') }
        else {

            const formData = new FormData();
            formData.append('file', excel);
            setLoadingUpdateBtn(true);
            const requestOptionsPost = {
                method: 'POST',
                headers: { 
                    'Authorization': `Token ${token}`
                },
                body: formData  
            };

            apiCall(`/api/admin/bulk_user_creation/?user_type=${selectedUserType}`, requestOptionsPost)
              .then((res) => {
                if(res?.success) {
                    let tmp = []
                    // {res.data.error_list.map(function(d, idx){return (<li key={idx}>{d.sl_no}</li>)})}
                    tmp.push({ error_type: 'total_entries',             error_count: res.data.total_entries})
                    tmp.push({error_type: 'user_created_count',         error_count: res.data.user_created_count})
                    tmp.push({error_type: 'phone_already_exists',         error_count: res.data.phone_already_exist})
                    tmp.push({error_type: 'email_already_exist',        error_count: res.data.email_already_exist})
                    tmp.push({error_type: 'already_exist_data',         error_count: res.data.already_exist_data.length})
                    tmp.push({ error_type: 'error_list_count',          error_count: res.data.error_list_count})
                    res.data.error_list.map((item)=>{
                        tmp.push({error_type:'sl_no', error_count:item.sl_no})
                        tmp.push({error_type:'error', error_count:item.error})
                    })
                    //console.log("the data",res.data.error_list)
                    // tmp.push({ error_type: 'Current Year Errors',    error_count: res.data.errors['Current Year Errors'].length})
                    // tmp.push({ error_type: 'Gender Errors',          error_count: res.data.errors['Gender Errors'].length})
                    // tmp.push({ error_type: 'Ethnicity Error',        error_count: res.data.errors['Ethnicity Error'].length})
                    // tmp.push({ error_type: 'Veteran Status Error',   error_count: res.data.errors['Veteran Status Error'].length})
                    setResponse(res.data)
                    setData(tmp);
                    SuccessAlert('Upload Successful...')
                    window.document.getElementById('add__student__excel__card__form').reset();
                }
                else {
                    FailureAlert(res?.error)
                }

                setLoadingUpdateBtn(false);
              })
        }
    }

    const columns = [
        { title: "Error Type",   field: "error_type",     headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Error Count",  field: "error_count",    headerStyle: headerCSS, cellStyle: cellCSS },
    ]    

    return (
        !loading
        ?
        <div>
            <Card className="add__student__excel__card" style={{marginBottom:'10px'}}>
                <h3>Upload User Data</h3><br />
                <Form className="add__student__excel__form" id="add__student__excel__card__form" onSubmit={e=>{uploadExcel(e)}}>
                    <span>Please select user type to continue!</span> <br /><br/>
                    <Form.Group className="typeSearchCollege">
                        <Autocomplete
                            id="select-college"
                            disabled= {loadingUpdateBtn}
                            options={userType}
                            getOptionLabel={(item) => `${item?.name}`}
                                onChange={(e, item) => {
                                    if (item) {
                                        setSelectedUserType(item?.id)
                                        
                                    }
                                    else setSelectedUserType(null);
                                }}
                            renderInput={(params) => <TextField {...params} label="Select User Type" variant="outlined" />}
                        />
                    </Form.Group>
                    <small>Upload File (Excel Format only)</small>
                    <Form.File
                        disabled= {loadingUpdateBtn}
                        type="file"
                        onChange={e=> { setExcel(e.target.files[0]); 
                            if (excel){setVisible(true)}}}

                    /><br />
                
                    <Button type="submit" variant="primary" style={{
                        minWidth:200,

                    }}>
                        
                        {loadingUpdateBtn ? <CustomButtonCircularProgress/> : "Update Details" }
                        </Button>
                </Form>
            </Card>
            <div className="add__student__response">
                {
                data.length===0 ?
                    <div style={{ width: "86%", margin: "auto" }}>
                        <h3>Instructions:</h3>
                        i. Excel sheet should include following column names:{" "}
                        <b><br/>
                        <i>1. Sl. No*, 2. Name*, 3.Email*, 4. Phone*, 5. utm_source, 6.utm_medium, 7. utm_campaign, 8.utm_term, 9., utm_content </i>
                        </b>
                        .<br />
                        <br />
                        ii. <a
                            href={BulkUserCreate}
                            style={{fontSize:'18px', color:'blue', backgroundColor:'transparent'}}    
                        >
                        Click here to download sample file.</a>
                        <br />
                        <br />
                    </div> 
                    : 
                    <div>
                        <h6>Total number of Entries: {response.total_entries ? response.total_entries : 0}</h6>
                        <h6>Total Created Entries: {response.user_created_count ? response.user_created_count : 0}</h6>
                        <h6>Total Failed Entries: {response.error_list_count ? response.error_list_count : 0}</h6>
                        <br />
                    </div>
                }
                {
                    response.error_list_count > 0 
                    ? 
                    <TableWithExport 
                        title = "Error List"
                        data = {data}
                        columns = {columns}
                        fName = "Selected Errors"
                    />
                    :
                    null
                }
            </div>
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default BulkCreate;