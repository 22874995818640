import React, { useEffect, useState } from 'react'
import { Col, Form } from "react-bootstrap";
import {
    AppBar, Tabs, Tab, TextField, CircularProgress, Box, Radio, RadioGroup, Checkbox, FormControlLabel
} from "@material-ui/core";
import draftToHtml from "draftjs-to-html";
import { Autocomplete } from "@material-ui/lab";
import { praveshAxiosCall } from '../../../util/apiCall';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { FailureAlert } from '../../../util/Notifications';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    keyvalue: {
        fontWeight: 600,
        fontSize: 16,
    },

}))



const LocationDetails = ({ newCompanyDetails, setNewCompanyDetails, cities, states, }) => {

    const classes = useStyles();

    const [fullAddress, setFullAddress] = useState(EditorState?.createWithContent(ContentState?.createFromBlockArray(convertFromHTML(newCompanyDetails?.companyBasicInfo?.fullAddress ? newCompanyDetails?.companyBasicInfo?.fullAddress : " "))));

    const [hqAddress, setHqAddress] = useState(EditorState?.createWithContent(ContentState?.createFromBlockArray(convertFromHTML(newCompanyDetails?.companyBasicInfo?.fullAddress ? newCompanyDetails?.companyBasicInfo?.fullAddress : " "))));


    return (
        <div style={{ marginTop: "30px" }}>
            <Form
                className="edit__poc__details__form"
                id="edit__poc__details__form__details"
            >
                <Form.Row>
                    <Form.Group as={Col} controlId="pocdetails">
                        <Autocomplete
                            id="select-state"
                            options={states}
                            getOptionLabel={(item) => item.state}
                            value={newCompanyDetails?.companyBasicInfo?.state}
                            onChange={(e, item) => {
                                setNewCompanyDetails({
                                    ...newCompanyDetails,
                                    companyBasicInfo: {
                                        ...newCompanyDetails?.companyBasicInfo,
                                        state: item
                                    }
                                });
                            }
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select State*"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="pocdetails">
                        <Autocomplete
                            id="select-city"
                            options={cities}
                            getOptionLabel={(item) => item.city}
                            value={newCompanyDetails?.companyBasicInfo?.city}
                            onChange={(e, item) => {
                                setNewCompanyDetails({
                                    ...newCompanyDetails,
                                    companyBasicInfo: {
                                        ...newCompanyDetails?.companyBasicInfo,
                                        city: item
                                    }
                                });
                            }
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select City*"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="companydetails">
                        <Form.Label className={classes.keyvalue}>Lattitude</Form.Label>
                        <Form.Control
                            name="lattitude"
                            type="number"
                            placeholder={"Lattitude"}
                            value={newCompanyDetails?.companyBasicInfo?.lattitude}
                            onChange={(event) =>

                                setNewCompanyDetails({
                                    ...newCompanyDetails,
                                    companyBasicInfo: {
                                        ...newCompanyDetails?.companyBasicInfo,
                                        lattitude: event.target.value,
                                    }
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="collegedetails">
                        <Form.Label className={classes.keyvalue}>Longitude</Form.Label>
                        <Form.Control
                            name="longitude"
                            type="number"
                            placeholder={"Longitude"}
                            value={newCompanyDetails?.companyBasicInfo?.longitude}
                            onChange={(event) =>

                                setNewCompanyDetails({
                                    ...newCompanyDetails,
                                    companyBasicInfo: {
                                        ...newCompanyDetails?.companyBasicInfo,
                                        longitude: event.target.value,
                                    }
                                })
                            }
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="mapLink">
                        <Form.Label className={classes.keyvalue}>Map link</Form.Label>
                        <Form.Control
                            required
                            name="mapLink"
                            type="text"
                            placeholder={"Map Url"}
                            value={newCompanyDetails?.companyBasicInfo?.mapLink}
                            onChange={(e) =>

                                setNewCompanyDetails({
                                    ...newCompanyDetails,
                                    companyBasicInfo: {
                                        ...newCompanyDetails?.companyBasicInfo,
                                        mapLink: e.target.value
                                    }
                                })
                            }
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="collegedetails">
                        <Form.Label className={classes.keyvalue}>Full Address*</Form.Label>
                        <div className="editor">
                            <Editor
                                editorState={fullAddress}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                editorStyle={{ border: "1px solid #C0C0C0", minHeight: 150 }}
                                onEditorStateChange={(state) => {
                                    setFullAddress(state);
                                    setNewCompanyDetails({
                                        ...newCompanyDetails,
                                        companyBasicInfo: {
                                            ...newCompanyDetails?.companyBasicInfo,
                                            fullAddress: draftToHtml(convertToRaw(state.getCurrentContent())),
                                        }
                                    });
                                }}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row style={{ marginTop: '-2rem', width: "100%" }}>
                    <Form.Group as={Col} controlId="collegedetails">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Same As Full Address"
                            value={newCompanyDetails?.companyBasicInfo?.hqSameAsfullAddress}
                            checked={newCompanyDetails?.companyBasicInfo?.hqSameAsfullAddress}

                            onChange={(e) => {

                                // changing checkbox value

                                setNewCompanyDetails({
                                    ...newCompanyDetails,
                                    companyBasicInfo: {
                                        ...newCompanyDetails?.companyBasicInfo,
                                        hqSameAsfullAddress: !newCompanyDetails?.companyBasicInfo?.hqSameAsfullAddress,
                                        hqAddress: draftToHtml(convertToRaw(fullAddress.getCurrentContent())),
                                    }
                                });

                                // copying and cleaning the editor field on choice of checkbox
                                if (!(e.target.value === 'true')) {

                                    setHqAddress(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(newCompanyDetails?.companyBasicInfo?.fullAddress))));

                                } else {

                                    setHqAddress(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML("<p></p>\n"))));
                                }
                            }
                            }
                        />
                    </Form.Group>

                </Form.Row>

                <Form.Row style={{ marginTop: '-2rem', width: "100%" }}>
                    <Form.Group as={Col} controlId="collegedetails">
                        <Form.Label className={classes.keyvalue}>HQ Address</Form.Label>
                        <div className="editor">
                            <Editor
                                editorState={hqAddress}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                editorStyle={{ border: "1px solid #C0C0C0", minHeight: 150 }}

                                onEditorStateChange={(state) => {
                                    setHqAddress(state)
                                    setNewCompanyDetails({
                                        ...newCompanyDetails,
                                        companyBasicInfo: {
                                            ...newCompanyDetails?.companyBasicInfo,
                                            hqAddress: draftToHtml(convertToRaw(state.getCurrentContent())),
                                        }
                                    });
                                }}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    )
}

export default LocationDetails