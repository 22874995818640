import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/SharerCategory.css';
import { gifStyling, requestPraveshOptions } from '../../constants';
import {Form, Col, Modal, Table, Button} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, CircularProgress} from '@material-ui/core';
import category_placeholder_image from '../../assets/category_placeholder.png'
import TextField from '@material-ui/core/TextField';
import {TabPanel} from '../../util/TabPanel';
import {Autocomplete} from '@material-ui/lab';

    const token = localStorage.getItem('token');
  
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
          id: `wrapped-tab-${index}`,
          'aria-controls': `wrapped-tabpanel-${index}`,
        };
    } 

function SharerSubCategory() {

    const [userTypeId, setUserTypeId] = useState(null);
    const [sharerCategoryId, setSharerCategoryId] = useState(null);
    const [sharerCategories, setSharerCategories] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategoryDescription, setSubCategoryDescription] = useState('');
    const [subCategoryImage, setSubCategoryImage] = useState(null);
    const [textColor, setTextColor] = useState('');
    const [circularLoading, setCircularLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [patchSharerCategory, setPatchSharerCategory] = useState(null);
    const [patchSubCategoryName, setPatchSubCategoryName] = useState('');
    const [patchSubCategoryDescription, setPatchSubCategoryDescription] = useState('');
    const [patchSubCategoryImage, setPatchSubCategoryImage] = useState(null);
    const [patchTextColor, setPatchTextColor] = useState('');

    const handleOpen = () => setOpen(true);

    const userTypesList = [
        {id:1, value:'Student'},
        {id:5, value:'Company Admin'},
        {id:7, value:'College TPO'},
    ]

    useEffect(() => {
        setSharerCategories([]);
        setSharerCategoryId(null);
    }, [userTypeId])

    const handleClose = () => {
        setOpen(false);
        setModalData({});
        setPatchSubCategoryName('');
        setPatchSubCategoryDescription('');
        setPatchTextColor('');
        setPatchSharerCategory(null);
        setPatchSubCategoryImage(null);
    }

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    // Fetching existing Sharer Category
    useEffect(()=> {
        setLoading(true);
        if(userTypeId) {
            apiCall(`/api/admin/sharer_category/?user_type_id=${userTypeId}`, requestPraveshOptions)
            .then((res)=> {
                setSharerCategories(res.data)
                setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userTypeId, count]);

    // Fetching existing Sharer SubCategory
    useEffect(()=> {
        setLoading(true);
        if(sharerCategoryId) {
            apiCall(`/api/admin/sharer_subcategory/?sharer_category_id=${sharerCategoryId}`, requestPraveshOptions)
            .then((res)=> {
                setData(res.data)
                setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharerCategoryId, count]);

    const editSharerCategory = (e, obj) => {
        setModalData(obj);
        setPatchSubCategoryName(obj.subcategory_name);
        setPatchSubCategoryDescription(obj.description);
        setPatchTextColor(obj.text_color);
        setPatchSharerCategory(obj.sharer_category_id)
        handleOpen();
    }

    const patchCategory = (e) => {
        e.preventDefault();
        const formData = new FormData();
        modalData.sharer_category_id !== patchSharerCategory && formData.append('sharer_category', patchSharerCategory);
        modalData.subcategory_name !== patchSubCategoryName && formData.append('subcategory_name', patchSubCategoryName);
        patchSubCategoryImage && formData.append('subcategory_image', patchSubCategoryImage);
        modalData.description !== patchSubCategoryDescription && formData.append('description', patchSubCategoryDescription);
        patchTextColor && formData.append('text_color', patchTextColor);
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: {
                'Authorization': `Token ${token}`
            },
            body: formData,
        };
        apiCall(`/api/admin/sharer_subcategory/${modalData.id}/`, requestOptionsPatch)
            .then((res) => {
                if(res.success) {
                    SuccessAlert(res.data.message);
                    setCount(count+1);
                    handleClose()
                }
                else FailureAlert(res.error);
            })
    }

    const addSharerCategory = (e) => {
        e.preventDefault();
        if(!textColor) { FailureAlert('Choose Color for Image Text'); return; }
        if(!subCategoryImage) { FailureAlert('Upload an image to continue...'); return; }
        const formData = new FormData();
        formData.append('sharer_category', sharerCategoryId);
        formData.append('subcategory_name', subCategoryName);
        formData.append('subcategory_image', subCategoryImage);
        formData.append('description', subCategoryDescription);
        formData.append('text_color', textColor);
        setCircularLoading(true);
        const requestOptionsPost = {
            method: 'POST',
            headers: { 'Authorization': `Token ${token}` },
            body: formData
        };
        apiCall('/api/admin/sharer_subcategory/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setSubCategoryName(''); setSubCategoryDescription('');
                setSubCategoryImage(null); setTextColor('');
                setCount(count+1);
            }
            else FailureAlert(res.error);
            setCircularLoading(false);
        })
    }

    return (
        <div className="update__banner">
            <Form className="sharer__subcategory" style={{display:'flex', flexDirection:'row'}} >
                <Autocomplete
                    id="select-user-type"
                    options={userTypesList}
                    getOptionLabel={(item) => item.value}
                    style={{ width: '94%', margin: '1% 3% 2% 3%' }}
                    onChange={(e, item)=>item ? setUserTypeId(item.id) : setUserTypeId(null)}
                    renderInput={(params) => <TextField {...params} label="Select User Type" variant="outlined" />}
                />
                <Autocomplete
                    id="select-sharer-category"
                    options={sharerCategories}
                    getOptionLabel={(item) => item.category_name}
                    style={{ width: '94%', margin: '1% 3% 2% 3%' }}
                    onChange={(e, item)=>item ? setSharerCategoryId(item.id) : setSharerCategoryId(null)}
                    renderInput={(params) => <TextField {...params} label="Select Sharer Category" variant="outlined" />}
                />
            </Form>
            {
                sharerCategoryId
                ?
                !loading
                ?
                <div className="update__banner__section" style={{marginLeft:'1.5%', width:'97%'}}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Existing Sharer SubCategories" {...a11yProps(0)} />
                            <Tab label="Add a New Sharer SubCategory" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    {/* Listing existing Categories */}
                    <TabPanel value={value} index={0} dir={theme.direction}> 
                        {
                            data.length>0
                            ?
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Sub_Category_Name</th>
                                        <th>Sub_Category_Description</th>
                                        <th>Sub_Category Image</th>
                                        <th>Edit_Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((d, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{d.subcategory_name}</td>
                                                <td>{d.description}</td>
                                                <td>
                                                    <img  
                                                        style={{height:'180px', width:'350px'}}
                                                        src={d.subcategory_image || category_placeholder_image} 
                                                        alt="category"
                                                    />
                                                </td>
                                                <td>
                                                    <Button size="sm" variant="outline-info"
                                                        onClick={e=>editSharerCategory(e, d)}
                                                    >
                                                        Edit_Category
                                                    </Button>
                                                </td>
                                            </tr>
                                        )})
                                    }
                                </tbody>
                            </Table>
                            :
                            <p style={{paddingTop: '10%', paddingLeft: '40%'}}>
                                Nothing to Display
                            </p>
                        }        
                    </TabPanel>

                    {/* Adding a new Sharer Category */}
                    <TabPanel value={value} index={1} dir={theme.direction}>    
                        {
                            !circularLoading
                            ?                       
                            <Form id="sharer_category_add" onSubmit={e=>addSharerCategory(e)}>
                                <Form.Row>
                                    <Form.Group controlId="categoryName" as={Col}>
                                        <Form.Label>Sharer SubCategory Name*</Form.Label>
                                        <Form.Control 
                                            name="categoryName" type="text" placeholder="Sharer Category Name*" required
                                            onChange={e=> {setSubCategoryName(e.target.value)}}   
                                        />
                                    </Form.Group>     
                                    <Form.Group controlId="textColor" as={Col}>   
                                        <Form.Label>Image Text Color*</Form.Label>
                                        <Form.Control 
                                            name="textColor" type="color" placeholder="Type color name or hex*" required
                                            onChange={e=> {setTextColor(e.target.value)}}   
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group controlId="description">
                                    <Form.Label>Sharer SubCategory Description</Form.Label>
                                    <Form.Control 
                                        name="description" as="textarea" rows='3' placeholder={`Enter Description`}
                                        onChange={e=> { setSubCategoryDescription(e.target.value); }}
                                    />
                                </Form.Group>
                                <Form.File 
                                    id="formcheck-api-custom" 
                                    custom style={{marginTop:'1%', marginBottom:'3%'}}
                                >
                                    <Form.File.Input onChange={e=>setSubCategoryImage(e.target.files[0])} isValid />
                                    <Form.File.Label data-browse="Upload SubCategory Image">
                                        Click to choose file from local directory
                                    </Form.File.Label>
                                    {
                                        subCategoryImage ? 
                                        <Form.Control.Feedback type="valid">{subCategoryImage.name}</Form.Control.Feedback> 
                                        : null
                                    }
                                </Form.File>
                                <Button type='submit'>Upload</Button>       
                            </Form>
                            :
                            <CircularProgress 
                                style={{display:'block', margin:'auto', marginTop:'200px'}}
                            />
                        }             
                    </TabPanel>
                </div>
                :
                <img 
                    src={gif} 
                    alt="Getwork Loading Gif..."
                    style={gifStyling}
                />
                :
                null
            }


            {/* Edit Category Modal*/}
            {
                modalData
                ?
                <Modal show={open} onHide={handleClose} scrollable={true}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Sharer SubCategory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="sharer_category_add" onSubmit={e=>patchCategory(e)}>
                            <Form.Row>
                                <Form.Group controlId="categoryName" as={Col}>
                                    <Form.Label>Sharer SubCategory Name*</Form.Label>
                                    <Form.Control 
                                        name="categoryName" type="text" placeholder="Sharer Category Name*"
                                        value={patchSubCategoryName}
                                        onChange={e=> {setPatchSubCategoryName(e.target.value)}}   
                                    />
                                </Form.Group>     
                                <Form.Group controlId="textColor" as={Col}>   
                                    <Form.Label>Image Text Color*</Form.Label>
                                    <Form.Control 
                                        name="textColor" type="color" placeholder="Type color name or hex*"
                                        value={patchTextColor}
                                        onChange={e=> {setPatchTextColor(e.target.value)}}   
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Group controlId="userType">
                                <Autocomplete
                                    id="select-sharer-category"
                                    options={sharerCategories}
                                    getOptionLabel={(item) => item.category_name}
                                    style={{ margin: '1% 0 2% 0' }}
                                    onChange={(e, item)=>item ? setPatchSharerCategory(item.id) : setPatchSharerCategory(null)}
                                    renderInput={(params) => <TextField {...params} label="Select Sharer Category" variant="outlined" />}
                                />   
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Sharer SubCategory Description</Form.Label>
                                <Form.Control 
                                    name="description" as="textarea" rows='2' placeholder={`Enter Description`}
                                    value={patchSubCategoryDescription}
                                    onChange={e=> { setPatchSubCategoryDescription(e.target.value); }}
                                />
                            </Form.Group>
                            <Form.File 
                                id="formcheck-api-custom" 
                                custom style={{marginTop:'1%', marginBottom:'1%'}}
                            >
                                <Form.File.Input onChange={e=>setPatchSubCategoryImage(e.target.files[0])} isValid />
                                <Form.File.Label data-browse="Upload SubCategory Image">                                        Click to choose file from local directory
                                </Form.File.Label>
                                {
                                    patchSubCategoryImage ? 
                                    <Form.Control.Feedback type="valid">{patchSubCategoryImage.name}</Form.Control.Feedback> 
                                    : null
                                }
                            </Form.File>
                            <br />
                            <br />
                            <Button type='submit'>Edit</Button>       
                        </Form>
                    </Modal.Body>
                </Modal>
                :
                null
            }

        </div>
    )
}

export default SharerSubCategory;