import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import React, { useEffect, useReducer, useState } from "react";
import { Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import gif from "../../assets/loopingcircles-3.gif";
import CompanyReportFilter from "../../components/DialogSection/Reports/CompanyReportFilter";
import CustomButtonCircularProgress from "../../components/loader";
import TableWithExport from "../../components/TableWithExport";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiNiyuktiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import { IconButton, makeStyles } from "@material-ui/core";
import DownloadDialog from "../../components/DialogSection/Download/DownloadDialog";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const CompanyWiseReports = () => {
  const classes = useStyles();
  const [pageLoading, setPageLoading] = useState(false);
  let date = new Date();
  date.setDate(date.getDate() - 1);
  const [dateRange, setDateRange] = useState([date, new Date()]);
  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [show, setShow] = useState(false);
  const handleExpandClick = () => {
    setShow(!show);
  };
  const loginRecruiterId = JSON.parse(localStorage.getItem("user-data")).id;
  const loginRecruiterName = JSON.parse(
    localStorage.getItem("user-data")
  ).full_name;
  // const dateRange = [new Date(2023, 0, 1), new Date()];

  const [filters, setFilters] = useState({
    advanceFilterDateValue: dateRange,
    selectedCompanyList: [],
    selectedRecruiterList: [
      { id: loginRecruiterId, admin_name: loginRecruiterName },
    ],
    selectedJobCategory: [],
    selectedSortBy: { sort: "Created Date", value: 1 },
  });

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setDateRange([
        valueDate[0].toISOString().substring(0, 10),
        valueDate[1].toISOString().substring(0, 10),
      ]);
    }
  };

  const [toggleGWScreening, setToggledGWScreening] = useState(true);
  const [toggleGWScreeningInProcess, setToggledGWScreeningInProcess] =
    useState(true);
  const [toggleClientScreening, setToggledClientScreening] = useState(true);
  const [toggleInterview, setToggledInterview] = useState(true);
  const [toggleOffer, setToggledOffer] = useState(true);
  const [toggleJoining, setToggledJoining] = useState(true);
  const [toggleInvoice, setToggledInvoice] = useState(true);

  const columns = [
    {
      title: "Name",
      field: "memberName",

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      // filtering: false,
      // searchable: false,
      render: (row) => (
        <div
          style={{
            fontWeight: row?.fontWeight ? row?.fontWeight : "400",
            width: "100px",
          }}
        >
          {row?.showArrow && <SubdirectoryArrowRightIcon />}
          {row.memberName}
        </div>
      ),
    },
    {
      title: "No_of_Jobs",
      field: "noOfJobs",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,

      // filtering: false,
      // searchable: false,
    },
    {
      title: "Total_Applicants",
      field: "totalApplicants",

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#0509ff",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.totalApplicants}
          </div>
        ) : (
          <div>{row.totalApplicants}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    // {
    //   title: (
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         paddingLeft: 20,
    //       }}
    //     >
    //       Testing{" "}
    //       <IconButton
    //         className={clsx(classes.expand, {
    //           [classes.expandOpen]: show,
    //         })}
    //         size="small"
    //         onClick={handleExpandClick}
    //         aria-expanded={show}
    //       >
    //         {" "}
    //         <ArrowForwardIosIcon />{" "}
    //       </IconButton>
    //     </div>
    //   ),
    //   field: "testing",
    //   headerCSS: headerCSS,
    //   cellCSS: cellCSS,
    //   render: (row) => <div>Testing</div>,
    // },

    // {
    //   title: (
    //     <div >Testing Two</div>
    //   ),
    //   field: "testing_Two",
    //   headerCSS: headerCSS,
    //   cellCSS: cellCSS,
    //   render: (row) =>  <div>Testing </div>,
    // },
    // {
    //   title: (
    //     <div >Testing three</div>
    //   ),
    //   field: "testing_Two",
    //   headerCSS: headerCSS,
    //   cellCSS: cellCSS,
    //   render: (row) =>  <div>Testing </div>,
    // },
    {
      title: "Total_Rating",
      field: "totalRating",

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#0509ff",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.totalRating}
          </div>
        ) : (
          <div>{row.totalRating}</div>
        ),
      // filtering: false,
      // searchable: false,
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 20,
            color: "#0F0FBE",
          }}
        >
          GW_Screening{" "}
          {toggleGWScreening === true ? (
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: !toggleGWScreening,
              })}
              style={{ color: "#0F0FBE" }}
              size="small"
              onClick={() => {
                setToggledGWScreening(false);
              }}
              aria-expanded={toggleGWScreening ? "false" : "true"}
            >
              {" "}
              <ArrowForwardIosIcon />{" "}
            </IconButton>
          ) : (
            <IconButton
              className={classes.expandOpen}
              style={{ color: "#0F0FBE" }}
              size="small"
              onClick={() => {
                setToggledGWScreening(true);
                setToggledGWScreeningInProcess(true);
              }}
              aria-expanded={toggleGWScreening ? "false" : "true"}
            >
              {" "}
              <ArrowForwardIosIcon />{" "}
            </IconButton>
          )}
        </div>
      ),
      field: "gw-screening",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#0F0FBE",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(
              row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.screeningRejected +
                row.screeningWaitlisted +
                row.interviewLinedUp
            )
              ? null
              : row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.screeningRejected +
                row.screeningWaitlisted +
                row.interviewLinedUp}
          </div>
        ) : (
          <div style={{ color: "#0F0FBE" }}>
            {isNaN(
              row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.screeningRejected +
                row.screeningWaitlisted +
                row.interviewLinedUp
            )
              ? null
              : row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.screeningRejected +
                row.screeningWaitlisted +
                row.interviewLinedUp}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 20,
            color: "#2563EB",
          }}
        >
          In_Process{" "}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: !toggleGWScreeningInProcess,
            })}
            size="small"
            style={{ color: "#2563EB" }}
            onClick={() => setToggledGWScreeningInProcess((prev) => !prev)}
            aria-expanded={!toggleGWScreeningInProcess}
          >
            {" "}
            <ArrowForwardIosIcon style={{ fontSize: "18px" }} />{" "}
          </IconButton>
        </div>
      ),
      // title: <div style={{ color: "rgba(51, 51, 51, 0.87)" }}>In_Process</div>,
      // title: "",
      field: "remove",
      hidden: toggleGWScreening,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#2563EB",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(
              row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.selfAppliedCount
            )
              ? null
              : row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.selfAppliedCount}
          </div>
        ) : (
          <div style={{ color: "#2563EB" }}>
            {isNaN(
              row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.selfAppliedCount
            )
              ? null
              : row.vendorAppliedCount +
                row.preappliedCount +
                row.appliedCount +
                row.selfAppliedCount}
          </div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#2563EB" }}>By_Vendor</div>,
      // title: "",
      field: "remove",
      hidden: toggleGWScreeningInProcess,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#2563EB",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(row.vendorAppliedCount) ? null : row.vendorAppliedCount}
          </div>
        ) : (
          <div style={{ color: "#2563EB" }}>
            {isNaN(row.vendorAppliedCount) ? null : row.vendorAppliedCount}
          </div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#2563EB" }}>By_Self</div>,
      // title: "",
      field: "remove",
      hidden: toggleGWScreeningInProcess,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#2563EB",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.selfAppliedCount}
          </div>
        ) : (
          <div style={{ color: "#2563EB" }}>{row.selfAppliedCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#2563EB" }}>By_Recruiter</div>,
      // title: "",
      field: "remove",
      hidden: toggleGWScreeningInProcess,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#2563EB",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(row.preappliedCount + row.appliedCount)
              ? null
              : row.preappliedCount + row.appliedCount}
          </div>
        ) : (
          <div style={{ color: "#2563EB" }}>
            {isNaN(row.preappliedCount + row.appliedCount)
              ? null
              : row.preappliedCount + row.appliedCount}
          </div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      // title: "Reject",
      title: <div style={{ color: "#3232E5" }}>Interview_Lined_Up</div>,

      // title: "",
      field: "remove",
      hidden: toggleGWScreening,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#3232E5",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.interviewLinedUp}
          </div>
        ) : (
          <div style={{ color: "#3232E5" }}>{row.interviewLinedUp}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      // title: "Reject",
      title: <div style={{ color: "#3232E5" }}>GW_Screen_Backout</div>,

      // title: "",
      field: "remove",
      hidden: toggleGWScreening,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#3232E5",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.screeningBackout}
          </div>
        ) : (
          <div style={{ color: "#3232E5" }}>{row.screeningBackout}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      // title: "Reject",
      title: <div style={{ color: "#3232E5" }}>Reject</div>,

      // title: "",
      field: "remove",
      hidden: toggleGWScreening,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#3232E5",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.screeningRejected}
          </div>
        ) : (
          <div style={{ color: "#3232E5" }}>{row.screeningRejected}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#3232E5" }}>Waitlisted</div>,

      // title: "Waitlisted",
      // title: "Waitlisted",
      field: "remove",
      hidden: toggleGWScreening,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#3232E5",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.screeningWaitlisted}
          </div>
        ) : (
          <div style={{ color: "#3232E5" }}>{row.screeningWaitlisted}</div>
        ),
      // filtering: false,
      // searchable: false,
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 20,
            color: "#A020F0",
          }}
        >
          Client_Screening{" "}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: !toggleClientScreening,
            })}
            style={{ color: "#A020F0" }}
            size="small"
            onClick={() => setToggledClientScreening((prev) => !prev)}
            aria-expanded={!toggleClientScreening}
          >
            {" "}
            <ArrowForwardIosIcon />{" "}
          </IconButton>
        </div>
      ),
      field: "client-screening",

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#A020F0",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(
              row.getworkScreenedCount +
                row.clientScreeningRejected +
                row.clientScreeningHold +
                row.clientScreeningDuplicate
            )
              ? null
              : row.getworkScreenedCount +
                row.clientScreeningRejected +
                row.clientScreeningHold +
                row.clientScreeningDuplicate}
          </div>
        ) : (
          <div style={{ color: "#A020F0" }}>
            {isNaN(
              row.getworkScreenedCount +
                row.clientScreeningRejected +
                row.clientScreeningHold +
                row.clientScreeningDuplicate
            )
              ? null
              : row.getworkScreenedCount +
                row.clientScreeningRejected +
                row.clientScreeningHold +
                row.clientScreeningDuplicate}
          </div>
        ),
    },
    {
      title: <div style={{ color: "#C465FF" }}>In_Process</div>,

      // title: "In_Process",
      // title: "",
      field: "remove",
      hidden: toggleClientScreening,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#C465FF",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.getworkScreenedCount}
          </div>
        ) : (
          <div style={{ color: "#C465FF" }}>{row.getworkScreenedCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#C465FF" }}>Reject</div>,

      // title: "Reject",
      // title: "",
      field: "remove",
      hidden: toggleClientScreening,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#C465FF",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.clientScreeningRejected}
          </div>
        ) : (
          <div style={{ color: "#C465FF" }}>{row.clientScreeningRejected}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#C465FF" }}>Hold</div>,

      // title: "Hold",
      // title: "",
      field: "remove",
      hidden: toggleClientScreening,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#C465FF",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.clientScreeningHold}
          </div>
        ) : (
          <div style={{ color: "#C465FF" }}>{row.clientScreeningHold}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#C465FF" }}>Duplicate</div>,

      // title: "Duplicate",
      // title: "",
      field: "remove",
      hidden: toggleClientScreening,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#C465FF",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.clientScreeningDuplicate}
          </div>
        ) : (
          <div style={{ color: "#C465FF" }}>{row.clientScreeningDuplicate}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 20,
            color: "#EF476F",
            width: 170,
          }}
        >
          Interview In_Process
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: !toggleInterview,
            })}
            size="small"
            style={{ color: "#EF476F" }}
            onClick={() => setToggledInterview((prev) => !prev)}
            aria-expanded={!toggleInterview}
          >
            {" "}
            <ArrowForwardIosIcon />{" "}
          </IconButton>
        </div>
      ),
      field: "interview",

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#EF476F",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(
              row.rejectedCount +
                row.interviewedCount +
                row.interviewHold +
                row.interviewFeedback +
                row.interviewedBackoutCount
            )
              ? null
              : row.rejectedCount +
                row.interviewedCount +
                row.interviewHold +
                row.interviewFeedback +
                row.interviewedBackoutCount}
          </div>
        ) : (
          <div style={{ color: "#EF476F" }}>
            {isNaN(
              row.rejectedCount +
                row.interviewedCount +
                row.interviewHold +
                row.interviewFeedback +
                row.interviewedBackoutCount
            )
              ? null
              : row.rejectedCount +
                row.interviewedCount +
                row.interviewHold +
                row.interviewFeedback +
                row.interviewedBackoutCount}
          </div>
        ),
    },
    {
      title: <div style={{ color: "#F26B8B" }}>Interviewed</div>,

      // title: "Interviewed",
      field: "remove",
      hidden: toggleInterview,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#F26B8B",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.interviewedCount}
          </div>
        ) : (
          <div style={{ color: "#F26B8B" }}>{row.interviewedCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#F26B8B" }}>Reject</div>,

      // title: "Reject",
      field: "remove",
      hidden: toggleInterview,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#F26B8B",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.rejectedCount}
          </div>
        ) : (
          <div style={{ color: "#F26B8B" }}>{row.rejectedCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#F26B8B" }}>Hold</div>,

      // title: "Hold",
      // title: "",
      field: "remove",
      hidden: toggleInterview,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#F26B8B",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.interviewHold}
          </div>
        ) : (
          <div style={{ color: "#F26B8B" }}>{row.interviewHold}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#F26B8B" }}>Feedback_Pending</div>,

      // title: "Feedback_Pending",
      field: "remove",
      hidden: toggleInterview,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#F26B8B",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.interviewFeedback}
          </div>
        ) : (
          <div style={{ color: "#F26B8B" }}>{row.interviewFeedback}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#F26B8B" }}>L2_Interview_Backout</div>,

      // title: "Backout",
      field: "remove",
      hidden: toggleInterview,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#F26B8B",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.interviewedBackoutCount}
          </div>
        ) : (
          <div style={{ color: "#F26B8B" }}>{row.interviewedBackoutCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 20,
            color: "#964b00",
          }}
        >
          Offer{" "}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: !toggleOffer,
            })}
            size="small"
            style={{ color: "#964b00" }}
            onClick={() => setToggledOffer((prev) => !prev)}
            aria-expanded={!toggleOffer}
          >
            {" "}
            <ArrowForwardIosIcon />{" "}
          </IconButton>
        </div>
      ),
      field: "offer",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#964b00",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(
              row.hiredCount + row.waitlistedCount + row.selectedBackoutCount
            )
              ? null
              : row.hiredCount + row.waitlistedCount + row.selectedBackoutCount}
          </div>
        ) : (
          <div style={{ color: "#964b00" }}>
            {isNaN(
              row.hiredCount + row.waitlistedCount + row.selectedBackoutCount
            )
              ? null
              : row.hiredCount + row.waitlistedCount + row.selectedBackoutCount}
          </div>
        ),
    },
    {
      title: <div style={{ color: "#A96E6E" }}>In_Process</div>,

      // title: "In_Process",
      field: "remove",
      hidden: toggleOffer,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#A96E6E",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.hiredCount}
          </div>
        ) : (
          <div style={{ color: "#A96E6E" }}>{row.hiredCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#A96E6E" }}>Hold</div>,

      // title: "Hold",
      field: "remove",
      hidden: toggleOffer,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#A96E6E",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.waitlistedCount}
          </div>
        ) : (
          <div style={{ color: "#A96E6E" }}>{row.waitlistedCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#A96E6E" }}>Backout</div>,

      // title: "Backout",
      field: "remove",
      hidden: toggleOffer,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#A96E6E",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.selectedBackoutCount}
          </div>
        ) : (
          <div style={{ color: "#A96E6E" }}>{row.selectedBackoutCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 20,
            color: "#047B4F",
          }}
        >
          Joining{" "}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: !toggleJoining,
            })}
            style={{ color: "#047B4F" }}
            size="small"
            onClick={() => setToggledJoining((prev) => !prev)}
            aria-expanded={!toggleJoining}
          >
            {" "}
            <ArrowForwardIosIcon />{" "}
          </IconButton>
        </div>
      ),
      field: "joining",

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#047B4F",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {!isNaN(
              row.offeredCount + row.offeredBackoutCount + row.joiningHold
            )
              ? row.offeredCount + row.offeredBackoutCount + row.joiningHold
              : null}
          </div>
        ) : (
          <div style={{ color: "#047B4F" }}>
            {!isNaN(
              row.offeredCount + row.offeredBackoutCount + row.joiningHold
            )
              ? row.offeredCount + row.offeredBackoutCount + row.joiningHold
              : null}
          </div>
        ),
    },
    {
      title: <div style={{ color: "#16C484" }}>In_Process</div>,

      // title: "In_Process",
      field: "remove",
      hidden: toggleJoining,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#16C484",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.offeredCount}
          </div>
        ) : (
          <div style={{ color: "#16C484" }}>{row.offeredCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#16C484" }}>Backout</div>,

      // title: "Backout",
      field: "remove",
      hidden: toggleJoining,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#16C484",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.offeredBackoutCount}
          </div>
        ) : (
          <div style={{ color: "#16C484" }}>{row.offeredBackoutCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#16C484" }}>Hold</div>,

      // title: "Hold",
      // title: "",
      hidden: toggleJoining,

      field: "remove",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#16C484",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.joiningHold}
          </div>
        ) : (
          <div style={{ color: "#16C484" }}>{row.joiningHold}</div>
        ),
      // filtering: false,
      // searchable: false,
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 20,
            color: "#6c757d",
          }}
        >
          Invoice{" "}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: !toggleInvoice,
            })}
            style={{ color: "#6c757d" }}
            size="small"
            onClick={() => setToggledInvoice((prev) => !prev)}
            aria-expanded={!toggleInvoice}
          >
            {" "}
            <ArrowForwardIosIcon />{" "}
          </IconButton>
        </div>
      ),
      field: "invoice",

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#6c757d",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {isNaN(
              row.joinedCount + row.joinedBackoutCount + row.maturedRevenueCount
            )
              ? null
              : row.joinedCount +
                row.joinedBackoutCount +
                row.maturedRevenueCount}
          </div>
        ) : (
          <div style={{ color: "#6c757d" }}>
            {isNaN(
              row.joinedCount + row.joinedBackoutCount + row.maturedRevenueCount
            )
              ? null
              : row.joinedCount +
                row.joinedBackoutCount +
                row.maturedRevenueCount}
          </div>
        ),
    },
    {
      title: <div style={{ color: "#6c757d" }}>In_Process</div>,

      // title: "In_Process",
      field: "remove-invoice",
      hidden: toggleInvoice,
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#6c757d",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.joinedCount}
          </div>
        ) : (
          <div style={{ color: "#6c757d" }}>{row.joinedCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#6c757d" }}>Raised</div>,

      // title: "Backout",
      field: "remove-invoice",
      hidden: toggleInvoice,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#6c757d",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.joinedBackoutCount}
          </div>
        ) : (
          <div style={{ color: "#6c757d" }}>{row.joinedBackoutCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },
    {
      title: <div style={{ color: "#6c757d" }}>Payment_Received</div>,

      // title: "Payment_Received",
      // title: "",
      hidden: true,
      field: "remove-invoice",
      hidden: toggleInvoice,

      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row?.jobData ? (
          <div
            style={{
              cursor: "pointer",
              color: "#6c757d",
            }}
            onClick={() =>
              window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
            }
          >
            {row.maturedRevenueCount}
          </div>
        ) : (
          <div style={{ color: "#6c757d" }}>{row.maturedRevenueCount}</div>
        ),
      // filtering: false,
      // searchable: false,
    },

    // {
    //   title: "Vendor Applied",
    //   field: "vendorAppliedCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.vendorAppliedCount}
    //       </div>
    //     ) : (
    //       <div>{row.vendorAppliedCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Pre-Applied",
    //   field: "preappliedCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.preappliedCount}
    //       </div>
    //     ) : (
    //       <div>{row.preappliedCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Applied",
    //   field: "appliedCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.appliedCount}
    //       </div>
    //     ) : (
    //       <div>{row.appliedCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "GW Screened",
    //   field: "getworkScreenedCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.getworkScreenedCount}
    //       </div>
    //     ) : (
    //       <div>{row.getworkScreenedCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },

    // {
    //   title: "Interviewed",
    //   field: "interviewedCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.interviewedCount}
    //       </div>
    //     ) : (
    //       <div>{row.interviewedCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Interviewed Backout",
    //   field: "interviewedBackoutCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.interviewedBackoutCount}
    //       </div>
    //     ) : (
    //       <div>{row.interviewedBackoutCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Selected(Hired)",
    //   field: "hiredCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.hiredCount}
    //       </div>
    //     ) : (
    //       <div>{row.hiredCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Selected Backout",
    //   field: "selectedBackoutCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.selectedBackoutCount}
    //       </div>
    //     ) : (
    //       <div>{row.selectedBackoutCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Offered",
    //   field: "offeredCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.offeredCount}
    //       </div>
    //     ) : (
    //       <div>{row.offeredCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Offered Backout",
    //   field: "offeredBackoutCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.offeredBackoutCount}
    //       </div>
    //     ) : (
    //       <div>{row.offeredBackoutCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Joined",
    //   field: "joinedCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.joinedCount}
    //       </div>
    //     ) : (
    //       <div>{row.joinedCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Joined Backout",
    //   field: "joinedBackoutCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.joinedBackoutCount}
    //       </div>
    //     ) : (
    //       <div>{row.joinedBackoutCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
    // {
    //   title: "Matured Revenue",
    //   field: "maturedRevenueCount",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#0509ff",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {row.maturedRevenueCount}
    //       </div>
    //     ) : (
    //       <div>{row.maturedRevenueCount}</div>
    //     ),
    //   // filtering: false,
    //   // searchable: false,
    // },
  ];

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formatDate2 = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const getDateParam = () => {
    if (
      filters?.advanceFilterDateValue &&
      filters?.advanceFilterDateValue?.length === 2 &&
      filters?.advanceFilterDateValue[0] &&
      filters?.advanceFilterDateValue[1]
    ) {
      return {
        // start_date: filters?.advanceFilterDateValue[0]
        //   .toISOString()
        //   .substring(0, 10),
        // end_date: filters?.advanceFilterDateValue[1]
        //   .toISOString()
        //   .substring(0, 10),
        start_date: formatDate(filters.advanceFilterDateValue[0]),
        end_date: formatDate(filters.advanceFilterDateValue[1]),
      };
    }
    return {};
  };

  const getData = async () => {
    setPageLoading(true);

    let data = getDateParam();
    let endPoint = "/job/admin/applicant_multiple_stats/?is_recruiter=1";
    let companyIds = [];
    if (filters?.selectedCompanyList?.length > 0) {
      for (let i in filters?.selectedCompanyList) {
        companyIds.push(filters?.selectedCompanyList[i]?.id);
      }
      endPoint = endPoint + `&company_ids=${companyIds.join(",")}`;
    }
    let recruiterIds = [];
    if (filters?.selectedRecruiterList?.length > 0) {
      for (let i in filters?.selectedRecruiterList) {
        recruiterIds.push(filters?.selectedRecruiterList[i]?.id);
      }
      endPoint = endPoint + `&user_ids=${recruiterIds.join(",")}`;
    }
    let jobCategoryIds = [];
    if (filters?.selectedJobCategory?.length > 0) {
      for (let i in filters?.selectedJobCategory) {
        jobCategoryIds.push(filters?.selectedJobCategory[i]?.id);
      }
      endPoint = endPoint + `&job_category=${jobCategoryIds.join(",")}`;
    }
    if (filters?.selectedSortBy !== null) {
      endPoint = endPoint + `&is_created=${filters?.selectedSortBy.value}`;
    }

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    };

    mainApiCall(endPoint, requestOptionsPost);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const mainApiCall = (endPoint, postData) => {
    apiNiyuktiCall(endPoint, postData)
      .then((res) => {
        if (res?.success) {
          const data = res?.data;
          let tempArr = [];
          let parentData = [];
          let indx = 1;
          for (let i in data) {
            let obj = {
              joiningHold: data[i]?.joining_hold,
              interviewHold: data[i]?.interview_hold,
              interviewFeedback: data[i]?.interview_feedback,
              getworkScreenedCount: data[i]?.getwork_screened_count,
              memberName: data[i]?.company_name,
              totalApplicants: data[i]?.applicant_count,
              totalRating: data[i]?.rated_count,
              noOfJobs: data[i]?.job_count,
              clientScreeningRejected: data[i]?.client_screening_rejected,
              clientScreeningHold: data[i]?.client_screening_hold,
              clientScreeningDuplicate: data[i]?.client_screening_duplicate,
              screeningRejected: data[i]?.screening_rejected,
              screeningWaitlisted: data[i]?.screening_waitlisted,
              interviewLinedUp: data[i]?.interview_lined_up,
              screeningBackout: data[i]?.screening_backout,
              waitlistedCount: data[i]?.wait_listed_count,
              selectedBackoutCount: data[i]?.selected_backout_count,
              rejectedCount: data[i]?.rejected_count,
              preappliedCount: data[i]?.preapplied_count,
              offeredCount: data[i]?.offered_count,
              offeredBackoutCount: data[i]?.offered_backout_count,
              joinedCount: data[i]?.joined_count,
              joinedBackoutCount: data[i]?.joined_backout_count,
              interviewedCount: data[i]?.interviewed_count,
              interviewedBackoutCount: data[i]?.interview_backout_count,
              hiredCount: data[i]?.hired_count,
              appliedBy: data[i]?.applied_by,
              appliedCount: data[i]?.applied_count,
              vendorAppliedCount: data[i]?.vendor_applied_count,
              selfAppliedCount: data[i]?.self_applied_count,
              maturedRevenueCount: data[i]?.matured_revenue_count,
              id: data[i]?.company_id,
              companyId: data[i]?.company_id,
              fontWeight: "800",
              mainData: true,
            };

            tempArr.push(obj);
            let newObj = {
              id: data[i]?.company_id,
              companyId: data[i]?.company_id,
            };
            if (
              !parentData.includes(data[i]?.company_id) &&
              data[i]?.company_id !== "-"
            ) {
              newObj["parentId"] = data[i]?.company_id;
              newObj["memberName"] = "JOB WISE REPORT";
              newObj["showArrow"] = true;
              newObj["fontWeight"] = "800";
              parentData.push(data[i]?.company_id);
              tempArr.push(newObj);
            }
            indx = indx + 1;
          }
          setTableData(tempArr);
          setPageLoading(false);
        } else {
          FailureAlert("Some Error Occurred!");
        }
        setLoadingOverlay(false);
      })
      .catch((err) => {
        FailureAlert("Something went wrong!!");
        setLoadingOverlay(false);
      });
  };

  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const onTreeExpandChange = (row, openState) => {
    if (openState) {
      if (row?.jobData) {
        getUserData(row);
      } else {
        getJobData(row);
      }
    } else {
      removeDataFromTable(row);
    }
  };

  const removeDataFromTable = (row) => {
    let newTableData = [];
    tableData?.forEach((item, index) => {
      if (row?.mainData) {
        if (
          row?.companyId !== item?.companyId ||
          !(item?.isJob || item?.isUser)
        ) {
          newTableData.push(item);
        }
      } else {
        if (
          row?.companyId !== item?.companyId ||
          row?.jobId !== item?.jobId ||
          !item?.isUser
        ) {
          newTableData.push(item);
        }
      }
    });
    setTableData(newTableData);
  };

  const getJobData = (row) => {
    let endPoint = `/job/admin/applicant_multiple_stats/?is_recruiter=1`;
    setLoadingOverlay(true);

    let data = getDateParam();
    // data['user_id'] = row?.appliedBy;
    data["company_id"] = row?.companyId;
    let jobCategoryIds = [];
    if (filters?.selectedJobCategory?.length > 0) {
      for (let i in filters?.selectedJobCategory) {
        jobCategoryIds.push(filters?.selectedJobCategory[i]?.id);
      }
      endPoint = endPoint + `&job_category=${jobCategoryIds.join(",")}`;
    }
    let recruiterIds = [];
    if (filters?.selectedRecruiterList?.length > 0) {
      for (let i in filters?.selectedRecruiterList) {
        recruiterIds.push(filters?.selectedRecruiterList[i]?.id);
      }
      endPoint = endPoint + `&user_ids=${recruiterIds.join(",")}`;
    }
    if (filters?.selectedSortBy !== null) {
      endPoint = endPoint + `&is_created=${filters?.selectedSortBy.value}`;
    }

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    };

    apiNiyuktiCall(endPoint, requestOptionsPost)
      .then((res) => {
        if (res?.success) {
          const data = res?.data;
          let tempArr = [];
          let parentData = [];
          for (let i in data) {
            let obj = {
              memberName: `(${data[i]?.job_id})${data[i]?.job_title}`,
              // totalApplicants: data[i]?.applicant_count,
              // totalRating: data[i]?.rated_count,
              // noOfJobs: data[i]?.job_count,
              // waitlistedCount: data[i]?.wait_listed_count,
              // selectedBackoutCount: data[i]?.selected_backout_count,
              // rejectedCount: data[i]?.rejected_count,
              // waitlistedCount: data[i]?.wait_listed_count,
              // preappliedCount: data[i]?.preapplied_count,
              // offeredCount: data[i]?.offered_count,
              // offeredBackoutCount: data[i]?.offered_backout_count,
              // joinedCount: data[i]?.joined_count,
              // joinedBackoutCount: data[i]?.joined_backout_count,
              // interviewedCount: data[i]?.interviewed_count,
              // interviewedBackoutCount: data[i]?.interview_backout_count,
              // hiredCount: data[i]?.hired_count,
              // getworkScreenedCount: data[i]?.getwork_screened_count,
              // appliedBy: data[i]?.user_id,
              // appliedCount: data[i]?.applied_count,
              // getworkScreenedCount: data[i]?.getwork_screened_count,
              // vendorAppliedCount: data[i]?.vendor_applied_count,
              // maturedRevenueCount: data[i]?.matured_revenue_count,
              joiningHold: data[i]?.joining_hold,
              interviewHold: data[i]?.interview_hold,
              interviewFeedback: data[i]?.interview_feedback,
              getworkScreenedCount: data[i]?.getwork_screened_count,
              totalApplicants: data[i]?.applicant_count,
              totalRating: data[i]?.rated_count,
              noOfJobs: data[i]?.job_count,
              clientScreeningRejected: data[i]?.client_screening_rejected,
              clientScreeningHold: data[i]?.client_screening_hold,
              clientScreeningDuplicate: data[i]?.client_screening_duplicate,
              screeningRejected: data[i]?.screening_rejected,
              screeningWaitlisted: data[i]?.screening_waitlisted,
              screeningBackout: data[i]?.screening_backout,
              interviewLinedUp: data[i]?.interview_lined_up,
              waitlistedCount: data[i]?.wait_listed_count,
              selectedBackoutCount: data[i]?.selected_backout_count,
              rejectedCount: data[i]?.rejected_count,
              preappliedCount: data[i]?.preapplied_count,
              offeredCount: data[i]?.offered_count,
              offeredBackoutCount: data[i]?.offered_backout_count,
              joinedCount: data[i]?.joined_count,
              joinedBackoutCount: data[i]?.joined_backout_count,
              interviewedCount: data[i]?.interviewed_count,
              interviewedBackoutCount: data[i]?.interview_backout_count,
              hiredCount: data[i]?.hired_count,
              appliedBy: data[i]?.user_id,
              appliedCount: data[i]?.applied_count,
              vendorAppliedCount: data[i]?.vendor_applied_count,
              selfAppliedCount: data[i]?.self_applied_count,

              maturedRevenueCount: data[i]?.matured_revenue_count,
              id: `JOB_${data[i]?.job_id}`,
              // parentId: `USER_${data[i]?.company_id}_${data[i]?.user_id}`,
              parentId: data[i]?.company_id,
              companyId: data[i]?.company_id,
              jobId: data[i]?.job_id,
              isJob: true,
              jobData: true,
            };
            tempArr.push(obj);
            let newObj = {
              id: `${data[i]?.job_id}`,
              companyId: data[i]?.company_id,
            };
            if (!parentData.includes(data[i]?.job_id)) {
              newObj["parentId"] = `JOB_${data[i]?.job_id}`;
              newObj["memberName"] = "RECRUITER WISE REPORT";
              newObj["showArrow"] = true;
              newObj["fontWeight"] = "800";
              newObj["isJob"] = true;
              parentData.push(data[i]?.job_id);
              tempArr.push(newObj);
            }
          }
          setTableData([...tableData, ...tempArr]);
        } else {
          FailureAlert(res?.error);
        }
        setLoadingOverlay(false);
      })
      .catch((err) => {
        FailureAlert("Something went wrong!!");
        setLoadingOverlay(false);
      });
  };

  const getUserData = (row) => {
    let endPoint = `/job/admin/applicant_multiple_stats/?is_recruiter=1`;
    setLoadingOverlay(true);

    let data = getDateParam();
    data["job_id"] = row?.jobId;
    data["company_id"] = row?.companyId;

    let jobCategoryIds = [];
    if (filters?.selectedJobCategory?.length > 0) {
      for (let i in filters?.selectedJobCategory) {
        jobCategoryIds.push(filters?.selectedJobCategory[i]?.id);
      }
      endPoint = endPoint + `&job_category=${jobCategoryIds.join(",")}`;
    }
    let recruiterIds = [];
    if (filters?.selectedRecruiterList?.length > 0) {
      for (let i in filters?.selectedRecruiterList) {
        recruiterIds.push(filters?.selectedRecruiterList[i]?.id);
      }
      endPoint = endPoint + `&user_ids=${recruiterIds.join(",")}`;
    }
    if (filters?.selectedSortBy !== null) {
      endPoint = endPoint + `&is_created=${filters?.selectedSortBy.value}`;
    }

    // const data = {
    //     "start_date": filters?.advanceFilterDateValue[0].toISOString().substring(0, 10),
    //     "end_date": filters?.advanceFilterDateValue[1].toISOString().substring(0, 10),
    //     "company_id": row?.companyId
    // };

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    };

    apiNiyuktiCall(endPoint, requestOptionsPost)
      .then((res) => {
        if (res?.success) {
          const data = res?.data;
          let tempArr = [];
          let parentData = [];
          for (let i in data) {
            let obj = {
              memberName: data[i]?.applied_name,
              // totalApplicants: data[i]?.applicant_count,
              // totalRating: data[i]?.rated_count,
              // noOfJobs: data[i]?.job_count,
              // waitlistedCount: data[i]?.wait_listed_count,
              // selectedBackoutCount: data[i]?.selected_backout_count,
              // rejectedCount: data[i]?.rejected_count,
              // waitlistedCount: data[i]?.wait_listed_count,
              // preappliedCount: data[i]?.preapplied_count,
              // offeredCount: data[i]?.offered_count,
              // offeredBackoutCount: data[i]?.offered_backout_count,
              // joinedCount: data[i]?.joined_count,
              // joinedBackoutCount: data[i]?.joined_backout_count,
              // interviewedCount: data[i]?.interviewed_count,
              // interviewedBackoutCount: data[i]?.interview_backout_count,
              // hiredCount: data[i]?.hired_count,
              // getworkScreenedCount: data[i]?.getwork_screened_count,
              // appliedBy: data[i]?.applied_by,
              // appliedCount: data[i]?.applied_count,
              // getworkScreenedCount: data[i]?.getwork_screened_count,
              // vendorAppliedCount: data[i]?.vendor_applied_count,
              // maturedRevenueCount: data[i]?.matured_revenue_count,
              joiningHold: data[i]?.joining_hold,
              interviewHold: data[i]?.interview_hold,
              interviewFeedback: data[i]?.interview_feedback,
              getworkScreenedCount: data[i]?.getwork_screened_count,
              totalApplicants: data[i]?.applicant_count,
              totalRating: data[i]?.rated_count,
              noOfJobs: data[i]?.job_count,
              clientScreeningRejected: data[i]?.client_screening_rejected,
              clientScreeningHold: data[i]?.client_screening_hold,
              clientScreeningDuplicate: data[i]?.client_screening_duplicate,
              screeningRejected: data[i]?.screening_rejected,
              screeningWaitlisted: data[i]?.screening_waitlisted,
              interviewLinedUp: data[i]?.interview_lined_up,
              screeningBackout: data[i]?.screening_backout,
              waitlistedCount: data[i]?.wait_listed_count,
              selectedBackoutCount: data[i]?.selected_backout_count,
              rejectedCount: data[i]?.rejected_count,
              preappliedCount: data[i]?.preapplied_count,
              offeredCount: data[i]?.offered_count,
              offeredBackoutCount: data[i]?.offered_backout_count,
              joinedCount: data[i]?.joined_count,
              joinedBackoutCount: data[i]?.joined_backout_count,
              interviewedCount: data[i]?.interviewed_count,
              interviewedBackoutCount: data[i]?.interview_backout_count,
              hiredCount: data[i]?.hired_count,
              appliedBy: data[i]?.applied_by,
              appliedCount: data[i]?.applied_count,
              vendorAppliedCount: data[i]?.vendor_applied_count,
              selfAppliedCount: data[i]?.self_applied_count,

              maturedRevenueCount: data[i]?.matured_revenue_count,

              id: `USER_${data[i]?.company_id}_${data[i]?.job_id}`,
              parentId: `JOB_${data[i]?.job_id}`,
              companyId: data[i]?.company_id,
              jobId: data[i]?.job_id,
              isUser: true,
            };
            tempArr.push(obj);
            // let newObj = {id: `${data[i]?.applied_by}`, companyId: data[i]?.company_id, appliedBy: data[i]?.applied_by};
            // if(!parentData.includes(data[i]?.applied_by)){
            //     newObj['parentId'] = `USER_${data[i]?.company_id}_${data[i]?.applied_by}`;
            //     newObj['memberName'] = "JOB WISE REPORT";
            //     newObj['showArrow'] = true;
            //     newObj['fontWeight'] = "800";
            //     newObj['isUser'] = true;
            //     parentData.push(data[i]?.applied_by);
            //     tempArr.push(newObj);
            // }
          }
          setTableData([...tableData, ...tempArr]);
        } else {
          FailureAlert(res?.error);
        }
        setLoadingOverlay(false);
      })
      .catch((err) => {
        FailureAlert("Something went wrong!!");
        setLoadingOverlay(false);
      });
  };

  // useEffect(() => {
  //     applyDateFilter(filters?.advanceFilterDateValue);
  // }, [filters]);

  const getTableTitleText = () => {
    if (
      filters?.advanceFilterDateValue &&
      filters?.advanceFilterDateValue?.length === 2 &&
      filters?.advanceFilterDateValue[0] &&
      filters?.advanceFilterDateValue[1]
    ) {
      // return `Company Wise Report  (${filters?.advanceFilterDateValue[0]
      //   .toISOString()
      //   .substring(0, 10)} - ${filters?.advanceFilterDateValue[1]
      //   .toISOString()
      //   .substring(0, 10)})`;
      return `Company Wise Report  (${formatDate2(
        filters.advanceFilterDateValue[0]
      )} - ${formatDate2(filters.advanceFilterDateValue[1])})`;
    }
    return `Company Wise Report`;
  };

  const [loadingAllDataDownload, setLoadingAllDataDownload] = useState(false);

  let endPoint =
    "/job/admin/export_applicant_company_user_job_stats/?is_recruiter=1";
  const downloadAllDataCSV = () => {
    setLoadingAllDataDownload(true);
    let data = getDateParam();

    let companyIds = [];
    if (filters?.selectedCompanyList?.length > 0) {
      for (let i in filters?.selectedCompanyList) {
        companyIds.push(filters?.selectedCompanyList[i]?.id);
      }
      data["company_ids"] = companyIds;
    }

    let jobCategoryIds = [];
    if (filters?.selectedJobCategory?.length > 0) {
      for (let i in filters?.selectedJobCategory) {
        jobCategoryIds.push(filters?.selectedJobCategory[i]?.id);
      }
      endPoint = endPoint + `&job_category=${jobCategoryIds.join(",")}`;
    }
    if (filters?.selectedSortBy !== null) {
      endPoint = endPoint + `&is_created=${filters?.selectedSortBy.value}`;
    }

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    };

    apiNiyuktiCall(endPoint, requestOptionsPost)
      .then((res) => {
        if (res?.success) {
          SuccessAlert(res?.data?.message);
        } else {
          FailureAlert(res?.error);
        }
        setLoadingAllDataDownload(false);
      })
      .catch((err) => {
        setLoadingAllDataDownload(false);
        FailureAlert("Something went wrong!");
        console.log("Error", err);
      });
  };

  const presetValues = [
    "company_id",
    "company_name",
    "job_id",
    "job_name",
    "current_status",
    "job_track_link",
    "recruiter_id",
    "recruiter_name",
    "candidate_id",
    "apply_date",
    "update_date",
    "interview_scheduled_date_time",
    "candidate_track_link",
  ];

  return (
    <>
      {pageLoading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        <LoadingOverlay
          active={loadingOverlay}
          spinner
          text="Updating Details..."
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "5px",
            }}
          >
            <Button
              style={{
                width: "8rem",
                height: "2.5rem",
                marginRight: "5rem",
              }}
              onClick={() => {
                setShowFilter(true);
              }}
            >
              Filters
            </Button>
            <div style={{ marginRight: 10 }}>
              <DownloadDialog
                getDateParam={getDateParam}
                filters={filters}
                reportName={"company-report"}
                endPoint={endPoint}
                presetValues={presetValues}
              />
            </div>
            {/* <Button
              style={{
                width: "12rem",
                height: "2.5rem",
              }}
              onClick={() => {
                //   setCanDownload(true);
                downloadAllDataCSV();
              }}
            >
              {loadingAllDataDownload ? (
                <CustomButtonCircularProgress />
              ) : (
                "Download"
              )}
            </Button> */}
          </div>
          <TableWithExport
            title={getTableTitleText()}
            data={tableData}
            // columns={filteredColumns}
            columns={columns}
            fName="Selected_Companies"
            showNestedTable={true}
            onTreeExpandChange={onTreeExpandChange}
            selection={false}
            sorting={false}
            search={false}
            filtering={false}
          />
        </LoadingOverlay>
      )}
      {showFilter && (
        <CompanyReportFilter
          open={showFilter}
          handleClose={() => {
            setShowFilter(false);
          }}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </>
  );
};

export default CompanyWiseReports;
