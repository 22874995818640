import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { praveshAxiosGetReq } from "../../util/baseApi";
import { gifStyling } from "../../constants";
import UnlockHistory from "./UnlockHistory";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function PlanUnlockHistory() {
  const [showHistory, setShowHistory] = useState(false);
  const location = useLocation();
  const [companyHistoryToShow, setCompanyHistoryToShow] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      location?.pathname &&
      location?.pathname?.includes("plan-unlock-history")
    )
      setShowHistory(true);
  }, [location?.pathname]);

  useEffect(() => {
    if (showHistory) getCompanyList();
  }, [showHistory]);

  const companySelection = ({ companyData }) => {
    setCompanyHistoryToShow(companyData);
  };

  const getCompanyList = async () => {
    const endPoint = "/api/admin/company/get/";
    setLoading(true);

    try {
      const res = await praveshAxiosGetReq(endPoint);

      if (res?.data?.success) setCompanyList(res?.data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  useEffect(() => {
    if (companyList?.length > 0) {
      let string = location?.search;
      const compId = parseInt(string.replace(/\D/g, ""));

      const matchingCompany = companyList?.filter(
        (company) => company?.company_id === compId
      );

      setCompanyHistoryToShow(matchingCompany[0]);
    }
  }, [companyList]);

  return (
    <>
      {!showHistory ? null : (
        <Autocomplete
          id="newEditCompany"
          name="newEditCompany"
          options={companyList}
          getOptionLabel={(item) => item.company_name}
          value={companyHistoryToShow}
          onChange={(e, newValue) => {
            if (newValue) {
              setCompanyHistoryToShow(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Company*" variant="outlined" />
          )}
        />
      )}
      <br />
      {loading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : companyHistoryToShow ? (
        <UnlockHistory
          showHistory={showHistory}
          companyHistoryToShow={companyHistoryToShow}
          companySelection={companySelection}
        />
      ) : null}
    </>
  );
}

export default PlanUnlockHistory;
