import { getworkApp, websiteRegexNew, GETWORK_INFO, prashasanUrl } from "../constants";

export const createPublicJobLink = (jobId, hiring_type) => {
  return `${getworkApp}/public/job/${hiring_type}/${jobId}`;
};

export const createLocationString = (detail) => {
  if (detail.location) {
    return Array.prototype.map
      .call(detail.location, function (item) {
        return item.city;
      })
      .join(",");
  } else if (detail.work_from_home) return "Work From Home";
  else return "PAN India";
};

export const educationTypes = [
  { education_id: 1, education_name: "Secondary" },
  { education_id: 2, education_name: "Senior Secondary" },
  { education_id: 3, education_name: "Graduation (UG)" },
  { education_id: 4, education_name: "Post Graduation (PG)" },
  { education_id: 5, education_name: "PhD" },
  { education_id: 6, education_name: "Diploma" },
  { education_id: 7, education_name: "Institute Certification" },

];

export const executeScrollCommon = (value) => {
  if (value !== null && value?.current?.scrollIntoView) {
    value.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export const validateWebAddress = (web) => {

  websiteRegexNew.lastIndex = 0;
  let validAddress = websiteRegexNew.test(web?.toLowerCase());
  return validAddress;

};

export const indianCurrencySeparator = (num) => {

  const curr = num.toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR'
  });
  return curr;
};

export const getMatchingElementInArr = (targetId, arr, key) => {

  const match = arr?.filter((item) => (targetId === item?.[key]));
  return match[0]

}


export function objectToFormData(obj) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
}

export function createGetWorkPublicLink(detail, source="") {
  const userData = JSON.parse(localStorage.getItem("user-data"));
  let utmDetails = "";
  if(userData?.email){
    utmDetails = `?utm_source=${source ? source : "prashasan" }&utm_medium=admin&utm_campaign=${userData?.email}&utm_content=${userData?.id}`
  }
  return `${GETWORK_INFO}/job-details/${detail?.job_id}${utmDetails}`
}


export function prashasanJobDetailLink(detail) {
  return `${prashasanUrl}/admin/public/job/${detail?.job_id}`
}


export function formatDateObject(rawDate) {
  let month = rawDate.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = rawDate.getDate();
  day = day < 10 ? `0${day}` : day;
  let startDateString = `${rawDate.getFullYear()}-${month}-${day}`;
  return startDateString;
}

export function getNumberOfDaysCurrMonth(){
// Get the current date
let now = new Date();

// Get the current year and month
let year = now.getFullYear();
let month = now.getMonth(); // Note: getMonth() returns 0 for January, 1 for February, etc.

// Create a date object for the first day of the next month
let nextMonth = new Date(year, month + 1, 1);

// Subtract one day to get the last day of the current month
nextMonth.setDate(nextMonth.getDate() - 1);

// Get the number of days in the current month
let daysInMonth = nextMonth.getDate();
console.log("DAYS", daysInMonth);

return daysInMonth;
}

export function getMonthFirstDate(){
  // Get the current date
let now = new Date();

// Get the current year and month
let year = now.getFullYear();
let month = now.getMonth(); // Note: getMonth() returns 0 for January, 1 for February, etc.

// Create a date object for the first day of the current month
let firstDateOfMonth = new Date(year, month, 1);

return firstDateOfMonth;
}