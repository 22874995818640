import { Button, Chip, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { FailureAlert } from "../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
  },
  pchip: {
    whiteSpace: 'initial',
    overflow: 'true',
    // textOverflow: 'ellipsis',
    maxWidth: '40rem',
  },
}));

export default function JobRoles({jobRolesList, JobRolesPreference, savePreferences}) {
  const classes = useStyles();

  const [tempJobRoles, setTempJobRoles] = useState([]);

  useEffect(() => {
      setTempJobRoles(JobRolesPreference ? JobRolesPreference : []);
  }, [JobRolesPreference])

  const handleDelete = (index)  => {
    setTempJobRoles((tempJobRoles) =>
    tempJobRoles.filter((_, jobRoleIndex) => jobRoleIndex !== index)
    );
  };

  const addJobRoles = (jobRole) => {
    if(jobRole){
      const industryAlreadyAdded = tempJobRoles.some(jR => jR.id === jobRole.id);
      if(industryAlreadyAdded)
        FailureAlert("Already Added!");
      else
        setTempJobRoles([...tempJobRoles , jobRole])
    }
  }

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Job Roles Interested in</Typography>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "grid" }}>
          <Autocomplete
              // multiple
              id="combo-box-demo"
              onChange={(_, newValue) => {
                if (newValue) {
                  addJobRoles(newValue);
                }
              }}
              
              options={jobRolesList}
              getOptionLabel={(option) => `${option.job_role_name}`}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.job_role_name}`}</span>;
              }}
                className={classes.fieldStyling1}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}>
                        Select Job Roles
                      </span>
                    }
                    variant="outlined"
                    
                  />
                )}}
          />
          </Grid>
          {/* <Grid item xs={2} style={{ display: "grid" }}>
            <Button variant="contained" color="primary">
              Add
            </Button>
          </Grid> */}
        </Grid>
      </div>

      <div className={classes.root}>
        {tempJobRoles && tempJobRoles.map((data, index) => {
          return (
            <li key={data.id}>
              <Chip
                color="primary"
                deleteIcon={<CloseIcon color="primary" />}
                variant="outlined"
                size="medium"
                label={
                  <div className={classes.pchip}>
                  {data.job_role_name}
                  </div>
                  }
                onDelete={() => handleDelete(index)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" color="primary" onClick={() => {savePreferences("jobrole", tempJobRoles)}}>
          Save
        </Button>
      </div>
    </div>
  );
}
