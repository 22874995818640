import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
  },
  paperRed: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
    background: "#ffdadd",
  },
  para1: {
    color: "#6c757d",
    textAlign: "left",
    fontSize: 13,
  },
  para2: {
    color: "#6c757d",
    textAlign: "left",
    fontSize: 12,
  },
  para3: {
    color: "#6c757d",
    textAlign: "left",
    fontSize: 13,
  },
  heading: {
    textAlign: "left",
    fontSize: 15,
  },

  paraRed1: {
    color: "#ff172c",
    textAlign: "left",
    fontSize: 13,
  },
  paraRed2: {
    color: "#ff6775",
    textAlign: "left",
    fontSize: 12,
  },
  paraRed3: {
    textAlign: "left",
    fontSize: 13,
    color: "#ca0000",
  },
  headingRed: {
    textAlign: "left",
    fontSize: 15,
    color: "#ca0000",
  },
}));

function EducationDetailsCards(props) {
  const { data, index, editBtn, handleDelete, educationFormRef, handleDeleteParse, editBtnParse } = props;
  //   const {
  //     userDetails,
  //     setUserDetails,
  //     errorMessages,
  //     setErrorMessages,
  //     currentCity,
  //     setCurrentCity,
  //     currentHomeCity,
  //     setCurrentHomeCity,
  //     currentGender,
  //     setCurrentGender,
  //     allCities,
  //     buttonClicked,
  //     setButtonClicked,
  //     openAddEducation,
  //     setOpenAddEducation,
  //     allColleges,
  //     allBoards,
  //     educationDetails,
  //     setEducationDetails,
  //     educationDetailsErrors,
  //     setEducationDetailsErrors,
  //     educationDetailsList,
  //     setEducationDetailsList,
  //   } = props;
  const classes = useStyles();
  const checkIfAllDataPresent = (item) => {
    var boole = false;
    if (item.education) {
      if (item.education <= 2) {
        if (item.board && item.percentage && item.start_date && item.end_date) boole = true;
      } else {
        if (item.college_name && item.percentage && item.degree_id && item.specialization_id && item.start_date && item.end_date) boole = true;
      }
    }
    return boole;
  };
  return (
    <>
      <Paper className={checkIfAllDataPresent(data) ? classes.paper : classes.paperRed}>
        <Grid container>
          <Grid item xs={!data?.is_current ? 10 : 11}>
            <Typography variant="body1" className={checkIfAllDataPresent(data) ? classes.heading : classes.headingRed}>
              Education&nbsp;{index + 1}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <EditIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                if (!data.education_id) editBtnParse(index);
                else editBtn(data?.education_id);
              }}
            />
          </Grid>
          {!data?.is_current && (
            <Grid item xs={1}>
              <DeleteIcon
                style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
                onClick={() => {
                  if (!data.education_id) handleDeleteParse(index);
                  else handleDelete(data?.education_id);
                }}
              />
            </Grid>
          )}
        </Grid>
        {data?.is_saas && (
          <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para1 : classes.paraRed1}>
            Enrollment Id: {data?.enrollment_id ? data?.enrollment_id : "NA"}
          </Typography>
        )}
        <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para1 : classes.paraRed1}>
          {data?.education <= 2 ? data?.board_name : data?.college === 142 ? data?.temp_college_name : data?.college_name}
        </Typography>
        {/* Education start and end date */}
        <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para3 : classes.paraRed3}>
          {data?.formatted_start_date ? data?.formatted_start_date + " - " : ""} {data?.formatted_end_date}
        </Typography>

        <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para1 : classes.paraRed1}>
          {data?.education <= 2
            ? data?.highest_qualification
            : data?.degree && data?.specialization
            ? data?.highest_qualification + " - " + data?.degree + " in " + data?.specialization
            : data?.highest_qualification}
        </Typography>
        <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para1 : classes.paraRed1}>
          Percentage: {data?.percentage}
        </Typography>
      </Paper>
    </>
  );
}

export default EducationDetailsCards;
