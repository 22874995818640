import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {copyToClipboard} from '../../util/copyToClipboard';
import { gifStyling, createIconStyling, requestPraveshOptions } from '../../constants';
import {Form, Col, Table, Button} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {TabPanel} from '../../util/TabPanel';
import {AppBar, Tabs, Tab} from '@material-ui/core';

    const token = localStorage.getItem('token');
  
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
          id: `wrapped-tab-${index}`,
          'aria-controls': `wrapped-tabpanel-${index}`,
        };
    } 

function UploadImages() {

    const [applicationType, setApplicationType] = useState(null);
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [count, setCount] = useState(0);
    
    const [file, setFile] = useState(null);

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    // Fetching existing Images
    useEffect(()=> {
        if(value === 0) {
        setLoading(true);
        
        if(applicationType) {
            apiCall(`/api/admin/upload/image_to_s3/?folder_name=${applicationType}`, requestPraveshOptions)
            .then((res)=> {
                setImages(res.data)
                setLoading(false)
            })
        }
        setLoading(false)
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationType, count,value]);

    const deleteImage = (e, image) => {
        const requestOptionsPatch = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };
        setLoading(true)
        apiCall(`/api/admin/upload/image_to_s3/${image.id}/`, requestOptionsPatch)
            .then((res) => {
                if(res.success) {
                    SuccessAlert(res.data.message);
                    setCount(count+1);
                }
                else {
                    FailureAlert(res.error);
                }
              setLoading(false)
            })
    }

    const addImage = (e) => {
        if(!file) {
            FailureAlert('Upload an image to continue...')
            return
        }
        e.preventDefault();
        const formData = new FormData();
         formData.append('folder_name', applicationType);
         formData.append('image', file);
        formData.append('asset_key', fileName);
        const requestOptionsPost = {
            method: 'POST',
            headers: { 'Authorization': `Token ${token}` },
            body: formData
        };
        setLoading(true)
        apiCall('/api/admin/upload/image_to_s3/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.error);
            }
            setLoading(false)
        })
        .then(setFile(null))
        e.target.reset();
    }

    return (
        <div className="update__image">
            <Form.Row style={{marginLeft: '10%', width: '80%', marginTop: '2%'}}>
                           <Form.Group as={Col} controlId="applicationType">
                                    <Form.Label>Select Application Type</Form.Label>
                                    <Form.Control as="select" onChange = {e=> {setApplicationType(e.target.value)}} >
                                        <option value="">--select--</option>
                                        <option value="GETWORK">getwork.org</option>
                                        <option value="APP GETWORK">app.getwork.org</option>
                                        <option value="MOBILE">Android/IOS App</option>
                                        <option value="COMMON">COMMON</option>
                                    </Form.Control>
                                </Form.Group>
                
            </Form.Row>
            {
                applicationType 
                ?
                !loading
                ?
                <div className="upload__images__section">
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Existing Images" {...a11yProps(0)} />
                            <Tab label="Add a New Image" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    {/* Deleting existing Images */}
                    <TabPanel className='upload_Pic' value={value} index={0} dir={theme.direction}>
                    {
                        images.length>0
                        ?
                        <Table striped bordered hover size="sm">
                            <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>File Name</th>
                                        <th>Image URL</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                            <tbody>
                                {
                                    images.map((image, key) => {
                                    return (
                                        <tr key={key}>
                                            <td style={{display: 'flex', flexDirection: 'row', placeItems:'center'}}>
                                                <img  
                                                    style={{height:'180px', width:'350px'}}
                                                    src={image.public_url} 
                                                    alt="images"
                                                />
                                               </td>
                                               <td>
                                                 {image.asset_key}
                                                 <FileCopyIcon style={createIconStyling} onClick={e => copyToClipboard(image.asset_key)} />
                                                </td>
                                            <td>
                                             {image.public_url}
                                             <FileCopyIcon style={createIconStyling} onClick={e => copyToClipboard(image.public_url)} />
                                             </td>
                                             <td>
                                                { image.id && 
                                                    <Button 
                                                        variant="danger"
                                                        onClick={e=>deleteImage(e, image)}
                                                    >
                                                    Delete 
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </Table>
                        :
                        <p style={{paddingTop: '10%', paddingLeft: '40%'}}>
                            Nothing to Display
                        </p>
                    }
                              
                    </TabPanel>

                    {/* Adding a new Image */}
                    
                    <TabPanel value={value} index={1} dir={theme.direction}>
                    <Form.Group as={Col} controlId="file">
                            <Form.Label>File Name</Form.Label>
                            <Form.Control 
                                name="filename" 
                                type="text" 
                                placeholder="File Name should not be more than 50 Characters, E.g. STUDENT_BANNER.png"
                                onChange={e=> {setFileName(e.target.value)}}
                                required 
                            />
                            
                        </Form.Group>                     
                        <Form onSubmit={e=>addImage(e)}>
                        <Form.File 
                            id="formcheck-api-custom" 
                            custom style={{marginTop:'3%', marginBottom:'3%'}}
                        >
                            <Form.File.Input onChange={e=>{
                                setFile(e.target.files[0])
                                } 
                            }
                                isValid />
                            
                            <Form.File.Label data-browse="Upload Image">
                                Click to choose file from local directory
                            </Form.File.Label>
                            {
                                file ? 
                                <Form.Control.Feedback type="valid">{file.name}</Form.Control.Feedback> 
                                : null
                            }
                        </Form.File>
                        <Button type='submit'>Upload</Button>            
                        </Form>                
                    </TabPanel>
                </div>
                :
                <img 
                    src={gif} 
                    alt="Getwork Loading Gif..."
                    style={gifStyling}
                />
                :
                null
            }
        </div>
    )
}

export default UploadImages;