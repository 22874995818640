import React from 'react'

function NotFound() {
    return (
        <div>
            <h3>Page Not Found</h3>
            <a href="/admin">Home</a>
        </div>
    )
}

export default NotFound
