import {
  TextField,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { niyuktiAxiosCall, niyuktiAxiosGetReq } from "../../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../../util/Notifications"; //../../../util/Notifications

export default function SendMail({
  open,
  handleClose,
  studentIds
}) {
  const [showLoader, setShowLoader] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const [openJobs, setOpenJobs] = useState([]);

  useEffect(() => {
    const getOpenJobsList = () => {
      niyuktiAxiosCall
        .get(`/job/all_job/list/`)
        .then((res) => {
          if (res.data.success) {
            setOpenJobs(res.data.data);
          } /* else {
                    setOpenJobsFlag(true);
                    setAllApiSuccess({ status: -1, message: res.error });
                  } */
        })
        .catch((err) => {
          // setOpenJobsFlag(true);
          // setAllApiSuccess({
          //   status: -1,
          //   message: `Open Job List Error : ${err.message}`,
          // });
        });
    };
    getOpenJobsList();
  }, []);

  const handleSubmit = () => {
    if (selectedJob && studentIds) {
      // if (selectedJob) {
      // http://niyuktitest.getwork.org/job/admin/send_email_to_candidate/?job_id=1305&student_id=991
      setShowLoader(true);
      niyuktiAxiosGetReq(
        `/job/admin/send_email_to_candidate/?job_id=${
          selectedJob.job_id
        }&student_id=${
          Array.isArray(studentIds) ? studentIds.join(",") : studentIds
        }`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert("Mail Sent Successfully!!");
            handleClose();
          } else {
            FailureAlert(res.data.error);
          }
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          FailureAlert(err.message);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.075)",
            // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 4px",
          },
        }}
      >
        <LoadingOverlay active={showLoader} spinner text="Please Wait...">
          <DialogContent>
            <Typography variant="h6" id="form-dialog-title">
              Select Job
            </Typography>
            <br />
            <Autocomplete
              value={selectedJob}
              onChange={(event, newValue) => {
                // if (newValue) {
                setSelectedJob(newValue);
                // }
              }}
              id="role345"
              options={openJobs}
              getOptionLabel={(option) =>
                `${option.job_title}-${option.hiring_type} (${option.job_id})`
              }
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.job_title} -${option.hiring_type} (${option.job_id})`}</span>
                );
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}>
                        Select Job
                        <span /* className={classes.RedStar} */>*</span>
                      </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </DialogContent>
        </LoadingOverlay>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (!selectedJob?.job_id) {
                FailureAlert("Please Select the Job");
                return;
              } else {
                handleSubmit();
              }
            }}
          >
            Send Mail
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
