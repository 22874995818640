import React from "react";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";

import { CsvBuilder } from "filefy";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#ff9100",
    },
  },
});

function BulkStudentApply({
  title,
  tableRef = { tableRef },
  data,
  columns,
  fName,
  pageValue,
  editableObject,
  selection = true,
  setSelectedID,
  onSelectionChange,
  cellFixedIndex = 1,
  selectedRowsData,
}) {
  // this has been done to sync up filtering between filters and selection through checkbox.
  const [selectedItems, setSelectedItems] = useState({
    initialLoad: true,
    rows: [],
    cloumns: [],
  });
  //debugger
  useEffect(() => {
    if (columns?.length > 0 && cellFixedIndex) {
      columns[cellFixedIndex].cellStyle = {
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
      };
      columns[cellFixedIndex].headerStyle = {
        position: "sticky",
        left: 0,
        zIndex: 1000,
      };
      columns[cellFixedIndex].filterCellStyle = {
        position: "sticky",
        left: 0,
        zIndex: 100,
        backgroundColor: "#fff",
      };
    }
  }, [columns, cellFixedIndex]);

  return (
    <div className="table__with__export">
      <ThemeProvider theme={theme}>
        <MaterialTable
          title={title}
          tableRef={tableRef}
          data={data}
          columns={columns}
          options={{
            search: true,
            sorting: true,
            selection: selection,
            filtering: true,
            paging: true,
            pageSize: pageValue || 50,
            pageSizeOptions: [10, 20, 50, 100, 200],
            exportButton: { pdf: false, csv: true },
            exportAllData: true,
            emptyRowsWhenPaging: false,
            draggable: false,
            headerStyle: {
              position: "sticky",
              top: 0,
              backgroundColor: "#ebf5fa",
            },
            maxBodyHeight: "430px",
            cellStyle: {
              textAlign: "center",
              formControlStyle: {
                minWidth: "300px",
                maxWidth: "300px",
                width: "500px",
              },
            },
          }}
          // onSelectionChange={onSelectionChange}
          onSelectionChange={(rows) => {
            // setSelectedID(rows);
            // onSelectionChange(rows);
            selectedRowsData.current = rows;
            setSelectedItems({
              ...selectedItems,
              data: rows,
              initialLoad: false,
              columns: selectedItems.initialLoad
                ? columns
                : selectedItems.columns,
            });
          }}
          actions={[
            {
              position: "toolbarOnSelect",
              icon: SaveAlt,
              tooltip: "Export the selected rows!",
              onClick: (e, rowData) => {
                const fileName = fName;
                const builder = new CsvBuilder(fileName + ".csv");
                builder
                  .setColumns(columns.map((columnDef) => columnDef.title))
                  .addRows(
                    rowData.map((rowData) =>
                      columns.map((columnDef) => rowData[columnDef.field])
                    )
                  )
                  .exportFile();
              },
            },
          ]}
          editable={editableObject}
          localization={{
            header: {
              actions: "",
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
}

export default BulkStudentApply;
