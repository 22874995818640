import { AppBar, CircularProgress, Tab, Tabs } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { useTheme } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  createIconStyling,
  gifStyling,
  headerCSS,
  requestNiyuktiOptions,
  requestPraveshOptions,
} from "../../constants";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import { copyToClipboard } from "../../util/copyToClipboard";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { printNumber, validateDateTime } from "../../util/printNumber";
import { TabPanel } from "../../util/TabPanel";
import { createLocationString, createPublicJobLink } from "../../util/utility";
import { viewJobDetail } from "../../util/ViewJobFunction";
import { DateSelector } from "../common/DateSelector";
import DialogWithReason from "../DialogSection/DialogWithReason";
import TableWithExport from "../TableWithExport";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const labelStyle = {
  fontSize: "13px",
  marginBottom: "1px",
  color: "green",
};

function CloseJobRequest({ job_status, history, offCampus }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [onCampusData, setOnCampusData] = useState([]);
  const [offCampusData, setOffCampusData] = useState([]);
  const [hasOnCampusData, setHasOnCampusData] = useState(false);
  const [hasOffCampusData, setHasOffCampusData] = useState(false);
  const theme = useTheme();
  const [value, setValue] = useState(offCampus ? 1 : 0);

  const [open, setOpen] = useState(false);
  const [modalCollegesData, setModalCollegesData] = useState([]);
  const [modalAssignmentsData, setModalAssignmentsData] = useState([]);
  const [modalCloseJobData, setModalCloseJobData] = useState({});
  const [modalOpenAgain, setModalOpenAgain] = useState({});
  const [closeReason, setCloseReason] = useState("");
  const [count, setCount] = useState(0);
  const [jobId, setJobId] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const [uploadAssignment, setUploadAssignment] = useState(false);
  const [viewCollegeModal, setViewCollegeModal] = useState(false);
  const [viewAssignmentModal, setViewAssignmentModal] = useState(false);
  const [uploadAssignmentModal, setUploadAssignmentModal] = useState(false);
  const [viewExtraDataModal, setViewExtraDataModal] = useState(false);
  const [extraData, setExtraData] = useState({});
  const [jobStage, setJobStage] = useState("");
  const [followUp1, setFollowUp1] = useState("");
  const [followUp2, setFollowUp2] = useState("");
  const [followUp3, setFollowUp3] = useState("");

  const dateRange = [new Date(2021, 0, 1), new Date()];

  const jobCategorySearchObject = {
    HOT: "HOT",
    URGENT: "URGENT",
    PREMIUM: "PREMIUM",
    POPULAR: "POPULAR",
  };

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  const handleClose = () => {
    setOpen(false);
    // setUploadAssignment(false);
    setViewCollegeModal(false);
    setViewAssignmentModal(false);
    setUploadAssignmentModal(false);
    setViewExtraDataModal(false);
    setJobId(null);
    setJobTitle("");
    setCompanyName("");
    setModalCollegesData([]);
    setModalCloseJobData({});
    setModalOpenAgain({});
    setModalAssignmentsData([]);
    setExtraData({});
    setJobStage("");
    setFollowUp1("");
    setFollowUp2("");
    setFollowUp3("");
  };

  const updateList = () => {
    if (value === 0) setHasOnCampusData(false);
    if (value === 1) setHasOffCampusData(false);
    setCount(count + 1);
  };
  const handleOpen = () => setOpen(true);

  const expLabel = [
    { value: 0, label: "0" },
    { value: 10, label: "10 lpa" },
    { value: 25, label: "25 lpa" },
  ];

  const explabelVacancy = [
    { value: 0, label: "0" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
  ];

  const handleSlider = (newRange, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, newRange);
  };

  const handleVacancySlider = (newRange, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, newRange);
  };

  const loadCollegesModal = (e, job_id) => {
    setViewCollegeModal(true);
    setCircularLoading(true);
    apiNiyuktiCall(
      `/job/job_college/?job_id=${job_id}`,
      requestNiyuktiOptions
    ).then((res) => {
      if (res.success) {
        setModalCollegesData(res.data);
        setCircularLoading(false);
      } else FailureAlert(res.error);
    });
    handleOpen();
  };

  const loadCloseJobModal = (e, obj) => {
    setModalCloseJobData(obj);
    handleOpen();
  };

  const loadAgainOpenJobModal = (e, obj) => {
    setModalOpenAgain(obj);
    handleOpen();
  };

  const closeParticularJob = () => {
    const jobId = modalCloseJobData?.job_id;
    if (!closeReason) {
      FailureAlert("Specify Reason to Close Job!!");
      return;
    }

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        job_ids: [jobId],
        close_job: "CLOSE",
        close_reason: closeReason,
        company_id: modalCloseJobData?.company_id,
      }),
    };

    apiNiyuktiCall("/job/saas/admin/job_closing/", requestOptionsPost).then(
      (res) => {
        if (res?.success) {
          SuccessAlert(res?.data?.message);
          setCloseReason("");
          updateList();
          handleClose();
        } else {
          FailureAlert(res.error);
        }
      }
    );
  };

  const againOpenParticularJob = () => {
    const jobId = modalOpenAgain?.job_id;
    if (!closeReason) {
      FailureAlert("Specify Reason to Open this Job!!");
      return;
    }

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        job_ids: [jobId],
        close_job: "OPEN",
        close_reason: closeReason,
        company_id: modalOpenAgain?.company_id,
      }),
    };

    apiNiyuktiCall("/job/saas/admin/job_closing/", requestOptionsPost)
      .then((res) => {
        if (res?.success) {
          SuccessAlert(res.data.message);
          setCloseReason("");
          updateList();
          handleClose();
        } else {
          FailureAlert(res?.error);
        }
      })
      .catch((err) => {
        FailureAlert("Something went wrong!");
      });
  };

  function getCtcRange(row) {
    return row.ctc_min && row.ctc_max
      ? `${printNumber(row.ctc_min)}-${printNumber(row.ctc_max)}`
      : row.ctc_min
      ? `${printNumber(row.ctc_min)}+}`
      : row.ctc_max
      ? `< ${printNumber(row.ctc_max)}}`
      : null;
  }

  function getSalaryText(detail, count) {
    let return_text = "";
    if (detail.job_type_id === 1) {
      return_text += `*${count++}. Stipend:* Rs. ${printNumber(
        detail.stipend
      )} per month \n*${count++}. PPO:* ${detail.ppo ? "Yes" : "No"} \n`;
      if (detail.ppo) {
        return_text += `*${count++}. CTC After PPO:* Rs. ${getCtcRange(
          detail
        )} \n`;
      }
    } else {
      return_text += `*${count++}. CTC:* ${getCtcRange(detail)} \n`;
    }
    return_text += `*${count++}. Work from Home:* ${
      detail.work_from_home ? "Yes" : "No"
    } \n*${count++}. Location:* ${createLocationString(detail)} \n*${count++}.`;
    return return_text;
  }

  function contactDetail(detail) {
    var return_text = "";
    if (detail?.company_user) {
      for (var i = 0; i < detail.company_user.length; i++) {
        return_text += `*Contact Details :* \n`;
        return_text += `*1. Name:* ${detail?.company_user[i]?.name}\n*2. Contact:* ${detail?.company_user[i]?.mobile}\n*3. Email:* ${detail?.company_user[i]?.email}\n`;
      }
    }
    return return_text;
  }

  function sharingText(detail) {
    let count = 1;
    return `*${count++}. Company Name:* ${
      detail.company_name
    } \n*${count++}. Job Title:* ${
      detail.job_title
    } \n*${count++}. Job Type:* ${detail.job_type_name} \n${getSalaryText(
      detail,
      count
    )} \n\n${contactDetail(
      detail
    )} \n*Note:* _This job is powered by GetWork! When calling the contact person, always tell that you found this job on GetWork._\n`;
  }

  const loadExtraDataModal = (e, job_id, extra_data) => {
    setJobId(job_id);
    setViewExtraDataModal(true);
    setExtraData(extra_data);
    extra_data?.job_stage && setJobStage(extra_data.job_stage);
    extra_data?.follow_up1 && setFollowUp1(extra_data.follow_up1);
    extra_data?.follow_up2 && setFollowUp2(extra_data.follow_up2);
    extra_data?.follow_up3 && setFollowUp3(extra_data.follow_up3);
    handleOpen();
  };

  const editExtraData = (e, jobId) => {
    e.preventDefault();
    if (!jobId) {
      FailureAlert("Job ID not Selected!!");
      return;
    }
    let temp = { ...extraData };
    temp["job_stage"] = jobStage;
    temp["follow_up1"] = followUp1;
    temp["follow_up2"] = followUp2;
    temp["follow_up3"] = followUp3;

    const requestForExtraDataEdit = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ extra_data: temp }),
    };
    apiNiyuktiCall(
      `/job/admin/job_stage/${jobId}/`,
      requestForExtraDataEdit
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        updateList();
        handleClose();
      } else FailureAlert(res.error);
    });
  };

  useEffect(() => {
    let hiring_type = " ";
    if (value === 1) hiring_type = "OFF CAMPUS";
    if (value === 0) hiring_type = "ON CAMPUS";
    if (value === 0 && hasOnCampusData) return;
    if (value === 1 && hasOffCampusData) return;

    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 7)
    );
    let initialEndDate = new Date();

    if (startDate === "") {
      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = new Date(queryParams.from);
      }
      if (queryParams.to) {
        initialEndDate = new Date(queryParams.to);
      }
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      return;
    } else if (startDate === null) {
      initialStartDate = null;
    } else {
      initialStartDate = new Date(startDate);
      initialEndDate = new Date(endDate);
    }

    let dateString = "";
    if (initialStartDate) {
      let month = initialStartDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = initialStartDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${initialEndDate
          .toISOString()
          .substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      dateString = `&start_date=${startDateString}&end_date=${initialEndDate
        .toISOString()
        .substring(0, 10)}`;
    } else {
      history.replace({
        search: "",
      });
    }

    function convertToPlain(rawHtml) {
      let tempDivElement = document.createElement("div");
      tempDivElement.innerHTML = rawHtml;
      return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    setLoading(true);

    apiCall(
      `/api/admin/job/detail/?job_status=${job_status}&hiring_type=${hiring_type}${dateString}`,
      requestPraveshOptions
    ).then((json) => {
      if (json.success) {
        // console.log("json data", json.data);
        let temp = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            job_id: json.data[i].job_id,
            job_title: json.data[i].job_title,
            job_type_name: json.data[i].job_type_name,
            employment_type: json.data[i].employment_type_name,
            job_type_id: json.data[i].job_type_id,
            ppo: json.data[i].ppo,
            company_user: json.data[i].company_user,
            work_from_home: json.data[i].work_from_home,
            location: json.data[i].location,
            formatted_location: json?.data[i]?.location.map((item) => {
              return `${item.city},`;
            }),
            stipend: json.data[i].stipend,
            job_role: json.data[i].job_role,
            job_payment_type: json.data[i].job_payment_type,
            // job_tmc: json.data[i].job_tmc ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(json.data[i].job_tmc) }}></div> : '',

            job_tmc: json.data[i].job_tmc
              ? convertToPlain(json.data[i].job_tmc)
              : null,

            hiring_type: json.data[i].hiring_type,
            colleges: json.data[i].college,
            ctc_min: json.data[i].ctc_min,
            ctc_max: json.data[i].ctc_max,
            vacancy: json.data[i].vacancy || "",
            create_time: validateDateTime(json.data[i].create_time),
            update_time: validateDateTime(json.data[i].update_time),
            equity_min: json.data[i].equity_min,
            equity_max: json.data[i].equity_max,
            applicants: json.data[i].applicants,
            counts: json.data[i].counts || [],
            apply_start_date: json.data[i].apply_start_date,
            apply_end_date: json.data[i].apply_end_date,
            company_id: json.data[i].company
              ? json.data[i].company.company_id
              : null,
            company_name: json.data[i].company
              ? json.data[i].company.company_name
              : null,
            job_close_reason: json.data[i].job_close_reason,
            job_assignment: json.data[i].job_assignment,
            job_category: json?.data[i]?.job_category,
            extra_data: json.data[i].extra_data,
            job_lake: json.data[i].job_lake ? "Yes" : "No",
            job_stage:
              json.data[i].extra_data && json.data[i].extra_data.job_stage
                ? json.data[i].extra_data.job_stage
                : "",
            follow_up1:
              json.data[i].extra_data && json.data[i].extra_data.follow_up1
                ? json.data[i].extra_data.follow_up1
                : "",
            follow_up2:
              json.data[i].extra_data && json.data[i].extra_data.follow_up2
                ? json.data[i].extra_data.follow_up2
                : "",
            follow_up3:
              json.data[i].extra_data && json.data[i].extra_data.follow_up3
                ? json.data[i].extra_data.follow_up3
                : "",
            acc_manager_name:
              json.data[i].company && json.data[i].company.account_manager
                ? (json.data[i].company.account_manager.first_name || "") +
                  " " +
                  (json.data[i].company.account_manager.last_name || "")
                : "",
            acc_manager_email:
              json.data[i].company && json.data[i].company.account_manager
                ? json.data[i].company.account_manager.email
                : "",
          };
          let applied = obj.counts.find((o) => o.status === 1);
          obj["applied"] = applied?.count || 0;
          // let reviewed = obj.counts.find(o => o.status === 8)
          // obj['reviewed'] = reviewed?.count || 0
          // let shortlisted = obj.counts.find(o => o.status === 2)
          // obj['shortlisted'] = shortlisted?.count || 0
          let interviewed = obj.counts.find((o) => o.status === 7);
          obj["interviewed"] = interviewed?.count || 0;
          let hired = obj.counts.find((o) => o.status === 13);
          let rejected = obj.counts.find((o) => o.status === 14);
          obj["hired-rejected"] = (rejected?.count || 0) + (hired?.count || 0);
          let offered = obj.counts.find((o) => o.status === 17);
          obj["offered"] = offered?.count || 0;
          let joined = obj.counts.find((o) => o.status === 18);
          obj["joined"] = joined?.count || 0;
          let waitlisted = obj.counts.find((o) => o.status === 19);
          obj["waitlisted"] = waitlisted?.count || 0;
          temp.push(obj);
        }
        if (hiring_type === "ON CAMPUS") {
          setOnCampusData(temp);
          setHasOnCampusData(true);
        }
        if (hiring_type === "OFF CAMPUS") {
          setOffCampusData(temp);
          setHasOffCampusData(true);
        }
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate, value]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      title: "Job_ID",
      headerStyle: {
        position: "sticky",
        left: 0,
        zIndex: 1000,
      },
      cellStyle: {
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
        fontSize: 13,
      },
      filterCellStyle: {
        position: "sticky",
        left: 0,
        zIndex: 100,
        backgroundColor: "#fff",
      },
      field: "job_id",
      render: (row) =>
        row.job_id ? (
          <div style={{ display: "flex", flexDirection: "col" }}>
            <div style={{ paddingRight: "5px" }}>{row.job_id}</div>
            <WhatsappShareButton
              style={{ outline: "none", display: "flex" }}
              url={createPublicJobLink(
                row.job_id,
                (row.hiring_type = "OFF CAMPUS" ? "offcampus" : "oncampus")
              )}
              title={sharingText(row)}
              separator="*Job Link:*"
            >
              <WhatsappIcon round={true} size={32} />
            </WhatsappShareButton>
          </div>
        ) : null,
    },
    {
      title: "Company_Name",
      field: "company_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job Category",
      field: "job_category",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: jobCategorySearchObject,
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Account_Manager_Name",
      field: "acc_manager_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Account_Manager_Email",
      field: "acc_manager_email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.acc_manager_email ? (
          <div>
            {row.acc_manager_email}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.acc_manager_email)}
            />
          </div>
        ) : null,
    },
    {
      title: "Employment",
      field: "employment_type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { "Full Time": "Full Time", "Part Time": "Part Time" },
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Job_Title",
      field: "job_title",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },

    {
      title: "Job_Type",
      field: "job_type_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: {
        Internship: "Internship",
        "On campus student employment": "On campus student employment",
        Fellowship: "Fellowship",
        "Part Time Job": "Part Time Job",
        Volunteer: "Volunteer",
        Training: "Training",
        "Full Time Job": "Full Time Job",
      },
      searchable: false,
      filterPlaceholder: "Select",
    },

    {
      title: "Job_Role",
      field: "job_role",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "View_Job Details",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={() => viewJobDetail(row.job_id)}
        >
          View Job
        </Button>
      ),
    },
    ...(value === 0
      ? [
          {
            title: "Colleges",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <Button
                variant="outline-info"
                size="sm"
                style={{
                  fontSize: 13,
                }}
                onClick={(e) => loadCollegesModal(e, row.job_id)}
              >
                Colleges
              </Button>
            ),
          },
        ]
      : []),
    {
      title: "CTC_Range",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      field: "ctc_min",
      searchable: false,
      filterComponent: (props) => (
        <Slider
          style={{ zIndex: "10" }}
          value={props.columnDef.tableData.filterValue || [0, 25]}
          defaultValue={[0, 25]}
          onChange={(e, val) => handleSlider(val, props)}
          min={0}
          max={25}
          step={2}
          marks={expLabel}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={(e) => `${e} lpa`}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData.ctc_min / 100000 >= term[0] &&
          rowData.ctc_max / 100000 <= term[1]) ||
        rowData.ctc_max / 100000 >= term[0],

      render: (row) => (
        <div>
          {row.ctc_min && row.ctc_max
            ? `${printNumber(row.ctc_min)}-${printNumber(row.ctc_max)}`
            : row.ctc_min
            ? `${printNumber(row.ctc_min)}+}`
            : row.ctc_max
            ? `< ${printNumber(row.ctc_max)}}`
            : null}
        </div>
      ),
    },
    {
      title: "Stipend",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      field: "stipend",
      render: (row) => (
        <div>{row.stipend ? `${printNumber(row.stipend)}` : null}</div>
      ),
    },
    {
      title: "Location",
      field: "formatted_location",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
        fontSize: "13px",
        padding: "6px 25px",
        marginRight: "5px",
        overflow: "hidden",
      },

      customFilterAndSearch: (term, rowData) => {
        let searchTerms = [];
        if (term) {
          searchTerms = term.split(",").map(function (item) {
            return item.trim();
          });
          // if (searchTerms.length > 1)
          //     searchTerms = searchTerms.slice(0, -1);
        }
        const bb = searchTerms.reduce((prev, currVal) => {
          if (currVal)
            rowData?.formatted_location?.forEach((item) => {
              prev =
                prev || item?.toLowerCase().includes(currVal?.toLowerCase());
            });
          return prev;
        }, false);
        return bb;
      },
    },
    {
      title: "Creation_Date",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.create_time) &&
        Date.parse(term[1]) >= Date.parse(rowData.create_time),
    },
    {
      title: "Apply_Start_Date",
      field: "apply_start_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Open Calendar",
      type: "date",
    },
    {
      title: "Apply_End_Date",
      field: "apply_end_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Open Calendar",
      type: "date",
    },
    // ...value === 0 ? [{
    //     title: "Posted_On_Job_Lake?", field: 'job_lake', headerStyle: headerCSS, cellStyle: cellCSS
    // }] : [],
    ...(value === 0
      ? [
          {
            title: "Posted_On_Job_Lake?",
            field: "job_lake",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            lookup: { Yes: "Yes", No: "No" },
            searchable: false,
            filterPlaceholder: "Select",
          },
        ]
      : []),
    {
      title: "Job_Stage",
      field: "job_stage",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Follow_Up_1",
      field: "follow_up1",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Follow_Up_2",
      field: "follow_up2",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Follow_Up_3",
      field: "follow_up3",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Vacancies",
      field: "vacancy",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterComponent: (props) => (
        <Slider
          style={{ zIndex: "10" }}
          value={props.columnDef.tableData.filterValue || [0, 50]}
          defaultValue={[0, 50]}
          onChange={(e, val) => handleVacancySlider(val, props)}
          min={0}
          max={50}
          step={5}
          marks={explabelVacancy}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={(e) => `${e}`}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.vacancy >= term[0] && rowData.vacancy <= term[1],
      render: (row) => `${row.vacancy}`,
    },
    {
      title: "Applicants",
      field: "applicants",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {row.applicants > 0 ? (
            <Button
              variant="outline-info"
              size="sm"
              onClick={() =>
                window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)
              }
            >
              {`View (${row.applicants})`}
            </Button>
          ) : (
            <Button
              variant="outline-info"
              size="sm"
              onClick={() =>
                window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)
              }
            >
              {`View (${row.applicants})`}
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Job_Payment_Type",
      field: "job_payment_type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { PAID: "PAID", UNPAID: "UNPAID" },
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Job_Terms_&_Conditions",
      field: "job_tmc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Applied",
      field: "applied",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Waitlisted",
      field: "waitlisted",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Interviewed",
      field: "interviewed",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Offered",
      field: "offered",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Joined",
      field: "joined",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Hired/Rejected",
      field: "hired-rejected",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Extra_Data",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={(e) => loadExtraDataModal(e, row.job_id, row.extra_data)}
        >
          <span style={{ whiteSpace: "noWrap" }}>Enter Client Response</span>
        </Button>
      ),
    },
    ...(job_status === "CLOSE_REQUEST"
      ? [
          {
            title: "Reason_To_Close",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <div>
                {row.job_close_reason ? (
                  <div>{row.job_close_reason}</div>
                ) : (
                  <div style={{ paddingLeft: "10%", fontSize: 13 }}>-</div>
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {
            <Button
              variant="outline-danger"
              size="sm"
              style={{ whiteSpace: "nowrap", fontSize: 13 }}
              onClick={(e) => loadCloseJobModal(e, row)}
            >
              Close Job
            </Button>
          }
        </div>
      ),
    },
    {
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {
            <Button
              variant="success"
              size="sm"
              style={{ whiteSpace: "nowrap", fontSize: 13 }}
              onClick={(e) => loadAgainOpenJobModal(e, row)}
            >
              Again Open
            </Button>
          }
        </div>
      ),
    },
  ];

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
      updateList();
    }
  };

  const removeDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    updateList();
  };

  return (
    <div className="open__close__jobs">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="ON CAMPUS Jobs" {...a11yProps(0)} />
          <Tab label="OFF CAMPUS Jobs" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {value === 0 ? (
        !loading ? (
          <TabPanel value={value} index={0} dir={theme.direction}>
            <br />
            <DateSelector
              dateRange={[startDate, endDate]}
              applyDateFilter={applyDateFilter}
              removeDateFilter={removeDateFilter}
            />
            <TableWithExport
              title={
                job_status === "CLOSE_REQUEST"
                  ? "All On Campus Closed Jobs"
                  : "All On Campus Open Jobs"
              }
              data={onCampusData}
              columns={columns}
              fName={
                job_status === "CLOSE_REQUEST"
                  ? "Selected_On_Campus_Closed_Jobs"
                  : "Selected_On_Campus_Open_Jobs"
              }
              fromJobs={true}
            />
          </TabPanel>
        ) : (
          <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
        )
      ) : null}

      {value === 1 ? (
        !loading ? (
          <TabPanel value={value} index={1} dir={theme.direction}>
            <br />
            <DateSelector
              dateRange={[startDate, endDate]}
              applyDateFilter={applyDateFilter}
              removeDateFilter={removeDateFilter}
            />
            <TableWithExport
              title={"Hire For Sure Model: Job Closing Requests"}
              data={offCampusData}
              columns={columns}
              fName={"Hire For Sure Model: Job Closing Requests"}
              fromJobs={true}
            />
          </TabPanel>
        ) : (
          <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
        )
      ) : null}

      {/* Display Colleges Modal*/}
      {viewCollegeModal ? (
        !circularLoading ? (
          modalCollegesData.length > 0 ? (
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Colleges</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>College Name</th>
                      <th>Apply Start Date</th>
                      <th>Apply End Date</th>
                      <th>Approved?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalCollegesData.map((m, key) => {
                      return (
                        <tr key={key}>
                          <td>{m.college_name}</td>
                          <td>{m.apply_start_date}</td>
                          <td>{m.apply_end_date}</td>
                          <td>{m.is_approved ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          ) : (
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Colleges</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span
                  style={{
                    display: "block",
                    marginLeft: "30%",
                    fontSize: "18px",
                    color: "red",
                    fontFamily: "cursive",
                  }}
                >
                  No Colleges Added !!!
                </span>
              </Modal.Body>
            </Modal>
          )
        ) : (
          <Modal show={open} onHide={handleClose} scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title>Colleges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CircularProgress style={{ display: "block", margin: "auto" }} />
            </Modal.Body>
          </Modal>
        )
      ) : null}

      {/* Assignments Modal*/}
      {viewAssignmentModal ? (
        !circularLoading ? (
          modalAssignmentsData.length > 0 ? (
            <Modal show={open} onHide={handleClose} scrollable={true} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Assignment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>URL</th>
                      <th>Deadline</th>
                      <th>File</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalAssignmentsData.map((m, key) => {
                      return (
                        <tr key={key}>
                          <td>{m.title}</td>
                          <td style={{ minWidth: "200px" }}>{m.description}</td>
                          <td>{m.url}</td>
                          <td>{m.end_date}</td>
                          {m.file ? (
                            <td>
                              <p
                                onClick={() => window.open(m.file)}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                View
                              </p>
                            </td>
                          ) : (
                            <td>
                              {" "}
                              <p> No File </p>{" "}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          ) : (
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Assignment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span
                  style={{
                    display: "block",
                    marginLeft: "30%",
                    fontSize: "18px",
                    color: "red",
                    fontFamily: "cursive",
                  }}
                >
                  No Assignment Added !!!
                </span>
              </Modal.Body>
            </Modal>
          )
        ) : (
          <Modal show={open} onHide={handleClose} scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title>Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CircularProgress style={{ display: "block", margin: "auto" }} />
            </Modal.Body>
          </Modal>
        )
      ) : null}

      {/* Extra Data Modal*/}
      {viewExtraDataModal ? (
        <Modal show={open} onHide={handleClose} scrollable={true}>
          <Modal.Header closeButton>
            <Modal.Title>Extra Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              className="add__assignment__form"
              onSubmit={(e) => editExtraData(e, jobId)}
            >
              <Form.Group controlId="jobStage">
                <Form.Label style={labelStyle}>Job Stage: </Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setJobStage(e.target.value);
                  }}
                >
                  {jobStage ? (
                    <option value={jobStage}>--{jobStage}--</option>
                  ) : (
                    <option value="">--Select--</option>
                  )}
                  <option value="No Action">No Action</option>
                  <option value="Follow Up 1">Follow Up 1</option>
                  <option value="Follow Up 2">Follow Up 2</option>
                  <option value="Follow Up 3">Follow Up 3</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="followUp1">
                <Form.Label style={labelStyle}>Follow Up 1: </Form.Label>
                <Form.Control
                  name="followUp1"
                  type="text"
                  placeholder="Follow Up 1"
                  value={followUp1}
                  onChange={(e) => {
                    setFollowUp1(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="followUp2">
                <Form.Label style={labelStyle}>Follow Up 2: </Form.Label>
                <Form.Control
                  name="followUp2"
                  type="text"
                  placeholder="Follow Up 2"
                  value={followUp2}
                  onChange={(e) => {
                    setFollowUp2(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="followUp3">
                <Form.Label style={labelStyle}>Follow Up 3: </Form.Label>
                <Form.Control
                  name="followUp3"
                  type="text"
                  placeholder="Follow Up 3"
                  value={followUp3}
                  onChange={(e) => {
                    setFollowUp3(e.target.value);
                  }}
                />
              </Form.Group>
              <Button
                style={{ display: "block", margin: "auto" }}
                type="submit"
              >
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}

      {modalCloseJobData?.job_id ? (
        <DialogWithReason
          open={open}
          handleClose={handleClose}
          modalTitle={modalCloseJobData.job_title}
          modalBody={"Are you sure you want to close this job ?"}
          modalReasonText={"Kindly enter the reason to close this job*"}
          actionButtonText={"Close Job"}
          actionButtonFunc={closeParticularJob}
          setReason={setCloseReason}
        />
      ) : (
        <></>
      )}

      {modalOpenAgain?.job_id ? (
        <DialogWithReason
          open={open}
          handleClose={handleClose}
          modalTitle={modalOpenAgain.job_title}
          modalBody={"Are you sure you want to again open this job ?"}
          modalReasonText={"Kindly enter the reason to open this job*"}
          actionButtonText={"Open Job Again"}
          actionButtonFunc={againOpenParticularJob}
          setReason={setCloseReason}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default CloseJobRequest;
