import React, { useState, useEffect } from "react";
import { apiCall, apiNiyuktiCall, praveshAxiosGetReq } from "../../util/apiCall";
import "../../css/EditCollegeDetails.css";
import {
  gifStyling,
  createIconStyling,
  requestNiyuktiOptions,
  requestPraveshOptions,
} from "../../constants";
import { Form, Col, Button } from "react-bootstrap";
import gif from "../../assets/loopingcircles-3.gif";
import { characterCount } from "../../util/wordCounter";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import PropTypes from "prop-types";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  TextField,
  Box,
  CircularProgress,
  InputAdornment,
  Dialog,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../util/copyToClipboard";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TabPanel } from "../../util/TabPanel";
import { uniqueArray } from "../../util/uniqueArray";
import axios from "axios";
import { praveshUrl } from "../../constants";
import { makeStyles } from "@material-ui/styles";
import ShareLink from "../DialogSection/ShareLink/ShareLink";
import { praveshAxiosPostReq } from "../../util/baseApi";
import CustomButtonCircularProgress from "../Student/Components/loader";
import { validate } from "uuid";
import { FileCopyRounded } from "@material-ui/icons";

const token = localStorage.getItem("token");

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
    button: {
        height: '2.35rem',
        marginTop:'2rem',
        marginLeft:'1rem',
    },
     root1: {
        borderRadius: "20px",
        width: '80%',
        paddingBottom: '5%',
    },
    pocDialogSection:{
      padding :'1rem',
      margin:'1rem',

    },
    pocAdditionButtonSection:{
      display:'flex',
      alignItems:'center',

    },
    addButton:{
      width:100,
      marginRight:'1rem'
    },
    CancelButton:{
      width:100,
    },
    addbuttonSection:{
      display:'flex',
      flexDirection:'row-reverse'
    },
    totalPocCountSection:{
      display:'flex', alignItems:'baseline', marginTop:8, justifyContent:'center',
    },

}))

export function EditCompanySection({ cmpId }) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [count, setCount] = useState(0);
  const [citiesState, setCitiesState] = useState({});
  const [coworkings, setCoworkings] = useState([]);

  const [industries, setIndustries] = useState([]);
  const [tpoUsers, setTpoUsers] = useState([]);

  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);

  const [companyName, setCompanyName] = useState("");
  const [companyNickName, setCompanyNickName] = useState("");

  const initialCompDetails = {
    registeredName:null,
    gstin:null,
    companyCategory:'',
  };
  const [compDetails, setCompDetails] = useState(initialCompDetails);
  
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [orgType, setOrgType] = useState("");
  const [year, setYear] = useState("");
  const [address, setAddress] = useState("");
  const [onboardDate, setOnboardDate] = useState("");
  const [size, setSize] = useState("");
  const [about, setAbout] = useState("");
  const [description, setDescription] = useState("");
  const [aboutCount, setAboutCount] = useState(0);
  const [industryId, setIndustryId] = useState(null);
  const [coworkingId, setCoworkingId] = useState(null);
  const [removeCoworking, setRemoveCoworking] = useState(false);
  const [companyCategories,setCompanyCategories] = useState([]);

  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [blocked, setBlocked] = useState("");
  const [onboarded, setOnboarded] = useState("");

  const [addNew, setAddNew] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [altEmail, setAltEmail] = useState("");
  const [altPhone, setAltPhone] = useState("");
  const [specialities, setSpecialities] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [user, setUser] = useState(null);
  const [notListed, setNotListed] = useState(false);
  const [openAddPOCdialog,setOpenAddPOCdialog] = useState(false);

  const initialPoc = {
    firstName:'',
    lastName:'',
    designation:'',
    email:'',
    phone:'',

  }
  const [newPoc,setnewPoc] = useState(initialPoc);


  const [allJobSegments, setAllJobSegments] = useState([]);
  const [selectedJobSegments, setSelectedJobSegments] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [salesPersonDetail, setSalesPersonDetail] = useState();

  const initialCompanyProposal = {
    totalAmount:0,
    taxes_Charges:0,
    discount:0,
    amountToPay:0,
    couponCode:'',
    poc_user_id:{
       first_name: "",
       last_name:"",
    },
    extraData:{
      internship_replacement:'',
      fulltime_replacement:'',
      industryType:'',
    }   

  };

  const [companyProposalDetails,setCompanyProposalDetails] = useState({...initialCompanyProposal});
  const [openProposal,setOpenProposal] = useState(false);
  const [loadingProposal, setLoadingProposal] = useState(false);
  const [proposalLink, setProposalLink] = useState(null);
  const [agreementFile,setAgreementFile] = useState(null);
  const [userDetails,setuserDetails] = useState([]);

  const handleJobProposal = () => {
      setOpenProposal(true);
      setCompanyProposalDetails(() => (initialCompanyProposal));     
    
  };

  const handleCloseJopProposal  = () => {
            setOpenProposal(false);

  };

  const getCompanyCategory = async () => {

          const endPoint = `/api/shared/get/company/type/`;

          try {
            const res = await praveshAxiosGetReq(endPoint);

            if(res?.data?.success){

              setCompanyCategories(res?.data?.data);

            }
          } catch (error) {

            throw (error);

          }
  }

  const getProposalLink = async() => {

    const validate = () => {


      let isValid = true;
      if(!companyProposalDetails?.amountToPay){
        isValid=false;
        FailureAlert("Please fill all mandatory details");
        return;
      };
      if(!companyProposalDetails?.extraData?.industryType){

        isValid=false;
        FailureAlert("Please fill industry type");
         return;               
      };
      if(!companyProposalDetails?.extraData?.fulltime_replacement){

        isValid=false;
        FailureAlert("Please fill full-time Replacement Days");
         return;               
      };
      if(!companyProposalDetails?.extraData?.internship_replacement){

        isValid=false;
        FailureAlert("Please fill Intern Replacement Days");
         return;               
      };


      if(!companyProposalDetails?.totalAmount){
        isValid=false;
        FailureAlert("Please fill total amount field"); 
        return;

      };
      if(!companyProposalDetails?.poc_user_id){
        isValid=false;
        FailureAlert("Please select POC Name");
               
        
      };
      return isValid;

    }

    // if(companyProposalDetails?.amountToPay){
    if(validate()){

      setLoadingProposal(true);

      const formData = new FormData();

      formData.append('company',cmpId)
      if(agreementFile){
        formData.append('proposal_agreement', agreementFile ? agreementFile[0] : null )
      }
      
      formData.append('amount',(parseFloat(companyProposalDetails?.totalAmount))?.toFixed(2))
      formData.append('taxes',(parseFloat(companyProposalDetails?.taxes_Charges))?.toFixed(2))
      formData.append('discount',(parseFloat(companyProposalDetails?.discount))?.toFixed(2))
      formData.append('final_amount',companyProposalDetails?.amountToPay)
      formData.append('coupon_code',companyProposalDetails?.couponCode)
      formData.append('poc_user',companyProposalDetails?.poc_user_id?.user_id)
      formData.append('extra_data',JSON.stringify(companyProposalDetails?.extraData))
      // formData.append('industry_type',companyProposalDetails?.industryType)

      
      const endPoint = "/api/admin/company/proposal_link/";      

      try{
        const res = await praveshAxiosPostReq(endPoint,formData);

        const data = res?.data?.data;
        if(res?.data?.success){

          SuccessAlert("Proposal Link Generated Successfuly")
          setLoadingProposal(false);
          setProposalLink(data?.link);
          handleJobProposal();

        }else{
          FailureAlert("Some Error Occured")
          setLoadingProposal(false);
        }
      }
      catch(error){
        setLoadingProposal(false);
        throw(error);
      }
    }   
  }
 
  ;

  const setAmountToPay = () => {

    //  if(companyProposalDetails?.discount && companyProposalDetails?.taxes_Charges && companyProposalDetails?.totalAmount)
     if(companyProposalDetails?.taxes_Charges && companyProposalDetails?.totalAmount)
        {
          let amountToPay = parseFloat(companyProposalDetails.totalAmount) + parseFloat(companyProposalDetails.taxes_Charges) - 
          parseFloat(companyProposalDetails.discount ? companyProposalDetails.discount : 0.0);
        
      setCompanyProposalDetails(       
                         { ...companyProposalDetails, 
                          amountToPay:(amountToPay.toFixed(2) ? amountToPay.toFixed(2) : 0)
                        }                  
                      );
            } 
        }

  useEffect(() => {

    setAmountToPay();
   
    }, [companyProposalDetails?.discount,companyProposalDetails?.totalAmount,companyProposalDetails?.taxes_Charges]);
  
  const getPocName = async() => {
    const endPoint = `/api/admin/company/user/?company_id=${cmpId}`
   
    try{
        const res = await praveshAxiosGetReq(endPoint);
        if(res?.data?.success){

          setuserDetails(res?.data?.data);
        }
    }catch(error){
      throw(error);
    }
  }

  useEffect(() => {

    getPocName();

     if(companyCategories?.length === 0) {

      getCompanyCategory();
    }

  },[]);

  const handlePocAddition = async() => {

    const endPoint = '/api/admin/operations/change/admin/';
    const data = {

      user_id:null,
      first_name:newPoc?.firstName,
      last_name:newPoc?.lastName,
      phone:newPoc?.phone,
      email:newPoc?.email,
      job_title:newPoc?.designation,
      user_type:"Company",
      entity_id:cmpId,
      // previous_user_id:16185,
      previous_user_id:null,
      // previous_user_id:data?.poc_user_id,
    };

    try{
      
      const res = await praveshAxiosPostReq(endPoint, data);
      if(res?.data?.success){

      }else{
        FailureAlert(res?.data?.error);
      };   


    }catch(error){

      console.log(error,"manage errors");
      throw(error);
    }
  };
  const  handleCancelPOCAdd = () => {

    setnewPoc({...initialPoc});
  };

  
  const workExperiences = [
    { id: 1, name: "Fresher" },
    { id: 2, name: "1 Year" },
    { id: 3, name: "2 Years" },
    { id: 4, name: "3 Years" },
  ];

  const onEditorStateChange = (s) => {
    setEditorState(s);
    setSpecialities(draftToHtml(convertToRaw(s.getCurrentContent())));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Finding Job Types
  useEffect(() => {
    apiNiyuktiCall("/job/type/", requestNiyuktiOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].job_type,
          };
          tmpArray.push(obj);
        }
        setJobTypes(tmpArray);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities
  useEffect(() => {
    apiCall("/api/location/city/", requestPraveshOptions).then((json) => {
      setAllCities(json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities
  useEffect(() => {
    apiCall("/api/location/city_state/", requestPraveshOptions).then((json) => {
      setCitiesState(json.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Job Segment Finding API
  useEffect(() => {
    apiNiyuktiCall("/job/segment/", requestNiyuktiOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].job_segment,
          };
          tmpArray.push(obj);
        }
        setAllJobSegments(tmpArray);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Country Finding API
  useEffect(() => {
    apiCall("/api/location/country/", requestPraveshOptions).then((res) => {
      setCountries(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // State Finding API
  useEffect(() => {
    apiCall(
      `/api/location/state/?search=${countryId}`,
      requestPraveshOptions
    ).then((res) => {
      setStates(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  // City Finding API
  useEffect(() => {
    if (stateId !== 0) {
      apiCall(
        `/api/location/city/?search=${stateId}`,
        requestPraveshOptions
      ).then((res) => {
        setCities(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  // TPO users dropdown API
  useEffect(() => {
    apiCall(
      "/api/admin/operations/change/admin/?user_type=Company",
      requestPraveshOptions
    ).then((res) => {
      setTpoUsers(res?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Coworking ID
  useEffect(() => {
    apiCall("/api/admin/coworking/", requestPraveshOptions).then((json) => {
      if (json.success) {
        setCoworkings(json.data);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Industry ID
  useEffect(() => {
    apiCall("/api/admin/industry/", requestPraveshOptions).then((json) => {
      if (json.success) {
        setIndustries(json.data);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  // View Particular Company Details
  useEffect(() => {

    if(companyCategories?.length > 0){

      const getMatchingCompanyCategory = (matchId) => {

          const match = companyCategories?.filter((companyCategory) => (companyCategory?.id === matchId ));

          return match[0]
        }


    setLoading(true);
    apiCall(
      `/api/admin/company/view/?company_id=${cmpId}`,
      requestPraveshOptions
    ).then((json) => {
      if (json.success) {
        setSalesPersonDetail(json?.data?.sales_person);
        let obj = {
          company_id: json.data.company_id,
          company_name: json.data.company_name,
          company_nickname: json.data.company_nickname,
          gstin:json.data.gstin,
          registeredName:json.data.registered_company_name,
          coworking_id: json.data.coworking_id,
          coworking_name: json.data.coworking_name,
          industry_id: json.data.industry_id,
          industry_name: json.data.industry_name,
          organization_type: json.data.organization_type,
          size: json.data.size,
          year_founded: json.data.year_founded
            ? json.data.year_founded.slice(0, 4)
            : "",
          address: json.data.address ? json.data.address : "",
          about: json.data.about ? json.data.about : "",
          description: json.data.description ? json.data.description : "",
          website: json.data.website ? json.data.website : "",
          logo: json.data.logo ? json.data.logo : null,
          banner: json.data.banner ? json.data.banner : null,
          country: json.data.country,
          state: json.data.state,
          city: json.data.city,
          specialities: json.data.specialities,
          is_blocked: json.data.is_blocked ? "Yes" : "No",
          is_onboarded: json.data.is_onboarded ? "Yes" : "No",
          is_onboarded_date: json.data.is_onboarded_date
            ? json.data.is_onboarded_date
            : "",
          poc_user_id: json.data.company_user
            ? json.data.company_user.user_id
            : null,
          poc_first_name: json.data.company_user
            ? json.data.company_user.first_name
            : "",
          poc_last_name: json.data.company_user
            ? json.data.company_user.last_name
            : "",
          poc_email: json.data.company_user ? json.data.company_user.email : "",
          poc_phone: json.data.company_user ? json.data.company_user.phone : "",
          poc_designation: json.data.company_user
            ? json.data.company_user.job_title
            : "",
          poc_alt_email: json.data.company_user
            ? json.data.company_user.alt_email
            : "",
          poc_alt_phone: json.data.company_user
            ? json.data.company_user.alt_phone
            : "",
          preferences:
            json.data.preferences && typeof json.data.preferences === "object"
              ? json.data.preferences
              : {},
          linkedIn:
            typeof json.data.social_links === "object"
              ? json.data.social_links && json.data.social_links[0]
                ? json.data.social_links[0]["Value"] ||
                  json.data.social_links[0]["value"]
                : ""
              : JSON.parse(json.data.social_links) &&
                JSON.parse(json.data.social_links)[0]
              ? JSON.parse(json.data.social_links)[0]["Value"] ||
                JSON.parse(json.data.social_links)[0]["value"]
              : "",
          facebook:
            typeof json.data.social_links === "object"
              ? json.data.social_links && json.data.social_links[1]
                ? json.data.social_links[1]["Value"] ||
                  json.data.social_links[1]["value"]
                : ""
              : JSON.parse(json.data.social_links) &&
                JSON.parse(json.data.social_links)[1]
              ? JSON.parse(json.data.social_links)[1]["Value"] ||
                JSON.parse(json.data.social_links)[1]["value"]
              : "",
          instagram:
            typeof json.data.social_links === "object"
              ? json.data.social_links && json.data.social_links[2]
                ? json.data.social_links[2]["Value"] ||
                  json.data.social_links[2]["value"]
                : ""
              : JSON.parse(json.data.social_links) &&
                JSON.parse(json.data.social_links)[2]
              ? JSON.parse(json.data.social_links)[2]["Value"] ||
                JSON.parse(json.data.social_links)[2]["value"]
              : "",
        };
        setData(obj);
        const category = getMatchingCompanyCategory(json?.data?.type_id);

        setCompDetails((prevData) => ({...prevData,
          companyCategory:category}));        
        setSelectedCities(obj.preferences["location"] || []);
        setSelectedJobSegments(obj.preferences["job_segment"] || []);
        setSelectedExperience(obj.preferences["work_exp"] || []);
        setSelectedJobType(obj.preferences["job_type"] || []);
        obj.specialities &&
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(obj.specialities)
              )
            )
          );
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });

    }

    
  }, [cmpId, count,companyCategories?.length]);

  const updateGeneralDetails = (e) => {
    e.preventDefault();
    if (aboutCount > 255) {
      FailureAlert("Company About can only have upto 255 characters...");
      return;
    }
    setLoading(true);

    const formData = new FormData();
    companyName && formData.append("company_name", companyName);
    companyNickName && formData.append("company_nickname", companyNickName);
    compDetails.registeredName && formData.append("registered_company_name", compDetails.registeredName);
    compDetails.gstin && formData.append("gstin", compDetails.gstin);
    address && formData.append("address", address);
    orgType && formData.append("organization_type", orgType);
    size && formData.append("size", size);
    websiteUrl && formData.append("website", websiteUrl);
    cityId && stateId && formData.append("country", countryId);
    cityId && stateId && formData.append("state", stateId);
    cityId && stateId && formData.append("city", cityId);
    about && formData.append("about", about);
    year && formData.append("year_founded", `${year}-01-01`);
    description && formData.append("company_description", description);
    specialities && formData.append("specialities", specialities);
    (linkedIn || facebook || instagram) &&
      formData.append(
        "social_links",
        JSON.stringify([
          { id: "1", name: "LinkedIn", value: linkedIn || data.linkedIn || "" },
          { id: "2", name: "Facebook", value: facebook || data.facebook || "" },
          {
            id: "3",
            name: "Instagram",
            value: instagram || data.instagram || "",
          },
        ])
      );
    !removeCoworking &&
      coworkingId &&
      formData.append("coworking", coworkingId);
    removeCoworking && formData.append("coworking", "");
    industryId && formData.append("industry", industryId);
    blocked && blocked === "Yes" && formData.append("is_blocked", true);
    blocked && blocked === "No" && formData.append("is_blocked", false);
    onboardDate && formData.append("is_onboarded_date", `${onboardDate}T00:00`);
    onboarded && onboarded === "Yes" && formData.append("is_onboarded", true);
    onboarded && onboarded === "No" && formData.append("is_onboarded", false);
    logo && formData.append("logo", logo);
    banner && formData.append("banner", banner);
    compDetails?.companyCategory && formData.append("type", compDetails?.companyCategory?.id);

    const requestUpdateGeneralDetails = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    };

    apiCall(
      `/api/admin/company/edit/?company_id=${cmpId}`,
      requestUpdateGeneralDetails
    )
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setCompanyName("");
          setCompanyNickName("");
          setAddress("");
          setWebsiteUrl("");
          setYear("");
          setOrgType("");
          setSize("");
          setCoworkingId(null);
          setIndustryId(null);
          setBlocked("");
          setOnboarded("");
          setRemoveCoworking(false);
          setAbout("");
          setDescription("");
          setSpecialities("");
          setEditorState(EditorState.createEmpty());
          setLogo(null);
          setBanner(null);
          setCountryId(1);
          setStateId(0);
          setCityId(0);
          setOnboardDate("");
          setLinkedIn("");
          setFacebook("");
          setInstagram("");
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const updatePreferences = (e) => {
    e.preventDefault();
    setLoading(true);

    let preferences = { ...data.preferences };
    preferences["work_exp"] = uniqueArray(selectedExperience);
    preferences["job_type"] = uniqueArray(selectedJobType);
    preferences["location"] = uniqueArray(selectedCities);
    preferences["job_segment"] = uniqueArray(selectedJobSegments);

    const requestUpdatePreferences = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        preferences: preferences,
      }),
    };

    apiCall(
      `/api/admin/company/edit/?company_id=${cmpId}`,
      requestUpdatePreferences
    )
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const updatePOCDetails = (e) => {
    e.preventDefault();
    setLoading(true);

    const requestUpdateSTPODetails = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        user_type: "Company",
        entity_id: cmpId,
        job_title: designation || data.poc_designation,
        user_id: data.poc_user_id,
        first_name: firstName || data.poc_first_name,
        last_name: lastName || data.poc_last_name,
        email: email || data.poc_email,
        phone: phone || data.poc_phone,
        alternate_email: altEmail || data.poc_alt_email,
        alternate_phone: altPhone || data.poc_alt_phone,
      }),
    };

    apiCall(`/api/admin/operations/update/admin/`, requestUpdateSTPODetails)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setFirstName("");
          setLastName("");
          setEmail("");
          setDesignation("");
          setPhone("");
          setAltEmail("");
          setAltPhone("");
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const addPOC = (e) => {
    e.preventDefault();
    setLoading(true);

    const requestChangeTPODetails = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        user_type: "Company",
        entity_id: cmpId,
        job_title: designation || data.poc_designation || ".",
        user_id: user || data.poc_user_id,
        previous_user_id: data.poc_user_id,
        first_name: firstName || data.poc_first_name,
        last_name: lastName || data.poc_last_name,
        email: email || data.poc_email,
        phone: phone || data.poc_phone,
      }),
    };

    apiCall(`/api/admin/operations/change/admin/`, requestChangeTPODetails)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setUser(null);
          setFirstName("");
          setLastName("");
          setEmail("");
          setDesignation("");
          setPhone("");
          setAddNew(false);
          setNotListed(false);
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const addPOCDetails = (e) => {
    e.preventDefault();
    setLoading(true);

    const requestChangeTPODetails = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        user_type: "Company",
        entity_id: cmpId,
        user_id: null,
        previous_user_id: data.poc_user_id,
        first_name: firstName,
        last_name: lastName,
        job_title: designation,
        email: email,
        phone: phone,
      }),
    };

    apiCall(`/api/admin/operations/change/admin/`, requestChangeTPODetails)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setFirstName("");
          setLastName("");
          setEmail("");
          setDesignation("");
          setPhone("");
          setAddNew(false);
          setNotListed(false);
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
          setCount(count + 1);
        }
      })
      .then(setLoading(false));
  };

  //edit companies
  const getSalesPersonList = async () => {
    const url = `/api/common/selected/user_type/get_list/?user_type_ids=10,14`;
    axios
      .get(`${praveshUrl}${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          // console.log(res?.data?.data?.data);
          setSalesPersonList(res?.data?.data?.data);
        }
      })
      .catch((err) => {});
  };

  const patchSalesPerson = async (e) => {
    e.preventDefault();
    const url = `/api/admin/company/edit/?company_id=${cmpId}`;
    const fd1 = new FormData();
    fd1.append("sales_person", salesPersonDetail?.user_id);
    //setSalesPersonLoader(true);
    axios
      .patch(`${praveshUrl}${url}`, fd1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getSalesPersonList();
  }, []);

  return !loading ? (
    data ? (
      <div className="edit__company__section">
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="General Details" {...a11yProps(0)} />
            <Tab label="Preferences" {...a11yProps(1)} />
            <Tab label="Contact Person Details" {...a11yProps(2)} />
            <Tab label="Sales Person" {...a11yProps(3)} />
            <Tab label="Company Proposal" {...a11yProps(4)} />
          </Tabs>
        </AppBar>

        {/* General Details Edit */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          {data.banner && data.logo ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "250px",
              }}
            >
              <img
                alt="banner"
                src={data.banner}
                style={{ height: "250px", width: "96%", margin: "2% 0 0 0" }}
              />
              <img
                alt="logo"
                src={data.logo}
                style={{
                  height: "110px",
                  width: "20%",
                  margin: "-110px 40% 0px 40%",
                  border: "3px solid grey",
                  borderRadius: "50%",
                  position: "abloute",
                  bottom: "0",
                }}
              />
            </div>
          ) : data.banner ? (
            <img
              alt="banner"
              src={data.banner}
              style={{ height: "250px", width: "96%", margin: "2%" }}
            />
          ) : data.logo ? (
            <img
              alt="logo"
              src={data.logo}
              style={{
                height: "200px",
                width: "30%",
                margin: "10px 35%",
                border: "3px solid grey",
                borderRadius: "50%",
              }}
            />
          ) : null}
          <Form
            className="edit__general__details__form"
            onSubmit={(e) => updateGeneralDetails(e)}
          >
            <Form.Row>
              <Form.Group as={Col} controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  name="companyName"
                  type="text"
                  placeholder={data.company_name || "Enter Company Name"}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="companyNickName">
                <Form.Label>Company Nick Name</Form.Label>
                <Form.Control
                  name="companyNickName"
                  type="text"
                  placeholder={
                    data.company_nickname || "Enter Company Nick Name"
                  }
                  onChange={(e) => {
                    setCompanyNickName(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>

             <Form.Row>
              <Form.Group as={Col} controlId="RegisteredName">
                <Form.Label>Registered Company Name</Form.Label>
                <Form.Control
                  name="RegisteredName"
                  type="text"
                  placeholder={data.registeredName || "Registered Company Name"}
                  onChange={(e) => {
                    setCompDetails((previouseData) => { return{
                      ...previouseData , registeredName:e.target.value
                    }
                    })
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="GSTIN">
                <Form.Label>GSTIN</Form.Label>
                <Form.Control
                  name="gstin"
                  type="text"
                  placeholder={
                    data.gstin || "Enter GSTIN"
                  }
                  onChange={(e) => {
                    setCompDetails((previouseData) => { return{
                      ...previouseData , gstin:e.target.value
                    }
                    })
                  }}

                />
              </Form.Group>
            </Form.Row>


            <Form.Row>
              <Form.Group as={Col} controlId="organizationType">
                <Form.Label>Organization Type</Form.Label>
                <Form.Control
                  name="organizationType"
                  as="select"
                  onChange={(e) => {
                    setOrgType(e.target.value);
                  }}
                >
                  <option>{`--${data.organization_type}--`}</option>
                  <option value="Government">Government</option>
                  <option value="SemiPrivate">Semi Private</option>
                  <option value="Private">Private</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="companyWebsite">
                <Form.Label>Company Website URL :</Form.Label>
                <Form.Control
                  name="companyWebsite"
                  type="text"
                  placeholder={data.website || "Company Website URL"}
                  onChange={(e) => {
                    setWebsiteUrl(e.target.value);
                  }}
                />
              </Form.Group>
             
            </Form.Row>
            <Form.Row>
               <Form.Group as={Col} controlId="yearFounded">
                <Form.Label>Year Founded :</Form.Label>
                <Form.Control
                  name="yearFounded"
                  type="text"
                  placeholder={data.year_founded || "Company Founded"}
                  pattern="[0-9]*"
                  maxLength="4"
                  minLength="4"
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                />
              </Form.Group>
            <Form.Group  as={Col}  controlId="companyCategory">
             <Form.Label>Select Company Category* : </Form.Label>
             < Autocomplete
         
              id="companyCategory"
              options={companyCategories}
              value={compDetails?.companyCategory}
              getOptionLabel={(item) => item?.type}
              onChange={(e, item) =>{
                setCompDetails((prevData) => ({...prevData,companyCategory:item}))
              }               
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category*"
                  variant="outlined"
                  style={{ display: "flex" }}
                />
                 )}
             />
          </Form.Group>  

            </Form.Row>
               
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                name="address"
                as="textarea"
                placeholder={data.address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="removeCoworking">
                <Form.Label>Remove Coworking</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Remove Coworking"
                  onChange={(e) => setRemoveCoworking(!removeCoworking)}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="coworking">
                <Form.Label>Coworking</Form.Label>
                <Form.Control
                  as="select"
                  disabled={removeCoworking}
                  onChange={(e) => {
                    setCoworkingId(JSON.parse(e.target.value).id);
                  }}
                >
                  <option
                    value={JSON.stringify({ id: null })}
                    key="0"
                  >{`--${data.coworking_name}--`}</option>
                  {coworkings.map((coworking, key) => {
                    return (
                      <option key={key} value={JSON.stringify(coworking)}>
                        {coworking.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="industry">
                <Form.Label>Industry</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setIndustryId(JSON.parse(e.target.value).id);
                  }}
                >
                  <option
                    value={JSON.stringify({ id: null })}
                    key="0"
                  >{`--${data.industry_name}--`}</option>
                  {industries.map((ind, key) => {
                    return (
                      <option key={key} value={JSON.stringify(ind)}>
                        {ind.industry_name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Label>
              <b>
                <i>Location :</i>
              </b>
            </Form.Label>
            <Form.Row className="typeSearchLocation">
              <Form.Group as={Col} controlId="country">
                <Autocomplete
                  id="select-country"
                  options={countries}
                  getOptionLabel={(item) => item.country}
                  onChange={(e, item) =>
                    item ? setCountryId(item.country_id) : setCountryId(1)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Select Country (Current: ${
                        data.country || `NONE`
                      })`}
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="state">
                <Autocomplete
                  id="select-state"
                  options={states}
                  getOptionLabel={(item) => item.state}
                  onChange={(e, item) =>
                    item ? setStateId(item.state_id) : setStateId(0)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Select State (Current: ${data.state || `NONE`})`}
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="city">
                <Autocomplete
                  id="select-city"
                  options={cities}
                  getOptionLabel={(item) => item.city}
                  onChange={(e, item) =>
                    item ? setCityId(item.city_id) : setCityId(0)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Select City (Current: ${data.city || `NONE`})`}
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>
            </Form.Row>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Form.Label>
                Company About
                {aboutCount < 255 ? (
                  <i style={{ fontSize: "13px", color: "green" }}>
                    {" "}
                    Total Characters: {aboutCount} out of 255{" "}
                  </i>
                ) : (
                  <i style={{ fontSize: "13px", color: "red" }}>
                    {" "}
                    Character Limit Exceeded (max 255 characters) : Character
                    Count : {aboutCount}
                  </i>
                )}
              </Form.Label>
              {data.about ? (
                <span>
                  (Click icon to copy initial text)
                  <FileCopyIcon
                    style={createIconStyling}
                    onClick={(e) => copyToClipboard(data.about)}
                  />
                </span>
              ) : null}
            </div>
            <Form.Group controlId="instituteAbout">
              <Form.Control
                name="instituteAbout"
                as="textarea"
                rows="3"
                placeholder={data.about || "Enter About"}
                onChange={(e) => {
                  setAbout(e.target.value);
                  e.target.value &&
                    setAboutCount(characterCount(e.target.value));
                  !e.target.value && setAboutCount(0);
                }}
              />
            </Form.Group>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Form.Label>Company Description </Form.Label>
              {data.description ? (
                <span>
                  (Click icon to copy initial text)
                  <FileCopyIcon
                    style={createIconStyling}
                    onClick={(e) => copyToClipboard(data.description)}
                  />
                </span>
              ) : null}
            </div>
            <Form.Group controlId="instituteDescription">
              <Form.Control
                name="instituteDescription"
                as="textarea"
                rows="4"
                placeholder={data.description || "Enter Description"}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Label>Specialities : </Form.Label>
            <div className="editor">
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                onEditorStateChange={onEditorStateChange}
              />
            </div>

            <Form.Label>
              <b>
                <i>Social Media Links :</i>
              </b>
            </Form.Label>
            <Form.Row className="socialMediaLinks">
              <Form.Group as={Col} controlId="linkedInPage">
                <Form.Label>LinkedIn Account :</Form.Label>
                <Form.Control
                  name="linkedInPage"
                  type="text"
                  placeholder={data.linkedIn || "Enter LinkedIn URL"}
                  onChange={(e) => {
                    setLinkedIn(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="facebookPage">
                <Form.Label>Facebook Account :</Form.Label>
                <Form.Control
                  name="facebookPage"
                  type="text"
                  placeholder={data.facebook || "Enter Facebook URL"}
                  onChange={(e) => {
                    setFacebook(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="instaAccount">
                <Form.Label>Instagram Account :</Form.Label>
                <Form.Control
                  name="instaAccount"
                  type="text"
                  placeholder={data.instagram || "Enter Instagram URL"}
                  onChange={(e) => {
                    setInstagram(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="onboarded_date">
                <Form.Label>
                  Onboarded Date ({data.is_onboarded_date || "None"})
                </Form.Label>
                <Form.Control
                  name="onboarded_date"
                  type="date"
                  // placeholder={data.is_onboarded_date || ''}
                  onChange={(e) => setOnboardDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="isOnboarded">
                <Form.Label>Onboard Status</Form.Label>
                <Form.Control
                  name="isOnboarded"
                  as="select"
                  onChange={(e) => {
                    setOnboarded(e.target.value);
                  }}
                >
                  <option>{`--${data.is_onboarded}--`}</option>
                  <option value="Yes">On Board</option>
                  <option value="No">Off Board</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="size">
                <Form.Label>Company Size</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setSize(e.target.value);
                  }}
                >
                  <option value="">{`--(${data.size})--`}</option>
                  <option value="1-10">1-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-1000">101-1000</option>
                  <option value="1000+">1000+</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="isBlocked">
                <Form.Label>Block Status</Form.Label>
                <Form.Control
                  name="isBlocked"
                  as="select"
                  onChange={(e) => {
                    setBlocked(e.target.value);
                  }}
                >
                  <option>{`--${data.is_blocked}--`}</option>
                  <option value="Yes">Block</option>
                  <option value="No">Unblock</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Label>Company Logo :</Form.Label>
            <Form.File
              id="formcheck-api-custom"
              custom
              style={{ marginBottom: "3%" }}
            >
              <Form.File.Input
                onChange={(e) => setLogo(e.target.files[0])}
                isValid
              />
              <Form.File.Label data-browse="Upload Logo">
                Click to choose file from local directory
              </Form.File.Label>
              {logo ? (
                <Form.Control.Feedback type="valid">
                  {logo.name}
                </Form.Control.Feedback>
              ) : null}
            </Form.File>
            <Form.Label>Company Banner :</Form.Label>
            <Form.File
              id="formcheck-api-custom"
              custom
              style={{ marginBottom: "3%" }}
            >
              <Form.File.Input
                onChange={(e) => setBanner(e.target.files[0])}
                isValid
              />
              <Form.File.Label data-browse="Upload Banner">
                Click to choose file from local directory
              </Form.File.Label>
              {banner ? (
                <Form.Control.Feedback type="valid">
                  {banner.name}
                </Form.Control.Feedback>
              ) : null}
            </Form.File>

            <Button type="submit" variant="info">
              Save Changes
            </Button>
          </Form>
        </TabPanel>

        {/* Preferences */}
        <TabPanel value={value} index={1} dir={theme.direction}>
          {data.preferences ? (
            <Form
              className="edit__preferences__form"
              onSubmit={(e) => updatePreferences(e)}
            >
              <Form.Label>
                <i>
                  <u>Preference : </u>
                </i>
              </Form.Label>
              <Box
                className="typeSearchPreferences"
                boxShadow={2}
                style={{ padding: "16px 1rem", marginBottom: "1rem" }}
              >
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-job_type"
                    options={jobTypes}
                    value={selectedJobType}
                    getOptionLabel={(item) => item.name}
                    onChange={(e, i) =>
                      i ? setSelectedJobType(i) : setSelectedJobType({})
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Job Type"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-experience"
                    options={workExperiences}
                    value={selectedExperience}
                    getOptionLabel={(item) => item.name}
                    onChange={(e, i) =>
                      i ? setSelectedExperience(i) : setSelectedExperience([])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Work Experience"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-cities"
                    value={selectedCities}
                    options={citiesState}
                    getOptionLabel={(option) =>
                      `${option.city}, ${option.state}`
                    }
                    onChange={(e, i) =>
                      i ? setSelectedCities(i) : setSelectedCities([])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Cities"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-job-segments"
                    options={allJobSegments}
                    value={selectedJobSegments}
                    getOptionLabel={(item) => item.name}
                    onChange={(e, item) =>
                      item
                        ? setSelectedJobSegments(item)
                        : setSelectedJobSegments([])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Job Segments"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
              </Box>

              <Button type="submit" variant="info">
                Save Changes
              </Button>
            </Form>
          ) : (
            <CircularProgress
              style={{ display: "block", margin: "auto", marginTop: "50px" }}
            />
          )}
        </TabPanel>

        {/* POC Details Edit */}
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Form.Group controlId="changeUserCheckBox">
            <Form.Check
              type="checkbox"
              label="Change User"
              onChange={(e) => setAddNew(!addNew)}
              checked={addNew}
            />
          </Form.Group>
          {!addNew && data.poc_email ? (
            <Form
              className="edit__poc__details__form"
              onSubmit={(e) => updatePOCDetails(e)}
            >
              {/* <Form.Row className={classes.addPocSection}>
               <Form.Group as={Col} controlId="Select Team Member">
                 <Autocomplete
                 
                  id="Select Team Member"
                  size="small"
                  options={userDetails}
                  // style={{height:8}}
                  // value={}
                  getOptionLabel={(item) =>
                    item?.first_name && item?.last_name
                      ? item?.first_name + " " + item?.last_name
                      : item?.first_name && !item?.last_name
                      ? item?.first_name
                      : ""
                  }
                 
                  // getOptionLabel={(item) => item.name}
                  style={{
                    width: "100%",
                    height: "10px",
                    marginRight: "20px",
                    marginBottom:'30px',
                    //  margin: '5%'
                  }}
                  onChange={(e, item) =>{}
                   
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select Team Member" variant="outlined" />
                  )}
                 />
                </Form.Group>
                <Form.Group as={Col} controlId="Total POC" >
                  <section className={classes.totalPocCountSection}
                   >
                 <Typography>
                     Total POC:
                 </Typography>
                 <h6>{userDetails?.length}</h6>

                  </section>
                  
                </Form.Group>
                <Form.Group as={Col} controlId="Add">
                  <section className={classes.addbuttonSection}>

                  
                    <Button
                    variant="outline-primary"
                    className={classes.addButton}
                    onClick={() => {
                        setOpenAddPOCdialog(true);
                    }}
                    >
                      Add
                    </Button>
                    <Dialog
                     PaperProps={{ classes: { root: classes.root1 } }}
                      fullWidth={true}
                      maxWidth={"md"}
                      contentStyle={{
                          // width: '100%',
                          // width: '650px',
                          maxWidth: 'none',
                      }}
                      open={openAddPOCdialog}
                      onClose={() => {
                        setOpenAddPOCdialog(false)
                      }}
                      aria-labelledby="responsive-dialog-title"                    
                    >
                  <section className={classes.pocDialogSection}>
                    <Typography
                     variant="h5" >Add POC</Typography>
                     
                    <Form
                      className="Add POC Details"
                      // onSubmit={(e) => { handle}}
                    >

                    <Form.Row>
                      <Form.Group as={Col} controlId="firstName">
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          name="firstName"
                          type="text"
                          value={newPoc?.firstName}
                          onChange={(e) => {
                            setnewPoc({...newPoc,firstName:e.target.value});
                          }}
                        />
                       </Form.Group>
                        <Form.Group as={Col} controlId="last Name">
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          name="last name"
                          type="text"
                          value={newPoc?.lastName}
                          onChange={(e) => {
                            setnewPoc({...newPoc,lastName:e.target.value});
                          }}
                          
                        />
                       </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="designation">
                        <Form.Label>Designation*</Form.Label>
                        <Form.Control
                          name="designation"
                          type="text"
                          value={newPoc?.designation}
                          onChange={(e) => {
                            setnewPoc({...newPoc,designation:e.target.value});
                          }}
                          
                        />
                       </Form.Group>
                        <Form.Group as={Col} controlId="email">
                        <Form.Label>POC Email*</Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          value={newPoc?.email}
                          onChange={(e) => {
                            setnewPoc({...newPoc,email:e.target.value});
                          }}
                        />
                       </Form.Group>
                    </Form.Row>

                     <Form.Row style={{width:'50%'}}>
                      <Form.Group as={Col} 
                      
                        controlId="pocContact">
                        <Form.Label>POC Contact*</Form.Label>
                        <Form.Control
                          name="pocContact"
                          type="number" 
                          value={newPoc?.phone}
                          onChange={(e) => {
                            setnewPoc({...newPoc,phone:e.target.value});
                          }}                        
                         
                        />
                      </Form.Group>
                     
                    </Form.Row>
                      <section
                       className={classes.pocAdditionButtonSection}
                       >
                       <Button
                        className={classes.addButton}
                           onClick={() => {
                            handlePocAddition();
                            // setOpenAddPOCdialog(true);
                        }}
                        >
                        Add
                       </Button>  
                        <Button
                        variant="outline-danger"
                        className={classes.CancelButton}
                        onClick={() => {
                            // setOpenAddPOCdialog(false);
                            handleCancelPOCAdd();
                            // setnewPoc(initialPoc);
                        }}
                        >
                        Cancel
                       </Button> 

                       </section>
                     
                    </Form>

                    </section>
                    
                    </Dialog>
                    </section>
                  </Form.Group>
              </Form.Row> */}
              <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder={data.poc_first_name}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder={data.poc_last_name || ""}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="designation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    name="designation"
                    type="text"
                    placeholder={data.poc_designation}
                    onChange={(e) => {
                      setDesignation(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>POC Email</Form.Label>
                   <section style={{display:'flex', alignContent:'center',
                    alignItems:'center',
                    width:'100%'
                     }}>
                   
                  {/* <Form.Control
                   name="email"
                    type="email"
                     onClick={() =>{
                      copyToClipboard(data.poc_email);
                    }}
                    placeholder={data.poc_email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />                   */}
                  <TextField
                  variant="outlined"
                  style={{width:"100%"}}
                  size="small"
                   onClick={() =>{
                     // copyToClipboard(data.poc_email);
                    }}
                    placeholder={data.poc_email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    InputProps={{
                    endAdornment: (
                      
                      <InputAdornment position="start">
                         <FileCopyRounded
                            onClick={() => {                              
                              copyToClipboard(data?.poc_email);                            
                            
                            }}
                            style={{ color: 'gray', cursor:"pointer" }}
                          />
                      </InputAdornment>
                    ),
                  }}
                  
                  />

                 
                  {/* <FileCopyRounded
                    onClick={() => copyToClipboard(data.poc_email)}
                    style={{ color: 'gray' }}
                  /> */}

                   </section>
                 
                  
                </Form.Group>
                <Form.Group as={Col} controlId="phone">
                  <Form.Label>POC Contact</Form.Label>
                  <section style={{display:'flex', alignContent:'center', 
                  alignItems:'center', width:'100%'}}  >

                     <TextField
                        variant="outlined"
                        style={{width:"100%"}}
                        size="small"
                        onClick={() =>{
                          // copyToClipboard(data?.poc_phone);
                   
                      // copyToClipboard(data?.poc_phone);
                    }}
                    placeholder={data?.poc_phone}
                    onChange={(e) => {
                     setPhone(e.target.value);
                    }}
                    InputProps={{
                    endAdornment: (
                      
                      <InputAdornment position="start">
                         <FileCopyRounded
                            onClick={() => copyToClipboard(data?.poc_phone)}
                            style={{ color: 'gray', cursor:"pointer" }}
                          />
                      </InputAdornment>
                    ),
                  }}
                  
                  />
                   {/* <Form.Control
                    name="phone"
                    type="text"
                    pattern="[0-9]*"
                    onClick={() =>{
                      copyToClipboard(data?.poc_phone );
                    }}
                    maxLength="10"
                    minLength="10"
                    placeholder={data?.poc_phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
       
                  /> */}
                 

                  </section>
                  
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>TPO Alternate Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder={data.poc_alt_email}
                    onClick={() =>{
                      // copyToClipboard(altEmail);
                    }}
                    onChange={(e) => {
                      setAltEmail(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="phone">
                  <Form.Label>TPO Alternate Contact</Form.Label>
                  <Form.Control
                    name="phone"
                    type="text"
                    placeholder={data.poc_alt_phone}
                    pattern="[0-9]*"
                    maxLength="10"
                    minLength="10"
                    onChange={(e) => {
                      setAltPhone(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Button type="submit" variant="info">
                Save Changes
              </Button>
            </Form>
          ) : notListed ? (
            <Form
              className="edit__poc__details__form"
              onSubmit={(e) => addPOCDetails(e)}
            >
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Not Listed"
                  onChange={(e) => setNotListed(!notListed)}
                  checked={notListed}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    required
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="lastName">
                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    required
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="designation">
                  <Form.Label>Designation*</Form.Label>
                  <Form.Control
                    name="designation"
                    type="text"
                    placeholder="Job Title Goes here.."
                    required
                    onChange={(e) => {
                      setDesignation(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>POC Email*</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    required
                    onClick={() =>{
                      // copyToClipboard(email);
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="phone">
                  <Form.Label>POC Contact*</Form.Label>
                  <Form.Control
                    name="phone"
                    type="text"
                    pattern="[0-9]*"
                    required
                    maxLength="10"
                    minLength="10"
                    placeholder="Phone Number"
                    onClick={() => {
                                //  copyToClipboard(phone);
                    }}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Button type="submit" variant="info">
                Save Changes
              </Button>
            </Form>
          ) : (
            <Form
              className="add__poc__details__form"
              onSubmit={(e) => addPOC(e)}
            >
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Not Listed"
                  onChange={(e) => setNotListed(!notListed)}
                  checked={notListed}
                />
              </Form.Group>
              <Form.Row>
                <Autocomplete
                  id="select-user"
                  options={tpoUsers}
                  getOptionLabel={(item) =>
                    `${item.first_name} ${item.last_name}  (${item.email})`
                  }
                  style={{ width: "90%", margin: "5%", marginTop: "0px" }}
                  onChange={(e, item) =>
                    item ? setUser(item.user_id) : setUser(null)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select User"
                      variant="outlined"
                    />
                  )}
                />
              </Form.Row>
              <Button type="submit" variant="info">
                Update User
              </Button>
            </Form>
          )}
        </TabPanel>

        {/* Sales person */}
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Form
            className="edit__preferences__form"
            /*  onSubmit={(e) => patchSalesPerson(e)} */
          >
            <Form.Label>Sales Person</Form.Label>
            <Box
              className="typeSearchPreferences"
              boxShadow={2}
              style={{ padding: "16px 1rem", marginBottom: "1rem" }}
            >
              <Form.Group>
                <Autocomplete
                  id="select-job_type"
                  options={salesPersonList}
                  value={salesPersonDetail}
                  getOptionLabel={(item) =>
                    item?.user__first_name && item?.user__last_name
                      ? item?.user__first_name + " " + item?.user__last_name
                      : item?.user__first_name && !item?.user__last_name
                      ? item?.user__first_name
                      : ""
                  }
                  onChange={(e, newValue) => {
                    newValue
                      ? setSalesPersonDetail({ ...newValue })
                      : setSalesPersonDetail({});
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sales Person Name"
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} controlId="collegedetails">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    name="collegeaddress"
                    type="text"
                    disabled={true}
                    placeholder={"Sales Person Email"}
                    style={{ height: "50px" }}
                    value={
                      salesPersonDetail?.user__email
                        ? salesPersonDetail?.user__email
                        : null
                    }
                    //onChange={}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="collegedetails">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    required
                    name="collegeaddress"
                    type="text"
                    disabled={true}
                    style={{ height: "50px" }}
                    placeholder={"Sales Person Phono Number"}
                    value={
                      salesPersonDetail?.user__phone
                        ? salesPersonDetail?.user__phone
                        : null
                    }
                    //onChange={}
                  />
                </Form.Group>
              </Form.Row>
            </Box>

            <Button
              /* type="submit" */ variant="info"
              onClick={(e) => {
                patchSalesPerson(e);
              }}
            >
              Save Changes
            </Button>
          </Form>
        </TabPanel>
         <TabPanel value={value} index={4} dir={theme.direction}>
           <Form.Row>               
                <Form.Group as={Col} controlId="totalAmount">
                  <Form.Label>Amount* (in INR)</Form.Label>
                  <Form.Control
                    name="totalAmount"
                    value={companyProposalDetails?.totalAmount}
                    type="number"
                    required                  
                    placeholder={companyProposalDetails?.totalAmount || "Total Amount" }
                    onChange={(e) => {
                      setCompanyProposalDetails(
                        {
                          ...companyProposalDetails, 
                          totalAmount:e.target.value,
                          // taxes_Charges: .18*(e.target.value),
                        }
                      )
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="taxes&charges">
                  <Form.Label>18% Taxes & Charges* (in INR)</Form.Label>
                  <Form.Control
                    name="taxes&charges"
                    type="number"
                    value={companyProposalDetails?.taxes_Charges}
                    required                  
                    placeholder={ companyProposalDetails?.taxes_Charges || "Taxes & Charges" }
                    onChange={(e) => {
                      setCompanyProposalDetails(                        
                        {
                          ...companyProposalDetails, taxes_Charges:e.target.value
                        }
                      )
                    }}                                       
                  />
                </Form.Group>
                
                <Form.Group as={Col} controlId="discount">
                  <Form.Label>Discount (in INR)</Form.Label>
                  <Form.Control
                    name="discount"
                    type="number"
                    value= {companyProposalDetails?.discount}
                    required                   
                    placeholder={companyProposalDetails?.discount || "discount" }
                     onChange={(e) => {
                       if(e.target.value > companyProposalDetails?.totalAmount){
                        FailureAlert("Discount cannot be more than total amount!");
                       }
                       else{
                      setCompanyProposalDetails(
                        {
                          ...companyProposalDetails, discount:e.target.value
                        }
                      );
                       }                     
                      }}                     
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="couponCode">
                  <Form.Label>Coupon Code</Form.Label>
                  <Form.Control
                    name="couponCode"
                    type="text"
                    value= {companyProposalDetails?.couponCode}
                    required                   
                    placeholder={companyProposalDetails?.couponCode || "coupon Code" }
                     onChange={(e) => {
                      setCompanyProposalDetails(
                        {
                          ...companyProposalDetails, couponCode:e.target.value
                        }
                      );                     
                      }}                     
                  />
                </Form.Group>
               
              </Form.Row> 
              <Form.Row>   

                 <Form.Group as={Col} controlId="discount">
                   <Form.Label>Poc Name*</Form.Label>
                <Autocomplete
                  id="select-user-Details"
                  options={userDetails}
                  // style={{height:8}}
                  value={companyProposalDetails?.poc_user_id}
                  getOptionLabel={(item) =>
                    item?.first_name && item?.last_name
                      ? item?.first_name + " " + item?.last_name
                      : item?.first_name && !item?.last_name
                      ? item?.first_name
                      : ""
                  }
                  onChange={(e, newValue) => {
                    newValue
                      ? setCompanyProposalDetails({...companyProposalDetails,poc_user_id:newValue})
                      : setCompanyProposalDetails({});
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="POC Details"
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>            
                <Form.Group as={Col} controlId="discount">
                  <Form.Label>Industry Type*</Form.Label>
                  <Form.Control
                    name="industryType"
                    type="text"
                    value= {companyProposalDetails?.extraData?.industryType}
                    required                   
                    placeholder={companyProposalDetails?.extraData?.industryType || "Industry Type" }
                     onChange={(e) => {
                      setCompanyProposalDetails(
                        {  ...companyProposalDetails, extraData:{
                           ...companyProposalDetails.extraData,
                            industryType:e.target.value
                          }
                         
                        }
                      );                     
                      }}                     
                  />
                </Form.Group>
                
                <Form.Group as={Col} controlId="discount">
                  <Form.Label>Intern Replacement Days*</Form.Label>
                  <Form.Control
                    name="interReplacementDays"
                    type="text"
                    value= {companyProposalDetails?.extraData?.internship_replacement}
                    // required                   
                    placeholder={companyProposalDetails?.extraData?.internship_replacement || "Intern Replacement Days" }
                     onChange={(e) => {
                      setCompanyProposalDetails(
                        {
                          ...companyProposalDetails, extraData:{
                           ...companyProposalDetails.extraData,
                            internship_replacement:e.target.value
                          }
                        }
                      );                     
                      }}                     
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="couponCode">
                  <Form.Label>Fulltime Replacement Days*</Form.Label>
                  <Form.Control
                    name="FullTime"
                    type="text"
                    value= {companyProposalDetails?.extraData?.fulltime_replacement}
                    required                   
                    placeholder={companyProposalDetails?.extraData?.fulltime_replacement || "Fulltime Replacement Days" }
                     onChange={(e) => {
                      setCompanyProposalDetails(
                        {
                          ...companyProposalDetails, extraData:{
                           ...companyProposalDetails.extraData,
                            fulltime_replacement:e.target.value
                          }
                        }                       
                      );                     
                      }}                     
                  />
                </Form.Group>
               
              </Form.Row> 
               <Form.Group as={Col} controlId="amountToBePaid"
               style={{maxWidth:300}}
               >
                  <Form.Label>Amount To be Paid</Form.Label>
                  <Form.Control
                    name="amountToBePaid"
                    type='number'
                    value={ companyProposalDetails?.amountToPay}
                    required                   
                    readOnly
                    placeholder={ companyProposalDetails?.amountToPay || "amount to be paid" }
                  />
                </Form.Group>

                 <section style={{display:'flex',alignItems:'flex-end'}}>
                  
                 {/* <Form.Group as={Col} controlId="amountToBePaid"
                  style={{maxWidth:300}}
                   >
                     <Form.Label>Upload Agreement</Form.Label>
                      <form>
                        <input type="file" 
                        multiple accept=".doc, .docx, .pdf" 
                        onChange={e => {                   
                        setAgreementFile(e.target.files);                              
                        }                    
                      }                   
                        />
                    </form>                  
                </Form.Group>   */}

                <Form.Group as={Col} controlId="amountToBePaid"
               style={{maxWidth:300, marginLeft:'-12px'}}
               >
                  <Button
                className={classes.button}
                style={{minWidth:200}}
                onClick={() => {

                  getProposalLink();
                }}
                >
                 {loadingProposal ? <CustomButtonCircularProgress/> : "Generate Job Proposal"} 
                </Button>
                </Form.Group>
    
                  </section>                            
    
        </TabPanel>
        <ShareLink
        open={openProposal}
        handleClose= {handleCloseJopProposal}
        link= {proposalLink}
        />
      </div>
    ) : null
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}
