import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #6C757D",
    borderRadius: 8,
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
}));

export default function LoadMore({loading, setLoading}) {
  const classes = useStyles();
  const timerRef = React.useRef();

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  return (
    <div className={classes.root}>
      <Button onClick={() => {setLoading(true)}} className={classes.button}>
        {loading ? (
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        ) : (
          <Typography style={{ color: "#6C757D", fontSize: 14 }}>
            Load More
          </Typography>
        )}
      </Button>
    </div>
  );
}
