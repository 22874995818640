import React, { useEffect, useState } from "react";
import { TabPanel } from "../../../util/TabPanel";
import PropTypes from "prop-types";
import { Form, Col, Button } from "react-bootstrap";
import { useTheme } from "@material-ui/core/styles";

import {
    AppBar,
    Tabs,
    Tab,
    TextField,
    CircularProgress,
    Box,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import YearMonthPicker from "../../common/YearMonthPicker";
import { praveshAxiosGetReq, praveshAxiosPostReq } from "../../../util/baseApi";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    keyvalue: {
        fontWeight: 600,
        fontSize: 16,
    },

}))



const CommunicationLinks = ({ newCompanyDetails, setNewCompanyDetails }) => {

    const classes = useStyles();

    const checkDomainAvailability = async () => {

        const endPoint = `/api/admin/company/domain/availability/`;

        try {

            const data = {
                domain: newCompanyDetails?.companyBasicInfo?.gwDomain,
            }
            const res = await praveshAxiosPostReq(endPoint, data);

            if (res?.data?.success) {

                SuccessAlert("Domain Validated.")
                setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                        ...newCompanyDetails?.companyBasicInfo,
                        isDomainValid: true,
                    },
                });

            }
        } catch (error) {

            throw (error);
        }
    }


    //  if user deletes the domain after validating then validation is reset to false for domain.

    // useEffect(() => {

    //     if (!newCompanyDetails?.companyBasicInfo?.website) {

    //         setNewCompanyDetails({
    //             ...newCompanyDetails,
    //             companyBasicInfo: {
    //                 ...newCompanyDetails?.companyBasicInfo,
    //                 isDomainValid: false
    //             }
    //         });
    //     }
    // }, [newCompanyDetails?.companyBasicInfo?.website])


    return (

        <div style={{ marginTop: "30px" }}>
            <Form
                className="edit__poc__details__form"
                id="edit__poc__details__form__details"
            >
                <Form.Row>
                    <Form.Group as={Col} controlId="companyDetails">
                        <Form.Label className={classes.keyvalue}>Company Website Url*</Form.Label>
                        <Form.Control
                            required
                            name="companyDetails"
                            type="text"
                            placeholder={"Company Website Url*"}
                            value={newCompanyDetails?.companyBasicInfo?.website}
                            onChange={(e) => {
                                setNewCompanyDetails({
                                    ...newCompanyDetails,
                                    companyBasicInfo: {
                                        ...newCompanyDetails?.companyBasicInfo,
                                        website: e.target.value
                                    }
                                });
                            }
                            }
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Group controlId="socialLinks">
                    <Form.Label className={classes.keyvalue}>Facebook Url</Form.Label>
                    <Form.Control
                        name="facebookPage"
                        type="text"
                        placeholder={"Facebook Page Url"}
                        value={newCompanyDetails?.companyBasicInfo?.facebookPage}
                        onChange={(e) =>

                            setNewCompanyDetails({
                                ...newCompanyDetails,
                                companyBasicInfo: {
                                    ...newCompanyDetails?.companyBasicInfo,
                                    facebookPage: e.target.value
                                }
                            })
                        }
                    />
                </Form.Group>
                <Form.Group controlId="instaAccount">
                    <Form.Label className={classes.keyvalue}>Instagram Url</Form.Label>
                    <Form.Control
                        name="instaAccount"
                        type="text"
                        placeholder={"Instagram Account Url"}
                        value={newCompanyDetails?.companyBasicInfo?.instaAccount}
                        onChange={(e) =>
                            setNewCompanyDetails({
                                ...newCompanyDetails,
                                companyBasicInfo: {
                                    ...newCompanyDetails?.companyBasicInfo,
                                    instaAccount: e.target.value
                                }
                            })
                        }

                    />
                </Form.Group>
                <Form.Group controlId="instaAccount">
                    <Form.Label className={classes.keyvalue}>Glassdoor</Form.Label>
                    <Form.Control
                        name="glassdoor"
                        type="text"
                        placeholder={"Glassdoor Account Url"}
                        value={newCompanyDetails?.companyBasicInfo?.glassdoor}
                        onChange={(e) =>
                            setNewCompanyDetails({
                                ...newCompanyDetails,
                                companyBasicInfo: {
                                    ...newCompanyDetails?.companyBasicInfo,
                                    glassdoor: e.target.value
                                }
                            })
                        }

                    />
                </Form.Group>
                <Form.Group controlId="instaAccount">
                    <Form.Label className={classes.keyvalue}>Linked In</Form.Label>
                    <Form.Control
                        name="LinkedIn"
                        type="text"
                        placeholder={"LinkedInAccount Url"}
                        value={newCompanyDetails?.companyBasicInfo?.linkedIn}
                        onChange={(e) =>
                            setNewCompanyDetails({
                                ...newCompanyDetails,
                                companyBasicInfo: {
                                    ...newCompanyDetails?.companyBasicInfo,
                                    linkedIn: e.target.value
                                }
                            })
                        }

                    />
                </Form.Group>
                {
                    !(newCompanyDetails?.companyBasicInfo?.companyType === "premium") ? null :

                        <>
                            <Form.Group controlId="getworkDomain">
                                <Form.Label className={classes.keyvalue}>GW Domain</Form.Label>
                                <Form.Control
                                    name="gwDomain"
                                    type="text"
                                    placeholder={"Getwork Domain"}
                                    value={newCompanyDetails?.companyBasicInfo?.gwDomain}
                                    onChange={(e) =>
                                        setNewCompanyDetails({
                                            ...newCompanyDetails,
                                            companyBasicInfo: {
                                                ...newCompanyDetails?.companyBasicInfo,
                                                gwDomain: e.target.value
                                            }
                                        })
                                    }

                                />
                            </Form.Group>
                            <section>
                                {
                                    newCompanyDetails?.companyBasicInfo?.onEditCompanyPage ? null :
                                        <Button
                                            onClick={() => {
                                                if (newCompanyDetails?.companyBasicInfo?.gwDomain) {

                                                    checkDomainAvailability();
                                                } else {
                                                    FailureAlert("Please enter domain detils first")
                                                }
                                            }}
                                        >

                                            Check Domain Availability
                                        </Button>
                                }

                            </section>
                        </>

                }

            </Form>
        </div>

    )
}

export default CommunicationLinks