import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/StudentsPerCollege.css';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Button, Modal, Table} from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';
import { viewJobDetail } from '../../util/ViewJobFunction';
import { printNumber } from '../../util/printNumber';
import TextField from '@material-ui/core/TextField';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {Autocomplete} from '@material-ui/lab';


function JobsPerCollege() {
    
    const [collegeId, setCollegeId] = useState(null);
    const [colleges, setColleges] = useState([]);
    
    // Fetch all Colleges Dropdown
    useEffect(() => {
        apiCall('/api/admin/college/get/', requestPraveshOptions)
        .then((res)=> {
            if(res.success) {
                let collegeNames = {}
                for (var i = 0; i < res.data.length; i++) {
                    let obj = res.data[i]
                    collegeNames[obj.college_id] = obj.college_name;
                }
                setColleges(res.data)
                localStorage.setItem('colleges', JSON.stringify(collegeNames));
            }
            else {
                FailureAlert(res.error)
            }
        })
    }, [collegeId]);

    return (
        <div className="students__per__college">
             <Form className="select__college__form" >
                <h3>College-wise Jobs</h3>
                <hr />
                <Autocomplete
                    id="select-user"
                    options={colleges}
                    getOptionLabel={(item) => item.college_name}
                    style={{ width: '90%', margin: '5%' }}
                    onChange={(e, item)=>item ? setCollegeId(item.college_id) : setCollegeId(null)}
                    renderInput={(params) => <TextField {...params} label="Select College" variant="outlined" />}
                />
            </Form>
            {
                !collegeId ? null : <JobDetails clgId = {collegeId} />
            }

        </div>
    )
}

export default JobsPerCollege;

function JobDetails( {clgId} ) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const clgName = 
          JSON.parse(localStorage.getItem('colleges'))
          ?
          JSON.parse(localStorage.getItem('colleges'))[clgId] :
          'College Name';

    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const loadModal = (e, arr) => {
        setModalData(arr)
        handleOpen()
    }

    useEffect(() => {
        setLoading(true);    
        apiCall(`/api/admin/operations/college/job/?college_id=${clgId}`, requestPraveshOptions)
        .then(json => {
          if(json.success) {
            let tmpArray = []
            for (var i = 0; i < json.data.length; i++) {
                let specializationArray = []
                for(var j=0; j < json.data[i].preferences.length; j++) {
                    for(var k=0; k < json.data[i].preferences[j].specialization.length; k++) {
                        let sp_obj = {
                            degree_name:          json.data[i].preferences[j].degree_name,
                            specialization_name:  json.data[i].preferences[j].specialization[k].specialization_name ||
                                                  json.data[i].preferences[j].specialization[k].name,
                        }
                        specializationArray.push(sp_obj)
                    }
                }
                let obj = {
                    company_id:        json.data[i].company_id,
                    company_name:      json.data[i].company_name,
                    ctc_min:           json.data[i].ctc_min,
                    ctc_max:           json.data[i].ctc_max,
                    specializations:   specializationArray,
                    hiring_type:       json.data[i].hiring_type,
                    employment_type:   json.data[i].employment_type_name,
                    job_id:            json.data[i].job_id,
                    job_title:         json.data[i].job_title,
                    job_type_name:     json.data[i].job_type_name,
                    job_role:          json.data[i].job_role,
                    job_post_date:     json.data[i].job_post_date,
                    apply_start_date:  json.data[i].apply_start_date,
                    apply_end_date:    json.data[i].apply_end_date,
                    job_status:        json.data[i].job_status,
                    applicants:        json.data[i].total_applicants,
                    is_job_approved:   json.data[i].is_approved ? 'Yes' : 'No',
                }
                tmpArray.push(obj)
            }
            setLoading(false);
            setData(tmpArray);
        }
        else {
            FailureAlert(json.error)
        }    
      })
    }, [clgId]);


    const handleChange = (newRange, props) => {
      props.onFilterChanged(props.columnDef.tableData.id, newRange);
    };

    const ctcLabel = [
      { value: 0, label: '0', },
      { value: 1000000, label: '10L', },
      { value: 2000000, label: '20L', },
    ];

    const dateRange = [new Date(2021, 0, 1), new Date()];

    const handleChangeDate = (newRange, props) => {
      let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
      let endDate = (newRange && newRange[1]) || new Date();
      props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
    };

    const employeeTypeFilter = {'Full Time':'Full Time', 'Part Time':'Part Time'}
    const hiringTypeFilter = {'ON CAMPUS':'ON CAMPUS', 'OFF CAMPUS':'OFF CAMPUS'}
    const jobStatusFilter = {'OPEN':'OPEN', 'CLOSE':'CLOSE', 'DRAFT':'DRAFT'}

    const columns = [
        { title: "Job_ID",         field: "job_id",          headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false, },
        { title: "Company_ID",  
           field: "company_id",
           headerStyle: headerCSS,
        
           cellStyle:{
             minWidth: 200,
             maxWidth: 200,
             fontSize: 13,
           },
           render: (row) => ( 
            <div 
            style={{
              fontSize: 13,
            }}
            >
                {
                  row?.company_id ? row?.company_id : null
                }

            </div>
             ),
           
          //  cellCSS,

          searchable: false, },
        { title: "Company_Name",   field: "company_name",    headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Job_Title",      field: "job_title",       headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Job_Role",       field: "job_role",        headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Minimum_CTC",    field: "ctc_min",         headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,       render: row => row.ctc_min ? printNumber(row.ctc_min) : null,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}}
              value={props.columnDef.tableData.filterValue || [0,2000000]}
              defaultValue={[0, 2000000]}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={2000000}
              step={50000} marks={ctcLabel}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={printNumber}
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.ctc_min >= term[0] && rowData.ctc_min <= term[1],
        },
        { title: "Maximum_CTC",    field: "ctc_max",         headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,       render: row => row.ctc_max ? printNumber(row.ctc_max) : null,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}}
              value={props.columnDef.tableData.filterValue || [0,2000000]}
              defaultValue={[0, 2000000]}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={2000000}
              step={50000} marks={ctcLabel}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={printNumber}
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.ctc_max >= term[0] && rowData.ctc_max <= term[1],
        },
        { title: "CTC_Range",      headerStyle: headerCSS,   cellStyle: cellCSS,  searchable: false,
          render: row => <div>{
                row.ctc_min && row.ctc_max ? `${printNumber(row.ctc_min)}-${printNumber(row.ctc_max)}` :
                row.ctc_min ? `${printNumber(row.ctc_min)}+}` :
                row.ctc_max ? `< ${printNumber(row.ctc_max)}}` : null
            }</div>
        },
        { title: "Job_Type",       field: "job_type_name",   headerStyle: headerCSS, cellStyle: cellCSS,
          filterPlaceholder: 'Job Type', },
        { title: "Hiring_Type",    field: "hiring_type",     headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,       lookup: hiringTypeFilter, filterPlaceholder: 'Select', },
        { title: "Employee_Type",  field: "employment_type", headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,       lookup: employeeTypeFilter, filterPlaceholder: 'Select', },
        { title: "Job_Posted",     field: "job_post_date",   headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'date',            searchable: false,        filterPlaceholder: 'Open Calendar',
          filterComponent: (props) =>
            <DateRangePicker 
              rangeDivider="to"
              value={props.columnDef.tableData.filterValue || dateRange}
              onChange={e=> handleChangeDate(e, props)}
            />,
          customFilterAndSearch: (term, rowData) =>
                Date.parse(term[0]) <= Date.parse(rowData.job_post_date) && Date.parse(term[1]) >= Date.parse(rowData.job_post_date)
          // customFilterAndSearch: (term, rowData) =>
          //     new Date(term).toISOString().substring(0, 10) === rowData.job_post_date.substring(0, 10) 
        },
        { title: "Apply_Start_Date", field: "apply_start_date",  headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'date',            searchable: false,        filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.apply_start_date.substring(0, 10) 
        },
        { title: "Apply_End_Date", field: "apply_end_date",  headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'date',            searchable: false,        filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.apply_end_date.substring(0, 10) 
        },
        { title: "Job_Status",     field: "job_status",      headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,       lookup: jobStatusFilter,  filterPlaceholder: 'Select', },
        { title: "Is Job Approved?", field: "is_job_approved", headerStyle: headerCSS, cellStyle: cellCSS,
          lookup: {'Yes':'Yes', 'No':'No'},  searchable: false,    filterPlaceholder: 'Yes/No',
        },
        { title: "Applications",   field: "applicants",      headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'numeric',         searchable: false, },
        { title: "View_Job Details", headerStyle: headerCSS, cellStyle: cellCSS,     filtering: false,
          render: row =>
                <Button 
                    variant="outline-info" 
                    size="sm"
                    style={{
                    fontSize: 13,
                    }}
                    onClick={() => viewJobDetail(row.job_id)}
                >
                View Job
                </Button>
        },
        { headerStyle: headerCSS,  cellStyle: cellCSS,
            render: row => 
              <Button 
                  variant="outline-info" 
                  size="sm" 
                  style={{
                    fontSize: 13,
                    }}
                  onClick={e=> loadModal(e, row.specializations)}
              >
              Specializations
              </Button>
        },
    ]

    return (
        !loading
        ?
        <div>
            <TableWithExport
                title = {`${clgName} Jobs`}
                data = {data}
                columns = {columns}
                fName = "Selected_Jobs"
            />
            {
            modalData
            ?
            <Modal show={open} onHide={handleClose} scrollable={true}>
                <Modal.Header closeButton>
                <Modal.Title>Specializations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Degree</th>
                                <th>Specialization</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modalData.map((m, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{m.degree_name}</td>
                                        <td>{m.specialization_name}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            :
            null
            }    
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}