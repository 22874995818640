import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import generateCsv from "../../util/generateCsv";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";
import AddSubCategoryDialog from "./AddSubCategoryDialog";

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "2px",
    marginBottom: "10px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const token = localStorage.getItem("token");

function AllClubSubCategory(props) {
  const classes = useStyles();
  const [userCreatedColleges, setUserCreatedCollges] = useState(props.userCreated ? 1 : 0);

  const loading = false;

  const columns = [
    {
      title: "Sub Category Name",
      field: "title",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Sub Category",
      render: (row) => (
        <div
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => {
            handleClickOpen();
            setCurrentRow(row);
            setEdit(true);
          }}
        >
          {row.title} &nbsp; &nbsp; &nbsp;
          <EditIcon style={{ cursor: "pointer" }} />
        </div>
      ),
    },
    {
      title: "Sub Category Tagline",
      field: "tagline",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Tagline",
      filtering: false,
    },
    {
      title: "Selected Category(s)",
      field: "category_list",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    },
    {
      title: "Status",
      field: "is_active",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Status",
      render: (row) => <>{row?.is_active ? <div style={{ color: "green", cursor: "pointer" }}>Active</div> : <div style={{ color: "red", cursor: "pointer" }}>InActive</div>}</>,
    },
  ];

  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/sub_category/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            item["category_list"] = generateCsv(item?.category, "title");
          });
          setDataCategory(json);
        } else FailureAlert("Link History was not fetched. Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [subCategory, setSubCategory] = useState(null);
  const [category, setCategory] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(null);
  const [tagline, setTagline] = useState(null);

  const addCategory = () => {
    var arr = [];
    category?.forEach((item) => {
      arr.push(item.id);
    });
    const requestOptionsPatch = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        title: subCategory,
        tagline: tagline,
        categories: arr,
        is_active: status === "ACTIVE" ? true : false,
      }),
    };
    apiCall(`/api/getwork_club/sub_category/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
        setCategory([]);
        setSubCategory("");
        setStatus(null);
        setTagline("");
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const updateCategory = (id) => {
    var arr = [];
    category?.forEach((item) => {
      arr.push(item.id);
    });
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        title: subCategory,
        tagline: tagline,
        categories: arr,
        is_active: status === "ACTIVE" ? true : false,
      }),
    };
    apiCall(`/api/getwork_club/sub_category/${id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
        setCategory([]);
        setSubCategory("");
        setStatus(null);
        setTagline("");
      } else {
        FailureAlert(res.error);
      }
    });
  };

  useEffect(() => {
    if (edit && currentRow) {
      setCategory(currentRow?.category);
      setSubCategory(currentRow?.title);
      setTagline(currentRow?.tagline);
      setStatus(currentRow?.is_active ? "ACTIVE" : "INACTIVE");
    }
  }, [edit]);

  return (
    <div className="all__colleges">
      
      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <>
          {/* <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}> */}
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{ minWidth: "145px" }}
                  color="primary"
                  onClick={() => {
                    handleClickOpen();
                  }}
                  startIcon={<AddIcon className={classes.icon} />}
                >
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>
          <TableWithExport title="Sub Categories" columns={columns} data={dataCategory} fName="Sub_Category" />
          {/* </TabPanel> */}
        </>
      ) : (
        <></>
      )}
      <AddSubCategoryDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
        addCategory={addCategory}
        category={category}
        setCategory={setCategory}
        updateCategory={updateCategory}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        edit={edit}
        setEdit={setEdit}
        status={status}
        setStatus={setStatus}
        tagline={tagline}
        setTagline={setTagline}
      />{" "}
    </div>
  );
}

export default withRouter(AllClubSubCategory);
