import { Box, Tab, Tabs, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { cellCSS, headerCSS } from "../../constants";
import { niyuktiAxiosPostReq } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import { viewJobDetail } from "../../util/ViewJobFunction";
import TableWithoutExport from "../TableWithoutExport";
import FilterDialog from "./FilterDialog";
import VendorPartnersFilter from "./VendorPartnersFilter";
import { Tooltip } from "@material-ui/core";

const textBox = {
  fontSize: "14px",
  color: "#2563EB",
  textAlign: "center",
};

const numberHeading = {
  backgroundColor: "#ebf5fa",
  fontWeight: "bold",
  fontSize: "14px",
  padding: "4px 15px",
  height: "50px",
  textAlign: "center",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

function VendorJob() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [status, setStatus] = useState("ACCEPTED");

  const [data, setData] = useState([]);
  const [count, setCount] = useState({});

  let date = new Date();
  date.setDate(date.getDate() - 7);
  const dateRange = [date, new Date()];

  const [filters, setFilters] = useState({
    advanceFilterDateValue: dateRange,
    selectedCompanyList: [],
    selectedJobTitleList: [],
    selectedPartnerList: [],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStatus(
      newValue === 0 ? "ACCEPTED" : newValue === 1 ? "PENDING" : "REJECTED"
    );
  };

  const [showFilter, setShowFilter] = React.useState(false);
  // const [sendFilterPayload, setSendFilterPayload] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setShowFilter(true);
  };
  const handleClose = () => {
    setShowFilter(false);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const payload = {
      status: status,
    };
    if (filters.selectedPartnerList && filters.selectedPartnerList.length > 0) {
      payload.vendor_ids = filters.selectedPartnerList.map(
        (item) => item.vendor_id
      );
    }

    if (filters.selectedCompanyList && filters.selectedCompanyList.length > 0) {
      payload.company_ids = filters.selectedCompanyList.map(
        (item) => item.company_id
      );
    }

    if (
      filters.selectedJobTitleList &&
      filters.selectedJobTitleList.length > 0
    ) {
      payload.job_ids = filters.selectedJobTitleList.map((item) => item.job_id);
    }
    if (
      filters.advanceFilterDateValue &&
      filters.advanceFilterDateValue.length === 2 &&
      filters.advanceFilterDateValue[0] &&
      filters.advanceFilterDateValue[1]
    ) {
      payload.start_date = formatDate(filters.advanceFilterDateValue[0]);
      payload.end_date = formatDate(filters.advanceFilterDateValue[1]);
    }

    setLoading(true);
    niyuktiAxiosPostReq(
      `/job/admin/vendor/job_list/`,
      // sendFilterPayload ? payload : { status: status }
      payload
    )
      .then((res) => {
        if (res?.data?.success) {
          setData(res?.data?.data);
        } else {
          FailureAlert("Error in Getting Firm Jobs");
        }
        setLoading(false);
      })
      .catch((err) => {
        FailureAlert("Error in Getting Firm Jobs");
        setLoading(false);
      });

    niyuktiAxiosPostReq(
      `/job/admin/vendor/job_count/`,
      // sendFilterPayload ? payload : { status: status }
      payload
    )
      .then((res) => {
        if (res?.data?.success) {
          setCount(res?.data?.data);
        } else {
          FailureAlert("Error in getting Count");
        }
      })
      .catch((err) => {
        FailureAlert("Error in Getting Count");
      });
  }, [status, filters]);

  const getTableTitleText = () => {
    let type;

    const typeMap = {
      0: "Accepted",
      1: "Pending",
      2: "Rejected",
    };

    if (value in typeMap) {
      type = typeMap[value];
    } else {
      // Default value if 'value' doesn't match any key in typeMap
      type = "Accepted";
    }
    if (
      filters?.advanceFilterDateValue &&
      filters?.advanceFilterDateValue?.length === 2 &&
      filters?.advanceFilterDateValue[0] &&
      filters?.advanceFilterDateValue[1]
    ) {
      return `${type} Jobs (${formatDate(
        filters.advanceFilterDateValue[0]
      )} - ${formatDate(filters.advanceFilterDateValue[1])})`;
    }
    return `Firms Report`;
  };

  const lengthChecker = (text) => {
    const maxLength = 75;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const columns = [
    {
      title: "Company Name",
      field: "company_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
    },
    {
      title: "Job Title",
      field: "job_title",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) =>
        row.job_title ? (
          <div
            onClick={() => {
              viewJobDetail(row?.job_id);
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "#2563EB",
                cursor: "pointer",
              }}
            >
              {row.job_title}
            </Typography>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Job ID",
      field: "job_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      filtering: false,
    },
    {
      title: "Applicant",
      field: "applicant",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      render: (row) => (
        <div>
          <button
            style={{
              color: "#2563EB",
              paddingLeft: "10px",
              paddingRight: "10px",
              height: "32px",
              backgroundColor: "#fff",
              border: "1px solid #2563EB",
              borderRadius: "6px",
            }}
            onClick={() => {
              window.open(
                `/admin/job-track/${row?.job_id}/${row?.company_id}`,
                "_blank"
              );
            }}
          >
            View ({row?.applied_count})
          </button>
        </div>
      ),
      filtering: false,
    },
    {
      title: "Assigned by",
      field: "assigned_by",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Hired/ Selected",
      field: "hired_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.hired_count}</Typography>
        </div>
      ),
    },
    {
      title: "Assigned date & time",
      field: "assigned_date_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Accepted by",
      field: "action_by",
      headerStyle: headerCSS,
      // cellStyle: {
      //   minWidth: 150,
      //   maxWidth: 150,
      // },
      filtering: false,
      render: (row) => (
        <Tooltip title={`${row.action_by}`} placement="right">
          <div
            style={{
              fontWeight: row?.fontWeight ? row?.fontWeight : "400",
              width: "250px",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            {lengthChecker(row.action_by)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Acceptance Datetime",
      field: "action_date_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Firm ID",
      field: "vendor_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
    },

    {
      title: "Firm name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "User Name",
      field: "user_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },

    {
      title: "Job Title",
      field: "job_title",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) =>
        row.job_title ? (
          <div
            onClick={() => {
              viewJobDetail(row?.job_id);
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "#2563EB",
                cursor: "pointer",
              }}
            >
              {row.job_title}
            </Typography>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Job type",
      field: "job_type",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "Applicant",
      field: "applicant",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      render: (row) => (
        <div>
          <button
            style={{
              color: "#2563EB",
              paddingLeft: "10px",
              paddingRight: "10px",
              height: "32px",
              backgroundColor: "#fff",
              border: "1px solid #2563EB",
              borderRadius: "6px",
            }}
            onClick={() => {
              window.open(
                `/admin/job-track/${row?.job_id}/${row?.company_id}`,
                "_blank"
              );
            }}
          >
            View ({row?.applied_count})
          </button>
        </div>
      ),
      filtering: false,
    },

    {
      title: "Job Stage",
      field: "stage",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      render: (row) => (
        <div>
          <Typography
            style={{
              fontSize: "14px",
              color: `${status === "ACCEPTED" ? "#0DA06A" : "#0DA06A"}`,
            }}
          >
            {status === "ACCEPTED"
              ? "Accepted"
              : status === "PENDING"
              ? "Pending"
              : "Rejected"}
          </Typography>
        </div>
      ),
      filtering: false,
    },

    {
      title: "Firm uploaded count",
      field: "vendor_applied_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.vendor_applied_count}</Typography>
        </div>
      ),
    },
    {
      title: "Waitlisted",
      field: "waitlisted",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row?.wait_listed_count}</Typography>
        </div>
      ),
    },
    {
      title: "Interviewed (job rounds)",
      field: "interviewed_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.interviewed_count}</Typography>
        </div>
      ),
    },

    {
      title: "Offered",
      field: "offered_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.offered_count}</Typography>
        </div>
      ),
    },
    {
      title: "Offered backout",
      field: "offered_backout_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.offered_backout_count}</Typography>
        </div>
      ),
    },
    {
      title: "Joined",
      field: "joined_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.joined_count}</Typography>
        </div>
      ),
    },
    {
      title: "Joined backout",
      field: "joined_backout_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.joined_backout_count}</Typography>
        </div>
      ),
    },
    {
      title: "Hired/ Selected",
      field: "hired_count",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.hired_count}</Typography>
        </div>
      ),
    },

    {
      title: "Selected backout ",
      field: "selected_backout_count ",
      headerStyle: numberHeading,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) => (
        <div>
          <Typography style={textBox}>{row.selected_backout_count}</Typography>
        </div>
      ),
    },
  ];

  const pendingJobcolumns = [
    {
      title: "Firm ID",
      field: "vendor_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
    },

    {
      title: "Firm name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "User Name",
      field: "user_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },
    {
      title: "Company Name",
      field: "company_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Job Title",
      field: "job_title",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) =>
        row.job_title ? (
          <div
            onClick={() => {
              viewJobDetail(row?.job_id);
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "#2563EB",
                cursor: "pointer",
              }}
            >
              {row.job_title}
            </Typography>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Job ID",
      field: "job_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      filtering: false,
    },
    {
      title: "Job type",
      field: "job_type",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
    },
    {
      title: "Assigned by",
      field: "assigned_by",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "Assigned date & time",
      field: "assigned_date_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Job Stage",
      field: "stage",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <Typography
            style={{
              fontSize: "14px",
              color: `${status === "ACCEPTED" ? "#0DA06A" : "#0DA06A"}`,
            }}
          >
            {status === "ACCEPTED"
              ? "Accepted"
              : status === "PENDING"
              ? "Pending"
              : "Rejected"}
          </Typography>
        </div>
      ),
      filtering: false,
    },
  ];

  const rejectedJobcolumns = [
    {
      title: "Firm ID",
      field: "vendor_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
    },

    {
      title: "Firm name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "User Name",
      field: "user_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },
    {
      title: "Company Name",
      field: "company_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Job Title",
      field: "job_title",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
      render: (row) =>
        row.job_title ? (
          <div
            onClick={() => {
              viewJobDetail(row?.job_id);
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "#2563EB",
                cursor: "pointer",
              }}
            >
              {row.job_title}
            </Typography>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Job ID",
      field: "job_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      filtering: false,
    },
    {
      title: "Job type",
      field: "job_type",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
    },
    {
      title: "Assigned by",
      field: "assigned_by",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "Assigned date & time",
      field: "assigned_date_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Rejected by",
      field: "action_by",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "Rejection date & time",
      field: "action_date_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Rejection reason",
      field: "reject_reason",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Job Stage",
      field: "stage",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <Typography
            style={{
              fontSize: "14px",
              color: `${status === "ACCEPTED" ? "#0DA06A" : "#0DA06A"}`,
            }}
          >
            {status === "ACCEPTED"
              ? "Accepted"
              : status === "PENDING"
              ? "Pending"
              : "Rejected"}
          </Typography>
        </div>
      ),
      filtering: false,
    },
  ];

  return (
    <>
      <div>
        <div className={classes.root}>
          <Box style={{ borderBottom: "2px solid #CBD5E1" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              // style={{ backgroundColor: "#fff" }}
              aria-label="simple tabs example"
            >
              <Tab
                label={`Accepted (${
                  count?.accepted_count ? count?.accepted_count : "0"
                })`}
                {...a11yProps(0)}
              />
              <Tab
                label={`Pending (${
                  count?.pending_count ? count?.pending_count : "0"
                })`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Rejected (${
                  count?.rejected_count ? count?.rejected_count : "0"
                })`}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <Box
            style={{
              marginTop: "25px",
              marginBottom: "25px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <FilterTab /> */}
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#2563EB",
                borderRadius: "6px",
                height: "34px",
                color: "#fff",
                marginRight: "10px",
                boxShadow: "none",
              }}
              onClick={handleClickOpen}
            >
              Filter
            </Button>
          </Box>
          <TabPanel value={value} index={0}>
            <div
              style={{
                // marginTop: "20px",
                // marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  color: "#fff",
                  marginRight: "10px",
                  boxShadow: "none",
                }}
                onClick={handleClickOpen}
              >
                Filter
              </Button> */}

              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  boxShadow: "none",
                  color: "#fff",
                }}
              >
                Download Excel Data
              </Button> */}
            </div>
            <TableWithoutExport
              columns={columns}
              title={getTableTitleText()}
              selection={false}
              data={data}
              loading={loading}
            />{" "}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  color: "#fff",
                  marginRight: "10px",
                  boxShadow: "none",
                }}
                onClick={handleClickOpen}
              >
                Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  boxShadow: "none",
                  color: "#fff",
                }}
              >
                Download Excel Data
              </Button>
            </div>
             */}
            <TableWithoutExport
              columns={pendingJobcolumns}
              title={getTableTitleText()}
              selection={false}
              data={data}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  color: "#fff",
                  marginRight: "10px",
                  boxShadow: "none",
                }}
                onClick={handleClickOpen}
              >
                Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2563EB",
                  borderRadius: "6px",
                  height: "34px",
                  boxShadow: "none",
                  color: "#fff",
                }}
              >
                Download Excel Data
              </Button>
            </div>
             */}
            <TableWithoutExport
              columns={rejectedJobcolumns}
              title={getTableTitleText()}
              selection={false}
              data={data}
              loading={loading}
            />
          </TabPanel>
        </div>
        {showFilter && (
          <VendorPartnersFilter
            open={showFilter}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            filters={filters}
            setFilters={setFilters}
            status={status}
            // setSendFilterPayload={setSendFilterPayload}
          />
        )}
      </div>
    </>
  );
}

export default VendorJob;
