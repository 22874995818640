import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import "../../css/Miscellaneous.css";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";

const token = localStorage.getItem("token");

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",

    marginBottom: "10px",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    border: "1px solid #b0b6ba",
    //marginBottom: "1%",
    borderRadius: "10px",
    padding: "10px",
  },
  editingtext: {
    color: "#b0b7c3",
    fontStyle: "italic",
  },
  btn: {
    backgroundColor: "#17a2b8",
    boxShadow: "none",
    color: "#fff",
    marginTop: "10px",
  },
  btn2: {
    backgroundColor: "#17a2b8",
    boxShadow: "none",
    color: "#fff",
  },
  btn3: {
    backgroundColor: "red",
    boxShadow: "none",
    color: "#fff",
  },
}));

function AddCompanyClub() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const params = useParams();


  const [companyDetails, setCompanyDetails] = useState({
    company_name: null,
    brand_name: null,
    website: null,
    poc_list: [],
  });

  const [currentPoc, setCurrentPoc] = useState({
    name: null,
    phone: null,
    designation: null,
    email: null,
  });

  const handleSubmit = () => {
    if (!companyDetails?.company_name) {
      FailureAlert("Please Enter Company Name");
      return;
    }
    if (!companyDetails?.brand_name) {
      FailureAlert("Please Enter Brand Name");
      return;
    }
    if (!companyDetails?.website) {
      FailureAlert("Please Enter Company Website");
      return;
    }
    if (companyDetails?.poc_list?.length === 0) {
      FailureAlert("Please Enter atleast one POC");
      return;
    }
    if (params?.vendor_id) updateVendor();
    else addVendor();
  };

  const addVendor = () => {
    const requestOptionsPatch = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        company_name: companyDetails?.company_name,
        brand_name: companyDetails?.brand_name,
        website: companyDetails?.website,
        spoc_detail: companyDetails?.poc_list,
      }),
    };
    apiCall(`/api/getwork_club/vendors/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCompanyDetails({
          company_name: "",
          brand_name: "",
          website: "",
          poc_list: [],
        });
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const updateVendor = () => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        company_name: companyDetails?.company_name,
        brand_name: companyDetails?.brand_name,
        website: companyDetails?.website,
        spoc_detail: companyDetails?.poc_list,
      }),
    };
    apiCall(`/api/getwork_club/vendors/${params?.vendor_id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCompanyDetails({
          company_name: "",
          brand_name: "",
          website: "",
          poc_list: [],
        });
      } else {
        FailureAlert(res.error);
      }
    });
  };

  useEffect(() => {
    if (params?.vendor_id) {
      getData();
    }
  }, [params?.vendor_id]);
  const [getCompanyData, setGetCompanyData] = useState(null);
  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/vendors/?vendor_id=${params?.vendor_id}`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          setCompanyDetails({
            company_name: json?.company_name,
            brand_name: json?.brand_name,
            website: json?.website,
            poc_list: json?.poc_list,
          });
          setGetCompanyData(json);
        } else FailureAlert("Link History was not fetched. Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  useEffect(() => {
    if (getCompanyData)
      setCompanyDetails({
        company_name: getCompanyData?.company_name,
        brand_name: getCompanyData?.brand_name,
        website: getCompanyData?.website,
        poc_list: getCompanyData?.poc_list,
      });
  }, [getCompanyData]);

  return !loading ? (
    <>
      <div className="education__skils">
        <div className="education__skils__form">
          <div className={classes.heading}>
            <Typography variant="h5" style={{ fontWeight: "600" }}>
              {" "}
              {params?.vendor_id ? "Edit Company/Vendor" : "Add Company/Vendor"}
            </Typography>
          </div>
          <div
            style={{
              borderBottom: "3px solid  #008080",
              paddingBottom: "30px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginBottom: "-10px" }}>
                <Typography variant="h6"> Company details </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={{ width: "100%" }}
                  label="Company Name"
                  required
                  size="small"
                  variant="outlined"
                  value={companyDetails?.company_name}
                  onChange={(e) => {
                    setCompanyDetails({ ...companyDetails, company_name: e.target.value });
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TextField
                  style={{ width: "100%" }}
                  label="Brand Name"
                  size="small"
                  variant="outlined"
                  value={companyDetails?.brand_name}
                  onChange={(e) => {
                    setCompanyDetails({ ...companyDetails, brand_name: e.target.value });
                  }}
                  InputLabelProps={{ shrink: true }}
                />{" "}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Company Website"
                  required
                  size="small"
                  variant="outlined"
                  value={companyDetails?.website}
                  onChange={(e) => {
                    setCompanyDetails({ ...companyDetails, website: e.target.value });
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              
              <Grid item xs={12} style={{ marginBottom: "-10px" }}>
                <Typography variant="h6"> Add POC </Typography>
              </Grid>
              {companyDetails?.poc_list?.map((item, index) => (
                <>
                  <Grid item xs={3}>
                    <TextField style={{ width: "100%" }} label="Name" required size="small" variant="outlined" value={item?.name} disabled />
                  </Grid>
                  <Grid item xs={3}>
                    {" "}
                    <TextField style={{ width: "100%" }} label="Email" size="small" variant="outlined" value={item?.email} disabled />{" "}
                  </Grid>
                  <Grid item xs={2}>
                    {" "}
                    <TextField style={{ width: "100%" }} label="Phone No." size="small" variant="outlined" value={item?.phone} disabled />{" "}
                  </Grid>
                  <Grid item xs={3}>
                    {" "}
                    <TextField style={{ width: "100%" }} label="Designation" size="small" variant="outlined" value={item?.designation} disabled />{" "}
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant="contained"
                      className={classes.btn3}
                      onClick={() => {
                        var arr = [...companyDetails?.poc_list];
                        arr.splice(index, 1);
                        setCompanyDetails({ ...companyDetails, poc_list: arr });
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </>
              ))}

              <Grid item xs={3}>
                <TextField
                  style={{ width: "100%" }}
                  label="Name"
                  required
                  size="small"
                  variant="outlined"
                  value={currentPoc?.name}
                  onChange={(e) => {
                    setCurrentPoc({ ...currentPoc, name: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                {" "}
                <TextField
                  style={{ width: "100%" }}
                  label="Email"
                  size="small"
                  variant="outlined"
                  value={currentPoc?.email}
                  onChange={(e) => {
                    setCurrentPoc({ ...currentPoc, email: e.target.value });
                  }}
                />{" "}
              </Grid>
              <Grid item xs={2}>
                {" "}
                <TextField
                  style={{ width: "100%" }}
                  label="Phone No."
                  size="small"
                  variant="outlined"
                  value={currentPoc?.phone}
                  onChange={(e) => {
                    setCurrentPoc({ ...currentPoc, phone: e.target.value });
                  }}
                />{" "}
              </Grid>
              <Grid item xs={3}>
                {" "}
                <TextField
                  style={{ width: "100%" }}
                  label="Designation"
                  size="small"
                  variant="outlined"
                  value={currentPoc?.designation}
                  onChange={(e) => {
                    setCurrentPoc({ ...currentPoc, designation: e.target.value });
                  }}
                />{" "}
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  className={classes.btn2}
                  onClick={() => {
                    if (!currentPoc?.name) {
                      FailureAlert("Please enter Poc Name");
                      return;
                    }
                    if (!currentPoc?.email) {
                      FailureAlert("Please enter Poc Email");
                      return;
                    }
                    if (!currentPoc?.phone) {
                      FailureAlert("Please enter Poc Phone");
                      return;
                    }
                    if (!currentPoc?.designation) {
                      FailureAlert("Please enter Poc Designation");
                      return;
                    }
                    var arr = [...companyDetails?.poc_list];
                    arr.push(currentPoc);
                    setCompanyDetails({ ...companyDetails, poc_list: arr });
                    setCurrentPoc({
                      name: "",
                      designation: "",
                      email: "",
                      phone: "",
                    });
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </div>

          <div>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              {params?.vendor_id ? "Edit Company/Vendor" : "Add Company/Vendor"}
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default AddCompanyClub;
