import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
// import { Button as Button2 } from "react-bootstrap";
import User from "../../../assets/images/user-default.png";
import { praveshAxiosPostReq } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import BlockStudent from "../../DialogSection/Block/BlockStudent";
import CustomButtonCircularProgress from "./loader";
import BlockIcon from "@material-ui/icons/Block";
import RestoreIcon from "@material-ui/icons/Restore";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import UTMReportDialog from "../../DialogSection/UTMReportDialog/UTMReportDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
    //maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },

  textField: {
    width: "100%",
  },
  blockStudent: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function Basic({
  basicInfo,
  cityList,
  saveBasicDetails,
  userId,
  currentEducation,
  getStudentDetails,
  tempBasicInfo,
  setTempBasicInfo = () => {},
  gender,
  setGender = () => {},
}) {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [blockData, setBlockData] = useState({});

  const [loaderOnSave, setLoaderOnSave] = useState(false);

  const [openUtm, setOpenUtm] = useState(false);

  const handleClickOpenUtm = () => {
    setOpenUtm(true);
  };

  const handleCloseUtm = () => {
    setOpenUtm(false);
  };

  // const [name, setName] = useState(tempBasicInfo?.studentName)
  //  const [data, setData] = useState([]);
  useEffect(() => {
    if (basicInfo?.user_id) {
      let tempData1 = { ...basicInfo };
      // note here we are just switching the keys, when new user is created

      tempData1.is_active = !tempData1?.is_blocked;
      tempData1.user_id = tempData1?.userId;

      setTempBasicInfo((prevData) => ({
        ...prevData,
        is_active: !tempData1?.is_blocked,
        user_id: tempData1?.userId,
        createDate: tempData1?.createDate,
      }));
      setGender(basicInfo?.gender);
    }

    // when user already exists: ie for prefilling
    if (basicInfo?.userId) {
      // setTempBasicInfo(basicInfo);
      let tempData1 = { ...basicInfo };
      // note here we are just switching the keys, when new user is created

      tempData1.is_active = !tempData1?.is_blocked;

      setTempBasicInfo({ ...basicInfo, is_active: !tempData1?.is_blocked });
      setGender(basicInfo?.gender);
    }
  }, [basicInfo]);

  const handleDobChange = (date) => {
    setTempBasicInfo({ ...tempBasicInfo, dob: date });
  };

  const blockStudent = async (
    blockStatus,
    deleteReason,
    handleClose,
    setDeleteReason,
    StudentID,
    setLoadingStatus,
    setBlockStatusButton,
    blockStatusButton
  ) => {
    if (!tempBasicInfo?.studentName) {
      FailureAlert("Student name is empty");
      return false;
    }

    const endPoint = "/api/admin/students/block/";
    const tempArr = [];
    tempArr.push(basicInfo?.userId);

    const data = {
      user_ids: tempArr,
      is_active: !blockStatus,
      reason: deleteReason ? deleteReason : "unblock",
    };

    try {
      const res = await praveshAxiosPostReq(endPoint, data);

      if (res?.data?.success) {
        SuccessAlert(res?.data?.data?.message);
        handleClose();
        setDeleteReason(null);
        // getStudentDetails();
      } else {
        FailureAlert("Some Error Occured!");
      }
    } catch (error) {
      throw error;
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(tempBasicInfo?.emailId)) {
      FailureAlert("Please enter a valid email");
      return false;
    }
    return true;
  };
  const validateForm = () => {
    if (!tempBasicInfo?.emailId) {
      FailureAlert("Email Id can't be empty");
      return false;
    }
    if (!validateEmail()) return false;
    if (!tempBasicInfo?.studentName) {
      FailureAlert("Student Name is empty");
      return false;
    }
    if (
      !tempBasicInfo?.phoneNumber ||
      tempBasicInfo?.phoneNumber.toString().length !== 10
    ) {
      FailureAlert("Enter Valid Phone number");
      return false;
    }
    // if(!tempBasicInfo?.gender){
    //   FailureAlert("Gender can't be empty");
    //   return false;
    // }
    if (
      (tempBasicInfo?.expYear || tempBasicInfo?.expMonth) &&
      !(tempBasicInfo?.expYear && tempBasicInfo?.expMonth) &&
      !(tempBasicInfo?.expYear === 0 || tempBasicInfo?.expYear === 0)
    ) {
      FailureAlert("please enter years/ months");
      return false;
    }
    if (tempBasicInfo?.gender == null) {
      FailureAlert("please select gender");
      return false;
    }
    // if(!tempBasicInfo?.currentCtc && tempBasicInfo?.currentCtc !== 0){

    //   FailureAlert("Current CTC can't be empty");
    //   return false;
    // }
    if (tempBasicInfo?.currentCtc < 100 && tempBasicInfo?.currentCtc > 0) {
      FailureAlert("Please enter CTC in INR. For e.g 300000, 567000");
      return false;
    }

    // if(!tempBasicInfo?.expectedCtc && tempBasicInfo?.expectedCtc !== 0){
    //   FailureAlert("Expected CTC can't be empty");
    //   return false;
    // }
    if (tempBasicInfo?.expectedCtc < 100 && tempBasicInfo?.expectedCtc > 0) {
      FailureAlert("Please enter CTC in INR. For e.g 300000, 567000");
      return false;
    }
    // if(!tempBasicInfo?.noticePeriod && tempBasicInfo?.noticePeriod !== 0){
    //   FailureAlert("Notice Period can't be empty");
    //   return false;
    // }
    return true;
  };

  const buttonToShow = () => {
    if (basicInfo?.is_blocked === true) {
      return true;
    }
    if (basicInfo?.is_blocked === false) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.blockStudent}>
            <Typography variant="h6">Basic Info</Typography>

            {buttonToShow() ? (
              <section>
                <Button
                  startIcon={
                    <AssessmentOutlinedIcon
                      style={{
                        fontSize: "16px",
                        color: "#007BFF",
                      }}
                    />
                  }
                  variant="text"
                  onClick={handleClickOpenUtm}
                  style={{
                    fontSize: 13,
                  }}
                >
                  <Typography
                    style={{
                      color: "#007BFF",
                      fontSize: 13,
                    }}
                  >
                    UTM Report
                  </Typography>
                </Button>
                <UTMReportDialog
                  openUtm={openUtm}
                  handleCloseUtm={handleCloseUtm}
                  basicInfo={basicInfo}
                />
                <Button
                  startIcon={
                    <RestoreIcon
                      style={{
                        fontSize: "16px",
                        color: "#007BFF",
                      }}
                    />
                  }
                  variant="text"
                  // size="sm"
                  onClick={() => {
                    window.open(`/admin/student-history?student_id=${userId}`);
                  }}
                  style={{
                    fontSize: 13,
                  }}
                >
                  <Typography
                    style={{
                      color: "#007BFF",
                      fontSize: 13,
                    }}
                  >
                    Student History
                  </Typography>
                </Button>
                <Button
                  startIcon={
                    <BlockIcon
                      style={{
                        fontSize: "16px",
                        color: "#EF4444",
                      }}
                    />
                  }
                  variant="text"
                  size="sm"
                  onClick={() => {
                    setDialogOpen(true);
                    setBlockData(basicInfo);
                  }}
                  style={{
                    fontSize: 13,
                  }}
                >
                  <Typography
                    style={{
                      color: "#EF4444",

                      fontSize: 13,
                    }}
                  >
                    {tempBasicInfo?.is_active ? "Block User" : "Unblock User"}
                  </Typography>
                </Button>
                <BlockStudent
                  // BlockStudent={blockStudent}
                  // blockStatus={row?.is_active}
                  // StudentID={row?.user_id}
                  open={dialogOpen}
                  setOpen={setDialogOpen}
                  blockData={tempBasicInfo}
                  // allData={tempBasicInfo}
                  setAllData={setTempBasicInfo}
                  isDetail={true}
                  // loadingStatus={loadingStatus}
                  // getAllStudentsData={getAllStudentsData}
                />
              </section>
            ) : null}
          </Grid>
          {userId && (
            <>
              <Grid item>
                <div style={{ height: "115px" }}>
                  <div className={classes.image}>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={
                        tempBasicInfo?.profilePicture
                          ? tempBasicInfo?.profilePicture
                          : User
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  spacing={2}
                  style={{ display: "contents" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Typography variant="h6">
                        {basicInfo?.studentName}
                      </Typography>
                    </div>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginBottom: "5px" }}
                    >
                      {currentEducation?.college_name}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginBottom: "5px" }}
                    >
                      {currentEducation?.degree}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginBottom: "5px" }}
                    >
                      {currentEducation?.specialization}
                    </Typography>
                    {tempBasicInfo?.platform && (
                      <Typography variant="body2" color="textSecondary">
                        SignUp Platform: {tempBasicInfo?.platform}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {userId && (
              <>
                <TextField
                  id="outlined-basic"
                  label="User Id"
                  value={basicInfo?.userId}
                  size="small"
                  disabled
                  variant="outlined"
                  margin="normal"
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled
                    margin="normal"
                    disableToolbar
                    variant="outlined"
                    size="small"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    defaultValue="MM/YYYY"
                    label="Created Date"
                    value={tempBasicInfo?.createDate}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    style={{ width: "100%" }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
            <TextField
              // disabled={userId ? true : false}
              id="outlined-basic"
              label="Email ID*"
              variant="outlined"
              margin="normal"
              size="small"
              value={tempBasicInfo?.emailId}
              onChange={(e) =>
                setTempBasicInfo({ ...tempBasicInfo, emailId: e.target.value })
              }
            />

            <TextField
              id="outlined-basic"
              label="Student Name*"
              size="small"
              variant="outlined"
              margin="normal"
              value={
                tempBasicInfo?.studentName ? tempBasicInfo?.studentName : ""
              }
              onChange={(e) =>
                setTempBasicInfo({
                  ...tempBasicInfo,
                  studentName: e.target.value,
                })
              }
            />
            <TextField
              id="outlined-basic"
              label="Phone Number*"
              margin="normal"
              variant="outlined"
              size="small"
              type="number"
              onWheel={() => document.activeElement.blur()}
              value={
                tempBasicInfo?.phoneNumber ? tempBasicInfo?.phoneNumber : ""
              }
              onChange={(e) => {
                setTempBasicInfo({
                  ...tempBasicInfo,
                  phoneNumber: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // disableToolbar
                variant="outlined"
                size="small"
                openTo="year"
                views={["year", "month", "date"]}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                id="date-picker-inline"
                defaultValue="MM/YYYY"
                label="DOB"
                value={tempBasicInfo?.dob ? tempBasicInfo?.dob : null}
                onChange={handleDobChange}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                style={{ width: "100%" }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              className={classes.textField}
              size="small"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={classes.floatingLabelFocusStyle}
              >
                Gender*
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={gender}
                onChange={(e) => {
                  setTempBasicInfo({
                    ...tempBasicInfo,
                    gender: e.target.value,
                  });
                  setGender(e.target.value);
                }}
                label="Gender"
                MenuProps={{
                  style: {
                    marginTop: "30px",
                  },
                }}
                // renderInput={(params) => <TextField {...params} label="Select Gender*" variant="outlined" />}
              >
                <MenuItem value={"Male"} name="gender">
                  Male
                </MenuItem>
                <MenuItem value={"Female"} name="gender">
                  Female
                </MenuItem>
                <MenuItem value={"Other"} name="gender">
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              id="select-city"
              options={cityList}
              getOptionLabel={(item) => {
                let location = [];
                if (typeof item === "string") {
                  location = item.split(",").map((value) => value.trim());
                }
                return `${item.city || location[0]}${
                  item.state || location[1] ? "," : ""
                } ${item.state || location[1] || ""}`;
              }}
              onChange={(e, item) => {
                if (item) {
                  setTempBasicInfo({
                    ...tempBasicInfo,
                    currentCityId: item.city_id || -1,
                    currentCityName: item.city || item,
                  });
                } else {
                  setTempBasicInfo({ ...tempBasicInfo, currentCityId: 0 });
                }
              }}
              onInputChange={(e, item) => {
                let location = [];
                if (typeof item === "string") {
                  location = item.split(",").map((value) => value.trim());
                }
                item
                  ? setTempBasicInfo({
                      ...tempBasicInfo,
                      currentCityId: item?.city_id || -1,
                      currentCityName: item?.city || item,
                    })
                  : setTempBasicInfo({ ...tempBasicInfo, currentCityId: 0 });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Select Current City (Current: ${
                    tempBasicInfo?.currentCityName || `NONE`
                  })`}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              id="select-hometown-city"
              options={cityList}
              // getOptionLabel={(item) => `${item.city}, ${item.state}`}
              getOptionLabel={(item) => {
                let location = [];
                if (typeof item === "string") {
                  location = item.split(",").map((value) => value.trim());
                }
                return `${item.city || location[0]}${
                  item.state || location[1] ? "," : ""
                } ${item.state || location[1] || ""}`;
              }}
              onChange={(e, item) => {
                if (item) {
                  setTempBasicInfo({
                    ...tempBasicInfo,
                    hometownCityId: item.city_id || -1,
                    hometownCityName: item.city || item,
                  });
                } else {
                  setTempBasicInfo({ ...tempBasicInfo, hometownCityId: 0 });
                }
              }}
              onInputChange={(e, item) => {
                let location = [];
                if (typeof item === "string") {
                  location = item.split(",").map((value) => value.trim());
                }
                item
                  ? setTempBasicInfo({
                      ...tempBasicInfo,
                      hometownCityId: item.city_id || -1,
                      hometownCityName: item.city || item,
                    })
                  : setTempBasicInfo({ ...tempBasicInfo, hometownCityId: 0 });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Select Hometown (Current: ${
                    tempBasicInfo?.hometownCityName || `NONE`
                  })`}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <div>Total Experience:(Enter 0 for freshers.)</div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              label="years"
              style={{ width: "100%", marginBottom: "10px" }}
              variant="outlined"
              size="small"
              type="number"
              onWheel={(event) => {
                event.preventDefault();
                document.activeElement.blur();
              }}
              value={tempBasicInfo?.expYear}
              onChange={(e) => {
                setTempBasicInfo({ ...tempBasicInfo, expYear: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              label="months"
              style={{ width: "100%", marginBottom: "10px" }}
              variant="outlined"
              size="small"
              type="number"
              onWheel={() => document.activeElement.blur()}
              value={tempBasicInfo?.expMonth}
              onChange={(e) => {
                setTempBasicInfo({
                  ...tempBasicInfo,
                  expMonth: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Current CTC(in INR)"
              style={{ width: "100%" }}
              variant="outlined"
              size="small"
              onWheel={() => document.activeElement.blur()}
              type="number"
              value={tempBasicInfo?.currentCtc}
              onChange={(e) => {
                setTempBasicInfo({
                  ...tempBasicInfo,
                  currentCtc: e.target.value,
                });
              }}
            />
            <FormControl component="fieldset" style={{ marginBottom: "-20px" }}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={tempBasicInfo?.isConfidential ? true : false}
                      onChange={(e) => {
                        setTempBasicInfo({
                          ...tempBasicInfo,
                          isConfidential: !tempBasicInfo.isConfidential,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "12px" }}>
                      Confidential
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              id="outlined-basic"
              label="Expected CTC(in INR)"
              variant="outlined"
              size="small"
              type="number"
              value={tempBasicInfo?.expectedCtc}
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => {
                setTempBasicInfo({
                  ...tempBasicInfo,
                  expectedCtc: e.target.value,
                });
              }}
            />
            <FormControl component="fieldset" style={{ marginBottom: "-10px" }}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  className={classes.fieldStyling1}
                  value={tempBasicInfo?.isNegotiable}
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      checked={tempBasicInfo?.isNegotiable ? true : false}
                      onChange={(e) => {
                        setTempBasicInfo({
                          ...tempBasicInfo,
                          isNegotiable: !tempBasicInfo.isNegotiable,
                        });
                      }}
                      name={"college_not_listed"}
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "12px" }}>
                      Negotiable
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Notice Period(in days)"
                style={{ width: "100%", marginBottom: "10px" }}
                variant="outlined"
                size="small"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 365 } }}
                value={tempBasicInfo?.noticePeriod}
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  setTempBasicInfo({
                    ...tempBasicInfo,
                    noticePeriod: e.target.value,
                  });
                }}
              />
            </Grid>
            <TextField
              style={{ width: "100%" }}
              id="outlined-basic"
              label="About"
              multiline
              rows={5}
              variant="outlined"
              margin="normal"
              size="small"
              value={tempBasicInfo?.about}
              onChange={(e) => {
                setTempBasicInfo({ ...tempBasicInfo, about: e.target.value });
              }}
            />
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  if (validateForm()) {
                    saveBasicDetails(tempBasicInfo, setLoaderOnSave);
                  }
                }}
              >
                {loaderOnSave ? <CustomButtonCircularProgress /> : "Save"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
