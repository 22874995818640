import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { apiNiyuktiCall } from "../../util/apiCall";
import {
  headerCSS,
  cellCSS,
  gifStyling,
  createIconStyling,
  requestPraveshOptions,
} from "../../constants";
import TableWithExport from "../TableWithExport";
import { Button, Modal, Form } from "react-bootstrap";
import gif from "../../assets/loopingcircles-3.gif";
import { SuccessAlert, FailureAlert } from "../../util/Notifications";
import { printNumber, validateDateTime } from "../../util/printNumber";
import { viewJobDetail } from "../../util/ViewJobFunction";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { TabPanel } from "../../util/TabPanel";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { DateSelector } from "../common/DateSelector";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../util/copyToClipboard";
import QueryString from "query-string";
import { useHistory } from "react-router-dom";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

function PublicJobPosting() {
  const jobCategorySearchObject = {
    HOT: "HOT",
    URGENT: "URGENT",
    PREMIUM: "PREMIUM",
    POPULAR: "POPULAR",
  };

  const [startDate, setStartDate] = useState("");
  let history = useHistory();
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  // value = 0 --> Pending, 1--> Posted, 2 --> Rejected

  const [open, setOpen] = useState(false);
  const [modalRejectJobData, setModalRejectJobData] = useState({});
  const [closeReason, setCloseReason] = useState("");
  const [count, setCount] = useState(0);

  const dateRange = [new Date(2021, 0, 1), new Date()];

  const userData = JSON.parse(localStorage.getItem("user-data"));
  const userId = userData.id;

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  const handleClose = () => {
    setOpen(false);
    // setUploadAssignment(false);
    setModalRejectJobData({});
  };

  const updateList = () => {
    setCount(count + 1);
  };
  const handleOpen = () => setOpen(true);

  const loadRejectJobModal = (e, obj) => {
    setModalRejectJobData(obj);
    handleOpen();
  };

  const rejectParticularJob = (jobId) => {
    if (!closeReason) {
      FailureAlert("Specify Reason to Reject Job!!");
      return;
    }

    const requestOptionsPost = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        status: "REJECTED",
        reject_reason: closeReason,
        posted_by: userId,
      }),
    };

    apiNiyuktiCall(
      `/job/public_job_posting/${jobId}/`,
      requestOptionsPost
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCloseReason("");
      } else {
        FailureAlert(res.error);
      }
      updateList();
      handleClose();
    });
  };

  const formatSalary = (min_sal, max_sal) => {
    return min_sal && max_sal
      ? `${printNumber(min_sal)}-${printNumber(max_sal)}`
      : min_sal
      ? `${printNumber(min_sal)}+`
      : max_sal
      ? `${printNumber(max_sal)}`
      : null;
  };

  useEffect(() => {
    const jobStatus =
      value === 0 ? "PENDING" : value === 1 ? "POSTED" : "REJECTED";

    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 7)
    );
    let initialEndDate = new Date(new Date().setDate(new Date().getDate() + 1));

    if (startDate === "") {
      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = new Date(queryParams.from);
      }
      if (queryParams.to) {
        initialEndDate = new Date(queryParams.to);
      }
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      return;
    } else if (startDate === null) {
      initialStartDate = null;
    } else {
      initialStartDate = new Date(startDate);
      initialEndDate = new Date(endDate);
    }

    let dateString = "";
    if (initialStartDate) {
      let month = initialStartDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = initialStartDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${initialEndDate
          .toISOString()
          .substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      dateString = `&start_date=${startDateString}&end_date=${initialEndDate
        .toISOString()
        .substring(0, 10)}`;
    } else {
      history.replace({
        search: "",
      });
    }

    setLoading(true);

    apiNiyuktiCall(
      `/job/public_job_posting/?status=${jobStatus}${dateString}`,
      requestPraveshOptions
    ).then((json) => {
      if (json.success) {
        let temp = [];
        for (var i = 0; i < json.data.length; i++) {
          const singleJobData = json.data[i];

          const getWorkLocationMode = () => {
            if (
              json?.data[i]?.work_from_home &&
              json?.data[i]?.job_location?.length > 0
            ) {
              return "Hybrid";
            } else if (json?.data[i]?.work_from_home) {
              return "Work From Home";
            } else {
              return "Work From Offc";
            }
          };

          let obj = {
            jobId: singleJobData.id,
            postedJobId: jobStatus === "POSTED" ? singleJobData.job_id : null,
            jobTitle: singleJobData.job_title,
            jobTypeName: singleJobData.job_type_name,
            hiringType: singleJobData.hiring_type,
            jobPaymentType: singleJobData.job_payment_type,
            salaryType: singleJobData.salary_type,
            jobTmc: singleJobData.job_tmc ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(singleJobData.job_tmc),
                }}
              ></div>
            ) : (
              ""
            ),
            jobCategory: singleJobData?.job_category,
            ctcMin: singleJobData.ctc_min,
            ctcMax: singleJobData.ctc_max,
            ctc: formatSalary(singleJobData.ctc_min, singleJobData.ctc_max),
            stipendMin: singleJobData.stipend_min,
            stipendMax: singleJobData.stipend_max,
            internship_salary_type: singleJobData.internship_salary_type,
            stipend: formatSalary(
              singleJobData.stipend_min,
              singleJobData.stipend_max
            ),
            vacancy: singleJobData.vacancy || "",
            createTime: validateDateTime(singleJobData.create_time),
            updateTime: validateDateTime(singleJobData.update_time),
            companyId: singleJobData?.company?.company_id,
            companyName:
              singleJobData?.company?.company_name +
              ", " +
              singleJobData?.company?.company_location?.city_name,
            companyWebsite: singleJobData?.company?.company_website,
            contactEmail: singleJobData.contact_email,
            contactPhone: singleJobData.contact_phone,
            contactName: singleJobData.contact_name,
            location: singleJobData.job_location
              .map((loc) => {
                return loc.city;
              })
              .join(", "),
            workExperience: singleJobData.work_experience,
            workFromHome: singleJobData.work_from_home ? "Yes" : "No",
            utmCampaign: singleJobData.utm_campaign,
            workLocationMode: getWorkLocationMode(),
            utmSource: singleJobData.utm_source,
            utmMedium: singleJobData.utm_medium,
            utmCategory: singleJobData.utm_category,
            utmContent: singleJobData.utm_content,
            jdLink: singleJobData?.jd_link,
            rejectReason:
              jobStatus === "REJECTED" ? singleJobData.reject_reason : "",
            postedBy:
              jobStatus === "POSTED" ? singleJobData.posted_by_name : "",
            postedDateTime:
              jobStatus === "POSTED"
                ? validateDateTime(singleJobData.posted_datetime)
                : "",
            rejectedBy:
              jobStatus === "REJECTED"
                ? singleJobData.posted_by_name || "XYZ"
                : null,
            rejectPersonName:
              jobStatus === "REJECTED"
                ? singleJobData.posted_by || "XYZ"
                : null,
          };
          temp.push(obj);
        }
        setJobData(temp);
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      title: "Job ID",
      field: "jobId",
      headerStyle: {
        position: "sticky",
        left: 0,
        zIndex: 1000,
      },
      cellStyle: {
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
        fontSize: 13,
      },
      filterCellStyle: {
        position: "sticky",
        left: 0,
        zIndex: 100,
        backgroundColor: "#fff",
      },
    },
    ...(value === 1
      ? [
          {
            title: "Posted Job",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <Button
                variant="outline-info"
                size="sm"
                style={{
                  fontSize: 13,
                }}
                onClick={() => viewJobDetail(row.postedJobId)}
              >
                View Job
              </Button>
            ),
          },
        ]
      : []),

    {
      title: "Company Name",
      field: "companyName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.companyName ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                row.companyWebsite.startsWith("http")
                  ? row.companyWebsite
                  : "http://" + row.companyWebsite,
                "_blank"
              )
            }
          >
            {row.companyName}
          </div>
        ) : null,
    },
    {
      title: "Contact Name",
      field: "contactName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Contact Email",
      field: "contactEmail",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.contactEmail ? (
          <div>
            {row.contactEmail}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.contactEmail)}
            />
          </div>
        ) : null,
    },
    {
      title: "Contact Phone",
      field: "contactPhone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.contactPhone ? (
          <div
            style={{
              minWidth: 150,
              maxWidth: 200,
            }}
          >
            {row.contactPhone}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.contactPhone)}
            />
          </div>
        ) : null,
    },
    {
      title: "Job Category",
      field: "jobCategory",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: jobCategorySearchObject,
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Job Title",
      field: "jobTitle",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job Type",
      field: "jobTypeName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Hiring Type",
      field: "hiringType",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job Payment Type",
      field: "jobPaymentType",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job Terms & Conditions",
      field: "jobTmc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Location",
      field: "location",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Work Experience",
      field: "workExperience",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Work Mode",
      field: "workLocationMode",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
    },
    {
      title: "Work From Home",
      field: "workFromHome",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "CTC Range",
      field: "ctc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Stipend",
      field: "stipend",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Creation Date",
      field: "createTime",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.createTime) &&
        Date.parse(term[1]) >= Date.parse(rowData.createTime),
    },

    {
      title: "Vacancies",
      field: "vacancy",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Campaign",
      field: "utmCampaign",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Source",
      field: "utmSource",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Medium",
      field: "utmMedium",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Category",
      field: "utmCategory",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Content",
      field: "utmContent",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job Link",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {row?.jdLink ? (
            <Button
              variant="outline-info"
              size="sm"
              style={{ whiteSpace: "nowrap", fontSize: 13 }}
              onClick={() => window.open(`${row?.jdLink}`, "_blank")}
            >
              Job Link
            </Button>
          ) : null}
        </div>
      ),
    },
    ...(value === 0
      ? [
          {
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <div>
                {
                  <Button
                    variant="outline-info"
                    size="sm"
                    style={{ whiteSpace: "nowrap", fontSize: 13 }}
                    onClick={() =>
                      window.open(
                        `/admin/public-job-posting/${row.jobId}/${row.companyId}/?job_title=${row.jobTitle}&company_name=${row.companyName}`,
                        "_blank"
                      )
                    }
                  >
                    Post Job
                  </Button>
                }
              </div>
            ),
          },
          {
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <div>
                {
                  <Button
                    variant="outline-danger"
                    size="sm"
                    style={{ whiteSpace: "nowrap", fontSize: 13 }}
                    onClick={(e) => loadRejectJobModal(e, row)}
                  >
                    Reject Job
                  </Button>
                }
              </div>
            ),
          },
        ]
      : []),
    ...(value === 2
      ? [
          {
            title: "Reject Reason",
            field: "rejectReason",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
        ]
      : []),
    ...(value === 2
      ? [
          {
            title: "Rejected By",
            field: "rejectedBy",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
        ]
      : []),
    ...(value === 2
      ? [
          {
            title: "Reject Person Name",
            field: "rejectPersonName",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
        ]
      : []),
    ...(value === 1
      ? [
          {
            title: "Posted By",
            field: "postedBy",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
          {
            title: "Posted Date Time",
            field: "postedDateTime",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            type: "datetime",
            searchable: false,
            filterComponent: (props) => (
              <DateRangePicker
                rangeDivider="to"
                value={props.columnDef.tableData.filterValue || dateRange}
                onChange={(e) => handleChangeDate(e, props)}
              />
            ),
            customFilterAndSearch: (term, rowData) =>
              Date.parse(term[0]) <= Date.parse(rowData.postedDateTime) &&
              Date.parse(term[1]) >= Date.parse(rowData.postedDateTime),
          },
        ]
      : []),
  ];

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
      updateList();
    }
  };

  const removeDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    updateList();
  };

  return (
    <div className="open__close__jobs">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="PENDING" {...a11yProps(0)} />
          <Tab label="POSTED" {...a11yProps(1)} />
          <Tab label="REJECTED" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {!loading ? (
        <TabPanel value={value} index={value} dir={theme.direction}>
          <br />
          <DateSelector
            dateRange={[startDate, endDate]}
            applyDateFilter={applyDateFilter}
            removeDateFilter={removeDateFilter}
          />
          <TableWithExport
            title={
              value === 0
                ? "Pending Public Jobs"
                : value === 1
                ? "Posted Public Jobs"
                : "Rejected Public Jobs"
            }
            data={
              value === 1
                ? jobData.sort(
                    (a, b) =>
                      new Date(b.postedDateTime) - new Date(a.postedDateTime)
                  )
                : jobData
            }
            columns={columns}
            fName={
              value === 0
                ? "Pending_Public_Jobs"
                : value === 1
                ? "Posted_Public_Jobs"
                : "Rejected_Public_Jobs"
            }
            fromJobs={true}
          />
        </TabPanel>
      ) : (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      )}

      {/* Reject Jobs Modal*/}
      {modalRejectJobData.jobId ? (
        <Modal
          show={open}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalRejectJobData.jobTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to reject this job ?</p>
            <br />
            <Form>
              <Form.Label>
                Kindly enter the reason to reject this job*
              </Form.Label>
              <Form.Group controlId="closeJobReason">
                <Form.Control
                  name="closeJobReason"
                  type="text"
                  placeholder="Reason to Reject this job goes here..."
                  required
                  onChange={(e) => {
                    setCloseReason(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={(e) => rejectParticularJob(modalRejectJobData.jobId)}
            >
              Reject Job
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}

export default PublicJobPosting;
