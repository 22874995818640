import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, TextField, Button } from "@material-ui/core";
import PaymentCard from '../../common/Payment/PaymentCard';
import { makeStyles } from "@material-ui/styles";
import SinglePlan from './PlanItem/SinglePlan';
import h1 from "../../../assets/svg/Plan/h1.svg";
import h2 from "../../../assets/svg/Plan/h2.svg";
import h4 from "../../../assets/svg/Plan/h4.svg";
import HireSmallCard from './PlanItem/HireSmallCard';
import { praveshAxiosPostReq } from '../../../util/baseApi';
import { Autocomplete } from '@material-ui/lab';
import { FailureAlert, SuccessAlert } from '../../../util/Notifications';
import CustomButtonCircularProgress from '../../loader';

const useStyles = makeStyles((theme) => ({

    btnCouponApply: {
        backgroundColor: "#007bff",
        marginTop: '1rem',
        color: 'white',
    },
    orderbox: {
        display: "flex",
        justifyContent: "space-around",

    },
    txtRequestOverview: {

        fontWeight: "400",
        marginTop: 8,
        marginBottom: 8,
    },
    gridPayNow: {
        marginTop: '1rem',
    },
    paymentSuccess: {
        height: 40,
        boxShadow: "none",
        borderRadius: "30px",
        fontSize: "15px",
        backgroundColor: "#007bff",
        outlineColor: "none",
    }


}));



const OrderSummary = ({ planDetails, setPlanDetails, companyPurchaseDetails, handleForward, setCompanyPurchaseDetails, setActiveStep }) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [priceOverview, setPriceOverview] = useState(null);

    const validateOrder = () => {
        if (!companyPurchaseDetails?.paymentMethod || !companyPurchaseDetails?.paymentType) {
            FailureAlert("Select Payment Details!");
            return false;
        }
        return true
    }


    const getCalculatedDetails = async () => {

        const endPoint = `/api/admin/company/plan/calculate/`;

        let payload = {};
        let orderDetails = {};

        if (companyPurchaseDetails?.planSelected) {

            const planSelectedArr = planDetails?.plan_list?.filter(item => (item?.selected === true));
            let selectedPlan = planSelectedArr[0];

            orderDetails = {
                count: 1,
                id: selectedPlan?.id,
                is_annual: companyPurchaseDetails?.planDuration === 'yearly',
                model: selectedPlan?.model,
                model_id: selectedPlan?.model_id,
                name: selectedPlan?.name,
                price: companyPurchaseDetails?.planDuration === 'yearly' ? selectedPlan?.annual_pricing : selectedPlan?.monthly_pricing,
            }

            payload = {
                plan_count: [orderDetails],
                company_id: companyPurchaseDetails?.companyId,

            }
        } else {

            let slotsSelected = [];
            for (let i in planDetails?.slot_list) {

                let slotsInSlab = planDetails?.slot_list[i]?.filter((item) => (item?.slotsBooked > 0));
                slotsSelected = [...slotsSelected, ...slotsInSlab]
            }

            let finalArrPayload = slotsSelected?.map((item) => ({
                id: item?.id,
                count: item?.slotsBooked,
                model: item?.model,
                model_id: item?.model_id,
                name: item?.name,
                price: parseInt(item?.price_per_slot),
            }))
            payload = {
                plan_count: finalArrPayload,
                company_id: companyPurchaseDetails?.companyId,

            }

        }
        setLoading(true);

        try {
            const res = await praveshAxiosPostReq(endPoint, payload);

            if (res?.data?.success) {
                setPriceOverview(res?.data?.data);
            }
            setLoading(false);

        } catch (error) {

            setLoading(false);
            throw (error)
        }

    }


    useEffect(() => {

        getCalculatedDetails();

    }, [planDetails?.slot_list]);

    const postPaymentMethodType = async (payload) => {

        const endPoint = `/api/admin/company/plan/purchase/`;

        try {
            const res = await praveshAxiosPostReq(endPoint, payload)

        } catch (error) {

            throw (error)
        }
    }

    const handleGenerateOrder = async () => {


        if (!validateOrder()) {
            return
        }
        let payload = {};
        let orderDetails = {};
        let endPoint = `/api/admin/company/plan/create_order/`;

        if (companyPurchaseDetails?.planSelected) {

            const planSelectedArr = planDetails?.plan_list?.filter(item => (item?.selected === true));
            let selectedPlan = planSelectedArr[0];

            orderDetails = {
                count: 1,
                id: selectedPlan?.id,
                is_annual: companyPurchaseDetails?.planDuration === 'yearly',
                model: selectedPlan?.model,
                model_id: selectedPlan?.model_id,
                name: selectedPlan?.name,
                price: companyPurchaseDetails?.planDuration === 'yearly' ? selectedPlan?.annual_pricing : selectedPlan?.monthly_pricing,

            }

            payload = {
                plan_count: [orderDetails],
                company_id: companyPurchaseDetails?.companyId,
                payment_type: companyPurchaseDetails?.paymentType?.id,
                payment_method: companyPurchaseDetails?.paymentMethod?.id,
            }
        } else {

            let slotsSelected = [];
            for (let i in planDetails?.slot_list) {

                let slotsInSlab = planDetails?.slot_list[i]?.filter((item) => (item?.slotsBooked > 0));
                slotsSelected = [...slotsSelected, ...slotsInSlab]
            }

            let finalArrPayload = slotsSelected?.map((item) => ({
                id: item?.id,
                count: item?.slotsBooked,
                model: item?.model,
                model_id: item?.model_id,
                name: item?.name,
                price: parseInt(item?.price_per_slot),
            }))
            payload = {
                plan_count: finalArrPayload,
                company_id: companyPurchaseDetails?.companyId,
                payment_type: companyPurchaseDetails?.paymentType?.id,
                payment_method: companyPurchaseDetails?.paymentMethod?.id,
            }

        }
        setLoading(true);

        try {

            const res = await praveshAxiosPostReq(endPoint, payload);

            if (res?.data?.success) {
                setPriceOverview(res?.data?.data);
                SuccessAlert(`Order Created with Order Id ${res?.data?.data?.order_id}`);

                const methodTypePayload = {
                    company_id: companyPurchaseDetails?.companyId,
                    getwork_order_id: res?.data?.data?.order_id,
                    payment_status: true,
                }
                postPaymentMethodType(methodTypePayload);
                setActiveStep((prevData) => (prevData + 1));
            }
            setLoading(false);


        } catch (error) {
            setLoading(false);
            throw (error);
        }

    }

    const paymentTypes = [{ id: "ONLINE", name: 'Online' },
    { id: "CASH", name: 'Cash' }, { id: "ONLINE_OTHER", name: 'Online other' },
    ]
    const paymentMethods = [{ id: "NEFT", name: 'NEFT' },
    { id: "CASH", name: 'Cash' }, { id: "UPI", name: 'UPI' }, { id: "RTGS", name: 'RTGS' },
    ]


    return (

        <>
            <Typography variant="h4" style={{ fontWeight: "600", mt: 2, mb: 2 }}>
                Hiring Request Summary
            </Typography>
            <br />
            <Box
                className={classes.orderbox}
            >
                <Grid container spacing={2} className={classes.orderbox}  >
                    <Grid md={5}>
                        <Typography variant="h5" className={classes.txtRequestOverview}
                        // style={{ fontWeight: "400", mt: 2, mb: 2 }}
                        >
                            Request overview
                        </Typography>

                        {companyPurchaseDetails?.planSelected ?
                            <>
                                {planDetails?.plan_list?.map(
                                    (value, index) =>
                                        value?.name !== "Enterprise"
                                        && value?.selected
                                        && (
                                            <Grid item md={8} style={{ marginBottom: "1rem" }}>

                                                <SinglePlan
                                                    index={index}
                                                    heading={value?.name}
                                                    monthlyPrice={value?.monthly_pricing}
                                                    annualPrice={value?.annual_pricing}
                                                    benefits={value?.details?.benefit_list}
                                                    alignment={companyPurchaseDetails?.planDuration}
                                                    handleForward={handleForward}
                                                    planDetails={planDetails}
                                                    setPlanDetails={setPlanDetails}
                                                />
                                            </Grid>
                                        )
                                )}
                            </> : null}

                        <>
                            {Object.keys(planDetails?.slot_list)?.map((item) => {
                                return item === "Intern hiring" ||
                                    item === "Non-Tech hiring" ||
                                    item === "Tech hiring" ? (
                                    <>
                                        {planDetails?.slot_list[item]?.map(
                                            (val, index) =>
                                                val?.slotsBooked > 0 && (
                                                    <Grid xs={12} md={12} style={{ marginBottom: '2rem' }}>
                                                        <HireSmallCard

                                                            type={
                                                                item === "Tech hiring"
                                                                    ? "techHiring"
                                                                    : item === "Non-Tech hiring"
                                                                        ? "nonTechHiring"
                                                                        : "internHiring"
                                                            }
                                                            index={index}
                                                            image={index === 0 ? h1 : h2}
                                                            title={val.name}
                                                            subtitle={val.details}
                                                            amount={val.price_per_slot}
                                                            slots={val?.slotsBooked ? val?.slotsBooked : 0}
                                                            id={val?.id}
                                                            setPlanDetails={setPlanDetails}
                                                            planDetails={planDetails}

                                                        />
                                                    </Grid>
                                                )
                                        )}
                                    </>
                                ) : (
                                    <></>
                                );
                            })}
                        </>


                        {/* <Grid xs={12} md={12}>
                            <Box
                                style={{
                                    backgroundColor: '#007bff',
                                    borderRadius: "10px",
                                    padding: 16,
                                }}
                            >
                                <Typography variant="h6" style={{ color: "#fff" }}>
                                    Apply Coupons
                                </Typography>

                                <TextField
                                    placeholder="Enter Coupon Code"
                                    fullWidth
                                    style={{
                                        backgroundColor: "#fff",
                                        borderRadius: "5px",
                                        marginTop: 2,
                                        outlineColor: 'none',
                                    }}
                                />
                            </Box>
                            <Button className={classes.btnCouponApply} >

                                Apply
                            </Button>
                        </Grid> */}
                        <Grid xs={12} md={6} className={classes.gridPayNow} style={{ display: "grid" }}>

                            <Autocomplete
                                id="select-plan-type"
                                options={paymentTypes}
                                value={companyPurchaseDetails?.paymentType}
                                getOptionLabel={(item) => item?.name}
                                onChange={(e, item) => {

                                    const paymentType = item ? item : null;
                                    setCompanyPurchaseDetails((prevData) => ({ ...prevData, paymentType: paymentType }))
                                }
                                }
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        size="small"
                                        label="Select Payment Type"
                                        variant="outlined"
                                        style={{ backgroundColor: "white", }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={6} className={classes.gridPayNow} style={{ display: "grid" }}>
                            <Autocomplete
                                id="select-plan-type"
                                options={paymentMethods}
                                value={companyPurchaseDetails?.paymentMethod}
                                getOptionLabel={(item) => item?.name}
                                onChange={(e, item) => {

                                    const paymentMethod = item ? item : null;
                                    setCompanyPurchaseDetails((prevData) => ({ ...prevData, paymentMethod: paymentMethod }))
                                }
                                }
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        size="small"
                                        label="Select Payment Method"
                                        variant="outlined"
                                        style={{ backgroundColor: "white", }}
                                    />
                                }
                            />

                        </Grid>

                        <Grid xs={12} md={6} className={classes.gridPayNow} style={{ display: "grid" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: '#303F9F', color: 'white' }}
                                // disabled={loading ? true : false}
                                onClick={() => {
                                    handleGenerateOrder()
                                }}
                                className={classes.paymentSuccess}


                            >
                                {loading ? <CustomButtonCircularProgress /> : "Generate Order"}
                            </Button>
                        </Grid>

                    </Grid>
                    <Grid md={5}>
                        <Typography variant="h5" style={{ fontWeight: "400", mt: 2, mb: 2 }}>
                            Price overview
                        </Typography>
                        <Box style={{ marginTop: "24px" }}>

                            <PaymentCard data={priceOverview} loading={loading} />
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </>

    )
}

export default OrderSummary