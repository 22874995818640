import {
  Button,
  Dialog,
  DialogContent,
  Grid, IconButton, InputAdornment, TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from "@material-ui/lab";
import {  default as React, useEffect, useState } from "react";
import { DateSelector } from "../../../components/common/DateSelector";
import { niyuktiAxiosCall, praveshAxiosCall } from "../../../util/apiCall";
import { FailureAlert } from "../../../util/Notifications";


const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  chipsection: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "0px",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "10px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function JobTrackFilter(props) {
  const classes = useStyles();
  const fullWidth = true;
  const maxWidth = "xs";

  const [vendor, setVendor] = React.useState([]);
  const [recruiter, setRecruiter] = React.useState([]);
  const [hr, setHr] = React.useState([]);

  const [selectedVendorList, setSelectedVendorList] = useState(
    props?.filters?.selectedVendorList
  );
  const [selectedRecruiterList, setSelectedRecruiterList] = useState(
    props?.filters?.selectedRecruiterList
  );
  const [selectedHrList, setSelectedHrList] = useState(
    props?.filters?.selectedHrList
  );

  const [searchText1, setSearchText1] = useState(props?.filters?.searchTerm);

  const [dateFilter, setDateFilter] = useState(
    props?.filters?.advanceFilterDateValue
  );

  useEffect(() => {
    const getVendorList = () => {
      praveshAxiosCall
        .get(`/api/admin/vendor_lists/`)
        .then((res) => {
          if (res?.data?.success) {
            setVendor(res?.data?.data);
          } else {
            // setAllApiSuccess({status: -1, message: res.error})
          }
        })
        .catch((err) => {
          // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
        });
    };

    const getRecruiterList = () => {
      praveshAxiosCall
        .get(`/api/admin/admin_user_lists/`)
        .then((res) => {
          if (res?.data?.success) {
            setRecruiter(res?.data?.data);
          } else {
            // setAllApiSuccess({status: -1, message: res.error})
          }
        })
        .catch((err) => {
          // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
        });
    };

    const getHrList = () => {
      niyuktiAxiosCall
        .get(`job/admin/company_hr_list/?job_id=${props?.jobId}`)
        .then((res) => {
          if (res?.data?.success) {
            setHr(res?.data?.data);
          } else {
            FailureAlert("Error getting Hrs name");
          }
        })
        .catch((err) => {
          FailureAlert("Error :", err);
          // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
        });
    };

    getVendorList();
    getRecruiterList();
    getHrList();
  }, []);

  const handleClose = () => {
    props.handleClose();
  };

  const checkValidation = () => {
    if (selectedVendorList?.length < 1 && selectedRecruiterList?.length < 1) {
      FailureAlert("Please select atleast one filter or a date range");
      return false;
    }

    return true;
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        //onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6">Filters</Typography>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedVendorList}
              multiple
              onChange={(e, item) => {
                setSelectedVendorList(item);
              }}
              id="role345"
              options={vendor}
              getOptionLabel={(option) =>
                `${option.vendor_id}-${option.vendor_name}`
              }
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.vendor_id}-${option.vendor_name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}> Select Vendor </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedRecruiterList}
              multiple
              onChange={(e, item) => {
                setSelectedRecruiterList(item);
              }}
              id="role345"
              options={recruiter}
              getOptionLabel={(option) => `${option.id}-${option.admin_name}`}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.admin_name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}> Select Recruiters </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedHrList}
              multiple
              onChange={(e, item) => {
                setSelectedHrList(item);
              }}
              id="role345"
              options={hr}
              getOptionLabel={(item) =>
                item.charAt(0).toUpperCase() + item.slice(1)
              }
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{option}</span>;
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={<span style={{ fontSize: 13 }}> Select Hrs </span>}
                    variant="outlined"
                  />
                );
              }}
            />
          </div>
          
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <TextField
          placeholder="Search Name,Email,Phone"
          variant="outlined"
          size="small"
          onChange={(event) => {
            setSearchText1(event.target.value);
          }}
          style={{width: "100%"}}
          value={searchText1}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" style={{cursor: "pointer"}}>
                <IconButton
                onClick={() => setSearchText1("")}
                size="small"
              >
                <ClearIcon />
              </IconButton>
              </InputAdornment>
            ),
          }}
        />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <DateSelector
              dateRange={[props?.startDate, props?.endDate]}
              setAdvanceFilterDateValue={setDateFilter}
              advanceFilterDateValue={dateFilter}
              isAdvanceFilter={true}
              clearDates={true}
              justifyContent="space-between"
              removeDateFilter={() => {
                setDateFilter([null, null]);
              }}
              label={"Application Date"}
            />
          </div>

          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ height: "45px", display: "flex" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ height: "45px", display: "flex" }}
                onClick={() => {
                  // if (checkValidation()) {
                  props?.setFilters({
                    ...props?.filters,
                    selectedVendorList: selectedVendorList,
                    selectedRecruiterList: selectedRecruiterList,
                    selectedHrList: selectedHrList,
                    advanceFilterDateValue: dateFilter,
                    searchTerm: searchText1
                  });
                  props?.handleClose();
                  // }
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
