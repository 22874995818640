import { Button, InputBase, Paper, Typography, Chip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";
import { useHistory } from "react-router-dom";
import UseAxiosApi from "../../../Api/UseAxiosApi";
import { niyuktiUrl } from "../../../constants";
import CloseIcon from "@material-ui/icons/Close";
import { FailureAlert } from "../../../util/Notifications";

export default function SearchMultipleJobs({
  setSourceFromJob,
  setJobFromSourceJob,
  setLoading,
  searchSelected,
  setSearchSelected,
  from,
  companyId = 230,
  selectedJobs,
  setSelectedJobs,
  handleSubmitQuery
}) {

  const history = useHistory();

  const [searchLoading, setSearchLoading] = React.useState(false);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [searchData, setSearchData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  
  const searchHelper = (data) => {
    setSearchData(data?.data?.results);
  };

  const { loading, error, apiCall } = UseAxiosApi({
    url: `${niyuktiUrl}/job/admin/company/job/info/list/?search=${searchQuery}&compact=1`,
    method: "get",
    mountingCallFlag: false,
    setResponse: searchHelper,
  });

  const getSearch = () => {
    apiCall(null, true);
  };

  React.useEffect(() => {
    if (searchQuery && /* userDetails?.company */ companyId) {
      getSearch();
    }
  }, [searchQuery]);

  const debounce = (func) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const handleChange = (e) => {
    setSearchData([]);
    setSearchQuery(e);
  };

  const optimisedHandleChange = React.useCallback(debounce(handleChange), []);

  React.useEffect(() => {
    if (searchSelected) {
      setSourceFromJob(searchSelected);
      setJobFromSourceJob(searchSelected.job_id);
    }
  }, [searchSelected]);

  React.useEffect(() => {
    if (searchQuery) {
      setOpenAutocomplete(true);
    }
  }, [searchQuery]);

  const handleDelete = (id) => {
    setSelectedJobs((selectedJobs) => selectedJobs.filter((chip) => chip.job_id !== id));
  };

  return (
      <>
    <Paper
      component="form"
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
        border: "1px solid #E2E6EA",
        minWidth: "340px",
        height: "40px",
        justifyContent: "space-between",
        borderRadius: "8px",
      }}
    >
      <div
        style={{ width: from === "Dialog" ? "96%" : "80%", display: "grid" }}
      >
        <Autocomplete
          style={{
            display: "inline-block",
            "& input": {
              //width: 200,
              bgcolor: "background.paper",
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          }}
          multiple
          open={openAutocomplete}
          noOptionsText={
            <div
              style={{ fontStyle: "italic", display: "flex" }}
              onClick={() => history?.push("/post-job")}
            >
              <Typography
                sx={{ fontStyle: "italic" }}
              >{`Job not posted yet ?`}</Typography>
              <Typography
                color="primary"
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  fontStyle: "italic",
                }}
              >
                {" "}
                &nbsp; Post Job Now{" "}
              </Typography>
            </div>
          }
          loading={searchLoading}
          value={selectedJobs}
          onChange={(event, newValue) => {
            let ind = selectedJobs?.findIndex(item => item?.job_id === newValue?.job_id);
            if (ind !== -1){
                FailureAlert("Already Added");
                return;
            }
            setSelectedJobs(newValue);
            // setOpenAutocomplete(false);
          }}
          onInputChange={(ev, value) => {
            // dont fire API if the user delete or not entered anything
            if (value !== "" || value !== null) {
              optimisedHandleChange(value);
            }
          }}
          onClose={() => {
            setOpenAutocomplete(false);
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          PaperComponent={Paper}
          id="free-solo-with-text-demo"
          options={searchData ? searchData : []}
          getOptionLabel={(option) => {
            return `${option?.job_title} | ${option?.job_id}`;
          }}
          //freeSolo
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <InputBase
                {...params}
                inputProps={{
                  ...params.inputProps,

                  style: {
                    border: "none",
                    marginLeft: "10px",
                    minWidth: "100%",
                  },
                }}
                margin="normal"
                variant="outlined"
                placeholder="Type & select posted Job title"
                // value={selectedJobs}
              />
            </div>
          )}
        />
        
      </div>
      {from === "Dialog" ? (
        <></>
      ) : (
        <div style={{ /* width: "20%", */ position: "absolute", right: "0" }}>
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            variant="contained"
            style={{
              display: "flex",
              borderRadius: "8px",
              height: "40px",
              fontSize: "14px",
              marginRight: "-1px",
              // marginLeft: "-63px",
              boxShadow: "none",
            }}
            aria-label="directions"
            onClick={() => {
              if (selectedJobs?.length > 0) handleSubmitQuery("", "multipleJobs");
            }}
          >
            Search
          </Button>
        </div>
      )}
    </Paper>
    <div style={{marginTop: "20px"}}>
    {selectedJobs && selectedJobs?.map((item => {
        return (
            <li key={item?.job_id} style={{marginTop: "10px"}}>
              <Chip
                variant="outlined"
                color="primary"
                size="small"
                onDelete={() => handleDelete(item?.job_id)}
                deleteIcon={<CloseIcon />}
                label={`${item?.job_title} | ${item?.job_id}`}
              />
            </li>
        )
    }))}
    </div>
    </>
  );
}
