import {
    Box,
    Typography,
    useTheme
} from "@material-ui/core";
import React, { useState } from "react";
//import { useSelector } from "react-redux";
import { ReactComponent as Vactor } from "../../../assets/svg/customNoSearch.svg";
import SearchMultipleJobs from "./SearchMultipleJobs";
  
  function SourceFromJobComponent({
    value,
    setShowSourceResult,
    handleSubmitQuery,
    setSourceFromJob,
    setJobFromSourceJob,
    sourceFromJob,
    searchSelected,
    setSearchSelected,
    submitLoading,
    selectedJobs,
    setSelectedJobs
  }) {
    
    const [loading, setLoading] = useState(false);
  
    const theme = useTheme();
    return (
      <>
        <Box style={{ width: "60%", marginTop: 20 }}>
          <SearchMultipleJobs
            setSourceFromJob={setSourceFromJob}
            setJobFromSourceJob={setJobFromSourceJob}
            setLoading={setLoading}
            searchSelected={searchSelected}
            setSearchSelected={setSearchSelected}
            selectedJobs={selectedJobs}
            setSelectedJobs={setSelectedJobs}
            handleSubmitQuery={handleSubmitQuery}
          />
        </Box>
        {!sourceFromJob && selectedJobs?.length === 0 && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                width: 500,
                // boxShadow: "0px 0px 5px 2px #00000017",
                //    borderRadius: "8px",
                // p: 2,
                marginTop: 2,
                // ml: 5,
                // backgroundColor: "#fff",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  aligncontent: "center",
                }}
              >
                <Vactor
                  style={{ height: "200px" }}
                  fill={theme.palette.primary.main}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    /* color: "#12DC00", */ fontWeight: "600",
  
                    marginTop: 2,
                  }}
                >
                  Type And Select Multiple Jobs...
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: "#6C757D",
                    padding: "20px 10px 0px 10px",
                    textAlign: "center",
                  }}
                >
                  Get all the previously applied candidate's details with the status on the jobs.
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: "#6C757D",
                    textAlign: "center",
                  }}
                >
                  We focus on candidate expertise & recruiters productivity.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  }
  
  export default SourceFromJobComponent;
  