import {
  Box,
  Chip,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router";
import calender from "../../assets/images/calender.png";
import map from "../../assets/images/map.png";
import money from "../../assets/images/money.png";
import sandclock from "../../assets/images/sandclock.png";
import suitcase from "../../assets/images/suitcase.png";
import user from "../../assets/images/user-default.png";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling, requestNiyuktiOptions } from "../../constants";
import "../../css/ViewJobPublicPage.css";
import { apiNiyuktiCall } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import { printDate, printNumber } from "../../util/printNumber";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  jobCat: {
    fontWeight: "bolder",
    border: "2px solid #FF0000",
    color: "#FF0000",
    marginLeft: "1rem",
  },
  root2: {
    fontWeight: "bolder",
    border: "2px solid red",
    color: "red",
  },
  root1: {
    fontWeight: "bolder",
    border: "2px solid #26a95b",
    color: "#26a95b",
  },
  root: {
    fontWeight: "bolder",
    border: "2px solid #3282C4",
    color: "#3282C4",
    marginLeft: "1rem",
  },
  root3: {
    fontWeight: "bolder",
    border: "2px solid #63C456",
    color: "#63C456",
  },
  "@media only screen and (max-width: 768px)": {},
});

const JobViewStyles = {
  Page: {
    height: "auto",
    background: "#E5E5E5",
  },
  Logo: {
    height: "auto",
    width: "40%",
  },
  Logo1: {
    borderRadius: "50%",
    height: "100px",
    width: "100px",
  },
};

const ViewJobPublicPage = () => {
  const { job_id } = useParams();

  const [jobData, setJobData] = useState();
  const [specializationKey, setSpecializationKey] = useState("name");
  const [qualificationData, setQualificationData] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    apiNiyuktiCall(
      `/job/?job_id=${btoa(job_id.toString())}`,
      requestNiyuktiOptions
    ).then((res) => {
      if (res?.success) {
        setJobData(res?.data);
        const temp = {};
        if (!res.data.is_posted_by_college) {
          if (
            res.data.hiring_type === "ON CAMPUS" &&
            res.data.eligibility_criteria?.courses_rank
          ) {
            setSpecializationKey("specialization_name");
            res.data.eligibility_criteria.courses_rank.forEach((item) => {
              if (temp[item.degree_name] === undefined)
                temp[item.degree_name] = [{ ...item }];
              else temp[item.degree_name].push({ ...item });
            });
            setQualificationData(temp);
          } else if (res.data.eligibility_criteria?.courses) {
            res.data.eligibility_criteria.courses.forEach((item) => {
              if (temp[item.degree_name] === undefined)
                temp[item.degree_name] = [{ ...item }];
              else temp[item.degree_name].push({ ...item });
            });
            setQualificationData(temp);
          }
        } else {
          res.data.eligibility_criteria.courses.forEach((item) => {
            if (temp[item.degree_name] === undefined)
              temp[item.degree_name] = [{ ...item }];
            else temp[item.degree_name].push({ ...item });
          });
          setQualificationData(temp);
        }
      } else FailureAlert(res.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <Layout> */}
      {jobData ? (
        <>
          <Hidden smDown>
            <div className="job-view" style={JobViewStyles.Page}>
              <div className="row p-5">
                <div className="col-md-3 col-12">
                  <div
                    className="card"
                    style={{ position: "fixed", width: "22%" }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col my-auto py-3">
                          {jobData.company && jobData.company.company_logo ? (
                            <img
                              style={JobViewStyles.Logo}
                              alt=""
                              src={jobData.company.company_logo}
                            />
                          ) : (
                            <img
                              style={JobViewStyles.Logo1}
                              alt=""
                              src={user}
                            />
                          )}
                        </div>
                      </div>
                      <div className="dropdown-divider" />
                      <div className="text-left my-3">
                        <p className="fs-18 fw-500 mb-0">
                          <b>{jobData.job_title}</b>
                        </p>
                        <p className="fs-16 gray-2">
                          <b>
                            <i>
                              {jobData.company
                                ? jobData.company.company_name
                                : jobData.company_details}
                            </i>
                          </b>
                        </p>
                      </div>
                      <div className="text-left my-3 gray-2 fs-14">
                        <a
                          id="view_job_ahref"
                          target="blank"
                          href={
                            jobData.company && jobData.company.company_website
                          }
                        >
                          {jobData.company &&
                            jobData.company.company_website && (
                              <p style={{ color: "#0645AD" }}>
                                <i className="fas fa-globe mr-2 " />
                                {jobData.company &&
                                  jobData.company.company_website}
                              </p>
                            )}
                        </a>
                        <p>
                          <i className="fas fa-briefcase mr-2" />
                          {jobData.employment_type_name}
                        </p>
                        <p>
                          <i className="fas fa-map-marker-alt mr-2" />
                          {jobData.job_location.length > 0 ? (
                            jobData.job_location.map((item, i) => (
                              <Fragment key={i}>
                                {i !== jobData.job_location.length - 1 ? (
                                  <>{item.city}, </>
                                ) : (
                                  <>{item.city}</>
                                )}
                              </Fragment>
                            ))
                          ) : (
                            <>PAN India</>
                          )}
                        </p>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12">
                  <div className="card gw-card w-100">
                    <div className="card-body mx-5">
                      <div className="row my-2">
                        <div className="col-md-6 col-12 text-left my-auto">
                          <h4>
                            <b>
                              {jobData.company
                                ? jobData.company.company_name
                                : jobData.company_details}
                            </b>
                          </h4>
                        </div>
                        <div className="col-md-2 text-right my-auto">
                          {jobData.job_status !== "CLOSE" &&
                          localStorage.getItem("college_id")
                            ? jobData.college[0].up_coming_state.code === 1 && (
                                <Chip
                                  label={"UPCOMING JOB"}
                                  variant="outlined"
                                  className={classes.root1}
                                  color="primary"
                                />
                              )
                            : jobData.up_coming_state.code === 1 && (
                                <Chip
                                  label={"UPCOMING JOB"}
                                  variant="outlined"
                                  className={classes.root1}
                                  color="primary"
                                />
                              )}
                          {jobData.job_status === "CLOSE" && (
                            <Chip
                              label={"JOB CLOSED"}
                              variant="outlined"
                              className={classes.root2}
                              color="primary"
                            />
                          )}
                        </div>
                        {jobData.is_posted_by_college === true && (
                          <div className="col-md-2 text-right my-auto">
                            {jobData.is_posted_by_college === true && (
                              <Chip
                                label={"POSTED BY COLLEGE"}
                                variant="outlined"
                                className={classes.root}
                                color="primary"
                              />
                            )}
                          </div>
                        )}
                        <div className="col-md-2 text-right my-auto">
                          <Chip
                            className={classes.root}
                            label={jobData.hiring_type + " HIRING"}
                            color="primary"
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div
                        className="row my-2"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className="col-md-6 col-12 text-left my-auto">
                          <h4>
                            {" "}
                            <b>{jobData.job_title}</b>{" "}
                          </h4>
                        </div>
                        <section className="plan-section-pills">
                          {jobData?.job_plan?.model_name &&
                            jobData?.job_plan?.name && (
                              <Chip
                                className={classes.root}
                                label={`${jobData?.job_plan?.model_name} ${jobData?.job_plan?.name}`}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          {jobData?.job_category && (
                            <Chip
                              className={classes.jobCat}
                              label={jobData?.job_category}
                              color="primary"
                              variant="outlined"
                            />
                          )}
                        </section>

                        {/* {jobData.is_posted_by_admin && (
                            <div className="col-md-2 text-right my-auto">
                              {jobData.is_posted_by_admin && (
                                <Chip
                                  label={"POSTED BY ADMIN"} variant="outlined"
                                  className={classes.root3} color="primary"
                                />
                              )}
                            </div>
                          )} */}
                      </div>
                      <div>
                        <h6>
                          <b> Skills </b>
                        </h6>
                        {jobData.eligibility_criteria.skills.map(
                          (item, ind) => (
                            <span className="badge skill-chip mx-2" key={ind}>
                              {item.skill_name}
                            </span>
                          )
                        )}
                      </div>
                      <br />
                      <div>
                        <h6>
                          <b> Evaluation Skills </b>
                        </h6>

                        {jobData.eligibility_criteria.key_skills_evaluation.map(
                          (item, ind) => (
                            <span className="badge skill-chip mx-2" key={ind}>
                              {" "}
                              {item.skill_name}{" "}
                            </span>
                          )
                        )}
                      </div>
                      <br />
                      {jobData.ctc_min && jobData.ctc_max ? <hr /> : ""}
                      <div className="row my-2">
                        {jobData.ctc_min && jobData.ctc_max ? (
                          <div className="col-md-6 text-left">
                            <h6>
                              <img alt="" src={money} height="20px" />
                              &nbsp; Compensation
                            </h6>
                            <p className="fs-14 gray-2 m-0">
                              ₹ {printNumber(jobData.ctc_min)} -{" "}
                              {printNumber(jobData.ctc_max)}{" "}
                              {jobData.salary_payment_type}
                            </p>
                          </div>
                        ) : null}
                        {jobData.equity_min !== 0 && jobData.equiy_max !== 0 ? (
                          <div className="col-md-6 text-left">
                            <h6>Equity</h6>
                            <p className="fs-14 gray-2 m-0">
                              {" "}
                              {jobData.equity_min}% - {jobData.equity_max}%{" "}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <hr />
                      <div className="row my-3 text-left pl-2">
                        <div className="col-md-4 col-12">
                          <div className="row pl-2">
                            <h6>
                              <b>
                                <img alt="" src={map} height="18px" /> Office
                                Location
                              </b>
                            </h6>
                          </div>
                          <div className="row pl-2">
                            <p className="gray-2 fs-14">
                              <>
                                {jobData.job_location.length > 0 &&
                                jobData.work_from_home
                                  ? "  Hybrid,  "
                                  : null}
                                {jobData.job_location.length > 0 ? (
                                  jobData.job_location.map((item, i) => (
                                    <Fragment key={i}>
                                      {i !== jobData.job_location.length - 1 ? (
                                        <>{item.city}, </>
                                      ) : (
                                        <>{item.city}</>
                                      )}
                                    </Fragment>
                                  ))
                                ) : (
                                  <>
                                    {jobData.work_from_home
                                      ? "Work From Home"
                                      : "PAN India"}
                                  </>
                                )}
                              </>
                            </p>
                          </div>
                        </div>
                        {jobData.job_type_name && (
                          <div className="col-md-4 col-12">
                            <div className="row">
                              <h6>
                                <b>
                                  <img alt="" src={suitcase} height="18px" />{" "}
                                  &nbsp; Job type
                                </b>
                              </h6>
                            </div>
                            <div className="row">
                              <p className="gray-2 fs-14">
                                {" "}
                                {jobData.job_type_name}{" "}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="col-md-4 col-12">
                          <div className="row">
                            <h6>
                              <b>
                                {" "}
                                <img alt="" src={calender} height="18px" />{" "}
                                &nbsp; Apply Duration{" "}
                              </b>
                            </h6>
                          </div>
                          <div className="row">
                            <p className="gray-2 fs-14">
                              {printDate(jobData.apply_start_date)}
                              {jobData.apply_end_date ? (
                                <> to {printDate(jobData.apply_end_date)}</>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row my-3 text-left pl-2">
                        <div className="col-md-4 col-12">
                          <div className="row pl-2">
                            <h6>
                              <b>
                                <img alt="" src={sandclock} height="20px" />{" "}
                                &nbsp; Employment Type
                              </b>
                            </h6>
                          </div>
                          <div className="row pl-2">
                            <p className="gray-2 fs-14">
                              {jobData.employment_type_name && (
                                <span>{jobData.employment_type_name}</span>
                              )}
                            </p>
                          </div>
                        </div>
                        {jobData.job_type_name === "Internship" && (
                          <div className="col-md-4 col-12">
                            <div className="row">
                              <h6>
                                {" "}
                                <b>PPO Offered</b>{" "}
                              </h6>
                            </div>
                            <div className="row">
                              <p className="gray-2 fs-14">
                                {" "}
                                {jobData.ppo === true ? "Yes" : "No"}{" "}
                              </p>
                            </div>
                          </div>
                        )}
                        {jobData.job_type_name === "Internship" &&
                          jobData.ppo &&
                          jobData.stipend && (
                            <div className="col-md-4 col-12">
                              <div className="row">
                                <h6>
                                  <img alt="" src={money} height="20px" />
                                  &nbsp; <b>Stipend</b>
                                </h6>
                              </div>
                              <div className="row">
                                <p className="gray-2 fs-14">
                                  {" "}
                                  ₹ {jobData.stipend || 0} Per Month
                                </p>
                              </div>
                            </div>
                          )}
                        {jobData.service_bond !== 0 && (
                          <div className="col-md-4 col-12">
                            <div className="row">
                              <h6>
                                {" "}
                                <b>Service Bond</b>{" "}
                              </h6>
                            </div>
                            <div className="row">
                              <p className="gray-2 fs-14">
                                {" "}
                                {jobData.service_bond} Months{" "}
                              </p>
                            </div>
                          </div>
                        )}
                        {jobData.service_bond === 0 && (
                          <div className="col-md-4 col-12">
                            <div className="row">
                              <h6>
                                {" "}
                                <b>Service Bond</b>{" "}
                              </h6>
                            </div>
                            <div className="row">
                              <p className="gray-2 fs-14"> None </p>
                            </div>
                          </div>
                        )}
                        <div className="col-md-4 col-12">
                          <div className="row">
                            <h6>
                              <b>
                                {" "}
                                <img alt="" src={calender} height="18px" />{" "}
                                &nbsp; Job Duration{" "}
                              </b>
                            </h6>
                          </div>
                          <div className="row">
                            <p className="gray-2 fs-14">
                              {printDate(jobData.job_duration_start)}
                              {jobData.job_duration_end ? (
                                <> to {printDate(jobData.job_duration_end)}</>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      {jobData.hiring_type === "ON CAMPUS" &&
                        localStorage.getItem("token") && (
                          <div className="row my-5 text-left pl-2">
                            <h6>
                              {" "}
                              <b>On Campus Hiring - College Information</b>{" "}
                            </h6>
                            <br />
                            <div className="col-md-12">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>
                                        {" "}
                                        College{" "}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {" "}
                                        Degrees{" "}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {" "}
                                        Specializations{" "}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {" "}
                                        Status{" "}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {" "}
                                        Apply Start Date{" "}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {" "}
                                        Apply End Date{" "}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {jobData.college.map((colgObj, ind) => (
                                      <StyledTableRow key={ind}>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {" "}
                                          {colgObj.name}{" "}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {colgObj.preferences.map(
                                            (prefObj, ind) => (
                                              <Fragment key={ind}>
                                                <ul>
                                                  <li>
                                                    {" "}
                                                    {prefObj.degree_name}
                                                  </li>
                                                </ul>
                                              </Fragment>
                                            )
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {colgObj.preferences.map((prefObj) =>
                                            prefObj.specialization.map(
                                              (spzl, ind) => (
                                                <Fragment key={ind}>
                                                  <ul>
                                                    <li>
                                                      {" "}
                                                      {spzl.specialization_name
                                                        ? spzl.specialization_name
                                                        : spzl.name}
                                                    </li>
                                                  </ul>
                                                </Fragment>
                                              )
                                            )
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.is_rejected &&
                                            !colgObj.is_approved && (
                                              <>Rejected</>
                                            )}
                                          {!colgObj.is_rejected &&
                                            colgObj.is_approved && (
                                              <>Accepted</>
                                            )}
                                          {!colgObj.is_rejected &&
                                            !colgObj.is_approved && (
                                              <>Pending</>
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.apply_start_date}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.apply_end_date}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        )}

                      <div className="text-left my-5">
                        <h6>
                          <b> Job Description </b>
                        </h6>
                        {ReactHtmlParser(
                          jobData.job_description.toString("html")
                        )}
                      </div>
                      {jobData?.service_bond !== 0 && (
                        <>
                          <div className="col-md-4 col-12">
                            <div className="row">
                              <h6>
                                {" "}
                                <b>Service Bond</b>{" "}
                              </h6>
                            </div>
                            <div className="row">
                              <p className="gray-2 fs-14">
                                {" "}
                                {jobData?.service_bond} Months{" "}
                              </p>
                            </div>
                          </div>
                          <section>
                            {ReactHtmlParser(
                              jobData?.bond_condition?.toString("html")
                            )}
                          </section>
                        </>
                      )}
                      <div className="text-left my-5">
                        <h6>
                          <b> Preferences </b>
                        </h6>
                        <ul className="fs-16 gray-2 m-0 py-2 px-4">
                          <li>
                            {" "}
                            <b>Work Experience:</b>{" "}
                            {jobData.eligibility_criteria.experience[0]}
                          </li>
                          <li>
                            {" "}
                            <b>Eligible Graduation Years:</b>{" "}
                            {jobData.eligibility_criteria.graduation_years.map(
                              (item, i) => (
                                <Fragment key={i}>
                                  {" "}
                                  {i !==
                                  jobData.eligibility_criteria.graduation_years
                                    .length -
                                    1 ? (
                                    <span className="mr-2">{item},</span>
                                  ) : (
                                    <span className="mr-2">{item}</span>
                                  )}
                                </Fragment>
                              )
                            )}
                          </li>
                          {(!localStorage.getItem("gw_token") ||
                            jobData.hiring_type === "OFF CAMPUS") && (
                            <li>
                              <b> Qualifications Required: </b>{" "}
                              {!jobData.is_posted_by_college ? (
                                <span className="mr-2">
                                  {jobData.hiring_type === "ON CAMPUS"
                                    ? Object.keys(qualificationData).map(
                                        (item, ind) => (
                                          <Fragment key={ind}>
                                            <ul>
                                              <li>
                                                {" "}
                                                <b>{item} &nbsp; </b>(
                                                {qualificationData[item].map(
                                                  (item1, i) => (
                                                    <>
                                                      {i !==
                                                      qualificationData[item]
                                                        .length -
                                                        1 ? (
                                                        <>
                                                          {" "}
                                                          {
                                                            item1[
                                                              specializationKey
                                                            ]
                                                          }
                                                          {", "}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          {
                                                            item1[
                                                              specializationKey
                                                            ]
                                                          }
                                                        </>
                                                      )}
                                                    </>
                                                  )
                                                )}
                                                )
                                              </li>
                                            </ul>
                                          </Fragment>
                                        )
                                      )
                                    : Object.keys(qualificationData).map(
                                        (item, ind) => (
                                          <Fragment key={ind}>
                                            <ul>
                                              <li>
                                                {" "}
                                                <b>{item}&nbsp; </b>(
                                                {qualificationData[item].map(
                                                  (item1, i) => (
                                                    <Fragment key={i}>
                                                      {i !==
                                                      qualificationData[item]
                                                        .length -
                                                        1 ? (
                                                        <>
                                                          {" "}
                                                          {item1.name}
                                                          {", "}{" "}
                                                        </>
                                                      ) : (
                                                        <>{item1.name}</>
                                                      )}
                                                    </Fragment>
                                                  )
                                                )}
                                                )
                                              </li>
                                            </ul>
                                          </Fragment>
                                        )
                                      )}
                                </span>
                              ) : (
                                <span className="mr-2">
                                  {Object.keys(qualificationData).map(
                                    (item, ind) => (
                                      <Fragment key={ind}>
                                        <ul>
                                          <li>
                                            {" "}
                                            <b>{item} &nbsp; </b>(
                                            {qualificationData[item].map(
                                              (item1, i) => (
                                                <>
                                                  {i !==
                                                  qualificationData[item]
                                                    .length -
                                                    1 ? (
                                                    <>
                                                      {item1.specialization_name
                                                        ? item1.specialization_name
                                                        : item1.name}
                                                      {", "}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item1.specialization_name
                                                        ? item1.specialization_name
                                                        : item1.name}
                                                    </>
                                                  )}
                                                </>
                                              )
                                            )}
                                            )
                                          </li>
                                        </ul>
                                      </Fragment>
                                    )
                                  )}
                                </span>
                              )}
                            </li>
                          )}
                          {jobData?.eligibility_criteria?.qualifications
                            ?.length > 0 && (
                            <li>
                              <b>Percentage:</b>{" "}
                              {jobData?.eligibility_criteria?.qualifications?.map(
                                (item) => (
                                  <>
                                    <br></br>
                                    <span>
                                      {" "}
                                      {item?.education_type_name} &nbsp;
                                      {item?.percentage}%
                                    </span>
                                  </>
                                )
                              )}
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="my-3 text-left">
                        {jobData.company && jobData.company.company_name && (
                          <>
                            <h6>
                              <b>
                                About{" "}
                                {jobData.company
                                  ? jobData.company.company_name
                                  : jobData.company_details}
                              </b>
                            </h6>
                            <p>
                              <span className="badge skill-chip my-3">
                                {jobData.company
                                  ? jobData.company.industry_name
                                  : "Industry Name NA"}
                              </span>
                            </p>
                            <p className="fs-14 gray-2">
                              {jobData.company
                                ? jobData.company.company_description
                                : "No Company Description"}
                            </p>
                          </>
                        )}
                        {jobData.company && jobData.company.company_size ? (
                          <p className="fs-14 mb-1">
                            {" "}
                            <b>Company Size:</b>{" "}
                            <span className="gray-2 fs-14 ml-2">
                              {jobData.company && jobData.company.company_size}
                            </span>
                          </p>
                        ) : null}
                        {jobData.company && jobData.company.company_website && (
                          <p className="fs-14 mb-1">
                            {" "}
                            <b>Check out us @</b>{" "}
                            <a
                              id="view_job_ahref"
                              target="blank"
                              href={
                                jobData.company &&
                                jobData.company.company_website
                              }
                            >
                              <span
                                style={{ color: "#0645AD" }}
                                className="gray-2 fs-14 ml-2"
                              >
                                {jobData.company &&
                                  jobData.company.company_website}
                              </span>
                            </a>
                          </p>
                        )}
                      </div>

                      {jobData.documents && jobData.documents.length > 0 ? (
                        <>
                          <div className="text-left my-5">
                            <h6>
                              <b> Documents Required </b>
                            </h6>
                            <ul className="fs-14 ml-3 my-0 pl-0 gray-2">
                              {jobData.documents &&
                                jobData.documents.map((item, ind) => (
                                  <li key={ind}> {item} </li>
                                ))}
                            </ul>
                          </div>
                        </>
                      ) : null}

                      <Grid container>
                        <Grid className="">
                          {jobData.job_rounds &&
                            jobData.job_rounds.length > 0 && (
                              <div>
                                <b>
                                  <h6 className="viewjob_heading">
                                    {" "}
                                    Selection Procedure{" "}
                                  </h6>
                                </b>
                                <ul className="fs-14 gray-2 m-0 py-2 px-4">
                                  {jobData.job_rounds &&
                                    jobData.job_rounds.map((doc, index) => {
                                      return (
                                        <li key={index}>
                                          <span>
                                            Round&nbsp; {index + 1}{" "}
                                            &nbsp;:&nbsp; {doc.round_name}{" "}
                                            &nbsp;{" "}
                                            {doc?.duration
                                              ? `Duration: ${doc?.duration} mins`
                                              : null}{" "}
                                            &nbsp;
                                            {doc?.venue
                                              ? `Venue: ${doc?.venue}`
                                              : null}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Hidden>
          <Hidden smUp>
            <Box>
              <div className="card gw-card w-100">
                <div className="card-body mx-0">
                  <div className="row my-2">
                    <div className="col-12 text-left my-auto">
                      <h4>
                        <b>
                          {jobData.company
                            ? jobData.company.company_name
                            : jobData.company_details}
                        </b>
                      </h4>
                    </div>
                    <div className="col-md-9 col-12 text-left my-auto">
                      <p style={{ fontSize: "18px", color: "#424242" }}>
                        {" "}
                        <b>{jobData.job_title}</b>
                      </p>
                    </div>
                    <div className="col-6 ">
                      {jobData.is_posted_by_college && (
                        <Chip
                          label={"POSTED BY COLLEGE"}
                          variant="outlined"
                          className={classes.root}
                          color="primary"
                        />
                      )}
                    </div>
                    <div className="col-6 text-left my-auto">
                      <Chip
                        className={classes.root}
                        label={jobData.hiring_type + " HIRING"}
                        color="primary"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  {jobData.eligibility_criteria.skills.length > 0 && (
                    <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                      {" "}
                      Skills{" "}
                    </p>
                  )}

                  <div className="row my-2">
                    {jobData.eligibility_criteria.skills.map((item, ind) => (
                      <span className="badge skill-chip mx-2" key={ind}>
                        {" "}
                        {item.skill_name}{" "}
                      </span>
                    ))}
                  </div>

                  <div className="row my-2">
                    {jobData.eligibility_criteria.key_skills_evaluation.map(
                      (item, ind) => (
                        <span className="badge skill-chip mx-2" key={ind}>
                          {" "}
                          {item.skill_name}{" "}
                        </span>
                      )
                    )}
                  </div>

                  <hr />
                  <div className="row my-2">
                    {jobData.ctc_min && jobData.ctc_max ? (
                      <div className="col-md-6 text-left pl-2">
                        <h6>
                          <img alt="" src={money} height="12px" /> &nbsp;
                          Compensation
                        </h6>
                        <p className="fs-14 gray-2 m-0">
                          ₹ {printNumber(jobData.ctc_min)} -{" "}
                          {printNumber(jobData.ctc_max)}{" "}
                          {jobData.salary_payment_type}
                        </p>
                      </div>
                    ) : null}
                    {jobData.equity_min !== 0 && jobData.equiy_max !== 0 ? (
                      <div className="col-md-6 text-left">
                        <h6>Equity</h6>
                        <p className="fs-14 gray-2 m-0">
                          {jobData.equity_min}% - {jobData.equity_max}%
                        </p>
                      </div>
                    ) : null}
                  </div>

                  <div className="row my-0 text-left pl-2">
                    <div className="col-md-4 col-12">
                      <div className="row pl-0">
                        <h6>
                          <b>
                            <img alt="" src={map} height="20px" /> Office
                            Location
                          </b>
                        </h6>
                      </div>
                      <div className="row pl-0">
                        <p className="gray-2 fs-14">
                          {jobData.job_location.length > 0 ? (
                            jobData.job_location.map((item, i) => (
                              <Fragment key={i}>
                                {i !== jobData.job_location.length - 1 ? (
                                  <>{item.city}, </>
                                ) : (
                                  <>{item.city}</>
                                )}
                              </Fragment>
                            ))
                          ) : (
                            <>
                              {jobData.work_from_home
                                ? "Work From Home"
                                : "PAN India"}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    {jobData.job_type_name && (
                      <div className="col-md-4 col-12">
                        <div className="row">
                          <h6>
                            <b>
                              <img alt="" src={suitcase} height="12px" />
                              &nbsp; Job type
                            </b>
                          </h6>
                        </div>
                        <div className="row">
                          <p className="gray-2 fs-14">
                            {" "}
                            {jobData.job_type_name}{" "}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="col-md-4 col-12">
                      <div className="row">
                        <h6>
                          <b>
                            {" "}
                            <img alt="" src={calender} height="18px" />
                            &nbsp; Apply Duration{" "}
                          </b>
                        </h6>
                      </div>
                      <div className="row">
                        <p className="gray-2 fs-14">
                          {printDate(jobData.apply_start_date)}
                          {jobData.apply_end_date ? (
                            <> to {printDate(jobData.apply_end_date)}</>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row my-0 text-left pl-2">
                    <div className="col-md-4 col-12">
                      <div className="row pl-0">
                        <h6>
                          <b>
                            <img alt="" src={sandclock} height="20px" /> &nbsp;
                            Employment Type
                          </b>
                        </h6>
                      </div>
                      <div className="row pl-0">
                        <p className="gray-2 fs-14">
                          {jobData.employment_type_name && (
                            <span>{jobData.employment_type_name}</span>
                          )}
                        </p>
                      </div>
                    </div>
                    {jobData.service_bond !== 0 && (
                      <div className="col-md-4 col-12">
                        <div className="row">
                          <h6>
                            <b> Service Bond </b>
                          </h6>
                        </div>
                        <div className="row">
                          <p className="gray-2 fs-14">
                            {" "}
                            {jobData.service_bond} Months{" "}
                          </p>
                        </div>
                      </div>
                    )}
                    {jobData.service_bond === 0 && (
                      <div className="col-md-4 col-12">
                        <div className="row">
                          <h6>
                            {" "}
                            <b>Service Bond</b>{" "}
                          </h6>
                        </div>
                        <div className="row">
                          <p className="gray-2 fs-14"> None </p>
                        </div>
                      </div>
                    )}
                    {jobData.job_type_name === "Internship" && (
                      <div className="col-md-4 col-12">
                        <div className="row">
                          <h6>
                            {" "}
                            <b>PPO Offered</b>{" "}
                          </h6>
                        </div>
                        <div className="row">
                          <p className="gray-2 fs-14">
                            {" "}
                            {jobData.ppo ? "Yes" : "No"}{" "}
                          </p>
                        </div>
                      </div>
                    )}
                    {jobData.job_type_name === "Internship" &&
                      jobData.ppo &&
                      jobData.stipend && (
                        <div className="col-md-4 col-12">
                          <div className="row">
                            <h6>
                              <img alt="" src={money} height="20px" />
                              &nbsp; <b>Stipend</b>
                            </h6>
                          </div>
                          <div className="row">
                            <p className="gray-2 fs-14">
                              {" "}
                              ₹ {jobData.stipend || 0} Per Month
                            </p>
                          </div>
                        </div>
                      )}
                    <div className="col-md-4 col-12">
                      <div className="row">
                        <h6>
                          <b>
                            {" "}
                            <img alt="" src={calender} height="18px" /> &nbsp;
                            Job Duration{" "}
                          </b>
                        </h6>
                      </div>
                      <div className="row">
                        <p className="gray-2 fs-14">
                          {printDate(jobData.job_duration_start)}
                          {jobData.job_duration_end ? (
                            <> to {printDate(jobData.job_duration_end)}</>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {jobData.hiring_type === "ON CAMPUS" && (
                    <div className="row my-5 text-left pl-2">
                      <h6>
                        <b>On Campus Hiring - College Information</b>
                      </h6>
                      <br />
                      <div className="col-md-12">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>College</StyledTableCell>
                                <StyledTableCell align="left">
                                  Degrees
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Specializations
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Status
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Apply Start Date
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Apply End Date
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {jobData.college.map((colgObj, ind) => (
                                <StyledTableRow key={ind}>
                                  <StyledTableCell component="th" scope="row">
                                    {" "}
                                    {colgObj.name}{" "}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {colgObj.preferences.map((prefObj, ind) => (
                                      <Fragment key={ind}>
                                        <ul>
                                          <li> {prefObj.degree_name} </li>
                                        </ul>
                                      </Fragment>
                                    ))}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {colgObj.preferences.map((prefObj) =>
                                      prefObj.specialization.map(
                                        (spzl, ind) => (
                                          <Fragment key={ind}>
                                            <ul>
                                              <li>
                                                {" "}
                                                {spzl.specialization_name
                                                  ? spzl.specialization_name
                                                  : spzl.name}
                                              </li>
                                            </ul>
                                          </Fragment>
                                        )
                                      )
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {colgObj.is_rejected &&
                                      !colgObj.is_approved && <>Rejected</>}
                                    {!colgObj.is_rejected &&
                                      colgObj.is_approved && <>Accepted</>}
                                    {!colgObj.is_rejected &&
                                      !colgObj.is_approved && <>Pending</>}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {colgObj?.apply_start_date?.slice(8, 10) +
                                      "/" +
                                      colgObj?.apply_start_date?.slice(5, 7) +
                                      "/" +
                                      colgObj?.apply_start_date?.slice(0, 4)}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {colgObj?.apply_end_date?.slice(8, 10) +
                                      "/" +
                                      colgObj?.apply_end_date?.slice(5, 7) +
                                      "/" +
                                      colgObj?.apply_end_date?.slice(0, 4)}{" "}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  )}
                  <div className="text-left my-5">
                    <p
                      style={{ fontSize: "18px", textDecoration: "underline" }}
                    >
                      <b>Job Description</b>
                    </p>
                    {ReactHtmlParser(jobData.job_description)}
                  </div>
                  <div className="text-left my-5">
                    <p
                      style={{ fontSize: "18px", textDecoration: "underline" }}
                    >
                      <b>Preferences</b>
                    </p>
                    <ul className="fs-16 gray-2 m-0 py-2 px-4">
                      <li>
                        <b>Work Experience:</b>{" "}
                        {jobData.eligibility_criteria.experience[0]}
                      </li>
                      <li>
                        <b>Eligible Graduation Years:</b>{" "}
                        {jobData.eligibility_criteria.graduation_years.map(
                          (item, i) => (
                            <Fragment key={i}>
                              {" "}
                              {i !==
                              jobData.eligibility_criteria.graduation_years
                                .length -
                                1 ? (
                                <span className="mr-2">{item},</span>
                              ) : (
                                <span className="mr-2">{item}</span>
                              )}
                            </Fragment>
                          )
                        )}
                      </li>
                      {(!localStorage.getItem("token") ||
                        jobData.hiring_type === "OFF CAMPUS") && (
                        <li>
                          <b>Qualifications Required:</b>{" "}
                          {!jobData.is_posted_by_college ? (
                            <span className="mr-2">
                              {jobData.hiring_type === "ON CAMPUS"
                                ? Object.keys(qualificationData).map(
                                    (item, ind) => (
                                      <Fragment key={ind}>
                                        <ul>
                                          <li>
                                            {" "}
                                            <b>{item} &nbsp; </b>(
                                            {qualificationData[item].map(
                                              (item1, i) => (
                                                <>
                                                  {i !==
                                                  qualificationData[item]
                                                    .length -
                                                    1 ? (
                                                    <>
                                                      {item1[specializationKey]}
                                                      {", "}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item1[specializationKey]}
                                                    </>
                                                  )}
                                                </>
                                              )
                                            )}
                                            )
                                          </li>
                                        </ul>
                                      </Fragment>
                                    )
                                  )
                                : Object.keys(qualificationData).map(
                                    (item, ind) => (
                                      <Fragment key={ind}>
                                        <ul>
                                          <li>
                                            {" "}
                                            <b>{item}&nbsp; </b>(
                                            {qualificationData[item].map(
                                              (item1, i) => (
                                                <Fragment key={i}>
                                                  {i !==
                                                  qualificationData[item]
                                                    .length -
                                                    1 ? (
                                                    <>
                                                      {item1.name}
                                                      {", "}
                                                    </>
                                                  ) : (
                                                    <>{item1.name}</>
                                                  )}
                                                </Fragment>
                                              )
                                            )}
                                            )
                                          </li>
                                        </ul>
                                      </Fragment>
                                    )
                                  )}
                            </span>
                          ) : (
                            <span className="mr-2">
                              {Object.keys(qualificationData).map(
                                (item, ind) => (
                                  <Fragment key={ind}>
                                    <ul>
                                      <li>
                                        {" "}
                                        <b>{item} &nbsp; </b>(
                                        {qualificationData[item].map(
                                          (item1, i) => (
                                            <>
                                              {i !==
                                              qualificationData[item].length -
                                                1 ? (
                                                <>
                                                  {item1.specialization_name
                                                    ? item1.specialization_name
                                                    : item1.name}
                                                  {", "}
                                                </>
                                              ) : (
                                                <>
                                                  {item1.specialization_name
                                                    ? item1.specialization_name
                                                    : item1.name}
                                                </>
                                              )}
                                            </>
                                          )
                                        )}
                                        )
                                      </li>
                                    </ul>
                                  </Fragment>
                                )
                              )}
                            </span>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>

                  <div className="my-3 text-left">
                    {jobData.company && jobData.company.company_name && (
                      <>
                        <p style={{ fontSize: "18px", marginBottom: "0px" }}>
                          <b>
                            About{" "}
                            {jobData.company
                              ? jobData.company.company_name
                              : jobData.company_details}
                          </b>
                        </p>
                        <p>
                          <span className="badge skill-chip my-3">
                            {jobData.company
                              ? jobData.company.industry_name
                              : "Industry Name NA"}
                          </span>
                        </p>
                        <p className="fs-14 gray-2">
                          {jobData.company
                            ? jobData.company.company_description
                            : "No Company Description"}
                        </p>
                      </>
                    )}
                    {jobData.company && jobData.company.company_size ? (
                      <p className="fs-14 mb-1">
                        {" "}
                        <b>Company Size:</b>{" "}
                        <span className="gray-2 fs-14 ml-2">
                          {jobData.company && jobData.company.company_size}
                        </span>
                      </p>
                    ) : null}
                    {jobData.company && jobData.company.company_website && (
                      <p className="fs-14 mb-1">
                        {" "}
                        <b>Check out us @</b>{" "}
                        <a
                          id="view_job_ahref"
                          target="blank"
                          href={
                            jobData.company && jobData.company.company_website
                          }
                        >
                          <span
                            style={{ color: "#0645AD" }}
                            className="gray-2 fs-14 ml-2"
                          >
                            {jobData.company && jobData.company.company_website}
                          </span>
                        </a>
                      </p>
                    )}
                  </div>

                  {jobData.documents && jobData.documents.length ? (
                    <>
                      <div className="text-left my-5">
                        <h6>
                          <b> Documents Required </b>
                        </h6>
                        <ul className="fs-14 ml-3 my-0 pl-0 gray-2">
                          {jobData.documents &&
                            jobData.documents.map((item, ind) => (
                              <li key={ind}> {item} </li>
                            ))}
                        </ul>
                      </div>
                    </>
                  ) : null}

                  <Grid container>
                    <Grid className="">
                      {jobData.job_rounds && jobData.job_rounds.length > 0 && (
                        <div>
                          <b>
                            <h6 className="viewjob_heading">
                              {" "}
                              Selection Procedure{" "}
                            </h6>
                          </b>
                          <ul className="fs-14 gray-2 m-0 py-2 px-4">
                            {jobData.job_rounds &&
                              jobData.job_rounds.map((doc, index) => {
                                return (
                                  <li key={index}>
                                    <span>
                                      {" "}
                                      Round&nbsp; {index + 1} &nbsp;:&nbsp;{" "}
                                      {doc.round_name}{" "}
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Box>
          </Hidden>
        </>
      ) : (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      )}
      {/* </Layout> */}
    </>
  );
};

export default ViewJobPublicPage;
