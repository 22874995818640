import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function CompanyCoworking() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [name, setName] = useState('');
    const [countryId, setCountryId] = useState(1);
    const [stateId, setStateId] = useState(1);
    const [cityId, setCityId] = useState(null);
    const [patchCountry, setPatchCountry] = useState(null);
    const [patchState, setPatchState] = useState(null);
    const [patchCity, setPatchCity] = useState(null);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/coworking/', requestPraveshOptions)
          .then(json => {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:           parseInt(json.data[i].id),
                        name:         json.data[i].name,
                        country_name: json.data[i].country_name,
                        state_name:   json.data[i].state_name,
                        city_name:    json.data[i].city_name,
                        is_blocked:   json.data[i].is_blocked ? 'Yes' : 'No',
                        is_onboarded: json.data[i].is_onboarded ? 'Yes' : 'No',
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    // Country Finding API
    useEffect(() => {
        apiCall('/api/location/country/', requestPraveshOptions)
        .then((res)=> {
            setCountries(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // State Finding API
    useEffect(() => {
        apiCall(`/api/location/state/?search=${countryId}`, requestPraveshOptions)
        .then((res)=> {
            setStates(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);

    // City Finding API
    useEffect(() => {
        apiCall(`/api/location/city/?search=${stateId}`, requestPraveshOptions)
        .then((res)=> {
            setCities(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateId]);


    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ 
            name: name,
            country: countryId,
            state: stateId,
            city: cityId
        })
    };

    const columns = [
        { title: "Coworking ID",   field: "id",             headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Coworking Name", field: "name",           headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Country",        field: "country_name",   headerStyle: headerCSS, cellStyle: cellCSS,
          editComponent: props => (
            <Select 
                onChange={e=> {setPatchCountry(JSON.parse(e.target.value).country_id)
                    setCountryId(JSON.parse(e.target.value).country_id)
                    props.onChange(JSON.parse(e.target.value).country)
                } }
            >
            {
                countries.map((c, key) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(c)} >
                        {c.country}
                    </MenuItem>
                )})
            }
            </Select>
          )
        },
        { title: "State",          field: "state_name",     headerStyle: headerCSS, cellStyle: cellCSS,
          editComponent: props => (
            <Select 
                onChange={e=> {setPatchState(JSON.parse(e.target.value).state_id)
                    setStateId(JSON.parse(e.target.value).state_id)
                    props.onChange(JSON.parse(e.target.value).state)
                } }
            >
            {
                states.map((s, key) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(s)} >
                        {s.state}
                    </MenuItem>
                )})
            }
            </Select>
          )
        },
        { title: "City",           field: "city_name",      headerStyle: headerCSS, cellStyle: cellCSS,
          editComponent: props => (
            <Select 
                onChange={e=> {setPatchCity(JSON.parse(e.target.value).city_id)
                    setCityId(JSON.parse(e.target.value).city_id)
                    props.onChange(JSON.parse(e.target.value).city)
                } }
            >
            {
                cities.map((c, key) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(c)} >
                        {c.city}
                    </MenuItem>
                )})
            }
            </Select>
          )
        },
        { title: "Onboarded",      field: "is_onboarded",   headerStyle: headerCSS, cellStyle: cellCSS,
          editComponent: props => (
            <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
            </Select>
          )  
        },
        { title: "Blocked",        field: "is_blocked",     headerStyle: headerCSS, cellStyle: cellCSS,
          editComponent: props => (
            <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
            </Select>
          )
        },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }).then(setPatchCountry(null))
        .then(setPatchState(null))
        .then(setPatchCity(null)),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                name: newData.name,
                ...(patchCountry && {country: patchCountry}),
                ...(patchState && {state: patchState}),
                ...(patchCity && {city: patchCity}),
                is_blocked: newData.is_blocked==="Yes" ? true : false,
                is_onboarded: newData.is_onboarded==="Yes" ? true : false,
            })
        };
        apiCall(`/api/admin/coworking/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
            }
            else {
                FailureAlert(res.error);
            }
        })
    }

    const addCoworking = (e) => {
            e.preventDefault();
            apiCall('/api/admin/coworking/', requestOptionsPost)
            .then((res) => {
                if(res.success) {
                    SuccessAlert(res.data.message);
                    setCount(count+1);
                }
                else {
                    FailureAlert(res.error);
                }
            })
            e.target.reset();
    }

    return (
        !loading 
        ?
        <div className="company__coworking">
            <div className="company__coworking__form">
                <h4>Add New Co-working</h4>
                <Form onSubmit={e=>addCoworking(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="name">
                            <Form.Label>Name*</Form.Label>
                            <Form.Control 
                                name="name" 
                                type="text" 
                                placeholder="Enter Name"
                                onChange={e=> {setName(e.target.value)}}
                                required 
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control 
                                as="select" 
                                onChange={e=> {setCountryId(JSON.parse(e.target.value).country_id)} }
                            >
                                <option key='0'>--select--</option>
                            {
                                countries.map((country, key) => {
                                return (
                                    <option key={key} value={JSON.stringify(country)} >
                                        {country.country}
                                    </option>
                                )})
                            }    
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Control 
                                as="select"
                                onChange={e=> {setStateId(JSON.parse(e.target.value).state_id)} }
                            >
                                <option>--select--</option>
                            {
                                states.map((state, key) => {
                                return (
                                    <option key={key} value={JSON.stringify(state)} >
                                        {state.state}
                                    </option>
                                )})
                            }
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control 
                                as="select"
                                onChange={e=> {setCityId(JSON.parse(e.target.value).city_id)} }
                            >
                                <option>--select--</option>
                            {
                                cities.map((city, key) => {
                                return (
                                    <option key={key} value={JSON.stringify(city)} >
                                        {city.city}
                                    </option>
                                )})
                            }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Button type="submit" variant="info">Add Coworking</Button>
                </Form>    
            </div>
            <TableWithExport 
                title = "Company Co-working"
                data = {data}
                columns = {columns}
                fName = "Selected Coworking List"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default CompanyCoworking;