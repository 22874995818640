import React, { useEffect, useState } from "react";
import gif from "../../assets/loopingcircles-3.gif";
import { Button } from "react-bootstrap";
import {
  gifStyling,
  headerCSS,
  cellCSS,
  requestPraveshOptions,
} from "../../constants";
import { apiPraveshCall } from "../../util/apiCall";
import TableWithExport from "../TableWithExport";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { Pagination } from "@material-ui/lab";
import FilterPopup from "../FilterPopup";
import QueryString from "query-string";
import LoadingOverlay from "react-loading-overlay";

function SearchStudentExcel() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [selectedPageNo, setSelectedPageNo] = useState(1);
  const [reRender, setReRender] = useState(true);
  const [metaData, setMetaData] = useState({
    totalCount: 0,
    totalPages: 1,
  });
  const moment = require("moment");
  const queryParams = QueryString.parse(window.location.search);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFilterDetails = () => {
    let job_id = queryParams.job_id?`&job_id=${queryParams.job_id}`:''
        let job_title = queryParams.job_title?`&job_title=${queryParams.job_title}`:''
        let date_of_application = queryParams.date_of_application?`&date_of_application=${queryParams.date_of_application}`:''
        let source_of_application = queryParams.source_of_application?`&source_of_application=${queryParams.source_of_application}`:''
        let name = queryParams.name?`&student_names=${queryParams.name}`:''
        let email_id = queryParams.email_id?`&email_id=${queryParams.email_id}`:''
        let phone_no = queryParams.phone_no?`&phone_no=${queryParams.phone_no}`:''
        let current_location = queryParams.current_location?`&current_location=${queryParams.current_location}`:''
        let preferred_locations = queryParams.preferred_locations?`&preferred_location_name=${queryParams.preferred_locations}`:''
        let current_company_name = queryParams.current_company_name?`&current_company_name=${queryParams.current_company_name}`:''
        let current_company_designation = queryParams.current_company_designation?`&current_company_designation=${queryParams.current_company_designation}`:''
        let functional_area = queryParams.functional_area?`&functional_area=${queryParams.functional_area}`:''
        let role = queryParams.role?`&role=${queryParams.role}`:''
        let industry = queryParams.industry?`&industry=${queryParams.industry}`:''
        let key_skills = queryParams.key_skills?`&key_skills=${queryParams.key_skills}`:''
        let resume_headline = queryParams.resume_headline?`&resume_headline=${queryParams.resume_headline}`:''
        let summary = queryParams.summary?`&summary=${queryParams.summary}`:''
        let ug_degree = queryParams.ug_degree?`&ug_degree=${queryParams.ug_degree}`:''
        let ug_specialization = queryParams.ug_specialization?`&ug_specialization=${queryParams.ug_specialization}`:''
        let ug_university_or_institute_name = queryParams.ug_university_or_institute_name?`&ug_university_institute_name=${queryParams.ug_university_or_institute_name}`:''
        let pg_degree = queryParams.pg_degree?`&pg_degree=${queryParams.pg_degree}`:''
        let pg_specialization = queryParams.pg_specialization?`&pg_specialization=${queryParams.pg_specialization}`:''
        let pg_university_or_institute_name = queryParams.pg_university_or_institute_name?`&pg_university_institute_name=${queryParams.pg_university_or_institute_name}`:''
        let phd_degree = queryParams.phd_degree?`&phd_degree=${queryParams.phd_degree}`:''
        let phd_specialization = queryParams.phd_specialization?`&phd_specialization=${queryParams.phd_specialization}`:''
        let phd_university_or_institute_name = queryParams.phd_university_or_institute_name?`&phd_university_institute_name=${queryParams.phd_university_or_institute_name}`:''
        let gender = queryParams.gender?`&gender=${queryParams.gender}`:''
        let marital_status = queryParams.marital_status?`&marital_status=${queryParams.marital_status}`:''
        let home_town = queryParams.home_town?`&home_town=${queryParams.home_town}`:''
        let pincode = queryParams.pincode?`&pincode=${queryParams.pincode}`:''
        let permanent_address = queryParams.permanent_address?`&permanent_address=${queryParams.permanent_address}`:''
        let total_experience_min = queryParams.total_experience_min?`&total_experience_min=${+queryParams.total_experience_min*12}`:''
        let total_experience_max = queryParams.total_experience_max?`&total_experience_max=${queryParams.total_experience_max*12}`:''
        let current_ctc_min = queryParams.current_ctc_min?`&current_ctc_min=${+queryParams.current_ctc_min*100000}`:''
        let current_ctc_max = queryParams.current_ctc_max?`&current_ctc_max=${+queryParams.current_ctc_max*100000}`:''
        let notice_period_min = queryParams.notice_period_min?`&notice_period_min=${queryParams.notice_period_min}`:''
        let notice_period_max = queryParams.notice_period_max?`&notice_period_max=${queryParams.notice_period_max}`:''
        let ug_year_min = queryParams.ug_year_min?`&ug_year_min=${queryParams.ug_year_min}`:''
        let ug_year_max = queryParams.ug_year_max?`&ug_year_max=${queryParams.ug_year_max}`:''
        let pg_year_min = queryParams.pg_year_min?`&pg_year_min=${queryParams.pg_year_min}`:''
        let pg_year_max = queryParams.pg_year_max?`&pg_year_max=${queryParams.pg_year_max}`:''
        let phd_year_min = queryParams.phd_year_min?`&phd_year_min=${queryParams.phd_year_min}`:''
        let phd_year_max = queryParams.phd_year_max?`&phd_year_max=${queryParams.phd_year_max}`:''
        let date_of_birth_min = queryParams.date_of_birth_min?`&date_of_birth_min=${moment(queryParams.date_of_birth_min).format('YYYY-MM-DD')}`:''
        let date_of_birth_max = queryParams.date_of_birth_max?`&date_of_birth_max=${moment(queryParams.date_of_birth_max).format('YYYY-MM-DD')}`:''
        let create_time_min = queryParams.create_time_min?`&create_time_min=${moment(queryParams.create_time_min).format('YYYY-MM-DD')}`:''
        let create_time_max = queryParams.create_time_max?`&create_time_max=${moment(queryParams.create_time_max).format('YYYY-MM-DD')}`:''
        let uploaded_by = queryParams.uploaded_by?`&username=${queryParams.uploaded_by}`:''
        return `page_no=${selectedPageNo}${job_id}${job_title}${date_of_application}${source_of_application}${name}${email_id}${phone_no}${current_location}${preferred_locations}${current_company_name}${current_company_designation}${functional_area}${role}${industry}${key_skills}${resume_headline}${summary}${ug_degree}${ug_specialization}${ug_university_or_institute_name}${pg_degree}${pg_specialization}${pg_university_or_institute_name}${phd_degree}${phd_specialization}${phd_university_or_institute_name}${gender}${marital_status}${home_town}${pincode}${permanent_address}${total_experience_min}${total_experience_max}${current_ctc_min}${current_ctc_max}${notice_period_min}${notice_period_max}${ug_year_min}${ug_year_max}${pg_year_min}${pg_year_max}${phd_year_min}${phd_year_max}${date_of_birth_min}${date_of_birth_max}${create_time_min}${create_time_max}${uploaded_by}`
  }

    useEffect(() => {
        setLoading(false);
    apiPraveshCall(
      `/api/admin/third_party/get_candidate_excel/?${getFilterDetails()}`,
      requestPraveshOptions
    ).then((json) => {
      if (json?.success) {
        setMetaData({
          totalCount: json?.data?.student_data_list_count,
          totalPages: json?.data?.total_no_pages,
        });
        let tempArry = [];
        for (var i = 0; i < json.data?.student_data_list.length; i++) {
          let obj = {
            job_id: json?.data?.student_data_list[i]?.job_id ?? "N/A",
            job_title: json?.data?.student_data_list[i]?.job_title ?? "N/A",
            date_of_application:
              json?.data?.student_data_list[i]?.date_of_application ?? "N/A",
            source_of_application:
              json?.data?.student_data_list[i]?.source_of_application ?? "N/A",
            name: json?.data?.student_data_list[i]?.name ?? "N/A",
            email_id: json?.data?.student_data_list[i]?.email_id ?? "N/A",
            phone_no: json?.data?.student_data_list[i]?.phone_no ?? "N/A",
            current_location:
              json?.data?.student_data_list[i]?.current_location ?? "N/A",
            preferred_locations:
              json?.data?.student_data_list[i]?.preferred_locations ?? "N/A",
            total_experience:
              json?.data?.student_data_list[i]?.total_experience ?? "N/A",
            current_company_name:
              json?.data?.student_data_list[i]?.current_company_name ?? "N/A",
            current_company_designation:
              json?.data?.student_data_list[i]?.current_company_designation ??
              "N/A",
            functional_area:
              json?.data?.student_data_list[i]?.functional_area ?? "N/A",
            role: json?.data?.student_data_list[i]?.role ?? "N/A",
            industry: json?.data?.student_data_list[i]?.industry ?? "N/A",
            key_skills: json?.data?.student_data_list[i]?.key_skills ?? "N/A",
            current_ctc: json?.data?.student_data_list[i]?.current_ctc ?? "N/A",
            notice_period:
              json?.data?.student_data_list[i]?.notice_period ?? "N/A",
            job_resume_headlineid:
              json?.data?.student_data_list[i]?.resume_headline ?? "N/A",
            summary: json?.data?.student_data_list[i]?.summary ?? "N/A",
            ug_degree: json?.data?.student_data_list[i]?.ug_degree ?? "N/A",
            ug_specialization:
              json?.data?.student_data_list[i]?.ug_specialization ?? "N/A",
            ug_university_or_institute_name:
              json?.data?.student_data_list[i]?.ug_university_institute_name ??
              "N/A",
            ug_year: json?.data?.student_data_list[i]?.ug_year ?? "N/A",
            pg_degree: json?.data?.student_data_list[i]?.pg_degree ?? "N/A",
            pg_specialization:
              json?.data?.student_data_list[i]?.pg_specialization ?? "N/A",
            pg_university_or_institute_name:
              json?.data?.student_data_list[i]?.pg_university_institute_name ??
              "N/A",
            pg_year: json?.data?.student_data_list[i]?.pg_year ?? "N/A",
            phd_degree: json?.data?.student_data_list[i]?.phd_degree ?? "N/A",
            phd_specialization:
              json?.data?.student_data_list[i]?.phd_specialization ?? "N/A",
            phd_university_or_institute_name:
              json?.data?.student_data_list[i]?.phd_university_institute_name ??
              "N/A",
            phd_year: json?.data?.student_data_list[i]?.phd_year ?? "N/A",
            gender: json?.data?.student_data_list[i]?.gender ?? "N/A",
            marital_status:
              json?.data?.student_data_list[i]?.marital_status ?? "N/A",
            home_town: json?.data?.student_data_list[i]?.home_town ?? "N/A",
            pincode: json?.data?.student_data_list[i]?.pincode ?? "N/A",
            dob:
              json?.data?.student_data_list[i]?.dob ?? "N/A",
            permanent_address:
              json?.data?.student_data_list[i]?.permanent_address ?? "N/A",
            uploaded_by: json?.data?.student_data_list[i]?.username ?? "N/A",
            uploaded_date:
              moment(json?.data?.student_data_list[i]?.create_time).format(
                "YYYY-MM-DD"
              ) ?? "N/A",
          };
          tempArry.push(obj);
        }
        setData(tempArry);
        setLoading(true);
      } else {
        FailureAlert(json?.error);
      }
    }).catch(err => {
      FailureAlert("Something went wrong!!");
    })
  }, [selectedPageNo, reRender]);

  const [downloadOverlay, setDownloadOverlay] = useState(false);

  const exportCSVfun = () => {
    setDownloadOverlay(true);
    apiPraveshCall(
      `/api/admin/third_party/export_candidate_excel/?column_name=job_id,job_title,date_of_application,source_of_application,student_name,email_id,phone_no,current_location,preferred_locations,total_experience,current_company_name,current_company_designation,functional_area,role,industry,key_skills,current_ctc,notice_period,ug_degree,ug_specialization,ug_university_institute_name,ug_year,pg_degree,pg_specialization,pg_university_institute_name,pg_year,phd_degree,phd_specialization,phd_university_institute_name,phd_year,gender,marital_status,home_town,pincode,dob,permanent_address,uploaded_by,uploaded_date&${getFilterDetails()}`,
      requestPraveshOptions
    ).then((json) => {
      setDownloadOverlay(false);
      if (json?.success) {
        SuccessAlert(json?.data?.message);
      } else {
        FailureAlert(json?.error);
      }
    }).catch(err => {
      FailureAlert("Something went wrong!!");
    })
  }

    const columns = [
        {
          title: "Job ID",
          field: "job_id",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Job Title ",
          field: "job_title",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Date of Application",
          field: "date_of_application",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Source of Application",
          field: "source_of_application",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Name",
          field: "name",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Email ID",
          field: "email_id",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Phone No",
          field: "phone_no",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
    
        {
          title: "Current Location",
          field: "current_location",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Preferred Locations",
          field: "preferred_locations",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Total Experience",
          field: "total_experience",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Current Company Name",
          field: "current_company_name",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Current Company Designation",
          field: "current_company_designation",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Functional Area",
          field: "functional_area",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Role",
          field: "role",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Industry",
          field: "industry",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Key Skills",
          field: "key_skills",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Current ctc",
          field: "current_ctc",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Notice Period",
          field: "notice_period",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        // {
        //   title: "Resume Headline",
        //   field: "resume_headline",
        //   headerStyle: headerCSS,
        //   cellStyle: cellCSS,
        // },
        // {
        //   title: "Summary",
        //   field: "summary",
        //   headerStyle: headerCSS,
        //   cellStyle: { ...cellCSS, minWidth: "15rem" },
        // },
        {
          title: "UG Degree",
          field: "ug_degree",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "UG Specialization",
          field: "ug_specialization",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "UG University/Institute Name",
          field: "ug_university_or_institute_name",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "UG Year",
          field: "ug_year",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "PG Degree",
          field: "pg_degree",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "PG Specialization",
          field: "pg_specialization",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "PG University/Institute_name",
          field: "pg_university_or_institute_name",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "PG Year",
          field: "pg_year",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Phd Degree",
          field: "phd_degree",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Phd Specialization",
          field: "phd_specialization",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Phd University/Institute Name",
          field: "phd_university_or_institute_name",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Phd Year",
          field: "phd_year",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Gender",
          field: "gender",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Marital Status",
          field: "marital_status",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Home Town",
          field: "home_town",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Pincode",
          field: "pincode",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Date of Birth",
          field: "dob",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Permanent Address",
          field: "permanent_address",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Uploaded By",
          field: "uploaded_by",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
        {
          title: "Uploaded Date",
          field: "uploaded_date",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
        },
      ];
      const handleFilterPopup = () => {
        setShowFilterPopup(!showFilterPopup); 
      };
  return loading?(
    <div>
      <LoadingOverlay
              active={downloadOverlay}
              spinner
              text="Please Wait..."
      >
      <Button
        size="sm"
        style={{ marginBottom: "0.5rem" }}
        onClick={handleFilterPopup}
      >
        Filters
      </Button>
      <TableWithExport
        title="3rd Party Candidates"
        data={data}
        columns={columns}
        fname="name"
        hasPaging={false}
        cellFixedIndex="4"
        filtering={false}
        dynamicExport={true}
        exportCSVfun={exportCSVfun}
        search={false}
        sorting={false}
        selection={false}
      />
      <section style={{ float: "right", marginTop: "1rem" }}>
        <Pagination
          page={selectedPageNo || 1}
          onChange={(e, value) => {
            setSelectedPageNo(value);
          }}
          count={metaData?.totalPages}
          color="primary"
        />
      </section>
      {showFilterPopup && (
      <FilterPopup
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        showFilterPopup={showFilterPopup}
        setShowFilterPopup={setShowFilterPopup}
        reRender={reRender}
        setReRender={setReRender}
        setSelectedPageNo={setSelectedPageNo}
      />
    )}
      </LoadingOverlay>
    </div>
  ) : (
    <>
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
    
    </>
  );
}

export default SearchStudentExcel;
