import React, { useEffect, useState } from "react";
import ColorPicker from "../common/ColorPicker";
import { Form, Col, Button } from "react-bootstrap";

import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { praveshAxiosGetReq } from "../../util/apiCall";
import { Typography } from "@material-ui/core";
export default function AddNewCollegeAdditionalInfo({
  formData,
  setFormData,
  isSaas, collegeId,
  primaryColor,
  setPrimaryColor,
  secondaryColor,
  setSecondaryColor,
  logoLink,
  circularLogoLink,
  faviconLink,
  sideImageLink,
  bannerLink,
}) {


  const [passcode, setPasscode] = useState(formData.passcode || '');
  const [errorForPasscode, setErrorForPasscode] = useState('');


  useEffect(() => {

    if (passcode && passcode !== formData.passcode) {

      const timeOutId = setTimeout(() => { handlePasscode(passcode) }, 1000);

      return () => clearTimeout(timeOutId)
    }

  }, [passcode])

  const handlePasscode = async (value) => {

    if (value.length < 3 || value.length > 8) return

    const endPoint = `/api/college/check-passcode/?passcode=${value}${collegeId ? ("&college_id=" + collegeId) : ''}`

    try {

      const res = await praveshAxiosGetReq(endPoint);
      const data = res?.data?.data;

      if (res?.data?.success) {

        if (data?.flag) {

          SuccessAlert(data?.message);
          setFormData({ ...formData, passcode: value })
          setErrorForPasscode('');

        } else {
          setFormData({ ...formData, passcode: '' })
          setErrorForPasscode(data?.message);
        }
      }
    } catch (error) {

      console.error("Passcode failed", error);
    }
  }


  return (
    <>
      <div style={{ padding: "30px" }}>
        <Form className="additional__info__form" id="additional__info__form">
          <Form.Row>
            {isSaas && (
              <Form.Group as={Col} controlId="additionalinfo">
                <Form.Label>Student License Count*</Form.Label>
                <Form.Control
                  name="studentlicensecount"
                  type="number"
                  placeholder="StudentLicenseCount*"
                  value={formData.studentlicensecount}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      studentlicensecount: event.target.value,
                    })
                  }
                />
              </Form.Group>
            )}
            {isSaas && (
              <Form.Group as={Col} controlId="additionalinfo">
                <Form.Label>GetWork Domain*</Form.Label>
                <Form.Control
                  name="getworkdomain"
                  type="text"
                  placeholder="GetWork Domain(For SAAS College Only)"
                  value={formData.getworkdomain}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      getworkdomain: event.target.value,
                    })
                  }
                />
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="additionalinfo">
              <Form.Label>Passcode{isSaas ? "*" : ""}</Form.Label>
              <Form.Control
                name="passcode"
                type="text"
                placeholder={`Passcode${isSaas ? "*" : ""}`}
                value={passcode}
                onChange={(event) => {
                  let passcodeValue = event.target.value.toUpperCase();

                  if (passcodeValue.length <= 8) {
                    setPasscode(passcodeValue)
                  }
                }
                }
              />
              <Typography style={{ color: 'red', fontSize: "12px", marginTop: '4px' }}>{errorForPasscode ? `${errorForPasscode}` : ''}</Typography>
            </Form.Group>
          </Form.Row>
          {isSaas && (
            <>
              <div style={{ marginLeft: "15px" }}>
                <Form.Row >
                  <Form.Group as={Col} controlId="formCheckbox">
                    <Form.Check type="checkbox" label="Premium"
                      checked={formData.premium}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          premium: e?.target?.checked
                        });
                      }}
                    />
                  </Form.Group>
                </Form.Row>
              </div>
              <Form.Row>
                <Form.Group>
                  <Form.File
                    id="collegeSideImage"
                    name="collegesideimage"
                    value={formData.collegesideimage}
                  >
                    <Form.File.Label>Upload SideImage*</Form.File.Label>
                    <Form.File.Input
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          sideImage: e?.target?.files[0],
                        });
                      }}
                    />
                    <>
                      {" "}
                      {formData?.sideImage?.name &&
                        `Selected File:${formData?.sideImage.name} `}
                    </>
                    <p>Recommended Size: 720px*700px</p>
                  </Form.File>
                </Form.Group>

                {sideImageLink && !formData?.sideImage?.name && (
                  <Form.Group>
                    <a href={sideImageLink} target="_blank">
                      <img
                        src={sideImageLink}
                        alt="sideImage"
                        height="100px"
                        width="100px"
                      />
                    </a>
                  </Form.Group>
                )}
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Group>
                  <Form.File id="collegeLogo">
                    <Form.File.Label>
                      Upload Favicon (.ico file)*
                    </Form.File.Label>
                    <Form.File.Input
                      onChange={(e) => {
                        if (e.target.files[0]?.name?.includes("ico")) {
                          setFormData({
                            ...formData,
                            favicon: e?.target?.files[0],
                          });
                        } else {
                          FailureAlert("Please Enter .ico extension file");
                          e.target.value = null;
                        }
                      }}
                    />
                    <>
                      {" "}
                      {formData?.favicon?.name &&
                        `Selected File:${formData?.favicon.name} `}
                    </>
                    <p>Recommended Size: 16px*16px</p>
                  </Form.File>
                </Form.Group>
                {faviconLink && (
                  <Form.Group>
                    <a href={faviconLink} target="_blank">
                      <img
                        src={faviconLink}
                        alt="favicon"
                        height="100px"
                        width="100px"
                      />
                    </a>
                  </Form.Group>
                )}
              </Form.Row>{" "}
            </>
          )}
          <br />
          <Form.Row>
            <Form.Group>
              <Form.File id="collegeLogo">
                <Form.File.Label>Upload Logo*</Form.File.Label>
                <Form.File.Input
                  onChange={(e) => {
                    setFormData({ ...formData, logo: e?.target?.files[0] });
                  }}
                />
                <>
                  {" "}
                  {formData?.logo?.name &&
                    `Selected File:${formData?.logo?.name} `}
                </>
                <p>Recommended Size: 150px*150px</p>
              </Form.File>
            </Form.Group>
            {logoLink && (
              <Form.Group>
                <a href={logoLink} target="_blank">
                  <img src={logoLink} alt="logo" height="100px" width="100px" />
                </a>
              </Form.Group>
            )}
          </Form.Row>
          <br />
          {isSaas && (
            <Form.Row>
              <Form.Group>
                <Form.File id="collegeLogo">
                  <Form.File.Label>Upload Circular Logo*</Form.File.Label>
                  <Form.File.Input
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        circularLogo: e?.target?.files[0],
                      });
                    }}
                  />
                  <>
                    {" "}
                    {formData?.circularLogo?.name &&
                      `Selected File:${formData?.circularLogo?.name} `}
                  </>
                  <p>Recommended Size: 50px*50px</p>
                </Form.File>
              </Form.Group>

              {logoLink && (
                <Form.Group>
                  <a href={logoLink} target="_blank">
                    <img
                      src={logoLink}
                      alt="logo"
                      height="100px"
                      width="100px"
                    />
                  </a>
                </Form.Group>
              )}
            </Form.Row>
          )}
          <br />
          <Form.Row>
            {" "}
            <Form.Group>
              <Form.File id="collegeBanner">
                <Form.File.Label>Upload Banner*</Form.File.Label>
                <Form.File.Input
                  onChange={(e) => {
                    setFormData({ ...formData, banner: e?.target?.files[0] });
                  }}
                />
                <>
                  {" "}
                  {formData?.banner?.name &&
                    `Selected File:${formData?.banner?.name} `}
                </>
                <p>Recommended Size: 860px*250px</p>
              </Form.File>
            </Form.Group>
            {bannerLink && (
              <Form.Group>
                <a href={bannerLink} target="_blank">
                  <img
                    src={bannerLink}
                    alt="logo"
                    height="100px"
                    width="100px"
                  />
                </a>
              </Form.Group>
            )}
          </Form.Row>

          <br />

          {isSaas && (
            <>
              <Form.Group controlId="primaryColor" required>
                <Form.Label>Select Primary Color*</Form.Label>
                <ColorPicker color={primaryColor} setColor={setPrimaryColor} />
              </Form.Group>
              <br />
              <Form.Group controlId="secondaryColor" required>
                <Form.Label>Select Secondary Color*</Form.Label>
                <ColorPicker
                  color={secondaryColor}
                  setColor={setSecondaryColor}
                />
              </Form.Group>
            </>
          )}
          <br />
        </Form>
      </div >
    </>
  );
}
