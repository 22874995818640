import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SaveProject({data, projectIndex, handleDelete, handleOpen, setAddProject, setIsEdit, setEditIndex}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography
            variant="body1"
            style={{
              // fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {data?.title}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton color="primary" onClick={() => {
              setAddProject(data);
              setEditIndex(projectIndex);
              setIsEdit(true);
              handleOpen();
            }}>
              <EditIcon />
            </IconButton>
            <IconButton onClick = {() => {handleDelete(data?.id, projectIndex, "Project")}}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        {data?.links && 
        <a
          href={data?.links}
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="body2"
            color="primary"
            style={{
              marginBottom: "4px",
            }}
          >
            View Live
          </Typography>
        </a>
}
<Typography
        variant="body1"
        style={{
          // fontWeight: "bold",
          marginBottom: "4px",
        }}
      >
        {data?.description}
      </Typography>
      </div>
    </>
  );
}
