import React,{useState} from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Grid } from "@material-ui/core";
import { praveshAxiosGetReq, praveshAxiosDeleteReq } from '../../../../util/apiCall';
import { FailureAlert, SuccessAlert } from "../../../../util/Notifications";

import CustomButtonCircularProgress from "../../customLoader";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    width: "400px",
    padding: "20px",
  },
  margin: {
    width: "100%",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DeleteTag({open,handleClickOpen,handleClose,deleteTag,
  tagName,setTagsApiCall,removeResumes,setChecked,setTagsRefreshed}) {

const classes = useStyles();
const [loading, setLoading] = useState(false);

const deleteTagResumes = () => {

  if (deleteTag){

    const deleteTagResumesCall = async() => {
    
      setLoading(true);

    const endPoint = `/api/admin/bulk/tags/?tag=${tagName}`;

    try{
      const res = await praveshAxiosDeleteReq(endPoint);

      if(res?.data?.success){
        setTagsApiCall();
        handleClose();
        SuccessAlert(`${res?.data?.data?.message}`);
        setChecked(false);
        setLoading(false);

      }
      else{
        FailureAlert("Some Error Occurred!");
        setLoading(false);

      };

    }
    catch(error){

      setLoading(false);
      throw(error);

    }

  };

  deleteTagResumesCall();
  };


  if(removeResumes?.length > 0){

    // console.log(removeResumes);
        setLoading(true);

    const resumeTagsString = removeResumes.reduce((string, item) => {
      return string+`,${item}`;
          });
    const deleteIDsSelectedResumesCall = async() => {

    const endPoint = `/api/admin/bulk/resumes/?ids=${resumeTagsString}`;

    try{
      const res = await praveshAxiosDeleteReq(endPoint);

      if(res?.data?.success){
        setTagsRefreshed(true); // for resetting the checked boxes on card
        
        setTagsApiCall();
        handleClose();
        SuccessAlert(`${res?.data?.data?.message}`);
        setTagsRefreshed(false);
        setLoading(false);

      }
      else{
        FailureAlert("Some Error Occurred!");
        setLoading(false);

      };

    }
    catch(error){
      setLoading(false);
      throw(error);

    }

  };

  deleteIDsSelectedResumesCall();

  };
}
 
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{
          paper: classes.newDialog,
        }}
      >
        <DialogContent style={{ marginTop: "-20px" }}>
          <div>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography variant="h6">
                  Are sure you want to delete these files?
                </Typography>
                <br />
                {/* <Typography variant="body2">FileName.pdf</Typography> */}
              </Grid>
              <Grid item md={6} style={{ display: "grid" }}>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item md={6} style={{ display: "grid" }}>
                <Button
                  // onClick={handleClose}
                  onClick={deleteTagResumes}
                  variant="contained"
                  color="primary"
                >
                 {loading ? (<CustomButtonCircularProgress />) : (" Submit")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
