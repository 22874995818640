import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import {CircularProgress} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';

const token = localStorage.getItem('token');

function ReferralCode() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [paidServices, setPaidServices] = useState([]);
    const [serviceType, setServiceType] = useState(null);
    const [referralCode, setReferralCode] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [circularLoading, setCircularLoading] = useState(false);

    // const userTypeLookup = {
    //     1: 'Student',
    //     5: 'Company',
    //     7: 'College',
    // }

    // Fetching all referral codes data
    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/referral_code/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:              json.data[i].id,
                        referral_code:   json.data[i].referral_code,
                        credit_amount:   json.data[i].credit_amount,
                        is_active:       json.data[i].is_active ? 'Yes' : 'No',
                        user_id:         json.data[i].user_id,
                        user_email:      json.data[i].user_email,
                        user_name:       json.data[i].user_name,
                        user_type_id:    json.data[i].user_type_id,
                        user_type:       json.data[i].user_type,
                        service_type_id: json.data[i].service_type_id,
                        service_type:    json.data[i].service_type,
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);


    // Fetch all paid services
    useEffect(() => {
        apiCall('/api/admin/paid_services/', requestPraveshOptions)
          .then(json => {
            if(json.success) setPaidServices(json.data)
            else FailureAlert(json.error) 
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const requestOptionsPost = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            referral_code: referralCode,
            credit_amount: parseFloat(amount),
            is_active:     true,
            service_type:  serviceType,
            user_type:     parseInt(userType),
            user:          JSON.parse(localStorage.getItem('user-data')).id,
        })
    };

    const columns = [
        { title: "Referral_ID",         field: "id",                  headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',            searchable: false, },
        { title: "Referral_Code",       field: "referral_code",       headerStyle: headerCSS, cellStyle: cellCSS,
          editable: 'never', },
        { title: "Amount",              field: "credit_amount",       headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Active?",             field: "is_active",           headerStyle: headerCSS, cellStyle: cellCSS,
          filterPlaceholder: 'Yes/No',  searchable: false,            lookup: {'Yes':'Yes', 'No':'No'}, },
        { title: "Service_Type",        field: "service_type",        headerStyle: headerCSS, cellStyle: cellCSS,
          editable: 'never', },
        { title: "User_Name",           field: "user_name",           headerStyle: headerCSS, cellStyle: cellCSS,
          editable: 'never', },
        { title: "User_Email",          field: "user_email",          headerStyle: headerCSS, cellStyle: cellCSS,
          editable: 'never', },
        { title: "User_Type",           field: "user_type",           headerStyle: headerCSS, cellStyle: cellCSS,
          editable: 'never', },
    ]

    const addNewReferral = (e) => {
        e.preventDefault();
        if(!userType) { FailureAlert('Choose User Type to continue...'); return; }
        if(!serviceType) { FailureAlert('Choose a Service Type to continue...'); return; }
        setCircularLoading(true);
        apiCall(`/api/admin/referral_code/?email_id=${email}`, requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
                setServiceType('');  setAmount(''); setEmail('');
                setReferralCode(''); setUserType(null);
            }
            else {
                FailureAlert(res.error);
                res.verbose && res.verbose.referral_code && FailureAlert(res.verbose.referral_code[0]);
            }
            setCircularLoading(false);
        })
    }

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData, oldData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (nD, oD) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({
                ...(oD.credit_amount !== nD.credit_amount && { credit_amount: nD.credit_amount }),
                ...(nD.is_active==='Yes' && { is_active: true }),
                ...(nD.is_active==='No' && { is_active: false }),
            })
        };
        apiCall(`/api/admin/referral_code/${nD.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
            }
            else {
                FailureAlert(res.error);
            }
            setCount(count+1);
        })
    }

    return (
        <div id="paid__services">
        {
            !circularLoading
            ?
            <div id="paid__services__form">
                <h4>Add New Referral Code</h4>
                <Form onSubmit={e=>addNewReferral(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="userEmail">
                            <Form.Label>User Email*</Form.Label>
                            <Form.Control 
                                name="userEmail" type="email" placeholder="Enter User Email.."
                                required onChange={e=> {setEmail(e.target.value)}}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="userType">
                            <Form.Label>User Type*</Form.Label>
                            <Form.Control 
                                as="select" name="userType"
                                onChange={e=> {setUserType(e.target.value)}}
                            >
                                <option value=''>--Select--</option>
                                <option value={1}>Student</option>
                                <option value={5}>Company</option>
                                <option value={7}>College</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="referralCode">
                            <Form.Label>Referral Code*</Form.Label>
                            <Form.Control 
                                name="referralCode" type="text" placeholder="Enter Referral Code.."
                                required onChange={e=> {setReferralCode(e.target.value)}}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="typeSearchService" as={Col} controlId="serviceType">
                            <Autocomplete
                                id="select-service-type"
                                options={paidServices}
                                getOptionLabel={(item) => item.service_type}
                                style={{ width: '98%', margin: '3% 1% 1% 1%' }}
                                onChange={(e, item)=>item ? setServiceType(item.id) : setServiceType(null)}
                                renderInput={(params) => <TextField {...params} label="Select Service Type*" variant="outlined" />}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="creditAmount">
                            <Form.Label>Credit Amount*</Form.Label>
                            <Form.Control 
                                name="creditAmount" type="number" placeholder="Enter Credit Amount.."
                                required onChange={e=> {setAmount(e.target.value)}}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Button type="submit" variant="primary">Add Referral</Button>
                </Form>    
            </div>
            :
            <div style={{marginTop: '50px', marginBottom: '40px'}}>
                <CircularProgress
                    style={{display:'block', margin:'auto'}}
                />
            </div>
        }
        {
            !loading
            ?
            <TableWithExport 
                title = "GetWork Referral Codes"
                data = {data}
                columns = {columns}
                fName = "Selected Referral Codes"
                editableObject = {edit}
            />
            :
            <img 
            src={gif} 
            alt="Getwork Loading Gif..."
            style={gifStyling}
            />
        }
        </div>
    )
}

export default ReferralCode;