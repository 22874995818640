import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import pSBC from "shade-blend-color";
import Shareicon from "../../assets/svg/share.svg";
import { emailRegex } from "../../constants";
import { niyuktiAxiosGetReq, niyuktiAxiosPostReq } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import CustomButtonCircularProgress from "../Student/Components/loader";
import History from "../Table/History/History";
import TrackShareSuccess from "./TrackShareSuccess";

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  button: {
    // borderRadius: "30px",
    boxShadow: "none",
    width: "100%",
    height: "100%",
    display: "flex",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "30px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "20px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  MuiAccordionroot: {
    backgroundColor: "white !important",
    boxShadow: "none",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white !important",
    },
  },
  share: {
    borderRadius: "30px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    backgroundColor: "#fff",
    borderRadius: "30px",
    border: "none",
  },
  floatingLabelFocusStyle: {
    //color: "#7E7E7E",
    fontSize: "14px",
    color: "#C5C4BA",
  },
  subHeads: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 22,
    /* identical to box height */
    color: "#000000",
  },
  viewtext: {
    color: theme.palette.primary.main,
    fontSize: "18px",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const DialogTitle = withStyles(styles)((props) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root} {...other}>
//             <Typography variant="h6">{children}</Typography>
//             {onClose ? (
//                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogTitle>
//     );
// });

const ShareApplicantTrack = ({
  jobProfileObject,
  filterObject,
  jobProfileName,
  fromWithoutAction = true,
  ratingColumns,
  stepList,
  open,
  setOpen,
}) => {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const [generatedLink, setGeneratedLink] = useState("");
  const [passCode, setPassCode] = useState("");
  const [shareHistory, setShareHistory] = useState([]);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [shareObject, setShareObject] = useState({
    passcode: null,
    email: [],
    action: [],
    authority: { track: false, download: false, unlock: false },
    current_email: null,
  });

  const [shareObjectError, setShareObjectError] = useState({
    passcode: null,
    current_email: null,
  });

  let idCount = 0;
  const dataSelectedFields = ratingColumns.map((item) => {
    idCount++;
    return {
      dataID: idCount,
      dataName: item.title,
      selected: true,
      key: item.field,
      disabled: false,
    };
  });

  const round = Object.values(stepList);

  let roundCoundId = 0;

  const dataSelectedFieldsRound = round?.map((item) => {
    roundCoundId++;
    return {
      dataID: roundCoundId,
      dataName: item?.tooltip,
      selected: true,
      key: item?.tooltip,
      disabled: false,
      status_id: item?.status_id,
      round_id: item?.round_id,
    };
  });

  //  pre-applied column has been removed overhere

  dataSelectedFieldsRound.shift();

  const [selectedRounds, setSelectedRounds] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [columnsShown, setColumnsShown] = useState([]);
  const [columnsShownRounds, setColumnsShownRounds] = useState([]);

  const handleChange = (event) => {
    let updatedFields = [];

    const togglevalue = (id) => {
      updatedFields = selectedFields?.map((item) => {
        if (item.dataID === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      setSelectedFields(updatedFields);
    };

    const eventLength = event?.target?.value?.length - 1;
    togglevalue(event?.target?.value[eventLength]);

    // for making default selection of any id's have been made to be selected by default.
    // if (!(event?.target?.value[eventLength] === 1 || event?.target?.value[eventLength] === 2)) {

    // };

    let newlist = updatedFields?.map(function (i) {
      if (i?.selected) return i?.dataName;
    });
    const newlist2 = newlist?.filter((n) => n);

    setColumnsShown(newlist2);
  };

  let actions = selectedFields?.filter((item) => {
    if (item?.selected === true) {
      return item?.dataName;
    }
  });

  let actionsList = [];
  actions?.forEach(function (obj) {
    actionsList?.push(obj.key);
  });
  // actionsList?.unshift("id");

  // for rounds dropbox:
  const handleChangeRounds = (event) => {
    let updatedFields = [];

    const togglevalue = (id) => {
      updatedFields = selectedRounds?.map((item) => {
        if (item.dataID === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      setSelectedRounds(updatedFields);
    };

    const eventLength = event?.target?.value?.length - 1;
    togglevalue(event?.target?.value[eventLength]);

    // for making default selection of any id's have been made to be selected by default.
    // if (!(event?.target?.value[eventLength] === 1 || event?.target?.value[eventLength] === 2)) {

    // };
    let newlist = updatedFields?.map(function (i) {
      if (i?.selected) return i?.dataName;
    });
    const newlist2 = newlist?.filter((n) => n);
    setColumnsShownRounds(newlist2);
  };

  // the selected rounds are passed here.
  let actions2 = selectedRounds?.filter((item) => {
    if (item?.selected === true) {
      return item?.dataName;
    }
  });

  let actionsList2 = [];
  actions2?.forEach(function (obj) {
    actionsList2?.push({
      status_id: obj?.status_id,
      round_id: obj?.round_id,
      round_name: obj?.dataName,
    });
  });
  // actionsList2?.unshift("id");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setOpenShare(true);
    setIsCopy(false);
    setSelectedFields(dataSelectedFields);
    setColumnsShown([]);
    setSelectedRounds(dataSelectedFieldsRound);
    setColumnsShownRounds([]);
    setGeneratedLink("");
    setPassCode("");
  };

  const handleSubmit = () => {
    if (validateData()) {
      setLoading(true);
      setPassCode(shareObject?.passcode);
      postSharerCall();
    }
  };

  useEffect(() => {
    getSharerHistory();
    setSelectedFields(dataSelectedFields);
    setSelectedRounds(dataSelectedFieldsRound);
    setColumnsShownRounds(
      dataSelectedFieldsRound?.map((item) => {
        return item?.dataName;
      })
    );
    setColumnsShown(
      dataSelectedFields?.map((item) => {
        return item?.dataName;
      })
    );
  }, [open]);

  const postSharerCall = async () => {
    const url = `/api/track_sharer/company/sharer_main/`;

    let data = {
      job: jobProfileObject.jobId,
      passcode: shareObject?.passcode,
      // job_link: generatedLink,
      action_performed: {
        email: shareObject?.email,
        // action: actionsList,
        authority: shareObject?.authority,
        job_rounds: actionsList2,
        table_columns: actionsList,
      },
      shared_by: "ADMIN",
      sharer_id: null,
    };

    try {
      const res = await niyuktiAxiosPostReq(url, data);

      if (res?.data?.success) {
        setLoading(false);
        setShareObject({
          ...shareObject,
          passcode: "",
          email: [],
          action: [],
          authority: { track: false, download: false },
          current_email: null,
        });
        // handleOpenShareSuccess();
        // setGeneratedLink(res?.data?.data?.link);
        // setValidationData({
        //     link: res?.data?.data?.link,
        //     passcode: shareObject?.passcode,
        // });
        setOpenShare(true);
        setIsCopy(false);
        handleClose();
        setSelectedFields(dataSelectedFields);
        setColumnsShown([]);
        setSelectedRounds(dataSelectedFieldsRound);
        setColumnsShownRounds([]);
        setGeneratedLink(res?.data?.data?.link);
        setPassCode(res?.data?.data?.passcode);
      } else {
        FailureAlert("Some Error Occurred");
      }
      setLoading(false);
    } catch (error) {
      setIsCopy(false);
      setLoading(false);
      FailureAlert("Some Error Occurred!");
    }
  };

  const getSharerHistory = async () => {
    const url = `/api/track_sharer/company/sharer_history/?job_id=${jobProfileObject.jobId}`;

    try {
      const res = await niyuktiAxiosGetReq(url);

      if (res?.data?.success) {
        setShareHistory(res?.data?.data);
        // console.log(res);
      } else {
        // console.log(res);

        FailureAlert("Some Error Occurred");
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDelete = (index) => {
    if (index !== -1) {
      var arr = [...shareObject?.email];
      arr.splice(index, 1);
      setShareObject({ ...shareObject, email: arr });
    }
  };

  const validateData = () => {
    var passcode_error = null;
    var email_error = null;
    var is_valid = true;
    if (!shareObject?.passcode) {
      passcode_error = "Please enter Passcode";
      is_valid = false;
    }
    if (shareObject?.passcode?.length < 6) {
      passcode_error = "Passcode should be atleast 6 character";
      is_valid = false;
    }
    setShareObjectError({ shareObjectError, passcode: passcode_error });
    return is_valid;
  };

  return (
    <div>
      {/* <IconButton aria-label="delete" onClick={handleClickOpen}>
                <img src={Shareicon} height="30px" width="30px" /> &nbsp;{" "}
                {fromWithoutAction && (
                    <span
                        style={{
                            fontSize: 14,
                            marginBottom: 5,
                        }}
                    >
                        Share
                    </span>
                )}
            </IconButton> */}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <div style={{ padding: "20px" }}>
            <Typography variant="h5" style={{ fontWeight: "600" }}>
              Share Applicant Tracking info-{jobProfileObject?.jobTitle}
            </Typography>

            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item className={classes.grid} xs={10}>
                <div className={classes.paper}>
                  <Typography
                    style={{
                      color: "gray",
                      fontStyle: "italic",
                      fontSize: "14px",
                    }}
                  >
                    *An unique students details share link will be auto
                    generated once you fill all the below details.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    // color: 'gray',
                    // fontStyle: "italic",

                    fontFamily: "Nunito",
                    color: "#000000",
                    // fontStyle: normal,
                    // fontWeight: 600,
                    // fontSize: 16,
                    // lineHeight: 22,
                  }}
                >
                  Invite People (Optional)
                </Typography>
              </Grid>

              <Grid item xs={10} className={classes.grid}>
                {/* <div className={classes.paper}> */}
                <TextField
                  variant="outlined"
                  // required

                  fullWidth
                  // outline="none"

                  id="email"
                  label="Email Id"
                  name="email"
                  autoComplete="email"
                  value={shareObject?.current_email}
                  onChange={(e) => {
                    setShareObject({
                      ...shareObject,
                      current_email: e.target.value,
                    });
                    setShareObjectError({
                      ...shareObjectError,
                      current_email: null,
                    });
                  }}
                  error={shareObjectError?.current_email ? true : false}
                  helperText={shareObjectError?.current_email}
                  // placeholder="Email ID"
                  //autoFocus
                />
                {/* <br /> */}
                {/* </div> */}
              </Grid>

              <Grid
                item
                xs={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    if (emailRegex.test(shareObject.current_email)) {
                      var arr = [...shareObject.email];
                      arr.push(shareObject.current_email);
                      setShareObject({
                        ...shareObject,
                        email: arr,
                        current_email: "",
                      });
                    } else {
                      // alert.error("Invalid Email");
                    }
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: "15px", marginBottom: "10px" }}
            >
              <div>
                {shareObject?.email?.length > 0 &&
                  shareObject?.email?.map((item, index) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      onDelete={() => handleDelete(index)}
                      deleteIcon={<CloseIcon />}
                      label={item}
                      className={classes.chip}
                    />
                  ))}
              </div>
            </Grid>
            <Grid>
              <Grid
                item
                xs={3}
                className={classes.grid}
                style={{
                  marginLeft: "8 rem",
                  // height: "2rem"
                  marginBottom: "2rem",
                  marginTop: "2rem",
                }}
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  // fullWidth
                  label="Passcode*"
                  value={shareObject?.passcode}
                  onChange={(e) => {
                    setShareObject({
                      ...shareObject,
                      passcode: e.target.value,
                    });
                    setShareObjectError({
                      ...shareObjectError,
                      passcode: null,
                    });
                  }}
                  error={shareObjectError?.passcode ? true : false}
                  helperText={shareObjectError?.passcode}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                //marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "600",
                  fontFamily: "Nunito",
                  color: "#000000",
                }}
              >
                Select Information to be visible
              </Typography>

              <section style={{ display: "flex" }}>
                <FormGroup row>
                  <>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginRight: "30px",
                      }}
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        style={{
                          width: "300px",
                          marginRight: "1 rem",
                          // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                          // borderColor: "none",
                        }}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          id="demo-multiple-checkbox-label"
                        >
                          Select Job Round*
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox-Round"
                          multiple
                          inputProps={{
                            style: {
                              padding: "16px",
                              borderRadius: "30px",
                            },
                          }}
                          variant="outlined"
                          value={columnsShownRounds}
                          label="Select Table Columns"
                          onChange={handleChangeRounds}
                          // renderValue={(selected) =>
                          // selected?.map((x) => x?.dataName)?.join(", ")
                          // }
                          renderValue={(selected) =>
                            selected
                              ?.map(function (i) {
                                return i;
                              })
                              ?.join(",")
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                                maxWidth: "200px",
                                borderRadius: "15px",
                              },
                            },
                          }}
                        >
                          {Array.isArray(selectedRounds)
                            ? selectedRounds?.map((item) => (
                                <MenuItem
                                  key={item?.dataID}
                                  value={item?.dataID}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={item?.selected}
                                    disabled={
                                      item?.disabled ? item?.disabled : false
                                    }
                                  />
                                  <ListItemText primary={item?.dataName} />
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                </FormGroup>

                <FormGroup row>
                  <>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginRight: "30px",
                      }}
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        style={{
                          width: "300px",
                          marginRight: "1 rem",
                          // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                          // borderColor: "none",
                        }}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          id="demo-multiple-checkbox-label"
                        >
                          Select Table Columns*
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          inputProps={{
                            style: {
                              padding: "16px",
                              borderRadius: "30px",
                            },
                          }}
                          variant="outlined"
                          value={columnsShown}
                          label="Select Table Columns"
                          onChange={handleChange}
                          // renderValue={(selected) =>
                          // selected?.map((x) => x?.dataName)?.join(", ")
                          // }
                          renderValue={(selected) =>
                            selected
                              ?.map(function (i) {
                                return i;
                              })
                              ?.join(",")
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                                maxWidth: "200px",
                                borderRadius: "15px",
                              },
                            },
                          }}
                        >
                          {Array.isArray(selectedFields)
                            ? selectedFields?.map((item) => (
                                <MenuItem
                                  key={item?.dataID}
                                  value={item?.dataID}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={item?.selected}
                                    disabled={
                                      item?.disabled ? item?.disabled : false
                                    }
                                  />
                                  <ListItemText primary={item?.dataName} />
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                </FormGroup>
              </section>
            </div>
            <div
              style={{
                display: "flex",
                // marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Choose User Actions
              </Typography>

              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={shareObject?.authority?.download}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          authority: {
                            ...shareObject.authority,
                            download: e.target.checked,
                          },
                        });
                      }}
                      name="checkedA"
                    />
                  }
                  label={<Typography variant="body2">Download</Typography>}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={shareObject?.authority?.track}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          authority: {
                            ...shareObject.authority,
                            track: e.target.checked,
                          },
                        });
                      }}
                      name="checkedA"
                    />
                  }
                  label={<Typography variant="body2">Track</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={shareObject?.authority?.unlock}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          authority: {
                            ...shareObject.authority,
                            unlock: e.target.checked,
                          },
                        });
                      }}
                      name="checkedA"
                    />
                  }
                  label={<Typography variant="body2">Unlock</Typography>}
                />
              </FormGroup>
            </div>
            <Accordion
              // style={{
              //   boxShadow: "none",
              //   marginTop: "-2px",
              //   backgroundColor: "white !important",
              //   marginBottom: "3px",
              // }}
              classes={{
                root: classes.MuiAccordionroot,
              }}
              // classes={{
              //   root: classes.test,
              // }}
            >
              <AccordionSummary
                style={{
                  width: "19%",
                  backgroundColor: "white",
                }}
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h6"
                  className={classes.viewtext}
                  style={{
                    marginLeft: "-10px",
                    backgroundColor: "white",
                  }}
                >
                  View History
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  padding: "0px",
                  backgroundColor: "white",
                }}
              >
                <History
                  data={shareHistory ? shareHistory : []}
                  setGeneratedLink={setGeneratedLink}
                  setPassCode={setPassCode}
                  setOpenShare={setOpenShare}
                  setIsCopy={setIsCopy}
                  fromStudent={true}
                />
              </AccordionDetails>
            </Accordion>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{
                  height: "48px",
                  //borderRadius: "30px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  marginRight: "10px",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "48px",
                  /// borderRadius: "30px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
                onClick={() => {
                  if (!loading) {
                    handleSubmit();
                  }
                }}
              >
                {loading ? <CustomButtonCircularProgress /> : "Share"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <DialogActions>
        <TrackShareSuccess
          jobID={jobProfileObject?.jobId}
          generatedLink={generatedLink}
          passCode={passCode}
          filterObject={filterObject}
          usermail={shareObject?.email}
          handleShareOpen={handleClose}
          openShare={openShare}
          setOpenShare={setOpenShare}
          isCopy={isCopy ? true : false}
        />
      </DialogActions>
    </div>
  );
};

export default ShareApplicantTrack;
