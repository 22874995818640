import React, { useState, useEffect } from "react";
import "../../css/AddCollege.css";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import { characterCount } from "../../util/wordCounter";
import {
  gifStyling,
  requestPraveshOptions,
  requestNiyuktiOptions,
} from "../../constants";
import { Form, Col, Button } from "react-bootstrap";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import gif from "../../assets/loopingcircles-3.gif";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import { Box } from "@material-ui/core";
import { uniqueArray } from "../../util/uniqueArray";
import ColorPicker from "../common/ColorPicker";

function AddCollege() {
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const [institutionType, setInstitutionType] = useState("College");
  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [educationId, setEducationId] = useState(null);
  const [educationName, setEducationName] = useState("");
  const [education, setEducation] = useState([]);
  const [degreeId, setDegreeId] = useState(null);
  const [degreeName, setDegreeName] = useState("");
  const [degrees, setDegrees] = useState([]);
  const [specializationId, setSpecializationId] = useState(null);
  const [specializationName, setSpecializationName] = useState("");
  const [specializations, setSpecializations] = useState(null);
  const [courses, setCourses] = useState([]);
  const [universityId, setUniversityId] = useState();
  const [universityName, setUniversityName] = useState("");
  const [universities, setUniversities] = useState([]);
  const [tpoFirstName, setTpoFirstName] = useState("");
  const [tpoLastName, setTpoLastName] = useState("");
  const [tpoMobile, setTpoMobile] = useState("");
  const [altTpoPhone, setAltTpoPhone] = useState("");
  const [tpoDesignation, setTpoDesignation] = useState("");
  const [tpoEmail, setTpoEmail] = useState("");
  const [altTpoEmail, setAltTpoEmail] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [nickName, setNickName] = useState("");
  const [about, setAbout] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [size, setSize] = useState("");
  const [year, setYear] = useState("");
  const [url, setUrl] = useState("");
  const [minCtc, setMinCtc] = useState("");
  const [avgCtc, setAvgCtc] = useState("");
  const [message, setMessage] = useState("");
  const [onboard, setOnboard] = useState(true);
  const [isSaas, setIsSaas] = useState(false);
  const [paid, setPaid] = useState(true);
  const [active, setActive] = useState(true);
  const [logo, setLogo] = useState(null);
  const [circularLogo, setCircularLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [banner, setBanner] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("#007BFF");
  const [secondaryColor, setSecondaryColor] = useState("#e0a800");
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [trial, setTrial] = useState("");
  const [trialEnd, setTrialEnd] = useState("");
  const [payment, setPayment] = useState("");
  const [universityNotListed, setUniversityNotListed] = useState(false);

  const [allJobSegments, setAllJobSegments] = useState([]);
  const [selectedJobSegments, setSelectedJobSegments] = useState([]);
  const [immediateJoining, setImmediateJoining] = useState(true);
  const [getworkDomain, setGetworkDomain] = useState(null);
  const [passcode, setPasscode] = useState("");
  const [studentLicenseCount, setStudentLicenseCount] = useState(0);
  const [planStartDate, setPlanStartDate] = useState(null);
  const [planEndDate, setPlanEndDate] = useState(null);

  const [placementSession, setPlacementSession] = useState({});
  const [placementType, setPlacementType] = useState("");
  const [placementStart, setPlacementStart] = useState(null);
  const [placementEnd, setPlacementEnd] = useState(null);

  const [examSession, setExamSession] = useState({});
  const [semStart, setSemStart] = useState(null);
  const [semEnd, setSemEnd] = useState(null);

  const [jobTypes, setJobTypes] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);

  const [courseUID, setCourseUID] = useState(0);
  const [aboutCount, setAboutCount] = useState(0);

  const [isGradeSystem, setIsGradeSystem] = useState(false);
  const [percentageFactor, setPercentageFactor] = useState(null);

  const monthArray = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
    { id: 4, value: "April" },
    { id: 5, value: "May" },
    { id: 6, value: "June" },
    { id: 7, value: "July" },
    { id: 8, value: "August" },
    { id: 9, value: "September" },
    { id: 10, value: "October" },
    { id: 11, value: "November" },
    { id: 12, value: "December" },
  ];

  const searchMonth = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const workExperiences = [
    { id: 1, name: "Fresher" },
    { id: 2, name: "1 Year" },
    { id: 3, name: "2 Years" },
    { id: 4, name: "3 Years" },
  ];

  const placementTypes = [
    { id: 1, value: "Internship" },
    { id: 2, value: "Full-time" },
  ];

  const addCollege = (e) => {
    e.preventDefault();
    if (organizationType === "") {
      FailureAlert("Select Organization Type...");
      return;
    }
    if (size === "") {
      FailureAlert("Select Institute Size...");
      return;
    }
    if (aboutCount > 512) {
      FailureAlert("College About can only have upto 512 characters...");
      return;
    }
    if (institutionType === "College" && universityName === "") {
      FailureAlert("Select University...");
      return;
    }
    if (stateId === 0) {
      FailureAlert("Select State...");
      return;
    }
    if (cityId === 0) {
      FailureAlert("Select City...");
      return;
    }
    if (selectedJobSegments.length <= 0) {
      FailureAlert("Select atleast one Job Segment to continue...");
      return;
    }
    if (!logo) {
      FailureAlert("Upload College Logo...");
      return;
    }

    if (!banner) {
      FailureAlert("Upload College Banner...");
      return;
    }
    if (isSaas && !sideImage) {
      FailureAlert("Upload College Side Image...");
      return;
    }
    if (isSaas && !favicon) {
      FailureAlert("Upload Favicon...");
      return;
    }
    if (isSaas && !circularLogo) {
      FailureAlert("Upload College Circular Logo...");
      return;
    }
    if (isSaas && !primaryColor) {
      FailureAlert("Choose Primary Color...");
      return;
    }
    if (isSaas && !secondaryColor) {
      FailureAlert("Upload Secondary Color...");
      return;
    }
    if (isSaas) {
      if (!studentLicenseCount) {
        FailureAlert("Please Enter Student Licence Count");
        return;
      }
      if (!planStartDate) {
        FailureAlert("Please Enter Plan Start Date");
        return;
      }
      if (!planEndDate) {
        FailureAlert("Please Enter Plan End Date");
        return;
      }
      if (planStartDate > planEndDate) {
        FailureAlert("Please Enter Correct Plan Start Date and End Date");
        return;
      }
    }
    if (!passcode) {
      FailureAlert("Please Enter Passcode...");
      return;
    }
    if (passcode && passcode?.length > 8) {
      FailureAlert("Passcode can't exceed 8 characters...");
      return;
    }
    if (courses.length === 0) {
      FailureAlert("Please Select Courses...");
      return;
    }
    if (isGradeSystem && !percentageFactor) {
      FailureAlert("Select Percentage Factor...");
      return;
    }

    let examSessionObject = {};
    examSessionObject["total_sem"] = Object.keys(examSession).length;
    examSessionObject["exam_details"] = Object.keys(examSession).map((type) => {
      let p = examSession[type];
      return { start_month: p[0].start_month, end_month: p[0].end_month };
    });

    let preferences = {};
    preferences["work_exp"] = selectedExperience;
    preferences["job_type"] = selectedJobType;
    preferences["location"] = selectedCities;

    const formData = new FormData();
    formData.append("person_first_name", tpoFirstName);
    formData.append("person_last_name", tpoLastName);
    formData.append("person_mobile", tpoMobile);
    formData.append("person_email", tpoEmail);
    altTpoPhone && formData.append("alternate_phone", altTpoPhone);
    altTpoEmail && formData.append("alternate_email", altTpoEmail);
    formData.append("person_designation", tpoDesignation);
    institutionType === "College" &&
      universityId &&
      formData.append("university_id", universityId);
    institutionType === "University" && formData.append("is_university", true);
    institutionType === "College" &&
      !universityId &&
      formData.append("university_name", universityName);
    formData.append("name", institutionName);
    formData.append("nick_name", nickName);
    formData.append("about", about);
    formData.append("description", description);
    formData.append("address", address);
    formData.append("organization_type", organizationType);
    formData.append("size", size);
    formData.append("year_founded", `${year}-01-01`);
    url && formData.append("website", url);
    logo && formData.append("logo", logo);
    favicon && formData.append("favicon", favicon);
    circularLogo && formData.append("circular_logo", circularLogo);
    banner && formData.append("banner", banner);
    formData.append("min_ctc", minCtc);
    formData.append("avg_ctc", avgCtc);
    formData.append("country", countryId);
    formData.append("state", stateId);
    formData.append("city", cityId);
    message && formData.append("message_for_recruiters", message);
    // formData.append('faqs', null);
    formData.append(
      "social_links",
      JSON.stringify([
        { id: "1", Name: "LinkedIn", value: linkedIn },
        { id: "2", Name: "Facebook", value: facebook },
        { id: "3", Name: "Instagram", value: insta },
      ])
    );
    formData.append("is_onboarded", onboard);
    formData.append("is_saas", isSaas);
    formData.append("is_active", active);
    sideImage && formData.append("side_image", sideImage);
    formData.append("primary_color", primaryColor);
    formData.append("secondary_color", secondaryColor);
    formData.append("is_paid", paid);
    formData.append("immediate_joining_availability", immediateJoining);
    formData.append("domain", getworkDomain);
    formData.append("passcode", passcode.toUpperCase());
    latitude && formData.append("latitude", latitude);
    longitude && formData.append("longitude", longitude);
    formData.append("student_license_count", studentLicenseCount);
    formData.append("plan_start_date", planStartDate);
    formData.append("plan_end_date", planEndDate);
    formData.append("degree_specialization", JSON.stringify(courses));
    formData.append("preferences", JSON.stringify(preferences));
    formData.append(
      "job_segments",
      JSON.stringify(
        selectedJobSegments.map((jobS) => ({
          job_segment_id: jobS.id,
          job_segment_name: jobS.job_segment,
        }))
      )
    );
    formData.append("trial_period", trial);
    formData.append("trial_period_end", trialEnd);
    payment && formData.append("amount", payment);
    formData.append("placement_session", JSON.stringify(placementSession));
    formData.append("exam_session", JSON.stringify(examSessionObject));
    formData.append("is_grade_system", isGradeSystem);
    formData.append(
      "percentage_factor",
      percentageFactor !== null ? percentageFactor : 0
    );

    setLoading(true);

    const requestForCollegeCreation = {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    };
    apiCall("/api/admin/college/", requestForCollegeCreation).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setInterval(function () {
          window.location.reload();
        }, 1000);
        setCourses([]);
      } else {
        FailureAlert(res.error);
      }
      setLoading(false);
    });
  };

  const addCourse = (
    e,
    educationId,
    degreeId,
    specializationId,
    educationName,
    degreeName,
    specializationName
  ) => {
    const t = degrees.find(
      (degree) => degree.id === degreeId && degree.educationtype === educationId
    );
    if (t && educationId && degreeId) {
      if (t.is_specialized && !specializationId) {
        FailureAlert("Select Specialization for this degree");
        return;
      } else if (!t.is_specialized) {
        specializationId = null;
        specializationName = "NA";
      }
      var doNotAddThis = false;
      courses.forEach((course) => {
        if (
          course.education_type === educationId &&
          course.degree_id === degreeId &&
          course.specialization_id === specializationId
        ) {
          FailureAlert("This Course is already in your list");
          doNotAddThis = true;
          return;
        }
      });
      if (doNotAddThis) {
        setSpecializationName("");
        setSpecializationId(null);
        return;
      }
      setCourseUID((prevState) => prevState + 1);
      setCourses([
        ...courses,
        {
          uid: courseUID,
          degree_id: degreeId,
          specialization_id: specializationId,
          is_active: true,
          education_type: educationId,
          education: educationName,
          degree: degreeName,
          specialization: specializationName,
        },
      ]);
      setSpecializationName("");
      setSpecializationId(null);
    } else {
      FailureAlert("Select Valid Course");
    }
  };

  const deleteCourse = (uid) => {
    setCourses(courses.filter((course) => course.uid !== uid));
  };

  const addPlacementSession = (type, start, end) => {
    let temp = { ...placementSession };
    if (!type) {
      FailureAlert("Select Placement Type");
      return;
    }
    if (!start) {
      FailureAlert("Select Placement Start Month");
      return;
    }
    if (!end) {
      FailureAlert("Select Placement End Month");
      return;
    }
    if (!temp[type]) {
      temp[type] = [{ start_month: start, end_month: end }];
      setPlacementSession(temp);
    } else {
      FailureAlert(
        `${type} - Placement Session already added...\nDelete previous ${type} to change month!`
      );
    }
  };

  const deletePlacementSession = (type) => {
    let temp = { ...placementSession };
    delete temp[type];
    setPlacementSession(temp);
  };

  const addExamSession = (type, start, end) => {
    let temp = { ...examSession };
    if (!start) {
      FailureAlert("Select Semester Start Month");
      return;
    }
    if (!end) {
      FailureAlert("Select Semester End Month");
      return;
    }
    if (!temp[type]) {
      temp[type] = [{ start_month: start, end_month: end }];
      setExamSession(temp);
    } else {
      FailureAlert(
        `${type} already added...\nDelete previous ${type} to change month!`
      );
    }
  };

  const deleteExamSession = (type) => {
    let temp = { ...examSession };
    delete temp[type];
    setExamSession(temp);
  };

  // Finding Job Types
  useEffect(() => {
    apiNiyuktiCall("/job/type/", requestNiyuktiOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].job_type,
          };
          tmpArray.push(obj);
        }
        setJobTypes(tmpArray);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities
  useEffect(() => {
    apiCall("/api/location/city/", requestPraveshOptions).then((json) => {
      setAllCities(json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Job Segment Finding API
  useEffect(() => {
    apiNiyuktiCall("/job/segment/", requestNiyuktiOptions).then((json) => {
      if (json.success) setAllJobSegments(json.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Country Finding API
  useEffect(() => {
    apiCall("/api/location/country/", requestPraveshOptions).then((res) => {
      setCountries(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // State Finding API
  useEffect(() => {
    apiCall(
      `/api/location/state/?search=${countryId}`,
      requestPraveshOptions
    ).then((res) => {
      setStates(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  // City Finding API
  useEffect(() => {
    apiCall(
      `/api/location/city/?search=${stateId}`,
      requestPraveshOptions
    ).then((res) => {
      setCities(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  // Education Type API
  useEffect(() => {
    apiCall("/api/education/type/", requestPraveshOptions).then((res) => {
      setEducation(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Degree Type API
  useEffect(() => {
    apiCall(
      `/api/education/degree/?admin=true&search=${educationId}`,
      requestPraveshOptions
    ).then((res) => {
      setDegrees(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationId]);

  // Specialization API
  useEffect(() => {
    apiCall(
      `/api/education/getspecialization/?admin=true&search=${degreeId}`,
      requestPraveshOptions
    ).then((res) => {
      if (res[0] && res[0].specialization !== null) {
        setSpecializations(res);
      } else if (res[0] && !res[0].specialization) {
        setSpecializations(null);
      } else {
        setSpecializations(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degreeId]);

  // University Finding API
  useEffect(() => {
    apiCall("/api/admin/university", requestPraveshOptions).then((res) => {
      if (res.success) {
        setUniversities(res.data);
      } else {
        FailureAlert("Invalid request for University API");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <div className="add__college">
      <Form
        className="add__college__form"
        id="add__college__form__details"
        onSubmit={(e) => {
          addCollege(e);
          // setLoading(true)
        }}
      >
        <h2>Institution Details</h2>
        <hr />
        <Form.Label>Select Institution Type* : </Form.Label>
        <Form.Group type="radio" id="institutionType" required>
          <Form.Check
            inline
            name="institutionType"
            type="radio"
            label="College"
            value="College"
            checked={institutionType === "College"}
            onChange={(e) => {
              setInstitutionType(e.target.value);
            }}
          />
          <Form.Check
            inline
            name="institutionType"
            type="radio"
            value="University"
            label="University"
            checked={institutionType === "University"}
            onChange={(e) => {
              setInstitutionType(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Label>Your institution is : {institutionType}</Form.Label>
        <Form.Row>
          <Form.Group controlId="tpoFirstName" as={Col}>
            <Form.Control
              name="tpoFirstName"
              type="text"
              placeholder="Person's First Name*"
              required
              value={tpoFirstName}
              onChange={(e) => {
                setTpoFirstName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="tpoLastName" as={Col}>
            <Form.Control
              name="tpoLastName"
              type="text"
              placeholder="Person's Last Name*"
              required
              value={tpoLastName}
              onChange={(e) => {
                setTpoLastName(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="tpoDesignation">
          <Form.Label>Person's Designation*</Form.Label>
          <Form.Control
            name="tpoDesignation"
            type="text"
            placeholder="Designation"
            required
            value={tpoDesignation}
            onChange={(e) => {
              setTpoDesignation(e.target.value);
            }}
          />
          {/* <Form.Control
                            as="select"
                            name="tpoDesignation" required
                            onChange={e => { setTpoDesignation(e.target.value) }}
                        >
                            <option value="">--select--</option>
                            <option value="TPO_ADMIN">TPO ADMIN</option>
                            <option value="TPO_MEMBER">TPO MEMBER</option>
                            <option value="TPO_COORDINATOR">TPO COORDINATOR</option>
                            <option value="PROFESSOR">PROFESSOR</option>
                            <option value="ASST_PROFESSOR">ASST. PROFESSOR</option>
                            <option value="LECTURER">LECTURER</option>
                            <option value="STUDENT">STUDENT</option>
                        </Form.Control> */}
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="tpoEmail">
            <Form.Control
              name="tpoEmail"
              type="email"
              placeholder="Email*"
              required
              value={tpoEmail}
              onChange={(e) => {
                setTpoEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="tpoContact">
            <Form.Control
              name="tpoContact"
              type="text"
              placeholder="Phone Number*"
              required
              pattern="[0-9]*"
              maxLength="10"
              minLength="10"
              value={tpoMobile}
              onChange={(e) => {
                setTpoMobile(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="pocEmail">
            <Form.Control
              name="altpocEmail"
              type="email"
              placeholder="TPO Alternate Email (optional)"
              value={altTpoEmail}
              onChange={(e) => {
                setAltTpoEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="pocContact">
            <Form.Control
              name="altpocContact"
              type="text"
              placeholder="TPO Alternate Phone Number (optional)"
              pattern="[0-9]*"
              maxLength="10"
              minLength="10"
              value={altTpoPhone}
              onChange={(e) => {
                setAltTpoPhone(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <hr />
        <hr />
        <Form.Group controlId="institutionName">
          <Form.Control
            name="institutionName"
            type="text"
            placeholder={`${institutionType} Name*`}
            required
            value={institutionName}
            onChange={(e) => {
              setInstitutionName(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="institutionNickName">
          <Form.Control
            name="institutionNickName"
            type="text"
            placeholder={`${institutionType} Nick Name*`}
            value={nickName}
            onChange={(e) => {
              setNickName(e.target.value);
            }}
            required
          />
        </Form.Group>
        <Form.Group controlId="institutionAddress">
          <Form.Control
            name="institutionAddress"
            as="textarea"
            placeholder={`${institutionType} Address*`}
            value={address}
            required
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Label>Select Organization Type* : </Form.Label>
        <Form.Group type="radio" id="organizationType" required>
          <Form.Check
            inline
            type="radio"
            name="organizationType"
            id="government"
            value="Government"
            label="Government"
            checked={organizationType === "Government"}
            onChange={(e) => {
              setOrganizationType(e.target.value);
            }}
          />
          <Form.Check
            inline
            type="radio"
            name="organizationType"
            id="semiGovernment"
            value="SemiPrivate"
            label="Semi Private"
            checked={organizationType === "SemiPrivate"}
            onChange={(e) => {
              setOrganizationType(e.target.value);
            }}
          />
          <Form.Check
            inline
            type="radio"
            name="organizationType"
            id="private"
            value="Private"
            label="Private"
            checked={organizationType === "Private"}
            onChange={(e) => {
              setOrganizationType(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="year">
            <Form.Label>Year Founded*</Form.Label>
            <Form.Control
              name="year"
              type="text"
              placeholder={`${institutionType} Year Founded*`}
              pattern="[0-9]*"
              maxLength="4"
              minLength="4"
              required
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="size">
            <Form.Label>College Size*</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setSize(e.target.value);
              }}
            >
              <option value="">--select--</option>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-100">51-100</option>
              <option value="101-1000">101-1000</option>
              <option value="1000+">1000+</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="instituteWebsiteUrl">
          <Form.Control
            name="instituteWebsiteUrl"
            type="text"
            placeholder={`${institutionType} Website URL*`}
            required
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Label>
          {institutionType} About*
          {aboutCount < 512 ? (
            <i style={{ fontSize: "13px", color: "green" }}>
              {" "}
              Total Characters: {aboutCount} out of 512
            </i>
          ) : (
            <i style={{ fontSize: "13px", color: "red" }}>
              {" "}
              Character Limit Exceeded (max 512 characters) : Character Count :{" "}
              {aboutCount}
            </i>
          )}
        </Form.Label>
        <Form.Group controlId="instituteAbout">
          <Form.Control
            name="instituteAbout"
            as="textarea"
            rows="2"
            placeholder={`${institutionType} About*`}
            required
            value={about}
            onChange={(e) => {
              setAbout(e.target.value);
              e.target.value && setAboutCount(characterCount(e.target.value));
              !e.target.value && setAboutCount(0);
            }}
          />
        </Form.Group>
        <Form.Label>{institutionType} Description* </Form.Label>
        <Form.Group controlId="instituteDescription">
          <Form.Control
            name="instituteDescription"
            as="textarea"
            rows="3"
            placeholder={`${institutionType} Description*`}
            required
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Grade System*</Form.Label>
          </Form.Group>
          {isGradeSystem && (
            <Form.Group as={Col}>
              <Form.Label>Percentage Factor*</Form.Label>
            </Form.Group>
          )}
        </Form.Row>
        <Form.Row className="placement_session_list">
          <Form.Group type="radio" id="isGradeSystem" required as={Col}>
            <Form.Check
              inline
              id="yes"
              type="radio"
              name="Grade System"
              label="CGPA"
              checked={isGradeSystem}
              onChange={(e) => setIsGradeSystem(true)}
            />
            <Form.Check
              inline
              id="no"
              type="radio"
              name="Grade System"
              label="Percentage"
              checked={!isGradeSystem}
              onChange={(e) => setIsGradeSystem(false)}
            />
          </Form.Group>
          {isGradeSystem && (
            <Form.Group as={Col}>
              <Form.Control
                name="percentageFactor"
                type="number"
                placeholder="out of 10/9.5/5"
                step="0.01"
                onChange={(e) => {
                  setPercentageFactor(e.target.value);
                }}
              />
            </Form.Group>
          )}
        </Form.Row>

        {institutionType === "College" ? (
          <Form.Group controlId="universityName">
            <Form.Label>University Name</Form.Label>
            {!universityNotListed ? (
              <div className="typeSearchUniversity">
                <Autocomplete
                  id="select-university"
                  options={universities}
                  getOptionLabel={(item) => item.university_name}
                  onChange={(e, item) => {
                    if (item) {
                      setUniversityId(item.university_id);
                      setUniversityName(item.university_name);
                    } else {
                      setUniversityId(null);
                      setUniversityName("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select University"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            ) : (
              <Form.Control
                name="universityName"
                type="text"
                placeholder="University Name*"
                required
                onChange={(e) => {
                  setUniversityName(e.target.value);
                  setUniversityId(null);
                }}
              />
            )}
            <Form.Check
              type="checkbox"
              label="University Not Listed"
              onChange={(e) => setUniversityNotListed(!universityNotListed)}
              checked={universityNotListed}
              style={{ marginLeft: "20px" }}
            />
          </Form.Group>
        ) : null}

        <Form.Label>Social Links : </Form.Label>
        <Form.Group controlId="linkedInPage">
          <Form.Control
            name="linkedInPage"
            type="text"
            placeholder={`${institutionType} LinkedIn Page`}
            value={linkedIn}
            onChange={(e) => {
              setLinkedIn(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="facebookPage">
          <Form.Control
            name="facebookPage"
            type="text"
            placeholder={`${institutionType} Facebook Page`}
            value={facebook}
            onChange={(e) => {
              setFacebook(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="instaAccount">
          <Form.Control
            name="instaAccount"
            type="text"
            placeholder={`${institutionType} Instagram Account`}
            value={insta}
            onChange={(e) => {
              setInsta(e.target.value);
            }}
          />
        </Form.Group>

        <h4>Location*</h4>
        <Form.Row className="typeSearchLocation">
          <Form.Group as={Col} controlId="country">
            <Autocomplete
              id="select-country"
              options={countries}
              getOptionLabel={(item) => item.country}
              onChange={(e, item) =>
                item ? setCountryId(item.country_id) : setCountryId(1)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Country"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="state">
            <Autocomplete
              id="select-state"
              options={states}
              getOptionLabel={(item) => item.state}
              onChange={(e, item) =>
                item ? setStateId(item.state_id) : setStateId(0)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select State"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="city">
            <Autocomplete
              id="select-city"
              options={cities}
              getOptionLabel={(item) => item.city}
              onChange={(e, item) =>
                item ? setCityId(item.city_id) : setCityId(0)
              }
              renderInput={(params) => (
                <TextField {...params} label="Select City" variant="outlined" />
              )}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row className="geoLocation">
          <Form.Group as={Col} controlId="longitude">
            <Form.Label>Longitude :</Form.Label>
            <Form.Control
              name="longitude"
              type="text"
              placeholder="Enter longitude"
              onChange={(e) => {
                setLongitude(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="latitude">
            <Form.Label>Latitude :</Form.Label>
            <Form.Control
              name="latitude"
              type="text"
              placeholder="Enter Latitude"
              onChange={(e) => {
                setLatitude(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <hr />
        <hr />
        <h4>Select Courses</h4>
        {/* 

                */}
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Education Type</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Degree</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Specialization</Form.Label>
          </Form.Group>
        </Form.Row>
        {courses.length > 0
          ? courses.map((course, key) => {
              return (
                <Form.Row key={key} className="courses_list">
                  <Form.Group as={Col}>
                    <Form.Control value={course.education || ""} readOnly />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Control value={course.degree || ""} readOnly />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Control
                      value={course.specialization || ""}
                      readOnly
                    />
                  </Form.Group>
                  <Button
                    onClick={() => deleteCourse(course.uid)}
                    variant="danger"
                    size="sm"
                  >
                    {" "}
                    Delete
                  </Button>
                </Form.Row>
              );
            })
          : null}
        <Form.Row className="typeSearchCourses">
          <Form.Group as={Col} controlId="education">
            <Autocomplete
              id="select-education"
              options={education || []}
              getOptionLabel={(item) => item.type}
              onChange={(e, item) => {
                if (item) {
                  setEducationId(item.id);
                  setEducationName(item.type);
                  setDegreeId(null);
                  setDegreeName("");
                } else {
                  setEducationId(null);
                  setEducationName("");
                  setDegreeId(null);
                  setDegreeName("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Select Education`}
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="degree">
            <Autocomplete
              id="select-degree"
              options={degrees || []}
              getOptionLabel={(item) => item.name}
              onChange={(e, item) => {
                if (item) {
                  setDegreeId(item.id);
                  setDegreeName(item.name);
                } else {
                  setDegreeId(null);
                  setDegreeName("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Select Degree`}
                  variant="outlined"
                />
              )}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="specialization">
            <Autocomplete
              id="select-specialization"
              options={specializations || []}
              getOptionLabel={(item) => item.specialization.name}
              onChange={(e, item) => {
                if (item) {
                  setSpecializationId(item.specialization.id);
                  setSpecializationName(item.specialization.name);
                } else {
                  setSpecializationId(null);
                  setSpecializationName("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Select Specialization`}
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Button
            onClick={(e) =>
              addCourse(
                e,
                educationId,
                degreeId,
                specializationId,
                educationName,
                degreeName,
                specializationName
              )
            }
            variant="info"
            size="sm"
            style={{
              width: "10%",
              marginLeft: "2%",
              marginTop: "1%",
              padding: "8px 8px",
              height: "100%",
            }}
          >
            {" "}
            Add
          </Button>
        </Form.Row>
        {/* <Form.Row className="courses">
                        <Form.Group as={Col} controlId="educationType">
                            <Form.Control 
                                as="select"
                                onChange={(e)=>{
                                    setEducationId(JSON.parse(e.target.value).id)
                                    setEducationName(JSON.parse(e.target.value).type)
                                    setDegreeId(null)
                                    setDegreeName('')
                                }}
                            >
                                <option value={JSON.stringify({"id":null,"type":'--select--'})}>--select--</option>
                            {
                                education.map((e, key) => {
                                return <option key={key} value={JSON.stringify(e)}> {e.type} </option>
                                })
                            }    
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="degree">
                            <Form.Control 
                                as="select"
                                value={(!degreeId ? "--select--" : null)}
                                onChange={(e)=>{
                                    setDegreeId(JSON.parse(e.target.value).id)
                                    setDegreeName(JSON.parse(e.target.value).name)
                                }}
                            >
                                <option value={JSON.stringify({"id":null,"name":'--select--'})}>--select--</option>
                            {
                                degrees.map((d, key) => {
                                return <option key={key} value={JSON.stringify(d)}>{d.name}</option>
                                })
                            }  
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="specialization">
                            <Form.Control 
                                as="select" 
                                value={(!specializationId ? "--select--" : null)}
                                onChange={(e)=>{
                                    setSpecializationId(JSON.parse(e.target.value).specialization.id)
                                    setSpecializationName(JSON.parse(e.target.value).specialization.name)
                                }}
                            >
                                <option value={JSON.stringify({"id":null,"name":'--select--'})}>--select--</option>
                            {
                                specializations!==null ? specializations.map((s, key) => {
                                return <option key={key} value={JSON.stringify(s)}>{s.specialization.name}</option>
                                }): null
                            }  
                            </Form.Control>
                        </Form.Group>
                        <Button 
                            onClick={(e) => 
                                addCourse(e, educationId, degreeId, specializationId, educationName, degreeName, specializationName)
                            } 
                            variant="info" size="sm"
                        > Add
                        </Button>
                        
                    </Form.Row> */}
        <hr />
        <hr />
        <h4>Preferences</h4>
        <Form.Label>
          <i>
            <u>Placement Session : </u>
          </i>
        </Form.Label>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Placement Type</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Start Month</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>End Month</Form.Label>
          </Form.Group>
        </Form.Row>
        {Object.keys(placementSession).length > 0
          ? Object.keys(placementSession).map((type) => {
              let p = placementSession[type];
              return (
                <Form.Row key={type} className="placement_session_list">
                  <Form.Group as={Col}>
                    <Form.Control value={type || ""} readOnly />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Control
                      value={searchMonth[p[0].start_month] || ""}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Control
                      value={searchMonth[p[0].end_month] || ""}
                      readOnly
                    />
                  </Form.Group>
                  <Button
                    onClick={() => deletePlacementSession(type)}
                    variant="danger"
                    size="sm"
                  >
                    {" "}
                    Delete
                  </Button>
                </Form.Row>
              );
            })
          : null}
        <Form.Row className="placement_session_list">
          <Form.Group as={Col}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setPlacementType(JSON.parse(e.target.value).value);
              }}
            >
              <option value={JSON.stringify({ id: null, value: null })}>
                --select--
              </option>
              {placementTypes.map((e, key) => {
                return (
                  <option key={key} value={JSON.stringify(e)}>
                    {" "}
                    {e.value}{" "}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setPlacementStart(JSON.parse(e.target.value).id);
              }}
            >
              <option value={JSON.stringify({ id: null, value: "--select--" })}>
                --select--
              </option>
              {monthArray.map((e, key) => {
                return (
                  <option key={key} value={JSON.stringify(e)}>
                    {" "}
                    {e.value}{" "}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setPlacementEnd(JSON.parse(e.target.value).id);
              }}
            >
              <option value={JSON.stringify({ id: null, value: "--select--" })}>
                --select--
              </option>
              {monthArray.map((e, key) => {
                return (
                  <option key={key} value={JSON.stringify(e)}>
                    {" "}
                    {e.value}{" "}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Button
            onClick={() =>
              addPlacementSession(placementType, placementStart, placementEnd)
            }
            variant="info"
            size="sm"
          >
            {" "}
            Add
          </Button>
        </Form.Row>

        <Form.Label>
          <i>
            <u>Exam Session : </u>
          </i>
        </Form.Label>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Semester</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Start Month</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>End Month</Form.Label>
          </Form.Group>
        </Form.Row>
        {Object.keys(examSession).length > 0
          ? Object.keys(examSession).map((type, key) => {
              let p = examSession[type];
              return (
                <Form.Row key={type} className="placement_session_list">
                  <Form.Group as={Col}>
                    <Form.Control value={type || ""} readOnly />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Control
                      value={searchMonth[p[0].start_month] || ""}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Control
                      value={searchMonth[p[0].end_month] || ""}
                      readOnly
                    />
                  </Form.Group>
                  <Button
                    onClick={() => deleteExamSession(type)}
                    variant="danger"
                    size="sm"
                  >
                    {" "}
                    Delete
                  </Button>
                </Form.Row>
              );
            })
          : null}
        <Form.Row className="placement_session_list">
          <Form.Group as={Col}>
            <Form.Control
              value={`Semester ${Object.keys(examSession).length + 1}`}
              readOnly
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setSemStart(JSON.parse(e.target.value).id);
              }}
            >
              <option value={JSON.stringify({ id: null, value: "--select--" })}>
                --select--
              </option>
              {monthArray.map((e, key) => {
                return (
                  <option key={key} value={JSON.stringify(e)}>
                    {" "}
                    {e.value}{" "}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setSemEnd(JSON.parse(e.target.value).id);
              }}
            >
              <option value={JSON.stringify({ id: null, value: "--select--" })}>
                --select--
              </option>
              {monthArray.map((e, key) => {
                return (
                  <option key={key} value={JSON.stringify(e)}>
                    {" "}
                    {e.value}{" "}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Button
            onClick={() =>
              addExamSession(
                `Semester ${Object.keys(examSession).length + 1}`,
                semStart,
                semEnd
              )
            }
            variant="info"
            size="sm"
          >
            {" "}
            Add
          </Button>
        </Form.Row>

        <Form.Label>
          <i>
            <u>Preference : </u>
          </i>
        </Form.Label>
        <Box
          className="typeSearchPreferences"
          boxShadow={2}
          style={{ padding: "16px 1rem", marginBottom: "1rem" }}
        >
          <Form.Group>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-job_type"
              options={jobTypes}
              getOptionLabel={(item) => item.name}
              onChange={(e, i) =>
                i ? setSelectedJobType(i) : setSelectedJobType({})
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Job Type"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-experience"
              options={workExperiences}
              getOptionLabel={(item) => item.name}
              onChange={(e, i) =>
                i
                  ? setSelectedExperience(uniqueArray(i))
                  : setSelectedExperience([])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Work Experience"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-cities"
              options={allCities}
              getOptionLabel={(item) => item.city}
              onChange={(e, i) =>
                i ? setSelectedCities(i) : setSelectedCities([])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Cities"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
        </Box>
        <Box>
          <Form.Label>Job Segments* : </Form.Label>
          <Form.Group className="typeSearchSegment">
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-job-segments"
              options={allJobSegments}
              getOptionLabel={(item) => item.job_segment}
              onChange={(e, item) =>
                item ? setSelectedJobSegments(item) : setSelectedJobSegments([])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Job Segments"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>

          <Form.Group controlId="minCTC">
            <NumberFormat
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              prefix={"₹"}
              className="form-control"
              required
              name="minCTC"
              type="text"
              placeholder="Minimum CTC (in rupees)*"
              value={minCtc}
              onValueChange={(values) => {
                const { value } = values;
                setMinCtc(value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="avgCTC">
            <NumberFormat
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              prefix={"₹"}
              className="form-control"
              required
              name="avgCTC"
              type="text"
              placeholder="Average CTC (in rupees)*"
              value={avgCtc}
              onValueChange={(values) => {
                const { value } = values;
                setAvgCtc(value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Control
              name="message"
              as="textarea"
              rows="3"
              placeholder="Message for Recruiters (optional)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
        </Box>

        {/* <Form.Label>Job Segments* : </Form.Label>
          <Form.Group className="typeSearchSegment">
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-job-segments"
              options={allJobSegments}
              getOptionLabel={(item) => item.job_segment}
              onChange={(e, item) => (item ? setSelectedJobSegments(item) : setSelectedJobSegments([]))}
              renderInput={(params) => <TextField {...params} label="Select Job Segments" variant="outlined" />}
            />
          </Form.Group>

          <Form.Group controlId="minCTC">
            <NumberFormat
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              prefix={"₹"}
              className="form-control"
              required
              name="minCTC"
              type="text"
              placeholder="Minimum CTC (in rupees)*"
              onValueChange={(values) => {
                const { value } = values;
                setMinCtc(value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="avgCTC">
            <NumberFormat
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              prefix={"₹"}
              className="form-control"
              required
              name="avgCTC"
              type="text"
              placeholder="Average CTC (in rupees)*"
              onValueChange={(values) => {
                const { value } = values;
                setAvgCtc(value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Control name="message" as="textarea" rows="3" placeholder="Message for Recruiters (optional)" onChange={(e) => setMessage(e.target.value)} />
          </Form.Group> */}
        <Form.Label>
          <u>Onboard*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="onboard" required>
          <Form.Check
            inline
            id="yes"
            type="radio"
            name="onboard"
            label="Yes"
            checked={onboard}
            onChange={(e) => setOnboard(!onboard)}
          />
          <Form.Check
            inline
            id="no"
            type="radio"
            name="onboard"
            label="No"
            checked={!onboard}
            onChange={(e) => setOnboard(!onboard)}
          />
        </Form.Group>
        <Form.Label>
          <u>SAAS College*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="isSaas" required>
          <Form.Check
            inline
            id="yes"
            type="radio"
            name="SAAS College"
            label="Yes"
            checked={isSaas}
            onChange={(e) => setIsSaas(!isSaas)}
          />
          <Form.Check
            inline
            id="no"
            type="radio"
            name="SAAS College"
            label="No"
            checked={!isSaas}
            onChange={(e) => setIsSaas(!isSaas)}
          />
        </Form.Group>
        <Form.Label>
          <u>Paid*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="paid" required>
          <Form.Check
            inline
            id="yes"
            type="radio"
            name="paid"
            label="Yes"
            checked={paid}
            onChange={(e) => setPaid(!paid)}
          />
          <Form.Check
            inline
            id="no"
            type="radio"
            name="paid"
            label="No"
            checked={!paid}
            onChange={(e) => setPaid(!paid)}
          />
        </Form.Group>
        <Form.Label>
          <u>Active*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="active" required>
          <Form.Check
            inline
            id="yes"
            type="radio"
            name="active"
            label="Yes"
            checked={active}
            onChange={(e) => setActive(!active)}
          />
          <Form.Check
            inline
            id="no"
            type="radio"
            name="active"
            label="No"
            checked={!active}
            onChange={(e) => setActive(!active)}
          />
        </Form.Group>
        <Form.Label>
          <u>Immediate Joining*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="immediateJoining" required>
          <Form.Check
            inline
            id="yes"
            type="radio"
            name="immediateJoining"
            label="Yes"
            checked={immediateJoining}
            onChange={(e) => setImmediateJoining(!immediateJoining)}
          />
          <Form.Check
            inline
            id="no"
            type="radio"
            name="immediateJoining"
            label="No"
            checked={!immediateJoining}
            onChange={(e) => setImmediateJoining(!immediateJoining)}
          />
        </Form.Group>
        {isSaas && (
          <Form.Group controlId="GetWorkDomain" required>
            <Form.Label>GetWork Domain(For SAAS College Only):</Form.Label>
            <Form.Control
              name="GetWork Domain"
              type="text"
              placeholder="GetWork Domain"
              onChange={(e) => {
                setGetworkDomain(e.target.value);
              }}
            />
          </Form.Group>
        )}
        <br />
        <Form.Row>
          <Form.Group as={Col} controlId="trial">
            <Form.Label>Trial Period Start Date*</Form.Label>
            <Form.Control
              name="trial"
              type="date"
              placeholder="YYYY-MM-DD"
              required
              value={trial}
              onChange={(e) => {
                setTrial(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="trial">
            <Form.Label>Trial Period End Date*</Form.Label>
            <Form.Control
              name="trial"
              type="date"
              placeholder="YYYY-MM-DD"
              min={trial ? trial : ""}
              required
              value={trialEnd}
              onChange={(e) => {
                setTrialEnd(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="GetWorkDomain" required>
            <Form.Label>Passcode *:</Form.Label>
            <Form.Control
              className="passcode"
              name="passcode"
              type="text"
              placeholder="passcode"
              value={passcode}
              onChange={(e) => {
                setPasscode(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="studentLicenseCount" required>
            <Form.Label>Student License Count:</Form.Label>
            <Form.Control
              className="studentLicenseCount"
              name="studentLicenseCount"
              type="number"
              placeholder="Student License Count"
              value={studentLicenseCount}
              onChange={(e) => {
                setStudentLicenseCount(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="planStartDate" required>
            <Form.Label>Plan Start Date:</Form.Label>
            <Form.Control
              className="planStartDate"
              name="planStartDate"
              type="date"
              value={planStartDate}
              onChange={(e) => {
                setPlanStartDate(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="planEndDate" required>
            <Form.Label>Plan End Date:</Form.Label>
            <Form.Control
              className="planEndDate"
              name="planEndDate"
              type="date"
              value={planEndDate}
              onChange={(e) => {
                setPlanEndDate(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        {isSaas ? (
          <Form.Group controlId="GetWorkDomain" required>
            <Form.Label>GetWork Domain(For SAAS College Only):</Form.Label>
            <Form.Control
              name="GetWork Domain"
              type="text"
              placeholder="GetWork Domain"
              value={getworkDomain}
              onChange={(e) => {
                setGetworkDomain(e.target.value);
              }}
            />
          </Form.Group>
        ) : null}
        <br />
        <Form.Row>
          <Form.Group as={Col} controlId="payment">
            <Form.Label>Payment Pending (optional)</Form.Label>
            <NumberFormat
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              prefix={"₹"}
              className="form-control"
              name="payment"
              type="text"
              placeholder="Enter Amount"
              value={payment}
              onValueChange={(values) => {
                const { value } = values;
                setPayment(value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <br />

        {isSaas ? (
          <Form.Group controlId="primaryColor" required>
            <Form.Label>
              Select Primary Color(For SAAS College Only):
            </Form.Label>
            <ColorPicker color={primaryColor} setColor={setPrimaryColor} />
          </Form.Group>
        ) : null}
        <br />

        {isSaas ? (
          <Form.Group controlId="secondaryColor" required>
            <Form.Label>
              Select Secondary Color(For SAAS College Only):
            </Form.Label>
            <ColorPicker color={secondaryColor} setColor={setSecondaryColor} />
          </Form.Group>
        ) : null}
        <br />
        <>
          <Form.File id="collegeLogo">
            <Form.File.Label>
              Upload {institutionType} {isSaas ? "Rectangular" : "Main"} Logo*
            </Form.File.Label>
            <Form.File.Input onChange={(e) => setLogo(e.target.files[0])} />
            <p>Recommended Size: 150px*150px</p>
          </Form.File>
          <br />
        </>

        <Form.File id="collegeBanner">
          <Form.File.Label>Upload {institutionType} Banner*</Form.File.Label>
          <Form.File.Input onChange={(e) => setBanner(e.target.files[0])} />
          <p>Recommended Size: 860px*250px</p>
        </Form.File>
        <br />

        <Form.File id="collegeLogo">
          <Form.File.Label>
            Upload {institutionType} Circular Logo*
          </Form.File.Label>
          <Form.File.Input
            onChange={(e) => setCircularLogo(e.target.files[0])}
          />
          <p>Recommended Size: 50px*50px</p>
        </Form.File>
        <br />
        {isSaas ? (
          <>
            <Form.File id="collegeSideImage">
              <Form.File.Label>
                Upload {institutionType} SideImage(For SAAS Colleges Only)
              </Form.File.Label>
              <Form.File.Input
                onChange={(e) => setSideImage(e.target.files[0])}
              />
              <p>Recommended Size: 720px*700px</p>
            </Form.File>
            <br />
            <Form.File id="collegeLogo">
              <Form.File.Label>
                Upload {institutionType} Favicon (.ico file)*
              </Form.File.Label>
              <Form.File.Input
                onChange={(e) => {
                  if (e.target.files[0]?.name?.includes("ico"))
                    setFavicon(e.target.files[0]);
                  else FailureAlert("Please Enter .ico extension file");
                }}
              />
              <p>Recommended Size: 16px*16px</p>
            </Form.File>
          </>
        ) : null}
        <br />
        <br />
        <Button type="submit" variant="info">
          Add College
        </Button>
      </Form>
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default AddCollege;
