import {
  Checkbox,
  FormGroup, Grid,
  TextField, Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { emailRegex } from "../../../constants";
import { praveshAxiosGetReq, praveshAxiosPatchReq, praveshAxiosPostReq } from "../../../util/baseApi";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import CustomButtonCircularProgress from "../../Student/customLoader";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function AddTeamMember({open,memberData,setOpen,fromRowEdit=false,updateTable}) {

  const data = [
                  { title: "Admin",
                    id:10,
                },
                  { title: "Custom User",
                    id:14,
                },          
          ];
  const StatusData = [{ status: "Active",
                    flag:true,
                },
                  { status: "Inactive",
                    flag:false,
                }, ];

  const fullWidth = true;
  const maxWidth = "md"; 
  const [scroll, setScroll] = React.useState("paper");
  const [loadingSubmit, setLoadingSubmit] = useState(false);


  const [userAccessControl, setUserAccessControl] = useState([]);  
  const [userAccessFields, setUserAccessFields] = useState(null);
  const [selectedAll, setSelectedAll] = useState(false);


  const initialTeamMemberData = {
    userRoleId:null,
    Name:null,
    email:null,
    phone:null,
    designation:null,
    userRole:null,
    userActive:null,
    userPassword:null,
  };
  const [newTeamMemberData, setNewTeamMemberData] = useState(initialTeamMemberData);

  const  preFillUserAccessRoles = (access) =>{

    if(userAccessFields?.length > 0){
      
      const temp = userAccessFields?.map((item2) => {
        
        let subsectionCheck = false;
        let count =0;
        const subsection = item2?.subsection?.map((item3) => { 
          
           const match =   access?.find((item) => {

                    if(item?.menu_key === item3?.key ){
                      return true
                    }
                    else
                    {
                      return false
                    } 
              });
          
          if(match){
                ++count;
                   return({...item3,selected:true})  
                 }else{
                  return({...item3,selected:false}) 
                 }    
            
            })
          
            if(item2?.subsection?.length === count){

              subsectionCheck= true;              
            }

          return(
            {...item2,subsection:subsection,selected:subsectionCheck }
          )

        });

      setUserAccessFields(temp); 

        }

       
};

const setCustomRole = () => {
   if(!fromRowEdit) {return { title: null,
                    id:null,
                }};

  const role = data.filter((item) => {if(item.title === memberData?.role) return true});
  return role[0]

}

const setStatus = () => {

  // console.log(memberData?.is_blocked === "Yes" ? false : true);
 const status = StatusData.filter((item) => 
 {if(item.flag === (memberData?.is_blocked === "Yes" ? true : false)) 
       return true});
  // console.log(status);

  return status[0]  


}

// for prefilling data with previous data passed.
  useEffect(() =>{

          // if(fromRowEdit && memberData?.name){
          if(fromRowEdit ){         
          setNewTeamMemberData((previousdata) => { return{...previousdata, userRole: memberData?.role }} );
          setNewTeamMemberData((previousdata) => { return{...previousdata, Name: memberData?.name}} );
          setNewTeamMemberData((previousdata) => { return{...previousdata,email: memberData?.email}});
          setNewTeamMemberData((previousdata) => { return{...previousdata,phone: memberData?.phone}});
          setNewTeamMemberData((previousdata) => { return{...previousdata, designation: memberData?.designation}});
          setNewTeamMemberData((previousdata) => { return{...previousdata, userRoleId: setCustomRole()?.id }});
          preFillUserAccessRoles(memberData?.access);
         
        };
  },[open,memberData]);

  
 
  useEffect(() => {

    if(userAccessControl?.length > 0){

       const initialAccessState = userAccessControl?.map((item) => {

       const subsection = item?.subsection?.map((item2) => {return({...item2, selected:false})});

        return(
          { ...item, 
            selected:false,
            subsection: subsection,

          }
        )
      });

      setUserAccessFields(initialAccessState);

    }
  },[userAccessControl]);

  const  handleSelectionAll = () =>{

  setSelectedAll(!selectedAll);
  const temp = userAccessFields?.map((item2) => {
          const subsection = item2?.subsection?.map((item3) => {       

              return({...item3,selected:!selectedAll})            
            
            })
          return(
            {...item2,subsection:subsection, selected: !selectedAll}

          )

        });

      setUserAccessFields(temp); 
};


const  handleResetAll = () =>{

  setSelectedAll(false);
  const temp = userAccessFields?.map((item2) => {
          const subsection = item2?.subsection?.map((item3) => {       

              return({...item3,selected:false})            
            
            })

          return(
            {...item2,subsection:subsection, selected:false}

          )

        });

      setUserAccessFields(temp); 
};

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setNewTeamMemberData(initialTeamMemberData);    
    handleResetAll();

  };

 

  useEffect(() => {      
    
   
      const request = Axios.CancelToken.source() // (*)

       const getUserAccessControls = async() => {

              const endPoint = "/api/admin/sidebar/dropdown/";

              try{
                const res = await praveshAxiosGetReq(endPoint,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Token ${localStorage.getItem("token")}`,
                    },
                     cancelToken: request.token, // (*)
                  }
                 );
 
                if(res?.data?.success){

                  setUserAccessControl(res?.data?.data);

                }else{
                  // FailureAlert("Something went Wrong!")
                }
              }
              catch(error){

                throw(error);
              }

       };
      
      getUserAccessControls();
      return () => request.cancel() // (*)
   

  },[]);



  const handleSectionSelection = (e,item) => {

   
    const arr = userAccessFields.map((item2) => {      

       if(item2?.section === item?.section){

         const subsection = item2?.subsection?.map((item3) => {return({...item3,selected :! item?.selected })});

        return ({...item2, 
          selected : ! item?.selected,
          subsection: subsection
        })
       }else{
        return item2;
       }
      
      });

    setUserAccessFields(arr);

  };

 const handleSubSectionSelection = (e, item) => {
        
        const temp = userAccessFields?.map((item2) => {

          const subsection = item2?.subsection?.map((item3) => {
            
            if(item3?.id === item?.id){

              return({...item3,selected:!item?.selected})

            }else{
              return(item3)
            }
            
            })

          return(
            {...item2,subsection:subsection }
          )
        });

      setUserAccessFields(temp);

  };
// const checkPasswordCase = () => {

//   let flag = true;

//   // null values of password in patch/edit is allowed

//   if(fromRowEdit){

//     newTeamMemberData.userPassword ===null ? flag = false : flag =true

//   }
//   return flag

// }

const handleSubmit = async() => {

  const validate = () => {

    if(!newTeamMemberData.Name) {
      FailureAlert("Please enter the Name!");
      return false
    };
    if(!newTeamMemberData.userRoleId ) {
      FailureAlert("Please enter the UserRole!");
      return false
    };
    if(!newTeamMemberData.designation) {
      FailureAlert("Please enter the User Designation !");
      return false
    };
    if(!newTeamMemberData.phone) {
      FailureAlert("Please enter the valid phone No!");
      return false
    };

    // if patch it won't check for password! or can take null.
    // if(checkPasswordCase()){

    //   if(!newTeamMemberData.userPassword || !passwordRegex.test(newTeamMemberData.userPassword)) {
    //   FailureAlert("Password should contain min 8 chars including number, small & capital alphabets!");
    //   return false
    // };
    // } 
  
    
    if(!newTeamMemberData.email || !emailRegex.test(newTeamMemberData.email)) {

      FailureAlert("Please enter the valid email Address!");
      return false
    }else{

      return true;
    };

  }

  if(validate()) {

    setLoadingSubmit(true);

    let accessKeys = [];
    const accessKeysCheck = userAccessFields?.map((item) => {

      const list = item?.subsection?.map((item2) => {
        if(item2?.selected){
         accessKeys.push(item2?.id);
        }
      })

      return list
    });

    accessKeys = accessKeys.filter(item => item);    

    const data = {
       "full_name":newTeamMemberData.Name,
       "user_email":newTeamMemberData.email,
       "phone":newTeamMemberData.phone,
       "access_keys":accessKeys,
        user_role:newTeamMemberData.userRoleId,
        user_designation:newTeamMemberData.designation,
        user_active: newTeamMemberData.userActive ? newTeamMemberData.userActive : memberData?.active ,
        // user_password:newTeamMemberData.userPassword ? newTeamMemberData.userPassword : null,
    }
if(fromRowEdit){

try{
    const endPoint = `/api/admin/create/custom_user/${memberData?.user_id}/`;

     const res = await praveshAxiosPatchReq(endPoint, data);

     if(res?.data?.success){

          SuccessAlert("Your data is submitted!");
          handleClose();
          setNewTeamMemberData(initialTeamMemberData);    
          handleResetAll();
          updateTable();
          setLoadingSubmit(false);


     }else{
      FailureAlert(`${res?.data?.error}`);
      setLoadingSubmit(false);

      
     }
  }
  catch(error){
    throw(error);
  }

}else{

try{
    const endPoint = '/api/admin/create/custom_user/';

     const res = await praveshAxiosPostReq(endPoint, data);

     if(res?.data?.success){

          SuccessAlert("Your data is submitted!");
          handleClose();
          setNewTeamMemberData(initialTeamMemberData);    
          handleResetAll();
          updateTable();
          setLoadingSubmit(false);

     }else{
      FailureAlert(`${res?.data?.error}`); 
      setLoadingSubmit(false);
     
     }
  }
  catch(error){

    setLoadingSubmit(false);
    throw(error);

  }
}  

  }
}

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
    {
      fromRowEdit ? null :
      <Button
        variant="contained"
        style={{ backgroundColor: "#17A2B8", color: "#fff" }}
        color="primary"
        onClick={handleClickOpen("paper")}
      >
        Add New
      </Button>
    }
      
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent dividers={scroll === "paper"}>
          <Grid container spacing={2}>
            <Grid item md={6} style={{ borderRight: "2px solid #AFAFAF" }}>
              <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Typography variant="h6">Add New Team Member</Typography>
                <div style={{ marginTop: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Autocomplete
                        id="combo-box-demo"
                        defaultValue={setCustomRole}
                        options={data}
                        getOptionLabel={(option) => option.title}                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="User Role* "
                            variant="outlined"
                          />
                        )}
                        onChange = {(e,value) => {
                          setNewTeamMemberData((newTeamMemberData) => { return{...newTeamMemberData, userRoleId
                            : value?.id}});
                          setNewTeamMemberData((newTeamMemberData) => { return{...newTeamMemberData, userRole: value?.title}});                        
                           
                        }}
                      />
                    </Grid>
                    
                    <Grid item md={12} style={{ display: "grid" }}>
                      <TextField 
                       label="Name*"
                       variant="outlined" 
                       value={newTeamMemberData.Name}
                       onChange = {(e) => {
                          setNewTeamMemberData({...newTeamMemberData, Name
                            :e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <TextField 
                      label="Email ID*" 
                      type="email"
                      value={newTeamMemberData.email}

                      variant="outlined"
                      onChange = {(e) => {
                          setNewTeamMemberData({...newTeamMemberData, email
                            :e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <TextField label="Phone Number*" 
                      variant="outlined"
                      value={newTeamMemberData.phone}
                      inputProps={{
                                    maxlength: 10
                                  }}
                       onChange = {(e) => {
                          setNewTeamMemberData({...newTeamMemberData, phone
                            :e.target.value });
                        }}
                      />
                    </Grid>
                    {/* <Grid item md={12} style={{ display: "grid" }}>
                      <TextField label="Password*" 
                      variant="outlined"
                      inputProps={{
                                    maxlength: 20
                                  }}
                       onChange = {(e) => {
                          setNewTeamMemberData({...newTeamMemberData, userPassword
                            :e.target.value });
                        }}
                      />
                    </Grid> */}
                    <Grid item md={12} style={{ display: "grid" }} >

                         <TextField
                          label="Designation*" 
                          variant="outlined"
                          value={newTeamMemberData.designation}
                          onChange = {(e) => {
                          setNewTeamMemberData({...newTeamMemberData, designation
                            :e.target.value });
                        }}
                         />
                      {/* <Autocomplete
                        id="combo-box-demo"
                        options={data}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Designation"
                            variant="outlined"
                          />
                        )}
                      /> */}
                    </Grid>

                    {
                      fromRowEdit ? <Grid item md={12}>
                      <Autocomplete
                        id="combo-box-demo2"
                        defaultValue={setStatus}

                        // defaultValue={setCustomRole}
                        options={StatusData}
                        getOptionLabel={(option) => option.status}                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Active/Inactive* "
                            variant="outlined"
                          />
                        )}
                        onChange = {(e,value) => {
                          setNewTeamMemberData((newTeamMemberData) => { return{...newTeamMemberData, userActive
                            : value?.flag}});                                                
                           
                        }}
                      />
                    </Grid>  : null

                    }
                     
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Typography variant="h6">User Access Control</Typography>
                <div style={{ marginTop: "15px" }}>
                  <FormControlLabel
                    value="Select all"
                    control={
                    <Checkbox 
                    color="primary"
                    checked={selectedAll}
                    onChange={(e) => {
                      handleSelectionAll(e);
                    }}
                      />}
                    label={
                      <Typography style={{ fontSize: "16px" }}>
                        Select all
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  
                  <section>
                         <FormGroup aria-label="position" column>
                          {
                            userAccessFields?.map((item) => {
                              return(
                                <>                                
                                
                                 <FormControlLabel
                                        value="Select all"
                                        control={
                                        <Checkbox 
                                        color="primary" 
                                        checked={item?.selected}
                                        onChange={(e) => {
                                          handleSectionSelection(e, item);
                                        }}
                                                                                
                                         />}
                                        
                                        label={
                                          <Typography style={{ fontSize: "16px" ,fontWeight:"bold"}}>
                                            {item?.section}
                                          </Typography>
                                        }
                                        // onClick={handleSectionSelection}
                                        labelPlacement="end"
                                  />

                                {
                                  item?.subsection?.map((item2) => { return(

                                     <FormControlLabel
                                        value="Select all"
                                        control={<Checkbox 
                                          color="primary" 
                                          style={{marginLeft:"25px"}}
                                          checked={item2?.selected}
                                          onChange={(e) => {
                                          handleSubSectionSelection(e, item2);
                                        }}
                                          // onClick={handleSectionSelection}
                                          
                                          />}
                                        label={
                                          <Typography style={{ fontSize: "14px" }}>
                                            {item2?.subsection}
                                          </Typography>
                                        }
                                        labelPlacement="end"
                                        // onChange={handleSectionSelection}
                                      />

                                  )})
                                }                               
                                
                                </>
                                 
                              )
                            })                      

                          }                           

                         </FormGroup>
                  </section>
                  
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary"
          onClick={handleSubmit}
          
          >
          {loadingSubmit ? <CustomButtonCircularProgress/> : "Submit"}  
          </Button>

          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


