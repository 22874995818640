import { Button, Dialog, Grid, Typography } from "@material-ui/core";
import React, {useState} from "react";
import { niyuktiAxiosCall } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import CustomButtonCircularProgress from "../../Student/Components/loader";

export default function DeleteDialog({ open, handleClose, handleClickOpen, interviewId, onDelete, index }) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [loader, setLoader] = useState(false);

  const deleteCall = () => {
    setLoader(true);
    niyuktiAxiosCall.delete(`/api/manage_jobs/admin/interview/?interview_id=${interviewId}`).then(res => {
      if(res?.data?.success){
        SuccessAlert("Interview deleted successfully!");
        onDelete(index);
        handleClose();
      }else{
        FailureAlert("Something went wrong!")
      } 
      setLoader(false);
    }).catch(err => {
      FailureAlert("Something went wrong!")
      setLoader(false);
    })
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div style={{ padding: 20 }}>
          <Typography
            variant="body1"
            style={{ textAlign: "center", color: "#000" }}
          >
            Are you sure you want to Delete the <br />
            scheduled interview ?
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ width: "100%", margin: 0, marginTop: 10 }}
          >
            <Grid item md={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{
                  borderColor: "#6C757D",
                  boxShadow: "none",
                  height: 45,
                }}
              >
                <Typography variant="body2" style={{ color: "#6C757D" }}>
                  No
                </Typography>
              </Button>
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                onClick={deleteCall}
                style={{
                  backgroundColor: "#EF4444",
                  boxShadow: "none",
                  height: 45,
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {loader ? <CustomButtonCircularProgress /> : "Yes"}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
