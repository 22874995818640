import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/EditCollegeDetails.css';
import {Form} from 'react-bootstrap';
import { FailureAlert } from '../../util/Notifications';
import { EditCompanySection } from './EditCompanySection';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import { requestPraveshOptions } from '../../constants';

function EditCompanyDetails() {
    const [companyId, setCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);
    
    // Fetch all Companies Dropdown
    useEffect(() => {
        apiCall('/api/admin/company/get/', requestPraveshOptions)
        .then((res)=> {
            if(res.success) {
                setCompanies(res.data)
            }
            else {
                FailureAlert(res.error)
            }
        })
    }, []);

    return (
        <div className="edit__company__details">
            <Form className="edit__company__details__form" >
                <Autocomplete
                    id="select-user"
                    options={companies}
                    getOptionLabel={(item) => item.company_name}
                    style={{ width: '90%', margin: '5%' }}
                    onChange={(e, item)=>item ? setCompanyId(item.company_id) : setCompanyId(null)}
                    renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                />
            </Form>
            {
                !companyId ? null : <EditCompanySection cmpId = {companyId} />
            }
        </div>
    )
}

export default EditCompanyDetails;