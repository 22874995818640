import React, { useState, useEffect } from 'react';
import {apiNiyuktiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestNiyuktiOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

function JobRole() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [groups, setGroups] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [roleGroup, setRoleGroup] = useState(null);
    const [patchGroup, setPatchGroup] = useState(null);
    const [loading, setLoading] = useState(false);

    // Role Groups Finding API
    useEffect(() => {
        apiNiyuktiCall('/job/role_group/', requestNiyuktiOptions)
        .then((res)=> {
            setGroups(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading(true);
        apiNiyuktiCall('/job/role/?verbose=required', requestNiyuktiOptions)
          .then(json => {
                if(json.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            id:                   parseInt(json.data[i].id),
                            job_role_group_name:  json.data[i].job_role_group_name,
                            job_role_name:        json.data[i].job_role_name, 
                        }
                        tmpArray.push(obj)
                    }
                    setLoading(false);
                    setData(tmpArray);
                }
                else {
                    FailureAlert(json.error)
                }    
           })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
            job_role_group: roleGroup,
            job_role_name: roleName
        })
    };

    const columns = [
        { title: "Job Role ID",            field: "id",                   headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Job Role Name",          field: "job_role_name",        headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job Role Group Name",    field: "job_role_group_name",  headerStyle: headerCSS, cellStyle: cellCSS,
          editComponent: props => (
            <Select 
                onChange={e=> {setPatchGroup(JSON.parse(e.target.value).id)
                    props.onChange(JSON.parse(e.target.value).job_role_group)
                } }
            >
            {
                groups.map((g, key) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(g)} >
                        {g.job_role_group}
                    </MenuItem>
                )})
            }
            </Select>
          )  
        },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData, oldData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        })
    }

    const updateRow = (newData, oldData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ 
                ...(oldData.job_role_name!==newData.job_role_name && {job_role_name: newData.job_role_name}),
                ...(patchGroup && {job_role_group: patchGroup}),
            })
        };
        apiNiyuktiCall(`/job/role/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                SuccessAlert('Actual Changes will be reflected after 15 mins since the data is cached on disk...');
                setPatchGroup(null);
            }
            else {
                FailureAlert(res.error);
            }
            setCount(count+1);
        })
    }

    const addNewRole = (e) => {
        e.preventDefault();
        apiNiyuktiCall('/job/role/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                SuccessAlert('Actual Changes will be reflected after 15 mins since the data is cached on disk...');
                setCount(count+1);
            }
            else {
                FailureAlert(res.error);
            }
        })
        e.target.reset();
    }

    return (
        !loading
        ?
        <div className="job__role">
            <div className="job__role__form">
                <h4>Add New Role</h4>
                <Form onSubmit={e=>addNewRole(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="role">
                            <Form.Label>Role Name*</Form.Label>
                            <Form.Control 
                                name="role" 
                                type="text" 
                                placeholder="Enter Role Name"
                                onChange={e=> {setRoleName(e.target.value)}}
                                required 
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="roleGroup">
                            <Form.Label>Role Group</Form.Label>
                            <Form.Control 
                                as="select"
                                onChange={e=> {setRoleGroup(JSON.parse(e.target.value).id)} }
                            >
                                <option value={JSON.stringify({"id": null})}>--select--</option>
                            {
                                groups.map((group, key) => {
                                return (
                                    <option key={key} value={JSON.stringify(group)} >
                                        {group.job_role_group}
                                    </option>
                                )})
                            }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Button type="submit" variant="info">Add New Role</Button>
                    
                </Form>    
            </div>
            <TableWithExport 
                title = "Job Role"
                data = {data}
                columns = {columns}
                fName = "Selected Job Roles"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default JobRole;