import {
  Button,
  Dialog,
  DialogContent, Grid, Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { niyuktiAxiosGetReq } from "../../../util/apiCall";
import { CustomTextField } from "../../common/InputText/InputField";


const SelectJobSegment = ({ open, setOpen,
  jobSegmentState,
  setJobSegmentState,
  internshipPayment,
  setInternshipPayment,
  jobPayment,
  setJobPayment,
  addJob, }) => {

  const [allJobSegments, setAllJobSegments] = useState([]);

  const getJobSegments = async () => {
    const endPoint = `/job/segment/`;

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      setAllJobSegments(res?.data?.data);
    } catch (error) {
      throw error;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const [maxWidth, setMaxWidth] = React.useState("xs");

  useEffect(() => {
    if (allJobSegments?.length === 0) {
      getJobSegments();
    }
  }, []);

  return (
    <div>
      <Dialog
        // fullScreen={"md"}
        fullWidth={"50vh"}
        // width={"40vh"}
        // height={"70%"}
        open={open}
        minheight={"70%"}
        // maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: { borderRadius: 12, padding: 16, },
        }}
      >
        <DialogContent style={{ padding: 15 }}>
          <Typography style={{ fontSize: "20px", marginBottom: 10 }}>
            Select Job Segment
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12} style={{ display: "grid" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allJobSegments}
                getOptionLabel={(option) => option?.job_segment}
                value={jobSegmentState}
                onChange={(e, item) => {
                  if (item) {
                    setJobSegmentState(item);
                  }
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Select Job Role"
                  />
                )}
              />
            </Grid>
            <Grid item md={12} style={{ display: "grid" }}>
              <CustomTextField
                size="small"
                label="Max Stipened(Monthly)"
                type="number"
                variant="outlined"
                onWheel={() => document.activeElement.blur()}
                value={internshipPayment ? internshipPayment : ""}
                onChange={(event) => {
                  setInternshipPayment(event.target.value);
                }}
                onValueChange={(values) => {
                  const { value } = values;
                  setInternshipPayment(value);
                }}
              />
            </Grid>
            <Grid item md={12} style={{ display: "grid" }}>
              <CustomTextField
                size="small"
                label="Max Offered CTC"
                type="number"
                variant="outlined"
                onWheel={() => document.activeElement.blur()}
                value={jobPayment ? jobPayment : ""}
                onChange={(event) => {
                  setJobPayment(event.target.value);
                }}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid", marginTop: 16 }}>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  boxShadow: "none",
                  borderRadius: "30px",
                  height: "40px",
                  marginBottom: 8,

                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={6} style={{ display: "grid", marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  boxShadow: "none",
                  borderRadius: "30px",
                  height: "40px",
                  marginBottom: 8,
                }}
                onClick={(e) => {
                  addJob(e);
                  handleClose();
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectJobSegment;
