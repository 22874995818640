import {
    Button,

    Checkbox, FormControlLabel, Grid,
    Typography
} from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { praveshAxiosGetReq } from '../../../../util/apiCall';
import { FailureAlert } from "../../../../util/Notifications";
import DeleteTag from "../../Components/Dialog/DeleteTag";
import CustomButtonCircularProgress from "../../customLoader";
import ResumeCardsView from "../BulkUploadResume/ResumeCardsView";

const useStyles = makeStyles((theme) => ({

    tagSection: {
        display: "flex",
        alignItems: "center",
        color: "primary"

    }

}));




const TagsSection = ({ tagName, setTagsApiCall, tagsRefreshed, setTagsRefreshed }) => {


    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [totalResumeCount, setTotalResumeCount] = useState(null);
    const [totalResumeCardsData, setTotalResumeCardsData] = useState([]);
    const [removeViewMore, setRemoveViewMore] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [deleteTag, setDeleteTag] = useState(false);
    const [removeResumes, setRemoveResumes] = useState([]);
    const classes = useStyles();
    const [refreshOnMapView, setRefreshOnMapView] = useState(false);
    const [lastNoOfCardsAdded, setLastNoOfCardsAdded] = useState(0);


    const [mapped, setMapped] = useState({
        checkedA: false,
    });
    const [callMapped, setCallMapped] = useState(0);

    const handleToggle = (event) => {
        setMapped({ ...mapped, [event.target.name]: event.target.checked });
    };

    // this is for setting mapped type value pagination.
    useEffect(() => {

        if (mapped.checkedA) {
            setCallMapped(1);
        } else {
            setCallMapped(0);
        };

    }, [mapped.checkedA]);



    const handleChange = (e, index) => {
        setChecked(e.target?.checked);
        if (e.target?.checked) {
            setDeleteTag(true);
        }
        else {
            setDeleteTag(false);
        }
    };

    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    useEffect(() => {

        viewMoreCards();

    }, []);

    useEffect(() => {

        setRefreshOnMapView(true);
        // viewMoreCards();
        const refreshCard = async () => {

            const endPoint = `/api/admin/bulk/resumes/?tag=${tagName}&page=${1}&is_mapped=${callMapped}`;

            try {
                const res = await praveshAxiosGetReq(endPoint);
                if (res?.data?.success) {

                    setTotalResumeCount(res?.data?.data?.count);

                    setTotalResumeCardsData([...res?.data?.data?.results]);
                    if (res?.data?.data?.next === null) {
                        setRemoveViewMore(true);
                    } else {
                        setRemoveViewMore(false);
                    };
                    setPageNo(2);
                    setRefreshOnMapView(false);

                }
                else {
                    FailureAlert("Some Error Occurred!");
                    setRemoveViewMore(true);
                    setRefreshOnMapView(false);

                };

            }
            catch (error) {
                throw (error);

            }

        };

        refreshCard();

    }, [tagName, callMapped]);


    // this is for pagination of the app.

    const viewMoreCards = async () => {

        setLoading(true);
        const getMapstatus = () => {

            if (mapped.checkedA) { return 1 } else { return 0 };

        };
        const endPoint = `/api/admin/bulk/resumes/?tag=${tagName}&page=${pageNo}&is_mapped=${getMapstatus()}`;

        try {
            const res = await praveshAxiosGetReq(endPoint);
            if (res?.data?.success) {

                setLoading(false);
                // if no tag exists or there is no match of tag this directs to bulk-resume page.

                // if (!res?.data?.data?.count) history.push("/admin/bulk-resume");
                setTotalResumeCount(res?.data?.data?.count);
                setLastNoOfCardsAdded(res?.data?.data?.results?.length)

                setTotalResumeCardsData([...totalResumeCardsData, ...res?.data?.data?.results]);
                if (res?.data?.data?.next === null) {
                    setRemoveViewMore(true);

                };
                setPageNo(pageNo + 1);
            }
            else {
                FailureAlert("Some Error Occurred!");
                setRemoveViewMore(true);
            };

        }
        catch (error) {
            throw (error);
        }

    };
    useEffect(() => {

        if (totalResumeCardsData?.length < 7) {
            setRemoveViewMore(false);

        };

    }, [totalResumeCardsData])

    const viewLessCards = () => {

        setRefreshOnMapView(true);

        let arr = [];
        if (totalResumeCardsData?.length < 7) {
            setRemoveViewMore(false);
            return;
        };

        if (totalResumeCardsData?.length > 6) {

            arr = totalResumeCardsData.slice(0, totalResumeCardsData.length - lastNoOfCardsAdded);
            setPageNo(pageNo - 1);
            setTotalResumeCardsData(arr);
            setRefreshOnMapView(false);
            // setRemoveViewMore(false);

        }
        else {
            setRemoveViewMore(false);
            setPageNo(2);
        };

    };
    // if total cards count is less than  or equal  to 6  then buttons view more are not displayed
    const getViewCardsIdea = () => {

        if (totalResumeCount <= 6) {
            return true;
        }
        else {
            return false;
        }

    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12}>

                    <div style={{ marginLeft: "-10px", marginTop: "10px" }}>
                        <section
                        // style={{ display: "flex" }}

                        >
                            <section style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "-23px"

                            }}>
                                <Typography variant="body1">
                                    {tagName} {`(${totalResumeCount})`}
                                </Typography>

                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Switch checked={mapped.checkedA}
                                            onChange={handleToggle}
                                            name="checkedA" />}
                                        label="Mapped only"
                                    />
                                    {/* <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Primary"
                            /> */}

                                </FormGroup>

                            </section>

                            <hr style={{ marginBottom: "14px" }} />
                        </section>

                        <Grid item md={2}>
                            {/* <ResumeCard /> */}
                        </Grid>
                    </div>
                    <section>
                        <section style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: 'space-between',
                            alignItems: "center",
                            marginBottom: "10px",

                        }}>
                            <section>
                                {
                                    removeResumes?.length > 0 &&
                                    <FormControlLabel
                                        style={{ margin: 0 }}
                                        control={<Checkbox
                                            name="checkedB"
                                            value={checked}
                                            color="primary"
                                            checked={checked}
                                            onChange={handleChange}
                                        />}
                                        label={
                                            <Typography variant="body1">
                                                Select All
                                            </Typography>
                                        }
                                    />
                                }

                            </section>
                            <section>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={checked || removeResumes.length > 0 ? false : true}
                                    style={{
                                        // marginRight: "-865px",
                                        marginBottom: "15px",
                                        width: "108px"
                                    }}
                                    onClick={handleClickOpenDelete}
                                >
                                    Delete

                                </Button>
                                <DeleteTag
                                    open={openDelete}
                                    handleClickOpen={handleClickOpenDelete}
                                    handleClose={handleCloseDelete}
                                    deleteTag={deleteTag}
                                    tagName={tagName}
                                    setTagsApiCall={setTagsApiCall}
                                    removeResumes={removeResumes}
                                    setChecked={setChecked}
                                    setTagsRefreshed={setTagsRefreshed}
                                />

                            </section>

                        </section>
                    </section>
                    <Grid item md={12}>

                        <Grid container spacing={2} style={{ display: "flex" }}>
                            {
                                refreshOnMapView ? null : (

                                    totalResumeCardsData?.length === 0 ?
                                        <section className={classes.tagSection}>
                                            No Data Available for the Tag.
                                        </section> :

                                        totalResumeCardsData?.map((data) => (
                                            <Grid item md={2}>
                                                <ResumeCardsView
                                                    resumedata={data}
                                                    setRemoveResumes={setRemoveResumes}
                                                    removeResumes={removeResumes}
                                                    setTagsRefreshed={setTagsRefreshed}
                                                    totalResumeCardsData={totalResumeCardsData}
                                                    mapped={mapped.checkedA}
                                                    checkedTag={checked}

                                                />
                                            </Grid>
                                        ))
                                )

                            }
                        </Grid>
                        {/* <ResumeCard
                            resumeCardsData={totalResumeCardsData}
                            setRemoveResumes={setRemoveResumes}
                            removeResumes={removeResumes}
                        /> */}

                    </Grid>
                    <Grid item md={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                marginTop: "30px",
                            }}
                        >
                            {/* this is for toggling view of buttons for reducing and adding cards in respective tagsection */}
                            {getViewCardsIdea() ? null :
                                (removeViewMore ?
                                    <Button
                                        variant="outlined"
                                        color="primary"

                                        onClick={() => { viewLessCards() }}
                                    >
                                        {loading ? (<CustomButtonCircularProgress />) : ("View less")}

                                    </Button>
                                    : (

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            // endIcon={<ArrowForwardIcon />}
                                            // onClick={() => { getResumeData() }}
                                            onClick={() => { viewMoreCards() }}
                                        >
                                            {loading ? (<CustomButtonCircularProgress />) : ("View More")}

                                        </Button>
                                    ))


                            }

                        </div>
                    </Grid>
                </Grid>

            </Grid>

        </>




    )
}

export default TagsSection;