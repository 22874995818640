import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling, requestPraveshOptions } from '../../constants';
import '../../css/EditCollegeDetails.css';
import { apiCall } from '../../util/apiCall';
import { praveshAxiosGetReq } from '../../util/baseApi';
import { FailureAlert } from '../../util/Notifications';
import ProfileUnlockSection from './ProfileUnlockSection';

function ProfileUnlock() {
    const [companyId, setCompanyId] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [creditData, setCreditData] = useState();
    const [loadApi, setLoadApi] = useState(false);
    // Fetch all Companies Dropdown
    useEffect(() => {
        apiCall('/api/admin/company/get/', requestPraveshOptions)
        .then((res)=> {
            if(res.success) {
                setCompanies(res?.data)
            }
            else {
                FailureAlert(res?.error)
            }
        }).catch(err => {
            FailureAlert(err?.message);
        })
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (companyId || loadApi) { 


          const getCreditUnlockData = async() => {

            const endPoint = `/api/admin/company/add_credit/?company_id=${companyId}`;
            setIsLoading(true);

            try {
                
                const res = await praveshAxiosGetReq(endPoint);

                if(res?.data?.success){

                    setCreditData(res?.data?.data);

                }
                
                setIsLoading(false);
                setLoadApi(false);

            } catch (error) {
                
                setIsLoading(false);
                setLoadApi(false);
                throw(error);

                // FailureAlert(err?.message);

            };     
            
        }
         
        getCreditUnlockData();

        }
        }, [companyId, loadApi]);


    return (
        <div>
            <Form>
                <Autocomplete
                    id="select-user"
                    options={companies}
                    getOptionLabel={(item) => item.company_name}
                    style={{ width: '90%', margin: '5%' }}   
                    onChange={(e, item)=>item ? (setCompanyId(item.company_id), setCompanyName(item.company_name))  : setCompanyId(null)}
                    renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                />
            </Form>
            {
                companyId && isLoading ? 
                <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
                :
                !companyId && !isLoading ?
                <div style={{ width: '90%', margin: '5%' }}>
                    Select Company to view and add Unlock Credits
                </div>
                : <ProfileUnlockSection cmpId = {companyId} cmpName={companyName} creditdata = {creditData} setLoadApi={setLoadApi}/>
            }
        </div>
    )
}

export default ProfileUnlock;