import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { FailureAlert } from "../../../util/Notifications";
import ColorPicker from "../../common/ColorPicker";

const useStyles = makeStyles(() => ({
  fileInput: {
    cursor: "pointer",
  },
}));

const LogoBanner = ({
  newCompanyDetails,
  setNewCompanyDetails,
  setStepperClickToggle,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setStepperClickToggle(true);
  }, []);

  return (
    <div style={{ marginTop: "30px" }}>
      <Form
        className="edit__poc__details__form"
        id="edit__poc__details__form__details"
      >
        {newCompanyDetails?.companyBasicInfo?.companyType === "premium" ? (
          <Form.Row>
            <Form.Group controlId="primaryColor" required>
              <Form.Label>Select Primary Color*</Form.Label>
              <ColorPicker
                color={
                  newCompanyDetails?.companyBasicInfo?.primaryColor || "#000000"
                }
                setColor={(color) => {
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      primaryColor: color,
                    },
                  });
                }}
              />
            </Form.Group>
            <br />
            <br />
            <Form.Group controlId="secondaryColor" required>
              <Form.Label>Select Secondary Color*</Form.Label>
              <ColorPicker
                color={
                  newCompanyDetails?.companyBasicInfo?.secondaryColor ||
                  "#8420dc"
                }
                setColor={(color) => {
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      secondaryColor: color,
                    },
                  });
                }}
              />
            </Form.Group>
            <br />
            <br />
            <br />
            <br />
          </Form.Row>
        ) : null}

        <Form.File id="companyLogo">
          <Form.File.Label>Upload Company Logo*</Form.File.Label>
          <Form.File.Input
            onChange={(e) => {
              setNewCompanyDetails({
                ...newCompanyDetails,
                companyBasicInfo: {
                  ...newCompanyDetails?.companyBasicInfo,
                  companyLogo: e.target.files[0],
                },
              });
            }}
          />
          <>
            {" "}
            {newCompanyDetails?.companyBasicInfo?.companyLogo?.name &&
              `Selected File:${newCompanyDetails?.companyBasicInfo?.companyLogo?.name} `}
          </>
          <p>Recommended Size: 150px*150px</p>
        </Form.File>
        {newCompanyDetails?.companyBasicInfo?.companyLogoLink &&
          !newCompanyDetails?.companyLogo?.name && (
            <Form.Group>
              <a
                href={newCompanyDetails?.companyBasicInfo?.companyLogoLink}
                target="_blank"
              >
                <img
                  src={newCompanyDetails?.companyBasicInfo?.companyLogoLink}
                  alt="sideImage"
                  height="100px"
                  width="100px"
                />
              </a>
            </Form.Group>
          )}

        <br />
        <Form.File id="companyBanner">
          <Form.Group>
            <Form.File.Label>Upload Company Banner*</Form.File.Label>
            <Form.Control
              type="file"
              style={{ cursor: "pointer" }}
              label={"hfadsfadf"}
              // placeholder={"helo"}
              className={classes.fileInput}
              onChange={(e) => {
                // console.log("hellow files", e.target.files[0]);
                setNewCompanyDetails({
                  ...newCompanyDetails,
                  companyBasicInfo: {
                    ...newCompanyDetails?.companyBasicInfo,
                    banner: e.target.files[0],
                  },
                });
              }}
            />
          </Form.Group>

          <p>Recommended Size: 860px*250px</p>
          <>
            {" "}
            {newCompanyDetails?.companyBasicInfo?.banner?.name &&
              `Selected File:${newCompanyDetails?.companyBasicInfo?.banner?.name} `}
          </>
        </Form.File>

        {newCompanyDetails?.companyBasicInfo?.bannerLink &&
          !newCompanyDetails?.companyLogo?.name && (
            <Form.Group>
              <a
                href={newCompanyDetails?.companyBasicInfo?.bannerLink}
                target="_blank"
              >
                <img
                  src={newCompanyDetails?.companyBasicInfo?.bannerLink}
                  alt="sideImage"
                  height="100px"
                />
              </a>
            </Form.Group>
          )}

        {newCompanyDetails?.companyBasicInfo?.companyType === "premium" ? (
          <>
            <Form.Group>
              <Form.File
                id="companySideImage"
                name="companySideimage"
                value={newCompanyDetails?.companyBasicInfo?.sideImage}
              >
                <Form.File.Label>Upload SideImage*</Form.File.Label>
                <Form.File.Input
                  onChange={(e) => {
                    setNewCompanyDetails({
                      ...newCompanyDetails,
                      companyBasicInfo: {
                        ...newCompanyDetails?.companyBasicInfo,
                        sideImage: e.target.files[0],
                      },
                    });
                  }}
                />
                <>
                  {" "}
                  {newCompanyDetails?.companyBasicInfo?.sideImage?.name &&
                    `Selected File:${newCompanyDetails?.companyBasicInfo?.sideImage?.name} `}
                </>
                <p>Recommended Size: 720px*700px</p>
              </Form.File>
            </Form.Group>
            {newCompanyDetails?.companyBasicInfo?.sideImageLink && (
              <Form.Group>
                <a
                  href={newCompanyDetails?.companyBasicInfo?.sideImageLink}
                  target="_blank"
                >
                  <img
                    src={newCompanyDetails?.companyBasicInfo?.sideImageLink}
                    alt="favicon"
                    height="100px"
                    width="100px"
                  />
                </a>
              </Form.Group>
            )}
            <Form.Row>
              <Form.Group>
                <Form.File id="companyLogo">
                  <Form.File.Label>Upload Favicon (.ico file)</Form.File.Label>
                  <Form.File.Input
                    onChange={(e) => {
                      if (e.target.files[0]?.name?.includes("ico")) {
                        setNewCompanyDetails({
                          ...newCompanyDetails,
                          companyBasicInfo: {
                            ...newCompanyDetails?.companyBasicInfo,
                            favicon: e?.target?.files[0],
                          },
                        });
                      } else {
                        FailureAlert("Please Enter .ico extension file");
                        e.target.value = null;
                      }
                    }}
                  />
                  <>
                    {" "}
                    {newCompanyDetails?.companyBasicInfo?.favicon?.name &&
                      `Selected File:${newCompanyDetails?.companyBasicInfo?.favicon?.name} `}
                  </>
                  <p>Recommended Size: 16px*16px</p>
                </Form.File>
              </Form.Group>
              {newCompanyDetails?.companyBasicInfo?.faviconLink && (
                <Form.Group>
                  <a
                    href={newCompanyDetails?.companyBasicInfo?.faviconLink}
                    target="_blank"
                  >
                    <img
                      src={newCompanyDetails?.companyBasicInfo?.faviconLink}
                      alt="favicon"
                      height="100px"
                      width="100px"
                    />
                  </a>
                </Form.Group>
              )}
            </Form.Row>{" "}
          </>
        ) : null}
      </Form>
    </div>
  );
};

export default LogoBanner;
