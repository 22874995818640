import React, { useEffect, useState } from "react";
import { cellCSS, headerCSS } from "../../constants";
import { niyuktiAxiosGetReq, niyuktiAxiosPostReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import TableWithoutExport from "../TableWithoutExport";
import TableWithExport from "../TableWithExport";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import LoadingOverlay from "react-loading-overlay";
import { Tooltip } from "@material-ui/core";

function StudentsHistory() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const studentId = queryParams.get("student_id");

  const [pageLoading, setPageLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const [studentName, setStudentName] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // http://127.0.0.1:8002/api/manage_jobs/admin/get_candidate_job_history/?user_id=21149
    const getData = async () => {
      let endPoint = `/api/manage_jobs/admin/get_candidate_job_history/?user_id=${studentId}`;
      setPageLoading(true);

      try {
        const res = await niyuktiAxiosGetReq(endPoint);

        if (res?.data?.success) {
          setStudentName(res?.data?.data?.student_name);
          const data = res?.data?.data?.applicant_data;
          let tempArr = [];
          let parentData = [];
          let indx = 1;
          for (let i in data) {
            let obj = {
              id: data[i]?.applicant_id,
              appliedBy: data[i]?.applicant_id,
              jobID: data[i]?.job_id,
              companyName: data[i]?.company_name,
              jobTitle: data[i]?.job_title,
              roundName: data[i]?.current_round_name,
              notes: data[i]?.notes,
              lastActionDate: data[i]?.last_action_date,
              lastActionTakenBy: data[i]?.last_action_taken_by,
              mainData: true,
              companyId: data[i]?.company,
            };

            tempArr.push(obj);
            let newObj = {
              id: data[i]?.applicant_id,
              appliedBy: data[i]?.applicant_id,
            };
            if (!parentData.includes(data[i]?.applicant_id)) {
              newObj["parentId"] = data[i]?.applicant_id;
              newObj["jobID"] = "Old History";
              newObj["showArrow"] = true;
              newObj["fontWeight"] = "800";
              parentData.push(data[i]?.applied_by);
              tempArr.push(newObj);
            }
            indx = indx + 1;
          }
          setTableData(tempArr);
          setPageLoading(false);
        } else {
          FailureAlert("Some Error Occurred!");
          setPageLoading(false);
        }
      } catch (error) {
        throw error;
      }
    };
    getData();
  }, []);

  const lengthChecker = (text) => {
    const maxLength = 75;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const columns = [
    {
      title: "Job ID",
      field: "jobID",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
      // render: (row) => (
      //   <div>
      //     {row?.showArrow && <SubdirectoryArrowRightIcon />}
      //     {row.jobID}
      //   </div>
      // ),
      render: (row) => (
        <div
          style={{
            cursor: "pointer",
            color: "#0509ff",
          }}
          onClick={() => {
            window.open(`/admin/job-track/${row.jobID}/${row.companyId}`);
          }}
        >
          {row?.showArrow && <SubdirectoryArrowRightIcon />}
          {row.jobID}
        </div>
      ),
    },

    {
      title: "Company name",
      field: "companyName",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "Job title",
      field: "jobTitle",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    {
      title: "Round name",
      field: "roundName",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "Notes",
      field: "notes",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
      render: (row) =>
        !row.showArrow ? (
          row?.notes ? (
            <Tooltip title={`${row.notes}`} placement="right">
              <div
                style={{
                  width: "250px",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }}
              >
                {lengthChecker(row.notes)}
              </div>
            </Tooltip>
          ) : (
            "NA"
          )
        ) : (
          ""
        ),
    },

    {
      title: "Last action date",
      field: "lastActionDate",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
    },
    {
      title: "Last action taken by",
      field: "lastActionTakenBy",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
    },
  ];

  // http://127.0.0.1:8002/api/manage_jobs/admin/get_candidate_job_history_details/?applicant_id=5436
  const getOlderHIstory = (row) => {
    let endPoint = `/api/manage_jobs/admin/get_candidate_job_history_details/?applicant_id=${
      row.appliedBy
    }&is_rejected=${row.roundName === "Rejected" ? 1 : 0}`;
    setLoadingOverlay(true);

    niyuktiAxiosGetReq(endPoint)
      .then((res) => {
        if (res?.data?.success) {
          const data = res?.data?.data;
          let tempArr = [];
          for (let i in data) {
            let obj = {
              id: `OLD_${data[i]?.applicant_id}`,
              appliedBy: data[i]?.applicant_id,
              jobID: data[i]?.job_id,
              companyName: data[i]?.company_name,
              jobTitle: data[i]?.job_title,
              roundName: data[i]?.current_round_name,
              notes: data[i]?.notes,
              lastActionDate: data[i]?.last_action_date,
              lastActionTakenBy: data[i]?.last_action_taken_by,
              parentId: data[i]?.applicant_id,
              companyId: data[i]?.company,
              olderHistory: true,
            };
            tempArr.push(obj);
          }
          setTableData([...tableData, ...tempArr]);
        } else {
          FailureAlert(res?.error);
        }
        setLoadingOverlay(false);
      })
      .catch((err) => {
        FailureAlert("Something went wrong!!");
        setLoadingOverlay(false);
      });
  };

  const removeDataFromTable = (row) => {
    setTableData((prevData) =>
      prevData.filter((item) => {
        return !(item?.olderHistory && row?.appliedBy === item?.appliedBy);
      })
    );
  };

  const onTreeExpandChange = (row, openState) => {
    if (openState) {
      getOlderHIstory(row);
    } else {
      removeDataFromTable(row);
    }
  };

  return (
    <>
      {pageLoading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        <LoadingOverlay
          active={loadingOverlay}
          spinner
          text="Updating Details..."
        >
          <div style={{ marginTop: "20px" }}>
            <TableWithExport
              title={studentName}
              data={tableData}
              columns={columns}
              fName=""
              showNestedTable={true}
              onTreeExpandChange={onTreeExpandChange}
              selection={false}
              sorting={false}
              search={false}
              filtering={false}
              cellFixedIndex={null}
            />
          </div>
        </LoadingOverlay>
      )}
    </>
  );
}

export default StudentsHistory;
