import React from "react";
import { Skeleton } from "@material-ui/lab";

const svgVactor = (
  <svg
    width="1059"
    height="240"
    viewBox="0 0 1059 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="2.25"
      y1="-5.46392e-08"
      x2="2.25001"
      y2="238"
      stroke="#ADADAD"
      stroke-width="2.5"
    />
    <line
      x1="1"
      y1="236.75"
      x2="1059"
      y2="236.75"
      stroke="#ADADAD"
      stroke-width="2.5"
    />
    <path
      d="M1050 143H1029.29H1000.9H986.435C973.083 143 961.231 134.45 957.018 121.78L955.466 117.109C954.559 114.383 953.279 111.796 951.66 109.423L944.916 99.5341C939.142 91.0662 929.555 86 919.305 86H908.067H890.655C884.026 86 877.57 88.1255 872.238 92.0644L819.61 130.936C814.277 134.875 807.822 137 801.192 137H788.384H783.571C776.367 137 769.49 140.005 764.596 145.291L752.595 158.251C748.738 162.417 742.14 162.382 738.327 158.177V158.177C736.493 156.154 733.889 155 731.159 155H719.896C714.017 155 708.26 153.328 703.295 150.18L682.51 137L649.742 120.196C640.925 115.674 630.478 115.64 621.631 120.103L597.281 132.39C591.347 135.384 586.532 140.206 583.547 146.144L580.928 151.356C576.415 160.334 567.225 166 557.176 166V166C553.813 166 550.479 165.362 547.354 164.119L512.337 150.194C508.692 148.745 504.805 148 500.882 148H483.829C472.147 148 461.557 141.13 456.796 130.463V130.463C453.925 124.032 448.857 118.836 442.5 115.806L411.672 101.112C404.766 97.8209 396.887 97.1991 389.551 99.3667L347.705 111.73C344.854 112.572 341.896 113 338.922 113H331.79C319.696 113 308.706 120.033 303.64 131.016L284.709 172.057C278.653 185.188 264.322 192.387 250.172 189.408L242.811 187.858C231.544 185.485 219.891 189.553 212.547 198.421L207.495 204.522C201.737 211.476 193.179 215.5 184.151 215.5V215.5C177.005 215.5 170.089 212.975 164.624 208.371L153.849 199.293C146.694 193.265 136.329 192.964 128.835 198.566L127.387 199.648C120.511 204.789 110.956 204.334 104.6 198.564V198.564C98.4297 192.963 89.2138 192.351 82.3575 197.087L63.0049 210.457C58.2507 213.741 52.6093 215.5 46.8309 215.5V215.5C41.5957 215.5 36.462 216.944 31.9947 219.674L2 238"
      stroke="#ADADAD"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

function GraphAreaSkeleton() {
  return (
    <>
      <div style={{ position: "relative" }}>
        <Skeleton
          variant="rect"
          height={450}
          style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            width: "100%",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            alignItems: "center",
            zIndex: 2,
            top: "20px",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div>{svgVactor}</div>
        </div>
      </div>
    </>
  );
}

export default GraphAreaSkeleton;
