import {
  Chip,
  makeStyles,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import crossIcon from "../../../assets/images/cancel.png";
import { FailureAlert } from "../../../util/Notifications";
import { DateSelector } from "../../common/DateSelector";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    //backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
  },
}));

const crossStyle = {
  positin: "aboslute",
  width: "10px",
  fontWeight: "Bold",
  marginLeft: "564px",
  marginTop: "-38px",
  cursor:"pointer"
}

const AdvanceFiltersDialog = ({
  open,
  handleClickOpen,
  handleClose,
  dateRange,
  applyDateFilter,
  removeDateFilter,
  allJobSegments,
  selectedJobSegments,
  setSelectedJobSegments,
  minCtc,
  setMinCtc,
  minStipend,
  setMinStipend,
  handleSubmit,
  setAdvanceFilterDateValue,
  advanceFilterDateValue,
  setOpenAdvanceFilterDialog,
}) => {
  const classes = useStyles();

  const addJobSegment = (jobSegment) => {
    if (jobSegment) {
      const jobSegmentAlreadyAdded = selectedJobSegments?.some(
        (st) => st.id === jobSegment.id
      );
      if (jobSegmentAlreadyAdded) FailureAlert("job Segment Already Added!");
      else {
        const arr = selectedJobSegments ? [...selectedJobSegments] : [];
        arr.push(jobSegment);
        setSelectedJobSegments(arr);
      }
    }
  };

  const handleDelete = (index) => {
    const jobSegmentAfterDelete = selectedJobSegments?.filter(
      (_, i) => i !== index
    );
    setSelectedJobSegments(jobSegmentAfterDelete);
  };

 
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpenAdvanceFilterDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        height="60vh"
      >
        <DialogTitle id="form-dialog-title">Advance Filters</DialogTitle>
        <img 
        style={crossStyle}  
        src={crossIcon} 
       
        onClick={() => {setOpenAdvanceFilterDialog(false)}}
        alt="close Icon" />
        {/* <LoadingOverlay active={showLoader} spinner text="Please Wait..."> */}
        <DialogContent>
          <DateSelector
            //dateRange={}
            isAdvanceFilter={true}
            setAdvanceFilterDateValue={setAdvanceFilterDateValue}
            advanceFilterDateValue={advanceFilterDateValue}
          />
          <br></br>
          <Autocomplete
            id="select-job-segment"
            options={allJobSegments}
            value={selectedJobSegments}
            getOptionLabel={(item) => item?.job_segment}
            onChange={(e, newValue) => {
              if (newValue) addJobSegment(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Job Segment"
                variant="outlined"
              />
            )}
          />

          {
            <div className={classes.root}>
              {selectedJobSegments &&
                selectedJobSegments.map((data, index) => {
                  return (
                    <li key={data.id}>
                      <Chip
                        color="primary"
                        deleteIcon={<CloseIcon color="primary" />}
                        variant="outlined"
                        size="small"
                        label={data.job_segment}
                        onDelete={() => {
                          handleDelete(index);
                        }}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
            </div>
          }

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Min Stipend (INR)"
            type="number"
            value={minStipend}
            onChange={(e) => {
              setMinStipend(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Min CTC (INR)"
            type="number"
            value={minCtc}
            onChange={(e) => {
              setMinCtc(e.target.value);
            }}
          />
          <br></br>

          {/* <div style={{ marginBottom: 10 }}>
              <Typography>
                {" "}
                {name} : {rating}/10
              </Typography>
            </div> */}
          {/* <div style={{ marginBottom: 10 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">CheckList</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={location}
                        onChange={handleChange}
                        name="location"
                      />
                    }
                    label="Location Match"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={salary}
                        onChange={handleChange}
                        name="salary"
                      />
                    }
                    label="Salary Match"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={work_exp}
                        onChange={handleChange}
                        name="work_exp"
                      />
                    }
                    label="Work Experience Match"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={education}
                        onChange={handleChange}
                        name="education"
                      />
                    }
                    label="Education Match"
                  />
                </FormGroup>
              </FormControl>
            </div> */}
          {/* <div style={{ marginBottom: 20 }}>
              <Typography id="discrete-slider-custom" gutterBottom>
                Key Skills
              </Typography>
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                name="key_skills"
                marks={marks}
                min={1}
                max={10}
                value={studentJobMatchingData.key_skills}
                onChange={handleKeySkills}
              />
            </div> */}
          {/* <div style={{ marginBottom: 20 }}>
              <Typography id="discrete-slider-custom" gutterBottom>
                Communication Skills
              </Typography>
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                min={1}
                max={10}
                value={studentJobMatchingData.communication_skills}
                onChange={handleCommunicationSkills}
              />
            </div> */}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Clear
          </Button>
          <Button onClick={handleClose} color="primary" onClick={handleSubmit}>
            Apply filter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdvanceFiltersDialog;
