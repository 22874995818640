import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  emailRegex,
  gifStyling,
  latitudeRegex,
  longitudeRegex,
  requestPraveshOptions,
  urlRegex,
  websiteRegex,
} from "../../constants";
import {
  apiCall,
  niyuktiAxiosCall,
  praveshAxiosCall,
} from "../../util/apiCall";
import {
  praveshAxiosGetReq,
  praveshAxiosPatchReq,
  praveshAxiosPostReq,
} from "../../util/baseApi";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import CustomButtonCircularProgress from "../loader";
import AddNewCollegeAdditionalInfo from "./AddCollegeAdditionalInfo";
import AddCollegeCourses from "./AddCollegeCourses";
import AddNewCollegePrefrences from "./AddCollegePrefrences";
import AddNewCollegeBasicInfo from "./AddNewCollegeBasicInfo";
import AddNewCollegePayment from "./AddNewCollegePayment";
import AddNewCollegePocDetails from "./AddNewCollegePocDetails";
import gif from "../../assets/loopingcircles-3.gif";
import { useLocation } from "react-router";
import DegreeSpecializationSelect from "../Branch_Courses/DegreeSpecializationSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function AddNewCollegeStepper({
  isEdit,
  clgId,
  idCollegeCourses = null,
  collegeName = null,
}) {
  const token = localStorage.getItem("token");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingCollegeInfo, setloadingCollegeInfo] = useState(false);
  const [isSaas, setIsSaas] = useState(null);
  const [Universities, setUniversities] = useState();
  const [Countries, setCountries] = useState([]);
  const [count, setCount] = useState(0);
  const [allJobSegments, setAllJobSegments] = useState([]);
  const [isSaasWithJob, setisSaasWithJob] = useState(null);
  const [cities, setAllCities] = useState();
  const [countryId, setCountryId] = useState(1);
  const [states, setStates] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isonboard, setIsOnboard] = useState(null);

  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();
  const onboardsteps = onboardstep();
  const [primaryColor, setPrimaryColor] = useState("#007BFF");
  const [secondaryColor, setSecondaryColor] = useState("#23272B");
  const [logoLink, setLogoLink] = useState("");
  const [circularLogoLink, setCircularLogoLink] = useState("");
  const [faviconLink, setFaviconLink] = useState("");
  const [sideImageLink, setSideImageLink] = useState("");
  const [bannerLink, setBannerLink] = useState("");

  const [logoRes, setLogoRes] = useState("");
  const [circularLogoRes, setCircularLogoRes] = useState("");
  const [faviconRes, setFaviconRes] = useState("");
  const [sideImageRes, setSideImageRes] = useState("");
  const [bannerRes, setBannerRes] = useState("");
  const [universityNotListed, setUniversityNotListed] = useState(false);
  const [formData, setFormData] = React.useState({
    /* POC details */
    firstname: null,
    lastname: null,
    designation: null,
    email: null,
    phone: null,
    alternate_email: null,
    alternate_phone: null,

    /* college basic info */
    collegename: null,
    collegenickname: null,
    collegeaddress: null,
    organizationType: null,
    yearfounded: null,
    studentcount: null,
    website: null,
    description: null,
    about: null,
    gradesystem: null,
    percentageFactor: null,
    universityname: null,
    university: null,
    linkedInPage: null,
    facebookPage: null,
    instaAccount: null,
    country: 0,
    state: 0,
    city: 0,
    lattitude: null,
    longitude: null,

    //selectuniversity: "",

    /* Courses  */
    courses: [],

    /* College Preferences */
    jobType: [false, false, false, false, false, false, false],
    jobtypeFullTime: null,
    jonTypeInternship: null,
    jobtypeIntershipWithPpo: null,
    jobSegment: [],
    collegePreferenceState: [], // send "location" key for this in payload
    internshipAvailability: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ], //internship_availability
    jobAvailability: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ], //job_availability

    studentlicensecount: null,
    getworkdomain: null,
    premium: true,
    passcode: null,
    sideImage: null,
    logo: null,
    circularLogo: null,
    favicon: null,
    banner: null,
    planStartDate: null,
    planEndDate: null,
    totalpayment: null,
    paidpayment: null,
    pendingpayment: null,
  });

  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [coursesArray, setCoursesArray] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [dataSelectedNewFormat, setDataSelectedNewFormat] = useState([]);
  const [specializationInDegreeId, setSpecializationInDgegreeId] = useState([]);
  const [pushArray, setPushArray] = useState([]);
  const [extraflags, setExtraFlags] = useState({ emailEditFlag: true, noEmail: false })

  const [paid, setPaid] = useState(true);


  const getDataForEditPrefill = async () => {

    const url = `/api/admin/college/view/new/?college_id=${clgId}`;
    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        setSelectedCourses(res?.data?.data?.degree_specialization[0]);
        setIsOnboard(res?.data?.data?.is_onboarded === true ? "yes" : "no");
        setIsSaas(res?.data?.data?.is_saas);
        setisSaasWithJob(res?.data?.data?.is_saas_with_job);
        setLogoLink(res?.data?.data?.logo);
        setCircularLogoLink(res?.data?.data?.circular_logo);
        setBannerLink(res?.data?.data?.banner);
        setSideImageLink(res?.data?.data?.side_image);
        setFaviconLink(res?.data?.data?.favicon);

        setLogoRes(res?.data?.data?.logo);
        setCircularLogoRes(res?.data?.data?.circular_logo);
        setBannerRes(res?.data?.data?.banner);
        setSideImageRes(res?.data?.data?.side_image);
        setFaviconRes(res?.data?.data?.favicon);

        var newFacebookPage;
        var newInstaAccount;
        var newLinkedIn;
        /* social links */
        res?.data?.data?.social_links?.length > 0 &&
          res?.data?.data?.social_links?.map((item) => {
            if (item?.Name === "linkedin") {
              newLinkedIn = item?.value;
            } else if (item?.Name === "facebook" && item.value !== null) {
              newFacebookPage = item?.value;
            } else if (item?.Name === "instagram" && item.value !== null) {
              newInstaAccount = item?.value;
            }
          });

        //jobType

        let newJobType = [...formData?.jobType];

        res?.data?.data?.preferences?.job_type?.map((item) => {

          if (item?.id) {

            newJobType[item?.id - 1] = true;
          }
        });

        //intershipAvailabilty
        const newInternshipAvailabilty = [...formData?.internshipAvailability];
        res?.data?.data?.preferences?.internship_availability?.map((month) => {
          newInternshipAvailabilty[month - 1] = true;
        });

        //jobAvailability
        const newJobAvailabilty = [...formData.jobAvailability];
        res?.data?.data.preferences?.job_availability?.map((month) => {
          newJobAvailabilty[month - 1] = true;
        });

        const newUniversity = {
          address: null,
          banner: null,
          city: null,
          city_name: null,
          contact_person: null,
          designation: null,
          email: null,
          logo: null,
          phone: null,
          state: null,
          state_name: null,
          university_code: null,
          university_id: res?.data?.data?.university_id
            ? res?.data?.data?.university_id
            : null,
          university_name: res?.data?.data?.university_name
            ? res?.data?.data?.university_name
            : null,
          university_type: null,
          website: null,
        };
        setPaid(res?.data?.data?.is_paid || false);
        setPrimaryColor(
          res?.data?.data?.primary_color
            ? res?.data?.data?.primary_color
            : " #007BFF"
        );
        setSecondaryColor(
          res?.data?.data?.secondary_color
            ? res?.data?.data?.secondary_color
            : "#23272B"
        );

        if (res?.data?.data?.tpo_details[0]?.email) {

          setExtraFlags({ ...extraflags, emailEditFlag: false, noEmail: false })
        } else {
          setExtraFlags({ ...extraflags, emailEditFlag: false, noEmail: true })
        }


        setFormData({
          ...formData,
          firstname: res?.data?.data?.tpo_details[0]?.first_name
            ? res?.data?.data?.tpo_details[0]?.first_name
            : null,
          lastname: res?.data?.data?.tpo_details[0]?.last_name
            ? res?.data?.data?.tpo_details[0]?.last_name
            : null,
          designation: res?.data?.data?.tpo_details[0]?.job_title
            ? res?.data?.data?.tpo_details[0]?.job_title
            : null,
          email: res?.data?.data?.tpo_details[0]?.email,
          phone: res?.data?.data?.tpo_details[0]?.phone
            ? res?.data?.data?.tpo_details[0]?.phone
            : null,
          alternate_email: res?.data?.data?.tpo_details[0]?.alt_email
            ? res?.data?.data?.tpo_details[0]?.alt_email
            : null,
          alternate_phone: res?.data?.data?.tpo_details[0]?.alt_phone
            ? res?.data?.data?.tpo_details[0]?.alt_phone
            : null,
          collegename: res?.data?.data.college_name
            ? res?.data?.data.college_name
            : null,
          collegenickname: res?.data?.data?.nick_name || "",
          collegeaddress: res?.data?.data?.address
            ? res?.data?.data?.address
            : null,
          organizationType: res?.data?.data?.organization_type
            ? res?.data?.data?.organization_type
            : null,
          yearfounded: moment(res?.data?.data?.year_founded).format("YYYY"),

          studentcount: res?.data?.data?.size ? res?.data?.data?.size : null,
          website: res?.data?.data?.website ? res?.data?.data?.website : null,
          description: res?.data?.data?.description
            ? res?.data?.data?.description
            : "",
          about: res?.data?.data?.about ? res?.data?.data?.about : "",
          gradesystem: res?.data?.data?.is_grade_system ? "cgpa" : "percentage",
          percentageFactor: res?.data?.data?.percentage_factor
            ? res?.data?.data?.percentage_factor
            : null,
          //universityname: "",
          university: newUniversity,
          linkedInPage: newLinkedIn ? newLinkedIn : null,
          facebookPage: newFacebookPage ? newFacebookPage : null,
          instaAccount: newInstaAccount ? newInstaAccount : null,
          country: {
            country: res?.data?.data?.country ? res?.data?.data?.country : 0,
            country_id: res?.data?.data?.country_id
              ? res?.data?.data?.country_id
              : 0,
          },
          state: {
            state: res?.data?.data?.state ? res?.data?.data?.state : 0,
            state_id: res?.data?.data?.state_id ? res?.data?.data?.state_id : 0,
          },
          city: {
            city: res?.data?.data?.city ? res?.data?.data?.city : 0,
            city_id: res?.data?.data?.city_id ? res?.data?.data?.city_id : 0,
          },
          // state:res?.data?.data?.address? res?.data?.data?.address: 0,
          // city:res?.data?.data?.address? res?.data?.data?.address: 0,
          lattitude: res?.data?.data?.latitude
            ? res?.data?.data?.latitude
            : null,
          longitude: res?.data?.data?.longitude
            ? res?.data?.data?.longitude
            : null,

          courses: [],

          /* College Preferences */
          /*  jobType: [false, false, false, false, false, false, false], */

          jobSegment: res?.data?.data?.preferences?.job_segment
            ? res?.data?.data?.preferences?.job_segment
            : null,
          jobType: newJobType,
          internshipAvailability: newInternshipAvailabilty,
          jobAvailability: newJobAvailabilty,
          collegePreferenceState: res?.data?.data?.preferences?.location
            ? res?.data?.data?.preferences?.location
            : null, // send "location" key for this in payload

          studentlicensecount: res?.data?.data?.student_license_count
            ? res?.data?.data?.student_license_count
            : null,
          premium: res?.data?.data?.is_premium ? true : false,
          getworkdomain: res?.data?.data?.domain
            ? res?.data?.data?.domain
            : null,
          passcode: res?.data?.data?.passcode || '',
          sideImage: res?.data?.data?.side_image
            ? res?.data?.data?.side_image
            : null,
          circularLogo: res?.data?.data?.circular_logo
            ? res?.data?.data?.circular_logo
            : null,
          logo: res?.data?.data?.logo ? res?.data?.data?.logo : null,
          favicon: res?.data?.data?.favicon ? res?.data?.data?.favicon : null,
          banner: res?.data?.data?.banner ? res?.data?.data?.banner : null,

          planStartDate: res?.data?.data?.plan_start_date
            ? res?.data?.data?.plan_start_date
            : null,
          planEndDate: res?.data?.data?.plan_end_date
            ? res?.data?.data?.plan_end_date
            : null,
          totalpayment: res?.data?.data?.amount
            ? res?.data?.data?.amount?.toString()
            : null,
          paidpayment: res?.data?.data?.paid_amount
            ? res?.data?.data?.paid_amount?.toString()
            : null,
        });
      } else {
        FailureAlert("Error");
      }
      setLoading(false);
    } catch (error) {
      FailureAlert("some error occurred");
      setLoading(false);
    }
  };

  const patchForEdit = async (data) => {
    const url = `/api/admin/college/edit/?college_id=${clgId}`;
    setloadingCollegeInfo(true)
    try {
      const res = await praveshAxiosPatchReq(url, data);

      if (res.data.success) {
        SuccessAlert(res?.data?.data?.message);
        /*    setInterval(function () {
          window.location.reload();
        }, 1000); */
      } else {
        FailureAlert(res.data.error);
      }
      setloadingCollegeInfo(false)
    } catch (error) {
      setloadingCollegeInfo(false)
      FailureAlert("some error occurred");
    }


  };

  const getAllCourses = async () => {
    const url = `/api/saas/all_courses/`;

    setLoading(true);
    try {
      const res = await praveshAxiosGetReq(url);

      if (res.data.success) {
        setCourses(res?.data?.data);
        setLoading(false);
      } else {
        FailureAlert(res.data.error);
        setLoading(false);
      }
    } catch (error) {
      FailureAlert("some error occurred");
    }
  };

  const checkForDegree = (item) => {
    var bool_ = false;

    var arr = [...selectedCourses];
    if (selectedCourses?.length > 0) {
      arr.map((i) => {
        i.degrees.map((internal) => {
          if (internal.degree_id === item.degree_id) {
            if (internal.specialization.length > 0) bool_ = true;
            else bool_ = false;
          }
        });
      });
    } else bool_ = false;

    return bool_;
  };

  const checkForSpec = (item, deg_id, edu_id, spec_arr) => {
    var bool_ = false;
    var arr = [...selectedCourses];
    if (selectedCourses?.length > 0) {
      var eduIndex = arr.findIndex((i) => i.education_id === edu_id);
      if (eduIndex !== -1)
        var degIndex = arr[eduIndex].degrees.findIndex(
          (i) => i.degree_id === deg_id
        );

      if (eduIndex !== -1 && degIndex !== -1) {
        arr[eduIndex].degrees[degIndex].specialization.map((items) => {
          if (items.specialization_id === item.specialization_id) bool_ = true;
          // else bool_ = false;
        });
      }
    } else bool_ = false;

    return bool_;
  };

  const postNewCollege = async (data) => {

    const url = `/api/admin/college/stepper/add/`;

    setloadingCollegeInfo(true)
    try {
      const res = await praveshAxiosPostReq(url, data);

      if (res.data.success) {
        SuccessAlert(res?.data?.data?.message);

        setInterval(function () {
          window.location.reload();
        }, 1000);

      } else {
        FailureAlert(res.data.error);

      }
      setloadingCollegeInfo(false)
    } catch (error) {
      setloadingCollegeInfo(false)
      FailureAlert("some error occurred");
    }
  };

  useEffect(() => {
    let temp = {};

    if (coursesArray?.length > 0 && dataSelected?.length > 0) {
      dataSelected?.forEach((item) => {
        temp[item?.degree_id] = item?.specialization;
      });
      coursesArray?.forEach((item) => {
        item?.degrees?.forEach((item2) => {
          if (!Object.keys(temp).includes(String(item2?.degree_id)))
            temp[item2?.degree_id] = [];
        });
      });
      setSpecializationInDgegreeId(temp);
    } else {
      let temp = {};

      coursesArray?.forEach((item) => {
        item?.degrees?.forEach((item2) => {
          temp[item2?.degree_id] = [];
        });
      });
      setSpecializationInDgegreeId(temp);
    }
    var pushArr = [...pushArray];
    pushArr.push(1);
    setPushArray(pushArr);
  }, [coursesArray?.length]);

  useEffect(() => {
    if (dataSelected?.length > 0) {
      var arr = [...dataSelected];
      var newArr = [...dataSelectedNewFormat];
      arr.map((item) => {
        if (dataSelectedNewFormat?.length === 0) {
          var a = [];
          a.push(item);
          newArr.push({
            education_id: item.education_id,
            education_name: item.education_name,
            degrees: a,
          });
        } else {
          const found = dataSelectedNewFormat.some(
            (el) => el.education_id === item.education_id
          );

          if (!containsObject(item.education_id, newArr)) {
            if (item?.education_id) {
              var a = [];
              a.push(item);
              newArr.push({
                education_id: item.education_id,
                education_name: item.education_name,
                degrees: a,
              });
            }
          } else {
            var index = newArr.findIndex(
              (item2) => item2.education_id === item.education_id
            );

            var ee = arr.filter(
              (items) => items.education_id === item.education_id
            );

            newArr[index].degrees = ee;
          }
          // });
        }
      });

      const filterNewFormat = newArr.filter((educationSection) => {

        const match = dataSelected?.findIndex(degree => degree?.education_id === educationSection?.education_id);

        return match === -1 ? false : true
      });

      setDataSelectedNewFormat(filterNewFormat);

    }
    // this is for clearing when the last degree is unselected
    if (dataSelected?.length === 0) {

      setDataSelectedNewFormat([]);
    }

  }, [pushArray?.length]);

  function containsObject(id, list) {
    var i;
    var boole = false;
    for (i = 0; i < list.length; i++) {
      if (list[i].education_id === id) {
        boole = true;
      }
    }
    return boole;
  }

  useEffect(() => {
    if (dataSelectedNewFormat?.length > 0) {
      dataSelectedNewFormat?.forEach((item) => {
        item["show_more"] = false;
      });
    }
  }, [dataSelectedNewFormat?.length]);

  const handleDeleteChip = (spec_id, spec, deg_id, edu_id) => {
    var arr = [...dataSelected];

    var index = spec.findIndex((i) => i.specialization_id === spec_id);
    var degIndex = arr.findIndex((i) => i.degree_id === deg_id);

    var arrn = [...spec];
    arrn.splice(index, 1);
    if (arrn?.length > 0) {
      arr[degIndex].specialization = arrn;
    } else {
      arr.splice(degIndex, 1);
    }

    var pushArr = [...pushArray];
    pushArr.push(1);

    var loc = specializationInDegreeId;
    loc[deg_id] = arrn;

    var selectArray = [...coursesArray];
    var eduIndex = selectArray.findIndex((i) => i.education_id === edu_id);
    var newDegIndex = selectArray[eduIndex].degrees.findIndex(
      (i) => i.degree_id === deg_id
    );
    if (arrn?.length === 0) {
      selectArray[eduIndex].degrees[newDegIndex].select_all = false;
      selectArray[eduIndex].degrees[newDegIndex].specialization.forEach(
        (item) => {
          item.selected = false;
        }
      );
    } else {
      selectArray[eduIndex].degrees[newDegIndex].specialization.forEach(
        (item) => {
          if (item.specialization_id === spec_id) item.selected = false;
        }
      );
    }
    setCoursesArray(selectArray);
    setSpecializationInDgegreeId(loc);
    setDataSelected(arr);
    setPushArray(pushArr);
  };

  useEffect(() => {
    if (selectedCourses?.length > 0) {
      setDataSelectedNewFormat(selectedCourses);
      var arr = [...dataSelected];
      selectedCourses.map((item) => {
        item.degrees.map((i) =>
          arr.push({
            ...i,
            education_id: item.education_id,
            education_name: item?.education_name,
          })
        );
      });
      setDataSelected(arr);
    }
  }, [selectedCourses?.length]);

  useEffect(() => {
    if (courses?.length && selectedCourses?.length) {
      var arr = [...courses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2["select_all"] = checkForDegree(item2);
          item2["education_name"] = item.education_name;
          item2["education_id"] = item.education_id;
          item2.specialization.forEach((item3) => {
            item3["selected"] = checkForSpec(
              item3,
              item2.degree_id,
              item.education_id,
              item2.specialization
            );
            item3["degree_id"] = item2.degree_id;
            item3["degree_name"] = item2.degree_name;
          });
        });
      });
      setCoursesArray(arr);
    } else if (courses?.length) {
      var arr = [...courses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2["select_all"] = checkForDegree(item2);
          item2["education_name"] = item.education_name;
          item2["education_id"] = item.education_id;
          item2.specialization.forEach((item3) => {
            item3["selected"] = checkForSpec(
              item3,
              item2.degree_id,
              item.education_id,
              item2.specialization
            );
            item3["degree_id"] = item2.degree_id;
            item3["degree_name"] = item2.degree_name;
          });
        });
      });
      setCoursesArray(arr);
    }
  }, [courses?.length, selectedCourses?.length]);

  useEffect(() => {
    if (clgId) {
      setActiveStep(0);
      setCount(0);
      setLoading(true);
      getDataForEditPrefill();
    }
    getAllCourses();
  }, [clgId]);

  const stepperJumpAddPocDetails = () => {
    let isValid = true;
    /*  if (!formData?.firstname) {
      isValid = false;
    } else if (!formData?.lastname) {
      isValid = false;
    } else if (!formData?.designation) {
      isValid = false;
    } else if (!formData?.email || !emailRegex.test(formData?.email)) {
      isValid = false;
    } else if (formData?.phone?.length !== 10) {
      isValid = false;
    } else if (
      formData?.alternate_email &&
      !emailRegex.test(formData.alternate_email)
    ) {
      isValid = false;
    } else if (
      formData?.alternate_phone &&
      formData?.alternate_phone?.toString()?.length !== 10
    ) {
      isValid = false;
    } else {
      isValid = true;
    } */
    return isValid;
  };

  const stepperJumpAddCollegeDetails = () => {
    let isValid;
    if (!formData?.collegename) {
      isValid = false;
    } else if (isonboard === "yes" && !formData?.collegenickname) {
      isValid = false;
    } else if (!formData?.collegeaddress) {
      isValid = false;
    } else if (!formData?.organizationType) {
      isValid = false;
    } else if (isonboard === "yes" && !formData?.yearfounded) {
      isValid = false;
    } else if (isonboard === "yes" && !formData?.studentcount) {
      isValid = false;
    } else if (!formData?.website || !urlRegex.test(formData?.website)) {
      isValid = false;
    } else if (isonboard === "yes" && !formData?.description) {
      isValid = false;
    } else if (
      (isonboard === "yes" && !formData?.about) ||
      formData?.about?.length > 512
    ) {
      isValid = false;
    } else if (isonboard === "yes" && !formData?.gradesystem) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      formData?.gradesystem === "cgpa" &&
      !formData?.percentageFactor
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      !universityNotListed &&
      !formData?.university?.university_name
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      universityNotListed &&
      !formData?.universityname
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      formData?.linkedInPage &&
      !urlRegex.test(formData.linkedInPage)
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      formData?.facebookPage &&
      !urlRegex.test(formData.facebookPage)
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      formData?.instaAccount &&
      !urlRegex.test(formData.instaAccount)
    ) {
      isValid = false;
    } else if (isonboard === "yes" && formData?.country === 0) {
      isValid = false;
    } else if (isonboard === "yes" && formData.state === 0) {
      isValid = false;
    } else if (isonboard === "yes" && formData.city === 0) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      !isSaas &&
      formData?.lattitude &&
      !latitudeRegex.test(formData?.lattitude)
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      isSaas &&
      /*  formData?.lattitude &&  */
      !latitudeRegex.test(formData?.lattitude)
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      isSaas &&
      /*  formData?.longitude && */
      !longitudeRegex.test(formData?.longitude)
    ) {
      isValid = false;
    } else if (
      isonboard === "yes" &&
      !isSaas &&
      formData?.longitude &&
      !longitudeRegex.test(formData?.longitude)
    ) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const stepperJumpAddCourses = () => {
    let isValid = true;
    if (dataSelectedNewFormat?.length === 0) {
      isValid = false;
    }
    return isValid;
  };

  const stepperJumpAddCollegePrefrence = () => {
    let isValid;
    if (!formData?.jobType?.some((el) => el === true)) {
      isValid = false;
    } else if (formData?.jobSegment?.length <= 0) {
      isValid = false;
    } else if (!formData?.internshipAvailability?.some((el) => el === true)) {
      isValid = false;
    } else if (!formData?.jobAvailability?.some((el) => el === true)) {
      isValid = false;
    } else if (formData?.collegePreferenceState?.length === 0) {
      isValid = false;
    } else {
      isValid = true; //formData?.collegePreferenceState
    }
    return isValid;
  };

  const stepperJumpAddCollegeAddInfo = () => {
    let isValid;
    if (isSaas && !formData?.studentlicensecount) {
      isValid = false;
    } else if (isSaas && !formData?.getworkdomain) {
      isValid = false;
    } else if (!formData?.passcode) {
      isValid = false;
    } else if (formData?.passcode && formData?.passcode?.length > 8) {
      isValid = false;
    } else if (!isEdit && isSaas && !formData?.sideImage) {
      isValid = false;
    } else if (!isEdit && isSaas && !formData?.favicon) {
      isValid = false;
    } else if (!isEdit && !formData?.logo) {
      isValid = false;
    } else if (!isEdit && isSaas && !formData?.circularLogo) {
      isValid = false;
    } else if (!isEdit && !formData?.banner) {
      isValid = false;
    } else if (isSaas && !primaryColor) {
      isValid = false;
    } else if (isSaas && !secondaryColor) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const stepperJumpAddCollegepayment = () => {
    let isValid;
    if (!formData?.planStartDate) {
      isValid = false;
    } else if (!formData?.planEndDate) {
      isValid = false;
    } else if (!formData?.totalpayment) {
      isValid = false;
    } else if (!formData?.paidpayment) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };
  const handleStep = (step) => () => {
    const arr = [
      stepperJumpAddPocDetails,
      stepperJumpAddCollegeDetails,
      stepperJumpAddCourses,
      stepperJumpAddCollegePrefrence,
      stepperJumpAddCollegeAddInfo,
      stepperJumpAddCollegepayment,
    ];
    if (arr[step]()) {
      setActiveStep(step);
      setCount(step);
    }
  };

  function onboardstep() {
    return ["Fill POC Details", "College Basic Info" /* , "College Courses" */];
  }

  function getSteps() {
    return [
      "Fill POC Details",
      "College Basic Info",
      "College Courses",
      "College Prefrences",
      "College Additional Info",
      "College Payment",
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddNewCollegePocDetails
            formData={formData}
            setFormData={setFormData}
            emailEditable={!isEdit || extraflags?.emailEditFlag}
            noEmail={extraflags?.noEmail}

          />
        );
      case 1:
        return (
          <AddNewCollegeBasicInfo
            formData={formData}
            setFormData={setFormData}
            Countries={Countries}
            setCountries={setCountries}
            states={states}
            setStates={setStates}
            cities={cities}
            setAllCities={setAllCities}
            Universities={Universities}
            setUniversities={setUniversities}
            isonboard={isonboard}
            universityNotListed={universityNotListed}
            setUniversityNotListed={setUniversityNotListed}
            isSaas={isSaas}
          />
        );
      case 2:
        return (
          <AddCollegeCourses
            formData={formData}
            setFormData={setFormData}
            dataSelected={dataSelected}
            setDataSelected={setDataSelected}
            specializationInDegreeId={specializationInDegreeId}
            setSpecializationInDgegreeId={setSpecializationInDgegreeId}
            coursesArray={coursesArray}
            pushArray={pushArray}
            setPushArray={setPushArray}
            errorMessage={null}
            loading={loading}
            dataSelectedNewFormat={dataSelectedNewFormat}
            setDataSelectedNewFormat={setDataSelectedNewFormat}
            handleDeleteChip={handleDeleteChip}
          />
        );
      case 3:
        return (
          <AddNewCollegePrefrences
            formData={formData}
            setFormData={setFormData}
            allJobSegments={allJobSegments}
            setAllJobSegments={setAllJobSegments}
            states={states}
            setStates={setStates}
            isSaas={isSaas}
          />
        );
      case 4:
        return (
          <AddNewCollegeAdditionalInfo
            formData={formData}
            setFormData={setFormData}
            isSaas={isSaas}
            primaryColor={primaryColor}
            setPrimaryColor={setPrimaryColor}
            secondaryColor={secondaryColor}
            setSecondaryColor={setSecondaryColor}
            logoLink={logoLink}
            circularLogoLink={circularLogoLink}
            faviconLink={faviconLink}
            sideImageLink={sideImageLink}
            bannerLink={bannerLink}
            collegeId={clgId}

          />
        );
      case 5:
        return (
          <AddNewCollegePayment
            formData={formData}
            setFormData={setFormData}
            paid={paid}
            setPaid={setPaid}
            isSaas={isSaas}

          />
        );
    }
  }
  function getOnboardNo(step) {
    switch (step) {
      case 0:
        return (
          <AddNewCollegePocDetails
            formData={formData}
            setFormData={setFormData}
            emailEditable={!isEdit || extraflags?.emailEditFlag}
            noEmail={extraflags?.noEmail}
          />
        );
      case 1:
        return (
          <AddNewCollegeBasicInfo
            formData={formData}
            setFormData={setFormData}
            Countries={Countries}
            setCountries={setCountries}
            states={states}
            setStates={setStates}
            cities={cities}
            setAllCities={setAllCities}
            Universities={Universities}
            setUniversities={setUniversities}
          />
        );
      /*  case 2:
        return (
          <AddCollegeCourses
            formData={formData}
            setFormData={setFormData}
            dataSelected={dataSelected}
            setDataSelected={setDataSelected}
            specializationInDegreeId={specializationInDegreeId}
            setSpecializationInDgegreeId={setSpecializationInDgegreeId}
            coursesArray={coursesArray}
            pushArray={pushArray}
            setPushArray={setPushArray}
            errorMessage={null}
            loading={loading}
            dataSelectedNewFormat={dataSelectedNewFormat}
            setDataSelectedNewFormat={setDataSelectedNewFormat}
            handleDeleteChip={handleDeleteChip}
          />
        ); */
    }
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  // Country Finding API
  useEffect(() => {
    praveshAxiosCall
      .get("/api/location/country/")
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        FailureAlert(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    {
      praveshAxiosCall(`/api/location/state/?search=${countryId}`)
        .then((res) => {
          setStates(res.data);
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  // Finding Cities
  useEffect(() => {
    if (states) {
      praveshAxiosCall(`/api/location/city/?state_id=${formData.state}`)
        .then((res) => {
          setAllCities(res.data);
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.state]);

  // University Finding API
  useEffect(() => {
    apiCall("/api/admin/university/", requestPraveshOptions).then((res) => {
      if (res.success) {
        setUniversities(res.data);
      } else {
        FailureAlert("Invalid request for University API");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Job Segment Finding API
  useEffect(() => {
    niyuktiAxiosCall("/job/segment/")
      .then((json) => {
        setAllJobSegments(json.data);
      })
      .catch((err) => {
        FailureAlert(err.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    const arr = [
      addPocDetails,
      addCollegeDetails,
      addCourses,
      addCollegePrefrence,
      addCollegeAddInfo,
      Collegepayment,
    ];
    if (arr[count]()) {
      const newActiveStep = isLastStep()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
      setCount(count + 1);
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCount(count - 1);
  };

  const addPocDetails = () => {
    let isValid;

    if (!formData.firstname) {
      isValid = false;
      FailureAlert("First Name field cannot be left blank");
    } else if (!formData.lastname) {
      isValid = false;
      FailureAlert("Last Name field cannot be left blank");
    } else if (!formData.designation) {
      isValid = false;
      FailureAlert("Please enter Designation");
    } else if (!formData.email || !emailRegex.test(formData.email)) {
      isValid = false;
      FailureAlert("Please enter valid email");
    } else if (formData?.phone?.toString()?.length < 10) {
      isValid = false;
      FailureAlert("Please enter valid Phone Number");
    } else if (
      formData.alternate_email &&
      !emailRegex.test(formData.alternate_email)
    ) {
      isValid = false;
      FailureAlert("Please enter valid alternate email");
    } else if (
      formData.alternate_phone &&
      formData?.alternate_phone?.toString()?.length < 10
    ) {
      isValid = false;
      FailureAlert("Please enter valid alternate Phone Number");
    } else {
      isValid = true;
    }
    return isValid;
  };
  const addCollegeDetails = () => {
    let isValid;
    if (!formData.collegename) {
      isValid = false;
      FailureAlert("Please enter college name");
    } else if (isonboard === "yes" && isSaas && !formData.collegenickname) {
      isValid = false;
      FailureAlert("Please enter College nick Name");
    } else if (!formData.collegeaddress) {
      isValid = false;
      FailureAlert("Please enter college address");
    } else if (!formData.organizationType) {
      isValid = false;
      FailureAlert("Please enter Organization Type");
    } else if (isonboard === "yes" && !formData.yearfounded) {
      isValid = false;
      FailureAlert("Please enter Year Founded");
    } else if (isonboard === "yes" && !formData.studentcount) {
      isValid = false;
      FailureAlert("Please enter Student Count");
    } else if (!formData.website || !urlRegex.test(formData.website)) {
      isValid = false;
      FailureAlert("Please enter valid Website ");
    } else if (isonboard === "yes" && !formData.description || formData?.description?.length > 512) {

      isValid = false;

      if (!formData.description) {

        FailureAlert("College Description can't be empty! ");

      } else {

        FailureAlert("College Description can only have upto 512 characters...");
      }

    } else if (isonboard === "yes" && !formData?.about || formData?.about?.length > 256) {

      isValid = false;
      FailureAlert("College About can only have upto 256 characters...");

    } else if (isonboard === "yes" && !formData.gradesystem) {
      isValid = false;
      FailureAlert("Please enter Grade ");
    } else if (
      isonboard === "yes" &&
      formData.gradesystem === "cgpa" &&
      !formData?.percentageFactor
    ) {
      isValid = false;
      FailureAlert("Please Enter Percentage Factor ");

    } else if (
      isonboard === "yes" &&
      !universityNotListed && isSaas &&
      !formData?.university?.university_name
    ) {
      isValid = false;
      FailureAlert("Please enter university Name");

    } else if (
      isonboard === "yes" &&
      universityNotListed && isSaas &&
      !formData?.universityname
    ) {
      isValid = false;
      FailureAlert("Please enter university Name");
    } else if (

      isonboard === "yes" &&
      formData.linkedInPage &&
      !urlRegex.test(formData.linkedInPage)

    ) {
      isValid = false;
      FailureAlert("Please enter valid Linked Page Url");
    } else if (
      isonboard === "yes" &&
      formData.facebookPage &&
      !urlRegex.test(formData.facebookPage)
    ) {
      isValid = false;
      FailureAlert("Please enter Facebook Page Url");
    } else if (
      isonboard === "yes" &&
      formData.instaAccount &&
      !urlRegex.test(formData.instaAccount)
    ) {
      isValid = false;
      FailureAlert("Please enter insta Account Url");
    } else if (isonboard === "yes" && formData?.country === 0) {
      FailureAlert("Select Country...");
      return;
    } else if (isonboard === "yes" && formData.state === 0) {
      FailureAlert("Select State...");
      return;
    } else if (isonboard === "yes" && formData.city === 0) {
      FailureAlert("Select City...");
      return;
    } else if (
      isonboard === "yes" &&
      !isSaas &&
      formData?.lattitude &&
      !latitudeRegex.test(formData?.lattitude)
    ) {
      FailureAlert("Please enter valid lattitude...");
      return;
    } else if (
      isonboard === "yes" &&
      isSaas &&
      /*  formData?.lattitude &&  */
      !latitudeRegex.test(formData?.lattitude)
    ) {
      FailureAlert("Please enter valid lattitude...");
      return;
    } else if (
      isonboard === "yes" &&
      isSaas &&
      /*  formData?.longitude && */
      !longitudeRegex.test(formData?.longitude)
    ) {
      FailureAlert("Please enter valid longitude...");
      return;
    } else if (
      isonboard === "yes" &&
      !isSaas &&
      formData?.longitude &&
      !longitudeRegex.test(formData?.longitude)
    ) {
      FailureAlert("Please enter valid longitude...");
      return;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    const arr = [
      addPocDetails,
      addCollegeDetails,
      addCourses,
      addCollegePrefrence,
      addCollegeAddInfo,
      Collegepayment,
    ];

    if (arr[count]()) {
      e.preventDefault();
      var jobTypeArr = [];
      for (let i = 0; i < formData?.jobType.length; i++) {
        if (formData?.jobType[i]) {
          if (i === 1) {
            jobTypeArr.push({ id: i + 1, ppo: true });
          } else {
            jobTypeArr.push({ id: i + 1, ppo: false });
          }
        }
      }

      var courseDataToSend = [];
      dataSelectedNewFormat.map((item) =>
        item.degrees.map((item2) =>
          item2.specialization.map((item3) => {
            courseDataToSend.push({
              uid: 0,
              education_type: item2.education_id,
              education: item2.education_name,
              degree: item2.degree_name,
              degree_id: item2.degree_id,
              specialization_id: item3.specialization_id,
              specialization: item3.specialization_name,
              is_active: true,
            });
          })
        )
      );

      var internship_availability = [];
      for (let i = 0; i < formData?.internshipAvailability.length; i++) {
        if (formData?.internshipAvailability[i]) {
          internship_availability.push(i + 1);
        }
      }
      var job_availability = [];
      for (let i = 0; i < formData?.jobAvailability.length; i++) {
        if (formData?.jobAvailability[i]) {
          job_availability.push(i + 1);
        }
      }

      var social_links = [];
      let count = 0;
      formData?.linkedInPage &&
        social_links.push({
          id: ++count,
          Name: "linkedin",
          value: formData?.linkedInPage,
        });
      formData?.facebookPage &&
        social_links.push({
          id: ++count,
          Name: "facebook",
          value: formData?.facebookPage,
        });
      formData?.instaAccount &&
        social_links.push({
          id: ++count,
          Name: "instagram",
          value: formData?.instaAccount,
        });

      var preferences = {
        job_type: jobTypeArr,
        job_segment: formData?.jobSegment ? formData?.jobSegment : null,
        internship_availability,
        job_availability,
        location: formData?.collegePreferenceState,
      };

      var fd = new FormData();
      fd.append("is_onboarded", isonboard === "yes" ? true : false);
      isonboard === "yes" && isSaas !== null && fd.append("is_saas", isSaas);

      isSaasWithJob &&
        isSaasWithJob !== null &&
        fd.append("is_saas_with_job", isSaasWithJob);
      isonboard === "yes" && isSaas && fd.append("is_paid", paid);

      fd.append(
        "person_first_name",
        formData?.firstname ? formData?.firstname : null
      );
      fd.append(
        "person_last_name",
        formData?.lastname ? formData?.lastname : null
      );
      fd.append(
        " person_designation",
        formData?.designation ? formData?.designation : null
      );
      fd.append("person_email", formData?.email ? formData?.email : null);
      fd.append("person_mobile", parseInt(formData?.phone));
      formData?.alternate_email &&
        fd.append(
          "alternate_email",
          formData?.alternate_email ? formData?.alternate_email : null
        );
      formData?.alternate_phone &&
        fd.append(
          "alternate_mobile",
          formData?.alternate_phone ? parseInt(formData?.alternate_phone) : null
        );

      /* college basic info */
      fd.append("name", formData?.collegename ? formData?.collegename : null);
      isonboard === "yes" && formData?.collegenickname &&
        fd.append(
          "nick_name",
          formData?.collegenickname
        );
      fd.append(
        "address",
        formData?.collegeaddress ? formData?.collegeaddress : null
      );
      fd.append(
        "organization_type",
        formData?.organizationType ? formData?.organizationType : null
      );
      isonboard === "yes" &&
        fd.append(
          "year_founded",
          formData?.yearfounded ? formData?.yearfounded : null
        );
      isonboard === "yes" &&
        fd.append("size", formData.studentcount ? formData.studentcount : null);
      fd.append("website", formData?.website ? formData?.website : null);
      isonboard === "yes" &&
        fd.append(
          "description",
          formData?.description ? formData?.description : null
        );
      isonboard === "yes" &&
        fd.append("about", formData?.about ? formData?.about : null);
      isonboard === "yes" &&
        fd.append(
          "is_grade_system",
          formData?.gradesystem === "cgpa" ? true : false
        );
      isonboard === "yes" &&
        formData?.gradesystem === "cgpa" &&
        fd.append(
          "percentage_factor",
          formData?.percentageFactor ? parseInt(formData?.percentageFactor) : 0
        );
      //universityname: "",
      formData?.university?.university_id && isonboard === "yes" &&
        !universityNotListed &&
        fd.append("university_id", formData?.university?.university_id);

      (formData?.university?.university_name || formData?.universityname) && isonboard === "yes" &&
        fd.append(
          "university_name",
          !universityNotListed
            ? formData?.university?.university_name
            : formData?.universityname
        );
      isonboard === "yes" &&
        fd.append("social_links", JSON.stringify(social_links));
      isonboard === "yes" &&
        fd.append(
          "country",
          formData?.country ? formData?.country?.country_id : null
        );
      isonboard === "yes" &&
        isonboard === "yes" &&
        fd.append("state", formData?.state ? formData?.state?.state_id : null);
      isonboard === "yes" &&
        fd.append("city", formData?.city ? formData?.city?.city_id : null);

      isonboard === "yes" &&
        formData?.lattitude &&
        fd.append("latitude", formData?.lattitude ? formData?.lattitude : null);
      isonboard === "yes" &&
        formData?.longitude &&
        fd.append(
          "longitude",
          formData?.longitude ? formData?.longitude : null
        );

      /* courses */
      isonboard === "yes" &&
        fd.append("degree_specialization", JSON.stringify(courseDataToSend));

      /*   var newJobSegment = {
      job_segment_id: formData?.jobSegment?.job_segment_id
        ? formData?.jobSegment?.job_segment_id
        : null,
      job_segment_name: formData?.jobSegment?.job_segment_name
        ? formData?.jobSegment?.job_segment_name
        : null,
    }; */
      /* college Preferences */
      isonboard === "yes" &&
        fd.append("preferences", JSON.stringify(preferences));
      isonboard === "yes" &&
        fd.append(
          "job_segment",
          formData?.jobSegment ? JSON.stringify(formData?.jobSegment) : null
        );

      isonboard === "yes" &&
        isSaas &&
        fd.append(
          "student_license_count",
          formData?.studentlicensecount ? formData?.studentlicensecount : null
        );
      isonboard === "yes" &&
        isSaas &&
        fd.append(
          "domain",
          formData?.getworkdomain ? formData?.getworkdomain : null
        );
      isonboard === "yes" &&
        // formData?.passcode  &&
        fd.append("passcode", formData?.passcode || '');

      !isEdit &&
        isonboard === "yes" &&
        isSaas &&
        fd.append(
          "side_image",
          formData?.sideImage ? formData?.sideImage : null
        );
      !isEdit &&
        isonboard === "yes" &&
        fd.append("logo", formData?.logo ? formData?.logo : null);
      !isEdit &&
        isonboard === "yes" &&
        isSaas &&
        fd.append(
          "circular_logo",
          formData?.circularLogo ? formData?.circularLogo : null
        );
      !isEdit &&
        isonboard === "yes" &&
        isSaas &&
        fd.append("favicon", formData?.favicon ? formData?.favicon : null);
      !isEdit &&
        isonboard === "yes" &&
        fd.append("banner", formData?.banner ? formData?.banner : null);

      if (
        isEdit &&
        isonboard === "yes" &&
        isSaas &&
        formData?.sideImage !== sideImageRes
      ) {
        fd.append(
          "side_image",
          formData?.sideImage ? formData?.sideImage : null
        );
      }
      if (isEdit && isonboard === "yes" && formData?.logo !== logoRes) {
        fd.append("logo", formData?.logo ? formData?.logo : null);
      }
      if (
        isEdit &&
        isonboard === "yes" &&
        isSaas &&
        formData?.circularLogo !== circularLogoRes
      ) {
        fd.append(
          "circular_logo",
          formData?.circularLogo ? formData?.circularLogo : null
        );
      }
      if (
        isEdit &&
        isonboard === "yes" &&
        isSaas &&
        formData?.favicon !== faviconRes
      ) {
        fd.append("favicon", formData?.favicon ? formData?.favicon : null);
      }
      if (
        isEdit &&
        isonboard === "yes" &&
        isSaas &&
        formData?.banner !== bannerRes
      ) {
        fd.append("banner", formData?.banner ? formData?.banner : null);
      }

      isonboard === "yes" &&
        isSaas &&
        fd.append("primary_color", primaryColor ? primaryColor : null);
      isonboard === "yes" &&
        isSaas &&
        fd.append("secondary_color", secondaryColor ? secondaryColor : null);

      /* college payment */
      isonboard === "yes" && formData?.planStartDate &&
        fd.append(
          "plan_start_date",
          formData?.planStartDate
        );
      isonboard === "yes" && formData?.planEndDate &&
        fd.append(
          "plan_end_date",
          formData?.planEndDate
        );
      isonboard === "yes" && formData?.totalpayment &&
        fd.append(
          "amount",
          formData?.totalpayment
        );
      isonboard === "yes" && formData?.paidpayment &&
        fd.append(
          "paid_amount",
          formData?.paidpayment
        );
      fd.append("is_premium", formData?.premium)
      isEdit ? patchForEdit(fd) : postNewCollege(fd);
      /*   } */
    }
  };

  const addCourses = () => {
    if (dataSelectedNewFormat?.length === 0 && isSaas) {
      FailureAlert("Please Select Courses...");
      return;
    } else {
      return true;
    }
  };
  const addCollegePrefrence = () => {
    let isValid;
    if (isSaas && !formData?.jobType?.some((el) => el === true)) {
      isValid = false;
      FailureAlert("Please enter jobtype");
      return;
    } else if (isSaas && formData?.jobSegment?.length <= 0) {
      isValid = false;
      FailureAlert("Select atleast one Job Segment to continue...");
      return;
    } else if (isSaas && !formData?.internshipAvailability?.some((el) => el === true)) {
      isValid = false;
      FailureAlert("Select atleast one month for internship to continue...");
      return;
    } else if (isSaas && !formData?.jobAvailability?.some((el) => el === true)) {
      isValid = false;
      FailureAlert("Select atleast one month for full time job to continue...");
      return;
    } else if (isSaas && formData?.collegePreferenceState?.length === 0) {
      isValid = false;
      FailureAlert("Select atleast one state to continue...");
      return;
    } else {
      isValid = true; //formData?.collegePreferenceState
    }
    return isValid;
  };

  const addCollegeAddInfo = () => {
    let isValid;
    if (isSaas && !formData.studentlicensecount) {
      isValid = false;
      FailureAlert("Please enter License Count");
    } else if (isSaas && !formData.getworkdomain) {
      isValid = false;
      FailureAlert("Please enter Get work Domain");
    } else if (isSaas && !formData?.passcode) {
      isValid = false;
      FailureAlert("Please Enter Passcode...");
      return;
    } else if (formData?.passcode && formData?.passcode?.length > 8) {
      isValid = false;
      FailureAlert("Passcode can't exceed 8 characters...");
      return;
    } else if (!isEdit && isSaas && !formData?.sideImage) {
      FailureAlert("Upload College Side Image...");
      return;
    } else if (!isEdit && isSaas && !formData?.favicon) {
      FailureAlert("Upload Favicon...");
      return;
    } else if (!isEdit && !formData?.logo) {
      FailureAlert("Upload College  Logo...");
      return;
    } else if (!isEdit && isSaas && !formData?.circularLogo) {
      FailureAlert("Upload College Circular Logo...");
      return;
    } else if (!isEdit && !formData?.banner) {
      FailureAlert("Upload College Banner...");
      return;
    } else if (isSaas && !primaryColor) {
      FailureAlert("Choose Primary Color...");
      return;
    } else if (isSaas && !secondaryColor) {
      FailureAlert("Upload Secondary Color...");
      return;
    } else {
      return true;
    }
  };

  const Collegepayment = () => {

    let isValid;

    if (isSaas && !formData.planStartDate) {
      isValid = false;
      FailureAlert("Please Select Start Date");
    } else if (isSaas && !formData.planEndDate) {
      isValid = false;
      FailureAlert("Please Select End Date");
    } else if (isSaas && formData?.planStartDate > formData?.planEndDate) {
      FailureAlert("Please Enter Correct Plan Start Date and End Date");
      isValid = false;
      return;
    } else if (isSaas && !formData.totalpayment) {
      isValid = false;
      FailureAlert("Please mention Total Amount");
    } else if (isSaas && !formData.paidpayment) {
      isValid = false;
      FailureAlert("Please mention Paid Amount");
    } else {
      isValid = true;
    }
    // for optional cases of college payemnt
    if (formData.planStartDate && formData?.planEndDate) {

      if (formData?.planStartDate > formData?.planEndDate) {

        FailureAlert("Please Enter Correct Plan Start Date and End Date");
        isValid = false;
        return;
      }
    }

    return isValid;
  };

  const search = useLocation().search;
  const Id = new URLSearchParams(search).get("Id");


  return !loading ? (
    !idCollegeCourses ? (
      <div className={classes.root}>
        {!collegeName ? (
          <h2>{isEdit ? "Edit College" : "Add New College"}</h2>
        ) : (
          <h2>{collegeName}</h2>
        )}
        <hr />
        <Form.Label>Is Onboarded* : </Form.Label>
        <Form.Group type="radio" id="isonboard" required>
          <Form.Check
            inline
            name="is_onboarded"
            type="radio"
            label="Yes"
            id="yes"
            value="yes"
            checked={isonboard === "yes" ? true : false}
            onChange={(e) => setIsOnboard(e.target.value)}
          />
          <Form.Check
            inline
            name="is_onboarded"
            type="radio"
            label="No"
            id="no"
            value="no"
            checked={isonboard === "no" ? true : false}
            onChange={(e) => {
              setIsOnboard(e.target.value);
              setIsSaas(null);
              setisSaasWithJob(null);
            }}
          />
          {isonboard === "yes" && (
            <>
              <br></br>
              <Form.Label>
                <u>SAAS College*</u>
              </Form.Label>
              <Form.Group type="radio" id="isSaas" required>
                <Form.Check
                  inline
                  id="yes"
                  type="radio"
                  name="saas_college"
                  label="Yes"
                  checked={isSaas !== null && isSaas === true && isSaas}
                  onChange={(e) => setIsSaas(true)}
                />
                <Form.Check
                  inline
                  id="no"
                  type="radio"
                  name="saas_college"
                  label="No"
                  checked={isSaas !== null && isSaas === false && !isSaas}
                  onChange={(e) => {
                    setIsSaas(false);
                    setisSaasWithJob(null);
                  }}
                />
              </Form.Group>
            </>
          )}
          {isonboard === "yes" && isSaas && (
            <>
              <br></br>
              <Form.Label>
                <u>SAAS With Job*</u>
              </Form.Label>
              <Form.Group type="radio" id="isSaasWithJob" required>
                <Form.Check
                  inline
                  id="yes"
                  type="radio"
                  name="SAAS College"
                  label="Yes"
                  checked={
                    isSaasWithJob !== null &&
                    isSaasWithJob === true &&
                    isSaasWithJob
                  }
                  onChange={(e) => setisSaasWithJob(true)}
                />
                <Form.Check
                  inline
                  id="no"
                  type="radio"
                  name="SAAS College"
                  label="No"
                  checked={
                    isSaasWithJob !== null &&
                    isSaasWithJob === false &&
                    !isSaasWithJob
                  }
                  onChange={(e) => setisSaasWithJob(false)}
                />
              </Form.Group>
            </>
          )}
          {isonboard === "no" && (
            <>
              <Stepper nonLinear activeStep={activeStep}>
                {onboardsteps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      completed={completed[index]}
                      onClick={handleStep(index)}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div>
                  <Typography className={classes.instructions}>
                    {getOnboardNo(activeStep)}
                  </Typography>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={activeStep === 1 ? handleSubmit : handleNext}
                      className={classes.button}
                      style={{ float: "right", width: '5rem', height: '2rem' }}

                      marginRight="5px"
                    >
                      {activeStep === 1
                        ?
                        loadingCollegeInfo ? <CustomButtonCircularProgress /> :
                          (isEdit ? "Update" : "Submit")
                        : "Next"
                      }
                    </Button>

                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      style={{ float: "right", width: '5rem', height: '2rem' }}

                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
          {isonboard === "yes" && isSaas !== null && !isSaas && (
            <>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      completed={completed[index]}
                      onClick={handleStep(index)}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Typography>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={activeStep === 5 ? handleSubmit : handleNext}
                      className={classes.button}
                      style={{ float: "right", width: '5rem', height: '2rem' }}
                      marginRight="5px"
                    >
                      {activeStep === 5
                        ?
                        loadingCollegeInfo ? <CustomButtonCircularProgress /> :
                          (isEdit ? "Update" : "Submit")
                        : "Next"}
                    </Button>

                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      style={{ float: "right", width: '5rem', height: '2rem' }}

                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}

          {isonboard === "yes" && isSaas && isSaasWithJob !== null && (
            <>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      completed={completed[index]}
                      onClick={handleStep(index)}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Typography>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={activeStep === 5 ? handleSubmit : handleNext}
                      className={classes.button}
                      style={{ float: "right", width: '5rem', height: '2rem' }}
                      marginRight="5px"
                    >
                      {activeStep === 5
                        ?
                        loadingCollegeInfo ? <CustomButtonCircularProgress /> :
                          (isEdit ? "Update" : "Submit")
                        : "Next"}
                    </Button>

                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      style={{ float: "right", width: '5rem', height: '2rem' }}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Form.Group>
      </div>
    ) : (
      <AddCollegeCourses
        formData={formData}
        setFormData={setFormData}
        dataSelected={dataSelected}
        setDataSelected={setDataSelected}
        specializationInDegreeId={specializationInDegreeId}
        setSpecializationInDgegreeId={setSpecializationInDgegreeId}
        coursesArray={coursesArray}
        pushArray={pushArray}
        setPushArray={setPushArray}
        errorMessage={null}
        loading={loading}
        dataSelectedNewFormat={dataSelectedNewFormat}
        setDataSelectedNewFormat={setDataSelectedNewFormat}
        handleDeleteChip={handleDeleteChip}
        idCollegeCourses={idCollegeCourses}
      />
    )
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}
export default AddNewCollegeStepper;
