import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, {useState, useEffect } from "react";
import { FailureAlert } from "../../../../util/Notifications";
import EducationForm from "./EductionForm";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
}));

export default function AddEducationDialog(props) {
  const classes = useStyles();

  const {collegeList, boardsList, open, handleClose, editEducation, setEditEducation, saveEducation} = props;
  const [collegeNotListed, setCollegeNotListed] = useState(false);
  const [isCGPA, setIsCgpa] = useState(false);

  const validateForm = () => {
    if(!editEducation?.education)
    {
      FailureAlert("Education type can't be empty");
      return false;
    }
    if(editEducation?.education > 2){
    if(!collegeNotListed && (!editEducation?.college || editEducation.college === 142))
    {
      FailureAlert("Select a college");
      return false;
    }
    if(collegeNotListed && !editEducation?.temp_college_name)
    {
      FailureAlert("Type a college");
      return false;
    }
    if(editEducation?.is_saas && !editEducation?.enrollment_id)
    {
      FailureAlert("Enter Enrollment Id");
      return false;
    }
    if(!editEducation?.degree_id){
      FailureAlert("Degree can't be empty");
      return false;
    }
    if(!editEducation?.specialization_id){
      FailureAlert("Specialization can't be empty");
      return false;
    }
  }
  else{
    if(!editEducation?.board)
    {
      FailureAlert("Select Board");
      return false;
    } 
  }

    if(!editEducation?.start_date){
      FailureAlert("Start Date can't be empty");
      return false;
    }
    if(!editEducation?.end_date)
    {
      FailureAlert("End Date can't be empty");
      return false;
    }
    if(!editEducation?.is_grade_system && (!editEducation?.percentage || editEducation?.percentage <=0 || editEducation?.percentage > 100))
      {
        FailureAlert("Invalid Percentage");
        return false;
      }
      if(editEducation?.is_grade_system && (!editEducation?.cgpa || editEducation?.cgpa <=0 || editEducation?.cgpa > 100))
      {
        FailureAlert("Invalid CGPA");
        return false;
      }
    return true;
  }

  useEffect (() => {
    if(collegeNotListed){
      setIsCgpa(false);
      setEditEducation({...editEducation, degree_id: null, degree: null, specialization: null, specialization_id: null, enrollment_id: null})
    }
  }, [collegeNotListed])

  const finalHandleClose = () => {
    setIsCgpa(false);
    setCollegeNotListed(false);
    handleClose();
  }

  useEffect(() => {
    if(!editEducation?.college || editEducation?.college === 142){
      setCollegeNotListed(true);
    }else{
      setCollegeNotListed(false);
    }
  }, [editEducation?.college])

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={finalHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>Add Education</b>
          </Typography>
          <EducationForm editEducation={editEducation} 
          setEditEducation={setEditEducation} 
          collegeList={collegeList} 
          boardsList={boardsList} 
          collegeNotListed={collegeNotListed} 
          setCollegeNotListed={setCollegeNotListed} 
          isCGPA={isCGPA} 
          setIsCgpa={setIsCgpa}
          />
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                onClick={finalHandleClose}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button variant="contained" color="primary" onClick={() => {
                if(validateForm()){
                  saveEducation();
                }
              }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
