import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  styled,
  withStyles,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
//import pSBC from "shade-blend-color";

let red = "#C4161C";
let cgreen = "#0B8A00";

const rate = {
  backgroundColor: "#CEE8CC !important",
  color: cgreen,
  borderColor: cgreen,
  border: "1px solid",
  padding: "0px !important",
};

const greenchip = {
  marginLeft: "20px",
  backgroundColor: "#CEE8CC !important",
  color: cgreen,
  borderColor: cgreen,
  paddingLeft: "20px",
  paddingRight: "20px",
};

const redchip = {
  marginLeft: "20px",
  backgroundColor: "#F3D0D2",
  color: red,
  borderColor: red,
  paddingLeft: "20px",
  paddingRight: "20px",
};

const ScoreTooltip = withStyles((theme) => ({
  tooltip: {
    borderRadius: "8px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
    // maxWidth: 320,
    // height: 320,
    display: "flex",
    justifyContent: "center",
    padding: "30px",
    alignItems: "center",
    alignContent: "center",
  },
}))(Tooltip);

export default function ScoreDialog(props) {
  //const classes = useStyles();
  return (
    <div>
      <ScoreTooltip
        placement="right"
        title={
          <>
            <div /* className={classes.root1} */>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "600", color: "#000" }}
                  >
                    Relevance Score Based On
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Key Skills
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress
                      variant="determinate"
                      size={15}
                      style={{ color: "#12CB02" }}
                      value={
                        props?.scoreData?.key_skills
                          ? props?.scoreData?.key_skills
                          : 1
                      }
                    />
                    <Typography
                      variant="body2"
                      style={{ marginLeft: "10px", color: "#000" }}
                    >
                      {props?.scoreData?.key_skills ?? 0}% Match
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Comm Skills
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress
                      variant="determinate"
                      style={{ color: "#EBB241" }}
                      size={15}
                      value={
                        props?.scoreData?.communication_skills
                          ? props?.scoreData?.communication_skills
                          : 1
                      }
                    />
                    <Typography
                      variant="body2"
                      style={{ marginLeft: "10px", color: "#000" }}
                    >
                      {props?.scoreData?.communication_skills ?? 0}% Match
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="body2" style={{ color: "#000" }}>
                    {" "}
                    Location
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {props?.scoreData?.location ? "Match" : "Not Match"}
                      </Typography>
                    }
                    style={props?.scoreData?.location ? greenchip : redchip}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Salary
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {props?.scoreData?.salary ? "Match" : "Not Match"}
                      </Typography>
                    }
                    style={props?.scoreData?.salary ? greenchip : redchip}
                  />
                </Grid>{" "}
                <Grid item sm={4}>
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Work Ex
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {props?.scoreData?.work_exp ? "Match" : "Not Match"}
                      </Typography>
                    }
                    style={props?.scoreData?.work_exp ? greenchip : redchip}
                  />
                </Grid>{" "}
                <Grid item sm={4}>
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Education
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                        }}
                      >
                        {props?.scoreData?.education ? "Match" : "Not Match"}
                      </Typography>
                    }
                    style={props?.scoreData?.education ? greenchip : redchip}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        }
      >
        <Chip
          size="small"
          variant="outlined"
          label={
            <Typography
              color="inherit"
              style={{
                fontWeight: "400",
                fontSize: "14px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              {props.score}
            </Typography>
          }
          style={rate}
          //className={classes.rate}
        />
      </ScoreTooltip>
    </div>
  );
}
