import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
}));

export default function SaveWorkExperience({experience, handleDelete, handleClickOpen, index, setEditWorkEx, setIsEdit, setEditIndex}) {

  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{
              marginBottom: "4px",
            }}
          >
            {experience.job_designation}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton color="primary" onClick={() => {
              setEditIndex(index);
              setIsEdit(true);
              handleClickOpen();
              setEditWorkEx(experience);
            }}>
              <EditIcon />
            </IconButton>
            <IconButton color="primary" onClick={() => {
              handleDelete(experience?.id, index)
            }}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
          }}
        >
          {" "}
          {experience.company_name}{" "}
        </Typography>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            fontWeight: "bold",
          }}
        >
         <span color="primary"> {experience?.job_type_name}</span>{" "}
        </Typography>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            fontWeight: "bold",
          }}
        >
         <span color="primary"> {experience?.formatted_start_date} - {`${experience?.formatted_end_date ? experience.formatted_end_date : 'Present' }`}</span>{" "}
        </Typography>

        <Typography>
          {experience?.job_description}
        </Typography>
      </div>
    </>
  );
}
