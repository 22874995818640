import { FailureAlert, SessionExpireAlert } from "./Notifications";
import { apiCall } from "./apiCall";

const requestOptionsPost = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
};

function setTablesOrderData() {
  const tableOrderData = {};

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key && key.startsWith("table_order")) {
      const data = localStorage.getItem(key);

      tableOrderData[key] = data;
    }
  }

  localStorage.clear();

  for (const [key, data] of Object.entries(tableOrderData)) {
    localStorage.setItem(key, data);
  }
}

export const logoutUser = () => {
  apiCall("/api/logout/", requestOptionsPost).then((res) => {
    if (res.success) {
      setTablesOrderData();
      SessionExpireAlert("Logging you out... \nPlease login again...");
      setInterval(function () {
        window.location.pathname = "/login";
        localStorage.removeItem("_expiredTime");
      }, 2000);
    } else {
      FailureAlert("Invalid token");
    }
  });
};

export const logoutAllUsers = () => {
  apiCall("/api/logout/all/", requestOptionsPost).then((res) => {
    if (res.success) {
      setTablesOrderData();
      SessionExpireAlert("Logging you out... \nPlease login again...");
      setInterval(function () {
        window.location.pathname = "/login";
        localStorage.removeItem("_expiredTime");
      }, 2000);
    } else {
      FailureAlert("Invalid token");
    }
  });
};
