import { Chip, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function SaveLanguages({data, langIndex, handleDelete}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {data.name}
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton onClick={() => handleDelete(langIndex)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
          }}
        >
          Proficiency: {data.proficiency}
        </Typography>

        <div className={classes.root}>
          {data?.level && data.level.map((data1, index) => {
            return (
              <li key={index}>
                <Chip
                  color="primary"
                  variant="outlined"
                  size="small"
                  label={data1}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </div>
      </div>
    </>
  );
}
