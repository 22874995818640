//import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  CircularProgress,
  Divider, FormControl as FormControl1, FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
  useTheme
} from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons/";
//import { ArticleOutlinedIcon } from "@material-ui/icons/";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
// import { useAlert } from "react-alert";
//import { HouseIcon } from "@material-ui/icons/";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import PsychologyOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import moment from "moment";
//import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import user from "../../../assets/png/user.png";
import generateCsv from "../../../constants";
import { niyuktiAxiosGetReq } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import RateAndApply from "../../Student/Components/Dialog/RateAndApply";
import SendMail from "../../Student/Components/Dialog/SendMail";
import CandidateComments from "./CandidateComments";



//import SendJobLink from "../../Components/Dialog/Job/SendJobLink";

let cgreen = "#0B8A00";

const Btn = {
  height: "40px",
  boxShadow: "none",
  borderRadius: "8px",
  fontSize: "15px",
  width: "180px",
  mr: 2,
};

export default function JobCandidate({
  cardViewApplicantList,
  setCardViewApplicantList,
  loading,
  selectedRow,
  setSelectedRow,
  moveNextArray,
  callBackMainApplicantApi,
  selectedJob,
  activeStatusObject,
  isPublic,
  sharedPublicTrackData,
  item,
  errorCallback,
  handleUnlockCandidate,
  loadingUnlock,
  userPermission,
  jobId,
  setApplicantData,
  applicantData,
  selectedCandidates,
  setSelectedCandidates,
  handleSingleSelect,
  setSourceResultsList,
  sourceResultsList,
  loadingUnlockSingle,
  handleCheckChange,
  selectedStudendsList,
  sendMailAll,
  setSendMailAll,
  openSendMailDialog,
  handleCloseSendMail,
  setOpenSendMailDialog,
  selectedStudentId,
  setSelectedStudentId,
  value,
  jobFromSourceJob,
  // studentIds,
  // setStudentIds,
}) {
  const [viewAll, setViewAll] = useState(false);
  
  const getBasicData = (item) => {
    return [
      {
        icon: <PhoneIcon style={{ color: "#6C757D" }} />,
        info: item?.phone ? item?.phone : "NA",
        type: "Phone Number",
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "phone"
          )
            ? true
            : false,
      },
      {
        icon: <MailOutlineIcon style={{ color: "#6C757D" }} />,
        info: item?.email ? item?.email : "NA",
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "email"
          )
            ? true
            : false,
      },
      {
        icon: <HomeOutlinedIcon style={{ color: "#6C757D" }} />,
        info: item?.current_city ? item?.current_city : "NA",
        show: item?.current_city ? true : false,
      },
    ];
  };

  const getSecondaryData = (item) => {
    return [
      {
        heading: "Total Experience",
        info: item?.work_ex ? item?.work_ex : "NA",

        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "experience"
            )) &&
          item?.work_ex
            ? true
            : false,
      },
      {
        heading: "Current CTC",
        info: item?.cur_ctc ? `${item?.cur_ctc}` : "NA",
        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "current_salary"
            )) &&
          item?.cur_ctc
            ? true
            : false,
      },
      {
        heading: "Expected CTC",
        info: item?.expected_ctc ? `${item?.expected_ctc}` : "NA",
        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "expected_salary"
            )) &&
          item?.expected_ctc
            ? true
            : false,
      },
      {
        heading: "Notice Period",
        info: item?.notice_period ? `${item?.notice_period}` : "NA",
        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "notice_period"
            )) &&
          item?.notice_period
            ? true
            : false,
      },
    ];
  };

  const getDetailedData = (item) => {
    var skill_arr = [];
    if (item?.skill && item?.skill?.length > 0) {
      item?.skill?.forEach((inn) => {
        if (inn?.skill_name !== null && inn?.skill_name !== "") {
          skill_arr.push(inn);
        }
      });
    }
    let skill_csv = generateCsv(skill_arr, "skill_name");
    let skill_csv_arr = [];
    if (skill_arr?.length) skill_csv_arr = [skill_csv];
    return [
      {
        icon: <WorkOutlineOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Experience",
        info: item?.work_experience
          ?.slice(0, 2)
          .map((item) =>
            item?.end_date
              ? `${item?.job_designation} at ${
                  item?.company_name
                }: ${item?.start_date?.slice(0, 4)} - ${item?.end_date?.slice(
                  0,
                  4
                )}`
              : `${item?.job_designation} at ${
                  item?.company_name
                }: ${item?.start_date?.slice(0, 4)} - Present`
          ),
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "experience"
          )
            ? true
            : false,
      },
      //   {
      //     icon: <SchoolOutlinedIcon style={{ color: "#6C757D" }} />,
      //     heading: "Education",
      //     info: item?.education
      //       ?.slice(0, 2)
      //       .map((item) =>
      //         item?.temp_college_name && item?.college === 142
      //           ? `${item?.degree ? item?.degree + " at " : ""}${
      //               item?.temp_college_name
      //             }: ${
      //               item?.is_current && !item?.end_date
      //                 ? (item?.start_date
      //                     ? item?.start_date?.slice(0, 4)
      //                     : "NA") + " - Present"
      //                 : (item?.start_date
      //                     ? item?.start_date?.slice(0, 4)
      //                     : "NA") +
      //                   " - " +
      //                   (item?.end_date ? item?.end_date?.slice(0, 4) : "NA")
      //             }`
      //           : item?.college
      //           ? `${item?.degree ? item?.degree + " at " : ""}${
      //               item?.college_name
      //             }: ${
      //               item?.is_current && !item?.end_date
      //                 ? (item?.start_date
      //                     ? item?.start_date?.slice(0, 4)
      //                     : "NA") + " - Present"
      //                 : (item.start_date ? item?.start_date?.slice(0, 4) : "NA") +
      //                   " - " +
      //                   (item?.end_date ? item?.end_date?.slice(0, 4) : "NA")
      //             }`
      //           : "Invalid College Entry (NA)"
      //       ),
      //     show:
      //       !isPublic ||
      //       !sharedPublicTrackData?.action_performed?.table_columns ||
      //       sharedPublicTrackData?.action_performed?.table_columns?.some(
      //         (item) => item === "education"
      //       )
      //         ? true
      //         : false,
      //   },
      {
        icon: <SchoolOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Education",
        info: item?.education
          ?.slice(0, 2)
          .map((item) =>
            item?.education_level < 3
              ? `${item?.education_level === 1 ? "10th: " : "12th: "}${
                  item?.temp_college_name
                    ? `${item?.temp_college_name}, `
                    : item?.college_name
                    ? `${item?.college_name}, `
                    : ""
                }${item?.board ? `${item?.board},` : ""} ${
                  item?.end_date
                    ? `${moment(item?.end_date)?.format("MMM YYYY")}, `
                    : ""
                } ${item?.percentage ? `${item?.percentage}%` : ""}`
              : item?.temp_college_name && item?.college === 142
              ? `${
                  item?.degree
                    ? item?.specialization
                      ? `${item?.degree} in ${item?.specialization} at `
                      : item?.degree + " at "
                    : ""
                }${item?.temp_college_name}: ${
                  item?.is_current && !item?.end_date
                    ? (item?.start_date
                        ? /* item?.start_date?.slice(0, 4) */ moment(
                            item?.start_date
                          )?.format("MMM YYYY")
                        : "NA") + " - Present"
                    : (item?.start_date
                        ? /* item?.start_date?.slice(0, 4) */ moment(
                            item?.start_date
                          )?.format("MMM YYYY")
                        : "NA") +
                      " - " +
                      (item?.end_date
                        ? /*  item?.end_date?.slice(0, 4) */ moment(
                            item?.end_date
                          )?.format("MMM YYYY")
                        : "NA")
                }`
              : item?.college
              ? `${
                  item?.degree
                    ? item?.specialization
                      ? `${item?.degree} in ${item?.specialization} at `
                      : item?.degree + " at "
                    : ""
                }${item?.college_name ?? "NA"}: ${
                  item?.is_current && !item?.end_date
                    ? (item?.start_date
                        ? /* item?.start_date?.slice(0, 4) */ moment(
                            item?.start_date
                          )?.format("MMM YYYY")
                        : "NA") + " - Present"
                    : (item.start_date
                        ? /* item?.start_date?.slice(0, 4) */ moment(
                            item?.start_date
                          )?.format("MMM YYYY")
                        : "NA") +
                      " - " +
                      (item?.end_date
                        ? /* item?.end_date?.slice(0, 4) */ moment(
                            item?.end_date
                          )?.format("MMM YYYY")
                        : "NA")
                }`
              : "Invalid College Entry (NA)"
          ),
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "education"
          )
            ? true
            : false,
      },
      // {
      //   icon: <ArticleOutlinedIcon style={{ color: "#6C757D" }} />,
      //   heading: "Skills",
      //   info: skill_csv_arr,
      //   show:
      //     !isPublic ||
      //     sharedPublicTrackData?.action_performed?.table_columns?.some(
      //       (item) => item === "skills"
      //     )
      //       ? true
      //       : false,
      // },
      // {
      //   icon: <LocationOnOutlinedIcon style={{ color: "#6C757D" }} />,
      //   heading: "Preferred Location",
      //   info: item?.preferred_locations?.map((item) => item.city),
      //   show:
      //     !isPublic ||
      //     sharedPublicTrackData?.action_performed?.table_columns?.some(
      //       (item) => item === "preferred_location"
      //     )
      //       ? true
      //       : false,
      // },
    ];
  };

  const getDetailedSecondData = (item) => {
    var skill_arr = [];
    if (item?.skill && item?.skill?.length > 0 && viewAll) {
      item?.skill?.forEach((inn) => {
        if (inn?.skill_name !== null && inn?.skill_name !== "") {
          skill_arr.push(inn);
        }
      });
    } else if (item?.skill && item?.skill?.length > 0 && !viewAll) {
      for (let i = 0; i < 10; i++) {
        if (item?.skill[i]?.skill_name) {
          skill_arr.push(item?.skill[i]);
        }
      }
    }
    let skill_csv = generateCsv(skill_arr, "skill_name");
    let skill_csv_arr = [];
    if (skill_arr?.length) skill_csv_arr = [skill_csv];

    let prefferedLocationDetails = [];
    let prefLocStr = ``;
    if (item?.preferred_locations?.length > 0) {
      for (let i = 0; i < item?.preferred_locations?.length; i++) {
        prefLocStr += `${item?.preferred_locations[i].city}, `;
      }
    }
    prefLocStr &&
      prefferedLocationDetails.push(prefLocStr.replace(/,\s*$/, ""));

    return [
      {
        icon: <PsychologyOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Skills",
        info: skill_csv_arr,
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "skills"
          )
            ? true
            : false,
      },
      {
        icon: <LocationOnOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Preferred Location",
        info: prefferedLocationDetails,
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "preferred_location"
          )
            ? true
            : false,
      },
    ];
  };

  const checkSocialLink = (name, value) => {
    let link = "";
    switch (name) {
      case "Linkedin":
        link = value;
        return link;

      case "Github":
        link = value;
        return link;

      case "Facebook":
        link = value;
        return link;

      case "Instagram":
        link = value;
        return link;

      default:
        break;
    }
  };

  const [open, setOpen] = React.useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const handleClickOpen = (item) => {
    setOpen(true);
    setCurrentCandidate({
      applicantName: item?.first_name + " " + item?.last_name,
      userId: item?.user_id,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [unlockedData, setUnlockedData] = useState(null);

  //const alert = useAlert();
  const [openMove, setOpenMove] = React.useState(false);
  const [currentItem, setCurrentItem] = useState(false);
  const [currentRejectItem, setCurrentRejectItem] = useState(false);

  const [openCandidateRelevance, setOpenCandidateRelevance] = useState(false);
  const handleClickOpenCandidateRelevance = (item) => {
    setOpenCandidateRelevance(true);
  };

  const handleCloseCandidateRelevance = () => {
    setOpenCandidateRelevance(false);
  };

  const [openReject, setOpenReject] = React.useState(false);

  const handleHideCandidate = (isHidden) => {
    // const body = {
    //   user_id: item?.user_id,
    //   is_hidden: isHidden,
    // };
    // fetchApi({
    //   url: `${PRAVESH_API}company_saas/dashboard/candidates/report/`,
    //   method: "patch",
    //   alert: alert,
    //   body: JSON.stringify(body),
    //   callBackFn: (data) => {
    //     let arr = [...sourceResultsList?.results];
    //     arr.forEach((item2, i) => {
    //       if (item2?.user_id === item?.user_id) {
    //         if (isHidden) {
    //           arr.splice(i, 1);
    //         }
    //       }
    //     });
    //     setSourceResultsList({ ...sourceResultsList, results: arr });
    //   },
    // });
  };

  const [openApplyAndRateDialog, setOpenApplyAndRateDialog] = useState(false);
  const [openSendMailDialogSingle, setOpenSendMailDialogSingle] = useState(false);
  const handleCloseApplyAndRateDialog = () => {
    setOpenApplyAndRateDialog(false);
  };
  
  // useEffect(() => {
  //   setOpenSendMailDialogSingle(openSendMailDialog);
  // }, [openSendMailDialog])

  const theme = useTheme();

  // const [selectedStudentId, setSelectedStudentId] = useState(1);

  const handleButtonClick = (id) => {
    setSelectedStudentId(id);
    if (value === 0) setOpenSendMailDialog(true);
    else setOpenSendMailDialog(false);
    setSendMailAll(false);
  };

  const handleSubmit = (student_id) => {
    if (jobFromSourceJob && student_id) {
      // http://niyuktitest.getwork.org/job/admin/send_email_to_candidate/?job_id=1305&student_id=991
      niyuktiAxiosGetReq(
        `/job/admin/send_email_to_candidate/?job_id=${jobFromSourceJob}&student_id=${student_id}`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert("Mail Sent Successfully!!");
          } else {
            FailureAlert(res.data.error);
          }
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
  };

  return (
    <>
      <div /*  className={classes.root} */>
        {/*   {cardViewApplicantList?.map((item) => ( */}
        <Paper
          /* className={classes.paper} */ key={item?.applicant_id}
          style={{
            borderRadius: "8px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            // maxWidth: 500,
            marginBottom: 30,
          }}
        >
          <Box style={{ display: "flex" }}>
            <div
              style={{ width: "40%" }}
              // item md={4}
            >
              {item?.new_flag && (
                <Box
                  /* className={classes.tag} */ style={{
                    background: theme.palette.primary.main,
                    display: "inline-block",
                    color: "#fff",
                    position: "relative",
                    padding: "5px",
                    marginTop: "20px",
                    // borderTopLeftRadius: "4px",
                    // borderBottomLeftRadius: "4px",
                    margin: "0 30px 0 0",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  <Typography
                    variant="body2"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "20px",
                      color: "#fff",
                    }}
                  >
                    New{" "}
                  </Typography>{" "}
                </Box>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                  paddingBottom: "0px",
                }}
              >
                <div>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={selectedCandidates?.some(
                          (item2) => item2?.user_id === item?.user_id
                        )}
                        onChange={(e) => handleSingleSelect(item, e)}
                        name="checkedA"
                      />
                    }
                    label={
                      !isPublic ||
                      !sharedPublicTrackData?.action_performed?.table_columns ||
                      sharedPublicTrackData?.action_performed?.table_columns?.some(
                        (item) => item === "name"
                      ) ? (
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "Antinoin" }}
                        >
                          {item?.first_name ?? ""} {item?.last_name ?? ""}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "Antinoin" }}
                        >
                          Hidden Name
                        </Typography>
                      )
                    }
                  /> */}
                  {!isPublic ||
                  !sharedPublicTrackData?.action_performed?.table_columns ||
                  sharedPublicTrackData?.action_performed?.table_columns?.some(
                    (item) => item === "name"
                  ) ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControl1 component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            style={{ margin: 0, marginRight: "10px" }}
                            control={
                              <Checkbox
                                color="primary"
                                style={{ padding: 0 }}
                                checked={selectedStudendsList.includes(
                                  item.user_id
                                )}
                                onChange={() => handleCheckChange(item.user_id)}
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl1>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "Antinoin" }}
                      >
                        {item?.first_name ?? ""} {item?.last_name ?? ""}
                      </Typography>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControl1 component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            style={{ margin: 0, marginRight: "10px" }}
                            control={
                              <Checkbox
                                style={{ padding: 0 }}
                                // checked={location}
                                // onChange={handleChange}
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl1>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "Antinoin" }}
                      >
                        Hidden Name
                      </Typography>
                    </div>
                  )}
                </div>
              </div>

              <Grid
                container
                spacing={1}
                /* className={classes.imggrid} */ style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  boxSizing: "border-box",
                  margin: 0,
                }}
              >
                <Grid
                  item
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }} /* className={classes.mainimg} */
                  >
                    <ButtonBase
                      /* className={classes.image} */ style={{
                        width: 128,
                        height: 128,
                      }}
                    >
                      <img
                        style={{
                          margin: "auto",
                          display: "block",
                          height: "150px",
                          width: "150px",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "50%",
                        }}
                        //className={classes.img}
                        alt="complex"
                        src={
                          item?.profile_picture ? item?.profile_picture : user
                        }
                        onError={(e) => {
                          e.target.onerror = () => null;
                          e.target.src = user;
                        }}
                      />
                    </ButtonBase>
                    <div
                      /* className={classes.verify} */ style={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        padding: "5px",
                        marginTop: "-40px",
                        zIndex: 100,
                        display: "flex",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.25 8L15.42 5.9075L15.675 3.14L12.9675 2.525L11.55 0.125L9 1.22L6.45 0.125L5.0325 2.5175L2.325 3.125L2.58 5.9L0.75 8L2.58 10.0925L2.325 12.8675L5.0325 13.4825L6.45 15.875L9 14.7725L11.55 15.8675L12.9675 13.475L15.675 12.86L15.42 10.0925L17.25 8ZM7.5675 11.54L4.7175 8.6825L5.8275 7.5725L7.5675 9.32L11.955 4.9175L13.065 6.0275L7.5675 11.54Z"
                          fill="#00ADEF"
                        />
                      </svg>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    {item?.relevant_score && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <Chip
                          size="small"
                          //   color={
                          //     item?.relevant_score?.split(" ")[0] < 5
                          //       ? "#FF0000"
                          //       : item?.relevant_score?.split(" ")[0] >= 8
                          //       ? "green"
                          //       : "#EBB241"
                          //   }
                          variant="outlined"
                          style={{
                            borderColor:
                              item?.relevant_score?.split(" ")[0] < 5
                                ? "#C4161C"
                                : item?.relevant_score?.split(" ")[0] >= 8
                                ? "#0B8A00"
                                : "#FBBC09",
                            bgcolor:
                              item?.relevant_score?.split(" ")[0] < 5
                                ? "#F6DCDD"
                                : item?.relevant_score?.split(" ")[0] >= 8
                                ? "#D0F5CC"
                                : "#FFEFD0",
                            color:
                              item?.relevant_score?.split(" ")[0] < 5
                                ? "#C4161C"
                                : item?.relevant_score?.split(" ")[0] >= 8
                                ? "#0B8A00"
                                : "#FBBC09",
                          }}
                          label={`${item?.relevant_score}`}
                        />
                      </div>
                    )}
                    {item?.gender && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                          marginLeft: "20px",
                        }}
                      >
                        <Chip
                          size="small"
                          color="primary"
                          // variant="outlined"
                          // sx={{
                          //   backgroundColor: alpha(
                          //     theme.palette.primary.main,
                          //     0.1
                          //   ),
                          // }}
                          label={`${item?.gender}`}
                          // deleteIcon={<CloseIcon />}
                          // onDelete={() => {
                          //   removeCollege(clg);
                          // }}
                        />
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid md={6} sm container>
                  <Grid
                    xs
                    container
                    direction="column"
                    // spacing={2}
                    style={{ display: "contents" }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      {getBasicData(item).map(
                        (val, ind) =>
                          val.show && (
                            <Grid container wrap="nowrap" spacing={2}>
                              <Grid item>{val?.icon}</Grid>
                              <Grid item xs style={{ display: "grid" }}>
                                {/* action= */}

                                <CopyToClipboard
                                  text={val.info}
                                  onCopy={() => SuccessAlert("Copied!")}
                                >
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    noWrap
                                    style={{ cursor: "pointer" }}
                                    // className={classes.emailtext}
                                  >
                                    {val.info}{" "}
                                  </Typography>
                                </CopyToClipboard>
                              </Grid>
                              {/* </CopyToClipboard> */}
                            </Grid>
                          )
                      )}
                      {/* {item?.is_unlocked &&
                      item?.profile_url &&
                      item?.profile_url?.length ? (
                        <>
                          <Grid
                            container
                            wrap="nowrap"
                            spacing={2}
                            style={{ marginBottom: 10 }}
                          >
                          

                            <Grid item xs>
                              <div>
                                {item?.profile_url !== null &&
                                  item?.profile_url.map(
                                    (item, ind) =>
                                      item?.logo && (
                                        <Tooltip
                                          title={item.name}
                                          placement="bottom"
                                        >
                                          <img
                                            alt="alt-img"
                                            src={
                                              item?.logo
                                                ? item?.logo
                                                : "" 
                                            }
                                            height="22px"
                                            style={{
                                              marginRight: "10px",
                                              cursor: "pointer",
                                            }}
                                            //className={classes.sociallink}
                                            onClick={() =>
                                              window.open(
                                                checkSocialLink(
                                                  item.name,
                                                  item.value
                                                )
                                              )
                                            }
                                          />
                                        </Tooltip>
                                      )
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      ) : null} */}
                    </div>
                  </Grid>
                </Grid>
                <Grid md={12} style={{ padding: "0px" }}>
                  <Divider />
                </Grid>
                <Grid item md={12}>
                  <div style={{ padding: "20px" }}>
                    {item?.date_of_joining && (
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                          <CalendarTodayOutlined style={{ color: "#6C757D" }} />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">
                            <span style={{ color: "#6C757D", fontWeight: 400 }}>
                              Date of Joining GetWork:{" "}
                            </span>
                            <span style={{ color: "#6C757D" }}>
                              {item?.date_of_joining}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    {/* {item?.total_applied_jobs && ( */}
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <WorkOutlineOutlinedIcon style={{ color: "#6C757D" }} />
                      </Grid>
                      <Grid item xs style={{cursor: "pointer"}}>
                        <Typography variant="body2" color="primary" onClick={() => {
                          window.open(`student-history?student_id=${item?.user_id}`, '_blank')
                        }}>
                          <span style={{ fontWeight: 600 }}>
                            {`Previously Applied Jobs: `}
                          </span>
                          <span
                            style={{ color: "#6C757D" }}
                          >{`${item?.applicant_count}`}</span>
                        </Typography>
                      </Grid>
                    </Grid>
                    {/*   )} */}
                    {item?.assigned_company_user &&
                      item?.assigned_company_user?.length > 0 && (
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>
                            <PeopleAltOutlinedIcon
                              style={{ color: "#6C757D" }}
                            />
                          </Grid>
                          {item?.assigned_company_user &&
                            item?.assigned_company_user?.length > 0 && (
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#6C757D" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Assigned Team Member :
                                  </span>
                                  {` ${
                                    item?.assigned_company_user[0]?.first_name
                                  }
                                      ${
                                        item?.assigned_company_user[0]
                                          ?.last_name
                                      } ${
                                    item?.assigned_company_user[0]?.designation
                                      ? `, ${item?.assigned_company_user[0]?.designation}`
                                      : ""
                                  }`}
                                </Typography>
                              </Grid>
                            )}
                        </Grid>
                      )}

                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <CommentOutlinedIcon style={{ color: "#6C757D" }} />
                      </Grid>

                      <Grid item xs>
                        <Typography
                          variant="body2"
                          onClick={() => handleClickOpen(item)}
                          color="primary"
                          style={{ fontWeight: 600, cursor: "pointer" }}
                        >
                          View Comments
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div
              // item md={8}

              style={{ borderLeft: "1px solid #E2E6EA", width: "60%" }}
            >
              <Grid container>
                <Grid
                  item
                  md={12}
                  style={{
                    minHeight: "54px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                    }}
                  >
                    {getSecondaryData(item)?.map((val, ind) => (
                      <>
                        {val.show && (
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginRight: "30px",
                              color: "#000",
                            }}
                          >
                            <span style={{ color: "#71787F" }}>
                              {val.heading}
                            </span>{" "}
                            <span>{` : ${val.info}`}</span>
                          </Typography>
                        )}
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <Divider style={{ height: ".5px" }} />
                </Grid>
                {getDetailedData(item).map((val, ind) =>
                  val.show ? (
                    <Grid
                      container
                      style={{
                        borderBottom: "1px solid #E3E6EA",
                        width: "100%",
                      }}
                    >
                      <Grid item md={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "80px",
                          }}
                        >
                          <ul>
                            {val?.info?.length > 0 ? (
                              val.info.map((line) => (
                                <Typography
                                  component="li"
                                  variant="body2"
                                  key={line}
                                  style={{ margin: "5px" }}
                                >
                                  {line}{" "}
                                </Typography>
                              ))
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ marginLeft: "-10px" }}
                              >
                                NA
                              </Typography>
                            )}
                          </ul>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      style={{
                        borderBottom: "1px solid #E3E6EA",
                        width: "100%",
                      }}
                    >
                      <Grid item md={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "80px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{ marginLeft: 30 }}
                          >
                            NA
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  )
                )}
                {getDetailedSecondData(item)?.map((val, ind) =>
                  val?.show ? (
                    <Grid
                      container
                      style={{
                        borderBottom: "1px solid #E3E6EA",
                        width: "100%",
                      }}
                    >
                      <Grid item md={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "80px",
                          }}
                        >
                          <ul
                            style={{
                              paddingLeft: "25px" /* display: "flex" */,
                            }}
                          >
                            {val?.info?.length > 0 ? (
                              val.info.map((line) => (
                                <Typography
                                  //component="li"
                                  variant="body2"
                                  key={line}
                                  style={{ margin: "5px" }}
                                >
                                  {line}
                                </Typography>
                              ))
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ marginLeft: "4px" }}
                              >
                                NA
                              </Typography>
                            )}
                            {item?.user_details?.skill?.length >= 10 &&
                              val?.heading === "Skills" && (
                                <Button
                                  style={{
                                    background: "none",
                                    color: theme.palette.primary.main,
                                    padding: "0 10px",
                                  }}
                                  //className={classes.hideAndShowBtn}
                                  //className={classes.hideAndShowBtn}
                                  onClick={() => setViewAll(!viewAll)}
                                >
                                  {viewAll ? "Show Less" : "Show More"}
                                </Button>
                              )}
                          </ul>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      style={{
                        borderBottom: "1px solid #E3E6EA",
                        width: "100%",
                      }}
                    >
                      <Grid item md={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "80px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{ marginLeft: 30 }}
                          >
                            NA
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </div>
          </Box>
          {/*  {userPermission && ( */}
          <Grid container spacing={2}>
            <>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid item md={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ padding: "10px", display: "flex" }}>
                    {item?.email && (
                      <Button
                        style={{
                          height: "40px",
                          boxShadow: "none",
                          borderRadius: "8px",
                          fontSize: "15px",
                          width: "180px",
                          marginRight: 5,
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          window.open(
                            `/admin/student-details/?email=${item?.email}`
                          );
                        }}
                      >
                        View Details
                      </Button>
                    )}

                    {(!isPublic ||
                      !sharedPublicTrackData?.action_performed?.table_columns ||
                      sharedPublicTrackData?.action_performed?.table_columns?.some(
                        (item) => item === "resume"
                      )) && (
                      <Button
                        variant="contained"
                        color="primary"
                        //className={classes.btn}
                        style={
                          activeStatusObject?.status_id === 7
                            ? {
                                height: "40px",
                                boxShadow: "none",
                                borderRadius: "8px",
                                fontSize: "15px",
                                width: "180px",
                                marginRight: 5,
                                marginLeft: "25px",
                                marginRight: "25px",
                              }
                            : {
                                height: "40px",
                                boxShadow: "none",
                                borderRadius: "8px",
                                fontSize: "15px",
                                width: "180px",
                                marginRight: 5,
                              }
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          window.open(item?.resume);
                        }}
                        disabled={
                          item?.is_locked || (item?.resume ? false : true)
                        }
                      >
                        <span>View Resume</span>
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        // if (basicInfo?.is_blocked) {
                        //   FailureAlert("Please unlock the student profile!");
                        //   return;
                        // }
                        setOpenApplyAndRateDialog(true);
                      }}
                      variant="contained"
                      color="primary"
                      size="sm"
                      style={{
                        height: "40px",
                        boxShadow: "none",
                        borderRadius: "8px",
                        fontSize: "15px",
                        width: "180px",
                        marginRight: 5,
                      }}
                      disabled={
                        item?.resume && item?.education?.length > 0
                          ? //    (currentEducation && Object.keys(currentEducation)?.length > 0 || education?.length > 0)
                            //      &&
                            //     // resume
                            //     //  && basicInfo?.currentCtc
                            //    (uploadedResume || resume)
                            false
                          : true
                      }
                    >
                      {false ? (
                        <CircularProgress
                          style={{
                            color: "white",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      ) : (
                        "Apply And Rate"
                      )}
                    </Button>

                    {/* {(!isPublic ||
                      !sharedPublicTrackData?.action_performed?.table_columns ||
                      sharedPublicTrackData?.action_performed?.table_columns?.some(
                        (item) => item === "resume"
                      )) && (
                      <SendJobLink
                        selectedRowsData={[item]}
                        setSelectedRowsData={() => {}}
                        setSelectedID={() => {}}
                        disable={!item?.is_unlocked}
                      />
                    )} */}
                    <Button
                      onClick={() => {
                        // if (basicInfo?.is_blocked) {
                        //   FailureAlert("Please unlock the student profile!");
                        //   return;
                        // }
                        if (value === 1) handleSubmit(item?.user_id);
                        handleButtonClick(item?.user_id);
                        setOpenSendMailDialogSingle(true);
                        setSendMailAll(false);
                      }}
                      variant="contained"
                      color="primary"
                      style={{
                        height: "40px",
                        boxShadow: "none",
                        borderRadius: "8px",
                        fontSize: "15px",
                        width: "180px",
                        marginRight: 5,
                      }}
                      disabled={selectedStudendsList.length}
                    >
                      <span>Send Mail</span>
                    </Button>
                  </div>
                  {/* 
                  {item?.is_relevant ? (
                    <>
                      <div style={{ display: "flex", marginRight: "15px" }}>
                        <Typography variant="body2">
                          Irrelevant Profile.
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          onClick={() => {
                            handleClickOpenCandidateRelevance();
                          }}
                        >
                          Tell Us Why?
                        </Typography>{" "}
                      </div>
                    </>
                  ) : (
                    <div style={{ display: "flex", marginRight: "15px" }}>
                      <Typography variant="body2">
                        Marked As Irrelevant.
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={() => {
                          handleHideCandidate(true);
                        }}
                      >
                        Hide?
                      </Typography>{" "}
                    </div>
                  )} */}
                </div>
              </Grid>
            </>
          </Grid>
          {/*  )}{" "} */}
        </Paper>
        {/* ))} */}
        <div style={{ display: "flex" }}>
          {" "}
          {/*  <AllComment data={item} /> */}
          {/* <AddCommentDialog
            open={openAddComment}
            handleClickOpen={handleClickOpenAddComment}
            handleClose={handleCloseAddComment}
            selectedRow={{
              details: [
                {
                  id: currentApplicant?.applicantId,
                  name: currentApplicant?.applicantName,
                },
              ],
              selectedIds: [currentApplicant?.applicantId],
            }}
            setSelectedRow={null}
            activeStatusObject={activeStatusObject}
          /> */}
          <CandidateComments
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            activeStatusObject={activeStatusObject}
            currentCandidate={currentCandidate}
            isPublic={isPublic}
          />
          <RateAndApply
            open={openApplyAndRateDialog}
            handleClose={handleCloseApplyAndRateDialog}
            userId={item?.user_id}
            studentName={
              item?.first_name
                ? `${item?.first_name} ${item?.last_name ?? ""}`
                : ""
            }
            collegeId={
              item?.education?.length > 0 ? item?.education[0]?.college : 0
            }
          />
          {/* openSendMailDialog &&{" "} */}
          {openSendMailDialogSingle && 
          <SendMail
            // value={value}
            open={openSendMailDialogSingle}
            handleClose={() => { setOpenSendMailDialogSingle(false);}}
            studentIds={sendMailAll ? selectedStudendsList : selectedStudentId}
            // studentIds={studentIds}
            // jobFromSourceJob={jobFromSourceJob}
            // prevStudentIdsRef={prevStudentIdsRef}
          />
          }
          {/* <CandidateRelevanceDialog
            open={openCandidateRelevance}
            handleClickOpen={handleClickOpenCandidateRelevance}
            handleClose={handleCloseCandidateRelevance}
            userId={item?.user_id}
            setSourceResultsList={setSourceResultsList}
            sourceResultsList={sourceResultsList}
          /> */}
        </div>
      </div>
    </>
  );
}
