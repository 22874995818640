import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { cellCSS, headerCSS } from "../../constants";
import { niyuktiAxiosGetReq, niyuktiAxiosPostReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import TableWithoutExport from "../TableWithoutExport";

function VendorRaisedRequest() {
  const [data, setData] = useState([]);

  useEffect(() => {
    niyuktiAxiosGetReq(`/job/admin/raised_request/`)
      .then((res) => {
        if (res?.data?.success) {
          setData(res?.data?.data);
        } else {
          FailureAlert("Error in Getting Vendor Jobs");
        }
      })
      .catch((err) => {
        FailureAlert("Error in Getting Vendor Jobs");
      });
  }, []);

  const acceptRejectApi = (isAccepted, row) => {
    niyuktiAxiosPostReq(`/job/admin/raised_request/`, {
      vendor_history_id: row?.id,
      status: isAccepted ? "ACCEPTED" : "REJECTED",
    })
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert(
            `${isAccepted ? "Accepted" : "Rejected"} Successfully!!!`
          );
        } else {
          FailureAlert("Error in Accepting/Rejecting");
        }
      })
      .catch((err) => {
        FailureAlert("Error in Accepting/Rejecting");
      });
  };

  const columns = [
    {
      title: "Vendor ID",
      field: "vendor_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
    },

    {
      title: "Vendor name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "User Name",
      field: "vendor_user_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      filtering: false,
    },
    {
      title: "User Email",
      field: "vendor_email",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Request raised date & time",
      field: "request_date_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },

    {
      title: "Request amount",
      field: "request_amount",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      filtering: false,
    },

    {
      title: "Vendor Agreement",
      field: "vendor_agreement",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      render: (row) =>
        row.vendor_agreement ? (
          <div>
            <button
              style={{
                color: "#2563EB",
                width: "70px",
                height: "32px",
                backgroundColor: "#fff",
                border: "1px solid #2563EB",
                borderRadius: "6px",
              }}
              onClick={() => {
                window?.open(row.vendor_agreement, "_blank");
              }}
            >
              View
            </button>
          </div>
        ) : null,
      filtering: false,
    },

    {
      title: "Invoice",
      // field: "vendor_agreement",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
      },
      render: (row) =>
        row.file ? (
          <div>
            <button
              style={{
                color: "#2563EB",
                width: "70px",
                height: "32px",
                backgroundColor: "#fff",
                border: "1px solid #2563EB",
                borderRadius: "6px",
              }}
              onClick={() => {
                window?.open(row.file, "_blank");
              }}
            >
              View
            </button>
          </div>
        ) : null,
      filtering: false,
    },

    {
      title: "Accept/Reject",
      field: "status",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.status === "PENDING" ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                color: "#fff",
                width: "100px",
                height: "32px",
                backgroundColor: "#fff",
                backgroundColor: "#0DA06A",
                borderRadius: "6px",
                marginRight: "15px",
              }}
              onClick={() => {
                acceptRejectApi(true, row);
              }}
            >
              Accept
            </button>
            <button
              style={{
                color: "#EF4444",
                width: "100px",
                height: "32px",
                backgroundColor: "#fff",
                border: "1px solid #EF4444",
                borderRadius: "6px",
              }}
              onClick={() => {
                acceptRejectApi(false, row);
              }}
            >
              Reject
            </button>
          </div>
        ) : (
          <Typography
            style={{
              fontSize: "14px",
              color: `${row.status === "ACCEPTED" ? "#0DA06A" : "#EF4444"}`,
            }}
          >
            {row.status === "ACCEPTED" ? "Accepted" : "Rejected"}
          </Typography>
        ),
      filtering: false,
    },

    {
      title: "Action by",
      field: "approved_by",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <Typography>
          {row?.approved_by?.first_name} {row?.approved_by?.last_name}
        </Typography>
      ),
    },
    {
      title: "Update date & time",
      field: "update_date_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
      filtering: false,
    },
    // {
    //   title: "Approved Amount",
    //   field: "approved_amount",
    //   headerStyle: headerCSS,
    //   cellStyle: {
    //     minWidth: 200,
    //     maxWidth: 200,
    //   },
    //   filtering: false,
    // },
    {
      title: "Rejected Reason",
      field: "rejected_reason",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      render: (row) =>
        row.reject_reason ? (
          <div>
            <Typography
              style={{
                fontSize: "14px",
                color: "#EF4444",
              }}
            >
              {row?.reject_reason}
            </Typography>
          </div>
        ) : (
          ""
        ),
      filtering: false,
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <TableWithoutExport
        title="Vendor Withdrawl Request"
        columns={columns}
        selection={false}
        data={data}
      />
    </div>
  );
}

export default VendorRaisedRequest;
