import React, { useEffect, useState } from 'react';
import '../../css/PaymentDetails.css';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, createIconStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import { Select, MenuItem } from "@material-ui/core";
import Slider from '@material-ui/core/Slider';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import { printNumber } from '../../util/printNumber';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';

const token = localStorage.getItem('token');

function PaymentDetails() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/college/payment/', requestPraveshOptions)
        .then((json)=> {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        college_id:         parseInt(json.data[i].college_id),
                        college_name:       json.data[i].college_name || '',
                        nick_name:          json.data[i].nick_name || '',
                        tpo_name:           json.data[i].tpo_details ? json.data[i].tpo_details.first_name + ' '+ json.data[i].tpo_details.last_name : '',
                        tpo_email:          json.data[i].tpo_details ? json.data[i].tpo_details.email : '',
                        tpo_phone:          json.data[i].tpo_details ? json.data[i].tpo_details.phone : '',
                        tpo_designation:    json.data[i].tpo_details ? json.data[i].tpo_details.job_title : '',
                        trial_period:       json.data[i].trial_period || '',
                        trial_period_end:   json.data[i].trial_period_end || '',
                        amount:             json.data[i].amount,
                        demo_date:          json.data[i].demo_date || '',
                        demo_given:         json.data[i].demo_given,
                        invoice_send_date:  json.data[i].invoice_send_date || '',
                        is_onboarded:       json.data[i].is_onboarded ? 'Yes' : 'No',
                        is_paid:            json.data[i].is_paid ? 'Yes' : 'No',
                        is_onboarded_date:  json.data[i].is_onboarded_date || '',
                        is_paid_date:       json.data[i].is_paid_date || '',
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
        })
    }, []);

    const handleChange = (newRange, props) => {
      props.onFilterChanged(props.columnDef.tableData.id, newRange);
    };

    const amountLabel = [
      { value: 0, label: '0', }, { value: 50000, label: '50K', }, { value: 100000, label: '1L', },
    ];

    const columns = [
        { title: "Clg ID",            field: "college_id",         headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',          searchable: false, },
        { title: "College_Name",      field: "college_name",       headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never', },
        { title: "College Nick_Name", field: "nick_name",          headerStyle: headerCSS, cellStyle: cellCSS, 
          filtering: false,           editable: 'never',},
        { title: "TPO Name",          field: "tpo_name",           headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',},
        { title: "TPO Email",         field: "tpo_email",          headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',
          render: row => row.tpo_email ? 
              <div>{row.tpo_email} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.tpo_email)} />
              </div> : null
        },
        { title: "TPO Phone",         field: "tpo_phone",          headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',
          render: row => row.tpo_phone ? 
            <div>{row.tpo_phone} 
              <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.tpo_phone)} />
            </div> : null
        },
        { title: "TPO Designation",   field: "tpo_designation",    headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',},
        { title: "Trial_Start Period",field: "trial_period",       headerStyle: headerCSS, cellStyle: cellCSS, 
          type: 'date',               searchable: false,           filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.trial_period.substring(0, 10)  
        },
        { title: "Trial_End Period",  field: "trial_period_end",   headerStyle: headerCSS, cellStyle: cellCSS, 
          type: 'date',               searchable: false,           filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.trial_period_end.substring(0, 10)  
        },
        { title: "Amount",            field: "amount",             headerStyle: headerCSS, cellStyle: cellCSS, 
          searchable: false,          render: row => row.amount ? printNumber(row.amount) : null,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}}
              value={props.columnDef.tableData.filterValue || [0,100000]}
              defaultValue={[0, 100000]}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={100000}
              step={5000} marks={amountLabel}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={printNumber}
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.amount >= term[0] && rowData.amount <= term[1],
        },
        { title: "Demo_Date",         field: "demo_date",          headerStyle: headerCSS, cellStyle: cellCSS, 
          type: 'date',               searchable: false,           filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.demo_date.substring(0, 10)    
        },
        { title: "Demo_Given",        field: "demo_given",         headerStyle: headerCSS, cellStyle: cellCSS, 
          searchable: false,          type: 'numeric', },
        { title: "Invoice_Sent Date", field: "invoice_send_date",  headerStyle: headerCSS, cellStyle: cellCSS, 
          type: 'date',               searchable: false,           filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.invoice_send_date.substring(0, 10)  
        },
        { title: "Onboard Status",    field: "is_onboarded",       headerStyle: headerCSS, cellStyle: cellCSS, 
          searchable: false,          filterPlaceholder: 'Yes/No', lookup: {'Yes':'Yes', 'No':'No'},
          editComponent: props => (
            <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
            </Select>
          ) 
        },
        { title: "Onboarded_Date",    field: "is_onboarded_date",  headerStyle: headerCSS, cellStyle: cellCSS, 
          type: 'date', editable: 'never', searchable: false,      filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.is_onboarded_date.substring(0, 10)  
        },
        { title: "Paid Status",       field: "is_paid",            headerStyle: headerCSS, cellStyle: cellCSS, 
          searchable: false,          filterPlaceholder: 'Yes/No', lookup: {'Yes':'Yes', 'No':'No'},  
          editComponent: props => (
            <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
            </Select>
          ) 
        },
        { title: "Paid_Date",         field: "is_paid_date",       headerStyle: headerCSS, cellStyle: cellCSS, 
          type: 'date',   editable: 'never', searchable: false,    filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.is_paid_date.substring(0, 10)  
        },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                trial_period : newData.trial_period ? new Date(newData.trial_period).toISOString().substring(0, 10) : null,
                trial_period_end : newData.trial_period_end ? new Date(newData.trial_period_end).toISOString().substring(0, 10) : null,
                amount: newData.amount,
                demo_date: newData.demo_date ? new Date(newData.demo_date).toISOString().substring(0, 10) : null,
                demo_given: newData.demo_given,
                invoice_send_date: newData.invoice_send_date ? new Date(newData.invoice_send_date).toISOString().substring(0, 10) : null,
                is_paid: newData.is_paid==="Yes" ? true : false,
                is_onboarded: newData.is_onboarded==="Yes" ? true : false,
            })
        };
        apiCall(`/api/admin/college/edit/?college_id=${newData.college_id}`, requestOptionsPatch)
        .then((res) => {
            if(res.success) { SuccessAlert(res.data.message); }
            else { FailureAlert(res.error); }
        })
    }

    return (
        !loading
        ?
        <div className="payment__details">
            <TableWithExport
                title = {`Payment Details`}
                data = {data}
                columns = {columns}
                fName = "Selected College Payment Details"
                editableObject = {edit}
            />
        </div>
        :
        <img
            alt="Getwork Loading Gif..."
            src={gif}
            style={gifStyling}
        />
    )
}

export default PaymentDetails;