import React, { useState, useEffect } from "react";
import { apiCall } from "../../util/apiCall";
import { Form } from "react-bootstrap";
import { FailureAlert } from "../../util/Notifications";
import { PostJobSection } from "./PostJobSection";
import no_college_data from "../../assets/images/no_college_data.png";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { requestPraveshOptions } from "../../constants";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../util/copyToClipboard";

function PostJob() {
  const [companyId, setCompanyId] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [postJobCredits, setPostJobCredits] = useState(0);
  const [paidService, setPaidService] = useState({});
  const [loading, setLoading] = useState(true);
  // Fetch all Companies Dropdown
  useEffect(() => {
    setLoading(true);
    apiCall("/api/admin/company/get/", requestPraveshOptions).then((res) => {
      if (res.success) {
        setCompanies(res.data);
        setLoading(false);
      } else {
        FailureAlert(res.error);
      }
    });
  }, [companyId]);

  useEffect(() => {
    apiCall("/api/admin/paid_services/", requestPraveshOptions).then((json) => {
      if (json.success) {
        for (var i = 0; i < json.data.length; i++) {
          if (
            json.data[i].service_type === "POST_JOB" &&
            json.data[i].user_type_id === 5
          ) {
            setPostJobCredits(parseFloat(json.data[i].credits_required));
            setPaidService(json.data[i]);
            break;
          }
        }
      } else {
        setPostJobCredits(0);
        setPaidService({});
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!companyId) return;
    apiCall(
      `/api/admin/main_user/credit_info/?main_user_type=Company&main_user_id=${companyId}`,
      requestPraveshOptions
    ).then((res) => {
      if (res.success) {
        setData(res.data);
      } else {
        setData({});
        FailureAlert(res.error);
      }
    });
  }, [companyId]);

  return (
    <div className="post__job">
      <Form className="post__job__form">
        <Autocomplete
          id="select-user"
          options={companies}
          getOptionLabel={(item) => item?.company_name}
          style={{ width: "90%", margin: "2% 5%" }}
          onChange={(e, item) =>
            item ? setCompanyId(item.company_id) : setCompanyId(null)
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Company" variant="outlined" />
          )}
          loading
        />
      </Form>
      {!companyId ? (
        <h3 style={{ marginLeft: "2rem" }}>
          Select Company to Post a New Job...
        </h3>
      ) : (
        <div>
          <h5 style={{ display: "flex", justifyContent: "space-around" }}>
            <p>
              Email_ID : {data.email || ""}{" "}
              {data.email && (
                <FileCopyIcon
                  style={{ cursor: "pointer" }}
                  onClick={(e) => copyToClipboard(data.email)}
                />
              )}
            </p>
            <p>Name : {data.name || ""}</p>
            <p>Credit Status : {data.credit_status || ""}</p>
          </h5>

          <h5 style={{ display: "flex", justifyContent: "space-around" }}>
            <p> Available credits : {data.available_credits || "0"} </p>
            <p>Burned credits: {data.burned_credits || "0"}</p>
            <p>Expired credits : {data.expired_credits || "0"}</p>
          </h5>
          {data.user_type_id ? (
            // data.available_credits && parseFloat(data.available_credits) >= postJobCredits ?
            // data.credit_status === "ACTIVE" ? (
            <PostJobSection
              cmpId={companyId}
              userId={data.user_id}
              amount={parseFloat(paidService.credits_required)}
              userTypeId={data.user_type_id}
              transactionType={paidService.transaction_type}
              serviceType={paidService.service_type}
            />
          ) : (
            // ) : (
            //   <div id="no__credits__data__div">
            //     <img
            //       src={no_college_data}
            //       alt="No Data"
            //       id="no__credits__data__image"
            //     />
            //     <div align="center" id="no__credits__data__div__div">
            //       <span>Your account credit status is INACTIVE !!</span> <br />
            //       <span>
            //         Kindly change credit status for above mentioned Email
            //       </span>
            //       <span
            //         style={{ color: "blue", cursor: "pointer" }}
            //         onClick={(e) => window.open("/admin/add-getwork-credits")}
            //       >
            //         {"  "}here.
            //       </span>
            //     </div>
            //   </div>
            // )
            //   ) : (
            //    <div id="no__credits__data__div">
            //      <img src={no_college_data} alt="No Data" id="no__credits__data__image" />
            //       <div align="center" id="no__credits__data__div__div">
            //         <span>Not enough credits to post Job !!</span> <br />
            //       <span>Kindly add credits for above mentioned Email.</span>
            //      </div>
            //    </div>
            //  )
            <div align="center" id="contact__tech__team__data__div__div">
              <span>Contact Tech Team :)</span>
            </div>
          )}

          {/* {data?.user_type_id && (
                    <PostJobSection
                  cmpId={companyId}
                  userId={data.user_id}
                  amount={parseFloat(paidService.credits_required)}
                  userTypeId={data.user_type_id}
                  transactionType={paidService.transaction_type}
                  serviceType={paidService.service_type}
                />
          )} */}
        </div>
      )}
    </div>
  );
}

export default PostJob;
