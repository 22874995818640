import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import gif from "../../assets/loopingcircles-3.gif";
import {
  gifStyling, niyuktiUrl, requestPraveshOptions,
  url
} from "../../constants";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import RateAndApply from "../Student/Components/Dialog/RateAndApply";
import EducationDetails from "./EducationDetails";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      thousandsGroupStyle="lakh"
      prefix="₹"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
function ApplyJob() {
  const [callAPi, setCallApi] = useState(false);
  const [showNextForm, setShowNextForm] = useState(false);
  const [educationLoader, setEducationLoader] = useState(false);
  const [currCollegeId, setCurrCollegeId] = useState(0);

  const [allBoards, setAllBoards] = useState([]);
  const [allColleges, setAllColleges] = useState([]);
  const [educationDetailsList, setEducationDetailsList] = useState([]);
  const [instituteList, setInstituteList] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [getEducation, setGetEducation] = useState(false);

  const [educationDetails, setEducationDetails] = useState({
    education_level: null,
    passing_years: null,
    board: null,
    percentage: null,
    college: null,
    college_typed: "",
    degree: null,
    courses: null,
    collegeNotListed: false,
    is_current: false,
    still_persuing: false,
    start_date: null,
    end_date: null,
    institute: null,
    enrollment_id: null,
  });

  const [educationDetailsErrors, setEducationDetailsErrors] = useState({
    education_level: "",
    passing_years: "",
    board: "",
    percentage: "",
    college: "",
    college_typed: "",
    degree: "",
    courses: "",
    collegeNotListed: "",
    is_current: "",
    still_persuing: "",
    start_date: "",
    end_date: "",
    institute: "",
    enrollment_id: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [openAddEducation, setOpenAddEducation] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const classes = useStyles();
  
  useEffect(() => {
    apiCall("/api/education/college/", requestPraveshOptions)
      .then((res) => {
        let tmpArray = [];
        for (var i = 0; i < res.length; i++) {
          tmpArray.push(res[i]);
        }
        setAllColleges(tmpArray);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    apiNiyuktiCall("/job/all_job/list/", requestPraveshOptions)
      .then((res) => {
        setAllJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    apiCall("/api/education/board", requestPraveshOptions)
      .then((res) => {
        if (res.success) {
          setAllBoards(res.data.board_list);
        } else {
          FailureAlert("Unable to fetch Education Board from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [studentDetails, setStudentDetails] = useState({
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    resume: null,
    current_ctc: 0,
    exp_ctc: 0,
    notice_period: 0,
    temp_resume: null,
    user_id: null,
    exp_years: null,
    exp_months: null,
    blockStatus:null,
  });
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    apiCall("/api/college/institute_list/", requestPraveshOptions)
      .then((res) => {
        if (res.success) {
          setInstituteList(res.data);
        } else {
          FailureAlert("Unable to fetch Colleges from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userId, setUserId] = useState(null);

  // Fetch all Companies Dropdown
  useEffect(() => {
    if (callAPi) {
      apiCall(
        "/api/admin/user/detailed_data/?email=" + studentDetails?.email,
        requestPraveshOptions
      ).then((res) => {
        if (res.success) {
          if (res?.data?.user_exist) {
            setStudentDetails({
              ...studentDetails,
              first_name: res?.data?.user_data?.user_detail?.first_name,
              last_name: res?.data?.user_data?.user_detail?.last_name,
              phone: res?.data?.user_data?.user_detail?.phone,
              temp_resume: res?.data?.user_data?.resume_obj,
              current_ctc: res?.data?.user_data?.curr_ctc
                ? res?.data?.user_data?.curr_ctc
                : 0,
              exp_ctc: res?.data?.user_data?.exp_ctc
                ? res?.data?.user_data?.exp_ctc
                : 0,
              notice_period: res?.data?.user_data?.notice_period
                ? res?.data?.user_data?.notice_period
                : 0,
              exp_years: res?.data?.user_data?.exp_years,
              exp_months: res?.data?.user_data?.exp_months,
              resume: null,
              user_id: res?.data?.user_data?.user_detail?.id,
              blockStatus:res?.data?.user_data?.user_detail?.is_active,
              studentIDExist:res?.data?.user_exist,
            });

            setEducationDetailsList(
              res?.data?.user_data?.education
                ? res?.data?.user_data?.education
                : []
            );
            setUserId(res?.data?.user_data?.user_detail?.id);
          } else {
            setStudentDetails({
              ...studentDetails,
              first_name: "",
              last_name: "",
              phone: "",
              temp_resume: null,
              current_ctc: 0,
              exp_ctc: 0,
              notice_period: 0,
              resume: null,
              exp_years: null,
              exp_months: null,
            });
            setEducationDetailsList([]);
            setJobDetails(null);
            setUserId(null);
          }
          setShowNextForm(true);
          setCallApi(false);
        } else {
          FailureAlert(res.error);
          setCallApi(false);
        }
      });
    }
  }, [callAPi]);
  var expression =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  var regex = new RegExp(expression);

  const validateData = () => {

    if (!studentDetails?.first_name) {
      FailureAlert("Please Enter Student First Name");
      return false;
    }
    if (!studentDetails?.last_name) {
      FailureAlert("Please Enter Student Last Name");
      return false;
    }
    if (!studentDetails?.phone) {
      FailureAlert("Please Enter Student Phone Number");
      return false;
    }
    if (
      studentDetails?.phone &&
      studentDetails?.phone.toString().length !== 10
    ) {
      FailureAlert("Please Enter Valid Phone Number");
      return false;
    }
    if (
      educationDetailsList?.length === 0 ||
      !educationDetailsList?.some((item) => item.is_current)
    ) {
      FailureAlert("Please Enter at least current student education ");
      return false;
    }
    // if (!studentDetails?.current_ctc && studentDetails?.current_ctc !== 0) {
    //   FailureAlert("PLease Enter Current CTC");
    //   return false;
    // }
    // if (!studentDetails?.exp_ctc && studentDetails?.exp_ctc !== 0) {
    //   FailureAlert("PLease Enter Expected CTC");
    //   return false;
    // }
    // if (!studentDetails?.notice_period && studentDetails?.notice_period !== 0) {
    //   FailureAlert("Please Enter Notice Period");
    //   return false;
    // }

    // if (!studentDetails?.exp_years){
    //   FailureAlert("Please Enter Years of Experience");
    //   return false;
    // }
    return true;
    // if (!jobDetails) {
    //   FailureAlert("Please Enter Job Details");
    //   return;
    // }

    // submitData();
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingResume, setLoadingResume] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);

  useEffect(() => {
    if (getEducation) {
      axios
        .get(`${url}/api/education/user_education?user_id=${userId}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res?.data?.success) {
            setEducationDetailsList(res?.data?.data);
            setGetEducation(false);
          }
        })
        .catch((err) => {
          setGetEducation(false);
        });
    }
  }, [getEducation]);

  const submitData = () => {
    setLoadingSubmit(true);
    var data = {
      user_data: {
        first_name: studentDetails?.first_name,
        last_name: studentDetails?.last_name,
        phone: studentDetails?.phone,
        email: studentDetails?.email,
        current_ctc: Number(studentDetails?.current_ctc),
        exp_ctc: Number(studentDetails?.exp_ctc),
        notice_period: Number(studentDetails?.notice_period),
        exp_years: Number(studentDetails?.exp_years),
        exp_months: Number(studentDetails?.exp_months),
      },
      education_list: educationDetailsList,
      job_id: jobDetails?.job_id,
    };
    if (userId) {
      data.user_data["user_id"] = userId;
    }
    axios
      .post(`${url}/api/admin/user/detailed_data/`, data, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
          setUserId(res.data.data.user_id);
          setGetEducation(true);
          // setStudentDetails({
          //   ...studentDetails,
          //   first_name: "",
          //   last_name: "",
          //   phone: "",
          //   temp_resume: null,
          //   email: "",
          //   resume: "",
          //   current_ctc: 0,
          //   exp_ctc: 0,
          //   notice_period: 0,
          // });
          // setEducationDetailsList([]);
          // setJobDetails(null);
          // setUserId(null);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Some Error Occured");
        throw err;
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const applyToJob = () => {
    setLoadingApply(true);
    var data = {
      student_data: {
        user_id: userId,
        job_id: jobDetails?.job_id,
        college_id: currCollegeId,
      },
    };

    axios
      .post(`${niyuktiUrl}/job/admin/operations/apply_student/`, data, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert(err);
        // throw err;
      })
      .finally(() => {
        setLoadingApply(false);
      });
  };

  const postResume = () => {
    setLoadingResume(true);
    const fd = new FormData();
    fd.append("resume", studentDetails?.resume);
    fd.append("user_id", userId);
    axios
      .post(`${url}/api/admin/user/resume/`, fd, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong");
        throw err;
      })
      .finally(() => {
        setLoadingResume(false);
      });
  };

  const [openApplyAndRateDialog, setOpenApplyAndRateDialog] = useState(false);
  const handleCloseApplyAndRateDialog = () => {
    setOpenApplyAndRateDialog(false);
  };
  return (
    <div className="post__job">
      <h3 style={{ textAlign: "center" }}>
        {" "}
        <i>Apply Job!</i>{" "}
      </h3>
      <hr /> <br />
      <Form
        className="post__job__form"
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <TextField
              style={{ width: "100%" }}
              label="Enter Your Email"
              required
              size="small"
              variant="outlined"
              value={studentDetails?.email}
              onChange={(e) => {
                setStudentDetails({ ...studentDetails, email: e.target.value });
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => {
                if (studentDetails?.email?.match(regex)) setCallApi(true);
                else FailureAlert("Please Enter Email in correct Format");
              }}
              color="primary"
              size="sm"
              disabled={!studentDetails?.email}
              style={
                !studentDetails?.email
                  ? {
                      background: "grey",
                      color: "white",
                      borderRadius: 30,
                      margin: "0px 30px",
                    }
                  : {
                      background: "#428180",
                      color: "white",
                      borderRadius: 30,
                      margin: "0px 30px",
                    }
              }
            >
              {" "}
              {callAPi ? (
                <CircularProgress
                  style={{ color: "white", height: "25px", width: "25px" }}
                />
              ) : (
                "Search"
              )}
            </Button>
          </Grid>
        </Grid>
        <br />
        {!callAPi ? (
          showNextForm && (
            <>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    style={{ width: "100%" }}
                    label="First Name"
                    required
                    size="small"
                    variant="outlined"
                    value={studentDetails?.first_name}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        first_name: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Last Name"
                    required
                    size="small"
                    variant="outlined"
                    value={studentDetails?.last_name}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        last_name: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Phone Number"
                    required
                    size="small"
                    variant="outlined"
                    value={studentDetails?.phone}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        phone: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid container spacing={3}>
                <Typography variant="body2">
                  <strong> Education Details: </strong>
                </Typography>
                <br />
                <EducationDetails
                  openAddEducation={openAddEducation}
                  setOpenAddEducation={setOpenAddEducation}
                  setButtonClicked={setButtonClicked}
                  allBoards={allBoards}
                  educationDetails={educationDetails}
                  setEducationDetails={setEducationDetails}
                  allColleges={allColleges}
                  activeStep={activeStep}
                  educationDetailsErrors={educationDetailsErrors}
                  setEducationDetailsErrors={setEducationDetailsErrors}
                  buttonClicked={buttonClicked}
                  setEducationDetailsList={setEducationDetailsList}
                  educationDetailsList={educationDetailsList}
                  educationLoader={educationLoader}
                  instituteList={instituteList}
                />
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Current CTC( in INR)"
                    size="small"
                    variant="outlined"
                    value={studentDetails?.current_ctc}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        current_ctc: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { min: 0 },
                      style: { fontSize: 13 },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Expected CTC(in INR)"
                    size="small"
                    variant="outlined"
                    value={studentDetails?.exp_ctc}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        exp_ctc: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { min: 0 },
                      style: { fontSize: 13 },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Notice Period (in days)"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={studentDetails?.notice_period}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        notice_period: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    Total Experience:
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Years"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={studentDetails?.exp_years}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        exp_years: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Months"
                    required
                    size="small"
                    type="number"
                    variant="outlined"
                    value={studentDetails?.exp_months}
                    onChange={(e) => {
                      setStudentDetails({
                        ...studentDetails,
                        exp_months: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  disabled={loadingSubmit}
                  onClick={() => {
                    if (!loadingSubmit && validateData()) {
                      submitData();
                    }
                  }}
                  color="primary"
                  size="sm"
                  style={{
                    background: "#428180",
                    color: "white",
                    borderRadius: 30,
                    margin: "10px 30px",
                  }}
                >
                  {loadingSubmit ? (
                    <CircularProgress
                      style={{ color: "white", height: "25px", width: "25px" }}
                    />
                  ) : (
                    "Save Applicant Details"
                  )}
                </Button>
              </Grid>{" "}
              <br />
              <Divider />
              <br />
              <div style={{ marginLeft: 20 }}>
                <Grid container spacing={3}>
                  {studentDetails?.temp_resume?.resume &&
                    !studentDetails?.resume && (
                      <>
                        <Typography variant="body2">
                          <strong> Uploaded Resume: </strong>
                          <br />
                          {studentDetails?.temp_resume?.resume_name} <br />
                          <a
                            href={studentDetails?.temp_resume?.resume}
                            target="blank"
                          >
                            Click here
                          </a>{" "}
                          to see Resume!
                        </Typography>
                      </>
                    )}
                </Grid>
                <br /> <br />
                <Grid container spacing={3}>
                  <Form.File id="companyLogo">
                    <Form.File.Label>
                      {" "}
                      {studentDetails?.temp_resume
                        ? "Edit Resume *"
                        : "Resume *"}
                    </Form.File.Label>
                    <Form.File.Input
                      onChange={(e) =>
                        setStudentDetails({
                          ...studentDetails,
                          resume: e.target.files[0],
                        })
                      }
                      accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    <p>Please Upload your resume</p>
                  </Form.File>
                </Grid>
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    onClick={() => {
                      if (!loadingResume && validateData() && userId) {
                        if (
                          !studentDetails?.resume &&
                          !studentDetails?.temp_resume?.resume
                        ) {
                          FailureAlert("Please Enter Student Resume");
                          return false;
                        }
                        postResume();
                      }
                    }}
                    color="primary"
                    size="sm"
                    style={{
                      background: "#428180",
                      color: "white",
                      borderRadius: 30,
                      margin: "10px 30px",
                    }}
                  >
                    {loadingResume ? (
                      <CircularProgress
                        style={{
                          color: "white",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    ) : (
                      "Update Resume"
                    )}
                  </Button>
                </Grid>
              </div>
              <br />
              <Divider />
              <br />
              {/* <Grid container>
                <Autocomplete
                  value={jobDetails}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setJobDetails(newValue);
                    }
                  }}
                  id="role345"
                  options={allJobs}
                  getOptionLabel={(option) =>
                    `${option.job_title}-${option.hiring_type} (${option.job_id})`
                  }
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option.job_title} -${option.hiring_type} (${option.job_id})`}</span>
                    );
                  }}
                  className={classes.fieldStyling1}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={
                          <span style={{ fontSize: 13 }}>
                            Apply to Job{" "}
                            <span className={classes.RedStar}>*</span>
                          </span>
                        }
                        variant="outlined"
                        error={
                          educationDetailsErrors.education_level !== ""
                            ? true
                            : false
                        }
                        helperText={
                          <span
                            style={
                              educationDetailsErrors.education_level !== ""
                                ? { color: "#D64045", fontSize: 13 }
                                : { color: "grey", fontSize: 13 }
                            }
                          >
                            {educationDetailsErrors.education_level}
                          </span>
                        }
                      />
                    );
                  }}
                />
              </Grid> */}
              <br />
              <div className={classes.paper1}>
                <Grid container style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        if(studentDetails?.studentIDExist && !studentDetails?.blockStatus){

                          FailureAlert("Please unlock the student profile");
                          return;
                        }
                        if (!loadingApply /*  && validateData() */ && userId) {
                          // if (!jobDetails) {
                          //    FailureAlert("Please Enter Job Details");
                          //   return;
                          //  }
                          /*  applyToJob(); */
                          setOpenApplyAndRateDialog(true);
                        }
                      }}
                      color="primary"
                      size="sm"
                      style={{
                        background: "#428180",
                        color: "white",
                        borderRadius: 30,
                        margin: "10px 30px",
                      }}
                    >
                      {loadingApply ? (
                        <CircularProgress
                          style={{
                            color: "white",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      ) : (
                        "Apply And Rate"
                      )}
                    </Button>
                  </div>
                  <Typography
                    style={{
                      margin: "10px auto",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    <i>
                      Select Job Profile & Rate Student On That Job then only
                      the application will appear on job track.
                    </i>
                  </Typography>
                  <RateAndApply
                    open={openApplyAndRateDialog}
                    handleClose={handleCloseApplyAndRateDialog}
                    userId={userId}
                    studentName={
                      studentDetails?.first_name
                        ? `${studentDetails?.first_name} ${
                            studentDetails?.last_name
                              ? studentDetails?.last_name
                              : ""
                          }`
                        : ""
                    }
                    collegeId={
                      educationDetails?.college ? educationDetails?.college : 0
                    }
                  />
                </Grid>{" "}
              </div>
              <br />
            </>
          )
        ) : (
          <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
        )}
      </Form>
    </div>
  );
}

export default ApplyJob;

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    labelField: {
      fontSize: 13,
    },
    RedStar: {
      color: "red",
    },
    paper1: {
      padding: "20px 10px",
      margin: "auto",
      backgroundColor: "#fff",
      boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
      borderRadius: "15px",
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      // marginBottom: 20,
    },
    fieldStyling1: {
      width: "100%",
      marginBottom: 10,
    },
    "@media screen and (min-width: 960.5px)": {
      pcEnrollId: {
        display: "block",
        color: "blue",
      },
      mobileEnrollId: {
        display: "none",
      },
    },
    "@media screen and (max-width: 960.5px)": {
      pcEnrollId: {
        display: "none",
      },
      mobileEnrollId: {
        display: "block",
      },
    },
  })
);
