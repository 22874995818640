import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function PageType() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [pageName, setPageName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/page/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        page_id:   json.data[i].page_id,
                        page_name: json.data[i].page_name,
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
      },
      body: JSON.stringify({ 
          name: pageName,
      })
    };

    const columns = [
        { title: "Page ID",   field: "page_id",   headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Page Name", field: "page_name", headerStyle: headerCSS, cellStyle: cellCSS },
    ]

    const addNewPage = (e) => {
        e.preventDefault();
        apiCall('/api/admin/page/', requestOptionsPost)
        .then((res) => {
            if(res.success) { SuccessAlert(res.data.message); setPageName(''); setCount(count+1); }
            else { FailureAlert(res.verbose.name[0] || res.error); }
        })
        e.target.reset();
    }

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ name: newData.page_name, })
        };
        apiCall(`/api/admin/page/${newData.page_id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) SuccessAlert(res.data.message);
            else FailureAlert(res.verbose.name[0] || res.error);
            setCount(count+1);
        })
    }

    return (
        !loading
        ?
        <div className="page__type">
            <div className="page__type__form">
                <h4>Add New Page Type</h4>
                <Form onSubmit={e=>addNewPage(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="pageTypeName">
                            <Form.Control 
                                name="pageTypeName" type="text" required 
                                placeholder="Enter Page Type Name (without spaces)*"
                                pattern="^\S+$"
                                title="Page Type Name must not contain any spaces."
                                onChange={e=> {setPageName(e.target.value)}}
                            />
                        </Form.Group>
                        <Button type="submit" variant="info">Add Page</Button>
                    </Form.Row>
                </Form>    
            </div>
            <TableWithExport 
                title = "Page Types"
                data = {data}
                columns = {columns}
                fName = "Selected Pages"
                editableObject = {edit}
            />
        </div>
        :
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
    )
}

export default PageType;