import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function Industry() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [industryName, setIndustryName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/industry/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:            parseInt(json.data[i].id),
                        industry_name: json.data[i].industry_name,
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
      },
      body: JSON.stringify({ 
          name: industryName,
      })
    };

    const columns = [
        { title: "Domain ID",      field: "id",             headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',       searchable: false, },
        { title: "Industry Name",  field: "industry_name",  headerStyle: headerCSS, cellStyle: cellCSS },
    ]

    const addNewIndustry = (e) => {
        e.preventDefault();
        apiCall('/api/admin/industry/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.error);
            }
        })
        e.target.reset();
    }

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                name: newData.industry_name,
            })
        };
        apiCall(`/api/admin/industry/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
            }
            else {
                FailureAlert(res.error);
            }
            setCount(count+1);
        })
    }

    return (
        !loading
        ?
        <div className="industry">
            <div className="industry__form">
                <h4>Add New Industry</h4>
                <Form onSubmit={e=>addNewIndustry(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="industryName">
                            <Form.Control 
                                name="industryName" 
                                type="text" 
                                placeholder="Enter Industry Name*"
                                onChange={e=> {setIndustryName(e.target.value)}}
                                required 
                            />
                        </Form.Group>
                        <Button type="submit" variant="info">Add Industry</Button>
                    </Form.Row>
                </Form>    
            </div>
            <TableWithExport 
                title = "Company Industry"
                data = {data}
                columns = {columns}
                fName = "Selected Industry"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default Industry;