import {
    Box, CardContent,
    CircularProgress, Typography
} from "@material-ui/core";
import React from 'react';
// import paymentCard from '\assets\svg\Plan\payment.svg'
import paymentCard from '../../../assets/svg/Plan/payment.svg';

const PaymentCard = ({ planDetails, loading, data }) => {


    return (
        <>
            <Box
                style={{
                    boxShadow: "0px 0px 5px 2px #0000001A",
                    borderRadius: "12px", marginBottom: '.5rem'
                }}
            >
                <Box
                    style={{
                        backgroundColor: "#CCE5FF",
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        display: "flex",
                        alignItems: "center",
                        padding: "1rem",
                    }}
                >
                    <img src={paymentCard} />
                    <Box style={{ pl: 2, pr: 2 }}>
                        <Typography variant="h7" style={{ fontWeight: "400" }}>
                            You save{" "}
                            <span
                                style={{
                                    color: "#007BFF",
                                    fontWeight: "600"
                                }}
                            >
                                ₹54,999
                            </span>{" "}
                            by choosing Getwork for your hiring services
                        </Typography>
                    </Box>
                </Box>
                <CardContent>
                    <Typography variant="h5" style={{ fontWeight: "400" }}>
                        Price Summary
                    </Typography>
                    {/* <Grid container spacing={2} > 
            <Grid md={6} >

                </Grid>
            </Grid> */}

                    {!loading ? (
                        <>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: 8,
                                    marginTop: 8,
                                }}
                            >
                                <Typography variant="h7" style={{ fontWeight: "400" }}>
                                    Request cost
                                </Typography>

                                <Typography variant="h7" style={{ fontWeight: "400" }}>
                                    {`₹${data?.request_cost ? data?.request_cost.toFixed(2) : 0}`}
                                </Typography>
                            </Box>
                            <Box
                                style={{ display: "flex", justifyContent: "space-between", mb: 2 }}
                            >
                                <Typography variant="h7" style={{ fontWeight: "400" }}>
                                    Taxes
                                </Typography>

                                <Typography variant="h7" style={{ fontWeight: "400" }}>
                                    {`₹${data?.taxes ? data?.taxes.toFixed(2) : 0}`}
                                </Typography>
                            </Box>
                            <Box
                                style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}
                            >
                                <Typography variant="h7" style={{ fontWeight: "400" }}>
                                    Discount
                                </Typography>

                                <Typography
                                    variant="h7"
                                    style={{ fontWeight: "400", color: "#FF0000" }}
                                >
                                    {`- ₹${data?.discount ? data?.discount?.toFixed(2) : 0}`}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: 8,
                                    borderTop: "2px dashed #B0B6BA",
                                    paddingTop: 8,
                                }}
                            >
                                <Typography variant="h7" style={{ fontWeight: "400" }}>
                                    Grand Total
                                </Typography>

                                <Typography variant="h7" style={{ fontWeight: "400" }}>
                                    {`₹${data?.grand_total ? data?.grand_total.toFixed(2) : 0}`}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    marginTop: 8,
                                    backgroundColor: "#D9F0BF",
                                    padding: "1rem",
                                    borderRadius: "6px",
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                        color: "#5BA00A",
                                    }}
                                >
                                    You save extra ₹5000.00
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <>
                            <div
                                style={{
                                    width: "100%",
                                    height: "20vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <CircularProgress />
                                </div>
                            </div>
                        </>
                    )}
                </CardContent>
            </Box>

        </>
    )
}

export default PaymentCard;