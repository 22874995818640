import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import QueryString, { parse } from "query-string";
import React, { useEffect, useState, useRef, useCallback } from "react";
import FileViewer from "react-file-viewer";
import LoadingOverlay from "react-loading-overlay";
import gif from "../../assets/loopingcircles-3.gif";
import uploadResume from "../../assets/svg/Upload.svg";
import { gifStyling, requestPraveshOptions } from "../../constants";
import {
  apiCall,
  niyuktiAxiosCall,
  niyuktiAxiosGetReq,
  praveshAxiosCall,
} from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import Achievements from "./Components/Achievements/Achievements";
import Basic from "./Components/Basic";
import Certifications from "./Components/Certifications/Certifications";
import RateAndApply from "./Components/Dialog/RateAndApply";
// import ApplyStudentDialog from "./Components/Dialog/ApplyStudentDialog";
// import BloackStudentDialog from "./Components/Dialog/BlockStudentDialog";
import Education from "./Components/Educantion/Education";
import Hobbies from "./Components/Hobbies";
import ImportantLink from "./Components/ImportantLink";
import IndustryType from "./Components/IndustryType";
import JobRoles from "./Components/JobRoles";
import JobType from "./Components/JobType";
import Languages from "./Components/Languages/Languages";
import PreferedJobLocations from "./Components/PreferedJobLocations";
import Project from "./Components/Project/Project";
import Publications from "./Components/Publications/Publications";
import Resume from "./Components/Resume";
import Skills from "./Components/Skills";
import StudentSearch from "./Components/StudentSearch";
import WorkExperience from "./Components/WorkExperience/WorkExperience";
import DownloadDialog from "../DialogSection/Download/DownloadDialog";
// import { join } from "path/win32";

function StudentsDetails() {
  const [jobId, setJobId] = useState(null);
  const [compantId, setCompanyId] = useState(null);
  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentUrl).search);
    const jobId = searchParams.get("jobId");
    setJobId(jobId);
    const compantId = searchParams.get("companyId");
    setCompanyId(compantId);
  }, []);
  const [email, setEmail] = useState();
  const [userId, setUserId] = useState();
  const [showSearch, setShowSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateCall, setIsUpdateCall] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const classes = useStyles();

  const [allApiSuccess, setAllApiSuccess] = useState({
    status: 0,
    message: "",
  });

  //Static List Data State
  const [boardsList, setBoardsList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [jobRolesList, setJobRolesList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);

  //Static List Data Flag States
  const [boardsListFlag, setBoardsListFlag] = useState(false);
  const [collegeListFlag, setCollegeListFlag] = useState(false);
  const [openJobsFlag, setOpenJobsFlag] = useState(false);
  const [cityListFlag, setCityListFlag] = useState(false);
  const [jobRolesListFlag, setJobRolesListFlag] = useState(false);
  const [skillsListFlag, setSkillsListFlag] = useState(false);
  const [industriesListFlag, setIndustriesListFlag] = useState(false);
  const [companiesListFlag, setCompaniesListFlag] = useState(false);

  const [userNotExist, setUserNotExist] = useState(false);

  //Student Details State
  const initialStudentDetails = [
    {
      userId: null,
      createDate: null,
      studentName: null,
      phoneNumber: null,
      emailId: null,
      dob: null,
      gender: null,
      currentLocation: null,
      homeTown: null,
      expYear: null,
      expMonth: null,
      currentCtc: null,
      expectedCtc: null,
      about: null,
      is_blocked: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null,
    },
  ];

  const [basicInfo, setBasicInfo] = useState(...initialStudentDetails);

  const [projects, setProjects] = useState([]);
  const [publications, setPublications] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [workExperiences, setWorkExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [currentEducation, setCurrentEducation] = useState({});
  const [achievements, setAchievements] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [resume, setResume] = useState();
  const [skills, setSkills] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [uploadedResume, setUploadedResume] = useState();
  const [resumePath, setResumePath] = useState();
  const [resumeFileLoader, setResumeFileLoader] = useState(false);

  //preferences
  // const [preferredLocations, setPreferredLocations] = useState([]);
  // const [jobType, setJobType] = useState();

  const [JobRolesPreference, setJobRolesPreference] = useState([]);
  const [ppoPreference, setPpoPreference] = useState(false);
  const [wfhPreference, setWfhPreference] = useState(false);
  const [industryPreference, setIndustryPreference] = useState([]);
  const [locationPreference, setLocationPreference] = useState([]);
  const [jobTypePreference, setJobTypePreference] = useState([]);
  const [preferenceId, setPreferenceId] = useState();
  const [tempBasicInfo, setTempBasicInfo] = useState();
  const [gender, setGender] = useState("");

  useEffect(() => {
    const queryParams = QueryString.parse(window.location.search);
    if (queryParams?.email) {
      setEmail(queryParams?.email);
      setShowSearch(false);
    }
    initialData();
  }, []);

  const initialData = () => {
    getBoardsList();
    getCollegesList();
    getCityList();
    getSkillsList();
    getIndustriesList();
    getJobRolesList();
    getCompaniesList();
  };

  useEffect(() => {
    if (refreshData) {
      setSkillsListFlag(false);
      setBoardsListFlag(false);
      setCollegeListFlag(false);
      setCityListFlag(false);
      setIndustriesListFlag(false);
      setOpenJobsFlag(false);
      setJobRolesListFlag(false);
      setCompaniesListFlag(false);
      initialData();
      setRefreshData(false);
    }
  }, [refreshData]);

  // Pravesh Api Calls

  const getBoardsList = () => {
    praveshAxiosCall
      .get(`/api/education/board`)
      .then((res) => {
        if (res.data.success) {
          setBoardsList(res.data.data.board_list);
        } else {
          FailureAlert(res.data.error);
          setBoardsListFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        FailureAlert(err.message);
        setBoardsListFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `Board List Error : ${err.message}`,
        });
      });
  };

  const getCollegesList = () => {
    praveshAxiosCall
      .get(`/api/admin/college/get/`)
      .then((res) => {
        if (res.data.success) {
          setCollegeList(res.data.data);
        } else {
          FailureAlert(res.data.error);
          setCollegeListFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setCollegeListFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `College List Error : ${err.message}`,
        });
      });
  };

  const getCityList = () => {
    praveshAxiosCall
      .get(`/api/location/city_state/`)
      .then((res) => {
        if (res.data.success) {
          setCityList(res.data.data);
        } else {
          FailureAlert(res.data.error);
          setCityListFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setCityListFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `City List Error : ${err.message}`,
        });
      });
  };

  const getCompaniesList = () => {
    praveshAxiosCall
      .get(`/api/company/company_list/`)
      .then((res) => {
        if (res.data.success) {
          setCompaniesList(res.data.data.all_companies);
        } else {
          FailureAlert(res.data.error);
          setCompaniesListFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setCompaniesListFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `Company List Error : ${err.message}`,
        });
      });
  };

  const getSkillsList = () => {
    // praveshAxiosCall
    //   .get(`/api/education/skills`)
    //   .then((res) => {
    //     if (res.data.success) {
    //       setSkillsList(res.data.data.skills);
    //     } else {
    //       FailureAlert(res.data.error);
    //       setSkillsListFlag(true);
    //       setAllApiSuccess({ status: -1, message: res.error });
    //     }
    //   })
    //   .catch((err) => {
    //     setSkillsListFlag(true);
    //     setAllApiSuccess({
    //       status: -1,
    //       message: `Skills List Error : ${err.message}`,
    //     });
    //   });
  };

  const isdebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleSkillChange = (value) => {
    if (value)
      apiCall(
        `/api/education/skills?&skill_name=${value}&result_count=50`,
        requestPraveshOptions
      ).then((json) => {
        if (json.success) setSkillsList(json.data.skills);
        else FailureAlert(json.error);
      });
  };

  const debounceSkillList = useCallback(isdebounce(handleSkillChange), []);

  const getIndustriesList = () => {
    praveshAxiosCall
      .get(`/api/admin/industry/`)
      .then((res) => {
        if (res.data.success) {
          setIndustriesList(res.data.data);
        } else {
          setIndustriesListFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setIndustriesListFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `Industries List Error : ${err.message}`,
        });
      });
  };

  const getJobRolesList = () => {
    niyuktiAxiosCall
      .get(`/job/role/`)
      .then((res) => {
        if (res.data.success) {
          setJobRolesList(res.data.data);
        } else {
          setJobRolesListFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setJobRolesListFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `Job Roles List Error : ${err.message}`,
        });
      });
  };

  const initialDataErrorCheck = () => {
    return (
      jobRolesListFlag ||
      openJobsFlag ||
      industriesListFlag ||
      skillsListFlag ||
      cityListFlag ||
      collegeListFlag ||
      boardsListFlag ||
      companiesListFlag
    );
  };

  const handleStudentTempBasicInfo = () => {
    setGender(" ");
    setTempBasicInfo({
      createDate: null,
      emailId: email ?? "",
      expYear: "",
      expMonth: "",
      currentCtc: "",
      isConfidential: false,
      expectedCtc: "",
      isNegotiable: false,
      noticePeriod: "",
      about: "",
    });
  };
  //Student Detail Apis

  const getStudentDetails = () => {
    setIsLoading(true);
    setResumePath(null);
    praveshAxiosCall
      .get(`/api/admin/student_details_edit/?email_id=${email}`)
      .then((res) => {
        if (res?.data?.success) {
          setIsLoading(false);
          if (!res?.data.data?.user_exist) {
            setUserNotExist(true);
            setUserId();
            reloadInitialData();
            setBasicInfo({
              emailId: email,
            });
            handleStudentTempBasicInfo();
            return;
          }
          setUserNotExist(false);
          const studentData = res?.data?.data;
          const studentUserDetails = studentData?.student_user_details;
          setUserId(studentData.id);
          // if (studentUserDetails?.resume)
          setResume(studentUserDetails?.resume);
          setBasicInfo({
            userId: studentData?.id,
            createDate: studentData?.date_joined,
            studentName: studentData?.last_name
              ? `${studentData?.first_name} ${studentData?.last_name}`
              : studentData?.first_name,
            phoneNumber: studentData?.phone,
            // phoneVerified: studentData?.phone,
            emailId: studentData?.email,
            emailVerified: studentData?.email_verified,
            dob: studentData?.dob,
            gender: studentUserDetails?.gender,
            currentCityId: studentData?.current_city_id,
            currentCityName: studentData?.current_city_name,
            hometownCityId: studentData?.hometown_city_id,
            hometownCityName: studentData?.hometown_city_name,
            platform: studentData?.platform,
            profilePicture: studentData?.profile_picture,
            expYear: studentUserDetails?.exp_year,
            expMonth: studentUserDetails?.exp_month,
            currentCtc: studentUserDetails?.current_ctc,
            expectedCtc: studentUserDetails?.expected_ctc,
            noticePeriod: studentUserDetails?.notice_period,
            about: studentUserDetails?.about,
            isNegotiable: studentUserDetails?.is_negotiable,
            isConfidential: studentUserDetails?.is_confidential,
            is_blocked: studentUserDetails?.is_blocked,
            utm_source: studentData?.utm_source,
            utm_medium: studentData?.utm_medium,
            utm_campaign: studentData?.utm_campaign,
            utm_term: studentData?.utm_term,
            utm_content: studentData?.utm_content,
          });
          setSocialLinks(studentData?.profile_url_app);
          setCurrentEducation(
            studentUserDetails?.education ? studentUserDetails?.education : {}
          );
        } else {
          setIsLoading(false);
          FailureAlert(res.data.error);
          handleStudentTempBasicInfo();
        }
      })
      .catch((err) => {
        setAllApiSuccess({
          status: -1,
          message: `User Detail Api Error : ${err.message}`,
        });
        handleStudentTempBasicInfo();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (email) {
      getStudentDetails();
      setParseResume("");
    }
  }, [email]);

  useEffect(() => {
    if (userId) {
      getStudentExtraData();
      getStudentSkills();
      getStudentWorkEx();
      getStudentEducation();
      getProjects();
      getCeritifications();
      getUserPreferences();
    }
  }, [userId]);

  const reloadInitialData = () => {
    setProjects([]);
    setPublications([]);
    setCertifications([]);
    setLanguages([]);
    setWorkExperience([]);
    setCurrentEducation({});
    setEducation([]);
    setAchievements([]);
    setHobbies([]);
    setResume();
    setSkills([]);
    setSocialLinks([]);
    setJobRolesPreference([]);
    setPpoPreference(false);
    setWfhPreference(false);
    setIndustryPreference([]);
    setLocationPreference([]);
    setJobTypePreference([]);
    setPreferenceId();
  };

  const getStudentExtraData = () => {
    praveshAxiosCall
      .get(`/api/education/student_user_extras/?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          setAchievements(res?.data?.data?.achievements);
          setHobbies(res?.data?.data?.hobbies);
          setLanguages(res?.data?.data?.languages);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Error", err.message);
      });
  };

  const getStudentSkills = () => {
    praveshAxiosCall
      .get(`/api/education/user_skill?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          setSkills(res.data.data?.user_skill);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Error", err.message);
      });
  };

  const getStudentWorkEx = () => {
    praveshAxiosCall
      .get(`/api/company/work_experience/?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          setWorkExperience(res.data.data);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Error", err.message);
      });
  };

  const getStudentEducation = () => {
    praveshAxiosCall
      .get(`/api/education/user_education?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          setEducation(res.data.data);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Error", err.message);
      });
  };

  const getCeritifications = () => {
    praveshAxiosCall
      .get(`/api/education/user_certifications/?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          setCertifications(res.data.data);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Error", err.message);
      });
  };

  const getProjects = () => {
    praveshAxiosCall
      .get(`/api/education/user_project?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          var tempProjects = [];
          var tempPublications = [];

          res.data.data.forEach((pub, _) => {
            if (pub.type === "Project") tempProjects.push(pub);
            else if (pub.type === "Publications" || pub.type === "Publication")
              tempPublications.push(pub);
          });
          setProjects(tempProjects);
          setPublications(tempPublications);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert(err.message);
      });
  };

  const getUserPreferences = () => {
    praveshAxiosCall
      .get(`/api/education/user_preferences?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          const preferenceData = res.data.data[0];
          setPreferenceId(preferenceData?.id);
          setIndustryPreference(preferenceData?.preference?.industry_type);
          setLocationPreference(preferenceData?.preference?.job_location);
          setJobRolesPreference(preferenceData?.preference?.job_roles);
          setJobTypePreference(preferenceData?.preference?.job_type);
          setPpoPreference(preferenceData?.preference?.ppo);
          setWfhPreference(preferenceData?.preference?.wfh);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert(err.message);
      });
  };

  const getResumeUrl = async () => {
    let fileURL;
    setResumeFileLoader(true);

    axios.get(`${resume}`, { responseType: "arraybuffer" }).then((res) => {
      if (resume.includes("doc")) {
        const blob = new Blob([res.data], { type: "application/msword" });
        fileURL = URL.createObjectURL(blob);
        setResumePath(fileURL);
      } else {
        const blob = new Blob([res.data], { type: "application/pdf" });
        fileURL = URL.createObjectURL(blob);
        setResumePath(fileURL);
      }
      setResumeFileLoader(false);
    });
  };

  useEffect(() => {
    if (resume) {
      getResumeUrl();
    }
  }, [resume]);

  const getFileType = () => {
    if (resume?.includes("docx")) return "docx";
    if (resume?.includes("doc")) return "docx";
    if (resume?.includes("jpg")) return "jpg";
    if (resume?.includes("jpeg")) return "jpeg";
    if (resume?.includes("png")) return "png";
  };

  //All Update Apis
  const postResume = (newResume) => {
    setIsUpdateCall(true);
    const fd = new FormData();
    fd.append("resume", newResume);
    fd.append("user_id", userId);
    praveshAxiosCall
      .post(`/api/admin/user/resume/`, fd)
      .then((res) => {
        if (res.data.success) {
          // setResume(newResume);
          SuccessAlert(res?.data?.data?.message);
          setResume(res?.data?.data?.resume_link);
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err.message);
        setIsUpdateCall(false);
      });
  };

  const postHobbies = (hobbies) => {
    setIsUpdateCall(true);
    const body = {
      hobbies: hobbies,
      user: userId,
    };
    praveshAxiosCall
      .post(`/api/education/student_user_extras/?user_id=${userId}`, body)
      .then((res) => {
        if (res.data.success) {
          // setResume(newResume);
          setHobbies(hobbies);
          SuccessAlert(res.data.data.message);
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err.message);
        setIsUpdateCall(false);
      });
  };

  const postAchievements = (achievements) => {
    setIsUpdateCall(true);
    const body = {
      achievements: achievements,
      user: userId,
    };
    praveshAxiosCall
      .post(`/api/education/student_user_extras/?user_id=${userId}`, body)
      .then((res) => {
        if (res.data.success) {
          // setResume(newResume);
          setAchievements(achievements);
          SuccessAlert(res.data.data.message);
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err.message);
        setIsUpdateCall(false);
      });
  };

  const postSkills = (skills) => {
    setIsUpdateCall(true);
    const body = {
      skill: skills,
      user: userId,
    };
    praveshAxiosCall
      .post(`/api/education/user_skill?user_id=${userId}`, body)
      .then((res) => {
        if (res.data.success) {
          // setResume(newResume);
          setSkills(skills);
          SuccessAlert(res.data.data.message);
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err.message);
        setIsUpdateCall(false);
      });
  };

  const postLanguages = (languages) => {
    setIsUpdateCall(true);
    const body = {
      languages: languages,
      user: userId,
    };
    praveshAxiosCall
      .post(`/api/education/student_user_extras/?user_id=${userId}`, body)
      .then((res) => {
        if (res.data.success) {
          // setResume(newResume);
          setLanguages(languages);
          SuccessAlert(res.data.data.message);
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err.message);
        setIsUpdateCall(false);
      });
  };

  //Projects & Publication Api Calls

  const addProject = (project, handleClose, type) => {
    const body = {
      ...project,
      type: type,
    };
    setIsUpdateCall(true);
    praveshAxiosCall
      .post(`/api/education/user_project?user_id=${userId}`, body)
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message);
          const newProject = {
            ...body,
            id: res?.data?.data?.project_id,
          };
          type === "Project"
            ? setProjects([...projects, newProject])
            : setPublications([...publications, newProject]);
          handleClose();
        } else {
          FailureAlert(res?.data?.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert(err?.message);
        setIsUpdateCall(false);
      });
  };

  const patchProject = (project, projectId, index, handleClose, type) => {
    setIsUpdateCall(true);
    const body = project;
    praveshAxiosCall
      .patch(
        `/api/education/user_project/${projectId}/?user_id=${userId}`,
        body
      )
      .then((res) => {
        if (res?.data?.success) {
          setIsUpdateCall(true);
          SuccessAlert(res?.data?.data?.message);

          let items = type === "Project" ? [...projects] : [...publications];
          items[index] = project;
          type === "Project" ? setProjects(items) : setPublications(items);
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert(err.message);
      });
  };

  const deleteProject = (projectId, index, type) => {
    setIsUpdateCall(true);
    praveshAxiosCall
      .delete(`/api/education/user_project/${projectId}/?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
          type === "Project"
            ? setProjects((projects) =>
                projects.filter((_, projectIndex) => projectIndex !== index)
              )
            : setPublications((publications) =>
                publications.filter((_, projectIndex) => projectIndex !== index)
              );
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert(err.message);
      });
  };

  //Certifications Api Calls

  const addCertification = (cerification, handleClose) => {
    setIsUpdateCall(true);
    const body = {
      ...cerification,
      start_date: cerification?.start_date
        ? moment(cerification?.start_date).format("MM/yyyy")
        : null,
      end_date: cerification?.end_date
        ? moment(cerification?.end_date).format("MM/yyyy")
        : null,
      valid_date: cerification?.valid_date
        ? moment(cerification?.valid_date).format("MM/yyyy")
        : null,
      user_type: 1,
      expiration: cerification?.expiration ? cerification?.expiration : false,
    };
    praveshAxiosCall
      .post(`/api/education/user_certifications/?user_id=${userId}`, body)
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
          setCertifications([...certifications, res.data.data.data]);
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert(err.message);
        setIsUpdateCall(false);
      });
  };

  const patchCertification = (cerification, certiId, index, handleClose) => {
    setIsUpdateCall(true);
    const body = {
      ...cerification,
      start_date: cerification?.start_date
        ? moment(cerification?.start_date).format("MM/yyyy")
        : null,
      end_date: cerification?.end_date
        ? moment(cerification?.end_date).format("MM/yyyy")
        : null,
      valid_date: cerification?.valid_date
        ? moment(cerification?.valid_date).format("MM/yyyy")
        : null,
    };
    praveshAxiosCall
      .patch(
        `/api/education/user_certifications/${certiId}/?user_id=${userId}`,
        body
      )
      .then((res) => {
        if (res.data.success) {
          setIsUpdateCall(true);
          SuccessAlert(res.data.data.message);
          let items = [...certifications];
          items[index] = res.data.data.data;
          setCertifications(items);
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert(err.message);
      });
  };

  const deleteCertification = (certiId, index) => {
    setIsUpdateCall(true);
    praveshAxiosCall
      .delete(
        `/api/education/user_certifications/${certiId}/?user_id=${userId}`
      )
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
          setCertifications((certi) =>
            certi.filter((_, certiIndex) => certiIndex !== index)
          );
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert(err.message);
      });
  };

  //WorkExperience Api Calls
  const addWorkEx = (workEx, handleClose) => {
    const body = {
      company_id: workEx?.company_id,
      company_name: workEx?.company_name,
      company_website: workEx?.company_website ? workEx?.company_website : null,
      city_id: null,
      job_type_id: workEx?.job_type_id,
      job_type: workEx?.job_type_name,
      start_date: moment(workEx?.start_date).format("MM/yyyy"),
      end_date: workEx?.end_date
        ? moment(workEx?.end_date).format("MM/yyyy")
        : null,
      job_designation: workEx?.job_designation,
      job_description: workEx?.job_description,
      skills: [],
      is_current_working: workEx?.is_current_working,
    };
    setIsUpdateCall(true);
    praveshAxiosCall
      .post(`/api/company/work_experience/?user_id=${userId}`, body)
      .then((res) => {
        if (res.data.success) {
          // const newWorkEx = {
          //   ...workEx,
          //   id: res.data.data.work_ex_id
          // }
          setWorkExperience([...workExperiences, res.data.data.data]);
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert("Error", err.message);
      });
  };

  const patchWorkEx = (workEx, workExId, index, handleClose) => {
    // return;

    const body = {
      company_id: workEx?.company_id,
      company_name: workEx?.company_name,
      company_website: workEx?.company_website ? workEx?.company_website : null,
      city_id: null,
      job_type_id: workEx?.job_type_id,
      job_type: workEx?.job_type_name,
      start_date: moment(workEx?.start_date).format("MM/yyyy"),
      end_date: workEx?.end_date
        ? moment(workEx?.end_date).format("MM/yyyy")
        : null,
      job_designation: workEx?.job_designation,
      job_description: workEx?.job_description,
      skills: [],
      is_current_working: workEx?.is_current_working,
    };
    setIsUpdateCall(true);
    praveshAxiosCall
      .patch(
        `/api/company/work_experience/${workExId}/?user_id=${userId}`,
        body
      )
      .then((res) => {
        if (res.data.success) {
          let items = [...workExperiences];
          items[index] = res.data.data.data;
          setWorkExperience(items);
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert("Error", err.message);
      });
  };

  const deleteWorkEx = (workExId, index) => {
    setIsUpdateCall(true);
    praveshAxiosCall
      .delete(`/api/company/work_experience/${workExId}/?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          setWorkExperience((workExperiences) =>
            workExperiences.filter((_, workExIndex) => workExIndex !== index)
          );
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert("Error", err.message);
      });
  };

  //Education Apis
  const saveEducation = () => {
    setIsUpdateCall(true);
    praveshAxiosCall
      .get(`/api/education/user_education?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          setEducation(res.data.data);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert("Error", err.message);
      });
  };

  //Education Api Calls
  const addEducation = (newEducation, handleClose) => {
    var newDegreeId = newEducation?.degree_id;
    var newSpecializationId = newEducation?.specialization_id;
    var tempEducation = {
      ...newEducation,
      education_level: newEducation.education,
      start_date: moment(newEducation.start_date).format("yyyy-MM-DD"),
      end_date: moment(newEducation.end_date).format("yyyy-MM-DD"),
      specialization: newSpecializationId,
      degree: newDegreeId,
      college_name:
        newEducation?.college_id && newEducation?.college_id !== 142
          ? newEducation?.college_name
          : newEducation?.temp_college_name,
      degree_id: null,
      specialization_id: null,
    };

    setIsUpdateCall(true);
    praveshAxiosCall
      .post(`/api/education/user_education?user_id=${userId}`, tempEducation)
      .then((res) => {
        if (res.data.success) {
          let items = education ? [...education] : [];
          setEducation([...items, res.data.data.data]);
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert("Error", err.message);
      });
  };

  const patchEducation = (newEducation, eduId, index, handleClose) => {
    setIsUpdateCall(true);
    var newDegreeId = newEducation?.degree_id;
    var newSpecializationId = newEducation?.specialization_id;
    var tempEducation = {
      ...newEducation,
      education_level: newEducation.education,
      start_date: moment(newEducation.start_date).format("yyyy-MM-DD"),
      end_date: moment(newEducation.end_date).format("yyyy-MM-DD"),
      specialization: newSpecializationId,
      degree: newDegreeId,
      college_name:
        newEducation?.college_id && newEducation?.college_id !== 142
          ? newEducation?.college_name
          : newEducation?.temp_college_name,
      degree_id: null,
      specialization_id: null,
    };
    delete tempEducation.education_id;
    praveshAxiosCall
      .patch(
        `/api/education/user_education/${eduId}/?user_id=${userId}`,
        tempEducation
      )
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
          let items = [...education];
          items[index] = res.data.data.data;
          setEducation(items);
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert("Error", err.message);
      });
  };

  const deleteEducation = (eduId, index) => {
    setIsUpdateCall(true);
    praveshAxiosCall
      .delete(`/api/education/user_education/${eduId}/?user_id=${userId}`)
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
          setEducation((edu) =>
            edu.filter((_, eduIndex) => eduIndex !== index)
          );
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        setIsUpdateCall(false);
        FailureAlert("Error", err.message);
      });
  };

  //Basic Details
  const saveBasicDetails = (details, setLoading) => {
    setIsUpdateCall(true);
    const fd = new FormData();
    const [firstName, ...lastName] = details.studentName
      .split(" ")
      .filter(Boolean);
    fd.append("email", details.emailId);
    fd.append("first_name", firstName);
    fd.append("last_name", lastName.join(" "));
    fd.append("phone", details.phoneNumber);
    details?.dob &&
      fd.append(
        "dob",
        details?.dob ? moment(details?.dob).format("yyyy-MM-DD") : null
      );
    details?.gender &&
      fd.append("gender", details?.gender ? details?.gender : null);
    details?.currentCityId && fd.append("current_city", details?.currentCityId);
    details?.currentCityName &&
      fd.append("current_city_name", details?.currentCityName);

    details?.hometownCityId &&
      fd.append("hometown_city", details?.hometownCityId);
    details?.hometownCityName &&
      fd.append("hometown_city_name", details?.hometownCityName);
    details?.expYear &&
      fd.append("exp_year", details?.expYear ? details?.expYear : null);
    details?.expMonth &&
      fd.append("exp_month", details?.expMonth ? details?.expMonth : null);
    details?.currentCtc &&
      fd.append("cur_ctc", details?.currentCtc ? details?.currentCtc : null);
    details?.expectedCtc &&
      fd.append(
        "expected_ctc",
        details?.expectedCtc ? details?.expectedCtc : null
      );
    details?.noticePeriod &&
      fd.append(
        "notice_period",
        details?.noticePeriod ? details?.noticePeriod : null
      );
    details?.about &&
      fd.append("about", details?.about ? details?.about : null);
    parseResume && fd.append("resume", parseResume ? parseResume : null);
    fd.append(
      "salary_validation",
      JSON.stringify({
        is_negotialble: details.isNegotiable ? true : false,
        is_confidential: details.isConfidential ? true : false,
      })
    );
    const url = userId
      ? `/api/admin/student_details_edit/?user_id=${userId}`
      : `/api/admin/student_details_edit/`;

    setLoading(true);
    praveshAxiosCall
      .post(url, fd)
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message);
          setUserId(res?.data?.data?.user_id);
          // setBasicInfo({...basicInfo, userId: res.data.data.user_id});
          let updateObject = {
            userId: res?.data?.data?.user_id,
            studentName: details?.studentName,
          };
          if (userNotExist) {
            updateObject = { ...updateObject, createDate: new Date() };
          }
          setBasicInfo(() => ({
            ...details,
            ...updateObject,
          }));
          setResume(res?.data?.data?.verbose?.resume);
          setUserNotExist(false);
        } else {
          FailureAlert(res.data.error);
          throw Error(res?.data?.error);
        }
        setIsUpdateCall(false);
        setLoading(false);
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err.message);
        setIsUpdateCall(false);
        setLoading(false);
      });
  };

  //Preferences

  const savePreferences = (type, data) => {
    var body = null;
    if (type === "industry")
      body = {
        preference: {
          industry_type: data,
        },
      };
    else if (type === "location")
      body = {
        preference: {
          job_location: data,
        },
      };
    else if (type === "jobrole")
      body = {
        preference: {
          job_roles: data,
        },
      };
    else if (type === "jobtype")
      body = {
        preference: {
          job_type: data.jobType ? parseInt(data.jobType) : data.jobType,
          ppo: data.ppo,
          wfh: data.wfh,
        },
      };
    if (!body) {
      FailureAlert("Fill all detail");
      return;
    }
    setIsUpdateCall(true);
    if (!preferenceId) {
      praveshAxiosCall
        .post(`/api/education/user_preferences?user_id=${userId}`, body)
        .then((res) => {
          if (res.data.success) {
            // setResume(newResume);
            setPreferenceId(res?.data?.data?.data?.id);
            SuccessAlert(res.data.data.message);
          } else {
            FailureAlert(res.data.error);
          }
          setIsUpdateCall(false);
        })
        .catch((err) => {
          FailureAlert("Something Went Wrong", err.message);
          setIsUpdateCall(false);
        });
    } else {
      praveshAxiosCall
        .patch(
          `/api/education/user_preferences/${preferenceId}/?user_id=${userId}`,
          body
        )
        .then((res) => {
          if (res.data.success) {
            // setResume(newResume);
            SuccessAlert(res.data.data.message);
          } else {
            FailureAlert(res.data.error);
          }
          setIsUpdateCall(false);
        })
        .catch((err) => {
          FailureAlert("Something Went Wrong", err.message);
          setIsUpdateCall(false);
        });
    }
  };

  //Add Social Links
  const addSocialLinks = (newSocialLink) => {
    const body = {
      profile_url: newSocialLink,
    };
    setIsUpdateCall(true);
    praveshAxiosCall
      .post(`api/shared/user_links/?user_id=${userId}`, body)
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert(err.message);
        setIsUpdateCall(false);
      });
  };

  const [openApplyAndRateDialog, setOpenApplyAndRateDialog] = useState(false);
  const handleCloseApplyAndRateDialog = () => {
    setOpenApplyAndRateDialog(false);
  };

  //resume parser states, functions

  const [parseResume, setParseResume] = useState(null);

  //All Update Apis
  const postParseResume = () => {
    if (parseResume && !parseResume?.type?.includes("pdf")) {
      FailureAlert(
        "Only PDF file is parsable. Please manually check and create the candidate!"
      );
      return;
    }
    setIsUpdateCall(true);
    const fd = new FormData();
    fd.append("resume", parseResume);
    setIsUpdateCall(true);
    praveshAxiosCall
      .post(`/api/admin/resume/parse/open_ai/`, fd)
      .then((res) => {
        if (res?.data?.success) {
          // setResume(newResume);
          SuccessAlert(
            res?.data?.data?.message
              ? res?.data?.data?.message
              : "Resume Parsed successfully!"
          );
          setBasicInfo({
            studentName: res?.data?.data?.name,
            phoneNumber: res?.data?.data?.phone,
            emailId: res?.data?.data?.email,
          });
          setTempBasicInfo({
            studentName: res?.data?.data?.name,
            phoneNumber: res?.data?.data?.phone,
            emailId: res?.data?.data?.email,
          });
          if (res?.data?.data?.email && res?.data?.data?.is_user_exists)
            setEmail(res?.data?.data?.email);
          if (res?.data?.data?.is_temp_email) {
            FailureAlert(
              "Temporary Email used. Please update it with the new one!!"
            );
          }
        } else {
          FailureAlert(res.data.error);
        }
        setIsUpdateCall(false);
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err?.message);
        setIsUpdateCall(false);
      });
  };

  const inputRef = useRef(null);

  const handleButtonClick = (e) => {
    // e.preventDefault();
    inputRef.current.click();
    document.activeElement.blur();
  };

  // const ParseResumeComponent = () => {
  //   return (
  //     <div>
  //       <div style={{ marginBottom: "20px" }}>
  //         Student Not Present in GW-Database
  //       </div>
  //       <div className={classes.paper}>
  //         <Grid container spacing={2}>
  //           <Grid item xs={12}>
  //             <Typography variant="h6">Add Resume</Typography>
  //           </Grid>
  //           {parseResume && (
  //             <Grid item xs={12}>
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   justifyContent: "space-between",
  //                 }}
  //               >
  //                 <div>Uploaded Resume (parsing available only for pdf):</div>
  //               </div>
  //             </Grid>
  //           )}
  //           <Grid item xs={12}>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 justifyContent: "space-between",
  //               }}
  //             >
  //               <Button
  //                 startIcon={<AddCircleOutlineIcon color="white" />}
  //                 color="primary"
  //                 variant="contained"
  //                 component="label"
  //                 onMouseDown={handleButtonClick}
  //               >
  //                 Add Resume to Parse
  //                 <input
  //                   ref={inputRef}
  //                   type="file"
  //                   hidden
  //                   onChange={(e) => {
  //                     setParseResume(e.target.files[0]);
  //                   }}
  //                 />
  //               </Button>
  //               {parseResume ? parseResume?.name : ""}
  //             </div>
  //           </Grid>
  //           <Grid item xs={12}>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 justifyContent: "right",
  //               }}
  //             >
  //               <Button
  //                 variant="contained"
  //                 color="primary"
  //                 disabled={parseResume ? false : true}
  //                 onClick={() => {
  //                   postParseResume();
  //                 }}
  //               >
  //                 Parse
  //               </Button>
  //             </div>
  //           </Grid>
  //         </Grid>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      {!initialDataErrorCheck() ? (
        <LoadingOverlay
          active={isUpdateCall}
          spinner
          text="Updating Details..."
        >
          <div style={{ padding: "10px" }}>
            {showSearch && <StudentSearch email={email} setEmail={setEmail} />}
            <br />
            {!isLoading ? (
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <Basic
                    basicInfo={basicInfo}
                    cityList={cityList}
                    saveBasicDetails={saveBasicDetails}
                    userId={userId}
                    currentEducation={currentEducation}
                    getStudentDetails={getStudentDetails}
                    gender={gender}
                    setGender={setGender}
                    tempBasicInfo={tempBasicInfo}
                    setTempBasicInfo={setTempBasicInfo}
                  />
                  <br />
                  {userId && !userNotExist && (
                    <>
                      <ImportantLink
                        socialLinks={socialLinks}
                        postSocialLinks={addSocialLinks}
                      />
                      <br />
                      {/* <Resume
                        userId={userId}
                        resume={resume}
                        postResume={postResume}
                        uploadedResume={uploadedResume}
                        setUploadedResume={setUploadedResume}
                        uploadResume={uploadResume}
                        resumeFileLoader={resumeFileLoader}
                        gifStyling={gifStyling}
                        gif={gif}
                        resumePath={resumePath}
                        getFileType={getFileType}
                      /> */}
                      {/* comment start */}
                      <Resume
                        userId={userId}
                        resume={resume}
                        postResume={postResume}
                        uploadedResume={uploadedResume}
                        setUploadedResume={setUploadedResume}
                      />
                      <br />
                      <div className={classes.paper1}>
                        <Grid container>
                          {!resume ? (
                            <>
                              <Typography>
                                No Resume found.
                                <br />
                                Please upload the resume for the respective
                                student.
                              </Typography>
                              <img
                                src={uploadResume}
                                style={{ width: "40%", marginLeft: "30%" }}
                              />
                            </>
                          ) : resumeFileLoader ? (
                            <img src={gif} alt="" style={gifStyling} />
                          ) : (
                            resume &&
                            (resume?.includes("pdf") ? (
                              <embed
                                style={{
                                  width: "100%",
                                  height: 500,
                                }}
                                src={resume ? resumePath : null}
                              />
                            ) : (
                              <section
                                style={{ borderStyle: "ridge", width: "100%" }}
                              >
                                {resumePath && (
                                  <FileViewer
                                    style={{
                                      minWidth: "300px",
                                      minHeight: "300px",
                                    }}
                                    fileType={getFileType()}
                                    filePath={resumePath}
                                  />
                                )}
                              </section>
                            ))
                          )}
                        </Grid>
                      </div>
                      {/* comment end */}
                      <br />
                      <JobRoles
                        jobRolesList={jobRolesList}
                        JobRolesPreference={JobRolesPreference}
                        savePreferences={savePreferences}
                      />
                      <br />
                      <PreferedJobLocations
                        cityList={cityList}
                        locationPreference={locationPreference}
                        savePreferences={savePreferences}
                      />
                      <br />
                      <JobType
                        jobTypePreference={jobTypePreference}
                        ppoPreference={ppoPreference}
                        wfhPreference={wfhPreference}
                        savePreferences={savePreferences}
                      />
                      <br />
                      <IndustryType
                        industries={industriesList}
                        industryPreference={industryPreference}
                        savePreferences={savePreferences}
                      />

                      <br />
                      <Skills
                        skillsList={skillsList}
                        userSkills={skills}
                        postSkills={postSkills}
                        debounceSkillList={debounceSkillList}
                      />

                      <Divider />
                      <br />

                      <br />
                      <div className={classes.paper1}>
                        <Grid container /* style={{ display: "flex" }} */>
                          <Typography
                            style={{ margin: "10px", fontSize: "18px" }}
                          >
                            <i>
                              Select Job Profile & Rate Student On That Job then
                              only the application will appear on job track.
                            </i>
                          </Typography>
                          <Typography>
                            * Resume and Basic education details are mandatory
                            to Apply and Rate.
                          </Typography>
                          {/* <Button
                            onClick={() => {
                              applyToJob();
                            }}
                            variant="contained"
                            color="primary"
                            // color="primary"
                            size="sm"
                            style={{ margin: "10px 30px" }}
                            disabled={
                              currentEducation &&
                              resume &&
                              basicInfo?.currentCtc
                                ? false
                                : true
                            }
                          >
                            {false ? (
                              <CircularProgress
                                style={{
                                  color: "white",
                                  height: "25px",
                                  width: "25px",
                                }}
                              />
                            ) : (
                              "Apply"
                            )}
                          </Button> */}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "10px",
                              width: "100%",
                            }}
                          >
                            <Button
                              onClick={() => {
                                if (basicInfo?.is_blocked) {
                                  FailureAlert(
                                    "Please unlock the student profile!"
                                  );
                                  return;
                                }
                                setOpenApplyAndRateDialog(true);
                              }}
                              variant="contained"
                              color="primary"
                              size="sm"
                              // style={{ margin: "10px" }}
                              disabled={
                                ((currentEducation &&
                                  Object.keys(currentEducation)?.length > 0) ||
                                  education?.length > 0) &&
                                // resume
                                //  && basicInfo?.currentCtc
                                (uploadedResume || resume)
                                  ? false
                                  : true
                              }
                            >
                              {false ? (
                                <CircularProgress
                                  style={{
                                    color: "white",
                                    height: "25px",
                                    width: "25px",
                                  }}
                                />
                              ) : (
                                "Rate And Apply"
                              )}
                            </Button>
                          </div>
                          <RateAndApply
                            jobSkills={[]}
                            jobId={jobId}
                            companyId={compantId}
                            open={openApplyAndRateDialog}
                            handleClose={handleCloseApplyAndRateDialog}
                            userId={basicInfo?.userId}
                            studentName={basicInfo?.studentName}
                            collegeId={
                              currentEducation?.college
                                ? currentEducation?.college
                                : 0
                            }
                            jobTrack={jobId ? true : false}
                          />
                        </Grid>{" "}
                      </div>
                      <br />
                      {/* </div> */}
                    </>
                  )}
                </Grid>

                <Grid item xs={6}>
                  {userId && !userNotExist ? (
                    <>
                      <Education
                        collegeList={collegeList}
                        boardsList={boardsList}
                        education={education}
                        saveEducation={saveEducation}
                        handleAddEdu={addEducation}
                        handlePatchEdu={patchEducation}
                        handleDeleteEdu={deleteEducation}
                      />
                      <br />
                      <WorkExperience
                        companiesList={companiesList}
                        workExperiences={workExperiences}
                        handleAddWorkEx={addWorkEx}
                        handlePatchWorkEx={patchWorkEx}
                        handleDeleteWorkEx={deleteWorkEx}
                      />
                      <br />
                      <Project
                        projects={projects}
                        handleAddProject={addProject}
                        handlePatchProject={patchProject}
                        handleDeleteProject={deleteProject}
                      />
                      <br />
                      <Certifications
                        cerifications={certifications}
                        handleAddCertifications={addCertification}
                        handlePatchCertifications={patchCertification}
                        handleDeleteCertifications={deleteCertification}
                      />
                      <br />
                      <Publications
                        publications={publications}
                        handleAddPublications={addProject}
                        handlePatchPublications={patchProject}
                        handleDeletePublications={deleteProject}
                      />
                      <br />
                      <Languages
                        languages={languages}
                        postLanguages={postLanguages}
                      />
                      <br />
                      <Achievements
                        achievements={achievements}
                        postAchievements={postAchievements}
                      />
                      <br />
                      <Hobbies hobbies={hobbies} postHobbies={postHobbies} />
                    </>
                  ) : (
                    // <ParseResumeComponent />
                    <div>
                      <div style={{ marginBottom: "20px" }}>
                        Student Not Present in GW-Database
                      </div>
                      <div className={classes.paper}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Add Resume</Typography>
                          </Grid>
                          {parseResume && (
                            <Grid item xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  Uploaded Resume (parsing available only for
                                  pdf):
                                </div>
                              </div>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                startIcon={
                                  <AddCircleOutlineIcon color="white" />
                                }
                                color="primary"
                                variant="contained"
                                component="label"
                                onMouseDown={handleButtonClick}
                              >
                                Add Resume to Parse
                                <input
                                  ref={inputRef}
                                  type="file"
                                  hidden
                                  onChange={(e) => {
                                    setParseResume(e.target.files[0]);
                                  }}
                                />
                              </Button>
                              {parseResume ? parseResume?.name : ""}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={parseResume ? false : true}
                                onClick={() => {
                                  postParseResume();
                                }}
                              >
                                Parse
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            ) : (
              <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
            )}
          </div>
        </LoadingOverlay>
      ) : (
        <div>
          <Button
            onClick={() => {
              setRefreshData(true);
            }}
          >
            Click Here to Refresh
          </Button>
          <br />
          Error: {allApiSuccess.message}
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    paper: {
      padding: 20,
      margin: "auto",
      backgroundColor: "#fff",
      boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
      borderRadius: "15px",
      //maxWidth: 500,
    },
    labelField: {
      fontSize: 13,
    },
    RedStar: {
      color: "red",
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      // marginBottom: 20,
    },
    fieldStyling1: {
      width: "100%",
      marginBottom: 10,
    },
    paper1: {
      padding: "20px 10px",
      margin: "auto",
      backgroundColor: "#fff",
      boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
      borderRadius: "15px",
    },
    "@media screen and (min-width: 960.5px)": {
      pcEnrollId: {
        display: "block",
        color: "blue",
      },
      mobileEnrollId: {
        display: "none",
      },
    },
    "@media screen and (max-width: 960.5px)": {
      pcEnrollId: {
        display: "none",
      },
      mobileEnrollId: {
        display: "block",
      },
    },
  })
);

export default StudentsDetails;
