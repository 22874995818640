import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    circularProgressColored: {
        color: theme.palette?.primary.main,
    },
    circularProgress: {
        color: '#fff',
    },
}));
function CustomButtonCircularProgress({isWhite=true}) {
    const classes = useStyles();
    return <CircularProgress className={isWhite ?classes.circularProgress : classes.circularProgressColored } size={14} />;
}
export default CustomButtonCircularProgress;