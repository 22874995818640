import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { cellCSS, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";
import ProductCouponDialog from "./ProductCouponDialog";

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "15px",
    marginBottom: "20px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const token = localStorage.getItem("token");

function ProductCoupons(props) {
  const classes = useStyles();

  const [editCouponObject, setEditCouponObject] = useState({
    product: null,
    expiry: null,
    coupon_code: null,
    status: null,
  });
  // useEffect(() => console.log(startDate.toISOString()), [startDate]);


  const [currentRow, setCurrentRow] = useState(null);
  const [edit, setEdit] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditCouponObject({
      ...editCouponObject,
      product: null,
      expiry: "",
      coupon_code: "",
      status: null,
    });
  };
  const columns = [
    {
      title: "Product/Service",
      field: "title",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
      // filtering: false,
      filterPlaceholder: "Product/Service",
      render: (row) => (
        <div
        //   style={{
        //     display: "flex",
        //     flexDirection: "row",
        //     justifyContent: "center",
        //   }}
        >
          {row.title}
        </div>
      ),
    },
    {
      title: "Expiry Date",
      field: "expiry",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      //   filtering: false,
      // lookup: primaryFilter,
      filterPlaceholder: "Expiry Date",
      filtering: false,
      render: (row) => <>{moment(row.expiry).format("DD/MM/YYYY")}</>,
    },

    {
      title: "Coupon codes",
      field: "coupon_code",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
      filtering: false,
    },
    {
      title: "Used",
      field: "is_used",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,

      filterPlaceholder: "Status",
      render: (row) => <>{row?.is_used ? <div>Yes</div> : <div>No</div>}</>,
    },
    {
      title: "Status",
      field: "is_active",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,

      filterPlaceholder: "Status",
      render: (row) => <>{row?.is_active ? <div style={{ color: "green", cursor: "pointer" }}>Active</div> : <div style={{ color: "red", cursor: "pointer" }}>InActive</div>}</>,
    },
    {
      title: "Price(in INR)",
      field: "actual_price",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Discount (in %)",
      field: "discount",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Getwork Price (in INR)",
      field: "gw_price",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Actions",
      field: "actions",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
      render: (row) => (
        <>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClickOpen();
              setCurrentRow(row);
              setEdit(true);
            }}
          />
        </>
      ),
    },
  ];

  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const checkProduct = () => {
    if (selectedProduct) return `product_id=${selectedProduct?.id}`;
    else return ``;
  };

  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/product_coupons/?${checkProduct()}`, requestOptionJobId)
      .then((res) => {
        if (res?.success) {
          const json = res?.data;
          json.forEach((item) => {
            item["actions"] = "actions";
          });
          setDataCategory(json);
        } else FailureAlert(" Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [dataProducts, setDataProducts] = useState([]);
  const getProducts = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/product_service/?list=true&is_active=1`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataProducts(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (selectedProduct) getData();
  }, [selectedProduct]);

  useEffect(() => {
    if (edit && currentRow) {
      setEditCouponObject({
        product: { id: currentRow?.product_id, title: currentRow?.title },
        expiry: currentRow?.expiry?.slice(0, 10),
        coupon_code: currentRow?.coupon_code,
        status: currentRow?.is_active ? "ACTIVE" : "INACTIVE",
      });
    }
  }, [edit]);

  const updateCoupon = (id) => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        coupon_code: editCouponObject?.coupon_code,
        expiry: editCouponObject?.expiry + "T00:00:00",
        is_active: editCouponObject?.status === "ACTIVE" ? true : false,
      }),
    };
    apiCall(`/api/getwork_club/product_coupons/${id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
        setEditCouponObject({
          ...editCouponObject,
          product: null,
          expiry: "",
          coupon_code: "",
          status: null,
        });
      } else {
        FailureAlert(res.error);
      }
    });
  };

  return (
    <div className="all__colleges">
      <Grid container spacing={3} className={classes.action}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Autocomplete
            options={dataProducts}
            getOptionLabel={(item) => item.title}
            style={{ width: "70%" }}
            onChange={(e, item) => {
              if (item) {
                setSelectedProduct(item);
              } else {
                setSelectedProduct(null);
              }
            }}
            value={selectedProduct}
            renderInput={(params) => <TextField {...params} label="Select Product/Service" variant="outlined" />}
          />
          {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
        </Grid>
      </Grid>
      <TableWithExport
        title="Coupon"
        columns={columns}
        data={dataCategory}
        fName="Coupons"
        // editableObject={edit}
      />
      <ProductCouponDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        edit={edit}
        setEdit={setEdit}
        editCouponObject={editCouponObject}
        setEditCouponObject={setEditCouponObject}
        updateCoupon={updateCoupon}
      />
    </div>
  );
}

export default withRouter(ProductCoupons);
