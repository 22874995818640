import {
  FormControl,
  Grid, InputLabel,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import "../../css/Miscellaneous.css";

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",

    marginBottom: "10px",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    border: "1px solid #b0b6ba",
    //marginBottom: "1%",
    borderRadius: "10px",
    padding: "10px",
  },
  editingtext: {
    color: "#b0b7c3",
    fontStyle: "italic",
  },
  btn: {
    backgroundColor: "#17a2b8",
    boxShadow: "none",
    color: "#fff",
    marginTop: "10px",
  },
}));

function ELearnings() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const state = {
    age: "",
    name: "hai",
  }

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  return !loading ? (
    <>
      <div className="education__skils">
        <div className="education__skils__form">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Enter Name of Courses or instructor
                  </InputLabel>
                  <Select
                    native
                    value={state.age}
                    onChange={handleChange}
                    label="Enter Name of Courses or instructor"
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default ELearnings;
