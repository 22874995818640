import React from 'react'
import TableWithExport from '../TableWithExport'
import { useState } from 'react';
import { copyToClipboard } from '../../util/copyToClipboard';
import { Button } from "react-bootstrap";

import {
    headerCSS,
    cellCSS,
    gifStyling,
    createIconStyling,
    requestPraveshOptions,
} from "../../constants";
import gif from "../../assets/loopingcircles-3.gif";

import { makeStyles } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { praveshAxiosGetReq } from '../../util/baseApi';
import { useEffect } from 'react';
import { praveshAxiosCall } from '../../util/apiCall';

const useStyles = makeStyles((theme) => ({
    button: {

        height: '2rem',
    }

}))

const CompanyProposalLinks = () => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [agreementPdf, setAgreementPdf] = useState(null);


    // const getAgreementPdf = async (e, url) => {

    //     praveshAxiosCall.get(url, { responseType: "arraybuffer" })
    //         .then((res) => {
    //             const blob = new Blob([res.data], { type: "application/pdf" });
    //             let fileURL = URL.createObjectURL(blob);
    //             setAgreementPdf(fileURL);

    //         })
    //         .catch((error) => {
    //             throw (error);
    //         })

    // try {
    //     const res = await praveshAxiosGetReq(url, {
    //         "Content-Type": "application/json",
    //         Authorization: `Token ${localStorage.getItem("token")}`,

    //     },
    //         // { responseType: "arraybuffer", }

    //     );

    //     if (res?.data?.success) {

    //         const blob = new Blob([res.data], { type: "application/pdf" });
    //         let fileURL = URL.createObjectURL(blob);
    //         setAgreementPdf(fileURL);

    //     }

    // }
    // catch (error) {
    //     throw (error);
    // }

    // };

    const getTimeStamp = (timeStamp) => {

        const date = new Date(timeStamp).toLocaleString();

        return date;
    };


    const columns = [
        {
            title: "Company ID",
            field: "company_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
        },
        {
            title: "Registered Company Name",
            field: "registered_company_name",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            render: ((row) => (
                <section style={{ minWidth: 200 }}>

                    {row?.registered_company_name}
                </section>
            ))

        },
        {
            title: "Company Name",
            field: "company_name",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            render: ((row) => (
                <section style={{ minWidth: 200 }}>

                    {row?.company_name}
                </section>
            ))

        },
        {
            title: "Job Proposal Link",
            field: "proposalLink",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            render: ((row) => (
                <section>

                    Copy Link
                    <FileCopy
                        style={createIconStyling}
                        onClick={(e) => copyToClipboard(row?.proposalLink)}
                    />

                </section>
            )

            )
        },


        {
            title: "Payment Status",
            field: "status",
            headerStyle: headerCSS,
            cellStyle: {
                minWidth: 180,
                maxWidth: 200,
                fontSize: 13,
            },
            searchable: false,
            filterPlaceholder: "pay status",
            lookup: {
                "PAYMENT_FAILED": "PAYMENT FAILED",
                "PENDING": "PENDING",
                "PAID": 'PAID',
            },

            customFilterAndSearch: (term, rowData) => {

                for (let i in term) {
                    if (term[i] === rowData?.status) {
                        return true;
                    } else {
                        return false
                    }
                };
                if (term.length < 1) {
                    return true;
                }
            },
            // render:((row) =>   row.gstin)
        },


        {
            title: "Total Amount (Including Taxes & Charges)",
            field: "final_amount",
            headerStyle: headerCSS,
            cellStyle: cellCSS,

        },
        {
            title: "Amount",
            field: "amount",
            headerStyle: headerCSS,
            cellStyle: cellCSS,

        },
        {
            title: "18% Taxes",
            field: "taxes",
            headerStyle: headerCSS,
            cellStyle: cellCSS,

        },
        {
            title: "Discount",
            field: "discount",
            headerStyle: headerCSS,
            cellStyle: cellCSS,

        },
        {
            title: "Coupon Code",
            field: "coupon_code",
            headerStyle: headerCSS,
            cellStyle: cellCSS,

        },
        {
            title: "POC Name",
            field: "poc_name",
            headerStyle: headerCSS,
            cellStyle: {
                minWidth: 200,
                maxWidth: 200,
                fontSize: 13,
            },

        },
        {
            title: "Intern Replacement Days",
            field: "internship_replacement",
            headerStyle: headerCSS,
            cellStyle: cellCSS,

        },
        {
            title: "Full Time Replacement Days",
            field: "fulltime_replacement",
            headerStyle: headerCSS,
            cellStyle: cellCSS,

        },

        {
            title: "View Proposal Agreement",
            field: "",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            render: ((row) => (

                row?.proposal_agreement ?
                    <Button
                        variant="outline-success"
                        size="sm"
                        onClick={(e) => {

                            // getAgreementPdf(e, row?.proposal_agreement)}
                            window.open(row?.proposal_agreement, '_blank').focus();
                        }}
                    >
                        Agreement
                    </Button>
                    : null
            ))

        },
        {
            title: "Expiry Time",
            field: "",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            render: ((rowData) => (
                <section>

                    {getTimeStamp(rowData?.expiry_time)}

                </section>))

        },

        {
            title: "Generated By",
            field: "generated_by",
            headerStyle: headerCSS,
            // cellStyle: cellCSS,
            cellStyle: {
                minWidth: 200,
                maxWidth: 200,
                fontSize: 13,
            },

        },

    ];

    const getCompanyProposalData = async () => {

        setLoading(true);
        const endPoint = `/api/admin/company/proposal_link/`;

        try {
            const res = await praveshAxiosGetReq(endPoint);
            const data = await res?.data?.data;

            if (res?.data?.success) {

                let tempArray = [];

                for (let i in data) {

                    let obj = {
                        company_id: data[i].id
                            ? parseInt(data[i].id)
                            : "",
                        company_name: data[i].company_name ? data[i].company_name : '',
                        proposalLink: data[i]?.link ? data[i]?.link : '',
                        status: data[i]?.status ? data[i]?.status : '',
                        proposalLink: data[i]?.link ? data[i]?.link : '',
                        expiry_time: data[i]?.expiry_time ? data[i]?.expiry_time : '',
                        taxes: data[i]?.taxes ? data[i]?.taxes : '',
                        proposal_agreement: data[i]?.proposal_agreement ? data[i]?.proposal_agreement : '',
                        generated_by: data[i]?.generated_by ? data[i]?.generated_by : '',
                        amount: data[i]?.amount ? data[i]?.amount : '',
                        coupon_code: data[i]?.coupon_code ? data[i]?.coupon_code : '',
                        discount: data[i]?.discount ? data[i]?.discount : '',
                        expiry_time: data[i]?.expiry_time ? data[i]?.expiry_time : '',
                        final_amount: data[i]?.final_amount ? data[i]?.final_amount : '',
                        fulltime_replacement: data[i]?.fulltime_replacement ? data[i]?.fulltime_replacement : '',
                        internship_replacement: data[i]?.internship_replacement ? data[i]?.internship_replacement : '',
                        poc_name: data[i]?.poc_name ? data[i]?.poc_name : '',
                        registered_company_name: data[i]?.registered_company_name ? data[i]?.registered_company_name : '',


                        // : data[i]?. ? data[i]?. : '',


                    };

                    tempArray.push(obj);

                };

                setData(tempArray);

            };
            setLoading(false);

        }
        catch (error) {
            setLoading(false);
            throw (error);
        }
    };

    useEffect(() => {

        getCompanyProposalData();

    }, [])


    return (loading ? <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} /> :
        <>
            <TableWithExport
                title="Company Proposal Links"
                data={data}
                columns={columns}
                fName="Selected_Companies_proposal_links"
            />
        </>
    )
}

export default CompanyProposalLinks