import React, { useState, useEffect } from 'react';
import {apiNiyuktiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestNiyuktiOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
// import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

function JobRoleGroup() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [segmentId, setSegmentId] = useState(null);
    const [segments, setSegments] = useState([]);
    const [segmentFilter, setSegmentFilter] = useState({}); // For Filter Purpose
    const [roleGroup, setRoleGroup] = useState('');
    // const [patchSegment, setPatchSegment] = useState(null);
    const [loading, setLoading] = useState(false);

    // Segment Finding API
    useEffect(() => {
        apiNiyuktiCall('/job/segment/', requestNiyuktiOptions)
        .then((res)=> {
            if(res.success) {
                setSegments(res.data)
                let obj = {}
                res.data.forEach((s) => { obj[s.id] = s.job_segment })
                setSegmentFilter(obj)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading(true);
        apiNiyuktiCall('/job/role_group/', requestNiyuktiOptions)
          .then(json => {
                if(json.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            id:                json.data[i].id,
                            job_segment:       parseInt(json.data[i].job_segment),
                            job_segment_name:  json.data[i].job_segment_name,
                            job_role_group:    json.data[i].job_role_group, 
                        }
                        tmpArray.push(obj)
                    }
                    setLoading(false);
                    setData(tmpArray);
                }
                else {
                    FailureAlert(json.error)
                }    
           })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
            job_segment: segmentId,
            job_role_group: roleGroup
        })
    };

    const columns = [
        { title: "Job Role Group ID",    field: "id",                headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Job Role Group Name",  field: "job_role_group",    headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job Segment Name",     field: "job_segment",       headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,             lookup: segmentFilter,      filterPlaceholder: 'Segment',
        //   editComponent: props => (
        //     <Select 
        //         onChange={e=> {setPatchSegment(JSON.parse(e.target.value).id)
        //             props.onChange(JSON.parse(e.target.value).job_segment)
        //         } }
        //     >
        //     {
        //         segments.map((s, key) => {
        //         return (
        //             <MenuItem key={key} value={JSON.stringify(s)} >
        //                 {s.job_segment}
        //             </MenuItem>
        //         )})
        //     }
        //     </Select>
        //   )
        },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData, oldData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        })
    }

    const updateRow = (newData, oldData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ 
                ...(oldData.job_role_group!==newData.job_role_group && {job_role_group: newData.job_role_group}),
                ...(oldData.job_segment!==newData.job_segment && {job_segment: parseInt(newData.job_segment)}),
                // ...(patchSegment && {job_segment: patchSegment}),
            })
        };
        apiNiyuktiCall(`/job/role_group/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) SuccessAlert(res.data.message); 
            else FailureAlert(res.error);
            setCount(count+1);
        })
    }

    const addRoleGroup = (e) => {
        e.preventDefault();
        apiNiyuktiCall('/job/role_group/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.error);
            }
        })
        e.target.reset();
    }

    return (
        !loading
        ?
        <div className="job__role__group">
            <div className="job__role__group__form">
                <h4>Add New Role Group</h4>
                <Form onSubmit={e=>addRoleGroup(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="roleGroup">
                            <Form.Label>Role Group Name*</Form.Label>
                            <Form.Control 
                                name="roleGroup" 
                                type="text" 
                                placeholder="Enter Role Group Name"
                                onChange={e=> {setRoleGroup(e.target.value)}}
                                required 
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="segment">
                            <Form.Label>Segment Type</Form.Label>
                            <Form.Control 
                                as="select"
                                onChange={e=> {setSegmentId(JSON.parse(e.target.value).id)} }
                            >
                                <option value={JSON.stringify({"id": null})}>--select--</option>
                            {
                                segments.map((segment, key) => {
                                return (
                                    <option key={key} value={JSON.stringify(segment)} >
                                        {segment.job_segment}
                                    </option>
                                )})
                            }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Button type="submit" variant="info">Add Role Group</Button>
                    
                </Form>    
            </div>
            <TableWithExport 
                title = "Job Role Group"
                data = {data}
                columns = {columns}
                fName = "Selected Job Role Groups"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default JobRoleGroup;