import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import React, { useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import gif from "../../assets/loopingcircles-3.gif";
import AddMessageTemplate from '../../components/DialogSection/AddMessageTemplate/AddMessageTemplate';
import DisplayHtml from '../../components/DialogSection/DisplayHtml/DisplayHtml';
import TableWithExport from '../../components/TableWithExport';
import { cellCSS, createIconStyling, gifStyling, headerCSS, TEMPLATE_USER_TYPES, CHANNEL_TYPES } from '../../constants';
import { sandeshAxiosGetReq, sandeshAxiosPatchReq, sandeshAxiosPostReq } from '../../util/baseApi';
import { copyToClipboard } from '../../util/copyToClipboard';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const useStyles = makeStyles((theme) => ({
    heading: {
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '1rem',

    },
    headingText: {
        fontWeight: 600,
        font: "Nunito"
    },
    btnAdd: {
        minWidth: "6rem",
        fontSize: 13,
    }
}))

const ViewTemplate = () => {

    const classes = useStyles()
    const [allCommunicationData, setAllCommunicationData] = useState(() => []);
    const [open, setOpen] = useState(false);
    const [openAddTemplate, setOpenAddTemplate] = useState(false)

    const initialMessageTemplate = {
        user_type: [],
        template_key: '',
        description: '',
        template: '',
        template_id: '',
        template_html: '',
        is_active: false,
        channels: [],
    }
    const [messageTemplate, setMessageTemplate] = useState(initialMessageTemplate);

    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [templateHeading, setTemplateHeading] = useState('');

    const [htmlContent, setHtmlContent] = useState(
        `<>
        <h1>The Crushing Bore</h1>

<p>By Chris Mills</p>

<h2>Chapter 1: The dark night</h2>

<p>
  It was a dark night. Somewhere, an owl hooted. The rain lashed down on the…
</p>

<h2>Chapter 2: The eternal silence</h2>

<p>Our protagonist could not so much as a whisper out of the shadowy figure…</p>

<h3>The specter speaks</h3>

<p>
  Several more hours had passed, when all of a sudden the specter sat bolt
  upright and exclaimed, "Please have mercy on my soul!"
</p>
            <p>
                This paragraph
                contains a lot of lines
                in the source code,
                but the browser
                ignores it.
            </p >

            <p>
                This paragraph
                contains         a lot of spaces
                in the source         code,
                but the        browser
                ignores it.
            </p>


        </>`
    )

    const handleToggle = async (id) => {

        const findMatchingTemplate = allCommunicationData?.filter((item) => (item?.id === id));
        let matchingElement = findMatchingTemplate[0];

        const endPoint = `/api/admin/message-template/${id}/`;

        try {
            const res = await sandeshAxiosPatchReq(endPoint, { is_active: matchingElement?.is_active ? false : true });
            if (res?.data?.success) {
                var tempComm = [...allCommunicationData];
                var ind = allCommunicationData?.findIndex((item) => (item?.id === id));
                tempComm[ind]["is_active"] = matchingElement?.is_active ? false : true;
                setAllCommunicationData(tempComm);
                SuccessAlert(res?.data?.data?.message);
            }
            else {
                FailureAlert(res?.data?.error);
            }
        } catch {
            FailureAlert("Something went wrong!!");
        }


    }

    const handleTemplateBoxPrefill = (ID) => {

        const selectedTemplateDetails = allCommunicationData?.find(item => item?.id === ID);

        let tempObj = {
            id: selectedTemplateDetails?.id,
            user_type: TEMPLATE_USER_TYPES?.filter(item => {
                return selectedTemplateDetails?.user_type?.includes(item?.name)
            }
            ),
            template_key: selectedTemplateDetails?.template_key || '',
            description: selectedTemplateDetails?.description || '',
            template: selectedTemplateDetails?.template || '',
            template_id: selectedTemplateDetails?.template_id,
            template_title: selectedTemplateDetails?.template_title,
            redirect_end_point: selectedTemplateDetails?.redirect_end_point,
            template_html: selectedTemplateDetails?.template_html || '',
            is_active: selectedTemplateDetails?.is_active,
            channels: CHANNEL_TYPES?.filter(item => {
                return selectedTemplateDetails?.channels?.includes(item?.name)

            }
            ) || [],
            editTemplateFlag: true,
        }
        setMessageTemplate(tempObj)

    }


    const columns = [
        {
            title: "Template Key",
            field: "template_key",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) =>
                <section
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        placeItems: "center",
                        width: "250px",
                    }}>
                    {
                        row?.template_key ? (
                            <span style={{
                            }}>
                                {row?.template_key}
                                <FileCopyIcon
                                    style={createIconStyling}
                                    onClick={(e) => copyToClipboard(row?.template_key)}
                                />
                            </span>
                        ) : null

                    }

                </section>

        },
        {
            title: "Template Title",
            field: "template_title",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) =>
                <section
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        placeItems: "center",
                        width: "250px",
                    }}>
                    {
                        row?.template_title ? (
                            <span style={{
                            }}>
                                {row?.template_title}
                                <FileCopyIcon
                                    style={createIconStyling}
                                    onClick={(e) => copyToClipboard(row?.template_title)}
                                />
                            </span>
                        ) : null

                    }

                </section>

        },

        {
            title: "User Type",
            field: "user_type",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row => (row?.user_type && row?.user_type?.toString()))
        },

        {
            title: "Description",
            field: "description",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) =>
                <section
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        placeItems: "center",
                        width: "250px",
                    }}>
                    {
                        row?.description ? (
                            <span style={{
                                // textOverflow: "ellipsis",
                                // textOverflow: "hidden",
                                // whiteSpace: 'nowrap',
                            }}>
                                {row?.description}
                                <FileCopyIcon
                                    style={createIconStyling}
                                    onClick={(e) => copyToClipboard(row?.description)}
                                />
                            </span>
                        ) : null

                    }

                </section>
        },
        {
            title: "Template",
            field: "template",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) =>
                <section
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        placeItems: "center",
                        width: "150px",
                    }}>
                    {
                        row?.template ? (
                            <span style={{
                                // textOverflow: "ellipsis",
                                // textOverflow: "hidden",
                                // whiteSpace: 'nowrap',
                            }}>
                                {row?.template}
                                <FileCopyIcon
                                    style={createIconStyling}
                                    onClick={(e) => copyToClipboard(row?.template)}
                                />
                            </span>
                        ) : null

                    }

                </section>

        },
        {
            title: 'Template Html', field: "template_html", headerStyle: headerCSS, cellStyle: cellCSS,
            render: row =>
                <div>
                    {
                        row.template_html ?
                            <Button
                                style={{
                                    fontSize: 13,
                                    minWidth: '5rem'
                                }}
                                variant="outline-primary"
                                // size="sm"
                                onClick={() => {
                                    setOpen(true);
                                    setHtmlContent(row?.template_html);
                                    setTemplateHeading(row?.template_title)
                                }
                                }
                            > view</Button>
                            : <></>
                    }
                </div>
        },
        {
            title: "Template ID",
            field: "template_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Channels",
            field: "channels",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row => (row?.channels && row?.channels?.toString()))

        },
        {
            title: "Redirect End Point",
            field: "redirect_end_point",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) =>
                <section
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        placeItems: "center",
                        width: "250px",
                    }}>
                    {
                        row?.redirect_end_point ? (
                            <span style={{
                                // textOverflow: "ellipsis",
                                // textOverflow: "hidden",
                                // whiteSpace: 'nowrap',
                            }}>
                                {row?.redirect_end_point}
                                <FileCopyIcon
                                    style={createIconStyling}
                                    onClick={(e) => copyToClipboard(row?.redirect_end_point)}
                                />
                            </span>
                        ) : null

                    }

                </section>

        },
        {
            title: 'Edit Template', field: "", headerStyle: headerCSS, cellStyle: cellCSS,
            render: row =>
                <div>
                    {
                        row.template_key ?
                            <Button
                                style={{
                                    fontSize: 13,
                                    minWidth: '8rem'
                                }}
                                variant="outline-primary"
                                // size="sm"
                                onClick={() => {
                                    setOpenAddTemplate(true)
                                    handleTemplateBoxPrefill(row?.id)
                                }
                                }
                            > Edit Template</Button>
                            : <></>
                    }
                </div>
        },
        {
            title: "Last Action By",
            field: "last_updated_by",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },

        {
            title: "Create Time",
            field: "create_time",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Update Time",
            field: "update_time",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Is Active",
            field: "is_active",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
            render: (row) => (
                <section>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={row?.is_active}
                                onChange={() => {
                                    handleToggle(row?.id)
                                }
                                }
                                color="primary"
                                size="small"
                                name="checked"
                            />}
                    // label={row?.status ? "Active" : "InActive"}
                    />
                </section>)
        },


    ]

    const UpdatedTable = useMemo(() =>
        <TableWithExport
            title="Communication Messages"
            data={allCommunicationData}
            columns={columns}
            fName="Selected_Companies"
            cellFixedIndex={0}
            selection={false}
        />, [allCommunicationData])


    const validateForm = () => {

        if (!messageTemplate?.user_type || !messageTemplate?.template_key ||
            !messageTemplate?.description || !messageTemplate?.template || !messageTemplate?.channels) {
            FailureAlert("Please fill all required fields!");
            return false;
        }

        if (messageTemplate?.channels?.length === 0) {

            FailureAlert("Please select channels!");
            return false;
        }
        return true;
    }


    const postTemplate = async () => {

        if (validateForm()) {

            let endPoint = `/api/admin/message-template/`;

            if (messageTemplate?.editTemplateFlag) {

                endPoint = `/api/admin/message-template/${messageTemplate?.id}/`
            }
            let data = {
                user_type: messageTemplate?.user_type?.map(item => item?.name),
                template_key: messageTemplate?.template_key,
                description: messageTemplate?.description,
                template: messageTemplate?.template,
                template_id: messageTemplate?.template_id,
                template_html: messageTemplate?.template_html,
                redirect_end_point: messageTemplate?.redirect_end_point,
                template_title: messageTemplate?.template_title,
                is_active: true,
                channels: messageTemplate?.channels?.map(item => item?.name),
            };

            if (messageTemplate?.editTemplateFlag) {
                // template key is not required for edit template as it is unique
                delete data?.template_key;

            }

            setLoadingUpdate(true);


            try {
                let res;

                if (messageTemplate?.editTemplateFlag) {

                    res = await sandeshAxiosPatchReq(endPoint, data);

                } else {

                    res = await sandeshAxiosPostReq(endPoint, data);
                }


                if (res?.data?.success) {

                    // let data = res?.data?.data;

                    if (messageTemplate?.editTemplateFlag) {

                        let tempArr = [...allCommunicationData];

                        const findIndex = allCommunicationData?.findIndex(item => item?.id === messageTemplate?.id);

                        let tempObj = {
                            ...messageTemplate,
                            user_type: messageTemplate?.user_type?.map(item => item?.name),
                            channels: messageTemplate?.channels?.map(item => item?.name),

                        }

                        tempArr?.splice(findIndex, 1, tempObj);
                        setAllCommunicationData(tempArr);
                        setMessageTemplate(initialMessageTemplate);

                    }
                    setOpenAddTemplate(false)

                    // for (let i in data) {
                    //     let obj = {};

                    //     obj = {
                    //         ...data[i],

                    //     }
                    //     tempArr.push(obj);
                    // }
                    SuccessAlert(res?.data?.data?.message);

                } else {
                    FailureAlert("Some Error Occured!");
                }

                setLoadingUpdate(false);

            } catch (error) {
                setLoadingUpdate(false);

                throw (error)
            }
        }

    };

    const getMessageTemplate = async () => {

        setLoading(true);

        const endPoint = `/api/admin/message-template/`;

        try {
            const res = await sandeshAxiosGetReq(endPoint);

            if (res?.data?.success) {

                let data = res?.data?.data;

                let tempArr = [];

                for (let i in data) {
                    let obj = {};

                    obj = {
                        ...data[i],
                        // user_type: data[i]?.user_type?.toString(),
                        // channels: data[i]?.channels?.toString(),
                        create_time: new Date(data[i]?.create_time).toLocaleString(),
                        update_time: new Date(data[i]?.update_time).toLocaleString(),

                    }
                    tempArr.push(obj);
                }

                setAllCommunicationData(tempArr);

            } else {
                FailureAlert("Some Error Occured!");
            }

            setLoading(false);

        } catch (error) {

            setLoading(false);

            throw (error)
        }
    }

    useEffect(() => {

        if (allCommunicationData?.length === 0) {

            getMessageTemplate();
        }

    }, []);

    if (loading) {
        return (<>

            <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
        </>)
    }


    return (
        <div>
            <>

                <Box className={classes.heading}>
                    <Button
                        className={classes.btnAdd}
                        variant="primary"
                        onClick={() => {
                            setOpenAddTemplate(true)
                            setMessageTemplate(initialMessageTemplate);
                        }}
                    >
                        + New Template
                    </Button>
                </Box>
                {/* for preventing rendering of the table when sorting selected in headers*/}
                {UpdatedTable}

            </>
            <div>
                <>
                    {

                        open ?
                            <DisplayHtml
                                htmlContent={htmlContent}
                                open={open}
                                templateHeading={templateHeading}
                                setOpen={setOpen}
                            /> : null
                    }
                    {
                        openAddTemplate ?
                            <AddMessageTemplate
                                open={openAddTemplate}
                                setOpen={setOpenAddTemplate}
                                setMessageTemplate={setMessageTemplate}
                                messageTemplate={messageTemplate}
                                handleFormSubmission={postTemplate}
                                loading={loadingUpdate}
                            />
                            : null
                    }

                </>

            </div>


        </div>



    )
}


export default ViewTemplate