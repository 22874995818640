import React, { useState, useEffect } from 'react';
import {apiCall, apiNiyuktiCall} from '../../util/apiCall';
import {Form} from 'react-bootstrap';
import { FailureAlert } from '../../util/Notifications';
import { EditJobSection } from './EditJobSection';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import { requestPraveshOptions, requestNiyuktiOptions } from '../../constants';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../util/copyToClipboard";
import no_college_data from "../../assets/images/no_college_data.png";
import { getMatchingElementInArr } from '../../util/utility';

function EditJob({isClone=false}) {

    const [companyId, setCompanyId] = useState();
    const [jobPostingCompanyId, setJobPostingCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const userData = JSON.parse(localStorage.getItem("user-data"));
    const userId = userData.id;
    const [companyDetails,setCompanyDetails] = useState(null)
    const [postJobCredits, setPostJobCredits] = useState(0);
    const [selectedJobName, setSelectedJobName] = useState({});
    const [paidService, setPaidService] = useState({});
    const [jobId, setJobId] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [data, setData] = useState({});
    const [editView, setEditView] = useState();
      
    let jobIdFromOpenJobs = (new URLSearchParams(window?.location?.search)).get("jobId");
    let companyIdFromOpenJobs = (new URLSearchParams(window?.location?.search)).get("companyId");

    // Fetch all Companies Dropdown
    useEffect(() => {
        apiCall('/api/admin/company/get/', requestPraveshOptions)
        .then((res)=> {
            if(res?.success) {
                setCompanies(res?.data)
            }
            else {
                FailureAlert(res?.error)
            }
        })
    }, []);


    useEffect(() => {
      
      setEditView({jobId:jobIdFromOpenJobs,companyId:companyIdFromOpenJobs});
      
    },[]);

    useEffect(() => {

      if(editView?.jobId && editView?.companyId){

        setCompanyId(parseInt(editView?.companyId));
        setJobId(parseInt(editView?.jobId));
        let matchingCompany = getMatchingElementInArr(parseInt(editView?.companyId),companies,"company_id");
        setCompanyDetails(matchingCompany);
        let matchingJob = getMatchingElementInArr(parseInt(editView?.jobId),jobs,"job_id")
        setSelectedJobName(matchingJob);
        
      }
    },[editView,jobs,companies])

    // Fetch all Jobs using Company ID Dropdown
    useEffect(() => {
        companyId && apiNiyuktiCall(`/job/company/job/list/?company_id=${btoa(companyId.toString())}`, requestNiyuktiOptions)
        .then((res)=> {
            if(res?.success) {
                setJobs(res?.data)
            }
            else FailureAlert(res?.error)
        })
    }, [companyId]);

    useEffect(() => {
      getCreditInfo();
      }, [jobPostingCompanyId]);

    const getCreditInfo = () => {
      if (isClone && jobPostingCompanyId){
        apiCall(`/api/admin/main_user/credit_info/?main_user_type=Company&main_user_id=${jobPostingCompanyId}`, requestPraveshOptions).then((res) => {
          if (res?.success) {
            setData(res?.data);
          } else {
            setData({});
            FailureAlert(res?.error);
          }
        });
      }
    }

      useEffect(() => {
          if(isClone)
        apiCall("/api/admin/paid_services/", requestPraveshOptions).then((json) => {
          if (json.success) {
            for (var i = 0; i < json.data.length; i++) {
              if (json.data[i].service_type === "POST_JOB" && json.data[i].user_type_id === 5) {
                setPostJobCredits(parseFloat(json.data[i].credits_required));
                setPaidService(json.data[i]);
                break;
              }
            }
          } else {
            setPostJobCredits(0);
            setPaidService({});
            FailureAlert(json.error);
          }
        });
      }, []);



    return (
        <div className="post__job">
            <h3 style={{textAlign:'center'}}> <i>{isClone ? "Clone Job" : "Edit Job!"}</i> </h3> 
            <hr /> <br />
            <Form className="post__job__form" style={{display:'flex', flexDirection:'row'}} >
                <Autocomplete
                    id="select-company"
                    options={companies}
                    value={companyDetails}
                    getOptionLabel={(item) => item.company_name}
                    style={{ width: '94%', margin: '1% 3% 2% 3%' }}
                    onChange={(e, item)=> {

                          const newValue = item ? item?.company_id : null
                          const newCompany = item ? item : null
                          setCompanyId(newValue);
                          setCompanyDetails(newCompany)
                          setJobId(null);
                          setSelectedJobName({});
                        }
                    }
                    renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                />
                <Autocomplete
                    id="select-job"
                    options={jobs}
                    getOptionLabel={(item) => item?.job_title}
                    value={selectedJobName}
                    style={{ width: '94%', margin: '1% 3% 2% 3%' }}
                    onChange={(e, item)=> {
                      if(item)
                      {
                        setJobId(item.job_id);
                        setSelectedJobName(item);
                      }else
                      setJobId(null);
                    }}
                    renderInput={(params) => <TextField {...params} label={jobs && jobs.length > 0 ? "Select Job": "No Job"} variant="outlined" />}
                    disabled={jobs && jobs.length > 0 ? false : true}
                />
                
            </Form>
            {isClone && companyId && jobId &&
                <Autocomplete
                    id="select-company"
                    options={companies}
                    getOptionLabel={(item) => item.company_name}
                    style={{ width: '94%', margin: '1% 3% 2% 3%' }}
                    onChange={(e, item)=> {
                          item ? setJobPostingCompanyId(item?.company_id) : setJobPostingCompanyId(null);
                          // setJobId(null);
                          // setSelectedJobName({});
                        }
                    }
                    renderInput={(params) => <TextField {...params} label="Select Posting Company" variant="outlined" />}
                />
                  }
            {
              ((isClone && jobPostingCompanyId) || (!isClone && companyId)) && jobId ?
                <>
                {isClone && 
                <div>
                  <h5 style={{ display: "flex", justifyContent: "space-around" }}>
                    <p>
                      Email_ID : {data.email || ""} {data.email && <FileCopyIcon style={{ cursor: "pointer" }} onClick={(e) => copyToClipboard(data.email)} />}
                    </p>
                    <p>Name : {data.name || ""}</p>
                    <p>Credit Status : {data.credit_status || ""}</p>
                  </h5>

                  <h5 style={{ display: "flex", justifyContent: "space-around" }}>
                    <p> Available credits : {data.available_credits || "0"} </p>
                    <p>Burned credits: {data.burned_credits || "0"}</p>
                    <p>Expired credits : {data.expired_credits || "0"}</p>
                  </h5>
                </div>
              }
          {jobId && (!isClone || data.user_type_id) ? (
            !isClone || data.available_credits && parseFloat(data.available_credits) >= postJobCredits ? (
              !isClone  || data.credit_status === "ACTIVE" ? (
                    <EditJobSection 
                    cmpId = {companyId}
                    jobId = {jobId} 
                    userId={data?.user_id || userId}
                    userTypeId={data?.user_type_id || 5}  
                    amount={parseFloat(paidService.credits_required)}
                    // userTypeId={data.user_type_id}
                    transactionType={paidService.transaction_type}
                    serviceType={paidService.service_type} isClone={isClone} getCreditInfo={getCreditInfo}/>
              ) : (
                <div id="no__credits__data__div">
                  <img src={no_college_data} alt="No Data" id="no__credits__data__image" />
                  <div align="center" id="no__credits__data__div__div">
                    <span>Your account credit status is INACTIVE !!</span> <br />
                    <span>Kindly change credit status for above mentioned Email</span>
                    <span style={{ color: "blue", cursor: "pointer" }} onClick={(e) => window.open("/admin/add-getwork-credits")}>
                      {"  "}here.
                    </span>
                  </div>
                </div>
              )
            ) : (
                <div id="no__credits__data__div">
                  <img src={no_college_data} alt="No Data" id="no__credits__data__image" />
                  <div align="center" id="no__credits__data__div__div">
                    <span>No enough credits to clone Job !!</span> <br />
                    <span>Kindly add credits for above mentioned Email.</span>
                  </div>
                </div>
              )
          ) : (
            <div align="center" id="contact__tech__team__data__div__div">
              <span>Contact Tech Team :</span>
            </div>
          )
              }
              </>
              : 
                    <h4 style={{marginLeft:'2rem'}}>Select Company and Job you wish to {isClone ? "Clone": "Edit"}...</h4>
                    
                }
        </div>
    )
}


export default EditJob;