import { DialogTitle, Typography } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Autocomplete } from "@material-ui/lab";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useState } from 'react';
import { Col, Form } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import crossIcon from "../../../assets/images/cancel.png";
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import CustomButtonCircularProgress from '../../Student/Components/loader';
import { CHANNEL_TYPES, TEMPLATE_USER_TYPES } from '../../../constants';


const useStyles = makeStyles((theme) => ({

    crossStyle: {
        width: "10px",
        height: "10px",
        cursor: "pointer"

    },
    crossSection: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    headingBox: {

        fontWeight: 600,

    },
    floatingLabelFocusStyle: {
        // color: "#7e7e7e",
        fontSize: "14px",
    },
    textfield: {

        maxHeight: '.3rem',

    },
    rowfield: {
        display: 'flex',
        alignItems: "center",
    },
    descriptionBox: {
        minHeight: "4rem",
    },
    editor: {
        minHeight: "4rem",
    },
    addTemplatebtnSection: {
        display: 'flex',
        flexDirection: 'row-reverse'
    }

}))

const AddMessageTemplate = ({ open, setOpen, messageTemplate, setMessageTemplate, handleFormSubmission, loading }) => {

    const classes = useStyles()

    const [scroll, setScroll] = React.useState("paper");
    const [templateHtml, setTemplateHtml] = useState(EditorState.createEmpty());

    const handleClose = () => {
        setOpen(false);

    };
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };


    const onTemplateEditorSTateChange = (state) => {

        setTemplateHtml(state);
        setMessageTemplate({ ...messageTemplate, template_html: draftToHtml(convertToRaw(state.getCurrentContent())) })
    }
    const [selectedUserTypes, setselectedUserTypes] = useState([])
    const [selectedChannelTypes, setselectedChannelTypes] = useState([])

    useEffect(() => {

        setselectedUserTypes(messageTemplate?.user_type ?? []);
        setselectedChannelTypes(messageTemplate?.channels ?? []);
        setTemplateHtml(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(messageTemplate?.template_html || ' ')
            )
        ))

    }, [])


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle>
                <div style={{ display: "flex", justifyContent: "space-between" }}>

                    <Typography variant='h5' className={classes.headingBox}>
                        {messageTemplate?.editTemplateFlag ? "Edit" : "Add"}  Message Template
                    </Typography>


                    <img
                        className={classes.crossStyle}
                        src={crossIcon}

                        onClick={() => { handleClose(); }}
                        alt="close Icon" />
                </div>
            </DialogTitle>

            <DialogContent dividers={scroll === "paper"}>

                <Form
                    id="addTemplate"
                    onSubmit={(e) => {

                    }}
                >
                    <Form.Row className={classes.rowfield}>
                        <Form.Group as={Col} controlId="addTemplate">

                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                id="select-cities"
                                disableCloseOnSelect
                                options={TEMPLATE_USER_TYPES}
                                value={selectedUserTypes}
                                getOptionLabel={(item) => item.name}
                                onChange={(e, item) => {

                                    const item2 = item || [];
                                    setselectedUserTypes(item2);
                                    setMessageTemplate({ ...messageTemplate, user_type: (item2) })

                                }}
                                renderInput={(params) => <TextField {...params} label="User Types" required variant="outlined" />}
                            />

                        </Form.Group>
                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                value={messageTemplate?.template_key}
                                onChange={(e) => {
                                    setMessageTemplate({ ...messageTemplate, template_key: e.target.value })
                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.textfield }}
                                label="Template Key"

                            />

                        </Form.Group>


                    </Form.Row>
                    <Form.Row className={classes.rowfield}>

                        <Form.Group as={Col} controlId="addTemplate">
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                id="select-channel"
                                options={CHANNEL_TYPES}
                                value={selectedChannelTypes}
                                disableCloseOnSelect
                                getOptionLabel={(item) => item.name}
                                onChange={(e, item) => {

                                    const item2 = item || [];
                                    setselectedChannelTypes(item2);
                                    setMessageTemplate({ ...messageTemplate, channels: (item2) })

                                }}
                                renderInput={(params) => <TextField {...params} label="Channels" required variant="outlined" />}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                value={messageTemplate?.template_id}
                                onChange={(e) => {
                                    setMessageTemplate({ ...messageTemplate, template_id: e.target.value })
                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.textfield }}
                                label="Template Id"

                            />
                        </Form.Group>


                    </Form.Row>


                    <Form.Row>

                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                value={messageTemplate?.description}
                                onChange={(e) => {
                                    setMessageTemplate({ ...messageTemplate, description: e.target.value })
                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.descriptionBox }}
                                label="Description"

                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                value={messageTemplate?.template}
                                onChange={(e) => {
                                    setMessageTemplate({ ...messageTemplate, template: e.target.value })
                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.descriptionBox }}
                                label="Template"

                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                value={messageTemplate?.template_title}
                                onChange={(e) => {
                                    setMessageTemplate({ ...messageTemplate, template_title: e.target.value })
                                }}
                                required
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.textfield }}
                                label="Template Title"

                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                value={messageTemplate?.redirect_end_point}
                                onChange={(e) => {
                                    setMessageTemplate({ ...messageTemplate, redirect_end_point: e.target.value })
                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.textfield }}
                                label="Redirect End Point"

                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>

                        <Form.Group as={Col} controlId="addTemplate2">
                            <Form.Label>Template Html</Form.Label>
                            <div className={classes.editor}>
                                <Editor
                                    editorState={templateHtml}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    onEditorStateChange={onTemplateEditorSTateChange}
                                />
                            </div>
                        </Form.Group>

                    </Form.Row>
                </Form>
                <Form.Row className={classes.addTemplatebtnSection}>
                    <Button
                        variant="primary"
                        onClick={() => { handleFormSubmission() }}
                        style={{ minWidth: '8rem' }}
                    >
                        {loading ? <CustomButtonCircularProgress /> : messageTemplate?.editTemplateFlag ? "Edit Template" : "Add Template"}
                    </Button>
                </Form.Row>


            </DialogContent>

        </Dialog>
    )
}

export default AddMessageTemplate

