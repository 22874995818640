import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';

function CollegeSpecializations() {

  const { clg_id } = useParams();
  console.log(useParams())

    const [clgName, setClgName] = useState('');
    const [specializations, setSpecializations] = useState([]);
    const [educations, setEducations] = useState({});   // For Filter purpose
    const [degrees, setDegrees] = useState({});   // For Filter purpose
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall(`/api/admin/college/view/?college_id=${clg_id}`, requestPraveshOptions)
          .then(json => {
            if(json.success) {
                setClgName(json.data.college_name);
                setSpecializations(json.data.degree_specialization);
                setLoading(false);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Education Type API
    useEffect(() => {
        apiCall('/api/education/type/', requestPraveshOptions)
        .then((res)=> {
            let obj={}
            res.forEach((e)=> { obj[e.type] = e.type })
            setEducations(obj)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Degrees Finding API
    useEffect(() => {
        apiCall(`/api/admin/degree/all/`, requestPraveshOptions)
        .then((res)=> {
            let obj = {}
            if(res.success) {
              res.data.forEach((degree)=> { obj[degree.degree_name] = degree.degree_name })
              setDegrees(obj)
            }
            else FailureAlert(res.error)  
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "Education Level", field: "education_type_name",   headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,        filterPlaceholder: 'Education', lookup: educations },
        { title: "Degree_ID",       field: "degree_id",             headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,        filterPlaceholder: 'Degree ID', },
        { title: "Degree",          field: "degree_name",           headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,        filterPlaceholder: 'Degree',    lookup: degrees },
        { title: "Specialization_ID",  field: "specialization_id",  headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Specialization",  field: "specialization_name",   headerStyle: headerCSS, cellStyle: cellCSS },
    ]


    return (
        !loading
        ?
        <div className="college__specializations">
            <TableWithExport 
                title = {`${clgName} Specializations`}
                data = {specializations}
                columns = {columns}
                fName = "Selected_Specializations"
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default CollegeSpecializations;