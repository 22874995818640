import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { praveshAxiosCall } from "../../../../util/apiCall";
import { FailureAlert } from "../../../../util/Notifications";
import { jobCategoryList } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  chipsection: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "0px",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "10px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function AssignCandidatesFilter(props) {
  const classes = useStyles();
  const fullWidth = true;
  const maxWidth = "xs";

  const [selectedJobList, setSelectedJobList] = useState(
    props?.filters?.selectedJobList
  );
  const [selectedCompanyList, setSelectedCompanyList] = useState(
    props?.filters?.selectedCompanyList
  );
  const [selectedCurrentStatusList, setSelectedCurrentStatusList] = useState(
    props?.filters?.selectedCurrentStatusList
  );

  const handleClose = () => {
    props.handleClose();
  };

  const checkValidation = () => {
    // if (
    // ) {
    //   FailureAlert("Please select a date range");
    //   return false;
    // }
    return true;
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props?.open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6">Filters</Typography>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedJobList}
              multiple
              onChange={(e, item) => {
                setSelectedJobList(item);
              }}
              id="role345"
              options={props?.filtersList.jobs}
              getOptionLabel={(option) => `${option.id}-${option.name}`}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={<span style={{ fontSize: 13 }}> Select Jobs </span>}
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedCompanyList}
              multiple
              onChange={(e, item) => {
                setSelectedCompanyList(item);
              }}
              id="role345"
              options={props?.filtersList.company}
              getOptionLabel={(option) => `${option.id}-${option.name}`}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}> Select Companies </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedCurrentStatusList}
              id="role345"
              multiple
              options={props?.filtersList.currentStatus}
              getOptionLabel={(option) => `${option.id}-${option.name}`}
              onChange={(e, item) => {
                setSelectedCurrentStatusList(item);
              }}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={<span style={{ fontSize: 13 }}>Current Status</span>}
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ height: "45px", display: "flex" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ height: "45px", display: "flex" }}
                onClick={() => {
                  if (checkValidation()) {
                    props?.setFilters({
                      ...props?.filters,
                      selectedJobList: selectedJobList,
                      selectedCompanyList: selectedCompanyList,
                      selectedCurrentStatusList: selectedCurrentStatusList,
                    });
                    props?.handleClose();
                  }
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
