import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo } from "react";
import { cellCSS, headerCSS } from "../constants";
import { Button } from "react-bootstrap";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#fae570",
    },
  },
});

function JobTrackBulkApply({
  title,
  tableRef,
  data,
  columns,
  fName,
  pageValue,
  actionArray,
  onSelectionChange,
  selection = true,
  editableObject,
  exportAllData = true,
  cellFixedIndex = 1,
  paging = true,
}) {
  // the no in cellfixedIndex decides for the column to be in the table shown freezed.
  // So we can freeze the respective column from code here.

  useEffect(() => {
    if (columns?.length > 0 && cellFixedIndex) {
      columns[cellFixedIndex].cellStyle = {
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
      };
      columns[cellFixedIndex].headerStyle = {
        position: "sticky",
        left: 0,
        zIndex: 10,
      };
      columns[cellFixedIndex].filterCellStyle = {
        position: "sticky",
        left: 0,
        zIndex: 0,
        backgroundColor: "#fff",
      };
    }
  }, [columns, cellFixedIndex]);

  // Memoize the columns and data to prevent unnecessary re-renders
  const memoizedColumns = useMemo(() => {
    if (false) {
      columns.push({
        title: "Credit_Note(pdf)",
        field: "credit_note",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
        filtering: false,
        searchable: false,
        editable: "never",
        render: (row) => (
          <div>
            {row?.credit_note ? (
              <div style={{ display: "flex" }}>
                <Button
                  variant={"outline-success"}
                  size="sm"
                  style={{
                    fontSize: 13,
                    marginLeft: 5,
                    width: "100px",
                  }}
                  onClick={() => {
                    window.open(row?.credit_note, "_blank");
                  }}
                >
                  View
                </Button>
              </div>
            ) : (
              <Button
                variant={"outline-success"}
                size="sm"
                style={{
                  marginLeft: 5,

                  fontSize: 13,
                  width: "100px",
                }}
                onClick={() => {
                  // handleOpenCreditNoteUpload(row, "Upload");
                }}
              >
                {" "}
                Upload
              </Button>
            )}
          </div>
        ),
      });
    }

    return columns;
  }, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const memoizedActions = useMemo(() => actionArray, [actionArray]);
  const memoizedEditable = useMemo(() => editableObject, [editableObject]);

  // Memoize the onSelectionChange function
  const memoizedOnSelectionChange = useCallback(
    (selectedRows) => {
      onSelectionChange(selectedRows);
    },
    [onSelectionChange]
  );

  return (
    <div className="table__with__export">
      <ThemeProvider theme={theme}>
        <MaterialTable
          title={title}
          tableRef={tableRef}
          data={memoizedData}
          columns={memoizedColumns}
          options={{
            search: false,
            sorting: true,
            selection: selection,
            filtering: true,
            paging: paging,
            pageSize: pageValue || 50,
            pageSizeOptions: [10, 20, 50, 100, 200],
            exportButton: false,
            exportAllData: exportAllData,
            draggable: false,
            headerStyle: {
              position: "sticky",
              top: 0,
              backgroundColor: "#ebf5fa",
            },
            maxBodyHeight: "430px",
            emptyRowsWhenPaging: false,
          }}
          onSelectionChange={memoizedOnSelectionChange}
          actions={memoizedActions}
          editable={memoizedEditable}
          // onSearchChange={(data) => {
          //   onSearchChange(data);
          // }
          // }
          localization={{
            header: {
              actions: "",
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
}

export default JobTrackBulkApply;
