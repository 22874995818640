import {
    Button,
    Dialog,
    DialogContent, Grid, TextField,
    Typography
  } from "@material-ui/core";
  import { makeStyles } from "@material-ui/core/styles";
  import { Autocomplete } from "@material-ui/lab";
  import React, { useEffect, useState } from "react";
  import { DateSelector } from "../../../components/common/DateSelector";
  import { praveshAxiosCall, niyuktiAxiosCall } from "../../../util/apiCall";
  import { INTERVIEW_STATUS} from "../../../constants";
    
    const useStyles = makeStyles((theme) => ({
      form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
      },
      formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
      formControlLabel: {
        marginTop: theme.spacing(1),
      },
      chipsection: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        paddingLeft: "0px",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
      },
      chip: {
        margin: theme.spacing(0.5),
        marginLeft: 0,
      },
      comboOptions: {
        fontSize: "13px",
        padding: "10px 11px",
      },
      fieldStyling: {
        width: "100%",
        // marginBottom: 20,
      },
      fieldStyling1: {
        width: "100%",
        marginBottom: 10,
      },
    }));
    
    export default function AllInterviewsFilter(props) {
      const classes = useStyles();
      const fullWidth = true;
      const maxWidth = "xs";
      const [company, setCompany] = React.useState([]);
      const [recruitersList, setRecruitersList] = useState([]);
      const [jobList, setJobList] = useState([]);
  
      const [dateFilter, setDateFilter] = useState(props?.filters?.advanceFilterDateValue);
      const [selectedCompanyList, setSelectedCompanyList] = useState(props?.filters?.selectedCompanyList);
      const [selectedJobList, setSelectedJobList] = useState(props?.filters?.selectedJobList);
      const [selectedCreatedBy, setSelectedCreatedBy] = useState(props?.filters?.createdBy);
      const [selectedStatus, setSelectedStatus] = useState(props?.filters?.status);
  
  
      useEffect(() => {
          getCompanyList();
          getRecruiterList();
          getJobList();
      }, []);

      const getJobList = () => {
        niyuktiAxiosCall.get(`/job/all_job/list/`).then((res) => {
          if(res?.data?.success){
            setJobList(res?.data?.data);
            for (var i in res?.data?.data){

              if(res?.data?.data[i]?.job_id == props?.filters?.initialJobId){
                setSelectedJobList([res?.data?.data[i]]);
              }
            }
          }
          else{
            
            // setAllApiSuccess({status: -1, message: res.error})
          }
        }).catch(err => {
          // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
        })
      }

      const getRecruiterList = () => {
        praveshAxiosCall.get(`/api/admin/admin_user_lists/`).then((res) => {
          if(res.data.success){
            setRecruitersList(res?.data?.data);
          }
          else{
            
            // setAllApiSuccess({status: -1, message: res.error})
          }
        }).catch(err => {
          // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
        })
      }
      
      const getCompanyList = () => {
        praveshAxiosCall.get(`/api/company/company/filter/`).then((res) => {
          if(res.data.success){
            setCompany(res?.data?.data);
          }
          else{
            
            // setAllApiSuccess({status: -1, message: res.error})
          }
        }).catch(err => {
          // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
        })
      }
  
      const handleClose = () =>{
        props.handleClose()
      }
  
      const checkValidation = () => {
        // if((!dateFilter || dateFilter?.length < 2 || !dateFilter[0] || !dateFilter[1]) && (selectedCompanyList?.length === 0 && selectedPlanList?.length === 0)){
        //     FailureAlert("Please select atleast one company/plan or a date range");
        //     return false;
        // }
        return true;
    }
      
      return (
        <React.Fragment>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={props.open}
            //onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogContent>
              <Typography variant="h6">Advance Filters</Typography>
      
              <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                <Autocomplete
                  value={selectedCompanyList}
                  multiple
                  onChange={(e, item) => {
                    setSelectedCompanyList(item);
                  }}
                  id="role345"
                  options={company}
                  getOptionLabel={(option) =>
                    `${option.id}-${option.name}`
                  }
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option.id}-${option.name}`}</span>
                    );
                  }}
                  className={classes.fieldStyling1}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={<span style={{ fontSize: 13 }}> Select Company </span>}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </div>
  
              <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                <Autocomplete
                  // value={selectedJob}
                  // onChange={(event, newValue) => {
                  //   if (newValue) {
                  //     setSelectedJob(newValue);
                  //   }
                  // }}
                  value={selectedJobList}
                  id="role345"
                  multiple
                  options={jobList}
                  getOptionLabel={(option) => `${option?.job_id} - ${option?.job_title}`}
                  onChange={(e, item) => {
                    setSelectedJobList(item);
                  }}
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option?.job_id} - ${option?.job_title}`}</span>
                    );
                  }}
                  className={classes.fieldStyling1}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={<span style={{ fontSize: 13 }}>Select Job</span>}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </div>
              
              <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <DateSelector
                  dateRange={[props?.startDate, props?.endDate]}
                  setAdvanceFilterDateValue={setDateFilter}
                  advanceFilterDateValue={dateFilter}
                  isAdvanceFilter={true}
                  clearDates={true}
                  justifyContent="space-between"
                  removeDateFilter={() => {
                        setDateFilter([null, null])
                    }}
                    label="Create Date Range"
                />
              </div>

              <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                <Autocomplete
                  // value={selectedJob}
                  // onChange={(event, newValue) => {
                  //   if (newValue) {
                  //     setSelectedJob(newValue);
                  //   }
                  // }}
                  value={selectedCreatedBy}
                  id="role345"
                  multiple
                  options={recruitersList}
                  getOptionLabel={option => `${option?.id}-${option?.admin_name}`}
                  onChange={(e, item) => {
                    setSelectedCreatedBy(item);
                  }}
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option?.id}-${option?.admin_name}`}</span>
                    );
                  }}
                  className={classes.fieldStyling1}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={<span style={{ fontSize: 13 }}>Select Created By</span>}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </div>

              <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                <Autocomplete
                  // value={selectedJob}
                  // onChange={(event, newValue) => {
                  //   if (newValue) {
                  //     setSelectedJob(newValue);
                  //   }
                  // }}
                  value={selectedStatus}
                  id="role345"
                  // multiple
                  options={INTERVIEW_STATUS}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, item) => {
                    setSelectedStatus(item);
                  }}
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option.name}`}</span>
                    );
                  }}
                  className={classes.fieldStyling1}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={<span style={{ fontSize: 13 }}>Select Interview Status</span>}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </div>
  
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={6} style={{ display: "grid" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    style={{ height: "45px", display: "flex" }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ display: "grid" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: "45px", display: "flex" }} 
                    onClick={() => {
                      if(checkValidation()){
                        props?.setFilters({...props?.filters, 
                          selectedCompanyList: selectedCompanyList, 
                          selectedJobList: selectedJobList, 
                          advanceFilterDateValue: dateFilter,
                          createdBy: selectedCreatedBy,
                          status: selectedStatus,
                          initialJobId: null,
                          page: 1
                        })
                        props?.handleClose();
                      }
                    }}
                  >
                      Apply
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      );
    }
    