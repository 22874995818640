import { Button, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import generateCsv from "../../util/generateCsv";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";
import AddCategoryDialog from "./AddCategoryDialog";

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "2px",
    marginBottom: "10px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const token = localStorage.getItem("token");



function AllClubCategory(props) {
  const classes = useStyles();
  const [userCreatedColleges, setUserCreatedCollges] = useState(props.userCreated ? 1 : 0);

  const [loading, setLoading] = useState(false);

  const [currentRow, setCurrentRow] = useState(null);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(null);
  const columns = [
    {
      title: "Category Name",
      field: "title",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Category",
      render: (row) => (
        <div
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => {
            handleClickOpen();
            setCurrentRow(row);
            setEdit(true);
          }}
        >
          {row.title} &nbsp; &nbsp; &nbsp;
          <EditIcon style={{ cursor: "pointer" }} />
        </div>
      ),
    },
    {
      title: "Segment Name",
      field: "segment_name_list",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Segment"
    },
    {
      title: "Status",
      field: "is_active",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Status",
      render: (row) => <>{row?.is_active ? <div style={{ color: "green", cursor: "pointer" }}>Active</div> : <div style={{ color: "red", cursor: "pointer" }}>InActive</div>}</>,
    },
  ];

  useEffect(() => {
    if (edit && currentRow) {
      setCategory(currentRow?.title);
      setSegment(currentRow?.segment_id_list);
      setStatus(currentRow?.is_active ? "ACTIVE" : "INACTIVE");
    }
  }, [edit, currentRow]);

  // const edit = {
  //   onRowUpdate: (newData, oldData) =>
  //     new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         const dataUpdate = [...dataCategory];
  //         const index = oldData.tableData.id;
  //         dataUpdate[index] = newData;
  //         updateRow(newData);
  //         setDataCategory([...dataUpdate]);
  //         resolve();
  //       }, 1000);
  //     }),
  // };
  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/category/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            var arr = [];
            item.segment.forEach((item2) => {
              arr.push(item2?.segment_id);
            });
            item["segment_name_list"] = generateCsv(item.segment, "segment_name");
            item["segment_id_list"] = arr;
          });
          setDataCategory(json);
        } else FailureAlert("Link History was not fetched. Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [category, setCategory] = useState(null);
  const [segment, setSegment] = useState([]);
  const addCategory = () => {
    const requestOptionsPatch = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        title: category,
        segment: segment,
        is_active: status === "ACTIVE" ? true : false,
      }),
    };
    apiCall(`/api/getwork_club/category/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const updateCategory = (id) => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        title: category,
        segment: segment,
        is_active: status === "ACTIVE" ? true : false,
      }),
    };
    apiCall(`/api/getwork_club/category/${id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
        setCategory("");
        setSegment([]);
        setEdit(false);
        setCurrentRow(null);
        setStatus(null);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  return (
    <div className="all__colleges">

      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <>
          {/* <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}> */}
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{ minWidth: "145px" }}
                  color="primary"
                  onClick={() => {
                    handleClickOpen();
                  }}
                  startIcon={<AddIcon className={classes.icon} />}
                >
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>
          <TableWithExport title="Categories" columns={columns} data={dataCategory} fName="Selected_Colleges_Not_User_Created" />
          {/* </TabPanel> */}
        </>
      ) : (
        <></>
        // <TabPanel value={userCreatedColleges} index={1} dir={theme.direction}>
        //   <Grid container spacing={3} className={classes.action}>
        //     <Grid
        //       item
        //       xs={10}
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} />
        //     </Grid>
        //     <Grid item xs={2}>
        //       <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //         <Button variant="contained" color="primary" style={{ minWidth: "145px" }} startIcon={<AddIcon className={classes.icon} />}>
        //           Add New
        //         </Button>
        //       </div>
        //     </Grid>
        //   </Grid>
        //   <TableWithExport title="All Instructor" columns={seccolumns} data={secdata} fName="Selected_Colleges_User_Created" editableObject={edit} />
        // </TabPanel>
      )}

      <AddCategoryDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        category={category}
        setCategory={setCategory}
        addCategory={addCategory}
        segment={segment}
        setSegment={setSegment}
        updateCategory={updateCategory}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        edit={edit}
        setEdit={setEdit}
        status={status}
        setStatus={setStatus}
      />
    </div>
  );
}

export default withRouter(AllClubCategory);
