import React, { useState, useEffect } from 'react';
import '../../css/AccountManager.css';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, createIconStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import { Select, MenuItem } from "@material-ui/core";
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';
import {Autocomplete} from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';


const token = localStorage.getItem('token');

function AccountManagerCompany() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [managers, setManagers] = useState([]);
    const [managersFilter, setManagersFilter] = useState([]);

    const [newCompany, setNewCompany] = useState(null);
    const [newUser, setNewUser] = useState(null);
    const [newDate, setNewDate] = useState('');
    const [newActive, setNewActive] = useState(true);

    const [patchCompany, setPatchCompany] = useState(null);
    const [patchUser, setPatchUser] = useState(null);

    // Fetch List of all managers
    useEffect(() => {
        apiCall('/api/admin/account_manager/user/', requestPraveshOptions)
        .then((res)=> { 
            if(res.success) {
                setManagers(res.data)
                let obj = {}
                res.data.forEach((user)=> { obj[`${user.first_name} ${user.last_name}`] = `${user.first_name} ${user.last_name}`} )
                setManagersFilter(obj)
            }
        })
    }, []);

    // Fetch all Company list
    useEffect(() => {
        apiCall('/api/company/company_list/', requestPraveshOptions)
        .then((res)=> { setCompanies(res.data.all_companies) })
    }, []);


    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/account_manager/company/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    for(var j=0; j < json.data[i].managers.length; j++) {
                        let obj = {
                            company_id:    json.data[i].company_id,
                            company_name:  json.data[i].company_name,
                            manager_id:    json.data[i].managers[j].manager_id,
                            user_id:       json.data[i].managers[j].user_id,
                            manager_name:  json.data[i].managers[j].first_name + ' ' + json.data[i].managers[j].last_name,
                            email:         json.data[i].managers[j].email,
                            phone:         json.data[i].managers[j].phone,
                            date:          json.data[i].managers[j].date,
                            is_active:     json.data[i].managers[j].is_active ? 'Yes' : 'No',
                        }
                        tmpArray.push(obj)
                    }
                }
                setData(tmpArray);
                setLoading(false);
            }
            else {
                FailureAlert(json.error)
            }    
          })
    }, [count]);

    const columns = [
        { title: "Company_Name",  field: "company_name",  headerStyle: headerCSS, cellStyle: cellCSS,
          editComponent: props => (
            <Select
                onChange={e=> {setPatchCompany(JSON.parse(e.target.value).id)
                    props.onChange(JSON.parse(e.target.value).company)
                } }
            >
            {
                companies.map((m, key) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(m)} >
                        {m.company}
                    </MenuItem>
                )})
            }
            </Select>
          )
        },
        { title: "Manager Name",  field: "manager_name", headerStyle: headerCSS, cellStyle: cellCSS,
          lookup: managersFilter, searchable: false,     filterPlaceholder: 'Select',
          editComponent: props => (
            <Select
                onChange={e=> {setPatchUser(JSON.parse(e.target.value).user_id)
                    props.onChange(JSON.parse(e.target.value).first_name + " " + JSON.parse(e.target.value).last_name)
                } }
            >
            {
                managers.map((m, key) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(m)} >
                        {`${m.first_name} ${m.last_name}`}
                    </MenuItem>
                )})
            }
            </Select>
          )
        },
        { title: "Email",    field: "email",         headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never',
          render: row => row.email ? 
            <div>{row.email} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.email)} />
            </div> : null
        },
        { title: "Phone",    field: "phone",         headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never',
          render: row => row.phone ? 
            <div>{row.phone} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.phone)} />
            </div> : null
        },
        { title: "Date",     field: "date",          headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'date',      searchable: false,      filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.date.substring(0, 10) },
        { title: "Active",   field: "is_active",     headerStyle: headerCSS, cellStyle: cellCSS,
          lookup: {'Yes':'Yes', 'No':'No'},          filterPlaceholder: 'Yes/No',
          editComponent: props => (
            <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
            </Select>
          ) 
        },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                user : patchUser || newData.user_id,
                company: patchCompany || newData.company_id,
                ...((newData.date instanceof Date) && {assign_date: newData.date.toISOString().substring(0,10)} ),
                is_active: newData.is_active==="Yes" ? true : false,
            })
        };
        apiCall(`/api/admin/account_manager/company/${newData.manager_id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setPatchUser(null); setPatchCompany(null);
                setCount(count+1);
            }
            else { FailureAlert(res.error); }
        })
    }

    const addAccountManager = (e) => {
        e.preventDefault();
        if(newUser===null) { FailureAlert("Select Manager") }
        if(newCompany===null) { FailureAlert("Select Company") }
        const requestOptionsPost = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                user : newUser,
                company: newCompany,
                assign_date: newDate,
                is_active: newActive
            })
        };
        apiCall('/api/admin/account_manager/company/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                window.document.getElementById('comp__account__manager__form__details').reset();
                setNewUser(null); setNewCompany(null);
                setCount(count+1);
            }
            else { FailureAlert(res.error); }
        })
      }

    return (
        !loading
        ?
        <div className="account__manager">
            <div className="account__manager__form">
                <h4>Add New Account Manager</h4>
                <Form id="comp__account__manager__form__details" onSubmit={e=>addAccountManager(e)}>
                    <Form.Row>
                        <Form.Group as={Col} className="typeSearchManagerName">
                        <Autocomplete
                            id="Manager Name"
                            options={managers}
                            getOptionLabel={(item) =>`${item.first_name} ${item.last_name}`}
                            onChange={(e, item)=>item ? setNewUser(item.user_id) : setNewUser(null)}
                            renderInput={(params) =>
                                <TextField {...params} label="Manager Name*" variant="outlined" 
                                style={{ display:'flex' }} />}
                        />
                        </Form.Group>
                       <Form.Group as={Col} className="typeSearchAutoCompleteCompany">
                            <Autocomplete
                            id="company"
                            options={companies}
                            getOptionLabel={(item) => `${item.company}`}
                            onChange={(e, item)=>item ? setNewCompany(item.id) : setNewCompany(null)}
                            renderInput={(params) =>
                                <TextField {...params} label="Select Company*" variant="outlined" />}
                        />
                        </Form.Group>                
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="assignDate">
                            <Form.Label>Assign Date*</Form.Label>
                            <Form.Control 
                            name="assignDate" type="date" placeholder="YYYY-MM-DD"
                            required onChange = {e=> {setNewDate(e.target.value)}}
                        />
                        </Form.Group>
                        <Form.Group as={Col} controlId="isactive">
                            <Form.Label>Active*</Form.Label>
                            <Form.Control 
                                as="select" required
                                onChange={e=> {setNewActive(e.target.value)} }
                            >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                   <Button type="submit" variant="info">Add Manager</Button>
                    
                </Form>    
            </div>
            <TableWithExport 
                title = "Account Managers"
                data = {data}
                columns = {columns}
                fName = "Selected Account Managers"
                editableObject = {edit} 
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default AccountManagerCompany;