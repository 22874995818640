import { Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useParams } from "react-router-dom";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import "../../css/Miscellaneous.css";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { uniqueArray } from "../../util/uniqueArray";

const token = localStorage.getItem("token");

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    borderBottom: "3px solid  #008080",
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    border: "1px solid #b0b6ba",
    //marginBottom: "1%",
    borderRadius: "10px",
    padding: "10px",
  },
  editingtext: {
    color: "#b0b7c3",
    fontStyle: "italic",
    fontWeight: "normal",
  },
  btn: {
    backgroundColor: "#17a2b8",
    boxShadow: "none",
    color: "#fff",
    marginTop: "10px",
  },
}));

const BlackRadio = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "#000",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function AllRewards() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const [rewardDetails, setRewardDetails] = useState({
    service: null,
    vendor: null,
    category: null,
    sub_category: [],
    type: null,
    title: null,
    value: 0,
    coupon_codes: [],
    description: null,
    redemption: null,
    expiry: null,
    terms_conditions: null,
    image: null,
    coupon_code: null,
    temp_image: null,
    url: null,
  });
  const handleRadioChange = (event) => {
    setRewardDetails({ ...rewardDetails, type: event.target.value });
  };

  const [dataCompany, setDataCompany] = useState([]);

  const getCompanies = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/vendors/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          var new_arr = [];
          json.forEach((item) => {
            new_arr.push({ id: item?.id, company_name: item?.company_name });
          });
          setDataCompany(new_arr);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };
  
  useEffect(() => {
    getCompanies();
    // getProducts();
  }, []);

  useEffect(() => {
    if (params?.coupon_id) getSingleCouponData();
  }, [params?.coupon_id]);

  const [getCouponData, setGetCouponData] = useState(null);

  const getSingleCouponData = () => {
    setLoading(true);
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/coupons/?coupon_id=${params?.coupon_id}`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          // json.forEach((item) => {
          //   item["codes"] = generateCsvNew(item.codes);
          // });
          setGetCouponData(json);
        } else FailureAlert(" Some error occurred");
        setLoading(false);
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (getCouponData) {
      setRewardDetails({
        ...rewardDetails,
        category: getCouponData?.category_id ? { id: getCouponData?.category_id, title: getCouponData?.category_name } : null,
        vendor: getCouponData?.vendor_id ? { id: getCouponData?.vendor_id, company_name: getCouponData?.vendor_name } : null,
        sub_category: getCouponData?.sub_category,
        type: getCouponData?.type,
        title: getCouponData?.title,
        value: getCouponData?.value,
        coupon_code: getCouponData?.code,
        description: getCouponData?.description,
        redemption: getCouponData?.redemption,
        expiry: getCouponData?.expiry?.slice(0, 10),
        terms_conditions: getCouponData?.terms_conditions,
        temp_image: getCouponData?.coupon_image,
        url: getCouponData?.coupon_url ? getCouponData?.coupon_url : null,
      });
      //DESCRIPTION
      if (getCouponData?.description) {
        const html = getCouponData?.description;
        const blocksFromHTML = convertFromHTML(html);
        const content = ContentState.createFromBlockArray(blocksFromHTML);
        setEditorState(EditorState.createWithContent(content));
      }
      //REDEMPTION
      if (getCouponData?.redemption) {
        const html_redeem = getCouponData?.redemption;
        const blocksRedeemFromHTML = convertFromHTML(html_redeem);
        const content_redeem = ContentState.createFromBlockArray(blocksRedeemFromHTML);
        setRedemptionState(EditorState.createWithContent(content_redeem));
      }
      //TERMS CONDITIONS
      if (getCouponData?.terms_condition) {
        const html_terms = getCouponData?.terms_condition;
        const blocksTermsFromHTML = convertFromHTML(html_terms);
        const content_terms = ContentState.createFromBlockArray(blocksTermsFromHTML);
        setTermsState(EditorState.createWithContent(content_terms));
      }
    }
  }, [getCouponData]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [redemptionState, setRedemptionState] = useState(EditorState.createEmpty());
  const [termsState, setTermsState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (s) => {
    setEditorState(s);
    setRewardDetails({ ...rewardDetails, description: draftToHtml(convertToRaw(s.getCurrentContent())) });
  };

  const onEditorStateChangeRedeem = (s) => {
    setRedemptionState(s);
    setRewardDetails({ ...rewardDetails, redemption: draftToHtml(convertToRaw(s.getCurrentContent())) });
  };

  const onEditorStateChangeTerms = (s) => {
    setTermsState(s);
    setRewardDetails({ ...rewardDetails, terms_conditions: draftToHtml(convertToRaw(s.getCurrentContent())) });
  };

  const handleSubmit = () => {
    if (!rewardDetails?.category) {
      FailureAlert("PLease Enter Category");
      return;
    }
    if (!rewardDetails?.sub_category) {
      FailureAlert("PLease Enter Sub Category");
      return;
    }
    if (!rewardDetails?.type) {
      FailureAlert("Please Enter Coupon Type");
      return;
    }
    if (!rewardDetails?.title) {
      FailureAlert("Please Enter Coupon Title");
      return;
    }
    if (!rewardDetails?.url) {
      FailureAlert("Please Enter Coupon URL");
      return;
    }
    if (!rewardDetails?.value) {
      FailureAlert("Please Enter Coupon Value");
      return;
    }
    // if (rewardDetails?.coupon_codes?.length === 0) {
    //   FailureAlert("Please Enter at least one coupon code");
    //   return;
    // }
    if (!rewardDetails?.coupon_code) {
      FailureAlert("Please Enter coupon code");
      return;
    }
    if (!rewardDetails?.expiry) {
      FailureAlert("Please Enter Expiry Date");
      return;
    }
    if (!rewardDetails?.terms_conditions) {
      FailureAlert("Please Enter Terms and Conditions");
      return;
    }
    if (params?.coupon_id) updateData();
    else addCoupons();
  };

  const addCoupons = () => {
    var arr = [];
    rewardDetails?.sub_category.forEach((item) => {
      arr.push(item?.id);
    });
    const fd = new FormData();
    fd.append("vendor", rewardDetails?.vendor?.id);
    if (rewardDetails?.category) fd.append("category", rewardDetails?.category?.id);
    if (rewardDetails?.sub_category) fd.append("sub_category", JSON.stringify(arr));
    fd.append("title", rewardDetails?.title);
    fd.append("type", rewardDetails?.type);
    fd.append("value", rewardDetails?.value);
    // fd.append("codes", JSON.stringify(rewardDetails?.coupon_codes));
    fd.append("code", rewardDetails?.coupon_code);

    fd.append("description", rewardDetails?.description);
    fd.append("redemption", rewardDetails?.redemption);
    fd.append("expiry", rewardDetails?.expiry + "T00:00:00");
    fd.append("terms_condition", rewardDetails?.terms_conditions);
    fd.append("coupon_url", rewardDetails?.url);

    if (rewardDetails?.image) fd.append("coupon_image", rewardDetails?.image);
    const requestOptionsPatch = {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(`/api/getwork_club/coupons/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const updateData = () => {
    var arr = [];
    rewardDetails?.sub_category.forEach((item) => {
      arr.push(item?.id);
    });
    const fd = new FormData();
    if (rewardDetails?.category) fd.append("category", rewardDetails?.category?.id);
    if (rewardDetails?.sub_category) fd.append("sub_category", JSON.stringify(arr));
    fd.append("title", rewardDetails?.title);
    fd.append("type", rewardDetails?.type);
    fd.append("value", rewardDetails?.value);
    fd.append("code", rewardDetails?.coupon_code);

    fd.append("description", rewardDetails?.description);
    fd.append("redemption", rewardDetails?.redemption);
    fd.append("expiry", rewardDetails?.expiry + "T00:00:00");
    fd.append("terms_condition", rewardDetails?.terms_conditions);
    fd.append("coupon_url", rewardDetails?.url);

    if (rewardDetails?.image) fd.append("coupon_image", rewardDetails?.image);
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(`/api/getwork_club/coupons/${params?.coupon_id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const [dataCategory, setDataCategory] = useState([]);

  const getCategories = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/category/?is_active=1&segment_id=3`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          var arr = [];
          json.forEach((item) => {
            arr.push({ id: item?.id, title: item?.title });
          });
          setDataCategory(arr);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };
  const [dataSubCategory, setDataSubCategory] = useState([]);
  const getSubCategory = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/sub_category/?category_id=${rewardDetails?.category?.id}`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataSubCategory(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (rewardDetails?.category) getSubCategory();
  }, [rewardDetails?.category]);
  return !loading ? (
    <>
      <div className="education__skils">
        <div className="education__skils__form">
          <div className={classes.heading}>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Rewards/Coupon
            </Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <Divider style={{ backgroundColor: "#008080", padding: "1.1px" }} />
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  options={dataCompany}
                  getOptionLabel={(item) => item.company_name}
                  style={{ width: "50%" }}
                  onChange={(e, item) => {
                    if (item) {
                      console.log(item);
                      setRewardDetails({ ...rewardDetails, vendor: item });
                    } else {
                      setRewardDetails({ ...rewardDetails, vendor: null });
                    }
                  }}
                  value={rewardDetails?.vendor}
                  renderInput={(params) => <TextField {...params} label="Select Vendor" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={dataCategory}
                  getOptionLabel={(item) => item.title}
                  style={{ width: "50%" }}
                  onChange={(e, item) => {
                    if (item) {
                      console.log(item);
                      setRewardDetails({ ...rewardDetails, category: item });
                    } else {
                      setRewardDetails({ ...rewardDetails, category: null });
                    }
                  }}
                  value={rewardDetails?.category}
                  renderInput={(params) => <TextField {...params} label="Select Category" variant="outlined" />}
                />
              </Grid>
              {rewardDetails?.category?.id && (
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    options={dataSubCategory}
                    getOptionLabel={(item) => item.title}
                    style={{ width: "50%" }}
                    onChange={(e, item) => {
                      if (item) {
                        setRewardDetails({ ...rewardDetails, sub_category: uniqueArray(item) });
                      } else {
                        setRewardDetails({ ...rewardDetails, sub_category: [] });
                      }
                    }}
                    value={rewardDetails?.sub_category}
                    renderInput={(params) => <TextField {...params} label="Select Sub Categories" variant="outlined" />}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Coupon Title"
                  size="small"
                  variant="outlined"
                  value={rewardDetails?.title}
                  onChange={(e) => {
                    setRewardDetails({ ...rewardDetails, title: e.target.value });
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Coupon Url"
                  size="small"
                  variant="outlined"
                  value={rewardDetails?.url}
                  onChange={(e) => {
                    setRewardDetails({ ...rewardDetails, url: e.target.value });
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Coupon Type
                  </Typography>
                  <RadioGroup aria-label="quiz" name="quiz" value={rewardDetails?.type} onChange={handleRadioChange} row>
                    <FormControlLabel style={{ marginBottom: "0px" }} value="NUMERIC" control={<BlackRadio />} label="Numeric" />
                    <FormControlLabel style={{ marginBottom: "0px" }} value="PERCENTAGE" control={<BlackRadio />} label="Percentage" />
                  </RadioGroup>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Coupon Value"
                  size="small"
                  variant="outlined"
                  value={rewardDetails?.value}
                  onChange={(e) => {
                    setRewardDetails({ ...rewardDetails, value: e.target.value });
                  }}
                />
              </Grid>
              <br />
              {/* <Grid item xs={12}>
                <Typography variant="body2" style={{ fontWeight: "600" }}>
                  Add Coupons
                </Typography>
              </Grid> */}
              <Grid item xs={6}>
                <TextField
                  style={{ width: "80%" }}
                  label="Coupon Code"
                  size="small"
                  variant="outlined"
                  value={rewardDetails?.coupon_code}
                  onChange={(e) => {
                    setRewardDetails({ ...rewardDetails, coupon_code: e.target.value });
                  }}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Button
                  style={{ marginLeft: "0px" }}
                  startIcon={<AddCircleOutlineIcon color="primary" />}
                  variant="text"
                  color="primary"
                  onClick={() => {
                    if (currentCoupon) {
                      var arr = [...rewardDetails?.coupon_codes];
                      arr.push(currentCoupon);
                      setRewardDetails({ ...rewardDetails, coupon_codes: arr });
                    } else {
                      FailureAlert("Please Enter Coupon Code");
                    }
                  }}
                >
                  Add
                </Button>{" "}
              </Grid> */}
              {/* {rewardDetails?.coupon_codes?.length > 0 &&
                rewardDetails?.coupon_codes?.map((item, index) => (
                  <>
                    <Grid item xs={3}>
                      {" "}
                      <TextField style={{ width: "100%" }} label="" size="small" variant="outlined" value={item} disabled />{" "}
                    </Grid>
                    <Grid item xs={1}>
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          var arr = [...rewardDetails?.coupon_codes];
                          arr.splice(index, 1);
                          setRewardDetails({ ...rewardDetails, coupon_codes: arr });
                        }}
                      />
                    </Grid>
                  </>
                ))} */}
              <br />
              <br />
              {/* <Grid item xs={12}>
                <Button style={{ marginLeft: "0px" }} startIcon={<AddCircleOutlineIcon color="primary" />} variant="text" color="primary">
                  Add New Coupo
                </Button>
              </Grid> */}
              <Grid item xs={12}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    id="date"
                    label="Expiry Date"
                    type="date"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={rewardDetails?.expiry}
                    onChange={(e) => {
                      setRewardDetails({ ...rewardDetails, expiry: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Description <span className={classes.editingtext}> (Maximum 10 Words)</span>
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      How to Redeem?
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={redemptionState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateChangeRedeem}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Terms & Conditions
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={termsState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateChangeTerms}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Upload Image
                  </Typography>
                </div>
                <div style={{ marginBottom: 10 }}>
                  {rewardDetails?.temp_image && !rewardDetails?.image && (
                    <>
                      <img src={rewardDetails?.temp_image} alt="" height="200px" />
                    </>
                  )}
                </div>

                <Form.File id="collegeBanner">
                  <Form.File.Input onChange={(e) => setRewardDetails({ ...rewardDetails, image: e.target.files[0] })} />
                  <p style={{ color: "#6c757d", marginBottom: "0px" }}>Recommended Size: 150px*150px</p>
                </Form.File>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ backgroundColor: "#008080", padding: "1.1px" }} />
              </Grid>
            </Grid>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              {params?.coupon_id ? "Edit Coupon Details" : "Add Coupon Details"}
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default AllRewards;
