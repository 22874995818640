import React, { useEffect, useState } from "react";
import { TabPanel } from "../../util/TabPanel";
import PropTypes from "prop-types";
import { Form, Col, Button } from "react-bootstrap";
import AddNewCollegePocDetails from "./AddNewCollegePocDetails";
import { useTheme } from "@material-ui/core/styles";

import {
  AppBar,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  Box,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import YearMonthPicker from "../common/YearMonthPicker";
import moment from "moment";

function AddNewCollegeBasicInfo({
  formData,
  setFormData,
  Countries,
  Universities,
  states,
  cities,
  isonboard,
  universityNotListed,
  setUniversityNotListed,isSaas,
}) {
  const [value, setValue] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [countryId, setCountryId] = useState(1);
  const [aboutCount, setAboutCount] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [institutionType, setInstitutionType] = useState("College");

  const [universityId, setUniversityId] = useState();
  const [universityName, setUniversityName] = useState("");
  const MAX_CHARACTER_ABOUT = 256;
  const MAX_CHARACTER_DESC = 512;
  return (
    <>
      <div style={{ padding: "30px" }}>
        <Form
          className="college__basic__info__form"
          id="college__basic__info__form"
        >
          <Form.Row>
            <Form.Group as={Col} controlId="collegedetails">
              <Form.Label>College Name*</Form.Label>
              <Form.Control
                name="collegename"
                type="text"
                placeholder={"College Name*"}
                value={formData.collegename}
                onChange={(event) =>
                  setFormData({ ...formData, collegename: event.target.value })
                }
              />
            </Form.Group>
            {isonboard === "yes" && (
              <Form.Group as={Col} controlId="collegedetails">
                <Form.Label>College Nick Name{isSaas ? "*" : ''}</Form.Label>
                <Form.Control
                  name="collegenickname"
                  type="text"
                  placeholder={`Nick Name${isSaas ? "*" : ''}`}
                  value={formData.collegenickname}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      collegenickname: event.target.value,
                    })
                  }
                />
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="collegedetails">
              <Form.Label>Complete College Address*</Form.Label>
              <Form.Control
                required
                name="collegeaddress"
                type="text"
                placeholder={"College Complete Address*"}
                value={formData.collegeaddress}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    collegeaddress: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Label>Select Organization Type* </Form.Label>
          <Form.Group type="radio" id="organizationType" required>
            <Form.Check
              inline
              type="radio"
              name="organizationType"
              id="government"
              label="Government"
              value="Government"
              checked={
                formData.organizationType === "Government" ? true : false
              }
              onChange={(event) =>
                setFormData({
                  ...formData,
                  organizationType: event.target.value,
                })
              }
            />
            <Form.Check
              inline
              type="radio"
              name="organizationType"
              id="semiGovernment"
              label="Semi Private"
              value="SemiPrivate"
              checked={
                formData.organizationType === "SemiPrivate" ? true : false
              }
              onChange={(event) =>
                setFormData({
                  ...formData,
                  organizationType: event.target.value,
                })
              }
            />
            <Form.Check
              inline
              type="radio"
              name="organizationType"
              id="private"
              label="Private"
              value="Private"
              checked={formData.organizationType === "Private" ? true : false}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  organizationType: event.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Row>
            {isonboard === "yes" && (
              <Form.Group as={Col} controlId="collegedetails">
                <Form.Label>Year Founded</Form.Label>
                <YearMonthPicker
                  label="Year Founded"
                  selectedDate={formData?.yearfounded}
                  onDateChange={(date) => {
                    setFormData({
                      ...formData,
                      yearfounded: moment(date).format("YYYY"),
                    });
                  }}
                />
              </Form.Group>
            )}

            {isonboard === "yes" && (
              <Form.Group as={Col} controlId="size">
                <Form.Label>Student Count</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.studentcount}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      studentcount: event.target.value,
                    })
                  }
                >
                  <option value="">Student Count*</option>
                  <option value="1-10">1-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-500">101-500</option>
                  <option value="501-1000">501-1000</option>
                  <option value="1000+">1000+</option>
                </Form.Control>
              </Form.Group>
            )}
            <Form.Group as={Col} controlId="collegedetails">
              <Form.Label>College Website Url*</Form.Label>
              <Form.Control
                required
                name="collegewebsite"
                type="text"
                placeholder={"College Website Url*"}
                value={formData.website}
                onChange={(event) =>
                  setFormData({ ...formData, website: event.target.value })
                }
              />
            </Form.Group>
          </Form.Row>

          {isonboard === "yes" && (
            <>
              <Form.Row>
                <Form.Group as={Col} controlId="collegedetails">
                  <Form.Label
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>College Description*</div>
                    <div>
                      {
                        <i
                          style={{
                            fontSize: "13px",
                            color: "green",
                            paddingRight: "10px",
                          }}
                        >
                          {`${
                            formData?.description
                              ? formData?.description?.length
                              : 0
                          }/${MAX_CHARACTER_DESC}`}
                        </i>
                      }
                    </div>
                  </Form.Label>
                  <Form.Control
                    required={true}
                    name="collegedescription"
                    as="textarea"
                    rows="5"
                    placeholder={"College Description*"}
                    maxLength={MAX_CHARACTER_DESC}
                    value={formData.description}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        description: event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="collegedetails">
                  <Form.Label
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>About*</div>
                    <div>
                      {
                        <i
                          style={{
                            fontSize: "13px",
                            color: "green",
                            /* textAlign: "right",
                            paddingLeft: "30px" */
                          }}
                        >
                          {`${
                            formData?.about ? formData?.about?.length : 0
                          }/${MAX_CHARACTER_ABOUT}`}
                        </i>
                      }
                    </div>
                  </Form.Label>
                  <Form.Control
                    name="aboutcollege"
                    as="textarea"
                    rows="5"
                    placeholder="About Your College*"
                    maxLength={MAX_CHARACTER_ABOUT}
                    value={formData.about}
                    onChange={(event) =>
                      setFormData({ ...formData, about: event.target.value })
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Label>Grade System* : </Form.Label>
              <Form.Group type="radio" id="gradesystem" required>
                <Form.Check
                  inline
                  type="radio"
                  name="gradesystem"
                  id="cgpa"
                  label="CGPA"
                  value="cgpa"
                  checked={formData.gradesystem === "cgpa" ? true : false}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      gradesystem: event.target.value,
                    })
                  }
                />
                <Form.Check
                  inline
                  type="radio"
                  name="gradesystem"
                  id="percentage"
                  label="Percentage"
                  value="percentage"
                  checked={formData.gradesystem === "percentage" ? true : false}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      gradesystem: event.target.value,
                    })
                  }
                />
                {formData.gradesystem === "cgpa" ? (
                  <Form.Group controlId="percentageFactor">
                    <Form.Label>Percentage Factor* : </Form.Label>
                    <Form.Control
                      name="percentageFactor"
                      type="number"
                      placeholder={"Enter Percentage Factor"}
                      value={formData?.percentageFactor}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          percentageFactor: event.target.value,
                        })
                      }
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Form.Group>
              {institutionType === "College" ? (
                <Form.Group controlId="universityName">
                  {!universityNotListed ? (
                    <div className="typeSearchUniversity">
                      <Autocomplete
                        id="select-university"
                        name="selectuniversity"
                        options={Universities}
                        getOptionLabel={(item) => item.university_name}
                        value={formData?.university}
                        onChange={(e, item) => {
                          setFormData({ ...formData, university: item });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Select University${isSaas ? "*" : ''}`}
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <Form.Control
                      name="universityName"
                      type="text"
                      placeholder={`University Name${isSaas ? "*" : ''}`}
                      required
                      value={formData.universityname}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          universityname: event.target.value,
                        })
                      }
                    />
                  )}
                  <Form.Check
                    type="checkbox"
                    label="University Not Listed"
                    onChange={(e) =>
                      setUniversityNotListed(!universityNotListed)
                    }
                    checked={universityNotListed}
                    style={{ marginLeft: "20px" }}
                  />
                </Form.Group>
              ) : null}

              <Form.Group controlId="linkedInPage">
                <Form.Label>LinkedIn Url</Form.Label>
                <Form.Control
                  name="linkedInPage"
                  type="text"
                  placeholder={"LinkedIn Page Url"}
                  value={formData.linkedInPage}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      linkedInPage: event.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="facebookPage">
                <Form.Label>Facebook Url</Form.Label>
                <Form.Control
                  name="facebookPage"
                  type="text"
                  placeholder={"Facebook Page Url"}
                  value={formData.facebookPage}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      facebookPage: event.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="instaAccount">
                <Form.Label>Instagram Url</Form.Label>
                <Form.Control
                  name="instaAccount"
                  type="text"
                  placeholder={"Instagram Account Url"}
                  value={formData.instaAccount}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      instaAccount: event.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Row className="typeSearchLocation">
                <Form.Group as={Col} controlId="country">
                  <Autocomplete
                    id="select-country"
                    options={Countries}
                    getOptionLabel={(item) => item.country}
                    value={formData?.country}
                    onChange={(e, item) =>
                      setFormData({ ...formData, country: item })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Country*"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="state">
                  <Autocomplete
                    id="select-state"
                    options={states}
                    getOptionLabel={(item) => item.state}
                    value={formData?.state}
                    onChange={(e, item) =>
                      setFormData({ ...formData, state: item })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select State*"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="city">
                  <Autocomplete
                    id="select-city"
                    options={cities}
                    getOptionLabel={(item) => item.city}
                    value={formData?.city}
                    onChange={(e, item) =>
                      setFormData({ ...formData, city: item })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select City*"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="collegedetails">
                  <Form.Label>Lattitude{isSaas ? "*" : ''}</Form.Label>
                  <Form.Control
                    name="lattitude"
                    type="text"
                    placeholder={"Lattitude"}
                    value={formData.lattitude}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        lattitude: event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="collegedetails">
                  <Form.Label>Longitude{isSaas ? "*" : ''}</Form.Label>
                  <Form.Control
                    name="longitude"
                    type="text"
                    placeholder={"Longitude"}
                    value={formData.longitude}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        longitude: event.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form.Row>
            </>
          )}
        </Form>
      </div>
    </>
  );
}
export default AddNewCollegeBasicInfo;
