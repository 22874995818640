import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import {Form} from 'react-bootstrap';
import { FailureAlert } from '../../util/Notifications';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import { requestPraveshOptions } from '../../constants';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';
import SalesJobResponseSection from './SalesJobResponseSection';

function SalesJobResponse() {
    const [companyId, setCompanyId] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [contactName, setContactName] = useState(null);
    const [contactEmail, setContactEmail] = useState(null);
    const [companies, setCompanies] = useState({});
    const [data, setData] = useState([]);

    const userData = JSON.parse(localStorage.getItem('user-data'));
    const userName = `${userData.first_name}_${userData.last_name}`; 

    // Fetch all Companies Dropdown
    useEffect(() => {
        apiCall('/api/admin/company/get/', requestPraveshOptions)
        .then((res)=> {
            if(res.success) {
                setCompanies(res.data)
            }
            else {
                FailureAlert(res.error)
            }
        })
    }, [companyId]);

    useEffect(() => {
        if(!companyId)
            return 
        apiCall(`/api/admin/main_user/credit_info/?main_user_type=Company&main_user_id=${companyId}`, requestPraveshOptions)
        .then((res)=> {
            if(res.success) {
                setData(res.data)
                setContactEmail(res.data.email)
                setContactName(res.data.name)
            }
            else {
                setData({})
                FailureAlert(res.error)
            }
        })
    }, [companyId]);

    return (
        <div className="post__job">
            <Form className="post__job__form" >
                <Autocomplete
                    id="select-user"
                    options={companies}
                    getOptionLabel={(item) => item.company_name}
                    style={{ width: '90%', margin: '2% 5%' }}
                    onChange={(e, item)=> {
                        if (item) {
                            setCompanyId(item.company_id)
                            setCompanyName(item.company_name)
                        }
                        else setCompanyId(null);
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                />
            </Form>
            {
                !companyId ? 
                    <h3 style={{marginLeft:'2rem'}}>Select Company to Post a New Job Response...</h3>
                    : 
                    <div>
                    <h5 style = {{display:'flex', justifyContent:'space-around'}}>
                       <p>
                            Email_ID : {data.email || '' } {' '}
                            { 
                                data.email && 
                                <FileCopyIcon style={{cursor:'pointer'}} onClick={e=>copyToClipboard(data.email)} /> 
                            }
                        </p>
                       <p>Name : {data.name || '' }</p>
                       <p>Credit Status : {data.credit_status || ''}</p>
                    </h5>
                    
                    <h5 style = {{display:'flex', justifyContent:'space-around'}}>
                      <p> Available credits : {data.available_credits || '0' } </p>
                       <p>Burned credits: {data.burned_credits || '0' }</p>
                       <p>Expired credits : {data.expired_credits || '0' }</p>
                    </h5>
                        <SalesJobResponseSection 
                            cmpId = {companyId} 
                            companyName= {companyName}
                            contactEmail={contactEmail}
                            contactName={contactName}
                            userName={userName}
                        />
                    </div>
            }
        </div>
    )
}

export default SalesJobResponse;