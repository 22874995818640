import React, { useState, useEffect, Fragment } from "react";
import "../../css/PostJobSection.css";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import {
  work_experiences,
  gifStyling,
  requestPraveshOptions,
  requestNiyuktiOptions,
} from "../../constants";
import { Form, Col, Button } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SuccessAlert, FailureAlert } from "../../util/Notifications";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import gif from "../../assets/loopingcircles-3.gif";
import line from "../../assets/lineOr.png";
import NumberFormat from "react-number-format";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { jobCategoryList } from "../../constants";

const useStyles = makeStyles({
  grid1: {
    marginTop: "20px",
    fontWeight: "bolder",
    paddingLeft: "7%",
    paddingTop: "4%",
  },
  grid2: {
    marginTop: "20px",
  },
  shadowBox: {
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    padding: "5px 0 5px 20px",
    borderRadius: "4px",
  },
  innerBox: {
    padding: "5px 0 5px 30px",
  },
  radio: {
    "&$checked": {
      color: "#3282C4",
    },
    "&:hover": {
      backgroundColor: "rgba(50, 130, 196, 0.04)",
    },
  },
  newLabel: {
    color: "white",
    fontWeight: "bolder",
  },
  checked: {},
});

export function SalesJobResponseSection({
  cmpId,
  companyName,
  contactName,
  contactEmail,
  userName,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const initialJobDetails = {
    jobCategory: "",
  };

  const [jobDetails, setJobDetails] = useState(initialJobDetails);
  const [jobTitle, setJobTitle] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(null);
  const [jobTypeName, setJobTypeName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ppoOffered, setPpoOffered] = useState(true);
  const [description, setDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [vacancy, setVacancy] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [jobPaymentType, setJobPaymentType] = useState("");
  const [jobTmc, setJobTmc] = useState("");
  const [jobTmcEditor, setJobTmcEditor] = useState(EditorState.createEmpty());
  const [minCtc, setMinCtc] = useState(null);
  const [maxCtc, setMaxCtc] = useState(null);
  const [allCities, setAllCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [allowRemote, setAllowRemote] = useState(false);
  const [workFromHome, setWorkFromHome] = useState(false);
  const [isAiAssessment, setIsAiAssessment] = useState(null);

  const [activeYears, setActiveYears] = useState([]);
  const [eligibleYears, setEligibleYears] = useState([]);
  const [minPercentage, setMinPercentage] = useState(null);
  const [allSkills, setAllSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  // const [workExp, setWorkExp] = useState('');
  const [minWorkExp, setMinWorkExp] = useState("");
  const [maxWorkExp, setMaxWorkExp] = useState("");
  const [citiesState, setCitiesState] = useState({});
  const [allRounds, setAllRounds] = useState([]);
  const [selectedRounds, setSelectedRounds] = useState([]);
  const [workLocationModes, setWorkLocationModes] = useState({
    wfh: "",
    hybrid: "",
    wfo: "",
  });

  const [isOffCampus, setIsOffCampus] = useState(false);
  const [offCampusAllCourses, setOffCampusAllCourses] = useState({});
  const [isOffCampusAllCourses, setIsOffCampusAllCourses] = useState(false);

  // Finding All courses + Specializations
  useEffect(() => {
    setLoading(true);
    apiCall(
      `/api/education/job/get_specialization`,
      requestPraveshOptions
    ).then((json) => {
      if (json.success) {
        var map = {};
        for (let i = 0; i < json.data.length; i++) {
          var degree_name = json.data[i].degree.name;
          if (!map[degree_name]) {
            map[degree_name] = [];
            map[degree_name]["select_all"] = false;
          }
          let s = {};
          s["isChecked"] = false;
          s["degree"] = json.data[i].degree;
          s["specialization"] = json.data[i].specialization;
          map[degree_name].push(s);
        }
        setOffCampusAllCourses(map);
      } else FailureAlert(json.error);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Job Types
  useEffect(() => {
    apiNiyuktiCall("/job/type/", requestNiyuktiOptions).then((json) => {
      if (json.success) setJobTypes(json.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Active Years
  useEffect(() => {
    apiCall("/api/admin/active_year/", requestPraveshOptions).then((json) => {
      if (json.success) setActiveYears(json.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Job Skills
  useEffect(() => {
    apiCall("/api/education/skills", requestPraveshOptions).then((json) => {
      if (json?.success) setAllSkills(json?.data?.skills);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Job Rounds
  useEffect(() => {
    apiNiyuktiCall("/job/round/", requestNiyuktiOptions).then((json) => {
      if (json.success) setAllRounds(json.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities
  // useEffect(() => {
  //   apiCall("/api/location/city/", requestPraveshOptions).then((json) => {
  //     setAllCities(json);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Finding Cities
  useEffect(() => {
    apiCall("/api/location/city_state/", requestPraveshOptions).then((json) => {
      setCitiesState(json.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditorStateChange = (s) => {
    setEditorState(s);
    setDescription(draftToHtml(convertToRaw(s.getCurrentContent())));
  };

  // term & condition (tmc) editor state change function
  const jobTmcEditorState = (s) => {
    setJobTmcEditor(s);
    setJobTmc(draftToHtml(convertToRaw(s.getCurrentContent())));
  };

  // OFF CAMPUS
  const handleSelectAllOffCampusCourses = () => {
    var key = !isOffCampusAllCourses;
    var temp = { ...offCampusAllCourses };
    var dict = Object.keys(offCampusAllCourses);
    dict.forEach((item) => {
      temp[item].select_all = key;
      var len = temp[item].length;
      for (let i = 0; i < len; i++) {
        temp[item][i].isChecked = key;
      }
    });
    setIsOffCampusAllCourses(key);
    setOffCampusAllCourses(temp);
  };

  // OFF CAMPUS
  const handleSelectSingleOffCampusSpecialization = (item, course) => {
    var temp = { ...offCampusAllCourses };
    var len = offCampusAllCourses[course].length;
    var allSpecializationsChecked = true;
    for (let i = 0; i < len; i++) {
      if (item.specialization.id === temp[course][i].specialization.id)
        temp[course][i].isChecked = !temp[course][i].isChecked;
    }
    for (let i = 0; i < len; i++) {
      if (!temp[course][i].isChecked) {
        allSpecializationsChecked = false;
        setIsOffCampusAllCourses(false);
        break;
      }
    }
    temp[course].select_all = allSpecializationsChecked;
    var dict = Object.keys(temp);
    var flag = true;
    dict.forEach((item) => {
      if (!temp[item].select_all) {
        flag = false;
      }
    });
    setIsOffCampusAllCourses(flag);
    setOffCampusAllCourses(temp);
  };

  // OFF CAMPUS
  const selectAllOffCampusSpecializations = (degree_name) => {
    var temp = { ...offCampusAllCourses };
    var key = !temp[degree_name].select_all;
    var len = temp[degree_name].length;
    for (let i = 0; i < len; i++) {
      temp[degree_name][i].isChecked = key;
    }
    temp[degree_name].select_all = key;
    var dict = Object.keys(temp);
    var flag = true;
    dict.forEach((item) => {
      if (!temp[item].select_all) {
        flag = false;
      }
    });
    setIsOffCampusAllCourses(flag);
    setOffCampusAllCourses(temp);
  };

  const [loadingButton, setLoadingButton] = useState(false);
  // CREATE A NEW JOB
  const addNewJob = (e) => {
    e.preventDefault();
    if (!jobTitle) {
      FailureAlert("Please fill job title...");
      return;
    }
    if (!jobTypeId) {
      FailureAlert("Choose Job Type...");
      return;
    }
    if (!description) {
      FailureAlert("Description Cannot be empty...");
      return;
    }
    if (!vacancy) {
      FailureAlert("Vacancy Cannot be empty...");
      return;
    }
    if (!jobPaymentType) {
      FailureAlert("Choose Job Payment Type...");
      return;
    }
    if (!jobTmc) {
      FailureAlert("Job terms and conditions Cannot be empty...");
      return;
    }
    if (isPaid && minCtc > maxCtc) {
      FailureAlert("Salary Range is Incorrect...");
      return;
    }
    if (
      !allowRemote &&
      (workLocationModes?.hybrid || workLocationModes?.wfo) &&
      selectedCities.length <= 0
    ) {
      FailureAlert("Select atleast one City to continue...");
      return;
    }
    if (isAiAssessment === null) {
      FailureAlert("Select Auto AI Assessment field...");
      return;
    }
    if (selectedSkills.length <= 0) {
      FailureAlert("Select atleast one Skill to continue...");
      return;
    }
    if (!minWorkExp || !maxWorkExp) {
      FailureAlert("Select Work Experience to continue...");
      return;
    }
    if (parseInt(minWorkExp) > parseInt(maxWorkExp)) {
      FailureAlert("Select correct range for work Experience...");
      return;
    }

    let offCampusSpecializations = [];
    let offCampusDegrees = [];
    var dict = Object.keys(offCampusAllCourses);
    dict.forEach((item) => {
      let len = offCampusAllCourses[item].length;
      let flag = false;
      for (let i = 0; i < len; i++) {
        if (offCampusAllCourses[item][i].isChecked) {
          if (!flag) {
            offCampusDegrees.push(offCampusAllCourses[item][i].degree);
            flag = true;
          }
          offCampusSpecializations.push(
            offCampusAllCourses[item][i].specialization
          );
        }
      }
    });

    let skillsArray = [];
    selectedSkills.forEach((skill) => {
      let obj = {
        type: skill.type,
        skill_id: skill.skill_id,
        skill_name: skill.skill_name,
        skill_type: skill.skill_type,
        rating_name: "Expert",
        rating_value: 5,
        user_created: skill.user_created,
      };
      skillsArray.push(obj);
    });

    let roundsArray = [];
    let roundCount = 1;

    selectedRounds.forEach((round) => {
      let obj = {
        round_id: round.id,
        round_no: roundCount++,
        r_name: round.name,
        is_interviewed: true,
      };
      roundsArray.push(obj);
    });

    const jobObject = [
      {
        company_id: cmpId,
        job_title: jobTitle,
        job_type: jobTypeId,
        work_experience: `${minWorkExp}-${maxWorkExp} years`,
        work_from_home:
          workLocationModes?.wfh || workLocationModes?.hybrid ? 1 : 0,
        skills_required: skillsArray,
        job_description: description,
        vacancy: parseInt(vacancy),
        job_payment_type: jobPaymentType,
        job_tmc: jobTmc,
        minimum_percentage: minPercentage,
        eligible_graduation_year: eligibleYears.map((ey) => ey.year),
        round: roundsArray,
        auto_ai_assessment: isAiAssessment,
        courses_allowed: {
          degrees: offCampusDegrees,
          courses: offCampusSpecializations,
        },
        status: "PENDING",
        ctc_min: minCtc,
        ctc_max: maxCtc,
        hiring_type: isOffCampus ? "OFF CAMPUS" : "ON CAMPUS",
        reference_source: "Others",
        utm_source: userName,
        utm_medium: "direct",
        utm_campaign: "operations_existing",
        job_category: jobDetails?.jobCategory?.id
          ? jobDetails?.jobCategory?.id
          : null,
        extra_data: {
          ppo: jobTypeName === "Internship" ? ppoOffered : false,
          city_id: null,
          state_id: null,
          city_name: null,
          state_name: null,
          salary_type: isPaid ? "PAID" : "UNPAID",
          allow_remote: allowRemote,
          company_name: companyName,
          contact_name: contactName,
          contact_email: contactEmail,
          contact_phone: null,
          company_website: null,
          job_duration_end: endDate,
          job_duration_start: startDate,
          salary_payment_type:
            jobTypeName === "Internship" ? "PER MONTH" : "PER ANNUM",
        },
        ...(!allowRemote && { location: selectedCities }),
        ...(allowRemote && { location: [] }),
      },
    ];
    setLoadingButton(true);

    const requestForJobCreation = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ job_detail: jobObject }),
    };

    apiNiyuktiCall(`/job/public_job_posting/`, requestForJobCreation).then(
      (res) => {
        if (res.success) {
          SuccessAlert(res.data.message);

          // window.location.reload();
        } else FailureAlert(res.error);
        setLoadingButton(false);
      }
    );
  };

  useEffect(() => {
    if (jobTypeName === "Internship" && ppoOffered) setIsPaid(true);
  }, [jobTypeName, ppoOffered]);

  useEffect(() => {
    if (!ppoOffered) setIsPaid(false);
  }, [ppoOffered]);

  return !loading ? (
    <div className="post__job" style={{ width: "96%", marginLeft: "2%" }}>
      <h2 style={{ textAlign: "center" }}>
        {" "}
        <i>Post Job Response!</i>{" "}
      </h2>
      <hr /> <br />
      <Form
        className="post__job__form"
        id="post__job__form__details"
        onSubmit={(e) => {
          addNewJob(e);
        }}
      >
        {/* BASICS */}
        <>
          <h4>Basics</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          <Form.Row>
            <Form.Group as={Col} controlId="jobCategory">
              <Form.Label>Job Category</Form.Label>

              <Autocomplete
                id="combo-box-demo"
                options={jobCategoryList}
                value={jobDetails?.jobCategory}
                getOptionLabel={(item) => item?.name}
                onChange={(e, newValue) => {
                  setJobDetails((prevData) => ({
                    ...prevData,
                    jobCategory: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label=""
                    variant="outlined"
                    style={{
                      backgroundColor: "white",
                    }}
                  />
                )}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="jobTitle">
              <Form.Label>Title*</Form.Label>
              <Form.Control
                name="jobTitle"
                type="text"
                placeholder="Job Title goes here...*"
                required
                onChange={(e) => {
                  setJobTitle(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>

          {/* Job Types, Start Date and End Date */}
          <Form.Row>
            <Form.Group as={Col} controlId="jobType">
              <Form.Label>Job Type*</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setJobTypeId(JSON.parse(e.target.value).id);
                  setJobTypeName(JSON.parse(e.target.value).job_type);
                }}
              >
                <option
                  value={JSON.stringify({ id: null, job_type: "--select--" })}
                >
                  --select--
                </option>
                {jobTypes.map((e, key) => {
                  return (
                    <option key={key} value={JSON.stringify(e)}>
                      {" "}
                      {e.job_type}{" "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="startDate">
              <Form.Label>Start Date*</Form.Label>
              <Form.Control
                name="startDate"
                type="date"
                placeholder="YYYY-MM-DD"
                required
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Form.Group>
            {jobTypeName === "Internship" ? (
              <Form.Group as={Col} controlId="endDate">
                <Form.Label>End Date*</Form.Label>
                <Form.Control
                  name="endDate"
                  type="date"
                  placeholder="YYYY-MM-DD"
                  min={startDate ? startDate : ""}
                  required
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
            ) : null}
          </Form.Row>

          {/* PPO offered only in case of Internship JOB TYPE */}
          {jobTypeName === "Internship" ? (
            <Form.Group type="radio" id="ppoOffered" required>
              <Form.Label style={{ marginRight: "20px" }}>
                PPO Offered* :{" "}
              </Form.Label>
              <Form.Check
                inline
                checked={ppoOffered}
                name="ppoOffered"
                type="radio"
                label="Yes"
                onChange={(e) => {
                  setPpoOffered(!ppoOffered);
                }}
              />
              <Form.Check
                inline
                checked={!ppoOffered}
                name="ppoOffered"
                type="radio"
                label="No"
                onChange={(e) => {
                  setPpoOffered(!ppoOffered);
                }}
              />
            </Form.Group>
          ) : null}
        </>
        {/* DETAILS */}
        <>
          <h4>Details</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />

          {/* Job TERMS and CONDITIONS (tmc) */}
          <Form.Label> Job terms and conditions </Form.Label>
          <div className="editor">
            <Editor
              editorState={jobTmcEditor}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={jobTmcEditorState}
            />
          </div>
          {/* Job Description */}
          <Form.Label>
            Job Description {"  "}
            <span style={{ color: "green" }}>
              (will be pre-filled on the basis of Job Role Selected !!)
            </span>
          </Form.Label>
          <div className="editor">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={onEditorStateChange}
            />
          </div>
          {/* No. of positions open */}
          <Form.Group id="job__vacancies">
            <Form.Label>Expected No. of vacancies:*</Form.Label>
            <Form.Control
              name="jobVacancies"
              type="number"
              min={0}
              max={500}
              onWheel={() => document.activeElement.blur()}
              required
              onChange={(e) => {
                setVacancy(e.target.value);
              }}
            />
            <span id="vacancy__message">
              <i>
                (This number can be approximate and will not be displayed to
                students)
              </i>
            </span>
          </Form.Group>
          {/* Job PAYMENT Type */}
          <Form.Group type="radio" id="jobPaymentType" required>
            <Form.Label style={{ marginRight: "20px" }}>
              Job Payment Type:*
            </Form.Label>
            <Form.Check
              inline
              checked={jobPaymentType === "PAID"}
              name="jobPaymentType"
              type="radio"
              label="Paid"
              onChange={(e) => {
                setJobPaymentType("PAID");
              }}
            />
            <Form.Check
              inline
              checked={jobPaymentType === "UNPAID"}
              name="jobPaymentType"
              type="radio"
              label="Unpaid"
              onChange={(e) => {
                setJobPaymentType("UNPAID");
              }}
            />
          </Form.Group>
          {/* Paid Salary */}
          <Form.Group type="radio" id="isPaid" required>
            <Form.Label style={{ marginRight: "20px" }}>
              Is Job Paid ?* :{" "}
            </Form.Label>
            <Form.Check
              inline
              // checked={isPaid}
              name="isPaid"
              type="radio"
              value="paid"
              label="Salary Paid"
              onChange={(e) => {
                if (e?.target?.value === "paid") {
                  setIsPaid(true);
                }
              }}
            />
            <Form.Check
              inline
              // checked={!isPaid}
              name="isPaid"
              type="radio"
              value="unpaid"
              label="Salary Unpaid"
              onChange={(e) => {
                if (e?.target?.value === "paid") {
                  setIsPaid(false);
                }
                setIsPaid(!isPaid);
              }}
            />
          </Form.Group>
          {/* CTC */}
          {isPaid ? (
            <Form.Row id="is__paid__row">
              <Form.Label>
                {" "}
                {jobTypeName === "Full Time Job"
                  ? "Annual Salary:*"
                  : "Monthly Stipend"}{" "}
              </Form.Label>
              <Form.Group as={Col}>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  required
                  name="minCTC"
                  type="text"
                  placeholder="Minimum (in rupees)*"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setMinCtc(floatValue);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  required
                  name="maxCTC"
                  type="text"
                  placeholder="Maximum (in rupees)*"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setMaxCtc(floatValue);
                  }}
                />
              </Form.Group>
            </Form.Row>
          ) : null}
          {/* Service Bond */}
          <Form.Row>
            <Form.Group as={Col} type="radio" id="serviceBond">
              <Form.Label style={{ marginRight: "20px" }}>
                Work Mode*:
              </Form.Label>
              <Form.Check
                inline
                checked={workLocationModes?.wfh}
                name="wfh"
                type="radio"
                label="Work From Home Only"
                onChange={(e) => {
                  setWorkFromHome(true);
                  setWorkLocationModes({
                    hybrid: false,
                    wfh: true,
                    wfo: false,
                  });
                }}
              />
              <Form.Check
                inline
                checked={workLocationModes?.hybrid}
                name="hybrid"
                value={workLocationModes?.hybrid}
                type="radio"
                label="Hybrid"
                onChange={(e) => {
                  setWorkLocationModes({
                    hybrid: true,
                    wfh: false,
                    wfo: false,
                  });
                }}
              />
              <Form.Check
                inline
                checked={workLocationModes?.wfo}
                name="wfo"
                type="radio"
                label="Work From Office only"
                onChange={(e) => {
                  setWorkLocationModes({
                    hybrid: false,
                    wfh: false,
                    wfo: true,
                  });
                  setWorkFromHome(false);
                }}
              />
            </Form.Group>
          </Form.Row>

          {/* Location for Posting */}
          {(!workFromHome || workLocationModes?.hybrid) && (
            <div id="hiring__location">
              <Form.Label>Where are you hiring for this position? *</Form.Label>
              <Form.Group>
                {!allowRemote ? (
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-cities"
                    options={citiesState}
                    getOptionLabel={(option) =>
                      `${option.city}, ${option.state}`
                    }
                    onChange={(e, item) =>
                      item ? setSelectedCities(item) : setSelectedCities([])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Cities"
                        variant="outlined"
                      />
                    )}
                  />
                ) : null}
                <Form.Check
                  type="checkbox"
                  onChange={(e) => setAllowRemote(!allowRemote)}
                  checked={allowRemote}
                  label="Remote Applications"
                  style={{ marginLeft: "4%" }}
                />
              </Form.Group>
            </div>
          )}

          {/* Job Lake */}
        </>
        {/* PREFERENCES */}
        <>
          <h4>Preferences</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* Skills */}
          <div id="eligible__degrees">
            <Form.Label>Choose Skills* :</Form.Label>
            <Form.Group>
              <Autocomplete
                multiple
                filterSelectedOptions
                id="select-job-skills"
                options={allSkills}
                getOptionLabel={(item) => item.skill_name}
                onChange={(e, item) =>
                  item ? setSelectedSkills(item) : setSelectedSkills([])
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Job Skills Required"
                    variant="outlined"
                  />
                )}
              />
            </Form.Group>
          </div>
          {/* Work Experience */}
          <Form.Row id="work__experience">
            <Form.Label id="work__experience__label">
              Work Experience* :
            </Form.Label>
            <Form.Group as={Col}>
              <Form.Label>Min Work Experience* :</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setMinWorkExp(JSON.parse(e.target.value).value);
                }}
              >
                <option value={JSON.stringify({ name: "", value: "" })}>
                  --Min Experience--
                </option>
                {work_experiences.map((e, key) => {
                  return (
                    <option key={key} value={JSON.stringify(e)}>
                      {" "}
                      {e.name}{" "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Max Work Experience* :</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setMaxWorkExp(JSON.parse(e.target.value).value);
                }}
              >
                <option value={JSON.stringify({ name: "", value: "" })}>
                  --Max Experience--
                </option>
                {work_experiences.map((e, key) => {
                  return (
                    <option key={key} value={JSON.stringify(e)}>
                      {" "}
                      {e.name}{" "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {/* Eligible Years */}
          <div id="eligible__years">
            <Form.Label>Eligible Graduation Years(Optional)</Form.Label>
            <Form.Group>
              <Autocomplete
                multiple
                filterSelectedOptions
                id="select-eligible-years"
                options={activeYears.sort((a, b) => a.year - b.year)}
                getOptionLabel={(item) => String(item.year)}
                onChange={(e, item) =>
                  item ? setEligibleYears(item) : setEligibleYears([])
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Eligible Graduation Years"
                    variant="outlined"
                  />
                )}
              />
            </Form.Group>
          </div>
          {/* Minimum Percentage Required Throughout */}
          <Form.Group id="min__percentage">
            <Form.Label>Minimum Percentage (optional):</Form.Label>
            <Form.Control
              type="number"
              min={1}
              max={100}
              onWheel={() => document.activeElement.blur()}
              placeholder="Minimum Percentage Required"
              onChange={(e) => setMinPercentage(e.target.value)}
            />
          </Form.Group>
        </>
        {/* SELECTION CRITERIA */}
        <>
          <h4>Selection Criteria</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* Auto AI Assessment */}
          <Form.Group type="radio" id="jobPaymentType" required>
            <Form.Label style={{ marginRight: "20px" }}>
              Auto AI Assessment:*
            </Form.Label>
            <Form.Check
              inline
              checked={isAiAssessment === true}
              name="isAiAssessment"
              type="radio"
              label="Yes"
              onChange={(e) => {
                setIsAiAssessment(true);
              }}
            />
            <Form.Check
              inline
              checked={isAiAssessment === false}
              name="isAiAssessment"
              type="radio"
              label="No"
              onChange={(e) => {
                setIsAiAssessment(false);
              }}
            />
          </Form.Group>
          {/* Rounds */}
          <div id="job__rounds">
            <Form.Label>Rounds(Optional)</Form.Label>
            <Form.Group>
              <Autocomplete
                multiple
                filterSelectedOptions
                id="select-rounds"
                options={allRounds}
                getOptionLabel={(item) => item.name}
                onChange={(e, item) =>
                  item ? setSelectedRounds(item) : setSelectedRounds([])
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Rounds in Order"
                    variant="outlined"
                  />
                )}
              />
            </Form.Group>
          </div>
        </>
        {/* COLLEGES */}
        <>
          <h4>Colleges</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* College/ Off Campus */}
          <Form.Group type="radio" id="isOffCampus" required>
            <Form.Label style={{ marginRight: "20px" }}>
              How do you want to hire?* :{" "}
            </Form.Label>
            <Form.Check
              inline
              checked={isOffCampus}
              name="isOffCampus"
              type="radio"
              label="Open To All"
              onChange={(e) => {
                setIsOffCampus(!isOffCampus);
              }}
            />
            <Form.Check
              inline
              checked={!isOffCampus}
              name="isOffCampus"
              type="radio"
              label="GetWork Verified Colleges"
              onChange={(e) => {
                setIsOffCampus(!isOffCampus);
              }}
            />
          </Form.Group>

          <div id="all__courses__available">
            {Object.keys(offCampusAllCourses).length > 0 && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <FormLabel className={classes.grid1} component="legend">
                      {" "}
                      <i>Courses* :</i>{" "}
                    </FormLabel>
                  </Grid>
                  <Grid
                    id="offCampusSelectAllCoursesSpan"
                    item
                    xs={9}
                    className={classes.grid2}
                  >
                    <FormControlLabel
                      label="Select All Courses"
                      control={
                        <Checkbox
                          name="checkboxes"
                          style={{ marginLeft: "10%" }}
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          onChange={handleSelectAllOffCampusCourses}
                          checked={isOffCampusAllCourses}
                        />
                      }
                    />
                    <img src={line} alt="OR Line" width="100%" />
                    <br />
                    <br />
                    {Object.keys(offCampusAllCourses).map((course, index) => {
                      return (
                        <Fragment key={index}>
                          <Box className={classes.shadowBox}>
                            <Grid container spacing={3}>
                              <Grid
                                id="offCampusSelectAllSpecializationsSpan"
                                item
                                xs={10}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="checkboxes"
                                      checked={
                                        offCampusAllCourses[course].select_all
                                      }
                                      onChange={(e) =>
                                        selectAllOffCampusSpecializations(
                                          course
                                        )
                                      }
                                      classes={{
                                        root: classes.radio,
                                        checked: classes.checked,
                                      }}
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  }
                                  label={course}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton
                                  color="primary"
                                  aria-label="add to shopping cart"
                                  data-toggle="collapse"
                                  data-target={`#${course}`}
                                  style={{ cursor: "pointer", border: "none" }}
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <div className="collapse" id={course}>
                              <Box
                                className={classes.innerBox}
                                id="offCampusSingleCourses"
                              >
                                {offCampusAllCourses[course].map((i, index) => (
                                  <Fragment key={index}>
                                    {i.specialization ? (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="checkboxes"
                                            checked={i.isChecked}
                                            onChange={(e) =>
                                              handleSelectSingleOffCampusSpecialization(
                                                i,
                                                course
                                              )
                                            }
                                            inputProps={{
                                              "aria-label": "primary checkbox",
                                            }}
                                            classes={{
                                              root: classes.radio,
                                              checked: classes.checked,
                                            }}
                                          />
                                        }
                                        label={i.specialization.name}
                                      />
                                    ) : null}
                                    <br />
                                  </Fragment>
                                ))}
                              </Box>
                            </div>
                          </Box>
                          <br />
                        </Fragment>
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </>

        <Button
          type="submit"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 4px",
            marginLeft: "40%",
            width: "152px",
            height: "36px",
            marginTop: "15px",
            padding: "0.35rem 1.25rem",
            paddingBottom: "2.2rem",
            borderRadius: "4px",
            color: "rgb(255, 255, 255)",
            background: "#2b9424",
            fontWeight: 500,
            fontSize: "20px",
            border: "none",
          }}
        >
          {loadingButton ? (
            <CircularProgress
              size={15}
              style={{ color: "white", fontSize: 15 }}
            />
          ) : (
            "Post Job"
          )}
        </Button>
        <br />
        <br />
      </Form>
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default SalesJobResponseSection;
