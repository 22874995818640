import {
    Button
} from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportToExcel = ({ apiData, fileName, handleClose }) => {
// debugger
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
                variant="contained"
                color="secondary"
                style={{ height: "45px", display: "flex" }} 
                onClick={() => {
                    exportToCSV(apiData, fileName);
                    handleClose();
                    
                }}
              >
                  Export
              </Button>
    
  );
};