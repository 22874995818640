import { headerCSS, cellCSS } from "../../constants";
import { Button } from "@material-ui/core";

export const commonColumns = [
  {
    title: "Job Tittle",
    field: "jobTitle",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div
        style={{
          fontWeight: row?.fontWeight ? row?.fontWeight : "400",
          width: "100px",
          color: "#2563EB",
        }}
        onClick={() =>
          window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
        }
      >
        {row.jobTitle}
      </div>
    ),
  },
  {
    title: "Company Name",
    field: "companyName",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.companyName}
      </div>
    ),
  },
  {
    title: "Candidate name",
    field: "candidateName",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Mobile Number",
    field: "number",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Current Stage",
    field: "stage",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Ageing",
    field: "ageing",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "HR Name",
    field: "hrName",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },

  {
    title: "Resume",
    field: "resume",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div
        style={{
          cursor: "pointer",
          color: "#0509ff",
        }}
      >
        <Button variant="outlined" color="inherit">
          View
        </Button>
      </div>
    ),
  },
];

export const teamColumns = [
  {
    title: "Updated By",
    field: "updated_by",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.updated_by}
      </div>
    ),
  },
  {
    title: "Job Tittle",
    field: "jobTitle",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div
        style={{
          fontWeight: row?.fontWeight ? row?.fontWeight : "400",
          width: "100px",
          color: "#2563EB",
        }}
        onClick={() =>
          window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
        }
      >
        {row.jobTitle}
      </div>
    ),
  },
  {
    title: "Company Name",
    field: "companyName",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.companyName}
      </div>
    ),
  },
  {
    title: "Candidate name",
    field: "candidateName",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Mobile Number",
    field: "number",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Current Stage",
    field: "stage",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Ageing",
    field: "ageing",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "HR Name",
    field: "hrName",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },

  {
    title: "Resume",
    field: "resume",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div
        style={{
          cursor: "pointer",
          color: "#0509ff",
        }}
      >
        <Button variant="outlined" color="inherit">
          View
        </Button>
      </div>
    ),
  },
];

export const recruiterColumns = [
  {
    title: "Job Tittle",
    field: "job_title",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div
        style={{
          fontWeight: row?.fontWeight ? row?.fontWeight : "400",
          width: "100px",
          color: "#2563EB",
          cursor: "pointer",
        }}
        onClick={() =>
          window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)
        }
      >
        {row.job_title}
      </div>
    ),
  },
  {
    title: "Company Name",
    field: "company_name",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.company_name}
      </div>
    ),
  },
  {
    title: "Candidate name",
    field: "candidate_name",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Mobile Number",
    field: "mobile_no",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Current Stage",
    field: "current_status",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Ageing",
    field: "ageing",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
];

export const teamLeadColumns = [
  {
    title: "Updated By",
    field: "updated_by",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.updated_by}
      </div>
    ),
  },
  {
    title: "Job Tittle",
    field: "job_title",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div
        style={{
          fontWeight: row?.fontWeight ? row?.fontWeight : "400",
          width: "100px",
          color: "#2563EB",
          cursor: "pointer",
        }}
        onClick={() =>
          window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)
        }
      >
        {row.job_title}
      </div>
    ),
  },
  {
    title: "Company Name",
    field: "company_name",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.company_name}
      </div>
    ),
  },
  {
    title: "Candidate name",
    field: "candidate_name",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Mobile Number",
    field: "mobile_no",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Current Stage",
    field: "current_status",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Ageing",
    field: "ageing",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
];

export const managerColumns = [
  {
    title: "Team Lead name",
    field: "team_lead_name",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.team_lead_name}
      </div>
    ),
  },
  {
    title: "Updated By",
    field: "updated_by",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.updated_by}
      </div>
    ),
  },
  {
    title: "Job Tittle",
    field: "job_title",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div
        style={{
          fontWeight: row?.fontWeight ? row?.fontWeight : "400",
          width: "100px",
          color: "#2563EB",
          cursor: "pointer",
        }}
        onClick={() =>
          window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)
        }
      >
        {row.job_title}
      </div>
    ),
  },
  {
    title: "Company Name",
    field: "company_name",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    render: (row) => (
      <div style={{ fontWeight: "bold", color: "#6C757D" }}>
        {row?.company_name}
      </div>
    ),
  },
  {
    title: "Candidate name",
    field: "candidate_name",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Mobile Number",
    field: "mobile_no",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Current Stage",
    field: "current_status",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
  {
    title: "Ageing",
    field: "ageing",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
  },
];
