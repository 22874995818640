import React, { useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { Button } from "react-bootstrap";
import { FailureAlert } from "../../util/Notifications";
import { useEffect } from "react";
import { DeleteOutline } from "@material-ui/icons";

export function DateSelector({
  dateRange,
  applyDateFilter,
  removeDateFilter,
  showRemove = true,
  isAdvanceFilter = false,
  advanceFilterDateValue,
  setAdvanceFilterDateValue,
  clearDates = false,
  justifyContent = "center",
  label = "Date Range",
}) {
  const [valueDate, setValueDate] = useState(
    isAdvanceFilter ? advanceFilterDateValue : dateRange
  );

  useEffect(() => {
    if (dateRange?.length === 2) {
      setValueDate(dateRange);
    }
  }, [dateRange]);

  const applyFilter = (e) => {
    e.preventDefault();
    if (!valueDate[0] || !valueDate[1]) {
      FailureAlert("Date range not selected correctly!!");
      return;
    }
    applyDateFilter(valueDate);
  };

  const removeFilter = (e) => {
    e.preventDefault();
    if (!valueDate[0] && !valueDate[1]) {
      FailureAlert("Filter already removed!!");
      return;
    }
    removeDateFilter();
  };

  return (
    <div
      style={{
        //zIndex: 100000,
        display: "flex",
        alignItems: "center",
        justifyContent: justifyContent,
        // paddingBottom: "10px",
      }}
    >
      <span style={{ marginRight: "0.5rem", fontWeight: 700 }}>{label} :</span>
      <DateRangePicker
        rangeDivider="to"
        value={isAdvanceFilter ? advanceFilterDateValue : valueDate}
        clearIcon={null}
        // onChange={isAdvanceFilter ? setAdvanceFilterDateValue : setValueDate}
        onChange={
          isAdvanceFilter
            ? (value) => {
                setAdvanceFilterDateValue(value);
              }
            : (value) => {
                setValueDate(value);
              }
        }
        format="dd/MM/yyyy"
      />
      {!isAdvanceFilter && (
        <Button
          variant="outline-success"
          size="sm"
          style={{ marginLeft: "1.5rem" }}
          onClick={(e) => applyFilter(e)}
        >
          Apply Filter
        </Button>
      )}
      {showRemove && !isAdvanceFilter ? (
        <Button
          variant="outline-danger"
          size="sm"
          style={{ marginLeft: "1.5rem" }}
          onClick={(e) => removeFilter(e)}
        >
          Remove Filter
        </Button>
      ) : null}
      {clearDates && (
        <DeleteOutline
          style={{ cursor: "pointer" }}
          onClick={(e) => removeDateFilter(e)}
        />
      )}
    </div>
  );
}
