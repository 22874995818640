import { Typography, Grid, Button, useTheme } from "@material-ui/core";
import React from "react";

import { ReactComponent as NoData } from "../../../assets/svg/New-entries-rafiki.svg"; //../../../Assets/Images/svg/New-entries-rafiki.svg
import moment from "moment";
//import NoData from "../../../assets/svg/New-entries-rafiki.svg";
function AllComment({
  data,
  currentApplicant,
  isDetail = false,
  fromCandidate = false,
}) {
  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const colorSvg = [theme.palette.primary.main];

  return (
    <>
      <div>
        <Typography
          variant="h6"
          style={{ fontWeight: "600", marginBottom: "15px" }}
        >
          Comments {data && data?.length > 0 && `(${data?.length})`}
        </Typography>
        {!isDetail && !fromCandidate && (
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Applicant Name: {currentApplicant?.applicantName}
          </Typography>
        )}

        {!fromCandidate && data?.length > 0 ? (
          data?.map((item) => (
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  /*  marginBottom: "10px", */
                }}
              >
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {item?.user_name}: {item?.status_name}
                </Typography>
                <Typography style={{ color: "#B0B6BA", fontSize: "12px" }}>
                  {item.create_time
                    ? moment(item.create_time).format("DD/MM/YYYY")
                    : ""}
                </Typography>
              </div>
              <Typography variant="body2" style={{ color: "#6C757D" }}>
                {item?.comment
                  ? item?.comment
                  : item?.feedbcak_name
                  ? item?.feedbcak_name
                  : item?.feedback_name}
              </Typography>
            </div>
          ))
        ) : fromCandidate && data?.count > 0 ? (
          Object.keys(data)?.map((item) => {
            if (item !== "count") {
              return (
                data[item]?.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                      {item}
                    </Typography>
                    {data[item]?.map((item2) => (
                      <div style={{ marginTop: "10px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            /*  marginBottom: "10px", */
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500 }}
                          >
                            {item2?.user_name}: {item2?.status_name}
                          </Typography>
                          <Typography
                            style={{ color: "#B0B6BA", fontSize: "12px" }}
                          >
                            {item2?.create_time
                              ? moment(data[item][0]?.create_time).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          style={{ color: "#6C757D" }}
                        >
                          {item2?.comment
                            ? item2?.comment
                            : item2?.feedbcak_name
                            ? item2?.feedbcak_name
                            : item2?.feedback_name}
                        </Typography>
                      </div>
                    ))}
                  </div>
                )
              );
            }
          })
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <NoData
                height="180px"
                width="180px"
                alt="404 Error"
                fill={colorSvg}
              />
              <p align="center">{"No Comments Available"}</p>
            </div>{" "}
          </div>
        )}
      </div>
    </>
  );
}

export default AllComment;
