import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/AllStudents.css'
import ParticularStudentDetails from './ParticularStudentDetails';
import {Form, Button} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import {Box} from '@material-ui/core';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import { requestPraveshOptions, gifStyling } from '../../constants';


function AllStudents() {

    const [userEmail, setUserEmail] = useState('');
    const [studentDetails, setStudentDetails] = useState({});
    const [colleges, setColleges] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [boards, setBoards] = useState([]);
    const [specializations, setSpecializations] = useState([]);

    const [loading, setLoading] = useState(false);
    const [mainLoading, setMainLoading] = useState(false);

    // Degrees Finding API
    useEffect(() => {
        apiCall(`/api/admin/degree/all/`, requestPraveshOptions)
        .then((res)=> {
            if(res.success) setDegrees(res.data)
            else FailureAlert(res.error)  
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Specialization Type API
    useEffect(() => {
        apiCall(`/api/admin/specialization/all/`, requestPraveshOptions)
        .then((res)=> {
            setSpecializations(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Board Finding API
    useEffect(() => {
        apiCall(`/api/education/board`, requestPraveshOptions)
        .then((res)=> {
            if(res.success) setBoards(res.data.board_list)
            else FailureAlert(res.error)  
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Getting List of all Colleges
    useEffect(() => {
        setMainLoading(true);
        apiCall('/api/admin/college/get/', requestPraveshOptions)
        .then((res)=> {
            if(res.success) setColleges(res.data)
            else FailureAlert(res.error)
            setMainLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateReport = () => {
        apiCall(`/api/admin/operations/student/user/`, requestPraveshOptions)
        .then((res) => {
            if(res.success) SuccessAlert(res.data.message);
            else FailureAlert(res.error);
        })
    }

    const findUser = (e) => {
        e.preventDefault();
        if(!userEmail) { FailureAlert('Please enter an email to continue...'); return; }
        setLoading(true);
        apiCall(`/api/shared/public_profile/?email_id=${userEmail}`, requestPraveshOptions)
        .then((res) => {
            if(res.success) setStudentDetails(res.data)
            else {
                FailureAlert(res.error);
                setStudentDetails({})
            }
            setLoading(false);
        })
    }

    return (
        !mainLoading
        ?
        <>
            <div className="all__students">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', margin:'6px 3% 4px 3%', justifyContent:'space-between'}}>
                    <h3>Get All Student Users Details</h3>
                    <Button variant="info" onClick={generateReport}>
                        Send Mail
                    </Button>
                </div>
                <hr/>
            </div>
            <div className="particular__student__details" style={{margin:'6px 5% 12px 3%'}}>
                <h5>Get Particular Student Details</h5>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    <Form style={{width:'75%'}}>
                        <Form.Group controlId="userEmailId" style={{margin:'6px 0px'}}>
                            <Form.Control 
                                name="userEmailId" type="email" placeholder="Enter User's Email Id*"
                                required onChange={e=> {setUserEmail(e.target.value)}} 
                            />
                        </Form.Group>
                    </Form>
                    <Button style={{width:'20%'}} onClick={e=>findUser(e)}  variant="primary">Search User</Button>
                </div>
            </div>
            {
                studentDetails.id
                ?
                studentDetails.student_user_details.id
                ?
                !loading
                ?
                <Box boxShadow={1} 
                    style={{margin:'0 2% 20px 2%', width:'96%', height:'500px', overflowY:'scroll', scrollbarWidth:"none"}}
                >
                    <ParticularStudentDetails 
                        details={studentDetails}
                        colleges={colleges}
                        degrees={degrees}
                        specializations={specializations}
                        boards={boards}
                    />
                </Box>
                :
                <img 
                    src={gif} 
                    alt="Getwork Loading Gif..."
                    style={gifStyling}
                />
                :
                <div style={{textAlign:'center', marginTop:'30px'}}>
                    <span style={{color:'red', fontSize:'20px'}}>
                        You are not registered as a Student...
                    </span>
                </div>
                :
                null
            }
        </>
        :
        <img 
            src={gif} 
            alt="Getwork Loading Gif..."
            style={gifStyling}
        />
        
    )
}

export default AllStudents;