import ApartmentOutlinedIcon from "@material-ui/icons/Apartment";
import BadgeOutlinedIcon from "@material-ui/icons/Contacts";
import FmdGoodOutlinedIcon from "@material-ui/icons/Room";
import PaymentsOutlinedIcon from "@material-ui/icons/Payment";
import PersonAddAltOutlinedIcon from "@material-ui/icons/PersonAdd";
import SchoolOutlinedIcon from "@material-ui/icons/School";
import WorkHistoryOutlinedIcon from "@material-ui/icons/Dns";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutline";
import WorkspacePremiumOutlinedIcon from "@material-ui/icons/LocalActivity";
import { Box, Typography, Grid } from "@material-ui/core";
import React from "react";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PsychologyOutlinedIcon from "@material-ui/icons/Settings";
function generateCsv(arr, type) {
  let str = "";
  if (arr && typeof arr === "object") {
    if (type === "jobSegment") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.job_segment_name + ", ")
          : (str += item?.job_segment_name)
      );
    } else if (type === "jobRoles") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.job_role_group + ", ")
          : (str += item?.job_role_group)
      );
    } else if (type === "skills") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.skill_name + ", ")
          : (str += item?.skill_name)
      );
    } /*  else if (type === "jobRoles") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.job_role_name + ", ")
          : (str += item?.job_role_name)
      );
    } */ else if (type === "expLevels") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.title + ", ")
          : (str += item?.title)
      );
    } else if (type === "noticePeriods") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item + ", ")
          : (str += item)
      );
    } else if (type === "eduLevels") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.name + ", ")
          : (str += item?.name)
      );
    } else if (type === "locations") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += `${item?.city},  `)
          : (str += `${item?.city}`)
      );
    } else if (type === "states") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += `${item?.state},  `)
          : (str += `${item?.state}`)
      );
    } else if (type === "categories") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.category + ", ")
          : (str += item?.category)
      );
    } else if (type === "degrees") {
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item?.name + ", ")
          : (str += item?.name)
      );
    }
  }
  str = str.replace(/,\s*$/, "");
  return str;
}

const infoitem = {
  display: "flex",
  flexDirection: "row",
  alignContent: "space-around",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: "10px",
};

const info_icon = {
  color: "#6c757d",
  height: "28px",
  marginRight: "10px",
};
const detail = {
  display: "flex",
  flexWrap: "wrap",
  // flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  alignContent: "center",
};
export const QuerySource = ({ data, sourceResults, from, jobId }) => {
  const mainData = [
    {
      icon: <WorkOutlineOutlinedIcon style={info_icon} />,
      heading: "Job Segments",
      details: generateCsv(data?.jobSegments, "jobSegment"),
      show: data?.jobSegments?.length > 0 ? true : false,
    },
    {
      icon: <BadgeOutlinedIcon style={info_icon} />,
      heading: "Job Role/Profile",
      details: generateCsv(data?.jobRoles, "jobRoles"),
      show: data?.jobRoles?.length > 0 ? true : false,
    },
    {
      icon: <PsychologyOutlinedIcon style={info_icon} />,
      heading: "Skills",
      details: generateCsv(data?.skills, "skills"),
      show: data?.skills?.length > 0 ? true : false,
    },

    {
      icon: <PersonAddAltOutlinedIcon style={info_icon} />,
      heading: "Experience Level",
      details: generateCsv(data?.expLevels, "expLevels"),
      show: data?.expLevels?.length > 0 ? true : false,
    },
    {
      icon: <PaymentsOutlinedIcon style={info_icon} />,
      heading: "Expected CTC",
      details:
        from === "sourceJob"
          ? data?.expCtc
          : data?.expCtc && `${data?.expCtc[0]} - ${data?.expCtc[1]}L`,
      show:
        data?.expCtc && from === "sourceJob"
          ? true
          : data?.expCtc && (data?.expCtc[0] > 0 || data?.expCtc[1] > 0)
          ? true
          : false,
    },
    {
      icon: <PaymentsOutlinedIcon style={info_icon} />,
      heading: "Current CTC",
      details:
        from === "sourceJob"
          ? data?.currentCtc
          : data?.currentCtc &&
            `${data?.currentCtc[0]} - ${data?.currentCtc[1]}L`,
      show:
        data?.currentCtc && from === "sourceJob"
          ? true
          : data?.currentCtc &&
            (data?.currentCtc[0] > 0 || data?.currentCtc[1] > 0)
          ? true
          : false,
    },

    {
      icon: <WorkHistoryOutlinedIcon style={info_icon} />,
      heading: "Notice Period",
      details: generateCsv(data?.noticePeriods, "noticePeriods"),
      show: data?.noticePeriods?.length > 0 ? true : false,
    },
    {
      icon: <FmdGoodOutlinedIcon style={info_icon} />,
      heading: "Current Location",
      details: generateCsv(data?.locations, "locations"),
      show: data?.locations?.length > 0 ? true : false,
    },
    {
      icon: <FmdGoodOutlinedIcon style={info_icon} />,
      heading: "Prefered Location",
      details: generateCsv(data?.preferedLocations, "locations"),
      show: data?.preferedLocations?.length > 0 ? true : false,
    },
    {
      icon: <HomeWorkIcon style={info_icon} />,
      heading: "States",
      details: generateCsv(data?.states, "states"),
      show: data?.states?.length > 0 ? true : false,
    },
    {
      icon: <SchoolOutlinedIcon style={info_icon} />,
      heading: "Education Level",
      details: generateCsv(data?.eduLevels, "eduLevels"),
      // show: data?.eduLevels && data?.eduLevels?.length > 0 ? true : false,
      show:
        from === "sourceJob" || jobId
          ? false
          : data?.eduLevels && data?.eduLevels?.length > 0
          ? true
          : false,
    },
    {
      icon: <WorkspacePremiumOutlinedIcon style={info_icon} />,
      heading: "Degree",
      details:
        from === "sourceJob"
          ? data?.degrees
          : generateCsv(data?.degrees, "degrees"),
      // show: data?.degrees && data?.degrees?.length > 0 ? true : false,
      show:
        from === "sourceJob" || jobId
          ? false
          : data?.degrees && data?.degrees?.length > 0
          ? true
          : false,
    },
    {
      icon: <WorkspacePremiumOutlinedIcon style={info_icon} />,
      heading: "Specializations",
      details:
        from === "sourceJob"
          ? data?.specializations
          : generateCsv(data?.specializations, "degrees"),
      // show: data?.degrees && data?.degrees?.length > 0 ? true : false,
      show:
        from === "sourceJob" || jobId
          ? false
          : data?.specializations && data?.specializations?.length > 0
          ? true
          : false,
    },
    {
      icon: <ApartmentOutlinedIcon style={info_icon} />,
      heading: "College Category",
      details: generateCsv(data?.clgCategories, "categories"),
      show: data?.clgCategories?.length > 0 ? true : false,
    },
  ];

  // console.log("data", data);
  return (
    <>
      {sourceResults === "source"
        ? mainData?.map(
            (item) =>
              item.show && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0 }}
                >
                  <Box
                    style={{
                      display: "flex",
                      // mt: 1,
                      marginBottom: 8,
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "200px",
                      }}
                    >
                      {item.icon}

                      <Typography
                        color="textSecondary"
                        style={{ fontWeight: 500, fontSize: "16px" }}
                      >
                        {item.heading}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        /*   noWrap */
                        variant="body1"
                        style={{
                          marginLeft: "15px",
                          maxWidth: "450px",
                          color: "#000",
                        }}
                      >
                        {item.details}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )
          )
        : mainData?.map(
            (item) =>
              item.show && (
                <Grid item md={4}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "200px",
                      }}
                    >
                      {item.icon}

                      <Typography
                        color="textSecondary"
                        style={{ fontWeight: 600, fontSize: "16px" }}
                      >
                        {item.heading}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        noWrap
                        variant="body1"
                        color="textSecondary"
                        style={{ marginLeft: "5px", maxWidth: "200px" }}
                      >
                        {item.details}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )
          )}
    </>
  );
};
