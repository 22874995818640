import { AppBar, CircularProgress, Tab, Tabs, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  gifStyling,
  headerCSS,
  praveshUrl,
  requestPraveshOptions,
} from "../../constants";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { validateDateTime } from "../../util/printNumber";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";
import axios from "axios";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

function CollegeInboundLeads() {
  const [startDate, setStartDate] = useState("");
  let history = useHistory();
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [file,setFile] = useState(null);
  const theme = useTheme();
  const inputRef = useRef(null);
  const inputRefs = useRef([]);
  const [value, setValue] = useState(0);

  const [open, setOpen] = useState(false);
  const [modalRejectJobData, setModalRejectJobData] = useState({});
  const [closeReason, setCloseReason] = useState("");
  const [count, setCount] = useState(0);

  const dateRange = [new Date(2021, 0, 1), new Date()];

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  const handleClose = () => {
    setOpen(false);
    setModalRejectJobData({});
  };

  const updateList = () => {
    setCount(count + 1);
  };

  const rejectParticularJob = (jobId) => {
    if (!closeReason) {
      FailureAlert("Specify Reason to Reject Job!!");
      return;
    }

    const requestOptionsPost = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        status: "REJECTED",
        reject_reason: closeReason,
      }),
    };

    apiNiyuktiCall(
      `/job/public_job_posting/${jobId}/`,
      requestOptionsPost
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCloseReason("");
      } else {
        FailureAlert(res.error);
      }
      updateList();
      handleClose();
    });
  };

  useEffect(() => {
    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 7)
    );
    let initialEndDate = new Date();

    if (startDate === "") {
      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = new Date(queryParams.from);
      }
      if (queryParams.to) {
        initialEndDate = new Date(queryParams.to);
      }
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      return;
    } else if (startDate === null) {
      initialStartDate = null;
    } else {
      initialStartDate = new Date(startDate);
      initialEndDate = new Date(endDate);
    }

    if (initialStartDate) {
      let month = initialStartDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = initialStartDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${initialEndDate
          .toISOString()
          .substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
    } else {
      history.replace({
        search: "",
      });
    }

    setLoading(true);

    apiCall(`/api/common/college_form/`, requestPraveshOptions).then((json) => {
      if (json.success) {
        let temp = [];
        for (var i = 0; i < json.data.length; i++) {
          let singleCollegeData = json.data[i];
        
          let obj = {
            createTime: validateDateTime(singleCollegeData.create_time),
            name: singleCollegeData.name,
            collegeName: singleCollegeData.college_name,
            collegeLocation: singleCollegeData.college_location,
            contactPhone: singleCollegeData.phone,
            contactEmail: singleCollegeData.email,
            collegeWebsite:singleCollegeData.college_website,
            collegeDesignation:singleCollegeData.designation,
            utmTerm: singleCollegeData?.utm_details?.utm_term,
            utmSource: singleCollegeData?.utm_details?.utm_source,
            utmCampign: singleCollegeData?.utm_details?.utm_campaign,
            utmContent: singleCollegeData?.utm_details?.utm_content,
            utmCategory: singleCollegeData?.utm_details?.utm_category,
            utmMedium: singleCollegeData?.utm_details?.utm_medium,
            idProofDoc:singleCollegeData?.id_proof_doc,
            id:singleCollegeData?.id,
            loading:false,
            index: i
          };
          temp.push(obj);
        }
        setJobData(temp);
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 useEffect(() => {
  if (selectedRow && file){
    const temp = [...jobData]
    temp[selectedRow.index].loading=true;
    setJobData(temp);
    const fd = new FormData();
    fd.append("id_proof_doc", file);
    axios.patch(`${praveshUrl}/api/common/college_form/${selectedRow.id}/`, fd, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        temp[selectedRow.index].loading=false;
        setJobData(temp);
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message);
          temp[selectedRow.index].idProofDoc=res?.data?.data?.college_form_data?.id_proof_doc;
          setJobData(temp);
          setFile(null)
        } else {
          FailureAlert(res?.error);
        }
      })
      .catch((err) => {
        temp[selectedRow.index].loading=false;
        setJobData(temp);
        FailureAlert("Something Went Wrong");
        throw err;
      });
  }
 }, [selectedRow, file])


  const columns = [
      {
        title: "Contact Name",
        field: "name",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
      },
  
      {
        title: "College Name",
        field: "collegeName",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
      },
      {
        title: "College Location",
        field: "collegeLocation",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
      },
      {
        title: "Contact Email",
        field: "contactEmail",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
      },
      {
        title: "Contact Phone",
        field: "contactPhone",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
      },
      {
        title: "Document",
        field: "idProofDoc",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
        render: (params) =>  (
            <>
             {params?.idProofDoc ? (<Button variant="outline-info" style={{paddingInline:"15px"}}onClick={()=> window.open(params?.idProofDoc)}>View</Button>) : (
             <>
             <input
             ref={(el) => (inputRefs.current[params.index] = el)}
             type="file"
             onChange={(e)=>{
               setFile(e.target.files[0])
             }}
             style={{ display: "none" }}
             accept=".doc,.docx,.pdf"
           />
           <Button variant="outline-info" disabled={params.loading} style={{paddingInline:"15px"}} onClick={() => {
             setSelectedRow(params);
             inputRefs.current[params.index]?.click();      
          }}> {params.loading ? <CircularProgress color="white" size={16}/> : "Add"}</Button> 
           </>
            )}
           </>)
      },
      {
        title: "College Website",
        field: "collegeWebsite",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
        renderCell: (params) => (
          <span >
            {params.row.collegeWebsite ? params.row.collegeWebsite : "NA"}
          </span>
        ),
      },
      {
        title: "Designation",
        field: "collegeDesignation",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
        renderCell: (params) => (
          <span>
            {params.row.collegeDesignation ? params.row.collegeDesignation : "NA"}
          </span>
        ),
      },
     
      {
        title: "Creation Date",
        field: "createTime",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
        type: "datetime",
        searchable: false,
        filterComponent: (props) => (
          <DateRangePicker
            rangeDivider="to"
            value={props.columnDef.tableData.filterValue || dateRange}
            onChange={(e) => handleChangeDate(e, props)}
          />
        ),
        customFilterAndSearch: (term, rowData) =>
          Date.parse(term[0]) <= Date.parse(rowData.createTime) &&
          Date.parse(term[1]) >= Date.parse(rowData.createTime),
      },
      {
        title: "UTM Term",
        field: "utmTerm",
        headerStyle: headerCSS,
        cellStyle: cellCSS,
        renderCell: (params) => (
          <span>
            {params.row.utmTerm ? params.row.utmTerm  : "NA"}
          </span>
        )},
        {
          title: "UTM Source",
          field: "utmSource",
          headerStyle: headerCSS,
          cellStyle: cellCSS,
          renderCell: (params) => (
            <span>
              {params.row.utmSource ? params.row.utmSource : "NA"}
            </span>
          )},
          {
            title: "UTM Medium",
            field: "utmMedium",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            renderCell: (params) => (
              <span>
                {params.row.utmMedium ? params.row.utmMedium : "NA"}
              </span>
            )},
            {
              title: "UTM Content",
              field: "utmContent",
              headerStyle: headerCSS,
              cellStyle: cellCSS,
              renderCell: (params) => (
                <span>
                  {params.row.utmContent ? params.row.utmContent : "NA"}
                </span>
              )},
              {
                title: "UTM Category",
                field: "utmCategory",
                headerStyle: headerCSS,
                cellStyle: cellCSS,
                renderCell: (params) => (
                  <span>
                    {params.row.utmCategory ? params.row.utmCategory : "NA"}
                  </span>
                )},
                {
                  title: "UTM Campign",
                  field: "utmCampign",
                  headerStyle: headerCSS,
                  cellStyle: cellCSS,
                  renderCell: (params) => (
                    <span>
                      {params.row.utmCampign ? params.row.utmCampign : "NA"}
                    </span>
                  )}
                ];


  return (
    <div className="open__close__jobs">
      <AppBar position="static" color="default">
      
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="LEADS" {...a11yProps(0)} />
          {/* <Tab label="WORKED" {...a11yProps(1)} /> */}
        </Tabs>
      </AppBar>
      {!loading ? (
        <TabPanel value={value} index={value} dir={theme.direction}>
          <br />
          {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
          <TableWithExport
            title={value === 0 ? "College's Leads" : "College's Leads"}
            data={jobData}
            columns={columns}
            fName={value === 0 ? "Colleges Leads" : "Colleges Leads"}
          />
        </TabPanel>
      ) : (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      )}

      {/* Reject Jobs Modal*/}
      {modalRejectJobData.jobId ? (
        <Modal
          show={open}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalRejectJobData.jobTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to reject this job ?</p>
            <br />
            <Form>
              <Form.Label>
                Kindly enter the reason to reject this job*
              </Form.Label>
              <Form.Group controlId="closeJobReason">
                <Form.Control
                  name="closeJobReason"
                  type="text"
                  placeholder="Reason to Reject this job goes here..."
                  required
                  onChange={(e) => {
                    setCloseReason(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={(e) => rejectParticularJob(modalRejectJobData.jobId)}
            >
              Close Job
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}

export default CollegeInboundLeads;
