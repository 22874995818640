import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, createIconStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Button} from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';
import { FlexboxGrid } from 'rsuite';


function HiredStudents() {

    const {clg_id} = useParams();

    const clgName = 
          JSON.parse(localStorage.getItem('colleges'))
          ?
          JSON.parse(localStorage.getItem('colleges'))[clg_id] :
          'College Name';
    const [data, setData] = useState([]);
    const [educations, setEducations] = useState({});   // For Filter purpose
    const [degrees, setDegrees] = useState({});   // For Filter purpose
    const [loading, setLoading] = useState(false);
    const [specializationList, setSpecializationList] = useState();
    // Education Type API
    useEffect(() => {
        apiCall('/api/education/type/', requestPraveshOptions)
        .then((res)=> {
            let obj={}
            res.forEach((e)=> { obj[e.type] = e.type })
            setEducations(obj)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Degrees Finding API
    useEffect(() => {
        apiCall(`/api/admin/degree/all/`, requestPraveshOptions)
        .then((res)=> {
            let obj = {}
            if(res.success) {
              res.data.forEach((degree)=> { obj[degree.degree_name] = degree.degree_name })
              setDegrees(obj)
            }
            else FailureAlert(res.error)  
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (newRange, props) => { props.onFilterChanged(props.columnDef.tableData.id, newRange); };

    const percentageLabel = [ {value:0, label:'0',}, {value:40, label:'40%',}, {value:100, label:'100%',}, ];

    useEffect(() => {
        setLoading(true);
        apiCall(`/api/admin/college/student/?college_id=${clg_id}&state=hired`, requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        user_id:             json.data[i].user_id,
                        student_name:        json.data[i].first_name + ' '+ json.data[i].last_name,
                        email:               json.data[i].email,
                        phone:               json.data[i].phone,
                        education_level:     json.data[i].education_level,
                        degree_name:         json.data[i].degree_name,
                        specialization_name: json.data[i].specialization_name,
                        tenth:               json.data[i].tenth,
                        twelfth:             json.data[i].twelfth,
                        percentage:          json.data[i].percentage,
                        resume:              json.data[i].resume,
                        company_name:        json.data[i].company_name
                    }
                    tmpArray.push(obj);

                    // done to make object list for filtering data from table as drop down
                    const specializationNameList = [...(new Set(tmpArray.map((item) => item.specialization_name)))] ;         
              
                    const specializationList =  specializationNameList.reduce((a, v) => ({ ...a, [v]: v}), {});
                    
                    setSpecializationList(specializationList);
                }
                setData(tmpArray);
                setLoading(false);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "Student Name",     field: "student_name",        headerStyle: headerCSS, cellStyle: {
           minWidth: 150,
            maxWidth: 200,
            fontSize: 13,
        },
      
      },
        { title: "Company Name",     field: "company_name",        headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Email",            field: "email",               headerStyle: headerCSS, cellStyle: cellCSS,
          render: row => row.email ? 
            <div>{row.email} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.email)} />
            </div> : null
        },
        { title: "Phone",            field: "phone",               headerStyle: headerCSS, cellStyle: cellCSS,
          render: row => row.phone ? 
            <div style={{
               minWidth: 120,
               maxWidth: 200,
               fontSize: 13,
               display:"flex",
            }}>{row.phone} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.phone)} />
            </div> : null
        },
        { title: "Education",        field: "education_level",     headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,         lookup: educations,           filterPlaceholder: 'Education', },
        { title: "Degree",           field: "degree_name",         headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,         lookup: degrees,              filterPlaceholder: 'Degree', },
        { title: "Specialization", 
          field: "specialization_name",
         headerStyle: headerCSS, 
         cellStyle: {
             minWidth: 200,
             maxWidth: 200,
             fontSize: 13,
          } ,
         searchable: false,
         filterPlaceholder: "Select",
         lookup: specializationList,
         filterCellStyle:{
          overflow:"hidden",
          minWidth: 200,
          maxWidth: 200,
         }         
        
        },

        { title: "Tenth%", field: "tenth",
          headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}} defaultValue={[0, 100]}
              value={props.columnDef.tableData.filterValue || [0,100]}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={100} step={5} marks={percentageLabel}
              valueLabelDisplay="auto" aria-labelledby="range-slider"
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.tenth >= term[0] && rowData.tenth <= term[1], },
        { title: "Twelfth%",         field: "twelfth",             headerStyle: headerCSS,       cellStyle: cellCSS,
          searchable: false,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}} defaultValue={[0, 100]}
              value={props.columnDef.tableData.filterValue || [0,100]}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={100} step={5} marks={percentageLabel}
              valueLabelDisplay="auto" aria-labelledby="range-slider"
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.twelfth >= term[0] && rowData.twelfth <= term[1], }, 
        { title: "Graduation",       field: "percentage", 
          headerStyle: headerCSS,
          cellStyle: cellCSS,
          searchable: false,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}} defaultValue={[0, 100]}
              value={props.columnDef.tableData.filterValue || [0,100]}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={100} step={5} marks={percentageLabel}
              valueLabelDisplay="auto" aria-labelledby="range-slider"
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.percentage >= term[0] && rowData.percentage <= term[1], },
        { title: 'Candidate Resume', field: "resume", 
                     headerStyle: headerCSS, 
                     cellStyle: cellCSS,
                      filtering: false,
          render: row => 
          <div
          
          > 
            { 
            row.resume 
            ?
            <Button style={{
              fontSize: 13,
               }}
           variant="outline-success" size="sm" onClick={() => window.open(row.resume)}> Resume</Button>
            :
            <Button variant="outline-secondary" 
             style={{
              fontSize: 13,
              }}
            size="sm" disabled> Resume</Button> 
            }
          </div> 
        },
    ]


    return (
        !loading
        ?
        <div className="hired__students">
            <TableWithExport 
                title = {`Hired Students - ${clgName}`}
                data = {data}
                columns = {columns}
                fName = "Selected Hired Students"
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default HiredStudents;