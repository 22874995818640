import React, { useState, useEffect } from 'react';
import {apiNiyuktiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestNiyuktiOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

function JobRound() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [roundName, setRoundName] = useState('');

    useEffect(() => {
        setLoading(true);
        apiNiyuktiCall('/job/round/', requestNiyuktiOptions)
          .then(json => {
                if(json.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            id:    parseInt(json.data[i].id),
                            name:  json.data[i].name,
                        }
                        tmpArray.push(obj)
                    }
                    setLoading(false);
                    setData(tmpArray);
                }
                else {
                    FailureAlert(json.error)
                }    
           })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ 
            name: roundName,
        })
    };

    const columns = [
        { title: "Round ID",   field: "id",    headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Round Name", field: "name",  headerStyle: headerCSS, cellStyle: cellCSS },
    ]

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ 
                name: newData.name,
            })
        };
        apiNiyuktiCall(`/job/round/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success)  SuccessAlert(res.data.message);
            else  FailureAlert(res.error);
            setCount(count+1);
        })
    }

    const addRound = (e) => {
        e.preventDefault();
        apiNiyuktiCall('/job/round/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message)
                setCount(count+1);
            }
            else  FailureAlert(res.error);
        })
        e.target.reset();
    }


    return (
        !loading
        ?
        <div className="job__round">
            <div className="job__round__form">
                <h4>Add New Job Round</h4>
                <Form onSubmit={e=>addRound(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="round">
                            <Form.Control 
                                name="round" 
                                type="text" 
                                placeholder="Enter Round Name*"
                                onChange={e=> {setRoundName(e.target.value)}}
                                required 
                            />
                        </Form.Group>
                        <Button type="submit" variant="info">Add Round</Button>
                    </Form.Row>
                </Form>    
            </div>
            <TableWithExport 
                title = "Job Round"
                data = {data}
                columns = {columns}
                fName = "Selected Job Rounds"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default JobRound;