import { Box, IconButton, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  amountPerSlot:{
    height:'5rem',
    outline:'none',
    marginTop:'1rem',
  }
}))

const paper = {
  borderRadius: "10px",
  boxShadow: "0px 0px 5px 2px #00000017",
  minWidth:'15rem',
  marginLeft:'20px',
};

const detail = {
  display: "flex",
  padding: "9px",
  alignItems: "center",
  // minWidth:'10rem',
  
};
const alltext = {
  textAlign: "left",
  marginLeft: "10px",
  minHeight:'6rem',
  // [theme.breakpoints.down("sm")]: {
  //   marginLeft: 0,
  //   textAlign: "center",
  // },
};



function HireSmallCard( { type, index, slots,image,title,subtitle,amount,setPlanDetails,planDetails }) {


const classes = useStyles();

const changeNoOfSlots = (action) =>{

  let tempArr = {...planDetails};
  let tempArray =[];
  
  if(type === 'techHiring'){

    tempArray = tempArr?.slot_list?.['Tech hiring'];
   
  }else if(type === 'nonTechHiring'){
    
     tempArray = tempArr?.slot_list?.['Non-Tech hiring']; 

    }else {      
      tempArray = tempArr?.slot_list?.['Intern hiring']; 
   }  
   
    let newSlotAdded =  tempArray[index];
    if(action === 'add'){

      newSlotAdded={...newSlotAdded,slotsBooked:((newSlotAdded?.slotsBooked ? newSlotAdded?.slotsBooked : 0) + 1)}
    }else{
      if(newSlotAdded?.slotsBooked === 0){
        //  FailureAlert("Can't be negative");
        return
      }
      newSlotAdded={...newSlotAdded,slotsBooked:((newSlotAdded?.slotsBooked ? newSlotAdded?.slotsBooked : 0) - 1)}
    }

    tempArray.splice(index,1,newSlotAdded);

  if(type === 'techHiring'){

    tempArr ={...planDetails,slot_list:{...planDetails?.slot_list,
      'Tech hiring':tempArray}}

   }else if(type === 'nonTechHiring'){

     tempArr ={...planDetails,slot_list:{...planDetails?.slot_list,
      'Non-Tech hiring':tempArray}}

    }else {      
       tempArr ={...planDetails,slot_list:{...planDetails?.slot_list,
      'Intern hiring':tempArray}}     
   }   

    setPlanDetails(tempArr); 

};

const handleSlotAmountChange = (value) => {

   let tempArr = {...planDetails};
  let tempArray =[];
  
  if(type === 'techHiring'){

    tempArray = tempArr?.slot_list?.['Tech hiring'];
   
  }else if(type === 'nonTechHiring'){
    
     tempArray = tempArr?.slot_list?.['Non-Tech hiring']; 

    }else {      
      tempArray = tempArr?.slot_list?.['Intern hiring']; 
   }  
   
    let slotAmount =  tempArray[index];
   
     
    slotAmount={...slotAmount,price_per_slot:value}    
   
    tempArray.splice(index,1,slotAmount);

  if(type === 'techHiring'){

    tempArr ={...planDetails,slot_list:{...planDetails?.slot_list,
      'Tech hiring':tempArray}}

   }else if(type === 'nonTechHiring'){

     tempArr ={...planDetails,slot_list:{...planDetails?.slot_list,
      'Non-Tech hiring':tempArray}}

    }else {      
       tempArr ={...planDetails,slot_list:{...planDetails?.slot_list,
      'Intern hiring':tempArray}}     
   }   

    setPlanDetails(tempArr); 
}



  return (
    <>
      <Box style={paper}>
        <Box style={detail}>
          <div style={{  
              marginLeft:'5px',
              marginRight:'5px',}}>
            <img
              //className={classes.img}             
              src={image}
              height="100px"
              width="50px"
              marginLeft='30px'
              marginRight='30px'
              alt="oneapp-chat"
            />
          </div>
          <Box style={alltext}>
            <Typography variant="body2" style={{ fontWeight: "bold",minWidth:"125px",overflow:'auto' }}>
              {title}
            </Typography>
            <Typography style={{ color: "#B0B6BA", fontSize: "10px" }}>
              {subtitle}
            </Typography>
           
            <TextField
             label="amount per slot"
             variant="outlined"
             value={amount}
             type='number'
             className={classes.amountPerSlot}
             onChange={(e) => {
               
              handleSlotAmountChange(e.target.value);

             }}
            />
            {/* <Typography variant="body1" style={{ fontWeight: "400" }}>
              {`₹${amount}/Slots`}
            </Typography> */}

            <Box
              style={{
                borderRadius: "6px",
                boxShadow: "0px 0px 4px 2px #0000001A",
                height: "35px",
                display: "flex",
                alignItems: "center",
                width: "100px",
                justifyContent: "space-between",
              }}
            >
              <IconButton style={{ color: "#B0B6BA" }}>
                <RemoveIcon
                 onClick={() => {
                  changeNoOfSlots('sub');
                }}
                />
              </IconButton>
              <Typography>{slots}</Typography>
              <IconButton style={{ color: "#B0B6BA" }} >
                <AddIcon 
                 onClick={() => {
                  changeNoOfSlots('add');
                }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default HireSmallCard;
