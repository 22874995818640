import { FormControlLabel, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Pagination from '@material-ui/lab/Pagination';
import QueryString from "query-string";
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import { DateSelector } from '../../components/common/DateSelector';
import CustomButtonCircularProgress from "../../components/loader";
import TableWithExport from '../../components/TableWithExport';
import { cellCSS, gifStyling, headerCSS } from '../../constants';
import { niyuktiAxiosPostReq, praveshAxiosPostReq } from '../../util/apiCall';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
    pageSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'

    },
    tableSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    globalSearch: {
        position: 'relative',

    },
    filterSearch: {
        display: 'flex',

    },


}
));

const CandidateCompanyLeads = () => {

    const classes = useStyles();

    const [allLeadsData, setAllLeadsData] = useState([]);
    const [allCompanyJobLeads, setAllCompanyJobLeads] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingAllData, setloadingAllData] = useState(false);
    const [count, setCount] = useState(0);
    const history = useHistory();
    const [downloadAllFlag, setDownloadAllFlag] = useState(false)
    const [forCandidates, setforCandidates] = useState(true);
    const [selectedPageNo, setSelectedPageNo] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [metaData, setMetaData] = useState({
        totalCount: 0,
        totalPages: 1,
    })

    const [isInitialCall, setInitialCall] = useState(true);

    const applyDateFilter = (valueDate) => {
        if (valueDate.length === 2) {
            setStartDate(valueDate[0]);
            setEndDate(valueDate[1]);
        }
        setSelectedPageNo(1);
    };


    const removeDateFilter = () => {

        let initialStartDate = new Date(
            new Date().setDate(new Date().getDate() - 6)
        );
        let initialEndDate = new Date();

        setStartDate(new Date(initialStartDate));
        setEndDate(new Date(initialEndDate));
        setCount(count + 1);
        setSelectedPageNo(1);

    };

    useEffect(() => {

        // if its initial call
        // and there is nothing in url then startdate and enddate should be today and t-30
        //  else get it from url

        if (isInitialCall) {

            let initialStartDate = new Date(
                new Date().setDate(new Date().getDate() - 6)
            );
            let initialEndDate = new Date();

            const queryParams = QueryString.parse(window.location.search);
            if (queryParams.from) {
                initialStartDate = queryParams.from;
            }
            if (queryParams.to) {
                initialEndDate = queryParams.to;
            }
            setStartDate(new Date(initialStartDate));
            setEndDate(new Date(initialEndDate));
        }

    }, []);

    const columns = [
        {
            title: "Applicant ID",
            field: "applicant_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },

        {
            title: "Name",
            field: "name",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 250,
                maxWidth: 300,

            },
            filtering: true,
        },
        {
            title: "User Id",
            field: "user_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Phone",
            field: "phone",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "E-mail",
            field: "email",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },

        {
            title: "Applied On",
            field: "applied_on",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Company Id",
            field: "company_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Date Joined",
            field: "date_joined",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 125,
                maxWidth: 250,
            },
            filtering: true,
        },
        {
            title: "Hired Date",
            field: "hired_date",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 125,
                maxWidth: 250,
            },
            filtering: true,
        },
        {
            title: "Job Id",
            field: "job_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },
        {
            title: "Job Title",
            field: "job_title",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 125,
                maxWidth: 250,
            },
            filtering: true,
        },
        {
            title: "Status",
            field: "status",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Total Applied Jobs Count",
            field: "total_applied_jobs_count",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Source",
            field: "utm_source",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Medium",
            field: "utm_medium",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Campaign",
            field: "utm_campaign",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "UTM Content",
            field: "utm_content",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Term",
            field: "utm_term",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Completion Percentage",
            field: "completion_percentage",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "Pending Fields",
            field: "pending_fields",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 250,
                maxWidth: 350,
            },
            filtering: true,
            searchable: true,
        },
    ]

    const columnsForCompany = [
        {
            title: "Job Id",
            field: "job_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,

        },
        {
            title: "Job Title",
            field: "job_title",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 200,
                maxWidth: 250,
            },
            filtering: true,
        },
        {
            title: "Company ID",
            field: "company_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },

        {
            title: "Company Name",
            field: "company_name",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filterPlaceholder: "Select",
            filterCellStyle: {
                overflow: "hidden",
                minWidth: 200,
                maxWidth: 250,
            },
        },
        {
            title: "Company Registered On",
            field: "company_registered_on",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },

        {
            title: "Total Jobs Posted",
            field: "company_job_post_count",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },
        {
            title: "Job Status",
            field: "job_status",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },
        {
            title: "Job Posted On",
            field: "job_posted_on",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 120,
                maxWidth: 120,
            },
            filtering: true,
        },
        {
            title: "Job Posted By",
            field: "posted_by",
            headerStyle: headerCSS,
            cellStyle: {
                ...cellCSS,
                minWidth: 120,
                maxWidth: 120,
            },
            filtering: true,
        },
        {
            title: "Applicants",
            field: "job_applicant_count",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },

        {
            title: "Hired Count",
            field: "job_hired_count",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },
        {
            title: "UTM Source",
            field: "utm_source",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Medium",
            field: "utm_medium",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Campaign",
            field: "utm_campaign",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,

        },
        {
            title: "UTM Content",
            field: "utm_content",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },
        {
            title: "UTM Term",
            field: "utm_term",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            searchable: true,
        },

    ]

    const handleDownloadAllData = () => {

        setDownloadAllFlag(true)

    }
    const getLeadsData = async () => {

        if (count === 0 && startDate === "") return;

        let dateString = ''
        let startDateString = '';

        if (startDate && endDate) {

            let month = startDate.getMonth() + 1;
            month = month < 10 ? `0${month}` : month;
            let day = startDate.getDate();
            day = day < 10 ? `0${day}` : day;
            startDateString = `${startDate.getFullYear()}-${month}-${day}`;
            history.replace(
                `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
            );
            dateString = `?start_date=${startDateString}&end_date=${endDate.toISOString().substring(0, 10)}`;
        };
        if (!downloadAllFlag) {

            setLoading(true);
        } else {
            setloadingAllData(true);
        }

        let endPoint = ``;
        if (forCandidates) {

            endPoint = `/api/admin/applicant/report_data/`;

            if (selectedPageNo > 0) {

                endPoint = `/api/admin/applicant/report_data/?page=${selectedPageNo}`;

            }
        } else {

            endPoint = `/job/admin/job/report_data/`;
            if (selectedPageNo > 0) {

                endPoint = `/job/admin/job/report_data/?page=${selectedPageNo}`;
            }
        }

        let payload = {

            "start_date": startDateString,
            "end_date": endDate.toISOString().substring(0, 10),
            "is_export": downloadAllFlag,
        }
        // for candidates we are having global search across all the pages
        if (searchString && forCandidates) {
            payload['search'] = searchString;
        }

        try {
            let res;

            if (forCandidates) {

                res = await praveshAxiosPostReq(endPoint, payload);

            } else {

                res = await niyuktiAxiosPostReq(endPoint, payload);
            }

            if (res?.data?.success) {

                setInitialCall(false);

                if (!downloadAllFlag) {

                    let data = res?.data?.data?.results;
                    let count = res?.data?.data?.count;

                    setMetaData({
                        totalCount: count,
                        totalPages: Math.ceil(count / 10),
                    })
                    let tempArr = [];

                    for (let i in data) {
                        let obj = {};

                        obj = {
                            ...data[i],

                            linked_user: `${data[i]?.linked_user?.first_name ? data[i]?.linked_user?.first_name : ''} ${data[i]?.linked_user?.last_name ? data[i]?.linked_user?.last_name : ''}`,

                        }
                        tempArr.push(obj);
                    }
                    if (forCandidates) {

                        setAllLeadsData(tempArr);

                    } else {

                        setAllCompanyJobLeads(tempArr)

                    }

                } else {

                    setDownloadAllFlag(false);
                    SuccessAlert("Request received.Please Check your email after 10 mins.")
                    // const data = res?.data?.data;
                    // const firstObj = res?.data?.data?.[0];

                    // const columns = Object.keys(firstObj);

                    // const fileName = "All_Data";
                    // const builder = new CsvBuilder(fileName + ".csv");
                    // builder.setColumns(columns).addRows(
                    //     data.map((rowData) => columns.map((columnDef) => rowData[columnDef]))
                    // ).exportFile();
                }

            } else {
                FailureAlert("Some Error Occured!");
            }

        } catch (error) {

            throw (error)
        } finally {

            setLoading(false);
            setDownloadAllFlag(false);
            setloadingAllData(false);

        }

    }

    useEffect(() => {

        const getData = setTimeout(() => {

            getLeadsData();

        }, 500);

        return () => clearTimeout(getData);


    }, [startDate, count, forCandidates]);

    useEffect(() => {

        if (selectedPageNo < 1) {
            return
        }

        const getData = setTimeout(() => {

            getLeadsData();

        }, 500);

        return () => clearTimeout(getData);

    }, [selectedPageNo, searchString])

    useEffect(() => {

        if (downloadAllFlag) {
            const getData = setTimeout(() => {

                getLeadsData();

            }, 500);

            return () => clearTimeout(getData);
        }
    }, [downloadAllFlag])

    return (
        <>
            <DateSelector
                dateRange={[startDate, endDate]}
                applyDateFilter={applyDateFilter}
                removeDateFilter={removeDateFilter}
            />

            <section>
                <section
                    className={classes.pageSection}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={forCandidates}
                                onChange={() => {
                                    setforCandidates(!forCandidates);
                                    setSelectedPageNo(0);
                                }}
                                name="checkedA"
                            />}
                        label={forCandidates ? "Toggle for Company Leads Table" : "Toggle for Candidates Leads Table"}
                    />
                    <section className={classes.filterSearch}>
                        {
                            forCandidates ?

                                <section className={classes?.globalSearch}>
                                    <TextField
                                        label="search filter-all pages"
                                        value={searchString}
                                        onChange={(e) => {

                                            setSearchString(e.target.value);
                                            setSelectedPageNo(1)
                                        }}
                                    />
                                </section> : null
                        }

                        <Button
                            style={{
                                width: '12rem', height: '2.5rem'
                            }}
                            onClick={() => {
                                handleDownloadAllData()
                            }
                            }
                        >
                            {loadingAllData ? <CustomButtonCircularProgress /> : " Download All Data"}
                        </Button>

                    </section>

                </section>
            </section>

            <section
                className={classes.tableSection}
            >
                <section>
                    {
                        loading ? <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} /> :
                            <>
                                <TableWithExport
                                    title={forCandidates ? `Marketing Leads for Candidates (${metaData?.totalCount || 0})` : `Marketing Leads for Company (${metaData?.totalCount || 0})`}
                                    data={forCandidates ? allLeadsData : allCompanyJobLeads}
                                    columns={forCandidates ? columns : columnsForCompany}
                                    fName="Selected_data"
                                    hasPaging={false}
                                    disableTableDownload={true}
                                    searchPlaceHolder={forCandidates ? " Search Candidates" : "Search Company"}
                                />
                                <section
                                    style={{ float: "right", marginTop: '1rem' }}
                                >
                                    <Pagination
                                        page={selectedPageNo || 1}
                                        onChange={
                                            (e, value) => {
                                                setSelectedPageNo(value);
                                            }
                                        }
                                        count={metaData?.totalPages}
                                        color="primary"
                                    />
                                </section>
                            </>
                    }
                </section>
            </section>
        </>
    )
}

export default CandidateCompanyLeads