import {
  Box,
  Button,
  CircularProgress, Grid,
  makeStyles, Typography
} from "@material-ui/core";
import { Pagination, Skeleton } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import { DASHBOARD_VIEWS } from "../../constants";
import { niyuktiAxiosGetReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { DateSelector } from "../common/DateSelector";
import TableWithExport from "../TableWithExportNew";
import { managerColumns, recruiterColumns, teamLeadColumns } from "./AllColumn";
import DashboardCard from "./DashboardCard";
import ErrorSection from "./ErrorSection";
import { TeamLeadAllData } from "./rowsData";
import TeamLaadSection from "./TeamLaadSection";
import FilterDialog from "./TeamLead/FilterDialog";

function ReportsGraphSection({
  skeletonInitialLoader,
  setSkeletonInitialLoader,
  skeletonGraphLoader,
  setSkeletonGraphLoader,
  cardId,
  setCardId,
  currentView,
  initialView,
  setCurrentView,
  value,
  filter,
  setFilter,
  recruitersList,
  teamLeadsList,
  cardCounts,
  graphData,
  inProcessTableData,
  selectedPageNo,
  setSelectedPageNo,
  tableDataLoader,
  setTableDataLoader,
  metaData,
  userData,
  errorScreenGraph,
  errorScreenTable,
  setErrorScreenGraph,
  setErrorScreenTable,
  selectedParamname
}) {
  const [dateFilter, setDateFilter] = useState(filter?.dateRange);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      dateRange: dateFilter,
    }));
    setSkeletonGraphLoader(true);
    setSkeletonInitialLoader(true);
  }, [dateFilter]);

  const handleChangeCard = (id) => {
    setCardId(id);
    setSkeletonGraphLoader(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = [
    {
      id: 1,
      title: {
        color: "#747677",
        grid: 2,
        title: "Total Lineups",
        value: cardCounts?.total_lineup_count,
      },
      tab: (
        <TeamLaadSection
          color="#747677"
          colors={graphData?.colors}
          tableTitle={`Total Lineups`}
          skeletonGraphLoader={skeletonGraphLoader}
          graphData={{ x_axis: graphData?.x_axis, y_axis: graphData?.y_axis }}
          currentView={currentView}
          filter={filter}
        />
      ),
    },
    {
      id: 2,
      title: {
        color: "#E5B905",
        grid: 2,
        title: "Total Footfalls",
        value: cardCounts?.total_footfall_count,
      },

      tab: (
        <TeamLaadSection
          color="#E5B905"
          colors={graphData?.colors}
          tableTitle="Total Footfalls"
          skeletonGraphLoader={skeletonGraphLoader}
          graphData={{ x_axis: graphData?.x_axis, y_axis: graphData?.y_axis }}
          currentView={currentView}
          filter={filter}
        />
      ),
    },
    {
      id: 3,
      title: {
        color: "#2563EB",
        grid: 4,
        title: "Total Selections",
        value: cardCounts?.total_selection_count,
        revenue: cardCounts?.selection_revenue,
      },
      tab: (
        <div>
          <TeamLaadSection
            color="#2563EB"
            colors={graphData?.colors}
            tableTitle="Total Selections"
            skeletonGraphLoader={skeletonGraphLoader}
            graphData={{ x_axis: graphData?.x_axis, y_axis: graphData?.y_axis }}
            currentView={currentView}
            filter={filter}
          />{" "}
          <br />
          <TeamLaadSection
            color="#000"
            colors={graphData?.colors}
            tableTitle="Selection Revenue"
            skeletonGraphLoader={skeletonGraphLoader}
            graphData={{
              x_axis: graphData?.x_axis,
              y_axis: graphData?.revenue_y_axis,
            }}
            currentView={currentView}
            filter={filter}
          />{" "}
        </div>
      ),
    },
    {
      id: 4,
      title: {
        color: "#199E50",
        grid: 4,
        title: "Total Joinings",
        value: cardCounts?.total_joining_count,
        revenue: cardCounts?.joining_revenue,
      },
      tab: (
        <div>
          <TeamLaadSection
            color="#199E50"
            colors={graphData?.colors}
            tableTitle="Total Joinings"
            skeletonGraphLoader={skeletonGraphLoader}
            graphData={{ x_axis: graphData?.x_axis, y_axis: graphData?.y_axis }}
            currentView={currentView}
            filter={filter}
          />{" "}
          <br />
          <TeamLaadSection
            color="#000"
            colors={graphData?.colors}
            tableTitle="Joining Revenue"
            skeletonGraphLoader={skeletonGraphLoader}
            graphData={{
              x_axis: graphData?.x_axis,
              y_axis: graphData?.revenue_y_axis,
            }}
            currentView={currentView}
            filter={filter}
          />{" "}
        </div>
      ),
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [skeletonGraphLoader]);

  const [downloadLoader, setDownloadLoader] = useState(false);
  const handleDownload = () => {
    const getAPIURL = () => {
      if (initialView === DASHBOARD_VIEWS.MANAGER) {
        const url = `/job/admin/export/performance/profile_in_process/?param_name=MANAGER`;
        return url;
      } else if (initialView === DASHBOARD_VIEWS.TEAM_LEAD) {
        const url = `/job/admin/export/performance/profile_in_process/?param_name=TEAM_LEAD&team_lead_id=${userData?.id}`;
        return url;
      } else if (initialView === DASHBOARD_VIEWS.RECRUITER) {
        const url = `/job/admin/export/performance/profile_in_process/?param_name=RECRUITER&recruiter_id=${userData?.id}`;
        return url;
      }
    };
    setDownloadLoader(true);
    niyuktiAxiosGetReq(getAPIURL())
      .then((res) => {
        if (res?.data?.success) {
          const data = res?.data?.data;
          SuccessAlert(data?.message);
        } else {
          FailureAlert("Error in downloading data");
        }
        setDownloadLoader(false);
      })
      .catch((err) => {
        FailureAlert("Error in downloading data");
        setDownloadLoader(false);
      });
  };

  return (
    <div style={{ marginTop: 15 }}>
      {!errorScreenGraph ? (
        <Box
          style={{
            backgroundColor: "#fff",
            padding: 15,
            borderRadius: 10,
            boxShadow: "0px 0px 10px 0px #0000001A",
          }}
        >
          {!skeletonInitialLoader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "#000", fontWeight: "600" }}
              >
                {currentView === initialView
                  ? "Dashboard"
                  : currentView === DASHBOARD_VIEWS.RECRUITER
                  ? `${filter?.user?.name}'s(R) Report`
                  : `${filter?.user?.name}'s(TL) Report`}
              </Typography>

              {initialView === DASHBOARD_VIEWS.RECRUITER ? (
                <DateSelector
                  dateRange={[]}
                  setAdvanceFilterDateValue={setDateFilter}
                  advanceFilterDateValue={dateFilter}
                  isAdvanceFilter={true}
                  justifyContent="space-between"
                  label={"Date range:"}
                />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleClickOpen}
                    style={{ backgroundColor: "#2563EB", boxShadow: "none" }}
                  >
                    <span style={{ color: "#FFF" }}>Filter</span>
                  </Button>
                  <FilterDialog
                    open={open}
                    AutocompleteLabel="Team Lead name"
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    initialView={initialView}
                    setCurrentView={setCurrentView}
                    value={value}
                    filter={filter}
                    setFilter={setFilter}
                    recruitersList={recruitersList}
                    teamLeadsList={teamLeadsList}
                    currentView={currentView}
                    setSkeletonInitialLoader={setSkeletonInitialLoader}
                    setSkeletonGraphLoader={setSkeletonGraphLoader}
                  />
                </>
              )}
            </div>
          ) : (
            <Box>
              <Skeleton
                variant="rect"
                height={50}
                style={{
                  borderRadius: 10,
                  width: "100%",
                }}
              />
            </Box>
          )}

          <Grid container spacing={2} style={{ marginTop: 15 }}>
            {data.map((item, id) => (
              <Grid item md={item.title.grid}>
                {!skeletonInitialLoader ? (
                  <Box
                    onClick={() => handleChangeCard(item.id)}
                    style={{
                      border: "1px solid",
                      borderColor:
                        cardId === item.id ? item?.title?.color : "#DCE0E4",
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                  >
                    <DashboardCard
                      color={item.title.color}
                      title={item.title.title}
                      value={item.title.value}
                      revenue={item.title.revenue}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Skeleton
                      variant="rect"
                      height={150}
                      style={{
                        borderRadius: 10,
                        width: "100%",
                      }}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
          {data.map((item, id) => (
            <div id="myTabContent">
              <div
                style={{
                  display: cardId === item.id ? "block " : "none",
                  marginTop: "20px",
                }}
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                {item.tab}
              </div>
            </div>
          ))}
        </Box>
      ) : (
        <Box style={{ marginTop: 15, position: "relative" }}>
          <ErrorSection
            setErrorScreen={setErrorScreenGraph}
            setSkeleton1={setSkeletonInitialLoader}
            setSkeleton2={setSkeletonGraphLoader}
          />
        </Box>
      )}
      {!errorScreenTable ? (
        <Box style={{ marginTop: 15, position: "relative" }}>
          {tableDataLoader ? (
            <Skeleton
              variant="rect"
              height={450}
              style={{
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
                width: "100%",
              }}
            />
          ) : (
            <>
              <div
                style={{
                  position: "absolute",
                  zIndex: 9,
                  right: "20px",
                  top: "15px",
                }}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  style={{ backgroundColor: "#2563EB", boxShadow: "none" }}
                  onClick={handleDownload}
                >
                  <span style={{ color: "#FFF" }}>
                    {downloadLoader ? (
                      <CircularProgress size={17} style={{ color: "white" }} />
                    ) : (
                      "Download"
                    )}
                  </span>
                </Button>
              </div>

              <TableWithExport
                title="Profile In Process{Open Stage}"
                columns={
                  initialView === DASHBOARD_VIEWS.RECRUITER
                    ? recruiterColumns
                    : initialView === DASHBOARD_VIEWS.TEAM_LEAD
                    ? teamLeadColumns
                    : managerColumns
                }
                data={inProcessTableData}
                selection={false}
                hasPaging={false}
                search={false}
                filtering={false}
              />
            </>
          )}
          <section
            style={{
              float: "right",
              marginTop: "1rem",
              marginBottom: "10px",
            }}
          >
            <Pagination
              page={selectedPageNo || 1}
              onChange={(e, value) => {
                setSelectedPageNo(value);
                setTableDataLoader(true);
              }}
              count={metaData?.totalPages}
              color="primary"
              disabled={tableDataLoader}
            />
          </section>
        </Box>
      ) : (
        <Box style={{ marginTop: 15, position: "relative" }}>
          <ErrorSection
            setErrorScreen={setErrorScreenTable}
            setSkeleton1={setTableDataLoader}
          />
        </Box>
      )}
    </div>
  );
}

export default ReportsGraphSection;
