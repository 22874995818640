import {
  Box,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import React from "react";

function Recruitment({
  cityData,
  preferences,
  setPreferences,
  handleSubmit,
  monthsLoader,
}) {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeInternship = (position) => {
    const updatedCheckedState = preferences?.months?.internshipAvailability.map(
      (item, index) => (index === position ? !item : item)
    );
    setPreferences({
      ...preferences,
      months: {
        ...preferences?.months,
        internshipAvailability: updatedCheckedState,
      },
    });
  };

  const handleChangeFullTime = (position) => {
    const updatedCheckedState = preferences?.months?.jobAvailability.map(
      (item, index) => (index === position ? !item : item)
    );
    setPreferences({
      ...preferences,
      months: {
        ...preferences?.months,
        jobAvailability: updatedCheckedState,
      },
    });
  };

  return (
    <>
      <Box
        style={{
          boxShadow: "0px 0px 4px 0px #00000040",
          padding: 20,
          borderRadius: "20px",
        }}
      >
        <Typography
          style={{ fontSize: "20px", fontWeight: "600", paddingLeft: 10 }}
        >
          Recruitment Process Months Preferences
        </Typography>

        <Box style={{ marginTop: 10, width: "70%" }}>
          <Typography
            style={{ fontSize: "16px", fontWeight: "40", paddingLeft: 10 }}
          >
            Tentative Availability For Full-time Hiring
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            {months.map((item, value) => (
              <Grid item md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={preferences?.months?.jobAvailability[value]}
                        onChange={(event) => handleChangeFullTime(value)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        {item.name}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box style={{ marginTop: 5, width: "70%" }}>
          <Typography
            style={{ fontSize: "16px", fontWeight: "40", paddingLeft: 10 }}
          >
            Tentative Availability For Intern Hiring
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            {months.map((item, value) => (
              <Grid item md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          preferences?.months?.internshipAvailability[value]
                        }
                        onChange={(event) => handleChangeInternship(value)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        {item.name}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button
          variant="contained"
          color="primary"
          style={{
            boxShadow: "none",
            borderRadius: "30px",
            paddingLeft: "20px",
            paddingRight: "20px",
            marginTop: 10,
            marginLeft: 10,
            minWidth: "9rem",
            minHeight: '2.7rem',
          
          }}
          onClick={() => {
            handleSubmit("months");
          }}
        >
          {monthsLoader ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            "Save Changes"
          )}
        </Button>
      </Box>
    </>
  );
}

export default Recruitment;

const months = [
  {
    abbreviation: "Jan",
    name: "January",
    value: 1,
  },
  {
    abbreviation: "Feb",
    name: "February",
    value: 2,
  },
  {
    abbreviation: "Mar",
    name: "March",
    value: 3,
  },
  {
    abbreviation: "Apr",
    name: "April",
    value: 4,
  },
  {
    abbreviation: "May",
    name: "May",
    value: 5,
  },
  {
    abbreviation: "Jun",
    name: "June",
    value: 6,
  },
  {
    abbreviation: "Jul",
    name: "July",
    value: 7,
  },
  {
    abbreviation: "Aug",
    name: "August",
    value: 8,
  },
  {
    abbreviation: "Sep",
    name: "September",
    value: 9,
  },
  {
    abbreviation: "Oct",
    name: "October",
    value: 10,
  },
  {
    abbreviation: "Nov",
    name: "November",
    value: 11,
  },
  {
    abbreviation: "Dec",
    name: "December",
    value: 12,
  },
];
