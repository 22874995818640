import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const CustomSlider = withStyles({
  root: {
    color: "#0B57D0",
    height: 6,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#0B57D0",
    // border: "2px solid currentColor",
    marginTop: -6,
    marginLeft: -3,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 6,
    borderRadius: 2,
  },
  rail: {
    height: 6,
    borderRadius: 2,
    backgroundColor: "#BABABA",
  },
})(Slider);

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 7,
  },
});

export default function SliderSection({
  index,
  initialValue,
  title,
  setStudentJobMatchingData,
  studentJobMatchingData,
  isKeySkill,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSliderChange = (event, newValue) => {
    if (!isKeySkill) {
      setStudentJobMatchingData((prev) => ({
        ...prev,
        communication_skills: newValue,
      }));
    } else {
      const individualSkills = studentJobMatchingData?.individual_skills;
      individualSkills[index].value = newValue;
      setStudentJobMatchingData((prev) => ({
        ...prev,
        individual_skills: individualSkills,
      }));
    }
    setValue(newValue);
  };
  // const individualSkills = studentJobMatchingData?.individual_skills;

  return (
    <div className={classes.root}>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography style={{ fontSize: "14px", color: "#6C757D" }}>
              {title}
            </Typography>
          </div>
          <div>
            <Typography style={{ fontSize: "14px", color: "#6C757D" }}>
              {value}%
            </Typography>
          </div>
        </div>

        <div>
          <CustomSlider
            value={typeof value === "number" ? value : 0}
            // value={individualSkills[index].value}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </div>
      </div>
    </div>
  );
}
