import React, { useState, useEffect } from 'react';
import {apiNiyuktiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestNiyuktiOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

function JobSegment() {

    const [data, setData] = useState([]);
    const [segment, setSegment] = useState('');
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        apiNiyuktiCall('/job/segment/', requestNiyuktiOptions)
          .then(json => {
                if(json.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            id:           parseInt(json.data[i].id),
                            job_segment:  json.data[i].job_segment,
                        }
                        tmpArray.push(obj)
                    }
                    setLoading(false);
                    setData(tmpArray);
                }
                else {
                    FailureAlert(json.error)
                }    
           })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ 
            job_segment: segment,
        })
    };

    const columns = [
        { title: "Job Segment ID",    field: "id",           headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Job Segment Name",  field: "job_segment",  headerStyle: headerCSS, cellStyle: cellCSS },
    ]

    const addNewSegment = (e) => {
        e.preventDefault();
        apiNiyuktiCall('/job/segment/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.error);
            }
        })
        e.target.reset();
    }

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ 
                job_segment: newData.job_segment,
            })
        };
        apiNiyuktiCall(`/job/segment/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) SuccessAlert(res.data.message);
            else FailureAlert(res.error);
            setCount(count+1);
        })
    }

    return (
        !loading
        ?
        <div className="job__segment">
            <div className="job__segment__form">
                <h4>Add New Job Segment</h4>
                <Form onSubmit={e=>addNewSegment(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="segment">
                            <Form.Control 
                                name="segment" 
                                type="text" 
                                placeholder="Enter Segment Name*"
                                onChange={e=> {setSegment(e.target.value)}}
                                required 
                            />
                        </Form.Group>
                        <Button type="submit" variant="info">Add Segment</Button>
                    </Form.Row>
                </Form>    
            </div>
            <TableWithExport 
                title = "Job Segment"
                data = {data}
                columns = {columns}
                fName = "Selected Job Segment"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default JobSegment;