import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function PaidServicesCredit() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [serviceType, setServiceType] = useState('');
    const [userType, setUserType] = useState(null);
    const [transactionType, setTransactionType] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [creditsRequired, setCreditsRequired] = useState(null);
    const [loading, setLoading] = useState(false);

    const userTypeLookup = {
        5: 'Company',
        7: 'College',
    }

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/paid_services/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:                   parseInt(json.data[i].id),
                        service_type:         json.data[i].service_type,
                        service_description:  json.data[i].service_description,
                        transaction_type:     json.data[i].transaction_type,
                        credits_required:     json.data[i].credits_required,
                        user_type_id:         json.data[i].user_type_id,
                        is_enabled:           json.data[i].is_enabled ? 'Yes' : 'No',
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const requestOptionsPost = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            service_type:        serviceType,
            service_description: serviceDescription,
            transaction_type:    transactionType,
            credits_required:    parseFloat(creditsRequired),
            user_type:           parseInt(userType),
        })
    };

    const columns = [
        { title: "Service_ID",          field: "id",                  headerStyle: headerCSS, cellStyle: cellCSS, 
          editable: 'never',            searchable: false, },
        { title: "Service_Type",        field: "service_type",        headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Service_Description", field: "service_description", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Credits_Required",    field: "credits_required",    headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Transaction_Type",    field: "transaction_type",    headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "User_Type",           field: "user_type_id",        headerStyle: headerCSS, cellStyle: cellCSS,
          lookup: userTypeLookup,       filterPlaceholder: 'Select',  searchable: false,
        },
        { title: "Is_Enabled?",         field: "is_enabled",          headerStyle: headerCSS, cellStyle: cellCSS,
          filterPlaceholder: 'Yes/No',  searchable: false,            lookup: {'Yes':'Yes', 'No':'No'},
        },
    ]

    const addNewIndustry = (e) => {
        e.preventDefault();
        if(!userType) { FailureAlert('Choose User Type to continue...'); return; }
        if(!transactionType) { FailureAlert('Choose Transaction Type to continue...'); return; }
        apiCall('/api/admin/paid_services/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
                setServiceType(''); setServiceDescription(''); setTransactionType('');
                setUserType(null); setCreditsRequired(null);
            }
            else {
                FailureAlert(res.error);
            }
        })
        e.target.reset();
    }

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData, oldData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (nD, oD) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({
                ...(oD.service_type !== nD.service_type && { service_type: nD.service_type }),
                ...(oD.service_description !== nD.service_description && { service_description: nD.service_description }),
                ...(oD.transaction_type !== nD.transaction_type && { transaction_type: nD.transaction_type }),
                ...(oD.credits_required !== nD.credits_required && { credits_required: nD.credits_required }),
                ...(oD.user_type_id !== nD.user_type_id && { user_type: parseInt(nD.user_type_id) }),
                ...(nD.is_enabled==='Yes' && { is_enabled: true }),
                ...(nD.is_enabled==='No' && { is_enabled: false }),
            })
        };
        apiCall(`/api/admin/paid_services/${nD.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
            }
            else {
                FailureAlert(res.error);
            }
            setCount(count+1);
        })
    }

    return (
        !loading
        ?
        <div id="paid__services">
            <div id="paid__services__form">
                <h4>Add New Paid Service</h4>
                <Form onSubmit={e=>addNewIndustry(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="serviceType">
                            <Form.Label>Service Type*</Form.Label>
                            <Form.Control 
                                name="serviceType" type="text" placeholder="Enter Service Type"
                                maxLength='50'
                                required onChange={e=> {setServiceType(e.target.value)}}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="userType">
                            <Form.Label>User Type*</Form.Label>
                            <Form.Control 
                                as="select" name="userType"
                                onChange={e=> {setUserType(e.target.value)}}
                            >
                                <option value=''>--Select--</option>
                                <option value={5}>Company</option>
                                <option value={7}>College</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="creditsRequired">
                            <Form.Label>Credits Required*</Form.Label>
                            <Form.Control 
                                name="creditsRequired" type="number" placeholder="Enter Credits.."
                                required onChange={e=> {setCreditsRequired(e.target.value)}}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="transactionType">
                            <Form.Label>Transaction Type*</Form.Label>
                            <Form.Control 
                                as="select" name="transactionType"
                                onChange={e=> {setTransactionType(e.target.value)}}
                            >
                                <option value=''>--Select--</option>
                                <option value='DEBIT'>DEBIT</option>
                                <option value='CREDIT'>CREDIT</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="serviceDescription">
                            <Form.Label>Service Description</Form.Label>
                            <Form.Control 
                                name="serviceDescription" type="text" placeholder="Enter Description.."
                                onChange={e=> {setServiceDescription(e.target.value)}}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Button type="submit" variant="primary">Add Service</Button>
                </Form>    
            </div>
            <TableWithExport 
                title = "GetWork Paid Services"
                data = {data}
                columns = {columns}
                fName = "Selected Services"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default PaidServicesCredit;