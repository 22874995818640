import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/styles";


export const CustomTextField = styled(TextField)({
  "& label": {
    color: "#6C757D",
    fontSize: "14px",
    paddingTop: "1.2px",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    height: "40px",
    "& fieldset": {
      borderRadius: "30px",
      color: "#7e7e7e",
    },

    "&.Mui-focused fieldset": {
      fontSize: "14px",
    },
    "&:hover fieldset": {
      fontSize: "14px",
    },
  },
});