import React from "react";
import { Slider, makeStyles, withStyles } from "@material-ui/core";

const CustomBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const CTCSlider = withStyles((theme) => ({
  color: theme.palette.primary.main,
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: CustomBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    // height: 8,
    // width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}))(Slider);

function calculateValue(value) {
  switch (value) {
    case 0:
      return 0;
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
    case 7:
      return 7;
    case 8:
      return 8;
    case 9:
      return 9;
    case 10:
      return 10;
    default:
      return 0;
  }
}

//[0, 3, 5, 8, 10, 12, 15, 18,]
const marks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
  value,
  label: calculateValue(value),
}));

export default function ExperienceSlider({ value = [0, 0], setValue }) {
  //const [value, setValue] = React.useState([1, 3]);

  return (
    <div
      style={{
        width: "100%",
        padding: "3px",
      }}
    >
      <CTCSlider
        step={null}
        value={value}
        onChange={(_, newValue) => {
          if (newValue !== value) {
            //console.log("newValue", newValue);
            setValue(newValue);
          }
        }}
        valueLabelDisplay="auto"
        marks={marks}
        scale={calculateValue}
        min={0} //2
        max={10} //16
      />
    </div>
  );
}
