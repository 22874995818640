import React, { Fragment, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { emailRegex } from "../../constants";
import { TextField } from "@material-ui/core";

function YearMonthPicker({ label, selectedDate, onDateChange }) {
  /* const [selectedDate, handleDateChange] = useState( new Date() )*/

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div
          style={{
            borderRadius: "30px",
            border: "1px soild black",
            backgroundColor: "#fff",
            borderRadius: "30px",
            display: "grid",
          }}
        >
          <DatePicker
            views={["year"]}
            /* label={label} */
            placeholder={label}
            inputVariant="outlined"
            size="small"
            /*   value={tempBasicInfo?.createDate}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }} */
            value={selectedDate}
            onChange={onDateChange}
            /*  variant="outline" */
            /*  renderInput={(props) => <TextField {...props} variant="outlined" />} */
          />
        </div>
      </MuiPickersUtilsProvider>
      {/* 
      <DatePicker
        views={["year", "month"]}
        label="Year and Month"
        helperText="With min and max"
        minDate={new Date("2018-03-01")}
        maxDate={new Date("2018-06-01")}
        value={selectedDate}
        onChange={handleDateChange}
      />

      <DatePicker
        variant="inline"
        openTo="year"
        views={["year", "month"]}
        label="Year and Month"
        helperText="Start from year selection"
        value={selectedDate}
        onChange={handleDateChange}
      /> */}
    </>
  );
}

export default YearMonthPicker;
