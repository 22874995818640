import {
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useRef, useEffect } from "react";
import { apiPraveshCall } from "../../util/apiCall";
import { requestPraveshOptions, emailRegex } from "../../constants";
import Camera from "@material-ui/icons/CameraAlt";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { praveshAxiosPostReq } from "../../util/baseApi";
import { objectToFormData } from "../../util/utility";
import LoadingOverlay from "react-loading-overlay";

function AddVendor({ isEdit = false }) {
  const [CurrentLocation, setCurrentLocation] = useState(null);
  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  const inputRef = useRef(null);
  const inputFileRef = useRef(null);
  const [profilePic, setProfilePic] = useState(null);
  const [agreement, setAgreement] = useState(null);

  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    apiPraveshCall(`/api/location/city_state/`, requestPraveshOptions)
      .then((res) => {
        if (res?.success) {
          setCityList(res?.data);
        } else {
          FailureAlert("Error in cities fetch");
        }
      })
      .catch((err) => {
        FailureAlert("Error in cities fetch");
      });
  }, []);

  const handleProfilePic = (e) => {
    setProfilePic(e.target.files[0]);
  };
  const handleRemovePic = () => {
    setProfilePic(null);
  };

  const handleAgreement = (e) => {
    setAgreement(e.target.files[0]);
  };
  const handleRemoveAgreement = () => {
    setAgreement(null);
  };

  const initialState = {
    gstin: "",
    vendorName: "",
    website: "",
    vendorAddress: "",
    vendorCity: "",
    userImage: "",
    fullName: "",
    firstName: "",
    lastName: "",
    emailId: "",
    altEmailId: "",
    phone: "",
    altPhone: "",
    userAddress: "",
    userCity: "",
    agreement: "",
    isJobPortal: false,
    jobPortalCode: "",
  };

  const [vendorDetail, setVendorDetails] = useState(initialState);

  const [loading, setLoading] = useState(false);

  const resetDetails = () => {
    setVendorDetails(initialState);
    setAgreement(null);
    setProfilePic(null);
    setCurrentLocation(null);
    setUserCurrentLocation(null);
  };

  const validateForm = () => {
    if (!vendorDetail?.vendorName) {
      FailureAlert("Firm Name Required");
      return false;
    } else if (!vendorDetail?.vendorAddress) {
      FailureAlert("Firm Address Required");
      return false;
    } else if (!CurrentLocation) {
      FailureAlert("Firm Location Required");
      return false;
    } else if (!vendorDetail?.firstName) {
      FailureAlert("First Name Required");
      return false;
    } else if (!vendorDetail?.emailId) {
      FailureAlert("Email Id Required");
      return false;
    } else if (!emailRegex.test(vendorDetail?.emailId)) {
      FailureAlert("Enter Valid Email Id");
      return false;
    } else if (!vendorDetail?.phone) {
      FailureAlert("Phone Required");
      return false;
    } else if (vendorDetail?.phone.toString().length !== 10) {
      FailureAlert("Enter Valid Contact Number");
      return false;
    } else if (!vendorDetail?.userAddress) {
      FailureAlert("User Address Required");
      return false;
    } else if (!userCurrentLocation) {
      FailureAlert("User Location Required");
      return false;
    } else if (!agreement) {
      FailureAlert("Agreement Required");
      return false;
    } else if (vendorDetail?.isJobPortal && !vendorDetail?.jobPortalCode) {
      FailureAlert("Enter Valid Job Portal Code");
      return false;
    }
    return true;
  };

  const createVendor = () => {
    if (validateForm()) {
      setLoading(true);
      const postData = {
        name: vendorDetail?.vendorName,
        gstin: vendorDetail?.gstin,
        address: vendorDetail?.vendorAddress,
        website: vendorDetail?.website,
        city: CurrentLocation?.city_id,
        state: CurrentLocation?.state_id,
        country: 1,
        first_name: vendorDetail?.firstName,
        email: vendorDetail?.emailId,
        alternate_email: vendorDetail?.altEmailId,
        phone: vendorDetail?.phone,
        alternate_phone: vendorDetail?.altPhone,
        current_city: userCurrentLocation?.city_id,
        last_name: vendorDetail?.lastName,
        current_address: vendorDetail?.userAddress,
        current_state: userCurrentLocation?.state_id,
        is_job_portal: vendorDetail?.isJobPortal,
        job_portal_code: vendorDetail?.jobPortalCode.toUpperCase(),
      };

      const formData = objectToFormData(postData);
      if (profilePic) {
        formData.append("profile_picture", profilePic);
      }
      if (agreement) {
        formData.append("file", agreement);
      }

      praveshAxiosPostReq(`/api/admin/vendor/`, formData)
        .then((res) => {
          if (res?.data?.success) {
            SuccessAlert(res?.data?.data?.message);
            resetDetails();
          } else {
            FailureAlert(res?.data?.error);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          FailureAlert("Error in creating vendor partner");
        });
    }
  };

  const handleOnChange = (key, value, trim = false) => {
    setVendorDetails({
      ...vendorDetail,
      [key]: trim ? value?.target?.value.trim() : value?.target?.value,
    });
  };

  return (
    <LoadingOverlay active={loading} spinner text="Please Wait...">
      <div style={{ width: "70%", paddingBottom: "20px" }}>
        <Typography variant="h6">Firm Details</Typography>
        <div style={{ marginTop: "25px" }}>
          <Grid container spacing={4}>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Firm Name"
                size="small"
                value={vendorDetail?.vendorName}
                required
                onChange={(item) => handleOnChange("vendorName", item)}
              />
            </Grid>{" "}
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Website"
                size="small"
                value={vendorDetail?.website}
                onChange={(item) => handleOnChange("website", item)}
              />
            </Grid>
            <Grid item md={12} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Address"
                size="small"
                value={vendorDetail?.vendorAddress}
                required
                onChange={(item) => handleOnChange("vendorAddress", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <Autocomplete
                id="current_location"
                value={CurrentLocation}
                required
                options={cityList}
                getOptionLabel={(item) => `${item?.city}, ${item?.state}`}
                onChange={(e, item) => {
                  setCurrentLocation(item);
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select City & State *"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="GSTIN"
                size="small"
                value={vendorDetail?.gstin}
                onChange={(item) => handleOnChange("gstin", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={vendorDetail?.isJobPortal}
                        color="primary"
                        onChange={() => {
                          if (vendorDetail?.isJobPortal) {
                            setVendorDetails((prev) => ({
                              ...prev,
                              jobPortalCode: "",
                            }));
                          }
                          setVendorDetails((prev) => ({
                            ...prev,
                            isJobPortal: !vendorDetail?.isJobPortal,
                          }));
                        }}
                        name="select all"
                      />
                    }
                    label="Job Portal"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
              <TextField
                disabled={!vendorDetail?.isJobPortal}
                variant="outlined"
                label="Job Portal Code"
                size="small"
                value={vendorDetail?.jobPortalCode.toUpperCase()}
                onChange={(item) => handleOnChange("jobPortalCode", item, true)}
              />
            </Grid>
            <Grid item md={12}>
              <div>
                <Typography variant="h6">User Details</Typography>
                <Typography variant="body2" style={{ marginTop: "5px" }}>
                  Profile Image
                </Typography>
              </div>

              <div style={{ marginTop: "25px" }}>
                <div
                  style={{
                    position: "relative",
                    border: "4px solid #2563EB",
                    borderRadius: "50%",
                    height: "146px",
                    width: "146px",
                  }}
                >
                  {profilePic && (
                    <img
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      src={URL.createObjectURL(profilePic)}
                    />
                  )}
                  <button
                    onClick={() => {
                      inputRef.current.click();
                    }}
                    style={{
                      backgroundColor: "#D9D9D9",
                      border: "none",
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      borderRadius: "50%",
                      padding: "6px",
                    }}
                  >
                    <Camera style={{ color: "#2563EB" }} />
                  </button>
                </div>
              </div>
              <input
                ref={inputRef}
                type="file"
                hidden
                name="myImage"
                accept="image/*"
                onChange={(event) => {
                  handleProfilePic(event);
                }}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="First name"
                size="small"
                required
                value={vendorDetail?.firstName}
                onChange={(item) => handleOnChange("firstName", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Last name"
                size="small"
                value={vendorDetail?.lastName}
                onChange={(item) => handleOnChange("lastName", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Email ID"
                size="small"
                required
                value={vendorDetail?.emailId}
                onChange={(item) => handleOnChange("emailId", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Alternate Email id"
                size="small"
                value={vendorDetail?.altEmailId}
                onChange={(item) => handleOnChange("altEmailId", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Contact no"
                size="small"
                type="number"
                value={vendorDetail?.phone}
                required
                onChange={(item) => handleOnChange("phone", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Alternate Contact no"
                size="small"
                type="number"
                value={vendorDetail?.altPhone}
                onChange={(item) => handleOnChange("altPhone", item)}
              />
            </Grid>
            <Grid item md={12} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                label="Current Address"
                size="small"
                required
                value={vendorDetail?.userAddress}
                onChange={(item) => handleOnChange("userAddress", item)}
              />
            </Grid>
            <Grid item md={6} style={{ display: "grid" }}>
              <Autocomplete
                id="current_location"
                value={userCurrentLocation}
                options={cityList}
                getOptionLabel={(item) => `${item?.city}, ${item?.state}`}
                onChange={(e, item) => {
                  setUserCurrentLocation(item);
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select City & State"
                    size="small"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item md={12} style={{ display: "grid" }}>
              <Typography variant="body2">Add Agreement *</Typography>

              <div
                style={{
                  marginTop: "10px",
                  border: "1px solid #CBD5E1",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "15px",
                }}
              >
                <div>
                  <Typography style={{ fontSize: "14px ", color: "#6C757D" }}>
                    Accepts .pdf, .doc and .docx
                  </Typography>
                  <input
                    ref={inputFileRef}
                    type="file"
                    onChange={handleAgreement}
                    accept=".doc,.docx,.pdf"
                    hidden
                  />
                  <Typography
                    style={{
                      fontSize: "14px ",
                      color: "#6C757D",
                      marginTop: "5px",
                    }}
                  >
                    {!agreement ? (
                      <> No File Selected </>
                    ) : (
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#000",
                          marginTop: "5px",
                        }}
                      >
                        <AttachFileIcon
                          style={{
                            color: "#181818",
                            fontSize: "16px",
                            marginRight: "4px",
                          }}
                        />
                        {"Selected File: " + agreement?.name}
                      </span>
                    )}
                  </Typography>
                </div>

                <div>
                  {!agreement ? (
                    <button
                      onClick={() => {
                        inputFileRef.current.click();
                      }}
                      style={{
                        border: "1px solid #2563EB",
                        color: "#2563EB",
                        height: "40px",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <PublishRoundedIcon />
                      Add Document
                    </button>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={handleRemoveAgreement}
                      style={{ backgroundColor: "#2563EB" }}
                    >
                      <CloseRoundedIcon style={{ color: "#fff" }} />
                    </IconButton>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {!isEdit && (
              <Button
                variant="outlined"
                color="inherit"
                style={{
                  color: "#6C757D",
                  borderColor: "#6C757D",
                  width: "180px",
                  height: "50px",
                  marginRight: "15px",
                }}
                onClick={resetDetails}
              >
                Reset
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#2563EB",
                width: "180px",
                height: "50px",
                boxShadow: "none",
              }}
              onClick={() => {
                createVendor();
              }}
            >
              Add Firm
            </Button>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default AddVendor;
