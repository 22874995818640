import React, { useState, useEffect } from "react";
import "../../css/AddDegreeSpecialization.css";
import { apiCall } from "../../util/apiCall";
import {
  headerCSS,
  cellCSS,
  gifStyling,
  requestPraveshOptions,
} from "../../constants";
import TableWithExport from "../TableWithExport";
import { Form, Col, Button } from "react-bootstrap";
import gif from "../../assets/loopingcircles-3.gif";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";

const token = localStorage.getItem("token");

function AddDegreeSpecialization() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [educationFilter, setEducationFilter] = useState({}); // For Filter purpose
  // const [degreeFilter, setDegreeFilter] = useState({});   // For Filter purpose

  const [educations, setEducations] = useState([]);
  const [educationId, setEducationId] = useState(null);
  const [degrees, setDegrees] = useState([]);
  const [degreeId, setDegreeId] = useState(null);
  const [degreeName, setDegreeName] = useState("");
  const [shortDegreeName, setShortDegreeName] = useState("");
  const [duration, setDuration] = useState(null);
  const [specializations, setSpecializations] = useState([]);
  const [specializationId, setSpecializationId] = useState(null);
  const [specializationName, setSpecializationName] = useState("");

  const [degreeNotListed, setDegreeNotListed] = useState(false);
  const [specializationNotListed, setSpecializationNotListed] = useState(false);
  const [isSpecialized, setIsSpecialized] = useState(true);
  // Education Type API
  useEffect(() => {
    apiCall("/api/education/type/", requestPraveshOptions).then((res) => {
      setEducations(res);
      let obj = {};
      res.forEach((e) => {
        obj[e.type] = e.type;
      });
      setEducationFilter(obj);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Degree Type API
  useEffect(() => {
    apiCall(`/api/admin/degree/all/`, requestPraveshOptions).then((res) => {
      // let obj = {}
      if (res.success) {
        setDegrees(res.data);
        //   res.data.forEach((degree)=> { obj[degree.degree_name] = degree.degree_name })
        //   setDegreeFilter(obj)
      } else FailureAlert(res.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  // Specialization Type API
  useEffect(() => {
    apiCall(`/api/admin/specialization/all/`, requestPraveshOptions).then(
      (res) => {
        setSpecializations(res.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  // Get all Degrees (DS Mapping)
  useEffect(() => {
    setLoading(true);
    apiCall("/api/admin/degree/", requestPraveshOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            degree_id: json.data[i].degree_id, // 1,
            degree_name: json.data[i].degree_name, // "Bachelor of Technology",
            degree_user_created: json.data[i].degree_user_created, // false,
            education_type_id: json.data[i].education_type_id, // 3,
            education_type_name: json.data[i].education_type_name, // "graduation",
            is_specialized: json.data[i].is_specialized, // true,
            specialization_id: json.data[i].specialization_id, // 1,
            specialization_name: json.data[i].specialization_name, // "Computer Science and Engineering",
            specialization_user_created:
              json.data[i].specialization_user_created, // false
            is_active: json.data[i].is_active ? "Yes" : "No",
          };
          tmpArray.push(obj);
        }
        setData(tmpArray);
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });
  }, [count]);

  const columns = [
    {
      title: "Education",
      editable: "never",
      field: "education_type_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: educationFilter,
      filterPlaceholder: "Education",
    },
    {
      title: "Degree_ID",
      editable: "never",
      field: "degree_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterPlaceholder: "Degree ID",
    },
    {
      title: "Degree",
      editable: "never",
      field: "degree_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Degree",
    },
    {
      title: "Specialization_ID",
      editable: "never",
      field: "specialization_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterPlaceholder: "Specialization ID",
    },
    {
      title: "Specialization",
      editable: "never",
      field: "specialization_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Specialization",
    },
    {
      title: "is Active?",
      field: "is_active",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterPlaceholder: "Yes/No",
      lookup: { Yes: "Yes", No: "No" },
      //   editComponent: props => (
      //     <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
      //         <MenuItem value="Yes">Yes</MenuItem>
      //         <MenuItem value="No">No</MenuItem>
      //     </Select>
      //   )
    },
  ];

  const addCourse = (e) => {
    e.preventDefault();
    if (degreeNotListed && !degreeName) {
      FailureAlert("Please Enter Degree Name");
      return;
    }

    if (!degreeNotListed && !degreeId) {
      FailureAlert("Please Select Degree Name from Dropdown");
      return;
    }
    if (degreeNotListed && !shortDegreeName) {
      FailureAlert("Please Enter Short Degree Name");
      return;
    }
    if (degreeNotListed && !duration) {
      FailureAlert("Please EnterDuration");
      return;
    }
    if (specializationNotListed && !specializationName) {
      FailureAlert("Please Enter specialization Name");
      return;
    }

    if (!specializationNotListed && !specializationId) {
      FailureAlert("Please Select specialization Name from Dropdown");
      return;
    }
    if (degreeName || degreeId) {
      const requestToAddCourse = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          education_type: educationId.id,
          degree_id: degreeName ? degreeId : null,
          degree_name: degreeName ? degreeName : null,
          is_specialized: specializationId || specializationName ? true : false,
          specialization_id: specializationId ? specializationId : null,
          specialization_name: specializationName ? specializationName : null,
          short_name: shortDegreeName ? shortDegreeName : null,
          duration: duration,
        }),
      };
      setLoading(true);

      apiCall(`/api/admin/degree/`, requestToAddCourse)
        .then((res) => {
          if (res.success) {
            SuccessAlert(res.data.message);
            setEducationId(null);
            setDegreeId(null);
            setDegreeName("");
            setSpecializationId(null);
            setSpecializationName("");
            setIsSpecialized(true);
            setDegreeNotListed(false);
            setSpecializationNotListed(false);
            setCount(count + 1);
            setShortDegreeName(null);
            setDuration(null);
          } else {
            FailureAlert(res.error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      FailureAlert("Add Degree from list or new one.");
    }
  };

  const edit = {
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          updateRow(newData);
          setData([...dataUpdate]);
          resolve();
        }, 1000);
      }),
  };

  const updateRow = (newData) => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        education_type_id: newData?.education_type_id,
        degree_id: newData?.degree_id,
        specialization_id: newData?.specialization_id,
        is_active: newData.is_active === "Yes" ? true : false,
      }),
    };
    apiCall(`/api/admin/degree/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  return !loading ? (
    <div className="add__degree__specialization">
      <Form className="add__degree__specialization__form">
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Education Type</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Degree</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Specialization</Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row className="courses">
          <Form.Group as={Col} controlId="educationType">
            <Form.Control
              as="select"
              value={!educationId ? "--select--" : null}
              onChange={(e) => setEducationId(JSON.parse(e.target.value))}
            >
              <option value={JSON.stringify({ id: null, type: "--select--" })}>
                --select--
              </option>
              {educations.map((e, key) => {
                return (
                  <option key={key} value={JSON.stringify(e)}>
                    {e.type}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          {educationId ? (
            !degreeNotListed ? (
              <Form.Group as={Col} controlId="degree">
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setDegreeId(JSON.parse(e.target.value).degree_id);
                    setIsSpecialized(JSON.parse(e.target.value).is_specialized);
                    setDegreeName(JSON.parse(e.target.value).degree_name);
                    setDegreeId(JSON.parse(e.target.value).degree_id);
                  }}
                >
                  <option
                    value={JSON.stringify({
                      degree_id: null,
                      degree_name: "--select--",
                    })}
                  >
                    --select--
                  </option>
                  {degrees.map((d, key) => {
                    return (
                      <>
                        {d.education_type_name === educationId.type ? (
                          <option key={key} value={JSON.stringify(d)}>
                            {d.degree_name}
                          </option>
                        ) : null}
                      </>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            ) : (
              <Form.Group as={Col} controlId="degreeName">
                <Form.Control
                  name="degreeName"
                  type="text"
                  placeholder="Degree Name goes here..."
                  onChange={(e) => {
                    setDegreeName(e.target.value);
                  }}
                />
                <Form.Control
                  name="shortDegreeName"
                  type="text"
                  placeholder="Short Degree Name goes here..."
                  onChange={(e) => {
                    setShortDegreeName(e.target.value);
                  }}
                />
                <Form.Control
                  name="duration"
                  type="text"
                  placeholder="Enter Duration of Degree in years..."
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                />
              </Form.Group>
            )
          ) : (
            <Form.Group as={Col} controlId="degree"></Form.Group>
          )}
          {(degreeId || degreeName) && isSpecialized ? (
            !specializationNotListed ? (
              <Form.Group as={Col} controlId="specialization">
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setSpecializationId(
                      JSON.parse(e.target.value).specialization_id
                    );
                    setSpecializationName(
                      JSON.parse(e.target.value).specialization_name
                    );
                  }}
                >
                  <option
                    value={JSON.stringify({
                      specialization_id: null,
                      specialization_name: "--select--",
                    })}
                  >
                    --select--
                  </option>
                  {specializations.map((s, key) => {
                    return (
                      <option key={key} value={JSON.stringify(s)}>
                        {s.specialization_name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            ) : (
              <Form.Group as={Col} controlId="specializationName">
                <Form.Control
                  name="specializationName"
                  type="text"
                  placeholder="Specialization Name goes here..."
                  onChange={(e) => {
                    setSpecializationName(e.target.value);
                  }}
                />
              </Form.Group>
            )
          ) : (
            <Form.Group as={Col} controlId="specialization"></Form.Group>
          )}
          <Button onClick={(e) => addCourse(e)} variant="info" size="sm">
            Add
          </Button>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} />
          {educationId ? (
            <Form.Group as={Col}>
              <Form.Check
                type="checkbox"
                label="Degree Not Listed"
                onChange={(e) => setDegreeNotListed(!degreeNotListed)}
                checked={degreeNotListed}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col}></Form.Group>
          )}
          {(degreeId || degreeName) && isSpecialized ? (
            <Form.Group as={Col}>
              <Form.Check
                type="checkbox"
                label="Specialization Not Listed"
                onChange={(e) =>
                  setSpecializationNotListed(!specializationNotListed)
                }
                checked={specializationNotListed}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col}></Form.Group>
          )}
        </Form.Row>
      </Form>
      {/* All Degrees in DataBase */}
      <TableWithExport
        title="All Degrees in DB"
        data={data}
        columns={columns}
        fName="Selected_Degrees"
        editableObject={edit}
      />
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default AddDegreeSpecialization;
