import { makeStyles, useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";
import EditOrderHistory from "./EditOrderHistory";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const token = localStorage.getItem("token");


function OrderHistory(props) {
  const [userCreatedColleges, setUserCreatedCollges] = useState(props.userCreated ? 1 : 0);

  const loading = false;

  const [currentRow, setCurrentRow] = useState(null);
  const [edit, setEdit] = useState(false);

  const [editOrder, setEditOrder] = useState({
    order_status: null,
    date: null,
    message: null,
  });
  const columns = [
    {
      title: "Product",
      field: "title",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Product",
      render: (row) => (
        <div
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => {
            handleClickOpen();
            setCurrentRow(row);
            setEdit(true);
          }}
        >
          {row.title}
          {/* &nbsp; &nbsp; &nbsp;
          <EditIcon style={{ cursor: "pointer" }} /> */}
        </div>
      ),
    },
    {
      title: "Vendor",
      field: "vendor_company_name",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    },
    {
      title: "Purchase Date",
      field: "purchase_date",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
      render: (row) => <div>{moment(row.purchase_date).format("DD/MM/YYYY")}</div>,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    },
    {
      title: "Order Status",
      field: "order_status",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    },
    {
      title: "User",
      field: "user_name",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    },
    {
      title: "Actions",
      field: "actions",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      render: (row) => (
        <>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClickOpen();
              setCurrentRow(row);
              setEdit(true);
            }}
          />
        </>
      ),
    },
    // {
    //   title: "Status",
    //   field: "is_active",
    //   type: "text",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   searchable: false,
    //   //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    //   filterPlaceholder: "Status",
    //   render: (row) => <>{row?.is_active ? <div style={{ color: "green", cursor: "pointer" }}>Active</div> : <div style={{ color: "red", cursor: "pointer" }}>InActive</div>}</>,
    //   filtering: false,
    // },
  ];

  const orderStatus = [
    { id: "CREATED", name: "Created" },
    { id: "PAYMENT_SUCCESS", name: "Payment Successful" },
    { id: "PAYMENT_FAILED", name: "Payment Failed" },
    { id: "PAYMENT_INITIATED", name: "Payment Initiated" },
    { id: "ACCEPTED", name: "Accepted" },
    { id: "RETURNED", name: "Returned" },
    { id: "REFUNDED", name: "Refunded" },
    { id: "DELIVERED", name: "Delivered" },
    { id: "CANCELLED", name: "Cancelled" },
  ];

  useEffect(() => {
    if (edit && currentRow) {
      setEditOrder({ ...editOrder, order_status: { id: currentRow?.order_status, name: checkForName(currentRow?.order_status) } });
    }
  }, [edit]);

  const checkForName = (id) => {
    var name = "";
    orderStatus.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/admin/order_history/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            item["actions"] = "action";
            item["user_name"] = item?.user_details?.user_name;
          });
          setDataCategory(json);
        } else FailureAlert("Link History was not fetched. Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRow(null);
    setEdit(false);
    setEditOrder({ ...editOrder, order_status: null, date: "", message: "" });
  };

  const putData = () => {
    var obj = currentRow?.extra_details ? { ...currentRow?.extra_details } : {};
    console.log(obj);
    if (editOrder?.order_status?.id === "RETURNED") {
      obj["return_data"] = { date: editOrder?.date + "T00:00:00", message: editOrder?.message };
    }
    if (editOrder?.order_status?.id === "REFUNDED") {
      obj["refund_data"] = { date: editOrder?.date + "T00:00:00", message: editOrder?.message };
    }
    if (editOrder?.order_status?.id === "DELIVERED") {
      obj["delivery_data"] = { date: editOrder?.date + "T00:00:00", message: editOrder?.message };
    }
    if (editOrder?.order_status?.id === "CANCELLED") {
      obj["cancellation_data"] = { date: editOrder?.date + "T00:00:00", message: editOrder?.message };
    }
    return obj;
  };

  const updateProduct = (id) => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        order_status: editOrder?.order_status?.id,
        extra_data: putData(),
      }),
    };
    apiCall(`/api/getwork_club/admin/order_history/${id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();

        setEdit(false);
        setCurrentRow(null);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const deleteProduct = (id) => {
    const fd = new FormData();

    fd.append("is_active", false);
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(`/api/getwork_club/product_banner/${id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
        // setProduct(null);
        // setBanner(null);
        // setEdit(false);
        // setCurrentRow(null);
        // setStatus(null);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  return (
    <div className="all__colleges">
      {/* <AppBar position="static" color="default">
        <Tabs value={userCreatedColleges} onChange={handleChangeToggle} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
          <Tab label="ALL COURSES" {...a11yProps(0)} />
          <Tab label="Instructors Profile" {...a11yProps(1)} />
        </Tabs>
      </AppBar> */}

      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <>
          {/* <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}> */}

          <TableWithExport title="Order History" columns={columns} data={dataCategory} fName="Product_banner" />
          {/* </TabPanel> */}
        </>
      ) : (
        <></>
        // <TabPanel value={userCreatedColleges} index={1} dir={theme.direction}>
        //   <Grid container spacing={3} className={classes.action}>
        //     <Grid
        //       item
        //       xs={10}
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} />
        //     </Grid>
        //     <Grid item xs={2}>
        //       <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //         <Button variant="contained" color="primary" style={{ minWidth: "145px" }} startIcon={<AddIcon className={classes.icon} />}>
        //           Add New
        //         </Button>
        //       </div>
        //     </Grid>
        //   </Grid>
        //   <TableWithExport title="All Instructor" columns={seccolumns} data={secdata} fName="Selected_Colleges_User_Created" editableObject={edit} />
        // </TabPanel>
      )}

      <EditOrderHistory
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        edit={edit}
        setEdit={setEdit}
        editOrder={editOrder}
        setEditOrder={setEditOrder}
        updateProduct={updateProduct}
      />
    </div>
  );
}

export default withRouter(OrderHistory);
