import React, { useEffect, useState } from "react";

import { Form, Col, Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useTheme } from "@material-ui/core/styles";

export default function AddNewCollegePayment({
  formData,
  setFormData,
  paid,
  setPaid,isSaas

}) {
  return (
    <>
      <div style={{ padding: "30px" }}>
        <Form className="paymemt__details__form" id="payment__details__form">
          <Form.Label>Paid{isSaas ? '*' : ''}</Form.Label>
          <Form.Group type="radio" id="paid" required>
            <Form.Check
              inline
              id="yes"
              type="radio"
              name="paid"
              label="Yes"
              checked={paid}
              onChange={(e) => setPaid(!paid)}
            />
            <Form.Check
              inline
              id="no"
              type="radio"
              name="paid"
              label="No"
              checked={!paid}
              onChange={(e) => setPaid(!paid)}
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="planStartDate" required>
              <Form.Label>Plan Start Date{isSaas ? '*' : ''}</Form.Label>
              <Form.Control
                className="planStartDate"
                name="planStartDate"
                type="date"
                value={formData.planStartDate}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    planStartDate: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="planEndDate" required>
              <Form.Label>Plan End Date{isSaas ? '*' : ''}</Form.Label>
              <Form.Control
                className="planEndDate"
                name="planEndDate"
                type="date"
                value={formData.planEndDate}
                onChange={(event) =>
                  setFormData({ ...formData, planEndDate: event.target.value })
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="paymentdetails">
              <Form.Label>Total Payment{isSaas ? '*' : ''}</Form.Label>
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
                className="form-control"
                name="totalpayment"
                type="text"
                placeholder="Total Amount"
                value={formData.totalpayment}
                onValueChange={(values) => {
                  const { value } = values;
                  setFormData({ ...formData, totalpayment: value });
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="paymentdetails">
              <Form.Label>Paid Payment{isSaas ? '*' : ''}</Form.Label>
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
                className="form-control"
                name="paidpayment"
                type="text"
                placeholder="Paid Amount"
                value={formData.paidpayment}
                /*  onChange={(event) =>
                  setFormData({ ...formData, paidpayment: event.target.value })
                } */
                onValueChange={(values) => {
                  const { value } = values;
                  setFormData({ ...formData, paidpayment: value });
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="paymentdetails">
              <Form.Label>Pending Payment</Form.Label>
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
                className="form-control"
                name="pendingpayment"
                type="text"
                placeholder={"Pending Amount"}
                value={
                  formData?.totalpayment &&
                  formData?.paidpayment &&
                  parseInt(formData?.totalpayment) -
                    parseInt(formData?.paidpayment)
                }
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </>
  );
}
