import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function SkillRating() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [ratingName, setRatingName] = useState('');
    const [ratingValue, setRatingValue] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/skill_rating/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:    parseInt(json.data[i].id),
                        rating_name:  json.data[i].rating_name,
                        rating_value: json.data[i].rating_value,
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);
    
    const requestOptionsPost = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ 
            rating_name: ratingName,
            rating_value: ratingValue
        })
    };

    const columns = [
        { title: "Rating ID",     field: "id",            headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Rating Name",   field: "rating_name",   headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Rating Value",  field: "rating_value",  headerStyle: headerCSS, cellStyle: cellCSS, type: 'numeric', align: 'left' },
    ];

    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData, oldData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }

    const updateRow = (newData, oldData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                ...(oldData.rating_name!==newData.rating_name && {rating_name: newData.rating_name}),
                rating_value: newData.rating_value
            })
        };
        apiCall(`/api/admin/skill_rating/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) SuccessAlert(res.data.message); 
            else FailureAlert(res.error);
            setCount(count+1);
        })
    }

    const addNewSkillRating = (e) => {
        e.preventDefault();
        apiCall('/api/admin/skill_rating/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.verbose.rating_name[0] || res.error);
            }
        })
        e.target.reset();
    }


    return (
        !loading
        ?
        <div className="skill__rating">
            <div className="skill__rating__form">
                <h4>Add New Skill Rating</h4>
                <Form onSubmit={e=>addNewSkillRating(e)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ratingName">
                            <Form.Control 
                                name="ratingName" 
                                type="text" 
                                placeholder="Enter Rating Name*"
                                onChange={e=> {setRatingName(e.target.value)}}
                                required 
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="ratingValue">
                            <Form.Control 
                                name="ratingValue" 
                                type="number" 
                                min="1"
                                placeholder="Enter Rating Value*"
                                onChange={e=> {setRatingValue(e.target.value)}}
                                required 
                            />
                        </Form.Group>
                    </Form.Row>
                    <Button type="submit" variant="info">Add Rating</Button>
                    
                </Form>    
            </div>
            <TableWithExport 
                title = "Skill Rating"
                data = {data}
                columns = {columns}
                fName = "Selected Skill Ratings"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default SkillRating;