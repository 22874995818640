import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import React, { useEffect, useState } from "react";
import { niyuktiAxiosCall, niyuktiAxiosGetReq } from "../../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../../util/Notifications"; //../../../util/Notifications
import LoadingOverlay from "react-loading-overlay";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams, useLocation } from "react-router";
import RatingDialog from "../../../common/Dialog/RatingDialog";

export default function RateAndApply({
  jobSkills,
  handleClickOpen,
  open,
  handleClose,
  jobApplicantId,
  getApiCall,
  name,
  jobId,
  companyId,
  jobTitle,
  rowData,
  stepList,
  statusMoveId,
  setStatusMoveId,
  userId,
  studentName,
  collegeId,
  jobTrack,
}) {
  // const initialJobMatchingData = {
  //   location: false,
  //   salary: false,
  //   work_exp: false,
  //   education: false,
  //   key_skills: 0,
  //   communication_skills: 0,
  // };
  // const [studentJobMatchingData, setStudentJobMatchingData] = useState(
  //   initialJobMatchingData
  // );
  const [rating, setRating] = useState(0);
  const [showLoader, setShowLoader] = useState(jobTrack);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedHr, setSelectedHr] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedOtherSource, setselectedOtherSource] = useState(null);

  // const [jobSkillls, setJobSkills] = useState([]);
  // // http://127.0.0.1:8000/api/manage_jobs/admin/job_skills/?job_id=677
  // useEffect(() => {
  //   if (jobId || selectedJob) {
  //     niyuktiAxiosGetReq(
  //       `/api/manage_jobs/admin/job_skills/?job_id=${
  //         jobId ? jobId : selectedJob?.job_id
  //       }`
  //     )
  //       .then((res) => {
  //         if (res.data.success) {
  //           setJobSkills(res.data.data.data);
  //           setStudentJobMatchingData(initialJobMatchingData);
  //         } else {
  //           FailureAlert(res.data.error);
  //         }
  //       })
  //       .catch((err) => {
  //         FailureAlert(err.message);
  //       });
  //   }
  // }, [jobId, selectedJob]);

  // const initialJobMatchingData = {
  //   location: false,
  //   salary: false,
  //   work_exp: false,
  //   education: false,
  //   // key_skills: 0,
  //   communication_skills: 0,
  //   individual_skills: jobSkillls,
  //   interview_summary: "",
  //   generate_report: false,
  // };
  // const [studentJobMatchingData, setStudentJobMatchingData] = useState(
  //   initialJobMatchingData
  // );
  const [jobSkillls, setJobSkills] = useState([]);
  const initialJobMatchingData = {
    location: false,
    salary: false,
    work_exp: false,
    education: false,
    communication_skills: 0,
    individual_skills: [],
    interview_summary: "",
    generate_report: false,
  };
  const [studentJobMatchingData, setStudentJobMatchingData] = useState(
    initialJobMatchingData
  );

  useEffect(() => {
    const initialJobMatchingData = {
      location: false,
      salary: false,
      work_exp: false,
      education: false,
      communication_skills: 0,
      individual_skills: jobSkillls, // Update individual_skills when jobSkillls changes
      interview_summary: "",
      generate_report: false,
    };
    setStudentJobMatchingData(initialJobMatchingData);
  }, [jobSkillls]); // Trigger effect when jobSkillls changes

  useEffect(() => {
    if (jobId || selectedJob) {
      niyuktiAxiosGetReq(
        `/api/manage_jobs/admin/job_skills/?job_id=${
          jobId ? jobId : selectedJob?.job_id
        }`
      )
        .then((res) => {
          if (res.data.success) {
            setJobSkills(res.data.data.data);
          } else {
            FailureAlert(res.data.error);
          }
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
  }, [jobId, selectedJob]);

  const [openJobs, setOpenJobs] = useState([]);
  const [comapnyHrs, setCompanyHrs] = useState([]);
  const [loadingApply, setLoadingApply] = useState(false);
  const [fromPreApplied, setFromPreApplied] = useState(false);

  const location1 = useLocation();

  /* ############################################################################################################## */
  //const [statusMoveId, setStatusMoveId] = React.useState(0);
  //   const [rejectReason, setRejectReason] = React.useState("");

  //   let statusCodes = {};
  //   Object.keys(stepList).forEach((step) => {
  //     statusCodes[step] = stepList[step]["tooltip"];
  //   });

  //   if (rowData && rowData?.status_id) {
  //     var strRow = "";
  //     strRow = `(${rowData?.status_id}`;
  //     if (rowData?.status_id === 7) strRow += `,${rowData?.job_round_id}`;
  //     strRow += ")";
  //   }

  /* ############################################################################################################## */

  // this is for pre-applied case where job id has already been allocated:

  const JobIdFromPreApplied = new URLSearchParams(location1.search).get(
    "preAppliedJobId"
  );

  const setJObIdForPreApplied = () => {
    if (JobIdFromPreApplied) {
      const data = openJobs?.find(
        (item) => item?.job_id === parseInt(JobIdFromPreApplied)
      );
      if (data) {
        setFromPreApplied(true);
        setSelectedJob(data);
      }
    }
  };

  useEffect(() => {
    if (openJobs && openJobs.length > 1) {
      if (jobId) {
        const preselectedJob = openJobs.find(
          (item) => item.job_id === parseInt(jobId)
        );
        setSelectedJob(preselectedJob);
      }
    }
  }, [openJobs, jobId]);

  const handleChange = (event) => {
    setStudentJobMatchingData({
      ...studentJobMatchingData,
      [event.target.name]: event.target.checked,
    });
  };

  const { location, salary, work_exp, education } = studentJobMatchingData;

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  <LoadingOverlay
    active={showLoader}
    spinner
    text="Updating Details..."
  ></LoadingOverlay>;

  /*   useEffect(() => {
    if (jobApplicantId && open) {
      setShowLoader(true);
      niyuktiAxiosCall
        .get(`/job/admin/applicant_rating/?job_applicant_id=${jobApplicantId}`)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            if (data.student_job_matching_data) {
              setStudentJobMatchingData({
                ...studentJobMatchingData,
                location: data?.student_job_matching_data?.location
                  ? true
                  : false,
                salary: data?.student_job_matching_data?.salary ? true : false,
                work_exp: data?.student_job_matching_data?.work_exp
                  ? true
                  : false,
                education: data?.student_job_matching_data?.education
                  ? true
                  : false,
                key_skills: data?.student_job_matching_data?.key_skills,
                communication_skills:
                  data?.student_job_matching_data?.communication_skills,
              });
              setRating(data.student_score);
            }
          } else {
            FailureAlert(res.data.error);
            handleClose();
          }
          setShowLoader(false);
        })
        .catch((err) => {
          handleClose();
          FailureAlert(err.message);
          setShowLoader(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]); */

  const handleKeySkills = (e, newValue) => {
    setStudentJobMatchingData({
      ...studentJobMatchingData,
      key_skills: parseInt(newValue),
    });
  };
  const handleCommunicationSkills = (e, newValue) => {
    setStudentJobMatchingData({
      ...studentJobMatchingData,
      communication_skills: parseInt(newValue),
    });
  };

  const handleSubmit = () => {
    /*    if (statusCodes[statusMoveId] === "Rejected" && !rejectReason) {
        FailureAlert("Enter Reason to reject Applicants...");
        return;
      }
      let updateRecords = [];
  
      let statId = statusMoveId.split(",")[0].substring(1);
      if (statusMoveId.split(",").length === 1) statId = statId.slice(0, -1);
      let moveRoundId = null;
      if (statusMoveId.split(",")[1] !== undefined) {
        moveRoundId = statusMoveId.split(",")[1].slice(0, -1);
      }
      updateRecords.push({
        id: rowData.applicant_id,
        round: moveRoundId ? parseInt(moveRoundId) : null,
        status: parseInt(statId),
        // feedback: null,
        user_id: rowData.user_id,
        job_id: jobId,
        company_id: rowData.company_id,
      });
  
      let body = { student_data: updateRecords };
  
      if (statusCodes[statusMoveId] === "Rejected") {
        let userData = JSON.parse(localStorage.getItem("user-data"));
        let statId = statusMoveId.split(",")[0].substring(1);
        if (statusMoveId.split(",").length === 1) statId = statId.slice(0, -1);
        let feedbackObj = {};
        feedbackObj["user_id"] = userData.id;
        feedbackObj["first_name"] = userData.first_name || "";
        feedbackObj["last_name"] = userData.last_name || "";
        feedbackObj["status_id"] = parseInt(statId);
        feedbackObj["status_name"] = statusCodes[statusMoveId];
        feedbackObj["feedback_name"] = rejectReason;
        feedbackObj["is_getwork_admin"] = true;
        body.applicant_feedback = feedbackObj;
      } */

    let body = {
      // ...body,
      student_job_matching_data: studentJobMatchingData,
      job_applicant_id: jobApplicantId,
    };

    setShowLoader(true);
    niyuktiAxiosCall
      .post(`/job/admin/applicant_rating/`, body)
      .then((res) => {
        if (res.data.success) {
          SuccessAlert("Applicant Rated Successfully!!");
          getApiCall();
          handleClose();
        } else {
          FailureAlert(res.data.error);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(true);
        FailureAlert(err.message);
      });
  };

  const applyToJob = (withoutRating) => {
    withoutRating ? setLoader1(true) : setLoader2(true);
    var data = {
      student_data: {
        user_id: userId,
        job_id: selectedJob?.job_id,
        college_id: collegeId,
      },
      utm_info: {
        utm_content:
          selectedSource?.value === "Other"
            ? selectedOtherSource
            : selectedSource?.value,
      },
    };
    niyuktiAxiosCall
      .post(`/job/admin/operations/apply_student/`, data, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          if (res?.data?.data?.sanchalan_sync !== "ALREADY_APPLIED") {
            RateApplicant(
              res?.data?.data?.applicant_id,
              res?.data?.data?.company_id,
              withoutRating
            );

            if (withoutRating) SuccessAlert(res.data.data.message);
          } else {
            setRateApply(false);
            withoutRating ? setLoader1(false) : setLoader2(false);
            handleClose();
            SuccessAlert(res?.data?.data?.message);
          }
        } else {
          withoutRating ? setLoader1(false) : setLoader2(false);
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert(err);
        // throw err;
      })
      .finally(() => {});
  };
  const RateApplicant = (jobApplicantId, cmpId, withoutRating) => {
    let body = {
      // ...body,
      student_data: [
        {
          id: jobApplicantId,
          round: null,
          status: 1,
          user_id: userId,
          job_id: selectedJob?.job_id,
          company_id: cmpId,
        },
      ],
      student_job_matching_data: studentJobMatchingData,
      job_applicant_id: jobApplicantId,
      is_locked: false,
      hr_info: selectedHr,
    };

    setShowLoader(true);
    niyuktiAxiosCall
      .post(`/job/admin/applicant_rating/`, body)
      .then((res) => {
        if (res.data.success) {
          if (!withoutRating) SuccessAlert(res.data.data.message);
          //getApiCall();
          handleClose();
          setRateApply(false);
          if (jobId && companyId) {
            window.location.href = `/admin/job-track/${jobId}/${companyId}`;
          }
        } else {
          FailureAlert(res.data.error);
        }
        setShowLoader(false);
        withoutRating ? setLoader1(false) : setLoader2(false);
      })
      .catch((err) => {
        setShowLoader(false);
        withoutRating ? setLoader1(false) : setLoader2(false);

        FailureAlert(err.message);
      });
  };
  const getOpenJobsList = () => {
    niyuktiAxiosCall
      .get(`/job/all_job/list/`)
      .then((res) => {
        if (res.data.success) {
          setOpenJobs(res.data.data);
        } /* else {
          setOpenJobsFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        } */
      })
      .catch((err) => {
        // setOpenJobsFlag(true);
        // setAllApiSuccess({
        //   status: -1,
        //   message: `Open Job List Error : ${err.message}`,
        // });
      });
  };

  const getCompayHrList = () => {
    niyuktiAxiosCall
      .get(
        `job/admin/company_hr_list/?job_id=${
          jobId ? jobId : selectedJob.job_id
        }`
      )
      .then((res) => {
        if (res.data.success) {
          setCompanyHrs(res.data.data);
        } else {
          FailureAlert("Error getting Hrs name");
        }
      })
      .catch((err) => {
        FailureAlert("Error :", err);
      });
  };
  useEffect(() => {
    if (selectedJob || jobId) getCompayHrList();
  }, [selectedJob]);

  const checkAlreadyApplied = () => {
    setStudentJobMatchingData(initialJobMatchingData);
    setShowLoader(true);
    niyuktiAxiosCall
      .get(
        `job/admin/applicant_rating/?user_id=${userId}&job_id=${selectedJob?.job_id}`
      )
      .then((res) => {
        if (res.data.success) {
          if (res?.data?.data?.student_job_matching_data) {
            setStudentJobMatchingData(
              res?.data?.data?.student_job_matching_data
            );
          }

          //  setOpenJobs(res.data.data);
          // } else {
          //   setOpenJobsFlag(true);
          //   setAllApiSuccess({ status: -1, message: res.error });
        }
        setShowLoader(false);
      })
      .catch((err) => {
        // setOpenJobsFlag(true);
        // setAllApiSuccess({
        //   status: -1,
        //   message: `Open Job List Error : ${err.message}`,
        // });
        setShowLoader(false);
      });
  };
  const handleApplyAndRate = (withoutRating) => {
    if (selectedJob?.job_id && userId) applyToJob(withoutRating);
  };
  useEffect(() => {
    if (open) {
      getOpenJobsList();
    }
  }, [open]);

  useEffect(() => {
    if (selectedJob?.job_id && userId) checkAlreadyApplied();
  }, [selectedJob]);

  const handleClose1 = () => {
    setStudentJobMatchingData(initialJobMatchingData);
    setSelectedJob();
    setRateApply(false);
    handleClose();
  };
  const [rateApply, setRateApply] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
      event.target.blur();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        {/* <DialogTitle id="form-dialog-title">
          Apply To Job  on Job - {jobTitle} : {jobId}}
        </DialogTitle> */}
        <DialogTitle
          id="form-dialog-title"
          style={{ paddingLeft: 32, paddingRight: 32 }}
        >
          Apply To Job {/* on Job - {jobTitle} : {jobId} */}
        </DialogTitle>

        <LoadingOverlay active={showLoader} spinner text="Please Wait...">
          <DialogContent>
            <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
              {/* <Typography variant="h6" id="form-dialog-title">
                Apply To Job 
              </Typography>
              <br /> */}

              <Grid item md={12}>
                <Autocomplete
                  value={selectedJob}
                  onChange={(event, newValue) => {
                    if (newValue == null) {
                      setSelectedHr(null);
                      setSelectedSource(null);
                      setselectedOtherSource(null);
                    }
                    setSelectedJob(newValue);
                  }}
                  id="role345"
                  options={openJobs}
                  getOptionLabel={(option) =>
                    `${option.job_title}-${option.hiring_type} (${option.job_id})`
                  }
                  disabled={jobId || fromPreApplied ? true : false}
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option.job_title} -${option.hiring_type} (${option.job_id})`}</span>
                    );
                  }}
                  /* className={classes.fieldStyling1} */
                  renderInput={(params) => {
                    // params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={
                          <span style={{ fontSize: 13 }}>
                            Select your job{" "}
                            <span /* className={classes.RedStar} */>*</span>
                          </span>
                        }
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
              {selectedJob ? (
                <Grid item md={12}>
                  <Autocomplete
                    value={selectedHr}
                    freeSolo
                    filterSelectedOptions
                    id="select-hr"
                    options={comapnyHrs}
                    getOptionLabel={(item) =>
                      item.charAt(0).toUpperCase() + item.slice(1)
                    }
                    onChange={(e, item) => {
                      if (item == null) setSelectedSource(null);
                      setSelectedHr(item);
                    }}
                    onInputChange={(event, newValue) => {
                      if (newValue !== null) {
                        setSelectedHr(newValue.trim());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select/Enter Hr Name*"
                        variant="outlined"
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {selectedHr ? (
                <Grid item md={12}>
                  <Autocomplete
                    value={selectedSource}
                    id="select-hr"
                    options={SourceList}
                    getOptionLabel={(item) => (item ? item.title : "")}
                    onChange={(e, item) => {
                      if (item == null) setselectedOtherSource(null);

                      setSelectedSource(item);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select UTM source*"
                        variant="outlined"
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {selectedSource?.title === "Other" ? (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{
                    style: {
                      padding: "16px",
                    },
                  }}
                  style={{ width: "536px", margin: "auto", marginTop: "8px" }}
                  label="Enter UTM source"
                  type="text"
                  value={selectedOtherSource}
                  onChange={(e) => {
                    setselectedOtherSource(
                      e.target.value
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")
                    );
                  }}
                />
              ) : null}
              {rateApply && (
                <RatingDialog
                  studentJobMatchingData={studentJobMatchingData}
                  setStudentJobMatchingData={setStudentJobMatchingData}
                />
              )}

              {/* <br />

              <div style={{ marginBottom: 10 }}>
                <Typography variant="h6">
                  Rate Applicant - {studentName}{" "}
                  {rating > 0 && `: ${rating}/10 `}
                </Typography>
              </div>
              <br />
              <div style={{ marginBottom: 10 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">CheckList</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={location}
                          onChange={handleChange}
                          name="location"
                        />
                      }
                      label="Location Match"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salary}
                          onChange={handleChange}
                          name="salary"
                        />
                      }
                      label="Salary Match"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={work_exp}
                          onChange={handleChange}
                          name="work_exp"
                        />
                      }
                      label="Work Experience Match"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={education}
                          onChange={handleChange}
                          name="education"
                        />
                      }
                      label="Education Match"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div style={{ marginBottom: 20 }}>
                <Typography id="discrete-slider-custom" gutterBottom>
                  Key Skills
                </Typography>
                <Slider
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  valueLabelDisplay="auto"
                  name="key_skills"
                  marks={marks}
                  min={1}
                  max={10}
                  value={studentJobMatchingData.key_skills}
                  onChange={handleKeySkills}
                />
              </div>
              <div style={{ marginBottom: 20 }}>
                <Typography id="discrete-slider-custom" gutterBottom>
                  Communication Skills
                </Typography>
                <Slider
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  valueLabelDisplay="auto"
                  marks={marks}
                  min={1}
                  max={10}
                  value={studentJobMatchingData.communication_skills}
                  onChange={handleCommunicationSkills}
                />
              </div> */}
              {/* <FormControl  //className={classes.formControl}>
                <InputLabel shrink htmlFor="status-native-label-placeholder">
                  Choose Status:
                </InputLabel>
                <NativeSelect onChange={(e) => setStatusMoveId(e.target.value)}>
                  <option value={""}>Getwork Screened</option>
                  {Object.keys(statusCodes).map((st, k) => {
                    return <option value={st}>{statusCodes[st]}</option>;
                  })}
                </NativeSelect>
              </FormControl>
  
              {statusCodes[statusMoveId] === "Rejected" && (
                <FormControl
                  //className={classes.formControl}  style={{
                    marginLeft: "10px",
                  }}
                >
                  <TextField
                    //className={classes.textField}
                    id="reject-reason"
                    label="Reason To Reject"
                    onChange={(e) => setRejectReason(e.target.value)}
                  />
                </FormControl>
              )} */}
            </Grid>
          </DialogContent>
        </LoadingOverlay>
        <DialogActions
          style={{ paddingLeft: 32, paddingRight: 32, paddingBottom: 15 }}
        >
          <Button
            variant="contained"
            disabled={showLoader}
            onClick={
              !rateApply
                ? () => {
                    if (!selectedJob?.job_id) {
                      FailureAlert("Please Select the Job");
                      return;
                    } else if (!selectedHr) {
                      FailureAlert("Please Select/Enter Hr name");
                    } else if (
                      !selectedSource ||
                      (selectedSource.value === "Other" && !selectedOtherSource)
                    ) {
                      FailureAlert("Please Select the UTM source");
                    } else {
                      handleApplyAndRate(1);
                    }
                  }
                : handleClose1
            }
            color="primary"
          >
            {loader1 ? (
              <CircularProgress
                style={{
                  color: "white",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : !rateApply ? (
              "Apply Without Rating"
            ) : (
              "Cancel"
            )}
          </Button>
          <Button
            /* onClick={handleClose} */
            color="primary"
            variant="contained"
            disabled={showLoader}
            onClick={
              !rateApply
                ? () => setRateApply(true)
                : () => {
                    if (!selectedJob?.job_id) {
                      FailureAlert("Please Select the Job");
                      return;
                    } else if (!selectedHr) {
                      FailureAlert("Please Select/Enter Hr name");
                    } else if (
                      !studentJobMatchingData?.communication_skills > 0 &&
                      !studentJobMatchingData?.key_skills > 0
                    ) {
                      FailureAlert("Please Rate Skills");
                    } else if (
                      !selectedSource ||
                      (selectedSource.value === "Other" && !selectedOtherSource)
                    ) {
                      FailureAlert("Please Select the UTM source");
                    } else {
                      handleApplyAndRate(0);
                      // setRateApply(false);
                    }
                  }
            }
          >
            {loader2 ? (
              <CircularProgress
                style={{
                  color: "white",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              "Rate And Apply"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const allHrs = ["Hr1", "Hr2", "hr3", "hr4"];

const SourceList = [
  {
    title: "Naukri",
    value: "Naukri",
  },
  {
    title: "Shine",
    value: "Shine",
  },
  {
    title: "Monster",
    value: "Monster",
  },
  {
    title: "Other",
    value: "Other",
  },
];
