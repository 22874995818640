import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { cellCSS, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import generateCsv from "../../util/generateCsv";
import { FailureAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";


const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "15px",
    marginBottom: "20px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function AllCoupan(props) {
  const classes = useStyles();

 
  const history = useHistory();
  

  const columns = [
    {
      title: "Vendor",
      field: "vendor_name",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Vendor",
    },
    {
      title: "Category",
      field: "category_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      // lookup: universitiesFilter,
      filterPlaceholder: "Category",
    },
    {
      title: "Sub Category",
      field: "sub_category_list",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
      filtering: false,
      // filterPlaceholder: "Product/Service",
    },
    {
      title: "Expiry Date",
      field: "expiry",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      //   filtering: false,
      // lookup: primaryFilter,
      filterPlaceholder: "Expiry Date",
      filtering: false,
      render: (row) => <>{moment(row.expiry).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Coupon Title",
      field: "title",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Coupon type",
      field: "type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Coupon Type",
      filtering: false,
    },
    {
      title: "Coupon Value",
      field: "value",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Coupon code",
      field: "code",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
      filtering: false,
    },
    {
      title: "Actions",
      field: "actions",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
      render: (row) => (
        <>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/admin/add-coupons/" + row?.id);
            }}
          />
          {/* &nbsp;
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteService(row?.id, dataProducts);
            }}
          /> */}
        </>
      ),
    },
    // {
    //   title: "Details",
    //   field: "details",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    // },
    // {
    //   title: "Terms &  Conditions",
    //   field: "terms",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    // },
    // {
    //   title: "How to Redeem",
    //   field: "terms",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    // },
  ];

  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/coupons/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            item["sub_category_list"] = generateCsv(item?.sub_category, "title");
            item["actions"] = "actions";
          });
          setDataCategory(json);
        } else FailureAlert(" Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  return (
    <div className="all__colleges">
      <Grid container spacing={3} className={classes.action}>
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
        </Grid>
        <Grid item xs={2}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              style={{ minWidth: "145px" }}
              color="primary"
              onClick={() => {
                history.push("/admin/add-coupons");
              }}
              startIcon={<AddIcon className={classes.icon} />}
            >
              Add New
            </Button>
          </div>
        </Grid>
      </Grid>
      <TableWithExport
        title="Coupon"
        columns={columns}
        data={dataCategory}
        fName="Coupons"
        // editableObject={edit}
      />
    </div>
  );
}

export default withRouter(AllCoupan);
