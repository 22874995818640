import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { cellCSS, headerCSS, praveshUrl } from "../../constants";
import { apiPraveshCall, niyuktiAxiosGetReq, niyuktiAxiosPostReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import TableWithoutExport from "../TableWithoutExport";
import axios from "axios";

function Subscribers() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${praveshUrl}/api/admin/admin_podcast_news/`,{withCredentials:true})
      .then((res) => {
        if (res?.data?.success) {
          setData(res?.data?.data);
        } else {
          FailureAlert("Error in Getting Vendor Jobs");
        }
      })
      .catch((err) => {
        FailureAlert("Error in Getting Vendor Jobs");
      });
  }, []);


  const columns = [
    {
      title: "Email",
      field: "email",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
      },
      filtering: false,
    },

    {
      title: "Created Date",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <TableWithoutExport
        title="Subscribers"
        columns={columns}
        selection={false}
        data={data}
      />
    </div>
  );
}

export default Subscribers;
