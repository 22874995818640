import React from "react";
import { withRouter } from "react-router";
import Main from "../../components/Jobs/Interview/Main";

const token = localStorage.getItem("token");

function AllInterviews(props) {
  
    return (
    <div>
      <Main />
    </div>
  );
}

export default withRouter(AllInterviews);
