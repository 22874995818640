import React from 'react'
import { useState, useEffect } from 'react'
import ChoosePlan from '../../components/Company/NewCompanyStepper/PlanItem/ChoosePlan'
import Hiring from '../../components/Company/NewCompanyStepper/PlanItem/Hiring';
import { praveshAxiosGetReq } from '../../util/baseApi';
import { Box, Typography, makeStyles, Button, TextField } from "@material-ui/core";
import OrderSummary from '../../components/Company/NewCompanyStepper/OrderSummary';
import GWLogoLoader from '../../components/common/Loaders/GWLogoLoader';
import { Autocomplete } from "@material-ui/lab";
import ThankYouForPayment from '../../components/common/Payment/ThankYouForPayment';


const useStyles = makeStyles(() => ({

    subheading: {
        fontStyle: "italic",
        fontWeight: "600",
        marginTop: '.5rem',
        marginBottom: '.5rem',
        color: '#007bff',
    },
    stepBtn: {

        marginLeft: 10,
    },
    stepBtnSection: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: '1rem',
    }

}))


const CompanyPayment = () => {

    const classes = useStyles();
    const [planDetails, setPlanDetails] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [companyList, setCompanyList] = useState([]);
    const [companySelected, setcompanySelected] = useState(null);

    const initialCompanyPurchaseDetails = {
        planDuration: 'monthly',
        companyId: '',
        planSelected: false,
        paymentType: '',
    }
    const [companyPurchaseDetails, setCompanyPurchaseDetails] =
        useState(initialCompanyPurchaseDetails);

    const [loading, setLoading] = useState(false);

    const getCompanyList = async () => {

        const endPoint = '/api/admin/company/get/';
        setLoading(true);

        try {
            const res = await praveshAxiosGetReq(endPoint);

            if (res?.data?.success) {
                setCompanyList(res?.data?.data);
            };
            setLoading(false);


        } catch (error) {

            setLoading(false);
            throw (error);
        }
    };


    useEffect(() => {

        if (planDetails?.length === 0) {

            getPlans();
        };
        if (companyList?.length === 0) {
            getCompanyList();
        }

    }, []);

    const getPlans = async () => {

        const endPoint = `/api/admin/plans/`;
        setLoading(true);

        try {

            const res = await praveshAxiosGetReq(endPoint);

            if (res?.data?.success) {
                setPlanDetails({ ...res?.data?.data, planDuration: "monthly" });
            }
            setLoading(false);


        } catch (error) {

            setLoading(false);
            throw (error);
        }
    }

    const getStepcontent = () => {

        switch (activeStep) {
            case 1: return (
                <>
                    <Box>
                        <Hiring
                            planDetails={planDetails}
                            setPlanDetails={setPlanDetails}
                            handleForward={() => {
                                setActiveStep(activeStep + 1);
                                setCompanyPurchaseDetails((prevData) => ({ ...prevData, planSelected: false }))

                            }}
                        />
                    </Box>
                    <br></br>
                    <ChoosePlan
                        planDetails={planDetails}
                        setPlanDetails={setPlanDetails}
                        handleForward={(flag) => {

                            setCompanyPurchaseDetails((prevData) => ({ ...prevData, planSelected: true }))
                            setActiveStep(activeStep + 1)
                        }}
                        setCompanyPurchaseDetails={setCompanyPurchaseDetails}
                    />

                </>
            )
            case (2): return (

                <OrderSummary
                    planDetails={planDetails}
                    setPlanDetails={setPlanDetails}
                    setActiveStep={setActiveStep}
                    handleForward={() => { }}
                    companyPurchaseDetails={companyPurchaseDetails}
                    setCompanyPurchaseDetails={setCompanyPurchaseDetails}
                />

            )
            case (3): return (

                <ThankYouForPayment
                    handleReset={handleResetAfterOrderBooked}

                />
            )

        }
    }

    const resetSlotCounts = () => {

        let tempSlotList = { ...planDetails?.slot_list }

        for (let i in tempSlotList) {

            for (let j in tempSlotList[i]) {

                tempSlotList[i][j] = { ...tempSlotList[i][j], slotsBooked: 0 }
            }
        }

        setPlanDetails({ ...planDetails, slot_list: tempSlotList });
    }

    const handleResetAfterOrderBooked = () => {

        setActiveStep(1);
        setCompanyPurchaseDetails(initialCompanyPurchaseDetails);
        setcompanySelected(null);
        resetSlotCounts();

    }

    if (loading) {

        return (
            <GWLogoLoader />
        )
    }

    return (
        <>
            <Box>
                {
                    activeStep === 3 ? null :
                        <Box style={{ marginTop: "10px" }}>
                            {/* <Typography variant="h5">Book Company Slot Now</Typography> */}
                            <Box>
                                <Autocomplete
                                    id="planForCompany"
                                    name="newplan"
                                    options={companyList}
                                    getOptionLabel={(item) => item?.company_name}
                                    value={companySelected}
                                    onChange={(e, newValue) => {
                                        if (newValue) {

                                            setcompanySelected(newValue);
                                            setCompanyPurchaseDetails((prevData) => ({ ...prevData, companyId: newValue?.company_id }))
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Company*"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                }

                <>
                    {
                        companySelected ?
                            <>
                                {
                                    activeStep === 3 ? null :
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                            className={classes.subheading}
                                        // style={{ fontStyle: "italic", mt: 1, mb: 1, fontWeight: "400" }}
                                        >
                                            It's time for you to book this NOW! Effortlessly.
                                        </Typography>
                                }
                                {
                                    getStepcontent()
                                }
                                <Box className={classes.stepBtnSection}>

                                    {/* <Button
                                        className={classes.stepBtn}
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {

                                            if (activeStep !== 2) {

                                                setActiveStep((prevData) => (prevData + 1))
                                            }
                                        }}
                                    >
                                        Next Step
                                    </Button> */}
                                    {
                                        activeStep === 2 ?
                                            <Button
                                                className={classes.stepBtn}
                                                style={{
                                                    boxShadow: "none",
                                                    borderRadius: "30px",
                                                    color: "white",
                                                    backgroundColor: '#3F51B5'
                                                }}
                                                variant='contained'
                                                // color='primary'
                                                onClick={() => {
                                                    if (activeStep !== 1) {

                                                        setActiveStep((prevData) => (prevData - 1))
                                                    }
                                                }}
                                            >
                                                Previous Step
                                            </Button>
                                            : null
                                    }
                                </Box>

                            </>

                            : null

                    }

                </>
            </Box>
        </>
    )
}

export default CompanyPayment