import React, { useState, useEffect } from "react";
import { characterCount } from "../../util/wordCounter";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../css/AddCompany.css";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { apiCall, apiNiyuktiCall, praveshAxiosGetReq, praveshAxiosPostReq } from "../../util/apiCall";
import {
  gifStyling,
  praveshUrl,
  requestNiyuktiOptions,
  requestPraveshOptions,
} from "../../constants";
import { Form, Col, Button } from "react-bootstrap";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import gif from "../../assets/loopingcircles-3.gif";
import { Box } from "@material-ui/core";
import { uniqueArray } from "../../util/uniqueArray";
import axios from "axios";

const token = localStorage.getItem("token");

function AddCompany() {
  const [loading, setLoading] = useState(false);

  const [pocFirstName, setFirstPocName] = useState("");
  const [pocLastName, setLastPocName] = useState("");
  const [pocPhone, setPocPhone] = useState("");
  const [altPocPhone, setAltPocPhone] = useState("");
  const [pocDesignation, setPocDesignation] = useState("");
  const [pocEmail, setPocEmail] = useState("");
  const [altPocEmail, setAltPocEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [nickName, setNickName] = useState("");
  const initialDetailsState = {
      registeredCompanyName:'', 
      gstin:'',
      companyCategory:'',
  }
  const [companyDetails,setCompanyDetails] =useState(initialDetailsState)
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [description, setDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [specialities, setSpecialities] = useState("");
  const [size, setSize] = useState("");
  const [year, setYear] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [onboard, setOnboard] = useState("");
  const [active, setActive] = useState("");
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [citiesState, setCitiesState] = useState({});
  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [coworkingId, setCoworkingId] = useState(null);
  const [industryId, setIndustryId] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [companyCategories,setCompanyCategories] = useState([]);
  const [coworkings, setCoworkings] = useState([]);
  const [industries, setIndustries] = useState([]);

  const [aboutCount, setAboutCount] = useState(0);

  const [allJobSegments, setAllJobSegments] = useState([]);
  const [selectedJobSegments, setSelectedJobSegments] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);

  const [salesPersonList, setSalesPersonList] = useState([]);
  const [salesPersonDetail, setSalesPersonDetail] = useState(null);

  const workExperiences = [
    { id: 1, name: "Fresher" },
    { id: 2, name: "1 Year" },
    { id: 3, name: "2 Years" },
    { id: 4, name: "3 Years" },
  ];

  // Finding Job Types
  useEffect(() => {
    apiNiyuktiCall("/job/type/", requestNiyuktiOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].job_type,
          };
          tmpArray.push(obj);
        }
        setJobTypes(tmpArray);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities
  useEffect(() => {
    apiCall("/api/location/city/", requestPraveshOptions).then((json) => {
      setAllCities(json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities, State
  useEffect(() => {
    apiCall("/api/location/city_state/", requestPraveshOptions).then((json) => {
      setCitiesState(json.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Job Segment Finding API
  useEffect(() => {
    apiNiyuktiCall("/job/segment/", requestNiyuktiOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].job_segment,
          };
          tmpArray.push(obj);
        }
        setAllJobSegments(tmpArray);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Country Finding API
  useEffect(() => {
    apiCall("/api/location/country/", requestPraveshOptions).then((res) => {
      setCountries(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // State Finding API
  useEffect(() => {
    apiCall(
      `/api/location/state/?search=${countryId}`,
      requestPraveshOptions
    ).then((res) => {
      setStates(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  // City Finding API
  useEffect(() => {
    apiCall(
      `/api/location/city/?search=${stateId}`,
      requestPraveshOptions
    ).then((res) => {
      setCities(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  // Coworking ID
  useEffect(() => {
    apiCall("/api/admin/coworking/", requestPraveshOptions).then((json) => {
      if (json.success) {
        setCoworkings(json.data);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getCompanyCategory = async () => {

          const endPoint = `/api/shared/get/company/type/`;

          try {
            const res = await praveshAxiosGetReq(endPoint);

            if(res?.data?.success){

              setCompanyCategories(res?.data?.data);

            }
          } catch (error) {

            throw (error);

          }
  }
  // Industry ID && companyCategory
  useEffect(() => {
    apiCall("/api/admin/industry/", requestPraveshOptions).then((json) => {
      if (json.success) {
        setIndustries(json.data);
      } else {
        FailureAlert(json.error);
      }
    });

    if(companyCategories?.length === 0) {

      getCompanyCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCompany = (e) => {
    e.preventDefault();
    if (!industryId) {
      FailureAlert("Choose Industry");
      return;
    }
    if (size === "") {
      FailureAlert("Select Company Size...");
      return;
    }
    if (aboutCount > 255) {
      FailureAlert("College About can only have upto 255 characters...");
      return;
    }
    if (organizationType === "") {
      FailureAlert("Select Organization Type...");
      return;
    }
    // if(countryId==='') { FailureAlert('Select Country...'); return; }
    if (stateId === 0) {
      FailureAlert("Select State...");
      return;
    }
    if (cityId === 0) {
      FailureAlert("Select City...");
      return;
    }
    if (onboard === "") {
      FailureAlert("Select Onboard Status...");
      return;
    }
    if (active === "") {
      FailureAlert("Select Active Status...");
      return;
    }
    if (!logo) {
      FailureAlert("Upload Company Logo...");
      return;
    }
    if (!salesPersonDetail?.user_id) {
      FailureAlert("Upload ...");
      return;
    }
    if (!companyDetails?.companyCategory?.id) {
      FailureAlert("Select Company Category...");
      return;
    }

    let preferences = {};
    preferences["work_exp"] = selectedExperience;
    preferences["job_type"] = selectedJobType;
    preferences["location"] = selectedCities;
    preferences["job_segment"] = selectedJobSegments;

    setLoading(true);
    const formData = new FormData();
    formData.append("person_first_name", pocFirstName);
    formData.append("person_last_name", pocLastName);
    formData.append("person_mobile", pocPhone);
    formData.append("person_email", pocEmail);
    altPocPhone && formData.append("alternate_phone", altPocPhone);
    altPocEmail && formData.append("alternate_email", altPocEmail);
    formData.append("person_designation", pocDesignation);
    coworkingId && formData.append("coworking_id", coworkingId);
    formData.append("industry_id", industryId);
    formData.append("company_name", companyName);
    formData.append("company_nickname", nickName);
    formData.append("registered_company_name",companyDetails.registeredCompanyName );
    formData.append("gstin",companyDetails.gstin );
    formData.append("about", about);
    formData.append("company_description", description);
    formData.append("address", address);
    formData.append("organization_type", organizationType);
    formData.append("size", size);
    formData.append("specialities", specialities);
    formData.append("year_founded", `${year}-01-01`);
    formData.append("website", websiteUrl);
    logo && formData.append("logo", logo);
    banner && formData.append("banner", banner);
    formData.append("country", countryId);
    formData.append("state", stateId);
    formData.append("city", cityId);
    formData.append("preferences", JSON.stringify(preferences));
    formData.append(
      "social_links",
      JSON.stringify([
        { id: "1", Name: "LinkedIn", value: linkedIn },
        { id: "2", Name: "Facebook", value: facebook },
        { id: "3", Name: "Instagram", value: insta },
      ])
    );
    formData.append("is_onboarded", onboard);
    formData.append("is_active", active);
    formData.append("sales_person", salesPersonDetail?.user_id);
    formData.append("type_id", companyDetails?.companyCategory?.id);
    
    
    const postNewCompany = async () => {

      const endPoint = `/api/admin/company/`;

      try {
        const res = await praveshAxiosPostReq(endPoint,formData);

        if(res?.data?.success){
           SuccessAlert(res?.data?.data?.message);
           setInterval(function () {
          window.location.reload();
        }, 500);
        setEditorState(EditorState.createEmpty());
        }else{

          FailureAlert(res?.error?.message);
        };

        setLoading(false);


      } catch (error) {
          setLoading(false);

          throw (error);
      }
    };

    postNewCompany();    

  };

  const onEditorStateChange = (s) => {
    setEditorState(s);
    setSpecialities(draftToHtml(convertToRaw(s.getCurrentContent())));
  };

  const getSalesPersonList = async () => {
    const url = `/api/common/selected/user_type/get_list/?user_type_ids=10,14`;
    axios
      .get(`${praveshUrl}${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          // console.log(res?.data?.data?.data);
          setSalesPersonList(res?.data?.data?.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSalesPersonList();
  }, []);
  return !loading ? (
    <div className="add__company">
      <Form
        className="add__company__form"
        id="add__company__form__details"
        onSubmit={(e) => {
          addCompany(e);
        }}
      >
        <h4>Company Person Details</h4>
        <hr />
        <Form.Row>
          <Form.Group as={Col} controlId="pocFirstName">
            <Form.Control
              name="pocFirstName"
              type="text"
              placeholder="Person's First Name*"
              required
              onChange={(e) => {
                setFirstPocName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="pocLastName">
            <Form.Control
              name="pocLastName"
              type="text"
              placeholder="Person's Last Name*"
              required
              onChange={(e) => {
                setLastPocName(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="pocDesignation">
          <Form.Control
            name="pocDesignation"
            type="text"
            placeholder="Person's Designation*"
            required
            onChange={(e) => {
              setPocDesignation(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="pocEmail">
            <Form.Control
              name="pocEmail"
              type="email"
              placeholder="POC Email*"
              required
              onChange={(e) => {
                setPocEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="pocContact">
            <Form.Control
              name="pocContact"
              type="text"
              placeholder="POC Phone Number*"
              required
              pattern="[0-9]*"
              maxLength="10"
              minLength="10"
              onChange={(e) => {
                setPocPhone(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="pocEmail">
            <Form.Control
              name="altpocEmail"
              type="email"
              placeholder="POC Alternate Email (optional)"
              onChange={(e) => {
                setAltPocEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="pocContact">
            <Form.Control
              name="altpocContact"
              type="text"
              placeholder="POC Alternate Phone Number (optional)"
              pattern="[0-9]*"
              maxLength="10"
              minLength="10"
              onChange={(e) => {
                setAltPocPhone(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <h4>General Details</h4>
        <hr />
        {/* Coworking Id and industry Id */}
        <Form.Row>
          <Form.Group as={Col} className="typeSearchAutoCompleteCoworking">
            <Autocomplete
              id="coworking"
              options={coworkings}
              getOptionLabel={(item) => item.name}
              onChange={(e, item) =>
                item ? setCoworkingId(item.id) : setCoworkingId(null)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Co-working (Optional)"
                  variant="outlined"
                  style={{ display: "flex" }}
                />
              )}
            />
          </Form.Group>
          <Form.Group as={Col} className="typeSearchAutoCompleteIndustry">
            <Autocomplete
              id="industry"
              options={industries}
              getOptionLabel={(item) => item.industry_name}
              onChange={(e, item) =>
                item ? setIndustryId(item.id) : setIndustryId(null)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Industry*"
                  variant="outlined"
                  style={{ display: "flex" }}
                />
              )}
            />
          </Form.Group>
        </Form.Row>
        {/* Company name & nick name */}
        <Form.Row>
          <Form.Group as={Col} controlId="companyName">
            <Form.Control
              name="companyName"
              type="text"
              placeholder="Company Name*"
              required
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="companyNickName">
            <Form.Control
              name="companyNickName"
              type="text"
              placeholder="Company Nick Name*"
              required
              onChange={(e) => {
                setNickName(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
         <Form.Row>
          <Form.Group as={Col} controlId="registeredCompanyName">
            <Form.Control
              name="registeredCompanyName"
              type="text"
              placeholder="Registered Company Name"
              // required
              onChange={(e) => {
                setCompanyDetails({...companyDetails,registeredCompanyName:e.target.value})
                // setCompanyName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="gstin">
            <Form.Control
              name="gstin"
              type="text"
              placeholder="GSTIN"
              // required
              onChange={(e) => {
                setCompanyDetails({...companyDetails,gstin:e.target.value});
                
                // setNickName(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        {/* Company Address */}
        <Form.Group controlId="companyAddress">
          <Form.Control
            name="companyAddress"
            as="textarea"
            placeholder="Company Address*"
            required
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Form.Group>
        {/* Company Description */}
        <Form.Group controlId="companyDescription">
          <Form.Control
            name="companyDescription"
            as="textarea"
            placeholder="Company Description*"
            required
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Group>
        {/* Year founded & company size */}
        <Form.Row>
          <Form.Group as={Col} controlId="year">
            <Form.Label>Year Founded*</Form.Label>
            <Form.Control
              name="year"
              type="text"
              placeholder="Year Founded*"
              required
              pattern="[0-9]*"
              maxLength="4"
              minLength="4"
              onChange={(e) => {
                setYear(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="size">
            <Form.Label>Company Size*</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setSize(e.target.value);
              }}
            >
              <option value="">--select--</option>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-100">51-100</option>
              <option value="101-1000">101-1000</option>
              <option value="1000+">1000+</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        {/* Company About */}
        <Form.Label>
          Company About*
          {aboutCount <= 255 ? (
            <i style={{ fontSize: "13px", color: "green" }}>
              {" "}
              Total Characters: {aboutCount} out of 255
            </i>
          ) : (
            <i style={{ fontSize: "13px", color: "red" }}>
              {" "}
              Character Limit Exceeded (max 255 characters) : Character Count :{" "}
              {aboutCount}
            </i>
          )}
        </Form.Label>
        <Form.Group controlId="companyAbout">
          <Form.Control
            name="companyAbout"
            as="textarea"
            rows="2"
            placeholder={`Company About*`}
            required
            onChange={(e) => {
              setAbout(e.target.value);
              e.target.value && setAboutCount(characterCount(e.target.value));
              !e.target.value && setAboutCount(0);
            }}
          />
        </Form.Group>
          <Form.Row>

          
          <br></br>     

         {/* Organization Type */}
        <Form.Group  as={Col}  type="radio"
         id="organizationType" required>
        <Form.Label>Select Organization Type* : </Form.Label>
          <Form.Check
            inline
            id="government"
            value="Government"
            type="radio"
            name="organizationType"
            label="Government"
            onChange={(e) => {
              setOrganizationType(e.target.value);
            }}
          />
          <Form.Check
            inline
            id="semiGovernment"
            value="SemiPrivate"
            type="radio"
            name="organizationType"
            label="Semi Private"
            onChange={(e) => {
              setOrganizationType(e.target.value);
            }}
          />
          <Form.Check
            inline
            id="private"
            value="Private"
            type="radio"
            name="organizationType"
            label="Private"
            onChange={(e) => {
              setOrganizationType(e.target.value);
            }}
          />
        </Form.Group>

       </Form.Row>
       <Form.Row>            

          <Form.Group as={Col} className="typeSearchAutoCompleteIndustry" controlId="companyCategory">
          {/* <Form.Label>Select Company Category*: </Form.Label> */}
             < Autocomplete         
              id="companyCategory"
              options={companyCategories}
              getOptionLabel={(item) => item?.type}
              value={companyDetails?.companyCategory}
              onChange={(e, item) =>{
                const newItem = item ? item : null;
                setCompanyDetails((prevData) => ({...prevData,companyCategory:newItem}))
              }               
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category*"
                  variant="outlined"
                  style={{ display: "flex" }}
                />
                )}
             />
          </Form.Group>  
           {/* Wesite URL */}
        <Form.Group as={Col} controlId="websiteUrl">
          <Form.Control
            name="websiteUrl"
            type="text"
            placeholder="Company Website URL"
            onChange={(e) => {
              setWebsiteUrl(e.target.value);
            }}
          />
        </Form.Group>

       </Form.Row>

      
       
       

        {/* Social Links */}
        <Form.Label>Social Links : </Form.Label>
        <Form.Group controlId="linkedInPage">
          <Form.Control
            name="linkedInPage"
            type="text"
            placeholder="LinkedIn Page"
            onChange={(e) => {
              setLinkedIn(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="facebookPage">
          <Form.Control
            name="facebookPage"
            type="text"
            placeholder="Facebook Page"
            onChange={(e) => {
              setFacebook(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="instaAccount">
          <Form.Control
            name="instaAccount"
            type="text"
            placeholder="Instagram Account"
            onChange={(e) => {
              setInsta(e.target.value);
            }}
          />
        </Form.Group>

        <h4>Location*</h4>
        <hr />
        <Form.Row className="typeSearchLocation">
          <Form.Group as={Col} controlId="country">
            <Autocomplete
              id="select-country"
              options={countries}
              getOptionLabel={(item) => item.country}
              onChange={(e, item) =>
                item ? setCountryId(item.country_id) : setCountryId(1)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Country"
                  variant="outlined"
                  style={{ display: "flex" }}
                />
              )}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="state">
            <Autocomplete
              id="select-state"
              options={states}
              getOptionLabel={(item) => item.state}
              onChange={(e, item) =>
                item ? setStateId(item.state_id) : setStateId(0)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select State"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="city">
            <Autocomplete
              id="select-city"
              options={cities}
              getOptionLabel={(item) => item.city}
              onChange={(e, item) =>
                item ? setCityId(item.city_id) : setCityId(0)
              }
              renderInput={(params) => (
                <TextField {...params} label="Select City" variant="outlined" />
              )}
            />
          </Form.Group>
        </Form.Row>

        <h4>Preferences</h4>
        <hr />
        <Box
          className="typeSearchPreferences"
          boxShadow={2}
          style={{ padding: "16px 1rem", marginBottom: "1rem" }}
        >
          <Form.Group>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-job_type"
              options={jobTypes}
              getOptionLabel={(item) => item.name}
              onChange={(e, i) =>
                i ? setSelectedJobType(i) : setSelectedJobType({})
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Job Type"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-experience"
              options={workExperiences}
              getOptionLabel={(item) => item.name}
              onChange={(e, i) =>
                i
                  ? setSelectedExperience(uniqueArray(i))
                  : setSelectedExperience([])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Work Experience"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-cities"
              options={citiesState}
              getOptionLabel={(option) => `${option.city}, ${option.state}`}
              onChange={(e, i) =>
                i ? setSelectedCities(i) : setSelectedCities([])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Cities"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="select-job-segments"
              options={allJobSegments}
              getOptionLabel={(item) => item.name}
              onChange={(e, item) =>
                item ? setSelectedJobSegments(item) : setSelectedJobSegments([])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Job Segments"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
        </Box>
        <h4>Other Details</h4>
        <hr />
        <Form.Label>Specialities</Form.Label>
        <div className="editor">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <Form.Label>
          <u>Onboard*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="onboard">
          <Form.Check
            inline
            id="yes"
            type="radio"
            name="onboard"
            label="Yes"
            value="true"
            onChange={(e) => setOnboard(e.target.value)}
          />
          <Form.Check
            inline
            id="no"
            type="radio"
            name="onboard"
            label="No"
            value="false"
            onChange={(e) => setOnboard(e.target.value)}
          />
        </Form.Group>

        <Form.Label>
          <u>Active*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="active">
          <Form.Check
            inline
            id="yes"
            type="radio"
            name="active"
            label="Yes"
            value="true"
            onChange={(e) => setActive(e.target.value)}
          />
          <Form.Check
            inline
            id="no"
            type="radio"
            name="active"
            label="No"
            value="false"
            onChange={(e) => setActive(e.target.value)}
          />
        </Form.Group>
        <br />

        <Form.File id="companyLogo">
          <Form.File.Label>Upload Company Logo*</Form.File.Label>
          <Form.File.Input onChange={(e) => setLogo(e.target.files[0])} />
          <p>Recommended Size: 150px*150px</p>
        </Form.File>
        <br />
        <Form.File id="companyBanner">
          <Form.File.Label>Upload Company Banner (optional)</Form.File.Label>
          <Form.File.Input onChange={(e) => setBanner(e.target.files[0])} />
          <p>Recommended Size: 860px*250px</p>
        </Form.File>

        <Form.Row>
          {" "}
          <Form.Group as={Col} controlId="state">
            <Autocomplete
              id="select-state"
              options={salesPersonList}
              value={salesPersonDetail}
              getOptionLabel={(item) =>
                item?.user__first_name + " " + item?.user__last_name
              }
              onChange={(e, newValue) => {
                newValue
                  ? setSalesPersonDetail({ ...newValue })
                  : setSalesPersonDetail(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Sales Person"
                  variant="outlined"
                />
              )}
            />
          </Form.Group>
        </Form.Row>
        <br />
        <br />
        <Button type="submit" variant="info">
          Add Company
        </Button>
      </Form>
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default AddCompany;
