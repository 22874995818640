import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import { apiNiyuktiCall, niyuktiAxiosPostReq } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import { CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2.5),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  customButton: {
    width: "12rem",
    height: "2.5rem",
    background: "#007bff",
    "&:hover": {
      background: "#0056b3", // Set the desired hover color
    },
  },
}));

export default function DownloadDialog({
  getDateParam,
  filters,
  reportName,
  endPoint,
  presetValues,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const intialDialogState = {
    basic_info: {
      candidate_name: false,
      email: false,
      mobile_number: false,
      dob: false,
      gender: false,
      current_city: false,
      home_town: false,
      total_experience: false,
      current_ctc: false,
      expected_ctc: false,
      notice_period: false,
      about: false,
      resume_link: false,
    },
    education_info: {
      level_of_education: false,
      college_name: false,
      degree: false,
      specialization: false,
      start_date: false,
      end_date: false,
      percentage: false,
    },
    work_experience: {
      job_title: false,
      company_name: false,
      company_website: false,
      job_type: false,
      start_date: false,
      end_date: false,
      description: false,
    },
    certifications: {
      course_name: false,
      institute_name: false,
      start_date: false,
      end_date: false,
      valid_date: false,
    },
    project: {
      title: false,
      link: false,
      description: false,
    },
    publication: {
      title: false,
      link: false,
      description: false,
    },
    candidate_interest: {
      job_role: false,
      preferred_job_locations: false,
      industry_type: false,
      job_type: false,
    },
    important_link: {
      linkedin: false,
      github: false,
      facebook: false,
      instagram: false,
    },
    extras: {
      languages: false,
      achievements: false,
      hobbies: false,
      skills: false,
    },
    hr_info: {
      hr_name: false,
    },
  };

  const [dialogState, setDialogState] = useState(intialDialogState);
  const [totalCheckSelected, setTotalCheckSelected] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      localStorage.getItem(`${reportName}-download-details`) &&
      localStorage.getItem(`${reportName}-download-details-count`)
    ) {
      setDialogState(
        JSON.parse(localStorage.getItem(`${reportName}-download-details`))
      );
      setTotalCheckSelected(
        JSON.parse(localStorage.getItem(`${reportName}-download-details-count`))
      );
    }
  }, []);

  const [loadingDataDownload, setLoadingDataDownload] = useState(false);
  const handleDownload = () => {
    setLoadingDataDownload(true);
    // const payload = {};

    // if (totalCheckSelected > 0) {
    //   payload["basic_info"] = presetValues;
    // }

    // for (const category in dialogState) {
    //   const categoryData = dialogState[category];
    //   const trueFields = [];

    //   for (const field in categoryData) {
    //     if (categoryData[field]) {
    //       trueFields.push(field);
    //     }
    //   }
    //   if (trueFields.length > 0) {
    //     if (!(category in payload)) payload[category] = [];
    //     payload[category] = payload[category].concat(trueFields);
    //   }
    // }

    let payload = new Map();

    if (totalCheckSelected > 0) {
      payload.set("basic_info", presetValues);
    }

    for (const category in dialogState) {
      const categoryData = dialogState[category];
      const trueFields = [];

      for (const field in categoryData) {
        if (categoryData[field]) {
          trueFields.push(field);
        }
      }

      if (trueFields.length > 0) {
        if (!payload.has(category)) {
          payload.set(category, []);
        }

        const existingFields = payload.get(category);
        payload.set(category, existingFields.concat(trueFields));
      }
    }

    payload = JSON.stringify(Object.fromEntries(payload));

    let data = getDateParam();

    data["download_candidate_details"] = payload;
    let recruiterIds = [];
    if (filters?.selectedRecruiterList?.length > 0) {
      for (let i in filters?.selectedRecruiterList) {
        recruiterIds.push(filters?.selectedRecruiterList[i]?.id);
      }
      if (reportName == "recruiter-report" || reportName == "firm-report") {
        data["user_ids"] = recruiterIds;
      } else {
        endPoint = endPoint + `&user_ids=${recruiterIds.join(",")}`;
      }
    }

    let jobCategoryIds = [];
    if (filters?.selectedJobCategory?.length > 0) {
      for (let i in filters?.selectedJobCategory) {
        jobCategoryIds.push(filters?.selectedJobCategory[i]?.id);
      }
      endPoint = endPoint + `&job_category=${jobCategoryIds.join(",")}`;
    }
    let companyIds = [];
    if (filters?.selectedCompanyList?.length > 0) {
      for (let i in filters?.selectedCompanyList) {
        companyIds.push(filters?.selectedCompanyList[i]?.id);
      }
      endPoint = endPoint + `&company_id=${companyIds.join(",")}`;
    }
    if (filters?.selectedSortBy !== null) {
      endPoint = endPoint + `&is_created=${filters?.selectedSortBy.value}`;
    }

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    };

    apiNiyuktiCall(endPoint, requestOptionsPost)
      .then((res) => {
        if (res?.success) {
          SuccessAlert(res?.data?.message);
          handleClose();
        } else {
          FailureAlert(res?.error);
        }
        setLoadingDataDownload(false);
      })
      .catch((err) => {
        setLoadingDataDownload(false);
        FailureAlert("Something went wrong!");
        console.log("Error", err);
      });
    localStorage.setItem(
      `${reportName}-download-details`,
      JSON.stringify(dialogState)
    );
    localStorage.setItem(
      `${reportName}-download-details-count`,
      JSON.stringify(totalCheckSelected)
    );
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        // style={{
        //   width: "12rem",
        //   height: "2.5rem",
        //   background: "#007bff",
        // }}
        className={classes.customButton}
        variant="contained"
        color="primary"
      >
        Download
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Download Details({totalCheckSelected}/50)
        </DialogTitle>

        <div style={{ padding: 22, paddingTop: 0 }}>
          <Grid container spacing={2}>
            {data.map((mainTitle, index) => {
              const titleId = mainTitle.titleId;
              return (
                <Grid item md={4}>
                  <div>
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                      {mainTitle.title}
                    </Typography>

                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      {mainTitle.subTitles.map((SubTitle) => {
                        const subTitleId = SubTitle.subId;
                        return (
                          <FormGroup>
                            <FormControlLabel
                              disabled={loadingDataDownload}
                              control={<Checkbox name="" color="primary" />}
                              label={SubTitle.sub}
                              checked={dialogState[titleId][subTitleId]}
                              onChange={(e) => {
                                !dialogState[titleId][subTitleId]
                                  ? setTotalCheckSelected((prev) => prev + 1)
                                  : setTotalCheckSelected((prev) => prev - 1);
                                setDialogState((prev) => ({
                                  ...prev,
                                  [titleId]: {
                                    ...prev[titleId],
                                    [subTitleId]: !prev[titleId][subTitleId],
                                  },
                                }));
                              }}
                            />
                          </FormGroup>
                        );
                      })}
                    </FormControl>
                  </div>
                </Grid>
              );
            })}
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Button
                  disabled={loadingDataDownload}
                  variant="outlined"
                  color="primary"
                  style={{ width: 150, marginRight: 10 }}
                  onClick={() => {
                    setDialogState(intialDialogState);
                    setTotalCheckSelected(0);
                    localStorage.setItem(
                      `${reportName}-download-details`,
                      JSON.stringify(intialDialogState)
                    );
                    localStorage.setItem(
                      `${reportName}-download-details-count`,
                      JSON.stringify(0)
                    );
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ width: 150, height: 37 }}
                  onClick={handleDownload}
                  disabled={loadingDataDownload}
                  variant="contained"
                  color="primary"
                >
                  {loadingDataDownload ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Download"
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

const data = [
  {
    title: "Basic Information",
    titleId: "basic_info",
    subTitles: [
      { sub: "Candidate Name", subId: "candidate_name" },
      { sub: "Email", subId: "email" },
      { sub: "Mobile number", subId: "mobile_number" },
      { sub: "DOB", subId: "dob" },
      { sub: "Gender", subId: "gender" },
      { sub: "Current city", subId: "current_city" },
      { sub: "Hometown", subId: "home_town" },
      { sub: "Total Experience (2 years 5 months)", subId: "total_experience" },
      { sub: "Current CTC", subId: "current_ctc" },
      { sub: "Expected CTC", subId: "expected_ctc" },
      { sub: "Notice period (in days)", subId: "notice_period" },
      { sub: "About", subId: "about" },
      { sub: "Resume Link", subId: "resume_link" },
    ],
  },
  {
    title: "Education information",
    titleId: "education_info",

    subTitles: [
      { sub: "Level of education", subId: "level_of_education" },
      { sub: "College Name", subId: "college_name" },
      { sub: "Degree", subId: "degree" },
      { sub: "Specialization", subId: "specialization" },
      { sub: "Start date", subId: "start_date" },
      { sub: "End date", subId: "end_date" },
      { sub: "Percentage", subId: "percentage" },
    ],
  },
  {
    title: "Work Experience",
    titleId: "work_experience",

    subTitles: [
      { sub: "Job title", subId: "job_title" },
      { sub: "Company name", subId: "company_name" },
      { sub: "Company website", subId: "company_website" },
      { sub: "Job type (Internship, full-time, part-time)", subId: "job_type" },
      { sub: "Start date", subId: "start_date" },
      { sub: "End date", subId: "end_date" },
      { sub: "Description", subId: "description" },
    ],
  },
  {
    title: "Certifications",
    titleId: "certifications",
    subTitles: [
      { sub: "Course name", subId: "course_name" },
      { sub: "Institute name", subId: "institute_name" },
      { sub: "Start date", subId: "start_date" },
      { sub: "End date", subId: "end_date" },
      { sub: "Valid upto", subId: "valid_date" },
    ],
  },
  {
    title: "Project",
    titleId: "project",

    subTitles: [
      { sub: "Title", subId: "title" },
      { sub: "Link", subId: "link" },
      { sub: "Description", subId: "description" },
    ],
  },
  {
    title: "Publication",
    titleId: "publication",

    subTitles: [
      { sub: "Title", subId: "title" },
      { sub: "Link", subId: "link" },
      { sub: "Description", subId: "description" },
    ],
  },
  {
    title: "Candidate interest",
    titleId: "candidate_interest",

    subTitles: [
      { sub: "Job role intersted", subId: "job_role" },
      { sub: "Preferred job locations", subId: "preferred_job_locations" },
      { sub: "Industry type", subId: "industry_type" },
      { sub: "Job type", subId: "job_type" },
    ],
  },
  {
    title: "Important Link",
    titleId: "important_link",

    subTitles: [
      { sub: "Linkedin", subId: "linkedin" },
      { sub: "Github", subId: "github" },
      { sub: "Facebook", subId: "facebook" },
      { sub: "Instagram", subId: "instagram" },
    ],
  },
  {
    title: "Extras",
    titleId: "extras",
    subTitles: [
      { sub: "Languages", subId: "languages" },
      { sub: "Achievements", subId: "achievements" },
      { sub: "Hobbies", subId: "hobbies" },
      { sub: "Skills", subId: "skills" },
    ],
  },
  {
    title: "HR Info",
    titleId: "hr_info",

    subTitles: [{ sub: "HR Name", subId: "hr_name" }],
  },
];
