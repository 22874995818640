import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  niyuktiAxiosGetReq,
  praveshAxiosCall,
  praveshAxiosGetReq,
} from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import { DateSelector } from "../common/DateSelector";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  chipsection: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "0px",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "10px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function VendorPartnersFilter(props) {
  const classes = useStyles();
  const fullWidth = true;
  const maxWidth = "xs";

  const { status } = props;

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [dateFilter, setDateFilter] = useState(
    props?.filters?.advanceFilterDateValue
  );

  const [selectedCompanyList, setSelectedCompanyList] = useState(
    props?.filters?.selectedCompanyList
  );
  const [selectedJobTitleList, setSelectedJobTitleList] = useState(
    props?.filters?.selectedJobTitleList
  );
  const [selectedPartnerList, setSelectedPartnerList] = useState(
    props?.filters?.selectedPartnerList
  );

  const [company, setCompany] = useState([]);
  const [job, setJob] = useState([]);
  const [partner, setPartner] = useState([]);

  const checkValidation = () => {
    if (
      (!dateFilter ||
        dateFilter?.length < 2 ||
        !dateFilter[0] ||
        !dateFilter[1]) &&
      selectedCompanyList?.length === 0 &&
      selectedJobTitleList?.length === 0 &&
      selectedPartnerList?.length === 0
    ) {
      FailureAlert("Please select atleast one company/plan or a date range");
      return false;
    }
    return true;
  };

  // http://127.0.0.1:8002/job/admin/vendor/company_lists/?status=ACCEPTED

  useEffect(() => {
    const getCompanyList = async () => {
      try {
        const res = await niyuktiAxiosGetReq(
          `/job/admin/vendor/company_lists/?status=${status}`
        );
        if (res?.data?.success) {
          setCompany(res?.data?.data);
        } else {
          FailureAlert("Error getting company list");
        }
      } catch (error) {
        FailureAlert("Error getting company list");
      }
    };

    const getJobList = async () => {
      try {
        const res = await niyuktiAxiosGetReq(
          `/job/admin/vendor/company_job_lists/?status=${status}`
        );
        if (res?.data?.success) {
          setJob(res?.data?.data);
        } else {
          FailureAlert("Error getting job list");
        }
      } catch (error) {
        FailureAlert("Error getting job list");
      }
    };

    const getPartnerList = async () => {
      try {
        const res = await praveshAxiosGetReq(`/api/admin/vendor_lists/`);
        if (res?.data?.success) {
          setPartner(res?.data?.data);
        } else {
          FailureAlert("Error getting partner list");
        }
      } catch (error) {
        FailureAlert("Error getting partner list");
      }
    };

    getCompanyList();
    getJobList();
    getPartnerList();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        //onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6" style={{ marginBottom: "20px" }}>
            Advance Filters
          </Typography>

          <Grid container spacing={4} style={{ marginBottom: "10px" }}>
            <Grid item md={12}>
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  // size="small"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="Assigned date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider> */}
              <DateSelector
                dateRange={[props?.startDate, props?.endDate]}
                setAdvanceFilterDateValue={setDateFilter}
                advanceFilterDateValue={dateFilter}
                isAdvanceFilter={true}
                clearDates={true}
                justifyContent="space-between"
                removeDateFilter={() => {
                  setDateFilter([null, null]);
                }}
                label={"Assigned Date"}
              />
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                multiple
                value={selectedJobTitleList}
                limitTags={3}
                id="combo-box-demo"
                options={job}
                getOptionLabel={(option) => option.job_title}
                onChange={(e, item) => {
                  setSelectedJobTitleList(item);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Title"
                    variant="outlined"
                    // size="small"
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                multiple
                value={selectedCompanyList}
                limitTags={3}
                id="combo-box-demo"
                options={company}
                getOptionLabel={(option) => option.company_name}
                onChange={(e, item) => {
                  setSelectedCompanyList(item);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company"
                    variant="outlined"
                    // size="small"
                  />
                )}
              />
            </Grid>{" "}
            <Grid item md={12}>
              <Autocomplete
                multiple
                value={selectedPartnerList}
                limitTags={3}
                id="combo-box-demo"
                options={partner}
                getOptionLabel={(option) => option.vendor_name}
                onChange={(e, item) => {
                  setSelectedPartnerList(item);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Firm Name"
                    variant="outlined"
                    // size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={props.handleClose}
                style={{ height: "45px", display: "flex" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ height: "45px", display: "flex" }}
                onClick={() => {
                  // if (checkValidation()) {
                  props?.setFilters({
                    ...props?.filters,
                    // advanceFilterDateValue: selectedDate,
                    advanceFilterDateValue: dateFilter,
                    selectedCompanyList: selectedCompanyList,
                    selectedJobTitleList: selectedJobTitleList,
                    selectedPartnerList: selectedPartnerList,
                  });
                  // setSendFilterPayload(true);
                  props?.handleClose();
                  // }
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
