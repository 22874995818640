import { Button, Chip, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { FailureAlert } from "../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function Skills({
  skillsList,
  userSkills,
  postSkills,
  debounceSkillList,
}) {
  const classes = useStyles();
  const [tempUserSkills, setTempUserSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);

  useEffect(() => {
    setTempUserSkills(userSkills ? userSkills : []);
  }, [userSkills]);

  const handleDelete = (index) => {
    setTempUserSkills((tempUserSkills) =>
      tempUserSkills.filter((_, skillIndex) => skillIndex !== index)
    );
  };

  const addSkill = (skill) => {
    if (skill) {
      const skillAlreadyAdded = tempUserSkills.some(
        (skl) =>
          skl.skill_name.toLowerCase() ===
          (skill?.skill_name
            ? skill?.skill_name.toLowerCase()
            : skill.toLowerCase())
      );
      if (skillAlreadyAdded) FailureAlert("Already Added!");
      else {
        setTempUserSkills([
          ...tempUserSkills,
          {
            is_custom_skill: typeof skill === "string" ? 1 : 0,
            skill_id: skill?.skill_id || -1,
            skill_name: skill?.skill_name || skill,
            type: skill?.type,
            skill_rating_value: 3,
            skill_rating: "Intermediate",
          },
        ]);
        setSelectedSkill(null);
      }
    }
  };

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Skills</Typography>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Autocomplete
            freeSolo
            value={selectedSkill}
            id="combo-box-demo"
            onChange={(_, newValue) => {
              // setSelectedSkill(newValue);

              if (newValue) {
                addSkill(newValue);
              }
            }}
            onInputChange={(e, newInputValue) => {
              // if (e?.target?.value) {
              debounceSkillList(newInputValue);
              // setSelectedSkill(newInputValue);
              // }
            }}
            options={skillsList}
            // getOptionLabel={(option) => ""}
            getOptionLabel={(item) => item.skill_name}
            // renderOption={(option) => {
            //   return (
            //     <span style={{ fontSize: 13 }}>{`${option.skill_name}`}</span>
            //   );
            // }}
            className={classes.fieldStyling1}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={<span style={{ fontSize: 13 }}>Select Skill</span>}
                  variant="outlined"
                />
              );
            }}
          />
        </Grid>
      </div>

      <div className={classes.root}>
        {tempUserSkills &&
          tempUserSkills.map((data, index) => {
            return (
              <li key={data.skill_id}>
                <Chip
                  color="primary"
                  deleteIcon={<CloseIcon color="primary" />}
                  variant="outlined"
                  size="small"
                  label={data.skill_name}
                  onDelete={() => {
                    handleDelete(index);
                  }}
                  className={classes.chip}
                />
              </li>
            );
          })}
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => postSkills(tempUserSkills)}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
