import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import gif from "../../assets/loopingcircles-3.gif";
import RecruiterReportFilter from "../../components/DialogSection/Reports/RecruiterReportFilter";
// import CustomButtonCircularProgress from "../components/loader";
import TableWithExport from "../../components/TableWithExport";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { Form } from "react-bootstrap";
import {
  apiNiyuktiCall,
  niyuktiAxiosCall,
  niyuktiAxiosGetReq,
  niyuktiAxiosPostReq,
  praveshAxiosCall,
} from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { IconButton, TextField, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Autocomplete } from "@material-ui/lab";
import AssignCandidatesFilter from "./Components/Dialog/AssignCandidatesFilter";
import TableWithoutExport from "../TableWithoutExport";
// import DownloadDialog from "../components/DialogSection/Download/DownloadDialog";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import CustomButtonCircularProgress from "../loader";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#ff9100",
    },
  },
  overrides: {
    MuiListItemText: {
      root: {
        whiteSpace: "initial",
      },
    },
  },
});

const AssignCandidates = () => {
  const classes = useStyles();

  const [selectedRowData, setSelectedRowsData] = useState([]);
  const [applicantCount, setApplicantCount] = useState(0);
  const [selectedActiveRecruiter, setSelectedActiveRecruiter] = useState(null);
  const [selectedInActiveRecruiter, setSelectedInActiveRecruiter] =
    useState(null);

  const [inActiveRecruiters, setInActiveRecruiters] = useState([]);
  const [activeRecruiters, setActiveRecruiters] = useState([]);

  const [filtersList, setFiltersList] = useState({
    jobs: [],
    company: [],
    currentStatus: [],
  });

  useEffect(() => {
    const getRecruiterList = () => {
      praveshAxiosCall
        .get(`api/admin/active_and_inactive_recruiters/`)
        .then((res) => {
          if (res?.data?.success) {
            setActiveRecruiters(res?.data?.data.active_users);
            setInActiveRecruiters(res?.data?.data.inactive_users);
          } else {
            FailureAlert("Error Getting Recruiter List");
          }
        })
        .catch((err) => {
          FailureAlert("Error Getting Recruiter List");
        });
    };
    getRecruiterList();
  }, []);
  useEffect(() => {
    const getFiltersList = () => {
      if (selectedInActiveRecruiter)
        niyuktiAxiosCall
          .get(
            `job/admin/inactive_recruiter_applicants_filter/?recruiter_id=${selectedInActiveRecruiter.user_id}`
          )
          .then((res) => {
            if (res?.data?.success) {
              setFiltersList({
                jobs: res?.data?.data.job.data,
                company: res?.data?.data.company.data,
                currentStatus: res?.data?.data.current_status.data,
              });
            } else {
              FailureAlert("Error getting Filter Lists");
            }
          })
          .catch((err) => {
            FailureAlert("Error getting Filter Lists");
          });
    };
    getFiltersList();
  }, [selectedInActiveRecruiter]);

  const [pageLoading, setPageLoading] = useState(false);

  //   const currentDate = new Date();
  //   const startDate = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth(),
  //     1
  //   );

  //   const [dateRange, setDateRange] = useState([startDate, currentDate]);

  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const [filters, setFilters] = useState({
    // advanceFilterDateValue: dateRange,
    selectedJobList: [],
    selectedCompanyList: [],
    selectedCurrentStatusList: [],
  });

  //   const applyDateFilter = (valueDate) => {
  //     if (valueDate.length === 2) {
  //       setDateRange({
  //         startDate: valueDate[0].toISOString().substring(0, 10),
  //         endDate: valueDate[1].toISOString().substring(0, 10),
  //       });
  //       // setStartDate(valueDate[0]);
  //       // setEndDate(valueDate[1]);
  //     }
  //   };

  //   const removeDateFilter = () => {
  //     setDateRange({
  //       startDate: null,
  //       endDate: null,
  //     });
  //     // setStartDate(null);
  //     // setEndDate(null);
  //     // setCount(count + 1);
  //   };

  //   const formatDate = (date) => {
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() is zero-based
  //     const day = String(date.getDate()).padStart(2, "0");
  //     return `${year}-${month}-${day}`;
  //   };
  //   const formatDate2 = (date) => {
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() is zero-based
  //     const day = String(date.getDate()).padStart(2, "0");
  //     return `${day}-${month}-${year}`;
  //   };

  //   const getDateParam = () => {
  //     if (
  //       filters?.advanceFilterDateValue &&
  //       filters?.advanceFilterDateValue?.length === 2 &&
  //       filters?.advanceFilterDateValue[0] &&
  //       filters?.advanceFilterDateValue[1]
  //     ) {
  //       return {
  //         // start_date: filters?.advanceFilterDateValue[0]
  //         //   .toISOString()
  //         //   .substring(0, 10),
  //         // end_date: filters?.advanceFilterDateValue[1]
  //         //   .toISOString()
  //         //   .substring(0, 10),
  //         start_date: formatDate(filters.advanceFilterDateValue[0]),
  //         end_date: formatDate(filters.advanceFilterDateValue[1]),
  //       };
  //     }
  //     return {};
  //   };

  const columns = [
    {
      title: "Name",
      field: "name",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Email",
      field: "email",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,

      // filtering: false,
      // searchable: false,
    },

    {
      title: "Number",
      field: "phone",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },

    {
      title: "Job_ID",
      field: "job_id",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },

    {
      title: "Company_name",
      // title: "",
      field: "company_name",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      // title: "Reject",
      title: "Job_title",
      field: "job_title",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      // title: "Reject",
      title: "Current_Status",
      field: "current_status",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      // title: "Reject",
      title: "Rating",

      field: "rating",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Create_Time",

      field: "create_time",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },

    // {
    //   title: (
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         // paddingLeft: 20,
    //         color: "#A020F0",
    //       }}
    //     >
    //       Client_Screening{" "}
    //       <IconButton
    //         className={clsx(classes.expand, {
    //           [classes.expandOpen]: !toggleClientScreening,
    //         })}
    //         style={{ color: "#A020F0" }}
    //         size="small"
    //         onClick={() => setToggledClientScreening((prev) => !prev)}
    //         aria-expanded={!toggleClientScreening}
    //       >
    //         {" "}
    //         <ArrowForwardIosIcon />{" "}
    //       </IconButton>
    //     </div>
    //   ),
    //   field: "client-screening",

    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) =>
    //     row?.jobData ? (
    //       <div
    //         style={{
    //           cursor: "pointer",
    //           color: "#A020F0",
    //         }}
    //         onClick={() =>
    //           window.open(`/admin/job-track/${row.jobId}/${row.companyId}`)
    //         }
    //       >
    //         {isNaN(
    //           row.getworkScreenedCount +
    //             row.clientScreeningRejected +
    //             row.clientScreeningHold +
    //             row.clientScreeningDuplicate
    //         )
    //           ? null
    //           : row.getworkScreenedCount +
    //             row.clientScreeningRejected +
    //             row.clientScreeningHold +
    //             row.clientScreeningDuplicate}
    //       </div>
    //     ) : (
    //       <div style={{ color: "#A020F0" }}>
    //         {isNaN(
    //           row.getworkScreenedCount +
    //             row.clientScreeningRejected +
    //             row.clientScreeningHold +
    //             row.clientScreeningDuplicate
    //         )
    //           ? null
    //           : row.getworkScreenedCount +
    //             row.clientScreeningRejected +
    //             row.clientScreeningHold +
    //             row.clientScreeningDuplicate}
    //       </div>
    //     ),
    // },
    {
      title: "Update_Time",

      field: "update_time",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Candidate Resume",
      field: "resume",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
      editable: "never",
      render: (row) => (
        <div>
          {row.resume ? (
            <Button
              variant="outline-success"
              size="sm"
              style={{
                fontSize: 13,
              }}
              onClick={() => window.open(row.resume)}
            >
              {" "}
              Resume
            </Button>
          ) : (
            <Button variant="outline-secondary" size="sm" disabled>
              {" "}
              Resume
            </Button>
          )}
        </div>
      ),
    },
  ];

  const getData = async () => {
    let endPoint = `/job/admin/inactive_recruiter_applicants_get/?recruiter_id=${selectedInActiveRecruiter.user_id}`;
    setPageLoading(true);

    // const data = getDateParam();

    let jobIds = [];
    if (filters?.selectedJobList?.length > 0) {
      for (let i in filters?.selectedJobList) {
        jobIds.push(filters?.selectedJobList[i]?.id);
      }
      endPoint = endPoint + `&job_id=${jobIds.join(",")}`;
    }
    let companyIds = [];
    if (filters?.selectedCompanyList?.length > 0) {
      for (let i in filters?.selectedCompanyList) {
        companyIds.push(filters?.selectedCompanyList[i]?.id);
      }
      endPoint = endPoint + `&company_id=${companyIds.join(",")}`;
    }
    let currentStatusIds = [];
    if (filters?.selectedCurrentStatusList?.length > 0) {
      for (let i in filters?.selectedCurrentStatusList) {
        currentStatusIds.push(filters?.selectedCurrentStatusList[i]?.id);
      }
      endPoint = endPoint + `&current_status=${currentStatusIds.join(",")}`;
    }

    try {
      const res = await niyuktiAxiosGetReq(endPoint);

      if (res?.data?.success) {
        const data = res?.data?.data;
        let tempArr = [];
        setApplicantCount(data?.count);
        for (let i in data?.applicant_data) {
          let applicant = data?.applicant_data;
          let obj = {
            id: applicant[i]?.id,
            name: applicant[i]?.name,
            email: applicant[i]?.email,
            phone: applicant[i]?.phone,
            job_id: applicant[i]?.job_id,
            job_title: applicant[i]?.job_title,
            company_id: applicant[i]?.company_id,
            company_name: applicant[i]?.company_name,
            current_status: applicant[i]?.current_status,
            rating: applicant[i]?.rating,
            create_time: applicant[i]?.create_time,
            update_time: applicant[i]?.update_time,
            resume: applicant[i]?.resume,
          };

          tempArr.push(obj);
        }
        setTableData(tempArr);
      } else {
        FailureAlert("Some Error Occurred!");
      }
      setPageLoading(false);
    } catch (error) {
      throw error;
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (selectedInActiveRecruiter) getData();
  }, [filters, selectedInActiveRecruiter]);

  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const getTableTitleText = () => {
    // if (
    //   filters?.advanceFilterDateValue &&
    //   filters?.advanceFilterDateValue?.length === 2 &&
    //   filters?.advanceFilterDateValue[0] &&
    //   filters?.advanceFilterDateValue[1]
    // ) {
    //   return `Candidates (${formatDate2(
    //     filters.advanceFilterDateValue[0]
    //   )} - ${formatDate2(filters.advanceFilterDateValue[1])})`;
    // }
    return `Total Candidates (${applicantCount})`;
  };

  const [loading, setLoading] = useState(false);
  const handleAssignRecruiter = async () => {
    const url = `/job/admin/assign_applicants_active_recruiter/`;

    let data = {
      old_recruiter_id: selectedInActiveRecruiter?.user_id,
      recruiter_id: selectedActiveRecruiter?.user_id,
      applicant_id: selectedRowData.map((obj) => obj.id),
    };

    try {
      setLoading(true);
      const res = await niyuktiAxiosPostReq(url, data);

      if (res?.data?.success) {
        SuccessAlert(res?.data?.data.message);
      } else {
        FailureAlert("Some Error Occurred");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      FailureAlert("Some Error Occurred!");
    }
  };

  return (
    <>
      <Autocomplete
        id="select-user"
        value={selectedInActiveRecruiter}
        options={inActiveRecruiters}
        getOptionLabel={(item) => `${item?.name} - ${item?.email}`}
        style={{
          width: "100%",
          marginTop: "2rem",
          marginBottom: "2rem",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        onChange={(e, item) =>
          item
            ? setSelectedInActiveRecruiter(item)
            : setSelectedInActiveRecruiter(null)
        }
        renderInput={(params) => (
          <TextField {...params} label="Select Recruiter " variant="outlined" />
        )}
      />
      {selectedInActiveRecruiter ? (
        <>
          {pageLoading ? (
            <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
          ) : (
            <LoadingOverlay
              active={loadingOverlay}
              spinner
              text="Updating Details..."
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "16px",
                }}
              >
                <Button
                  style={{
                    width: "8rem",
                    height: "2.5rem",
                    // marginRight: "5rem",
                  }}
                  onClick={() => {
                    setShowFilter(true);
                  }}
                >
                  Filters
                </Button>
              </div>

              <ThemeProvider theme={theme}>
                <MaterialTable
                  title={getTableTitleText()}
                  data={tableData}
                  columns={columns}
                  options={{
                    // grouping: true,
                    // search: true,
                    sorting: true,
                    selection: true,
                    // filtering: true,
                    paging: true,
                    pageSize: 50,
                    pageSizeOptions: [10, 20, 50, 100, 200],

                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#ebf5fa",
                    },
                    maxBodyHeight: "450px",
                    cellStyle: {
                      textAlign: "center",
                      formControlStyle: {
                        minWidth: "300px",
                        maxWidth: "300px",
                        width: "500px",
                      },
                    },
                  }}
                  onSelectionChange={(data) => {
                    setSelectedRowsData(data);
                  }}
                  actions={[
                    {
                      position: "toolbarOnSelect",

                      icon: () => (
                        <Autocomplete
                          id="select-user"
                          value={selectedActiveRecruiter}
                          options={activeRecruiters}
                          getOptionLabel={(item) =>
                            `${item?.name} - ${item?.email}`
                          }
                          style={{
                            width: "20rem",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          onChange={(e, item) =>
                            item
                              ? setSelectedActiveRecruiter(item)
                              : setSelectedActiveRecruiter(null)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Recruiter To Assign"
                              variant="outlined"
                            />
                          )}
                        />
                      ),
                    },
                    {
                      position: "toolbarOnSelect",
                      icon: () => (
                        <Button
                          onClick={handleAssignRecruiter}
                          disabled={!selectedActiveRecruiter}
                          style={{
                            width: "200px",
                          }}
                        >
                          {loading ? (
                            <CustomButtonCircularProgress />
                          ) : (
                            "Assign Recruiter"
                          )}
                        </Button>
                      ),
                    },
                  ]}
                />
              </ThemeProvider>
            </LoadingOverlay>
          )}
        </>
      ) : null}

      {showFilter && (
        <AssignCandidatesFilter
          open={showFilter}
          handleClose={() => {
            setShowFilter(false);
          }}
          filters={filters}
          setFilters={setFilters}
          filtersList={filtersList}
        />
      )}
    </>
  );
};

export default AssignCandidates;
