import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useState } from 'react';
import sampleExcel from '../../../assets/Create-Student-Job-Apply-Upload.xlsx';
import { praveshUrl, headerCSS, cellCSS } from "../../../constants";
import '../../../css/Popup.css';
import { FailureAlert, SuccessAlert } from '../../../util/Notifications';
import CustomButtonCircularProgress from "../../loader";
import TableWithExport from '../../TableWithExport';

function StudentUploadDataPopup({showUploadFile,setShowUploadFile}) {
  const [filename, setFilename] = useState("None");
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [excel, setExcel] = useState(null);
  const [response, setResponse] = useState({});
  const [data, setData] = useState([]);
  const columns = [
    { title: "Name",   field: "student_name",     headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "Email",  field: "email",    headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "Row number",  field: "number_of_rows",   cellStyle: cellCSS },
    { title: "Error",  field: "error",    headerStyle: headerCSS, cellStyle: cellCSS },
] 

  const fileHandle = (file, name, type) => {
    // checking file format
    if (
      file.type.split("/")[0] !== "application" &&
      file.type.split("/")[0] !== "excel"
    ) {
      return false;
    } // checking file size
    else if (file.size > 2 * 1024 * 1024) {
      return false;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      //start loading bar
      setUploading(true)
      const promise = new Promise((resolve,reject)=>{
        setFilename(file.name);
        setFile(file);
        resolve("i am done")
      })
      promise.then(/*stop loading bar */
      setUploading(false)
      )
    };
  };

  const handleChange = (e) => {
    setExcel(e.target.files[0])
    Array.from(e.target.files).forEach((file) => {
      fileHandle(file, file.name, file.type);
    });
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let draggedFile = e.dataTransfer;
    let files = draggedFile.files;

    console.log(files);
    setExcel(files[0])
    Array.from(files).forEach((file) => {
      fileHandle(file, file.name, file.type);
    });
  };

  const [loader, setLoader] = useState(false);

  const handleSubmit = () => { 
    if(!excel){
      FailureAlert("Please select a file!");
      return;
    }
    setShowUploadFile(!showUploadFile);
    setLoader(true);
    const fd = new FormData();
    fd.append("file", excel);
    
    axios.post(`${praveshUrl}/api/admin/third_party/upload_candidate_excel/`, fd, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoader(false);
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.data?.message);
          setResponse(res?.data?.data?.data);
          setData(res?.data?.data?.data?.error_list ? res?.data?.data?.data?.error_list: [])
          setFilename('')
        } else {
          FailureAlert(res?.data?.error);
        }
      })
      .catch((err) => {
        setLoader(false);
        FailureAlert("Something Went Wrong");
        throw err;
      });
  };

  return (
    <>
      <div className="container1">
        <p className="heading">Upload Candidate Data</p>
        <p className="sub-heading">Please upload candidate excel file</p>

        <div
          className="container"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            id="input-file"
            multiple
            // accept="image/*,  application/pdf"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleChange}
            style={{cursor: "pointer"}}
          />
          <label htmlFor="input-file" className="lable-input">
            <i className="fa-solid fa-upload"></i> &nbsp; Choose/drag file
          </label>
        </div>
        <a href={sampleExcel} className="sample-file">Click Here to download sample file</a>
        <div className="error" style={{marginBottom: "1rem"}}>Selected File: {filename}</div>
        <div className="button" style={{display: "contents"}}>
                    <button className="upload" style={{width: "25%", borderRadius: "5px", height: "40px"}} disable={loader}  onClick={handleSubmit}>
            {loader ?
            <CustomButtonCircularProgress />
            :
            "Upload File"
            }
          </button>
        </div>
        {data?.length !== 0 && 
        <>
        <div style={{height: "3px", backgroundColor: "black", width: "100%", marginBottom: "20px", marginTop: "20px"}} />
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
        <div >
          <h4> Upload Response</h4>
            <h6>Total number of Entries: {response.total_entries ? response.total_entries : 0}</h6>
            <h6>Total Created Entries: {response.user_created_count ? response.user_created_count : 0}</h6>
            <h6>Total Failed Entries: {response.error_list_count ? response.error_list_count : 0}</h6>
          <br />
        </div>
        </div>
        </>
      }
        {
                    response.error_list_count > 0 
                    ? 
                    <TableWithExport 
                        title = "Error List"
                        data = {data}
                        columns = {columns}
                        fName = "Selected Errors"
                        search={false}
                        selection={false}
                        filtering={false}
                    />
                    :
                    null
                }
      </div>
    </>
  )
}

export default StudentUploadDataPopup;
