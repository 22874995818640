import React, { useState, useEffect } from 'react';
import { apiCall } from '../../util/apiCall';
import '../../css/EditCollegeDetails.css';
import { Form } from 'react-bootstrap';
import { FailureAlert } from '../../util/Notifications';
import EditGroupSection from './EditGroupSection';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { requestPraveshOptions } from '../../constants';

function EditGroup() {
    const [groupId, setGroupId] = useState(null);
    const [groups, setGroups] = useState([]);

    // Fetch all group Dropdown
    useEffect(() => {
        apiCall('/api/admin/education_group/list/', requestPraveshOptions)
            .then((res) => {
                if (res.success) {
                    let groupNames = {}
                    for (var i = 0; i < res.data.length; i++) {
                        let obj = res.data[i]
                        groupNames[obj.group_id] = obj.group_name;
                    }
                    setGroups(res.data)
                }
                else {
                    FailureAlert(res.error)
                }
            })
    }, []);

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    return (
        <div className="edit__college__details">
            <Form className="edit__college__details__form" >
                <Autocomplete
                    id="select-user"
                    options={groups}
                    filterOptions={filterOptions}
                    getOptionLabel={(item) => item.group_name}
                    style={{ width: '90%', margin: '5%' }}
                    onChange={(e, item) => item ? setGroupId(item.id) : setGroupId(null)}
                    renderInput={(params) => <TextField {...params} label="Select Group" variant="outlined" />}
                />
            </Form>
            {
                groupId ? <EditGroupSection grpId={groupId} /> : null
            }
        </div>
    )
}

export default EditGroup;