import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import InterviewInfo from "./InterviewInfo";
import AllInterviewsFilter from "../../Jobs/Dialog/AllInterviewsFilter";
import { Button, Typography } from "@material-ui/core";
import QueryString from "query-string";
import { niyuktiAxiosCall } from "../../../util/apiCall";
import { FailureAlert } from "../../../util/Notifications";
import { gifStyling } from "../../../constants";
import gif from "../../../assets/loopingcircles-3.gif";
import LoadMore from "./LoadMore";
import noData from "../../../assets/images/No data.png";

function Main(props) {
  const queryParams = QueryString.parse(window.location.search);

  let date = new Date();
  date.setDate(date.getDate() + 30);
  const dateRange = [new Date(), date];

  const [showFilter, setShowFilter] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [filters, setFilters] = useState({
    advanceFilterDateValue: dateRange,
    selectedCompanyList: [],
    selectedJobList: [],
    createdBy: [],
    status: {},
    page: 1,
    initialJobId: queryParams?.job_id,
  });

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getDateParam = () => {
    if (
      filters?.advanceFilterDateValue &&
      filters?.advanceFilterDateValue?.length === 2 &&
      filters?.advanceFilterDateValue[0] &&
      filters?.advanceFilterDateValue[1]
    ) {
      return {
        // start_date: filters?.advanceFilterDateValue[0]
        //   .toISOString()
        //   .substring(0, 10),
        // end_date: filters?.advanceFilterDateValue[1]
        //   .toISOString()
        //   .substring(0, 10),
        start_date: formatDate(filters.advanceFilterDateValue[0]),
        end_date: formatDate(filters.advanceFilterDateValue[1]),
      };
    }
    return {};
  };

  const [interviewData, setInterViewData] = useState([]);

  const [loadMore, setLoadMore] = useState(false);

  const [nextLink, setNextLink] = useState(null);

  const [totalCount, setTotalCount] = useState(0);

  const getData = () => {
    let endPoint = `api/manage_jobs/admin/interview/?page=${filters?.page}&`;
    const dateData = getDateParam();
    if (!loadMore) {
      setPageLoading(true);
    }
    let recuiterIds = [];
    if (filters?.createdBy?.length > 0) {
      for (let i in filters?.createdBy) {
        recuiterIds.push(filters?.createdBy[i]?.id);
      }
      endPoint = endPoint + `created_by=${recuiterIds.join(",")}&`;
    }
    if (filters?.initialJobId) {
      endPoint = endPoint + `job_id=${filters?.initialJobId}&`;
    } else if (filters?.selectedJobList?.length > 0) {
      let jobIds = [];
      for (let i in filters?.selectedJobList) {
        jobIds.push(filters?.selectedJobList[i]?.job_id);
      }
      endPoint = endPoint + `job_id=${jobIds.join(",")}&`;
    }

    let companyIds = [];
    if (filters?.selectedCompanyList?.length > 0) {
      for (let i in filters?.selectedCompanyList) {
        companyIds.push(filters?.selectedCompanyList[i]?.id);
      }
      endPoint = endPoint + `company_id=${companyIds.join(",")}&`;
    }

    if (filters?.status?.id) {
      endPoint = endPoint + `interview_status=${filters?.status?.id}&`;
    }

    if (!filters?.initialJobId && dateData?.start_date && dateData?.end_date) {
      endPoint =
        endPoint +
        `interview_start_date=${dateData?.start_date}&interview_end_date=${dateData?.end_date}`;
    }
    niyuktiAxiosCall
      .get(endPoint)
      .then((res) => {
        if (res?.data?.success) {
          let tempfinalData = [];
          setNextLink(res?.data?.data?.next);
          setTotalCount(res?.data?.data?.count);
          for (var i = 0; i < res?.data?.data?.results.length; i++) {
            const respData = res?.data?.data?.results[i];
            let tempData = {};
            tempData["companyName"] = respData?.company_name;
            tempData["jobTitle"] = respData?.job_title;
            tempData["jobId"] = respData?.job_id;
            tempData["createDate"] = respData?.created_date;
            tempData["interviewStatus"] = respData?.interview_status;
            tempData["interviewInvite"] = respData?.interviewers_new;
            tempData["interviewId"] = respData?.interview_id;
            tempData["interviewType"] = respData?.interview_type;
            tempData["address"] = respData?.address;
            tempData["description"] = respData?.description;
            tempData["endDate"] = respData?.end_date;
            tempData["endTime"] = respData?.end_time;
            tempData["startDate"] = respData?.start_date_string;
            tempData["startTime"] = respData?.start_time;
            tempData["link"] =
              respData?.interview_type === "OFFLINE"
                ? respData?.address
                : respData?.hangout_link;
            tempData["hiringType"] = respData?.hiring_type;
            tempData["jobRound"] = respData?.job_round;
            tempData["jobType"] = respData?.jobType;
            tempData["scheduledBy"] = respData?.scheduled_by;
            tempData["studentData"] = respData?.student_data;
            tempData["interviewTitle"] = respData?.title;
            tempData["totalSlots"] = respData?.total_slots;
            tempData["timeSlot"] = respData?.time_slot;
            tempfinalData.push(tempData);
          }
          setInterViewData([...interviewData, ...tempfinalData]);
        } else {
          FailureAlert("Some Error Occurred!");
        }
        setPageLoading(false);
        setLoadMore(false);
      })
      .catch((error) => {
        FailureAlert("Some Error Occurred!");
        setPageLoading(false);
        setLoadMore(false);
      });
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const onDelete = (index) => {
    let tempInterviewData = [...interviewData];
    tempInterviewData.splice(index, 1);
    setInterViewData(tempInterviewData);
    setTotalCount(totalCount - 1);
  };

  const onInterviewStatusUpdate = (status, rowIndex, studentIndex) => {
    let tempInterviewData = [...interviewData];
    tempInterviewData[rowIndex]["studentData"][studentIndex].interview_stage =
      status;
    setInterViewData(tempInterviewData);
  };

  return pageLoading ? (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  ) : (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontSize: "20px", fontWeight: "800" }}>
          Interview Count ({interviewData?.length}/{totalCount})
        </div>
        <Button
          variant="contained"
          style={{ backgroundColor: "#2563EB", boxShadow: "none" }}
          onClick={() => {
            setShowFilter(true);
          }}
        >
          <span style={{ color: "#fff" }}> Filters </span>
        </Button>
      </div>
      {/* {interviewData?.length > 0 &&
        interviewData.map((item, index) => {
          return <InterviewInfo data={item} index={index} onDelete={(index) => {onDelete(index)}} onInterviewStatusUpdate={(status, rowIndex, studentIndex) => {
            onInterviewStatusUpdate(status, rowIndex, studentIndex);
          }}/>;
        })} */}
      {interviewData?.length > 0 ? (
        interviewData.map((item, index) => {
          return (
            <InterviewInfo
              data={item}
              index={index}
              onDelete={(index) => {
                onDelete(index);
              }}
              onInterviewStatusUpdate={(status, rowIndex, studentIndex) => {
                onInterviewStatusUpdate(status, rowIndex, studentIndex);
              }}
            />
          );
        })
      ) : (
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={noData}
            style={{
              width: "40%",
              marginTop: "-50px",
            }}
            alt=""
          />
          <Typography
            style={{
              color: "black",
              fontWeight: "600",
              fontSize: "24px",
              marginTop: "-25px",
            }}
          >
            No Data Found
          </Typography>
          <Typography
            style={{
              color: "#007bff",
              fontWeight: "500",
              marginBottom: "40px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            Please schedule the interview for the selected filter first.
          </Typography>
        </section>
      )}

      {showFilter && (
        <AllInterviewsFilter
          open={showFilter}
          handleClose={() => {
            setShowFilter(false);
          }}
          filters={filters}
          setFilters={(filters) => {
            setInterViewData([]);
            setFilters(filters);
          }}
        />
      )}
      {nextLink && (
        <LoadMore
          loading={loadMore}
          setLoading={() => {
            setLoadMore(true);
            setFilters({ ...filters, page: filters?.page + 1 });
          }}
        />
      )}
    </div>
  );
}

export default withRouter(Main);
