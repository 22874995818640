import React, { useState, useEffect } from "react";
import { apiCall } from "../../util/apiCall";
import {
  headerCSS,
  cellCSS,
  gifStyling,
  requestPraveshOptions,
} from "../../constants";
import TableWithExport from "../TableWithExport";
import { Form, Col, Button } from "react-bootstrap";
import "../../css/Miscellaneous.css";
import gif from "../../assets/loopingcircles-3.gif";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

const token = localStorage.getItem("token");

export default function University() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [universityName, setuniversityName] = useState("");
  const [universityCode, setUniversityCode] = useState(null);
  const [universityType, setUniversityType] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setaddress] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const [contactPerson, setContactPerson] = useState('');
  const [designation, setDesignation] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);

  // State Finding API
  useEffect(() => {
    apiCall(`/api/location/state/?search=1`, requestPraveshOptions)
      .then((res) => {
        setStates(res)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // City Finding API
  useEffect(() => {
    apiCall(`/api/location/city/?search=${stateId}`, requestPraveshOptions)
      .then((res) => {
        setCities(res)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  useEffect(() => {
    setLoading(true);
    apiCall("/api/admin/university/", requestPraveshOptions).then(json => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            university_id: parseInt(json.data[i].university_id),
            university_name: json.data[i].university_name,
            university_code: json.data[i].university_code,
            university_type: json.data[i].university_type,
            website: json.data[i].website,
            address: json.data[i].address,
            city: json.data[i].city_name,
            state: json.data[i].state_name,
            logo: json.data[i].logo,
            banner: json.data[i].banner,
            contact_person: json.data[i].contact_person,
            designation: json.data[i].designation,
            email: json.data[i].email,
            phone: json.data[i].phone
          };
          tmpArray.push(obj);
        }
        setLoading(false);
        setData(tmpArray);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const columns = [
    {
      title: "Domain ID",
      field: "university_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "University Name",
      field: "university_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    { title: "University Code", field: "university_code", headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "University Type", field: "university_type", headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "Website", field: "website", headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "Address", field: "address", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "State", field: "state", headerStyle: headerCSS, cellStyle: cellCSS, editable: "never", },
    { title: "City", field: "city", headerStyle: headerCSS, cellStyle: cellCSS, editable: "never", },
    {
      title: "Logo", field: "logo", headerStyle: headerCSS, cellStyle: cellCSS,
      render: row => row.logo ? <img src={row.logo} alt="logo" width='200px' /> : null,
      editComponent: props => (
        <input
          type="file"
          onChange={e => { props.onChange(e.target.files[0]) }}
          accept="image/png, image/jpeg"
        />
      )
    },
    {
      title: "Banner", field: "banner", headerStyle: headerCSS, cellStyle: cellCSS,
      render: row => row.banner ? <img src={row.banner} alt="banner" width='200px' /> : null,
      editComponent: props => (
        <input
          type="file"
          onChange={e => { props.onChange(e.target.files[0]) }}
          accept="image/png, image/jpeg"
        />
      )
    },
    { title: "Contact Person", field: "contact_person", headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "Designation", field: "designation", headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "Email", field: "email", headerStyle: headerCSS, cellStyle: cellCSS },
    { title: "Phone", field: "phone", headerStyle: headerCSS, cellStyle: cellCSS }
  ];

  const addNewuniversity = e => {
    e.preventDefault();

    // payload for post data.
    const formData = new FormData()
    formData.append('name', universityName)
    universityCode && formData.append('university_code', universityCode)
    formData.append('university_type', universityType)
    website && formData.append('website', website)
    formData.append('address', address)
    if (stateId)
      formData.append('state', stateId)
    else {
      FailureAlert("Select state, state can't be blank");
      return;
    }
    if (cityId)
      formData.append('city', cityId)
    else {
      FailureAlert("Select City, city can't be blank")
      return
    }
    logo && formData.append('logo', logo, logo.name)
    banner && formData.append('banner', banner, banner.name)
    contactPerson && formData.append('contact_person', contactPerson)
    designation && formData.append('designation', designation)
    email && formData.append('email', email)
    phone && formData.append('phone', phone)

    // option (no content-type for sending data in formData)
    const requestOptionsPost = {
      method: "POST",
      headers: { Authorization: `Token ${token}` },
      body: formData,
    }

    apiCall("/api/admin/university/", requestOptionsPost).then(res => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCount(count + 1);
      } else {
        FailureAlert(res.error);
      }
    })
      .then(setLogo(null))
      .then(setBanner(null))
      .then(setUniversityCode(null))
      .then(setWebsite(''))
      .then(setContactPerson(''))
      .then(setDesignation(''))
      .then(setEmail(''))
      .then(setPhone(null))
      .then(setCityId(null))
      .then(setStateId(null))
    e.target.reset();
  };

  const edit = {
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];
          const index = oldData.tableData.university_id;
          dataUpdate[index] = newData;
          updateRow(newData, oldData);
          setData([...dataUpdate]);
          resolve();
        }, 1000);
      }),
  };

  // funtion for PATCH data.
  const updateRow = (newData, oldData) => {

    // payload for PATCH data.
    const formData = new FormData()
    formData.append('name', newData.university_name)
    newData.university_code && formData.append('university_code', newData.university_code)
    formData.append('university_type', newData.university_type)
    newData.website && formData.append('website', newData.website)
    formData.append('address', newData.address)
    // if (oldData.state !== newData.state) { formData.append('state', newData.state) }
    // if (oldData.city !== newData.city) { formData.append('city', newData.city) }
    if (oldData.logo !== newData.logo) { formData.append('logo', newData.logo); }
    if (oldData.banner !== newData.banner) { formData.append('banner', newData.banner); }
    newData.contact_person && formData.append('contact_person', newData.contact_person)
    newData.designation && formData.append('designation', newData.designation)
    newData.email && formData.append('email', newData.email)
    newData.phone && formData.append('phone', newData.phone)

    const requestOptionsPatch = {
      method: "PATCH",
      headers: { Authorization: `Token ${token}` },
      body: formData,
    };
    apiCall(
      `/api/admin/university/${newData.university_id}/`,
      requestOptionsPatch
    ).then(res => {
      if (res.success) {
        SuccessAlert(res.data.message);
      } else {
        FailureAlert(res.error);
      }
      setCount(count + 1);
    });
  };

  return !loading ? (
    <div className="industry">
      <div className="industry__form">
        <h4>Add New University</h4>
        <Form onSubmit={e => addNewuniversity(e)}>

          <Form.Row>
            <Form.Group as={Col} controlId="universityName">
              <Form.Label>University Name*</Form.Label>
              <Form.Control
                name="universityName"
                type="text"
                placeholder="Enter university Name*"
                onChange={e => {
                  setuniversityName(e.target.value);
                }}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="universityCode">
              <Form.Label>University Code</Form.Label>
              <Form.Control
                name="universityCode"
                type="text"
                placeholder="Enter university Code"
                onChange={e => {
                  setUniversityCode(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="universityType">
              <Form.Label>University Type*</Form.Label>
              <Form.Control
                as="select" name="universityType"
                onChange={e => {
                  setUniversityType(e.target.value);
                }}
                required
              >
                <option value=''>--Select--</option>
                <option value='DEEMED'>DEEMED</option>
                <option value='STATE'>STATE</option>
                <option value='CENTRAL'>CENTRAL</option>
                <option value='NATIONAL_IMPORTANCE'>NATIONAL IMPORTANCE</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="website">
              <Form.Label>Website</Form.Label>
              <Form.Control
                name="website"
                type="text"
                placeholder="Enter university website"
                onChange={e => {
                  setWebsite(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="address">
              <Form.Label>Address*</Form.Label>
              <Form.Control
                name="address"
                type="text"
                placeholder="Enter university address*"
                onChange={e => {
                  setaddress(e.target.value);
                }}
                required
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="state">
              <Autocomplete
                id="select-state" options={states}
                getOptionLabel={(item) => item.state}
                onChange={(e, item) => item ? setStateId(item.state_id) : setStateId(0)}
                renderInput={(params) => <TextField {...params} label="Select State" variant="outlined" />}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="city">
              <Autocomplete
                id="select-city" options={cities}
                getOptionLabel={(item) => item.city}
                onChange={(e, item) => item ? setCityId(item.city_id) : setCityId(0)}
                renderInput={(params) => <TextField {...params} label="Select City" variant="outlined" />}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="contactPerson">
              <Form.Label>Contact Person</Form.Label>
              <Form.Control
                name="contactPerson"
                type="text"
                placeholder=""
                onChange={e => {
                  setContactPerson(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="designation">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                name="designation"
                type="text"
                placeholder=""
                onChange={e => {
                  setDesignation(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="example@example.com"
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                name="phone"
                type="number"
                placeholder="123456789"
                max="2147483647"
                onChange={e => {
                  setPhone(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="logo">
              <Form.File
                id="formcheck-api-custom"
                custom style={{ marginTop: '1%', marginBottom: '1%' }}
              >
                <Form.File.Input onChange={e => setLogo(e.target.files[0])}
                  isValid accept="image/png, image/jpeg"
                />
                <Form.File.Label data-browse="Upload Logo">
                  Click to choose image from local directory
                </Form.File.Label>
                {
                  logo ?
                    <Form.Control.Feedback type="valid">{logo.name}</Form.Control.Feedback>
                    : null
                }
              </Form.File>
            </Form.Group>
            <Form.Group as={Col} controlId="banner">
              <Form.File
                id="formcheck-api-custom"
                custom style={{ marginTop: '1%', marginBottom: '1%' }}
              >
                <Form.File.Input onChange={e => setBanner(e.target.files[0])}
                  isValid accept="image/png, image/jpeg"
                />
                <Form.File.Label data-browse="Upload Banner">
                  Click to choose image from local directory
                </Form.File.Label>
                {
                  banner ?
                    <Form.Control.Feedback type="valid">{banner.name}</Form.Control.Feedback>
                    : null
                }
              </Form.File>
            </Form.Group>
          </Form.Row>

          <Button type="submit" variant="info">
            Add university
          </Button>
        </Form>
      </div>
      <TableWithExport
        title="university"
        data={data}
        columns={columns}
        fName="Selected University"
        editableObject={edit}
      />
    </div>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}
