import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { apiCall } from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';

function CollegeList() {

    const { group_id } = useParams();

    const [collegeList, setCollegeList] = useState(null);
    const [loading, setLoading] = useState(false);

    // Degrees Finding API
    useEffect(() => {
        setLoading(true)
        apiCall(`/api/admin/education_group/view/?group_id=${group_id}`, requestPraveshOptions)
            .then((res) => {
                var obj = []
                if (res.success) {
                    res.data.college_list?.forEach((item, index) => {
                        let temp = {
                            slNo : index + 1,
                            college_id : item.id,
                            name : item.name
                        }
                        obj.push(temp)
                    })
                    setCollegeList(obj)
                    setLoading(false)
                }
                else {
                    FailureAlert(res.error)
                    setLoading(false)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: "Sl No.", field: "slNo", headerStyle: headerCSS, cellStyle: cellCSS,
            searchable: false,
        },
        {
            title: "College Id", field: "college_id", headerStyle: headerCSS, cellStyle: cellCSS,
            searchable: false,
        },
        { title: "College name", field: "name", headerStyle: headerCSS, cellStyle: cellCSS },
    ]


    return (
        !loading
            ?
            <div className="college__specializations">
                <TableWithExport
                    title="College List"
                    data={collegeList}
                    columns={columns}
                    fName="CollegeList"
                />
            </div>
            :
            <img
                src={gif}
                alt="Getwork Loading Gif..."
                style={gifStyling}
            />
    )
}

export default CollegeList;