import React from 'react';
import background from '../assets/background-img.jpg';
import '../css/Login.css';
import logo from '../assets/getwork_new.png';
import Login from '../components/Login';
import ResetPassword from '../components/ResetPassword';
import {Card} from 'react-bootstrap';


function SignIn({remember}) {

    return (
        <div className="login">
            <img 
                src={background}
                alt="Background"
            />
            <div className="login__body">

                { !remember ? 
                    <Card className="login__body__left">
                        <h3>Welcome Admin!</h3><br />
                        <small>Please sign in with your valid credentials!</small>
                    </Card>
                   :
                    <Card className="login__body__left">
                        <h3>Welcome Admin!</h3><br />
                        <small>Please confirm your email to reset Password!</small>
                    </Card>
                }

                <div className="login__body__right">
                    <div className="login__body__right__upper">
                        <img 
                            src={logo}
                            alt="GetWork Logo"
                            height='50vh'
                            width='45vw'
                        />
                    </div>
                    <div className="login__body__form"> 
                      { !remember ?
                          <div>
                            <Login />
                            <div className="forgot__password">
                                <a href="/forgot-password">Forgot Password?</a>
                            </div>
                          </div>
                        : 
                          <ResetPassword />
                      }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;