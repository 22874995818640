import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";

export default function EditOrderHistory({
  open,
  handleClickOpen,
  handleClose,

  currentRow,
  edit,
  setCurrentRow,
  setEdit,
  editOrder,
  setEditOrder,
  updateProduct,
}) {
  //   const handleChangeStatus = (e) => {
  //     setStatus(e.target.value);
  //   };

  const orderStatus = [
    { id: "CREATED", name: "Created" },
    { id: "PAYMENT_SUCCESS", name: "Payment Successful" },
    { id: "PAYMENT_FAILED", name: "Payment Failed" },
    { id: "PAYMENT_INITIATED", name: "Payment Initiated" },
    { id: "ACCEPTED", name: "Accepted" },
    { id: "RETURNED", name: "Returned" },
    { id: "REFUNDED", name: "Refunded" },
    { id: "DELIVERED", name: "Delivered" },
    { id: "CANCELLED", name: "Cancelled" },
  ];

  useEffect(() => {
    if (editOrder?.order_status) {
      if (editOrder?.order_status?.id === "RETURNED") {
        setEditOrder({ ...editOrder, date: currentRow?.extra_details?.return_data?.date?.slice(0, 10), message: currentRow?.extra_details?.return_data?.message });
      }
      if (editOrder?.order_status?.id === "REFUNDED") {
        setEditOrder({ ...editOrder, date: currentRow?.extra_details?.refund_data?.date?.slice(0, 10), message: currentRow?.extra_details?.refund_data?.message });
      }
      if (editOrder?.order_status?.id === "DELIVERED") {
        setEditOrder({ ...editOrder, date: currentRow?.extra_details?.delivery_data?.date?.slice(0, 10), message: currentRow?.extra_details?.delivery_data?.message });
      }
      if (editOrder?.order_status?.id === "CANCELLED") {
        setEditOrder({ ...editOrder, date: currentRow?.extra_details?.cancellation_data?.date?.slice(0, 10), message: currentRow?.extra_details?.cancellation_data?.message });
      }
    }
  }, [editOrder?.order_status]);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle id="form-dialog-title">{edit ? "Edit" : "Add"} Category</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates occasionally.</DialogContentText> */}
          <Autocomplete
            options={orderStatus}
            getOptionLabel={(item) => item.name}
            // style={{ width: "50%" }}
            onChange={(e, item) => {
              if (item) {
                setEditOrder({ ...editOrder, order_status: item });
              } else {
                setEditOrder({ ...editOrder, order_status: null });
              }
            }}
            value={editOrder.order_status}
            renderInput={(params) => <TextField {...params} label="Order Status" variant="outlined" />}
          />
          <br />
          {(editOrder?.order_status?.id === "RETURNED" ||
            editOrder?.order_status?.id === "REFUNDED" ||
            editOrder?.order_status?.id === "DELIVERED" ||
            editOrder?.order_status?.id === "CANCELLED") && (
            <>
              <TextField
                margin="dense"
                variant="outlined"
                id="name"
                type="date"
                label="Date"
                fullWidth
                value={editOrder?.date}
                onChange={(e) => {
                  setEditOrder({ ...editOrder, date: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
              />
              <br />

              <TextField
                multiline
                margin="dense"
                variant="outlined"
                id="name"
                label="Message"
                fullWidth
                value={editOrder?.message}
                onChange={(e) => {
                  setEditOrder({ ...editOrder, message: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
                rows={5}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              //   if (!product) {
              //     FailureAlert("Please Enter A  Product");
              //     return;
              //   }
              //   if (!banner && !tempImage) {
              //     FailureAlert("Please select a Banner");
              //     return;
              //   }
              if (edit) updateProduct(currentRow?.id);
              //   else addProduct();
              handleClose();
            }}
            color="primary"
          >
            {edit ? "Edit" : "Add"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
