import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router';
import {apiCall} from '../../util/apiCall';
import {FormControlLabel, Switch} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactHtmlParser from 'react-html-parser';
import {Form, Col, Button} from 'react-bootstrap';
import { gifStyling, requestPraveshOptions } from '../../constants';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

function SingleBlog() {

    const {blog_id} = useParams();

    const [data, setData] = useState({});
    const [editBlog, setEditBlog] = useState(false);
    const [removeLink, setRemoveLink] = useState(false);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [iconImage, setIconImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [body, setBody] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');


    const onEditorStateChange = (s) => {
        setEditorState(s)
        setBody(draftToHtml(convertToRaw(s.getCurrentContent())))
    }

    // Fetching Blog Details
    useEffect(()=> {
        setLoading(true);
            apiCall(`/api/admin/blog/?blog_id=${blog_id}`, requestPraveshOptions)
            .then((res)=> {
                if(res.success) { 
                    setData(res.data); 
                    setEditorState(
                        EditorState
                        .createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.data.body)))
                    );
                    setName(res.data.author ? `${res.data.author.first_name} ${res.data.author.last_name}` : '')
                }
                else { FailureAlert(res.error) }
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const updateThisBlog = (e) => {
        e.preventDefault();
        setLoading(true);
        const slug = title.split(' ').join('-').toLowerCase();

        const formData = new FormData();
        title && formData.append('title', title);
        title && formData.append('slug', slug);
        status && formData.append('status', status);
        removeLink && formData.append('youtube_link', '');
        !removeLink && youtubeLink && formData.append('youtube_link', youtubeLink);
        iconImage && formData.append('icon_image', iconImage);
        mainImage && formData.append('main_image', mainImage);
        formData.append('body', body || data.body);
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 'Authorization': `Token ${localStorage.getItem('token')}` },
            body: formData
        };
        apiCall(`/api/admin/blog/${blog_id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                window.document.getElementById('edit__blog__form__details').reset();
                setEditorState(EditorState.createEmpty());
                setTitle(''); setStatus(''); setYoutubeLink('');
                setBody(''); setRemoveLink(false); setEditBlog(false);
                setIconImage(null); setMainImage(null);
                setCount(count+1);
            }
            else FailureAlert(res.error);
        })
        .then(setLoading(false))
    }

    return (
        !loading && data
        ?
        !editBlog
        ?
        <div className="blog__view__details">
            <h2>{data.title}</h2>
            <span>
                <p>By <b>{name}</b>: {data.formatted_time} ago</p>
            </span>
            <span>
                <p>Status: <i>{data.status}</i></p>
            </span>
            <div>
            {
                data.image?
                <img 
                    src={data.image}
                    alt="blog mainPic"
                    style={{height:'200px', width:'60%'}}
                />
                :
                null
            }
            </div>
            <div className="blog__body">
                {ReactHtmlParser(data.body)}
            </div>
            <div>
            {
                data.youtube?
                <Button 
                    style={{backgroundColor: 'red'}}    
                    onClick={()=>window.open(data.youtube)}
                >Youtube</Button> :null
            }
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={editBlog} onChange={e=>setEditBlog(!editBlog)}
                            name="editBlog" color="primary"
                        />
                    }
                    label="Edit Blog"
                />
            </div>
        </div>
        :
        <div className="edit__blog__details" style={{marginLeft:'3%', width:'94%'}}>
            <FormControlLabel
                control={
                    <Switch
                        checked={editBlog} onChange={e=>setEditBlog(!editBlog)}
                        name="editBlog" color="primary"
                    />
                }
                label="Return"
            />
            <Form id="edit__blog__form__details" onSubmit={e=>updateThisBlog(e)}>
                {/* Blog Title and Status */}
                <Form.Row>
                    <Form.Group as={Col} controlId="title">
                        <Form.Label>Blog Title*</Form.Label>
                        <Form.Control 
                            name="title" type="text" placeholder={data.title}  
                            onChange = {e=> {setTitle(e.target.value)}} 
                        />
                    </Form.Group>                
                    <Form.Group as={Col} controlId="status">
                        <Form.Label>Blog Status*</Form.Label>
                        <Form.Control as="select" onChange = {e=> {setStatus(e.target.value)}} >
                            <option value="">--select--</option>
                            <option value="DRAFT">Draft</option>
                            <option value="PUBLISHED">Published</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>                
                {/* Youtube Link (OPTIONAL) */}
                <Form.Group controlId="youtubeLink">
                    <Form.Label>Youtube Link (if any)</Form.Label>
                    <div style={{display:'flex', flexDirection:'row', flex:4}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={removeLink} onChange={e=>setRemoveLink(!removeLink)}
                                    name="removeLink" color="primary"
                                />
                            }
                            style={{width:'25%'}}
                            label="Remove-link"
                        />
                        <Form.Control 
                            name="youtubeLink" type="text" style={{width: '75%'}} disabled={removeLink}
                            placeholder={ removeLink ? "Youtube Link Disabled": data.youtube }
                            onChange = {e=> {setYoutubeLink(e.target.value)}} 
                        />
                    </div>
                </Form.Group>
                <Form.Label>Blog Body*</Form.Label>
                <div className="editor">
                    <Editor
                        editorState={editorState}
                        // editorState={ReactHtmlParser(data.body)}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        onEditorStateChange={onEditorStateChange}
                    />
                </div>
                {/* ICON Image */}
                <Form.File id="formcheck-api-custom" custom style={{marginTop:'1%', marginBottom:'2%'}}>
                    <Form.File.Input onChange={e=>setIconImage(e.target.files[0])} isValid />
                    <Form.File.Label data-browse="Upload Icon Image">
                        (ICON IMAGE) Click to choose file from local directory
                    </Form.File.Label>
                    {
                        iconImage ? 
                        <Form.Control.Feedback type="valid">{iconImage.name}</Form.Control.Feedback> 
                        : null
                    }
                </Form.File>
                {/* MAIN Image */}
                <Form.File id="formcheck-api-custom" custom style={{marginTop:'1%', marginBottom:'2%'}}>
                    <Form.File.Input onChange={e=>setMainImage(e.target.files[0])} isValid />
                    <Form.File.Label data-browse="Upload Main Image">
                        (MAIN IMAGE) Click to choose file from local directory
                    </Form.File.Label>
                    {
                        mainImage ? 
                        <Form.Control.Feedback type="valid">{mainImage.name}</Form.Control.Feedback> 
                        : null
                    }
                </Form.File>
                <Button type='submit' style={{marginBottom:'15px'}}>Update</Button>            
            </Form>       
        </div>
        :
        <img 
            src={gif} 
            alt="Getwork Loading Gif..."
            style={gifStyling}
        />
    )
}

export default SingleBlog;