import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { niyuktiAxiosCall, praveshAxiosCall } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import { ExportToExcel } from "../../Student/Components/Dialog/ExcelExport";
import CustomButtonCircularProgress from "../../Student/Components/loader";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  chipsection: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "0px",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "10px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function JobTrackApplyJob(props) {
  const classes = useStyles();
  const { SelectedID, setSelectedID, clearSelection } = props;
  const [showMore, setShowMore] = React.useState(false);
  const fullWidth = true;
  const maxWidth = "xs";
  const [isUpdateCall, setIsUpdateCall] = useState(false);

  const [chipData, setChipData] = React.useState(SelectedID);
  const [openJobs, setOpenJobs] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [companyFlag, setCompanyFlag] = React.useState(false);
  const [selectedJob, setSelectedJob] = useState();
  const [excel, setExcel] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [openJobsFlag, setOpenJobsFlag] = useState(false);
  const [allApiSuccess, setAllApiSuccess] = React.useState({
    status: 0,
    message: "",
  });
  const handleDelete = (id, index) => {
    // debugger
    // let temp = chipData.filter((chip) => chip.user_id !== id)
    setChipData((chips) => chips.filter((chip) => chip.user_id !== id));
  };
  const fileName = "myfile";
  useEffect(() => {
    if (SelectedID) {
      setChipData(SelectedID);
    }
  }, [SelectedID]);

  let user;
  if (chipData?.length > 0) {
    user = chipData.map((item) => item?.user_id);
  }

  const applyJob = () => {
    const body = {
      user_ids: user,
      job_id: selectedJob.job_id,
    };
    setLoading(true);
    niyuktiAxiosCall
      .post(`/job/student/apply/multiple/`, body)
      .then((res) => {
        if (res.data.success) {
          setSelectedID([]);
          SuccessAlert(res.data.data.message);
          setData(res.data.data?.error_list);
          setExcel(true);
          // handleClose()
          setLoading(false);
        } else {
          FailureAlert(res.data.error);
          handleClose();
          setLoading(false);
        }
      })
      .catch((err) => {
        FailureAlert("Something Went Wrong", err.message);
        setIsUpdateCall(false);
      });
  };

  const getOpenJobsList = () => {
    niyuktiAxiosCall
      .get(`/job/all_job/list/?company_id=${selectedCompany.id}`)
      .then((res) => {
        if (res.data.success) {
          setOpenJobs(res.data.data);
        } else {
          setOpenJobsFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setOpenJobsFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `Open Job List Error : ${err.message}`,
        });
      });
  };

  const getCompanyList = () => {
    praveshAxiosCall
      .get(`/api/company/company/filter/`)
      .then((res) => {
        if (res.data.success) {
          setCompany(res.data.data);
        } else {
          setCompanyFlag(true);
          setAllApiSuccess({ status: -1, message: res.error });
        }
      })
      .catch((err) => {
        setCompanyFlag(true);
        setAllApiSuccess({
          status: -1,
          message: `Open company List Error : ${err.message}`,
        });
      });
  };
  const handleClose = () => {
    setSelectedCompany();
    setSelectedJob();
    setExcel(false);
    props.handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        //onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6">Selected Student</Typography>
          <div component="ul" className={classes.chipsection}>
            {!showMore
              ? chipData?.slice(0, 5).map((data, index) => {
                  let icon;
                  return (
                    <li key={data.user_id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.user_id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.applicant_name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : chipData?.map((data, index) => {
                  let icon;
                  return (
                    <li key={data.user_id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.user_id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.applicant_name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
          </div>
          {SelectedID?.length > 5 && (
            <Button
              className={classes.button}
              onClick={() => {
                setShowMore(!showMore);
              }}
              style={{
                padding: "5px",
                marginLeft: "7px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              color="primary"
            >
              {showMore ? "Hide" : "View All"}
            </Button>
          )}
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedCompany}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedCompany(newValue);
                }
              }}
              id="role345"
              options={company}
              getOptionLabel={(option) => `${option.id}-${option.name}`}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    onClick={() => {
                      getCompanyList();
                    }}
                    label={
                      <span style={{ fontSize: 13 }}> Select Company </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedJob}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedJob(newValue);
                }
              }}
              id="role345"
              options={openJobs}
              getOptionLabel={(option) =>
                `${option.job_title}-${option.hiring_type} (${option.job_id})`
              }
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.job_title} -${option.hiring_type} (${option.job_id})`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    onClick={() => {
                      getOpenJobsList();
                    }}
                    label={<span style={{ fontSize: 13 }}>Apply to Job</span>}
                    variant="outlined"
                  />
                );
              }}
            />
          </div>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ height: "45px", display: "flex" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              {!excel ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "45px", display: "flex" }}
                  onClick={() => {
                    if (
                      Object.keys(selectedCompany)?.length !== 0 &&
                      Object.keys(selectedJob)?.length !== 0
                    )
                      applyJob();
                    clearSelection();
                  }}
                >
                  {loading ? <CustomButtonCircularProgress /> : "Apply"}
                </Button>
              ) : (
                <ExportToExcel
                  apiData={data}
                  fileName={fileName}
                  handleClose={handleClose}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
