import {
  AppBar, Box,
  makeStyles, Tab,
  Tabs,
  useTheme
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import RecruitersReportTabSection from "../../components/Dashboard/RecruitersReportTabSection";
import TeamLeadsReportTabSection from "../../components/Dashboard/TeamLeadsReportTabSection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5F5",
    width: "100%",
  },
}));

function ManagerTeamLeadDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="static"
          color="default"
          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Team Leads Report" {...a11yProps(0)} />
            <Tab label="Recruiters Report" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <TeamLeadsReportTabSection />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <RecruitersReportTabSection />
        </TabPanel>
      </div>
    </>
  );
}

export default ManagerTeamLeadDashboard;
