import {
  FormControl, FormControlLabel, Grid,
  Radio, RadioGroup
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import { niyuktiAxiosPostReq } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import CustomButtonCircularProgress from "../../loader";
import SocialMedia from "./SocialMedia";
import VendorField from "./VendorField";


const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ShareDialog({open, setOpen, currentSelectedRow}) {
  const fullWidth = true;
  const maxWidth = "sm";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState("social");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const [vendorData, setVendorData] = useState([]);

  const [loading, setLoading] = useState(false);

  const submitJobVendors = async () => {
    
    let tempData = vendorData?.filter( item  => {
        return item?.isChecked;
    })
    if(tempData?.length < 1){
      FailureAlert("Please share job to atleast one vendor!");
      return;
    }
    if( tempData?.filter(item => {
      return !item?.payment || item?.payment == 0;
    }).length > 0 ){
      FailureAlert("Payment value can't be 0!");
      return;
    }

    if( tempData?.filter(item => {
      return item?.paymentMode === 'PERCENT' && item?.payment > 100
    }).length > 0 ){
      FailureAlert("Payment percent can't be more than 100!");
      return;
    }

    

    let postData = tempData?.map(item => {
      return {job_id: currentSelectedRow?.job_id,
             vendor_id: item?.vendor_id,
             compensation_type: item?.paymentMode,
             compensation: item?.payment}
    })

    postData = {vendor_data: postData}
    setLoading(true);
    const res = await niyuktiAxiosPostReq(`/job/vendor/job_share/`, postData);
      if(res?.data?.success){
          SuccessAlert(res?.data?.data?.message || "Job Shared Successfully");
          
          handleClose();
      } else {
        FailureAlert("Error in sharing job to vendor");
      }
      setLoading(false);
  }

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Share {currentSelectedRow?.job_title} Job
        </DialogTitle>
        <DialogContent
          style={{ paddingTop: 0, paddingLeft: "25px", paddingRight: "25px" }}
        >
          <Grid container spacing={2}>
            <Grid item md={12} style={{ display: "grid" }}>
              <FormControl>
                <RadioGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  aria-label="quiz"
                  name="quiz"
                  value={value}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="social"
                    control={<Radio color="primary" />}
                    label="Social media"
                  />
                  <FormControlLabel
                    value="vendor"
                    control={<Radio color="primary" />}
                    label="Vendor"
                  />
                </RadioGroup>
              </FormControl>

              {value == "social" ? (
                <>
                  <SocialMedia data={currentSelectedRow}/>
                </>
              ) : (
                <>
                  <VendorField data={currentSelectedRow} vendorData={vendorData} setVendorData={setVendorData}/>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        {value !== 'social' && vendorData?.length > 0 &&
        <DialogActions
          style={{
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              height: "40px",
              boxShadow: "none",
            }}
            onClick={submitJobVendors}
          >
            <Typography style={{ fontSize: "14px" }}>
              {loading ? <CustomButtonCircularProgress /> : "Share Job"}
                </Typography>
          </Button>
        </DialogActions>
}
      </Dialog>
    </div>
  );
}
