import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from 'react';
import { Col, Form } from "react-bootstrap";
import { emailRegex } from '../../../constants';
import { praveshAxiosPostReq } from '../../../util/apiCall';
import { praveshAxiosGetReq } from '../../../util/baseApi';
import { FailureAlert, SuccessAlert } from '../../../util/Notifications';
import EditSpocDetails from './EditSpocDetails';

const useStyles = makeStyles((theme) => ({
    keyvalue: {
        fontWeight: 600,
        fontSize: 16,
    },

}))



const NewCompanySpoc = ({ newCompanyDetails, setNewCompanyDetails, editCompanyFlag }) => {

    const classes = useStyles();

    const [tpoUsers, setTpoUsers] = useState([]);
    const [email, setEmail] = useState('');

    const getTpoUsers = async () => {

        const endPoint = `/api/admin/operations/company/user/?company_id=${newCompanyDetails?.companyBasicInfo?.companyId}`

        try {
            const res = await praveshAxiosGetReq(endPoint);

            if (res?.data?.success) {
                setTpoUsers(res?.data?.data);
            }

        } catch (error) {
            throw (error);
        }
    }

    useEffect(() => {

        if (newCompanyDetails?.spocDetails?.email) {

            setEmail(newCompanyDetails?.spocDetails?.email);
        }

    }, [newCompanyDetails?.spocDetails?.email])
    // TPO users dropdown API

    useEffect(() => {

        if (tpoUsers?.length === 0 && newCompanyDetails?.companyBasicInfo?.companyId) {
            getTpoUsers();
        };

    }, []);

    const handleEmailchange = (e) => {


        const value = e.target.value;
        setEmail(value);

        emailRegex.lastIndex = 0;

        let validMail = emailRegex.test(value);

        if (value?.includes('.') && validMail) {

            const checkSpocAvailability = async () => {

                const endPoint = `/api/check_user/`;

                try {
                    const res = await praveshAxiosPostReq(endPoint, { email: value });

                    if (res?.data?.success) {

                        const is_valid = res?.data?.data?.is_company_user;

                        if (is_valid) {
                            FailureAlert(res?.data?.data?.message)
                        } else {
                            SuccessAlert("Email Available for SPOC creation")
                            setNewCompanyDetails({
                                ...newCompanyDetails,
                                spocDetails: {
                                    ...newCompanyDetails?.spocDetails,
                                    email: value
                                }
                            });
                        }
                    }

                } catch (error) {
                    throw (error)
                }
            }

            setTimeout(checkSpocAvailability(), 3000)
        }

        // setNewCompanyDetails({
        //     ...newCompanyDetails,
        //     spocDetails: {
        //         ...newCompanyDetails?.spocDetails,
        //         email: e.target.value
        //     }
        // });
    }


    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <Form
                    className="edit__poc__details__form"
                    id="edit__poc__details__form__details"
                >
                    {
                        editCompanyFlag ?
                            <EditSpocDetails
                            Details
                                tpoUsers={tpoUsers}
                                setTpoUsers={setTpoUsers}
                                newCompanyDetails={newCompanyDetails}
                                setNewCompanyDetails={setNewCompanyDetails}
                            />
                            :
                            // when new company is being created this form is being used
                            <>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="pocdetails">
                                        <Form.Label className={classes.keyvalue} >First Name*</Form.Label>
                                        <Form.Control
                                            name="firstname"
                                            type="text"
                                            placeholder={"First Name*"}
                                            value={newCompanyDetails?.spocDetails?.firstName}
                                            onChange={(e) => {
                                                setNewCompanyDetails({
                                                    ...newCompanyDetails,
                                                    spocDetails: {
                                                        ...newCompanyDetails?.spocDetails,
                                                        firstName: e.target.value
                                                    }
                                                });
                                            }}
                                            required={true}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="pocdetails">
                                        <Form.Label className={classes.keyvalue} >Last Name*</Form.Label>
                                        <Form.Control
                                            name="lastname"
                                            type="text"
                                            placeholder={"Lastname*"}
                                            value={newCompanyDetails?.spocDetails?.lastName}
                                            onChange={(e) => {
                                                setNewCompanyDetails({
                                                    ...newCompanyDetails,
                                                    spocDetails: {
                                                        ...newCompanyDetails?.spocDetails,
                                                        lastName: e.target.value
                                                    }
                                                });
                                            }}
                                            required={true}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="pocdetails">
                                        <Form.Label className={classes.keyvalue}>Designation*</Form.Label>
                                        <Form.Control
                                            name="designation"
                                            type="text"
                                            placeholder={"Designation*"}
                                            value={newCompanyDetails?.spocDetails?.designation}
                                            onChange={(e) => {
                                                setNewCompanyDetails({
                                                    ...newCompanyDetails,
                                                    spocDetails: {
                                                        ...newCompanyDetails?.spocDetails,
                                                        designation: e.target.value
                                                    }
                                                });

                                            }}
                                            required={true}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="pocdetails">
                                        <Form.Label className={classes.keyvalue}>Email*</Form.Label>
                                        <Form.Control
                                            name="email"
                                            placeholder={"Email*"}
                                            value={email}
                                            onChange={handleEmailchange}

                                            // onChange={(e) => {

                                            //     setNewCompanyDetails({
                                            //         ...newCompanyDetails,
                                            //         spocDetails: {
                                            //             ...newCompanyDetails?.spocDetails,
                                            //             email: e.target.value
                                            //         }
                                            //     });
                                            // }}
                                            required={true}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="pocdetails">
                                        <Form.Label className={classes.keyvalue}>Phone No*</Form.Label>
                                        <Form.Control
                                            name="Phone"
                                            type="number"
                                            pattern="[0-9]*"
                                            maxLength="10"
                                            minLength="10"
                                            placeholder={"Phone Number*"}
                                            value={newCompanyDetails?.spocDetails?.phone}
                                            onChange={(e) => {
                                                let stringNo = e.target.value?.toString();
                                                if (stringNo > 10) {
                                                    stringNo = stringNo.substring(0, 10);
                                                    stringNo = parseInt(stringNo);

                                                } else {
                                                    stringNo = e.target.value;
                                                };
                                                setNewCompanyDetails({
                                                    ...newCompanyDetails,
                                                    spocDetails: {
                                                        ...newCompanyDetails?.spocDetails,
                                                        phone: stringNo
                                                    }
                                                })
                                            }}
                                            required={true}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="pocdetails">
                                        <Form.Label className={classes.keyvalue}>Alternate Email:</Form.Label>
                                        <Form.Control
                                            name="alternateEmail"
                                            type="email"
                                            placeholder={"Alternate Email"}
                                            value={newCompanyDetails?.spocDetails?.altEmail}
                                            onChange={(e) => {

                                                setNewCompanyDetails({
                                                    ...newCompanyDetails,
                                                    spocDetails: {
                                                        ...newCompanyDetails?.spocDetails,
                                                        altEmail: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="pocdetails">
                                        <Form.Label className={classes.keyvalue}>Alternate Phone No:</Form.Label>
                                        <Form.Control
                                            name="alternatePhone"
                                            type="number"
                                            pattern="[0-9]*"
                                            maxLength="10"
                                            minLength="10"
                                            placeholder={"Alternate Phone"}
                                            value={newCompanyDetails?.spocDetails?.altPhone}
                                            onChange={(e) => {

                                                let stringNo = e.target.value?.toString();
                                                if (stringNo > 10) {
                                                    stringNo = stringNo.substring(0, 10);
                                                    stringNo = parseInt(stringNo);

                                                } else {
                                                    stringNo = e.target.value;
                                                };
                                                setNewCompanyDetails({
                                                    ...newCompanyDetails,
                                                    spocDetails: {
                                                        ...newCompanyDetails?.spocDetails,
                                                        altPhone: stringNo
                                                    }
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </>
                    }
                </Form>
            </div>
        </>

    )
}

export default NewCompanySpoc