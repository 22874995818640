import {
  Button,
  Chip,
  Grid,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { FailureAlert } from "../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  }
}));

export default function IndustryType({industries, industryPreference, savePreferences}) {
  const classes = useStyles();
  const [tempIndustries, setTempIndustries] = useState([]);

  useEffect(() => {
    if(industryPreference)
    setTempIndustries(industryPreference);
  }, [industryPreference])

  const handleDelete = (index)  => {
    setTempIndustries((tempIndustries) =>
    tempIndustries.filter((_, industryIndex) => industryIndex !== index)
    );
  };

  const addIndustry = (industry) => {
    if(industry){
      const industryAlreadyAdded = tempIndustries.some(skl => skl.id === industry.id);
      if(industryAlreadyAdded)
        FailureAlert("Already Added!");
      else
        setTempIndustries([...tempIndustries, {
            id: industry.id,
            industry_name: industry.industry_name
          }])
    }
  }

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Industry Type</Typography>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Autocomplete
              // multiple
              id="combo-box-demo"
              onChange={(_, newValue) => {
                if (newValue) {
                  addIndustry(newValue);
                }
              }}
              
              options={industries}
              getOptionLabel={(option) => `${option.industry_name}`}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.industry_name}`}</span>;
              }}
                className={classes.fieldStyling1}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}>
                        Select Industry
                      </span>
                    }
                    variant="outlined"
                    
                  />
                )}}
          />
        </Grid>
      </div>
      <div className={classes.root}>
        {tempIndustries && tempIndustries.map((data, index) => {
          return (
            <li key={data.industry_id}>
              <Chip
                color="primary"
                deleteIcon={<CloseIcon color="primary" />}
                variant="outlined"
                size="small"
                label={data.industry_name}
                onDelete={() => handleDelete(index)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" color="primary" onClick={() => {savePreferences("industry", tempIndustries)}}>
          Save
        </Button>
      </div>
    </div>
  );
}
