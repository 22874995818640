import React, { useEffect, useState } from "react";
//import Slide from "@m/core/Slide";
//import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Dialog,
  Grid,
  Typography,
  CircularProgress,
  Slide,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useParams } from "react-router-dom";

//import { NIYUKTI_API } from "../../Constants/constants";
//import fetchApi from "../../Apis/fetchApi";
//import AllComment from "./TackJobDialogs/AllComment";

//import { ReactComponent as NoData } from "../../Assets/Images/svg/Frame.svg";

import AllComment from "./AllComment";
import UseAxiosApi from "../../../Api/UseAxiosApi";
import { niyuktiUrl } from "../../../constants";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CandidateComments(props) {
  const [open, setOpen] = React.useState(false);
  const [commentsData, setCommentsData] = useState(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  let { token } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getCommentsApi();
  };

  const { loading, error, apiCall } = UseAxiosApi({
    url: `${niyuktiUrl}/job/admin/company/candidate/comments/?user_id=${btoa(
      props?.currentCandidate?.userId
    )}`,
    setResponse: (data) => {
      setCommentsData(data?.data);
    },
    mountingCallFlag: false,
  });

  useEffect(() => {
    if (props?.currentCandidate) {
      getCommentsApi();
      setCurrentCandidate(props?.currentCandidate);
    }
  }, [props?.currentCandidate]);

  const getCommentsApi = () => {
    apiCall(null, true);
  };

  const theme = useTheme();
  return (
    <div>
      <Dialog
        open={props.open}
        PaperProps={{
          style: { borderRadius: "8px", height: 400, width: 500 },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={60}
              style={{ marginTop: 30, marginBottom: 30 }}
            />
          </div>
        ) : (
          <div style={{ padding: "15px" }}>
            {commentsError ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                {/* <NoData height="200px" alt="404 Error" fill={"#007BFF"} /> */}
                <Typography
                  style={{
                    display: "flex",
                    margin: "auto",
                    marginTop: "20px",
                  }}
                >
                  No Comments Available
                </Typography>
              </div>
            ) : (
              <AllComment
                data={commentsData}
                currentApplicant={props?.currentCandidate}
                fromCandidate={true}
              />
            )}
          </div>
        )}
      </Dialog>
    </div>
  );
}
