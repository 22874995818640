import { DialogTitle, Typography } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from 'react';
import { Button, Col, Form } from "react-bootstrap";
import crossIcon from "../../assets/images/cancel.png";
import { FailureAlert } from '../../util/Notifications';
import CustomButtonCircularProgress from '../loader';


const useStyles = makeStyles((theme) => ({

    crossStyle: {
        width: "10px",
        height: "10px",
        cursor: "pointer"

    },
    crossSection: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    headingBox: {

        fontWeight: 600,

    },
    floatingLabelFocusStyle: {
        // color: "#7e7e7e",
        fontSize: "14px",
    },
    textfield: {

        maxHeight: '.3rem',

    },
    rowfield: {
        display: 'flex',
        alignItems: "center",
    },


    addTemplatebtnSection: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    btnTemplate: {
        minHeight: '2rem',
        minWidth: '8rem'
    }

}))


const AddResumeTemplate = ({ open, setOpen = () => { }, data, setData = () => { }, handleFormSubmission = () => { }, setEdited = () => { } }) => {

    const classes = useStyles()

    const scroll = "paper";

    const handleClose = () => {
        setOpen(false);

    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle>
                <div style={{ display: "flex", justifyContent: "space-between" }}>

                    <Typography variant='h5' className={classes.headingBox}>
                        {data?.editflag ? "Edit" : "Add"} Resume Template
                    </Typography>
                    <img
                        className={classes.crossStyle}
                        src={crossIcon}
                        onClick={() => { handleClose(); }}
                        alt="close Icon" />
                </div>
            </DialogTitle>

            <DialogContent dividers={scroll === "paper"}>

                <Form
                    id="addTemplate"
                    onSubmit={(e) => {

                    }}
                >
                    <Form.Row className={classes.rowfield}>

                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                value={data?.templateName}
                                onChange={(e) => {
                                    setData((prevData) => ({ ...prevData, templateName: e.target.value }))
                                    setEdited((prevData) => ({ ...prevData, templateName: true }))

                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.textfield }}
                                label="Template Name"
                            />

                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={classes.rowfield}>

                        <Form.Group as={Col} controlId="addTemplate2">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                value={data?.templatePath}
                                onChange={(e) => {
                                    setData((prevData) => ({ ...prevData, templatePath: e.target.value }));
                                    setEdited((prevData) => ({ ...prevData, templatePath: true }))
                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle,
                                }}
                                fullWidth
                                inputProps={{ className: classes.textfield }}
                                label="Template Path"
                            />
                        </Form.Group>
                        <Form.Group type="radio" id="company" required>
                            <Form.Label>
                                Profile Pic Included

                            </Form.Label>
                            <br></br>

                            <Form.Check
                                inline
                                id="yes"
                                type="radio"
                                name="yes"
                                value={"yes"}
                                label="Yes"
                                checked={
                                    data?.profilePicFlag === "yes"
                                }
                                onChange={(e) => {

                                    setData({
                                        ...data,
                                        profilePicFlag: e.target.value
                                    });

                                }}
                            />
                            <Form.Check
                                inline
                                id="no"
                                type="radio"
                                name="no"
                                label="No"
                                value={"no"}
                                checked={
                                    data?.profilePicFlag === "no"
                                }
                                onChange={(e) => {

                                    setData({
                                        ...data,
                                        profilePicFlag: e.target.value
                                    });

                                }}
                            />
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.File
                                id="collegeSideImage"
                                name="collegesideimage"
                                value={data?.templateFile}
                            >
                                <Form.File.Label>Upload Template Image*</Form.File.Label>
                                <Form.File.Input
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            templateFile: e?.target?.files[0],
                                            tempImage: URL.createObjectURL(e.target.files[0]),
                                        });
                                        setEdited((prevData) => ({ ...prevData, templateImage: true }))

                                    }}
                                />
                                <>
                                    {" "}
                                    {data?.templateFile?.name &&
                                        `Selected File:${data?.templateFile?.name} `}
                                </>
                                <p>Recommended Size: 720px*700px</p>
                            </Form.File>
                        </Form.Group>
                        <Form.Group>
                            {

                                (data?.tempImage || data?.templateImage) &&

                                <img style={{ height: '4rem', }}
                                    src={data?.tempImage || data?.templateImage}
                                    alt="template image"
                                />
                            }
                        </Form.Group>

                    </Form.Row>
                </Form>
                <Form.Row className={classes.addTemplatebtnSection}>
                    <Button
                        className={classes.btnTemplate}
                        variant="primary"
                        onClick={() => {
                            if (data?.templateName && (data?.templateFile || data?.templateImage) && data?.templatePath) {
                                handleFormSubmission()
                            } else {
                                FailureAlert("Enter all fields")
                            }

                        }}
                    >

                        {data?.loading ? <CustomButtonCircularProgress /> : `${data?.editflag ? "Edit" : "Add"} Template`}
                    </Button>
                </Form.Row>


            </DialogContent>

        </Dialog>
    )

}

export default AddResumeTemplate