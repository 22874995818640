import {
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useRef, useEffect } from "react";
import {
  apiPraveshCall,
  niyuktiAxiosGetReq,
  niyuktiAxiosPatchReq,
  niyuktiAxiosPostReq,
  praveshAxiosGetReq,
} from "../../util/apiCall";
import {
  requestPraveshOptions,
  emailRegex,
  headerCSS,
  cellCSS,
} from "../../constants";
import Camera from "@material-ui/icons/CameraAlt";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { praveshAxiosPostReq } from "../../util/baseApi";
import { objectToFormData } from "../../util/utility";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from "react-router";
import CustomButtonCircularProgress from "../loader";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import DatePicker from "react-date-picker";
import { BorderRight } from "@material-ui/icons";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
import TableWithoutExport from "../TableWithoutExport";
import moment from "moment";

function formatISODateTime(isoString, time = false) {
  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return time
    ? `${year}-${month}-${day} ${hours}:${minutes}`
    : `${year}-${month}-${day}`;
}

function AiCallingBot() {
  const [addCreditType, setAddCreditType] = useState(null);
  const [detailsCreditType, setDetailsCreditType] = useState({
    title: "AI Calling",
    val: "ai_calling",
  });
  const initiatAddCreditDetails = {
    paid_credits: 0,
    free_credits: 0,
    expiry_date: null,
  };
  const [addCreditDetails, setAddCreditDetails] = useState(
    initiatAddCreditDetails
  );

  const [pageLoading, setPageLoading] = useState(true);

  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [freeCreditExpiryDate, setFreeCreditExpiryDate] = useState(null);

  const [creditData, setCreditData] = useState({});
  const [transactionData, setTransactionData] = useState([]);
  const [creditDetailAdded, setCreditDetailAdded] = useState(false);

  useEffect(() => {
    if ((selectedCompany && detailsCreditType) || creditDetailAdded) {
      setPageLoading(true);
      niyuktiAxiosGetReq(
        `/api/ai_job/company/transaction_history/?company_id=${selectedCompany?.company_id}&credit_type=${detailsCreditType?.val}`
      )
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;
            if (data?.credits_data && data?.credits_data[0])
              setCreditData(data?.credits_data[0]);
            else setCreditData({});

            if (data?.transaction_data && data?.transaction_data.length)
              setTransactionData(data?.transaction_data);
            else setTransactionData([]);
            SuccessAlert(res?.data?.data?.message);
          } else {
            setCreditData({});
            setTransactionData([]);
            FailureAlert(res?.data?.error);
          }
          setPageLoading(false);
          setCreditDetailAdded(false);
        })
        .catch((err) => {
          setCreditData({});
          setTransactionData([]);
          FailureAlert("Error in fetching bot data");
          setPageLoading(false);
          setCreditDetailAdded(false);
        });
    }
  }, [selectedCompany, detailsCreditType, creditDetailAdded]);

  const [loading, setLoading] = useState(false);
  const [addCredtloading, setAddCreditLoading] = useState(false);

  const onAddCreditClicked = () => {
    setAddCreditLoading(true);
    const payload = {
      company_id: selectedCompany?.company_id,
      paid_credits: addCreditDetails?.paid_credits,
      credits_type: addCreditType?.val,
      free_credits: addCreditDetails?.free_credits,
      expiry_date: addCreditDetails?.expiry_date,
    };

    niyuktiAxiosPostReq(`/api/ai_job/company/ai_credits/`, payload)
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message);
          setAddCreditDetails(initiatAddCreditDetails);
          setAddCreditType(null);
          setFreeCreditExpiryDate(null);
          setCreditDetailAdded(true);
        } else {
          FailureAlert(res?.data?.error);
        }
        setAddCreditLoading(false);
      })
      .catch((err) => {
        setAddCreditLoading(false);
        FailureAlert("Error in creating vendor partner");
      });
  };

  const columns = [
    {
      title: "Transaction Id",
      field: "id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      filtering: false,
    },

    {
      title: "Transaction Type",
      field: "transaction_type",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },

    {
      title: "Free Credits",
      field: "free_credits_info",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>
          {row?.free_credits_info?.free_credits
            ? row?.free_credits_info?.free_credits
            : "--"}
        </div>
      ),
    },
    {
      title: "Free Credits Expiry",
      field: "job_portal_code",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>
          {row?.free_credits_info?.expiry_date
            ? formatISODateTime(row?.free_credits_info?.expiry_date)
            : "--"}
        </div>
      ),
    },
    {
      title: "Paid Credits",
      field: "paid_credits",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>{row?.paid_credits ? row?.paid_credits : "--"}</div>
      ),
    },
    {
      title: "Available Credits",
      field: "available_credits",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>{row?.available_credits ? row?.available_credits : "--"}</div>
      ),
    },
    {
      title: "Action Type",
      field: "action_type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Action By",
      field: "created_by",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
    },
    {
      title: "Date & Time",
      field: "created_at",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      filtering: false,
      render: (row) => (
        <div>
          {row?.created_at ? formatISODateTime(row?.created_at, true) : "--"}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const getCompanyList = async () => {
      const endPoint = "/api/admin/company/get/";
      setLoading(true);

      try {
        const res = await praveshAxiosGetReq(endPoint);

        if (res?.data?.success) {
          setCompanyList(res?.data?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };

    getCompanyList();
  }, []);

  const validate = () => {
    let isError = false;
    if (!addCreditType) {
      FailureAlert("Please select Credit type.");
      isError = true;
    }
    if (addCreditDetails?.free_credits && !addCreditDetails?.expiry_date) {
      FailureAlert("Please select Expiry date for Free credits.");
      isError = true;
    }

    return !isError;
  };

  return (
    <>
      <h3 style={{ marginBottom: 0 }}>{"Company Credits"}</h3>
      <hr style={{ marginTop: "3px" }} />
      <Grid container spacing={4}>
        <Grid item md={12} style={{ display: "grid" }}>
          <div style={{ height: "40px", marginBottom: "50px" }}>
            <Autocomplete
              id="newEditCompany"
              name="newEditCompany"
              options={companyList}
              getOptionLabel={(item) => item.company_name}
              value={selectedCompany}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedCompany(newValue);
                }
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Company*"
                  size="small"
                />
              )}
            />
          </div>
        </Grid>
      </Grid>

      {selectedCompany ? (
        <>
          {pageLoading ? (
            <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
          ) : (
            <LoadingOverlay active={loading} spinner text="Please Wait...">
              <div style={{ width: "70%", paddingBottom: "20px" }}>
                <div>
                  <Grid container spacing={4}>
                    <Grid item md={6}>
                      <div>
                        <Typography variant="h6">Add Credits</Typography>
                      </div>
                    </Grid>
                    <Grid item md={6} style={{ display: "grid" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "50%",
                          marginLeft: "auto",
                        }}
                      >
                        <Autocomplete
                          id="current_location"
                          value={addCreditType}
                          options={creditType}
                          getOptionLabel={(item) => `${item?.title}`}
                          onChange={(e, item) => {
                            setAddCreditType(item);
                          }}
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Credit Type"
                              size="small"
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} style={{ display: "grid" }}>
                      <TextField
                        variant="outlined"
                        label="Free Credits"
                        size="small"
                        type="text"
                        value={addCreditDetails?.free_credits}
                        InputLabelProps={{ shrink: true }}
                        disabled={!addCreditType}
                        onChange={(item) => {
                          let value = item.target.value;
                          if (value.length > 1 && value.startsWith("0")) {
                            value = value.slice(1);
                          }
                          value = value === "" ? 0 : value;

                          setAddCreditDetails((prev) => ({
                            ...prev,
                            free_credits: value,
                          }));
                        }}
                      />
                    </Grid>{" "}
                    <Grid item md={6} style={{ display: "grid" }}>
                      <TextField
                        value={addCreditDetails?.paid_credits}
                        variant="outlined"
                        label="Paid Credits"
                        size="small"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        disabled={!addCreditType}
                        onChange={(item) => {
                          let value = item.target.value;
                          if (value.length > 1 && value.startsWith("0")) {
                            value = value.slice(1);
                          }
                          value = value === "" ? 0 : value;

                          setAddCreditDetails((prev) => ({
                            ...prev,
                            paid_credits: value,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ paddingBottom: 0 }}>
                      <Typography>Free Credit Expiry Date :</Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ display: "grid", paddingTop: "2px" }}
                    >
                      <DatePicker
                        showIcon
                        value={freeCreditExpiryDate}
                        onChange={(date) => {
                          setFreeCreditExpiryDate(date);
                          setAddCreditDetails((prev) => {
                            return {
                              ...prev,
                              expiry_date: moment(date).format(
                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                              ),
                            };
                          });
                        }}
                        disabled={
                          !addCreditType || !addCreditDetails?.free_credits
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ display: "grid", paddingTop: "2px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#2563EB",
                            width: "170px",
                            height: "40px",
                            boxShadow: "none",
                          }}
                          onClick={() => {
                            if (validate()) onAddCreditClicked();
                          }}
                        >
                          {addCredtloading ? (
                            <CircularProgress size={18} color="white" />
                          ) : (
                            "Add Credits"
                          )}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item md={6} style={{ marginTop: "30px" }}>
                      <div>
                        <Typography variant="h6">Credits Details</Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ display: "grid", marginTop: "30px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "50%",
                          marginLeft: "auto",
                        }}
                      >
                        <Autocomplete
                          id="current_location"
                          value={detailsCreditType}
                          options={creditType}
                          getOptionLabel={(item) => `${item?.title}`}
                          onChange={(e, item) => {
                            setDetailsCreditType(item);
                          }}
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Credit Type"
                              size="small"
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <TextField
                        variant="outlined"
                        label="Total Available Credits"
                        style={{ width: "48.5%" }}
                        size="small"
                        value={
                          creditData?.free_credits != null
                            ? creditData?.free_credits +
                              creditData?.paid_credits
                            : "--"
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} style={{ display: "grid" }}>
                      <TextField
                        variant="outlined"
                        label="Free Credits"
                        size="small"
                        value={
                          creditData?.free_credits != null
                            ? creditData?.free_credits
                            : "--"
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} style={{ display: "grid" }}>
                      <TextField
                        variant="outlined"
                        label="Paid Credits"
                        size="small"
                        value={
                          creditData?.paid_credits != null
                            ? creditData?.paid_credits
                            : "--"
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} style={{ display: "grid" }}>
                      <TextField
                        variant="outlined"
                        label="Expired Credits"
                        size="small"
                        value={
                          creditData?.expired_credits != null
                            ? creditData?.expired_credits
                            : "--"
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} style={{ display: "grid" }}>
                      <TextField
                        variant="outlined"
                        label="Burnt Credits"
                        size="small"
                        value={
                          creditData?.burnt_credits != null
                            ? creditData?.burnt_credits
                            : "--"
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <TableWithoutExport
                columns={columns}
                title="Transaction History"
                selection={false}
                data={transactionData}
                // loading={loading}
              />
            </LoadingOverlay>
          )}
        </>
      ) : null}
    </>
  );
}

export default AiCallingBot;

const creditType = [
  { title: "AI Calling", val: "ai_calling" },
  { title: "AI Assessment", val: "ai_assessment" },
];
