import React from 'react';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid, Typography, LinearProgress, CircularProgress } from "@material-ui/core";
// import Csv from "../../../assets/Icons/csv.png";
import Csv from "../../../../assets/Icons/csv.png";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";






const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 8,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff",
    },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
    root1: {
        borderRadius: "20px",
    },
    pro: {
        width: "100%",
        zIndex: 1000,
        // marginLeft: "40rem",

    },
    whitebox: {
        marginBottom: "5rem",
    },
    button: {
        borderRadius: "30px",
        margin: theme.spacing(1),
        boxShadow: "none",
        padding: "7px",
        height: "40px",
        backgroundColor: theme.palette.primary.secondary,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.secondary,
        },
        paddingLeft: "30px",
        paddingRight: "30px",
        display: "flex",
    },
    btn: {
        borderRadius: "30px",
        margin: theme.spacing(1),
        boxShadow: "none",
        padding: "7px",
        height: "40px",
        paddingLeft: "30px",
        paddingRight: "30px",
        display: "flex",
    },
    processing: {
        display: 'flex',
        alignContent: "center",
        alignItems: "center"

    }
}));


const UploadBox = ({ open, progress, loadedInfo }) => {


    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                maxWidth="md"
                PaperProps={{ classes: { root: classes.root1 } }}
                open={open}
                // open={true}
                aria-labelledby="responsive-dialog-title"
            >
                <div style={{ padding: "20px", width: "500px", }}>
                    <div
                        style={{
                            padding: "10px",
                            paddingLeft: "10px",
                            // paddingBlockEnd: "40px",
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                        }}
                    >
                        <Typography variant="h6">Uploading selected Files </Typography>
                    </div>

                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={2}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                //  justifyContent: "center",
                            }}
                        >
                            <img src={Csv} height="50px" width="50px" alt="icon" />
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            style={{
                                display: "flex",
                                alignItems: "center",

                                // justifyContent: "center",
                            }}
                        >

                            <div
                                className={classes.pro}
                            >
                                {/* <Typography
                                >
                                    New Files being uploaded
                                </Typography> */}
                                <BorderLinearProgress
                                    variant="determinate"
                                    //  value={progress} 
                                    value={progress}
                                />
                                <div
                                    style={{
                                        // display: "flex",
                                        justifyContent: "space-between",
                                        width: "70%"
                                    }}
                                >
                                    <section className={classes.processing}>
                                        <Typography variant="overline" color="secondary">
                                            {
                                                progress === 100 ? "processing..." : null
                                            }
                                            {
                                                progress === 100 ? <CircularProgress style={{ padding: "10px" }} /> : null
                                            }

                                        </Typography>
                                    </section>
                                    <section>
                                        <Typography variant="overline" color="primary">
                                            {
                                                `${loadedInfo?.loaded?.toFixed(2)}kb of total ${loadedInfo?.total?.toFixed(2)}kb`
                                            }
                                        </Typography>
                                    </section>

                                    <Typography color="primary" variant="overline">
                                        {
                                            progress === 100 ? "Uploaded 100%" : `Uploading... ${progress}%`
                                        }

                                    </Typography>
                                </div>
                            </div>
                        </Grid>

                        {/* <Grid item xs={6} style={{ display: "grid", paddingTop: "0px" }}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                                onClick={handleCancelImport}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </Grid> */}
                        {/* <Grid item xs={6} style={{ display: "grid", paddingTop: "0px" }}>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={handleCancelImport}
                                color="primary"
                            >
                                Close
                            </Button>
                        </Grid> */}
                    </Grid>
                </div>
            </Dialog>

        </>


    )
}

export default UploadBox