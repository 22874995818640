import axios from "axios";
import React, { useState, useEffect } from "react";
import { FailureAlert, SuccessAlert } from "../util/Notifications";

const UseAxiosApi = ({
  url,
  method = "get",
  body = null,
  formData = null,
  reqConfigs = JSON.stringify({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    withCredentials: true,
  }),
  callBackFn = () => {},
  errorCallback = () => {},
  setResponse = () => {},
  source = null,
  loginFlow = false,
  mountingCallFlag = true,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState(null);

  const apiCall = async (
    customBody = null,
    callFunction = false,
    nextUrl = null,
    helperCallBack = () => {}
  ) => {
    if (!mountingCallFlag) {
      if (!callFunction) return;
    }

    setLoading(true);
    try {
      const res = await axios?.[method](
        nextUrl ? nextUrl : url,
        formData
          ? formData
          : customBody
          ? JSON?.parse(customBody)
          : body
          ? JSON?.parse(body)
          : source
          ? { ...JSON?.parse(reqConfigs), cancelToken: source?.token }
          : JSON?.parse(reqConfigs),
        formData
          ? source
            ? { ...JSON?.parse(reqConfigs), cancelToken: source?.token }
            : JSON?.parse(reqConfigs)
          : body || customBody
          ? source
            ? { ...JSON?.parse(reqConfigs), cancelToken: source?.token }
            : JSON?.parse(reqConfigs)
          : null
      );

      if (method === "get") {
        if (res?.data?.success) {
          const data = res?.data?.data;

          if (Object.keys(res?.data?.data).includes("next")) {
            setPagination({
              end: data?.next ? false : true,
              next: data?.next ? true : false,
              totalDataCount: data?.count,
            });
          }
          setError(null);
          setData(data);
          callBackFn(data);
          setResponse(res?.data);
        } else {
          setError(res?.data?.error);
        }
      } else {
        if (res?.data?.success) {
          res?.data?.data?.message && SuccessAlert(res?.data?.data?.message);
          setResponse(res?.data);
          callBackFn(res?.data);
          helperCallBack(res?.data);
        } else {
          if (!loginFlow && res?.data?.data?.code === 1) {
            errorCallback();
          } else {
            errorCallback(res?.data);
            res?.data?.data?.rd_code !== 71 &&
              FailureAlert(res?.data?.error ?? " ");
            setError(res?.data?.error);
          }
        }
      }
    } catch (error) {
      FailureAlert("Something went wrong. Please try again!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (url) {
        apiCall();
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [url]);

  return { data, loading, error, apiCall };
};

export default UseAxiosApi;
