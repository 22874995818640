// import {
//   Button,
//   Grid,
//   Typography,
//   Tab,
//   Tabs,
//   AppBar,
//   Box,
//   useTheme,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   IconButton,
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import React from "react";
// import PropTypes from "prop-types";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import FileViewer from "react-file-viewer";
// import { Close } from "@material-ui/icons";
// import ResumeSlider from "./ResumeSlider";
// import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";
// import ResumeSliderDialog from "../../DialogSection/ResumeSlider/ResumeSliderDialog";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={2}>{children}</Box>}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }

// // const useStyles = makeStyles((theme) => ({
// //   root: {
// //     backgroundColor: "#fff",
// //     borderRadius: 15,
// //   },
// // }));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: "#fff",
//     borderRadius: 15,
//   },
//   paper: {
//     //padding: theme.spacing(2),
//     margin: "auto",
//     backgroundColor: "#fff",
//     boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
//     borderRadius: "15px",
//     //maxWidth: 500,
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
// }));

// export default function Resume({
//   resume,
//   postResume,
//   uploadedResume,
//   setUploadedResume,
//   uploadResume,
//   gifStyling,
//   resumeFileLoader,
//   gif,
//   resumePath,
//   getFileType,
// }) {
//   const classes = useStyles();
//   const theme = useTheme();
//   const [value, setValue] = React.useState(0);
//   const [changeResume, setChangeResume] = React.useState(false);

//   const handleOpenResume = () => {
//     setChangeResume(true);
//   };

//   const handleCloseResume = () => {
//     setChangeResume(false);
//   };
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <>
//       <div className={classes.paper}>
//         <div className={classes.root}>
//           <AppBar
//             position="static"
//             color="default"
//             style={{
//               boxShadow: "none",
//               backgroundColor: "#fff",
//               borderRadius: 15,
//             }}
//           >
//             <Tabs
//               value={value}
//               onChange={handleChange}
//               indicatorColor="primary"
//               textColor="primary"
//               variant="fullWidth"
//               style={{ borderBottom: "1px solid #DCDCDC" }}
//             >
//               <Tab label="Uploaded Resume" {...a11yProps(0)} />
//               <Tab label="Generated Resume" {...a11yProps(1)} />
//             </Tabs>
//           </AppBar>
//           <TabPanel value={value} index={0} dir={theme.direction}>
//             <div style={{ padding: 15 }}>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <Button
//                   startIcon={<AddCircleOutlineIcon />}
//                   color="primary"
//                   variant="contained"
//                   component="label"
//                 >
//                   Add Resume
//                   <input
//                     type="file"
//                     hidden
//                     onChange={(e) => setUploadedResume(e.target.files[0])}
//                   />
//                 </Button>
//                 {uploadedResume ? (
//                   <div style={{ display: "flex", alignItems: "center" }}>
//                     <Typography style={{ fontSize: 12 }} noWrap>
//                       {uploadedResume?.name}
//                     </Typography>{" "}
//                     <IconButton
//                       size="small"
//                       onClick={() => setUploadedResume(false)}
//                     >
//                       <Close />
//                     </IconButton>
//                   </div>
//                 ) : (
//                   ""
//                 )}
//               </div>

//               {/* {resume && (
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                   }}
//                 >
//                   <div>Uploaded Resume:</div>
//                   <Typography variant="body">
//                     <a href={resume} target="blank">
//                       Click here to see Resume!
//                     </a>{" "}
//                   </Typography>
//                 </div>
//               )} */}

//               {!uploadedResume ? (
//                 ""
//               ) : (
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   disabled={uploadedResume ? false : true}
//                   onClick={() => {
//                     postResume(uploadedResume);
//                   }}
//                   style={{ marginTop: 10 }}
//                 >
//                   Upload
//                 </Button>
//               )}

//               {!resume ? (
//                 ""
//               ) : (
//                 <div
//                   style={{
//                     backgroundColor: "#fff",
//                     borderRadius: 5,
//                     marginTop: 15,
//                   }}
//                 >
//                   <Accordion
//                     defaultExpanded
//                     style={{
//                       backgroundColor: "#fff",
//                       boxShadow: "none",
//                       borderRadius: 5,
//                     }}
//                   >
//                     <AccordionSummary
//                       style={{
//                         backgroundColor: "#fff",
//                         borderRadius: 5,
//                         border: "1px solid #DBDBDB",
//                       }}
//                       expandIcon={
//                         <ExpandMoreIcon style={{ color: "#2563EB" }} />
//                       }
//                       aria-controls="panel1a-content"
//                       id="panel1a-header"
//                     >
//                       <div
//                         style={{
//                           width: "100%",
//                           display: "flex",
//                           justifyContent: "space-between",
//                           alignItems: "center",
//                         }}
//                       >
//                         <Typography className={classes.heading}>
//                           Resume
//                         </Typography>

//                         <Typography style={{ fontSize: 14, color: "#2563EB" }}>
//                           See Preview
//                         </Typography>
//                       </div>
//                     </AccordionSummary>
//                     <AccordionDetails
//                       style={{ backgroundColor: "#fff", padding: 0 }}
//                     >
//                       {!resume ? (
//                         <>
//                           <Typography>
//                             No Resume found.
//                             <br />
//                             Please upload the resume for the respective student.
//                           </Typography>
//                           <img
//                             src={uploadResume}
//                             style={{ width: "40%", marginLeft: "30%" }}
//                           />
//                         </>
//                       ) : resumeFileLoader ? (
//                         <img src={gif} alt="" style={gifStyling} />
//                       ) : (
//                         resume &&
//                         (resume?.includes("pdf") ? (
//                           <embed
//                             style={{
//                               width: "100%",
//                               height: 500,
//                             }}
//                             src={resume ? resumePath : null}
//                           />
//                         ) : (
//                           <section
//                             style={{ borderStyle: "ridge", width: "100%" }}
//                           >
//                             {resumePath && (
//                               <FileViewer
//                                 style={{
//                                   minWidth: "300px",
//                                   minHeight: "300px",
//                                 }}
//                                 fileType={getFileType()}
//                                 filePath={resumePath}
//                               />
//                             )}
//                           </section>
//                         ))
//                       )}
//                     </AccordionDetails>
//                   </Accordion>
//                 </div>
//               )}
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={1} dir={theme.direction}>
//             <div style={{ padding: 15 }}>
//               <Typography variant="body2" style={{ color: "#6C757D" }}>
//                 By Default Generate Resume
//               </Typography>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   marginTop: 10,
//                 }}
//               >
//                 <Button
//                   startIcon={<AddCircleOutlineIcon />}
//                   color="primary"
//                   variant="contained"
//                   component="label"
//                 >
//                   Generate Resume
//                 </Button>
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <Typography style={{ fontSize: 12 }} noWrap>
//                     Generated Resume Template 1.pdf
//                   </Typography>

//                   <IconButton size="small">
//                     <Close />
//                   </IconButton>
//                 </div>
//               </div>
//               <div>
//                 <Button
//                   startIcon={<PostAddRoundedIcon />}
//                   variant="text"
//                   style={{ color: "#2563EB", marginTop: 10, marginBottom: 10 }}
//                   onClick={handleOpenResume}
//                 >
//                   Change Template
//                 </Button>
//               </div>

//               <div>
//                 <ResumeSliderDialog
//                   openResume={changeResume}
//                   handleCloseResume={handleCloseResume}
//                 />
//               </div>

//               <div style={{ marginTop: 15 }}>
//                 <Accordion
//                   style={{
//                     backgroundColor: "#fff",
//                     boxShadow: "none",
//                     border: "1px solid #DBDBDB",
//                     borderRadius: 5,
//                   }}
//                 >
//                   <AccordionSummary
//                     style={{
//                       backgroundColor: "#fff",
//                       borderRadius: 5,
//                     }}
//                     expandIcon={<ExpandMoreIcon style={{ color: "#2563EB" }} />}
//                     aria-controls="panel1a-content"
//                     id="panel1a-header"
//                   >
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                       }}
//                     >
//                       <Typography className={classes.heading}>
//                         Generate Resume
//                       </Typography>

//                       <Typography style={{ fontSize: 14, color: "#2563EB" }}>
//                         See Preview
//                       </Typography>
//                     </div>
//                   </AccordionSummary>
//                   <AccordionDetails style={{ backgroundColor: "#fff" }}>
//                     {!resume ? (
//                       <>
//                         <Typography>
//                           No Resume found.
//                           <br />
//                           Please upload the resume for the respective student.
//                         </Typography>
//                         <img
//                           src={uploadResume}
//                           style={{ width: "40%", marginLeft: "30%" }}
//                         />
//                       </>
//                     ) : resumeFileLoader ? (
//                       <img src={gif} alt="" style={gifStyling} />
//                     ) : (
//                       resume &&
//                       (resume?.includes("pdf") ? (
//                         <embed
//                           style={{
//                             width: "100%",
//                             height: 500,
//                           }}
//                           src={resume ? resumePath : null}
//                         />
//                       ) : (
//                         <section
//                           style={{ borderStyle: "ridge", width: "100%" }}
//                         >
//                           {resumePath && (
//                             <FileViewer
//                               style={{
//                                 minWidth: "300px",
//                                 minHeight: "300px",
//                               }}
//                               fileType={getFileType()}
//                               filePath={resumePath}
//                             />
//                           )}
//                         </section>
//                       ))
//                     )}
//                   </AccordionDetails>
//                 </Accordion>
//               </div>
//             </div>
//           </TabPanel>
//         </div>
//         {/* <Grid container spacing={2}>
//           <Grid item xs={12}></Grid>

//           <Grid item xs={12}></Grid>
//           <Grid item xs={12}>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "right",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 color="primary"
//                 disabled={uploadedResume ? false : true}
//                 onClick={() => {
//                   postResume(uploadedResume);
//                 }}
//               >
//                 Upload
//               </Button>
//             </div>
//           </Grid>
//         </Grid> */}
//       </div>
//     </>
//   );
// }

import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
    //maxWidth: 500,
  },
}));

export default function Resume({
  resume,
  postResume,
  uploadedResume,
  setUploadedResume,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Resume</Typography>
          </Grid>
          {resume && (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Uploaded Resume:</div>
                <Typography variant="body">
                  <a href={resume} target="blank">
                    Click here to see Resume!
                  </a>{" "}
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                startIcon={<AddCircleOutlineIcon color="primary" />}
                color="primary"
                variant="contained"
                component="label"
              >
                Add Resume
                <input
                  type="file"
                  hidden
                  onChange={(e) => setUploadedResume(e.target.files[0])}
                />
              </Button>
              {uploadedResume ? uploadedResume?.name : ""}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={uploadedResume ? false : true}
                onClick={() => {
                  postResume(uploadedResume);
                }}
              >
                Upload
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
