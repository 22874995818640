import { CopyTextAlert } from "./Notifications";

export const copyToClipboard = (msg) => {
    if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(msg)
    CopyTextAlert('Copied.')
    }
    else{
    let input = document.createElement('textarea');
    input.innerHTML = msg;
    document.body.appendChild(input);
    input.select();
    let result = document.execCommand('copy');
    document.body.removeChild(input);
    CopyTextAlert("Copied!");
    return result;
    }
}