import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/AllStudents.css';
import { Box, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {Form, Col, Button} from 'react-bootstrap';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import { requestPraveshOptions } from '../../constants';
import default_logo from '../../assets/default_logo.png';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';


function ParticularStudentDetails( {details, colleges, degrees, specializations, boards} ) {

    const [studentDetails, setStudentDetails] = useState(details);
    const [profilePic, setProfilePic] = useState(null);
    const [uploadPic, setUploadPic] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [educationArray, setEducationArray] = useState([]);
    const [isBlocked, setIsBlocked] = useState(false);
    const [blockReason, setBlockReason] = useState('');
    const [resume, setResume] = useState(null);
    const [currentCtc, setCurrentCtc] = useState(null);
    const [expectedCtc, setExpectedCtc] = useState(null);
    const [noticePeriod, setNoticePeriod] = useState(null);
    const [remarks, setRemarks] = useState(null);

    const [circularLoader, setCircularLoader] = useState(false);

    const findUser = () => {
        apiCall(`/api/shared/public_profile/?email_id=${email}`, requestPraveshOptions)
        .then((res) => {
            if(res.success) setStudentDetails(res.data);
            else FailureAlert(res.error)
        })
    }

    useEffect(() => {
        setProfilePic(studentDetails.profile_picture);
        setFirstName(studentDetails.first_name);
        setLastName(studentDetails.last_name);
        setEmail(studentDetails.email);
        setMobile(studentDetails.phone);
        setEducationArray(studentDetails.student_user_details.education);
        setIsBlocked(studentDetails.student_user_details.is_blocked);
        setBlockReason('');
        setResume(null);
        setUploadPic(null);
        setCurrentCtc(studentDetails.student_user_details.current_ctc);
        setExpectedCtc(studentDetails.student_user_details.expected_ctc);
        setNoticePeriod(studentDetails.student_user_details.notice_period);
        setRemarks(studentDetails.student_user_details.remark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetails]);

    const changePercentage = (val, id) => {
        let temp = [...educationArray];
        temp.forEach(e=> {
            if(e.education_id===id) {
                e.percentage = parseInt(val);
            }
        })
        setEducationArray(temp)
    }

    const changeCgpa = (val, id) => {
        let temp = [...educationArray];
        temp.forEach(e => {
            if (e.education_id === id) {
                e.cgpa = parseInt(val);
            }
        })
        setEducationArray(temp)
    }

    const changeCollege = (clg, id) => {
        let temp = [...educationArray];
        temp.forEach(e=> {
            if(e.education_id===id) { e.college = clg; }
        })
        setEducationArray(temp)
    }

    const changeDegree = (deg, id) => {
        let temp = [...educationArray];
        temp.forEach(e=> {
            if(e.education_id===id) { e.degree_id = deg; }
        })
        setEducationArray(temp)
    }

    const changeSpecialization = (spec, id) => {
        let temp = [...educationArray];
        temp.forEach(e=> {
            if(e.education_id===id) { e.specialization_id = spec; }
        })
        setEducationArray(temp)
    }

    const changeBoard = (board, id) => {
        let temp = [...educationArray];
        temp.forEach(e=> {
            if(e.education_id===id) { e.board_id = board; }
        })
        setEducationArray(temp)
    }

    const saveStudentChanges = (e) => {
        e.preventDefault();
        if(uploadPic) {
            const picExt = uploadPic.name.substring(uploadPic.name.lastIndexOf('.') + 1);
            if(picExt!=='jpeg' && picExt!=='png' && picExt!=='jpg') { 
                FailureAlert('Upload Picture in jpeg, jpg and png format...');
                return;
            }
        }
        if(resume) {
            const resExt = resume.name.substring(resume.name.lastIndexOf('.') + 1);
            if(!['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(resExt.toLowerCase())) { 
                FailureAlert('Upload Resume in pdf, doc, docx, jpg, jpeg, png format only...');
                return;
            }
        }
        if(isBlocked && !blockReason) {
            FailureAlert('Mention reason to block this candidate profile..');
            return;
        }

        if(uploadPic || resume) {
            const formData = new FormData();
            uploadPic && formData.append('profile_picture', uploadPic);
            resume && formData.append('resume', resume);
            const requestPatchFormData = {
                method: 'PATCH',
                headers: { 'Authorization': `Token ${localStorage.getItem('token')}` },
                body: formData
            };
            apiCall(`/api/admin/student_detail/edit/${studentDetails.id}/`, requestPatchFormData)
            .then((res) => {
                if(res.success) {
                    SuccessAlert('File Uploaded !!!');
                    setCircularLoader(true);
                    setTimeout(function() { 
                        setCircularLoader(false)
                        findUser();
                    }, 2000);
                }
                else FailureAlert(res.error)
            })
        }

        let studentUsers = {
            ...(studentDetails.current_ctc !== currentCtc && { cur_ctc: currentCtc }),
            ...(studentDetails.expected_ctc !== expectedCtc && { expected_ctc: expectedCtc }),
            ...(studentDetails.notice_period !== noticePeriod && { notice_period: noticePeriod }),
            ...(studentDetails.remark !== remarks && { remark: remarks }),
        }
        studentUsers['is_blocked'] = isBlocked;
        const arr = educationArray.map(e => ({
            'id': e.education_id,
            'education': e.education,
            'percentage': e.percentage,
            'cgpa': e.cgpa,
            'current_year': e.current_year,
            'college': e.college,
            'degree': e.degree_id,
            'specialization': e.specialization_id,
            'board': e.board_id,
        }));
        
        const requestOptionsStudentDetailsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ 
                ...(studentDetails.first_name!==firstName && {first_name: firstName}),
                ...(studentDetails.last_name!==lastName && {last_name: lastName}),
                ...(studentDetails.phone!==mobile && {phone: mobile}),
                ...(studentDetails.email !== email && { email: email }),
                studentuser_set: [studentUsers],
                education_set: arr,
            })
        };
        
        apiCall(`/api/admin/student_detail/edit/${studentDetails.id}/`, requestOptionsStudentDetailsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCircularLoader(true);
                setTimeout(function() { 
                    setCircularLoader(false)
                    findUser();
                }, 1500);
            }
            else FailureAlert(res.error)
        })
    }


    return (
        !circularLoader
        ?
        <>
            {/* profile pic */}
            <div style={{display:'flex', flexDirection:'row', marginTop:'1rem'}}>
                <img 
                    src={profilePic || default_logo}
                    alt='profile pic'
                    height='80px'
                    width='80px'
                    style={{borderRadius:'50%', alignItems:'center', display:'block', marginLeft:'42%', marginTop:'5px'}}
                />
                <EditIcon 
                    style={{cursor:'pointer', marginLeft:'1rem'}}
                    onClick={e=>window.document.getElementById('profile-pic-upload').click()}
                />
                {
                    uploadPic && 
                    <span style={{marginTop:'55px', fontSize:'14px', color:'green'}}>
                    {uploadPic.name}</span>
                }
                <input 
                    id='profile-pic-upload' type='file' 
                    style={{visibility:'hidden'}} 
                    onChange={e=>setUploadPic(e.target.files[0])}
                />
            </div>
            {/* personal details */}
            <div style={{marginLeft:'2%', width:'96%'}}>
                <Form className='personal__student__details'>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                                name="firstName" type="text" placeholder="Enter User's First Name"
                                value={firstName || ''} onChange={e=> {setFirstName(e.target.value)}} 
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                                name="lastName" type="text" placeholder="Enter User's Last Name"
                                value={lastName || ''} onChange={e=> {setLastName(e.target.value)}} 
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                name="email" type="email" placeholder="Enter User's Email"
                                value={email || ''} onChange={e=> {setEmail(e.target.value)}} 
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Mobile No.</Form.Label>
                            <Form.Control 
                                name="mobileNo" type="text" placeholder="User's Mobile Number"
                                pattern="[0-9]*" maxLength="10" minLength="10"
                                value={mobile || ''} onChange={e=> setMobile(e.target.value)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Creation Date</Form.Label>
                        <Form.Control 
                            name="createDate" type="text" readOnly
                            value={
                                studentDetails.student_user_details.creation_date 
                                ?
                                new Date(studentDetails.student_user_details.creation_date).toString() 
                                :
                                ''
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last Login</Form.Label>
                        <Form.Control 
                            name="createDate" type="text" readOnly
                            value={
                                studentDetails.last_login ? new Date(studentDetails.last_login).toString() : ''
                            }
                        />
                    </Form.Group>
                    {
                        educationArray.map((item, key) => (
                          <Box boxShadow={2} key={key} style={{padding:'2px 1rem', marginBottom:'1.5rem'}}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Education</Form.Label>
                                    <Form.Control 
                                        name="education" type="text" disabled
                                        placeholder={item.highest_qualification || ''}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Percentage</Form.Label>
                                    <Form.Control 
                                        name="percentage" type="text"
                                        placeholder="Percentage goes here..."
                                        value={item.percentage || 0}
                                        onChange={e => changePercentage(e.target.value, item.education_id)}
                                    />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>CGPA</Form.Label>
                                        <Form.Control
                                            name="cgpa" type="text"
                                            placeholder="CGPA goes here..."
                                            value={item.cgpa || 0}
                                            onChange={e => changeCgpa(e.target.value, item.education_id)}
                                        />
                                    </Form.Group>
                            </Form.Row>
                            {
                                item.education === 1 || item.education === 2
                                ?
                                <Form.Group>
                                    <Form.Label>Education Board: <b style={{fontSize:'16px'}}>Current:- {item.board_name || 'None'}</b></Form.Label>
                                    <Autocomplete
                                        id="select-board"
                                        options={boards}
                                        getOptionLabel={(item) => item.name}
                                        onChange={(e, i)=>i ? 
                                                changeBoard(i.id, item.education_id) : 
                                                changeBoard(null, item.education_id)}
                                        renderInput={(params) => <TextField {...params} label="Select Board" variant="outlined" />}
                                    />
                                </Form.Group>
                                :
                                <div>
                                    <Form.Group>
                                        <Form.Label>College Name: <b style={{fontSize:'16px'}}>Current:- {item.college_name || 'None'}</b></Form.Label>
                                        <Autocomplete
                                            id="select-college"
                                            options={colleges}
                                            getOptionLabel={(item) => `${item.college_name} - (${item.college_id}) `}
                                            onChange={(e, i)=>i ? 
                                                    changeCollege(i.college_id, item.education_id) : 
                                                    changeCollege(null, item.education_id)}
                                            renderInput={(params) => <TextField {...params} label="Select College" variant="outlined" />}
                                        />
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Degree Name: <b style={{fontSize:'16px'}}>Current:- {item.degree || 'None'}</b></Form.Label>
                                            <Autocomplete
                                                id="select-degree"
                                                options={degrees}
                                                getOptionLabel={(item) => `${item.degree_name} - (${item.degree_id}) `}
                                                onChange={(e, i)=>i ? 
                                                        changeDegree(i.degree_id, item.education_id) : 
                                                        changeDegree(null, item.education_id)}
                                                renderInput={(params) => <TextField {...params} label="Select Degree" variant="outlined" />}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Specialization Name: <b style={{fontSize:'16px'}}>Current:- {item.specialization || 'None'}</b></Form.Label>
                                            <Autocomplete
                                                id="select-specialization"
                                                options={specializations}
                                                getOptionLabel={(item) => `${item.specialization_name} - (${item.specialization_id}) `}
                                                onChange={(e, i)=>i ? 
                                                        changeSpecialization(i.specialization_id, item.education_id) : 
                                                        changeSpecialization(null, item.education_id)}
                                                renderInput={(params) => <TextField {...params} label="Select Specialization" variant="outlined" />}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                            }
                          </Box>
                        ))
                    }
                    <Form.Row>
                        <Form.Group as={Col}>
                                <Form.Label>Current CTC (in Lacs)</Form.Label>
                            <Form.Control 
                                name="currentCtc" type="number" placeholder="Enter CTC in Lacs"
                                value={currentCtc} onChange={e=> {setCurrentCtc(e.target.value)}} 
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Expected CTC (in Lacs)</Form.Label>
                            <Form.Control 
                                name="expectedCtc" type="number" placeholder="Enter Expected Salary in Lacs"
                                value={expectedCtc} onChange={e=> setExpectedCtc(e.target.value)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Notice Period (in days)</Form.Label>
                            <Form.Control 
                                name="noticePeriod" type="number" placeholder="Enter Notice period in days"
                                value={noticePeriod} onChange={e => setNoticePeriod(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Other Remarks</Form.Label>
                            <Form.Control 
                                name="remarks" type="text" placeholder="Enter other remarks"
                                value={remarks} onChange={e => setRemarks(e.target.value)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <Form.Group as={Col}>
                            <Form.Label>Block Status</Form.Label>
                            <Form.Control 
                                as="select" 
                                onChange={e=> e.target.value==='true' ? setIsBlocked(true) : setIsBlocked(false) }
                            >
                                <option value={studentDetails.student_user_details.is_blocked}>
                                    --{studentDetails.student_user_details.is_blocked?'Yes':'No'}--
                                </option>
                                <option value={true}>Block</option>
                                <option value={false}>Unblock</option>
                            </Form.Control>
                        </Form.Group>
                        {
                            isBlocked
                            ?
                            <Form.Group as={Col}>
                                <Form.Label>Reason to block: </Form.Label>
                                <Form.Control 
                                    name='blockReason' type='text'
                                    placeholder='Enter Reason...'
                                    required onChange={e=> setBlockReason(e.target.value)}
                                />
                            </Form.Group>
                            :
                            <Form.Group as={Col}>
                                <Form.Label>Reason to block: </Form.Label>
                                <Form.Control 
                                    name='blockReason' type='text'
                                    disabled
                                />
                            </Form.Group>
                        }
                    </Form.Row>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        {
                            studentDetails.student_user_details.resume
                            ?
                            <Button variant="outline-success"
                                onClick={()=>window.open(studentDetails.student_user_details.resume)}
                            > View Resume </Button>
                            :
                            <Button variant="outline-secondary" disabled> View Resume </Button>
                        }
                        <Form.File 
                            id="formcheck-api-custom" 
                            custom style={{marginLeft:'2%', width:'75%'}}
                        >
                            <Form.File.Input onChange={e=>setResume(e.target.files[0])} isValid />
                            <Form.File.Label data-browse="Upload Latest Resume">
                                Choose from local directory
                            </Form.File.Label>
                            {
                                resume ? 
                                <Form.Control.Feedback type="valid">{resume.name}</Form.Control.Feedback> 
                                : null
                            }
                        </Form.File>
                    </div>
                    <button
                        className="save__student__changes"
                        onClick={(e) => {saveStudentChanges(e)} }
                    >
                    Save Changes
                    </button>
                </Form>
                <br />
            </div>   
        </>
        :
        <CircularProgress
            style={{display:'block', margin:'auto', marginTop:'225px'}}
        />
    )
}

export default ParticularStudentDetails;