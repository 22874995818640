import { Button, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { FailureAlert } from "../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
    //maxWidth: 500,
  },
}));

export default function ImportantLink({socialLinks, postSocialLinks}) {
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [githubLink, setGithubLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if(socialLinks)
      socialLinks?.forEach(item => {
          if(item?.id === 1)
            setLinkedinLink(item?.value);
          if(item?.id === 2)
            setGithubLink(item?.value);
          if(item?.id === 3)
            setFacebookLink(item?.value);
          if(item?.id === 4)
            setInstagramLink(item?.value);
      })
  }, [socialLinks])

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Important Links</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              id="outlined-basic"
              label="Linkedin Link"
              variant="outlined"
              size="small"
              margin="normal"
              value={linkedinLink}
              onChange={(e) => {setLinkedinLink(e.target.value)}}
            />
            <TextField
              id="outlined-basic"
              label="Github Link"
              margin="normal"
              variant="outlined"
              size="small"
              value={githubLink}
              onChange={(e) => {setGithubLink(e.target.value)}}
            />
            <TextField
              id="outlined-basic"
              label="Facebook Link"
              variant="outlined"
              margin="normal"
              size="small"
              value={facebookLink}
              onChange={(e) => {setFacebookLink(e.target.value)}}
            />
            <TextField
              id="outlined-basic"
              label="Instagram Link"
              variant="outlined"
              margin="normal"
              size="small"
              value={instagramLink}
              onChange={(e) => {setInstagramLink(e.target.value)}}
            />

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="contained" color="primary" onClick={() => {
                var newSocialLinks = [];
                if(!(linkedinLink || githubLink || facebookLink || instagramLink)){
                  FailureAlert("Please add atleast one social links!");
                  return;
                }
                if(linkedinLink){
                  newSocialLinks.push({
                    id: 1,
                    name: "Linkedin",
                    value: linkedinLink
                  })
                }
                if(githubLink){
                  newSocialLinks.push({
                    id: 2,
                    name: "Github",
                    value: githubLink
                  })
                }
                if(facebookLink){
                  newSocialLinks.push({
                    id: 4,
                    name: "Facebook",
                    value: facebookLink
                  })
                }
                if(instagramLink){
                  newSocialLinks.push({
                    id: 3,
                    name: "Instagram",
                    value: instagramLink
                  })
                }
                
                postSocialLinks(newSocialLinks);
                }
              }>
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
