import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import InterviewForm from "./InterviewForm";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function InterviewDialog({
  open,
  studentData,
  title,
  jobId,
  round,
  handleClickOpen,
  handleClose,
  currRoundId,
  statusName,
  currStatusId,
  setStudentData,
  setstepData,
  step,
  selectedIDs,
}) {
  // const [open, setOpen] = React.useState(false);
  const fullWidth = true;
  const maxWidth = "md";

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Schedule Interview
      </Button> */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div style={{ padding: 20 }}>
          <InterviewForm
            handleClose={handleClose}
            studentData={studentData}
            setStudentData={setStudentData}
            title={title}
            jobId={jobId}
            round={round}
            currRoundId={currRoundId}
            statusName={statusName}
            currStatusId={currStatusId}
            setstepData={setstepData}
            step={step}
            selectedIDs={selectedIDs}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
}
