import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    width: "600px",
  },
  margin: {
    width: "100%",
  },
}));

export default function AddNewCompany(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        classes={{
          paper: classes.newDialog,
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
          Add New Company
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                variant="outlined"
                margin="dense"
                id="name"
                label="Company Name"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">Company Location</Typography>
            </Grid>
            <Grid item md={4}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={bookslotdata}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Country"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={bookslotdata}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={bookslotdata}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                variant="outlined"
                margin="dense"
                id="name"
                label="Company Website"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">POC Details</Typography>
            </Grid>
            <Grid item md={12}>
              <TextField
                variant="outlined"
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
              />
            </Grid>{" "}
            <Grid item md={12}>
              <TextField
                variant="outlined"
                margin="dense"
                id="name"
                label="Email"
                type="email"
                fullWidth
              />
            </Grid>{" "}
            <Grid item md={12}>
              <TextField
                variant="outlined"
                margin="dense"
                id="name"
                label="Phone Number"
                type="text"
                fullWidth
              />
            </Grid>{" "}
            <Grid item md={12}>
              <TextField
                variant="outlined"
                margin="dense"
                id="name"
                label="Designation"
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "20px" }}>
          <Button
            onClick={props.handleClose}
            variant="contained"
            style={{ backgroundColor: "#17A2B8", color: "#ffff" }}
          >
            Add Company
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const bookslotdata = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
];
