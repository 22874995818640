import React from 'react'
import { Button, Typography } from '@material-ui/core';

// import PaymentSuccess from "../assets/images/PaymentSuccess.png";
import PaymentSuccess from "../../../assets/images/PaymentSuccess.png";


const ThankYouForPayment = ({ handleReset }) => {
    return (
        <div>
            <section style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "center",
            }}>
                <img src={PaymentSuccess}
                    style={{
                        // width: "40%",
                        height: '50%'
                        // marginTop: "1rem"
                    }}
                    alt="" />
                {/* <Typography
                    style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: "24px",
                        marginTop: "-25px"
                    }}>
                   
                </Typography> */}

                <Typography
                    style={{
                        color: "#007bff",
                        fontWeight: "500",
                        marginBottom: "40px",
                        textAlign: "center",
                        fontStyle: "italic"
                    }}>
                    Thank You for Purchasing this Order! Your Order is booked successfully!
                </Typography>
                <Button
                    variant='contained'
                    onClick={() => {
                        handleReset()
                    }
                    }
                >
                    Puchase More
                </Button>


            </section>

        </div>
    )
}

export default ThankYouForPayment