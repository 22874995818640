import { Grid, Typography, TextField, Button } from "@material-ui/core";
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddNewCompany from "./Dialog/AddNewCompany";

function BookSlot() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5" style={{ marginBottom: "20px" }}>
          Book Slot(s)
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={bookslotdata}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} label="Combo box" variant="outlined" />
              )}
            />

            <Button
              variant="text"
              color="priamry"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleClickOpen}
            >
              Add New Company
            </Button>
          </Grid>
        </Grid>
      </div>
      <AddNewCompany
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </>
  );
}

export default BookSlot;

const bookslotdata = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
];
