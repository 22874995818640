import { useState, useEffect } from "react";
const allData = [
  {
    jobTitle: "Job Tittle",
    companyName: "GetWork",
    candidateName: "Sanket pareek",
    number: "0123456789",
    stage: "Interview process",
    ageing: 74,
    hrName: "fgrehgerh",
    resume: "",
  },
  {
    jobTitle: "Job Tittle",
    companyName: "GetWork",
    candidateName: "Sanket pareek",
    number: "0123456789",
    stage: "Interview process",
    ageing: 74,
    hrName: "fgrehgerh",
    resume: "",
  },
];

const teamColData = [
  {
    updated_by: "sanket",
    jobTitle: "Job Tittle",
    companyName: "GetWork",
    candidateName: "Sanket pareek",
    number: "0123456789",
    stage: "Interview process",
    ageing: 74,
    hrName: "fgrehgerh",
    resume: "",
  },
  {
    updated_by: "sanket",
    jobTitle: "Job Tittle",
    companyName: "GetWork",
    candidateName: "Sanket pareek",
    number: "0123456789",
    stage: "Interview process",
    ageing: 74,
    hrName: "fgrehgerh",
    resume: "",
  },
];

const teamLeadColData = [
  {
    leadName: "Sanket pareek",
    updated_by: "sanket",
    jobTitle: "Job Tittle",
    companyName: "GetWork",
    candidateName: "Sanket pareek",
    number: "0123456789",
    stage: "Interview process",
    ageing: 74,
    hrName: "fgrehgerh",
    resume: "",
  },
  {
    leadName: "Sanket pareek",
    updated_by: "sanket",
    jobTitle: "Job Tittle",
    companyName: "GetWork",
    candidateName: "Sanket pareek",
    number: "0123456789",
    stage: "Interview process",
    ageing: 74,
    hrName: "fgrehgerh",
    resume: "",
  },
];

export const RowData = () => {
  const [rowsData, setrowsData] = useState(allData);

  return rowsData;
};

export const TeamAllData = () => {
  const [teamData, seteamData] = useState(teamColData);

  return teamData;
};

export const TeamLeadAllData = () => {
  const [teamLeadData, seteamLeadData] = useState(teamLeadColData);

  return teamLeadData;
};
