import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  gifStyling,
  headerCSS,
  requestPraveshOptions,
} from "../../constants";
import { apiNiyuktiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { printNumber, validateDateTime } from "../../util/printNumber";
import { TabPanel } from "../../util/TabPanel";
import { viewJobDetail } from "../../util/ViewJobFunction";
import { DateSelector } from "../common/DateSelector";
import ThirdPartyCompany from "../DialogSection/ThirdPartyCompany";
import TableWithExport from "../TableWithExport";
import { Pagination } from "@material-ui/lab";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

function ThirdPartyJobsPosting() {
  const [startDate, setStartDate] = useState("");
  let history = useHistory();
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [open, setOpen] = useState(false);
  const [modalRejectJobData, setModalRejectJobData] = useState({});
  const [closeReason, setCloseReason] = useState("");
  const [count, setCount] = useState(0);

  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState({});

  const [openThirdPartyCompany, setOpenThirdPartyCompany] = useState(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  const [downloadOverlay, setDownloadOverlay] = useState(false);

  const [metaData, setMetaData] = useState({});

  const [selectedPageNo, setSelectedPageNo] = useState(1);

  const dateRange = [new Date(2021, 0, 1), new Date()];

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props?.columnDef?.tableData?.id, [
      startDate,
      endDate,
    ]);
  };

  const handleClose = () => {
    setOpen(false);
    // setUploadAssignment(false);
    setModalRejectJobData({});
  };

  const updateList = () => {
    setCount(count + 1);
  };
  const handleOpen = () => setOpen(true);

  const loadRejectJobModal = (e, obj) => {
    setModalRejectJobData(obj);
    handleOpen();
  };

  const rejectParticularJob = (jobId) => {
    if (!closeReason) {
      FailureAlert("Specify Reason to Reject Job!!");
      return;
    }

    const requestOptionsPost = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        status: "REJECTED",
        reject_reason: closeReason,
      }),
    };

    apiNiyuktiCall(
      `/job/third_party/job_details/${jobId}/`,
      requestOptionsPost
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCloseReason("");
      } else {
        FailureAlert(res.error);
      }
      updateList();
      handleClose();
    });
  };

  const formatSalary = (min_sal, max_sal) => {
    return min_sal && max_sal
      ? `${printNumber(min_sal)}-${printNumber(max_sal)}`
      : min_sal
      ? `${printNumber(min_sal)}+`
      : max_sal
      ? `${printNumber(max_sal)}`
      : null;
  };

  useEffect(() => {
    const jobStatus =
      value === 0 ? "PENDING" : value === 1 ? "POSTED" : "REJECTED";

    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 7)
    );
    let initialEndDate = new Date(new Date().setDate(new Date().getDate() + 1));

    if (startDate === "") {
      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = new Date(queryParams.from);
      }
      if (queryParams.to) {
        initialEndDate = new Date(queryParams.to);
      }
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      return;
    } else if (startDate === null) {
      initialStartDate = null;
    } else {
      initialStartDate = new Date(startDate);
      initialEndDate = new Date(endDate);
    }

    let dateString = "";
    if (initialStartDate) {
      let month = initialStartDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = initialStartDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${initialEndDate
          .toISOString()
          .substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      dateString = `&start_date=${startDateString}&end_date=${initialEndDate
        .toISOString()
        .substring(0, 10)}`;
    } else {
      history.replace({
        search: "",
      });
    }

    setLoading(true);

    apiNiyuktiCall(
      `/job/third_party/job_lists/?status=${jobStatus}${dateString}&page_size=20&page=${selectedPageNo}`,
      requestPraveshOptions
    ).then((json) => {
      if (json?.success) {
        let temp = [];
        const count = json?.data?.count || 0;
        setMetaData({
          totalCount: count,
          totalPages: Math.ceil(count / 20),
        });
        for (var i = 0; i < json?.data?.results?.length; i++) {
          const singleJobData = json?.data?.results[i];

          const getWorkLocationMode = () => {
            if (
              json?.data[i]?.work_from_home &&
              json?.data[i]?.job_location?.length > 0
            ) {
              return "Hybrid";
            } else if (json?.data[i]?.work_from_home) {
              return "Work From Home";
            } else {
              return "Work From Offc";
            }
          };

          let obj = {
            jobId: singleJobData?.id,
            postedJobId: jobStatus === "POSTED" ? singleJobData.job_id : null,
            jobTitle: singleJobData?.job_title,
            jobTypeName: singleJobData?.job_type_name,
            salaryType: singleJobData?.salary_type,
            ctcMin: singleJobData?.ctc_min,
            ctcMax: singleJobData?.ctc_max,
            ctc: formatSalary(singleJobData?.ctc_min, singleJobData?.ctc_max),
            stipendMin: singleJobData?.stipend_min,
            stipendMax: singleJobData?.stipend_max,
            internship_salary_type: singleJobData?.internship_salary_type,
            stipend: formatSalary(
              singleJobData?.stipend_min,
              singleJobData?.stipend_max
            ),
            vacancy: singleJobData?.vacancy || "",
            createTime: validateDateTime(singleJobData?.create_time),
            updateTime: validateDateTime(singleJobData?.update_time),
            companyId: singleJobData?.company_id,
            companyName: singleJobData?.company_name,
            companyWebsite: singleJobData?.company_website,
            contactEmail: singleJobData?.contact_email,
            contactPhone: singleJobData?.contact_phone,
            contactName: singleJobData?.contact_name,
            location: singleJobData?.job_location
              .map((loc) => {
                return loc;
              })
              .join(", "),
            workExperience: singleJobData?.work_experience,
            workFromHome: singleJobData?.work_from_home ? "Yes" : "No",
            utmCampaign: singleJobData?.utm_campaign,
            workLocationMode: getWorkLocationMode(),
            utmSource: singleJobData?.utm_source,
            utmMedium: singleJobData?.utm_medium,
            utmCategory: singleJobData?.utm_category,
            utmContent: singleJobData?.utm_content,
            vendorLink: singleJobData?.jd_link,
            applyLink: singleJobData?.apply_link,
            rejectReason:
              jobStatus === "REJECTED" ? singleJobData?.reject_reason : "",
            postedBy:
              jobStatus === "POSTED" ? singleJobData?.posted_by_name : "",
            postedDateTime:
              jobStatus === "POSTED"
                ? validateDateTime(singleJobData?.posted_datetime)
                : "",
            rejectedBy:
              jobStatus === "REJECTED"
                ? singleJobData?.posted_by_name || "XYZ"
                : null,
            rejectPersonName:
              jobStatus === "REJECTED"
                ? singleJobData?.posted_by || "XYZ"
                : null,
            vendorCompanyId: singleJobData?.vendor_company_id,
            vendorName: singleJobData?.vendor_name,
            platformId: singleJobData?.platform_id,
            companyDetails: singleJobData?.company,
            logo: singleJobData?.company?.preview_url,
          };
          temp.push(obj);
        }
        setJobData(temp);
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate, value, selectedPageNo]);

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      setSelectedPageNo(1);
      setValue(newValue);
    }
  };

  const thirdColumns = [
    {
      title: "Job ID",
      field: "jobId",
      headerStyle: {
        position: "sticky",
        left: 0,
        zIndex: 1000,
      },
      cellStyle: {
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
        fontSize: 13,
      },
      filterCellStyle: {
        position: "sticky",
        left: 0,
        zIndex: 100,
        backgroundColor: "#fff",
      },
    },
    ...(value === 1
      ? [
          {
            title: "Posted Job",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <Button
                variant="outline-info"
                size="sm"
                style={{
                  fontSize: 13,
                }}
                onClick={() => viewJobDetail(row.postedJobId)}
              >
                View Job
              </Button>
            ),
          },
        ]
      : []),

    {
      title: "Company Name",
      field: "companyName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.companyName ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                row.companyWebsite.startsWith("http")
                  ? row.companyWebsite
                  : "http://" + row.companyWebsite,
                "_blank"
              )
            }
          >
            {row.companyName}
          </div>
        ) : null,
    },
    {
      title: "Job Title",
      field: "jobTitle",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job Type",
      field: "jobTypeName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Location",
      field: "location",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Work Experience",
      field: "workExperience",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Work Mode",
      field: "workLocationMode",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
    },
    {
      title: "Work From Home",
      field: "workFromHome",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "CTC Range",
      field: "ctc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Stipend",
      field: "stipend",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Creation Date",
      field: "createTime",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.createTime) &&
        Date.parse(term[1]) >= Date.parse(rowData.createTime),
    },

    {
      title: "Vacancies",
      field: "vacancy",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Apply Link",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {
            <Button
              variant="outline-info"
              size="sm"
              style={{ whiteSpace: "nowrap", fontSize: 13 }}
              onClick={() => window.open(`${row?.applyLink}`, "_blank")}
            >
              Apply Link
            </Button>
          }
        </div>
      ),
    },
    {
      title: "Vendor Link",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {
            <Button
              variant="outline-info"
              size="sm"
              style={{ whiteSpace: "nowrap", fontSize: 13 }}
              onClick={() => window.open(`${row?.vendorLink}`, "_blank")}
            >
              Job Link
            </Button>
          }
        </div>
      ),
    },
    {
      title: "Utm Campaign",
      field: "utmCampaign",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Source",
      field: "utmSource",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Medium",
      field: "utmMedium",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Category",
      field: "utmCategory",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Utm Content",
      field: "utmContent",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "GetWork Link",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {row?.getworjLink ? (
            <Button
              variant="outline-info"
              size="sm"
              style={{ whiteSpace: "nowrap", fontSize: 13 }}
              onClick={() => window.open(`${row?.getworjLink}`, "_blank")}
            >
              GetWork Link
            </Button>
          ) : null}
        </div>
      ),
    },
    ...(value === 0
      ? [
          {
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <div>
                {
                  <Button
                    variant="outline-info"
                    size="sm"
                    style={{ whiteSpace: "nowrap", fontSize: 13 }}
                    onClick={() => {
                      setOpenThirdPartyCompany(true);
                      setSelectedCompanyDetails(row);
                    }}
                  >
                    Post Job
                  </Button>
                }
              </div>
            ),
          },
          {
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <div>
                {
                  <Button
                    variant="outline-danger"
                    size="sm"
                    style={{ whiteSpace: "nowrap", fontSize: 13 }}
                    onClick={(e) => loadRejectJobModal(e, row)}
                  >
                    Reject Job
                  </Button>
                }
              </div>
            ),
          },
        ]
      : []),
    ...(value === 2
      ? [
          {
            title: "Reject Reason",
            field: "rejectReason",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
        ]
      : []),
    ...(value === 2
      ? [
          {
            title: "Rejected By",
            field: "rejectedBy",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
        ]
      : []),
    ...(value === 2
      ? [
          {
            title: "Reject Person Name",
            field: "rejectPersonName",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
        ]
      : []),
    ...(value === 1
      ? [
          {
            title: "Posted By",
            field: "postedBy",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
          },
          {
            title: "Posted Date Time",
            field: "postedDateTime",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            type: "datetime",
            searchable: false,
            filterComponent: (props) => (
              <DateRangePicker
                rangeDivider="to"
                value={props.columnDef.tableData.filterValue || dateRange}
                onChange={(e) => handleChangeDate(e, props)}
              />
            ),
            customFilterAndSearch: (term, rowData) =>
              Date.parse(term[0]) <= Date.parse(rowData.postedDateTime) &&
              Date.parse(term[1]) >= Date.parse(rowData.postedDateTime),
          },
        ]
      : []),
  ];

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
      updateList();
    }
  };

  const removeDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    updateList();
  };

  const exportCSVfun = () => {
    setDownloadOverlay(true);
    apiNiyuktiCall(
      `/api/admin/third_party/export_candidate_excel/?column_name=job_id,job_title,date_of_application,source_of_application,student_name,email_id,phone_no,current_location,preferred_locations,total_experience,current_company_name,current_company_designation,functional_area,role,industry,key_skills,current_ctc,notice_period,ug_degree,ug_specialization,ug_university_institute_name,ug_year,pg_degree,pg_specialization,pg_university_institute_name,pg_year,phd_degree,phd_specialization,phd_university_institute_name,phd_year,gender,marital_status,home_town,pincode,dob,permanent_address,uploaded_by,uploaded_date`,
      requestPraveshOptions
    )
      .then((json) => {
        setDownloadOverlay(false);
        if (json?.success) {
          SuccessAlert(json?.data?.message);
        } else {
          FailureAlert(json?.error);
        }
      })
      .catch((err) => {
        FailureAlert("Something went wrong!!");
      });
  };

  return (
    <div className="open__close__jobs">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="PENDING" {...a11yProps(0)} />
          <Tab label="POSTED" {...a11yProps(1)} />
          <Tab label="REJECTED" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {!loading ? (
        <TabPanel value={value} index={value} dir={theme.direction}>
          <br />
          <DateSelector
            dateRange={[startDate, endDate]}
            applyDateFilter={applyDateFilter}
            removeDateFilter={removeDateFilter}
          />
          <TableWithExport
            title={
              value === 0
                ? "Pending 3rd Party Jobs"
                : value === 1
                ? "Posted 3rd Party Jobs"
                : "Rejected 3rd Party Jobs"
            }
            data={
              value === 1
                ? jobData.sort(
                    (a, b) =>
                      new Date(b?.postedDateTime) - new Date(a?.postedDateTime)
                  )
                : jobData
            }
            columns={thirdColumns}
            fName={
              value === 0
                ? "Pending_3_Party_Jobs"
                : value === 1
                ? "Posted_3_Party_Jobs"
                : "Rejected_3_Party_Jobs"
            }
            fromJobs={true}
            hasPaging={false}
            cellFixedIndex="4"
            filtering={false}
            dynamicExport={true}
            exportCSVfun={exportCSVfun}
            search={false}
            sorting={false}
            selection={false}
            disableTableDownload={true}
          />
          <section style={{ float: "right", marginTop: "1rem" }}>
            <Pagination
              page={selectedPageNo || 1}
              onChange={(e, value) => {
                setSelectedPageNo(value);
              }}
              count={metaData?.totalPages}
              color="primary"
            />
          </section>
        </TabPanel>
      ) : (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      )}
      {openThirdPartyCompany ? (
        <ThirdPartyCompany
          open={openThirdPartyCompany}
          setOpen={setOpenThirdPartyCompany}
          companyDetail={selectedCompanyDetails}
          setSelectedCompanyId={setSelectedCompanyId}
          selectedCompanyId={selectedCompanyId}
        />
      ) : null}
      {modalRejectJobData?.jobId ? (
        <Modal
          show={open}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalRejectJobData.jobTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to reject this job ?</p>
            <br />
            <Form>
              <Form.Label>
                Kindly enter the reason to reject this job*
              </Form.Label>
              <Form.Group controlId="closeJobReason">
                <Form.Control
                  name="closeJobReason"
                  type="text"
                  placeholder="Reason to Reject this job goes here..."
                  required
                  onChange={(e) => {
                    setCloseReason(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={(e) => rejectParticularJob(modalRejectJobData.jobId)}
            >
              Reject Job
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}

export default ThirdPartyJobsPosting;
