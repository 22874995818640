import React, { useState, useEffect } from "react";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import "../../css/EditCollegeDetails.css";
import { gifStyling, createIconStyling, requestPraveshOptions, requestNiyuktiOptions } from "../../constants";
import { Form, Col, Button } from "react-bootstrap";
import { characterCount } from "../../util/wordCounter";
import gif from "../../assets/loopingcircles-3.gif";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, TextField, CircularProgress, Box } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../util/copyToClipboard";
import { TabPanel } from "../../util/TabPanel";
import { uniqueArray } from "../../util/uniqueArray";
import ColorPicker from "../common/ColorPicker";

const token = localStorage.getItem("token");

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

export function EditCollegeSection({ clgId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [count, setCount] = useState(0);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [courseUID, setCourseUID] = useState(0);
  const [courses, setCourses] = useState([]);
  const [logo, setLogo] = useState(null);
  const [circularLogo, setCircularLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [banner, setBanner] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [universities, setUniversities] = useState([]);
  const [educationId, setEducationId] = useState(null);
  const [educationName, setEducationName] = useState("");
  const [education, setEducation] = useState([]);
  const [degreeId, setDegreeId] = useState(null);
  const [degreeName, setDegreeName] = useState("");
  const [degrees, setDegrees] = useState([]);
  const [specializationId, setSpecializationId] = useState(null);
  const [specializationName, setSpecializationName] = useState("");
  const [specializations, setSpecializations] = useState(null);
  const [tpoUsers, setTpoUsers] = useState([]);

  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [collegeName, setCollegeName] = useState("");
  const [collegeNickName, setCollegeNickName] = useState("");
  const [university, setUniversity] = useState(null);
  const [address, setAddress] = useState("");
  const [orgType, setOrgType] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [year, setYear] = useState("");
  const [size, setSize] = useState("");
  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [minCtc, setMinCtc] = useState("");
  const [avgCtc, setAvgCtc] = useState("");
  const [blocked, setBlocked] = useState("");
  const [onboarded, setOnboarded] = useState("");
  const [onboardDate, setOnboardDate] = useState("");
  const [isSaas, setIsSaas] = useState("");
  const [immediateJoining, setImmediateJoining] = useState("");
  const [getworkDomain, setGetworkDomain] = useState(null);
  const [passcode, setPasscode] = useState("");
  const [about, setAbout] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [aboutCount, setAboutCount] = useState(0);
  const [allJobSegments, setAllJobSegments] = useState([]);
  const [selectedJobSegments, setSelectedJobSegments] = useState([]);

  const [addNew, setAddNew] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [altEmail, setAltEmail] = useState("");
  const [altPhone, setAltPhone] = useState("");

  const [user, setUser] = useState(null);
  const [notListed, setNotListed] = useState(false);

  const [placementSession, setPlacementSession] = useState({});
  const [placementType, setPlacementType] = useState("");
  const [placementStart, setPlacementStart] = useState(null);
  const [placementEnd, setPlacementEnd] = useState(null);

  const [examSession, setExamSession] = useState({});
  const [semStart, setSemStart] = useState(null);
  const [semEnd, setSemEnd] = useState(null);

  const [jobTypes, setJobTypes] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [studentLicenseCount, setStudentLicenseCount] = useState(0);
  const [planStartDate, setPlanStartDate] = useState(null);
  const [planEndDate, setPlanEndDate] = useState(null);

  const [isGradeSystem, setIsGradeSystem] = useState(null);
  const [percentageFactor, setPercentageFactor] = useState(null);

  const monthArray = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
    { id: 4, value: "April" },
    { id: 5, value: "May" },
    { id: 6, value: "June" },
    { id: 7, value: "July" },
    { id: 8, value: "August" },
    { id: 9, value: "September" },
    { id: 10, value: "October" },
    { id: 11, value: "November" },
    { id: 12, value: "December" },
  ];

  const searchMonth = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const workExperiences = [
    { id: 1, name: "Fresher" },
    { id: 2, name: "1 Year" },
    { id: 3, name: "2 Years" },
    { id: 4, name: "3 Years" },
  ];

  const placementTypes = [
    { id: 1, value: "Internship" },
    { id: 2, value: "Full-time" },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteCourse = (uid) => {
    const index = courses.findIndex((course) => course.uid === uid);
    let newArray = [...courses];
    newArray[index].is_active = false;
    setCourses(newArray);
  };

  const addCourse = (e, educationId, degreeId, specializationId, educationName, degreeName, specializationName) => {
    const t = degrees.find((degree) => degree.id === degreeId && degree.educationtype === educationId);
    if (t && educationId && degreeId) {
      if (t.is_specialized && !specializationId) {
        FailureAlert("Select Specialization for this degree");
        return;
      } else if (!t.is_specialized) {
        specializationId = null;
        specializationName = "NA";
      }

      setCourses([
        ...courses,
        {
          uid: courseUID + 1,
          degree_id: degreeId,
          degree: degreeName,
          specialization_id: specializationId,
          specialization: specializationName,
          education_type: educationId,
          education: educationName,
          is_active: true,
        },
      ]);
      setCourseUID((prevState) => prevState + 1);
      setSpecializationName("");
      setSpecializationId(null);
    } else {
      FailureAlert("Select Valid Course");
    }
  };

  const addPlacementSession = (e, type, start, end) => {
    e.preventDefault();
    let temp = { ...placementSession };
    if (!type) {
      FailureAlert("Select Placement Type");
      return;
    }
    if (!start) {
      FailureAlert("Select Placement Start Month");
      return;
    }
    if (!end) {
      FailureAlert("Select Placement End Month");
      return;
    }
    if (!temp[type]) {
      temp[type] = [{ start_month: start, end_month: end }];
      setPlacementSession(temp);
    } else {
      FailureAlert(`${type} - Placement Session already added...\nDelete previous ${type} to change month!`);
    }
  };

  const deletePlacementSession = (e, type) => {
    e.preventDefault();
    let temp = { ...placementSession };
    delete temp[type];
    setPlacementSession(temp);
  };

  const addExamSession = (e, type, start, end) => {
    e.preventDefault();
    let temp = { ...examSession };
    if (!start) {
      FailureAlert("Select Semester Start Month");
      return;
    }
    if (!end) {
      FailureAlert("Select Semester End Month");
      return;
    }
    if (!temp[type]) {
      temp[type] = [{ start_month: start, end_month: end }];
      setExamSession(temp);
    } else {
      FailureAlert(`${type} already added...\nDelete previous ${type} to change month!`);
    }
  };

  const deleteExamSession = (e, type) => {
    e.preventDefault();
    let temp = { ...examSession };
    delete temp[type];
    setExamSession(temp);
  };

  // Finding Job Types
  useEffect(() => {
    apiNiyuktiCall("/job/type/", requestNiyuktiOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].job_type,
          };
          tmpArray.push(obj);
        }
        setJobTypes(tmpArray);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities
  useEffect(() => {
    apiCall("/api/location/city/", requestPraveshOptions).then((json) => {
      setAllCities(json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Job Segment Finding API
  useEffect(() => {
    apiNiyuktiCall("/job/segment/", requestNiyuktiOptions).then((json) => {
      if (json.success) {
        let arr = json.data.map((js) => ({
          job_segment_id: js.id,
          job_segment_name: js.job_segment,
        }));
        setAllJobSegments(arr);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Country Finding API
  useEffect(() => {
    apiCall("/api/location/country/", requestPraveshOptions).then((res) => {
      setCountries(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // State Finding API
  useEffect(() => {
    apiCall(`/api/location/state/?search=${countryId}`, requestPraveshOptions).then((res) => {
      setStates(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  // City Finding API
  useEffect(() => {
    if (stateId !== 0) {
      apiCall(`/api/location/city/?search=${stateId}`, requestPraveshOptions).then((res) => {
        setCities(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  // TPO users dropdown API
  useEffect(() => {
    apiCall("/api/admin/operations/change/admin/?user_type=College", requestPraveshOptions).then((res) => {
      setTpoUsers(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Education Type API
  useEffect(() => {
    apiCall("/api/education/type/", requestPraveshOptions).then((res) => {
      setEducation(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Degree Type API
  useEffect(() => {
    apiCall(`/api/education/degree/?admin=true&search=${educationId}`, requestPraveshOptions).then((res) => {
      setDegrees(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationId]);

  // Specialization API
  useEffect(() => {
    apiCall(`/api/education/getspecialization/?admin=true&search=${degreeId}`, requestPraveshOptions).then((res) => {
      if (res[0] && res[0].specialization !== null) {
        setSpecializations(res);
      } else if (res[0] && !res[0].specialization) {
        setSpecializations(null);
      } else {
        setSpecializations(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degreeId]);

  // University Finding API
  useEffect(() => {
    apiCall("/api/admin/university", requestPraveshOptions).then((res) => {
      if (res.success) {
        setUniversities(res.data);
      } else {
        FailureAlert("Invalid request for University API");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // View Particular College Details
  useEffect(() => {
    setLoading(true);
    apiCall(`/api/admin/college/view/?college_id=${clgId}`, requestPraveshOptions).then((json) => {
      if (json.success) {
        let obj = {
          college_id: parseInt(json.data.college_id),
          college_name: json.data.college_name,
          nick_name: json.data.nick_name,
          organization_type: json.data.organization_type,
          university_id: json.data.university_id,
          university_name: json.data.university_name ? json.data.university_name : "",
          address: json.data.address ? json.data.address : "",
          logo: json.data.logo ? json.data.logo : null,
          circular_logo: json.data?.circular_logo ? json.data?.circular_logo : null,
          favicon: json.data?.favicon ? json.data?.favicon : null,
          banner: json.data.banner ? json.data.banner : null,
          website: json.data.website ? json.data.website : "",
          specializations: json.data.degree_specialization,
          year_founded: json.data.year_founded ? json.data.year_founded.slice(0, 4) : "",
          min_ctc: json.data.min_ctc,
          avg_ctc: json.data.avg_ctc,
          country: json.data.country,
          state: json.data.state,
          city: json.data.city,
          preferences: json.data.preferences && typeof json.data.preferences === "object" ? json.data.preferences : {},
          placement_session: json.data.placement_session ? json.data.placement_session : {},
          exam_session: json.data.exam_session ? json.data.exam_session : {},
          job_segments: json.data.job_segments,
          is_blocked: json.data.is_blocked ? "Yes" : "No",
          is_onboarded: json.data.is_onboarded ? "Yes" : "No",
          immediate_joining: json.data.immediate_joining_availability ? "Yes" : "No",
          is_onboarded_date: json.data.is_onboarded_date ? json.data.is_onboarded_date : " ",
          is_saas: json.data.is_saas ? "Yes" : "No",
          domain: json.data.domain ? json.data.domain : "",
          tpo_user_id: json.data.tpo_details ? json.data.tpo_details.user_id : null,
          tpo_first_name: json.data.tpo_details ? json.data.tpo_details.first_name : "",
          tpo_last_name: json.data.tpo_details ? json.data.tpo_details.last_name : "",
          tpo_email: json.data.tpo_details ? json.data.tpo_details.email : "",
          tpo_phone: json.data.tpo_details ? json.data.tpo_details.phone : "",
          tpo_alt_email: json.data.tpo_details ? json.data.tpo_details.alt_email : "",
          tpo_alt_phone: json.data.tpo_details ? json.data.tpo_details.alt_phone : "",
          tpo_designation: json.data.tpo_details ? json.data.tpo_details.job_title : "",
          size: json.data.size,
          linkedIn:
            typeof json.data.social_links === "object"
              ? json.data.social_links && json.data.social_links[0]
                ? json.data.social_links[0]["Value"] || json.data.social_links[0]["value"]
                : ""
              : JSON.parse(json.data.social_links) && JSON.parse(json.data.social_links)[0]
              ? JSON.parse(json.data.social_links)[0]["Value"] || JSON.parse(json.data.social_links)[0]["value"]
              : "",
          facebook:
            typeof json.data.social_links === "object"
              ? json.data.social_links && json.data.social_links[1]
                ? json.data.social_links[1]["Value"] || json.data.social_links[1]["value"]
                : ""
              : JSON.parse(json.data.social_links) && JSON.parse(json.data.social_links)[1]
              ? JSON.parse(json.data.social_links)[1]["Value"] || JSON.parse(json.data.social_links)[1]["value"]
              : "",
          instagram:
            typeof json.data.social_links === "object"
              ? json.data.social_links && json.data.social_links[2]
                ? json.data.social_links[2]["Value"] || json.data.social_links[2]["value"]
                : ""
              : JSON.parse(json.data.social_links) && JSON.parse(json.data.social_links)[2]
              ? JSON.parse(json.data.social_links)[2]["Value"] || JSON.parse(json.data.social_links)[2]["value"]
              : "",
          about: json.data.about,
          message: json.data.message,
          description: json.data.description,
          students_registered: json.data.students_registered,
          side_image: json.data.side_image,
          primary_color: json.data.primary_color ? json.data.primary_color : null,
          secondary_color: json.data.secondary_color ? json.data.secondary_color : null,
          is_grade_system: json.data.is_grade_system ? "CGPA" : "Percentage",
          percentage_factor: json.data.percentage_factor,
          latitude: json.data.latitude,
          longitude: json.data.longitude,
          passcode: json.data.passcode,
          student_license_count: json.data.student_license_count,
          plan_start_date: json.data.plan_start_date,
          plan_end_date: json.data.plan_end_date,
        };
        setSelectedJobSegments(obj.job_segments);
        setPlacementSession(obj.placement_session);
        let examSessionObject = {};
        if (obj.exam_session["exam_details"]) {
          let sem = 1;
          obj.exam_session.exam_details.forEach((p) => {
            examSessionObject[`Semester ${sem++}`] = [{ start_month: p.start_month, end_month: p.end_month }];
          });
        }
        setExamSession(examSessionObject);
        setSelectedCities(obj.preferences["location"] || []);
        setSelectedExperience(obj.preferences["work_exp"] || []);
        setSelectedJobType(obj.preferences["job_type"] || []);
        setPlanStartDate(json.data.plan_start_date);
        setPlanEndDate(json.data.plan_end_date);
        setStudentLicenseCount(json.data.student_license_count);
        setData(obj);
        setEmail(obj.tpo_email)
        setFirstName(obj.tpo_first_name)
        setLastName(obj.tpo_last_name)
        setDesignation(obj.tpo_designation)
        setPhone(obj.tpo_phone)
        setAltEmail(obj.tpo_alt_email)
        setAltPhone(obj.tpo_alt_phone)
        setIsSaas(json.data.is_saas ? "Yes" : "No");
        let c = [];
        for (var i = 0; i < obj.specializations.length; i++) {
          let course_obj = {
            uid: i + 1,
            degree_id: obj.specializations[i].degree_id,
            degree: obj.specializations[i].degree_name,
            specialization_id: obj.specializations[i].specialization_id,
            specialization: obj.specializations[i].specialization_name,
            education_type: obj.specializations[i].education_type,
            education: obj.specializations[i].education_type_name,
            is_active: obj.specializations[i].is_active,
          };
          c.push(course_obj);
        }
        setCourses(c);
        setCourseUID(c.length);
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });
  }, [clgId, count]);

  const updateGeneralDetails = (e) => {
    e.preventDefault();
    if (aboutCount > 512) {
      FailureAlert("College About can only have upto 512 characters...");
      return;
    }
    if (passcode.length > 10) {
      FailureAlert("Passcode can't exceed 10 characters...");
      return;
    }

    if (isSaas==='Yes') {
      if (!studentLicenseCount) {
        FailureAlert("Please Enter Student Licence Count");
        return;
      }
      if (!planStartDate) {
        FailureAlert("Please Enter Plan Start Date");
        return;
      }
      if (!planEndDate) {
        FailureAlert("Please Enter Plan End Date");
        return;
      }
      if (planStartDate > planEndDate) {
        FailureAlert("Please Enter Correct Plan Start Date and End Date");
        return;
      }
    }

    // job_segment: uniqueArray(selectedJobSegments)

    const formData = new FormData();
    collegeName && formData.append("name", collegeName);
    collegeNickName && formData.append("nick_name", collegeNickName);
    university && formData.append("university", university);
    address && formData.append("address", address);
    orgType && formData.append("organization_type", orgType);
    size && formData.append("size", size);
    year && formData.append("year_founded", `${year}-01-01`);
    websiteUrl && formData.append("website", websiteUrl);
    cityId && stateId && formData.append("country", countryId);
    cityId && stateId && formData.append("state", stateId);
    cityId && stateId && formData.append("city", cityId);
    about && formData.append("about", about);
    description && formData.append("description", description);
    message && formData.append("message_for_recruiters", message);
    (linkedIn || facebook || instagram) &&
      formData.append(
        "social_links",
        JSON.stringify([
          { id: "1", name: "LinkedIn", value: linkedIn || data.linkedIn || "" },
          { id: "2", name: "Facebook", value: facebook || data.facebook || "" },
          { id: "3", name: "Instagram", value: instagram || data.instagram | "" },
        ])
      );
    minCtc && formData.append("min_ctc", minCtc);
    avgCtc && formData.append("avg_ctc", avgCtc);
    blocked && blocked === "Yes" && formData.append("is_blocked", true);
    blocked && blocked === "No" && formData.append("is_blocked", false);
    onboardDate && formData.append("is_onboarded_date", `${onboardDate}T00:00`);
    onboarded && onboarded === "Yes" && formData.append("is_onboarded", true);
    onboarded && onboarded === "No" && formData.append("is_onboarded", false);
    isSaas && formData.append("is_saas", isSaas === "Yes" ? true : false);
    getworkDomain && formData.append("domain", getworkDomain);
    passcode && formData.append("passcode", passcode.toUpperCase());
    immediateJoining && immediateJoining === "Yes" && formData.append("immediate_joining_availability", true);
    immediateJoining && immediateJoining === "No" && formData.append("immediate_joining_availability", false);
    // selectedJobSegments && formData.append('job_segments', JSON.stringify(uniqueArray(selectedJobSegments)));
    logo && formData.append("logo", logo);
    circularLogo && formData.append("circular_logo", circularLogo);
    favicon && formData.append("favicon", favicon);

    banner && formData.append("banner", banner);
    sideImage && formData.append("side_image", sideImage);
    primaryColor && formData.append("primary_color", primaryColor);
    secondaryColor && formData.append("secondary_color", secondaryColor);
    isGradeSystem && formData.append("is_grade_system", isGradeSystem === "CGPA" ? true : false);
    percentageFactor && formData.append("percentage_factor", percentageFactor);
    latitude && formData.append("latitude", latitude);
    longitude && formData.append("longitude", longitude);
    studentLicenseCount && formData.append("student_license_count", `${studentLicenseCount}`);
    planStartDate && formData.append("plan_start_date", planStartDate);
    planEndDate && formData.append("plan_end_date", planEndDate);

    const requestUpdateGeneralDetails = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    };

    apiCall(`/api/admin/college/edit/?college_id=${clgId}`, requestUpdateGeneralDetails).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        window.document.getElementById("edit__general__details__form__details").reset();
        setCollegeName("");
        setCollegeNickName("");
        setYear("");
        setUniversity(null);
        setOrgType("");
        setWebsiteUrl("");
        setSize("");
        setAddress("");
        setLogo(null);
        setBanner(null);
        setMinCtc("");
        setAvgCtc("");
        setBlocked("");
        setSelectedJobSegments([]);
        setLinkedIn("");
        setFacebook("");
        setInstagram("");
        setOnboardDate("");
        setAbout("");
        setDescription("");
        setMessage("");
        setCountryId(1);
        setStateId(0);
        setCityId(0);
        setGetworkDomain("");
        setIsSaas("");
        setCount(count + 1);
        setSideImage(null);
        setIsGradeSystem(null);
        setPercentageFactor(null);
        setLatitude(null);
        setLongitude(null);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const updateSpecializations = (e) => {
    e.preventDefault();
    setLoading(true);

    const requestUpdateSpecializations = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        degree_specialization: courses,
      }),
    };
    apiCall(`/api/admin/college/edit/?college_id=${clgId}`, requestUpdateSpecializations)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setCourses([]);
          setCourseUID(0);
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const updatePreferences = (e) => {
    e.preventDefault();
    setLoading(true);
    let examSessionObject = {};
    examSessionObject["total_sem"] = Object.keys(examSession).length;
    examSessionObject["exam_details"] = Object.keys(examSession).map((type) => {
      let p = examSession[type];
      return { start_month: p[0].start_month, end_month: p[0].end_month };
    });

    let preferences = { ...data.preferences };
    preferences["work_exp"] = uniqueArray(selectedExperience);
    preferences["job_type"] = uniqueArray(selectedJobType);
    preferences["location"] = uniqueArray(selectedCities);

    const requestUpdatePreferences = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        exam_session: examSessionObject,
        placement_session: placementSession,
        preferences: preferences,
        job_segments: uniqueArray(selectedJobSegments),
      }),
    };
    apiCall(`/api/admin/college/edit/?college_id=${clgId}`, requestUpdatePreferences)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const updateTPODetails = (e) => {
    e.preventDefault();
    setLoading(true);

    const requestUpdateSTPODetails = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        user_type: "College",
        entity_id: clgId,
        job_title: designation || data.tpo_designation,
        user_id: data.tpo_user_id,
        first_name: firstName || data.tpo_first_name,
        last_name: lastName || data.tpo_last_name,
        email: email || data.tpo_email,
        phone: phone || data.tpo_phone,
        alternate_email: altEmail || data.tpo_alt_email,
        alternate_phone: altPhone || data.tpo_alt_phone,
      }),
    };

    apiCall(`/api/admin/operations/update/admin/`, requestUpdateSTPODetails)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setFirstName("");
          setLastName("");
          setEmail("");
          setDesignation("");
          setPhone("");
          setAltEmail("");
          setAltPhone("");
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const addTPO = (e) => {
    e.preventDefault();
    setLoading(true);

    const requestChangeTPODetails = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        user_type: "College",
        entity_id: clgId,
        job_title: designation || data.tpo_designation || ".",
        user_id: user || data.tpo_user_id,
        previous_user_id: data.tpo_user_id,
        first_name: firstName || data.tpo_first_name,
        last_name: lastName || data.tpo_last_name,
        email: email || data.tpo_email,
        phone: phone || data.tpo_phone,
      }),
    };

    apiCall(`/api/admin/operations/change/admin/`, requestChangeTPODetails)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setUser(null);
          setFirstName("");
          setLastName("");
          setEmail("");
          setDesignation("");
          setPhone("");
          setAddNew(false);
          setNotListed(false);
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };

  const addTPODetails = (e) => {
    e.preventDefault();
    setLoading(true);

    const requestChangeTPODetails = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        user_type: "College",
        entity_id: clgId,
        user_id: null,
        previous_user_id: data.tpo_user_id,
        first_name: firstName,
        last_name: lastName,
        job_title: designation,
        email: email,
        phone: phone,
      }),
    };

    apiCall(`/api/admin/operations/change/admin/`, requestChangeTPODetails)
      .then((res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setFirstName("");
          setLastName("");
          setEmail("");
          setDesignation("");
          setPhone("");
          setAddNew(false);
          setNotListed(false);
          setCount(count + 1);
        } else {
          FailureAlert(res.error);
        }
      })
      .then(setLoading(false));
  };
  return !loading ? (
    data ? (
      <div className="edit__college__section">
        <AppBar position="static" color="default">
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
            <Tab label="General" {...a11yProps(0)} /> {/* College_name, university_name, organization_type, size */}
            <Tab label="TPO Details" {...a11yProps(1)} /> {/*Tpo name, tpo email, tpo   */}
            <Tab label="Preferences" {...a11yProps(2)} /> {/*Preferences */}
            <Tab label="Specializations" {...a11yProps(3)} />
          </Tabs>
        </AppBar>

        {/* General Details Edit */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          {data.banner && data.logo ? (
            <div style={{ display: "flex", flexDirection: "column", maxHeight: "250px" }}>
              <img alt="banner" src={data.banner} style={{ height: "250px", width: "96%", margin: "2% 0 0 0" }} />
              <img
                alt="logo"
                src={data.logo}
                style={{ height: "110px", width: "20%", margin: "-110px 40% 0px 40%", border: "3px solid grey", borderRadius: "50%", position: "abloute", bottom: "0" }}
              />
            </div>
          ) : data.banner ? (
            <img alt="banner" src={data.banner} style={{ height: "250px", width: "96%", margin: "2%" }} />
          ) : data.logo ? (
            <img
              alt="logo"
              src={data.logo}
              style={{
                height: "200px",
                width: "30%",
                margin: "10px 35%",
                border: "3px solid grey",
                borderRadius: "50%",
              }}
            />
          ) : null}
          <Form className="edit__general__details__form" id="edit__general__details__form__details" onSubmit={(e) => updateGeneralDetails(e)}>
            {/* College Name & College Nick Name */}
            <Form.Row>
              <Form.Group as={Col} controlId="collegeName">
                <Form.Label>College Name</Form.Label>
                <Form.Control
                  name="collegeName"
                  type="text"
                  placeholder={data.college_name}
                  onChange={(e) => {
                    setCollegeName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="collegeNickName">
                <Form.Label>College Nick Name</Form.Label>
                <Form.Control
                  name="collegeNickName"
                  type="text"
                  placeholder={data.nick_name || ""}
                  onChange={(e) => {
                    setCollegeNickName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="yearFounded">
                <Form.Label>Year Founded</Form.Label>
                <Form.Control
                  name="yearFounded"
                  type="text"
                  placeholder={data.year_founded || ""}
                  pattern="[0-9]*"
                  maxLength="4"
                  minLength="4"
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Label>Select University : </Form.Label>
            <Form.Group controlId="universityName">
              <div className="typeSearchUniversity">
                <Autocomplete
                  id="select-university"
                  options={universities}
                  getOptionLabel={(item) => item.university_name}
                  onChange={(e, item) => {
                    if (item) {
                      setUniversity(item.university_id);
                    } else {
                      setUniversity(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={`Select University (Current: ${data.university_name || `NONE`})`} variant="outlined" />}
                />
              </div>
            </Form.Group>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Form.Label>Address : </Form.Label>
              {data.address ? (
                <span>
                  (Click icon to copy initial text)
                  <FileCopyIcon style={createIconStyling} onClick={(e) => copyToClipboard(data.address)} />
                </span>
              ) : null}
            </div>
            <Form.Group controlId="collegeAddress">
              <Form.Control
                name="collegeAddress"
                as="textarea"
                placeholder={data.address || "Enter College Address"}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="organizationType">
                <Form.Label>Organization Type :</Form.Label>
                <Form.Control
                  name="organizationType"
                  as="select"
                  onChange={(e) => {
                    setOrgType(e.target.value);
                  }}
                >
                  <option>{`--${data.organization_type}--`}</option>
                  <option value="Government">Government</option>
                  <option value="SemiPrivate">Semi Private</option>
                  <option value="Private">Private</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="collegeWebsite">
                <Form.Label>College Website URL :</Form.Label>
                <Form.Control
                  name="collegeWebsite"
                  type="text"
                  placeholder={data.website || "Enter College Website URL"}
                  onChange={(e) => {
                    setWebsiteUrl(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="size">
                <Form.Label>Student Count :</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setSize(e.target.value);
                  }}
                >
                  <option value="">{`--(${data.size})--`}</option>
                  <option value="1-10">1-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-1000">101-1000</option>
                  <option value="1000+">1000+</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Label>
              <b>
                <i>Location :</i>
              </b>
            </Form.Label>
            <Form.Row className="typeSearchLocation">
              <Form.Group as={Col} controlId="country">
                <Autocomplete
                  id="select-country"
                  options={countries}
                  getOptionLabel={(item) => item.country}
                  onChange={(e, item) => (item ? setCountryId(item.country_id) : setCountryId(1))}
                  renderInput={(params) => <TextField {...params} label={`Select Country (Current: ${data.country || `NONE`})`} variant="outlined" />}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="state">
                <Autocomplete
                  id="select-state"
                  options={states}
                  getOptionLabel={(item) => item.state}
                  onChange={(e, item) => (item ? setStateId(item.state_id) : setStateId(0))}
                  renderInput={(params) => <TextField {...params} label={`Select State (Current: ${data.state || `NONE`})`} variant="outlined" />}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="city">
                <Autocomplete
                  id="select-city"
                  options={cities}
                  getOptionLabel={(item) => item.city}
                  onChange={(e, item) => (item ? setCityId(item.city_id) : setCityId(0))}
                  renderInput={(params) => <TextField {...params} label={`Select City (Current: ${data.city || `NONE`})`} variant="outlined" />}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row className="geoLocation">
              <Form.Group as={Col} controlId="longitude">
                <Form.Label>Longitude :</Form.Label>
                <Form.Control
                  name="longitude"
                  type="text"
                  placeholder={data.longitude || "Enter longitude"}
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="latitude">
                <Form.Label>Latitude :</Form.Label>
                <Form.Control
                  name="latitude"
                  type="text"
                  placeholder={data.latitude || "Enter Latitude"}
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Form.Label>
                College About
                {aboutCount < 512 ? (
                  <i style={{ fontSize: "13px", color: "green" }}> Total Characters: {aboutCount} out of 512 </i>
                ) : (
                  <i style={{ fontSize: "13px", color: "red" }}> Character Limit Exceeded (max 512 characters) : Character Count : {aboutCount}</i>
                )}
              </Form.Label>
              {data.about ? (
                <span>
                  (Click icon to copy initial text)
                  <FileCopyIcon style={createIconStyling} onClick={(e) => copyToClipboard(data.about)} />
                </span>
              ) : null}
            </div>
            <Form.Group controlId="instituteAbout">
              <Form.Control
                name="instituteAbout"
                as="textarea"
                rows="4"
                placeholder={data.about || "Enter About"}
                onChange={(e) => {
                  setAbout(e.target.value);
                  e.target.value && setAboutCount(characterCount(e.target.value));
                  !e.target.value && setAboutCount(0);
                }}
              />
            </Form.Group>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Form.Label>College Description </Form.Label>
              {data.description ? (
                <span>
                  (Click icon to copy initial text)
                  <FileCopyIcon style={createIconStyling} onClick={(e) => copyToClipboard(data.description)} />
                </span>
              ) : null}
            </div>
            <Form.Group controlId="instituteDescription">
              <Form.Control
                name="instituteDescription"
                as="textarea"
                rows="4"
                placeholder={data.description || "Enter Description"}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Label>
              <b>
                <i>Social Media Links :</i>
              </b>
            </Form.Label>
            <Form.Row className="socialMediaLinks">
              <Form.Group as={Col} controlId="linkedInPage">
                <Form.Label>LinkedIn Account :</Form.Label>
                <Form.Control
                  name="linkedInPage"
                  type="text"
                  placeholder={data.linkedIn || "Enter LinkedIn URL"}
                  onChange={(e) => {
                    setLinkedIn(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="facebookPage">
                <Form.Label>Facebook Account :</Form.Label>
                <Form.Control
                  name="facebookPage"
                  type="text"
                  placeholder={data.facebook || "Enter Facebook URL"}
                  onChange={(e) => {
                    setFacebook(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="instaAccount">
                <Form.Label>Instagram Account :</Form.Label>
                <Form.Control
                  name="instaAccount"
                  type="text"
                  placeholder={data.instagram || "Enter Instagram URL"}
                  onChange={(e) => {
                    setInstagram(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Form.Label>Message for Recruiters :</Form.Label>
              {data.message ? (
                <span>
                  (Click icon to copy initial text)
                  <FileCopyIcon style={createIconStyling} onClick={(e) => copyToClipboard(data.message)} />
                </span>
              ) : null}
            </div>
            <Form.Group controlId="message">
              <Form.Control name="message" as="textarea" rows="3" placeholder={data.message || "Message for Recruiters"} onChange={(e) => setMessage(e.target.value)} />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="isGradeSystem">
                <Form.Label>Grade System</Form.Label>
                <Form.Control
                  name="isGradeSystem"
                  as="select"
                  onChange={(e) => {
                    setIsGradeSystem(e.target.value);
                  }}
                >
                  <option>{`--${data.is_grade_system}--`}</option>
                  <option value="CGPA">CGPA</option>
                  <option value="Percentage">Percentage</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="percentageFactor">
                <Form.Label>Percentage Factor</Form.Label>
                <Form.Control
                  name="percentageFactor"
                  type="number"
                  placeholder={data.percentage_factor ? data.percentage_factor : "out of 10/9.5/5"}
                  step="0.01"
                  onChange={(e) => {
                    setPercentageFactor(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="minCtc">
                <Form.Label>Minimum CTC</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  name="minCTC"
                  type="text"
                  placeholder={data.min_ctc}
                  onValueChange={(values) => {
                    const { value } = values;
                    setMinCtc(value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="avgCtc">
                <Form.Label>Average CTC</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  name="avgCTC"
                  type="text"
                  placeholder={data.avg_ctc}
                  onValueChange={(values) => {
                    const { value } = values;
                    setAvgCtc(value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="immediateJoining">
                <Form.Label>Immediate Joining</Form.Label>
                <Form.Control
                  name="immediateJoining"
                  as="select"
                  onChange={(e) => {
                    setImmediateJoining(e.target.value);
                  }}
                >
                  <option value="">{`--${data.immediate_joining}--`}</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="GetWork Domain">
                <Form.Label>GetWork Domain:</Form.Label>
                <Form.Control
                  name="GetWorkDomain"
                  type="text"
                  placeholder={data.domain || "GetWork Domain"}
                  onChange={(e) => {
                    setGetworkDomain(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="onboarded_date">
                <Form.Label>Onboarded Date ({data.is_onboarded_date || "None"})</Form.Label>
                <Form.Control
                  name="onboarded_date"
                  type="date"
                  // placeholder={data.is_onboarded_date || ''}
                  onChange={(e) => setOnboardDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="isOnboarded">
                <Form.Label>Onboard Status</Form.Label>
                <Form.Control
                  name="isOnboarded"
                  as="select"
                  onChange={(e) => {
                    setOnboarded(e.target.value);
                  }}
                >
                  <option>{`--${data.is_onboarded}--`}</option>
                  <option value="Yes">On Board</option>
                  <option value="No">Off Board</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="passcode">
                <Form.Label>Passcode:</Form.Label>
                <Form.Control
                  className="passcode"
                  name="passcode"
                  type="text"
                  placeholder={data.passcode || "passcode"}
                  onChange={(e) => {
                    setPasscode(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="isSaas">
                <Form.Label>SAAS College</Form.Label>
                <Form.Control
                  name="isSaas"
                  as="select"
                  onChange={(e) => {
                    setIsSaas(e.target.value);
                  }}
                >
                  <option>{`--${data.is_saas}--`}</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="isBlocked">
                <Form.Label>Block Status</Form.Label>
                <Form.Control
                  name="isBlocked"
                  as="select"
                  onChange={(e) => {
                    setBlocked(e.target.value);
                  }}
                >
                  <option>{`--${data.is_blocked}--`}</option>
                  <option value="Yes">Block</option>
                  <option value="No">Unblock</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="passcode">
                <Form.Label>Student License Count:</Form.Label>
                <Form.Control
                  className="studentLicenseCount"
                  name="studentLicenseCount"
                  type="text"
                  placeholder={data.studentLicenseCount || "studentLicenseCount"}
                  value={studentLicenseCount}
                  onChange={(e) => {
                    setStudentLicenseCount(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="planStartDate">
                <Form.Label>Plan Start Date ({data.planStartDate || "None"})</Form.Label>
                <Form.Control
                  name="planStartDate"
                  type="date"
                  // placeholder={data.is_planStartDate || ''}
                  value={planStartDate}
                  onChange={(e) => setPlanStartDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="planEndDate">
                <Form.Label>Plan End Date ({data.planEndDate || "None"})</Form.Label>
                <Form.Control
                  name="planEndDate"
                  type="date"
                  value={planEndDate}
                  // placeholder={data.is_planEndDate || ''}
                  onChange={(e) => setPlanEndDate(e.target.value)}
                />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="primaryColor" required>
              <Form.Label>Select Primary Color(For SAAS College Only):</Form.Label>
              <ColorPicker color={primaryColor ? primaryColor : data.primary_color ? data.primary_color : "#007BFF"} setColor={setPrimaryColor} />
            </Form.Group>
            <br />
            <Form.Group controlId="secondaryColor" required>
              <Form.Label>Select Secondary Color(For SAAS College Only):</Form.Label>
              <ColorPicker color={secondaryColor ? secondaryColor : data.secondary_color ? data.secondary_color : "#e0a800"} setColor={setSecondaryColor} />
            </Form.Group>
            <br />

            <Form.Label>
              College {isSaas?.toLowerCase()?.includes("yes") && "Rectangular"} Logo {isSaas?.toLowerCase()?.includes("yes") && "(Recommended for SAAS College)"} :
            </Form.Label>
            <Form.File id="formcheck-api-custom" custom style={{ marginBottom: "3%" }}>
              <Form.File.Input onChange={(e) => setLogo(e.target.files[0])} isValid />
              <Form.File.Label data-browse="Upload Logo">Click to choose file from local directory</Form.File.Label>
              {logo ? <Form.Control.Feedback type="valid">{logo.name}</Form.Control.Feedback> : null}
            </Form.File>
            <Form.Label>College Banner :</Form.Label>
            <Form.File id="formcheck-api-custom" custom style={{ marginBottom: "3%" }}>
              <Form.File.Input onChange={(e) => setBanner(e.target.files[0])} isValid />
              <Form.File.Label data-browse="Upload Banner">Click to choose file from local directory</Form.File.Label>
              {banner ? <Form.Control.Feedback type="valid">{banner.name}</Form.Control.Feedback> : null}
            </Form.File>

            <br />
            {/* {isSaas?.toLowerCase()?.includes("yes") && (
              <> */}
            <Form.Label>College Circular Logo (Recommended for SAAS College) :</Form.Label>
            {data?.circular_logo && (
              <>
                <br />
                <img style={{ width: "15%", borderRadius: "20px", margin: "10px 0px" }} src={data?.circular_logo} alt="side_image" />
              </>
            )}
            <Form.File id="formcheck-api-custom" custom style={{ marginBottom: "3%" }}>
              <Form.File.Input onChange={(e) => setCircularLogo(e.target.files[0])} isValid />
              <Form.File.Label data-browse="Upload Circular Logo">Click to choose/update file from local directory</Form.File.Label>
              {circularLogo ? <Form.Control.Feedback type="valid">{circularLogo.name}</Form.Control.Feedback> : null}
            </Form.File>
            <br />

            <Form.Label>College Favicon (Recommended for SAAS College) :</Form.Label>
            {data?.favicon && (
              <>
                <br />
                <img style={{ width: "10%", borderRadius: "20px", margin: "10px 0px" }} src={data?.favicon} alt="side_image" />
                <br />
              </>
            )}

            <Form.File id="formcheck-api-custom" custom style={{ marginBottom: "3%" }}>
              <Form.File.Input
                onChange={(e) => {
                  if (e.target.files[0]?.name?.includes("ico")) setFavicon(e.target.files[0]);
                  else FailureAlert("Please Enter .ico extension file");
                }}
                isValid
              />
              <Form.File.Label data-browse="Upload Favicon">Click to choose/update file from local directory</Form.File.Label>
              {favicon ? <Form.Control.Feedback type="valid">{favicon.name}</Form.Control.Feedback> : null}
            </Form.File>
            <br />
            {/* </>
            )} */}
            <img style={{ width: "30%", borderRadius: "20px", margin: "10px 0px" }} src={data.side_image} alt="side_image" />
            <Form.File id="formcheck-api-custom" custom style={{ marginBottom: "3%" }}>
              <Form.File.Input onChange={(e) => setSideImage(e.target.files[0])} isValid />
              <Form.File.Label data-browse="Upload Side Image">Click to choose/update file from local directory</Form.File.Label>
              {sideImage ? <Form.Control.Feedback type="valid">{sideImage.name}</Form.Control.Feedback> : null}
            </Form.File>

            <Button type="submit" variant="info">
              Save Changes
            </Button>
          </Form>
        </TabPanel>
        
        {/* TPO Details Edit */}
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Form.Group controlId="changeUserCheckBox">
            <Form.Check type="checkbox" label="Change User" checked={addNew} onChange={(e) => setAddNew(!addNew)} />
          </Form.Group>
          {!addNew && data.tpo_email ? (
            <Form className="edit__tpo__details__form" onSubmit={(e) => updateTPODetails(e)}>
              <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    value={lastName || ""}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="designation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    name="designation"
                    type="text"
                    value={designation}
                    required
                    onChange={(e) => {
                      setDesignation(e.target.value);
                    }}
                  />
                  {/* <Form.Control
                                        as="select"
                                        name="designation"
                                        onChange={e => { setDesignation(e.target.value) }}
                                    >
                                        <option value={data.tpo_designation}>--{data.tpo_designation}--</option>
                                        <option value="TPO_ADMIN">TPO ADMIN</option>
                                        <option value="TPO_MEMBER">TPO MEMBER</option>
                                        <option value="TPO_COORDINATOR">TPO COORDINATOR</option>
                                        <option value="PROFESSOR">PROFESSOR</option>
                                        <option value="ASST_PROFESSOR">ASST. PROFESSOR</option>
                                        <option value="LECTURER">LECTURER</option>
                                        <option value="STUDENT">STUDENT</option>
                                    </Form.Control> */}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>TPO Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="phone">
                  <Form.Label>TPO Contact</Form.Label>
                  <Form.Control
                    name="phone"
                    type="text"
                    value={phone}
                    pattern="[0-9]*"
                    maxLength="10"
                    minLength="10"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>TPO Alternate Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    value={altEmail}
                    onChange={(e) => {
                      setAltEmail(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="phone">
                  <Form.Label>TPO Alternate Contact</Form.Label>
                  <Form.Control
                    name="phone"
                    type="text"
                    value={altPhone}
                    pattern="[0-9]*"
                    maxLength="10"
                    minLength="10"
                    onChange={(e) => {
                      setAltPhone(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Button type="submit" variant="info">
                Save Changes
              </Button>
            </Form>
          ) : notListed ? (
            <Form className="edit__tpo__details__form" onSubmit={(e) => addTPODetails(e)}>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Not Listed" onChange={(e) => setNotListed(!notListed)} checked={notListed} />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    required
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="lastName">
                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    required
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="designation">
                  <Form.Label>Designation*</Form.Label>
                  <Form.Control
                    name="designation"
                    type="text"
                    placeholder="Job Title Goes here.."
                    required
                    onChange={(e) => {
                      setDesignation(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>TPO Email*</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="phone">
                  <Form.Label>TPO Contact</Form.Label>
                  <Form.Control
                    name="phone"
                    type="text"
                    placeholder="Phone Number"
                    pattern="[0-9]*"
                    maxLength="10"
                    minLength="10"
                    required
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Button type="submit" variant="info">
                Save Changes
              </Button>
            </Form>
          ) : (
            <Form className="add__tpo__details__form" onSubmit={(e) => addTPO(e)}>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Not Listed" onChange={(e) => setNotListed(!notListed)} checked={notListed} />
              </Form.Group>
              <Form.Row>
                <Autocomplete
                  id="select-user"
                  options={tpoUsers}
                  getOptionLabel={(item) => `${item.first_name} ${item.last_name}  (${item.email})`}
                  style={{ width: "90%", margin: "5%", marginTop: "0px" }}
                  onChange={(e, item) => (item ? setUser(item.user_id) : setUser(null))}
                  renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                />
              </Form.Row>
              <Button type="submit" variant="info">
                Update User
              </Button>
            </Form>
          )}
        </TabPanel>

        {/* Preferences */}
        <TabPanel value={value} index={2} dir={theme.direction}>
          {data.preferences ? (
            <Form className="edit__preferences__form" onSubmit={(e) => updatePreferences(e)}>
              <Form.Label>
                <i>
                  <u>Placement Session : </u>
                </i>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Placement Type</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Start Month</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>End Month</Form.Label>
                </Form.Group>
              </Form.Row>
              {Object.keys(placementSession).length > 0
                ? Object.keys(placementSession).map((type) => {
                    let p = placementSession[type];
                    return (
                      <Form.Row key={type} className="placement_session_list">
                        <Form.Group as={Col}>
                          <Form.Control value={type || ""} readOnly />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Control value={searchMonth[p[0].start_month] || ""} readOnly />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Control value={searchMonth[p[0].end_month] || ""} readOnly />
                        </Form.Group>
                        <Button onClick={(e) => deletePlacementSession(e, type)} variant="danger" size="sm">
                          {" "}
                          Delete
                        </Button>
                      </Form.Row>
                    );
                  })
                : null}
              <Form.Row className="placement_session_list">
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setPlacementType(JSON.parse(e.target.value).value);
                    }}
                  >
                    <option value={JSON.stringify({ id: null, value: null })}>--select--</option>
                    {placementTypes.map((e, key) => {
                      return (
                        <option key={key} value={JSON.stringify(e)}>
                          {" "}
                          {e.value}{" "}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setPlacementStart(JSON.parse(e.target.value).id);
                    }}
                  >
                    <option value={JSON.stringify({ id: null, value: "--select--" })}>--select--</option>
                    {monthArray.map((e, key) => {
                      return (
                        <option key={key} value={JSON.stringify(e)}>
                          {" "}
                          {e.value}{" "}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setPlacementEnd(JSON.parse(e.target.value).id);
                    }}
                  >
                    <option value={JSON.stringify({ id: null, value: "--select--" })}>--select--</option>
                    {monthArray.map((e, key) => {
                      return (
                        <option key={key} value={JSON.stringify(e)}>
                          {" "}
                          {e.value}{" "}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Button onClick={(e) => addPlacementSession(e, placementType, placementStart, placementEnd)} variant="info" size="sm">
                  {" "}
                  Add
                </Button>
              </Form.Row>

              <Form.Label>
                <i>
                  <u>Exam Session : </u>
                </i>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Semester</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Start Month</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>End Month</Form.Label>
                </Form.Group>
              </Form.Row>
              {Object.keys(examSession).length > 0
                ? Object.keys(examSession).map((type, key) => {
                    let p = examSession[type];
                    return (
                      <Form.Row key={type} className="placement_session_list">
                        <Form.Group as={Col}>
                          <Form.Control value={type || ""} readOnly />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Control value={searchMonth[p[0].start_month] || ""} readOnly />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Control value={searchMonth[p[0].end_month] || ""} readOnly />
                        </Form.Group>
                        <Button onClick={(e) => deleteExamSession(e, type)} variant="danger" size="sm">
                          {" "}
                          Delete
                        </Button>
                      </Form.Row>
                    );
                  })
                : null}
              <Form.Row className="placement_session_list">
                <Form.Group as={Col}>
                  <Form.Control value={`Semester ${Object.keys(examSession).length + 1}`} readOnly />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setSemStart(JSON.parse(e.target.value).id);
                    }}
                  >
                    <option value={JSON.stringify({ id: null, value: "--select--" })}>--select--</option>
                    {monthArray.map((e, key) => {
                      return (
                        <option key={key} value={JSON.stringify(e)}>
                          {" "}
                          {e.value}{" "}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setSemEnd(JSON.parse(e.target.value).id);
                    }}
                  >
                    <option value={JSON.stringify({ id: null, value: "--select--" })}>--select--</option>
                    {monthArray.map((e, key) => {
                      return (
                        <option key={key} value={JSON.stringify(e)}>
                          {" "}
                          {e.value}{" "}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Button onClick={(e) => addExamSession(e, `Semester ${Object.keys(examSession).length + 1}`, semStart, semEnd)} variant="info" size="sm">
                  {" "}
                  Add
                </Button>
              </Form.Row>

              {/* <Form.Label>Job Segments : </Form.Label> */}
              <br />
              <Form.Group className="typeSearchSegment">
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  id="select-job-segments"
                  options={allJobSegments}
                  value={selectedJobSegments}
                  // defaultValue={data.job_segments ? data.job_segments : undefined}
                  getOptionLabel={(item) => item.job_segment_name}
                  onChange={(e, item) => (item ? setSelectedJobSegments(item) : setSelectedJobSegments([]))}
                  renderInput={(params) => <TextField {...params} label="Select Job Segments" variant="outlined" />}
                />
              </Form.Group>
              <br />

              <Form.Label>
                <i>
                  <u>Preference : </u>
                </i>
              </Form.Label>
              <Box className="typeSearchPreferences" boxShadow={2} style={{ padding: "16px 1rem", marginBottom: "1rem" }}>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-job_type"
                    options={jobTypes}
                    value={selectedJobType}
                    getOptionLabel={(item) => item.name}
                    onChange={(e, i) => (i ? setSelectedJobType(i) : setSelectedJobType({}))}
                    renderInput={(params) => <TextField {...params} label="Select Job Type" variant="outlined" />}
                  />
                </Form.Group>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-experience"
                    options={workExperiences}
                    value={selectedExperience}
                    getOptionLabel={(item) => item.name}
                    onChange={(e, i) => (i ? setSelectedExperience(i) : setSelectedExperience([]))}
                    renderInput={(params) => <TextField {...params} label="Select Work Experience" variant="outlined" />}
                  />
                </Form.Group>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-cities"
                    value={selectedCities}
                    options={allCities}
                    getOptionLabel={(item) => item.city}
                    onChange={(e, i) => (i ? setSelectedCities(i) : setSelectedCities([]))}
                    renderInput={(params) => <TextField {...params} label="Select Cities" variant="outlined" />}
                  />
                </Form.Group>
              </Box>

              <Button type="submit" variant="info">
                Save Changes
              </Button>
            </Form>
          ) : (
            <CircularProgress style={{ display: "block", margin: "auto", marginTop: "50px" }} />
          )}
        </TabPanel>

        {/* Edit Specializations */}
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Form className="edit__specializations__form" onSubmit={(e) => updateSpecializations(e)}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Education Type</Form.Label>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Degree</Form.Label>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Specialization</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row className="typeSearchCourses">
              <Form.Group as={Col} controlId="education">
                <Autocomplete
                  id="select-education"
                  options={education || []}
                  getOptionLabel={(item) => item.type}
                  onChange={(e, item) => {
                    if (item) {
                      setEducationId(item.id);
                      setEducationName(item.type);
                      setDegreeId(null);
                      setDegreeName("");
                    } else {
                      setEducationId(null);
                      setEducationName("");
                      setDegreeId(null);
                      setDegreeName("");
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={`Select Education`} variant="outlined" />}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="degree">
                <Autocomplete
                  id="select-degree"
                  options={degrees || []}
                  getOptionLabel={(item) => item.name}
                  onChange={(e, item) => {
                    if (item) {
                      setDegreeId(item.id);
                      setDegreeName(item.name);
                    } else {
                      setDegreeId(null);
                      setDegreeName("");
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={`Select Degree`} variant="outlined" />}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="specialization">
                <Autocomplete
                  id="select-specialization"
                  options={specializations || []}
                  getOptionLabel={(item) => item.specialization.name}
                  onChange={(e, item) => {
                    if (item) {
                      setSpecializationId(item.specialization.id);
                      setSpecializationName(item.specialization.name);
                    } else {
                      setSpecializationId(null);
                      setSpecializationName("");
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={`Select Specialization`} variant="outlined" />}
                />
              </Form.Group>
              <Button
                onClick={(e) => addCourse(e, educationId, degreeId, specializationId, educationName, degreeName, specializationName)}
                variant="info"
                size="sm"
                style={{ width: "10%", marginLeft: "2%", marginTop: "1%" }}
              >
                {" "}
                Add
              </Button>
            </Form.Row>
            {/* <Form.Row className="courses">
                        <Form.Group as={Col} controlId="educationType">
                            <Form.Control 
                                as="select"
                                onChange={(e)=>{
                                    setEducationId(JSON.parse(e.target.value).id)
                                    setEducationName(JSON.parse(e.target.value).type)
                                    setDegreeId(null)
                                    setDegreeName('')
                                }}
                            >
                                <option value={JSON.stringify({"id":null,"type":'--select--'})}>--select--</option>
                            {
                                education.map((e, key) => {
                                return <option key={key} value={JSON.stringify(e)}> {e.type} </option>
                                })
                            }    
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="degree">
                            <Form.Control 
                                as="select"
                                value={(!degreeId ? "--select--" : null)}
                                onChange={(e)=>{
                                    setDegreeId(JSON.parse(e.target.value).id)
                                    setDegreeName(JSON.parse(e.target.value).name)
                                }}
                            >
                                <option value={JSON.stringify({"id":null,"name":'--select--'})}>--select--</option>
                            {
                                degrees.map((d, key) => {
                                return <option key={key} value={JSON.stringify(d)}>{d.name}</option>
                                })
                            }  
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="specialization">
                            <Form.Control 
                                as="select" 
                                value={(!specializationId ? "--select--" : null)}
                                onChange={(e)=>{
                                    setSpecializationId(JSON.parse(e.target.value).specialization.id)
                                    setSpecializationName(JSON.parse(e.target.value).specialization.name)
                                }}
                            >
                                <option value={JSON.stringify({"id":null,"name":'--select--'})}>--select--</option>
                            {
                                specializations!==null ? specializations.map((s, key) => {
                                return <option key={key} value={JSON.stringify(s)}>{s.specialization.name}</option>
                                }): null
                            }  
                            </Form.Control>
                        </Form.Group>
                        <Button 
                            onClick={(e) => 
                                addCourse(e, educationId, degreeId, specializationId, educationName, degreeName, specializationName)
                            } 
                            variant="info" size="sm"
                        > Add
                        </Button>
                    </Form.Row> */}
            {courses.length > 0
              ? courses.map((course, key) => {
                  return (
                    course.is_active && (
                      <Form.Row key={key} className="courses_list">
                        <Form.Group as={Col}>
                          <Form.Control value={course.education || ""} readOnly />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Control value={course.degree || ""} readOnly />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Control value={course.specialization || ""} readOnly />
                        </Form.Group>
                        <Button onClick={() => deleteCourse(course.uid)} variant="danger" size="sm" disabled={!course.is_active}>
                          {" "}
                          Delete
                        </Button>
                      </Form.Row>
                    )
                  );
                })
              : null}
            <Button type="submit" variant="info">
              Save Changes
            </Button>
          </Form>
        </TabPanel>
      </div>
    ) : (
      <div>Data not yet set</div>
    )
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}
