import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { DateSelector } from "../../../components/common/DateSelector";
import { niyuktiAxiosPostReq, praveshAxiosCall } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import { jobCategoryList } from "../../../constants";
import CustomButtonCircularProgress from "../../loader";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  chipsection: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "0px",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "10px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function AddAdminUser(props) {
  console.log("abcd", props.selectedRowJobId);
  const classes = useStyles();
  const fullWidth = true;
  const maxWidth = "xs";

  const [loading, setLoading] = useState(false);
  const [recruiter, setRecruiter] = React.useState([]);
  //   const [selectedRecruiterList, setSelectedRecruiterList] = useState(
  //     props.preSelectedAdminUser.map(({ id, admin_name }) => ({ id, admin_name }))
  //   );
  //   const [selectedRecruiterList, props?.setSelectedRecruiterList] = useState([]);
  useEffect(() => {
    if (recruiter.length)
      props?.setSelectedRecruiterList(
        props.preSelectedAdminUser.map(({ id }) => {
          return recruiter.find((option) => option.id === id);
        })
      );
  }, [recruiter]);
  //   useEffect(
  //     () => console.log("selectedRecruiterList", selectedRecruiterList),
  //     [selectedRecruiterList]
  //   );
  useEffect(() => console.log("recruiter", recruiter), [recruiter]);

  const getRecruiterList = () => {
    praveshAxiosCall
      .get(`/api/admin/admin_user_lists/`)
      .then((res) => {
        if (res?.data?.success) {
          setRecruiter(res?.data?.data);
        } else {
          // setAllApiSuccess({status: -1, message: res.error})
        }
      })
      .catch((err) => {
        // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
      });
  };
  useEffect(() => {
    getRecruiterList();
  }, []);

  const handleClose = () => {
    props.handleClose();
    props?.setSelectedRecruiterList([]);
  };

  //   const checkValidation = () => {
  //     if (props?.selectedRecruiterList?.length === 0) {
  //       FailureAlert("Please select atleast one recruiter");
  //       return false;
  //     }
  //     return true;
  //   };

  const handleSubmit = () => {
    setLoading(true);
    // http://niyuktitest.getwork.org/job/admin/job_users/
    const payload = {
      job_id: props.selectedRowJobId,
      job_users: props?.selectedRecruiterList.map((user) => user.id),
    };
    niyuktiAxiosPostReq("/job/admin/job_users/", payload)
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert("User Admin added successfuly");
          props?.setAddUserAdminClick(true);
          props?.handleClose();
        } else {
          FailureAlert("Error in adding user admin");
        }
        setLoading(false);
        props?.setSelectedRecruiterList([]);
      })
      .catch((err) => {
        FailureAlert("Error: ", err);
        setLoading(false);
        props?.setSelectedRecruiterList([]);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        //onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6">Add/Edit Recruiter</Typography>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={props?.selectedRecruiterList}
              multiple
              onChange={(e, item) => {
                props?.setSelectedRecruiterList(item);
              }}
              id="role345"
              options={recruiter}
              getOptionLabel={(option) => `${option.id}-${option.admin_name}`}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.admin_name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}> Select Recruiters </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                disabled={loading}
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ height: "45px", display: "flex" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                style={{ height: "45px", display: "flex" }}
                onClick={() => {
                  //   if (checkValidation()) {
                  handleSubmit();
                  //   }
                }}
              >
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CustomButtonCircularProgress />
                    </div>
                  </div>
                ) : (
                  <div>Add</div>
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
