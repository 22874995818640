import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
  Select,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import React, { useEffect, useState } from "react";
import { niyuktiAxiosCall } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import LoadingOverlay from "react-loading-overlay";

import SliderSection from "./SliderSection";
import RatingDialog from "../../common/Dialog/RatingDialog";

import OpenJobs from "../OpenJobs";

export default function RateApplicant({
  jobSkills,
  handleClickOpen,
  open,
  handleClose,
  jobApplicantId,
  getApiCall,
  name,
  jobId,
  jobTitle,
  rowData,
  stepList,
  statusMoveId,
  setStatusMoveId,
  stepData,
  setStepData,
  setCallCountApi= () => {}
}) {

  const initialJobMatchingData = {
    location: false,
    salary: false,
    work_exp: false,
    education: false,
    // key_skills: 0,
    communication_skills: 0,
    individual_skills: jobSkills,
    interview_summary: "",
    generate_report: false,
  };
  const [studentJobMatchingData, setStudentJobMatchingData] = useState(
    initialJobMatchingData
  );

  const [rating, setRating] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  /* ############################################################################################################## */
  //const [statusMoveId, setStatusMoveId] = React.useState(0);
  const [rejectReason, setRejectReason] = React.useState("");

  let statusCodes = {};
  Object.keys(stepList).forEach((step) => {
    statusCodes[step] = stepList[step]["tooltip"];
  });

  if (rowData && rowData?.status_id) {
    var strRow = "";
    strRow = `(${rowData?.status_id}`;
    if (rowData?.status_id === 7) strRow += `,${rowData?.job_round_id}`;
    strRow += ")";
  }

  /* ############################################################################################################## */


  <LoadingOverlay
    active={showLoader}
    spinner
    text="Updating Details..."
  ></LoadingOverlay>;

  //getting previous set data for the dialog
  useEffect(() => {
    if (open && jobApplicantId) {
      setShowLoader(true);
      const ratedJobSkills = jobSkills;

      niyuktiAxiosCall
        .get(`/job/admin/applicant_rating/?job_applicant_id=${jobApplicantId}`)
        .then((res) => {
          if (res.data.success) {
            const data = res?.data?.data;
            
            if (data?.student_job_matching_data?.individual_skills) {
              let dataIdMap = {};
              data?.student_job_matching_data?.individual_skills.forEach(
                (item) => {
                  dataIdMap[item.skill_id] = item.value || 0;
                }
              );
              ratedJobSkills.forEach((skill) => {
                skill.value = dataIdMap[skill.skill_id] || 0;
              });
            }
            if (data?.student_job_matching_data) {
              setStudentJobMatchingData({
                ...studentJobMatchingData,
                location: data?.student_job_matching_data?.location
                  ? true
                  : false,
                salary: data?.student_job_matching_data?.salary ? true : false,
                work_exp: data?.student_job_matching_data?.work_exp
                  ? true
                  : false,
                education: data?.student_job_matching_data?.education
                  ? true
                  : false,
                key_skills: data?.student_job_matching_data?.key_skills,
                individual_skills: ratedJobSkills,
                communication_skills:
                  data?.student_job_matching_data?.communication_skills,
                interview_summary:
                  data?.student_job_matching_data?.interview_summary,
                generate_report:
                  data?.student_job_matching_data?.generate_report
              });
              setRating(data?.student_score);
            }
          } else {
            FailureAlert(res?.data?.error);
            handleClose();
          }
          setShowLoader(false);
        })
        .catch((err) => {
          handleClose();
          FailureAlert(err.message);
          setShowLoader(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleKeySkills = (e, newValue) => {
    setStudentJobMatchingData({
      ...studentJobMatchingData,
      key_skills: parseInt(newValue),
    });
  };
  const handleCommunicationSkills = (e, newValue) => {
    setStudentJobMatchingData({
      ...studentJobMatchingData,
      communication_skills: parseInt(newValue),
    });
  };

  const handleSubmit = () => {
    if (statusCodes[statusMoveId] === "Rejected" && !rejectReason) {
      FailureAlert("Enter Reason to reject Applicants...");
      return;
    }
    let updateRecords = [];

    let statId = statusMoveId.split(",")[0].substring(1);
    if (statusMoveId.split(",").length === 1) statId = statId.slice(0, -1);
    let moveRoundId = null;

    if (statusMoveId.split(",")[1] !== undefined) {
      moveRoundId = statusMoveId.split(",")[1].slice(0, -1);
    }
    updateRecords.push({
      id: rowData.applicant_id,
      round: moveRoundId ? parseInt(moveRoundId) : null,
      status: parseInt(statId),
      // feedback: null,
      user_id: rowData.user_id,
      job_id: jobId,
      company_id: rowData.company_id,
    });

    let body = { student_data: updateRecords };

    if (statusCodes[statusMoveId] === "Rejected") {
      let userData = JSON.parse(localStorage.getItem("user-data"));
      let statId = statusMoveId.split(",")[0].substring(1);
      if (statusMoveId.split(",").length === 1) statId = statId.slice(0, -1);
      let feedbackObj = {};
      feedbackObj["user_id"] = userData.id;
      feedbackObj["first_name"] = userData.first_name || "";
      feedbackObj["last_name"] = userData.last_name || "";
      feedbackObj["status_id"] = parseInt(statId);
      feedbackObj["status_name"] = statusCodes[statusMoveId];
      feedbackObj["feedback_name"] = rejectReason;
      feedbackObj["is_getwork_admin"] = true;
      body.applicant_feedback = feedbackObj;
    }

    body = {
      ...body,
      student_job_matching_data: studentJobMatchingData,
      job_applicant_id: jobApplicantId,
    };
    let dataObj = { ...stepData };
    setShowLoader(true);
    niyuktiAxiosCall
      .post(`/job/admin/applicant_rating/`, body)
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data.data.message);

          let objOld = { ...rowData };

          let objNew = {
            ...rowData,
            status_id: parseInt(statId),
            job_round_id: moveRoundId ? parseInt(moveRoundId) : null,
            student_score: res?.data?.data?.data?.student_score,
            overall_calculated_rating: res?.data?.data?.data?.student_score,
            score_job_count: res?.data?.data?.data?.score_job_count,
          };
          let strOld = "";
          strOld = `(${objOld["status_id"]}`;
          if (objOld["job_round_id"] && objOld["status_id"] === 7)
            strOld += `,${objOld["job_round_id"]}`;
          strOld += ")";

          if (statusMoveId === strOld) {
            Object.keys(dataObj)?.filter((item, i) => {
              if (item === strOld) {
                for (let j = 0; j < dataObj[item]?.data?.length; j++) {
                  if (
                    dataObj[item]?.data[j]?.applicant_id ===
                    objNew?.applicant_id
                  ) {
                    dataObj[item]?.data.splice(j, 1, objNew);
                    return;
                  }
                }
              }
            });
          }

          if (statusMoveId !== strOld) {
            Object.keys(dataObj)?.filter((item, i) => {
              if (item === strOld) {
                for (let j = 0; j < dataObj[item]?.data?.length; j++) {
                  if (
                    dataObj[item]?.data[j]?.applicant_id ===
                    objNew?.applicant_id
                  ) {
                    dataObj[item]?.data.splice(j, 1);
                    return;
                  }
                }
              }
            });
            if (dataObj[statusMoveId] === undefined) {
              dataObj[statusMoveId] = {};
              dataObj[statusMoveId]["data"] = [objNew];
              dataObj[statusMoveId]["count"] = 1;
            } else {
              dataObj[statusMoveId]["data"].unshift(objNew);
              dataObj[statusMoveId]["count"]++;
              strOld !== statusMoveId && dataObj[strOld]["count"]--;
            }
          }
          //getApiCall();
          setStepData(dataObj);
          setStudentJobMatchingData((prev) => ({
            ...prev,
            individual_skills: jobSkills.map((skill) => ({
              ...skill,
              value: 0,
            })),
          }));
          handleClose();
          setCallCountApi(true);
        } else {
          setStudentJobMatchingData((prev) => ({
            ...prev,
            individual_skills: jobSkills.map((skill) => ({
              ...skill,
              value: 0,
            })),
          }));
          FailureAlert(res.data.error);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setStudentJobMatchingData((prev) => ({
          ...prev,
          individual_skills: jobSkills.map((skill) => ({
            ...skill,
            value: 0,
          })),
        }));

        // console.log("first3", err);

        setShowLoader(false);
        handleClose();
        // FailureAlert(err.message);
      });
  };

  const handleClose1 = () => {
    setStudentJobMatchingData((prev) => ({
      ...initialJobMatchingData,
      individual_skills: jobSkills.map((skill) => ({ ...skill, value: 0 })),
    }));
    // setRateApply(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ paddingLeft: 32, paddingRight: 32 }}
        >
          Applicant Rating on Job - {jobTitle} : {jobId}
        </DialogTitle>

        <LoadingOverlay active={showLoader} spinner text="Please Wait...">
          <DialogContent style={{ paddingTop: 0 }}>
            <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
              <Grid item md={12}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 600, marginBottom: 12 }}
                >
                  Move Candidate
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  color="primary"
                  size="small"
                >
                  <InputLabel shrink htmlFor="status-native-label-placeholder">
                    Choose Status:
                  </InputLabel>
                  <Select
                    native
                    label="Choose Status:"
                    onChange={(e) => setStatusMoveId(e.target.value)}
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value={""}>Client Screening Inprocess</option>
                    {Object.keys(statusCodes).map((st, k) => {
                      return <option value={st}>{statusCodes[st]}</option>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* {rateApply && ( */}
              <RatingDialog
                studentJobMatchingData={studentJobMatchingData}
                setStudentJobMatchingData={setStudentJobMatchingData}
              />
              {/* )} */}

              {/* <>
              <Grid item md={12}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 600, marginTop: 5 }}
                >
                  Job Checklist( 0/4)
                </Typography>
                <div>
                  <FormControl component="fieldset" fullWidth>
                    <FormGroup
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={location}
                            onChange={handleChange}
                            name="location"
                          />
                        }
                        label={
                          <span style={{ fontSize: 12, color: "#6C757D" }}>
                            Location Match
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={salary}
                            onChange={handleChange}
                            name="salary"
                            size="small"
                          />
                        }
                        label={
                          <span style={{ fontSize: 12, color: "#6C757D" }}>
                            Salary Match
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={work_exp}
                            onChange={handleChange}
                            name="work_exp"
                            size="small"
                          />
                        }
                        label={
                          <span style={{ fontSize: 12, color: "#6C757D" }}>
                            Work Exp Match
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={education}
                            onChange={handleChange}
                            name="education"
                            size="small"
                          />
                        }
                        label={
                          <span style={{ fontSize: 12, color: "#6C757D" }}>
                            Education Match
                          </span>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>

              <Grid item md={12}>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Key Skills
                </Typography>
                {jobSkills.map((skill) => (
                  <div key={skill.skill_id}>
                    <SliderSection
                      id={skill.skill_id}
                      title={skill.skill_name}
                      individualSkills={individualSkills}
                      setIndividualSkills={setIndividualSkills}
                    />
                  </div>
                ))}

                <div>
                  <SliderSection title="User Experience Design" />
                </div>
              </Grid>

              <Grid item md={12}>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Communication Skills
                </Typography>
                <div style={{ marginTop: -28 }}>
                  <SliderSection />
                </div>
              </Grid>

              <Grid item md={12}>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Interview Summary
                </Typography>
                <div style={{ marginTop: 10 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Write summary about student eg “Candidate UX Skill is more than UI Skill”"
                    multiline
                    rows={7}
                    color="primary"
                  />
                </div>
              </Grid>

              <Grid item md={12}>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Do you want to generate Report ?
                </Typography>
                <div>
                  <FormControl component="fieldset">
                    <FormGroup style={{ flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox size="small" name="yes" color="primary" />
                        }
                        label={
                          <span style={{ fontSize: 12, color: "#6C757D" }}>
                            Yes
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="no" size="small" color="primary" />
                        }
                        label={
                          <span style={{ fontSize: 12, color: "#6C757D" }}>
                            No
                          </span>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>
            </> */}
            </Grid>

            {/* <div style={{ marginBottom: 10 }}>
              <Typography>
                {" "}
                {name} : {rating}/10
              </Typography>
            </div> */}
            {/* <div style={{ marginBottom: 10 }}></div>
            <div style={{ marginBottom: 20 }}>
              <Typography id="discrete-slider-custom" gutterBottom>
                Key Skills
              </Typography>
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                name="key_skills"
                marks={marks}
                min={1}
                max={10}
                value={studentJobMatchingData.key_skills}
                onChange={handleKeySkills}
              />
            </div> */}
            {/* <div style={{ marginBottom: 20 }}>
              <Typography id="discrete-slider-custom" gutterBottom>
                Communication Skills
              </Typography>
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                min={1}
                max={10}
                value={studentJobMatchingData.communication_skills}
                onChange={handleCommunicationSkills}
              />
            </div> */}

            {statusCodes[statusMoveId] === "Rejected" && (
              <FormControl
                /* className={classes.formControl} */ style={{
                  marginLeft: "10px",
                }}
              >
                <TextField
                  //className={classes.textField}
                  id="reject-reason"
                  label="Reason To Reject"
                  onChange={(e) => setRejectReason(e.target.value)}
                />
              </FormControl>
            )}
          </DialogContent>
        </LoadingOverlay>
        <DialogActions
          style={{ paddingLeft: 32, paddingRight: 32, paddingBottom: 15 }}
        >
          <Button onClick={handleClose1} color="primary" disabled={showLoader}>
            <span style={{ color: "#6C757D" }}> Cancel </span>
          </Button>
          <Button
            // onClick={handleClose}
            color="primary"
            disabled={showLoader}
            variant="contained"
            style={{ boxShadow: "none" }}
            onClick={() => handleSubmit()}
          >
            {showLoader ? (
              <CircularProgress
                style={{
                  color: "white",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              "Rate And Move"
            )}
          </Button>
          {/* <Button
            onClick={
              !rateApply
                ? () => {
                    handleSubmit();
                  }
                : handleClose1
            }
            color="primary"
          >
            {!rateApply ? "Apply" : "Cancel"}
          </Button>
          <Button
            onClick={handleClose} 
            color="primary"
            onClick={
              !rateApply
                ? () => setRateApply(true)
                : () => {
                    handleSubmit();
                    setRateApply(false);
                  }
            }
          >
            Apply And Rate
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
