import Axios from "axios";
import {
  niyuktiUrl,
  sanchalanUrl,
  url,
  praveshUrl,
  gptUrl,
} from "../constants";

export const apiCall = (path, requestOptions) => {
  return fetch(`${url}${path}`, requestOptions)
    .then((res) => res.json())
    .catch((err) => (err instanceof TypeError ? console.log(err) : alert(err)));
};

export const apiNiyuktiCall = (path, requestOptions) => {
  return fetch(`${niyuktiUrl}${path}`, requestOptions)
    .then((res) => res.json())
    .catch((err) => (err instanceof TypeError ? console.log(err) : alert(err)));
};

export const apiSanchalanCall = (path, requestOptions) => {
  return fetch(`${sanchalanUrl}${path}`, requestOptions)
    .then((res) => res.json())
    .catch((err) => (err instanceof TypeError ? console.log(err) : alert(err)));
};

export const apiPraveshCall = (path, requestOptions) => {
  return fetch(`${praveshUrl}${path}`, requestOptions)
    .then((res) => res.json())
    .catch((err) => (err instanceof TypeError ? console.log(err) : alert(err)));
};

export const praveshAxiosCall = Axios.create({
  baseURL: url,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
});

export const niyuktiAxiosCall = Axios.create({
  baseURL: niyuktiUrl,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
});
export const niyuktiAxiosGetReq = function (
  url,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.get(`${niyuktiUrl}${url}`, { headers });
};

export const niyuktiAxiosPostReq = function (
  url,
  data,
  headers = {
    // withCredentials: true,
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.post(`${niyuktiUrl}${url}`, data, { headers });
};

export const niyuktiAxiosPatchReq = function (
  url,
  data,
  headers = {
    // withCredentials: true,
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.patch(`${niyuktiUrl}${url}`, data, { headers });
};

export const gptAxiosGetReq = function (
  url,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.get(`${gptUrl}${url}`, { headers });
};

export const gptAxiosPostReq = function (
  url,
  data,
  headers = {
    // withCredentials: true,
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.post(`${gptUrl}${url}`, data, { headers });
};

export const sanchalanAxiosCall = Axios.create({
  baseURL: sanchalanUrl,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
});

export const praveshAxiosGetReq = function (
  endPoint,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.get(`${url}${endPoint}`, { headers });
};

export const praveshAxiosDeleteReq = function (
  endPoint,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.delete(`${url}${endPoint}`, { headers });
};

export const praveshAxiosPostReq = function (
  endPoint,
  data,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.post(`${url}${endPoint}`, data, { headers });
};

export const praveshAxiosPatchReq = function (
  endPoint,
  data,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  }
) {
  return Axios.patch(`${url}${endPoint}`, data, { headers });
};
