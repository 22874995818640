import React from "react";
import Charst from "react-apexcharts";

function GraphWithTable({ color }) {
  const options = {
    xaxis: {
      title: {
        text: "Dates",
        style: {
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      categories: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
    chart: {
      // id: DATA?.heading?.split(" ")?.join("_")?.replaceAll("(%)", "_Percent"),
      toolbar: {
        export: {
          csv: {
            headerCategory: "vnbn",
          },
        },
      },
    },

    colors: [color],
    markers: {
      size: 3,
      colors: [color],
      hover: {
        size: 4,
      },
    },

    dataLabels: {
      enabled: false,
      offsetY: -20,
      style: {
        fontSize: "10px",
        fontFamily: "Roboto",
        colors: ["#304758"],
      },
    },

    yaxis: {
      title: {
        text: "Total Lineup",
        style: {
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      categories: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],

      // labels: {
      //   formatter: function (value) {
      //     return Math.round(value); // Rounds the value to an integer
      //   },
      // },
    },
    plotOptions: {
      area: {
        horizontal: false,
        borderRadius: 8,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    fill: {
      gradient: {
        enabled: false,
        opacityFrom: 0,
        opacityTo: 0,
      },
    },
    stroke: {
      width: 1,
    },
    title: {
      //text: "Footfall Graph (29/05/2024 to 03/06/2024)",
      style: {
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 1000,
        cssClass: "apexcharts-yaxis-title",
      },
    },
    grid: {
      clipMarkers: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const series = [
    {
      name: "series-1",
      data: [
        30, 40, 25, 50, 49, 21, 70, 51, 30, 40, 25, 50, 49, 21, 70, 51, 30, 40,
        25, 50, 49, 21, 70, 51, 30, 40, 25, 50, 49, 21, 70,
      ],
    },
  ];
  return (
    <div>
      <Charst options={options} series={series} type="area" height={320} />
    </div>
  );
}

export default GraphWithTable;
