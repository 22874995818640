import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
//import { useSelector } from "react-redux";
import { ReactComponent as Vactor } from "../../../assets/svg/customNoSearch.svg";
//import SearchJob from "../../Common/InputText/SearchJob";
import { QuerySource } from "./QuerySource";
import SearchJob from "./SearchJob";
const infoitem = {
  display: "flex",
  flexDirection: "row",
  alignContent: "space-around",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: "10px",
};

const info_icon = {
  color: "#6c757d",
  height: "28px",
  marginRight: "10px",
};
const detail = {
  display: "flex",
  flexWrap: "wrap",
  // flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  alignContent: "center",
};

function SourceJob({
  value,
  setShowSourceResult,
  handleSubmitQuery,
  setSourceFromJob,
  setJobFromSourceJob,
  sourceFromJob,
  searchSelected,
  setSearchSelected,
  submitLoading,
}) {
  // const companyId = useSelector(
  //   (state) => state?.userDetails?.userDetails?.user_details?.company
  // );
  const initialState = {
    jobSegments: [],
    jobRoles: [],
    expLevels: [],
    expCtc: null,
    noticePeriods: [],
    locations: [],
    eduLevels: [],
    degrees: [],
    clgCategories: [],
  };
  const [loading, setLoading] = useState(false);
  const [expCtc, setExpCtc] = useState([0, 0]);

  const generateSourcefromJobQuery = () => {
    return {
      jobSegments: sourceFromJob?.job_segment && [
        { job_segment_name: sourceFromJob?.job_segment },
      ],
      jobRoles: sourceFromJob?.job_role && [
        { job_role_group: sourceFromJob?.job_role_group_name },
      ],
      expLevels: sourceFromJob?.experience && [
        { title: sourceFromJob?.experience },
      ],
      expCtc: sourceFromJob?.ctc,
      // expCtc: [
      //   parseInt(sourceFromJob?.ctc?.split("-")[0]?.split(" ")[0]),
      //   parseInt(sourceFromJob?.ctc?.split("-")[1]?.split(" ")[1]),
      // ],
      noticePeriods: sourceFromJob?.notice_period && [
        sourceFromJob?.notice_period,
      ],
      locations: sourceFromJob?.location && [{ city: sourceFromJob?.location }],
      eduLevels: sourceFromJob?.education_level && [
        { name: sourceFromJob?.education_level },
      ],
      degrees: sourceFromJob?.degree,
      skills: sourceFromJob?.skill,
      clgCategories: sourceFromJob?.college_category && [
        { category: sourceFromJob?.college_category },
      ],

      payload: sourceFromJob,
    };
  };

  const theme = useTheme();
  return (
    <>
      <Box style={{ width: "60%", marginTop: 20 }}>
        <SearchJob
          setSourceFromJob={setSourceFromJob}
          setJobFromSourceJob={setJobFromSourceJob}
          setLoading={setLoading}
          searchSelected={searchSelected}
          setSearchSelected={setSearchSelected}
        />
        {sourceFromJob ? (
          <>
            <Box
              style={{
                boxShadow: "0px 0px 5px 2px #00000017",
                borderRadius: "8px",
                padding: 20,
                marginTop: 20,
              }}
            >
              <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                Get applicants for posted jobs
              </Typography>
              <div style={{ marginTop: "10px" }}>
                <QuerySource
                  data={generateSourcefromJobQuery()}
                  sourceResults={"source"}
                  from="sourceJob"
                />
              </div>
            </Box>
            <Button
              variant="contained"
              color="primary"
              style={{
                borderRadius: "8px",
                height: "40px",
                boxShadow: "none",
                marginTop: 20,
                minWidth: "200px",
              }}
              onClick={() => {
                handleSubmitQuery(generateSourcefromJobQuery(), "jobQuery");
              }}
            >
              {submitLoading ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                "Submit"
              )}
            </Button>{" "}
          </>
        ) : (
          <> </>
        )}
      </Box>
      {!sourceFromJob && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: 500,
              // boxShadow: "0px 0px 5px 2px #00000017",
              //    borderRadius: "8px",
              // p: 2,
              marginTop: 2,
              // ml: 5,
              // backgroundColor: "#fff",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                aligncontent: "center",
              }}
            >
              <Vactor
                style={{ height: "200px" }}
                fill={theme.palette.primary.main}
              />
              {
                /* <img
                src={vactor}
                height="200px"
                /*       style={{ marginTop: "40px" }} */
                // /> */
              }
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                // height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  /* color: "#12DC00", */ fontWeight: "600",

                  marginTop: 2,
                }}
              >
                Type And Select A Posted Jobs Title...
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#6C757D",
                  padding: "20px 10px 0px 10px",
                  textAlign: "center",
                }}
              >
                AI Powered candidate sourcing for end-to-end recruitment
                solutions helping businesses to build cost effective hiring
                processes in no time.
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#6C757D",
                  textAlign: "center",
                }}
              >
                We focus on candidate expertise & recruiters productivity.
              </Typography>
              {/* <Link
                to="group_FAQs/companies-2"
                style={{ textDecoration: "none" }}
              > */}
              {/* <Button
                variant="contained"
                color="primary"
                sx={{
                   borderRadius: "8px",
                  height: "40px",
                  boxShadow: "none",
                  mt: 2,
                  minWidth: "200px",
                }}
                onClick={() => {
                  window.open("group_FAQs/companies-2", "_blank");
                }}
              >
                Learn How It Works ?
              </Button>{" "} */}
              {/* </Link> */}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default SourceJob;

const data = [
  {
    heading: "Average CTC:",
    result: "16 LPA",
  },

  {
    heading: "Type:",
    result: "Government",
  },
  {
    heading: "College Size:",
    result: "400-700 Students",
  },
  {
    heading: "Website:",
    result: "www.delhiuniversity.com",
  },
  {
    heading: "Founded Year:",
    result: "1978",
  },
];
