import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
  Select,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import React, { useEffect, useState } from "react";
import { niyuktiAxiosCall } from "../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import LoadingOverlay from "react-loading-overlay";
import SliderSection from "../../Jobs/Dialog/SliderSection";

function RatingDialog({ studentJobMatchingData, setStudentJobMatchingData }) {
  const handleChange = (event) => {
    setStudentJobMatchingData({
      ...studentJobMatchingData,
      [event.target.name]: event.target.checked,
    });
    handleChangeChecklist(event);
  };

  const { location, salary, work_exp, education } = studentJobMatchingData;

  const [Location, setLocation] = useState(location);
  const [Salary, setSalary] = useState(salary);
  const [WorkExp, setWorkExp] = useState(work_exp);
  const [Education, setEducation] = useState(education);

  useEffect(() => {
    setLocation(location);
    setSalary(salary);
    setWorkExp(work_exp);
    setEducation(education);
  }, [location, salary, work_exp, education]);

  console.log(Location, Salary, WorkExp);

  const handleChangeChecklist = (event) => {
    const { name, checked } = event.target;

    switch (name) {
      case "location":
        setLocation(checked);
        break;
      case "salary":
        setSalary(checked);
        break;
      case "work_exp":
        setWorkExp(checked);
        break;
      case "education":
        setEducation(checked);
        break;
      default:
        break;
    }
  };

  // Calculate the number of checkboxes checked
  const checkedCount = [Location, Salary, WorkExp, Education].filter(
    Boolean
  ).length;

  return (
    <>
      <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: 600, marginTop: 5 }}>
            ({checkedCount}/4)
          </Typography>
          <div>
            <FormControl component="fieldset" fullWidth>
              <FormGroup
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={location}
                      onChange={handleChange}
                      name="location"
                    />
                  }
                  label={
                    <span style={{ fontSize: 12, color: "#6C757D" }}>
                      Location Match
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={salary}
                      onChange={handleChange}
                      name="salary"
                      size="small"
                    />
                  }
                  label={
                    <span style={{ fontSize: 12, color: "#6C757D" }}>
                      Salary Match
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={work_exp}
                      onChange={handleChange}
                      name="work_exp"
                      size="small"
                    />
                  }
                  label={
                    <span style={{ fontSize: 12, color: "#6C757D" }}>
                      Work Exp Match
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={education}
                      onChange={handleChange}
                      name="education"
                      size="small"
                    />
                  }
                  label={
                    <span style={{ fontSize: 12, color: "#6C757D" }}>
                      Education Match
                    </span>
                  }
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>

        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Key Skills
          </Typography>
          {studentJobMatchingData?.individual_skills?.length &&
            studentJobMatchingData?.individual_skills.map((skill, index) => (
              <div key={skill.skill_id}>
                <SliderSection
                  //   id={skill.skill_id}
                  index={index}
                  title={skill.skill_name}
                  //   individualSkills={individualSkills}
                  //   setIndividualSkills={setIndividualSkills}
                  setStudentJobMatchingData={setStudentJobMatchingData}
                  studentJobMatchingData={studentJobMatchingData}
                  initialValue={skill.value || 0}
                  isKeySkill={1}
                />
              </div>
            ))}
        </Grid>

        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Communication Skills
          </Typography>
          <div style={{ marginTop: -28 }}>
            <SliderSection
              index={0}
              initialValue={studentJobMatchingData?.communication_skills}
              title={""}
              studentJobMatchingData={studentJobMatchingData}
              setStudentJobMatchingData={setStudentJobMatchingData}
              isKeySkill={0}
            />
          </div>
        </Grid>

        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Interview Summary
          </Typography>
          <div style={{ marginTop: 10 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Write summary about student eg “Candidate UX Skill is more than UI Skill”"
              multiline
              value={studentJobMatchingData?.interview_summary}
              rows={7}
              color="primary"
              onChange={(event) => {
                const value = event.target.value;
                setStudentJobMatchingData((prev) => ({
                  ...prev,
                  interview_summary: value,
                }));
              }}
            />
          </div>
        </Grid>

        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Do you want to generate Report ?
          </Typography>
          <div>
            <FormControl component="fieldset">
              <FormGroup style={{ flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="yes"
                      checked={studentJobMatchingData.generate_report}
                      color="primary"
                      onChange={() => {
                        setStudentJobMatchingData((prev) => ({
                          ...prev,
                          generate_report: true,
                        }));
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: 12, color: "#6C757D" }}>Yes</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="no"
                      size="small"
                      color="primary"
                      checked={!studentJobMatchingData.generate_report}
                      onChange={() => {
                        setStudentJobMatchingData((prev) => ({
                          ...prev,
                          generate_report: false,
                        }));
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: 12, color: "#6C757D" }}>No</span>
                  }
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>

      {/* {statusCodes[statusMoveId] === "Rejected" && (
        <FormControl
          style={{
            marginLeft: "10px",
          }}
        >
          <TextField
            id="reject-reason"
            label="Reason To Reject"
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </FormControl>
      )} */}
    </>
  );
}

export default RatingDialog;
