import DateFnsUtils from "@date-io/date-fns";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { praveshAxiosCall } from "../../../../util/apiCall";
import { FailureAlert } from "../../../../util/Notifications";
import { educationTypes } from "../../../../util/utility";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingBottom: "15px",
    paddingTop: "5px",
  },

  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  textField: {
    width: "100%",
  },
  comboOptions: {
    fontSize: "13px",
    padding: "6.5px 11px",
  },
  RedStar: {
    color: "red",
  },
}));

export default function EducationForm({
  editEducation,
  setEditEducation,
  collegeList,
  boardsList,
  collegeNotListed,
  setCollegeNotListed,
  isCGPA,
  setIsCgpa,
}) {
  const classes = useStyles();
  const [degreeList, setDegreeList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  // const [isCGPA, setIsCgpa] = useState(false);
  useEffect(() => {
    setIsCgpa(editEducation?.is_grade_system);
  }, [editEducation?.is_grade_system]);

  const checkCollege = () => {
    if (
      editEducation?.college &&
      editEducation?.college !== 142 &&
      editEducation?.is_onboarded
    )
      return `&college_id=${editEducation.college}`;
    else return `&college_id=142`;
  };

  const getCollegeCheck = () => {
    return editEducation?.college && editEducation?.college !== 142
      ? editEducation?.college
      : null;
  };

  useEffect(() => {
    if (editEducation?.education && editEducation?.education > 2) {
      praveshAxiosCall
        .get(
          `/api/education/get_degree?education_level=${
            editEducation.education
          }${checkCollege()}`
        )
        .then((res) => {
          if (res.data.success) setDegreeList(res.data.data);
          else FailureAlert(res.data.error);
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEducation.college, editEducation.education]);

  useEffect(() => {
    if (editEducation.degree_id) {
      praveshAxiosCall
        .get(
          `/api/education/get_specialization?degree_id=${
            editEducation.degree_id === -1 ? "" : editEducation.degree_id
          }${checkCollege()}`
        )
        .then((res) => {
          if (res.data.success) setSpecializationList(res.data.data);
          else FailureAlert(res.data.error);
        })
        .catch((err) => {
          console.log(err);
          FailureAlert(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEducation.degree_id]);

  const [specializationName, setSpecializationName] = useState("");

  return (
    <>
      <div>
        <div className={classes.overview}>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  value={{
                    education_id: editEducation.education,
                    education_name: editEducation?.highest_qualification,
                  }}
                  openOnFocus
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setEditEducation({
                        ...editEducation,
                        education: newValue?.education_id,
                        highest_qualification: newValue?.education_name,
                        degree_id: null,
                        degree: null,
                        specialization_id: null,
                        specialization_name: null,
                      });
                    }
                  }}
                  id="role345"
                  options={educationTypes}
                  getOptionLabel={(option) => option.education_name}
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option.education_name}`}</span>
                    );
                  }}
                  className={classes.fieldStyling1}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={
                          <span style={{ fontSize: 13 }}>
                            Level of Education{" "}
                            <span className={classes.RedStar}>*</span>
                          </span>
                        }
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>

              {editEducation.education > 2 && (
                <>
                  {collegeNotListed ? (
                    <TextField
                      className={classes.textField}
                      label="Enter College Name"
                      size="small"
                      variant="outlined"
                      id="job-title"
                      value={editEducation?.temp_college_name}
                      onChange={(event) => {
                        setEditEducation({
                          ...editEducation,
                          temp_college_name: event.target.value,
                          college: null,
                          college_name: null,
                          is_saas: null,
                        });
                      }}
                    />
                  ) : (
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        // value={editEducation.college}
                        openOnFocus
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setEditEducation({
                              ...editEducation,
                              college: newValue?.college_id,
                              college_name: newValue?.college_name,
                              is_grade_system: newValue?.is_grade_system,
                              is_onboarded: newValue?.is_onboarded,
                              degree_id: null,
                              degree: null,
                              specialization_id: null,
                              specialization: null,
                              is_saas: newValue?.is_saas,
                            });
                          }
                        }}
                        id="role345"
                        options={collegeList}
                        getOptionLabel={(option) => option.college_name}
                        renderOption={(option) => {
                          return (
                            <span
                              style={{ fontSize: 13 }}
                            >{`${option.college_name}`}</span>
                          );
                        }}
                        className={classes.fieldStyling1}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Select College (Current: ${
                              editEducation?.college_name || `NONE`
                            })`}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={collegeNotListed ? true : false}
                          onChange={() => {
                            if (collegeNotListed) {
                              setEditEducation({
                                ...editEducation,
                                degree_id: null,
                                degree: null,
                                specialization_id: null,
                                specialization: null,
                                college: 142,
                              });
                            }
                            setCollegeNotListed(!collegeNotListed);
                          }}
                          name="checkedG"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          College not listed
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      disabled={editEducation?.education ? false : true}
                      // value={editEducation.degree_id}
                      freeSolo
                      openOnFocus
                      onChange={(e, item) => {
                        if (item) {
                          setEditEducation({
                            ...editEducation,
                            degree_name: item?.name || item,
                            degree_id: item?.id || -1,
                            specialization_id: null,
                            specialization: null,
                          });
                        }
                      }}
                      // onInputChange={(event, newValue) => {
                      //   if (newValue) {
                      //     setEditEducation({
                      //       ...editEducation,
                      //       degree_name: newValue?.name || newValue,
                      //       degree_id: newValue?.id || -1,
                      //       specialization_id: null,
                      //       specialization: null,
                      //     });
                      //   }
                      // }}
                      onInputChange={(event, newValue) => {
                        if (!newValue) {
                          // If the field is cleared, reset the state values
                          setEditEducation({
                            ...editEducation,
                            degree_name: null, // Reset the degree_name
                            degree_id: null, // Reset the degree_id
                            specialization_id: null,
                            specialization: null,
                          });
                        } else {
                          // If a new value is typed, update the state accordingly
                          setEditEducation({
                            ...editEducation,
                            degree_name: newValue?.name || newValue,
                            degree_id: newValue?.id || -1,
                            specialization_id: null,
                            specialization: null,
                          });
                        }
                        setSpecializationName("");
                      }}
                      id="role345"
                      options={degreeList}
                      getOptionLabel={(option) => option.name || option}
                      renderOption={(option) => {
                        return (
                          <span
                            style={{ fontSize: 13 }}
                          >{`${option?.name}`}</span>
                        );
                      }}
                      className={classes.fieldStyling1}
                      // renderInput={(params) => {
                      //   params.inputProps.className = classes.comboOptions;
                      //   return (
                      //     <TextField
                      //       {...params}
                      //       label={
                      //         <span style={{ fontSize: 13 }}>
                      //           Select Degree <span className={classes.RedStar}>*</span>
                      //         </span>
                      //       }
                      //       variant="outlined"
                      //       // error={educationDetailsErrors.education_level !== "" ? true : false}
                      //       // helperText={
                      //       //   <span style={educationDetailsErrors.education_level !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                      //       //     {educationDetailsErrors.education_level}
                      //       //   </span>
                      //       // }
                      //     />
                      //   );
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Select Degree (Current: ${
                            editEducation?.degree_name ||
                            editEducation?.degree ||
                            `NONE`
                          })`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      value={specializationName}
                      freeSolo
                      disabled={editEducation?.degree_id ? false : true}
                      // value={editEducation.specialization_id}
                      openOnFocus
                      onChange={(e, item) => {
                        if (item) {
                          setEditEducation({
                            ...editEducation,
                            specialization_name:
                              item.specialization_name || item,
                            specialization_id: item?.specialization_id || -1,
                          });
                        }
                      }}
                      // onInputChange={(event, newValue) => {
                      //   if (newValue) {
                      //     setEditEducation({
                      //       ...editEducation,
                      //       specialization_name:
                      //         newValue.specialization_name || newValue,
                      //       specialization_id:
                      //         newValue?.specialization_id || -1,
                      //     });
                      //   }
                      // }}
                      onInputChange={(event, newValue) => {
                        if (!newValue) {
                          setEditEducation({
                            ...editEducation,
                            specialization_name: null, // Reset the specialization_name
                            specialization_id: null, // Reset the specialization_id
                          });
                        } else {
                          setSpecializationName(
                            newValue.specialization_name || newValue
                          );
                          // If a new value is typed, update the state accordingly for specialization
                          setEditEducation({
                            ...editEducation,
                            specialization_name:
                              newValue.specialization_name || newValue,
                            // specializationName,
                            specialization_id:
                              newValue?.specialization_id || -1,
                          });
                        }
                      }}
                      id="role345"
                      options={specializationList}
                      getOptionLabel={(option) =>
                        option.specialization_name || option
                      }
                      renderOption={(option) => {
                        return (
                          <span
                            style={{ fontSize: 13 }}
                          >{`${option.specialization_name}`}</span>
                        );
                      }}
                      className={classes.fieldStyling1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Select Specialization (Current: ${
                            editEducation?.specialization_name ||
                            editEducation?.specialization ||
                            `NONE`
                          })`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editEducation?.is_current ? true : false}
                          onChange={(e) => {
                            setEditEducation({
                              ...editEducation,
                              is_current: e.target.checked,
                            });
                          }}
                          name="checkedA"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          Is this your highest qualification
                        </Typography>
                      }
                    />
                  </Grid>
                </>
              )}
              {editEducation.education <= 2 && (
                <>
                  <TextField
                    className={classes.textField}
                    label="Enter School Name"
                    size="small"
                    variant="outlined"
                    id="job-title"
                    value={editEducation?.temp_college_name}
                    onChange={(event) => {
                      setEditEducation({
                        ...editEducation,
                        temp_college_name: event.target.value,
                      });
                    }}
                  />
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      freeSolo
                      // value={editEducation.college}
                      openOnFocus
                      onChange={(e, item) => {
                        if (item) {
                          setEditEducation({
                            ...editEducation,
                            board_name: item.name || item,
                            board: item?.id || -1,
                          });
                        }
                      }}
                      onInputChange={(event, newValue) => {
                        if (newValue) {
                          setEditEducation({
                            ...editEducation,
                            board_name: newValue.name || newValue,
                            board: newValue?.id || -1,
                          });
                        }
                      }}
                      id="role345"
                      options={boardsList}
                      getOptionLabel={(option) => option?.name || option}
                      renderOption={(option) => {
                        return (
                          <span
                            style={{ fontSize: 13 }}
                          >{`${option.name}`}</span>
                        );
                      }}
                      className={classes.fieldStyling1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Select Board* (Current: ${
                            editEducation?.board_name || `NONE`
                          })`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {editEducation?.is_saas && (
                <>
                  <Grid
                    item
                    xs={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Enrollment Id
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      className={classes.textField}
                      label={"Enter Enrollment Id"}
                      required
                      size="small"
                      variant="outlined"
                      id="custom-css-outlined-input"
                      onChange={(e) => {
                        setEditEducation({
                          ...editEducation,
                          enrollment_id: e.target.value,
                        });
                      }}
                      value={editEducation?.enrollment_id}
                    />
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" className={classes.font}>
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="outlined"
                    format="MM/yyyy"
                    openTo="year"
                    margin="normal"
                    views={["year", "month"]}
                    // format="MM/yyyy"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    defaultValue="MM/YYYY"
                    label="Start Date"
                    value={
                      editEducation?.start_date
                        ? editEducation?.start_date
                        : null
                    }
                    onChange={(date, value) => {
                      setEditEducation({ ...editEducation, start_date: date });
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" className={classes.font}>
                  End Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="outlined"
                    format="MM/yyyy"
                    openTo="year"
                    margin="normal"
                    views={["year", "month"]}
                    // format="MM/yyyy"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    defaultValue="MM/YYYY"
                    label="End Date"
                    value={
                      editEducation?.end_date ? editEducation?.end_date : null
                    }
                    onChange={(date, value) => {
                      setEditEducation({ ...editEducation, end_date: date });
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid
                item
                xs={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {isCGPA ? "CGPA" : "Percentage"}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  className={classes.textField}
                  label={isCGPA ? "Enter Your CGPA" : "Enter Your Percentage"}
                  required
                  size="small"
                  //   InputProps={{
                  //     className: classes.input,
                  //   }}
                  variant="outlined"
                  id="custom-css-outlined-input"
                  type="number"
                  onChange={(e) => {
                    if (isCGPA)
                      setEditEducation({
                        ...editEducation,
                        cgpa: e.target.value,
                      });
                    else
                      setEditEducation({
                        ...editEducation,
                        percentage: e.target.value,
                      });
                  }}
                  value={
                    isCGPA ? editEducation?.cgpa : editEducation?.percentage
                  }
                />
              </Grid>
              {!isCGPA && (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      // marginBottom: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        width: "90%",
                        color: "#b0b6ba",
                      }}
                    >
                      Please convert CGPA to Percentage with your college grade
                      system.
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
