import { Checkbox, FormControlLabel } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CloudDownloadOutlined } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useState } from "react";
import newIcon from "../../../../assets/svg/Jobhunt.svg";
import pdficon from "../../../../assets/svg/pdf.svg";
import wordicon from "../../../../assets/svg/word.svg";
import { praveshAxiosCall } from "../../../../util/apiCall";
import ResumeView from "../Dialog/ResumeView";

const Pdficon = <img src={pdficon} height="25px" />;
const Wordicon = <img src={wordicon} height="25px" />;
// const NewIcon = <img src={newIcon} height="25px" />;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 300,
        borderRadius: "10px",
        //height: "255px",
        cursor: "pointer",
        boxShadow: "0px 0px 4px 2px #0000001A",
        // "&:hover": {
        //   border: `3.5px solid ${theme.palette.primary.main}`,
        // },
    },
    rootSelected: {
        maxWidth: 300,
        borderRadius: "10px",
        boxShadow: "none",
        cursor: "pointer",

        border: `3.5px solid ${theme.palette.primary.main}`,
    },
    media: {
        height: "10rem",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        border: "1px solid #b0b7c3 ",
        // border: "1px solid",
        paddingTop: "56.25%", // 16:9
        marginRight: "1rem",
        // "&:hover": {
        //   borderBottom: "1px solid #b0b7c3 ",
        //   borderTop: "none",
        //   borderRight: "none",
        //   borderLeft: "none",
        // },
    },
    mediaSelected: {
        height: "195px",
        borderRadius: "10px",
        // border: "1px solid",
        paddingTop: "100%", // 16:9
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
    },
    // cardsContainer:{
    //   display: 'grid',

    //   gridTemplateColumns: 'repeat(3, 1fr)',

    //   gridAutoRows: 'auto',

    //   gridGap: '1rem',

    // },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    checkbox: {
        //width: "100%",
        // marginLeft: "160px",
        // marginTop: "-195px",
        // marginTop: "-97%",
        // marginLeft: "15%",
        // marginRight: "15%",
        [theme.breakpoints.down("md")]: {
            marginTop: "-148px",
            marginLeft: "115px",
        },
    },
}));

const ResumeCardsView = ({
    resumedata,
    setRemoveResumes,
    removeResumes,
    setTagsRefreshed,
    totalResumeCardsData,
    mapped,
    checkedTag
}) => {

    const [thumbnail, setThumbnail] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(resumedata);
    const [file, setFileId] = useState(resumedata?.file);

    useEffect(() => {
        // this resets resume when mapped resumes are toggled.
        setData(resumedata);
    }, [file]);

    let toggleData = {
        currentCardIndex: totalResumeCardsData?.findIndex(
            (item) => item?.id === resumedata?.id
        ),
        totalTags: totalResumeCardsData?.length,
    };

    const [toggleCardsData, setToggleCardsData] = useState(toggleData);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const getThumbNail = () => {
        if (data?.thumbnail) {
            praveshAxiosCall
                .get(`${data?.thumbnail}`, { responseType: "arraybuffer" })
                .then((res) => {
                    const blob = new Blob([res?.data], { type: "image/jpeg" });

                    const blobUrl = URL.createObjectURL(blob);

                    const fileReaderInstance = new FileReader();
                    fileReaderInstance.readAsDataURL(blob);
                    fileReaderInstance.onloadend = () => {

                        const base64data = fileReaderInstance.result;
                        // console.log(base64data);
                        // setThumbnail({ uri: base64data });
                        setThumbnail(base64data);
                    };
                });
        }

        // !   convertion for image type file:

        const validateImageFileType = () => {

            if (data?.file?.includes("jpg") || data?.file?.includes("png") || data?.file?.includes("jpeg")) {
                return true
            } else if (data?.file?.includes("JPG") || data?.file?.includes("PNG") || data?.file?.includes("JPEG")) {
                return true
            } else {
                return false
            };

        }

        if (validateImageFileType()) {
            praveshAxiosCall
                .get(`${data?.file}`, { responseType: "arraybuffer" })
                .then((res) => {
                    const blob = new Blob([res?.data], { type: "image/jpeg" });

                    const blobUrl = URL.createObjectURL(blob);

                    const fileReaderInstance = new FileReader();
                    fileReaderInstance.readAsDataURL(blob);
                    fileReaderInstance.onloadend = () => {
                        const base64data = fileReaderInstance.result;
                        // console.log(base64data);
                        // setThumbnail({ uri: base64data });
                        setThumbnail(base64data);
                    };
                });
        }
    };

    useEffect(() => {
        getThumbNail();
    }, []);

    useEffect(() => {
        getThumbNail();
        setData(resumedata);

    }, [data, file]);


    const classes = useStyles();
    const [checked, setChecked] = useState(false);

    // we are collecting the id's for the cards to be removed.
    const handleChange = (e) => {
        setChecked(e?.target?.checked);
        if (e?.target?.checked) {
            let temp = [];
            temp.push(data?.id);
            temp = temp.concat(removeResumes);
            setRemoveResumes(temp);
        } else {
            let temp = removeResumes?.filter((item) => item !== data?.id);
            // let temp = removeResumes?.filter(function (e) { return e !== data?.id });
            setRemoveResumes(temp);
        }
    };

    // for checking together all different resumeViewCards
    useEffect(() => {

        if (checkedTag) {
            setChecked(true);
        } else {
            setChecked(false);

        }
    }, [checkedTag]);

    const thumbnailStyle = (thumbnail) => {
        if (thumbnail)
            return {
                position: "relative",
            };
        else
            return {
                position: "relative",
                backgroundSize: "80px",
            };
    };

    const getFileIcon = () => {
        const fileName = data?.file;
        if (fileName?.includes("pdf")) return Pdficon;
        if (fileName?.includes("PDF")) return Pdficon;
        if (fileName?.includes("docx") || fileName?.includes("doc")) return Wordicon;
        if (fileName?.includes("DOCX") || fileName?.includes("DOC")) return Wordicon;
        // return NewIcon;
        return null;
    };

    const getImage = (thumbnail) => {

        const fileName = data?.file;
        if (thumbnail) return `${thumbnail}`;
        if (fileName?.includes("docx") || fileName?.includes("doc"))
            return wordicon;
        if (fileName?.includes("DOCX") || fileName?.includes("DOC"))
            return wordicon;

        return <Skeleton variant="rect" animation="wave" height="167px" />;
    };

    const getSkeleton = () => {
        if (data?.file?.includes("docx") || data?.file?.includes("doc") || data?.file?.includes("DOCX") || data?.file?.includes("DOC"))
            return (
                <CardMedia
                    onClick={() => handleClickOpen()}
                    className={classes.mediaSelected}
                    image={getImage(thumbnail)}
                    title={""}
                    style={thumbnailStyle(thumbnail)}
                ></CardMedia>
            );
        return <Skeleton variant="rect" animation="wave" height="195px" />;
    };

    return (
        <>
            <Card className={classes.root}>
                {/* thumbnail is only present for pdf */}
                {thumbnail ? (
                    <CardMedia
                        onClick={() => handleClickOpen()}
                        className={classes.mediaSelected}
                        image={getImage(thumbnail)}
                        title={""}
                        style={thumbnailStyle(thumbnail)}
                    ></CardMedia>
                ) : (
                    getSkeleton()
                )}
                <CardContent style={{ padding: "4px" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "6px",
                            // gap: 30,
                        }}
                    >
                        <div style={{ display: "contents" }}>
                            <section>{getFileIcon()}</section>
                            <Typography
                                noWrap
                                style={{
                                    marginLeft: "7px",
                                    color: "#6c757d",
                                    maxWidth: "150px",
                                    fontSize: 10,
                                }}
                            >

                                {mapped ? resumedata?.user_detail?.name : null}

                            </Typography>
                        </div>


                        <section >

                            <CloudDownloadOutlined
                                color="primary"
                                onClick={() => {
                                    window.open(resumedata?.download_link || null)
                                }}
                            />
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        value={checked}
                                        checked={checked}
                                        onChange={handleChange}
                                    // onChange={(data) => {
                                    // console.log(data);
                                    // handleChange(e,data)
                                    // }}
                                    />
                                }
                            />


                        </section>


                    </div>
                </CardContent>
            </Card>
            <ResumeView
                open={open}
                data={data}
                fileUrlInitial={data?.file}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                setTagsRefreshed={setTagsRefreshed}
                toggleCardsData={toggleCardsData}
                totalResumeCardsData={totalResumeCardsData}
                thumbnail={thumbnail}
                setThumbnail={setThumbnail}
                mapped={mapped}
                resumedata={resumedata}
            />
        </>
    );
};

export default ResumeCardsView;
