import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: 'wrap',
    },
  root: {
    display: "flex",
    height: "8rem",
    width: "20rem",
    padding: "1rem",
    margin: "1rem 1rem 2rem 0rem",
    borderRadius: "0.5rem",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    lineHeight: '0.5rem',
  },
  image: {
    border: "1px solid black",
    width: "8rem",
    height: "100%",
  },
  heading: {
    fontSize: '1rem',
    color: '#1D2951',
    fontWeight: '700',
    marginBottom: '0.8rem',
    whiteSpace: 'nowrap',
  },
  subHeading: {
    fontSize: '0.8rem',
    color: '#999999',
    marginBottom: '1.2rem',
  },
  description: {
    fontSize: '0.9rem',
    color: '#000000',
  },
}));

export default function SlotCard(slotCard) {
  const classes = useStyles();
  const card = slotCard
  return (<div className={classes.container}>
    {card?.slotCard.map((item)=>(
        <Card className={classes.root}>
        <div className={classes.image}></div>
        <div className={classes.details}>
          <div className={classes.content}>
              <p className={classes.heading}>{item.heading}</p>
              <p className={classes.subHeading}>{item.experience}</p>
              <p className={classes.description}>Booked Slots : {item.bookedSlots} </p>
              <p className={classes.description}> Available Slots : {item.availableSlots}</p>
          </div>
        </div>
      </Card>
    ))
        
}</div>
  );
}
