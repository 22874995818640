import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { praveshAxiosGetReq } from "../../../util/baseApi";
import ChoosePlan from "./PlanItem/ChoosePlan";
import Hiring from "./PlanItem/Hiring";

const useStyles = makeStyles(() => ({

  subheading: {
    fontStyle: "italic",
    fontWeight: "600",
    marginTop: '.5rem',
    marginBottom: '.5rem',
    color: '#007bff',

    //  marginTop:"",
  },
}))

function Plan({ newCompanyDetails, planDetails, setPlanDetails, handleForward }) {

  const classes = useStyles();

  useEffect(() => {

    if (planDetails?.length === 0) {

      getPlans();
    };

  }, []);

  const getPlans = async () => {

    const endPoint = `/api/admin/plans/`;

    try {

      const res = await praveshAxiosGetReq(endPoint);

      if (res?.data?.success) {

        // setPlans(res?.data?.data);
        setPlanDetails(res?.data?.data);
      }

    } catch (error) {

      throw (error);
    }
  }

  return (
    <>
      <Box>
        <Box style={{ marginTop: "10px" }}>
          <Typography variant="h5">Book Company Slot Now</Typography>
          <Typography
            color="primary"
            variant="body2"
            className={classes.subheading}
          // style={{ fontStyle: "italic", mt: 1, mb: 1, fontWeight: "400" }}
          >
            It's time for you to book this NOW! Effortlessly.
          </Typography>
          {/* <Typography variant="body2" style={{ color: "#6C757D" }}>
            Book your slots NOW with GetWork and enjoy hassle-free Hiring
            services.
          </Typography> */}
        </Box>
        <Box>
          <Hiring
            planDetails={planDetails}
            setPlanDetails={setPlanDetails}
            handleForward={handleForward}
          />
        </Box>
        <br></br>
        <ChoosePlan
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
          handleForward={handleForward}

        />
      </Box>
    </>
  );
}

export default Plan;
