import React from "react";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import tick from "../../../assets/Icons/approve.png";
import reject from "../../../assets/Icons/reject.png";
import UploadSpocMember from "./UploadSpocMember/UploadSpocMember";
import { useState } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: ".2px solid #ced4da",
  },
  body: {
    fontSize: 14,
    border: ".2px solid #ced4da",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "Roshan Kapoor",
    "9839302906",
    "HR Head",
    "Technology",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "Roshan Kapoor",
    "9839302906",
    "HR Head",
    "Technology",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "Roshan Kapoor",
    "9839302906",
    "HR Head",
    "Technology",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "Roshan Kapoor",
    "9839302906",
    "HR Head",
    "Technology",
    "prerit.chaudhary@getwork.org"
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  btnAddSpoc: {
    marginTop: "2rem",
  },
});

const EditSpocDetails = ({ tpoUsers, newCompanyDetails, setTpoUsers }) => {
  const [open, setOpen] = useState(false);
  const [newSpocCreateFlag, setNewSpocCreateFlag] = useState(false);
  const [index, setIndex] = useState();

  const initialSpocDetails = {
    firstName: "",
    lastName: "",
    designation: "",
    email: "",
    phone: "",
    altEmail: "",
    altPhone: "",
    userId: "",
    status: "",
    blocked: "",
    is_default_user: false,
  };

  const [spocDetails, setSpocDetails] = useState(initialSpocDetails);

  const handleSpocDialogBox = (action) => {
    setOpen(true);
    if (action === "new") {
      setSpocDetails(initialSpocDetails);
      setNewSpocCreateFlag(true);
    } else {
      setNewSpocCreateFlag(false);
    }
  };

  const getUserRole = (userDetail) => {
    if (userDetail?.user_role) {
      return userDetail?.user_role;
    } else {
      if (userDetail?.user_type_id === 5) {
        return "Admin";
      } else {
        return "Viewer";
      }
      // else if (userDetail?.user_type_id === 11) {
      //     return "Viewer"
      // }
    }
  };

  const updateSpocDialog = (index) => {
    setIndex(index);
    let tempSpoc = tpoUsers?.[index];

    const details = {
      firstName: tempSpoc?.first_name,
      lastName: tempSpoc?.last_name,
      designation: tempSpoc?.job_title,
      email: tempSpoc?.email,
      phone: tempSpoc?.phone,
      userId: tempSpoc?.user_id,
      status: tempSpoc?.is_verified ? "verified" : "notVerified",
      blocked: tempSpoc?.is_blocked ? "blocked" : "",
      userRole: getUserRole(tempSpoc),
      is_default_user: tempSpoc?.is_default_user,
    };

    setSpocDetails(details);
  };

  const classes = useStyles();

  return (
    <>
      <>
        {tpoUsers?.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Designation</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center">User Role</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                  <StyledTableCell align="center">Default User</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tpoUsers?.length > 0 &&
                  tpoUsers?.map((row, index) => (
                    <StyledTableRow key={row?.user_id}>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {row?.first_name + " " + row?.last_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.job_title}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.email}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.phone}
                      </StyledTableCell>{" "}
                      <StyledTableCell align="center">
                        {getUserRole(row)}
                      </StyledTableCell>{" "}
                      <StyledTableCell align="center">
                        {row?.is_blocked ? (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              margin: "auto",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={reject}
                              height="20px"
                              style={{ marginRight: 10 }}
                            />{" "}
                            <span
                              style={{ color: "#e74d3d", fontWeight: "bold" }}
                            >
                              Blocked
                            </span>
                          </span>
                        ) : null}
                        {!row?.is_blocked ? (
                          row?.is_verified ? (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "auto",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={tick}
                                height="20px"
                                style={{ marginRight: 10 }}
                              />{" "}
                              <span
                                style={{ color: "#51c03e", fontWeight: "bold" }}
                              >
                                Verified
                              </span>
                            </span>
                          ) : (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "auto",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={reject}
                                height="20px"
                                style={{ marginRight: 10 }}
                              />{" "}
                              <span
                                style={{ color: "#e74d3d", fontWeight: "bold" }}
                              >
                                Not Verified
                              </span>
                            </span>
                          )
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          onClick={() => {
                            handleSpocDialogBox();
                            updateSpocDialog(index);
                          }}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>{" "}
                      <StyledTableCell align="center">
                        {row?.is_default_user ? "Yes" : "No"}
                      </StyledTableCell>{" "}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
      <Button
        className={classes.btnAddSpoc}
        variant="outlined"
        color="secondary"
        onClick={() => {
          handleSpocDialogBox("new");
        }}
      >
        Add Spoc
      </Button>
      {open ? (
        <UploadSpocMember
          open={open}
          setOpen={setOpen}
          pocList={tpoUsers}
          setTpoUsers={setTpoUsers}
          index={index}
          pocDetail={spocDetails}
          setpocDetails={setSpocDetails}
          newSpocCreateFlag={newSpocCreateFlag}
          newCompanyDetails={newCompanyDetails}
        />
      ) : null}
    </>
  );
};

export default EditSpocDetails;
