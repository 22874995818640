import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F6F6F6",
    color: "#000000",
  },
  body: {
    fontSize: 14,
    border: "none",
    borderRight: "1px solid #6C757D",
    // "&:last-of-type": {
    //   borderRight: "none",
    // },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function transformData(data) {
  const result = [];
  data?.x_axis.forEach((date, index) => {
    result.push({ date: date, data: data?.y_axis[0].data[index] });
  });
  return result;
}

export default function ReportTable({ color, tableTitle, tableData }) {
  // const rowdata = [
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  //   {
  //     date: "29/05/2024",
  //     data: 50,
  //   },
  // ];

  const rowdata = transformData(tableData);

  console.log("rowdata", rowdata);
  const classes = useStyles();

  return (
    <TableContainer
      style={{ border: "1px solid #6C757D", borderRadius: "4px" }}
    >
      <Table
        className={classes.table}
        stickyHeader
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              style={{
                borderRight: "1px solid #6C757D",
                fontWeight: "600",
                backgroundColor: "#F6F6F6",
                width: "50%",
              }}
            >
              Date
            </StyledTableCell>

            <StyledTableCell
              align="center"
              style={{
                fontWeight: "600",
                width: "50%",
                backgroundColor: color,
                color: "#fff",
              }}
            >
              {tableTitle}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowdata?.length &&
            rowdata?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row" align="center">
                  {row?.date}
                </StyledTableCell>

                <StyledTableCell align="center" style={{ borderRight: "none" }}>
                  {row?.data}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
