import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { CsvBuilder } from "filefy";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#ff9100",
    },
  },
  overrides: {
    MuiListItemText: {
      root: {
        whiteSpace: "initial",
      },
    },
  },
});

// function TableWithExport({
//   title,
//   data,
//   columns,
//   fName,
//   pageValue,
//   editableObject,
//   selection = true,
//   onSelectionChange,
//   cellFixedIndex = 1,
//   disableTableDownload = false,
//   fromJobs = false,
//   hasPaging = true,
//   searchPlaceHolder = "Search",
//   filtering = true,
//   exportCSVfun = () => {},
//   dynamicExport = false,
//   search = true,
//   sorting = true,
//   showNestedTable = false,
//   onTreeExpandChange = () => {},
// }) {
//   //utility functions starts

//   // Function to get the column order from localStorage
//   const getColumnOrderFromLocalStorage = (tableName) => {
//     const order = localStorage.getItem(tableName);
//     return order ? JSON.parse(order) : null;
//   };

//   // Function to insert elements in the order array
//   function insertElementAt(array, sourceIndex, destinationIndex) {
//     const clonedArray = [...array];

//     const [removed] = clonedArray.splice(sourceIndex, 1);
//     clonedArray.splice(destinationIndex, 0, removed);

//     return clonedArray;
//   }

//   //utility functions ends

//   const [tableNamefForLocalStorage, setTableNamefForLocalStorage] = useState(
//     "table_order_" + title.replace(/\([^)]*\)/g, "").trim()
//   );

//   const [orderedColumns, setOrderedColumns] = useState([]);

//   useEffect(() => {
//     const order = getColumnOrderFromLocalStorage(tableNamefForLocalStorage);

//     if (order) {
//       const updatedColumns = order.map((colNumber) => columns[colNumber]);
//       setOrderedColumns(updatedColumns);
//     } else {
//       setOrderedColumns(columns);
//     }
//   }, []);

//   useEffect(() => {
//     if (columns?.length > 0 && (cellFixedIndex || cellFixedIndex === 0)) {
//       columns[cellFixedIndex].cellStyle = {
//         position: "sticky",
//         left: fromJobs ? 75 : 0,
//         backgroundColor: "#fff",
//         fontSize: 13,
//       };
//       columns[cellFixedIndex].headerStyle = {
//         position: "sticky",
//         left: fromJobs ? 75 : 0,
//         zIndex: 1000,
//       };
//       columns[cellFixedIndex].filterCellStyle = {
//         position: "sticky",
//         left: fromJobs ? 75 : 0,
//         zIndex: 100,
//         backgroundColor: "#fff",
//       };
//     }
//   }, [columns, cellFixedIndex]);

//   const handleColumnDragged = (sourceIndex, destinationIndex) => {
//     let order = [];

//     const storedOrder = getColumnOrderFromLocalStorage(
//       tableNamefForLocalStorage
//     );

//     if (storedOrder) {
//       order = storedOrder;
//     } else {
//       order = Array.from({ length: columns.length }, (_, index) => index);
//     }

//     const updatedOrder = insertElementAt(order, sourceIndex, destinationIndex);

//     localStorage.setItem(
//       tableNamefForLocalStorage,
//       JSON.stringify(updatedOrder)
//     );
//   };

//   return (
//     <div className="table__with__export">
//       <ThemeProvider theme={theme}>
//         {/* <MaterialTable
//           title={title}
//           data={data}
//           columns={columns}
//           // options={{
//           //   search: search,
//           //   sorting: sorting,
//           //   selection: selection,
//           //   filtering: filtering,
//           //   paging: hasPaging,
//           //   pageSize: pageValue || 50,
//           //   pageSizeOptions: [10,20,50,100,200],
//           //   exportButton: !disableTableDownload ?  { pdf: false, csv: true} : null,
//           //   exportAllData:disableTableDownload,
//           //   exportCsv: dynamicExport ?  exportCSVfun : null,
//           //   emptyRowsWhenPaging: false,
//           //   draggable:false,
//           //    headerStyle: {
//           //    position: 'sticky',
//           //    top: 0,
//           //    backgroundColor: "#ebf5fa",
//           //    },
//           //   maxBodyHeight: "450px",
//           //   cellStyle: {
//           //     textAlign: "center",
//           //     formControlStyle: {
//           //       minWidth: "300px",
//           //       maxWidth: "300px",
//           //       width: "500px",
//           //     },
//           //   },
//           // }}
//           // onSelectionChange={onSelectionChange}
//           // actions={[
//           //   {
//           //     position: "toolbarOnSelect",
//           //     icon: SaveAlt,
//           //     tooltip: "Export the selected rows!",
//           //     onClick: (e, rowData) => {
//           //       const fileName = fName;
//           //       const builder = new CsvBuilder(fileName + ".csv");
//           //       builder
//           //         .setColumns(columns.map((columnDef) => columnDef.title))
//           //         .addRows(
//           //           rowData.map((rowData) => columns.map((columnDef) => rowData[columnDef.field]))
//           //         )
//           //         .exportFile();
//           //     },
//           //   },
//           // ]}
//           // editable={editableObject}
//           // localization={{
//           //   header: {
//           //     actions: "",
//           //   },
//           //   toolbar:{
//           //      searchPlaceholder:searchPlaceHolder,
//           //   }
//           // }}
//           parentChildData={(row, rows) => rows.find(a => row.parentId === a.appliedBy)}
//           // onTreeExpandChange={() => {

//           // }}
//         /> */}
//         <MaterialTable
//           title={title}
//           data={data}
//           columns={orderedColumns}
//           options={{
//             // grouping: true,
//             search: search,
//             sorting: sorting,
//             selection: selection,
//             filtering: filtering,
//             paging: hasPaging,
//             pageSize: pageValue || 50,
//             pageSizeOptions: [10, 20, 50, 100, 200],
//             // exportButton: !disableTableDownload ?  { pdf: false, csv: true} : null,
//             exportAllData: disableTableDownload,
//             exportCsv: dynamicExport ? exportCSVfun : null,
//             emptyRowsWhenPaging: false,
//             draggable: true,
//             headerStyle: {
//               position: "sticky",
//               top: 0,
//               backgroundColor: "#ebf5fa",
//             },
//             maxBodyHeight: "450px",
//             cellStyle: {
//               textAlign: "center",
//               formControlStyle: {
//                 minWidth: "300px",
//                 maxWidth: "300px",
//                 width: "500px",
//               },
//             },
//           }}
//           onSelectionChange={onSelectionChange}
//           onColumnDragged={handleColumnDragged}
//           actions={[
//             {
//               position: "toolbarOnSelect",
//               icon: SaveAlt,
//               tooltip: "Export the selected rows!",
//               onClick: (e, rowData) => {
//                 const fileName = fName;
//                 const builder = new CsvBuilder(fileName + ".csv");
//                 builder
//                   .setColumns(columns.map((columnDef) => columnDef.title))
//                   .addRows(
//                     rowData.map((rowData) =>
//                       columns.map((columnDef) => rowData[columnDef.field])
//                     )
//                   )
//                   .exportFile();
//               },
//             },
//           ]}
//           editable={editableObject}
//           localization={{
//             header: {
//               actions: "",
//             },
//             toolbar: {
//               searchPlaceholder: searchPlaceHolder,
//             },
//           }}
//           parentChildData={
//             showNestedTable
//               ? (row, rows) => rows.find((a) => a.id === row.parentId)
//               : () => {}
//           }
//           onTreeExpandChange={(e, value) => {
//             onTreeExpandChange(e, value);
//           }}
//           // options={{
//           //   selection: true,
//           // }}
//         />
//       </ThemeProvider>
//     </div>
//   );
// }

// export default TableWithExport;

const TableWithExport = React.memo((props) => {
  const {
    title,
    data,
    columns,
    fName,
    pageValue,
    editableObject,
    selection = true,
    onSelectionChange,
    cellFixedIndex = 1,
    disableTableDownload = false,
    fromJobs = false,
    hasPaging = true,
    searchPlaceHolder = "Search",
    filtering = true,
    exportCSVfun = () => {},
    dynamicExport = false,
    search = true,
    sorting = true,
    showNestedTable = false,
    onTreeExpandChange = () => {},
  } = props;

  //utility functions starts

  // Function to get the column order from localStorage
  const getColumnOrderFromLocalStorage = (tableName) => {
    const order = localStorage.getItem(tableName);
    return order ? JSON.parse(order) : null;
  };

  // Function to insert elements in the order array
  function insertElementAt(array, sourceIndex, destinationIndex) {
    const clonedArray = [...array];

    const [removed] = clonedArray.splice(sourceIndex, 1);
    clonedArray.splice(destinationIndex, 0, removed);

    return clonedArray;
  }

  //utility functions ends

  const [tableNamefForLocalStorage, setTableNamefForLocalStorage] = useState(
    "table_order_" + title.replace(/\([^)]*\)/g, "").trim()
  );

  const [orderedColumns, setOrderedColumns] = useState([]);

  useEffect(() => {
    const order = getColumnOrderFromLocalStorage(tableNamefForLocalStorage);

    if (order) {
      const updatedColumns = order.map((colNumber) => columns[colNumber]);
      setOrderedColumns(updatedColumns);
    } else {
      setOrderedColumns(columns);
    }
  }, [columns, tableNamefForLocalStorage]);

  useEffect(() => {
    if (columns?.length > 0 && (cellFixedIndex || cellFixedIndex === 0)) {
      columns[cellFixedIndex].cellStyle = {
        position: "sticky",
        left: fromJobs ? 75 : 0,
        backgroundColor: "#fff",
        fontSize: 13,
      };
      columns[cellFixedIndex].headerStyle = {
        position: "sticky",
        left: fromJobs ? 75 : 0,
        zIndex: 1000,
      };
      columns[cellFixedIndex].filterCellStyle = {
        position: "sticky",
        left: fromJobs ? 75 : 0,
        zIndex: 100,
        backgroundColor: "#fff",
      };
    }
  }, [columns, cellFixedIndex, fromJobs]);

  const handleColumnDragged = (sourceIndex, destinationIndex) => {
    let order = [];

    const storedOrder = getColumnOrderFromLocalStorage(
      tableNamefForLocalStorage
    );

    if (storedOrder) {
      order = storedOrder;
    } else {
      order = Array.from({ length: columns.length }, (_, index) => index);
    }

    const updatedOrder = insertElementAt(order, sourceIndex, destinationIndex);

    localStorage.setItem(
      tableNamefForLocalStorage,
      JSON.stringify(updatedOrder)
    );
  };

  return (
    <div className="table__with__export">
      <ThemeProvider theme={theme}>
        <MaterialTable
          title={title}
          data={data}
          columns={orderedColumns}
          options={{
            // grouping: true,
            search: search,
            sorting: sorting,
            selection: selection,
            filtering: filtering,
            paging: hasPaging,
            pageSize: pageValue || 50,
            pageSizeOptions: [10, 20, 50, 100, 200],
            // exportButton: !disableTableDownload ?  { pdf: false, csv: true} : null,
            exportAllData: disableTableDownload,
            exportCsv: dynamicExport ? exportCSVfun : null,
            emptyRowsWhenPaging: false,
            draggable: true,
            headerStyle: {
              position: "sticky",
              top: 0,
              backgroundColor: "#ebf5fa",
            },
            maxBodyHeight: "450px",
            cellStyle: {
              textAlign: "center",
              formControlStyle: {
                minWidth: "300px",
                maxWidth: "300px",
                width: "500px",
              },
            },
          }}
          onSelectionChange={onSelectionChange}
          onColumnDragged={handleColumnDragged}
          actions={[
            {
              position: "toolbarOnSelect",
              icon: SaveAlt,
              tooltip: "Export the selected rows!",
              onClick: (e, rowData) => {
                const fileName = fName;
                const builder = new CsvBuilder(fileName + ".csv");
                builder
                  .setColumns(columns.map((columnDef) => columnDef.title))
                  .addRows(
                    rowData.map((rowData) =>
                      columns.map((columnDef) => rowData[columnDef.field])
                    )
                  )
                  .exportFile();
              },
            },
          ]}
          editable={editableObject}
          localization={{
            header: {
              actions: "",
            },
            toolbar: {
              searchPlaceholder: searchPlaceHolder,
            },
          }}
          parentChildData={
            showNestedTable
              ? (row, rows) => rows.find((a) => a.id === row.parentId)
              : () => {}
          }
          onTreeExpandChange={(e, value) => {
            onTreeExpandChange(e, value);
          }}
        />
      </ThemeProvider>
    </div>
  );
});

export default TableWithExport;
