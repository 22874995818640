import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Button} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import Slider from '@material-ui/core/Slider';
import { FailureAlert } from '../../util/Notifications';
import { printNumber } from '../../util/printNumber';


function JobsSent() {

    const {clg_id} = useParams();

    const clgName = 
          JSON.parse(localStorage.getItem('colleges'))
          ?
          JSON.parse(localStorage.getItem('colleges'))[clg_id] :
          'College Name';
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall(`/api/admin/college/job/?college_id=${clg_id}`, requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                let companyByJobID = {}
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        company_name:      json.data[i].company_name,
                        company_nickname:  json.data[i].company_nickname,
                        company_id:        json.data[i].company_id,
                        job_title:         json.data[i].job_title,
                        job_role:          json.data[i].job_role,
                        job_id:            json.data[i].job_id,
                        ctc_min:           json.data[i].ctc_min,
                        ctc_max:           json.data[i].ctc_max,
                        apply_start_date:  json.data[i].apply_start_date,
                        apply_end_date:    json.data[i].apply_end_date,
                        vacancy:           json.data[i].vacancy,
                        job_post_date:     json.data[i].job_post_date,
                        applicant_count:   json.data[i].applicant_count ? json.data[i].applicant_count : 0, 
                    }
                    companyByJobID[obj.job_id] = obj.company_name;
                    tmpArray.push(obj)
                    localStorage.setItem('companyByJobID', JSON.stringify(companyByJobID));
                }
                setData(tmpArray);
                setLoading(false);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (newRange, props) => { props.onFilterChanged(props.columnDef.tableData.id, newRange); };

    const ctcLabel = [
      { value: 0, label: '0', },
      { value: 1000000, label: '10L', },
      { value: 2000000, label: '20L', },
    ];

    const columns = [
        { title: "Job ID",            field: "job_id",            headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Company_Name",      field: "company_name",      headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Company Nickname",  field: "company_nickname",  headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job_Title",         field: "job_title",         headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Job_Role",          field: "job_role",          headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Minimum_CTC",       field: "ctc_min",           headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}}
              value={props.columnDef.tableData.filterValue || [0,2000000]}
              defaultValue={[0, 2000000]} valueLabelFormat={printNumber}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={2000000} step={50000} marks={ctcLabel}
              valueLabelDisplay="auto" aria-labelledby="range-slider"
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.ctc_min >= term[0] && rowData.ctc_min <= term[1],
          render: row => row.ctc_min ? printNumber(row.ctc_min) : null  
        },
        { title: "Maximum_CTC",       field: "ctc_max",           headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,
          filterComponent: (props) => 
            <Slider
              style={{zIndex:'10'}}
              value={props.columnDef.tableData.filterValue || [0,2000000]}
              defaultValue={[0, 2000000]} valueLabelFormat={printNumber}
              onChange={(e,val)=>handleChange(val, props)}
              min={0} max={2000000} step={50000} marks={ctcLabel}
              valueLabelDisplay="auto" aria-labelledby="range-slider"
            />,
          customFilterAndSearch: (term, rowData) =>
              rowData.ctc_max >= term[0] && rowData.ctc_max <= term[1],
          render: row => row.ctc_max ? printNumber(row.ctc_max) : null  
        },
        { title: "Applications Intake Start",  field: "apply_start_date",  headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'date',               searchable: false,          filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.apply_start_date.substring(0, 10)
        },
        { title: "Deadline to Apply", field: "apply_end_date",    headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'date',               searchable: false,          filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) => 
              new Date(term).toISOString().substring(0, 10) === rowData.apply_end_date.substring(0, 10)
        },
        { title: "Vacancies",         field: "vacancy",           headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'numeric',            searchable: false, },
        { title: "Date_Posted",       field: "job_post_date",     headerStyle: headerCSS, cellStyle: cellCSS,
          type: 'date',               searchable: false,          filterPlaceholder: 'Open Calendar',
          customFilterAndSearch: (term, rowData) =>
              new Date(term).toISOString().substring(0, 10) === rowData.job_post_date.substring(0, 10)
        },
        { title: 'Total Applicants',  field: 'applicant_count',   headerStyle: headerCSS,     cellStyle: cellCSS, filtering: false,
          render: row => 
          <div> 
            { <Button 
                variant="outline-success" size="sm" 
                style={{whiteSpace:'nowrap'}}
                onClick={() => window.open(`/admin/college/applicants/${clg_id}/${row.job_id}`)}
              >
              Applicants ({row.applicant_count})</Button> }
          </div> 
        },
    ]


    return (
        !loading
        ?
        <div className="jobs__sent">
            <TableWithExport 
                title = {`Jobs for ${clgName}`}
                data = {data}
                columns = {columns}
                fName = "Selected Jobs"
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default JobsSent;