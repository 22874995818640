import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  makeStyles,
  Chip,
  alpha,
  styled,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
// import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { praveshAxiosGetReq } from "../../../util/baseApi";
import { preferenceData } from "./preferenceData";
import { CustomTextField } from "../../common/InputText/InputField";
import { TextField } from "@material-ui/core";

import SelectJobSegment from "./SelectJobSegment";
import SelectedPreferenceCards from "./SelectedPreferenceCards";
import { Autocomplete } from "@material-ui/lab";
import { FailureAlert } from "../../../util/Notifications";
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
  marginLeft: "0px",
}));

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    boxShadow: "0px 0px 4px 0px #00000040",
    padding: 20,
    borderRadius: "20px",
    paddingTop: 20,
    paddingBottom: 20,
  },
  contentContainer: {
    marginTop: 0,
    width: "100%",
    // marginLeft: "2.0rem",
  },
  applicantHeading: {
    fontSize: "20px",
    fontWeight: "600",
    // paddingLeft: 10,
    // marginLeft: "2.5rem",
  },
  subheading: {
    fontSize: "16px",
    fontWeight: "400",
    marginLeft: 14,
    marginBottom: "1.25rem",
    marginTop: "1.25rem",
  },
  contentBox: {
    marginLeft: "1rem",
  },
  btnAddNewPreference: {
    boxShadow: "none",
    borderRadius: "30px",
  },
  locationPreference: {
    // mar
  },
}));

const ApplicantsPreference = ({
  cityState,
  preferences,
  setPreferences,
  allJobSegments,
  qualificationData,
  handleSubmit,
  workExLevel,
  appLoader,

}) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const addLocation = (cityState) => {

    if (cityState) {
      const stateAlreadyAdded = preferences?.applicant?.location?.some(
        (st) => st.city_id === cityState.city_id
      );
      if (stateAlreadyAdded)
        FailureAlert("city Already Added!");
      // alert?.error("city Already Added!");
      else {
        const arr = preferences?.applicant?.location
          ? [...preferences?.applicant?.location]
          : [];
        arr.push(cityState);

        setPreferences({
          ...preferences,
          applicant: {
            ...preferences?.applicant,
            location: arr,
          },
        });
      }
    }
  };

  const deleteLocation = (index) => {
    const stateAfterDelete = preferences?.applicant?.location?.filter(
      (_, state) => state !== index
    );

    setPreferences({
      ...preferences,
      applicant: {
        ...preferences?.applicant,
        location: stateAfterDelete,
      },
    });
  };

  const [jobSegmentState, setJobSegmentState] = useState(null);
  const [internshipPayment, setInternshipPayment] = useState(null);
  const [jobPayment, setJobPayment] = useState(null);

  const addJob = (e) => {
    // const t = allJobSegments?.data.find(
    //   (d) => d?.job_segment === jobSegmentType
    // );
    const exist = preferences?.applicant?.jobSegment?.find(
      (j) => j?.job_segment_id === jobSegmentState?.id
    );
    if (!exist) {
      const arr = preferences?.applicant?.jobSegment
        ? [...preferences?.applicant?.jobSegment]
        : [];
      const newJobSegment = {
        ...jobSegmentState,
        name: jobSegmentState?.job_segment,
        max_stipend: parseInt(internshipPayment),
        max_ctc: parseInt(jobPayment),
      };
      arr.unshift(newJobSegment);
      setPreferences({
        ...preferences,
        applicant: {
          ...preferences?.applicant,
          jobSegment: arr,
        },
      });
      setJobSegmentState(null);
      setInternshipPayment(null);
      setJobPayment(null);
    } else {
      // alert?.error("Job Segment Already Added");
      FailureAlert("Job Segment Already Added")
      // alert?.error("Job Segment Already Added");
    }
  };

  const deleteJob = (uid) => {
    // console.log(uid);
    const arrayAfterDeletion = preferences?.applicant?.jobSegment?.filter(
      (job) => job.id !== uid
    );

    //console.log("arrayAfterDeletion", arrayAfterDeletion);

    setPreferences({
      ...preferences,
      applicant: {
        ...preferences?.applicant,
        jobSegment: arrayAfterDeletion,
      },
    });
  };

  const handleQualification = (e) => {
    let arr =
      preferences?.applicant?.eduLevel?.length > 0
        ? [...preferences?.applicant?.eduLevel]
        : [];
    if (e.target?.checked) {
      arr.push(parseInt(e.target.value));
    } else {
      arr = arr.filter((el) => el !== parseInt(e.target.value));
    }
    setPreferences({
      ...preferences,
      applicant: {
        ...preferences?.applicant,
        eduLevel: arr,
      },
    });
  };

  const handleWorkExLevel = (e, item) => {
    let arr =
      preferences?.applicant?.workExLevel?.length > 0
        ? [...preferences?.applicant?.workExLevel]
        : [];
    let arrID =
      preferences?.applicant?.workExLevel?.length > 0
        ? [...preferences?.applicant?.workExLevelId]
        : [];

    if (e.target?.checked) {
      arr.push(item);
      arrID.push(parseInt(item?.work_level_id));
    } else {
      arr = arr?.filter((el) => el?.work_level_id !== item?.work_level_id);
      arrID = arrID?.filter((id) => id !== item?.work_level_id);
    }
    setPreferences({
      ...preferences,
      applicant: {
        ...preferences?.applicant,
        workExLevel: arr,
        workExLevelId: arrID,
      },
    });
  };


  return (
    <div className={classes.mainContainer}>
      <Typography
        className={classes.applicantHeading}
        style={{ marginLeft: "15px" }}
      >
        Applicants Preferences
      </Typography>
      <div className={classes.contentContainer}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Typography className={classes.subheading}>
              Tentative Availability For Full-time Hiring
            </Typography>
            <Grid
              container
              spacing={2}
              className={classes.contentBox}
              style={{ pl: 0, pr: 0 }}
            >
              {workExLevel?.map((item, index) => (
                <Grid item md={12} key={item?.work_level_id}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          value={item}
                          onChange={(e) => {
                            handleWorkExLevel(e, item);
                          }}
                          checked={preferences?.applicant?.workExLevelId?.includes(
                            item?.work_level_id
                          )}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "14px" }}>
                          {item?.name}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Typography
              className={classes.subheading}
              style={{ marginLeft: -10 }}
            >
              Qualification Level
            </Typography>
            <Grid container spacing={2} style={{ pl: 0, pr: 0 }}>
              {qualificationData?.map((item, value) => (
                <Grid item md={4} key={item?.id}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          value={item?.id}
                          onChange={handleQualification}
                          checked={preferences?.applicant?.eduLevel?.includes(
                            item?.id
                          )}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "14px" }}>
                          {item?.type}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className={classes.contentContainer}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Typography className={classes.subheading}>
              Job Segment & Offers{" "}
            </Typography>
          </Grid>
        </Grid>

        <Button
          // onClick={handleClickOpen}
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
          color="primary"
          className={classes.btnAddNewPreference}
          style={{
            boxShadow: "none",
            borderRadius: "30px",
            pl: 7,
            pr: 7,
            mt: 2,
            ml: 2,
          }}
        >
          Add New Preference
        </Button>
        <SelectJobSegment
          open={open} setOpen={setOpen}
          allJobSegments={allJobSegments}
          jobSegmentState={jobSegmentState}
          setJobSegmentState={setJobSegmentState}
          internshipPayment={internshipPayment}
          setInternshipPayment={setInternshipPayment}
          jobPayment={jobPayment}
          setJobPayment={setJobPayment}
          addJob={addJob}
        />
        <Grid container spacing={2}>
          {/* {preferenceData?.job_segment?.map((obj) => ( */}
          {preferences?.applicant?.jobSegment?.map((obj) => (
            <Grid item md={4}>
              <SelectedPreferenceCards jobSegment={obj} deleteJob={deleteJob} />
              {/* <Offers ={obj} deleteJob={deleteJob} /> */}
            </Grid>
          ))}
        </Grid>

        <Typography
          style={{ fontSize: "16px", fontWeight: "400", paddingLeft: 20, marginTop: "2rem" }}
        >
          Applicants Location
        </Typography>

        <Box
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "10px    ",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={5}>
              <Autocomplete
                id="combo-box-demo"
                options={cityState}
                value={preferences?.applicant?.location}
                getOptionLabel={(option) =>
                  option?.city && option?.state
                    ? `${option?.city}, ${option?.state}`
                    : ""
                }
                onChange={(e, newValue) => {
                  if (newValue) addLocation(newValue);
                }}
                style={{ width: 300, }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    label="Add Location"
                  />
                )}
              />

            </Grid>
            <Grid item md={7}>

            </Grid>
            <Grid item md={12}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flexe-start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  padding: 5,
                  margin: 0,
                  paddingLeft: 0,
                  marginTop: 20,
                  minWidth: "20rem",

                }}
              >
                {preferences?.applicant?.location?.map((data, index) => {
                  return (
                    <ListItem key={data?.city_id}>
                      <Chip
                        label={`${data?.city} | ${data?.state}`}
                        color="primary"
                        deleteIcon={<CloseIcon />}
                        variant="outlined"
                        style={
                          {
                            // backgroundColor: alpha(
                            //     theme.palette.primary.main,
                            //     0.1
                            // ),
                          }
                        }
                        onDelete={() => {
                          deleteLocation(index);
                        }}
                      />
                    </ListItem>
                  );
                })}
              </Box>
            </Grid>

          </Grid>

          <Button
            variant="contained"
            color="primary"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              borderRadius: "30px",
              minWidth: "9rem",
              minHeight: '2.7rem',
            }}
            onClick={() => {
              handleSubmit("applicant");
            }}
          >
            {appLoader ? (
              <CircularProgress size={18} style={{ color: "white" }} />
            ) : (
              "Save Changes"
            )}
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default ApplicantsPreference;
