export const printNumber = (val) => {
    if(val>=10000000)
        return `${(val/10000000).toFixed(1).replace(/\.0$/, '')} cr`
    else if(val>=100000)
        return `${(val/100000).toFixed(1).replace(/\.0$/, '')} lpa` 
    else if(val>=1000)
        return `${(val/1000).toFixed(1).replace(/\.0$/, '')}k` 
    else
        return val       
}


export const printDate = (date) => {
    return date?.slice(8, 10) + "/" + date?.slice(5, 7) + "/" + date?.slice(0, 4)
}

export const validateDateTime = (dateTime) => {
    if(!dateTime) return "" ;
    if(dateTime.length > 0) {
        if(dateTime.charAt(dateTime.length-1) === 'Z' || dateTime.charAt(dateTime.length-1) === 'z')
            return dateTime.slice(0, -1);
        else
            return dateTime;
    }
    return '';
}


export const convertTitleCase = (str) => {
    var lower = String(str).toLowerCase();
    return lower.replace(/(^| )(\w)/g, function(x) {
        return x.toUpperCase();
    });
}