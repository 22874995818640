import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IconsCollection } from "./IconCollection";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    marginLeft: "16px",
    marginRight: "16px",
  },
  sidebarlink: {
    display: "flex",
    color: "#000",
    padding: "8px 0px 8px 16px",
    textDecoration: "none",
    listStyle: "none",
    justifyContent: "flex-start",
    height: "50px",
    alignItems: "center",
    // padding: "0 16px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#007BFF",
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueSideBar: {
    backgroundColor: "red",
    color: "#fff ",
    display: "flex",
    padding: "8px 0px 8px 16px",
    listStyle: "none",
    height: "50px",
    alignItems: "center",
    // padding: "0 16px",
    borderRadius: "4px",
  },
  subNavSelected: {
    backgroundColor: "#007BFF",
    textDecoration: "none",
    color: "#fff !important",

    display: "flex",
    padding: "8px 0px 8px 16px",
    listStyle: "none",
    justifyContent: "flex-start",
    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "4px",
  },
  dropdownlink: {
    display: "flex",
    color: "#000",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    // backgroundcolor: "",
    alignItems: "center",
    padding: "0px 16px 0px 40px",
    justifyContent: "flex-start",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#007BFF",
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueLink: {
    display: "flex",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    alignItems: "center",
    padding: "0px 16px 0px 40px",
    justifyContent: "flex-start",
    borderRadius: "4px",
    backgroundColor: "#007BFF",
    textDecoration: "none",
    color: "#fff",
  },
}));

const NewSideBar = ({ items, key, setCollapseSubNav, collapseSubNav }) => {
  const [subnav, setSubnav] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const [item, setItem] = useState({});
  const showSubnav = () => {
    setSubnav(!subnav);
  };
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (
  //     items.subsection &&
  //     items.subsection.length &&
  //     items.subsection.some((i) => i.path === location.pathname)
  //   )
  //     setSubnav(true);
  //   else setSubnav(false);
  // }, [items]);

  useEffect(() => {
    if (location?.pathname === items.path)
      setItem({
        ...items,
        selected: true,
      });
    else
      setItem({
        ...items,

        selected: false,
      });

    if (items.subsection && items.subsection.length) {
      items.subsection.forEach((i) => {
        if (i.path === location?.pathname) i["selected"] = true;
        else i["selected"] = false;
      });
    }
  }, [items, location?.pathname]);

  return (
    <>
      <div
      // onMouseEnter={() => {
      //   if (item.subsection) showSubnav();
      // }}
      // onMouseLeave={() => {
      //   if (item.subsection) showSubnav();
      // }}
      >
        {" "}
        <Link
          className={
            item.selected
              ? classes.blueSideBar
              : item.subsection &&
                item.subsection.length &&
                item.subsection.some((i) => i.path === location?.pathname)
              ? classes.subNavSelected
              : classes.sidebarlink
          }
          style={{ width: "220px", position: "relative" }}
          to={item.path ? item.path : "#"}
          onClick={() => {
            setCollapseSubNav(false);
            if (item.subsection) showSubnav();
          }}
        >
          <div style={{ display: "contents" }} className={classes.text}>
            {IconsCollection[item?.section]}

            <Typography
              variant="body2"
              color="inherit"
              className={classes.text}
            >
              {item?.section}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: 10,
              top: 14,
            }}
          >
            {item.subsection && !collapseSubNav && subnav ? (
              <KeyboardArrowUpIcon />
            ) : item.subsection ? (
              <KeyboardArrowDownIcon />
            ) : null}
          </div>
        </Link>
        {!collapseSubNav &&
          subnav &&
          item.subsection.map((item, index) => {
            return (
              <Link
                className={
                  item.selected ? classes.blueLink : classes.dropdownlink
                }
                to={item.path}
                key={index}
              >
                {IconsCollection[item?.key]}
                <Typography
                  style={{ fontSize: "12px", marginLeft: "5px" }}
                  color="inherit"
                >
                  {item?.subsection}
                </Typography>
              </Link>
            );
          })}
      </div>
    </>
  );
};

export default NewSideBar;
