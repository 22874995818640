import { TextField } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router';
import gif from '../../assets/loopingcircles-3.gif';
import { cellCSS, createIconStyling, gifStyling, headerCSS, requestPraveshOptions, work_experiences } from '../../constants';
import '../../css/StudentResdex.css';
import { apiCall } from '../../util/apiCall';
import { copyToClipboard } from '../../util/copyToClipboard';
import { FailureAlert } from '../../util/Notifications';
import TableWithExport from '../TableWithExport';


const token = localStorage.getItem('token');

function AllColleges(props) {

  const [excel, setExcel] = useState(null);

  const [searchData, setSearchData] = useState([]);
  const [currentIndustry, setCurrentIndustry] = useState([]);
  const [currentLocation, setCurrentLocation] = useState([]);
  const [industry, setIndustry] = useState([]);   // For Filter purpose
  const [location, setLocation] = useState({});   // For Filter purpose
    // For Filter purpose
  // const [coursesFilter, setCoursesFilter] = useState({});   // For Filter purpose
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [stateId, setStateId] = useState(1);
  const [cityId, setCityId] = useState(null);
  const [industryType, setIndustryType] = useState([]);
  const [minCtcFilter, setMinCtcFilter] = useState(null);
  const [maxCtcFilter, setMaxCtcFilter] = useState(null);

  const [minWorkExp, setMinWorkExp] = useState('');
  const [maxWorkExp, setMaxWorkExp] = useState('');
  
  //const [minCtcFilter, setMinCtcFilter] = useState(null);

  const [applyFiltersBool, setApplyFiltersBool] = useState(false);
  const [removeFiltersBool, setRemoveFiltersBool] = useState(false);
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState([]);
  const [selectedManSkills, setSelectedManSkills] = useState([]);
  const [selectedOptionSkills, setSelectedOptionSkills] = useState([]);
  const [currentComJobRole, setCurrentComJobRole] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [educationType, setEducationType] = useState('');
  const [genderType, setGenderType] = useState('');
  const [passoutYear, setPassoutYear] = useState(null);
  const [passoutYears, setPassoutYears] = useState([]);
  
  const [open, setOpen] = useState(false);
  //const [industry, setIndustry] = useState([]);
  const [modalFeedbacksData, setModalFeedbacksData] = useState('');
  const [viewFeedBackModal, setViewFeedBackModal] = useState(false);


  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setModalFeedbacksData([]);
    setViewFeedBackModal(false);
  }

  useEffect(() => {
    setLoading(true);
    apiCall('/api/admin/degree/all/', requestPraveshOptions)
      .then((json) => {
        if (json.success) {
          // for (var i = 0; i < json.data.length; i++) {
          //     let obj = {
          //         requirement_id:       json.data[i].requirement_id,
          //         full_name :           json.data[i].full_name,
          //         email :               json.data[i].email,
          //         mobile :              json.data[i].mobile,
          //         current_location :    json.data[i].current_location,
          //         preferred_location:   json.data[i].preferred_location,
          //         gender:               json.data[i].gender,
          //         total_experience:     json.data[i].total_experience,
          //         designation :         json.data[i].designation,
          //         annual_salary:        json.data[i].annual_salary,
          //         notice_period:        json.data[i].notice_period,
          //         summary:              json.data[i].summary,
          //         is_active:            json.data[i].is_active ? 'Yes' : 'No',
          //     }
          //     tmpArray.push(obj)
          // }
          setSearchData(json.data);
          setLoading(false);
        }
        else {
          FailureAlert(json.error)
        }
      })
  }
    , [applyFiltersBool])

  const applyFilters = () => {
    setApplyFiltersBool(true);

  }

  const loadFeedbackModal = (e, arr) => {
    setViewFeedBackModal(true)
    setModalFeedbacksData(arr)
    handleOpen()
  }

  const removeFilters = () => {
    setRemoveFiltersBool(true);
    setName(null);
    setMinWorkExp(null);
    setMaxWorkExp(null);
    setSelectedManSkills([]);
    setSelectedOptionSkills([]);
    setCurrentLocation([]);
    setCurrentComJobRole([]);
    setCurrentIndustry([]);
    setMinCtcFilter(null);
    setMaxCtcFilter(null);
    setPassoutYear(null);
    setEducationType(null);
    setGenderType(null);
  }

  // const checkIndustry = () => {
  //   if (industryType.length > 0) {
  //     return `&industry_type=${industryType}`;
  //   }
  //   else return ''
  // }
  // const checkFilterMinCtc = () => {
  //   if (minCtcFilter)
  //     return `&min_ctc=${minCtcFilter}`;
  //   else return '';
  // }
  // const checkFilterMaxCtc = () => {
  //   if (maxCtcFilter)
  //     return `&max_ctc=${maxCtcFilter}`;
  //   else return '';
  // }
  // const checkFilterLocations = () => {
  //   if (selectedLocationsFilter.length > 0) {
  //     return `&city=${selectedLocationsFilter.map(item => item.city_id)}`;
  //   }
  //   else return '';
  // }
  // const checkFilterMandatorySkills = () => {
  //   if (selectedLocationsFilter.length > 0) {
  //     return `&mandatory_skills=${selectedLocationsFilter.map(item => item.mandatorySkills_id)}`;
  //   }
  //   else return '';
  // }
  // const checkFilterOptionalSkills = () => {
  //   if (selectedLocationsFilter.length > 0) {
  //     return `&optional_skills=${selectedLocationsFilter.map(item => item.optionalSkills_id)}`;
  //   }
  //   else return '';
  // }

  // `/api/admin/college/edit/?{checkIndustry()checkMinimumExp()}

  const uploadExcel = (e) => {
    e.preventDefault();
    // if (collegeId === null) { FailureAlert("Please select a college to continue"); return; }
    // if (excel === null) { FailureAlert("Upload file..."); return; }
    // const ext = excel.name.substring(excel.name.lastIndexOf('.') + 1);
    // if (ext !== 'xlsx' && ext !== 'xls') { FailureAlert('Upload Excel File Only...') }
    // else {
    //   const formData = new FormData();
    //   formData.append('college_id', collegeId);
    //   formData.append('file', excel);
    //   const requestOptionsPost = {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     },
    //     body: formData
    //   };

    //   apiCall('/api/admin/college/upload/', requestOptionsPost)
    //     .then((res) => {
    //       if (res.success) {
    //         let tmp = []
    //         tmp.push({ error_type: 'Existing Users', error_count: res.data.errors['Existing Users'].length })
    //         tmp.push({ error_type: 'Degree Errors', error_count: res.data.errors['Degree Errors'].length })
    //         tmp.push({ error_type: 'Specialization Errors', error_count: res.data.errors['Specialization Errors'].length })
    //         tmp.push({ error_type: 'Education Type Errors', error_count: res.data.errors['Education Type Errors'].length })
    //         tmp.push({ error_type: 'Start Date Errors', error_count: res.data.errors['Start Date Errors'].length })
    //         tmp.push({ error_type: 'End Date Errors', error_count: res.data.errors['End Date Errors'].length })
    //         tmp.push({ error_type: 'Current Year Errors', error_count: res.data.errors['Current Year Errors'].length })
    //         tmp.push({ error_type: 'Gender Errors', error_count: res.data.errors['Gender Errors'].length })
    //         tmp.push({ error_type: 'Ethnicity Error', error_count: res.data.errors['Ethnicity Error'].length })
    //         tmp.push({ error_type: 'Veteran Status Error', error_count: res.data.errors['Veteran Status Error'].length })
    //         setResponse(res.data)
    //         setData(tmp);
    //         SuccessAlert('Upload Successful...')
    //         window.document.getElementById('add__student__excel__card__form').reset();
    //       }
    //       else {
    //         FailureAlert(res.error)
    //       }
    //     })
    // }
  }


  //   const addCoworking = (e) => {
  //     e.preventDefault();
  //     apiCall('/api/admin/coworking/', requestOptionsPost)
  //     .then((res) => {
  //         if(res.success) {
  //             SuccessAlert(res.data.message);
  //             setCount(count+1);
  //         }
  //         else {
  //             FailureAlert(res.error);
  //         }
  //     })
  //     e.target.reset();
  // }





  //Industry Finding API

  useEffect(() => {
    apiCall(`/api/admin/operations/student_dex/upload/industry/`, requestPraveshOptions)
      .then((res) => {
        if (res) setIndustry(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Location Finding API
  useEffect(() => {
    apiCall(`/api/admin/operations/student_dex/upload/location/`, requestPraveshOptions)
      .then((res) => {
        if (res) setLocation(res.data);
        else FailureAlert("Can't fetch Location");
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding passout Year
  useEffect(() => {
    apiCall('/api/admin/operations/student_dex/upload/pass_out/', requestPraveshOptions)
      .then(json => {
        if (json) {
          if (json.success) setPassoutYears(json.data);
          else FailureAlert(json.error);
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);





  const requestOptionsPost = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`
    },
    body: JSON.stringify({
      name: name,
      state: stateId,
      city: cityId
    })
  };
  const columns = [
    {
      title: "Requirement_ID", field: "requirement_id", headerStyle: headerCSS, cellStyle: cellCSS, searchable: true,
      editComponent: props => (
        <TextField
          value={props.value} fullWidth={true}
          onChange={e => props.onChange(e.target.value)}
        />
      )
    },
    {
      title: "Full Name", field: "full_name", headerStyle: headerCSS, cellStyle: cellCSS, searchable: true,
      editComponent: props => (
        <TextField
          value={props.value} fullWidth={true}
          onChange={e => props.onChange(e.target.value)}
        />
      )
    },

    {
      title: "Email", field: "email", headerStyle: headerCSS, cellStyle: cellCSS,
      render: row =>
        <div>
          {
            row.email
              ?
              <span>
                {row.email}
                <FileCopyIcon style={createIconStyling} onClick={e => copyToClipboard(row.email)} />
              </span>
              : null
          }
        </div>
    },
    {
      title: "Mobile", field: "mobile", headerStyle: headerCSS, cellStyle: cellCSS,
      render: row => row.mobile ?
        <div>{row.mobile}
          <FileCopyIcon style={createIconStyling} onClick={e => copyToClipboard(row.mobile)} />
        </div> : null
    },
    { title: "Current Location", field: "current_location", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Preferred Location", field: "preferred_location", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Gender", field: "gender", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Total Experience", field: "total_experience", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Current Company", field: "current_company", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Designation", field: "designation", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Annual Salary", field: "annual_salary", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Notice Period", field: "notice_period", headerStyle: headerCSS, cellStyle: cellCSS, },
    { title: "Summary", field: "summary", headerStyle: headerCSS, cellStyle: cellCSS, },
    {
      title: "Active Status", field: "is_active", headerStyle: headerCSS, cellStyle: cellCSS,
      lookup: { 'Yes': 'Yes', 'No': 'No' }, filterPlaceholder: 'Yes/No',
    },
    {
      title: 'View_Feedbacks', headerStyle: headerCSS, cellStyle: cellCSS,
      render: row => row.feedback ? row.feedback :
        <Button variant="outline-info" size="sm" onClick={(e) => loadFeedbackModal(e, row.feedback)}>
          Feedbacks
        </Button>
    },
  ]

  let col = [...columns];
  col.push(
    {
      title: "Excel Uploaded?", field: 'is_admin_created', headerStyle: headerCSS, cellStyle: cellCSS,
      lookup: { 'Yes': 'Yes', 'No': 'No' }, searchable: false, filterPlaceholder: 'Yes/No',
    }
  );

  // useEffect(() => {
  //   console.log(name);
  // }, [name]);


  //   const edit = {
  //     onRowUpdate: (newData, oldData) =>
  //     new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         const dataUpdate = [...data];
  //         const index = oldData.tableData.id;
  //         dataUpdate[index] = newData;
  //         updateRow(newData);
  //         setData([...dataUpdate]);
  //         resolve();
  //       }, 1000)
  //     }).then(setPatchCountry(null))
  //     .then(setPatchState(null))
  //     .then(setPatchCity(null)),
  // }

  return (
    !loading
      ?
      <div className="candidate__information">
        <Card className="add__student__excel__card" id="upload_excel_buttton_card" >
          <Form className="add__student__excel__form" id="add__student__excel__card__form"
            onSubmit={e => { uploadExcel(e) }}
          >
            <small>Upload File (Excel Format only)</small>
            <Form.File
              type="file"
              onChange={e => { setExcel(e.target.files[0]) }} accept=".xls,.xlsx"
            /><br />
            <Button type="submit" variant="primary">Upload Candidate Data</Button>
          </Form>
        </Card>
        <div className="candidate__information__form">
          <h4>Search the Desired Candidates</h4>
          <hr style={{ width: '50%', marginLeft: '0' }} />
          <Form>

            <Form.Row>
              <div id="job_profile_name">
                <Form.Group as={Col} controlId="name">
                  <Form.Label >Job Profile Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={name}
                    type="text"
                    placeholder="Enter Job Profile Name"
                    onChange={e => { setName(e.target.value) }}
                    required
                  />
                </Form.Group>
              </div>
            </Form.Row>

            <Form.Row>
              <div id="min_work_experience">
                <Form.Group as={Col}>
                  <Form.Label>Min Work Experience :</Form.Label>
                  <Form.Control as="select"
                    onChange={(e) => { setMinWorkExp(JSON.parse(e.target.value).value) }}
                  >
                    <option value={JSON.stringify({ "name": "", "value": "" })}>--Min Experience--</option>
                    {
                      work_experiences.map((e, key) => {
                        return <option key={key} value={JSON.stringify(e)}> {e.name} </option>
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </div>
              <div id="max_work_experience">
                <Form.Group as={Col}>
                  <Form.Label>Max Work Experience :</Form.Label>
                  <Form.Control as="select"
                    onChange={(e) => { setMaxWorkExp(JSON.parse(e.target.value).value) }}
                  >
                    <option value={JSON.stringify({ "name": "", "value": "" })}>--Max Experience--</option>
                    {
                      work_experiences.map((e, key) => {
                        return <option key={key} value={JSON.stringify(e)}> {e.name} </option>
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </div>
            </Form.Row>

            <Form.Row>
              <div id="mandatory_skills">
                {
                  <Form.Group as={Col} controlId="mandatory">
                    <Form.Label >Mandatory Skills</Form.Label>
                    <Form.Control
                      name="man_skills"
                      value={selectedManSkills}
                      type="text"
                      placeholder="Enter Mandatory Skills Separated by the comma"
                      onChange={e => { setSelectedManSkills(e.target.value) }}
                      required
                    />
                  </Form.Group>
                }
              </div>
              {/* <div id="mandatory_skills">
                          <Form.Label>Mandatory Skills </Form.Label>
                          <Form.Group>
                            <Autocomplete
                              multiple filterSelectedOptions id="mandatory-skills"
                              options={allSkills}
                              value={selectedManSkills}
                              getOptionLabel={(item) => item.mandatory_skills}
                              onChange={(e, item)=>item ? 
                                setSelectedManSkills(item) : setSelectedManSkills([])
                              }
                              renderInput={(params) => <TextField {...params} label="Select skills" variant="outlined" />}
                            />
                          </Form.Group> */}

              <div id="optional_skills">
                {
                  <Form.Group as={Col} controlId="optional">
                    <Form.Label >Optional Skills</Form.Label>
                    <Form.Control
                      name="optional_skills"
                      value={selectedOptionSkills}
                      type="text"
                      placeholder="Enter Optional Skills Separated by the comma"
                      onChange={e => { selectedOptionSkills(e.target.value) }}
                      required
                    />
                  </Form.Group>
                }
              </div>
              {/* <div id="optional_skills">
                          <Form.Label > Optional Skills </Form.Label>
                          <Form.Group >
                            <Autocomplete
                              multiple filterSelectedOptions id="optional-skills"
                              options={allSkills}
                              value={selectedOptionSkills}
                              
                              onChange={(e, item)=>item ? 
                                setSelectedOptionSkills(item) : setSelectedOptionSkills([])
                              }
                              renderInput={(params) => <TextField {...params} label="Select skills" variant="outlined" />}
                            />
                          </Form.Group>
                    </div> */}
            </Form.Row>
            <Form.Row>
              <div id="current_company_job_Role" >
                <Form.Label >Current Company Job Role</Form.Label>
                <Form.Group>
                  <Autocomplete
                    multiple filterSelectedOptions id="current-company-job-role"
                    options={allSkills}
                    value={currentComJobRole}
                    getOptionLabel={(item) => item.city}
                    onChange={(e, item) => item ?
                      setCurrentComJobRole(item) : setCurrentComJobRole([])
                    }
                    renderInput={(params) => <TextField {...params} label="Select role" variant="outlined" />}
                  />
                </Form.Group>
              </div>

              <div id="industry_type" >
                <Form.Label >Select Industry Type</Form.Label>
                <Form.Group>
                  <Autocomplete
                    multiple filterSelectedOptions id="industry-type"
                    options={industry}
                    value={currentIndustry}
                    onChange={(e, item) => {
                      item ?
                        setCurrentIndustry(item) : setCurrentIndustry([])
                      console.log(currentIndustry)
                    }
                    }
                    renderInput={(params) => <TextField {...params} label="Select Industry Type" variant="outlined" />}
                  />
                </Form.Group>
              </div>
            </Form.Row>

            <Form.Row>
              <div id="minimum_CTC">
                <Form.Label > Minimum CTC : </Form.Label>
                <Form.Group >
                  <NumberFormat
                    thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'}
                    className="form-control" required
                    value={minCtcFilter || 0}
                    name="minCTC" type="text" placeholder="Minimum CTC (in rupees)"
                    onValueChange={(values) => {
                      const { floatValue } = values; setMinCtcFilter(floatValue)
                    }}
                  />
                </Form.Group>
              </div>

              <div id="maximum_CTC">
                <Form.Label > Maximum CTC : </Form.Label>
                <Form.Group as={Col}>
                  <NumberFormat
                    thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'}
                    className="form-control" required
                    value={maxCtcFilter || 0}
                    name="maxCTC" type="text" placeholder="Maximum CTC (in rupees)"
                    onValueChange={(values) => {
                      const { floatValue } = values; setMaxCtcFilter(floatValue)
                    }}
                  />
                </Form.Group>
              </div>

              <Form.Group id="passout_year">
                <Form.Label >Passout Year</Form.Label>
                <Form.Control as="select"
                  onChange={(e) => { setPassoutYear(JSON.parse(e.target.value).id) }}
                >
                  <option value={JSON.stringify({ "id": null, "passout_year": '--select--' })}>--Select Passout Year--</option>
                  {
                    passoutYears.map((e, key) => {
                      return <option key={key} value={JSON.stringify(e)}> {e} </option>
                    })
                  }
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <div id="gender_details">
                <Form.Group as={Col}>
                  <Form.Label> Select Gender Type</Form.Label>
                  <Form.Control as="select"
                    onChange={(e) => { setGenderType(e.target.value) }}
                  >
                    <option value=''>--Select Gender--</option>
                    <option value='MALE'>Male</option>
                    <option value='FEMALE'>Female</option>
                    <option value='OTHERS'>Others</option>
                  </Form.Control>
                </Form.Group>
              </div>

              <div id="hiring_location">
                <Form.Label>Location</Form.Label>
                <Form.Group>
                  <Autocomplete
                    multiple filterSelectedOptions id="location"
                    options={location}
                    value={currentLocation}
                    onChange={(e, item) => item ?
                      setCurrentLocation(item) : setCurrentLocation([])
                    }
                    renderInput={(params) => <TextField {...params} label="Select locations" variant="outlined" />}
                  />
                </Form.Group>
              </div>

              <div id="education_details">
                <Form.Group as={Col}>
                  <Form.Label> Education Details </Form.Label>
                  <Form.Control as="select"
                    onChange={(e) => { setEducationType(e.target.value) }}
                  >
                    <option value=''>--Select Education Type--</option>
                    <option value='UNDER GRADUATE'>Under Graduate</option>
                    <option value='POST GRADUATE'>Post Graduate</option>
                    <option value='DOCTORATE'>Doctorate</option>
                  </Form.Control>
                </Form.Group>
              </div>

            </Form.Row>

            <div id="button_filter_remove">
              <Button variant="secondary" onClick={e => removeFilters()}>Remove Filters</Button>
              <Button variant="primary" style={{ marginLeft: '10px' }} onClick={e => applyFilters()}>Apply Filters</Button>
            </div>
          </Form>

        </div>
        <TableWithExport
          title="Candidate Information"
          data={searchData}
          columns={col}
          fName="All Candidate Information"
        //editableObject = {edit}
        />
        {/* View Feedbacks */}
        {
          viewFeedBackModal
            ||
            modalFeedbacksData
            ?
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Candidate Feedbacks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Col} controlId="name">
                  <Form.Label >Enter the feedback </Form.Label>
                  <Form.Control
                    name="feedback"
                    value={modalFeedbacksData}
                    type="text"
                    placeholder="Candidate feedback"
                    onChange={e => { setModalFeedbacksData(e.target.value) }}
                    required
                  />
                </Form.Group>
              </Modal.Body>
            </Modal>
            :
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Student Feedbacks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span id='no-feedback'> No Feedback Added !!! </span>
              </Modal.Body>
            </Modal>
        }
      </div>
      :
      <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  )
}

export default withRouter(AllColleges);