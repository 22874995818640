export default function generateCsv(arr, name) {
  let str = "";
  arr.forEach((item, _) => (arr.indexOf(item) !== arr.length - 1 ? (str += item[name] + ", ") : (str += item[name])));
  return str;
}

export function generateCsvNew(arr) {
  let str = "";

  if (arr?.length > 0) {
    arr.forEach((item, _) => (arr.indexOf(item) !== arr.length - 1 ? (str += item + ", ") : (str += item)));
  }
  return str;
}

export function generateCsvWithoutSpacing(arr, name) {
  let str = "";
  arr.forEach((item, _) => (arr.indexOf(item) !== arr.length - 1 ? (str += item[name] + ",") : (str += item[name])));
  return str;
}
