import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

function ColorPicker({color, setColor}) {
  const [state, setState] = useState({
    displayColorPicker: false
  });

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker });
  };
  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  const handleChange = (col) => {
    setColor(col.hex);
  };
  const styles = reactCSS({
    default: {
      color: {
        width: "56px",
        height: "24px",
        borderRadius: "30px",
        background: color,
      },
      swatch: {
        borderRadius: "30px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "relative",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });
  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {state.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}
export default ColorPicker;