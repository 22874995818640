import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Divider, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
  icon: {
    color: "#007bff",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SaveEducation({education, index, setEditEducation, handleDelete, handleClickOpen, setIsEdit, setEditIndex}) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.overview}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {education?.highest_qualification}
          </Typography>
          
          <div style={{ display: "flex" }}>
           
            <IconButton color="primary" onClick = {() => {
                setEditIndex(index);
                setIsEdit(true);
                handleClickOpen();
                setEditEducation(education);
            }}>
              <EditIcon />
            </IconButton>
{!education?.is_current && 
            <IconButton onClick={() => {
              handleDelete(education?.education_id, index)
            }}>
              <DeleteIcon />
            </IconButton>
            }
          </div>
        
        </div>
        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            fontWeight: "bold",
          }}
        >
          {education?.education >2 ? `${education?.college && education?.college !==142 ? education?.college_name : education?.temp_college_name}` : 
          <div>
            {education?.board_name} <br/>
            {education?.college_name ? education?.college_name : ""}
          </div>
          } 
        </Typography>
        {education?.education >2 &&
        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
          }}
        >
          {education?.degree} in {education?.specialization}
        </Typography>
        }
        {education?.enrollment_id && 
        <Typography variant="body2">Enrollment Id: {education?.enrollment_id}</Typography>
        }
        <Typography variant="body2">{education?.is_grade_system ? 'CGPA' : 'Percentage'}: {education?.is_grade_system ?  education?.cgpa : education?.percentage}</Typography>
        <Typography variant="body2">{education?.formatted_start_date} - {education?.formatted_end_date}</Typography>
      </div>
    </>
  );
}
