import React, { useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { Button } from "react-bootstrap";
import { FailureAlert } from "../../util/Notifications";
import { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { apiCall } from "../../util/apiCall";
import {convertISODateFormat} from "../../util/checkDateFilter";
import {
  requestPraveshOptions
} from "../../constants";



function DateSelectorPopup({
  dateRange,
  applyDateFilter,
  removeDateFilter,
  showRemove = true,
  isAdvanceFilter = false,
  advanceFilterDateValue,
  setAdvanceFilterDateValue,
  filters
}) {

  const [valueDate, setValueDate] = useState(dateRange);
  const [showFilter,setShowFilter] = useState(true)
  const [source, setScource] = useState("")
  const [medium, setMedium] = useState("")
  const [campaign, setCampaign] = useState("")
  const [term, setTerm] = useState("")
  const [content, setContent] = useState("")  
  const [options, setOptions] = useState({});
  
  useEffect(() => {
    if(dateRange?.length === 2){
      setValueDate(dateRange)
    }
  },[dateRange])


  const applyFilter = (e) => {
    e.preventDefault();
    if (!valueDate[0] || !valueDate[1]) {
      FailureAlert("Date range not selected correctly!!");
      return;
    }
    applyDateFilter({valueDate, showFilter, source, medium, campaign, term, content});
  };

  const removeFilter = (e) => {
    e.preventDefault();
    
    removeDateFilter(valueDate);
  };

  useEffect(() => {
      setScource(filters?.source);
      setMedium(filters?.medium);
      setCampaign(filters?.campaign);
      setContent(filters?.content);
      setTerm(filters?.term);
  }, []);


  useEffect(() => {
    apiCall(
      `/api/admin/get_all_utm_details/?start_date=${convertISODateFormat(dateRange[0], 'YYYY-MM-DD')}&end_date=${convertISODateFormat(dateRange[1], 'YYYY-MM-DD')}&user_type=1`,
      requestPraveshOptions
    ).then((json) => {
      if(json?.success){
          setOptions(json?.data?.result);
      }
    })
  }, [])

  return (
    <div
    style={{
      zIndex: 100,
      display: "flex",
      flexDirection:"column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: "1rem",
      backgroundColor:"#ffffff",
      borderRadius:"0.5rem",
      boxShadow:"0.1rem 0.1rem 0.5rem 0.3rem rgba(0,0,0,0.3)",
      position:"absolute",
      top:"25%",
      left:"50%",
      transform:"translate(-50%)",
    }}
  >
    <span style={{ margin:"0.5rem 0rem", fontWeight: 700}}>
    <span style={{padding:"7px 5px"}}>Select Date Range :</span>
      <DateRangePicker
      rangeDivider="to"
      value={isAdvanceFilter ? advanceFilterDateValue : valueDate}
      clearIcon={null}
      onChange={isAdvanceFilter ? setAdvanceFilterDateValue : setValueDate}
    />
    </span>
    
    <div style={{fontWeight: 700, width:"100%",margin:"0.5rem 0rem", display:"flex", whiteSpace:"nowrap"}}>
      <span style={{padding:"7px 5px"}}>Select Utm Source :</span>
      <Autocomplete 
            id="utm_source"
            options={options?.utm_source}
            getOptionLabel={(item) => item}
            value={options?.utm_source ? source: null}
            onChange={(e, item) => {
             setScource(item ? item : "");
            }}
            style={{ width: "100%"}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Utm Source"
                placeholder="Utm Source"
                size = "small"
              />
            )}
          />
    </div>
    
    <div style={{fontWeight: 700, width:"100%",margin:"0.5rem 0rem", display:"flex", whiteSpace:"nowrap"}}>
    <span style={{padding:"7px 5px"}}>Select Utm Medium :</span> 
      <Autocomplete
            id="utm_medium"
            options={options?.utm_medium}
            getOptionLabel={(item) => item}
            value={options?.utm_medium ? medium: null}
            onChange={(e, item) => {
              setMedium(item ? item : "");
            }}
            style={{ width: "100%"}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Utm Medium"
                placeholder="Utm Medium"
                size = "small"
              />
            )}
          />
    </div>
    
    <div style={{fontWeight: 700, width:"100%",margin:"0.5rem 0rem", display:"flex", whiteSpace:"nowrap"}}>
    <span style={{padding:"7px 5px"}}>Select Utm Campaign :</span>
      <Autocomplete
            id="utm_campaign"
            options={options?.utm_campaign}
            getOptionLabel={(item) => item}
            value={options?.utm_campaign ? campaign: null}
            onChange={(e, item) => {
              setCampaign(item ? item : "");
            }}
            style={{ width: "100%"}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Utm Campaign"
                placeholder="Utm Campaign"
                size = "small"
              />
            )}
          />
    </div>
    
    <div style={{fontWeight: 700, width:"100%",margin:"0.5rem 0rem", display:"flex", whiteSpace:"nowrap"}}>
    <span style={{padding:"7px 5px"}}>Select Utm Term :</span>
      <Autocomplete
            id="utm_term"
            options={options?.utm_term}
            getOptionLabel={(item) => item}
            value={options?.utm_term ? term: null}
            onChange={(e, item) => {
              setTerm(item ? item : "");
            }}
            style={{ width: "100%"}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Utm Term"
                placeholder="Utm Term"
                size = "small"
              />
            )}
          />
    </div>
    
    <div style={{fontWeight: 700, width:"100%",margin:"0.5rem 0rem", display:"flex", whiteSpace:"nowrap"}}>
    <span style={{padding:"7px 5px"}}>Select Utm Content :</span>
      <Autocomplete
            id="utm_content"
            options={options?.utm_content}
            getOptionLabel={(item) => item}
            value={options?.utm_content ? content: null}
            onChange={(e, item) => {
              setContent(item ? item : "");
            }}
            style={{ width: "100%"}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Utm Content"
                placeholder="Utm Content"
                size = "small"
              />
            )}
          />
    </div>
    <div style={{
      display:"flex",
      gap:"0.5rem",
      justifyContent:"space-between",
    }}>
    {!isAdvanceFilter && (
      <Button
        variant="outline-success"
        //size="sm"
        style={{ alignSelf:"end" }}
        onClick={(e) => {setShowFilter(false)
          applyFilter(e)}}
      >
        Apply Filter
      </Button>
    )}
    { (
      <Button
        variant="outline-danger"
        //size="sm"
        style={{ alignSelf:"end" }}
        onClick={(e) => removeFilter(e)}
      >
        Close
      </Button>
    ) }
    </div>
  </div>
);
}

export default DateSelectorPopup
