import {
  Box, Chip,
  Collapse, Divider,
  Grid, IconButton,
  InputBase,
  makeStyles, Paper,
  Typography
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import ContentCopyIcon from "@material-ui/icons/FileCopyOutlined";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnOutlined";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import clsx from "clsx";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import AssignTable from "./AssignTable";
import DeleteDialog from "./DeleteDialog";
import { copyToClipboard } from "../../../util/copyToClipboard";
import {createGetWorkPublicLink} from "../../../util/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#000",
  },
  chip: {
    backgroundColor: "#C8D8FC",
    margin: theme.spacing(0.5),
    borderRadius: 4,
    borderColor: "#2563EB",
    color: "#000",
  },
  dividerGrid: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export default function InterviewInfo({ data, index, onDelete, onInterviewStatusUpdate }) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const status = data?.interviewStatus;
  function fetchIcon(status) {
    return status === "SCHEDULED" ? (
      <WatchLaterIcon style={{ color: "#48494A", marginRight: 5 }} />
    ) : status === "ACTIVE" ? (
      <NotificationsActiveIcon style={{ color: "#2563EB", marginRight: 5 }} />
    ) : status === "COMPLETED" ? (
      <CheckCircleIcon style={{ color: "#0DA06A", marginRight: 5 }} />
    ) : (
      ""
    );
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      style={{
        boxShadow: "2px 6px 6.800000190734863px 0px #00000026",
        // boxShadow: "0px 0px 4px 2px #00000040",
        borderRadius: "8px",
        marginTop: 15,
        marginBottom: 15,
        backgroundColor: "#fff",
      }}
    >
      <Box
        style={{
          backgroundColor:
            status === "SCHEDULED"
              ? "#DDDDDD"
              : status === "ACTIVE"
              ? "#E0E9FF"
              : status === "COMPLETED"
              ? "#E0FFF4"
              : "",
          padding: 20,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Typography variant="h6">{data?.interviewTitle}</Typography>
            <Typography  onClick={() => {
              window.open(`${createGetWorkPublicLink({job_id: data?.jobId}, "interview")}`, "_blank")
            }} style={{fontSize: "16px", cursor:"pointer", marginTop: 8, color: "#2563EB" }}>
              
              {data?.jobTitle}
              <span style={{ color: "#6C757D", fontSize: "14px" }}>
                {" "}
                at {data?.companyName}{" "}
              </span>
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box
              style={{
                borderRadius: "4px",
                border: "1px solid",
                borderColor:
                  status === "SCHEDULED"
                    ? "#48494A"
                    : status === 2
                    ? "#2563EB"
                    : status === "COMPLETED"
                    ? "#0DA06A"
                    : "",
                width: "fit-content",
                height: "32px",
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                alignItems: "center",
              }}
            >
              {fetchIcon(status)}
              <Typography
                style={{
                  fontSize: "12px",
                  color:
                    status === "SCHEDULED"
                      ? "Scheduled"
                      : status === "ACTIVE"
                      ? "#2563EB"
                      : status === "COMPLETED"
                      ? "#0DA06A"
                      : "",
                }}
              >
                {status === "SCHEDULED"
                  ? "Scheduled"
                  : status === "ACTIVE"
                  ? "Active"
                  : status === "COMPLETED"
                  ? "Completed"
                  : ""}
              </Typography>
            </Box>
            <div>
              <IconButton>
                <DeleteOutlineIcon
                  onClick={handleClickOpen}
                  style={{
                    fontSize: 30,
                    color:
                      status === "SCHEDULED"
                        ? "#6C757D"
                        : status === "ACTIVE"
                        ? "#2563EB"
                        : status === "COMPLETED"
                        ? "#0DA06A"
                        : "",
                  }}
                />
              </IconButton>

              <DeleteDialog
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                onDelete={(index) => onDelete(index)}
                interviewId={data?.interviewId}
                index={index}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box style={{ padding: 15 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "-12px",
          }}
        >
          <Box
            style={{ paddingLeft: 10, display: "flex", alignItems: "center" }}
          >
            <Typography variant="h6" style={{ fontSize: "18px" }}>
              Interview
            </Typography>

            <Box>
              <Box
                style={{
                  marginLeft: 8,
                  borderRadius: "4px",
                  backgroundColor:
                    data?.interviewType === "OFFLINE" ? "#757575" : "#0DA06A",
                  width: "fit-content",
                  height: "32px",
                  paddingLeft: 10,
                  paddingRight: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  {data?.interviewType}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              paddingRight: 30,
            }}
          >
            <Typography variant="body1">
              Scheduled by: {data?.scheduledBy ? data?.scheduledBy : "NA"}
            </Typography>
            <Typography variant="body2" style={{ color: "#6C757D" }}>
              Created date: {data?.createDate}
            </Typography>
            <Typography variant="body2" style={{ color: "#6C757D" }}>
              Job ID: {data?.jobId ? data?.jobId : "NA"}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
          <Grid item md={6} style={{ paddingTop: 0 }}>
            <Paper
              component="form"
              style={{
                border: "1px solid",
                borderColor: "#2563EB",
                backgroundColor: "#C8D8FC",
                boxShadow: "none",
                borderRadius: "8px",
                padding: "2px 4px",
                display: "flex",
                alignItems: "center",
                // width: 400,
              }}
            >
              <InputBase
                style={{
                  marginLeft: 8,
                  flex: 1,
                  color: "#000",
                }}
                // placeholder="https://meet.google.com/wcv-qvev-niw?authuser=1"
                readOnly
                inputProps={{ "aria-label": "search google maps" }}
                value={data?.link ? data?.link : "NA"}
              />

              <IconButton
                style={{ padding: "10px", color: "#007bff" }}
                aria-label="directions"
              >
                {data?.interviewType === "ONLINE" ? (
                  <ContentCopyIcon onClick={(e) => copyToClipboard(data?.link)}/>
                ) : (
                  <LocationOnRoundedIcon />
                )}
              </IconButton>
            </Paper>
          </Grid>

          <Grid item md={12}>
            <Typography variant="h6" style={{ fontSize: "18px" }}>
              Interview Details
            </Typography>

            <div style={{ marginTop: 7 }}>
              <Grid
                container
                alignItems="center"
                className={classes.dividerGrid}
              >
                <div style={{ paddingRight: 40 }}>
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Start Date & Time: {data?.startDate}, {data?.startTime}
                    {/* {data?.start_date
                    ? moment(data?.start_date)?.format("DD-MMM-YYYY")
                    : "NA"} */}
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ color: "#000" }}>
                    End Date & Time: {data?.endDate}, {data?.endTime}
                    {/* {data?.start_date
                    ? moment(data?.start_date)?.format("DD-MMM-YYYY")
                    : "NA"} */}
                  </Typography>
                </div>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ backgroundColor: "#6C757D" }}
                />
                <div
                  style={{
                    paddingRight: 40,
                    paddingLeft: 40,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Hiring Type: {data?.hiringType}
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Round Name: {data?.jobRound?.name}
                  </Typography>
                </div>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ backgroundColor: "#6C757D" }}
                />
                <div
                  style={{
                    paddingRight: 40,
                    paddingLeft: 40,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Total Slot : {data?.totalSlots} Applicants
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ color: "#000" }}>
                    Time Slot : {data?.timeSlot} minutes/appicant
                  </Typography>
                </div>
              </Grid>
            </div>
          </Grid>

          {/* <Grid item md={12} style={{ display: "grid" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "-5px",
              }}
            >
              <InsertInvitationRoundedIcon style={{ color: "#6C757D" }} />
              <Typography
                variant="body2"
                style={{ color: "#6C757D", width: "200px", marginLeft: 10 }}
              >
                {data?.start_date
                  ? moment(data?.start_date)?.format("DD-MMM-YYYY")
                  : "NA"}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AccessTimeIcon style={{ color: "#6C757D" }} />{" "}
                <Typography
                  variant="body2"
                  style={{ color: "#6C757D", width: "200px", marginLeft: 10 }}
                >
                  {data?.end_date
                    ? moment(data?.start_date)?.format("hh:mm A")
                    : "NA"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ChatBubbleOutlineIcon style={{ color: "#6C757D" }} />{" "}
                <Typography
                  variant="body2"
                  style={{ color: "#6C757D", marginLeft: 10 }}
                >
                  Online
                </Typography>
              </div>
            </div>
          </Grid> */}

          <Grid item md={12}>
            <Typography
              variant="h6"
              style={{ marginBottom: 10, fontSize: "18px" }}
            >
              Interview invite
            </Typography>
            <Paper
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                listStyle: "none",
                padding: 4,
                margin: 0,
                marginLeft: "-8px",
                boxShadow: "none",
              }}
              component="ul"
            >
              {data?.interviewInvite?.length > 0 ? (
                data?.interviewInvite?.map((data) => {
                  return (
                    <li key={data}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="medium"
                        label={data}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              ) : (
                <Typography style={{ marginLeft: "4px", color: "#6c757d" }}>
                  NA
                </Typography>
              )}
            </Paper>
          </Grid>

          <Grid item md={12} style={{ display: "grid", paddingTop: 0 }}>
            <Typography
              variant="h6"
              style={{ marginTop: 8, marginBottom: 8, fontSize: "18px" }}
            >
              Interview Description
            </Typography>
            <Typography variant="body2" style={{ color: "#6C757D" }}>
              {data?.description ? ReactHtmlParser(data?.description) : "NA"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          borderTop: "1px solid #B0BEC5",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 8,
          paddingLeft: 44,
          paddingRight: 44,
        }}
      >
        <Typography variant="h6">Applicants Status</Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box style={{ paddingLeft: 40, paddingRight: 40 }}>
          <div style={{ paddingBottom: 20 }}>
            <AssignTable data={data?.studentData}
            onInterviewStatusUpdate={onInterviewStatusUpdate}
            rowIndex={index}
            />
          </div>
        </Box>
      </Collapse>
    </Box>
  );
}
