import { Button, Typography } from "@material-ui/core";
import React from "react";

const ErrorSvg = (
  <svg
    width="142"
    height="142"
    viewBox="0 0 142 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M71.0013 23.667C44.8496 23.667 23.668 44.8487 23.668 71.0003C23.668 97.152 44.8496 118.334 71.0013 118.334C97.153 118.334 118.335 97.152 118.335 71.0003C118.335 44.8487 97.153 23.667 71.0013 23.667ZM76.918 100.584H65.0846V88.7503H76.918V100.584ZM76.918 76.917H65.0846V41.417H76.918V76.917Z"
      fill="#FF2727"
    />
    <path
      d="M70.9395 11.833C38.2795 11.833 11.832 38.3397 11.832 70.9997C11.832 103.66 38.2795 130.166 70.9395 130.166C103.659 130.166 130.165 103.66 130.165 70.9997C130.165 38.3397 103.659 11.833 70.9395 11.833ZM70.9987 118.333C44.847 118.333 23.6654 97.1513 23.6654 70.9997C23.6654 44.848 44.847 23.6663 70.9987 23.6663C97.1504 23.6663 118.332 44.848 118.332 70.9997C118.332 97.1513 97.1504 118.333 70.9987 118.333ZM65.082 88.7497H76.9154V100.583H65.082V88.7497ZM65.082 41.4163H76.9154V76.9163H65.082V41.4163Z"
      fill="#FF2727"
    />
  </svg>
);

function ErrorSection({
  setErrorScreen,
  setSkeleton1,
  setSkeleton2 = () => {},
}) {
  const handleRetry = () => {
    setErrorScreen(false);
    setSkeleton1(true);
    setSkeleton2(true);
  };
  return (
    <div
      style={{
        height: "70dvh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        borderRadius: "10px",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      {ErrorSvg}

      <Typography
        style={{
          fontSize: "24px",
          fontWeight: "600",
          textAlign: "center",
          marginTop: 10,
        }}
      >
        An error occurred while generating the report. <br />
        Please try again later or refresh the page
      </Typography>

      <Button
        variant="contained"
        color="primary"
        style={{ boxShadow: "none", marginTop: 15 }}
        onClick={handleRetry}
      >
        Retry
      </Button>
    </div>
  );
}

export default ErrorSection;
