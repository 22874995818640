import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  gifStyling,
  headerCSS,
  requestPraveshOptions,
} from "../../constants";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { validateDateTime } from "../../util/printNumber";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";
import CloseLead from "./Dialog/CloseLead";
import Status from "./Dialog/Status";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

function CompanyLeads() {
  const [startDate, setStartDate] = useState("");
  let history = useHistory();
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  // value = 0 --> Pending, 1--> Posted, 2 --> Rejected

  const [open, setOpen] = useState(false);
  const [modalRejectJobData, setModalRejectJobData] = useState({});
  const [closeReason, setCloseReason] = useState("");
  const [count, setCount] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const [dialogOpenCloseLead, setDialogOpenCloseLead] = useState(false);

  const dateRange = [new Date(2021, 0, 1), new Date()];

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  const handleClose = () => {
    setOpen(false);
    setModalRejectJobData({});
  };

  const updateList = () => {
    setCount(count + 1);
  };

  const rejectParticularJob = (jobId) => {
    if (!closeReason) {
      FailureAlert("Specify Reason to Reject Job!!");
      return;
    }

    const requestOptionsPost = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        status: "REJECTED",
        reject_reason: closeReason,
      }),
    };

    apiNiyuktiCall(
      `/job/public_job_posting/${jobId}/`,
      requestOptionsPost
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCloseReason("");
      } else {
        FailureAlert(res.error);
      }
      updateList();
      handleClose();
    });
  };

  useEffect(() => {
    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 7)
    );
    let initialEndDate = new Date();

    if (startDate === "") {
      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = new Date(queryParams.from);
      }
      if (queryParams.to) {
        initialEndDate = new Date(queryParams.to);
      }
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      return;
    } else if (startDate === null) {
      initialStartDate = null;
    } else {
      initialStartDate = new Date(startDate);
      initialEndDate = new Date(endDate);
    }

    if (initialStartDate) {
      let month = initialStartDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = initialStartDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${initialEndDate
          .toISOString()
          .substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
    } else {
      history.replace({
        search: "",
      });
    }

    setLoading(true);

    apiCall(`/api/company/leads/`, requestPraveshOptions).then((json) => {
      if (json?.success) {
        let temp = [];
        for (var i = 0; i < json.data.length; i++) {
          const companyLeads = json.data[i];
          let obj = {
            createTime: validateDateTime(companyLeads.create_time),
            companyName: companyLeads.company_name,
            contactPersonName: companyLeads.contact_person_name,
            website: companyLeads.website,
            contactPhone: companyLeads.phone,
            contactEmail: companyLeads.email,
            status: companyLeads.status,
            source: companyLeads.source,
            assigned_to: companyLeads.full_name,
            close_Lead: companyLeads.assigned_user,
            assigned: companyLeads.assigned_user,
            leadID: companyLeads.id,
            slotTime: companyLeads.response,
            utmMedium: companyLeads.utm_medium,
            utmCampaign: companyLeads.utm_campaign,
            utmContent: companyLeads.utm_content,
            utmSource: companyLeads.utm_source,
          };
          temp.push(obj);
        }
        setJobData(temp);
        setLoading(false);
      } else {
        FailureAlert(json?.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      title: "Company Name",
      field: "companyName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 200,
            maxWidth: 200,
            fontSize: 13,
          }}
        >
          {row.companyName}
        </div>
      ),
    },

    {
      title: "POC Name",
      field: "contactPersonName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 200,
            maxWidth: 200,
            fontSize: 13,
          }}
        >
          {row.contactPersonName}
        </div>
      ),
    },
    {
      title: "Website",
      field: "website",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Contact Email",
      field: "contactEmail",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Contact Phone",
      field: "contactPhone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 120,
            maxWidth: 200,
            fontSize: 13,
          }}
        >
          {row.contactPhone}
        </div>
      ),
    },

    {
      title: "Slot Time",
      field: "slotTime",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.slotTime) &&
        Date.parse(term[1]) >= Date.parse(rowData.slotTime),
    },

    {
      title: "Status",
      field: "status",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: {
        PENDING: "PENDING",
        ASSIGNED: "ASSIGNED",
        CLOSED: "CLOSED",
      },
      customFilterAndSearch: (term, rowData) => {
        if (term.length > 0) {
          for (let i = 0; i < term.length; i++) {
            if (rowData?.status?.includes(term[i])) return true;
          }
          return false;
        } else {
          return true;
        }
      },
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 150,
            fontSize: "13px",
            padding: "6px 15px",
          }}
        >
          {row?.status === "ASSIGNED" || row?.status === "CLOSED" ? (
            row?.status === "ASSIGNED" ? (
              "ASSIGNED"
            ) : (
              "CLOSED"
            )
          ) : (
            <Button
              // variant={row?.assigned ?  "outline-success" :  "outline-danger"}
              variant={"outline-danger"}
              size="sm"
              onClick={() => {
                setDialogOpen(true);
                setSelectedRowData(row);
              }}
              style={{
                fontSize: 13,
              }}
            >
              {"Pending"}
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Assigned To",
      field: "assigned_to",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 200,
            fontSize: 13,
          }}
        >
          {row.assigned_to}
        </div>
      ),
    },
    {
      title: "Source",
      field: "source",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Close Lead",
      field: "close_Lead",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 150,
            fontSize: "13px",
            padding: "6px 15px",
          }}
        >
          {row?.status === "CLOSED" ? (
            "Lead Closed"
          ) : (
            <Button
              variant={row?.is_active ? "outline-danger" : "outline-success"}
              size="sm"
              onClick={() => {
                setDialogOpenCloseLead(true);
                //   setDialogOpen(true);
                setSelectedRowData(row);
              }}
              style={{
                fontSize: 13,
              }}
            >
              {"Close Lead"}
            </Button>
          )}
        </div>
      ),
    },

    {
      title: "UTM Source",
      field: "utm_source",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 200,
            fontSize: 13,
            "line-height": "1.25em",
            "max-height": "4em",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 2,
            "word-wrap": "break-word",
          }}
        >
          {row.utmSource}
        </div>
      ),
    },
    {
      title: "UTM Medium",
      field: "utm_medium",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 200,
            fontSize: 13,
            "line-height": "1.25em",
            "max-height": "4em",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 2,
            "word-wrap": "break-word",
          }}
        >
          {row.utmMedium}
        </div>
      ),
    },
    {
      title: "UTM Campaign",
      field: "utm_campaign",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 200,
            fontSize: 13,
            "line-height": "1.25em",
            "max-height": "4em",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 2,
            "word-wrap": "break-word",
          }}
        >
          {row.utmCampaign}
        </div>
      ),
    },
    {
      title: "UTM Content",
      field: "content",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 200,
            fontSize: 13,
            "line-height": "1.25em",
            "max-height": "4em",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 2,
            "word-wrap": "break-word",
          }}
        >
          {row.utmContent}
        </div>
      ),
    },

    {
      title: "Creation Date",
      field: "createTime",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.createTime) &&
        Date.parse(term[1]) >= Date.parse(rowData.createTime),
    },
  ];

  return (
    <div className="open__close__jobs">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="LEADS" {...a11yProps(0)} />
          {/* <Tab label="WORKED" {...a11yProps(1)} /> */}
        </Tabs>
      </AppBar>
      {!loading ? (
        <TabPanel value={value} index={value} dir={theme.direction}>
          <br />
          {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
          <TableWithExport
            title={value === 0 ? "Company's Leads" : "Company's Leads"}
            data={jobData}
            columns={columns}
            fName={value === 0 ? "Company's Leads" : "Company_Leads"}
            cellFixedIndex={0}
          />
        </TabPanel>
      ) : (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      )}

      {/* Reject Jobs Modal*/}
      {modalRejectJobData.jobId ? (
        <Modal
          show={open}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalRejectJobData.jobTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to reject this job ?</p>
            <br />
            <Form>
              <Form.Label>
                Kindly enter the reason to reject this job*
              </Form.Label>
              <Form.Group controlId="closeJobReason">
                <Form.Control
                  name="closeJobReason"
                  type="text"
                  placeholder="Reason to Reject this job goes here..."
                  required
                  onChange={(e) => {
                    setCloseReason(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={(e) => rejectParticularJob(modalRejectJobData.jobId)}
            >
              Close Job
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      <Status
        open={dialogOpen}
        setOpen={setDialogOpen}
        selectedRowData={selectedRowData}
        allData={jobData}
        setAllData={setJobData}
      />
      <CloseLead
        open={dialogOpenCloseLead}
        setOpen={setDialogOpenCloseLead}
        selectedRowData={selectedRowData}
        allData={jobData}
        setAllData={setJobData}
      />
    </div>
  );
}

export default CompanyLeads;
