import {
    Box, Grid,
    styled, Typography
} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState } from 'react';
import SinglePlan from './SinglePlan';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({

    border: ".5px solid",
    width: "350px",
    borderColor: theme.palette.primary.main,
    borderRadius: "30px",
    height: "44px",
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        border: 0,
        "&.Mui-disabled": {
            border: 0,
        },
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const NewToggleButton = styled(ToggleButton)(({ theme }) => ({
    margin: 0,
    width: "175px",
    color: theme.palette.primary.main,
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
    },
}));

const ChoosePlan = ({ planDetails = {}, setPlanDetails = () => { }, handleForward = () => { },
    setCompanyPurchaseDetails }) => {

    const [alignment, setAlignment] = useState("monthly");

    const handleChange = (e, alignment) => {

        setAlignment(alignment);

        setPlanDetails((prevData) => ({ ...prevData, alignment: (alignment === 'yearly' ? 'yearly' : "monthly") }));
        setCompanyPurchaseDetails((prevData) => ({ ...prevData, planDuration: (alignment === 'yearly' ? 'yearly' : "monthly") }))
    };


    return (
        <>
            <Box style={{ backgroundColor: '##EAF4FF' }}>
                <Grid container spacing={4}>
                    <Grid item md={9}>
                        <Typography variant="h4" style={{ textAlign: "center" }}>
                            {/* {selectedPlans?.hireForSurePlanDetails?.main_heading} */}
                            Choose a plan that works best for the company
                        </Typography>
                        <br />
                        {/* <Typography
                            variant="body2"
                            sx={{
                                fontWeight: "400",
                                color: "#6C757D",
                                textAlign: "center",
                            }}
                        >
                            {selectedPlans?.hireForSurePlanDetails?.heading}
                            GetWork Application Tracking System & Profile Unlocks.{" "}
                            <span
                                style={{
                                    color: "secondary",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                }}
                            >
                                {" "}
                                Know More
                            </span>{" "}
                        </Typography> */}

                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                            <StyledToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <NewToggleButton
                                    style={{
                                        margin: 0,
                                        borderTopLeftRadius: "30px",
                                        borderBottomLeftRadius: "30px",
                                        borderTopRightRadius: "0px",
                                        borderBottomRightRadius: "0px",
                                    }}
                                    value="monthly"
                                >
                                    Monthly
                                </NewToggleButton>
                                <NewToggleButton
                                    style={{
                                        margin: 0,
                                        borderTopRightRadius: "30px",
                                        borderBottomRightRadius: "30px",
                                        borderTopLeftRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                    }}
                                    value="yearly"
                                >
                                    Yearly
                                </NewToggleButton>
                            </StyledToggleButtonGroup>
                        </div>
                    </Grid>


                    <Grid item md={12}>
                        <Typography variant="body1" style={{ fontWeight: "600" }}>
                            Non Custom Plans
                        </Typography>
                    </Grid>


                    {planDetails?.plan_list?.map(
                        (value, index) =>
                            value?.name !== "Enterprise" && !value?.is_custom && (
                                <Grid item md={3}>
                                    <SinglePlan
                                        index={index}
                                        heading={value?.name}
                                        monthlyPrice={value?.monthly_pricing}
                                        annualPrice={value?.annual_pricing}
                                        benefits={value?.details?.benefit_list}
                                        alignment={alignment}
                                        handleForward={handleForward}
                                        planDetails={planDetails}
                                        setPlanDetails={setPlanDetails}
                                    />
                                </Grid>
                            )
                    )}

                    <Grid item md={12}>
                        <Typography variant="body1" style={{ fontWeight: "600" }}>
                            Custom Plans
                        </Typography>
                    </Grid>


                    {planDetails?.plan_list?.map(
                        (value, index) =>
                            value?.name !== "Enterprise" && value?.is_custom && (
                                <Grid item md={3}>
                                    <SinglePlan
                                        index={index}
                                        heading={value?.name}
                                        monthlyPrice={value?.monthly_pricing}
                                        annualPrice={value?.annual_pricing}
                                        benefits={value?.details?.benefit_list}
                                        alignment={alignment}
                                        handleForward={handleForward}
                                        planDetails={planDetails}
                                        setPlanDetails={setPlanDetails}
                                    />
                                </Grid>
                            )
                    )}

                </Grid>
            </Box>

        </>
    )
}

export default ChoosePlan