import React, { useState, useEffect } from 'react';
import { apiSanchalanCall } from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import { Form, Col, Button } from 'react-bootstrap';
import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function PlacementRules() {

    const [data, setData] = useState([]);
    const [ruleKey, setRuleKey] = useState(null);
    const [ruleText, setRuleText] = useState(null);
    const [forUser, setForUser] = useState("student");
    const [inputField, setInputField] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiSanchalanCall('/api/settings/tnp_rules/', requestPraveshOptions)
            .then(json => {
                if (json?.success) {
                    let tmpArray = []
                    for (var i = 0; i < json.data.length; i++) {
                        let obj = {
                            id: json.data[i].id,
                            rule_text: json.data[i].rule_text,
                            rule_key: json.data[i].rule_key,
                            is_input: json.data[i].is_input ? 'Yes' : 'No',
                            rule_id: json.data[i].rule_id,
                            user_key: json.data[i].key.toUpperCase()
                        }
                        tmpArray.push(obj)
                    }
                    setLoading(false);
                    setData(tmpArray);
                }
                else { FailureAlert(json.error) }
                setLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "Rule Id", field: "rule_id", headerStyle: headerCSS, cellStyle: cellCSS, editable: 'never' },
        { title: "Rule Key", field: "rule_key",
          headerStyle: headerCSS, 
          cellStyle: cellCSS,
          editable:'never',
          render: (row) => ( <div 
          style={{
               minWidth: 200,
               maxWidth: 200,
               fontSize: 13,
            }}
          >
            {row?.rule_key}
          </div> )
        },
        {
            title: "Rule Text", field: "rule_text", headerStyle: headerCSS, cellStyle: cellCSS, searchable: false,
        },
        {
            title: "Input Field", field: "is_input", headerStyle: headerCSS, cellStyle: cellCSS,
            editComponent: props => (
                <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </Select>
              )
        },
        { title: "User", field: "user_key", headerStyle: headerCSS, cellStyle: cellCSS, editable:'never'},
        // {
        //     headerStyle: headerCSS, cellStyle: cellCSS, export: false,
        //     render: row =>
        //         <div>
        //             <Button variant="outline-danger" size="sm" onClick={e => deleteAccessControl(e, row.id)}>Delete</Button>
        //         </div>
        // },
    ];

    const requestOptionsPost = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            is_input: inputField,
            rule_key: ruleKey,
            rule_text: ruleText,
            key: forUser
        })
    };

    const edit = {
        onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    updateRow(newData, oldData);
                    setData([...dataUpdate]);
                    resolve();
                }, 1000)
            })
    }

    const updateRow = (newData, oldData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({
                ...(oldData.rule_text !== newData.rule_text &&
                    { rule_text: newData.rule_text }
                ),
                ...(oldData.is_input !== newData.is_input &&
                    { is_input: newData.is_input === 'Yes' ? true : false }
                ),
            })
        };
        apiSanchalanCall(`/api/settings/tnp_rules/${oldData.id}/`, requestOptionsPatch)
            .then((res) => {
                if (res?.success) {
                    SuccessAlert(res.data?.message || "Successfully Updated");
                    setInterval(function () {
                        window.location.reload();
                    }, 1000);
                }
                else FailureAlert(res.error);
            })
    }

    const addNewSkill = (e) => {
        e.preventDefault();
        apiSanchalanCall('/api/settings/tnp_rules/', requestOptionsPost)
            .then((res) => {
                if (res?.success) {
                    SuccessAlert(res.data?.message || "Successfully created");
                    setInterval(function () {
                        window.location.reload();
                    }, 1000);
                }
                else FailureAlert(res.error);
            })
        e.target.reset();
    }

    // const deleteAccessControl = (e, accessControlId) => {
    //     apiSanchalanCall(`/api/settings/tnp_rules/${oldData.id}/`, {
    //         method: 'DELETE',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Token ${localStorage.getItem('token')}`
    //         }
    //     })
    //         .then((res) => {
    //             if (res.success) {
    //                 SuccessAlert(res.data?.message);
    //                 setInterval(function () {
    //                     window.location.reload();
    //                 }, 1000);
    //             }
    //             else FailureAlert(res.error);
    //         })
    // }


    return (
        !loading
            ?
            <div className="education__skils">
                <div className="education__skils__form">
                    <h4>Placement Rules</h4>
                    <Form onSubmit={e => addNewSkill(e)}>
                        <Form.Row>
                            {/* <Form.Group as={Col} controlId="id">
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    name="id"
                                    type="text"
                                    placeholder="Enter id"
                                    onChange={e => { setId(e.target.value) }}
                                    required
                                />
                            </Form.Group> */}

                            <Form.Group as={Col} controlId="RuleKey">
                                <Form.Label>Rule Key*</Form.Label>
                                <Form.Control
                                    name="RuleKey"
                                    type="text"
                                    placeholder="Enter rule key"
                                    onChange={e => { setRuleKey(e.target.value) }}
                                    required
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="RuleText">
                                <Form.Label>Rule Text*</Form.Label>
                                <Form.Control
                                    as="textarea" rows='3'
                                    required
                                    value={ruleText}
                                    name="RuleText"
                                    placeholder="Enter rule text"
                                    onChange={e => { setRuleText(e.target.value) }}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="forUser">
                                <Form.Label>Rule For*</Form.Label>
                                <Form.Control
                                    as="select" rows='3'
                                    required
                                    value={forUser}
                                    name="ForUser"
                                    onChange={e => { setForUser(JSON.parse(e.target.value).id) }}
                                    >
                                    {[{"id": "student", "name": "STUDENT"},
                                     {"id": "college", "name": "COLLEGE"},
                                      {"id": "company", "name": "COMPANY"},
                                      {"id": "team_member", "name": "TEAM_MEMBER"}
                                    ].map((e, key) => {
                  return (
                    <option key={key} value={JSON.stringify(e)}>
                      {" "}
                      {e.name}{" "}
                    </option>
                  );
                })}
                                </Form.Control>
                            </Form.Group>
                            
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="inputField">
                                <Form.Label>Input Field: &nbsp;</Form.Label>
                                <Form.Check
                                    inline type="radio" name="inputField"
                                    id="Yes" value="Yes" label="Yes"
                                    checked={inputField}
                                    onChange={e => { setInputField(!inputField) }}
                                />
                                <Form.Check
                                    inline type="radio" name="inputField"
                                    id="No" value="No" label="No"
                                    checked={!inputField}
                                    onChange={e => { setInputField(!inputField) }}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Button type="submit" variant="info">Add Placement Rules</Button>

                    </Form>
                </div>
                <TableWithExport
                    title="Placement Rules"
                    data={data?.sort((a, b) => b.rule_id - a.rule_id)}
                    columns={columns}
                    fName="Selected Placement Rules"
                    editableObject={edit}
                />
            </div>
            :
            <img
                src={gif}
                alt="Getwork Loading Gif..."
                style={gifStyling}
            />
    )
}

export default PlacementRules;