import moment from "moment";

export const checkDateFilter = (currentDate) => {

    let date = new Date();
    let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let endDate = new Date();

    if(currentDate) {
        let newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        let newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 30);
        return `?from=${newStartDate}&to=${newEndDate}`
    }

    return `?from=${startDate}&to=${endDate.toISOString().substring(0,10)}`;
}

export const lastMonth = () =>{
    let date = new Date();
    let startDate = new Date(date.getFullYear(), date.getMonth()-1, 1);
    let endDate = new Date(date.getFullYear(), date.getMonth(), 0);
    return `?from=${startDate}&to=${endDate.toISOString().substring(0,10)}`;

}

export const convertISODateFormat = (initialDate, format) => {
    return moment(initialDate).format(format);
}