import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Dialog, Button, Typography, TextField, CircularProgress } from "@material-ui/core";
import { Button as Button2 } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { FailureAlert } from "../../../util/Notifications";
import CustomButtonCircularProgress from "../../Student/Components/loader";
import { useEffect } from "react";
import { praveshAxiosPostReq } from "../../../util/apiCall";
import { SuccessAlert } from "../../../util/Notifications";
import { praveshAxiosPatchReq } from "../../../util/baseApi";


const useStyles = makeStyles((theme) => ({
    root1: {
        borderRadius: "20px",
        border: ".5px solid #707070",
        boxShadow: "none",
        padding: "20px",
    },
    button: {
        display: "flex",
        borderRadius: "30px",
        boxShadow: "none",
        height: "40px",
        paddingLeft: "40px",
        paddingRight: "40px",

    },
    btngrid: {
        paddingRight: "25px",
        display: "flex",
        paddingLeft: "25px",
        justifyContent: "space-around",
        paddingBottom: "20px",
    },

}));

const CloseLead = ({ open, setOpen, selectedRowData, allData, setAllData }) => {

    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [loadingStatus, setLoadingStatus] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeLead = async () => {

        setLoadingStatus(true);

        const endPoint = `/api/company/leads/new/${selectedRowData?.leadID}/`;
        const assignee = localStorage.getItem("user-data") &&
            JSON.parse(localStorage.getItem("user-data")).id;

        const data = {
            status: "CLOSED",

        };


        try {

            const res = await praveshAxiosPatchReq(endPoint, data);
            if (res?.data?.success) {

                SuccessAlert(res?.data?.data?.message);
                handleClose();

                let tempData = [...allData];
                let rowToUpdate = null;
                tempData.forEach((item, index) => {

                    if (item?.leadID === selectedRowData?.leadID) {
                        rowToUpdate = index;
                    };

                })
                if (rowToUpdate || rowToUpdate === 0) {
                    tempData[rowToUpdate].status = "CLOSED";

                };
                setAllData(tempData);

                setLoadingStatus(false);

            } else {
                FailureAlert("Some Error Occured!");
            }
        }
        catch (error) {
            setLoadingStatus(false);
            throw (error)
        }
    };

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                maxWidth="md"
                PaperProps={{ classes: { root: classes.root1 } }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <div style={{ width: "400px" }}>
                    <div
                        style={{
                            padding: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h6">

                            Are you sure<br />
                            {`you  want to close this Lead?`}
                        </Typography>

                    </div>
                </div>


                <br />

                <div className={classes.btngrid}>
                    <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={handleClose}
                        color="blue"
                    >
                        Cancel
                    </Button>

                    <Button
                        autoFocus
                        className={classes.button}
                        variant="contained"
                        style={{
                            // color: `${blockStatus ? 'white' : }`,
                            color: "white",
                            backgroundColor: `${true ? 'green' : 'red'}`,
                        }}

                        onClick={() => {
                            closeLead();
                        }}
                    >
                        {loadingStatus ? <CustomButtonCircularProgress /> : "Yes"}
                    </Button>
                </div>
                {/* </DialogActions> */}
            </Dialog>

        </>
    )
}

export default CloseLead