import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import AddEducationDialog from "./AddEducationDialog";
import SaveEducation from "./SaveEducation";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
  icon: {
    color: "#007bff",
  },
  font: {
    fontFamily: "",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function Education({
  collegeList,
  boardsList,
  education,
  saveEducation,
  handleAddEdu,
  handlePatchEdu,
  handleDeleteEdu,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tempEduList, setTempEduList] = useState([]);
  const [editEducation, setEditEducation] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    if (education) setTempEduList(education);
  }, [education]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setOpen(false);
    setEditEducation({});
  };

  const saveEducationChange = () => {
    isEdit
      ? handlePatchEdu(
          editEducation,
          editEducation?.education_id,
          editIndex,
          handleClose
        )
      : handleAddEdu(editEducation, handleClose);
  };

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Education</Typography>
        <br />
        {tempEduList &&
          tempEduList.map((item, index) => {
            return (
              <SaveEducation
                education={item}
                index={index}
                setEditEducation={setEditEducation}
                handleDelete={handleDeleteEdu}
                handleClickOpen={handleClickOpen}
                setIsEdit={setIsEdit}
                setEditIndex={setEditIndex}
              />
            );
          })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            color="primary"
            onClick={handleClickOpen}
          >
            Add Education
          </Button>
        </div>
      </div>
      <AddEducationDialog
        collegeList={collegeList}
        boardsList={boardsList}
        open={open}
        handleClose={handleClose}
        editEducation={editEducation}
        setEditEducation={setEditEducation}
        saveEducation={saveEducationChange}
      />
    </>
  );
}
