import { AppBar, Button, Grid, Tab, Tabs } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "15px",
    marginBottom: "20px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const token = localStorage.getItem("token");

function AllGetworkClubService(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const location = useLocation();
  const [userCreatedColleges, setUserCreatedCollges] = useState(
    location.flag && location.flag === 1 ? 1 : 0
  );

  // useEffect(() => console.log(startDate.toISOString()), [startDate]);

  const handleChangeToggle = (event, newValue) => {
    setUserCreatedCollges(newValue);
  };

  const history = useHistory();
  const [dataProducts, setDataProducts] = useState([]);

  const columns = [
    {
      title: "Title",
      field: "title",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      //   filtering: false,
      // lookup: primaryFilter,
      filterPlaceholder: "Title",
      render: (row) => (
        <div
          style={{ color: "green", cursor: "pointer", fontSize: "13px" }}
          onClick={() => {
            history.push("/admin/all-services/" + row?.id);
          }}
        >
          {row.title}
          {/* &nbsp; &nbsp; &nbsp;
          <EditIcon style={{ cursor: "pointer" }} /> */}
        </div>
      ),
    },
    {
      title: "Category",
      field: "category_name",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Category",
    },
    {
      title: "Company Name",
      field: "company_name",
      headerStyle: headerCSS,
      //cellStyle: cellCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
        fontSize: 13,
      },
      // lookup: universitiesFilter,
      filterPlaceholder: "Company",
    },
    {
      title: "Brand Name",
      field: "brand_name",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: 13,
      },
      searchable: false,
      //    lookup: states,
      filterPlaceholder: "Brand Name",
    },

    // {
    //   title: "Tags",
    //   field: "tags",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   //lookup: primaryFilter,
    //   filterPlaceholder: "Tags",
    // },
    // {
    //   title: "Name of Service",
    //   field: "name_service",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   searchable: false,
    //   filtering: false,
    // },
    {
      title: "Link",
      field: "link",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Price(in INR)",
      field: "actual_price",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Discount (in %)",
      field: "discount",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Getwork Price (in INR)",
      field: "gw_price",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Actions",
      field: "actions",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/admin/all-services/" + row?.id);
            }}
          />
          &nbsp;
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteService(row?.id, row);
            }}
          />
        </>
      ),
    },
  ];

  const deleteService = (id, row) => {
    const fd = new FormData();
    fd.append("is_active", false);

    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(
      `/api/getwork_club/product_service/${id}/`,
      requestOptionsPatch
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getProductsNew(row);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const getProducts = (check) => {
    if (!check) setLoading(true);
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(
      `/api/getwork_club/product_service/?segment_id=${
        userCreatedColleges === 0 ? 2 : 1
      }&is_active=1`,
      requestOptionJobId
    )
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            item["company_name"] = item?.vendor?.company_name;
            item["brand_name"] = item?.vendor?.brand_name;
            item["actions"] = "actions";
          });

          setDataProducts(json);
          if (!check) setLoading(false);
        } else FailureAlert("Some error occurred");
        if (!check) setLoading(false);
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
        setLoading(false);
      });
  };

  const getProductsNew = (row) => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(
      `/api/getwork_club/product_service/?segment_id=${row.segment_id}&is_active=1`,
      requestOptionJobId
    )
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            item["company_name"] = item?.vendor?.company_name;
            item["brand_name"] = item?.vendor?.brand_name;
            item["actions"] = "actions";
          });

          setDataProducts(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  useEffect(() => {
    getProducts();
  }, [userCreatedColleges]);

  return (
    <div className="all__colleges">
      <AppBar position="static" color="default">
        <Tabs
          value={userCreatedColleges}
          onChange={handleChangeToggle}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="ALL SERVICES" {...a11yProps(0)} />
          <Tab label="All PRODUCTS" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}>
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{ minWidth: "145px" }}
                  color="primary"
                  onClick={() => {
                    history.push("/admin/all-services/");
                  }}
                  startIcon={<AddIcon className={classes.icon} />}
                >
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>
          <TableWithExport
            title="ALL SERVICES"
            columns={columns}
            data={dataProducts}
            fName="Selected_Colleges_Not_User_Created"
          />
        </TabPanel>
      ) : (
        <TabPanel value={userCreatedColleges} index={1} dir={theme.direction}>
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{ minWidth: "145px" }}
                  color="primary"
                  onClick={() => {
                    history.push("/admin/all-services/");
                  }}
                  startIcon={<AddIcon className={classes.icon} />}
                >
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>

          <TableWithExport
            title="ALL PRODUCTS"
            columns={columns}
            data={dataProducts}
            fName="Selected_Colleges_Not_User_Created"
          />
        </TabPanel>
      )}
    </div>
  );
}

export default withRouter(AllGetworkClubService);
