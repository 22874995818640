import { IconButton, Tooltip, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  gifStyling,
  headerCSS,
  requestPraveshOptions,
} from "../../constants";
import { apiCall } from "../../util/apiCall";
import { praveshAxiosPatchReq } from "../../util/baseApi";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { validateDateTime } from "../../util/printNumber";
import AddTeamMember from "../DialogSection/AddTeamMember/AddTeamMember";
import EditTeamMember from "../DialogSection/AddTeamMember/EditTeamMember";
import TableWithExport from "../TableWithExport";

const token = localStorage.getItem("token");

function TeamGetwork(props) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [isInitialCall, setInitialCall] = useState(true);
  const dateRange = [new Date(2021, 0, 1), new Date()];
  const [open, setOpen] = useState(false);

  const [openEditTeamMember, setOpenEditTeamMember] = useState(false);
  const [teamMemberData, setTeamMemberData] = useState(null);

  useEffect(() => {
    // if its initial call
    // and there is nothing in url then startdate and enddate should be today and t-30
    //  else get it from url
    if (isInitialCall) {
      let initialStartDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      );
      let initialEndDate = new Date();

      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = queryParams.from;
      }
      if (queryParams.to) {
        initialEndDate = queryParams.to;
      }
      setStartDate(new Date(initialStartDate));
      setEndDate(new Date(initialEndDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blockUnblock = async (e, row) => {
    e.preventDefault();
    // console.log(row);

    const newStatus = row?.is_blocked === "Yes" ? false : true;
    const data = {
      user_active: newStatus,
    };
    try {
      const endPoint = `/api/admin/update/custom_user/${row?.user_id}/`;

      const res = await praveshAxiosPatchReq(endPoint, data);

      if (res?.data?.success) {
        SuccessAlert("Action Successful!");
        setCount(count + 1);
      } else {
        FailureAlert(`${res?.data?.error}`);
      }
    } catch (error) {
      throw error;
    }

    // apiCall(
    //   `/api/admin/company/edit/?company_id=${row.company_id}`,
    //   request
    // ).then((res) => {
    //   if (res.success) {
    //     SuccessAlert("Action Successful");
    //     setCount(count + 1);
    //   } else {
    //     FailureAlert(res.error);
    //   }
    // });
  };

  useEffect(() => {
    if (count === 0 && startDate === "") return;

    let dateString = "";
    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      props.history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      dateString = `?start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }
    setLoading(true);
    setInitialCall(false);
    apiCall(`/api/admin/create/custom_user/`, requestPraveshOptions).then(
      (json) => {
        if (json?.success) {
          let tmpArray = [];
          for (var i = 0; i < json.data.length; i++) {
            const accessKeys = (data) => {
              const accessArray = [];

              data?.map((item) => {
                accessArray?.push(item?.sub_menu);
              });

              return accessArray?.toString();
            };

            let obj = {
              id: json.data[i].id ? parseInt(json.data[i].id) : "",
              name: json.data[i].name,
              role: json.data[i].role || "",
              phone: json.data[i].phone,
              designation: json.data[i].designation,
              dateJoined: json.data[i].date_joined,
              user_id: json.data[i].user_id,
              create_time: validateDateTime(json.data[i].create_time),
              access: json.data[i].keys,
              email: json.data[i].email ? json.data[i].email : "",

              access_control: json.data[i]?.keys
                ? accessKeys(json.data[i]?.keys)
                : "",
              is_blocked: json.data[i]?.active ? "Yes" : "No",
              active: json.data[i]?.active,
            };

            tmpArray.push(obj);
          }
          setLoading(false);
          setData(tmpArray);
        } else {
          FailureAlert(json?.error);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate]);

  const handleEditMember = (e, rowData) => {
    setOpenEditTeamMember(true);
    setTeamMemberData(rowData);
  };

  const handleCount = () => {
    setCount(count + 1);
  };

  const columns = [
    {
      title: "User ID",
      field: "user_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
    },
    {
      title: "Name",
      field: "name",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,

      filterCellStyle: {
        overflow: "hidden",
        minWidth: 110,
        maxWidth: 110,
      },
      render: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            placeItems: "center",
            width: "200px",
            fontSize: "13px",
          }}
        >
          <IconButton>
            <EditIcon
              onClick={(e) => {
                handleEditMember(e, row);
              }}
              // data={row}
            />
          </IconButton>
          <Typography
            style={{ fontSize: "12px", color: "#407BFF" }}
          >{`${row?.name}`}</Typography>
        </div>
      ),
    },
    {
      title: "Email",
      field: "email",
      headerStyle: headerCSS,
      filterCellStyle: {
        overflow: "hidden",
        minWidth: 150,
        maxWidth: 150,
      },
      searchable: false,
    },
    {
      title: "User Role",
      field: "role",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 200,
        fontSize: 13,
      },
    },
    {
      title: "Phone Number",
      field: "phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
    },
    {
      title: "Designation",
      field: "designation",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
    },
    {
      title: "Joining Date",
      field: "dateJoined",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
    },
    {
      title: "Access Control",
      field: "access_control",
      headerStyle: headerCSS,
      searchable: false,
      cellStyle:
        //  cellCSS,
        {
          minWidth: 150,
          maxWidth: 200,
          fontSize: 13,
        },
      render: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            placeItems: "center",
            fontSize: "13px",
            cursor: "pointer",
          }}
        >
          <Tooltip title={row.access_control}>
            <Typography noWrap style={{ fontSize: "12px" }}>
              {row.access_control}
            </Typography>
          </Tooltip>
        </div>
      ),
    },

    {
      title: "Status",
      field: "is_blocked",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      export: false,
      filtering: false,
      render: (row) => (
        <div>
          {row.is_blocked === "No" ? (
            <Button
              variant="outline-danger"
              size="sm"
              onClick={(e) => blockUnblock(e, row)}
              style={{
                width: "60px",
                fontSize: 13,
              }}
            >
              Inactive
            </Button>
          ) : (
            <Button
              variant="outline-success"
              size="sm"
              style={{
                width: "60px",
                fontSize: 13,
              }}
              onClick={(e) => blockUnblock(e, row)}
            >
              Active
            </Button>
          )}
        </div>
      ),
    },
  ];

  return !loading ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "10px",
          paddingRight: "20px",
        }}
      >
        <AddTeamMember
          open={open}
          setOpen={setOpen}
          updateTable={handleCount}
        />
      </div>
      <TableWithExport
        title="Team GetWork"
        data={data}
        columns={columns}
        fName="Selected_Companies"
      />

      <EditTeamMember
        open={openEditTeamMember}
        setOpen={setOpenEditTeamMember}
        teamMemberData={teamMemberData}
        updateTable={handleCount}
      />
    </div>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default withRouter(TeamGetwork);
