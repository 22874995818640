import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/StudentsPerCollege.css';
import { headerCSS, cellCSS, gifStyling, createIconStyling, requestPraveshOptions } from '../../constants';
// import { appReactTest } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Button, Form} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import {Modal, Table} from 'react-bootstrap';

function StudentsPerCollege() {

    const [collegeId, setCollegeId] = useState(null);
    const [colleges, setColleges] = useState([]);
    
    // Fetch all Colleges Dropdown
    useEffect(() => {
        apiCall('/api/admin/college/get/', requestPraveshOptions)
        .then((res)=> {
            if(res.success) {
                let collegeNames = {}
                for (var i = 0; i < res.data.length; i++) {
                    let obj = res.data[i]
                    collegeNames[obj.college_id] = obj.college_name;
                }
                setColleges(res.data)
                localStorage.setItem('colleges', JSON.stringify(collegeNames));
            }
            else {
                FailureAlert(res.error)
            }
        })
    }, [collegeId]);


    return (
        <div className="students__per__college">
             <Form className="select__college__form" >
                <h3>College-wise Student Details</h3>
                <hr />
                <Autocomplete
                    id="select-user"
                    options={colleges}
                    getOptionLabel={(item) => item.college_name}
                    style={{ width: '90%', margin: '5%' }}
                    onChange={(e, item)=>item ? setCollegeId(item.college_id) : setCollegeId(null)}
                    renderInput={(params) => <TextField {...params} label="Select College" variant="outlined" />}
                />
            </Form>
            {
                !collegeId ? null : <CollegeDetails clgId = {collegeId} />
            }

        </div>
    )
}

export default StudentsPerCollege;

function CollegeDetails( {clgId} ) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [educations, setEducations] = useState({});   // For Filter purpose
    const [degrees, setDegrees] = useState({});   // For Filter purpose
    const [showModal, setShowModal] = useState(false)
    const [workExperience, setWorkExperience] = useState([])


    const handleClick = (rows)=>{
        window.open(`/admin/student-details/?studentEmail=${rows.email}`)
    }

    const clgName = 
          JSON.parse(localStorage.getItem('colleges'))
          ?
          JSON.parse(localStorage.getItem('colleges'))[clgId] :
          'College Name';

     const handleShowModal = ()=>{
            setShowModal(!showModal)
          }
    
    useEffect(() => {
        setLoading(true);    
        apiCall(`/api/admin/operations/student/education/?college_id=${clgId}`, requestPraveshOptions)
        .then(json => {
          if(json.success) {
            let tmpArray = []
            for (var i = 0; i < json.data.length; i++) {
                let obj = {
                    user_id:          json.data[i]?.user_id,
                    user_name:        json.data[i]?.first_name + ' ' + json.data[i].last_name,
                    email:            json.data[i]?.email,
                    phone:            json.data[i]?.phone,
                    education_level:  json.data[i]?.education_level,
                    degree_name:      json.data[i]?.degree_name,
                    specialization:   json.data[i]?.specialization_name,
                    is_placed:        json.data[i]?.is_placed ? 'Yes' : 'No',   // null or job_id
                    completion_percentage: `${json.data[i]?.completion_percentage}%` ?? 'N/A',
                    preferred_locations : json.data[i]?.preferred_locations ?? 'N/A',
                    current_location : json.data[i]?.current_location ?? 'N/A',
                    pending_set : json.data[i]?.pending_set ?? 'N/A',
                    status : json.data[i]?.status==='' ? 'N/A' : json.data[i]?.status,
                    active : json.data[i]?.is_active ?? 'N/A',
                    experience : json.data[i]?.work_experiecne,
                }
                tmpArray.push(obj)
            }
            setLoading(false);
            setData(tmpArray);
        }
        else {
            FailureAlert(json.error)
        }    
      })
    }, [clgId]);

    // Education Type API
    useEffect(() => {
        apiCall('/api/education/type/', requestPraveshOptions)
        .then((res)=> {
            let obj={}
            res.forEach((e)=> { obj[e.type] = e.type })
            setEducations(obj)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Degrees Finding API
    useEffect(() => {
        apiCall(`/api/admin/degree/all/`, requestPraveshOptions)
        .then((res)=> {
            let obj = {}
            if(res.success) {
              res.data.forEach((degree)=> { obj[degree.degree_name] = degree.degree_name })
              setDegrees(obj)
            }
            else FailureAlert(res.error)  
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const columns = [
        { title: "Student ID",
          field: "user_id", 
          headerStyle: headerCSS, 
          cellStyle: cellCSS
     },
        { title: "Student Name", 
         field: "user_name", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: row => (row.user_name) ? ( <div style={{ minWidth : "250px", fontSize:13, color:"blue", cursor:"pointer"}}
         onClick={ () => {
            handleClick(row)
         }}>
              {row.user_name} </div> ) : null
            

         },
       
        { title: "Email", 
                 field: "email", 
            headerStyle: headerCSS, 
            cellStyle: cellCSS,
          render: row => row.email ? 
              <div>{row.email} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.email)} />
              </div> : null
        },
        { title: "Phone", 
        field: "phone", 
        headerStyle: headerCSS,
        cellStyle: cellCSS,
          render: row => row.phone ? 
            <div style={{
              display:"flex",
              minWidth: 150,
              maxWidth: 200,
            }}>{row.phone} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.phone)} />
            </div> : null
        },
        { title: "Education",      field: "education_level", headerStyle: headerCSS, cellStyle: cellCSS,
        
          searchable: false,       lookup: educations,       filterPlaceholder: 'Select', },
        { title: "Degree",         field: "degree_name",     headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,       lookup: degrees,          filterPlaceholder: 'Select', },
        { title: "Specialization", field: "specialization",  headerStyle: headerCSS, 
        cellStyle: {
                     minWidth: 100,
                     maxWidth: 150,
                     fontSize: 13,
                     overflow:"hidden",
                     maxHeight:50,
                     textOverflow: "ellipsis",
                     whiteSpace: 'nowrap',
        },
          searchable: false },
        { title: "Placed?",       
         field: "is_placed",      
         headerStyle: headerCSS, 
         cellStyle: cellCSS, 
         lookup : {No:"No", "Yes":"Yes"},
         searchable: false,
         filterPlaceholder: 'Select', 
        //   render: row => row.is_placed ? 
        //     <Button 
        //         variant="outline-success" size="sm" 
        //         onClick={() => window.open(`${appReactTest}/job/${row.is_placed}`)}
        //     > Yes </Button>
        //     : 
        //     <div style={{marginLeft:'15px'}}>No</div>
        },
        { title: "Completion Percentage", 
         field: "completion_percentage", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: row => (row.completion_percentage) ? ( <div 
            style={{
                    minWidth : "250px",
                    fontSize:13,
        
        }}>  {row.completion_percentage} </div> ) : null
            

         },
         { title: "Preferred Location", 
         field: "preferred_locations", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: row => (row.preferred_locations) ? ( <div 
            style={{
                    minWidth : "250px",
                    fontSize:13,
        
        }}>  {(row?.preferred_locations ==='N/A')? row?.preferred_locations : row?.preferred_locations.map((item)=>(`${item.city}, `))} </div> ) : null
            

         },
         { title: "Current Location", 
         field: "current_location", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: row => (row.current_location) ? ( <div 
            style={{
                    minWidth : "250px",
                    fontSize:13,
        
        }}>  {row.current_location} </div> ) : null
            

         },
         { title: "Pending Fields Name", 
         field: "pending_set", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: row => (row.pending_set) ? ( <div 
            style={{
                    minWidth : "250px",
                    fontSize:13,
        
        }}>  {row.pending_set.map((item)=>(`${item}, `))} </div> ) : null
            

         },
         { title: "Status", 
         field: "status", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: row => (row.status) ? ( <div 
            style={{
                    minWidth : "250px",
                    fontSize:13,
        
        }}>  {row.status} </div> ) : null
            

         },
         { title: "Active", 
         field: "active", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: row => (row.active) ? ( <div 
            style={{
                    minWidth : "250px",
                    fontSize:13,
        
        }}>  {row.active} </div> ) : null
            

         },
         { title: "Experience", 
         field: "experience", 
         headerStyle: headerCSS,
         cellStyle: cellCSS,
         render: (row) =>
                <Button
                    variant="outline-info"
                    size="sm"
                    style={{
                        fontSize: 13,
                        }}

                    onClick={()=>{handleShowModal()
                    setWorkExperience(row.experience)}}
                >
                    View{console.log(workExperience)}
            </Button>
         },
    ]

    return (<>{
        !loading
        ?
        <div>
            <TableWithExport
                title = {`${clgName} Students`}
                data = {data}
                columns = {columns}
                fName = "Selected_Students"
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
        }
{
        showModal &&
        <Modal style={{position:'absolute', top:'30%'}} show={showModal} onHide={handleShowModal} scrollable={true}>
        <Modal.Header closeButton>
            <Modal.Title>Colleges</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>Currently Working</th>
                        <th>Job Designation</th>
                        <th>Skills</th>
                        <th>Start Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        workExperience.map((m, key) => {
                            return (
                                <tr key={key}>
                                    <td>{m?.company_name ?? 'N/A'}</td>
                                    <td>{m?.is_current_working ?? 'N/A'}</td>
                                    <td>{m?.job_designation ?? 'N/A'}</td>
                                    <td>{m?.skills.map((item)=>(`${item?.skill_name}, `)) ?? 'N/A'}</td>
                                    <td>{m?.start_date ?? 'N/A'}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </Modal.Body>
    </Modal>
}
        </>)
}
