
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ContactsIcon from "@material-ui/icons/Contacts";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupsIcon from '@material-ui/icons/Group';
import AccessControlsIcon from "@material-ui/icons/LockOpenSharp";
import PersonIcon from "@material-ui/icons/Person";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PublicIcon from '@material-ui/icons/Public';
import SchoolIcon from "@material-ui/icons/School";
import TelegramIcon from "@material-ui/icons/Telegram";
import WorkIcon from "@material-ui/icons/Work";
import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { subsectionsToRemove } from "../constants";
import "../css/Sidebar.css";
import { praveshAxiosGetReq } from "../util/apiCall";
import { FailureAlert } from "../util/Notifications";



const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(13),
        flexBasis: "30%",
        flexShrink: 0,
        color: "rgb(163, 158, 158)",
    },
    expanded: {
        "&$expanded": {
            margin: "4px 0",
        },
    },
}));

const panel = localStorage.getItem("panel");

// const userEmail =
//     localStorage.getItem("user-data") &&
//     JSON.parse(localStorage.getItem("user-data")).email;

const SideBarAccessControl = () => {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(panel || "panel1");
    const [sideBarData, setSideBarData] = useState(null);
    const [modifiedSideBarData, setmodifiedSideBarData] = useState(null);

    const history = useHistory();
    const location = useLocation();

    const checkUrlAccessValidity = (path) => {

        let flag = false;
        const check = sideBarData?.map((item) => {

            const check2 = item?.subsection?.map((item2) => {

                if (path.includes(item2?.path) || path.includes("/admin/new-dashboard") || path.includes("/admin/welcome_to_getwork")) {

                    // console.log("Match found!");
                    flag = true;

                } else {
                    // console.log("Match not found!", "path", path, "item path", item2?.path);
                    // FailureAlert("Access Denied for the section!")
                };
            })

        });
        return flag
    }


    useEffect(() => {

        if (sideBarData?.length > 0) {

            const matched = checkUrlAccessValidity(location?.pathname);
            if (!matched) {
                history.push("/admin/new-dashboard");
                // console.log(location?.pathname);
                window.location.reload();
            }
        }

    }, [sideBarData, location?.pathname]);

    useEffect(() => {

        if (sideBarData?.length > 0) {

            // for removing these subsections from the sidebar option

            // const subsectionsToRemove = ["WELCM", "TRACK", "JOBD", "PUBJOB"];

            const temp = sideBarData?.map((item) => {

                const subsection = item?.subsection?.filter((item2) => {

                    if (subsectionsToRemove.includes(item2?.key)) {
                        // return ({ ...item2, selected: !item?.selected })
                        return false
                    } else {
                        return true
                    }
                })
                return (
                    { ...item, subsection: subsection }
                )
            });

            // welcome section is being removed here
            const temp2 = temp?.filter((item) => {

                if (item?.section ===
                    "Welcome Page") {
                    return false
                }
                else {
                    return true
                }

            });

            setmodifiedSideBarData(temp2);
            // setSideBarData(temp)
        }
    }, [sideBarData])


    const handleChange = (panel) => (event, newExpanded) => {
        localStorage.setItem("panel", panel);
        setExpanded(newExpanded ? panel : false);
    };


    const getSideBarData = async () => {

        const endPoint = "/api/admin/sidebar/";

        try {
            const res = await praveshAxiosGetReq(endPoint);
            if (res?.data?.success) {

                setSideBarData(res?.data?.data);

            }
            else {
                FailureAlert("Some Error occured!");
            }
        }
        catch (error) {
            throw (error);
        }
    }

    useEffect(() => {
        getSideBarData();
    }, []);

    const Icons = {

        "EDIT_DEGREE": <EditIcon />,
        "ADD_DEGREE": <AddBoxIcon />,
        "ALL_COLLEGES": <SchoolIcon />,
        "ADD_COLLEGES": <AddBoxIcon />,
        "COLLEGE_EXCEL": <AddBoxIcon />,
        "EDIT_COLLEGE": <EditIcon />,
        "PAYMENT_DETAILS": <TelegramIcon />,
        "ACCOUNT_MANAGER": <PersonIcon />,
        "COLLEGE_STUDENTS": <SchoolIcon />,
        "COLLEGE_JOBS": <WorkIcon />,
        "USER_MANAGEMENT": <PersonIcon />,
        "INBOUND_LEADS": <ContactsIcon />,
        "ADD_COMPANIES": <AddBoxIcon />,
        "EDIT_COMPANIES": <EditIcon />,
        "ALL_COMPANIES": <ApartmentIcon />,
        "EDIT_GROUPS": <EditIcon />,
        "ADD_GROUPS": <AddBoxIcon />,
        "ALL_GROUPS": <SchoolIcon />,
        "SUB_CATAGORY": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "CATAGORY": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "SEGMENTS": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "OPEN_JOBS": <DescriptionIcon />,
        "CLOSED_JOBS": <DescriptionIcon />,
        "POST_JOBS": <DescriptionIcon />,
        "EDIT_JOBS": <EditIcon />,
        "TEMPLATE_VIEW": <DescriptionIcon />,
        "CLOSE_REQUEST": <CloseIcon />,
        "MANAGER_DASH": <DashboardIcon />,
        "ALL_STUDNETS": <PersonIcon />,
        "ADD_STUDNET": <AddBoxIcon />,
        "VAULT_CATAGORY": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "VAULT_SUB_CATAGORY": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "USER_ROLES": <DescriptionIcon />,

        "NOTIFICATION": <AddBoxIcon />,

        "DEGREE_ADD": <AddBoxIcon />,
        "DEGREE_EDIT": <EditIcon />,

        "DEGREE_SPEC_EDIT": <EditIcon />,

        "COLLEGE_EDIT": <EditIcon />,
        "COLLEGE_EXCEL": <AddBoxIcon />,
        "COLLEGE_ALL": <SchoolIcon />,
        "COLLEGE_ADD": <AddBoxIcon />,
        "IN_LEADS": <ContactsIcon />,
        "COLLEGE_USER_MAN": <PersonIcon />,

        "COLLEGE_JOBS": <WorkIcon />,

        "COLLEGE_STUDENTS": <SchoolIcon />,
        "COLLEGE_ACC_MAN": <PersonIcon />,

        "COLLEGE_PAYMENT": <TelegramIcon />,

        "COMPANY_ALL": <ApartmentIcon />,
        "COMPANY_ADD": <AddBoxIcon />,

        "COMPANY_EDIT": <EditIcon />,

        "COMPANY_VERIFY": <EditIcon />,

        "COMPANY_ACC_MAN": <PersonIcon />,
        "COMPANY_USER_MAN": <PersonIcon />,
        "COMPANY_EXCEL": <AddBoxIcon />,
        "7_CLUB_LEADS": <ContactsIcon />,
        "MAN_DASH": <DashboardIcon />,
        "DASH": <DashboardIcon />,
        "POD_CAST": <DashboardIcon />,
        "TEAM": <ApartmentIcon />,
        "GROUP_ALL": <SchoolIcon />,
        "GROUP_ADD": <AddBoxIcon />,
        "GROUP_EDIT": <EditIcon />,
        "STUDENT_EXCEL": <AddBoxIcon />,
        "STUDENT_UPLOAD": <AddBoxIcon />,
        "STUDENT_DETAILS": <PersonIcon />,
        "STUDENT_ALL": <PersonIcon />,

        "STUDENT_SOURCE": <PersonIcon />,

        "STUDENT_BULK_RESUME": <ContactsIcon />,
        "USER_BULK_CREATE": <GroupsIcon />,

        "JOB_OPEN": <DescriptionIcon />,

        "JOB_CLOSED": <DescriptionIcon />,
        "JOB_POST_SALES": <DescriptionIcon />,

        "JOB_POST_PUBLIC": <PublicIcon />,
        "JOB_POST_3_PARTY": <DescriptionIcon />,
        "JOB_POST": <DescriptionIcon />,
        "JOB_EDIT": <EditIcon />,
        "CLONE_JOB": <EditIcon />,

        "JOB_APPLY": <EditIcon />,

        "CREDIT_ADD": <AddBoxIcon />,
        "CREDIT_PAY": <DescriptionIcon />,
        "CREDIT_UNLOCK": <DescriptionIcon />,

        "PASS_CHANGE": <EditIcon />,
        "USER_DETAILS": <EditIcon />,

        "BLOG_UPDATE": <DescriptionIcon />,
        "BANNER_UPDATE": <EditIcon />,
        "IMAGES_UPLOAD": <EditIcon />,

        "SHARER_CATEGORY": <EditIcon />,
        "SHARER_SUB": <EditIcon />,

        "SHARER_SUB_POST": <EditIcon />,
        "ACTIVE_YEARS": <EditIcon />,
        "LOCATION": <DescriptionIcon />,

        "PAGE_TYPE": <DescriptionIcon />,
        "SKILL_EDU": <DescriptionIcon />,

        "SKILL_TYPE": <DescriptionIcon />,
        "SKILL_RATING": <DescriptionIcon />,

        "COMPANY_DOMAIN": <DescriptionIcon />,
        "INDUSTRY": <DescriptionIcon />,

        "UNIVERSITY": <DescriptionIcon />,
        "RECHARGE_UPLOAD": <DescriptionIcon />,

        "CREDITS_SERVICE": <DescriptionIcon />,

        "REFERRAL_CODE": <DescriptionIcon />,

        "COWORKING": <DescriptionIcon />,
        "JOB_DESC": <DescriptionIcon />,

        "JOB_ROUND": <DescriptionIcon />,

        "JOB_STATUS": <DescriptionIcon />,
        "JOB_TYPE": <DescriptionIcon />,

        "JOB_SEGMENT": <DescriptionIcon />,

        "JOB_ROLE": <DescriptionIcon />,

        "JOB_GROUP": <DescriptionIcon />,
        "JOB_EMP_TYPE": <DescriptionIcon />,

        "CONTACT_US": <ContactsIcon />,
        "SAAS_ACCESS": <AccessControlsIcon />,
        "SAAS_PLACE_RULE": <DescriptionIcon />,

        "CLUB_ALL": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "CLUB_ADD": <PostAddIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "CLUB_VENDOR": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "CLUB_VENDOR_ADD": <CreateOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "CLUB_SEGMENTS": <CreateOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "CLUB_CATEGORY": <CreateOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "CLUB_SUB": <CreateOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "CLUB_HISTORY": <PostAddIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "CLUB_BANNER": <CreateOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "CLUB_COUPON": <CreateOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "CLUB_COUPON_ALL": <CreateOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "CLUB_COUPON_ADD": <PostAddIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,
        "VAULT_CATAGORY": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

        "VAULT_SUB": <CategoryOutlinedIcon
            style={{ fontSize: "18px", marginRight: "5px" }}
        />,

    };



    return (
        <>
            <Navbar expand="md" style={{ width: "98vw" }}>
                <Navbar.Toggle aria-controls="basic-sidebar-nav" />
                <Navbar.Collapse className="ml-auto" id="basic-sidebar-nav">
                    <Nav className="sidebar ml-auto">
                        <ul className={classes.root && "sidebar__content"} id="items">
                            {
                                // sideBarData?.map((item) => {
                                modifiedSideBarData?.map((item) => {
                                    return (

                                        <Accordion
                                            classes={{ expanded: classes.expanded }}
                                            square
                                            expanded={expanded === `${item?.section}`}
                                            onChange={handleChange(`${item?.section}`)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                            >
                                                <Typography className={classes.heading}>{item?.section}</Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <ul id="items">
                                                    {item?.subsection?.map((item2) => {
                                                        return (
                                                            // console.log(item2)
                                                            <a href={`${item2?.path}`}>
                                                                <li style={{ display: "flex", margin: "4px" }}>
                                                                    {
                                                                        Icons[item2?.key]
                                                                        // getSubSectionIcon(item2?.key)
                                                                    }
                                                                    <div> {item2?.subsection} </div>
                                                                </li>
                                                            </a>

                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })
                            }
                        </ul>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default SideBarAccessControl