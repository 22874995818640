import React, { useState, useEffect } from 'react';
import '../../css/AddCredits.css';
import { apiCall } from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithoutExport from '../TableWithoutExport';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import { Form, Button, Col } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import gif from '../../assets/loopingcircles-3.gif';
import TextField from '@material-ui/core/TextField';
import { validateDateTime } from '../../util/printNumber';

function AddCredits() {

    const [userType, setUserType] = useState(7);
    const [loading, setLoading] = useState(false);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [amount, setAmount] = useState();
    const [expiry, setExpiry] = useState('');
    const [response, setResponse] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [serviceType, setServiceType] = useState('');
    const [creditStatus, setCreditStatus] = useState(false);
    const [creditValue, setCreditValue] = useState();


    const findUser = (e) => {
        e.preventDefault();
        if (!userEmail) { FailureAlert('Please enter an email to continue...'); return; }
        setTransactions([]);
        setLoading(true);
        apiCall(
            `/api/admin/operations/credit_details/?user_type_id=${userType}&email_id=${userEmail}`,
            requestPraveshOptions
        )
            .then((res) => {
                if (res.success) {
                    setResponse(res.data[0])
                    let flag = res.data[0].credit_status === 'ACTIVE' ? true : false;
                    setCreditStatus(flag)
                }
                else {
                    FailureAlert(res.error);
                    setResponse({})
                }
                setLoading(false);
            })
    }

    const changeAmount = (e) => {
        e.preventDefault();
        if (!amount) { FailureAlert('Please enter credits to add...'); return; }
        if (isNaN(amount)) { FailureAlert('Please enter amount in number format only...'); return; }
        if (!response.user_credit_id) { FailureAlert('Cannot Find Account to add credits...'); return; }
        if (!serviceType) { FailureAlert('Select  a service type'); return; }
        setLoading(true);
        const requestPraveshOptionsPatch = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "available_credits": parseFloat(response.available_credits) + parseFloat(amount),
                "service_type": serviceType,
                "expiry_date": expiry.toISOString().substring(0, 10),
            })
        }
        apiCall(`/api/admin/operations/credit_details/${response.user_credit_id}/`, requestPraveshOptionsPatch)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message)
                    setResponse({});
                    setExpiry('');
                    findUser(e);
                }
                else {
                    FailureAlert(res.error);
                }
                setLoading(false);
            })
    }

    const changeCreditStatus = (e) => {
        e.preventDefault();
        if (!response.user_credit_id) { FailureAlert('Cannot Find Account to add credits...'); return; }
        setLoading(true);
        const requestPraveshOptionsPatch = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "credit_status": !creditStatus ? 'ACTIVE' : 'INACTIVE'
            })
        }
        apiCall(`/api/admin/operations/credit_details/${response.user_credit_id}/`, requestPraveshOptionsPatch)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message)
                    setResponse({})
                    findUser(e);
                }
                else {
                    FailureAlert(res.error);
                }
                setLoading(false);
            })
    }

    const changeCreditValue = (e) => {
        e.preventDefault();
        if (!response.user_credit_id) { FailureAlert('Cannot Find Account...'); return; }
        setLoading(true);
        const requestPraveshOptionsPatch = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "credit_value": creditValue
            })
        }
        apiCall(`/api/admin/operations/credit_details/${response.user_credit_id}/`, requestPraveshOptionsPatch)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message)
                    setResponse({})
                    findUser(e);
                }
                else {
                    FailureAlert(res.error);
                }
                setLoading(false);
            })
    }

    const viewTransactionHistory = (e, id) => {
        e.preventDefault();
        setTransactionLoading(true);
        apiCall(`/api/admin/operations/transaction_history/?user_credit_id=${id}`, requestPraveshOptions)
        .then((res) => {
            if(res.success) {
                let tmpArray = []
                res.data.forEach((i) => {
                    let obj = {
                        amount:             i.amount,
                        available_credits:  i.available_credits,
                        id:                 i.id,
                        service_type:       i.service_type,
                        transaction_type:   i.transaction_type,
                        transaction_id:     i.transaction_id,
                        transaction_date:   validateDateTime(i.transaction_date),
                        credit_status:      i.credit_status,
                        expiry_date:        i.expiry_date || '',
                    }
                    tmpArray.push(obj)
                })  
                setTransactions(tmpArray)
            }
            else {
                FailureAlert(res.error);
            }
            setTransactionLoading(false);
        })
    }

    const creditStatusObj = {
        true: 'ACTIVE',
        false: 'INACTIVE'
    }

    const columns = [
        { title: "Transaction_ID", field: "transaction_id", headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Transaction_Type", field: "transaction_type", headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Amount", field: "amount", headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Service_Type", field: "service_type", headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Available Credits", field: "available_credits", headerStyle: headerCSS, cellStyle: cellCSS, },
        {
            title: "Expiry_Date", field: "expiry_date", headerStyle: headerCSS, cellStyle: cellCSS,
            type: 'date', searchable: false, filterPlaceholder: 'Open Date Picker',
        },
        {
            title: "Transaction_Date", field: "transaction_date", headerStyle: headerCSS, cellStyle: cellCSS,
            type: 'datetime', searchable: false, filterPlaceholder: 'Open DateTime Picker',
        },
    ]

    useEffect(() => {
        if (!serviceType) return;
        if (serviceType === 'RECHARGE') setExpiry(new Date(new Date().setDate(new Date().getDate() + 365)));
        if (serviceType === 'FREE_CREDIT') setExpiry(new Date(new Date().setDate(new Date().getDate() + 14)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceType]);

    return (
        <div>
            <div id="select__user__type">
                <Form.Label>Select User Type* : </Form.Label>
                <Form.Group type="radio" id="institutionType" required>
                    <Form.Check
                        inline defaultChecked name="institutionType" type="radio"
                        label="College" value={7}
                        onChange={e => { setUserType(parseInt(e.target.value)) }}
                    />
                    <Form.Check
                        inline name="institutionType" type="radio"
                        label="Company" value={5}
                        onChange={e => { setUserType(parseInt(e.target.value)) }}
                    />
                </Form.Group>
            </div>
            <div id="main__add__credits__div__container">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Form style={{ width: '75%' }}>
                        <Form.Group controlId="userEmailId" style={{ margin: '6px 10px' }}>
                            <Form.Control
                                name="userEmailId" type="email" placeholder="Enter User's Email Id*"
                                required onChange={e => { setUserEmail(e.target.value) }}
                            />
                        </Form.Group>
                    </Form>
                    <Button style={{ width: '20%', marginRight: '20px' }} onClick={e => findUser(e)} variant="primary">Search User</Button>
                </div>
                {
                    !loading
                        ?
                        response.user_credit_id
                            ?
                            <div>
                                <div id="credit_info" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ justifyContent: 'left' }}>
                                        <Form.Label>{response.institute} Name: <b>{response.institute_name}</b></Form.Label><br />
                                        <Form.Label>Available Credits: <b>{response.available_credits}</b></Form.Label><br />
                                        <Form.Label>Burned Credits: <b>{response.burned_credits}</b></Form.Label><br />
                                        <Form.Label>Expired Credits: <b>{response.expired_credits}</b></Form.Label><br />
                                        <Button
                                            onClick={e => changeCreditStatus(e)}
                                            variant="outline-info"
                                        > Change Credit Status to '{creditStatusObj[!creditStatus]}'
                            </Button>
                                        <br />
                                        <br />
                                        <Button
                                            onClick={e => viewTransactionHistory(e, response.user_credit_id)}
                                            variant="info"
                                        > View Transactions
                            </Button>
                                    </div>
                                    <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                        <Form.Label>Credit Status: <b>{response.credit_status}</b></Form.Label><br />
                                        <Form.Label>Paid Credit : <b>{response.paid_credits}</b></Form.Label><br />
                                        <Form.Label>Free Credit: <b>{response.free_credits}</b></Form.Label><br />
                                        <Form.Label>Credit Value: <b>{response.credit_value}</b></Form.Label><br/>
                                        <TextField
                                            id="standard-number"
                                            label="Enter credit value"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e=>setCreditValue(e.target.value)}
                                        />
                                        <br/>
                                        <br/>
                                        <Button
                                            onClick={e => changeCreditValue(e)}>
                                            Update credit value
                            </Button>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Form style={{ width: '80%', marginTop: '1rem' }}>
                                        <Form.Row>
                                            <Form.Group controlId="amount" as={Col} style={{ margin: '6px 10px' }}>
                                                <Form.Label>Amount:</Form.Label>
                                                <Form.Control
                                                    name="amount" type="number" placeholder="Enter Credits to Add..."
                                                    onChange={e => { setAmount(e.target.value) }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} style={{ marginTop: '6px' }}>
                                                <Form.Label>Service Type:</Form.Label>
                                                <Form.Control as="select" placeholder="Choose Option"
                                                    onChange={e => { setServiceType(e.target.value) }}>
                                                    <option value={''}> Service Type* </option>
                                                    <option value={'RECHARGE'} >RECHARGE</option>
                                                    <option value={'FREE_CREDIT'}>FREE_CREDIT</option>
                                                </Form.Control>
                                            </Form.Group>
                                            {
                                                serviceType && expiry
                                                    ?
                                                    <Form.Group controlId="expiry" as={Col} style={{ margin: '6px 10px' }}>
                                                        <Form.Label>Expiry Date:</Form.Label>
                                                        <Form.Control
                                                            name="expiry" type="date"
                                                            value={expiry.toISOString().substring(0, 10)}
                                                            onChange={e => { setExpiry(new Date(e.target.value)) }}
                                                        />
                                                    </Form.Group>
                                                    :
                                                    null
                                            }

                                        </Form.Row>

                                    </Form>
                                    <Button style={{ width: '15%', marginTop: '2rem', marginRight: '20px' }} onClick={e => changeAmount(e)} variant="success">Add</Button>

                                    {/* {console.log(creditStatus,setCreditStatus)} */}
                                </div>
                                <br /><br />
                            </div>
                            :
                            null
                        :
                        <img
                            src={gif} style={gifStyling}
                            alt="Getwork Logo"
                        />
                }
            </div>
            {
                !transactionLoading
                    ?
                    transactions.length > 0
                        ?
                        <TableWithoutExport
                            title={`Transaction History`}
                            data={transactions}
                            columns={columns}
                        />
                        :
                        null
                    :
                    <CircularProgress
                        style={{ display: 'block', margin: 'auto' }}
                    />
            }
        </div>

    )
}

export default AddCredits
