import { CircularProgress } from "@material-ui/core";
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import SampleRechargeExcel from "../../assets/sample_recharge.xlsx";
import '../../css/Miscellaneous.css';
import { apiCall } from '../../util/apiCall';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

export default function UploadRecharges() {
    const [excel, setExcel] = useState(null);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([])

    const uploadExcel = (e) => {
        e.preventDefault();
        // if (collegeId === null) { FailureAlert("Please select a college to continue"); return; }
        const token = localStorage.getItem('token');
        if (excel === null) { FailureAlert("Upload file..."); return; }
        const ext = excel.name.substring(excel.name.lastIndexOf('.') + 1);
        if (ext !== 'xlsx' && ext !== 'xls') { FailureAlert('Upload Excel File Only...') }
        else {
            const formData = new FormData();
            // formData.append('college_id', collegeId);
            formData.append('file', excel);
            const requestOptionsPost = {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`
                },
                body: formData
            };
            setLoader(true);
            apiCall('/api/admin/upload/recharge/', requestOptionsPost)
                .then((res) => {
                    if (res?.success) {
                        setLoader(false);
                        let arr=[];
                        arr.push(res.data)
                        setData(arr);
                        SuccessAlert('Upload Successful...')
                        window.document.getElementById('add__student__excel__card__form').reset();
                    }
                    else {
                        setLoader(false);
                        FailureAlert(res?.error)
                    }
                })
        }
    }

    return (
        <>
            {loader && (
                <div className="circularLoaderDiv">
                    <div className="circularLoader">
                        <CircularProgress style={{ color: "black" }} size={60} />
                    </div>
                </div>
            )}
            <Card className="add__student__excel__card" style={{ marginBottom: '10px', height: 300, }}>
                <h3>Upload Recharges</h3><br />
                <Form className="add__student__excel__form" id="add__student__excel__card__form" onSubmit={e => { uploadExcel(e) }}>
                    <small>Upload File (Excel Format only)</small>
                    <br />
                    <small>Refer below provided Excel file.</small>
                    <Form.File
                        type="file"
                        onChange={e => { setExcel(e.target.files[0]) }}
                        style={{marginTop: 10}}
                    /><br />
                    <Button type="submit" variant="primary">Update Details</Button>
                </Form>
            </Card>
            {data && (
                <table>
                    <tbody>
                        {
                            data.map((item, index) => {
                                if (item.length) { <tr>Failed Recharge {index}: {item.map(i => <td>{i}</td>)}</tr> }
                            })
                        }
                    </tbody>
                </table>
            )}
            <div className="add__student__response">
                <div style={{ width: "86%", margin: "auto" }}>
                    <h3>Instructions:</h3>
                    i. Excel sheet must include column names:{" "}
                    <b><br />
                        <i>1. company_id, 2. rech_1_amt, 3. rech_1_dt, 4. rech_1_type.</i>
                    </b>
                    .<br />
                    <br />
                    ii. <a
                        href={SampleRechargeExcel}
                        style={{ fontSize: '18px', color: 'blue', backgroundColor: 'transparent' }}
                    >
                        Click here to download sample file.</a>
                    <br />
                    <br />
                </div>
            </div>
        </>
    )
}