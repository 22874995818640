import {
  Button,
  Dialog,
  DialogContent, Grid, TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { praveshAxiosCall } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
  
  const useStyles = makeStyles((theme) => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    chipsection: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      paddingLeft: "0px",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
      marginLeft: 0,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "10px 11px",
    },
    fieldStyling: {
      width: "100%",
      // marginBottom: 20,
    },
    fieldStyling1: {
      width: "100%",
      marginBottom: 10,
    },
  }));
  
  export default function AllVendorFilterDialog(props) {
    const classes = useStyles();
    const fullWidth = true;
    const maxWidth = "xs";
    const [vendor, setVendor] = React.useState([]);
    const [company, setCompany] = React.useState([]);
    const [city, setCity] = React.useState([]);
    const status = [
      {name: "ACTIVE", value: true},
      {name: "INACTIVE", value: false}
    ]

    const [selectedVendorList, setSelectedVendorList] = useState(props?.filters?.selectedVendorList);
    const [selectedCreatedByList, setSelectedCreatedList] = useState(props?.filters?.selectedCreatedList);
    const [selectedCityList, setSelectedCityList] = useState(props?.filters?.selectedCityList);
    const [selectedStatus, setSelectedStatus] = useState(props?.filters?.selectedStatus);


    useEffect(() => {
        getVendorList();
        getCompanyList();
        getCityStateList();
    }, [])
    
    const getVendorList = () => {
      praveshAxiosCall.get(`/api/admin/vendor_lists/`).then((res) => {
        if(res?.data?.success){
          setVendor(res?.data?.data);
        }
        else{
          
          // setAllApiSuccess({status: -1, message: res.error})
        }
      }).catch(err => {
        // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
      })
    }

    const getCompanyList = () => {
      praveshAxiosCall.get(`/api/admin/vendor_lists/`).then((res) => {
        if(res?.data?.success){
          setCompany(res?.data?.data);
        }
        else{
          
          // setAllApiSuccess({status: -1, message: res.error})
        }
      }).catch(err => {
        // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
      })
    }

    const getCityStateList = () => {
      praveshAxiosCall.get(`/api/location/city_state/`).then((res) => {
        if(res?.data?.success){
          setCity(res?.data?.data);
        }
        else{
          
          // setAllApiSuccess({status: -1, message: res.error})
        }
      }).catch(err => {
        // setAllApiSuccess({status: -1, message: `Open company List Error : ${err.message}`})
      })
    }

    const handleClose = () =>{
      props.handleClose()
    }

    const checkValidation = () => {
      if(selectedVendorList?.length < 1){
          FailureAlert("Please select atleast one company or a date range");
          return false;
      }
      return true;
  }
    
    return (
      <React.Fragment>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={props.open}
          //onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <Typography variant="h6">Advance Filters</Typography>
    
            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Autocomplete
                value={selectedVendorList}
                multiple
                onChange={(e, item) => {
                  setSelectedVendorList(item);
                }}
                id="role345"
                options={vendor}
                getOptionLabel={(option) =>
                  `${option.vendor_id}-${option.vendor_name}`
                }
                renderOption={(option) => {
                  return (
                    <span
                      style={{ fontSize: 13 }}
                    >{`${option.vendor_id}-${option.vendor_name}`}</span>
                  );
                }}
                className={classes.fieldStyling1}
                renderInput={(params) => {
                  params.inputProps.className = classes.comboOptions;
                  return (
                    <TextField
                      {...params}
                      label={<span style={{ fontSize: 13 }}> Select Vendor </span>}
                      variant="outlined"
                    />
                  );
                }}
              />
            </div>
            

            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Autocomplete
                value={selectedVendorList}
                multiple
                onChange={(e, item) => {
                  setSelectedVendorList(item);
                }}
                id="role345"
                options={vendor}
                getOptionLabel={(option) =>
                  `${option.vendor_id}-${option.vendor_name}`
                }
                renderOption={(option) => {
                  return (
                    <span
                      style={{ fontSize: 13 }}
                    >{`${option.vendor_id}-${option.vendor_name}`}</span>
                  );
                }}
                className={classes.fieldStyling1}
                renderInput={(params) => {
                  params.inputProps.className = classes.comboOptions;
                  return (
                    <TextField
                      {...params}
                      label={<span style={{ fontSize: 13 }}> Select Created By </span>}
                      variant="outlined"
                    />
                  );
                }}
              />
            </div>
            

            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Autocomplete
                value={selectedCityList}
                multiple
                onChange={(e, item) => {
                  setSelectedCityList(item);
                }}
                id="role345"
                options={city}
                getOptionLabel={(option) =>
                  `${option.city}-${option.state}`
                }
                renderOption={(option) => {
                  return (
                    <span
                      style={{ fontSize: 13 }}
                    >{`${option.city}-${option.state}`}</span>
                  );
                }}
                className={classes.fieldStyling1}
                renderInput={(params) => {
                  params.inputProps.className = classes.comboOptions;
                  return (
                    <TextField
                      {...params}
                      label={<span style={{ fontSize: 13 }}> Select City </span>}
                      variant="outlined"
                    />
                  );
                }}
              />
            </div>
            
            
            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Autocomplete
                value={selectedStatus}
                onChange={(e, item) => {
                  setSelectedStatus(item);
                }}
                id="role345"
                options={status}
                getOptionLabel={(option) =>
                  `${option.name}`
                }
                renderOption={(option) => {
                  return (
                    <span
                      style={{ fontSize: 13 }}
                    >{`${option.name}`}</span>
                  );
                }}
                className={classes.fieldStyling1}
                renderInput={(params) => {
                  params.inputProps.className = classes.comboOptions;
                  return (
                    <TextField
                      {...params}
                      label={<span style={{ fontSize: 13 }}> Status </span>}
                      variant="outlined"
                    />
                  );
                }}
              />
            </div>
            

            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
              <Grid item xs={6} style={{ display: "grid" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  style={{ height: "45px", display: "flex" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: "grid" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "45px", display: "flex" }} 
                  onClick={() => {
                      if(checkValidation()){
                      props?.setFilters({...props?.filters, 
                        selectedVendorList: selectedVendorList,
                        selectedCityList: selectedCityList,
                        selectedStatus: selectedStatus,
                        selectedCreatedByList: selectedCreatedByList
                      })
                      props?.handleClose();
                      }
                  }}
                >
                    Apply
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
  