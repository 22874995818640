import React, { useEffect, useState } from "react";
import AddNewCompany from "./AddNewCompany";
import { Link, useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { praveshAxiosGetReq } from "../../util/baseApi";
import { gifStyling } from "../../constants";
import gif from "../../assets/loopingcircles-3.gif";

const EditNewCompany = () => {
  const [editCompanyFlag, setEditCompanyFlag] = useState(false);
  const location = useLocation();
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      location?.pathname &&
      location?.pathname?.includes("new-edit-company")
    ) {
      setEditCompanyFlag(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (editCompanyFlag) {
      getCompanyList();
    }
  }, [editCompanyFlag]);

  const getCompanyList = async () => {
    const endPoint = "/api/admin/company/get/";
    setLoading(true);

    try {
      const res = await praveshAxiosGetReq(endPoint);

      if (res?.data?.success) {
        setCompanyList(res?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (companyList?.length > 0) {
      let string = location?.search;
      const compId = parseInt(string.replace(/\D/g, ""));

      const matchingCompany = companyList?.filter(
        (company) => company?.company_id === compId
      );

      setCompanyToEdit(matchingCompany[0]);
    }
  }, [companyList]);

  return (
    <>
      {" "}
      <h2>{"Edit New Company"}</h2>
      <hr />
      {!editCompanyFlag ? null : (
        <Autocomplete
          id="newEditCompany"
          name="newEditCompany"
          options={companyList}
          getOptionLabel={(item) => item.company_name}
          value={companyToEdit}
          onChange={(e, newValue) => {
            if (newValue) {
              setCompanyToEdit(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Company*" variant="outlined" />
          )}
        />
      )}
      <br></br>
      {loading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        <AddNewCompany
          editCompanyFlag={editCompanyFlag}
          companyToEdit={companyToEdit}
        />
      )}
    </>
  );
};

export default EditNewCompany;
