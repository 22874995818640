import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import AddPublicationsDialog from "./AddProjectDialog";
import SavePublications from "./SavePublications";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
}));

export default function Publications({publications, handleAddPublications, handlePatchPublications, handleDeletePublications}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tempPublications, setTempPublications] = useState([]);
  const [addPublications, setAddPublications] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    if(publications)
      setTempPublications(publications);
  }, [publications])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setAddPublications({});
    setOpen(false);
  };

  const savePublications = () => {
    isEdit ? handlePatchPublications(addPublications, addPublications?.id, editIndex, handleClose, "Publications") : handleAddPublications(addPublications, handleClose, "Publications");
  }

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Publications</Typography>
        <br />
        {tempPublications && tempPublications?.map((publication, index) => {
          return(
        <SavePublications publications={publication} publiIndex={index} setAddPublications={setAddPublications} handleOpen={handleClickOpen}  handleDelete={handleDeletePublications} setIsEdit={setIsEdit} setEditIndex={setEditIndex}/>
        )
      })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            color="primary"
            onClick={handleClickOpen}
          >
            Add Publications
          </Button>
        </div>
      </div>
      <AddPublicationsDialog open={open} editPublications={addPublications} setEditPublications={setAddPublications} handleClose={handleClose} savePublications={savePublications}/>
    </>
  );
}
