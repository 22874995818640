import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import sampleExcel from '../../assets/College_Upload_Excel.xlsx';
import gif from '../../assets/loopingcircles-3.gif';
import { cellCSS, gifStyling, headerCSS } from '../../constants';
import '../../css/UploadStudentJobApplyExcel.css';
import { apiCall } from '../../util/apiCall';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import TableWithoutExport from '../TableWithoutExport';

const token = localStorage.getItem('token');

function AddCollegesExcel() {    
    const [data, setData] = useState([]);
    const [companyErrorList, setCompanyErrorList] = useState([]);
    const [excel, setExcel] = useState(null);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const uploadExcel = (e) => {
        e.preventDefault();
        if(excel===null) { FailureAlert("Upload file..."); return; }
        const ext = excel.name.substring(excel.name.lastIndexOf('.') + 1);
        if(ext!=='xlsx' && ext!=='xls') { FailureAlert('Upload Excel File Only...') }
        else {
            const formData = new FormData();
            formData.append('file', excel);
            const requestOptionsPost = {
                method: 'POST',
                headers: { 'Authorization': `Token ${token}` },
                body: formData  
            };
            
            setLoading(true)
            //console.log()
            apiCall('/api/admin/college_uploader/', requestOptionsPost)
              .then((res) => {
                  //debugger
                if(res && res.success) {
                    let tmp = []
                   // console.log(res.data.error_list[0]['college_name'])
                    const list1 = res.data.error_list
                   // console.log(list1)
                    tmp.push({error_type: 'existing_users',                             error_count: res.data['existing_users']})
                    tmp.push({error_type: 'failed_transaction_user_ids',                error_count:res.data['failed_transaction_user_ids'].length})
                    tmp.push({error_type:'new_user_created  ',                          error_count:res.data['new_user_created']})
                    tmp.push({error_type:'existing_email',                                error_count:res.data['existing_mail_ids']})
                    //tmp.push({error_type:'error',                                       error_count:res.list1[i]['error']})
                    setCompanyErrorList(list1)
                    setResponse(res.data)
                    setData(tmp)
                   // console.log(tmp)
                    setLoading(false);
                    SuccessAlert('Upload Successful...');
                    window.document.getElementById('add__student__job__excel__card__form').reset();
                }
                else {
                   // console.log(res)
                    FailureAlert("error",res)
                    setLoading(false)
                }
            })
        }
    }  
    const columns = [
        { title: "Error Type",   field: "error_type",     headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Error Count",  field: "error_count",    headerStyle: headerCSS, cellStyle: cellCSS },
    ] 
    const college = [
        { title: "College Name",   field: "college_name",     headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Error",           field: "error",    headerStyle: headerCSS, cellStyle: cellCSS },
    ]    

    return (
        !loading
        ?
        <div>
            <Card className="add__student__job__excel__card" style={{marginBottom:'10px'}}>
                <h3>Upload Colleges</h3><br />
                <Form className="add__student__job__excel__form" id="add__student__job__excel__card__form" onSubmit={e=>{uploadExcel(e)}}>
                    <small>Upload File (Excel Format only)</small><br /><br />
                    <Form.File
                        type="file"
                        onChange={e=> { setExcel(e.target.files[0])}}
                    /><br />
                    <Button type="submit" variant="primary">Upload Details</Button>
                </Form>
            </Card>
            
            <div className="add__student__job__response">
                {
                <div style={{ width: "90%", margin: "auto" }}>
                    <h3>Instructions:</h3>
                    i. Excel sheet must include column names:{" "}
                    <b><br/>
                    <div id="info"><i>
                        1. College Name, 2. Address, 3. POC First Name, 4. POC Last Name, 5. POC Designation, 
                        6. POC Email, 7. POC Phone, 8. Organization Type, 9. Year Founded, 10. Website, 
                        11. City, 12. Job Segments, 13. Logo, 14. University ID (if applicable), 
                        15. University Name (if applicable), 16. Is University ?, 17. Full Time Availability ?,
                        18. Immediate Joining Availability ?, 19. Internship Availability ?, 
                        20. Minimum CTC and 21. Average CTC.
                    </i></div>
                    </b>
                    <br />
                    ii. <a
                        href={sampleExcel}
                        style={{fontSize:'18px', color:'blue', backgroundColor:'transparent'}}    
                    >
                    Click here to download sample file.</a>
                    <br />
                    <br />
                </div>
                
                }
               
                {
                    response
                    ?
                    <div>
                        <div>
                            {/* <h6>Successful Entries: {response.new_user_created || 0}</h6> */}
                            <h6>Total Entries: {response.total_entries || 0}</h6>
                            <br />
                            <TableWithoutExport 
                            title = "Error List"
                            data = {data}
                            columns = {columns}
                            fName = "Selected Errors"
                            search={false}
                            filtering={false}
                            sorting={false}
                            selection={false}
                        />
                        </div>
                        <div>
                            <br />
                            <TableWithoutExport 
                            
                            title = "Error List"
                            data = {companyErrorList}
                            columns = {college}
                            fName = "Selected Errors"
                            search={false}
                            filtering={false}
                            sorting={false}
                            selection={false}

                            />    
                        </div>
                    </div>
                    :
                    null
                
                }
            </div>
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default AddCollegesExcel;