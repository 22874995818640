import { Grid, makeStyles, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../../../constants";
import { praveshAxiosGetReq } from "../../../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../../../util/Notifications";
import CustomButtonCircularProgress from "../../customLoader";


const useStyles = makeStyles((theme) => ({
  newDialog: {
    width: "500px",
  },
  margin: {
    width: "100%",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function UploadResumes(props) {

  const [file, setFile] = React.useState(null);
  const [fileLength,setFileLength] = React.useState(0) 
  const [response,setResponse] = React.useState();
  const [data,setData] = React.useState()
  const [selectedTags, setSelectedTag] = React.useState("");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [totalSize, setTotalSize] = useState(null);

 useEffect(() => {

    const getAllTags = async () => {
      const endPoint = `/api/admin/bulk/get/tags/`;

      try {
        const res = await praveshAxiosGetReq(endPoint);

        if (res?.data?.success) {
          const temp = res?.data?.data.map((item) => {
            return item.name;
          });
          setAllTags(temp);
        } else {
          FailureAlert("Some Error Occurred!");
        }
      } catch (error) {
        FailureAlert("Some Error Occurred!");
        throw error;
      }
    };

    getAllTags();
  }, []);
 
  const uploadFile = (e) => {

    e.preventDefault();    

    // alerts for empty file or empty tags  

    if(selectedTags==="" || selectedTags===null) { FailureAlert("Enter Tag..."); return; }
    if(file===null) { FailureAlert("Upload file..."); return; }

    if(totalSize >= 50){FailureAlert("Upload can't exceed 50Mb!"); return;}
   

  // to avoid hitting of api again on the button.
    if(loading) {

      return;
    };

    {  
      setLoading(true);
      props.handleClose();
      props.setOpenProgressBar(true);

        const formData = new FormData();

        for (var x = 0; x < fileLength; x++)
            formData.append('resumes', file[x]);
            formData.append('tag',selectedTags);
       
            // const requestOptionsPost = {
            // method: 'POST',
            // headers: { 
            //     'Authorization': `Token ${token}`
            // },
            // body: formData,
            // onUploadProgress: progressEvent => console.log(progressEvent),
            // };    

        const UploadData = async() => {

          // const endPoint = "/api/admin/bulk/upload/";

          try{
          //  const  headers = {     
          //   "Content-Type": "application/json",
          //   'Authorization': `Token ${localStorage.getItem("token")}`,                      
          //  }
          
          const config = {

             onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                props?.setLoadedInfo((previousData) => {
                 
              return { ...previousData,loaded:loaded/1024}
                }
                  
                  );
                props?.setLoadedInfo((previousData) => {
                return  {...previousData,total:total/1024}
                } )

                let precentage = Math.floor((loaded * 100) / total);
                // console.log("upload progress percentage:",precentage);
                // console.log(precentage);

                props?.setProgress(precentage);
                if (precentage < 100) {
                  // console.log(precentage);
                  }
                },

            headers : {
            "Content-Type": "application/json",
            'Authorization': `Token ${localStorage.getItem("token")}`,
            }
          }
          
          // const res = await praveshAxiosPostReq(endPoint,formData,config);
          const res = await Axios.post(`${url}/api/admin/bulk/upload/`,formData,config);

            if(res?.data?.success){

                let tmp = []
                tmp.push({ error_type: 'total_entries', 
                error_count: res.data.total_entries})
                setResponse(res.data);
                props.handleClose();
                setSelectedTag(null);
                setFileLength(0);
                setData(tmp);
                SuccessAlert('Upload Successful...');
                props.setOpenProgressBar(false);

                setLoading(false);

                if(!props.fromBulkResume){
                  props.setTagsApiCall();
                };
                props.setShowUploadDialog(false);
                let errorFilesList = res?.data?.data?.['error_list'];
                
                if(errorFilesList.length > 0){

                  errorFilesList.map(item => {

                    FailureAlert(`${item?.name} ${item?.error}`);

                  })

                }              
                

            }
            else{
                FailureAlert(res?.error);
                FailureAlert("Some Error Occurred!");
                setLoading(true);
                props.setOpenProgressBar(false);

            }            
          }
          catch(error){

              FailureAlert("Some Error Occurred!");
              throw(error);
          }
        };

        UploadData();
       
    }
    // history.push("/admin/bulk-resume");
    // window.open(`/admin/all-resume`)
};

const tagsNaming = (e) => { 

const date = new Date();
let minute = date.getMinutes();
let hour = date.getHours();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

// this is for replacing the spaces entered by user with underscore
let replaced = e.split(' ').join('_');
const stamp = ([day, month, year, hour,minute ].join("-"));
const tagName = replaced.concat(`@${stamp}`);
setSelectedTag(tagName);
};

useEffect(() =>{

  let size=0;

  for(let i=0; i < fileLength ;i++ ) {
    
    size = size + file[i].size;
    // console.log(size);
  };
  
  // console.log(size/(1024*1024));
  // console.log((size / 1048576).toFixed(2));

  if((size / 1048576).toFixed(2) > 50){

    FailureAlert("Upload can't exceed 50Mb!");
  };
  setTotalSize((size / 1048576).toFixed(2));

},[file]);

const handleCloseOnUpload = () => {

  if (loading) {

    return props;
  }else{
    return props.handleClose;

  };
}

  return (
    <div>
      <Dialog
        // onClose={props.handleClose}
        onClose={handleCloseOnUpload()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        classes={{
          paper: classes.newDialog,
        }}
      >
        <DialogTitle 
        id="customized-dialog-title" 
        onClose={props.handleClose}
        >
          Upload Resumes
        </DialogTitle>
        <DialogContent style={{ marginTop: "-20px" }}>
          <div>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {/* <Autocomplete
                  value={selectedTags}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedTag(newValue);
                    }
                  }}
                  freeSolo
                  size="small"
                  id="free-solo-2-demo"
                  disableClearable
                  options={tags.map((option) => option)}
                  getOptionLabel={(option) =>`${option.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Create Upload Tag"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onClick={() => {
                        getTagList();
                    }}
                    />
                    
                  )}
                /> */}

               <Autocomplete
                id="select-user"
                size="small"
                options={allTags ? allTags : []}
                getOptionLabel={(item) => item}
                // value={tagName}
                disabled={loading ? true : false}
                freeSolo
                style={{
                  width: "90%",
                  height: "10px",
                  marginRight: "20px",
                  marginBottom:"20px"
                  //  margin: '5%'
                  }}
                onChange={(e, item) => {

                  setSelectedTag(item);                  
                 }}
                  renderInput={(params) => (
                  <TextField {...params} 
                  label="Enter/Select Tag Name" 
                  variant="outlined"
                  onChange={(e) => {
                          tagsNaming(e.target.value);                  
                        }}
                    />
                 )}
                />


              {/* <Form.Group >               */}


                {/* <TextField
                  required
                  style={{ width: "100%" }}
                  // value={selectedTags}
                  disabled={loading ? true : false}
                  size="small"
                  variant="outlined"
                  placeholder={"Enter Tag Name"}
                  inputProps={{
                      maxlength: 75
                    }}
                  onChange={(e) => {
                     tagsNaming(e.target.value);                  
                  }}
                /> */}
              {/* </Form.Group> */}
              </Grid>
              <Grid item md={12}>  
              <form>
                  <input type="file" 
                  multiple accept=".doc, .docx, .pdf" 
                  disabled={loading ? true: false}
                  onChange={e => {
                    
                  setFile(e.target.files);
                  setFileLength(e.target.files.length);                                 
                  }                 
                
                }                   
                  />
              </form>
              </Grid> 
              <Grid item md={12}>
                <Typography variant="body2">
                  Number of Resume : {fileLength}
                  <span style={{ color: "#12CB02" }}>  </span>
                </Typography>
              </Grid>
              {/* <Grid item md={12}>
                <Typography variant="body2">
                  Uploading Error: <span style={{ color: "#C4161C" }}>12</span>
                </Typography>
              </Grid> */}
              <Grid item md={12} style={{ display: "grid" }}>
                {/* <Link
                  href="/admin/all-resume"
                  style={{ textDecoration: "none" }}
                > */}
                  <Button
                    onClick={uploadFile}
                    variant="contained"
                    color="primary"
                    style={{ width: "100%" }}
                  >
                   {loading ? (<CustomButtonCircularProgress />) : ("Upload Now")} 
                  </Button>
                {/* </Link> */}
              </Grid>
            </Grid>
          </div>
        </DialogContent>        
      
      </Dialog>
 
      
    </div>
  );
}
