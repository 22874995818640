import React, {useState} from 'react';
import '../css/Login.css';
import {apiCall} from '../util/apiCall';
import {Form, Button} from 'react-bootstrap';
import { FailureAlert } from '../util/Notifications';


function Login() {

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');

    const validateUser = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                email: email,
                password: password
            })
        };

        apiCall('/api/login/', requestOptions)  
          .then((res)=> {
              if(res.success) {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('login', true);  
                const requestOptionsWithToken = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${res.data.token}`
                    },
                };
                apiCall(`/api/shared/user/?user_type=${res.data.user_type_id? res.data.user_type_id: 9}`, requestOptionsWithToken)
                .then((res)=> {
                    if(res.success) {
                        localStorage.setItem('user-data', JSON.stringify(res.data))
                        window.location.reload();
                    }
                    else { FailureAlert(res.error) }
                }) 
              }
              else {
                FailureAlert("Invalid Credentials")
            } 
          })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        validateUser();
    }


    return (
        <Form className="login__form" onSubmit={(e) => handleSubmit(e)}>
            <Form.Group controlId="formBasicEmail">
                <Form.Control 
                    name="email" 
                    type="email" 
                    placeholder="Email Address" 
                    required 
                    onChange={e=> {setEmail(e.target.value)}} 
                />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Control 
                    name="password" 
                    type="password" 
                    placeholder="Password" 
                    required 
                    onChange={e=> {setPassword(e.target.value)}}
                />
            </Form.Group>
            <Button 
                variant="primary" 
                type="submit" 
            >
                Submit
            </Button>
        </Form>               
    )
}

export default Login;