import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import AddCertificationDialog from "./AddCertificationDialog";
import SaveCertifications from "./SaveCertifications";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "15px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginBottom: "15px",
  },
}));

export default function Ceritfications({cerifications, handleAddCertifications, handlePatchCertifications, handleDeleteCertifications}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tempCertifications, setTempCertifications] = useState([]);
  const [addCertifications, setAddCertifications] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    setTempCertifications(cerifications ? cerifications : []);
  }, [cerifications])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditIndex();
    setIsEdit(false);
    setAddCertifications({});
    setOpen(false);
  };

  const saveCertifications = () => {
    isEdit ? handlePatchCertifications(addCertifications, addCertifications?.id, editIndex, handleClose) : handleAddCertifications(addCertifications, handleClose);
  }

  return (
    <>
      <div className={classes.overview}>
        <Typography variant="h6">Ceritifications</Typography>
        <br />
        {tempCertifications && tempCertifications?.map((certification, index) => {
          return(
        <SaveCertifications certification={certification} certiIndex={index} setAddCertifications={setAddCertifications} handleOpen={handleClickOpen}  handleDelete={handleDeleteCertifications} setIsEdit={setIsEdit} setEditIndex={setEditIndex}/>
        )
      })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            color="primary"
            onClick={handleClickOpen}
          >
            Add Certification
          </Button>
        </div>
      </div>
      <AddCertificationDialog open={open} editCertifications={addCertifications} setEditCertifications={setAddCertifications} handleClose={handleClose} saveCertifications={saveCertifications}/>
    </>
  );
}
