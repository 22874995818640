import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import '../../css/SharerCategory.css';
import {Form, Col, Button} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, CircularProgress} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {TabPanel} from '../../util/TabPanel';
import {Autocomplete} from '@material-ui/lab';
import TableWithExport from '../TableWithExport';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
 
    const token = localStorage.getItem('token');
    
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
          id: `wrapped-tab-${index}`,
          'aria-controls': `wrapped-tabpanel-${index}`,
        };
    } 

function PushNotifications() {

    const [circularLoading, setCircularLoading] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [data, setData] = useState([]);

    const [channel, setChannel] = useState('');
    const [audience, setAudience] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    const channelDict = [ 'ALL', 'APP', 'DESKTOP', 'MWEB' ];
    const audienceDict = [ 'ALL', 'STUDENT', 'COLLEGE', 'COMPANY' ];

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    // Fetching existing notifications
    useEffect(()=> {
        setLoading(true);
        apiCall(`/api/notification/send/`, requestPraveshOptions)
            .then((res)=> {
                setData(res.data)
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "ID",           field: "id",           headerStyle: headerCSS, cellStyle: cellCSS, searchable: false, },
        { title: "Channel",      field: "channel",      headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Audience",     field: "audience",     headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Notification_Title",     field: "title",   headerStyle: headerCSS, cellStyle: cellCSS, 
          render: row => row.title && <span style={{overflowWwrap: 'break-word'}}>{row.title}</span>,
        },
        { title: "Notification_Body",     field: "body",   headerStyle: headerCSS, cellStyle: cellCSS, 
          render: row => row.body && <span style={{overflowWwrap: 'break-word'}}>{row.body}</span>,
        },
        { title: "Create_Time",  field: "create_time",  headerStyle: headerCSS, cellStyle: cellCSS, type: 'datetime', 
          filterPlaceholder: 'Select DateTime' 
        },
        { title: "Update_Time",  field: "update_time",  headerStyle: headerCSS, cellStyle: cellCSS, type: 'datetime', 
          filterPlaceholder: 'Select DateTime' 
        },
    ];

    const addNewNotification = (e) => {
        e.preventDefault();
        if(channel.length<=0) { FailureAlert('Choose Channel to continue...'); return; }
        if(audience.length<=0) { FailureAlert('Choose Audience to continue...'); return; }
        if(title.length<=0) { FailureAlert('Enter Notification Title to continue...'); return; }
        if(body.length<=0) { FailureAlert('Enter Notification Body to continue...'); return; }
        setCircularLoading(true);
        const requestOptionsPost = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}` 
            },
            body: JSON.stringify({
                "channel": channel,
                "audience": audience,
                "body": body,
                "title": title,
            })
        };
        apiCall('/api/notification/send/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setChannel(''); setAudience('');
                setTitle('');   setBody('');
            }
            else FailureAlert(res.error);
            setCircularLoading(false);
        })
    }

    return (
        <div className="update__banner">
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Existing Push Notifications" {...a11yProps(0)} />
                    <Tab label="Add a New Push Notification" {...a11yProps(1)} />
                </Tabs>
            </AppBar>

            {/* Listing existing Notifications */}
            <TabPanel value={value} index={0} dir={theme.direction}> 
                {
                    !loading
                    ?
                    <TableWithExport 
                        title = "Push Notifications"
                        data = {data}
                        columns = {columns}
                        fName = "Selected Notifications"
                    />
                    :
                    <img 
                        src={gif} 
                        alt="Getwork Loading Gif..."
                        style={gifStyling}
                    />
                }        
            </TabPanel>

            {/* Adding a new Notification */}
            <TabPanel value={value} index={1} dir={theme.direction}>
            {
                !circularLoading
                ?
                <Form id="push_notification_add" onSubmit={e=>addNewNotification(e)}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Autocomplete
                                id="select-channel"
                                options={channelDict}
                                getOptionLabel={(item) => item}
                                onChange={(e, i)=>i ? setChannel(i) : setChannel(null)}
                                renderInput={(params) => <TextField {...params} label="Select Channel" variant="outlined" />}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Autocomplete
                                id="select-audience"
                                options={audienceDict}
                                getOptionLabel={(item) => item}
                                onChange={(e, i)=>i ? setAudience(i) : setAudience(null)}
                                renderInput={(params) => <TextField {...params} label="Select Audience" variant="outlined" />}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="title">
                        <Form.Label>Notification Title*</Form.Label>
                        <Form.Control 
                            name="title" type="text" placeholder={`Enter Notification Title ...`}
                            required
                            onChange={e=>setTitle(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="body">
                        <Form.Label>Notification Body*</Form.Label>
                        <Form.Control 
                            name="body" as="textarea" rows='3' placeholder={`Enter Notification Body ...`}
                            required
                            onChange={e=>setBody(e.target.value)}
                        />
                    </Form.Group>
                    <Button id="publish_notification" type='submit'>Publish Notification</Button>       
                </Form>
                :
                <CircularProgress 
                    style={{display:'block', margin:'auto', marginTop:'150px'}}
                />
            }     
            </TabPanel>
        </div>
    )
}

export default PushNotifications;