import React from "react";
import MaterialTable from "material-table";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#fae570",
    },
  },
});

function TableWithoutExport({
  title,
  data,
  columns,
  fName,
  actionArray,
  editableObject,
  search = true,
  sorting = true,
  selection = true,
  filtering = true,
  pageSize = 5,
  pageSizeOptions = [5, 10, 20, 50, 100, 200],
  loading = false,
}) {
  //debugger
  return (
    <div className="table__with__export">
      <ThemeProvider theme={theme}>
        <MaterialTable
          isLoading={loading}
          title={title}
          data={data}
          columns={columns}
          options={{
            search: search,
            sorting: sorting,
            selection: selection,
            filtering: filtering,
            paging: true,
            pageSize: pageSize,
            pageSizeOptions: pageSizeOptions,
          }}
          actions={actionArray}
          editable={editableObject}
          localization={{
            header: {
              actions: "",
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
}

export default TableWithoutExport;
