import { DataGrid } from '@material-ui/data-grid'
import React from 'react'
import { useState } from 'react'
import { praveshAxiosGetReq } from '../../util/apiCall'
import { useEffect } from 'react'
import SlotCard from '../../components/SlotCard'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from '../../constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function SlotDetail() {
    const [row,setRow] = useState([])
    const [slotCard,setSlotCard] = useState([])
    const { company_id } = useParams()
    const { company_name } = useParams()
    const [loading,setLoading] = useState(false)
    const history = useHistory()

    useEffect(()=>{
        fetchTableData()
        fetchCardData()
    },[])

    const fetchTableData = async()=>{
        const endpoint = `/api/admin/company/plan_details/slot_history/?company_id=${company_id}`
        try{
            setLoading(true)
        const res = await praveshAxiosGetReq(endpoint)
        if(res?.data?.success){
            const data = res?.data?.data?.results
            console.log(data)
            setRow(data.map((item,i)=>(
                {
                    id:item?.job_id?item?.job_id:(i+1),
                    transaction_type: item?.transaction_type,
                    plan_name:item?.plan_name,
                    no_of_slots:item?.no_of_slots,
                    remaining_slots:item?.remaining_slots,
                    posted_job:"yes",
                   // posted_job:item?.posted_job?item?.posted_job:"JOB POST",
                    transaction_by:item?.transaction_by,
                    transaction_on:item?.transaction_on,
                }
            )))
        }
    }
    catch(error){
        console.log("Error : ",error)
    }
    setLoading(false)
    }

    const fetchCardData = async()=>{
        const endPoint =  `/api/admin/company/plan_details/slots/?company_id=${company_id}`
        try{
            setLoading(true)
            const res = await praveshAxiosGetReq(endPoint)
            if(res?.data?.success){
                const data = res?.data?.data
                setSlotCard(data.map((item)=>(
                    {
                        heading:item?.slot_name,
                        experience:item?.slot_details?.message,
                        bookedSlots:item?.total_vacancies,
                        availableSlots:item?.remaining_vacancies,
                    }
                )))
            }
        }
        catch(error){
            console.log('Error 1 : ',error)
        }
        setLoading(false)
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Transaction ID',
            width: 200
        },
        {
            field: 'transaction_type',
            headerName: 'Transaction Type',
            width: 200
        },
        {
            field: 'plan_name',
            headerName: 'Plan Name',
            width: 250
        },
        {
            field: 'no_of_slots',
            headerName: 'No. of Slots',
            width: 200
        },
        {
            field: 'remaining_slots',
            headerName: 'Remaining Slots',
            width: 200
        },
        {
            field: 'posted_job',
            headerName: 'Posted Job',
            width: 200
        },
        {
            field: 'transaction_by',
            headerName: 'Transaction By',
            width: 200
        },
        {
            field: 'transaction_on',
            headerName: 'Transaction On',
            width: 200
        },
    ]
    

  return (<>
    {       loading ? <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} /> :
        (<div>
     <p>
        <a
          style={{ backgroundColor: "#f8f9fa" }}
          onClick={() => history.push(`/admin/plan-unlock-history/?company_id=${company_id}`)}
        >
          My Plans
        </a>{" "}
        / <span className="company-name">{company_name}</span>
      </p>
     <h2>Booked Slots Overview</h2>
     <SlotCard
     slotCard ={slotCard}
     />
     <h2>Slot History</h2>
        <div style={{height:"25rem", width:"100%"}}>
            <DataGrid
            rows={row}
            columns={columns}/>
        </div>
    </div>)}
    </>
  )
}

export default SlotDetail
