import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";

export default function AddCategoryDialog({
  open,
  handleClickOpen,
  handleClose,
  category,
  setCategory,
  addCategory,
  segment,
  setSegment,
  updateCategory,
  currentRow,
  edit,
  setCurrentRow,
  setEdit,
  status,
  setStatus,
}) {

  const handleChangeMap = (e, item, index) => {
    if (e.target.checked) {
      var arr = [...segment];
      if (!segment.includes(item?.id)) {
        arr.push(item?.id);
        setSegment(arr);
      }
    } else {
      var arr = [...segment];
      if (segment.includes(item?.id)) {
        index = arr.findIndex((i) => i === item?.id);
        arr.splice(index, 1);
        setSegment(arr);
      }
    }
  };
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const [dataSegement, setDataSegment] = useState([]);
  const getSegments = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/segment/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataSegment(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  useEffect(() => {
    getSegments();
  }, []);
  useEffect(() => {
    console.log(segment);
  }, [segment]);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle id="form-dialog-title">{edit ? "Edit" : "Add"} Category</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates occasionally.</DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="name"
            label="Enter your Category Name"
            fullWidth
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          />
          <br />
          <FormControl component="fieldset" style={{ marginTop: 20 }}>
            <FormLabel component="legend">Select Segment</FormLabel>
            {/* <RadioGroup aria-label="gender" name="gender1" value={segment} onChange={handleChange} row>
              <FormControlLabel value="SERVICE" control={<Radio />} label="Service" />
              <FormControlLabel value="PRODUCT" control={<Radio />} label="Product" />
            </RadioGroup> */}

            <FormGroup>
              {dataSegement?.map((item, index) => (
                <FormControlLabel control={<Checkbox checked={segment?.includes(item?.id)} onChange={(e) => handleChangeMap(e, item, index)} name="gilad" />} label={item?.name} />
              ))}
            </FormGroup>
          </FormControl>
          <br />
          <FormControl component="fieldset" style={{ marginTop: 20 }}>
            <FormLabel component="legend">Active/Inactive</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={status} onChange={handleChangeStatus} row>
              <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
              <FormControlLabel value="INACTIVE" control={<Radio />} label="Inactive" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setCurrentRow(null);
              setEdit(false);
              handleClose();
              setCategory("");
              setSegment([]);
              setStatus(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!category) {
                FailureAlert("Please Enter A Category");
                return;
              }
              if (!segment) {
                FailureAlert("Please select a segment");
                return;
              }
              if (edit) updateCategory(currentRow?.id);
              else addCategory();

              handleClose();
            }}
            color="primary"
          >
            {edit ? "Edit" : "Add"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
