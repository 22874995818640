import FileCopyIcon from "@material-ui/icons/FileCopy";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import default_logo from "../../assets/default_logo.png";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  createIconStyling,
  gifStyling,
  headerCSS,
  requestPraveshOptions,
} from "../../constants";
import { apiCall } from "../../util/apiCall";
import { copyToClipboard } from "../../util/copyToClipboard";
import { FailureAlert } from "../../util/Notifications";
import { validateDateTime } from "../../util/printNumber";
import { TabPanel } from "../../util/TabPanel";
import { DateSelector } from "../common/DateSelector";
import TableWithExport from "../TableWithExport";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function AllGroups(props) {
  const [states, setStates] = useState({}); // For Filter purpose
  // const [coursesFilter, setCoursesFilter] = useState({});   // For Filter purpose

  const [loading, setLoading] = useState(false);
  const [isInitialCall, setInitialCall] = useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateFiltersRemoved, setDateFiltersRemoved] = useState(false);

  const [data, setData] = useState([]);

  // useEffect(() => console.log(startDate.toISOString()), [startDate]);
  useEffect(() => {
    // if its initial call
    // and there is nothing in url then startdate and enddate should be today and t-30
    //  else get it from url
    if (isInitialCall) {
      let initialStartDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      );
      let initialEndDate = new Date();

      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = queryParams.from;
      }
      if (queryParams.to) {
        initialEndDate = queryParams.to;
      }
      setStartDate(new Date(initialStartDate));
      setEndDate(new Date(initialEndDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch all colleges details
  useEffect(() => {
    // if datefilters are removed let the code execute without dates
    if (!dateFiltersRemoved && !startDate) {
      return;
    }

    let dateString = "";

    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      props.history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      dateString = `&start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }

    setLoading(true);
    setInitialCall(false);
    apiCall(`/api/admin/education_group/`, requestPraveshOptions).then(
      (json) => {
        if (json.success) {
          let temp = [];
          for (var i = 0; i < json.data.length; i++) {
            let obj = {
              group_id: json.data[i].group_id,
              group_name: json.data[i].group_name,
              poc_name: json.data[i].tpo_details
                ? json.data[i].tpo_details.first_name +
                  " " +
                  json.data[i].tpo_details.last_name
                : "",
              poc_email: json.data[i].tpo_details
                ? json.data[i].tpo_details.email
                : "",
              poc_phone: json.data[i].tpo_details
                ? json.data[i].tpo_details.phone
                : "",
              alternate_email: json.data[i].tpo_details?.alternate_email
                ? json.data[i].tpo_details.alternate_email
                : "",
              alternate_phone: json.data[i].tpo_details?.alternate_phone
                ? json.data[i].tpo_details.alternate_phone
                : "",
              poc_designation: json.data[i].tpo_details
                ? json.data[i].tpo_details.job_title
                : "",
              last_login:
                json.data[i].tpo_details && json.data[i].tpo_details.last_login
                  ? validateDateTime(json.data[i].tpo_details.last_login)
                  : "",
              country: json.data[i].country,
              state: json.data[i].state,
              city: json.data[i].city,
              logo: json.data[i].logo ? json.data[i].logo : null,
              address: json.data[i].address ? json.data[i].address : "",
              is_saas: json.data[i].is_saas ? "Yes" : "No",
              domain: json.data[i].domain,
              website: json.data[i].website,
              is_paid: json.data[i].is_paid ? "Yes" : "No",
              is_paid_date: json.data[i].is_paid_date
                ? validateDateTime(json.data[i].is_paid_date)
                : "",
              trial_duration:
                json.data[i]?.trial_period_end &&
                json.data[i]?.trial_period &&
                validateDateTime(json.data[i]?.trial_period) +
                  " - " +
                  validateDateTime(json.data[i]?.trial_period_end),
              amount: json.data[i].amount ? parseInt(json.data[i].amount) : "",
              demo_given: json.data[i].demo_given ? "Yes" : "No",
              demo_date: json.data[i].demo_date
                ? validateDateTime(json.data[i]?.demo_date)
                : "",
              mou_sign_date: json.data[i].mou_sign_date
                ? validateDateTime(json.data[i]?.mou_sign_date)
                : "",
              invoice_send_date: json.data[i].invoice_send_date
                ? validateDateTime(json.data[i]?.invoice_send_date)
                : "",
              is_active: json.data[i].is_active ? "Yes" : "No",
              total_affiliated_college: json.data[i].total_affiliated_college
                ? parseInt(json.data[i].total_affiliated_college)
                : "",
              year_founded: json.data[i].year_founded,
            };
            // collegeNames[obj.college_id] = obj.college_name;
            temp.push(obj);
          }
          setData(temp);
          setLoading(false);
        } else {
          FailureAlert(json.error);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // State Finding API
  useEffect(() => {
    apiCall(`/api/location/state/?search=1`, requestPraveshOptions).then(
      (res) => {
        let obj = {};
        res.forEach((state) => {
          obj[state.state] = state.state;
        });
        setStates(obj);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Group_ID",
      field: "group_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
    },
    {
      title: "Group_Name   ",
      field: "group_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            placeItems: "center",
          }}
        >
          {row.logo ? (
            <img
              src={row.logo}
              alt="logo"
              width="30px"
              height="30px"
              style={{ borderRadius: "50%", marginRight: "3px" }}
            />
          ) : (
            <img
              src={default_logo}
              alt="logo"
              width="30px"
              height="30px"
              style={{ borderRadius: "50%", marginRight: "3px" }}
            />
          )}
          {`${row.group_name}`}
        </div>
      ),
    },
    {
      title: "Website",
      field: "website",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "Address",
      field: "address",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "City",
      field: "city",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "State",
      field: "state",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: states,
      filterPlaceholder: "Select",
    },
    {
      title: "Country",
      field: "country",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
    },
    {
      title: "POC_Name",
      field: "poc_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "POC_Email",
      field: "poc_email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "POC_Phone",
      field: "poc_phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.poc_phone ? (
          <div>
            {row.poc_phone}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.poc_phone)}
            />
          </div>
        ) : null,
    },
    {
      title: "Last_Login",
      field: "last_login",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
    },
    {
      title: "POC_Designation",
      field: "poc_designation",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "College_List",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
      export: false,
      render: (row) => (
        <div>
          <Link
            target="_blank"
            className="viewBoxLink"
            to={`/admin/group/college-list/${row.group_id}`}
          >
            View
          </Link>
        </div>
      ),
    },
    {
      title: "No._of_Affiliated college",
      field: "total_affiliated_college",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "GetWork_Domain",
      field: "domain",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "SAAS College",
      field: "is_saas",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "Active",
      field: "is_active",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "Invoice send_date",
      field: "invoice_send_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Mou sign_date",
      field: "mou_sign_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Demo_given",
      field: "demo_given",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "Demo_date",
      field: "demo_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Trial_Period_date",
      field: "trial_duration",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Paid",
      field: "is_paid",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "Payment_date",
      field: "is_paid_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Amount",
      field: "amount",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
  ];

  let col = [...columns];
  col.push({
    title: "Excel Uploaded?",
    field: "is_admin_created",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    lookup: { Yes: "Yes", No: "No" },
    searchable: false,
    filterPlaceholder: "Yes/No",
  });

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
    }
  };

  const removeDateFilter = () => {
    setDateFiltersRemoved(true);
    setStartDate(null);
    setEndDate(null);
  };

  return !loading ? (
    <div className="all__colleges">
      <DateSelector
        dateRange={[startDate, endDate]}
        applyDateFilter={applyDateFilter}
        removeDateFilter={removeDateFilter}
      />
      <TableWithExport
        title="All Groups Details"
        data={data.sort((a, b) => b.group_id - a.group_id)}
        columns={columns}
        fName="Selected_Groups"
      />
    </div>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default withRouter(AllGroups);
