import { Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useState } from "react";
import {
  praveshAxiosGetReq,
  praveshAxiosPatchReq,
  praveshAxiosPostReq,
} from "../../util/baseApi";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import NewCompanySpoc from "./NewCompanyStepper/NewCompanySpoc";
import CompanyBasicInfo from "./NewCompanyStepper/CompanyBasicInfo";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import {
  emailRegex,
  latitudeRegex,
  websiteRegex,
  websiteRegexNew,
} from "../../constants";
import CommunicationLinks from "./NewCompanyStepper/CommunicationLinks";
import {
  getMatchingElementInArr,
  validateWebAddress,
} from "../../util/utility";
import LocationDetails from "./NewCompanyStepper/LocationDetails";
import LogoBanner from "./NewCompanyStepper/LogoBanner";
import { CircularProgress } from "@material-ui/core";
import CompanyPreferences from "./NewCompanyStepper/CampusPreferences";
import Plan from "./NewCompanyStepper/Plan";
import AllPreference from "./NewCompanyStepper/AllPreference";
import { companySizes } from "../../constants";
import { praveshAxiosCall } from "../../util/apiCall";
import moment from "moment";
import OrderSummary from "./NewCompanyStepper/OrderSummary";
import { useHistory } from "react-router";
import { gifStyling } from "../../constants";
import gif from "../../assets/loopingcircles-3.gif";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#303F9F",
    color: "white",
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const AddNewCompany = ({ editCompanyFlag, companyToEdit }) => {
  const classes = useStyles();
  const steps = [
    "SPOC Details",
    "Company   Basic Info",
    "Social Links/Website",
    "Location Details",
    "Banner Logo",
    "Company Preferences",
  ];
  const [salesPersons, setSalesPersons] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = React.useState({});
  const [cities, setAllCities] = useState([]);
  const [stepperClickToggle, setStepperClickToggle] = useState(false);
  const [companyCreationPayload, setCompanyCreationPayload] = useState();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [companyCategoryList, setCompanyCategoryList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [isSendEmail, setIsSendEmail] = useState(null);
  const history = useHistory();

  const initialCompanyDetails = {
    salesPerson: null,
    spocDetails: {
      salesPerson: null,
      firstName: "",
      lastName: "",
      designation: "",
      email: "",
      phone: "",
      altEmail: "",
      altPhone: "",
      userId: "",
    },
    companyBasicInfo: {
      companyId: "",
      companyType: "",
      emailNotification: undefined,
      companyName: "",
      registeredCompanyName: "",
      industry: null,
      gstin: "",
      organizationType: "",
      category: "",
      events: [],
      yearFounded: null,
      companySize: null,
      description: "",
      about: "",
      linkedIn: "",
      glassdoor: "",
      instaAccount: "",
      facebookPage: "",
      website: "",
      gwDomain: "",
      isDomainValid: false,
      longitude: "",
      lattitude: "",
      city: null,
      state: "",
      hqAddress: "",
      fullAddress: "",
      hqSameAsfullAddress: false,
      mapLink: "",
      companyLogo: null,
      banner: null,
      favicon: "",
      sideImage: null,
      faviconLink: null,
      primaryColor: "",
      is_brand: false,
      perksAndBenefits: [''],
      ratingAndReviews: [{ name: "", rating: null }],
      foundersTeam: [{ name: "", image: null, social_link: "", designation: "" }]
    },
  };

  const [newCompanyDetails, setNewCompanyDetails] = useState(
    initialCompanyDetails
  );

  const getEventsList = async () => {
    const endPoint = `/api/admin/events/manage/`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setEventList(res?.data?.data);
      }
    } catch (error) { }
  };

  const getCompanyCategoryList = async () => {
    const endPoint = `/api/shared/get/company/type/`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setCompanyCategoryList(res?.data?.data);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (companyCategoryList?.length === 0) {
      getCompanyCategoryList();
    }
    if (eventList?.length === 0) {
      getEventsList();
    }
  }, []);

  const getSalesPersonList = async () => {
    const endPoint = `/api/common/selected/user_type/get_list/?user_type_ids=10,14`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        const data = res?.data?.data?.data;
        setSalesPersons(data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getIndustriesList = async () => {
    const endPoint = `/api/admin/industry/`;

    try {
      let res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setIndustries(res?.data?.data);
      } else {
        FailureAlert(res?.data?.error);
      }
    } catch (error) {
      throw error;
    }
  };

  // initial prefills :
  useEffect(() => {
    if (industries?.length === 0) {
      getIndustriesList();
    }
    if (salesPersons?.length === 0) {
      getSalesPersonList();
    }
  }, []);

  // Finding Cities
  useEffect(() => {
    if (newCompanyDetails?.companyBasicInfo?.state?.state_id) {
      praveshAxiosCall(
        `/api/shared/city_and_state/?state_id=${newCompanyDetails?.companyBasicInfo?.state?.state_id}`
      )
        .then((res) => {
          setAllCities(res?.data?.data);
        })
        .catch((err) => {
          FailureAlert(err?.message);
        });
    }
  }, [newCompanyDetails?.companyBasicInfo?.state?.state_id]);

  // Getting all the states in the list:

  useEffect(() => {
    if (states?.length === 0) {
      {
        praveshAxiosCall(`/api/location/state/?search=${1}`)
          .then((res) => {
            setStates(res?.data);
          })
          .catch((err) => {
            FailureAlert(err?.message);
          });
      }
    }
  }, []);

  const resetAllFormDetails = () => {
    setActiveStep(0);
    setNewCompanyDetails(initialCompanyDetails);
  };
  const getSalesPerson = (salesUserId) => {
    const matchedSalesPerson = salesPersons?.filter(
      (person) => person?.user_id === salesUserId
    );

    return matchedSalesPerson[0];
  };

  const getIndustry = (industryId) => {
    const matchedIndustry = industries?.filter(
      (industry) => industry?.id === industryId
    );

    return matchedIndustry[0];
  };

  const getMatchingCompanySize = (sizeLabel) => {
    const matchingSize = companySizes?.filter(
      (size) => size?.label === sizeLabel
    );

    return matchingSize[0];
  };

  useEffect(() => {
    if (
      newCompanyDetails?.companyBasicInfo?.onEditCompanyPage &&
      newCompanyDetails?.companyBasicInfo?.cityName &&
      cities?.length > 0
    ) {
      const getMatchingCity = getMatchingElementInArr(
        newCompanyDetails?.companyBasicInfo?.cityName,
        cities,
        "city"
      );

      setNewCompanyDetails((prevData) => ({
        ...prevData,
        companyBasicInfo: {
          ...prevData?.companyBasicInfo,
          city: getMatchingCity,
        },
      }));
    }
  }, [
    newCompanyDetails?.companyBasicInfo?.onEditCompanyPage,
    newCompanyDetails?.companyBasicInfo?.cityName,
    cities?.length,
  ]);

  const getMatchingState = (target) => {
    const matchingStateArr = states?.filter((state) => state?.state === target);

    return matchingStateArr[0];
  };

  const getMatchingCity = (target) => {
    const matchingStateArr = cities?.filter((city) => city?.city === target);

    return matchingStateArr[0];
  };

  const getMatchingCompanyCategory = (categoryType) => {
    const matchingCategory = companyCategoryList?.filter(
      (category) => category?.type === categoryType
    );

    return matchingCategory[0];
  };

  const getCompanyToBeEditedDetails = async (companyId) => {
    setLoading(true);

    const endPoint = `/api/admin/company/new/?company_id=${companyId}&is_edit_company=1`;
    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        const data = res?.data?.data?.[0];
        const location = data?.location?.[0];

        const companyInfo = {
          salesPerson: getSalesPerson(data?.sales_person?.id),
          spocDetails: {
            salesPerson: data?.sales_person,
            firstName: data?.company_user?.first_name,
            lastName: data?.company_user?.last_name,
            designation: data?.company_user?.job_title,
            email: data?.company_user?.email,
            phone: data?.company_user?.phone,
            altEmail: data?.company_user?.alternate_email,
            altPhone: data?.company_user?.alternate_phone,
            userId: data?.company_user?.user_id,
          },
          companyBasicInfo: {
            companyId: data?.company_id,
            companyType: data?.is_onboarded ? "premium" : "freemium",
            emailNotification: data?.is_send_email ? 1 : 0,
            companyName: data?.company_name,
            registeredCompanyName: data?.registered_company_name,
            industry: getIndustry(data?.industry_id),
            gstin: data?.gstin,
            organizationType: data?.organization_type,
            events: data?.company_events || [],
            yearFounded: moment(data?.year_founded).format("YYYY"),
            companySize: getMatchingCompanySize(data?.size),
            description: data?.description,
            category: getMatchingCompanyCategory(data?.type),
            about: data?.about,
            linkedIn: data?.social_links?.[0]?.value,
            glassdoor: data?.social_links?.[3]?.value,
            instaAccount: data?.social_links?.[2]?.value,
            facebookPage: data?.social_links?.[1]?.value,
            website: data?.website,
            gwDomain: data?.domain,
            isDomainValid: false,
            longitude: location?.longitude,
            lattitude: location?.latitude,
            state: getMatchingState(data?.state),
            city: getMatchingCity(data?.city),
            cityName: data?.city,
            hqAddress: data?.address,
            fullAddress: data?.address,
            hqSameAsfullAddress: false,
            mapLink: "",
            companyLogoLink: data?.logo,
            companyLogo: null,
            banner: null,
            bannerLink: data?.banner,
            favicon: null,
            sideImage: null,
            sideImageLink: data?.side_image,
            faviconLink: data?.favicon,
            primaryColor: data?.primary_color,
            secondaryColor: data?.secondary_color,
            onEditCompanyPage: true,
            is_brand: data?.is_brand,
            perksAndBenefits: data?.perks_benefit,
            ratingAndReviews: data?.reviews_rating,
            foundersTeam: data?.founders_team
          },
        };
        setNewCompanyDetails(companyInfo);
        setLoading(false);
        setActiveStep(0);
      } else {
        FailureAlert("some error occurred");
      }
    } catch (error) {
      FailureAlert("some error occurred");

      throw error;
    }
  };

  useEffect(() => {
    if (companyToEdit && salesPersons?.length > 0) {
      setNewCompanyDetails({
        ...newCompanyDetails,
        companyBasicInfo: {
          ...newCompanyDetails?.companyBasicInfo,
          companyId: companyToEdit?.company_id,
        },
      });
      getCompanyToBeEditedDetails(companyToEdit?.company_id);
    }
  }, [companyToEdit, salesPersons?.length]);

  const validateSpocDetails = () => {
    if (editCompanyFlag) {
      return true;
    }
    let isValid = true;
    if (!newCompanyDetails?.salesPerson) {
      FailureAlert("Please select Sales Person");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.spocDetails?.firstName) {
      FailureAlert("Please enter first Name");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.spocDetails?.lastName) {
      FailureAlert("Please enter last Name");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.spocDetails?.email) {
      FailureAlert("Please enter email");
      isValid = false;
      return;
    }
    if (newCompanyDetails?.spocDetails?.email) {
      emailRegex.lastIndex = 0;

      let validMail = emailRegex.test(newCompanyDetails?.spocDetails?.email);
      if (!validMail) {
        isValid = false;
        FailureAlert("Please enter valid email");
        return;
      }
    }
    if (newCompanyDetails?.spocDetails?.altEmail) {
      emailRegex.lastIndex = 0;
      let validMail = emailRegex.test(newCompanyDetails?.spocDetails?.altEmail);
      if (!validMail) {
        isValid = false;
        FailureAlert("Please enter valid alternative email");
        return;
      }
    }

    if (!newCompanyDetails?.spocDetails?.phone) {
      FailureAlert("Please enter phone No");
      isValid = false;
      return;
    }
    if (newCompanyDetails?.spocDetails?.phone) {
      let stringNo = newCompanyDetails?.spocDetails?.phone?.toString();
      if (stringNo?.length < 10) {
        FailureAlert("Please enter 10 digit phone No");
        isValid = false;
        return;
      }
    }
    if (newCompanyDetails?.spocDetails?.altPhone) {
      let stringNo = newCompanyDetails?.spocDetails?.altPhone?.toString();
      if (stringNo?.length < 10) {
        FailureAlert("Please enter 10 digit phone No");
        isValid = false;
        return;
      }
    }

    return isValid;
  };

  const isCompanyDetailsValid = () => {
    let isValid = true;

    if (!newCompanyDetails?.companyBasicInfo?.companyName) {
      FailureAlert("Please enter Company Name");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.companyType) {
      FailureAlert("Please select Company Type");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.registeredCompanyName) {
      FailureAlert("Please enter registered company Name");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.industry) {
      FailureAlert("Please select Industry");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.companySize) {
      FailureAlert("Please select company Size");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.category) {
      FailureAlert("Please select company category");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.organizationType) {
      FailureAlert("Please select Organization type");
      isValid = false;
      return;
    }

    if (!newCompanyDetails?.companyBasicInfo?.yearFounded) {
      FailureAlert("Please select company foundation year.");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.about) {
      FailureAlert("Please enter details about the company.");
      isValid = false;
      return;
    }
    if (newCompanyDetails?.companyBasicInfo?.aboutWordsLength > 256) {
      FailureAlert("Please enter recommended charactes limit.");
      isValid = false;
      return;
    }
    if (newCompanyDetails?.companyBasicInfo?.descripWordsLimit > 516) {
      FailureAlert("Please enter recommended charactes limit.");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.description) {
      FailureAlert("Please enter company description.");
      isValid = false;
      return;
    }

    return isValid;
  };

  const checkCommunicationLinks = () => {
    let isValid = true;

    const checkValidAddress = (web, index) => {
      let nameOfComm = {
        0: "Company Website",
        1: "Facebook Page",
        2: "Instagram Account",
        3: "Glassdoor",
        4: "Linked In",
      };

      if (!web) {
        FailureAlert(`Please enter ${nameOfComm[index]} with http/https`);
        isValid = false;
      }

      if (web) {
        let validAddress = validateWebAddress(web);

        if (!validAddress) {
          FailureAlert(`Please enter ${nameOfComm[index]} with http/https`);
          isValid = false;
        }
      }
    };
    let communicationArr = [
      newCompanyDetails?.companyBasicInfo?.website,
      newCompanyDetails?.companyBasicInfo?.facebookPage,
      newCompanyDetails?.companyBasicInfo?.instaAccount,
      newCompanyDetails?.companyBasicInfo?.glassdoor,
      newCompanyDetails?.companyBasicInfo?.linkedIn,
    ];

    const checkLinks = communicationArr?.map((webAddress, index) => {
      if (webAddress) {
        checkValidAddress(webAddress, index);
      }
      if (index === 0) {
        checkValidAddress(webAddress, index);
      }
    });

    //  GW domain is optional for the company:

    //  when company is of type premium and not from edit.
    // domain allocated can't be edited.

    if (
      newCompanyDetails?.companyBasicInfo?.companyType === "premium" &&
      newCompanyDetails?.companyBasicInfo?.gwDomain &&
      !newCompanyDetails?.companyBasicInfo?.onEditCompanyPage
    ) {
      websiteRegex.lastIndex = 0;

      let validAddress = websiteRegex.test(
        newCompanyDetails?.companyBasicInfo?.gwDomain
      );

      if (!validAddress) {
        FailureAlert(`Please enter proper domain details without https/http`);
        isValid = false;
      }
      if (!newCompanyDetails?.companyBasicInfo?.isDomainValid) {
        FailureAlert(`Please check domain availability`);
        isValid = false;
      }
    }

    return isValid;
  };

  const validateLocationDetails = () => {
    let isValid = true;
    if (!newCompanyDetails?.companyBasicInfo?.state) {
      FailureAlert("Please select state.");
      isValid = false;
      return;
    }
    if (!newCompanyDetails?.companyBasicInfo?.city) {
      FailureAlert("Please select city.");
      isValid = false;
      return;
    }

    if (newCompanyDetails?.companyBasicInfo?.lattitude) {
      latitudeRegex.lastIndex = 0;
      let validLatitude = latitudeRegex.test(
        newCompanyDetails?.companyBasicInfo?.lattitude
      );
      if (!validLatitude) {
        FailureAlert("Please enter latitude in properformat");
        isValid = false;
        return;
      }
    }

    if (newCompanyDetails?.companyBasicInfo?.longitude) {
      latitudeRegex.lastIndex = 0;
      let validLongitude = latitudeRegex.test(
        newCompanyDetails?.companyBasicInfo?.longitude
      );
      if (!validLongitude) {
        FailureAlert("Please enter longitude in properformat");
        isValid = false;
        return;
      }
    }

    if (!newCompanyDetails?.companyBasicInfo?.fullAddress) {
      FailureAlert("Please enter Company Address.");
      isValid = false;
      return;
    }
    // if (!newCompanyDetails?.companyBasicInfo?.hqAddress) {
    //     FailureAlert("Please enter company Head Quarter Address.");
    //     isValid = false;
    //     return;
    // };
    if (newCompanyDetails?.companyBasicInfo?.mapLink) {
      let validAddress = validateWebAddress(
        newCompanyDetails?.companyBasicInfo?.mapLink
      );

      if (!validAddress) {
        FailureAlert(`Please enter map link with http/https`);
        isValid = false;
        return;
      }
    }

    // if (!newCompanyDetails?.companyBasicInfo?.state) {
    //     FailureAlert("Please select state.");
    //     isValid = false;
    //     return;
    // }

    return isValid;
  };

  const validateBannerLogo = () => {
    let isValid = true;
    if (
      !newCompanyDetails?.companyBasicInfo?.banner &&
      !newCompanyDetails?.companyBasicInfo?.bannerLink
    ) {
      FailureAlert("Please upload Company Banner.");
      isValid = false;
      return;
    }
    // if (
    //   newCompanyDetails?.companyBasicInfo?.companyType === "premium" &&
    //   !newCompanyDetails?.companyBasicInfo?.favicon &&
    //   !newCompanyDetails?.companyBasicInfo?.faviconLink
    // ) {
    //   FailureAlert("Please upload favicon for the company.");
    //   isValid = false;
    //   return;
    // }
    // if (!newCompanyDetails?.companyBasicInfo?.companyLogo) {
    //     FailureAlert("Please upload Company Logo.");
    //     isValid = false;
    //     return;
    // }

    return isValid;
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const companyCreateEditApiCall = async () => {
    const endPoint = `/api/admin/company/new/`;

    setLoading(true);


    try {
      let res;

      if (editCompanyFlag) {
        res = await praveshAxiosPatchReq(endPoint, companyCreationPayload);
      } else {
        res = await praveshAxiosPostReq(endPoint, companyCreationPayload);
      }

      if (res?.data?.success) {
        if (res?.data?.error) {
          FailureAlert(res?.data?.error);
        } else {
          if (editCompanyFlag) {
            SuccessAlert(res?.data?.data?.message);
          } else {
            SuccessAlert(res?.data?.data?.message);
          }

          // for post request we are setting the company id, from which we will derive its preferences from the company.

          setActiveStep((prevActiveStep) => prevActiveStep + 1);

          if (!editCompanyFlag) {
            setNewCompanyDetails({
              ...newCompanyDetails,
              companyBasicInfo: {
                ...newCompanyDetails?.companyBasicInfo,
                companyId: res?.data?.data?.company_id,
              },
            });
          }
        }
      } else {
        FailureAlert("some error occurred");
      }

      setLoading(false);
    } catch (error) {
      // setActiveStep((prevActiveStep) => prevActiveStep - 1);
      FailureAlert("some error occurred");
      setLoading(false);
      throw error?.message;
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <NewCompanySpoc
            setNewCompanyDetails={setNewCompanyDetails}
            newCompanyDetails={newCompanyDetails}
            editCompanyFlag={editCompanyFlag}
          />
        );
      case 1:
        return (
          <CompanyBasicInfo
            setNewCompanyDetails={setNewCompanyDetails}
            newCompanyDetails={newCompanyDetails}
            industries={industries}
            setIndustries={setIndustries}
            companyCategoryList={companyCategoryList}
            eventList={eventList}
          />
        );
      case 2:
        return (
          <CommunicationLinks
            setNewCompanyDetails={setNewCompanyDetails}
            newCompanyDetails={newCompanyDetails}
          />
        );
      case 3:
        return (
          <LocationDetails
            setNewCompanyDetails={setNewCompanyDetails}
            newCompanyDetails={newCompanyDetails}
            cities={cities}
            states={states}
          />
        );
      case 4:
        return (
          <LogoBanner
            setNewCompanyDetails={setNewCompanyDetails}
            newCompanyDetails={newCompanyDetails}
            setStepperClickToggle={setStepperClickToggle}
          />
        );
      case 5:
        return (
          <AllPreference
            newCompanyDetails={newCompanyDetails}
            companyId={newCompanyDetails?.companyBasicInfo?.companyId}
          />
        );
    }
  };
  const handleNext = async () => {
    if (activeStep === 0) {
      let is_validInfo = validateSpocDetails();
      // let is_validInfo = true;
      if (!is_validInfo) {
        return;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      let is_validInfo = isCompanyDetailsValid();
      // let is_validInfo = true;

      if (!is_validInfo) {
        return;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 2) {
      // let is_validInfo = true;
      let is_validInfo = checkCommunicationLinks();

      if (!is_validInfo) {
        return;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 3) {
      // let is_validInfo = true;
      let is_validInfo = validateLocationDetails();

      if (!is_validInfo) {
        return;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 4) {
      // let is_validInfo = true;
      let is_validInfo = validateBannerLogo();

      if (!is_validInfo) {
        return;
      } else {
        companyCreateEditApiCall();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  useEffect(() => {
    if (newCompanyDetails) {
      const getSocialLinksArray = () => {
        let tempArr = [];
        if (newCompanyDetails?.companyBasicInfo?.linkedIn) {
          tempArr.push({
            id: 1,
            Name: "LinkedIn",
            value: newCompanyDetails?.companyBasicInfo?.linkedIn,
          });
        }
        if (newCompanyDetails?.companyBasicInfo?.facebookPage) {
          tempArr.push({
            id: 2,
            Name: "Facebook",
            value: newCompanyDetails?.companyBasicInfo?.facebookPage,
          });
        }
        if (newCompanyDetails?.companyBasicInfo?.instaAccount) {
          tempArr.push({
            id: 3,
            Name: "Instagram",
            value: newCompanyDetails?.companyBasicInfo?.instaAccount,
          });
        }
        if (newCompanyDetails?.companyBasicInfo?.glassdoor) {
          tempArr.push({
            id: 4,
            Name: "Glassdoor",
            value: newCompanyDetails?.companyBasicInfo?.glassdoor,
          });
        }
        return tempArr;
      };

      const formData = new FormData();

      // for patch call these keys will be included
      if (editCompanyFlag) {
        formData.append(
          "company_id",
          newCompanyDetails?.companyBasicInfo?.companyId
        );
      }

      if (newCompanyDetails?.companyBasicInfo?.companyType === "premium") {
        formData.append("is_onboarded", true);
        newCompanyDetails?.companyBasicInfo?.sideImage &&
          formData.append(
            "side_image",
            newCompanyDetails?.companyBasicInfo?.sideImage
          );
        newCompanyDetails?.companyBasicInfo?.favicon &&
          formData.append(
            "favicon",
            newCompanyDetails?.companyBasicInfo?.favicon
          );
      } else {
        formData.append("is_onboarded", false);
      }
      if (newCompanyDetails?.companyBasicInfo?.emailNotification === 1) {
        formData.append("is_send_email", true);
      } else if (newCompanyDetails?.companyBasicInfo?.emailNotification === 0) {
        formData.append("is_send_email", false);
      }
      //  for post request
      if (!editCompanyFlag) {
        formData.append(
          "person_first_name",
          newCompanyDetails?.spocDetails?.firstName
        );
        formData.append(
          "person_last_name",
          newCompanyDetails?.spocDetails?.lastName
        );
        formData.append("person_mobile", newCompanyDetails?.spocDetails?.phone);
        formData.append("person_email", newCompanyDetails?.spocDetails?.email);
        newCompanyDetails?.spocDetails?.altPhone &&
          formData.append(
            "alternate_phone",
            newCompanyDetails?.spocDetails?.altPhone
          );
        newCompanyDetails?.spocDetails?.altEmail &&
          formData.append(
            "alternate_email",
            newCompanyDetails?.spocDetails?.altEmail
          );
        formData.append(
          "person_designation",
          newCompanyDetails?.spocDetails?.designation
        );
      }

      formData.append(
        "industry_id",
        newCompanyDetails?.companyBasicInfo?.industry?.id
      );
      formData.append(
        "company_name",
        newCompanyDetails?.companyBasicInfo?.companyName
      );
      formData.append(
        "is_brand",
        newCompanyDetails?.companyBasicInfo?.is_brand
      );
      formData.append(
        "registered_company_name",
        newCompanyDetails?.companyBasicInfo?.registeredCompanyName
      );
      newCompanyDetails?.companyBasicInfo?.gstin &&
        formData.append("gstin", newCompanyDetails?.companyBasicInfo?.gstin);
      formData.append("about", newCompanyDetails?.companyBasicInfo?.about);
      formData.append(
        "company_description",
        newCompanyDetails?.companyBasicInfo?.description
      );
      formData.append(
        "address",
        newCompanyDetails?.companyBasicInfo?.fullAddress
      );
      formData.append(
        "organization_type",
        newCompanyDetails?.companyBasicInfo?.organizationType
      );
      formData.append(
        "company_type_id",
        newCompanyDetails?.companyBasicInfo?.category?.id
      );
      formData.append(
        "size",
        newCompanyDetails?.companyBasicInfo?.companySize?.label
      );
      formData.append(
        "year_founded",
        `${newCompanyDetails?.companyBasicInfo?.yearFounded}-01-01`
      );
      formData.append("website", newCompanyDetails?.companyBasicInfo?.website);
      newCompanyDetails?.companyBasicInfo?.companyLogo &&
        formData.append(
          "logo",
          newCompanyDetails?.companyBasicInfo?.companyLogo
        );
      newCompanyDetails?.companyBasicInfo?.banner &&
        formData.append("banner", newCompanyDetails?.companyBasicInfo?.banner);

      if (newCompanyDetails?.companyBasicInfo?.state) {
        formData.append("country", 1);
        formData.append(
          "state",
          newCompanyDetails?.companyBasicInfo?.state?.state_id
        );
        formData.append(
          "city",
          newCompanyDetails?.companyBasicInfo?.city?.city_id
        );
      }
      const socialLinks = getSocialLinksArray();
      formData.append(
        "social_links",
        JSON.stringify(socialLinks)

        // JSON.stringify([
        //     { id: 1, Name: "LinkedIn", value: newCompanyDetails?.companyBasicInfo?.linkedIn },
        //     { id: 2, Name: "Facebook", value: newCompanyDetails?.companyBasicInfo?.facebookPage },
        //     { id: 3, Name: "Instagram", value: newCompanyDetails?.companyBasicInfo?.instaAccount },
        //     { id: 4, Name: "Glassdoor", value: newCompanyDetails?.companyBasicInfo?.glassdoor },
        // ])
      );
      if (newCompanyDetails?.companyBasicInfo?.gwDomain) {
        formData.append(
          "domain",
          newCompanyDetails?.companyBasicInfo?.gwDomain
        );
      }

      if (
        newCompanyDetails?.companyBasicInfo?.lattitude &&
        newCompanyDetails?.companyBasicInfo?.longitude
      ) {
        formData.append(
          "location",
          JSON.stringify([
            {
              latitude: parseFloat(
                newCompanyDetails?.companyBasicInfo?.lattitude
              ),
              longitude: parseFloat(
                newCompanyDetails?.companyBasicInfo?.longitude
              ),
            },
          ])
        );
      }
      formData.append("maplink", newCompanyDetails?.companyBasicInfo?.mapLink);
      formData.append("sales_person", newCompanyDetails?.salesPerson?.user_id);
      formData.append(
        "hq_address",
        newCompanyDetails?.companyBasicInfo?.hqAddress
      );
      if (newCompanyDetails?.companyBasicInfo?.primaryColor) {
        formData.append(
          "primary_color",
          newCompanyDetails?.companyBasicInfo?.primaryColor
        );
      }
      if (newCompanyDetails?.companyBasicInfo?.secondaryColor) {
        formData.append(
          "secondary_color",
          newCompanyDetails?.companyBasicInfo?.secondaryColor
        );
      }

      formData.append("is_active", true);

      newCompanyDetails?.companyBasicInfo?.events?.length > 0 &&
        formData.append(
          "event_ids",
          JSON.stringify(
            newCompanyDetails?.companyBasicInfo?.events?.map((item) =>
              parseInt(item?.event_id)
            )
          )
        );

      newCompanyDetails?.companyBasicInfo?.perksAndBenefits?.length && formData.append(
        "perks_benefit", JSON.stringify(newCompanyDetails?.companyBasicInfo?.perksAndBenefits)
      )

      newCompanyDetails?.companyBasicInfo?.ratingAndReviews?.length && formData.append(
        "reviews_rating", JSON.stringify(newCompanyDetails?.companyBasicInfo?.ratingAndReviews)
      )

      newCompanyDetails?.companyBasicInfo?.foundersTeam?.length && formData.append(
        "founders_team", JSON.stringify(newCompanyDetails?.companyBasicInfo?.foundersTeam)
      )
      newCompanyDetails?.companyBasicInfo?.foundersTeam?.forEach((item, index) => {
        if (item?.image?.name) formData.append(`founders_image`, item?.image)
      })

      setCompanyCreationPayload(formData);
    }
  }, [newCompanyDetails]);

  if (loading) {
    return <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />;
  }

  return (
    <div className={classes.root}>
      {editCompanyFlag ? null : (
        <>
          <h2>Add New Company</h2>
          <hr />
        </>
      )}
      <br></br>
      {editCompanyFlag &&
        !newCompanyDetails?.companyBasicInfo?.companyId ? null : (
        <>
          <Autocomplete
            id="selectSalesPerson"
            name="selectSalesPerson"
            options={salesPersons}
            getOptionLabel={(item) =>
              item?.user__first_name && item?.user__last_name
                ? item?.user__first_name + " " + item?.user__last_name
                : item?.user__first_name && !item?.user__last_name
                  ? item?.user__first_name
                  : ""
            }
            value={newCompanyDetails?.salesPerson}
            onChange={(e, newValue) => {
              newValue
                ? setNewCompanyDetails((prevData) => ({
                  ...prevData,
                  salesPerson: newValue,
                }))
                : setNewCompanyDetails((prevData) => ({
                  ...prevData,
                  salesPerson: null,
                }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Sales Person*"
                variant="outlined"
              />
            )}
          />
          <br></br>
          <Form.Label>
            <u
              style={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Select Company Type*
            </u>
          </Form.Label>
          <Form.Group type="radio" id="company" required>
            <Form.Check
              inline
              id="freemium"
              type="radio"
              name="freemium"
              value={"freemium"}
              label="Freemium"
              checked={
                newCompanyDetails?.companyBasicInfo?.companyType === "freemium"
              }
              onChange={(e) => {
                setNewCompanyDetails({
                  ...newCompanyDetails,
                  companyBasicInfo: {
                    ...newCompanyDetails?.companyBasicInfo,
                    companyType: e.target.value,
                  },
                });
              }}
            />
            <Form.Check
              inline
              id="premium"
              type="radio"
              name="premium"
              label="Premium"
              value={"premium"}
              checked={
                newCompanyDetails?.companyBasicInfo?.companyType === "premium"
              }
              onChange={(e) => {
                setNewCompanyDetails({
                  ...newCompanyDetails,
                  companyBasicInfo: {
                    ...newCompanyDetails?.companyBasicInfo,
                    companyType: e.target.value,
                  },
                });
              }}
            />
          </Form.Group>

          <Form.Label>
            <u
              style={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Email Notification*
            </u>
          </Form.Label>
          <Form.Group type="radio" id="email" required>
            <Form.Check
              inline
              id="yes"
              type="radio"
              name="yes"
              value={1}
              label="Yes"
              checked={
                newCompanyDetails?.companyBasicInfo?.emailNotification === 1
              }
              onChange={(e) => {
                setNewCompanyDetails({
                  ...newCompanyDetails,
                  companyBasicInfo: {
                    ...newCompanyDetails?.companyBasicInfo,
                    emailNotification: 1,
                  },
                });
              }}
            />
            <Form.Check
              inline
              id="no"
              type="radio"
              name="no"
              label="No"
              value={0}
              checked={
                newCompanyDetails?.companyBasicInfo?.emailNotification === 0
              }
              onChange={(e) => {
                setNewCompanyDetails({
                  ...newCompanyDetails,
                  companyBasicInfo: {
                    ...newCompanyDetails?.companyBasicInfo,
                    emailNotification: 0,
                  },
                });
              }}
            />
          </Form.Group>

          {newCompanyDetails?.companyBasicInfo?.companyType &&
            newCompanyDetails?.companyBasicInfo?.emailNotification !==
            undefined ? (
            <>
              <Stepper nonLinear activeStep={activeStep}>
                {steps?.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      completed={completed[index]}
                      onClick={() => {
                        if (editCompanyFlag || stepperClickToggle) {
                          setActiveStep(index);
                        }
                      }}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CircularProgress />
                    </div>
                  </div>
                ) : (
                  <div>
                    <Typography className={classes.instructions}>
                      {getStepContent(activeStep)}
                    </Typography>

                    {activeStep === 5 ? null : (
                      <div style={{ marginTop: "-3rem" }}>
                        <Button
                          variant="contained"
                          // color="primary"
                          type="submit"
                          disabled={loading || activeStep === 5}
                          // onClick={activeStep === 5 ? handleSubmit : handleNext}
                          onClick={() => {
                            handleNext();
                          }}
                          className={classes.button}
                          style={{
                            float: "right",
                            //  marginBottom: "2rem"
                          }}
                          marginRight="5px"
                        >
                          {activeStep === 4
                            ? editCompanyFlag
                              ? "Edit Company Details"
                              : "Create Company"
                            : "Next"}
                        </Button>

                        <Button
                          disabled={activeStep === 0 || loading}
                          onClick={() => {
                            handleBack();
                          }}
                          className={classes.button}
                          variant="contained"
                          // color="primary"
                          style={{ float: "right" }}
                        >
                          Back
                        </Button>
                      </div>
                    )}
                    {activeStep === 5 &&
                      (!editCompanyFlag ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              float: "right",
                              marginBottom: "2rem",
                            }}
                            marginRight="5px"
                            onClick={() => {
                              resetAllFormDetails();
                            }}
                          >
                            Create New Company
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.button}
                            style={{ float: "right", marginBottom: "2rem" }}
                            marginRight="5px"
                            onClick={() => {
                              history.push("/admin/new-edit-company");
                            }}
                          >
                            Go To Edit New Company
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            float: "right",
                            marginBottom: "2rem",
                          }}
                          marginRight="5px"
                          onClick={() => {
                            history.push("/admin/add-new-company");
                          }}
                        >
                          Create New Company
                        </Button>
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AddNewCompany;
