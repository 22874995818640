import React, { useEffect,useState } from "react";
import { Col, Form } from "react-bootstrap";

function AddNewCollegePocDetails({ formData, setFormData,emailEditable,noEmail}) {

  const [emailDisableflag ,setemailDisableflag] = useState(true);

  useEffect(() => {

    setemailDisableflag(() =>  emailEditable ? false : true);

    if(noEmail){

      setemailDisableflag(() => false)
    }
  },[emailEditable,formData])

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Form
          className="edit__poc__details__form"
          id="edit__poc__details__form__details"
        >
          <Form.Row>
            <Form.Group as={Col} controlId="pocdetails">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                name="firstname"
                type="text"
                placeholder={"Firstname*"}
                value={formData?.firstname}
                onChange={(event) =>
                  setFormData({ ...formData, firstname: event.target.value })
                }
                required={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="pocdetails">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                name="lastname"
                type="text"
                placeholder={"Lastname*"}
                value={formData?.lastname}
                onChange={(event) =>
                  setFormData({ ...formData, lastname: event.target.value })
                }
                required={true}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="pocdetails">
              <Form.Label>Designation*</Form.Label>
              <Form.Control
                name="designation"
                type="text"
                placeholder={"Designation*"}
                value={formData?.designation}
                onChange={(event) =>
                  setFormData({ ...formData, designation: event.target.value })
                }
                required={true}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="pocdetails">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                name="email"
                disabled={emailDisableflag}
                type="email"
                placeholder={"Email*"}
                value={formData?.email}
                onChange={(event) =>
                  setFormData({ ...formData, email: event.target.value })
                }
                required={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="pocdetails">
              <Form.Label>Phone No*</Form.Label>
              <Form.Control
                name="Phone"
                type="number"
                pattern="[0-9]*"
                maxLength="10"
                minLength="10"
                placeholder={"Phone Number*"}
                value={formData?.phone}
                onChange={(event) =>
                {
                  let temptext = event.target.value;
                  if(temptext?.length < 13){

                    setFormData({ ...formData, phone: event.target.value })
                  }
                }
                }
                required={true}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="pocdetails">
              <Form.Label>Alternate Email:</Form.Label>
              <Form.Control
                name="alternateEmail"
                type="email"
                placeholder={"Alternate Email"}
                value={formData?.alternate_email}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    alternate_email: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="pocdetails">
              <Form.Label>Alternate Phone No:</Form.Label>
              <Form.Control
                name="alternatePhone"
                type="number"
                pattern="[0-9]*"
                maxLength="10"
                minLength="10"
                placeholder={"Alternate Phone"}
                value={formData?.alternate_phone}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    alternate_phone: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </>
  );
}
export default AddNewCollegePocDetails;
