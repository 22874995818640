import React, { useEffect, useState } from "react";
import "../../css/PaymentDetails.css";
import { apiCall } from "../../util/apiCall";
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from "../../constants";
import TableWithExport from "../TableWithExport";
import { TextField } from "@material-ui/core";
import gif from "../../assets/loopingcircles-3.gif";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";

const token = localStorage.getItem("token");

function EditDegrees() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [educations, setEducations] = useState({}); // For Filter purpose
  const [degrees, setDegrees] = useState({}); // For Filter purpose
  const [loading, setLoading] = useState(false);

  // Education Type API
  useEffect(() => {
    apiCall("/api/education/type/", requestPraveshOptions).then((res) => {
      let obj = {};
      res.forEach((e) => {
        obj[e.type] = e.type;
      });
      setEducations(obj);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Degrees Finding API
  useEffect(() => {
    apiCall(`/api/admin/degree/all/`, requestPraveshOptions).then((res) => {
      let obj = {};
      if (res.success) {
        res.data.forEach((degree) => {
          obj[degree.degree_name] = degree.degree_name;
        });
        setDegrees(obj);
      } else FailureAlert(res.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    setLoading(true);
    apiCall("/api/admin/degree/all/", requestPraveshOptions).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            degree_id: json.data[i].degree_id,
            degree_name: json.data[i].degree_name,
            degree_user_created: json.data[i].degree_user_created ? "Yes" : "No",
            education_type_id: json.data[i].education_type_id,
            education_type_name: json.data[i].education_type_name,
            is_specialized: json.data[i].is_specialized ? "Yes" : "No",
            short_name: json.data[i].short_name,
            duration: json.data[i].duration,
          };
          tmpArray.push(obj);
        }
        setLoading(false);
        setData(tmpArray);
      } else {
        FailureAlert(json.error);
      }
    });
  }, [count]);

  const columns = [
    { title: "Degree_ID", field: "degree_id", headerStyle: headerCSS, cellStyle: cellCSS, filtering: false, editable: "never" },
    {
      title: "Education_Type",
      field: "education_type_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
        fontSize: "13px",
      },
      editable: "never",
      filterPlaceholder: "Education",
      searchable: false,
      lookup: educations,
      render: (row) => ( 

        <div 
        style={{

          fontSize: "13px",
        }}
        >
          {row?.education_type_name}
        </div>
      ),
    },
    {
      title: "Degree_Name",
      field: "degree_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: degrees,
      filterPlaceholder: "Degree",
      editComponent: (props) => <TextField value={props.value} fullWidth={true} onChange={(e) => props.onChange(e.target.value)} />,
    },
    {
      title: "Degree Short Name",
      field: "short_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //   lookup: degrees,
      filterPlaceholder: "Short Name",
      editComponent: (props) => <TextField value={props.value} fullWidth={true} onChange={(e) => props.onChange(e.target.value)} />,
    },
    {
      title: "Duration",
      field: "duration",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //   lookup: degrees,
      filterPlaceholder: "Duration",
      editComponent: (props) => <TextField value={props.value} fullWidth={true} onChange={(e) => props.onChange(e.target.value)} />,
    },
    {
      title: "User_Created?",
      field: "degree_user_created",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterPlaceholder: "Yes/No",
      lookup: { Yes: "Yes", No: "No" },
      //   editComponent: props => (
      //     <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
      //         <MenuItem value="Yes">Yes</MenuItem>
      //         <MenuItem value="No">No</MenuItem>
      //     </Select>
      //   )
    },
    {
      title: "Specialized?",
      field: "is_specialized",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
      lookup: { Yes: "Yes", No: "No" },
      filterPlaceholder: "Yes/No",
    },
  ];

  const edit = {
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          updateRow(newData);
          setData([...dataUpdate]);
          resolve();
        }, 1000);
      }),
  };

  const updateRow = (newData) => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        degree_id: newData.degree_id,
        degree_name: newData.degree_name,
        user_created: newData.degree_user_created === "Yes" ? true : false,
        short_name: newData.short_name,
        duration: newData.duration,
      }),
    };
    apiCall(`/api/admin/degree/all/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setCount(count + 1);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  return !loading ? (
    <div className="degrees__details">
      <TableWithExport title="List of Degrees" data={data} columns={columns} fName="Selected Degrees" editableObject={edit} />
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default EditDegrees;
