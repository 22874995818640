import {
  Button,
  Checkbox, FormControlLabel, Grid,
  InputAdornment, Radio,
  RadioGroup, TextField, Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import ReactHtmlParser from "react-html-parser";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling } from "../../constants";
import "../../css/Miscellaneous.css";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { uniqueArray } from "../../util/uniqueArray";
import ClubChip from "./ClubChip";

const token = localStorage.getItem("token");

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    borderBottom: "3px solid  #008080",
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    border: "1px solid #b0b6ba",
    //marginBottom: "1%",
    borderRadius: "10px",
    padding: "10px",
  },
  editingtext: {
    color: "#b0b7c3",
    fontStyle: "italic",
  },
  btn: {
    backgroundColor: "#17a2b8",
    boxShadow: "none",
    color: "#fff",
    marginTop: "10px",
  },
}));

const BlackRadio = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "#000",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      thousandsGroupStyle="lakh"
      prefix="₹"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function AllServices() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState("a");
  const params = useParams();
  const [clubDetails, setClubDetails] = useState({
    segment: null,
    category: null,
    sub_category: [],
    company: null,
    link: null,
    title: null,
    product_image: null,
    banner_image: null,
    video: null,
    short_description: null,
    tags: [],
    description: [],
    price: 0,
    discount: 0,
    gw_price: 0,
    conditions: null,
    temp_image: null,
    cancellation_policy: null,
    refund_policy: null,
    instructor: {
      name: null,
      tagline: null,
      link: null,
      about: null,
      // profile: null,
    },
    is_youtube_link: false,
    is_address_required: false,
    coupon_available: false,
    coupon_code: null,
    coupon_expiry: null,
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleRadioChange = (event) => {
    setClubDetails({ ...clubDetails, segment: event.target.value });
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [conditions, setConditions] = useState(EditorState.createEmpty());
  const [cancellation, setCancellation] = useState(EditorState.createEmpty());
  const [refund, setRefund] = useState(EditorState.createEmpty());
  const [aboutInstructor, setAboutInstructor] = useState(EditorState.createEmpty());
  const [addDescription, setAddDescription] = useState(false);
  const [editorStateDesc, setEditorStateDesc] = useState(EditorState.createEmpty());
  const [currentEditorStateDesc, setCurrentEditorStateDesc] = useState(EditorState.createEmpty());
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentEdit, setCurrentEdit] = useState(false);
  const history = useHistory();
  const [currentDescTitle, setCurrentDescTitle] = useState(null);
  const onEditorStateChange = (s) => {
    setEditorState(s);
    setClubDetails({ ...clubDetails, short_description: draftToHtml(convertToRaw(s.getCurrentContent())) });
  };

  const onEditorStateConditions = (s) => {
    setConditions(s);
    setClubDetails({ ...clubDetails, conditions: draftToHtml(convertToRaw(s.getCurrentContent())) });
  };
  const onEditorStateCancellation = (s) => {
    setCancellation(s);
    setClubDetails({ ...clubDetails, cancellation_policy: draftToHtml(convertToRaw(s.getCurrentContent())) });
  };

  const onEditorStateRefund = (s) => {
    setRefund(s);
    setClubDetails({ ...clubDetails, refund_policy: draftToHtml(convertToRaw(s.getCurrentContent())) });
  };
  const onEditorStateChangeDesc = (s) => {
    setEditorStateDesc(s);
    setCurrentEditorStateDesc(draftToHtml(convertToRaw(s.getCurrentContent())));
  };

  const onEditorStateAbout = (s) => {
    setAboutInstructor(s);
    setClubDetails({ ...clubDetails, instructor: { ...clubDetails?.instructor, about: draftToHtml(convertToRaw(s.getCurrentContent())) } });
  };

  var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);

  const handleSubmit = () => {
    if (!clubDetails?.segment) {
      FailureAlert("Please Enter Segment");
      return;
    }
    if (!clubDetails?.category) {
      FailureAlert("Please Enter Category");
      return;
    }
    if (clubDetails?.sub_category?.length === 0) {
      FailureAlert("Please Enter at least one Sub Category");
      return;
    }
    if (!clubDetails?.company) {
      FailureAlert("Please Enter Company");
      return;
    }
    if (!clubDetails?.title) {
      FailureAlert("Please Enter Title");
      return;
    }
    if (!clubDetails?.link) {
      FailureAlert("Please Enter Link");
      return;
    }
    if (clubDetails?.link && !clubDetails?.link?.match(regex)) {
      FailureAlert("Please Enter Correct Link url");
      return;
    }
    if (!clubDetails?.product_image && !clubDetails?.temp_image) {
      FailureAlert("Please Enter Product Image");
      return;
    }
    // if (!clubDetails?.short_description) {
    //   FailureAlert("Please Enter Short Descrption");
    //   return;
    // }
    // if (clubDetails?.short_description && clubDetails?.short_description?.length > 60) {
    //   FailureAlert("Short Description must have less than 50 words...");
    //   return;
    // }
    if (clubDetails?.tags?.length === 0) {
      FailureAlert("Please Enter atleast one Tag");
      return;
    }
    if (clubDetails?.description?.length === 0) {
      FailureAlert("Please Enter atleast one Description Content along with title");
      return;
    }
    if (clubDetails?.discount && (clubDetails?.discount < 0 || clubDetails?.discount > 100)) {
      FailureAlert("Please Enter Correct Discount");
      return;
    }
    if (!clubDetails?.conditions) {
      FailureAlert("Please Enter Terms and Conditions");
      return;
    }
    if (!clubDetails?.cancellation_policy) {
      FailureAlert("Please Enter Cancellation Policy");
      return;
    }

    if (!clubDetails?.refund_policy) {
      FailureAlert("Please Enter Refund Policy");
      return;
    }

    if (!params?.product_id && clubDetails?.coupon_available) {
      if (!clubDetails?.coupon_code) {
        FailureAlert("Please Enter Coupon Code");
        return;
      }
      if (!clubDetails?.coupon_expiry) {
        FailureAlert("Please Enter Coupon Expiry");
        return;
      }
    }
    if (clubDetails?.segment === "SERVICE" && clubDetails?.category?.title?.toLowerCase().includes("learning")) {
      if (!clubDetails?.instructor?.name) {
        FailureAlert("Please Enter Name of the instructor");
        return;
      }
      if (!clubDetails?.instructor?.tagline) {
        FailureAlert("Please Enter Tagline");
        return;
      }
      if (!clubDetails?.instructor?.link) {
        FailureAlert("Please Enter Links of instructor");
        return;
      }
      if (!clubDetails?.instructor?.about) {
        FailureAlert("Please Enter Instructor's Bio");
        return;
      }
      // if (!clubDetails?.instructor?.profile) {
      //   FailureAlert("Please Enter Profile of the instructor");
      //   return;
      // }
    }
    if (params?.product_id) addService(`/api/getwork_club/product_service/${params?.product_id}/`, "PATCH");
    else addService(`/api/getwork_club/product_service/`, "POST");
  };

  const [dataCategory, setDataCategory] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [dataTags, setDataTags] = useState([]);

  const getCategories = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/category/?segment_id=${clubDetails?.segment === "SERVICE" ? 2 : 1}&is_active=1`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataCategory(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };
  const [dataSubCategory, setDataSubCategory] = useState([]);
  const getSubCategory = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/sub_category/?category_id=${clubDetails?.category?.id}`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataSubCategory(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  const getCompanies = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/vendors/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataCompany(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  const getTags = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/tags/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataTags(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  useEffect(() => {
    if (clubDetails?.segment) getCategories();
  }, [clubDetails?.segment]);

  useEffect(() => {
    getCompanies();
    getTags();
  }, []);

  useEffect(() => {
    if (clubDetails?.category) getSubCategory();
  }, [clubDetails?.category]);

  const addService = (url, method) => {
    var arr = [];
    clubDetails?.tags?.forEach((item) => {
      arr.push(item?.id);
    });

    var sub_cat_arr = [];
    clubDetails?.sub_category?.forEach((item) => {
      sub_cat_arr.push(item?.id);
    });
    var obj = {
      instructor_profile: clubDetails?.instructor,
    };
    const fd = new FormData();
    fd.append("segment", clubDetails?.segment === "SERVICE" ? 2 : 1);
    fd.append("category", clubDetails?.category?.id);
    // fd.append("sub_category", 1);
    fd.append("sub_category", JSON.stringify(sub_cat_arr));
    fd.append("vendor", clubDetails?.company?.id);
    fd.append("title", clubDetails?.title);
    fd.append("link", clubDetails?.link);
    fd.append("is_youtube_link", clubDetails?.is_youtube_link);
    if (clubDetails?.product_image) fd.append("image", clubDetails?.product_image);
    fd.append("tags", JSON.stringify(arr));
    // fd.append("tags", 1);
    fd.append("description", JSON.stringify(clubDetails?.description));
    fd.append("actual_price", clubDetails?.price);
    fd.append("discount", clubDetails?.discount);
    fd.append("gw_price", clubDetails?.gw_price);
    fd.append("terms_condition", clubDetails?.conditions);
    fd.append("refund_policies", clubDetails?.refund_policy);
    fd.append("is_address_required", clubDetails?.is_address_required);
    fd.append("is_active", true);
    fd.append("extra_data", JSON.stringify(obj));
    if (method === "POST") {
      var coupons = [{ code: clubDetails?.coupon_code, expiry: clubDetails?.coupon_expiry }];

      fd.append("coupon_available", clubDetails?.coupon_available);
      if (clubDetails?.coupon_available) fd.append("coupon_codes", JSON.stringify(coupons));
    }

    const requestOptionsPatch = {
      method: method,
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(`${url}`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        history.push({ pathname: "/admin/all-getworkclub-services", flag: clubDetails?.segment === "SERVICE" ? 0 : 1 });
      } else {
        FailureAlert(res.error);
      }
    });
  };

  useEffect(() => {
    if (clubDetails?.price && clubDetails?.discount) {
      var main_price = clubDetails?.price * (clubDetails?.discount / 100);
      setClubDetails({ ...clubDetails, gw_price: clubDetails?.price - main_price });
    }
  }, [clubDetails?.price, clubDetails?.discount]);

  useEffect(() => {
    if (params?.product_id) {
      getProductData();
    }
  }, [params?.product_id]);
  const [dataProducts, setDataProducts] = useState(null);
  const getProductData = () => {
    setLoading(true);
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/product_service/?product_id=${params?.product_id}`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setDataProducts(json);
          setLoading(false);
        } else FailureAlert("Some error occurred");
        setLoading(false);
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dataProducts) {
      if (dataProducts?.sub_category?.length > 0) {
        dataProducts?.sub_category?.forEach((item) => {
          item["title"] = item.name;
        });
      }
      setClubDetails({
        ...clubDetails,
        segment: dataProducts?.segment_id === 2 ? "SERVICE" : "PRODUCT",
        category: dataProducts?.category_id ? { id: dataProducts?.category_id, title: dataProducts?.category_name } : null,
        sub_category: dataProducts?.sub_category,
        company: dataProducts?.vendor,
        link: dataProducts?.link,
        title: dataProducts?.title,
        product_image: null,
        temp_image: dataProducts?.image[0].url,
        // banner_image: null,
        // video: null,
        // short_description: null,
        tags: dataProducts?.tags,
        description: dataProducts?.description,
        price: dataProducts?.actual_price,
        discount: dataProducts?.discount,
        gw_price: dataProducts?.gw_price,
        conditions: dataProducts?.terms_condition,
        cancellation_policy: dataProducts?.cancellation_policies,
        refund_policy: dataProducts?.refund_policies,
        instructor: dataProducts?.extra_data?.instructor_profile
          ? dataProducts?.extra_data?.instructor_profile
          : {
              name: null,
              tagline: null,
              link: null,
              about: null,
              // profile: null,
            },
        is_youtube_link: dataProducts?.is_youtube_link,
        is_address_required: dataProducts?.is_address_required,
      });
    }

    if (dataProducts?.terms_condition) {
      const html = dataProducts?.terms_condition;
      const blocksFromHTML = convertFromHTML(html);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      setConditions(EditorState.createWithContent(content));
    }

    // if (dataProducts?.cancellation_policies) {
    //   const html = dataProducts?.cancellation_policies;
    //   const blocksFromHTML = convertFromHTML(html);
    //   const content = ContentState.createFromBlockArray(blocksFromHTML);
    //   setCancellation(EditorState.createWithContent(content));
    // }

    if (dataProducts?.refund_policies) {
      const html_new = dataProducts?.refund_policies;
      const blocksFromNewHTML = convertFromHTML(html_new);
      const content_new = ContentState.createFromBlockArray(blocksFromNewHTML);
      setRefund(EditorState.createWithContent(content_new ? content_new : ""));
    }

    // if (dataProducts?.inst) {
    //   const html = dataProducts?.cancellation_policies;
    //   const blocksFromHTML = convertFromHTML(html);
    //   const content = ContentState.createFromBlockArray(blocksFromHTML);
    //   setConditions(EditorState.createWithContent(content));
    // }
  }, [dataProducts]);

  return !loading ? (
    <>
      <div className="education__skils">
        <div className="education__skils__form">
          <div className={classes.heading}>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {" "}
              {params?.product_id ? "Edit" : "Add"} Service/Product{" "}
            </Typography>
          </div>
          <div style={{ marginTop: "30px", borderBottom: "3px solid  #008080" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RadioGroup aria-label="quiz" name="quiz" value={clubDetails?.segment} onChange={handleRadioChange} row>
                  <FormControlLabel value="PRODUCT" control={<BlackRadio />} label="Product" />

                  <FormControlLabel value="SERVICE" control={<BlackRadio />} label="Service" />
                </RadioGroup>
              </Grid>
              {/* <Grid item xs={12} style={{ marginBottom: "-10px" }}>
                <Typography variant="h6"> Company details </Typography>
              </Grid> */}
              {clubDetails?.segment && (
                <Grid item xs={12}>
                  <Autocomplete
                    options={dataCategory}
                    getOptionLabel={(item) => item.title}
                    style={{ width: "50%" }}
                    onChange={(e, item) => {
                      if (item) {
                        setClubDetails({ ...clubDetails, category: item });
                      } else {
                        setClubDetails({ ...clubDetails, category: null });
                      }
                    }}
                    value={clubDetails?.category}
                    renderInput={(params) => <TextField {...params} label="Select Category" variant="outlined" />}
                  />
                </Grid>
              )}
              {clubDetails?.category && (
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    options={dataSubCategory}
                    getOptionLabel={(item) => item.title}
                    style={{ width: "50%" }}
                    onChange={(e, item) => {
                      if (item) {
                        setClubDetails({ ...clubDetails, sub_category: uniqueArray(item) });
                      } else {
                        setClubDetails({ ...clubDetails, sub_category: [] });
                      }
                    }}
                    value={clubDetails?.sub_category}
                    renderInput={(params) => <TextField {...params} label="Select Sub Categories" variant="outlined" />}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Autocomplete
                  options={dataCompany}
                  getOptionLabel={(item) => item.company_name}
                  style={{ width: "50%" }}
                  onChange={(e, item) => {
                    if (item) {
                      setClubDetails({ ...clubDetails, company: item });
                    } else {
                      setClubDetails({ ...clubDetails, company: null });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                  value={clubDetails?.company}
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TextField
                  style={{ width: "100%" }}
                  label={`${clubDetails?.segment === "SERVICE" ? "Services" : "Product's"} Title`}
                  size="small"
                  variant="outlined"
                  value={clubDetails?.title}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, title: e.target.value });
                  }}
                  InputLabelProps={{ shrink: true }}
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label={`${clubDetails?.is_youtube_link ? "Youtube Link" : clubDetails?.segment === "SERVICE" ? "Services" : "Product's"} Link (or Vendors m-site link)`}
                  required
                  size="small"
                  variant="outlined"
                  value={clubDetails?.link}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, link: e.target.value });
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={clubDetails?.is_youtube_link}
                      onChange={(e) => {
                        setClubDetails({ ...clubDetails, is_youtube_link: e.target.checked });
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Is Youtube Link?"
                />
              </Grid>
              {/* <Grid item xs={4}>
                <TextField style={{ width: "100%" }} label="Facebook link" size="small" variant="outlined" />
              </Grid>
              <Grid item xs={4}>
                <TextField style={{ width: "100%" }} label="Instagram link" size="small" variant="outlined" />
              </Grid>
              <Grid item xs={4}>
                <TextField style={{ width: "100%" }} label="LinkedIn link" size="small" variant="outlined" />
              </Grid> */}
            </Grid>
            <br />
          </div>
          <div
            style={{
              marginTop: "15px",
              marginBottom: "10px",
              borderBottom: "3px solid  #008080",
              paddingBottom: "30px",
            }}
          >
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Upload Product Promotional Banner Image
                  </Typography>
                </div>
                <Form.File id="collegeBanner">
                  <Form.File.Input onChange={(e) => setClubDetails({ ...clubDetails, banner_image: e.target.files[0] })} />
                  <p style={{ color: "#6c757d", marginBottom: "0px" }}>Recommended Size: 200px*120px</p>
                </Form.File>
              </Grid> */}
              <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Upload Product Image
                  </Typography>
                </div>

                {clubDetails?.temp_image && !clubDetails?.product_image && (
                  <>
                    <img src={clubDetails?.temp_image} alt="" height="200px" />
                  </>
                )}
                <br />
                <Form.File id="collegeBanner">
                  <Form.File.Input onChange={(e) => setClubDetails({ ...clubDetails, product_image: e.target.files[0] })} />
                  <p style={{ color: "#6c757d", marginBottom: "0px" }}>Recommended Size: 150px*150px</p>
                </Form.File>
              </Grid>
            </Grid>

            <br />
            {/* <Grid item xs={12}>
              <TextField
                style={{ width: "100%" }}
                label={`Youtube Link`}
                required
                size="small"
                variant="outlined"
                value={clubDetails?.video}
                onChange={(e) => {
                  setClubDetails({ ...clubDetails, video: e.target.value });
                }}
              />
            </Grid> */}
          </div>

          <div
            style={{
              marginTop: "15px",
              marginBottom: "10px",
              borderBottom: "3px solid  #008080",
              paddingBottom: "30px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginBottom: "-10px" }}>
                <Typography variant="h6"> About Product </Typography>
              </Grid>

              {/* <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Short Description
                      <span className={classes.editingtext}>(Maximum 50 Words)</span>
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
              </Grid> */}

              <Grid item xs={12}>
                <div>
                  <Autocomplete
                    options={dataTags}
                    getOptionLabel={(item) => item.name}
                    style={{ width: "50%" }}
                    onChange={(e, item) => {
                      if (item) {
                        var arr = [...clubDetails.tags];
                        arr.push(item);
                        setClubDetails({ ...clubDetails, tags: uniqueArray(arr) });
                      }
                    }}
                    renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" />}
                  />
                  <div>
                    <ClubChip chipData={clubDetails} setChipData={setClubDetails} />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                {clubDetails?.description.map((item, index) => (
                  <>
                    <div style={{ border: "1px solid black", borderRadius: 5, padding: 10 }}>
                      <div style={{ display: "flex" }}>
                        <Typography variant="body1" style={{ fontWeight: "600" }}>
                          {item?.title}
                        </Typography>{" "}
                        <span style={{ display: "flex", flexDirection: "row", marginLeft: "90%" }}>
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCurrentDescTitle(item?.title);
                              setCurrentEditorStateDesc(item?.content);
                              // setEditorStateDesc(item?.raw);

                              if (item?.content) {
                                const html = item?.content;
                                const blocksFromHTML = convertFromHTML(html);
                                const content = ContentState.createFromBlockArray(blocksFromHTML);
                                setEditorStateDesc(EditorState.createWithContent(content));
                              }

                              setCurrentIndex(index);
                              setCurrentEdit(true);
                              setAddDescription(true);
                              console.log(index);
                            }}
                          />{" "}
                          &nbsp; &nbsp;
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              var arr = [...clubDetails?.description];
                              arr.splice(index, 1);
                              setClubDetails({ ...clubDetails, description: arr });
                            }}
                          />
                        </span>
                      </div>
                      <br />
                      {ReactHtmlParser(item.content)}
                    </div>
                    <br />
                  </>
                ))}
                {(addDescription || clubDetails?.description?.length === 0) && (
                  <>
                    <div style={{ marginTop: "-5px" }}>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography variant="body2" style={{ fontWeight: "600" }}>
                          {currentEdit ? "Edit" : "Add"} Description
                        </Typography>
                      </div>
                      <div className={classes.editor}>
                        <TextField
                          style={{ width: "100%" }}
                          label={`Title`}
                          required
                          size="small"
                          variant="outlined"
                          value={currentDescTitle}
                          onChange={(e) => {
                            setCurrentDescTitle(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <Editor
                          editorState={editorStateDesc}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          onEditorStateChange={onEditorStateChangeDesc}
                        />
                      </div>
                      <br />
                    </div>
                    <div style={{ display: "flex", marginLeft: "-65%" }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        // className={classes.button}
                        onClick={() => {
                          if (currentEdit) {
                            setAddDescription(false);

                            var arr = [...clubDetails?.description];
                            arr[currentIndex].title = currentDescTitle;
                            arr[currentIndex].content = currentEditorStateDesc;
                            arr[currentIndex].raw = editorStateDesc;
                            setClubDetails({ ...clubDetails, description: arr });
                            setCurrentIndex(null);
                            setCurrentEdit(false);
                          } else {
                            setAddDescription(false);
                            setCurrentIndex(null);
                            setCurrentEdit(false);

                            var arr = [...clubDetails?.description];
                            arr.push({ title: currentDescTitle, content: currentEditorStateDesc, raw: editorStateDesc });
                            setClubDetails({ ...clubDetails, description: arr });
                          }
                        }}
                      >
                        {currentEdit ? "Edit" : "Save"}
                      </Button>

                      <Button
                        color="primary"
                        variant="outlined"
                        // className={classes.button}
                        onClick={() => {
                          setAddDescription(false);
                          setCurrentIndex(null);
                          setCurrentEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                )}
                {!addDescription && clubDetails?.description?.length > 0 && (
                  <div style={{ display: "flex", marginLeft: 0 }}>
                    <Button
                      color="primary"
                      className={classes.button}
                      endIcon={<AddCircleOutlineIcon />}
                      onClick={() => {
                        setAddDescription(true);
                        setCurrentIndex(null);
                        setCurrentEdit(false);
                      }}
                    >
                      Add Description
                    </Button>
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Regular Price* <span style={{ color: "#6c757d" }}> (INR) </span>
                  </Typography>
                </div>
                <TextField
                  style={{ width: "100%" }}
                  label="Add Price"
                  size="small"
                  variant="outlined"
                  value={clubDetails?.price}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, price: e.target.value });
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600", color: "#6c757d" }}>
                    Discount* (In Percentage)
                  </Typography>
                </div>
                <TextField
                  style={{ width: "100%" }}
                  label="Add Discount"
                  size="small"
                  variant="outlined"
                  value={clubDetails?.discount}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, discount: e.target.value });
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: { min: 0, max: 100 },
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600", color: "#6c757d" }}>
                    Getwork Price* (INR)
                  </Typography>
                </div>
                <TextField
                  style={{ width: "100%" }}
                  label="Add Price"
                  size="small"
                  variant="outlined"
                  value={clubDetails?.gw_price}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, gw_price: e.target.value });
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              {!params?.product_id && (
                <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={clubDetails?.coupon_available}
                          onChange={(e) => {
                            setClubDetails({ ...clubDetails, coupon_available: e.target.checked });
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Coupon Available?"
                    />
                  </Grid>
                  {clubDetails?.coupon_available && (
                    <>
                      <Grid item xs={6}>
                        <div style={{ marginBottom: "10px" }}>
                          <Typography variant="body2" style={{ fontWeight: "600", color: "#6c757d" }}>
                            Coupon Code
                          </Typography>
                        </div>
                        <TextField
                          style={{ width: "100%" }}
                          label="Coupon Code"
                          size="small"
                          variant="outlined"
                          value={clubDetails?.coupon_code}
                          onChange={(e) => {
                            setClubDetails({ ...clubDetails, coupon_code: e.target.value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ marginBottom: "10px" }}>
                          <Typography variant="body2" style={{ fontWeight: "600", color: "#6c757d" }}>
                            Expiry Date
                          </Typography>
                        </div>
                        <TextField
                          style={{ width: "100%" }}
                          label="Expiry Date"
                          type="date"
                          size="small"
                          variant="outlined"
                          value={clubDetails?.coupon_expiry}
                          onChange={(e) => {
                            setClubDetails({ ...clubDetails, coupon_expiry: e.target.value });
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Terms & Conditons
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={conditions}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateConditions}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Cancellation Policies
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={cancellation}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateCancellation}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      Refund Policies
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={refund}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateRefund}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clubDetails?.is_address_required}
                    onChange={(e) => {
                      setClubDetails({ ...clubDetails, is_address_required: e.target.checked });
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Is Address Required at the time of payment checkout?"
              />
            </Grid>
          </div>
          {clubDetails?.segment === "SERVICE" && clubDetails?.category?.title?.toLowerCase().includes("learning") && (
            <div
              style={{
                marginTop: "15px",
                marginBottom: "10px",
                borderBottom: "3px solid  #008080",
                paddingBottom: "30px",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h6"> Instructor profile </Typography>
              </Grid>
              <br />
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Name"
                  size="small"
                  variant="outlined"
                  value={clubDetails?.instructor?.name}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, instructor: { ...clubDetails?.instructor, name: e.target.value } });
                  }}
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="TagLine"
                  size="small"
                  variant="outlined"
                  value={clubDetails?.instructor?.tagline}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, instructor: { ...clubDetails?.instructor, tagline: e.target.value } });
                  }}
                />
              </Grid>
              <br />
              {/* <Grid item xs={12}>
              <div>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <InputLabel htmlFor="outlined-age-native-simple">Tags</InputLabel>
                  <Select
                    native
                    value={state.age}
                    onChange={handleChange}
                    label="Tags"
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <br /> */}
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="YouTube Link"
                  size="small"
                  variant="outlined"
                  value={clubDetails?.instructor?.link}
                  onChange={(e) => {
                    setClubDetails({ ...clubDetails, instructor: { ...clubDetails?.instructor, link: e.target.value } });
                  }}
                />
              </Grid>
              <br />
              {/* <Grid item xs={12}>
              <div>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <InputLabel htmlFor="outlined-age-native-simple">My Course</InputLabel>
                  <Select
                    native
                    value={state.age}
                    onChange={handleChange}
                    label="  My Course"
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <br /> */}
              <Grid item xs={12}>
                <div style={{ marginTop: "-5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      About Us
                    </Typography>
                  </div>
                  <div className={classes.editor}>
                    <Editor
                      editorState={aboutInstructor}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateAbout}
                    />
                  </div>
                </div>
              </Grid>{" "}
              <br />
              {/* <Grid item xs={12}>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Upload Instructor Profile
                  </Typography>
                </div>
                <Form.File id="collegeBanner">
                  <Form.File.Input onChange={(e) => setClubDetails({ ...clubDetails, instructor: { ...clubDetails?.instructor, profile: e.target.files[0] } })} />
                  <p style={{ color: "#6c757d", marginBottom: "0px" }}>Recommended Size: 200px*120px</p>
                </Form.File>
              </Grid>
              <br /> */}
            </div>
          )}
          <div>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              {params?.product_id ? "Edit" : "Create"} {clubDetails?.segment === "SERVICE" ? "Service" : "Product"}
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default AllServices;
