import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, createIconStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert } from '../../util/Notifications';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';


function Applicants() {

    const {clg_id, job_id} = useParams();

    const clgName = 
          JSON.parse(localStorage.getItem('colleges'))
          ?
          JSON.parse(localStorage.getItem('colleges'))[clg_id] :
          'College Name';
    const compName = 
          JSON.parse(localStorage.getItem('companyByJobID'))
          ?
          JSON.parse(localStorage.getItem('companyByJobID'))[job_id] :
          'Company Name';
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiCall(`/api/admin/college/applicant/?job_id=${job_id}&college_id=${clg_id}`, requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        user_id:       json.data[i].user_id,
                        student_name:  json.data[i].first_name + " " + json.data[i].last_name,
                        email:         json.data[i].email,
                        phone:         json.data[i].phone,
                        job_id:        json.data[i].job_id,
                        round:         json.data[i].round,
                        status:        json.data[i].status,
                    }
                    tmpArray.push(obj)
                }
                setData(tmpArray);
                setLoading(false);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: "User ID",       field: "user_id",       headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false, },
        { title: "Student_Name",  field: "student_name",  headerStyle: headerCSS, cellStyle: cellCSS, },
        { title: "Email",         field: "email",         headerStyle: headerCSS, cellStyle: cellCSS,
          render: row => row.email ? 
            <div>{row.email} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.email)} />
            </div> : null
        },
        { title: "Phone",         field: "phone",         headerStyle: headerCSS, cellStyle: cellCSS,
          render: row => row.phone ? 
            <div>{row.phone} 
                <FileCopyIcon style={createIconStyling} onClick={e=>copyToClipboard(row.phone)} />
            </div> : null
        },
        { title: "Job ID",        field: "job_id",        headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false  },
        { title: "Round",         field: "round",         headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Status",        field: "status",        headerStyle: headerCSS, cellStyle: cellCSS },
    ]

    return (
        !loading
        ?
        <div className="jobs__sent">
            <TableWithExport 
                title = {`Applicants from ${clgName} for ${compName}`}
                data = {data}
                columns = {columns}
                fName = "Selected Applicants"
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default Applicants;