import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { DateSelector } from "../../../components/common/DateSelector";
import { praveshAxiosCall } from "../../../util/apiCall";
import { FailureAlert } from "../../../util/Notifications";
import { jobCategoryList } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  chipsection: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "0px",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "10px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function RecruiterReport(props) {
  const classes = useStyles();
  const fullWidth = true;
  const maxWidth = "xs";
  // const [props?.recruiter, setRecruiter] = React.useState([]);

  const [dateFilter, setDateFilter] = useState(
    props?.filters?.advanceFilterDateValue
  );
  const [selectedRecruiterList, setSelectedRecruiterList] = useState(
    props?.filters?.selectedRecruiterList
  );
  const [selectedCompanyList, setSelectedCompanyList] = useState(
    props?.filters?.selectedCompanyList
  );
  const [selectedJobCategory, setSelectedJobCategory] = useState(
    props?.filters?.selectedJobCategory
  );
  const [selectedSortBy, setSelectedSortBy] = useState(
    props?.filters?.selectedSortBy
  );

  const handleClose = () => {
    props.handleClose();
  };

  const checkValidation = () => {
    if (
      !dateFilter ||
      dateFilter?.length < 2 ||
      !dateFilter[0] ||
      !dateFilter[1]
    ) {
      FailureAlert("Please select a date range");
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props?.open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6">Filters</Typography>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedRecruiterList}
              multiple
              onChange={(e, item) => {
                setSelectedRecruiterList(item);
              }}
              id="role345"
              options={props?.recruiter}
              getOptionLabel={(option) => `${option.id}-${option.admin_name}`}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.admin_name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}> Select Recruiters </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedCompanyList}
              multiple
              onChange={(e, item) => {
                setSelectedCompanyList(item);
              }}
              id="role345"
              options={props?.company}
              getOptionLabel={(option) => `${option.id}-${option.name}`}
              renderOption={(option) => {
                return (
                  <span
                    style={{ fontSize: 13 }}
                  >{`${option.id}-${option.name}`}</span>
                );
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}> Select Companies </span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              // value={selectedJob}
              // onChange={(event, newValue) => {
              //   if (newValue) {
              //     setSelectedJob(newValue);
              //   }
              // }}
              value={selectedJobCategory}
              id="role345"
              multiple
              options={jobCategoryList}
              getOptionLabel={(option) => option?.name}
              onChange={(e, item) => {
                setSelectedJobCategory(item);
              }}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}>Select Job Category</span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <DateSelector
              dateRange={[props?.startDate, props?.endDate]}
              setAdvanceFilterDateValue={setDateFilter}
              advanceFilterDateValue={dateFilter}
              isAdvanceFilter={true}
              clearDates={true}
              justifyContent="space-between"
              removeDateFilter={() => {
                setDateFilter([null, null]);
              }}
              label={"Application Date"}
            />
          </div>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              value={selectedSortBy}
              onChange={(e, item) => {
                setSelectedSortBy(item);
              }}
              id="role345"
              options={sortBy}
              getOptionLabel={(option) => `${option.sort}`}
              // renderOption={(option) => {
              //   return (
              //     <span
              //       style={{ fontSize: 13 }}
              //     >{`${option.id}-${option.admin_name}`}</span>
              //   );
              // }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}> Select Sort By</span>
                    }
                    variant="outlined"
                  />
                );
              }}
            />
          </div>

          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ height: "45px", display: "flex" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ height: "45px", display: "flex" }}
                onClick={() => {
                  if (checkValidation()) {
                    props?.setFilters({
                      ...props?.filters,
                      selectedRecruiterList: selectedRecruiterList,
                      selectedCompanyList: selectedCompanyList,
                      advanceFilterDateValue: dateFilter,
                      selectedJobCategory: selectedJobCategory,
                      selectedSortBy: selectedSortBy,
                    });
                    props?.handleClose();
                  }
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

var sortBy = [
  { sort: "Created Date", value: 1 },
  { sort: "Updated Date", value: 0 },
];
