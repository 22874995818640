import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2.5),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function UTMReportDialog({
  openUtm,
  handleCloseUtm,
  basicInfo,
}) {
  const classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const data = [
    {
      title: "UTM Source",
      res: basicInfo?.utm_source ? basicInfo?.utm_source : "NA",
    },
    {
      title: "UTM Medium",
      res: basicInfo?.utm_medium ? basicInfo?.utm_medium : "NA",
    },
    {
      title: "UTM Campaign",
      res: basicInfo?.utm_campaign ? basicInfo?.utm_campaign : "NA",
    },
    {
      title: "UTM Term",
      res: basicInfo?.utm_term ? basicInfo?.utm_term : "NA",
    },
    {
      title: "UTM Content ",
      res: basicInfo?.utm_content ? basicInfo?.utm_content : "NA",
    },
  ];

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openUtm}
        onClose={handleCloseUtm}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseUtm}>
          UTM Details
        </DialogTitle>
        <div style={{ padding: 20, paddingTop: 0 }}>
          {data?.map((item) => (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <Typography variant="body2" style={{ width: 120 }}>
                  {item?.title} :{" "}
                </Typography>
                <Typography variant="body2" style={{ color: "#6C757D" }}>
                  {item?.res}
                </Typography>
              </div>
            </>
          ))}
        </div>
      </Dialog>
    </React.Fragment>
  );
}
