import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useHistory } from "react-router";
import { requestPraveshOptions } from "../constants";
import { apiPraveshCall } from "../util/apiCall";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingLeft: 10 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: {
    padding: 0,
    // position: "absolute",
    // left: "50%",
    // transform: "translate(-50%)",
    // top: "6rem",
    // zIndex: "9999",
    // padding: "1rem",
  },
  box: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridAutoFlow: "row",
    gap: "1rem",
  },
  sections: {
    display: "flex",
    gap: "0.5rem",
    marginBottom: "0.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    whiteSpace: "nowrap",
  },
  fieldText: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
});

function FilterPopup({
  showFilterPopup,
  setShowFilterPopup,
  reRender,
  setReRender,
  setSelectedPageNo,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [JobId, setJobId] = useState([]);
  const [Name, setName] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [Role, setRole] = useState("");
  const [Industry, setIndustry] = useState([]);
  const [JobTitle, setJobTitle] = useState([]);
  const [DateOfApplication, setDateOfApplication] = useState([]);
  const [SourceOfApplication, setSourceOfApplication] = useState([]);
  const [CurrentLocation, setCurrentLocation] = useState([]);
  const [PreferredLocations, setPreferredLocations] = useState([]);
  const [TotalExperienceMin, setTotalExperienceMin] = useState("");
  const [TotalExperienceMax, setTotalExperienceMax] = useState("");
  const [CurrentCompanyName, setCurrentCompanyName] = useState([]);
  const [CurrentCompanyDesignation, setCurrentCompanyDesignation] = useState(
    []
  );
  const [FunctionalArea, setFunctionalArea] = useState([]);
  const [KeySkills, setKeySkills] = useState([]);
  const [CurrentCtcMin, setCurrentCtcMin] = useState("");
  const [CurrentCtcMax, setCurrentCtcMax] = useState("");
  const [NoticePeriodMin, setNoticePeriodMin] = useState("");
  const [NoticePeriodMax, setNoticePeriodMax] = useState("");
  const [ResumeHeadline, setResumeHeadline] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [UgDegree, setUgDegree] = useState([]);
  const [UgSpecialization, setUgSpecialization] = useState([]);
  const [UgUniversityOrInstituteName, setUgUniversityOrInstituteName] =
    useState([]);
  const [UgYearMin, setUgYearMin] = useState("");
  const [UgYearMax, setUgYearMax] = useState("");
  const [PgDegree, setPgDegree] = useState([]);
  const [PgSpecialization, setPgSpecialization] = useState([]);
  const [PgUniversityOrInstituteName, setPgUniversityOrInstituteName] =
    useState([]);
  const [PgYearMin, setPgYearMin] = useState("");
  const [PgYearMax, setPgYearMax] = useState("");
  const [PhdDegree, setPhdDegree] = useState([]);
  const [PhdSpecialization, setPhdSpecialization] = useState([]);
  const [PhdUniversityOrInstituteName, setPhdUniversityOrInstituteName] =
    useState([]);
  const [PhdYearMin, setPhdYearMin] = useState("");
  const [PhdYearMax, setPhdYearMax] = useState("");
  const [Gender, setGender] = useState([]);
  const [MaritalStatus, setMaritalStatus] = useState([]);
  const [HomeTown, setHomeTown] = useState([]);
  const [Pincode, setPincode] = useState([]);
  const [DateofBirthMin, setDateofBirthMin] = useState("");
  const [DateofBirthMax, setDateofBirthMax] = useState("");
  const [PermanentAddress, setPermanentAddress] = useState([]);
  const [CreateDateMin, setCreateDateMin] = useState("");
  const [CreateDateMax, setCreateDateMax] = useState("");
  const [uploadedBy, setUploadedBy] = useState([]);

  const [jobIdOption, setJobIdOption] = useState([]);
  const [genderOption, setGenderOption] = useState(["Male", "Female"]);
  const [martialStatusOption, setMartialStatusOption] = useState([
    "Married",
    "Unmarried",
  ]);
  
  const [sourceOfApplicationOption, setSourceOfApplicationOption] = useState(
    []
  );
  const [currentLocationOption, setCurrentLocationOption] = useState([]);
  const [preferredLocationsOption, setPreferredLocationsOption] = useState([]);
  
  const [currentCompanyNameOption, setCurrentCompanyNameOption] = useState([]);
  const [currentCompanyDesignationOption, setCurrentCompanyDesignationOption] =
    useState([]);
  
  const [keySkillsOption, setKeySkillsOption] = useState([]);
  const [ugDegreeOption, setUgDegreeOption] = useState([]);
  const [ugSpecializationOption, setUgSpecializationOption] = useState([]);
  const [
    ugUniversityOrInstituteNameOption,
    setUgUniversityOrInstituteNameOption,
  ] = useState([]);
  const [pgDegreeOption, setPgDegreeOption] = useState([]);
  const [pgSpecializationOption, setPgSpecializationOption] = useState([]);
  const [
    pgUniversityOrInstituteNameOption,
    setPgUniversityOrInstituteNameOption,
  ] = useState([]);
  const [phdDegreeOption, setPhdDegreeOption] = useState([]);
  const [phdSpecializationOption, setPhdSpecializationOption] = useState([]);
  const [
    phdUniversityOrInstituteNameOption,
    setPhdUniversityOrInstituteNameOption,
  ] = useState([]);
  const [homeTownOption, setHomeTownOption] = useState([]);
  
  const [dateOfBirthOptionMin, setDateOfBirthOptionMin] = useState();
  const [dateOfBirthOptionMax, setDateOfBirthOptionMax] = useState();
  const [createDateOptionMin, setCreateDateOptionMin] = useState();
  const [createDateOptionMax, setCreateDateOptionMax] = useState();
  const [uploadedByOption, setUploadedByOption] = useState([]);

  useEffect(() => {
    const queryParams = QueryString.parse(window.location.search);
    setJobId(queryParams?.job_id ? [queryParams?.job_id] : []);
    setName(queryParams?.name ? queryParams.name : "");
    setEmailId(queryParams?.email_id ? queryParams.email_id : "");
    setPhoneNo(queryParams?.phone_no ? queryParams.phone_no : "");
    setRole(queryParams?.role ? queryParams.role : "");
    setIndustry(queryParams?.industry ? [queryParams.industry] : []);
    setJobTitle(queryParams?.job_title ? [queryParams.job_title] : []);
    setDateOfApplication(
      queryParams?.date_of_application ? [queryParams.date_of_application] : []
    );
    setSourceOfApplication(
      queryParams?.source_of_application
        ? [queryParams.source_of_application]
        : []
    );
    setCurrentLocation(
      queryParams?.current_location ? [queryParams.current_location] : []
    );
    setPreferredLocations(
      queryParams?.preferred_locations ? [queryParams.preferred_locations] : []
    );
    setTotalExperienceMin(
      queryParams?.total_experience_min ? queryParams.total_experience_min : ""
    );
    setTotalExperienceMax(
      queryParams?.total_experience_max ? queryParams.total_experience_max : ""
    );
    setCurrentCompanyName(
      queryParams?.current_company_name
        ? [queryParams.current_company_name]
        : []
    );
    setCurrentCompanyDesignation(
      queryParams?.current_company_designation
        ? [queryParams.current_company_designation]
        : []
    );
    setFunctionalArea(
      queryParams?.functional_area ? [queryParams.functional_area] : []
    );
    setKeySkills(queryParams?.key_skills ? [queryParams.key_skills] : []);
    setCurrentCtcMin(
      queryParams?.current_ctc_min ? queryParams.current_ctc_min : ""
    );
    setCurrentCtcMax(
      queryParams?.current_ctc_max ? queryParams.current_ctc_max : ""
    );
    setNoticePeriodMin(
      queryParams?.notice_period_min ? queryParams.notice_period_min : ""
    );
    setNoticePeriodMax(
      queryParams?.notice_period_max ? queryParams.notice_period_max : ""
    );
    setResumeHeadline(
      queryParams?.resume_headline
        ? [queryParams.resume_headlineresume_headline]
        : []
    );
    setSummary(queryParams?.summary ? [queryParams.summary] : []);
    setUgDegree(queryParams?.ug_degree ? [queryParams.ug_degree] : []);
    setUgSpecialization(
      queryParams?.ug_specialization ? [queryParams.ug_specialization] : []
    );
    setUgUniversityOrInstituteName(
      queryParams?.ug_university_or_institute_name
        ? [queryParams.ug_university_or_institute_name]
        : []
    );
    setUgYearMin(queryParams?.ug_year_min ? queryParams.ug_year_min : "");
    setUgYearMax(queryParams?.ug_year_max ? queryParams.ug_year_max : "");
    setPgDegree(queryParams?.pg_degree ? [queryParams.pg_degree] : []);
    setPgSpecialization(
      queryParams?.pg_specialization ? [queryParams.pg_specialization] : []
    );
    setPgUniversityOrInstituteName(
      queryParams?.pg_university_or_institute_name
        ? [queryParams.pg_university_or_institute_name]
        : []
    );
    setPgYearMin(queryParams?.pg_year_min ? queryParams.pg_year_min : "");
    setPgYearMax(queryParams?.pg_year_max ? queryParams.pg_year_max : "");
    setPhdDegree(queryParams?.phd_degree ? [queryParams.phd_degree] : []);
    setPhdSpecialization(
      queryParams?.phd_specialization ? [queryParams.phd_specialization] : []
    );
    setPhdUniversityOrInstituteName(
      queryParams?.phd_university_or_institute_name
        ? [queryParams.phd_university_or_institute_name]
        : []
    );
    setPhdYearMin(queryParams?.phd_year_min ? queryParams.phd_year_min : "");
    setPhdYearMax(queryParams?.phd_year_max ? queryParams.phd_year_max : "");
    setGender(queryParams?.gender ? [queryParams.gender] : []);
    setMaritalStatus(
      queryParams?.marital_status ? [queryParams.marital_status] : []
    );
    setHomeTown(queryParams?.home_town ? [queryParams.home_town] : []);
    setPincode(queryParams?.pincode ? [queryParams.pincode] : []);
    setDateOfBirthOptionMax(
      queryParams?.date_of_birth_max ? queryParams.date_of_birth_max : ""
    );
    setDateOfBirthOptionMin(
      queryParams?.date_of_birth_min ? queryParams.date_of_birth_min : ""
    );
    setPermanentAddress(
      queryParams?.permanent_address ? [queryParams.permanent_address] : []
    );
    setUploadedBy(queryParams?.uploaded_by ? [queryParams.uploaded_by] : []);
    setCreateDateOptionMin(
      queryParams?.create_time_min ? queryParams.create_time_min : ""
    );
    setCreateDateOptionMax(
      queryParams?.create_time_max ? queryParams.create_time_max : ""
    );
  }, []);

  const handleSubmit = () => {
    let searchString = "";
    if (JobId.length !== 0) searchString += `job_id=${JobId}`;
    if (Name !== "") searchString += `&name=${Name}`;
    if (EmailId !== "") searchString += `&email_id=${EmailId}`;
    if (PhoneNo !== "") searchString += `&phone_no=${PhoneNo}`;
    if (Role !== "") searchString += `&role=${Role}`;
    if (Industry.length !== 0) searchString += `&industry=${Industry}`;
    if (JobTitle.length !== 0) searchString += `&job_title=${JobTitle}`;
    if (DateOfApplication.length !== 0)
      searchString += `&date_of_application=${DateOfApplication}`;
    if (SourceOfApplication.length !== 0)
      searchString += `&source_of_application=${SourceOfApplication}`;
    if (CurrentLocation.length !== 0)
      searchString += `&current_location=${CurrentLocation}`;
    if (PreferredLocations.length !== 0)
      searchString += `&preferred_locations=${PreferredLocations}`;
    if (TotalExperienceMin !== "")
      searchString += `&total_experience_min=${TotalExperienceMin}`;
    if (TotalExperienceMax !== "")
      searchString += `&total_experience_max=${TotalExperienceMax}`;
    if (CurrentCompanyName.length !== 0)
      searchString += `&current_company_name=${CurrentCompanyName}`;
    if (CurrentCompanyDesignation.length !== 0)
      searchString += `&current_company_designation=${CurrentCompanyDesignation}`;
    if (FunctionalArea.length !== 0)
      searchString += `&functional_area=${FunctionalArea}`;
    if (KeySkills.length !== 0) searchString += `&key_skills=${KeySkills}`;
    if (CurrentCtcMin !== "")
      searchString += `&current_ctc_min=${CurrentCtcMin}`;
    if (CurrentCtcMax !== "")
      searchString += `&current_ctc_max=${CurrentCtcMax}`;
    if (NoticePeriodMin !== "")
      searchString += `&notice_period_min=${NoticePeriodMin}`;
    if (NoticePeriodMax !== "")
      searchString += `&notice_period_max=${NoticePeriodMax}`;
    if (ResumeHeadline.length !== 0)
      searchString += `&resume_headline=${ResumeHeadline}`;
    if (Summary.length !== 0) searchString += `&summary=${Summary}`;
    if (UgDegree.length !== 0) searchString += `&ug_degree=${UgDegree}`;
    if (UgSpecialization.length !== 0)
      searchString += `&ug_specialization=${UgSpecialization}`;
    if (UgUniversityOrInstituteName.length !== 0)
      searchString += `&ug_university_or_institute_name=${UgUniversityOrInstituteName}`;
    if (UgYearMin !== "") searchString += `&ug_year_min=${UgYearMin}`;
    if (UgYearMax !== "") searchString += `&ug_year_max=${UgYearMax}`;
    if (PgDegree.length !== 0) searchString += `&pg_degree=${PgDegree}`;
    if (PgSpecialization.length !== 0)
      searchString += `&pg_specialization=${PgSpecialization}`;
    if (PgUniversityOrInstituteName.length !== 0)
      searchString += `&pg_university_or_institute_name=${PgUniversityOrInstituteName}`;
    if (PgYearMin !== "") searchString += `&pg_year_min=${PgYearMin}`;
    if (PgYearMax !== "") searchString += `&pg_year_max=${PgYearMax}`;
    if (PhdDegree.length !== 0) searchString += `&phd_degree=${PhdDegree}`;
    if (PhdSpecialization.length !== 0)
      searchString += `&phd_specialization=${PhdSpecialization}`;
    if (PhdUniversityOrInstituteName.length !== 0)
      searchString += `&phd_university_or_institute_name=${PhdUniversityOrInstituteName}`;
    if (PhdYearMin !== "") searchString += `&phd_year_min=${PhdYearMin}`;
    if (PhdYearMax !== "") searchString += `&phd_year_max=${PhdYearMax}`;
    if (Gender.length !== 0) searchString += `&gender=${Gender}`;
    if (MaritalStatus.length !== 0)
      searchString += `&marital_status=${MaritalStatus}`;
    if (HomeTown.length !== 0) searchString += `&home_town=${HomeTown}`;
    if (Pincode.length !== 0) searchString += `&pincode=${Pincode}`;
    if (dateOfBirthOptionMin !== "")
      searchString += `&date_of_birth_min=${dateOfBirthOptionMin}`;
    if (dateOfBirthOptionMax !== "")
      searchString += `&date_of_birth_max=${dateOfBirthOptionMax}`;
    if (PermanentAddress.length !== 0)
      searchString += `&permanent_address=${PermanentAddress}`;
    if (createDateOptionMin !== "")
      searchString += `&create_time_min=${createDateOptionMin}`;
    if (createDateOptionMax !== "")
      searchString += `&create_time_max=${createDateOptionMax}`;
    if (uploadedBy.length !== 0) searchString += `&uploaded_by=${uploadedBy}`;

    history.replace(`/admin/3rd-party-search/?${searchString}`);
    setShowFilterPopup(!showFilterPopup);
    setReRender(!reRender);
    setSelectedPageNo(1);
  };

  const handleClear = () => {
    setJobId([]);
    setName("");
    setEmailId("");
    setPhoneNo("");
    setRole("");
    setIndustry([]);
    setJobTitle([]);
    setDateOfApplication([]);
    setSourceOfApplication([]);
    setCurrentLocation([]);
    setPreferredLocations([]);
    setTotalExperienceMin("");
    setTotalExperienceMax("");
    setCurrentCompanyName([]);
    setCurrentCompanyDesignation([]);
    setFunctionalArea([]);
    setKeySkills([]);
    setCurrentCtcMin("");
    setCurrentCtcMax("");
    setNoticePeriodMin("");
    setNoticePeriodMax("");
    setResumeHeadline([]);
    setSummary([]);
    setUgDegree([]);
    setUgSpecialization([]);
    setUgUniversityOrInstituteName([]);
    setUgYearMin("");
    setUgYearMax("");
    setPgDegree([]);
    setPgSpecialization([]);
    setPgUniversityOrInstituteName([]);
    setPgYearMin("");
    setPgYearMax("");
    setPhdDegree([]);
    setPhdSpecialization([]);
    setPhdUniversityOrInstituteName([]);
    setPhdYearMin("");
    setPhdYearMax("");
    setGender([]);
    setMaritalStatus([]);
    setHomeTown([]);
    setPincode([]);
    setDateOfBirthOptionMin("");
    setDateOfBirthOptionMax("");
    setPermanentAddress([]);
    setCreateDateOptionMin("");
    setCreateDateOptionMax("");
    setUploadedBy([]);
  };
  const handleCancel = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  return (
    <Dialog fullScreen open={showFilterPopup} onClose={handleCancel}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        Filter
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          spacing={2}
          style={{ width: "100%", margin: 0, position: "relative" }}
        >
          <Grid item md={6} style={{ position: "relative" }}>
            <Grid
              container
              spacing={2}
              style={{ width: "100%", margin: 0, position: "relative" }}
            >
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Job Id :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                {" "}
                <Autocomplete
                  multiple
                  disablePortal
                  id="job_id"
                  options={jobIdOption}
                  value={JobId}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setJobId(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Job Id"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=job_id&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json?.success) {
                            setJobIdOption(json?.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Name :
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  style={{ width: "100%" }}
                  id="total_experience_min"
                  label="Name"
                  variant="outlined"
                  size="small"
                  value={Name}
                  onChange={(item) => {
                    item ? setName(item.target.value) : setName("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Phone No. :
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  style={{ width: "100%" }}
                  id="total_experience_min"
                  label="Phone No"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={PhoneNo}
                  onChange={(item) => {
                    item ? setPhoneNo(item.target.value) : setPhoneNo("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Current Location :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="current_location"
                  value={CurrentLocation}
                  options={currentLocationOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setCurrentLocation(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Current Location"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=current_location&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setCurrentLocationOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  {" "}
                  Total Experience :
                </Typography>
              </Grid>
              <Grid item md={4}>
                {" "}
                <TextField
                  style={{ width: "100%" }}
                  id="total_experience_min"
                  label="Min(years)"
                  variant="outlined"
                  size="small"
                  value={TotalExperienceMin}
                  onChange={(item) => {
                    item
                      ? setTotalExperienceMin(item.target.value)
                      : setTotalExperienceMin("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="total_experience_min"
                  label="Max(years)"
                  variant="outlined"
                  size="small"
                  value={TotalExperienceMax}
                  onChange={(item) => {
                    item
                      ? setTotalExperienceMax(item.target.value)
                      : setTotalExperienceMax("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Current Company Designation :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="current_commpany_designation"
                  value={CurrentCompanyDesignation}
                  options={currentCompanyDesignationOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setCurrentCompanyDesignation(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Current company designation"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=current_company_designation&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setCurrentCompanyDesignationOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Current ctc :
                </Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="current_ctc"
                  label="Min(lacs)"
                  variant="outlined"
                  size="small"
                  value={CurrentCtcMin}
                  onChange={(item) => {
                    item
                      ? setCurrentCtcMin(item.target.value)
                      : setCurrentCtcMin("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="current_ctc"
                  label="Max(lacs)"
                  variant="outlined"
                  size="small"
                  value={CurrentCtcMax}
                  onChange={(item) => {
                    item
                      ? setCurrentCtcMax(item.target.value)
                      : setCurrentCtcMax("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  UG Degree :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="ug_degree"
                  value={UgDegree}
                  options={ugDegreeOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setUgDegree(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="UG Degree"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=ug_degree&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setUgDegreeOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  UG University :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="ug_university"
                  value={UgUniversityOrInstituteName}
                  options={ugUniversityOrInstituteNameOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setUgUniversityOrInstituteName(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="UG University"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=ug_university_institute_name&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setUgUniversityOrInstituteNameOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  PG Degree :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="pg_degree"
                  value={PgDegree}
                  options={pgDegreeOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setPgDegree(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="PG Degree"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=pg_degree&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setPgDegreeOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  PG University :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="pg_university"
                  value={PgUniversityOrInstituteName}
                  options={pgUniversityOrInstituteNameOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setPgUniversityOrInstituteName(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="PG University"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=pg_university_institute_name&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setPgUniversityOrInstituteNameOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Phd Degree :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="phd_degree"
                  value={PhdDegree}
                  options={phdDegreeOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setPhdDegree(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Phd Degree"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=phd_degree&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setPhdDegreeOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Phd University :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="phd_university"
                  value={PhdUniversityOrInstituteName}
                  options={phdUniversityOrInstituteNameOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setPhdUniversityOrInstituteName(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Phd University"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=phd_university_institute_name&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setPhdUniversityOrInstituteNameOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Gender :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="gender"
                  value={Gender}
                  options={genderOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setGender(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Gender"
                      size="small"
                      // style={{ overflow: "hidden" }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Home Town :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="home_town"
                  value={HomeTown}
                  options={homeTownOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setHomeTown(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Home Town"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=home_town&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setHomeTownOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Create Date :
                </Typography>
              </Grid>
              <Grid item md={4} style={{ display: "grid" }}>
                {" "}
                <DatePicker
                  showIcon
                  value={createDateOptionMin}
                  onChange={(date) => {
                    setCreateDateOptionMin(date);
                    setCreateDateMin(date);
                  }}
                />
              </Grid>
              <Grid item md={4} style={{ display: "grid" }}>
                <DatePicker
                  showIcon
                  value={createDateOptionMax}
                  onChange={(date) => {
                    setCreateDateOptionMax(date);
                    setCreateDateMax(date);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} style={{ position: "relative" }}>
            <Grid
              container
              spacing={2}
              style={{ width: "100%", margin: 0, position: "relative" }}
            >
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Source of Application :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="source_of_application"
                  value={SourceOfApplication}
                  options={sourceOfApplicationOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setSourceOfApplication(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Source of Application"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=source_of_application&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setSourceOfApplicationOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Email ID :
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  style={{ width: "100%" }}
                  id="total_experience_min"
                  label="Email ID"
                  variant="outlined"
                  size="small"
                  value={EmailId}
                  onChange={(item) => {
                    item ? setEmailId(item.target.value) : setEmailId("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Role :
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  style={{ width: "100%" }}
                  id="total_experience_min"
                  label="Role"
                  variant="outlined"
                  size="small"
                  value={Role}
                  onChange={(item) => {
                    item ? setRole(item.target.value) : setRole("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Preferred Location :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="preferred_locations"
                  value={PreferredLocations}
                  options={preferredLocationsOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setPreferredLocations(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Preferred Location"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=preferred_locations&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setPreferredLocationsOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Current Company Name :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="current_commpany_name"
                  value={CurrentCompanyName}
                  options={currentCompanyNameOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setCurrentCompanyName(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Current company name"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=current_company_name&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setCurrentCompanyNameOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Key Skills :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal={true}
                  id="key_skills"
                  value={KeySkills}
                  options={keySkillsOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setKeySkills(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Key Skills"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=key_skills&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setKeySkillsOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Notice Period :
                </Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="notice_period_min"
                  label="Min(days)"
                  variant="outlined"
                  size="small"
                  value={NoticePeriodMin}
                  onChange={(item) => {
                    item
                      ? setNoticePeriodMin(item.target.value)
                      : setNoticePeriodMin("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="notice_period_max"
                  label="Max(days)"
                  variant="outlined"
                  size="small"
                  value={NoticePeriodMax}
                  onChange={(item) => {
                    item
                      ? setNoticePeriodMax(item.target.value)
                      : setNoticePeriodMax("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  UG Specialization :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="ug_specialization"
                  value={UgSpecialization}
                  options={ugSpecializationOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setUgSpecialization(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="UG Specialization"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=ug_specialization&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setUgSpecializationOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
             
             
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  UG Year :
                </Typography>
              </Grid>
              
              <Grid item md={4} style={{ display: "block" }}>
                
                <TextField
                  style={{ width: "100%" }}
                  id="ug_year_min"
                  label="Min"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={UgYearMin}
                  onChange={(item) => {
                    item ? setUgYearMin(item.target.value) : setUgYearMin("");
                  }}
                />
              </Grid>
              
              <Grid item md={4} style={{ display: "block" }}>
                
                <TextField
                  style={{ width: "100%" }}
                  id="ug_year_max"
                  label="Max"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={UgYearMax}
                  onChange={(item) => {
                    item ? setUgYearMax(item?.target?.value) : setUgYearMax("");
                  }}
                />
              </Grid>

              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  PG Specialization :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="pg_specialization"
                  value={PgSpecialization}
                  options={pgSpecializationOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setPgSpecialization(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="PG Specialization"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=pg_specialization&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setPgSpecializationOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  PG Year :
                </Typography>
              </Grid>
              <Grid item md={4} style={{ display: "block" }}>
                
                <TextField
                  style={{ width: "100%" }}
                  id="pg_year_min"
                  label="Min"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={PgYearMin}
                  onChange={(item) => {
                    item ? setPgYearMin(item.target.value) : setPgYearMin("");
                  }}
                />
              </Grid>
              <Grid item md={4} style={{ display: "block" }}>
                
                <TextField
                  style={{ width: "100%" }}
                  id="pg_year_max"
                  label="Max"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={PgYearMax}
                  onChange={(item) => {
                    item ? setPgYearMax(item.target.value) : setPgYearMax("");
                  }}
                />
                
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Phd Specialization :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="phd_specialization"
                  value={PhdSpecialization}
                  options={phdSpecializationOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setPhdSpecialization(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Phd Specialization"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=phd_specialization&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setPhdSpecializationOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Phd Year :
                </Typography>
              </Grid>
              <Grid item md={4} style={{ display: "block" }}>
                
                <TextField
                  style={{ width: "100%" }}
                  id="phd_year_min"
                  label="Min"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={PhdYearMin}
                  onChange={(item) => {
                    item ? setPhdYearMin(item.target.value) : setPhdYearMin("");
                  }}
                />
              </Grid>
              <Grid item md={4} style={{ display: "block" }}>
                
                <TextField
                  style={{ width: "100%" }}
                  id="phd_year_max"
                  label="Max"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={PhdYearMax}
                  onChange={(item) => {
                    item ? setPhdYearMax(item.target.value) : setPhdYearMax("");
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Marital Status :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="marital_status"
                  value={MaritalStatus}
                  options={martialStatusOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item && setMaritalStatus(item);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Marital Status"
                      size="small"
                      // style={{ overflow: "hidden" }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Date of Birth :
                </Typography>
              </Grid>
             
              <Grid item md={4} style={{ display: "grid" }}>
                {" "}
                <DatePicker
                  showIcon
                  value={dateOfBirthOptionMin}
                  onChange={(date) => {
                    setDateOfBirthOptionMin(date);
                    setDateofBirthMin(date);
                  }}
                />
              </Grid>
              <Grid item md={4} style={{ display: "grid" }}>
                <DatePicker
                  showIcon
                  value={dateOfBirthOptionMax}
                  onChange={(date) => {
                    setDateOfBirthOptionMax(date);
                    setDateofBirthMax(date);
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.fieldText}>
                  Uploaded By :
                </Typography>
              </Grid>
              <Grid item md={8} style={{ display: "block" }}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="uploaded_by"
                  value={uploadedBy}
                  options={uploadedByOption}
                  getOptionLabel={(item) => item}
                  onChange={(e, item) => {
                    item ? setUploadedBy(item) : setUploadedBy();
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Uploaded By"
                      size="small"
                      // style={{ overflow: "hidden" }}
                      onChange={(element) => {
                        apiPraveshCall(
                          `/api/admin/third_party/type_n_search/filter/?column_name=username&search=${element.target.value}`,
                          requestPraveshOptions
                        ).then((json) => {
                          if (json.success) {
                            setUploadedByOption(json.data);
                          }
                        });
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div style={{ paddingRight: 20 }}>
          <Button
            variant="outlined"
            style={{ width: "200px", borderColor: "#007bff", color: "#007bff" }}
            onClick={handleClear}
          >
            Clear
          </Button>

          <Button
            variant="contained"
            style={{
              width: "200px",
              marginLeft: "10px",
              backgroundColor: "#007bff",
              color: "#fff",
              boxShadow: "none",
            }}
            onClick={handleSubmit}
          >
            Apply
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default FilterPopup;
