import { MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS, requestPraveshOptions } from "../../constants";
import "../../css/Miscellaneous.css";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import TableWithExport from "../TableWithExport";

const token = localStorage.getItem("token");

function SubCategoryVault() {
  const [data, setData] = useState([]);
  const [ruleKey, setRuleKey] = useState(null);
  const [ruleText, setRuleText] = useState(null);
  const [inputField, setInputField] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiCall("/api/vault/admin/subcategories/", requestPraveshOptions).then((json) => {
      if (json?.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].name,
            category_id: json.data[i].category_id,
            category_name: json.data[i].category_name,

            is_active: json.data[i].is_active ? "Active" : "In Active",
          };
          tmpArray.push(obj);
        }
        setLoading(false);
        setData(tmpArray);
      } else {
        FailureAlert(json.error);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiCall("/api/vault/admin/categories/?is_active=1", requestPraveshOptions).then((json) => {
      if (json?.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: json.data[i].id,
            name: json.data[i].name,
          };
          tmpArray.push(obj);
        }
        setLoading(false);
        setDataCategory(tmpArray);
      } else {
        FailureAlert(json.error);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { title: "Sub Category id", field: "id", headerStyle: headerCSS, cellStyle: cellCSS, editable: "never" },
    { title: "Name", field: "name", headerStyle: headerCSS, cellStyle: cellCSS },
    {
      title: "Category",
      field: "category_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editComponent: (props) => (
        <Select
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        >
          {dataCategory?.map((item) => (
            <MenuItem value={item?.id}>{item?.name}</MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: "Status",
      field: "is_active",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Active : "Active", 'In Active': "In Active" },
      searchable: false,
      filterPlaceholder: "Select",

      editComponent: (props) => (
        <Select
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        >
          <MenuItem value="Yes">Active</MenuItem>
          <MenuItem value="No">In Active</MenuItem>
        </Select>
      ),
    },
    // {
    //     headerStyle: headerCSS, cellStyle: cellCSS, export: false,
    //     render: row =>
    //         <div>
    //             <Button variant="outline-danger" size="sm" onClick={e => deleteAccessControl(e, row.id)}>Delete</Button>
    //         </div>
    // },
  ];

  const requestOptionsPost = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      is_active: inputField,
      name: ruleKey,
      category: ruleText,
    }),
  };

  const edit = {
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          updateRow(newData, oldData);
          setData([...dataUpdate]);
          resolve();
        }, 1000);
      }),
  };

  const updateRow = (newData, oldData) => {
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        ...(oldData.name !== newData.name && { name: newData.name }),
        ...(oldData.is_active !== newData.is_active && { is_active: newData.is_active === "Yes" ? true : false }),
        ...(oldData.category_name !== newData.category_name && { category: newData.category_name }),
      }),
    };
    apiCall(`/api/vault/admin/subcategories/${oldData.id}`, requestOptionsPatch).then((res) => {
      if (res?.success) {
        SuccessAlert(res.data?.message || "Successfully Updated");
        setInterval(function () {
          window.location.reload();
        }, 1000);
      } else FailureAlert(res.error);
    });
  };

  const addNewSkill = (e) => {
    e.preventDefault();
    apiCall("/api/vault/admin/subcategories/", requestOptionsPost).then((res) => {
      if (res?.success) {
        SuccessAlert(res.data?.message || "Successfully created");
        setInterval(function () {
          window.location.reload();
        }, 1000);
      } else FailureAlert(res.error);
    });
    e.target.reset();
  };

  // const deleteAccessControl = (e, accessControlId) => {
  //     apiSanchalanCall(`/api/settings/tnp_rules/${oldData.id}/`, {
  //         method: 'DELETE',
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': `Token ${localStorage.getItem('token')}`
  //         }
  //     })
  //         .then((res) => {
  //             if (res.success) {
  //                 SuccessAlert(res.data?.message);
  //                 setInterval(function () {
  //                     window.location.reload();
  //                 }, 1000);
  //             }
  //             else FailureAlert(res.error);
  //         })
  // }

  return !loading ? (
    <div className="education__skils">
      <div className="education__skils__form">
        <h4>Add Vault Sub Categories</h4>
        <Form onSubmit={(e) => addNewSkill(e)}>
          <Form.Row>
            <Form.Group as={Col} controlId="RuleKey">
              <Form.Label>Sub Category Name*</Form.Label>
              <Form.Control
                name="RuleKey"
                type="text"
                placeholder="Enter Name"
                onChange={(e) => {
                  setRuleKey(e.target.value);
                }}
                required
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="RuleKey">
              <Form.Label>Select Category*</Form.Label>
              <br />
              <Select
                value={ruleText}
                onChange={(e) => {
                  setRuleText(e.target.value);
                }}
                style={{ width: "50%" }}
                variant="outlined"
              >
                {dataCategory?.map((item) => (
                  <MenuItem value={item?.id}>{item?.name}</MenuItem>
                ))}
              </Select>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="inputField">
              <Form.Label>Status: &nbsp;</Form.Label>
              <Form.Check
                inline
                type="radio"
                name="inputField"
                id="Yes"
                value="Yes"
                label="Active"
                checked={inputField}
                onChange={(e) => {
                  setInputField(!inputField);
                }}
              />
              <Form.Check
                inline
                type="radio"
                name="inputField"
                id="No"
                value="No"
                label="In Active"
                checked={!inputField}
                onChange={(e) => {
                  setInputField(!inputField);
                }}
              />
            </Form.Group>
          </Form.Row>

          <Button type="submit" variant="info">
            Add Sub Category
          </Button>
        </Form>
      </div>
      <TableWithExport title="Vault Sub Categories" data={data?.sort((a, b) => b.rule_id - a.rule_id)} columns={columns} fName="Selected Placement Rules" editableObject={edit} />
    </div>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default SubCategoryVault;
