import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../util/copyToClipboard';
import { gifStyling, createIconStyling, requestPraveshOptions } from '../../constants';
import {Form, Col, Table, Button} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {TabPanel} from '../../util/TabPanel';
import {AppBar, Tabs, Tab} from '@material-ui/core';

    const token = localStorage.getItem('token');
  
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
          id: `wrapped-tab-${index}`,
          'aria-controls': `wrapped-tabpanel-${index}`,
        };
    } 

function UpdateBanner() {

    const [userType, setUserType] = useState(null);
    const [pageType, setPageType] = useState('');
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [banners, setBanners] = useState([]);
    const [count, setCount] = useState(0);

    const [file, setFile] = useState(null);
    const [redirectionUrl, setRedirectionUrl] = useState(null);

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    // Fetching Pages
    useEffect(()=> {
        setLoading(true);
        apiCall(`/api/admin/page/`, requestPraveshOptions)
            .then((res)=> {
                setPages(res?.data)
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    // Fetching existing Banners
    useEffect(()=> {
        setLoading(true);
        if(userType) {
            apiCall(`/api/admin/banner/?page_name=${pageType}`, requestPraveshOptions)
            .then((res)=> {
                setBanners(res.data)
                setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType, count]);

    const deleteBanner = (e, banner) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({
                is_active: !banner?.is_active
            })
        };
        apiCall(`/api/admin/banner/${banner.banner_id}/`, requestOptionsPatch)
            .then((res) => {
                if(res.success) {
                    SuccessAlert(res.data.message);
                    setCount(count+1);
                }
                else {
                    FailureAlert(res.error);
                }
            })
    }

    const addBanner = (e) => {
        e.preventDefault();
        if(!file) {
            FailureAlert('Upload an image to continue...')
            return;
        }
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append('page', userType);
        formData.append('image', file);
        formData.append('is_active', true);
        redirectionUrl && formData.append('banner_url', redirectionUrl);
        const requestOptionsPost = {
            method: 'POST',
            headers: { 'Authorization': `Token ${token}` },
            body: formData
        };
        apiCall('/api/admin/banner/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.error);
            }
        })
        .then(setFile(null))
        setLoading(false)
        e.target.reset();
    }

    return (
        <div className="update__banner">
            <Form.Row style={{marginLeft: '10%', width: '80%', marginTop: '2%'}}>
                <Form.Group as={Col} controlId="userType">
                    <Form.Control 
                        as="select"
                        onChange={e=> {
                            setPageType(JSON.parse(e.target.value).page_name)
                            setUserType(JSON.parse(e.target.value).page_id)
                        }} 
                    >
                        <option value={JSON.stringify({"page_id":null, "page_name":"--select--"})}>--Select Page Type--</option>
                        {
                            pages.map((page, key) => { return (
                                <option key={key} value={JSON.stringify(page)}> {page.page_name} </option>
                            )})
                        }
                    </Form.Control>    
                </Form.Group>
            </Form.Row>
            {
                userType && banners
                ?
                !loading
                ?
                <div className="update__banner__section">
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Existing Banners" {...a11yProps(0)} />
                            <Tab label="Add a New Banner" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    {/* Deleting existing Banners */}
                    <TabPanel className='upload_Pic' value={value} index={0} dir={theme.direction}>
                    {
                        banners.length>0
                        ?
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Banner</th>
                                    <th>Status</th>
                                    <th>Banner URL</th>
                                    <th>Redirection URL</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    banners.map((banner, key) => {
                                    return (
                                        <tr key={key}>
                                            <td style={{display: 'flex', flexDirection: 'row', placeItems:'center'}}>
                                                <img  
                                                    style={{height:'35%', width:'300px'}}
                                                    src={banner.banner_path} 
                                                    alt="banner"
                                                />
                                            </td>
                                            <td>
                                                <div className="status" style={{marginLeft: '2%'}}>
                                                    {
                                                        banner.is_active ? 
                                                            <span style={{color:'green'}}>Active</span> 
                                                            : 
                                                            <span style={{color:'red'}}>Inactive</span>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                            <div style={{cursor: "pointer"}} onClick={e => copyToClipboard(banner.banner_path)}>
                                                Copy Banner URL
                                                <FileCopyIcon style={createIconStyling}  >
                                                </FileCopyIcon>
                                                </div>
                                            </td>
                                            <td>
                                                {banner?.redirection_url ? 
                                                <div style={{cursor: "pointer"}} onClick={e => copyToClipboard(banner?.redirection_url)}>
                                                    Copy Redirection URL
                                                        <FileCopyIcon style={createIconStyling} />
                                                </div>
                                                :
                                                "NA"
                                                }
                                            </td>
                                            <td>
                                                { 
                                                    <Button 
                                                        variant={banner.is_active ? "danger" : "primary"}
                                                        height='10%'
                                                        style={{marginLeft: '3%'}}
                                                        onClick={e=>deleteBanner(e, banner)}
                                                    >
                                                    {banner.is_active ? "Deactivate" : "Activate"}
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </Table>
                        :
                        <p style={{paddingTop: '10%', paddingLeft: '40%'}}>
                            Nothing to Display
                        </p>
                    }
                              
                    </TabPanel>

                    {/* Adding a new Banner */}
                    <TabPanel value={value} index={1} dir={theme.direction}>                           
                        <Form onSubmit={e=>addBanner(e)}>
                        <Form.File 
                            id="formcheck-api-custom" 
                            custom style={{marginTop:'3%', marginBottom:'3%'}}
                        >
                            <Form.File.Input onChange={e=>setFile(e.target.files[0])} isValid />
                            <Form.File.Label data-browse="Upload Banner">
                                Click to choose file from local directory
                            </Form.File.Label>
                            {
                                file ? 
                                <Form.Control.Feedback type="valid">{file.name}</Form.Control.Feedback> 
                                : null
                            }
                        </Form.File>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Redirection URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter Redirection URL" onChange={(e) => {
                            setRedirectionUrl(e.target.value);
                        }}/>
                        </Form.Group>
                        <Button type='submit'>Upload</Button>            
                        </Form>                
                    </TabPanel>
                </div>
                :
                <img 
                    src={gif} 
                    alt="Getwork Loading Gif..."
                    style={gifStyling}
                />
                :
                null
            }
        </div>
    )
}

export default UpdateBanner;