import React, { useEffect, useState } from 'react';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import { Button } from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { apiCall } from '../../util/apiCall';
import { SuccessAlert, FailureAlert } from '../../util/Notifications';

function VerifyCompany() {
    //const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [count, setCount] = useState(0);

    const verifiedUnverified = (e, row) => {
        e.preventDefault();
        const request = row.comp_profile_complete === 'Yes' ? requestForUnverified : requestForVerified;
        apiCall(`/api/admin/verify_company/${row.company_id}/`, request)
            .then((res) => {
                if (res.success) {
                    SuccessAlert('Action Successful')
                    setCount(count + 1);
                }
                else {
                    FailureAlert(res.error);
                }
            })
    }

    const token = localStorage.getItem('token');

    const requestForUnverified = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            comp_profile_complete: false
        })
    };

    const requestForVerified = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            comp_profile_complete: true
        })
    };

    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/verify_company/', requestPraveshOptions)
            .then(json => {
                if (json.success) {
                    let temp = []
                    for (let i = 0; i < json.data.length; i++) {
                        let obj = {
                            company_id: json.data[i].company_id,
                            company_name: json.data[i].company_name,
                            website: json.data[i].website,
                            email: json.data[i].company_user ? json.data[i].company_user.email : " ",
                            first_name: json.data[i].company_user ? json.data[i].company_user.first_name : " ",
                            last_name: json.data[i].company_user ? json.data[i].company_user.last_name : " ",
                            comp_profile_complete: json.data[i].comp_profile_complete ? 'Yes' : 'No',
                            phone: json.data[i].company_user ? json.data[i].company_user.phone : " ",
                            city: json.data[i].city,
                            address: json.data[i].address,
                            job_title: json.data[i].company_user ? json.data[i].company_user.job_title : " ",
                        }
                        temp.push(obj)
                    }
                    setData(temp);
                    setLoading(false);
                }
                else {
                    FailureAlert(json.error)
                }
            })

    }, [count])


    const columns = [
        { title: "Company Id", field: "company_id", 
        headerStyle: headerCSS, 
        cellStyle:cellCSS,
        
         },
        { title: "Company Name", field: "company_name", headerStyle: headerCSS,
          cellStyle: {
            minWidth: 180,
            maxWidth: 180,
            fontSize: "13px",
            padding: "6px 15px",       
           },
          render: (row) => row.company_name ? (<div style={{color: "blue",
           fontSize: "13px",
          }} >
                 {row.company_name}
                 </div>) : null,
        
        },
        { title: "Company Website", field: "website", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Company Location", field: "city", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Company Address", field: "address", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "User First Name", field: "first_name", headerStyle: headerCSS, 
          cellStyle:{
            minWidth: 150,
            maxWidth: 150,
            fontSize: "13px",
            padding: "6px 15px",       
           },
        },
        { title: "User Last Name", field: "last_name", headerStyle: headerCSS, 
          cellStyle:{
            minWidth: 150,
            maxWidth: 150,
            fontSize: "13px",
            padding: "6px 15px",       
           },
        
        },
        { title: "User Email", field: "email", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "User Designation", field: "job_title", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "User Phone No", field: "phone", headerStyle: headerCSS,
        
        cellStyle:{
            minWidth: 150,
            maxWidth: 150,
            fontSize: "13px",
            padding: "6px 15px",       
           }, },
        {
            headerStyle: headerCSS, cellStyle: cellCSS, export: false,
            render: row =>
                <div
                
                >
                    {
                        row.comp_profile_complete === 'No' ?
                            <Button variant="outline-success"
                             style={{
                                fontSize: 13,
                                }} 

                             size="sm"
                             onClick={e => verifiedUnverified(e, row)}
                              >Verified</Button>
                            : <Button variant="outline-danger" 
                               style={{
                                    fontSize: 13,
                                    }} 

                              size="sm" 
                              onClick={e => verifiedUnverified(e, row)} 
                              >Unverified</Button>
                    }
                </div>
        },

    ]


    return (
        <div>
            {
                !loading
                    ?
                    <TableWithExport
                        title={'Verify Company'}
                        data={data}
                        columns={columns} />
                    :
                    <img
                        src={gif}
                        alt="Getwork Loading Gif..."
                        style={gifStyling}
                    />
            }
        </div>
    )
}
export default VerifyCompany;