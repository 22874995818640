import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import { uniqueArray } from "../../util/uniqueArray";

export default function AddSubCategoryDialog({
  open,
  handleClickOpen,
  handleClose,
  subCategory,
  setSubCategory,
  addCategory,
  category,
  setCategory,
  updateCategory,
  currentRow,
  edit,
  setCurrentRow,
  setEdit,
  status,
  setStatus,
  tagline,
  setTagline,
}) {
  const [categoryList, setCategoryList] = useState([]);
  const getCategories = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/category/?is_active=1`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;

          setCategoryList(json);
        } else FailureAlert("Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle id="form-dialog-title">{edit ? "Edit" : "Add"} Sub Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="name"
            label="Enter your Sub Category Name"
            fullWidth
            value={subCategory}
            onChange={(e) => {
              setSubCategory(e.target.value);
            }}
          />
          <br />
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="name"
            label="Enter your Tagline"
            fullWidth
            value={tagline}
            onChange={(e) => {
              setTagline(e.target.value);
            }}
          />
          <br /> <br />
          <Autocomplete
            multiple
            filterSelectedOptions
            options={categoryList}
            getOptionLabel={(item) => item.title}
            onChange={(e, item) => {
              if (item) {
                setCategory(uniqueArray(item));
              } else {
                setCategory([]);
              }
            }}
            value={category}
            renderInput={(params) => <TextField {...params} label="Select Categories" variant="outlined" />}
          />{" "}
          <br />
          <FormControl component="fieldset" style={{ marginTop: 20 }}>
            <FormLabel component="legend">Active/Inactive</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={status} onChange={handleChange} row>
              <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
              <FormControlLabel value="INACTIVE" control={<Radio />} label="Inactive" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setCurrentRow(null);
              setEdit(false);
              handleClose();
              setCategory([]);
              setSubCategory("");
              setStatus(null);
              setTagline("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!subCategory) {
                FailureAlert("Please Enter A Sub Category");
                return;
              }
              if (category?.length === 0) {
                FailureAlert("Please select a segment");
                return;
              }
              if (!tagline) {
                FailureAlert("Please enter tagline");
                return;
              }
              if (!status) {
                FailureAlert("Please select a Status");
                return;
              }
              if (edit) updateCategory(currentRow?.id);
              else addCategory();

              handleClose();
            }}
            color="primary"
          >
            {edit ? "Edit" : "Add"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
