import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { FailureAlert } from "../../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
}));

export default function AddPublicationsDialog(props) {
  const classes = useStyles();
  const {open, editPublications, setEditPublications, savePublications, handleClose} = props;

  const validateForm = () => {
    if(!(editPublications?.title && editPublications?.description)){
      FailureAlert("Please fill all required fields!")
      return false;
  }
    return true;
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        // maxWidth="sm"
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle> */}
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>Add Publications</b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                margin="normal"
                size="small"
                label="Title*"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editPublications?.title}
                onChange={(e) => {setEditPublications({...editPublications, title: e.target.value})}}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                label="Link"
                size="small"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editPublications?.links}
                onChange={(e) => {setEditPublications({...editPublications, links: e.target.value})}}
              />
            </Grid>
            <Grid
              xs={12}
              className={classes.grid}
              style={{
                marginBottom: "10px",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginTop: "20px",
                display: "grid",
              }}
            >
              <TextField
                // className={classes.margin}
                //   margin="normal"

                label="Write Description*"
                multiline
                row={6}
                variant="outlined"
                id="custom-css-outlined-input"
                value={editPublications?.description}
                onChange={(e) => {setEditPublications({...editPublications, description: e.target.value})}}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button variant="contained" color="primary" onClick={() => {
                if(validateForm()){
                  savePublications();
                }
              }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
