
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default function DialogWithReasonAction ({open, 
    handleClose, 
    modalTitle,
     modalBody,
     modalReasonText,
     actionButtonText,
     actionButtonFunc,
    setReason
}) {
    return (
    <Modal
                        show={open}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                {modalBody}
                        </p><br />
                            <Form>
                                <Form.Label>{modalReasonText}</Form.Label>
                                <Form.Group controlId="closeJobReason">
                                    <Form.Control
                                        name="closeJobReason" type="text" placeholder={modalReasonText}
                                        required onChange={e => { setReason(e.target.value) }}
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                            <Button variant="danger" onClick={e => actionButtonFunc()}>{actionButtonText}</Button>
                        </Modal.Footer>

                    </Modal>
    )
}