import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import TableWithExport from "../TableWithExport";
import AddBannerDialog from "./AddBannerDialog";

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "2px",
    marginBottom: "10px",
  },
  icon: {
    color: "#fff !important",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const token = localStorage.getItem("token");

function AllClubProductBanner(props) {
  const classes = useStyles();
  const [userCreatedColleges, setUserCreatedCollges] = useState(props.userCreated ? 1 : 0);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateFiltersRemoved, setDateFiltersRemoved] = useState(false);

  const [currentRow, setCurrentRow] = useState(null);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(null);
  const [columns, setColumns] = useState([
    {
      title: "Product",
      field: "title",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      filterPlaceholder: "Product",
      render: (row) => (
        <div
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => {
            handleClickOpen();
            setCurrentRow(row);
            setEdit(true);
          }}
        >
          {row.title}
          {/* &nbsp; &nbsp; &nbsp;
          <EditIcon style={{ cursor: "pointer" }} /> */}
        </div>
      ),
    },
    {
      title: "Banner",
      field: "image",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      render: (row) => <img src={row.image} alt="image" height="100px" />,
    },
    {
      title: "Actions",
      field: "actions",
      type: "text",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,

      //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      render: (row) => (
        <>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClickOpen();
              setCurrentRow(row);
              setEdit(true);
            }}
          />
          &nbsp;
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteProduct(row?.banner_id);
            }}
          />
        </>
      ),
    },
    // {
    //   title: "Status",
    //   field: "is_active",
    //   type: "text",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   searchable: false,
    //   //lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    //   filterPlaceholder: "Status",
    //   render: (row) => <>{row?.is_active ? <div style={{ color: "green", cursor: "pointer" }}>Active</div> : <div style={{ color: "red", cursor: "pointer" }}>InActive</div>}</>,
    //   filtering: false,
    // },
  ]);
  const [tempImage, setTempImage] = useState(null);

  useEffect(() => {
    if (edit && currentRow) {
      setProduct({ id: currentRow?.product_id, title: currentRow?.title });
      setTempImage(currentRow?.image);
      setStatus(currentRow?.is_active ? "ACTIVE" : "INACTIVE");
    }
  }, [edit]);

  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const requestOptionJobId = {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    apiCall(`/api/getwork_club/product_banner/`, requestOptionJobId)
      .then((res) => {
        if (res.success) {
          const json = res.data;
          json.forEach((item) => {
            item["actions"] = "action";
          });
          setDataCategory(json);
        } else FailureAlert("Link History was not fetched. Some error occurred");
      })
      .catch((err) => {
        FailureAlert("Some error occured!");
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRow(null);
    setEdit(false);
    setProduct(null);
    setBanner(null);
    setStatus(null);
    setTempImage(null);
  };

  const [product, setProduct] = useState(null);
  const [banner, setBanner] = useState(null);
  const addProduct = () => {
    const fd = new FormData();
    fd.append("product", product?.id);
    if (banner) fd.append("banner", banner);
    fd.append("is_active", true);
    const requestOptionsPatch = {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(`/api/getwork_club/product_banner/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const updateProduct = (id) => {
    const fd = new FormData();
    fd.append("product", product?.id);
    if (banner) fd.append("banner", banner);
    fd.append("is_active", true);
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(`/api/getwork_club/product_banner/${id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
        setProduct(null);
        setBanner(null);
        setEdit(false);
        setCurrentRow(null);
        setStatus(null);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  const deleteProduct = (id) => {
    const fd = new FormData();

    fd.append("is_active", false);
    const requestOptionsPatch = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: fd,
    };
    apiCall(`/api/getwork_club/product_banner/${id}/`, requestOptionsPatch).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        getData();
        // setProduct(null);
        // setBanner(null);
        // setEdit(false);
        // setCurrentRow(null);
        // setStatus(null);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  return (
    <div className="all__colleges">
      {/* <AppBar position="static" color="default">
        <Tabs value={userCreatedColleges} onChange={handleChangeToggle} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
          <Tab label="ALL COURSES" {...a11yProps(0)} />
          <Tab label="Instructors Profile" {...a11yProps(1)} />
        </Tabs>
      </AppBar> */}

      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <>
          {/* <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}> */}
          <Grid container spacing={3} className={classes.action}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{ minWidth: "145px" }}
                  color="primary"
                  onClick={() => {
                    handleClickOpen();
                  }}
                  startIcon={<AddIcon className={classes.icon} />}
                >
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>
          <TableWithExport title="Banners" columns={columns} data={dataCategory} fName="Product_banner" />
          {/* </TabPanel> */}
        </>
      ) : (
        <></>
        // <TabPanel value={userCreatedColleges} index={1} dir={theme.direction}>
        //   <Grid container spacing={3} className={classes.action}>
        //     <Grid
        //       item
        //       xs={10}
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} />
        //     </Grid>
        //     <Grid item xs={2}>
        //       <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //         <Button variant="contained" color="primary" style={{ minWidth: "145px" }} startIcon={<AddIcon className={classes.icon} />}>
        //           Add New
        //         </Button>
        //       </div>
        //     </Grid>
        //   </Grid>
        //   <TableWithExport title="All Instructor" columns={seccolumns} data={secdata} fName="Selected_Colleges_User_Created" editableObject={edit} />
        // </TabPanel>
      )}

      <AddBannerDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        product={product}
        setProduct={setProduct}
        addProduct={addProduct}
        banner={banner}
        setBanner={setBanner}
        updateProduct={updateProduct}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        edit={edit}
        setEdit={setEdit}
        status={status}
        setStatus={setStatus}
        tempImage={tempImage}
      />
    </div>
  );
}

export default withRouter(AllClubProductBanner);
