import React from 'react';
import {useParams} from 'react-router';
import { EditJobSection } from './EditJobSection';

function PostPublicJob() {
    
    const {job_id: publicJobId, comp_id: companyId} = useParams();
    const params = new URLSearchParams(window.location.search);
    const jobTitle = params.get('job_title');
    const companyName = params.get('company_name');
    const userData = JSON.parse(localStorage.getItem("user-data"));
    const userId = userData.id;
 
    return (
        <div className="post__job">
            <h3 style={{textAlign:'center'}}> <i>Verify & Post Public Job!</i> </h3> 
            <hr /> <br />
            
            <>
            <h4 style={{marginLeft:'2rem'}}>Company Name: {companyName}</h4> 
             <h4 style={{marginLeft:'2rem'}}>Job Title: {jobTitle}</h4>
             </> 
            <EditJobSection cmpId = {companyId}  userId={userId} jobId = {publicJobId} isPublicJob={true} />
                    
        </div>
    )
}

export default PostPublicJob;