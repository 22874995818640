import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox, Dialog,
  DialogContent,
  FormControlLabel, Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React from "react";
import { FailureAlert } from "../../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
}));

export default function AddCertificationDialog(props) {
  const classes = useStyles();
  const {open, editCertifications, setEditCertifications, handleClose, saveCertifications} = props;

  const validateForm = () => {
    if(!(editCertifications?.name && editCertifications?.institute && editCertifications?.start_date && editCertifications?.end_date)){
      FailureAlert("Please fill all required fields!")
      return false;
  }
  if(!editCertifications?.expiration && !editCertifications?.valid_date){
    FailureAlert("Please fill all required fields!")
    return false;
  }
    return true;
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        // maxWidth="sm"
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle> */}
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>Add Certification</b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                margin="normal"
                size="small"
                label="Course Name*"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editCertifications?.name}
                onChange={(e) => {setEditCertifications({...editCertifications, name: e.target.value})}}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid}>
              <TextField
                className={classes.margin}
                label="Institute Name* "
                size="small"
                InputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={editCertifications?.institute}
                onChange={(e) => {setEditCertifications({...editCertifications, institute: e.target.value})}}
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" className={classes.font}>
                Start Date
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  format="MM/yyyy"
                  openTo="year"
                  margin="normal"
                  views={["year", "month"]}
                  format="MM/yyyy"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="Start Date*"
                  value={editCertifications?.start_date ? editCertifications?.start_date : null}
                  onChange={(date, value) => {
                    setEditCertifications({...editCertifications, start_date: date})
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
              
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" className={classes.font}>
                End Date
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  format="MM/yyyy"
                  openTo="year"
                  margin="normal"
                  views={["year", "month"]}
                  format="MM/yyyy"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="End Date*"
                  value={editCertifications?.end_date ? editCertifications?.end_date : null}
                  onChange={(date, value) => {
                    setEditCertifications({...editCertifications, end_date: date})
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            
            {!editCertifications?.expiration && 
        <>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
                
              <Typography variant="body2" className={classes.font}>
                Valid Upto
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  format="MM/yyyy"
                  openTo="year"
                  margin="normal"
                  views={["year", "month"]}
                  format="MM/yyyy"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="Valid Upto"
                  value={editCertifications?.valid_date ? editCertifications?.valid_date : null}
                  onChange={(date, value) => {
                    setEditCertifications({...editCertifications, valid_date: date})
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            </>    
            }

            <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editCertifications?.expiration ? true : false}
                      onChange={() => {
                          setEditCertifications({...editCertifications, expiration: !editCertifications?.expiration});
                      }}
                      name="checkedG"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      This Certification/Course doesn't Expire
                    </Typography>
                  }
                />
              </Grid>

             

            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button variant="contained" color="primary" onClick={() => {
                if(validateForm()){
                  saveCertifications();
                }
              }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
