import React from "react";
import { Button } from 'react-bootstrap';
import { FailureAlert } from '../../util/Notifications';
import DatePicker from 'react-date-picker';


export function MonthSelector({applyDateFilter, selectedDate, setSelectedDate }) {

  
    const applyFilter = (e) => {
      e.preventDefault();
      if(!selectedDate) {
        FailureAlert('Date range not selected correctly!!');
        return;
      }
      applyDateFilter(selectedDate);
     
    }
  
    
    
    return (
      <div
        style={{
          zIndex: 100000,
          display: "flex",
          alignItems: 'center',
          justifyContent: "center",
          paddingBottom: "10px",
        }}
      >
        <span style={{marginRight:'0.5rem', fontWeight:800}}>Select Month :</span>
        <DatePicker 
        value = {selectedDate} 
        onChange ={setSelectedDate}
        format = "yyyy-MM"
        maxDetail = "year"
        />
        <Button 
          variant="outline-success" size="sm" style={{marginLeft:'1.5rem'}}
          onClick={e=>applyFilter(e)}
        >
          Apply Filter
        </Button>
        
      </div>
    );
  }