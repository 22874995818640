import React from 'react'
import AddTeamMember from './AddTeamMember'

const EditTeamMember = ({ open, setOpen, teamMemberData, updateTable }) => {
    return (
        <>
            <AddTeamMember
                open={open}
                setOpen={setOpen}
                memberData={teamMemberData}
                fromRowEdit={true}
                updateTable={updateTable}
            />
        </>
    )
}

export default EditTeamMember