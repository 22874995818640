import { Checkbox, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { praveshAxiosPatchReq, praveshAxiosPostReq } from "../../../../util/baseApi";
import { FailureAlert } from "../../../../util/Notifications";
// import clx from 'clx';


const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },

    root1: {
        borderRadius: "20px",
        // minHeight: '80%'
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    floatingLabelFocusStyle: {
        color: "#7e7e7e",
        fontSize: "14px",
    },
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    formControl: {
        minWidth: "100%",
        marginTop: "20px",
    },
    button: {
        borderRadius: "30px",
        margin: theme.spacing(1),
        boxShadow: "none",
        padding: "7px",
        height: "48px",
        paddingLeft: "30px",
        width: "90%",
        paddingRight: "30px",
        display: "flex",
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    root21: {
        "& .MuiFormLabel-root": {
            color: "red", // or black
        },
    },
}));

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root21}
            disableRipple
            color="default"
            checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}



const UploadSpocMember = ({ pocDetail, index, pocList, open, setOpen, setpocDetails, newSpocCreateFlag, newCompanyDetails, setTpoUsers }) => {

    const classes = useStyles();
    const [tempPocList, setTempPocList] = useState([]);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const setUsersList = () => {

        let tempUsers = pocList;
        let user = tempUsers?.[index];

        user = {
            ...user,
            email: pocDetail?.email,
            phone: pocDetail?.phone,
            first_name: pocDetail?.firstName,
            last_name: pocDetail?.lastName,
            job_title: pocDetail?.designation,
        };
        if (pocDetail?.status === 'verified') {
            user = { ...user, is_verified: true }
        };
        if (pocDetail?.status === 'notVerified') {
            user = { ...user, is_verified: false }
        };
        if (pocDetail?.status === 'blocked') {
            user = { ...user, is_blocked: true, is_verified: false }
        };
        tempUsers?.splice(index, 1, user);

        setTpoUsers(tempUsers);

    };

    const addUsersToList = (userId) => {

        let tempUsers = pocList;

        let newUser = {
            user_id: userId,
            email: pocDetail?.email,
            phone: pocDetail?.phone,
            first_name: pocDetail?.firstName,
            last_name: pocDetail?.lastName,
            job_title: pocDetail?.designation,
            is_verified: true,
            is_active: true,
            is_blocked: false,
        };

        tempUsers.push(newUser);
        setTpoUsers(tempUsers);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const patchSpocApiCall = async () => {

        const endPoint = `/api/admin/operations/company/user/`;

        let payload = {
            company_id: newCompanyDetails?.companyBasicInfo?.companyId,
            user_id: pocDetail?.userId,
            first_name: pocDetail?.firstName,
            last_name: pocDetail?.lastName,
            phone: pocDetail?.phone,
            email: pocDetail?.email,
            job_title: pocDetail?.designation,
            is_default_user:pocDetail?.is_default_user
        };
        if (pocDetail?.status === 'verified') {
            payload = { ...payload, is_verified: true, is_blocked: false, }
        };
        if (pocDetail?.status === 'notVerified') {
            payload = { ...payload, is_verified: false }
        };
        if (pocDetail?.blocked === 'blocked') {
            payload = { ...payload, is_blocked: true, is_verified: false }
        };

        setLoadingSubmit(true);

        try {

            const res = await praveshAxiosPatchReq(endPoint, payload);

            if (res?.data?.success) {

                setUsersList();
                handleClose();
            }else FailureAlert(res?.data?.error)
            
            setLoadingSubmit(false);
            handleClose();

        } catch (error) {

            setLoadingSubmit(false);
            handleClose();
            throw (error)
        }
    };

    const newSpocApiCall = async () => {

        const endPoint = `/api/admin/operations/company/user/`;

        setLoadingSubmit(true);

        const payload = {
            company_id: newCompanyDetails?.companyBasicInfo?.companyId,
            first_name: pocDetail?.firstName,
            last_name: pocDetail?.lastName,
            phone: pocDetail?.phone,
            email: pocDetail?.email,
            job_title: pocDetail?.designation,
            is_verified: true,
            is_active: true,
            is_blocked: false,
            is_default_user:pocDetail?.is_default_user
        }

        try {

            const res = await praveshAxiosPostReq(endPoint, payload);

            if (res?.data?.success) {

                const uesrId = res?.data?.data?.message?.user_id;

                addUsersToList(uesrId);
            } else FailureAlert(res?.data?.error)

            setLoadingSubmit(false);
            handleClose();

        } catch (error) {

            setLoadingSubmit(false);
            throw (error);
        }
    }

    useEffect(() => {
        if (pocList?.length > 0) {
            setTempPocList(pocList);
        }
    }, [pocList]);


    return (
        <>
            <Dialog
                PaperProps={{ classes: { root: classes.root1 } }}
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <div style={{ padding: "40px" }}>
                    <Typography variant="h6">Update SPOC Details</Typography>
                    <section style={{ display: 'flex' }}>
                        <TextField
                            variant="outlined"
                            InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                            }}
                            margin="normal"
                            // defaultValue=""
                            fullWidth
                            inputProps={{
                                style: {
                                    padding: "8px",
                                },
                            }}
                            label="First Name"
                            value={pocDetail?.firstName}
                            onChange={(e) => {

                                setpocDetails({
                                    ...pocDetail,
                                    firstName: e.target.value
                                })
                            }}

                        />
                        <TextField
                            variant="outlined"
                            InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                            }}
                            margin="normal"
                            defaultValue=""
                            fullWidth
                            inputProps={{
                                style: {
                                    padding: "8px",
                                },
                            }}
                            label="Last Name"
                            value={pocDetail?.lastName}
                            onChange={(e) => {

                                setpocDetails({
                                    ...pocDetail,
                                    lastName: e.target.value
                                })
                            }}

                        />

                    </section>

                    <TextField
                        variant="outlined"
                        InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                        }}
                        margin="normal"
                        fullWidth
                        inputProps={{
                            style: {
                                padding: "8px",
                            },
                        }}
                        label="Phone"
                        value={pocDetail?.phone}
                        onChange={(e) => {

                            let stringNo = e.target.value?.toString();
                            if (stringNo > 10) {
                                stringNo = stringNo.substring(0, 10);
                                stringNo = parseInt(stringNo);

                            } else {
                                stringNo = e.target.value;
                            };

                            setpocDetails({
                                ...pocDetail,
                                phone: stringNo
                            })
                        }}

                    />
                    <TextField
                        variant="outlined"
                        InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                        }}
                        margin="normal"
                        disabled={!newSpocCreateFlag}
                        fullWidth
                        inputProps={{
                            style: {
                                padding: "8px",
                            },
                        }}
                        label="Email"
                        value={pocDetail?.email}
                        onChange={(e) => {

                            setpocDetails({
                                ...pocDetail,
                                email: e.target.value
                            })
                        }}

                    />
                    <TextField
                        variant="outlined"
                        InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                        }}
                        margin="normal"
                        fullWidth
                        inputProps={{
                            style: {
                                padding: "8px",
                            },
                        }}
                        label="Designation"
                        value={pocDetail?.designation}
                        onChange={(e) => {

                            setpocDetails({
                                ...pocDetail,
                                designation: e.target.value
                            })
                        }}

                    />

                    {
                        newSpocCreateFlag ? null :
                            <>
                                <TextField
                                    variant="outlined"
                                    InputLabelProps={{
                                        className: classes.floatingLabelFocusStyle,
                                    }}
                                    margin="normal"
                                    fullWidth
                                    disabled={true}
                                    inputProps={{
                                        style: {
                                            padding: "8px",
                                        },
                                    }}
                                    label="User Role"
                                    value={pocDetail?.userRole}
                                    onChange={(e) => {

                                        // setpocDetails({
                                        //     ...pocDetail,
                                        //     userRole: e.target.value
                                        // })
                                    }}

                                />
                                <br />

                                <Typography variant="body2" style={{ marginRight: "10px", marginTop: 20 }}>
                                    Verification Status
                                </Typography>
                                {/* <FormControl component="fieldset"> */}
                                <section
                                    style={{ display: 'flex' }}
                                >

                                    <RadioGroup
                                        name="customized-radios"
                                        className={classes.customize}
                                        value={pocDetail?.status}
                                        onChange={(e) => {
                                            if (e.target.value === 'verified') {
                                                setpocDetails({
                                                    ...pocDetail,
                                                    status: e.target.value,
                                                    blocked: ""
                                                })
                                            } else {
                                                setpocDetails({
                                                    ...pocDetail,
                                                    status: e.target.value
                                                })
                                            }
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                            value={"verified"}
                                            name="Verified"
                                            control={<StyledRadio />}
                                            label={<Typography
                                                variant="body2">Verified</Typography>}
                                        />
                                        <FormControlLabel
                                            value={"notVerified"}
                                            name="Not Verified"
                                            control={<StyledRadio />}
                                            label={<Typography variant="body2">Not Verified</Typography>}
                                        />
                                    </RadioGroup>
                                    <RadioGroup
                                        name="customized-radios"
                                        className={classes.customize}
                                        value={pocDetail?.blocked}
                                        onChange={(e) => {
                                            setpocDetails({
                                                ...pocDetail,
                                                blocked: e.target.value,
                                                status: ''
                                            })
                                        }}
                                    >
                                        <FormControlLabel
                                            value={"blocked"}
                                            name="Blocked"
                                            control={<StyledRadio />}
                                            label={<Typography variant="body2">Blocked</Typography>}
                                        />
                                    </RadioGroup>

                                </section>

                                {/* </FormControl> */}
                            </>
                    }

<FormControlLabel
  label="HR Status"
  control={
    <Checkbox
      checked={pocDetail?.is_default_user}
      onChange={(e,newValue)=>setpocDetails({...pocDetail,is_default_user:newValue})}
    />
  }
/>

                    <Grid container spacing={6} style={{ marginTop: "0px" }}>
                        <Grid item sm={6} style={{ display: "grid" }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                                style={{
                                    height: "48px",
                                    borderRadius: "30px",
                                    boxShadow: "none",
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item sm={6} style={{ display: "grid" }}>
                            {" "}
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    height: "48px",
                                    borderRadius: "30px",
                                    boxShadow: "none",
                                }}
                                onClick={() => {
                                    if (!loadingSubmit && !newSpocCreateFlag) {

                                        patchSpocApiCall();
                                    }
                                    if (!loadingSubmit && newSpocCreateFlag) {
                                        newSpocApiCall();
                                    }
                                }}
                            >
                                {loadingSubmit ?
                                    <section>
                                        <CircularProgress size={24} style={{ color: 'white' }} />
                                    </section>
                                    : "Submit"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>

        </>
    )
}

export default UploadSpocMember