import React, { useState } from "react";
import gif from "../../assets/loopingcircles-3.gif";
import {
  gifStyling
} from "../../constants";
import StudentUploadDataPopup from "./Components/StudentUploadDataPopup";

function NewUploadStudentApplyExcel() {
  const [loading, setLoading] = useState(true);

  const [showUploadFile, setShowUploadFile] = useState(false);

  return loading ? (
    <div>
        <StudentUploadDataPopup
          showUploadFile={showUploadFile}
          setShowUploadFile={setShowUploadFile}
        />
    </div>
  ) : (
    <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
  );
}

export default NewUploadStudentApplyExcel;
