import { Button, Chip, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { FailureAlert } from "../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "15px",
  },
}));

export default function PreferedJobLocations({cityList, locationPreference, savePreferences}) {
  const classes = useStyles();

  const [tempCityList, setTempCityList] = useState([]);

  useEffect(() => {
    if(locationPreference)
      setTempCityList(locationPreference);
  }, [locationPreference])

  const handleDelete = (index)  => {
    setTempCityList((tempCityList) =>
    tempCityList.filter((_, cityIndex) => cityIndex !== index)
    );
  };

  const addPreferredCity = (city) => {
    if(city){
      const industryAlreadyAdded = tempCityList.some(skl => skl.city_id === city.city_id);
      if(industryAlreadyAdded)
        FailureAlert("Already Added!");
      else
        setTempCityList([...tempCityList, {
            city_id: city.city_id,
            city: city.city
          }])
    }
  }

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Prefered Job Locations</Typography>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "grid" }}>
          <Autocomplete
              // multiple
              id="combo-box-demo"
              onChange={(_, newValue) => {
                if (newValue) {
                  addPreferredCity(newValue);
                }
              }}
              
              options={cityList}
              getOptionLabel={(option) => `${option.city}`}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.city}`}</span>;
              }}
                className={classes.fieldStyling1}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}>
                        Select Preferred City
                      </span>
                    }
                    variant="outlined"
                    
                  />
                )}}
          />
          </Grid>
          {/* <Grid item xs={2} style={{ display: "grid" }}>
            <Button variant="contained" color="primary">
              Add
            </Button>
          </Grid> */}
        </Grid>
      </div>

      <div className={classes.root}>
        {tempCityList && tempCityList.map((data, index) => {
          return (
            <li key={data.city_id}>
              <Chip
                color="primary"
                deleteIcon={<CloseIcon color="primary" />}
                variant="outlined"
                size="small"
                label={data.city}
                onDelete={() => handleDelete(index)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" color="primary" onClick={() => {savePreferences("location", tempCityList)}}>
          Save
        </Button>
      </div>
    </div>
  );
}
