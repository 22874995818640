import {
  Grid,
  TextField, Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { companySizes } from "../../../constants";
import { praveshAxiosPostReq, praveshAxiosGetReq } from "../../../util/baseApi";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import CustomButtonCircularProgress from "../../Student/customLoader";

export default function ThirdPartyCompany({open, setOpen, companyDetail, setSelectedCompanyId, selectedCompanyId}) {

  const fullWidth = true;
  const maxWidth = "md"; 
  const scroll = "paper";
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [allCompanyList, setAllCompanyList] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [showCreateCompany, setShowCompanyCreate] = useState(false);

  useEffect(() => {
    setCompanyName(companyDetail?.companyName);
    setCompanyLogo(companyDetail?.preview_url);
    setCompanyWebsite(companyDetail?.companyWebsite);
    if(companyDetail?.vendorCompanyId){
      getGetWorkCompanyId();
      }
  }, [companyDetail]);

  useEffect(() => {      
   
      const request = Axios.CancelToken.source() // (*)
       const getCompanyList = async() => {

              const endPoint = "/api/admin/company/get/";

              try{
                const res = await praveshAxiosGetReq(endPoint,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Token ${localStorage.getItem("token")}`,
                    },
                     cancelToken: request.token, // (*)
                  }
                 );
 
                if(res?.data?.success){
                    setAllCompanyList(res?.data?.data);

                }
              }
              catch(error){
                throw(error);
              }
       };
      
       getCompanyList();
      return () => request.cancel() // (*)
  },[]);

const getGetWorkCompanyId = () => {
  
  const endPoint = `/api/company/third_party/create/?vendor_company_id=${companyDetail?.vendorCompanyId}&platform_id=${companyDetail?.platformId}`;
  // setLoadingSubmit(true);
  praveshAxiosGetReq(endPoint,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        }
      }
     ).then(res => {
      if(res?.data?.success){
        if(res?.data?.data?.company_id)
          setSelectedCompanyId(res?.data?.data?.company_id);
        else{
          setShowCompanyCreate(true);
          setSelectedCompanyId(null);
        }
    }else{
        FailureAlert(res?.data?.error);
    }
    // setLoadingSubmit(false);
     }).catch(err => {
      // setLoadingSubmit(false);
      FailureAlert("Something went wrong!!")
     })
}


const handleSubmit = async() => {

  const validate = () => {

    if(!companyName) {
      FailureAlert("Please enter the Company Name!");
      return false;
    };
    if(!companyWebsite ) {
      FailureAlert("Please enter the Company Website!");
      return false
    };
    if(!companySize?.label) {
      FailureAlert("Please enter the Company Size!");
      return false
    };
    return true;
  }

  if(!showCreateCompany){
    window.open(`/admin/3rd-party-job-posting/${companyDetail.jobId}/${selectedCompanyId}/?job_title=${companyDetail.jobTitle}&company_name=${companyDetail.companyName}`, '_blank');
    handleClose();
    return;
  }

  if(validate()){
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("company_website", companyWebsite);
    if(selectedFile){
      formData.append("logo", selectedFile);
    } else{
      formData.append("logo_url", companyDetail?.companyDetails?.logo);
    }
    formData.append("company_size", companySize?.label);
    formData.append("company_present", false);
    formData.append("vendor_company_id", companyDetail?.vendorCompanyId);
    formData.append("platform_id", companyDetail?.platformId);
    formData.append("vendor_name",  companyDetail?.vendorName);
    formData.append("vendor_id", companyDetail?.platformId);

    const endPoint = `/api/company/third_party/create/`;
    try{
    setLoadingSubmit(true);
    const res = await praveshAxiosPostReq(endPoint, formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
          }
        }
       );
       setLoadingSubmit(false);
      if(res?.data?.success){
          setSelectedCompanyId(res?.data?.data?.company_id);
          window.open(`/admin/3rd-party-job-posting/${companyDetail.jobId}/${res?.data?.data?.company_id}/?job_title=${companyDetail.jobTitle}&company_name=${companyDetail.companyName}`, '_blank');
          handleClose();
          SuccessAlert("Company Created Successfully!!");
      }else{
          FailureAlert(res?.data?.error);
      }
    }catch(err){
        setLoadingSubmit(false);
        FailureAlert("Something went wrong!!")
    }

  }
}

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }

    setSelectedFile(e.target.files[0])
}

const handleClose = () => {
    setOpen(false);
}

const getSelectedCompany = () => {
  const ind = allCompanyList?.findIndex(
    (item) => item.company_id === selectedCompanyId
  )
  if (ind !== -1){
    return allCompanyList[ind];
  }
  return {};
}

useEffect(() => {
    if (!selectedFile) {
        setCompanyLogo(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setCompanyLogo(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [selectedFile])

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent dividers={scroll === "paper"}>
          <Grid container spacing={2}>
            <Grid item md={12} style={{ borderRight: "2px solid #AFAFAF" }}>
              <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Typography variant="h6">Select/Create 3rd Party Company</Typography>
                <div style={{ marginTop: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Autocomplete
                        id="combo-box-demo"
                        // defaultValue={setCustomRole}
                        options={allCompanyList}
                        value={getSelectedCompany()}
                        getOptionLabel={(option) => option?.company_name}                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="GetWork Company* "
                            variant="outlined"
                          />
                        )}
                        onChange={(e, newValue) => {
                            let tempSize = newValue?.company_id ? newValue.company_id : null;
                            setSelectedCompanyId(tempSize);
                            setShowCompanyCreate(false);
                        }}
                      />
                    </Grid>
                    
                    <Grid item md={12} style={{ display: "grid", justifyContent: "center", fontSize: "16px", fontWeight: "800" }}>
                        <Button variant="outlined" color="primary" onClick={() => {
                          setShowCompanyCreate(true);
                          setSelectedCompanyId("");
                        }}>
                        Or Create New Company 
                        </Button>
                    </Grid>
                    {showCreateCompany ?
                    <>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <TextField 
                       label="Company Name*"
                       variant="outlined" 
                       value={companyName}
                       onChange = {(e) => {
                          setCompanyName(e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                      <TextField 
                      label="Company Website*" 
                      type="email"
                      value={companyWebsite}

                      variant="outlined"
                      onChange = {(e) => {
                          setCompanyWebsite(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                    <Autocomplete
                                id="companySize"
                                options={companySizes}
                                getOptionLabel={(item) => item.label}
                                value={companySize}
                                onChange={(e, newValue) => {
                                    let tempSize = newValue ? newValue : null;
                                    setCompanySize(tempSize)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company Size*"
                                        variant="outlined"
                                        style={{ display: "flex", }}
                                    />
                                )}
                            />
                    </Grid>
                    <Grid item md={12} style={{ display: "grid" }}>
                        <div>Upload/View Logo</div>
                    {showCreateCompany ? 
                    <input type="file" 
                        accept="image/*" 
                        onChange={(e) => {onSelectFile(e)}}                  
                        /> :  null
                    }
                    {companyLogo ?
                    <img src={companyLogo} width= "auto" height= "100"></img>
                    :
                    <img src={companyDetail?.companyDetails?.preview_url} width= "auto" height= "100"></img>
                    }
                    </Grid>
                  
                  </> : null
}
</Grid>
                </div>
              </div>
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary"
          onClick={handleSubmit}
          
          >
          {loadingSubmit ? <CustomButtonCircularProgress/> : `${showCreateCompany ? "Submit & Post" : "Post Job"}`}  
          </Button>

          
        </DialogActions>
      </Dialog>
    </>
  );
}


