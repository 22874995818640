import { Tooltip, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  createIconStyling,
  gifStyling,
  headerCSS,
  requestPraveshOptions,
} from "../../constants";
import { apiCall, praveshAxiosPostReq } from "../../util/apiCall";
import { copyToClipboard } from "../../util/copyToClipboard";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { TabPanel } from "../../util/TabPanel";
import BulkStudentApply from "../BulkStudentApply";
import DateSelectorPopup from "../common/DateSelectorPopup";
import BlockStudent from "../DialogSection/Block/BlockStudent";
import ApplyJob from "./Components/Dialog/ApplyJobs";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function AllStudentsNew(props) {
  const [startDate, setStartDate] = useState("");
  const history = props.history;
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [listItems, setlistItems] = useState();
  const [filters, setFilters] = useState({
    source: "",
    medium: "",
    campaign: "",
    content: "",
    term: "",
  });

  const [stringDate, setStringDate] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const theme = useTheme();
  const value = 1;
  const tableRef = useRef();
  const dateRange = [new Date(2021, 0, 1), new Date()];
  const selectedRowsData = useRef(0);

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const getAllStudentsData = (rowUpdate, setLoadingStatus) => {
    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 7)
    );
    let initialEndDate = new Date();

    if (startDate === "") {
      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = new Date(queryParams.from);
      }
      if (queryParams.to) {
        initialEndDate = new Date(queryParams.to);
      }
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      setFilters({
        source: queryParams?.utm_source ? queryParams?.utm_source : "",
        medium: queryParams?.utm_medium ? queryParams?.utm_medium : "",
        campaign: queryParams?.utm_campaign ? queryParams?.utm_campaign : "",
        term: queryParams?.utm_term ? queryParams?.utm_term : "",
        content: queryParams?.utm_content ? queryParams?.utm_content : "",
      });
      return;
    } else if (startDate === null) {
      initialStartDate = null;
    } else {
      initialStartDate = new Date(startDate);
      initialEndDate = new Date(endDate);
    }

    let dateString = "";
    let utm_source =
      filters?.source?.length !== 0 && filters.source !== "None"
        ? `&utm_source=${filters.source}`
        : "";
    let utm_medium =
      filters?.medium?.length !== 0 && filters.medium !== "None"
        ? `&utm_medium=${filters.medium}`
        : "";
    let utm_campaign =
      filters?.campaign?.length !== 0 && filters.campaign !== "None"
        ? `&utm_campaign=${filters.campaign}`
        : "";
    let utm_content =
      filters?.content?.length !== 0 && filters.content !== "None"
        ? `&utm_content=${filters.content}`
        : "";
    let utm_term =
      filters?.term?.length !== 0 && filters.term !== "None"
        ? `&utm_term=${filters.term}`
        : "";

    if (initialStartDate) {
      let month = initialStartDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = initialStartDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${initialEndDate
          .toISOString()
          .substring(
            0,
            10
          )}${utm_source}${utm_medium}${utm_campaign}${utm_content}${utm_term}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      dateString = `&start_date=${startDateString}&end_date=${initialEndDate
        .toISOString()
        .substring(0, 10)}`;
    } else {
      history.replace({
        search: "",
      });
    }
    if (dateString === "") {
      FailureAlert("start date & end date can't be null");
      return;
    }

    if (!rowUpdate) {
      setLoading(true);
    }
    setStringDate(dateString);
    //getOptions(dateString)

    apiCall(
      `/api/admin/all_students/?${dateString}${utm_source}${utm_medium}${utm_campaign}${utm_content}${utm_term}`,
      requestPraveshOptions
    ).then((json) => {
      if (json?.success) {
        let temp = [];
        let string_data = "";
        for (var i = 0; i < json?.data?.result?.length; i++) {
          let string_data = "";
          json?.data?.result[i]?.jobs?.map((item) => {
            string_data += `company_name:${item?.company_name}, job_title:${item?.job_title}, job_status:${item?.status}, `;
          });
          let obj = {
            ...json.data.result[i],
            jobs: string_data,
          };
          temp.push(obj);
        }
        setData(temp);
        if (!rowUpdate) {
          setLoading(false);
        } else {
          setLoadingStatus(false);
        }
      } else {
        FailureAlert(json?.error);
        if (!rowUpdate) {
          setLoading(false);
        } else {
          setLoadingStatus(false);
        }
      }
    });
  };

  useEffect(() => {
    getAllStudentsData(false);
    // getOptions();
  }, [startDate, endDate, filters]);

  const blockStudent = async (
    blockStatus,
    deleteReason,
    handleClose,
    setDeleteReason,
    studentID,
    setLoadingStatus,
    setBlockStatusButton,
    blockStatusButton
  ) => {
    setLoadingStatus(true);
    // tempArr.push(basicInfo?.userId);
    const endPoint = "/api/admin/students/block/";
    const tempArr = [];
    tempArr.push(studentID);
    const data = {
      user_ids: tempArr,
      is_active: !blockStatus,
      reason: deleteReason ? deleteReason : "unblock",
    };

    try {
      const res = await praveshAxiosPostReq(endPoint, data);
      if (res?.data?.success) {
        SuccessAlert(res?.data?.data?.message);
        handleClose();
        setDeleteReason(null);
        setLoadingStatus(false);
        setBlockStatusButton(!blockStatusButton);

        // getAllStudentsData(true,setLoadingStatus);
        // setLoadingStatus(false);
      } else {
        FailureAlert("Some Error Occured!");
      }
    } catch (error) {
      throw error;
    }
  };

  const columns = [
    {
      title: "User Id",
      field: "user_id",
      editable: "never",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
        fontSize: "13px",
      },
    },
    {
      title: "Name",
      field: "name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: "13px",
        padding: "6px 15px",
      },
      render: (row) =>
        row.name ? (
          <div
            style={{ cursor: "pointer", color: "blue", fontSize: "13px" }}
            onClick={() =>
              window.open(`/admin/student-details/?email=${row.email}`)
            }
          >
            {row.name}
          </div>
        ) : null,
    },
    {
      title: "Phone",
      field: "phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.phone ? (
          <div style={{ display: "flex" }}>
            {row.phone}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.phone)}
            />
          </div>
        ) : null,
    },
    {
      title: "Email",
      field: "email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.email ? (
          <div style={{ display: "flex" }}>
            {row.email}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.email)}
            />
          </div>
        ) : null,
    },

    {
      title: "College Name",
      field: "college_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Degree Name",
      field: "degree_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Gender",
      field: "gender",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
        fontSize: "13px",
        padding: "6px 15px",
      },
      lookup: { Male: "Male", Female: "Female" },
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Date Joined",
      field: "created_at",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.created_at) &&
        Date.parse(term[1]) >= Date.parse(rowData.created_at),
    },
    {
      title: "Last Login",
      field: "last_login",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.last_login) &&
        Date.parse(term[1]) >= Date.parse(rowData.last_login),
    },
    {
      title: "Tags",
      field: "tag",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Source",
      field: "source",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Total Applied Job Count",
      field: "applied_job_count",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Latest Applied Job",
      field: "latest_job",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 150,
            fontSize: "13px",
            padding: "6px 15px",
          }}
        >
          {row.latest_job ? (
            <Button
              variant="outline-info"
              size="sm"
              style={{
                fontSize: 13,
              }}
              onClick={() => window.open(`/admin/public/job/${row.latest_job}`)}
            >
              {`${row.latest_job}`}
            </Button>
          ) : (
            row.latest_job
          )}
        </div>
      ),
    },
    {
      title: "Total Jobs Applied",
      field: "jobs",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <Tooltip title={row.jobs}>
            <Typography
              noWrap
              style={{ maxWidth: "100px", fontSize: "12px", cursor: "pointer" }}
            >
              {row.jobs}
            </Typography>
          </Tooltip>
        </div>
      ),
    },

    {
      title: "Change Status",
      field: "blocked",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div
          style={{
            minWidth: 150,
            maxWidth: 150,
            fontSize: "13px",
            padding: "6px 15px",
          }}
        >
          {/* <BlockStudent
              BlockStudent={blockStudent}
              blockStatus={row?.is_active}
              StudentID={row?.user_id}
              // loadingStatus={loadingStatus}
              // getAllStudentsData={getAllStudentsData}            
            />       */}

          <Button
            variant={row?.is_active ? "outline-danger" : "outline-success"}
            size="sm"
            onClick={() => {
              setDialogOpen(true);
              setBlockData(() => ({ ...row, userId: row?.user_id }));
            }}
            style={{
              fontSize: 13,
            }}
          >
            {row?.is_active ? "Block User" : "Unblock User"}
          </Button>
        </div>
      ),
    },
    {
      title: "Utm Source",
      field: "source",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Utm Medium",
      field: "medium",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Utm Campaign",
      field: "campaign",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 160,
        maxWidth: 160,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Utm term",
      field: "term",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
    {
      title: "Utm Content",
      field: "content",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: "13px",
        padding: "6px 15px",
      },
    },
  ];

  const applyDateFilter = ({
    valueDate,
    show,
    source,
    medium,
    campaign,
    term,
    content,
  }) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
    }
    setFilters({
      source: source,
      medium: medium,
      campaign: campaign,
      content: content,
      term: term,
    });
    setShowFilter(show);
  };

  const removeDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
    }
    setShowFilter(!showFilter);
  };

  const [open, setOpen] = React.useState(false);

  const [SelectedRowsData, setSelectedRowsData] = React.useState();
  const [SelectedID, setSelectedID] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
    setSelectedID(selectedRowsData.current);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [blockData, setBlockData] = useState({});

  return (
    <div className="open__close__jobs">
      {!loading ? (
        <TabPanel value={value} index={1} dir={theme.direction}>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Button
              onClick={handleClickOpen}

              // disabled={ selectedRowsData.current ? false : true}
              // disabled={SelectedID?.length >0 ? false : true}
            >
              Apply on Job
            </Button>

            {showFilter && (
              <DateSelectorPopup
                dateRange={[startDate, endDate]}
                applyDateFilter={applyDateFilter}
                removeDateFilter={removeDateFilter}
                showRemove={false}
                filters={filters}
                // options={options}
              />
            )}
            <Button
              variant="outline-success"
              size="sm"
              style={{ marginLeft: "1.5rem" }}
              onClick={(e) => setShowFilter(true)}
            >
              Filter
            </Button>
            <ApplyJob
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              SelectedID={SelectedID}
              setSelectedID={setSelectedID}
              clearSelection={clearSelection}
              selectedRowsData={selectedRowsData}
              // setSelectedRowsData = {setSelectedRowsData}
            />
            {/* <Button /> */}
          </div>

          <BulkStudentApply
            title={"All Student Data"}
            tableRef={tableRef}
            data={data}
            columns={columns}
            setSelectedID={setSelectedID}
            selectedRowsData={selectedRowsData}
            onSelectionChange={(data) => {
              // const selectedIDs = new Set(data);
              // const selectedRowData = data.filter((rows) =>
              // selectedIDs.has(rows.toString())
              // );
              // setSelectedRowsData(selectedIDs);
              setSelectedID(data);
            }}
            fName={"All_Student_Data"}
          />
        </TabPanel>
      ) : (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      )}
      <BlockStudent
        // BlockStudent={blockStudent}
        // blockStatus={row?.is_active}
        // StudentID={row?.user_id}
        open={dialogOpen}
        setOpen={setDialogOpen}
        blockData={blockData}
        allData={data}
        setAllData={setData}
        // loadingStatus={loadingStatus}
        // getAllStudentsData={getAllStudentsData}
      />
    </div>
  );
}

export default withRouter(AllStudentsNew);
