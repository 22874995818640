import React, { useEffect, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { Box, Checkbox, Chip, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Alert, Autocomplete } from "@material-ui/lab";
import { FormControl, FormControlLabel } from "@material-ui/core";
import NumberFormat from "react-number-format";
import CloseIcon from "@material-ui/icons/Close";

import {
  gifStyling,
  createIconStyling,
  requestPraveshOptions,
  requestNiyuktiOptions,
} from "../../constants";
import { FailureAlert } from "../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    //backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
  },
}));

export default function AddNewCollegePrefrences({
  formData,
  setFormData,
  allJobSegments,
  states,isSaas,
}) {
  const classes = useStyles();

  const [stateId, setStateId] = useState(0);
  const [jobSegmentType, setJobSegmentType] = useState(null);
  const [internshipPayment, setInternshipPayment] = useState(null);
  const [jobPayment, setJobPayment] = useState(null);

  const [month, setMonth] = useState([]);

  const addJob = (e) => {
    const t = allJobSegments?.data.find(
      (d) => d?.job_segment === jobSegmentType
    );
    const exist = formData?.jobSegment?.find(
      (j) => j?.job_segment_id === t?.id
    );
    if (!exist) {
      const arr = formData?.jobSegment ? [...formData?.jobSegment] : [];
      const newJobSegment = {
        job_segment_id: t.id,
        job_segment_name: jobSegmentType,
        internship_ctc: parseInt(internshipPayment),
        job_ctc: parseInt(jobPayment),
      };
      arr.unshift(newJobSegment);
      setFormData({
        ...formData,
        jobSegment: arr,
      });
      setJobSegmentType(null);
      setInternshipPayment(null);
      setJobPayment(null);
    } else {
      FailureAlert("Job Segment Already Added");
    }
  };

  const deleteJob = (uid) => {
    const arrayAfterDeletion = formData?.jobSegment?.filter(
      (job) => job.job_segment_id !== uid
    );
    setFormData({
      ...formData,
      jobSegment: arrayAfterDeletion,
    });
  };

  const addCollegePreferenceState = (state) => {
    if (state) {
      const stateAlreadyAdded = formData?.collegePreferenceState?.some(
        (st) => st.state_id === state.state_id
      );
      if (stateAlreadyAdded) FailureAlert("State Already Added!");
      else {
        const arr = formData?.collegePreferenceState
          ? [...formData?.collegePreferenceState]
          : [];
        arr.push(state);
        setFormData({ ...formData, collegePreferenceState: arr });
      }
    }
  };

  const handleDelete = (index) => {
    const stateAfterDelete = formData?.collegePreferenceState?.filter(
      (_, state) => state !== index
    );
    setFormData({
      ...formData,
      collegePreferenceState: stateAfterDelete,
    });
  };

  const handleChangeJobType = (position) => {
    const updatedCheckedState = formData?.jobType.map((item, index) =>
      index === position - 1 ? !item : item
    );
    setFormData({
      ...formData,
      jobType: updatedCheckedState,
    });
  };

  const handleChangeInternship = (position) => {
    const updatedCheckedState = formData?.internshipAvailability.map(
      (item, index) => (index === position - 1 ? !item : item)
    );
    setFormData({
      ...formData,
      internshipAvailability: updatedCheckedState,
    });
  };

  const handleChangeFullTime = (position) => {
    const updatedCheckedState = formData?.jobAvailability.map((item, index) =>
      index === position - 1 ? !item : item
    );
    setFormData({
      ...formData,
      jobAvailability: updatedCheckedState,
    });
  };

  return (
    <>
      <div style={{ padding: "30px" }}>
        <Form className="prefrences_info">
          <FormControl>
            <Form.Label>Job Type{isSaas ? "*" : ''} </Form.Label>
            <Form.Group type="checkbox" id="jobtype" required>
              <FormControlLabel
                control={<Checkbox />}
                label="Full Time"
                value={7}
                checked={formData?.jobType[6]}
                onChange={(event) => handleChangeJobType(7)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Internship"
                //value="internship"
                value={1}
                checked={formData?.jobType[0]}
                onChange={(event) => handleChangeJobType(1)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Internship with PPO"
                //value="internshipWithPpo"
                value={2}
                checked={formData?.jobType[1]}
                onChange={(event) => handleChangeJobType(2)}
              />
            </Form.Group>
          </FormControl>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Job Segment Type{isSaas ? "*" : ''}</Form.Label>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Internship or Job(monthly) </Form.Label>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Job CTC(yearly)</Form.Label>
            </Form.Group>
          </Form.Row>

          {formData?.jobSegment?.length > 0
            ? formData?.jobSegment?.map((job, key) => {
                return (
                  <Form.Row key={key} className="jobs_list">
                    <Form.Group as={Col}>
                      <Form.Control
                        value={job.job_segment_name || ""}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control value={job.internship_ctc || ""} readOnly />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control value={job.job_ctc || ""} readOnly />
                    </Form.Group>
                    <Button
                      onClick={() => deleteJob(job.job_segment_id)}
                      variant="danger"
                      size="sm"
                      style={{
                        width: "10%",
                        marginLeft: "2%",
                        /* marginTop: "1%", */
                        padding: "8px 8px",
                        height: "100%",
                      }}
                    >
                      {" "}
                      Delete
                    </Button>
                  </Form.Row>
                );
              })
            : null}

          <Form.Row className="placement_session_list">
            <Form.Group as={Col}>
              <Form.Control
                as="select"
                name="jobsegment"
                value={jobSegmentType ? jobSegmentType : ""}
                onChange={(event) => {
                  setJobSegmentType(event.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select Job Segment
                </option>
                {allJobSegments.data &&
                  allJobSegments.data.map((d, i) => {
                    return (
                      <option key={d.id} value={d.job_segment}>
                        {d.job_segment}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
                className="form-control"
                name="internshippayment"
                id="internshippayment"
                type="text"
                placeholder="Min Stipend For Internship"
                value={internshipPayment ? internshipPayment : ""}
                /*  onChange={(event) => {
                  setInternshipPayment(event.target.value);
                }} */
                onValueChange={(values) => {
                  const { value } = values;
                  setInternshipPayment(value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
                className="form-control"
                name="jobpayment"
                type="text"
                placeholder="Min CTC For Job"
                value={jobPayment ? jobPayment : ""}
                /*   onChange={(event) => {
                  setJobPayment(event.target.value);
                }} */
                onValueChange={(values) => {
                  const { value } = values;
                  setJobPayment(value);
                }}
              />
            </Form.Group>

            <Button
              onClick={
                jobSegmentType && internshipPayment && jobPayment
                  ? addJob
                  : () => {
                      FailureAlert("Please fill all job segement fields first");
                    }
              }
              variant="info"
              size="sm"
              style={{
                width: "10%",
                marginLeft: "2%",
                /*  marginTop: "1%", */
                padding: "8px 8px",
                height: "100%",
              }}
            >
              {" "}
              Add
            </Button>
          </Form.Row>
          <FormControl>
            <Form.Label>Student Availability For Internship{isSaas ? "*" : ''} </Form.Label>
            <Form.Group
              type="checkbox"
              id="internship"
              name="internshipAvail"
              required
            >
              <FormControlLabel
                control={<Checkbox />}
                label="Jan"
                value={1}
                checked={formData?.internshipAvailability[0]}
                onChange={(event) => handleChangeInternship(1)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Feb"
                value={2}
                checked={formData?.internshipAvailability[1]}
                onChange={(event) => handleChangeInternship(2)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Mar"
                value={3}
                checked={formData?.internshipAvailability[2]}
                onChange={(event) => handleChangeInternship(3)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Apr"
                value={4}
                checked={formData?.internshipAvailability[3]}
                onChange={(event) => handleChangeInternship(4)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="May"
                value={5}
                checked={formData?.internshipAvailability[4]}
                onChange={(event) => handleChangeInternship(5)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Jun"
                value={6}
                checked={formData?.internshipAvailability[5]}
                onChange={(event) => handleChangeInternship(6)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Jul"
                value={7}
                checked={formData?.internshipAvailability[6]}
                onChange={(event) => handleChangeInternship(7)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Aug"
                value={8}
                checked={formData?.internshipAvailability[7]}
                onChange={(event) => handleChangeInternship(8)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Sep"
                value={9}
                checked={formData?.internshipAvailability[8]}
                onChange={(event) => handleChangeInternship(9)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Oct"
                value={"October"}
                checked={formData?.internshipAvailability[9]}
                onChange={(event) => handleChangeInternship(10)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Nov"
                value={11}
                checked={formData?.internshipAvailability[10]}
                onChange={(event) => handleChangeInternship(11)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Dec"
                value={12}
                checked={formData?.internshipAvailability[11]}
                onChange={(event) => handleChangeInternship(12)}
              />
            </Form.Group>
          </FormControl>

          <FormControl>
            <Form.Label>Student Availability For Job{isSaas ? "*" : ''} </Form.Label>
            <Form.Group type="checkbox" id="Job" name="jobAvailabilty" required>
              <FormControlLabel
                control={<Checkbox />}
                label="Jan"
                value={1}
                checked={formData?.jobAvailability[0]}
                onChange={(event) => handleChangeFullTime(1)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Feb"
                value={2}
                checked={formData?.jobAvailability[1]}
                onChange={(event) => handleChangeFullTime(2)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Mar"
                value={3}
                checked={formData?.jobAvailability[2]}
                onChange={(event) => handleChangeFullTime(3)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Apr"
                value={4}
                checked={formData?.jobAvailability[3]}
                onChange={(event) => handleChangeFullTime(4)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="May"
                value={5}
                checked={formData?.jobAvailability[4]}
                onChange={(event) => handleChangeFullTime(5)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Jun"
                value={6}
                checked={formData?.jobAvailability[5]}
                onChange={(event) => handleChangeFullTime(6)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Jul"
                value={7}
                checked={formData?.jobAvailability[6]}
                onChange={(event) => handleChangeFullTime(7)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Aug"
                value={8}
                checked={formData?.jobAvailability[7]}
                onChange={(event) => handleChangeFullTime(8)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Sep"
                value={9}
                checked={formData?.jobAvailability[8]}
                onChange={(event) => handleChangeFullTime(9)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Oct"
                value={10}
                checked={formData?.jobAvailability[9]}
                onChange={(event) => handleChangeFullTime(10)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Nov"
                value={11}
                checked={formData?.jobAvailability[10]}
                onChange={(event) => handleChangeFullTime(11)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Dec"
                value={12}
                checked={formData?.jobAvailability[11]}
                onChange={(event) => handleChangeFullTime(12)}
              />
            </Form.Group>
          </FormControl>
          <Form.Row>
            <Form.Group as={Col} controlId="addcollegestate">
              <Form.Label>Job Location preference{isSaas ? "*" : ''}</Form.Label>
              <Autocomplete
                id="select-state"
                options={states}
                getOptionLabel={(item) => item.state}
                onChange={(e, item) => {
                  if (item) {
                    addCollegePreferenceState(item);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    variant="outlined"
                  />
                )}
              />
            </Form.Group>
          </Form.Row>

          {
            <div className={classes.root}>
              {formData?.collegePreferenceState &&
                formData?.collegePreferenceState.map((data, index) => {
                  return (
                    <li key={data.state_id}>
                      <Chip
                        color="primary"
                        deleteIcon={<CloseIcon color="primary" />}
                        variant="outlined"
                        size="small"
                        label={data.state}
                        onDelete={() => {
                          handleDelete(index);
                        }}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
            </div>
          }
        </Form>
      </div>
    </>
  );
}
