import React, { useState, useEffect } from 'react';
import { apiNiyuktiCall, apiCall } from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import { Button, Modal, Form } from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import { SuccessAlert, FailureAlert } from '../../util/Notifications';
import {  validateDateTime } from '../../util/printNumber';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { TabPanel } from '../../util/TabPanel';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { DateSelector } from '../common/DateSelector';
import QueryString from 'query-string';
import { useHistory } from "react-router-dom";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}


function ContactUsResponse() {

    const [startDate, setStartDate] = useState("");
    let history = useHistory();
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [jobData, setJobData] = useState([]);
    const theme = useTheme();
    const [value, setValue] = useState(0);
    // value = 0 --> Pending, 1--> Posted, 2 --> Rejected

    const [open, setOpen] = useState(false);
    const [modalRejectJobData, setModalRejectJobData] = useState({});
    const [closeReason, setCloseReason] = useState('');
    const [count, setCount] = useState(0);

    const dateRange = [new Date(2021, 0, 1), new Date()];

    const handleChangeDate = (newRange, props) => {
        let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
        let endDate = (newRange && newRange[1]) || new Date();
        props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
    };

    const handleClose = () => {
        setOpen(false);
        setModalRejectJobData({});
    }

    const updateList = () => {
        
        setCount(count + 1);
    }

    const rejectParticularJob = (jobId) => {
        if (!closeReason) { FailureAlert('Specify Reason to Reject Job!!'); return; }

        const requestOptionsPost = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                status: 'REJECTED',
                reject_reason: closeReason
            })
        };

        apiNiyuktiCall(`/job/public_job_posting/${jobId}/`, requestOptionsPost)
            .then((res) => {
                if (res.success) {
                    SuccessAlert(res.data.message)
                    setCloseReason('');
                }
                else {
                    FailureAlert(res.error)
                }
                updateList()
                handleClose()
            })
    }

    useEffect(() => {

        let initialStartDate = new Date(new Date().setDate(new Date().getDate() - 30));
        let initialEndDate = new Date();
 
        if(startDate === ""){
            const queryParams = QueryString.parse(window.location.search);
            if (queryParams.from) {
              initialStartDate = new Date(queryParams.from);
            }
            if (queryParams.to) {
              initialEndDate =  new Date(queryParams.to);
            }
            setStartDate(initialStartDate)
            setEndDate(initialEndDate)
            return
        } 
        else if(startDate === null){
            initialStartDate = null
        }
        else {
            initialStartDate = new Date(startDate);
            initialEndDate = new Date(endDate);
        }  
        if(initialStartDate) {
            let month = initialStartDate.getMonth() + 1;
            month = month < 10 ? `0${month}` : month;
            let day = initialStartDate.getDate();
            day = day < 10 ? `0${day}` : day;
            let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
            history.replace(`?from=${startDateString}&to=${initialEndDate.toISOString().substring(0, 10)}`)
            // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
        } else { 
           
           history.replace({
               search: ""
           })
        }    
           

        setLoading(true);

        apiCall(`/api/common/contact_us/`, requestPraveshOptions)
            .then(json => {
                if (json.success) {
                    let temp = []
                    for (var i = 0; i < json.data.length; i++) {
                        const singleQuery = json.data[i];
                        let obj = {
                            createTime: validateDateTime(singleQuery.create_time),
                            name: singleQuery.first_name + " " + singleQuery.last_name,
                            userType: singleQuery.user_type,
                            query: singleQuery.query_desc,
                            phone: singleQuery.phone,
                            email: singleQuery.email
                        }
                        temp.push(obj)
                    }
                    setJobData(temp);
                    setLoading(false);
                }
                else {
                    FailureAlert(json.error)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, startDate, value]);

    const handleChange = (event, newValue) => { setValue(newValue); };

    const columns = [
        { title: "Contact Name", field: "name", headerStyle: headerCSS, cellStyle: cellCSS },
        
        { title: "User Type", field: "userType",  headerStyle: headerCSS, cellStyle: cellCSS},
        { title: "Query", field: "query", headerStyle: headerCSS, cellStyle: cellCSS },
        { title: "Contact Email", field: "email", headerStyle: headerCSS, cellStyle: cellCSS},
        { title: "Contact Phone", field: "phone", headerStyle: headerCSS, cellStyle: cellCSS},
        {
            title: "Creation Date", field: "createTime", headerStyle: headerCSS, cellStyle: cellCSS,
            type: 'datetime', searchable: false,
            filterComponent: (props) =>
                <DateRangePicker
                    rangeDivider="to"
                    value={props.columnDef.tableData.filterValue || dateRange}
                    onChange={e => handleChangeDate(e, props)}
                />,
            customFilterAndSearch: (term, rowData) =>
                Date.parse(term[0]) <= Date.parse(rowData.createTime) && Date.parse(term[1]) >= Date.parse(rowData.createTime)
        }
    ];

    const applyDateFilter = (valueDate) => {
        if (valueDate.length === 2) {
            setStartDate(valueDate[0]);
            setEndDate(valueDate[1]);
            updateList();
        }
    }

    const removeDateFilter = () => {
        setStartDate(null);
        setEndDate(null);
        updateList();
    }

    return (
        <div className="open__close__jobs">
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="PENDING" {...a11yProps(0)} />
                    <Tab label="WORKED" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            {
                    !loading
                        ?
                        <TabPanel value={value} index={value} dir={theme.direction}>
                            <br />
                            <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} />
                            <TableWithExport
                                title={value === 0 ? "Pending College's Leads" : "Worked College's Leads"}
                                data={jobData}
                                columns={columns}
                                fName={value === 0 ? "Pending_Colleges_Leads" : "Worked_Colleges_Leads"}
                            />
                        </TabPanel>
                        :
                        <img
                            src={gif}
                            alt="Getwork Loading Gif..."
                            style={gifStyling}
                        />
            }

            {/* Reject Jobs Modal*/}
            {
                modalRejectJobData.jobId
                    ?
                    <Modal
                        show={open}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{modalRejectJobData.jobTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Are you sure you want to reject this job ?
                        </p><br />
                            <Form>
                                <Form.Label>Kindly enter the reason to reject this job*</Form.Label>
                                <Form.Group controlId="closeJobReason">
                                    <Form.Control
                                        name="closeJobReason" type="text" placeholder="Reason to Reject this job goes here..."
                                        required onChange={e => { setCloseReason(e.target.value) }}
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                            <Button variant="danger" onClick={e => rejectParticularJob(modalRejectJobData.jobId)}>Close Job</Button>
                        </Modal.Footer>

                    </Modal>
                    :
                    null
            }
        </div>

    )
}

export default ContactUsResponse;
