import React, { useEffect, useState } from 'react';
import { DialogTitle, Typography, Button } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {

    Checkbox,
    FormControlLabel,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { convertToRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Col, Form } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import crossIcon from "../../../assets/images/cancel.png";
import { praveshAxiosPatchReq, praveshAxiosPostReq } from '../../../util/baseApi';
import { FailureAlert, SuccessAlert } from '../../../util/Notifications';
import CustomButtonCircularProgress from '../../Student/customLoader';


const useStyles = makeStyles((theme) => ({

    crossStyle: {
        width: "10px",
        height: "10px",
        cursor: "pointer"

    },
    crossSection: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    headingBox: {

        fontWeight: 600,

    },
    floatingLabelFocusStyle: {
        // color: "#7e7e7e",
        fontSize: "14px",
    },
    textfield: {

        maxHeight: '.3rem',

    },
    rowfield: {
        display: 'flex',
        alignItems: "center",
    },
    descriptionBox: {
        minHeight: "4rem",
    },
    editor: {
        minHeight: "4rem",
        marginBottom: '3rem',
    },
    addTemplatebtnSection: {
        display: 'flex',
        flexDirection: 'row-reverse'
    }

}))


const ModelPlanCreation = ({ open, setOpen, data, setData, value, handelResetForm, modalData, setModalData, slotGroup, setPlanData, planData }) => {

    // value = 0 is for plans and 1 for models

    const classes = useStyles()

    const [scroll, setScroll] = React.useState("paper");
    const [modelDetails, setModelDetails] = useState(EditorState?.createWithContent(ContentState?.createFromBlockArray(convertFromHTML(data?.modelDetails ? data?.modelDetails : ' '))));
    const [planDetails, setPlanDetails] = useState(EditorState?.createWithContent(ContentState?.createFromBlockArray(convertFromHTML(data?.planDetails ? data?.planDetails : ' '))));
    const [loading, setLoading] = useState(false);
    const [modalOptions, setModalOptions] = useState([]);
    const [slotGroupOptions, setSlotGroupOptions] = useState([]);

    const onModelDetailsEditorStateChange = (state) => {

        setModelDetails(state);
        setData({ ...data, modelDetails: draftToHtml(convertToRaw(state.getCurrentContent())) });

    }

    const onPlanDetailsEditorStateChange = (state) => {

        setPlanDetails(state);
        setData({ ...data, planDetails: draftToHtml(convertToRaw(state.getCurrentContent())) })

    }

    useEffect(() => {
        if (modalData?.length > 0) {

            let newModelOptions = modalData?.map((item) => ({ id: item?.id, name: item?.name }))
            setModalOptions(newModelOptions);

        }
    }, [modalData])

    useEffect(() => {

        if (slotGroup?.length > 0) {

            let groupOptions = slotGroup?.map((item) => ({ id: item, name: item }));
            setSlotGroupOptions(groupOptions);
        }

    }, [slotGroup])


    const handleClose = () => {
        setOpen(false);

    };

    const validate = () => {

        if (value === 1) {
            if (!data?.modelName || !data?.modelDetails) {
                FailureAlert("Please Enter all details!");
                return false

            }
            return true
        } else if (value === 0) {

            if (!data?.planDetails) {
                FailureAlert("Please enter plan details!");
                return false
            };
            if (!data?.planName) {
                FailureAlert("Please enter plan Name!");
                return false
            };
            if (!data?.planMonthlyPricing) {
                FailureAlert("Please enter plan Monthly pricing!");
                return false
            }
            if (!data?.planAnnualPricing && data?.selectedModel?.id !== 1) {
                FailureAlert("Please enter plan annual pricing!");
                return false
            }
            if (!data?.selectedModel) {
                FailureAlert("Please select Model!");
                return false
            }
            if (data?.selectedModel?.id !== 1 && data?.unlimited === 'no' && !data?.planJobCount) {
                FailureAlert("Please Enter plan job count!");
                return false
            }
            if (data?.selectedModel?.id !== 1 && data?.unlimited === 'no' && !data?.planInternshipCount) {
                FailureAlert("Please Enter plan Intership Count!");
                return false
            }
            if (data?.selectedModel?.id !== 1 && !data?.planTestPinsCount) {
                FailureAlert("Please enter plan test pins count!");
                return false
            }
            if (data?.selectedModel?.id !== 1 && !data?.hasKAM) {
                FailureAlert("Please select KAM status!");
                return false
            }
            if (data?.selectedModel?.id === 1 && !data?.slotGroup) {
                FailureAlert("Please select a particular slot group!");
                return false
            }
            // if (!data?.planName){
            //     FailureAlert("Please plan name!");
            //     return false
            // }

            return true
        }

    }

    const getDetailsPayload = (details) => {


        const textArr = details?.split('\n');
        let benefitsArr = textArr?.map(item => ({ text: item, added: true }));

        let finalDetailsPayload = {
            message: "",
            benefit_list: benefitsArr
        };

        return finalDetailsPayload
    };

    const getModelDetailsPayload = (details) => {

        const textArr = details?.split('\n');

        return { data: textArr?.toString() }

    }

    const getDetailsString = (details) => {

        let textHtml = '';
        const stringArr = details?.benefit_list?.map((item) => {

            textHtml = `${textHtml} \n ${item?.text}`

        })
        return textHtml;
    }

    const handleFormSubmission = async () => {

        const endPoint = `/api/admin/plan/manage_plan/`;

        if (!validate()) {
            return
        }

        let payload = {};

        // this is for free model

        if (value === 0 && data?.selectedModel?.id === 1) {

            payload = {
                plan_name: data?.planName,
                plan_monthly_pricing: data?.planMonthlyPricing,
                plan_details: getDetailsPayload(data?.planDetails),
                model_id: data?.selectedModel?.id,
                slot_group: data?.slotGroup?.name,
                plan_annual_pricing: 0,
                profile_unlocks: data?.profileUnlocks,

            };

            if (data?.isEdit) {
                payload["plan_id"] = data?.id;
            }

        } else if (value === 0) {

            payload = {
                plan_name: data?.planName,
                plan_monthly_pricing: data?.planMonthlyPricing,
                plan_annual_pricing: data?.planAnnualPricing,
                plan_details: getDetailsPayload(data?.planDetails),
                plan_job_count: data?.unlimited === 'yes' ? -1 : data?.planJobCount,
                plan_internship_count: data?.unlimited === 'yes' ? -1 : data?.planInternshipCount,
                plan_test_pins_count: data?.planTestPinsCount,
                plan_is_manager: data?.hasKAM === 'yes' ? true : false,
                model_id: data?.selectedModel?.id,
                profile_unlocks: parseInt(data?.profileUnlocks),
                plan_is_custom: data?.isCustomPlan,

            }
            if (data?.isEdit) {

                payload["plan_id"] = data?.id;
            }

        } else {
            payload = {
                model_name: data?.modelName,
                model_details: getModelDetailsPayload(data?.modelDetails),
                model_is_custom: data?.isCustomModel,
            }
            if (data?.isEdit) {

                payload["model_id"] = data?.modelId;
            }
        }
        setLoading(true)

        try {
            let res

            if (data?.isEdit) {
                res = await praveshAxiosPatchReq(endPoint, payload)
            } else {

                res = await praveshAxiosPostReq(endPoint, payload);
            }
            if (res?.data?.success) {

                handelResetForm();
                setModelDetails(EditorState.createEmpty());

                if (value === 1) {

                    let newModel = res?.data?.data?.model;
                    let modelObj = {
                        ...newModel,
                        details: newModel?.details?.data,
                        isCustomModel: newModel?.is_custom,
                    };

                    if (data?.isEdit) {

                        let index = modalData?.findIndex((item) => (item?.id === data?.modelId));
                        let tempArr = modalData;
                        tempArr?.splice(index, 1, modelObj);
                        setModalData(tempArr);

                    } else {
                        setModalData((prevData) => ([...prevData, modelObj]));
                    }

                    SuccessAlert(res?.data?.data?.message);
                    setOpen(false);

                } else {

                    const newPlan = res?.data?.data?.plan;

                    let obj = {
                        ...newPlan,
                        job_count: newPlan?.job_count === -1 ? "Unlimited" : newPlan?.job_count,
                        extra_data: JSON.stringify(newPlan?.extra_data),
                        create_time: new Date(newPlan?.create_time).toLocaleString(),
                        update_time: new Date(newPlan?.update_time).toLocaleString(),
                        model_name: data?.selectedModel?.name,
                        details: getDetailsString(newPlan?.details),

                    }
                    if (data?.isEdit) {

                        let index = planData?.findIndex((item) => (item?.id === data?.id));
                        let tempArr = planData;
                        tempArr?.splice(index, 1, obj);
                        setPlanData(tempArr);

                    } else {

                        setPlanData((prevData) => ([...prevData, obj]));
                    }
                    SuccessAlert(res?.data?.data?.message);
                    setPlanDetails(EditorState.createEmpty());
                    setOpen(false);

                }
            }
            setLoading(false);

        } catch (error) {

            setLoading(false);
            throw (error)
        }
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                        <Typography variant='h5' className={classes.headingBox}>
                            {`${data?.isEdit ? "Update" : "Create"} ${value === 0 ? "Plan" : "Model"}`}
                        </Typography>
                        <img
                            className={classes.crossStyle}
                            src={crossIcon}
                            onClick={() => { handleClose(); }}
                            alt="close Icon" />
                    </div>
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                    {
                        // for plan
                        value === 0 ?
                            <>
                                <Form
                                    id='Plan_form'
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="selectModel">
                                            <Autocomplete
                                                id="select-channel"
                                                options={modalOptions}
                                                value={data?.selectedModel}
                                                getOptionLabel={(item) => item.name}
                                                onChange={(e, item) => {

                                                    const item2 = item || [];
                                                    setData({ ...data, selectedModel: item2 })

                                                }}
                                                renderInput={(params) => <TextField {...params} label="Models" required variant="outlined" />}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="selectGroups">

                                            {data?.selectedModel?.id === 1 ?
                                                <Autocomplete
                                                    id="select-groups"
                                                    options={slotGroupOptions}
                                                    value={data?.slotGroup}
                                                    getOptionLabel={(item) => item?.name}
                                                    onChange={(e, item) => {

                                                        const item2 = item || [];
                                                        setData({ ...data, slotGroup: item2 })

                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            label="Slot Groups"
                                                            required
                                                            variant="outlined" />}
                                                />
                                                : null
                                            }
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="selectGroups">
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                value={data?.planName}
                                                onChange={(e) => {
                                                    setData({ ...data, planName: e.target.value })
                                                }}
                                                InputLabelProps={{
                                                    className: classes.floatingLabelFocusStyle,
                                                }}
                                                fullWidth
                                                inputProps={{ className: classes.textfield }}
                                                label="Plan Name"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="selectGroups">
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                type='number'
                                                value={data?.planMonthlyPricing}
                                                onChange={(e) => {
                                                    setData({ ...data, planMonthlyPricing: e.target.value })
                                                }}
                                                InputLabelProps={{
                                                    className: classes.floatingLabelFocusStyle,
                                                }}
                                                fullWidth
                                                inputProps={{ className: classes.textfield }}
                                                label="Monthly Pricing"
                                            />

                                        </Form.Group>

                                    </Form.Row>
                                    {
                                        data?.selectedModel?.id !== 1 ?
                                            <>
                                                <Form.Row style={{ display: 'flex', alignItems: "center" }}>
                                                    <Form.Group as={Col} controlId="selectGroups">
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            type='number'
                                                            value={data?.planAnnualPricing}
                                                            onChange={(e) => {
                                                                setData({ ...data, planAnnualPricing: e.target.value })
                                                            }}
                                                            InputLabelProps={{
                                                                className: classes.floatingLabelFocusStyle,
                                                            }}
                                                            fullWidth
                                                            inputProps={{ className: classes.textfield }}
                                                            label="Annual Pricing"
                                                        />

                                                    </Form.Group>
                                                    <Form.Group as={Col} type="radio" id="hasKAM" required>
                                                        <Form.Label>Are Jobs*:</Form.Label>
                                                        <Form.Check
                                                            inline
                                                            name="unlimited"
                                                            type="radio"
                                                            label="Unlimited"
                                                            value={data?.unlimited}
                                                            checked={data?.unlimited === "yes"}
                                                            onChange={(e) => {
                                                                setData({
                                                                    ...data,
                                                                    unlimited: 'yes'
                                                                })
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            name="limited"
                                                            type="radio"
                                                            value={data?.unlimited}
                                                            label="Limited"
                                                            checked={data?.unlimited === 'no'}
                                                            onChange={(e) => {
                                                                setData({ ...data, unlimited: 'no' })
                                                            }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="selectGroups">
                                                        {
                                                            data?.unlimited === 'no' ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    type='number'
                                                                    value={data?.planJobCount}
                                                                    onChange={(e) => {
                                                                        setData({ ...data, planJobCount: e.target.value })
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.floatingLabelFocusStyle,
                                                                    }}
                                                                    fullWidth
                                                                    inputProps={{ className: classes.textfield }}
                                                                    label="Plan Job Count"
                                                                />

                                                                : null

                                                        }
                                                    </Form.Group>

                                                </Form.Row>
                                                <Form.Row style={{ display: 'flex', alignItems: 'center' }}>

                                                    {
                                                        data?.unlimited === 'no' ?
                                                            <>
                                                                <Form.Group as={Col} controlId="selectGroups">
                                                                    <TextField
                                                                        label="Plan Internship Count"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        type='number'
                                                                        value={data?.planInternshipCount}
                                                                        onChange={(e) => {
                                                                            setData({ ...data, planInternshipCount: e.target.value })
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: classes.floatingLabelFocusStyle,
                                                                        }}
                                                                        fullWidth
                                                                        inputProps={{ className: classes.textfield }}
                                                                    />

                                                                </Form.Group>

                                                            </>
                                                            : null
                                                    }
                                                    <Form.Group as={Col} controlId="selectGroups">
                                                        <TextField
                                                            label="Plan Test Pins Count"
                                                            variant="outlined"
                                                            type='number'
                                                            margin="normal"
                                                            value={data?.planTestPinsCount}
                                                            onChange={(e) => {
                                                                setData({ ...data, planTestPinsCount: e.target.value })
                                                            }}
                                                            InputLabelProps={{
                                                                className: classes.floatingLabelFocusStyle,
                                                            }}
                                                            fullWidth
                                                            inputProps={{ className: classes.textfield }}
                                                        />

                                                    </Form.Group>

                                                    <Form.Group as={Col} type="radio" id="hasKAM" required>
                                                        <Form.Label>Has KAM*: </Form.Label>
                                                        <Form.Check
                                                            inline
                                                            name="KAM"
                                                            type="radio"
                                                            label="Yes"
                                                            value="yes"
                                                            checked={data?.hasKAM === "yes"}
                                                            onChange={(e) => {
                                                                setData({ ...data, hasKAM: e.target.value })
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            name="KAM"
                                                            type="radio"
                                                            value="no"
                                                            label="No"
                                                            checked={data?.hasKAM === 'no'}
                                                            onChange={(e) => {
                                                                setData({ ...data, hasKAM: e.target.value })
                                                            }}
                                                        />
                                                    </Form.Group>

                                                </Form.Row>
                                            </>

                                            : null
                                    }
                                    <Form.Row style={{ display: 'flex', alignItems: "center" }}>
                                        <Form.Group as={Col} controlId="selectGroups">
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                type='number'
                                                value={data?.profileUnlocks}
                                                onChange={(e) => {
                                                    setData({ ...data, profileUnlocks: e.target.value })
                                                }}
                                                InputLabelProps={{
                                                    className: classes.floatingLabelFocusStyle,
                                                }}
                                                fullWidth
                                                inputProps={{ className: classes.textfield }}
                                                label="Profile Unlocks"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="isModelCustom">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        value={data?.isCustomPlan}
                                                        onChange={(e) => {
                                                            setData({ ...data, isCustomPlan: !(data?.isCustomPlan) })
                                                        }}
                                                        checked={data?.isCustomPlan}
                                                    />
                                                }
                                                label={
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        Is this a Custom Plan
                                                    </Typography>
                                                }
                                            />
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>

                                        <Form.Group as={Col} controlId="addTemplate2">
                                            <Form.Label>Plan Details</Form.Label>
                                            <div className={classes.editor}>
                                                <Editor
                                                    editorState={planDetails}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    editorStyle={{
                                                        border: "1px solid #C0C0C0",
                                                        minHeight: 150
                                                    }}
                                                    onEditorStateChange={onPlanDetailsEditorStateChange}
                                                />
                                            </div>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} controlId="addTemplate2">
                                        </Form.Group> */}
                                    </Form.Row>
                                </Form>
                            </>
                            :
                            <>
                                {/* for model */}
                                <Form
                                    id="model_form"
                                >
                                    <Form.Group as={Col} controlId="modelName">
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            value={data?.modelName}
                                            onChange={(e) => {

                                                setData({ ...data, modelName: e.target.value })
                                            }}
                                            InputLabelProps={{
                                                className: classes.floatingLabelFocusStyle,
                                            }}
                                            fullWidth
                                            inputProps={{ className: classes.textfield }}
                                            label="Model Name"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="isModelCustom">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    value={data?.isCustomModel}
                                                    onChange={(e) => {
                                                        setData({ ...data, isCustomModel: !(data?.isCustomModel) })
                                                    }}
                                                    checked={data?.isCustomModel}
                                                />
                                            }
                                            label={
                                                <Typography style={{ fontSize: "14px" }}>
                                                    Is this a Custom Model
                                                </Typography>
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="addTemplate2">
                                        <Form.Label>Model Details</Form.Label>
                                        <div className={classes.editor}>
                                            <Editor
                                                editorState={modelDetails}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                editorStyle={{ border: "1px solid #C0C0C0", minHeight: 150 }}
                                                onEditorStateChange={onModelDetailsEditorStateChange}

                                            />
                                        </div>
                                    </Form.Group>
                                </Form>
                            </>
                    }

                    <Form.Row className={classes.addTemplatebtnSection}>
                        <Button
                            // color="primary"
                            variant="contained"
                            style={{ minWidth: '10rem', backgroundColor: '#303F9F', color: 'white' }}
                            onClick={() => { handleFormSubmission() }}
                        >
                            {loading ? <CustomButtonCircularProgress /> :
                                `${data?.isEdit ? "Update" : "Create"} ${value === 0 ? "Plan" : "Model"}`}
                        </Button>
                    </Form.Row>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ModelPlanCreation