import { Typography } from '@material-ui/core';
import React from 'react'
import welcomeToGetwork from "../assets/images/welcomeToGetWork.png";



const WelcomePage = () => {

    const getUserName = () => {

        const userName = JSON.parse(localStorage.getItem("user-data")).first_name;
        const titleCase = userName => `${userName[0].toUpperCase()}${userName.slice(1).toLowerCase()}`
        return titleCase(userName)
    }

    return (
        <>
            <section style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "center",
            }}>
                <img src={welcomeToGetwork}
                    style={{
                        width: "40%",
                        marginTop: "-50px"
                    }}
                    alt="" />
                <Typography
                    style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: "24px",
                        marginTop: "-25px"
                    }}>
                    Welcome {`${getUserName()}`}
                </Typography>

                <Typography
                    style={{
                        color: "#007bff",
                        fontWeight: "500",
                        marginBottom: "40px",
                        textAlign: "center",
                        fontStyle: "italic"
                    }}>
                    There is joy in work. There is no happiness except in the realization that we have accomplished something.
                </Typography>

            </section>
        </>
    )
}

export default WelcomePage