import React, { useEffect, useMemo, useState } from "react";

import {
  Typography,
  useTheme,
  makeStyles,
  Button,
  withStyles,
} from "@material-ui/core";
import CampusBarGraph from "./GraphWithTable";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ReportTable from "./ReportTable";
import { useAlert } from "react-alert";
import GraphSkeleton from "./GraphSkeleton";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 350,
    width: 350,
  },
  selectOptions: {
    "& .MuiListItem-root": {
      // borderTop: "1px solid rgb(3,15,252)",
      // borderRadius: 8,
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
    backgroundColor: "#fff",
    "& .MuiCheckbox-root": {
      // color: "green",
    },
    "& .MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        // color: "orange",
      },
    },
  },
}));

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 350,
      marginLeft: "-17px",
    },
  },
};

function ReportHeading({ color, yAxis, xAxis, heading, tableTitle }) {
  //const classes = useStyles();
  const theme = useTheme();
  // const alert = useAlert();
  const [value, setValue] = useState(2);
  const [tabData, setTabData] = useState(null);

  function handleChange(id) {
    setValue(id);
  }

  const [loading, setLoading] = useState(false);

  // const [personName, setPersonName] = React.useState([]);
  // MenuProps.PaperProps.className = classes.selectOptions; //had to be here to use hook, and menu uses popper

  // const handleChangeField = (event) => {
  //   setPersonName(event.target.value);
  // };

  const chartHelper = () => {
    if (tabData) {
      const labels = [];
      const data = [];
      const color = [];
      for (let key of tabData) {
        labels.push(key.name);
        data.push(key.value);
        color.push(key.color);
      }

      return {
        labels: labels,
        data: data,
        color: color[0],
        yAxis: yAxis,
        xAxis: xAxis,
        heading: heading,
      };
    }
  };

  const data = [
    {
      id: 2,
      title: <div style={{ display: "flex", alignItems: "center" }}>Graph</div>,
      // tab: (<>{loading ? <GraphSkeleton/> :<ScrollBarGraph chartData={chartHelper()} yAxis={yAxis} xAxis={xAxis}  />}</>),
      tab: (
        <>{loading ? <GraphSkeleton /> : <CampusBarGraph color={color} />}</>
      ),
    },
    {
      id: 1,
      title: <div style={{ display: "flex", alignItems: "center" }}>Table</div>,
      tab: (
        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
          {" "}
          <ReportTable
            chartData={chartHelper()}
            color={color}
            tableTitle={tableTitle}
          />{" "}
        </div>
      ),
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [loading]);

  return (
    <>
      <div
        style={{
          border: "1px solid #b0b6ba",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
        >
          {/* <div>
            <Typography variant="h6">{heading}</Typography>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h6" style={{ fontWeight: "600" }}>
              Footfall Graph (29/05/2024 to 03/06/2024)
            </Typography>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  //   padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #E1DFDF",
                    borderRadius: "5px",
                  }}
                >
                  {memoizedData.map((item, id) => (
                    <ul
                      style={{
                        padding: 0,
                        display: "flex",
                        listStyleType: "none",
                        flexWrap: "wrap",
                        border: "1px solid #E1DFDF",
                        textAlign: "center",
                      }}
                      id="myTab"
                      data-tabs-toggle="#myTabContent"
                      role="tablist"
                    >
                      <li
                        role="presentation"
                        onClick={() => handleChange(item.id)}
                      >
                        <div
                          style={{
                            padding: "6px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            color: value === item.id ? "#747677" : "#000",
                            backgroundColor:
                              value === item.id ? "#F3F2F2" : "#fff",
                          }}
                        >
                          {item.title}
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {memoizedData.map((item, id) => (
          <div id="myTabContent">
            <div
              style={{
                display: value === item.id ? "block " : "none",
                marginTop: "20px",
              }}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {item.tab}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ReportHeading;
