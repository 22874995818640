import { AppBar, Tab, Tabs, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import UseAxiosApi from "../../Api/UseAxiosApi";
import default_logo from "../../assets/default_logo.png";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  createIconStyling,
  gifStyling,
  headerCSS,
  praveshUrl,
  requestPraveshOptions,
} from "../../constants";
import {
  apiCall,
  niyuktiAxiosCall,
  praveshAxiosCall,
} from "../../util/apiCall";
import { copyToClipboard } from "../../util/copyToClipboard";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { validateDateTime } from "../../util/printNumber";
import { TabPanel } from "../../util/TabPanel";
import CustomButtonCircularProgress from "../loader";
import TableWithExport from "../TableWithExport";
import AdvanceFiltersDialog from "./Dialog/AdvanceFiltersDialog";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const token = localStorage.getItem("token");

const requestForBlock = {
  method: "PATCH",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  },
  body: JSON.stringify({
    is_blocked: true,
  }),
};

const requestForUnblock = {
  method: "PATCH",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  },
  body: JSON.stringify({
    is_blocked: false,
  }),
};

function AllColleges(props) {
  const [userCreatedData, setUserCreatedData] = useState([]);
  const [noUserCreatedData, setNoUserCreatedData] = useState([]);

  const [userCreatedColleges, setUserCreatedCollges] = useState(
    props.userCreated ? 1 : 0
  );
  const [states, setStates] = useState({}); // For Filter purpose
  const [universitiesFilter, setUniversitiesFilter] = useState({}); // For Filter purpose
  // const [coursesFilter, setCoursesFilter] = useState({});   // For Filter purpose
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isInitialCall, setInitialCall] = useState(true);

  const theme = useTheme();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateFiltersRemoved, setDateFiltersRemoved] = useState(false);
  const [allJobSegments, setAllJobSegments] = useState([]);
  const [selectedJobSegments, setSelectedJobSegments] = useState([]);
  const current = new Date();
  const initialDate = new Date(current.setMonth(current.getMonth() - 1));
  // const dateRange = [new Date(2021, 0, 1), new Date()];
  const dateRange = [initialDate, new Date()];

  //for downloading all data
  const dateString = useRef("");
  const [canDownload, setCanDownload] = useState(false);

  const [minStipend, setMinStipend] = useState(null);
  const [minCtc, setMinCtc] = useState(null);
  const [allStates, setAllStates] = useState([]);
  const [stateLookUp, setStateLookUp] = useState({});
  const [filterCount, setFilterCount] = useState(1);

  /*  const [jobTypeArr, setJobTypeArr] = useState([]); */
  // useEffect(() => console.log(startDate.toISOString()), [startDate]);
  const [urlFilter, setUrlFilter] = useState(null);

  // for passing to dropdown city list filter
  const [cityList, setCityList] = useState();

  const blockUnblock = (e, row) => {
    e.preventDefault();
    const request =
      row.is_blocked === "Yes" ? requestForUnblock : requestForBlock;
    apiCall(
      `/api/admin/college/edit/?college_id=${row.college_id}`,
      request
    ).then((res) => {
      if (res.success) {
        SuccessAlert("Action Successful");
        setCount(count + 1);
      } else {
        FailureAlert(res.error);
      }
    });
  };

  useEffect(() => {
    {
      praveshAxiosCall(`/api/location/state/?search=1`)
        .then((res) => {
          setAllStates(res.data);
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let obj = allStates?.reduce(function (acc, cur, i) {
      acc[cur.state] = cur.state;

      return acc;
    }, {});
    setStateLookUp(obj);
  }, [allStates]);

  useEffect(() => {
    // if its initial call
    // and there is nothing in url then startdate and enddate should be today and t-30
    //  else get it from url
    if (isInitialCall) {
      let initialStartDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      );
      let initialEndDate = new Date();

      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = queryParams.from;
      }
      if (queryParams.to) {
        initialEndDate = queryParams.to;
      }
      setStartDate(new Date(initialStartDate));
      setEndDate(new Date(initialEndDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch all colleges details
  useEffect(() => {
    // if datefilters are removed let the code execute without dates
    if (!dateFiltersRemoved && !startDate) {
      return;
    }

    let dateString = "";

    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      props.history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      dateString = `&start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }

    if (urlFilter) {
      dateString += `${urlFilter}`;
    }
    setLoading(true);
    setInitialCall(false);
    apiCall(
      `/api/admin/college/?user_created=${
        userCreatedColleges !== 0
      }${dateString}`,
      requestPraveshOptions
    ).then((json) => {
      if (json.success) {
        let temp = [];
        let collegeNames = {};
        let jobTypeShowArr = [];
        if (openAdvanceFilterDialog) setOpenAdvanceFilterDialog(false);
        for (var i = 0; i < json.data.length; i++) {
          let jobTypeShowStr = "";
          let statePreferencesStr = "";
          let internshipAvailability = "";
          let fulltimeAvailabilty = "";
          json.data[i]?.preferences?.job_type?.map((item) => {
            if (item.id === 1) {
              jobTypeShowStr += "Internship, ";
            }
            if (item.id === 2) {
              jobTypeShowStr += "Internship with PPO, ";
            }
            if (item.id === 7) {
              jobTypeShowStr += "Full-Time, ";
            }
          });

          json.data[i]?.preferences?.location?.map((item) => {
            statePreferencesStr += `${item?.state}, `;
          });

          json.data[i]?.preferences?.internship_availability?.map((month) => {
            if (month === 1) {
              internshipAvailability += `Jan, `;
            }
            if (month === 2) {
              internshipAvailability += `Feb, `;
            }
            if (month === 3) {
              internshipAvailability += `Mar, `;
            }
            if (month === 4) {
              internshipAvailability += `Apr, `;
            }
            if (month === 5) {
              internshipAvailability += `May, `;
            }
            if (month === 6) {
              internshipAvailability += `Jun, `;
            }
            if (month === 7) {
              internshipAvailability += `Jly, `;
            }
            if (month === 8) {
              internshipAvailability += `Aug, `;
            }
            if (month === 9) {
              internshipAvailability += `Sep, `;
            }
            if (month === 10) {
              internshipAvailability += `Oct, `;
            }
            if (month === 11) {
              internshipAvailability += `Nov, `;
            }
            if (month === 12) {
              internshipAvailability += `Dec, `;
            }
          });

          json.data[i]?.preferences?.job_availability?.map((month) => {
            if (month === 1) {
              fulltimeAvailabilty += `Jan, `;
            }
            if (month === 2) {
              fulltimeAvailabilty += `Feb, `;
            }
            if (month === 3) {
              fulltimeAvailabilty += `Mar, `;
            }
            if (month === 4) {
              fulltimeAvailabilty += `Apr, `;
            }
            if (month === 5) {
              fulltimeAvailabilty += `May, `;
            }
            if (month === 6) {
              fulltimeAvailabilty += `Jun, `;
            }
            if (month === 7) {
              fulltimeAvailabilty += `Jly, `;
            }
            if (month === 8) {
              fulltimeAvailabilty += `Aug, `;
            }
            if (month === 9) {
              fulltimeAvailabilty += `Sep, `;
            }
            if (month === 10) {
              fulltimeAvailabilty += `Oct, `;
            }
            if (month === 11) {
              fulltimeAvailabilty += `Nov, `;
            }
            if (month === 12) {
              fulltimeAvailabilty += `Dec, `;
            }
          });

          let obj = {
            college_id: parseInt(json.data[i].college_id),
            college_name: json.data[i].college_name,
            college_nickname: json.data[i].nick_name || "",
            university_id: parseInt(json.data[i].university_id) || "",
            university_name: json.data[i].university_name || "",
            address: json.data[i].address ? json.data[i].address : "",
            min_ctc: json.data[i].min_ctc
              ? parseFloat(json.data[i].min_ctc)
              : "",
            avg_ctc: json.data[i].avg_ctc
              ? parseFloat(json.data[i].avg_ctc)
              : "",
            is_blocked: json.data[i].is_blocked ? "Yes" : "No",
            is_usercreated: json.data[i].is_usercreated ? "Yes" : "No",
            onboard_date: json.data[i].is_onboarded_date
              ? json.data[i].is_onboarded_date
              : " ",
            create_time: validateDateTime(json.data[i].create_time),
            update_time: validateDateTime(json.data[i].update_time),
            country: json.data[i].country,
            state: json.data[i].state,
            city: json.data[i].city,
            logo: json.data[i].logo ? json.data[i].logo : null,
            is_admin_created: json.data[i].is_admin_created ? "Yes" : "No",
            internship: json.data[i].internship_availability ? "Yes" : "No",
            fulltime: json.data[i].fulltime_availability ? "Yes" : "No",
            immediate: json.data[i].immediate_joining_availability
              ? "Yes"
              : "No",
            is_onboarded: json.data[i].is_onboarded ? "Yes" : "No",
            is_saas: json.data[i].is_saas ? "Yes" : "No",
            is_saas_with_job: json.data[i].is_saas_with_job ? "Yes" : "No",
            domain: json.data[i].domain ? json.data[i].domain : "",
            passcode: json.data[i].passcode ? json.data[i].passcode : "",
            poc_name: json.data[i].tpo_details
              ? json.data[i].tpo_details.first_name +
                " " +
                json.data[i].tpo_details.last_name
              : "",
            poc_email: json.data[i].tpo_details
              ? json.data[i].tpo_details.email
              : "",
            poc_phone: json.data[i].tpo_details
              ? json.data[i].tpo_details.phone
              : "",
            alternate_email: json.data[i].tpo_details?.alternate_email
              ? json.data[i].tpo_details.alternate_email
              : "",
            alternate_phone: json.data[i].tpo_details?.alternate_phone
              ? json.data[i].tpo_details.alternate_phone
              : "",
            poc_designation: json.data[i].tpo_details
              ? json.data[i].tpo_details.job_title
              : "",
            last_login:
              json.data[i].tpo_details && json.data[i].tpo_details.last_login
                ? validateDateTime(json.data[i].tpo_details.last_login)
                : "",
            acc_manager_name: json.data[i].account_manager
              ? (json.data[i].account_manager.first_name || "") +
                " " +
                (json.data[i].account_manager.last_name || "")
              : "",
            acc_manager_email: json.data[i].account_manager
              ? json.data[i].account_manager.email
              : "",
            preferences: json.data[i].preferences,
            totalStudents: json.data[i].total_students,
            hiredStudents: json.data[i].hired_students,
            jobType: jobTypeShowStr,
            state_preferences: statePreferencesStr,
            internship_availability: internshipAvailability,
            fulltime_availabilty: fulltimeAvailabilty,
            job_segments:
              json.data[i].preferences &&
              typeof json.data[i].preferences === "object"
                ? json.data[i].preferences &&
                  json.data[i].preferences.job_segment
                  ? json.data[i].preferences.job_segment
                  : []
                : JSON.parse(json.data[i].preferences) &&
                  JSON.parse(json.data[i].preferences)["job_segment"]
                ? JSON.parse(json.data[i].preferences)["job_segment"]
                : [],
            is_grade_system: json.data[i].is_grade_system
              ? "CGPA"
              : "Percentage",
            percentage_factor: json.data[i].percentage_factor
              ? json.data[i].percentage_factor
              : null,
          };
          collegeNames[obj.college_id] = obj.college_name;
          temp.push(obj);

          const citylist1 = [...new Set(temp.map((item) => item.city))];

          const citylist3 = citylist1.reduce((a, v) => ({ ...a, [v]: v }), {});

          setCityList(citylist3);
        }

        if (userCreatedColleges) {
          setUserCreatedData(temp);
        } else {
          setNoUserCreatedData(temp);
        }
        localStorage.setItem("colleges", JSON.stringify(collegeNames));
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreatedColleges, startDate, endDate, urlFilter]);

  useEffect(() => {
    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      props.history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      dateString.current = `start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }
  }, [startDate, endDate]);

  // dowload all data and get email for all colleges

  const downloadCallbackFn = (data) => {
    SuccessAlert(data?.message ?? "Link to download data will arrive in mail");
  };

  const endPoint = `${praveshUrl}/api/admin/college/?${
    dateString.current
  }&is_export=True&user_created=${userCreatedColleges === 0 ? false : true}`;

  const { apiCall: downloadAllDataCSV, loading: loadingAllDataDownload } =
    UseAxiosApi({
      url: endPoint,
      mountingCallFlag: canDownload,
      callBackFn: downloadCallbackFn,
    });

  useEffect(() => {
    if (canDownload) {
      downloadAllDataCSV();
    }
  }, [canDownload]);

  // State Finding API
  useEffect(() => {
    apiCall(`/api/location/state/?search=1`, requestPraveshOptions).then(
      (res) => {
        let obj = {};
        res?.forEach((state) => {
          obj[state.state] = state.state;
        });
        setStates(obj);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // University Finding API
  useEffect(() => {
    apiCall("/api/admin/university", requestPraveshOptions).then((res) => {
      if (res.success) {
        let obj = {};
        res.data.forEach((u) => {
          obj[u.university_name] = u.university_name;
        });
        setUniversitiesFilter(obj);
      } else {
        FailureAlert("Invalid request for University API");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Job Segment Finding API
  useEffect(() => {
    niyuktiAxiosCall("/job/segment/")
      .then((json) => {
        setAllJobSegments(json?.data?.data);
      })
      .catch((err) => {
        FailureAlert(err.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeToggle = (event, newValue) => {
    setUserCreatedCollges(newValue);
    // when the user switches toggle there shouldn't be automatic download api call
    setCanDownload(false);
  };

  const handleChange = (newRange, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, newRange);
  };

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  const ctcLabel = [
    { value: 0, label: "0" },
    { value: 1000000, label: "10L" },
    { value: 2000000, label: "20L" },
  ];

  const columns = [
    {
      title: "Clg_ID",
      field: "college_id",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 130,
        maxWidth: 130,
        fontSize: 13,
      },
      searchable: false,
    },
    {
      title: "College Name",
      field: "college_name",
      headerStyle: headerCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
        fontSize: 13,
      },

      render: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            placeItems: "center",
            width: "200px",
          }}
        >
          {row.logo ? (
            <img
              src={row.logo}
              alt="logo"
              width="30px"
              height="30px"
              style={{ borderRadius: "50%", marginRight: "3px" }}
            />
          ) : (
            <img
              src={default_logo}
              alt="logo"
              width="30px"
              height="30px"
              style={{ borderRadius: "50%", marginRight: "3px" }}
            />
          )}{" "}
          <Tooltip title={`${row.college_name}`} placement="right">
            <div
              style={{
                cursor: "pointer",
                color: "blue",
                fontSize: "13px",
                minWidth: 100,
                maxWidth: 150,
                fontSize: 13,
                overflow: "hidden",
                maxHeight: 50,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              onClick={() =>
                window.open(
                  `/admin/edit-college/?clgId=${row.college_id}&collegeName=${row.college_name}`
                )
              }
            >
              {`${row.college_name}`}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "City",
      field: "city",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: 13,
      },

      lookup: cityList,
      searchable: false,
      filterPlaceholder: "Select",
      filterCellStyle: {
        overflow: "hidden",
        minWidth: 200,
        maxWidth: 200,
      },
    },
    {
      title: "POC_Name",
      field: "poc_name",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
        fontSize: 13,
      },
    },
    {
      title: "Designation",
      field: "poc_designation",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      render: (row) => (
        <Tooltip title={`${row.poc_designation}`} placement="right">
          <div
            style={{
              cursor: "pointer",
              minWidth: 100,
              maxWidth: 150,
              fontSize: 13,
              overflow: "hidden",
              maxHeight: 50,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {`${row.poc_designation}`}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Phone Number",
      field: "poc_phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.poc_phone ? (
          <div
            style={{
              display: "flex",
              minWidth: 150,
              maxWidth: 200,
              fontSize: 13,
            }}
          >
            {row.poc_phone}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.poc_phone)}
            />
          </div>
        ) : null,
    },
    {
      title: "Alternate Phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.alternate_phone ? (
          <div style={{ display: "flex" }}>
            {row.alternate_phone}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.alternate_phone)}
            />
          </div>
        ) : null,
    },
    {
      title: "Email",
      field: "poc_email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.poc_email ? (
          <div style={{ display: "flex" }}>
            {row.poc_email}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.poc_email)}
            />
          </div>
        ) : null,
    },
    {
      title: "Alternate Email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.alternate_email ? (
          <div style={{ display: "flex" }}>
            {row.alternate_email}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.alternate_email)}
            />
          </div>
        ) : null,
    },
    {
      title: "Account_Manager_Name",
      field: "acc_manager_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Created At",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.create_time) &&
        Date.parse(term[1]) >= Date.parse(rowData.create_time),
    },
    // No. of Students in college
    {
      title: "Total Students",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <Button
            variant="outline-info"
            size="sm"
            style={{
              fontSize: 13,
            }}
            onClick={() =>
              window.open(`/admin/college/all-students/${row.college_id}`)
            }
          >
            View
            {/* {`View(${row.totalStudents})`} */}
          </Button>
        </div>
      ),
    },
    // No. of Students hired
    {
      title: "Hired Students",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <Button
            variant="outline-info"
            size="sm"
            style={{
              fontSize: 13,
            }}
            onClick={() =>
              window.open(`/admin/college/hired-students/${row.college_id}`)
            }
          >
            {/* {`View(${row.hiredStudents})`} */}
            View
          </Button>
        </div>
      ),
    },
    {
      title: "Grade_system",
      field: "is_grade_system",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Percentage: "Percentage", CGPA: "CGPA" },
      searchable: false,
      filterPlaceholder: "Select",
    },
    /*   {
      title: "Percentage factor",
      field: "percentage_factor",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    }, */
    {
      title: "Courses/Degrees",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filtering: false,
      export: false,
      render: (row) => (
        <div style={{ width: "100px" }}>
          <Button
            variant="outline-info"
            size="sm"
            style={{
              fontSize: 13,
            }}
            onClick={() =>
              window.open(
                `/admin/edit-college/?clgId=${row.college_id}&idCollegeCourses=${row.college_id}`
              )
            }
          >
            {" "}
            View
          </Button>
        </div>
      ),
    },
    {
      title: "Passcode",
      field: "passcode",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "On Boarded College",
      field: "is_onboarded",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      cellStyle: {
        minWidth: 150,
        maxWidth: 200,
        fontSize: 13,
      },

      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "SAAS College",
      field: "is_saas",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "SAAS + jobs",
      field: "is_saas_with_job",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      cellStyle: {
        minWidth: 120,
        maxWidth: 200,
        fontSize: 13,
      },

      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "Job_Type",
      field: "jobType",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: {
        Internship: "Internship",
        "Internship with PPO": "Internship with PPO",
        "Full-Time": "Full-Time",
      },
      render: (row) => (
        <div
          style={{
            fontSize: 13,
          }}
        >
          <span>{row.jobType}</span>
        </div>
      ),
      filterPlaceholder: "Select",
      customFilterAndSearch: (term, rowData) => {
        if (term.length > 0) {
          for (let i = 0; i < term.length; i++) {
            if (rowData?.jobType?.includes(term[i]) > 0) return true;
          }
          return false;
        } else {
          return true;
        }
      },
      // render: (row) => (row.min_ctc ? printNumber(row.min_ctc) : null),
    },
    {
      title: "Internship_Availabilty",
      field: "internship_availability",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: {
        Jan: "January",
        Feb: "February",
        Mar: "March",
        Apr: "April",
        May: "May",
        Jun: "June",
        Jly: "July",
        Aug: "August",
        Sep: "September",
        Oct: "October",
        Nov: "November",
        Dec: "December",
      },
      render: (row) => (
        <div>
          <span>{row.internship_availability}</span>
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        if (term.length > 0) {
          for (let i = 0; i < term.length; i++) {
            if (rowData?.internship_availability?.includes(term[i]) > 0)
              return true;
          }
          return false;
        } else {
          return true;
        }
      },
      filterPlaceholder: "Select",
    },
    {
      title: "Fulltime_Availabilty",
      field: "fulltime_availabilty",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: {
        Jan: "Jan",
        Feb: "February",
        Mar: "March",
        Apr: "April",
        May: "May",
        Jun: "June",
        Jly: "July",
        Aug: "August",
        Sep: "September",
        Oct: "October",
        Nov: "November",
        Dec: "December",
      },
      render: (row) => (
        <div>
          <span>{row.fulltime_availabilty}</span>
        </div>
      ),
      filterPlaceholder: "Select",
      customFilterAndSearch: (term, rowData) => {
        if (term.length > 0) {
          for (let i = 0; i < term.length; i++) {
            if (rowData?.fulltime_availabilty?.includes(term[i]) > 0)
              return true;
          }
          return false;
        } else {
          return true;
        }
      },
    },
    {
      title: "State_Preferences",
      field: "state_preferences",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      lookup: stateLookUp,
      filterPlaceholder: "select",
      render: (row) => (
        <div>
          <span>{row.state_preferences}</span>
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        // console.log(term);
        if (term.length > 0) {
          for (let i = 0; i < term.length; i++) {
            if (rowData?.state_preferences?.includes(term[i])) return true;
          }
          return false;
        } else {
          return true;
        }
      },
    },
    // {
    //   title: "University_Name",
    //   field: "university_name",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   searchable: false,
    //   lookup: universitiesFilter,
    //   filterPlaceholder: "Universities",
    // },
    // {
    //   title: "Address",
    //   field: "address",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    // },
    // {
    //   title: "State",
    //   field: "state",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   searchable: false,
    //   lookup: states,
    //   filterPlaceholder: "Select",
    // },
    // {
    //   title: "Country",
    //   field: "country",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    // },

    // {
    //   title: "Account_Manager_Email",
    //   field: "acc_manager_email",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   render: (row) => (
    //     <div>
    //       {row.acc_manager_email ? (
    //         <span>
    //           {row.acc_manager_email}
    //           <FileCopyIcon
    //             style={createIconStyling}
    //             onClick={(e) => copyToClipboard(row.acc_manager_email)}
    //           />
    //         </span>
    //       ) : null}
    //       {/*
    //       {row.user_email && row.alternate_email ? '/ ' : ''}
    //       {
    //         row.alternate_email
    //           ?
    //           <span>
    //             {row.alternate_email}
    //             <FileCopyIcon style={createIconStyling} onClick={e => copyToClipboard(row.alternate_email)} />
    //           </span>
    //           : null
    //       } */}
    //     </div>
    //   ),
    // },

    /*   {
      title: "Minimum_CTC",
      field: "min_ctc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterComponent: (props) => (
        <Slider
          style={{ zIndex: "10" }}
          value={props.columnDef.tableData.filterValue || [0, 2000000]}
          defaultValue={[0, 2000000]}
          onChange={(e, val) => handleChange(val, props)}
          min={0}
          max={2000000}
          step={50000}
          marks={ctcLabel}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={printNumber}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.min_ctc >= term[0] && rowData.min_ctc <= term[1],
      render: (row) => (row.min_ctc ? printNumber(row.min_ctc) : null),
    },
    {
      title: "Last_Login",
      field: "last_login",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
    },

    {
      title: "GetWork_Domain",
      field: "domain",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Passcode",
      field: "passcode",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Onboard_Date",
      field: "onboard_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "date",
      searchable: false,
      filterPlaceholder: "Open Calendar",
      customFilterAndSearch: (term, rowData) =>
        new Date(term).toISOString().substring(0, 7) ===
        rowData.onboard_date.substring(0, 7),
    },
    {
      title: "Last_Updated",
      field: "update_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
    },
    // No. of Students in final year
    {
      title: "Final Year Students",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <Button
            variant="outline-info"
            size="sm"
            onClick={() =>
              window.open(`/admin/college/final-year/${row.college_id}`)
            }
          >
            {" "}
            View
          </Button>
        </div>
      ),
    },
    // No. of Jobs sent
    {
      title: "Jobs Sent",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <Button
            variant="outline-info"
            size="sm"
            onClick={() =>
              window.open(`/admin/college/jobs-sent/${row.college_id}`)
            }
          >
            {" "}
            View
          </Button>
        </div>
      ),
    }, */
    /*     {
      title: "Internship Availability",
      field: "internship",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "Full-time Availability",
      field: "fulltime",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "Immediate Joining",
      field: "immediate",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    },
    {
      title: "SAAS College",
      field: "is_saas",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Yes: "Yes", No: "No" },
      searchable: false,
      filterPlaceholder: "Yes/No",
    }, */
    {
      title: "Status",
      field: "is_blocked",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      export: false,
      lookup: { No: "Block", Yes: "Unblock" },
      searchable: false,
      filterPlaceholder: "Select",
      render: (row) => (
        <div>
          {row.is_blocked === "No" ? (
            <Button
              variant="outline-danger"
              size="sm"
              onClick={(e) => blockUnblock(e, row)}
              style={{
                fontSize: 13,
              }}
            >
              Block
            </Button>
          ) : (
            <Button
              variant="outline-success"
              size="sm"
              onClick={(e) => blockUnblock(e, row)}
              style={{
                fontSize: 13,
              }}
            >
              Unblock
            </Button>
          )}
        </div>
      ),
    },
  ];

  let col = [...columns];
  col.push({
    title: "Excel Uploaded?",
    field: "is_admin_created",
    headerStyle: headerCSS,
    cellStyle: cellCSS,
    lookup: { Yes: "Yes", No: "No" },
    searchable: false,
    filterPlaceholder: "Yes/No",
  });

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
    }
  };

  const removeDateFilter = () => {
    setDateFiltersRemoved(true);
    setStartDate(null);
    setEndDate(null);
  };

  const handleClickOpen = () => {
    setOpenAdvanceFilterDialog(true);
    // setFilterCount(1);
  };

  const handleClose = () => {
    setSelectedJobSegments([]);
    setMinCtc(null);
    setMinStipend(null);
    setOpenAdvanceFilterDialog(false);
  };

  const [openAdvanceFilterDialog, setOpenAdvanceFilterDialog] =
    React.useState(false);

  const [advanceFilterDateValue, setAdvanceFilterDateValue] =
    useState(dateRange);

  const handleSubmit = (e) => {
    setFilterCount(1);
    e.preventDefault();
    if (!advanceFilterDateValue[0] || !advanceFilterDateValue[1]) {
      FailureAlert("Date range not selected correctly!!");
      return;
    }
    let urlFilterStr = ``;
    let AdvancedfiltersCount = 1;
    if (selectedJobSegments.length > 0) {
      let jobSegemntStr = "";
      selectedJobSegments.map((segment) => (jobSegemntStr += `${segment.id},`));
      urlFilterStr += `&job_segment_id=${jobSegemntStr.replace(/,*$/, "")}`;
      setFilterCount(++AdvancedfiltersCount);
      // console.log(urlFilterStr);
    }
    if (minCtc) {
      urlFilterStr += `&job_ctc=${minCtc}`;
      setFilterCount(++AdvancedfiltersCount);
    }

    if (minStipend) {
      urlFilterStr += `&internship_ctc=${minStipend}`;
      setFilterCount(++AdvancedfiltersCount);
    }

    /*   console.log(advanceFilterDateValue);
    console.log(selectedJobSegment);
    console.log(minStipend);
    console.log(minCtc); */

    urlFilterStr !== `` && setUrlFilter(urlFilterStr);
    applyDateFilter(advanceFilterDateValue);
  };

  return (
    <div className="all__colleges">
      <AppBar position="static" color="default">
        <Tabs
          value={userCreatedColleges}
          onChange={handleChangeToggle}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Not User Created" {...a11yProps(0)} />
          <Tab label="User Created" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      {loading ? (
        <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
      ) : userCreatedColleges === 0 ? (
        <TabPanel value={userCreatedColleges} index={0} dir={theme.direction}>
          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              variant="outline-success"
              size="sm"
              style={{
                //  marginRight: "1.5rem",
                //  position:"absolute",
                //  marginTop:"10px" ,
                //  zIndex:"100",
                width: "132px",
                //  marginLeft: "490px",
              }}
              onClick={(e) => handleClickOpen()}
            >
              Filter
              <span style={{ marginLeft: "5px" }}>{filterCount}</span>
            </Button>

            <AdvanceFiltersDialog
              open={openAdvanceFilterDialog}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              dateRange={[startDate, endDate]}
              applyDateFilter={applyDateFilter}
              removeDateFilter={removeDateFilter}
              allJobSegments={allJobSegments}
              selectedJobSegments={selectedJobSegments}
              setSelectedJobSegments={setSelectedJobSegments}
              minCtc={minCtc}
              setMinCtc={setMinCtc}
              minStipend={minStipend}
              setMinStipend={setMinStipend}
              handleSubmit={handleSubmit}
              setAdvanceFilterDateValue={setAdvanceFilterDateValue}
              advanceFilterDateValue={advanceFilterDateValue}
              setOpenAdvanceFilterDialog={setOpenAdvanceFilterDialog}
            />
            {/* <DateSelector
            dateRange={[startDate, endDate]}
            applyDateFilter={applyDateFilter}
            removeDateFilter={removeDateFilter}
          /> */}

            <Button
              style={{
                width: "12rem",
                height: "2.5rem",
              }}
              onClick={() => {
                setCanDownload(true);
                downloadAllDataCSV();
              }}
            >
              {loadingAllDataDownload ? (
                <CustomButtonCircularProgress />
              ) : (
                "Download All Data"
              )}
            </Button>
          </div>

          <TableWithExport
            title="All Colleges Not Created by User"
            data={noUserCreatedData}
            columns={col}
            fName="Selected_Colleges_Not_User_Created"
            //  selection = {false}
          />
        </TabPanel>
      ) : (
        <TabPanel value={userCreatedColleges} index={1} dir={theme.direction}>
          <br />
          {/* <DateSelector
            dateRange={[startDate, endDate]}
            applyDateFilter={applyDateFilter}
            removeDateFilter={removeDateFilter}
          /> */}
          <Button
            style={{
              width: "12rem",
              height: "2.5rem",
            }}
            onClick={() => {
              setCanDownload(true);
              downloadAllDataCSV();
            }}
          >
            {loadingAllDataDownload ? (
              <CustomButtonCircularProgress />
            ) : (
              "Download All Data"
            )}
          </Button>
          <TableWithExport
            title="All Colleges Created by User"
            data={userCreatedData}
            columns={columns}
            fName="Selected_Colleges_User_Created"
          />
        </TabPanel>
      )}
    </div>
  );
}

export default withRouter(AllColleges);
