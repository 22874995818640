import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ReactHtmlParser from "react-html-parser";
import crossIcon from "../../../assets/images/cancel.png";
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    crossStyle: {
        width: "10px",
        height: "10px",
        cursor: "pointer",
        margin: '2rem',

    },
    crossSection: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    templateTitleText: {
        marginLeft: '1rem',
    }

}))



const DisplayHtml = ({ open, setOpen, htmlContent, templateHeading }) => {

    const classes = useStyles()

    const scroll = "paper";

    const handleClose = () => {
        setOpen(false);

    };


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <section className={classes.crossSection}>
                    <Typography className={classes.templateTitleText}>
                        {templateHeading}
                    </Typography>
                    <img
                        className={classes.crossStyle}
                        src={crossIcon}

                        onClick={() => { handleClose(); }}
                        alt="close Icon" />
                </section>

                <DialogContent dividers={scroll === "paper"}>

                    {ReactHtmlParser(htmlContent.toString("html"))}
                </DialogContent>
                <DialogActions>

                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default DisplayHtml