import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useRef, useState } from "react";
import {
  PRASHASAN_CLIENT_SCREENING_REJECT,
  PRASHASAN_GW_SCREENING_REJECTED,
  PRASHASAN_INTERVIEW_REJECTED,
  PRASHASAN_INVOICE_INPROCESS,
  PRASHASAN_INVOICE_PAYMENT_RECEIVED,
  PRASHASAN_INVOICE_RAISED,
  PRASHASAN_JOINING_IN_PROCESS,
  PRASHASAN_OFFER_IN_PROCESS,
  userType,
  userTypeName,
} from "../../constants";
import "../../css/JobTrack.css";
import {
  apiNiyuktiCall,
  niyuktiAxiosGetReq,
  praveshAxiosPostReq,
} from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import JobTrackBulkApply from "../JobTrackBulkApply";
import CustomButtonCircularProgress from "../loader";
import JobTrackApplyJob from "./Dialog/JobTrackApplyJob";
import InterviewDialog from "./Interview/InterviewDialog";

const numberToWords = (num) => {
  if (!num) return "";

  const a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  // Function to convert integer part to words
  const getIntegerPart = (num) => {
    if (!num || isNaN(num)) return ""; // Return empty string for invalid inputs
    num = num.toString();
    if (num.length > 9) return "overflow";

    // Pad the number to ensure correct grouping
    const paddedNum = num.padStart(9, "0");

    // Match and group the number
    const n = paddedNum.match(/^(\d{2})(\d{2})(\d{3})(\d{2})$/);
    if (!n) return ""; // Return empty string if matching fails

    let str = "";
    str +=
      n[1] != "00"
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + " crore "
        : "";
    str +=
      n[2] != "00"
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + " lakh "
        : "";
    str +=
      n[3] != "000"
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + " thousand "
        : "";
    str +=
      n[4] != "00"
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + " "
        : "";

    return str.trim();
  };

  // Function to convert decimal part to words
  const getDecimalPart = (decimalPart) => {
    if (!decimalPart) return ""; // Return empty string if no decimal part

    // Convert each digit to word representation
    const decimalWords = decimalPart
      .split("")
      .map((digit) => a[digit])
      .join(" ");

    return "and " + decimalWords.trim() + " paise";
  };

  // Split number into integer and decimal parts
  const [integerPart, decimalPart] = num.toString().split(".");

  // Get words for integer part
  let words = getIntegerPart(integerPart);

  // Add words for decimal part if it exists
  if (decimalPart) {
    words += " " + getDecimalPart(decimalPart);
  }

  return words.trim();
};

function JobStatus({
  title,
  data,
  columns,
  job_id,
  currStatus,
  currRound,
  actionDropDownList,
  statusName,
  updateList,
  editableObject,
  currStatusId,
  currRoundId,
  setstepData,
  step,
  setShareMultiAssessementLoader,
  shareMultiAssessementLoader,
  pageNo,
  setPageNo,
  totalCount,
  setCallCountApi,
  setFilters,
  filters,
  jobInvoicingPeriod,
  callCountApi,
  // actionDropDownList
}) {
  const [SelectedID, setSelectedID] = useState([]);
  const [statusMoveId, setStatusMoveId] = useState(0);

  const [open, setOpen] = useState(false);
  const [openInterviewSchedule, setOpenInterviewSchedule] = useState(false);

  const [SelectedRowsData, setSelectedRowsData] = useState();

  const [rejectReason, setRejectReason] = useState("");
  const [offeredCTC, setOfferedCTC] = useState(null);
  const [hiringDate, setHiringDate] = useState(null);
  const [inDate, setInDate] = useState(null);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [taxableAmount, setTaxableAmount] = useState(null);
  const [gstAmount, setGstAmount] = useState(null);
  const [totalBillValue, setTotalBillValue] = useState(null);

  const [invoiceDate, setInvoiceDate] = useState(null);
  const [tds, setTds] = useState(null);
  const [amountRecieved, setAmountRecieved] = useState(null);
  const [netAmountRecieved, setNetAmountRecieved] = useState(null);
  const [paymentReceiveDate, setPaymentReceiveDate] = useState(null);

  const [pendingAmount, setPendingAmount] = useState(null);
  const [invoicePeriod, setInvoicePeriod] = useState(null);
  const [notes, setNotes] = useState(null);
  const [payout, setPayout] = useState(null);
  const [payoutType, setPayoutType] = useState(null);
  const [state, setState] = React.useState({
    flat: false,
  });
  const [paymentType, setPaymentType] = useState(null);

  const [isPaymentAlreadyInitiated, setIsPaymentAlreadyInitiated] =
    useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);

  const [percent, setPercecnt] = useState(null);

  const [joiningDate, setJoiningDate] = useState(null);

  const [isValidate, setIsValidate] = useState(true);

  const tableRef = useRef();

  useEffect(() => {
    setGstAmount(((taxableAmount * 18) / 100).toFixed(2));
  }, [taxableAmount]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickInterviewOpen = () => {
    setOpenInterviewSchedule(true);
  };

  const handleInterviewClose = () => {
    setOpenInterviewSchedule(false);
  };
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleFile = (e) => {
    const res = e.target.files[0];
    setFile(res);
    if (res) {
      setLoading(true);
      const payload = new FormData();
      payload.append("invoice_file", res);
      praveshAxiosPostReq("/api/admin/upload/generate_invoice_link/ ", payload)
        .then((res) => {
          setLoading(false);
          if (res?.data?.success) {
            // setPhdDegreeOption(json.data);
            SuccessAlert(res?.data?.data?.message);
            setLink(res?.data?.data?.invoice_link);
          } else {
            FailureAlert(res?.data?.error);
          }
        })
        .catch((err) => {
          FailureAlert("Something went wrong");
          setLoading(false);
        });
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };
  const onUploadResumeClicked = () => {
    inputRef.current.click();
  };

  const handleHiringDate = (date) => {
    let alert = "";
    SelectedID.forEach((student) => {
      const joiningDate = new Date(student.joining_date);
      const joiningDateWithoutTime = new Date(
        joiningDate.getFullYear(),
        joiningDate.getMonth(),
        joiningDate.getDate()
      );
      const selectedDateWithoutTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      if (
        student.joining_date &&
        joiningDateWithoutTime < selectedDateWithoutTime
      ) {
        alert += `Student ${student.applicant_name} have Joining date less than selected Hiring date \\n`;
      }
    });
    if (alert) {
      FailureAlert(alert);
      setHiringDate(null);
      setIsValidate(false);
      return;
    }
    if (joiningDate) {
      const joiningDateWithoutTime = new Date(
        joiningDate.getFullYear(),
        joiningDate.getMonth(),
        joiningDate.getDate()
      );
      const selectedDateWithoutTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      if (
        joiningDateWithoutTime &&
        joiningDateWithoutTime < selectedDateWithoutTime
      ) {
        FailureAlert("Hiring date can't be greater than Joining date");
        setHiringDate(null);
        setIsValidate(false);
        return;
      }
    }
    setHiringDate(date);
  };

  const handleJoiningDate = (date) => {
    let alert = "";

    SelectedID.forEach((student) => {
      if (student.hiring_date == null) {
        alert += `Student ${student.applicant_name} has not hired yet\n`;
      }
    });
    if (alert) {
      FailureAlert(alert);
      // setHiringDate(null);
      setJoiningDate(null);
      setIsValidate(false);
      return;
    }
    SelectedID.forEach((student) => {
      const hiringDate = new Date(student.hiring_date);
      const hiringDateWithoutTime = new Date(
        hiringDate.getFullYear(),
        hiringDate.getMonth(),
        hiringDate.getDate()
      );
      const selectedDateWithoutTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      if (
        student.hiring_date &&
        hiringDateWithoutTime > selectedDateWithoutTime
      ) {
        alert += `Student ${student.applicant_name} have Hiring date greater than selected Joining date \n`;
      }
    });
    if (alert) {
      FailureAlert(alert);
      // setHiringDate(null);
      setJoiningDate(null);
      setIsValidate(false);
      return;
    }
    if (hiringDate) {
      const hiring_date = new Date(hiringDate);
      const hiringDateWithoutTime = new Date(
        hiring_date.getFullYear(),
        hiring_date.getMonth(),
        hiring_date.getDate()
      );
      const selectedDateWithoutTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      if (
        hiringDateWithoutTime &&
        hiringDateWithoutTime > selectedDateWithoutTime
      ) {
        FailureAlert("Joining date can't be less than Hiring date");
        setJoiningDate(null);
        setIsValidate(false);
        return;
      }
    }
    setJoiningDate(date);
  };

  let statusCodes = {};
  let statusIds = {};

  Object.keys(actionDropDownList).forEach((step) => {
    statusCodes[step] = actionDropDownList[step]["tooltip"];
    statusIds[step] = actionDropDownList[step]["status_id"];
  });

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 100,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const formatRupees = (value) => {
    if (value === null || value === undefined) return value;

    const valueString = value.toString();

    const [integerPart, decimalPart] = valueString.split(".");

    const cleanedIntegerPart = integerPart.replace(/[^0-9]/g, "");

    let lastThree = cleanedIntegerPart.substring(cleanedIntegerPart.length - 3);
    let otherNumbers = cleanedIntegerPart.substring(
      0,
      cleanedIntegerPart.length - 3
    );
    if (otherNumbers !== "") {
      lastThree = "," + lastThree;
    }
    const formattedIntegerPart =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    const formattedValue = decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;

    return formattedValue;
  };
  useEffect(() => {
    if (gstAmount && taxableAmount)
      setTotalBillValue(
        (parseFloat(gstAmount) + parseFloat(taxableAmount)).toFixed(2)
      );
    // else setTotalBillValue(null);
  }, [gstAmount, taxableAmount]);

  const handleSubmitAssessment = () => {
    if (SelectedID.length) {
      setShareMultiAssessementLoader(true);
      const userIds = SelectedID.map((obj) => obj.user_id);
      niyuktiAxiosGetReq(
        `/job/admin/send_assessment_to_candidate/?job_id=${job_id}&candidate_id=${userIds.join(
          ","
        )}`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert(res.data?.data?.message);
          } else {
            FailureAlert(res.data?.error);
          }
          setShareMultiAssessementLoader(false);
        })
        .catch((err) => {
          FailureAlert(err.message);
          setShareMultiAssessementLoader(false);
        });
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    // if (payoutType?.value === 1 || payoutType?.value === 0)
    //   setTaxableAmount("");
    if (payoutType?.value === 0 && offeredCTC && percent)
      setTaxableAmount(
        (parseFloat(offeredCTC) * (parseFloat(percent) / 100)).toFixed(2)
      );
  }, [percent, offeredCTC, payoutType]);

  // useEffect(() => {
  //   if (totalBillValue && tds)
  //     setAmountRecieved(parseInt(totalBillValue) - parseInt(tds));
  // }, [tds, totalBillValue]);

  useEffect(() => {
    if (netAmountRecieved && amountRecieved) {
      setPendingAmount(
        parseFloat(netAmountRecieved) - parseFloat(amountRecieved)
      );
    } else if (netAmountRecieved == "" || amountRecieved == "") {
      setPendingAmount("");
    }
  }, [netAmountRecieved, amountRecieved]);

  useEffect(() => {
    if (totalBillValue && tds)
      setNetAmountRecieved(parseInt(totalBillValue) - parseInt(tds));
    else if (totalBillValue == "" || tds == "") setNetAmountRecieved("");
  }, [tds, totalBillValue]);

  // Move to next step
  const [actionButtons, setActionButtons] = useState([]);

  useEffect(() => {
    setActionButtons([]);
    setActionButtons((prev) => [
      ...prev,
      {
        position: "toolbarOnSelect",
        icon: () => (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitAssessment}
            disabled={shareMultiAssessementLoader}
            style={{ width: "200px", height: "36px" }}
          >
            {shareMultiAssessementLoader ? (
              <CustomButtonCircularProgress />
            ) : (
              "Share Assessment"
            )}
          </Button>
        ),
      },
    ]);

    setActionButtons((prev) => [
      ...prev,
      {
        position: "toolbarOnSelect",
        icon: () => (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            disabled={SelectedID?.length > 0 ? false : true}
          >
            Apply
          </Button>
        ),
      },
    ]);

    setActionButtons((prev) => [
      ...prev,
      {
        position: "toolbarOnSelect",
        icon: () => (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickInterviewOpen}
            // disabled={SelectedID?.length > 0 ? false : true}
          >
            Schedule Interview
          </Button>
        ),
      },
    ]);

    setActionButtons((prev) => [
      ...prev,
      {
        position: "toolbarOnSelect",

        icon: () => (
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="status-native-label-placeholder">
              Choose Status:
            </InputLabel>
            <NativeSelect
              onChange={(e) => {
                const status = e.target.value;
                if (
                  SelectedID.length > 1 &&
                  (status === "(18)" || status === "(22)" || status === "(29)")
                ) {
                  FailureAlert("Can't Move multiple students.");
                  setStatusMoveId(0);
                  return;
                }
                setStatusMoveId(e.target.value);
              }}
              value={statusMoveId}
            >
              <option value={""}>SELECT</option>
              {Object.keys(statusCodes).map((st, k) => {
                return <option value={st}>{statusCodes[st]}</option>;
              })}
            </NativeSelect>
          </FormControl>
        ),
      },
    ]);

    // Reject Reason
    (statusCodes[statusMoveId] === "Rejected" ||
      PRASHASAN_CLIENT_SCREENING_REJECT.includes(
        parseInt(statusIds[statusMoveId])
      ) ||
      PRASHASAN_GW_SCREENING_REJECTED.includes(
        parseInt(statusIds[statusMoveId])
      ) ||
      PRASHASAN_INTERVIEW_REJECTED.includes(
        parseInt(statusIds[statusMoveId])
      )) &&
      setActionButtons((prev) => [
        ...prev,
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.textField}
                id="reject-reason"
                label="Reason To Reject"
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </FormControl>
          ),
        },
      ]);

    (statusCodes[statusMoveId] === "Offered" ||
      PRASHASAN_JOINING_IN_PROCESS.includes(
        parseInt(statusIds[statusMoveId])
      )) &&
      setActionButtons((prev) => [
        ...prev,
        // {
        //   position: "toolbarOnSelect",
        //   icon: () => (
        //     <FormControl className={classes.formControl}>
        //       <TextField
        //         className={classes.textField}
        //         id="reject-reason"
        //         label="Offered CTC(in Rs.)"
        //         type="text"
        //         value={offeredCTC}
        //         onChange={(e) => {const inputValue = e.target.value;
        // Remove commas for internal state
        // const rawValue = inputValue.replace(/,/g, '');
        // setOfferedCTC(rawValue);}}
        //         // onChange={(e) => {
        //         //   const newValue = e.target.value;
        //         //   setOfferedCTC(newValue);
        //         //   // {const inputValue = e.target.value;
        // Remove commas for internal state
        // const rawValue = inputValue.replace(/,/g, '');
        // setOfferedCTC(rawValue);}

        //         // }}
        //       />
        //     </FormControl>
        //   ),
        // },
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              <div
                style={{
                  // marginBottom: !joiningDate && !hiringDate ? "10px" : 0,
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Selection Date"
                      value={hiringDate}
                      onChange={handleHiringDate}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {hiringDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setHiringDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div
                style={{
                  // marginBottom: !joiningDate && !hiringDate ? "10px" : 0,
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Joining Date"
                      value={joiningDate}
                      onChange={handleJoiningDate}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {joiningDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setJoiningDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            </FormControl>
          ),
        },
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              {/* <Grid>
                  <div> */}
              <div
                style={{
                  margin: "5px",
                }}
              >
                {/* <Typography variant="body2">Flat Type</Typography>
                <input
                  type="checkbox"
                  value={state.flat}
                  name="flat"
                  onChange={handleChange}
                /> */}
                <Autocomplete
                  options={payoutTypes}
                  getOptionLabel={(item) => item.name}
                  value={payoutType}
                  onChange={(e, item) => {
                    setPayoutType(item);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payout type"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {payoutType?.value === 0 && (
                <div
                  style={{
                    margin: "5px",
                  }}
                >
                  {/* <TextField
                    variant="outlined"
                    label="Percentage(%)"
                    type="text"
                    value={percent}
                    disabled={!payoutType}
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value);
                      if (
                        e.target.value === "" ||
                        (!isNaN(inputValue) &&
                          inputValue <= 100 &&
                          inputValue >= 0)
                      ) {
                        setPercecnt(inputValue);
                      }
                    }}
                    inputProps={{ max: 100, min: 0 }} // Specify the max attribute for the input element
                  /> */}
                  <TextField
                    variant="outlined"
                    label="Percentage(%)"
                    type="text"
                    value={percent}
                    disabled={!payoutType}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const floatRegex = /^\d*\.?\d{0,2}$/;

                      if (
                        inputValue === "" ||
                        (floatRegex.test(inputValue) &&
                          parseFloat(inputValue) <= 100.0 &&
                          parseFloat(inputValue) >= 0)
                      ) {
                        setPercecnt(inputValue);
                      }
                    }}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </div>
              )}
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Offered CTC"
                  type="text"
                  value={formatRupees(offeredCTC)}
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setOfferedCTC(rawValue);
                  }}

                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   if (!isNaN(newValue) && newValue <= 100000) {
                  //     setOfferedCTC(newValue);
                  //   }
                  // }}
                  // InputProps={{
                  //   inputProps: { min: 0, max: 100000 },
                  // }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Taxable Amount"
                  type="text"
                  disabled={payoutType?.value === 0 || !payoutType}
                  value={formatRupees(taxableAmount)}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setTaxableAmount(rawValue);
                  }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Invoice Period(days)"
                  type="text"
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  value={invoicePeriod}
                  onChange={(e) => setInvoicePeriod(e.target.value)}
                />
              </div>
              {/* <div
                style={{
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Invoicing Date"
                      value={inDate}
                      onChange={(date) => setInDate(date)}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {inDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setInDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div> */}
              {/* <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Payout"
                  type={"number"}
                  value={payout}
                  onChange={(e) => setPayout(e.target.value)}
                />
              </div> */}

              {/* </div> */}
              {/* </Grid>
              </div> */}
            </FormControl>
          ),
        },
      ]);

    (statusCodes[statusMoveId] === "Hired" ||
      PRASHASAN_OFFER_IN_PROCESS.includes(parseInt(statusIds[statusMoveId]))) &&
      setActionButtons((prev) => [
        ...prev,
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              <div
                style={{
                  // marginBottom: !joiningDate && !hiringDate ? "10px" : 0,
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Selection Date"
                      value={hiringDate}
                      onChange={handleHiringDate}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {hiringDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setHiringDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div
                style={{
                  // marginBottom: !joiningDate && !hiringDate ? "10px" : 0,
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Joining Date"
                      value={joiningDate}
                      onChange={handleJoiningDate}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {joiningDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setJoiningDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            </FormControl>
          ),
        },
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              {/* <Grid>
                  <div> */}
              <div
                style={{
                  margin: "5px",
                }}
              >
                {/* <Typography variant="body2">Flat Type</Typography>
                <input
                  type="checkbox"
                  value={state.flat}
                  name="flat"
                  onChange={handleChange}
                /> */}
                <Autocomplete
                  options={payoutTypes}
                  getOptionLabel={(item) => item.name}
                  value={payoutType}
                  onChange={(e, item) => {
                    setPayoutType(item);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payout type"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {payoutType?.value === 0 && (
                <div
                  style={{
                    margin: "5px",
                  }}
                >
                  {/* <TextField
                    variant="outlined"
                    label="Percentage(%)"
                    type="text"
                    value={percent}
                    disabled={!payoutType}
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value);
                      if (
                        e.target.value === "" ||
                        (!isNaN(inputValue) &&
                          inputValue <= 100 &&
                          inputValue >= 0)
                      ) {
                        setPercecnt(inputValue);
                      }
                    }}
                    inputProps={{ max: 100, min: 0 }} // Specify the max attribute for the input element
                  /> */}
                  <TextField
                    variant="outlined"
                    label="Percentage(%)"
                    type="text"
                    value={percent}
                    disabled={!payoutType}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const floatRegex = /^\d*\.?\d{0,2}$/;

                      if (
                        inputValue === "" ||
                        (floatRegex.test(inputValue) &&
                          parseFloat(inputValue) <= 100.0 &&
                          parseFloat(inputValue) >= 0)
                      ) {
                        setPercecnt(inputValue);
                      }
                    }}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </div>
              )}
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Offered CTC"
                  type="text"
                  value={formatRupees(offeredCTC)}
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setOfferedCTC(rawValue);
                  }}

                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   if (!isNaN(newValue) && newValue <= 100000) {
                  //     setOfferedCTC(newValue);
                  //   }
                  // }}
                  // InputProps={{
                  //   inputProps: { min: 0, max: 100000 },
                  // }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Taxable Amount"
                  type="text"
                  disabled={payoutType?.value === 0 || !payoutType}
                  value={formatRupees(taxableAmount)}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setTaxableAmount(rawValue);
                  }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Invoice Period(days)"
                  type="text"
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  value={invoicePeriod}
                  onChange={(e) => setInvoicePeriod(e.target.value)}
                />
              </div>
              {/* <div
                style={{
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Invoicing Date"
                      value={inDate}
                      onChange={(date) => setInDate(date)}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {inDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setInDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div> */}
              {/* <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Payout"
                  type={"number"}
                  value={payout}
                  onChange={(e) => setPayout(e.target.value)}
                />
              </div> */}

              {/* </div> */}
              {/* </Grid>
              </div> */}
            </FormControl>
          ),
        },
      ]);

    PRASHASAN_INVOICE_RAISED.includes(parseInt(statusIds[statusMoveId])) &&
      //   setActionButtons((prev) => [
      //     ...prev,
      //     {
      //       position: "toolbarOnSelect",
      //       icon: () => (
      //         <>
      //           <div>
      //             <div>
      //               <div>
      //                 <input
      //                   ref={inputRef}
      //                   type="file"
      //                   onChange={handleFile}
      //                   style={{ display: "none" }}
      //                   accept=".doc,.docx,.pdf"
      //                 />
      //               </div>
      //             </div>
      //             <div
      //               style={{
      //                 paddingBottom: 20,
      //                 display: "flex",
      //                 gap: "1rem",
      //                 flexDirection: "column",
      //               }}
      //             >
      //               <MuiPickersUtilsProvider utils={DateFnsUtils}>
      //                 <Grid
      //                   style={{
      //                     display: "flex",
      //                     flexDirection: "row",
      //                     // marginTop: hiringDate ? "16px" : "10px",
      //                   }}
      //                 >
      //                   <KeyboardDatePicker
      //                     // disableToolbar
      //                     variant="outlined"
      //                     size="small"
      //                     openTo="year"
      //                     views={["year", "month", "date"]}
      //                     format="dd/MM/yyyy"
      //                     inputVariant="outlined"
      //                     id="date-picker-inline"
      //                     // defaultValue="MM/YYYY"
      //                     label="Invoice Date"
      //                     value={invoiceDate}
      //                     onChange={(date) => setInvoiceDate(date)}
      //                     InputLabelProps={{
      //                       className: classes.floatingLabelFocusStyle,
      //                     }}
      //                     style={{ width: "80%" }}
      //                     KeyboardButtonProps={{
      //                       "aria-label": "change date",
      //                     }}
      //                     InputProps={{
      //                       className: classes.input,
      //                     }}
      //                   />
      //                   {invoiceDate && (
      //                     <IconButton
      //                       aria-label="delete"
      //                       onClick={() => setInvoiceDate(null)}
      //                     >
      //                       <DeleteIcon />
      //                     </IconButton>
      //                   )}
      //                 </Grid>
      //               </MuiPickersUtilsProvider>
      //               <TextField
      //                 variant="outlined"
      //                 label="Taxable amount"
      //                 type="text"
      //                 value={formatRupees(taxableAmount)}
      //                 onChange={(e) =>{const inputValue = e.target.value;
      // Remove commas for internal state
      // const rawValue = inputValue.replace(/,/g, ""); setTaxableAmount(e.target.value)}}
      //               />
      //               <TextField
      //                 variant="outlined"
      //                 label="GST Amount"
      //                 type="text"
      //                 value={formatRupees(gstAmount)}
      //                 disabled
      //                 // onChange={(e) => setGstAmount()}
      //               />
      //               <TextField
      //                 InputLabelProps={{ shrink: true }}
      //                 variant="outlined"
      //                 label="Total Bill Value"
      //                 type="text"
      //                 value={formatRupees(totalBillValue)}
      //                 disabled
      //                 // onChange={(e) =>
      //                 //   setTotalBillValue(int(gstAmount) + int(taxableAmount))
      //                 // }
      //               />

      //               {!file ? (
      //                 <Button
      //                   variant="outlined"
      //                   color="primary"
      //                   onClick={onUploadResumeClicked}
      //                 >
      //                   {!loading ? (
      //                     "Click to Upload Your Invoice"
      //                   ) : (
      //                     <CircularProgress />
      //                   )}
      //                 </Button>
      //               ) : (
      //                 <Button
      //                   variant="outlined"
      //                   color="primary"
      //                   onClick={() => {
      //                     inputRef.current.click();
      //                     handleRemoveFile();
      //                   }}
      //                 >
      //                   {!loading ? "Re-Upload" : <CircularProgress />}
      //                 </Button>
      //               )}
      //             </div>
      //           </div>
      //         </>
      //       ),
      //     },
      //   ]
      // );
      setActionButtons((prev) => [
        ...prev,

        // {
        //   position: "toolbarOnSelect",
        //   icon: () => (
        //     <FormControl className={classes.formControl}>
        //       <TextField
        //         className={classes.textField}
        //         id="reject-reason"
        //         label="Offered CTC(in Rs.)"
        //         type="text"
        //         value={offeredCTC}
        //         onChange={(e) => {const inputValue = e.target.value;
        // Remove commas for internal state
        // const rawValue = inputValue.replace(/,/g, '');
        // setOfferedCTC(rawValue);}}
        //         // onChange={(e) => {
        //         //   const newValue = e.target.value;
        //         //   setOfferedCTC(newValue);
        //         //   // {const inputValue = e.target.value;
        // Remove commas for internal state
        // const rawValue = inputValue.replace(/,/g, '');
        // setOfferedCTC(rawValue);}

        //         // }}
        //       />
        //     </FormControl>
        //   ),
        // },
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              <div
                style={{
                  // marginBottom: !joiningDate && !hiringDate ? "10px" : 0,
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Selection Date"
                      value={hiringDate}
                      onChange={handleHiringDate}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {hiringDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setHiringDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div
                style={{
                  // marginBottom: !joiningDate && !hiringDate ? "10px" : 0,
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Joining Date"
                      value={joiningDate}
                      onChange={handleJoiningDate}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {joiningDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setJoiningDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            </FormControl>
          ),
        },
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              {/* <Grid>
                <div> */}
              <div
                style={{
                  margin: "5px",
                }}
              >
                {/* <Typography variant="body2">Flat Type</Typography>
              <input
                type="checkbox"
                value={state.flat}
                name="flat"
                onChange={handleChange}
              /> */}
                <Autocomplete
                  options={payoutTypes}
                  getOptionLabel={(item) => item.name}
                  value={payoutType}
                  onChange={(e, item) => {
                    setPayoutType(item);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payout type"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {payoutType?.value === 0 && (
                <div
                  style={{
                    margin: "5px",
                  }}
                >
                  {/* <TextField
                  variant="outlined"
                  label="Percentage(%)"
                  type="text"
                  value={percent}
                  disabled={!payoutType}
                  onChange={(e) => {
                    const inputValue = parseInt(e.target.value);
                    if (
                      e.target.value === "" ||
                      (!isNaN(inputValue) &&
                        inputValue <= 100 &&
                        inputValue >= 0)
                    ) {
                      setPercecnt(inputValue);
                    }
                  }}
                  inputProps={{ max: 100, min: 0 }} // Specify the max attribute for the input element
                /> */}
                  <TextField
                    variant="outlined"
                    label="Percentage(%)"
                    type="text"
                    value={percent}
                    disabled={!payoutType}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const floatRegex = /^\d*\.?\d{0,2}$/;

                      if (
                        inputValue === "" ||
                        (floatRegex.test(inputValue) &&
                          parseFloat(inputValue) <= 100.0 &&
                          parseFloat(inputValue) >= 0)
                      ) {
                        setPercecnt(inputValue);
                      }
                    }}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </div>
              )}
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Offered CTC"
                  type="text"
                  value={formatRupees(offeredCTC)}
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setOfferedCTC(rawValue);
                  }}

                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   if (!isNaN(newValue) && newValue <= 100000) {
                  //     setOfferedCTC(newValue);
                  //   }
                  // }}
                  // InputProps={{
                  //   inputProps: { min: 0, max: 100000 },
                  // }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Taxable Amount"
                  type="text"
                  disabled={payoutType?.value === 0 || !payoutType}
                  value={formatRupees(taxableAmount)}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setTaxableAmount(rawValue);
                  }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Invoice Period(days)"
                  type="text"
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  value={invoicePeriod}
                  onChange={(e) => setInvoicePeriod(e.target.value)}
                />
              </div>
              {/* <div
              style={{
                margin: "5px",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginTop: hiringDate ? "16px" : "10px",
                  }}
                >
                  <KeyboardDatePicker
                    // disableToolbar
                    variant="outlined"
                    size="small"
                    openTo="year"
                    views={["year", "month", "date"]}
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    // defaultValue="MM/YYYY"
                    label="Invoicing Date"
                    value={inDate}
                    onChange={(date) => setInDate(date)}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    style={{ width: "80%" }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                  {inDate && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => setInDate(null)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </MuiPickersUtilsProvider>
            </div> */}
              {/* <div
              style={{
                margin: "5px",
              }}
            >
              <TextField
                variant="outlined"
                label="Payout"
                type={"number"}
                value={payout}
                onChange={(e) => setPayout(e.target.value)}
              />
            </div> */}

              {/* </div> */}
              {/* </Grid>
            </div> */}
            </FormControl>
          ),
        },
        {
          position: "toolbarOnSelect",
          icon: () => (
            <>
              <div>
                <div>
                  <div>
                    <input
                      ref={inputRef}
                      type="file"
                      onChange={handleFile}
                      style={{ display: "none" }}
                      accept=".doc,.docx,.pdf"
                    />
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: 20,
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // marginTop: hiringDate ? "16px" : "10px",
                      }}
                    >
                      <KeyboardDatePicker
                        // disableToolbar
                        variant="outlined"
                        size="small"
                        openTo="year"
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        // defaultValue="MM/YYYY"
                        label="Invoice Date"
                        value={invoiceDate}
                        onChange={(date) => setInvoiceDate(date)}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        style={{ width: "80%" }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                      {invoiceDate && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => setInvoiceDate(null)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </MuiPickersUtilsProvider>
                  {/* <TextField
                    variant="outlined"
                    label="Taxable amount"
                    type="text"
                    value={formatRupees(taxableAmount)}
                    onChange={(e) =>{const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, ""); setTaxableAmount(e.target.value)}}
                  /> */}
                  <TextField
                    variant="outlined"
                    label="GST Amount"
                    type="text"
                    value={formatRupees(gstAmount)}
                    disabled
                    // onChange={(e) => setGstAmount()}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label="Total Bill Value"
                    type="text"
                    value={formatRupees(totalBillValue)}
                    // disabled
                    // onChange={(e) =>
                    //   setTotalBillValue(int(gstAmount) + int(taxableAmount))
                    // }
                    // helperText={numberToWords(totalBillValue)}
                  />

                  {!file ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={onUploadResumeClicked}
                    >
                      {!loading ? (
                        "Click to Upload Your Invoice"
                      ) : (
                        <CircularProgress />
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        inputRef.current.click();
                        handleRemoveFile();
                      }}
                    >
                      {!loading ? "Re-Upload" : <CircularProgress />}
                    </Button>
                  )}
                </div>
              </div>
            </>
          ),
        },
      ]);
    PRASHASAN_INVOICE_PAYMENT_RECEIVED.includes(
      parseInt(statusIds[statusMoveId])
    ) &&
      setActionButtons((prev) => [
        ...prev,
        {
          position: "toolbarOnSelect",
          icon: () => (
            <div
              style={{
                margin: "5px",
                width: "200px",
              }}
            >
              <Autocomplete
                options={paymentTypes}
                getOptionLabel={(item) => item.name}
                value={paymentType}
                onChange={(e, item) => {
                  setPaymentType(item);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Payment type"
                    variant="outlined"
                  />
                )}
                disabled={isPaymentAlreadyInitiated}
              />
            </div>
          ),
        },
        {
          position: "toolbarOnSelect",
          icon: () => (
            <>
              <div>
                <div
                  style={{
                    // paddingBottom: 20,
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                  }}
                >
                  {isPaymentCompleted ? (
                    <p style={{ color: "green", margin: 0 }}>
                      Payment completed
                    </p>
                  ) : (
                    <>
                      {paymentType?.value === 0 && (
                        <>
                          <TextField
                            variant="outlined"
                            label="Total Bill Value"
                            type="text"
                            value={formatRupees(totalBillValue)}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const rawValue = inputValue.replace(/,/g, "");
                              setTotalBillValue(rawValue);
                            }}
                          />
                          <TextField
                            variant="outlined"
                            label="TDS Amount"
                            type="text"
                            value={tds}
                            onChange={(e) => setTds(e.target.value)}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            label="Net Received in Bank"
                            type="text"
                            value={netAmountRecieved}
                            onChange={(e) =>
                              setNetAmountRecieved(e.target.value)
                            }
                          />

                          <TextField
                            variant="outlined"
                            label="Taxable Amount"
                            type="text"
                            value={formatRupees(taxableAmount)}
                            disabled
                            // onChange={(e) =>{const inputValue = e.target.value;
                            // Remove commas for internal state
                            // const rawValue = inputValue.replace(/,/g, ""); setTaxableAmount(e.target.value)}}
                          />

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // marginTop: hiringDate ? "16px" : "10px",
                              }}
                            >
                              <KeyboardDatePicker
                                // disableToolbar
                                variant="outlined"
                                size="small"
                                openTo="year"
                                views={["year", "month", "date"]}
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                id="date-picker-inline"
                                // defaultValue="MM/YYYY"
                                label="Payment Receive Date"
                                value={paymentReceiveDate}
                                onChange={(date) => setPaymentReceiveDate(date)}
                                InputLabelProps={{
                                  className: classes.floatingLabelFocusStyle,
                                }}
                                style={{ width: "80%" }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputProps={{
                                  className: classes.input,
                                }}
                              />
                              {paymentReceiveDate && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => setPaymentReceiveDate(null)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Grid>
                          </MuiPickersUtilsProvider>

                          <TextField
                            variant="outlined"
                            label="Notes"
                            type="text"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                          />
                        </>
                      )}
                      {paymentType?.value === 1 && (
                        <>
                          <TextField
                            variant="outlined"
                            label="Total Bill Value"
                            type="text"
                            value={formatRupees(totalBillValue)}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const rawValue = inputValue.replace(/,/g, "");
                              setTotalBillValue(rawValue);
                            }}
                          />
                          <TextField
                            variant="outlined"
                            label="TDS Amount"
                            type="text"
                            value={tds}
                            onChange={(e) => setTds(e.target.value)}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            label="Net Received"
                            type="text"
                            value={netAmountRecieved}
                            onChange={(e) =>
                              setNetAmountRecieved(e.target.value)
                            }
                          />

                          <TextField
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            label="Received Amount"
                            type="text"
                            value={amountRecieved}
                            onChange={(e) => setAmountRecieved(e.target.value)}
                          />

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // marginTop: hiringDate ? "16px" : "10px",
                              }}
                            >
                              <KeyboardDatePicker
                                // disableToolbar
                                variant="outlined"
                                size="large"
                                openTo="year"
                                views={["year", "month", "date"]}
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                id="date-picker-inline"
                                // defaultValue="MM/YYYY"
                                label="Payment Receive Date"
                                value={paymentReceiveDate}
                                onChange={(date) => setPaymentReceiveDate(date)}
                                InputLabelProps={{
                                  className: classes.floatingLabelFocusStyle,
                                }}
                                style={{ width: "80%" }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputProps={{
                                  className: classes.input,
                                }}
                              />
                              {paymentReceiveDate && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => setPaymentReceiveDate(null)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Grid>
                          </MuiPickersUtilsProvider>

                          <TextField
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            label="Pending Amount"
                            type="text"
                            value={pendingAmount}
                            disabled
                            // onChange={(e) => setPendingAmount(e.target.value)}
                          />

                          <TextField
                            variant="outlined"
                            label="Notes"
                            type="text"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ),
        },
      ]);

    (statusCodes[statusMoveId] === "Joined" ||
      PRASHASAN_INVOICE_INPROCESS.includes(
        parseInt(statusIds[statusMoveId])
      )) &&
      setActionButtons((prev) => [
        ...prev,
        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Selection Date"
                      value={hiringDate}
                      onChange={(date) => setHiringDate(date)}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {hiringDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setHiringDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Joining Date"
                      value={joiningDate}
                      onChange={(date) => setJoiningDate(date)}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {joiningDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setJoiningDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            </FormControl>
          ),
        },

        {
          position: "toolbarOnSelect",
          icon: () => (
            <FormControl className={classes.formControl}>
              {/* <Grid>
                  <div> */}
              <div
                style={{
                  margin: "5px",
                }}
              >
                {/* <Typography variant="body2">Flat Type</Typography>
                <input
                  type="checkbox"
                  value={state.flat}
                  name="flat"
                  onChange={handleChange}
                /> */}
                <Autocomplete
                  options={payoutTypes}
                  getOptionLabel={(item) => item.name}
                  value={payoutType}
                  onChange={(e, item) => {
                    setPayoutType(item);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payout type"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {payoutType?.value === 0 && (
                <div
                  style={{
                    margin: "5px",
                  }}
                >
                  {/* <TextField
                    variant="outlined"
                    label="Percentage(%)"
                    type="text"
                    value={percent}
                    disabled={!payoutType}
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value);
                      if (
                        e.target.value === "" ||
                        (!isNaN(inputValue) &&
                          inputValue <= 100 &&
                          inputValue >= 0)
                      ) {
                        setPercecnt(inputValue);
                      }
                    }}
                    inputProps={{ max: 100, min: 0 }} // Specify the max attribute for the input element
                  /> */}
                  <TextField
                    variant="outlined"
                    label="Percentage(%)"
                    type="text"
                    value={percent}
                    disabled={!payoutType}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const floatRegex = /^\d*\.?\d{0,2}$/;

                      if (
                        inputValue === "" ||
                        (floatRegex.test(inputValue) &&
                          parseFloat(inputValue) <= 100.0 &&
                          parseFloat(inputValue) >= 0)
                      ) {
                        setPercecnt(inputValue);
                      }
                    }}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </div>
              )}
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Offered CTC"
                  type="text"
                  value={formatRupees(offeredCTC)}
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setOfferedCTC(rawValue);
                  }}

                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   if (!isNaN(newValue) && newValue <= 100000) {
                  //     setOfferedCTC(newValue);
                  //   }
                  // }}
                  // InputProps={{
                  //   inputProps: { min: 0, max: 100000 },
                  // }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Taxable Amount"
                  type="text"
                  disabled={payoutType?.value === 0 || !payoutType}
                  value={formatRupees(taxableAmount)}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove commas for internal state
                    const rawValue = inputValue.replace(/,/g, "");
                    setTaxableAmount(rawValue);
                  }}
                />
              </div>
              <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Invoice Period(days)"
                  type="text"
                  disabled={
                    (payoutType?.value === 0 && percent === null) || !payoutType
                  }
                  value={invoicePeriod}
                  onChange={(e) => setInvoicePeriod(e.target.value)}
                />
              </div>
              {/* <div
                style={{
                  margin: "5px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: hiringDate ? "16px" : "10px",
                    }}
                  >
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="outlined"
                      size="small"
                      openTo="year"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      // defaultValue="MM/YYYY"
                      label="Invoicing Date"
                      value={inDate}
                      onChange={(date) => setInDate(date)}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ width: "80%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {inDate && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => setInDate(null)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div> */}
              {/* <div
                style={{
                  margin: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Payout"
                  type={"number"}
                  value={payout}
                  onChange={(e) => setPayout(e.target.value)}
                />
              </div> */}

              {/* </div> */}
              {/* </Grid>
              </div> */}
            </FormControl>
          ),
        },
      ]);

    setActionButtons((prev) => [
      ...prev,
      {
        position: "toolbarOnSelect",
        icon: () => (
          <Button
            variant="contained"
            color="primary"
            disabled={statusCodes[statusMoveId] === undefined}
          >
            Move
          </Button>
        ),

        tooltip:
          statusCodes[statusMoveId] === undefined
            ? ""
            : `Move Applications to ${statusCodes[statusMoveId]}!`,
        onClick: (e, rowData) => {
          // if (!isValidate) {
          //   FailureAlert("Please select valid Hiring/Joining Date");
          //   setIsValidate(true);
          //   return;
          // }
          if (statusCodes[statusMoveId] !== undefined) {
            //   FailureAlert("Choose a status to move Selected Applicants...");
            //   return;
            // }
            // if (statusCodes[statusMoveId] === "Rejected" && !rejectReason) {
            //   FailureAlert("Enter Reason to reject Applicant/s...");
            //   return;
            // }
            if (
              (statusCodes[statusMoveId] === "Rejected" ||
                PRASHASAN_CLIENT_SCREENING_REJECT.includes(
                  parseInt(statusIds[statusMoveId])
                ) ||
                PRASHASAN_GW_SCREENING_REJECTED.includes(
                  parseInt(statusIds[statusMoveId])
                ) ||
                PRASHASAN_INTERVIEW_REJECTED.includes(
                  parseInt(statusIds[statusMoveId])
                )) &&
              !rejectReason
            ) {
              FailureAlert("Enter Reason to reject Applicant/s...");
              return;
            }

            let updateRecords = [];
            rowData.forEach((rd) => {
              let stat_id = statusMoveId.split(",")[0].substring(1);
              if (statusMoveId.split(",").length === 1)
                stat_id = stat_id.slice(0, -1);
              let move_round_id = null;
              if (statusMoveId.split(",")[1] !== undefined) {
                move_round_id = statusMoveId.split(",")[1].slice(0, -1);
              }
              updateRecords.push({
                id: rd.applicant_id,
                round: move_round_id ? parseInt(move_round_id) : null,
                status: parseInt(stat_id),
                // feedback: null,
                user_id: rd.user_id,
                job_id: job_id,
                company_id: rd.company_id,
              });
            });

            let requestBody = { student_data: updateRecords };

            if (
              statusCodes[statusMoveId] === "Offered" ||
              PRASHASAN_JOINING_IN_PROCESS.includes(
                parseInt(statusIds[statusMoveId])
              )
            ) {
              if (!offeredCTC || !hiringDate) {
                FailureAlert(
                  "Please Enter Valid Offered CTC , Joining Date and Selection Date"
                );
                return;
              }
              if (
                payoutType &&
                hiringDate &&
                joiningDate &&
                offeredCTC &&
                invoicePeriod &&
                taxableAmount
              ) {
                if (offeredCTC < 100000) {
                  FailureAlert(
                    "Offered CTC must be greater or equal to 1 Lakh"
                  );
                  return;
                }
                if (hiringDate) {
                  const hiring_date = new Date(hiringDate);
                  requestBody.hiring_date = hiring_date
                    .toISOString()
                    .slice(0, 10);
                }
                if (joiningDate) {
                  const joining_date = new Date(joiningDate);

                  requestBody.joining_date = joining_date
                    .toISOString()
                    .slice(0, 10);
                }

                requestBody.offered_ctc = offeredCTC;
                requestBody.taxable_amount = taxableAmount;

                // requestBody.invoicing_date = inDate.toISOString().slice(0, 10);
                requestBody.invoicing_details = {
                  invoice_period: invoicePeriod,
                  payout_type: payoutType.name,
                  payout_value:
                    payoutType.value === 0 ? percent : taxableAmount,
                };
              } else {
                FailureAlert("Please enter the complete Invoice Details");
                return;
              }
              // if (offeredCTC) {
              //   if (offeredCTC < 100000) {
              //     FailureAlert(
              //       "Offered CTC must be greater or equal to 1 Lakh"
              //     );
              //     return;
              //   }
              //   requestBody.offered_ctc = offeredCTC;
              // }
              // if (hiringDate) {
              //   const hiring_date = new Date(hiringDate);
              //   requestBody.hiring_date = hiring_date
              //     .toISOString()
              //     .slice(0, 10);
              // }
              // if (joiningDate) {
              //   const joining_date = new Date(joiningDate);

              //   requestBody.joining_date = joining_date
              //     .toISOString()
              //     .slice(0, 10);
              // }
            }

            if (
              statusCodes[statusMoveId] === "Hired" ||
              PRASHASAN_OFFER_IN_PROCESS.includes(
                parseInt(statusIds[statusMoveId])
              )
            ) {
              if (!hiringDate) {
                FailureAlert("Please enter Selection Date.");
                return;
              }
              if (
                payoutType &&
                hiringDate &&
                // joiningDate &&
                offeredCTC &&
                invoicePeriod &&
                taxableAmount
              ) {
                if (offeredCTC < 100000) {
                  FailureAlert(
                    "Offered CTC must be greater or equal to 1 Lakh"
                  );
                  return;
                }
                if (hiringDate) {
                  const hiring_date = new Date(hiringDate);
                  requestBody.hiring_date = hiring_date
                    .toISOString()
                    .slice(0, 10);
                }
                if (joiningDate) {
                  const joining_date = new Date(joiningDate);

                  requestBody.joining_date = joining_date
                    .toISOString()
                    .slice(0, 10);
                }

                requestBody.offered_ctc = offeredCTC;
                requestBody.taxable_amount = taxableAmount;

                // requestBody.invoicing_date = inDate.toISOString().slice(0, 10);
                requestBody.invoicing_details = {
                  invoice_period: invoicePeriod,
                  payout_type: payoutType.name,
                  payout_value:
                    payoutType.value === 0 ? percent : taxableAmount,
                };
              } else {
                FailureAlert("Please enter the complete Invoice Details");
                return;
              }
              // if (hiringDate)
              //   requestBody.hiring_date = hiringDate.toISOString().slice(0, 10);
              // if (joiningDate) {
              //   requestBody.joining_date = joiningDate
              //     .toISOString()
              //     .slice(0, 10);
              // }
            }

            if (
              statusCodes[statusMoveId] === "Joined" ||
              PRASHASAN_INVOICE_INPROCESS.includes(
                parseInt(statusIds[statusMoveId])
              )
            ) {
              if (
                payoutType &&
                hiringDate &&
                joiningDate &&
                offeredCTC &&
                invoicePeriod &&
                taxableAmount
              ) {
                if (offeredCTC < 100000) {
                  FailureAlert(
                    "Offered CTC must be greater or equal to 1 Lakh"
                  );
                  return;
                }
                if (hiringDate) {
                  const hiring_date = new Date(hiringDate);
                  requestBody.hiring_date = hiring_date
                    .toISOString()
                    .slice(0, 10);
                }
                if (joiningDate) {
                  const joining_date = new Date(joiningDate);

                  requestBody.joining_date = joining_date
                    .toISOString()
                    .slice(0, 10);
                }

                requestBody.offered_ctc = offeredCTC;
                requestBody.taxable_amount = taxableAmount;

                // requestBody.invoicing_date = inDate.toISOString().slice(0, 10);
                requestBody.invoicing_details = {
                  invoice_period: invoicePeriod,
                  payout_type: payoutType.name,
                  payout_value:
                    payoutType.value === 0 ? percent : taxableAmount,
                };
              } else {
                FailureAlert("Please enter the complete Invoice Details");
                return;
              }
            }

            if (
              PRASHASAN_INVOICE_RAISED.includes(
                parseInt(statusIds[statusMoveId])
              )
            ) {
              if (
                link &&
                invoiceDate &&
                totalBillValue &&
                gstAmount &&
                taxableAmount &&
                payoutType &&
                hiringDate &&
                joiningDate &&
                offeredCTC &&
                invoicePeriod
              ) {
                if (offeredCTC < 100000) {
                  FailureAlert(
                    "Offered CTC must be greater or equal to 1 Lakh"
                  );
                  return;
                }
                if (hiringDate) {
                  const hiring_date = new Date(hiringDate);
                  requestBody.hiring_date = hiring_date
                    .toISOString()
                    .slice(0, 10);
                }
                if (joiningDate) {
                  const joining_date = new Date(joiningDate);

                  requestBody.joining_date = joining_date
                    .toISOString()
                    .slice(0, 10);
                }

                if (invoiceDate) {
                  const invoice_date = new Date(invoiceDate);
                  const formatted_invoice_date = invoice_date
                    .toISOString()
                    .slice(0, 10);
                  requestBody.invoicing_date = formatted_invoice_date;
                }
                requestBody.offered_ctc = offeredCTC;

                // requestBody.invoicing_date = inDate.toISOString().slice(0, 10);
                requestBody.invoicing_details = {
                  invoice_period: invoicePeriod,
                  payout_type: payoutType.name,
                  payout_value:
                    payoutType.value === 0 ? percent : taxableAmount,
                };

                requestBody.taxable_amount = taxableAmount;
                // requestBody.gst_amount = gstAmount;
                // requestBody.total_bill_value = totalBillValue;
                requestBody.invoice_link = link;
                requestBody.invoicing_details = {
                  ...SelectedID[0].invoicing_details,
                  gst_amount: gstAmount,
                  total_bill_value: totalBillValue,
                };
              } else {
                FailureAlert("Please enter the complete Invoice Details");
                return;
              }
            }

            if (
              PRASHASAN_INVOICE_PAYMENT_RECEIVED.includes(
                parseInt(statusIds[statusMoveId])
              )
            ) {
              if (
                !paymentType ||
                !tds ||
                !totalBillValue ||
                !netAmountRecieved ||
                !paymentReceiveDate
              ) {
                FailureAlert("Please enter the complete Invoice Details");
                return;
              } else {
                const payment_receive_date = new Date(paymentReceiveDate);

                const paymentReceiveDateFormated = payment_receive_date;

                requestBody.invoicing_details = {
                  ...(SelectedID[0]?.invoicing_details || {}),
                  total_bill_value: totalBillValue,
                  payment_details: {
                    ...(SelectedID[0]?.invoicing_details?.payment_details ||
                      {}),
                    payment_type: SelectedID[0]?.invoicing_details
                      ?.payment_details?.payment_type
                      ? SelectedID[0]?.invoicing_details?.payment_details
                          ?.payment_type
                      : paymentType.name,
                    payments: [
                      ...(SelectedID[0]?.invoicing_details?.payment_details
                        ?.payments || []),
                    ],
                  },
                };
                if (paymentType.value === 0) {
                  if (!taxableAmount) {
                    FailureAlert("Please enter the complete Invoice Details");
                    return;
                  }

                  if (
                    requestBody?.invoicing_details?.payment_details
                      ?.payments instanceof Array
                  ) {
                    requestBody.invoicing_details.payment_details.payments.push(
                      {
                        tds: tds,
                        net_amount_received: netAmountRecieved,
                        received_amount: netAmountRecieved,
                        date_of_receive: paymentReceiveDateFormated,
                        notes: notes,
                      }
                    );
                  } else {
                    requestBody.invoicing_details.payment_details.payments = [
                      {
                        tds: tds,
                        net_amount_received: netAmountRecieved,
                        received_amount: netAmountRecieved,
                        date_of_receive: paymentReceiveDateFormated,
                        notes: notes,
                      },
                    ];
                  }
                } else {
                  if (!amountRecieved || !pendingAmount) {
                    FailureAlert("Please enter the complete Invoice Details");
                    return;
                  }

                  if (
                    requestBody?.invoicing_details?.payment_details
                      ?.payments instanceof Array
                  ) {
                    requestBody.invoicing_details.payment_details.payments.push(
                      {
                        tds: tds,
                        net_amount_received: netAmountRecieved,
                        received_amount: amountRecieved,
                        date_of_receive: paymentReceiveDateFormated,
                        pending_amount: pendingAmount,
                        notes: notes,
                      }
                    );
                  } else {
                    requestBody.invoicing_details.payment_details.payments = [
                      {
                        tds: tds,
                        net_amount_received: netAmountRecieved,
                        received_amount: amountRecieved,
                        date_of_receive: paymentReceiveDateFormated,
                        pending_amount: pendingAmount,
                        notes: notes,
                      },
                    ];
                  }
                }
              }
            }

            if (
              statusCodes[statusMoveId] === "Rejected" ||
              PRASHASAN_CLIENT_SCREENING_REJECT.includes(
                parseInt(statusIds[statusMoveId])
              ) ||
              PRASHASAN_GW_SCREENING_REJECTED.includes(
                parseInt(statusIds[statusMoveId])
              ) ||
              PRASHASAN_INTERVIEW_REJECTED.includes(
                parseInt(statusIds[statusMoveId])
              )
            ) {
              let user_data = JSON.parse(localStorage.getItem("user-data"));
              let stat_id = statusMoveId.split(",")[0].substring(1);
              if (statusMoveId.split(",").length === 1)
                stat_id = stat_id.slice(0, -1);
              let feedback_obj = {};
              feedback_obj["user_id"] = user_data.id;
              feedback_obj["first_name"] = user_data.first_name || "";
              feedback_obj["last_name"] = user_data.last_name || "";
              feedback_obj["status_id"] = parseInt(stat_id);
              feedback_obj["status_name"] = statusCodes[statusMoveId];
              feedback_obj["feedback_name"] = rejectReason;
              feedback_obj["is_getwork_admin"] = true;
              feedback_obj["user_name"] = `${user_data.first_name || ""} ${
                user_data.last_name || ""
              }`;
              feedback_obj["create_time"] = new Date();
              requestBody.applicant_feedback = feedback_obj;
            }

            const requestUpdate = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify(requestBody),
            };
            apiNiyuktiCall("/job/admin/status_update/", requestUpdate).then(
              (json) => {
                if (json.success) {
                  SuccessAlert(json.data.message);
                  updateList();
                  setHiringDate(null);
                  setJoiningDate(null);
                  setStatusMoveId(0);
                  setCallCountApi(true);
                  setLink(null);
                  setFile(null);
                  setInDate(null);
                  setPayout(null);
                  setState({ ...state, flat: false });
                  setInvoicePeriod(null);
                  setLink(null);
                  setInvoiceDate(null);
                  setTotalBillValue(null);
                  setGstAmount(null);
                  setTaxableAmount(null);
                  setPaymentType(null);
                  setTds(null);
                  setNetAmountRecieved(null);
                  setPaymentReceiveDate(null);
                  setAmountRecieved(null);
                  setPendingAmount(null);
                  setPayoutType(null);
                  setOfferedCTC(null);
                  setInvoicePeriod(null);
                } else {
                  FailureAlert(json.error);
                  setHiringDate(null);
                  setJoiningDate(null);

                  setStatusMoveId(0);
                  setLink(null);
                  setFile(null);
                  setInDate(null);
                  setPayout(null);
                  setState({ ...state, flat: false });
                  setInvoicePeriod(null);
                }
              }
            );

            let user_data = JSON.parse(localStorage.getItem("user-data"));
            let stat_id = statusMoveId.split(",")[0].substring(1);
            if (statusMoveId.split(",").length === 1)
              stat_id = stat_id.slice(0, -1);
            const actionObject = {
              reviewer_user_id: user_data.id,
              reviewer_user_type_id: userType,
              reviewer_user_type_name: userTypeName,
              reviewer_first_name: user_data.first_name || "",
              reviewer_last_name: user_data.last_name || "",
              status_from_id: currStatus,
              status_to_id: parseInt(stat_id),
              status_from: statusName,
              status_to: statusCodes[statusMoveId],
            };
            let updateActions = [];
            rowData.forEach((rd) => {
              updateActions.push({
                applicant_id: rd.applicant_id,
                action_taken: actionObject,
              });
            });
            const requestUpdateAction = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({ action_taken: updateActions }),
            };
            apiNiyuktiCall(
              "/job/company/applicant_action/",
              requestUpdateAction
            ).then((json) => {
              if (json.success) updateList();
              else FailureAlert(json.error);
            });
          }
        },
      },
    ]);
  }, [
    SelectedID,
    statusMoveId,
    hiringDate,
    joiningDate,
    offeredCTC,
    rejectReason,
    shareMultiAssessementLoader,
    link,
    inDate,
    state,
    payout,
    invoicePeriod,
    loading,
    taxableAmount,
    gstAmount,
    totalBillValue,
    invoiceDate,
    tds,
    pendingAmount,
    payoutType,
    percent,
    paymentType,
    pendingAmount,
    netAmountRecieved,
    paymentReceiveDate,
    notes,
  ]);

  useEffect(() => {
    setStatusMoveId(0);
  }, [SelectedID]);

  return (
    <div className="job__status">
      <JobTrackApplyJob
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        SelectedID={SelectedID}
        setSelectedID={setSelectedID}
        clearSelection={clearSelection}
        //   setSelectedRowsData = {setSelectedRowsData}
      />
      <InterviewDialog
        open={openInterviewSchedule}
        studentData={SelectedID}
        setStudentData={clearSelection}
        title={title}
        jobId={job_id}
        round={currRound}
        currRoundId={currRoundId}
        statusName={statusName}
        currStatusId={currStatusId}
        handleClickOpen={handleClickInterviewOpen}
        handleClose={handleInterviewClose}
        setstepData={setstepData}
        step={step}
        selectedIDs={SelectedID}
      />

      <JobTrackBulkApply
        title={title + " " + statusName + " Applicants"}
        tableRef={tableRef}
        data={data}
        columns={columns}
        onSelectionChange={(data) => {
          setStatusMoveId(0);
          const dataWithId = data.map((obj, index) => ({ ...obj, id: index }));
          const selectedIDs = new Set(data);
          const selectedRowData = data.filter((rows) =>
            selectedIDs.has(rows.toString())
          );
          setSelectedRowsData(selectedIDs);
          setSelectedID(dataWithId);
          setHiringDate(dataWithId[0]?.hiring_date || null);
          setJoiningDate(dataWithId[0]?.joining_date || null);
          setOfferedCTC(dataWithId[0]?.offered_ctc || null);
          setTaxableAmount(dataWithId[0]?.taxable_amount || null);
          setInvoicePeriod(
            dataWithId[0]?.invoicing_details?.invoice_period ||
              jobInvoicingPeriod ||
              null
          );
          setPayoutType(
            dataWithId[0]?.invoicing_details?.payout_type == "Percentage"
              ? payoutTypes[0]
              : payoutTypes[1] || null
          );
          if (dataWithId[0]?.invoicing_details?.payout_type == "Percentage")
            setPercecnt(dataWithId[0]?.invoicing_details?.payout_value || null);
          // else if (dataWithId[0]?.invoicing_details?.payout_type == "Flat"){
          //   setTaxableAmount(dataWithId[0]?.invoicing_details?.payout_value || null);
          // }
          setTotalBillValue(
            dataWithId[0]?.invoicing_details?.total_bill_value || null
          );
          if (dataWithId[0]?.invoicing_details?.payment_details?.payment_type) {
            setPaymentType(
              dataWithId[0]?.invoicing_details?.payment_details
                ?.payment_type === "Full Amount"
                ? paymentTypes[0]
                : paymentTypes[1]
            );
            setIsPaymentAlreadyInitiated(true);
          } else {
            setPaymentType(null);
            setIsPaymentAlreadyInitiated(false);
          }

          if (dataWithId[0]?.invoicing_details?.payment_details?.payments) {
            if (
              dataWithId[0]?.invoicing_details?.payment_details
                ?.payment_type === "Full Amount"
            ) {
              if (
                dataWithId[0]?.invoicing_details?.payment_details?.payments
                  .length === 1
              ) {
                setIsPaymentCompleted(true);
              } else {
                setIsPaymentCompleted(false);
              }
            } else {
              if (
                dataWithId[0]?.invoicing_details?.payment_details?.payments
                  .length === 2
              ) {
                setIsPaymentCompleted(true);
              } else {
                setIsPaymentCompleted(false);
              }
            }
          } else {
            setIsPaymentCompleted(false);
          }
        }}
        editableObject={editableObject}
        actionArray={actionButtons}
        exportButton={false}
        exportAllData={false}
        paging={false}
        pageValue={10}
      />

      <section style={{ float: "right", marginTop: "1rem" }}>
        <Pagination
          page={pageNo || 1}
          onChange={(e, value) => {
            setPageNo(value);
          }}
          count={totalCount}
          color="primary"
        />
      </section>
    </div>
  );
}

export default JobStatus;

const payoutTypes = [
  {
    id: 0,
    name: "Percentage",
    value: 0,
  },
  {
    id: 1,
    name: "Flat",
    value: 1,
  },
];

const paymentTypes = [
  {
    id: 0,
    name: "Full Amount",
    value: 0,
  },
  {
    id: 1,
    name: "Partial Amount",
    value: 1,
  },
];
