import {
    Box, Button,

    Card,
    CardContent,
    TextField, Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/styles";
import React, { useState } from 'react';
import close from '../../../../assets/svg/Plan/Close.svg';
import tick from '../../../../assets/svg/Plan/Tick.svg';



const useStyles = makeStyles((theme) => ({

    planPrice: {

    }

}));


const CommonInputFieldDiv = styled(Card)(
    ({ theme }) => `
    // border: 1px solid #fff;
    background-color: ${"#141414"};
    border-radius: 15px;
    display: block;
    align-items:center;
    box-shadow: 0px 0px 4px 0px #0000004D;
    flex-wrap: wrap;
    position:relative;

  `
);


const arrow = {
    width: "0",
    height: "0",
    borderTop: "15px solid transparent",
    borderBottom: "15px solid transparent",
    borderLeft: "15px solid #fff",
    marginRight: "-14px",
    zIndex: "10",
};

const cardPricing = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
    // marginBottom: theme.spacing(2),
};

const plantable = {
    display: "flex",
    alignItems: "center ",
    marginBottom: "10px",
};

const tickIcon = <img src={tick} height="15px" all="tick"/>;
const closeIcon = <img src={close} height="15px" all="close"/>;

const tag = {
    background: '#007bff',
    display: "inline-block",
    color: "#fff",
    position: "relative",
    padding: "5px",
    textDecoration: "none",
};


const SinglePlan = ({
    heading,
    monthlyPrice,
    annualPrice,
    index,
    benefits,
    showButton = true,
    alignment,
    handleForward, setPlanDetails, planDetails
}) => {

    const [onHover, setOnHover] = useState(false);
    const classes = useStyles()

    function selectPlan() {

        handleForward("planSelected");
        let tempPlans = planDetails?.plan_list;

        for (let i in tempPlans) {

            if (parseInt(i) === index) {

                let newPlanSelected = tempPlans[index];
                newPlanSelected = { ...newPlanSelected, selected: true };
                tempPlans?.splice(index, 1, newPlanSelected);


            }
            else {

                let newPlanSelected = tempPlans[i];
                newPlanSelected = { ...newPlanSelected, selected: false };
                tempPlans?.splice(i, 1, newPlanSelected);
            }
        };

        setPlanDetails((prevData) => ({ ...prevData, plan_list: tempPlans }));

    };

    const handlePlanAmountChange = (value) => {

        let tempPlans = planDetails?.plan_list;

        for (let i in tempPlans) {

            if (parseInt(i) === index) {

                let newPlanSelected = tempPlans[index];

                if (alignment === "monthly") {

                    newPlanSelected = { ...newPlanSelected, monthly_pricing: parseInt(value) };
                    tempPlans?.splice(index, 1, newPlanSelected);

                } else {

                    newPlanSelected = { ...newPlanSelected, annual_pricing: parseInt(value) };
                    tempPlans?.splice(index, 1, newPlanSelected);

                }
            }

        };

        setPlanDetails((prevData) => ({ ...prevData, plan_list: tempPlans }));
    }


    return (
        <>
            <CommonInputFieldDiv
            // style={cardSelected === index && showButton ? cardSelectedStyle : card}
            >
                <Box
                    style={{
                        border: `${onHover ? '2px solid #007bff' : '2px solid #DFE8F3'}`,
                        position: "relative",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        borderBottomLeftRadius: showButton ? "0px" : "15px",
                        borderBottomRightRadius: showButton ? "0px" : "15px",

                        "&:hover": {
                            borderColor: '2px solid red',
                        },
                        padding: 16,

                    }}
                    onMouseEnter={() => {
                        setOnHover(!onHover);
                    }}
                    onMouseLeave={() => {
                        setOnHover(!onHover);
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: 2,
                        }}
                    >
                        {" "}
                        <Typography component="h7" variant="h7" style={{ fontWeight: 500 }}>
                            {heading}
                        </Typography>
                        {heading === "Business" && (
                            <div
                                style={{
                                    // marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    position: "absolute",
                                    right: "-2px",
                                }}
                            >
                                <Box style={arrow} />

                                <div style={tag}>
                                    {" "}
                                    <Typography
                                        variant="body2"
                                        style={{
                                            paddingLeft: "20px",
                                            paddingRight: "10px",
                                            color: "#fff",
                                        }}
                                    >
                                        Most Popular
                                    </Typography>{" "}
                                </div>
                            </div>
                        )}
                    </Box>
                    <CardContent>
                        <div style={cardPricing}>
                            <TextField
                                label="plan price"
                                variant="outlined"
                                value={alignment === "monthly" ? monthlyPrice : annualPrice}
                                type='number'
                                className={classes.planPrice}
                                onChange={(e) => {

                                    handlePlanAmountChange(e.target.value);

                                }}
                            />

                            <Typography variant="h4" style={{ color: "#424242" }}>

                                {/* {alignment === "monthly" ? monthlyPrice : annualPrice} */}
                                <span
                                    style={{
                                        color: "#606F7B",
                                        fontSize: "14px",
                                    }}
                                >
                                    {alignment === "monthly" ? "/Month" : "annual"}
                                </span>
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    color: "#606F7B",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                Pay yearly to get special price:
                                <br />
                                <span style={{ textDecorationLine: "line-through" }}>
                                    {monthlyPrice * 12}
                                </span>
                                <span
                                    style={{
                                        color: "#000",
                                        marginLeft: "5px",
                                        fontWeight: "600",
                                    }}
                                >
                                    {annualPrice}
                                </span>
                            </Typography>
                        </div>

                        <ul style={{ padding: 0, marginBottom: "0px" }}>
                            {Array.isArray(benefits) && benefits?.map((obj, i) => {
                                return (
                                    <li style={plantable}>
                                        {obj?.added ? tickIcon : closeIcon}
                                        <Typography
                                            variant="body2"
                                            style={{ marginLeft: "10px", color: "#606F7B" }}
                                        >
                                            {obj?.text.replace(/(<([^>]+)>)/ig, '')}

                                            {/* Unlimited Job Posting */}
                                        </Typography>
                                    </li>
                                );
                            })}

                        </ul>
                    </CardContent>
                </Box>
                {showButton && (
                    <Button
                        fullWidth
                        variant="contained"
                        // color="primary"
                        onClick={selectPlan}
                        style={{
                            borderRadius: "0px",
                            fontSize: "14px",
                            height: "45px",
                            display: "flex",
                            color: "white",
                            backgroundColor: '#3F51B5'
                        }}
                    >
                        <Typography variant="h7" style={{ fontWeight: "500" }}>
                            {" "}
                            Get Started
                        </Typography>
                    </Button>
                )}
            </CommonInputFieldDiv>
        </>
    )
}

export default SinglePlan