import {
  Button,
  Dialog, DialogContent, Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, {useState, useEffect} from "react";
import { FailureAlert } from "../../../../util/Notifications";
import WorkExperienceForm from "./WorkExperienceForm";

const useStyles = makeStyles((theme) => ({
  newDialog: {
    position: "absolute",
  },
  margin: {
    width: "100%",
  },
}));

export default function AddWorkExperienceDialog(props) {
  const classes = useStyles();
  const {open, handleClose, companiesList, editWorkEx, setEditWorkEx, saveWorkEx, isCompanyNotListed, setIsCompanyNotListed} = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  // useEffect(() => {
  //   if(!editWorkEx?.company_id)
  //     setIsCompanyNotListed(true)
  // }, [editWorkEx?.company_id])

  const validateForm = () => {
    if(!editWorkEx?.job_designation)
    {
      FailureAlert("Job title can't be empty");
      return false;
    }
    if(!isCompanyNotListed && !editWorkEx?.company_id)
    {
      FailureAlert("Select a company");
      return false;
    }
    if(isCompanyNotListed && !editWorkEx?.company_name)
    {
      FailureAlert("Company name can't be empty");
      return false;
    }
    // if(editWorkEx?.company_website && !editWorkEx?.company_name)
    // {
    //   FailureAlert("Company name can't be empty");
    //   return false;
    // }
    if(!editWorkEx?.start_date){
      FailureAlert("Start Date can't be empty");
      return false;
    }
    if(!editWorkEx?.is_current_working && !editWorkEx?.end_date)
    {
      FailureAlert("End Date can't be empty");
      return false;
    }
    if(!editWorkEx?.job_description)
      {
        FailureAlert("Description can't be null");
        return false;
      }
    return true;
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        // maxWidth="sm"
        classes={{
          paper: classes.newDialog,
        }}
        open={props.open}
        onClose={() => {
          setIsCompanyNotListed(false);
          props.handleClose()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        <DialogContent style={{ padding: "40px" }}>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
            }}
          >
            <b>Work Experience</b>
          </Typography>
          <WorkExperienceForm addedWorkEx={editWorkEx} setAddedWorkEx={setEditWorkEx} companiesList={companiesList} isCompanyNotListed={isCompanyNotListed} setIsCompanyNotListed={setIsCompanyNotListed}/>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                  setIsCompanyNotListed(false);
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.grid}
              style={{ display: "grid" }}
            >
              <Button variant="contained" color="primary" onClick={() => {
                if(validateForm()){
                  saveWorkEx();
                }
              }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
