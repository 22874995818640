import React from "react";
import { useState, useEffect } from "react";
import ApplicantsPreference from "./ApplicantsPreference";
import CampusPreferences from "./CampusPreferences";
import Recruitment from "./Recruitment";
import { preferenceData } from "./preferenceData";
import { praveshAxiosGetReq } from "../../../util/apiCall";
import { CircularProgress } from "@material-ui/core";
import { praveshAxiosPatchReq } from "../../../util/baseApi";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";


function AllPreference({ companyId, newCompanyDetails,}) {

  const [cityState, setCityState] = useState([]);

  const initialState = {
    applicant: {
      workExLevel: [],
      eduLevel: [],
      jobSegment: [],
      location: [],
    },
    months: {
      internshipAvailability: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ], //internship_availability
      jobAvailability: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    },
    recruiting: {
      streams: [],
      location: [],
      categories: [],
    },
  };
  const [preferences, setPreferences] = useState(initialState);
  const [qualificationData, setQualificationData] = useState([]);
  const [workExLevel, setWorkExLevel] = useState([]);
  const [allJobSegments, setAllJobSegments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appLoader, setAppLoader] = useState(false);
  const [monthsLoader, setMonthsLoader] = useState(false);
  const [recruitingLoader, setRecruitingLoader] = useState(false);
  const [streamsData, setStreamsData] = useState([]); 
  const [collegeCategoryData, setCollegeCategoryData] = useState([]);


  const getCityState = async () => {

    const endPoint = `/api/location/city_state/`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      setCityState(res?.data?.data);

    } catch (error) {

      throw (error);
    };
  };

const getQualificationData = async () => {

  const endPoint = `/api/shared/get/college/qualification/`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      setQualificationData(res?.data?.data);

    } catch (error) {

      throw (error);
    };


};

const getStramsData = async() => {

    const endPoint = `/api/shared/get/college/streams/`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      setStreamsData(res?.data?.data);

    } catch (error) {

      throw (error);
    };
};

const getCollegeCategories = async() => {

     const endPoint = `/api/shared/get/college/category/`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      setCollegeCategoryData(res?.data?.data);

    } catch (error) {

      throw (error);
    };
}


   useEffect(() => {

    if (cityState?.length === 0) {

      getCityState();

    };
    if (qualificationData?.length === 0) {
        
      getQualificationData()
    };
    if (streamsData?.length === 0) {
        
      getStramsData();
    };
    if (collegeCategoryData?.length === 0) {

      getCollegeCategories();
    };


  }, []);

   useEffect(() => {
    if (companyId ) {

      // console.log("on company id set");
      getPreferences();
    }
  }, [companyId]);


  const getPreferences = async () => {

    const buffer = Buffer(companyId.toString());
    const string = buffer.toString("base64");
    setLoading(true);
    const endPoint = `/api/admin/company/new/preference/?company_id=${string}`;

    try {

      const res = await praveshAxiosGetReq(endPoint);
      const data = res?.data?.data;
      prefillDataHelper(data);

      setLoading(false);
      // console.log("preferences api call");
    } catch (error) { 

       setLoading(false);

    }
  };

   const prefillDataHelper = (data) => {
    // let detail = data?.data;
    let detail = data;
    setWorkExLevel(detail?.work_exp_master_list);
    const newInternshipAvailabilty = [
      ...preferences?.months?.internshipAvailability,
    ];
    detail?.internship_months?.map((month) => {
      newInternshipAvailabilty[month - 1] = true;
    });

    //jobAvailability
    const newJobAvailabilty = [...preferences?.months?.jobAvailability];
    detail?.full_time_months?.map((month) => {
      newJobAvailabilty[month - 1] = true;
    });

    const qual = [];
    detail?.qualification?.map((item) => {
      qual.push(item?.id);
    });

    const selectedStreams = [];
    detail?.stream?.map((item) => {
      selectedStreams?.push(item?.id);
    });
    const workExIDs = [];
    detail?.work_exp?.map((item) => {
      workExIDs?.push(parseInt(item?.work_level_id));
    });
    let obj = {
      applicant: {
        workExLevel: detail?.work_exp,
        workExLevelId: workExIDs,
        eduLevel: qual,
        jobSegment: detail?.job_segment,
        location: detail?.applicant_city,
      },
      months: {
        internshipAvailability: newInternshipAvailabilty, 
        jobAvailability: newJobAvailabilty,
      },
      recruiting: {
        streams: selectedStreams,
        location: detail?.college_city,
        categories: detail?.college_category,
      },
    };

    setPreferences(obj);
  };

  const handleSubmit = (type) => {

    let obj = {};
    obj["company_id"] = companyId;

    if (type === "applicant") {
      
      let locArr = [];
      for (let i = 0; i < preferences?.applicant?.location?.length; i++) {
        if (preferences?.applicant?.location[i]?.city_id) {
          locArr.push(parseInt(preferences?.applicant?.location[i]?.city_id));
        }
      }
      obj["save_flag"] = "APP";
      obj["work_exp"] = preferences?.applicant?.workExLevel;
      obj["qualification"] = preferences?.applicant?.eduLevel;

      if( preferences?.applicant?.jobSegment ){

        obj["job_segment"] = preferences?.applicant?.jobSegment;

      }
     
      obj["applicant_city"] = locArr;
    }else if (type === "months") {
      var internship_availability = [];
      for (
        let i = 0;
        i < preferences?.months?.internshipAvailability.length;
        i++
      ) {
        if (preferences?.months?.internshipAvailability[i]) {
          internship_availability.push(i + 1);
        }
      }
      var job_availability = [];
      for (let i = 0; i < preferences?.months?.jobAvailability?.length; i++) {
        if (preferences?.months?.jobAvailability[i]) {
          job_availability.push(i + 1);
        }
      }

      obj["save_flag"] = "MONTH";
      obj["full_time_months"] = job_availability;
      obj["internship_months"] = internship_availability;
    } else if (type === "campus") {
      let locArr = [];
      for (let i = 0; i < preferences?.recruiting?.location?.length; i++) {
        if (preferences?.recruiting?.location[i]?.city_id) {
          locArr.push(parseInt(preferences?.recruiting?.location[i]?.city_id));
        }
      }

      let cat = [];
      for (let i = 0; i < preferences?.recruiting?.categories?.length; i++) {
        if (preferences?.recruiting?.categories[i]?.id) {
          cat.push(parseInt(preferences?.recruiting?.categories[i]?.id));
        }
      }
      obj["save_flag"] = "CAMPUS";
      obj["stream"] = preferences?.recruiting?.streams;
      obj["college_city"] = locArr;
      obj["college_category"] = cat;
    };

    const postPreferencesData  = async() => {    
     

          if(type === "applicant" ){
            setAppLoader(true); 
          };
          if(type === "campus" ){
            setRecruitingLoader(true); 
          };
          if(type === "months" ){
            setMonthsLoader(true); 
          };
        
      const endPoint = `/api/admin/company/new/preference/`;

      try {
        const res = await praveshAxiosPatchReq(endPoint,obj);

        if(res?.data?.success){

          SuccessAlert(`${res?.data?.data?.message}`);

            if(type === "applicant" ){
            setAppLoader(false); 
          };
          if(type === "campus" ){
            setRecruitingLoader(false); 
          };
          if(type === "months" ){
            setMonthsLoader(false); 
          };
      
        }else{
          FailureAlert("Some Error Occured.")
            if(type === "applicant" ){
            setAppLoader(false); 
          };
          if(type === "campus" ){
            setRecruitingLoader(false); 
          };
          if(type === "months" ){
            setMonthsLoader(false); 
          };
      
        }

        } catch (error) {
          
           if(type === "applicant" ){
            setAppLoader(false); 
          };
          if(type === "campus" ){
            setRecruitingLoader(false); 
          };
          if(type === "months" ){
            setMonthsLoader(false); 
          };
      }
    }
    postPreferencesData();   
  };

  if(loading){
    return(
       <>
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <CircularProgress />
            </div>
          </div>
        </>
    )   
  }
  


  return (
    <>
    <ApplicantsPreference
         companyId={newCompanyDetails?.companyBasicInfo?.companyId}       
          cityState={cityState}
          allJobSegments={allJobSegments}
          preferences={preferences}
          setPreferences={setPreferences}
          qualificationData={qualificationData}
          handleSubmit={handleSubmit}
          workExLevel={workExLevel}
          appLoader={appLoader}
    />     
      <br />
      <Recruitment 
       preferences={preferences}
       setPreferences={setPreferences}
       handleSubmit={handleSubmit}
       monthsLoader={monthsLoader}
      />

      <br />
      <CampusPreferences 
        cityData={cityState}
        preferences={preferences}
        setPreferences={setPreferences}
        streamsData={streamsData}
        collegeCategoryData={collegeCategoryData}
        handleSubmit={handleSubmit}
        recruitingLoader={recruitingLoader}
      
      />
    </>
  );
}

export default AllPreference;
