import DateFnsUtils from "@date-io/date-fns";
import {
  Checkbox,
  FormControl, FormControlLabel, FormGroup, FormLabel,
  Grid, Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React, { useEffect } from "react";


const useStyles = makeStyles((theme) => ({
  overview: {
    paddingBottom: "15px",
    paddingTop: "5px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  input: {
    height: 48,
  },
  textfiledrow: {
    height: 120,
  },
  inputbase: {
    padding: "10px 14px",
    fontSize: "14px",
  },
  resize: {
    color: "#7e7e7e",
    padding: "6px",
    fontSize: "14px",
    paddingLeft: "10px",
  },

  MuiInput: {
    height: 48,
  },

  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  margin: {
    width: "100%",
  },
  cal: {
    marginBottom: "20px",
  },

  check: {
    display: "flex",
  },

  textField: {
    width: "100%",
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="primary"
      // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      //  icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function WorkExperienceForm({addedWorkEx, setAddedWorkEx, companiesList, setIsCompanyNotListed, isCompanyNotListed}) {
  const classes = useStyles();

  const jobData = [
    { job_type_id: "1", job_type_name: "Internship" },
    { job_type_id: "7", job_type_name: "Full-time Job" },
    { job_type_id: "4", job_type_name: "Part Time Job" }
  ];

  const jobTypeMap = {
    "1": "Internship",
    "7": "Full-time Job",
    "4": "Part Time Job"
  }

  useEffect(() => {
    if(addedWorkEx && !addedWorkEx?.company_id)
      setIsCompanyNotListed(true);
  }, [addedWorkEx?.company_id]);

  return (
    <>
      <div className={classes.overview}>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="Job Title"
                size="small"
                variant="outlined"
                id="custom-css-outlined-input"
                value={addedWorkEx?.job_designation}
                onChange={(event) => {
                  setAddedWorkEx({...addedWorkEx, job_designation: event.target.value})
                }}
              />
            </Grid>
            {isCompanyNotListed ?
            <TextField
            className={classes.textField}
            label="Enter Company Name"
            size="small"
            variant="outlined"
            id="job-title"
            value={addedWorkEx?.company_name}
            onChange={(event) => {
              setAddedWorkEx({...addedWorkEx, company_name: event.target.value, company_id: null})
            }}
          />
            : 
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.textField}
                size="small"
              >
                <Autocomplete
              id="select-company"
              // value={addedWorkEx.company_name}
              options={companiesList}
              getOptionLabel={(item) => `${item.company}`}
              onChange={(e, item) => (item ? setAddedWorkEx({...addedWorkEx, company_id: item.id, company_name: item.company}): null)}
              renderInput={(params) => <TextField {...params} label={`Select Company (Current: ${addedWorkEx?.company_name || `NONE`})`} variant="outlined" />}
            />
              </FormControl>
            </Grid>
}
            <Grid item xs={12}>
              <div>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup className={classes.check}>
                    <FormControlLabel
                      value="read"
                      control={<Checkbox color="primary" checked={isCompanyNotListed ? true : false}/>}
                      onClick={() => {
                        if(isCompanyNotListed)
                        setAddedWorkEx({...addedWorkEx, company_website: null, company_name: null});
                        else
                        setAddedWorkEx({...addedWorkEx, company_id: null});
                        setIsCompanyNotListed(!isCompanyNotListed)
                      }}
                      label={
                        <Typography
                          variant="body2"
                          className={classes.secondfont}
                        >
                          My Company not listed
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>
            
            
            {isCompanyNotListed && (
              <Grid item xs={12}>
                <TextField
                  style={{ marginTop: "-10px" }}
                  className={classes.textField}
                  label="Company Website"
                  size="small"
                  variant="outlined"
                  id="custom-css-outlined-input"
                  value={addedWorkEx?.company_website}
                  onChange={(e) => {setAddedWorkEx({...addedWorkEx, company_website: e.target.value})}}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl component="fieldset">
              <FormLabel id="demo-controlled-radio-buttons-group">Job Type</FormLabel>
                
                <RadioGroup
                      className={classes.check}
                      aria-label="gender"
                      value={JSON.stringify(addedWorkEx?.job_type_id)}
                      onChange={(event) => {
                        setAddedWorkEx({...addedWorkEx, 
                          job_type_id: parseInt(event.target.value), job_type_name: jobTypeMap[event.target.value]})
                      }}
                    >
                {jobData.map((data) => {
                  return (
                        <FormControlLabel
                          value={data?.job_type_id}
                          control={<StyledRadio/>}
                          label={
                            <Typography
                              variant="body2"
                              className={classes.secondfont}
                            >
                              {data.job_type_name}
                            </Typography>
                          }
                        />
                    
                   
                  );
                })}
                 </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <div>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <Typography variant="body2" className={classes.secondfont}>
                    Time Period
                  </Typography>
                  <FormGroup className={classes.check}>
                    <FormControlLabel
                      value="read"
                      control={<Checkbox checked={addedWorkEx?.is_current_working ? true : false}/>}
                      label={
                        <Typography
                          variant="body2"
                          className={classes.secondfont}
                        >
                          Currently working here
                        </Typography>
                      }
                      onChange={(event) => {setAddedWorkEx({...addedWorkEx, is_current_working: event.target.checked, end_date: null})}}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" className={classes.font}>
                Start Date
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  format="MM/yyyy"
                  openTo="year"
                  margin="normal"
                  views={["year", "month"]}
                  format="MM/yyyy"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="Start Date"
                  value={addedWorkEx?.start_date ? addedWorkEx?.start_date : null}
                  onChange={(date, value) => {
                    setAddedWorkEx({...addedWorkEx, start_date: date})
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {!addedWorkEx?.is_current_working && 
            <>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" className={classes.font}>
                End Date
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  format="MM/yyyy"
                  openTo="year"
                  margin="normal"
                  views={["year", "month"]}
                  format="MM/yyyy"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  defaultValue="MM/YYYY"
                  label="End Date"
                  value={addedWorkEx?.end_date ? addedWorkEx?.end_date : null}
                  onChange={(date, value) => {
                    setAddedWorkEx({...addedWorkEx, end_date: date})
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
</>
}
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.font}>
                Description
              </Typography>
              <Typography
                style={{ color: "#b0b6ba", marginTop: "10px" }}
                variant="body2"
              >
                In 150 words describe your professional responsibilities and
                accomplishments. (Eg: Maintained inventory of office supplies
                and ordered as and when needed)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                multiline
                margin="normal"
                InputProps={{
                  className: classes.textfiledrow,
                }}
                row={6}
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                value={addedWorkEx?.job_description}
                onChange={(e) => {
                  setAddedWorkEx({...addedWorkEx, job_description: e.target.value})
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
