import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { niyuktiUrl, praveshUrl } from "../../../constants";
import { FailureAlert, SuccessAlert } from "../../../util/Notifications";
import CustomButtonCircularProgress from "../../Student/Components/loader";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "30px",
    margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "16px",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

const UploadOfferLetter = ({
  handleClickOpen,
  handleClose,
  open,
  callApi,
  data,
}) => {
  const classes = useStyles();

  const fullWidth = true;
  const maxWidth = "sm";
  const [loading, setLoading] = useState(false);
  const [image, setImage] = React.useState(null);

  const checkFileType = (file) => {
    let status = false;

    if (file?.type === "application/pdf") {
      status = true;
    }
    return status;
  };
  const checkFileSizeAndType = (file) => {
    let status = true;
    const fsize = file?.size;
    const file1 = Math.round(fsize / 1024);

    if (file1 > 2048) {
      FailureAlert("File too Big, please select a file less than 2mb");
      status = false;
    } else if (checkFileType(file)) {
      status = true;
    } else {
      status = false;
      FailureAlert(`Accepted file formats: pdf`);
    }

    return status;
  };

  const handleFile = (e) => {
    if (checkFileSizeAndType(e?.target?.files[0])) setImage(e.target.files[0]);
  };

  const callBackSubmit = () => {
    callApi();
    handleClickClose();
  };
  const handleSubmit = () => {
    if (!image) {
      FailureAlert(`Please select file to upload`);
      return;
    }

    const fd = new FormData();
    fd.append("applicant_id", data?.applicantId);
    fd.append("credit_note", image);

    setLoading(true);
    const url = `${niyuktiUrl}/api/manage_jobs/admin/track/upload_credit_note/`;
    const method = "post";
    axios[method](url, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          SuccessAlert(res?.data?.data?.message);
          callBackSubmit();
        }
        setLoading(false);
      })
      .catch((err) => {
        FailureAlert(`Upload unsuccessful`);
        setLoading(false);

        throw new Error(err);
      });
  };

  const handleClickClose = () => {
    setImage(null);
    handleClose();
  };

  return (
    <div>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClickClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <Typography style={{ fontSize: "24px" }}>
            {`Upload Credit Note`}
          </Typography>
          <br />

          <br />

          <Form.File type="file" onChange={handleFile} />

          <Typography variant="body2" color="primary">
            {!image ? (
              <>Accepted file formats: pdf</>
            ) : (
              <Typography noWrap style={{ maxWidth: "250px" }}>
                {"Selected File: " + image?.name}
              </Typography>
            )}
          </Typography>

          <div
            style={{
              padding: "25px",
              marginLeft: "10px",
              paddingBottom: "0px",
              display: "flex",
              paddingLeft: "25px",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClickClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              disabled={loading ? true : false}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? <CustomButtonCircularProgress /> : `Upload File`}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UploadOfferLetter;
