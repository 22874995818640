import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  circularProgressColored: {
    color: "#000" /* theme.palette.primary.main */,
  },
  circularProgress: {
    color: "#ccc" /* theme.palette.common.white */,
  },
}));
function CustomButtonCircularProgress({ isWhite = true }) {
  const classes = useStyles();
  return (
    <CircularProgress
      className={
        isWhite ? classes.circularProgress : classes.circularProgressColored
      }
      size={30}
    />
  );
}
export default CustomButtonCircularProgress;
