import {
  Box,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  Chip,
  styled,
  useTheme,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { FailureAlert } from "../../../util/Notifications";
import { useHistory } from "react-router";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
  marginLeft: "0px",
}));

function CampusPreferences({
  cityData,
  streamsData,
  preferences,
  setPreferences,
  collegeCategoryData,
  handleSubmit,
  recruitingLoader,
}) {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const history = useHistory();
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Bangalore | Karnatka" },
    { key: 1, label: "Mumbai | Maharashtra" },
    { key: 2, label: "Pune | Maharashtra" },
  ]);

  const [chipCollegeData, setChipCollegeData] = React.useState([
    { key: 0, label: "IIT" },
    { key: 1, label: "NIT" },
  ]);

  const handleDeleteCollege = (chipToDelete) => () => {
    setChipCollegeData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const theme = useTheme();

  const addLocation = (cityState) => {
    if (cityState) {
      const stateAlreadyAdded = preferences?.recruiting?.location?.some(
        (st) => st.city_id === cityState.city_id
      );
      if (stateAlreadyAdded) FailureAlert("city Already Added!");
      else {
        const arr = preferences?.recruiting?.location
          ? [...preferences?.recruiting?.location]
          : [];
        arr.push(cityState);

        setPreferences({
          ...preferences,
          recruiting: {
            ...preferences?.recruiting,
            location: arr,
          },
        });
      }
    }
  };

  const deleteLocation = (index) => {
    const stateAfterDelete = preferences?.recruiting?.location?.filter(
      (_, state) => state !== index
    );

    setPreferences({
      ...preferences,
      recruiting: {
        ...preferences?.recruiting,
        location: stateAfterDelete,
      },
    });
  };

  const addCategory = (cat) => {
    if (cat) {

      const collegeAlreadyAdded = preferences?.recruiting?.categories?.some(
        (st) => st.id === cat.id);
      if (collegeAlreadyAdded) FailureAlert("Category Already Added!");
      else {
        const arr = preferences?.recruiting?.categories
          ? [...preferences?.recruiting?.categories]
          : [];
        arr.push(cat);

        setPreferences({
          ...preferences,
          recruiting: {
            ...preferences?.recruiting,
            categories: arr,
          },
        });
      }
    }
  };

  const deleteCategory = (index) => {
    const stateAfterDelete = preferences?.recruiting?.categories?.filter(
      (_, state) => state !== index
    );

    setPreferences({
      ...preferences,
      recruiting: {
        ...preferences?.recruiting,
        categories: stateAfterDelete,
      },
    });
  };

  const handleStream = (e) => {
    let arr =
      preferences?.recruiting?.streams?.length > 0
        ? [...preferences?.recruiting?.streams]
        : [];
    if (e.target?.checked) {
      arr.push(parseInt(e.target.value));
    } else {
      arr = arr.filter((el) => el !== parseInt(e.target.value));
    }
    setPreferences({
      ...preferences,
      recruiting: {
        ...preferences?.recruiting,
        streams: arr,
      },
    });
  };

  return (
    <>
      <Box
        style={{
          boxShadow: "0px 0px 4px 0px #00000040",
          padding: 20,
          borderRadius: "20px",
        }}
      >
        <Typography
          style={{ fontSize: "20px", fontWeight: "600", paddingLeft: 10 }}
        >
          Campus Recruiting Preferences
        </Typography>

        <Box
          style={{
            marginTop: 0,
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={10}>
              <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                Select Stream
              </Typography>
              <Grid container spacing={2} style={{ pl: 0, pr: 0 }}>
                {streamsData?.map((item, value) => (
                  <Grid item md={2} key={item?.id}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            value={item?.id}
                            onChange={handleStream}
                            checked={preferences?.recruiting?.streams?.includes(
                              item?.id
                            )}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: "14px" }}>
                            {item?.stream}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {/* <Grid
              md={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              <Box style={{ pb: 1.4 }}>
                <CustomTextField
                  size="small"
                  label="Type the Stream"
                  variant="outlined"
                />
              </Box>
            </Grid> */}
          </Grid>
        </Box>

        <Box
          style={{ marginTop: 20, paddingLeft: "10px", paddingRight: "10px" }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
            College Location
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={5}>
              <Autocomplete
                // disablePortal
                id="combo-box-demo"
                options={cityData}
                value={preferences?.recruiting?.location}
                getOptionLabel={(option) =>
                  option?.city && option?.state
                    ? `${option?.city}, ${option?.state}`
                    : ""
                }
                onChange={(e, newValue) => {
                  if (newValue) addLocation(newValue);
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    label="Add Location"
                  />
                )}
              />

             
            </Grid>
            <Grid item md={12}>
                 <Box
                style={{
                  display: "flex",
                  justifyContent: "flexe-start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  minWidth:"10rem",
                }}
                // component="ul"
              >
                {preferences?.recruiting?.location?.map((data, index) => {
                  return (
                    <ListItem key={data?.city_id}>
                      <Chip
                        label={`${data.city} | ${data.state}`}
                        color="primary"
                        deleteIcon={<CloseIcon />}
                        variant="outlined"
                        style={
                          {
                            // backgroundColor: alpha(
                            //   theme.palette.primary.main,
                            //   0.1
                            // ),
                          }
                        }
                        onDelete={() => {
                          deleteLocation(index);
                        }}
                      />
                    </ListItem>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{ marginTop: 20, paddingLeft: "10px", paddingRight: "10px" }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
            College Category
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={5}>
              <Autocomplete
                // disablePortal
                id="combo-box-demo"
                options={collegeCategoryData}
                getOptionLabel={(option) => option?.category}
                onChange={(e, newValue) => {
                  if (newValue) addCategory(newValue);
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    label="College Category"
                  />
                )}
              />
             
            </Grid>
             <Grid item md={12}>
                   <Box
                style={{
                  display: "flex",
                  justifyContent: "flexe-start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  padding: 5,
                  margin: 0,
                  paddingLeft: 0,
                  marginTop: 10,
                }}
                // component="ul"
              >
                {preferences?.recruiting?.categories?.map((data, index) => {
                  return (
                    <ListItem key={data.id}>
                      <Chip
                        label={data.category}
                        color="primary"
                        deleteIcon={<CloseIcon />}
                        variant="outlined"
                        style={
                          {
                            // backgroundColor: alpha(
                            //   theme.palette.primary.main,
                            //   0.1
                            // ),
                          }
                        }
                        onDelete={() => {
                          deleteCategory(index);
                        }}
                      />
                    </ListItem>
                  );
                })}
              </Box>

             </Grid>
          </Grid>
        </Box>
        <Button
          variant="contained"
          color="primary"
          style={{
            boxShadow: "none",
            borderRadius: "30px",
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "30px",
            marginLeft: "20px",
            minWidth: "9rem",
            minHeight: '2.7rem',
            border:"none"
          }}
          onClick={() => {
            handleSubmit("campus");
          }}
        >
          {recruitingLoader ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            "Save Changes"
          )}
        </Button>        
      </Box>
    </>
  );
}

export default CampusPreferences;

const Experience = [
  {
    name: "Fresher",
  },
  {
    name: "Junior Level (1-5 Years)",
  },
  {
    name: "Senior Level (5+ Years)",
  },
];

const Stream = [
  {
    name: "Engineering",
  },
  {
    name: "Computer Applications",
  },
  {
    name: "Commerce",
  },
  {
    name: "Arts",
  },
  {
    name: "Medical",
  },
  {
    name: "Vocational Courses",
  },
  {
    name: "Pharmacy",
  },
  {
    name: "Paramedical ",
  },
  {
    name: "Mass Communications",
  },
  {
    name: "Science",
  },
  {
    name: "Law",
  },
  {
    name: "Design",
  },
  {
    name: "Hotel Management",
  },
  {
    name: "Agriculture",
  },
  {
    name: "Veterinary Science",
  },
  {
    name: "Animation",
  },
  {
    name: "Dental",
  },
  {
    name: "Other",
  },
];

const Category = [
  { label: "  IIMs", year: 1994 },
  { label: "IIT", year: 1972 },
];

const location = [
  { label: "Bangalore | Karnatka" },
  { label: "Mumbai | Maharashtra" },
  { label: "Pune | Maharashtra" },
];
