import React, { useState, useEffect, Fragment, useCallback } from "react";
import "../../css/PostJobSection.css";
import {
  apiCall,
  apiNiyuktiCall,
  apiPraveshCall,
  praveshAxiosGetReq,
} from "../../util/apiCall";
import {formatDateObject} from "../../util/utility";
import { uniqueArray } from "../../util/uniqueArray";
import {
  url,
  work_experiences,
  gifStyling,
  requestPraveshOptions,
  requestNiyuktiOptions,
  educationType,
  venueType,
  roundtime,
} from "../../constants";
import { Form, Col, Button, InputGroup, Modal } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SuccessAlert, FailureAlert } from "../../util/Notifications";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import gif from "../../assets/loopingcircles-3.gif";
import line from "../../assets/lineOr.png";
import college_placeholder from "../../assets/college_placeholder.png";
import no_college_data from "../../assets/images/no_college_data.png";
import college_count from "../../assets/images/college_count.png";
import NumberFormat from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  createStyles,
  makeStyles,
  withStyles,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  Button as Button2,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@material-ui/core";
import { useLocation } from "react-router";
import CustomButtonCircularProgress from "../loader";
import CloseIcon from "@material-ui/icons/Close";
import RoundCard from "./RoundCard/RoundCard";
import { jobCategoryList } from "../../constants";
import PortalCard from "./PortalCard/PortalCard";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  grid1: {
    marginTop: "20px",
    fontWeight: "bolder",
    paddingLeft: "7%",
    paddingTop: "4%",
  },
  grid2: {
    marginTop: "20px",
  },
  shadowBox: {
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    padding: "5px 0 5px 20px",
    borderRadius: "4px",
  },
  innerBox: {
    padding: "5px 0 5px 30px",
  },
  radio: {
    "&$checked": {
      color: "#3282C4",
    },
    "&:hover": {
      backgroundColor: "rgba(50, 130, 196, 0.04)",
    },
  },
  newLabel: {
    color: "white",
    fontWeight: "bolder",
  },
  checked: {},
  table: {
    minWidth: 700,
  },
  root2: {
    fontWeight: "bolder",
    border: "2px solid red",
    color: "red",
  },
  root1: {
    fontWeight: "bolder",
    border: "2px solid #26a95b",
    color: "#26a95b",
  },
  root: {
    fontWeight: "bolder",
    border: "2px solid #3282C4",
    color: "#3282C4",
  },
  "@media only screen and (max-width: 768px)": {},
});

export function EditJobSection({
  cmpId,
  jobId,
  userId,
  userTypeId,
  serviceType,
  transactionType,
  amount,
  isPublicJob = false,
  isClone = false,
  getCreditInfo = () => {},
  isThirdPartyJob = false,
}) {
  const classes = useStyles();

  const [nextUrl, setNextUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [circularLoader, setCircularLoader] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [collegeLoading, setCollegeLoading] = useState(false);
  const [end, setEnd] = useState(false);
  const [data, setData] = useState({});

  const collegeUUID = uuidv4();

  const [jobTitle, setJobTitle] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(null);
  const [jobTypeName, setJobTypeName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [internshipSalaryType, setInternshipSalaryType] = useState("");
  const [ppoOffered, setPpoOffered] = useState(true);
  const [empTypes, setEmpTypes] = useState([]);
  const [empTypeId, setEmpTypeId] = useState(null);
  const [selectedJobSegment, setSelectedJobSegment] = useState(null);
  const [jobLakeBool, setJobLakeBool] = useState(false);
  const [ipoJobBool, setIpoJobBool] = useState(false);
  const [description, setDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [jobPaymentType, setJobPaymentType] = useState("");

  // AI Section State
  const [isAiAssessment, setIsAiAssessment] = useState(null);
  const [jdvscvid, setJdVsCvid] = useState("");
  const [aiassessmentid, setAiAssessmentId] = useState("");
  
  const [jobTmc, setJobTmc] = useState("");
  const [jobTmcEditor, setJobTmcEditor] = useState(EditorState.createEmpty());
  const [jobRoles, setJobRoles] = useState([]);
  const [jobRoleId, setJobRoleId] = useState(null);
  const [vacancy, setVacancy] = useState();

  const [invoicingPeriod, setInvoicingPeriod] = useState();

  const [salaryType, setSalaryType] = useState("");
  const [minCtc, setMinCtc] = useState(null);
  const [maxCtc, setMaxCtc] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [noEquity, setNoEquity] = useState(true);
  const [minEquity, setMinEquity] = useState(null);
  const [maxEquity, setMaxEquity] = useState(null);
  const [resumeRequired, setResumeRequired] = useState(false);
  const [coverLetterRequired, setCoverLetterRequired] = useState(false);
  const [transcriptRequired, setTranscriptRequired] = useState(false);
  const [idProofRequired, setIdProofRequired] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [workLocationModes, setWorkLocationModes] = useState({
    wfh: "",
    hybrid: "",
  });

  const [allowRemote, setAllowRemote] = useState(false);
  const [serviceBond, setServiceBond] = useState(false);
  const [bondMonths, setBondMonths] = useState(null);
  const [allJobSegments, setAllJobSegments] = useState([]);

  const [activeYears, setActiveYears] = useState([]);
  const [eligibleYears, setEligibleYears] = useState([]);
  const [graduationBool, setGraduationBool] = useState(false);
  const [postGraduationBool, setPostGraduationBool] = useState(false);
  const [phdBool, setPhdBool] = useState(false);
  const [allDegrees, setAllDegrees] = useState([]);
  const [selectedDegrees, setSelectedDegrees] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [minPercentage, setMinPercentage] = useState(null);
  const [allSkills, setAllSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSkillsEvaluation, setSelectedSkillsEvaluation] = useState([]);

  const [minWorkExp, setMinWorkExp] = useState("");
  const [maxWorkExp, setMaxWorkExp] = useState("");
  const [stipend, setStipend] = useState(null);

  const [allRounds, setAllRounds] = useState([]);
  const [jobportals, setJobPortals] = useState([]);

  const [selectedRounds, setSelectedRounds] = useState([]);

  const [isOffCampus, setIsOffCampus] = useState(false);
  const [globalStartDate, setGlobalStartDate] = useState("");
  const [globalEndDate, setGlobalEndDate] = useState("");
  const [offCampusAllCourses, setOffCampusAllCourses] = useState({});
  const [selectedOffCampusCourses, setSelectedOffCampusCourses] = useState([]);
  const [isOffCampusAllCourses, setIsOffCampusAllCourses] = useState(false);
  const [collegeList, setCollegeList] = useState({});
  const [filteredCollegeList, setFilteredCollegeList] = useState({});
  const [selectedColleges, setSelectedColleges] = useState([]);
  const [selectedCollegesFromAPI, setSelectedCollegesFromAPI] = useState([]);
  const [selectedCollegesNames, setSelectedCollegesNames] = useState(new Set());
  const [selectedCollegesNamesArr, setSelectedCollegesNamesArr] = useState([]);

  const [qualificationData, setQualificationData] = useState([]);
  const [degreesPayload, setDegreesPayload] = useState([]);
  const [specsPayload, setSpecsPayload] = useState([]);

  const [applyFiltersBool, setApplyFiltersBool] = useState(false);
  const [removeFiltersBool, setRemoveFiltersBool] = useState(false);
  const [graduationBoolFilter, setGraduationBoolFilter] = useState(false);
  const [postGraduationBoolFilter, setPostGraduationBoolFilter] =
    useState(false);
  const [phdBoolFilter, setPhdBoolFilter] = useState(false);
  const [selectedDegreesFilter, setSelectedDegreesFilter] = useState([]);
  const [selectedCoursesFilter, setSelectedCoursesFilter] = useState([]);
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState([]);
  const [minCtcFilter, setMinCtcFilter] = useState(null);
  const [selectedJobSegmentsFilter, setSelectedJobSegmentsFilter] = useState(
    []
  );

  const [collegeCount, setCollegeCount] = useState(0);
  const [selectAllColleges, setSelectAllColleges] = useState(false);
  const [selectAllCollegeList, setSelectAllCollegeList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [toggleOtherInfo, setToggleOtherInfo] = useState(true);
  const [plans, setPlans] = useState([]);

  const [applyLink, setApplyLink] = useState("");

  const initialJobDetails = {
    rolesResponsibilities: EditorState.createEmpty(),
    perksBenefits: EditorState.createEmpty(),
    workTiming: "",
    probationPeriod: "",
    travelAllowances: "",
    genderPreferences: "",
    jobDetailDoc: "",
    serviceBondDetails: EditorState.createEmpty(),
    workingDays: "",
    serviceBond: "",
    jobCategory: "",
    planSelected: null,
    qualifications: [],
    bond_condition: "",
    roundDetails: [],
    portalDetails: []
  };
  const [jobDetails, setJobDetails] = useState(initialJobDetails);

  const [educationTypeSelected, setEducationTypeSelected] = useState(null);
  const initialRoundDetails = { roundType: null, roundTime: "", venueType: "" };
  const [roundDetails, setRoundDetails] = useState(initialRoundDetails);

  const initialPortalDetails = {
    jobPortalDetails: null,
    jobPortalJobId: "",
  };
  const [portalDetails, setPortalDetails] = useState(initialPortalDetails);

  const [serviceBondEditor, setServiceBondEditor] = useState(
    EditorState.createEmpty()
  );

  const getMatchingCategory = (matchId) => {
    if (isThirdPartyJob) {
      matchId = "3RD_PARTY_JOBS";
    }
    const match = jobCategoryList?.filter((item) => item?.id === matchId);

    return match[0];
  };

  const getMatchingElement = (targetMatchId, arrayToSearch, keyToMatch) => {
    if (isThirdPartyJob && keyToMatch === "plan_id") {
      targetMatchId = -1;
    }

    const match = arrayToSearch?.filter(
      (item) => item?.[keyToMatch] === targetMatchId
    );

    return match[0];
  };

  const getJobDetailUrl = () => {
    if (isPublicJob) return `/job/public_job_posting/${jobId}/`;
    else if (isThirdPartyJob)
      return `/job/third_party/job_details/?id=${jobId}`;
    else return `/job/?job_id=${btoa(jobId.toString())}`;
  };

  // Load Job Details from Selected Job ID

  useEffect(() => {
    const getGenderPreference = (genderObj) => {
      let genderStr = "";
      if (genderObj && Object.keys(genderObj)?.length > 0) {
        for (let i in genderObj) {
          genderStr = genderObj[i] ? genderStr.concat(`${i},`) : genderStr;
        }
      }

      return genderStr;
    };

    if (plans?.length > 0) {
      setLoading1(true);
      let jobUrl = getJobDetailUrl();
      apiNiyuktiCall(jobUrl, requestNiyuktiOptions).then((json) => {
        if (json?.success) {
          let details = isPublicJob ? json?.data[0] : json?.data;
          setData(details);

          setIsAiAssessment(details?.auto_ai_assessment);
          setJdVsCvid(details?.jdvscvid);
          setAiAssessmentId(details?.aiassessmentid);

          setJobTitle(details?.job_title);
          setJobTypeId(details?.job_type);
          setJobTypeName(details?.job_type_name);
          setStartDate(details?.job_duration_start);
          setEndDate(details?.job_duration_end);
          setInternshipSalaryType(details?.internship_salary_type);
          setStipend(
            details?.stipend_max ? details?.stipend_max : details?.stipend
          );
          setMinCtc(details?.ctc_min);
          setMaxCtc(details?.ctc_max);
          setSalaryType(details?.salary_type || details?.job_payment_type);
          setApplyLink(details?.apply_link);
          setPpoOffered(details?.ppo || false);
          setEmpTypeId(isThirdPartyJob ? 1 : details?.employment_type);

          details?.job_description &&
            setEditorState(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(details?.job_description)
                )
              )
            );
          setJobPaymentType(
            isThirdPartyJob ? "UNPAID" : details?.job_payment_type
          );
          isThirdPartyJob
            ? setJobTmcEditor(
                EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML("<p>3rd Party Jobs</p>")
                  )
                )
              )
            : details?.job_tmc &&
              setJobTmcEditor(
                EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(details?.job_tmc)
                  )
                )
              );
          setNoEquity(
            isThirdPartyJob
              ? true
              : details?.equity_max === 0 && details?.equity_min === 0
          );
          setMinEquity(details?.equity_min);
          setMaxEquity(details?.equity_max);
          setJobLakeBool(details?.posted_on_job_lake);
          setIpoJobBool(
            isThirdPartyJob ? false : details?.is_ipo_jobPaymentType || false
          );
          if (Array.isArray(details?.job_segment))
            setSelectedJobSegment(details?.job_segment[0].id || null);
          else setSelectedJobSegment(details?.job_segment || null);
          setJobRoleId(details?.job_role);
          setSelectedCities(details?.job_location || []);
          setServiceBond(
            isThirdPartyJob ? "no" : details?.service_bond > 0 ? "yes" : "no"
          );

          setServiceBondEditor(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  details?.bond_condition ? details?.bond_condition : "NA"
                )
              )
            )
          );

          setBondMonths(details?.service_bond);
          setAllowRemote(
            isThirdPartyJob
              ? details?.posted_job_location &&
                  details?.posted_job_location?.length === 0
              : details?.job_location && details?.job_location?.length === 0
          );
          setResumeRequired(
            isThirdPartyJob
              ? true
              : details?.documents && details?.documents.includes("Resume")
          );
          setCoverLetterRequired(
            details?.documents && details?.documents.includes("Cover Letter")
          );
          setTranscriptRequired(
            details?.documents && details?.documents.includes("Transcript")
          );
          setIdProofRequired(
            isThirdPartyJob
              ? true
              : details?.documents &&
                  details?.documents.includes(
                    "ID Proof (e.g. Aadhar Card, PAN Card, etc.)"
                  )
          );

          setEligibleYears(
            details?.eligibility_criteria?.graduation_years
              ? details?.eligibility_criteria?.graduation_years
              : []
          );
          setGraduationBool(
            details?.eligibility_criteria?.education_levels &&
              details.eligibility_criteria.education_levels.includes(
                "Graduation"
              )
          );
          setPostGraduationBool(
            details?.eligibility_criteria?.education_levels &&
              details.eligibility_criteria.education_levels.includes(
                "Post Graduation"
              )
          );
          setPhdBool(
            details?.eligibility_criteria?.education_levels &&
              details.eligibility_criteria.education_levels.includes("Ph.D")
          );
          setSelectedDegrees(details?.eligibility_criteria?.degrees_rank || []);
          setSelectedCourses(details?.eligibility_criteria?.courses_rank || []);
          setSelectedSkills(details?.eligibility_criteria?.skills || []);
          setSelectedSkillsEvaluation(
            details?.eligibility_criteria?.key_skills_evaluation || []
          );
          if (
            details?.eligibility_criteria?.experience &&
            details.eligibility_criteria.experience[0]
          ) {
            let first = "";
            let second = "";
            if (
              !isNaN(details.eligibility_criteria.experience[0].split("-")[0])
            ) {
              first = details.eligibility_criteria.experience[0].split("-");
            }
            if (first.length > 1)
              second = details.eligibility_criteria.experience[0]
                .split("-")[1]
                .split(" ")[0];
            setMinWorkExp(first[0]);
            setMaxWorkExp(second);
          }
          setVacancy(details?.vacancy);
          setInvoicingPeriod(details?.invoicing_period);

          setJobDetails({
            ...jobDetails,
            serviceBond: details?.service_bond,
            rolesResponsibilities: EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(
                  details?.job_description ? details?.job_description : " "
                )
              )
            ),
            perksBenefits: EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(
                  details?.perks_benefits ? details?.perks_benefits : " "
                )
              )
            ),
            workTiming: details?.work_timing,
            workingDays: details?.working_days,
            probationPeriod: details?.probation_period,
            travelAllowances: details?.travel_allowances,
            genderPreferences: getGenderPreference(details?.gender_preference),
            jobDetailDoc: null,
            serviceBondDetails: EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(
                  details?.service_bond_details
                    ? details?.service_bond_details
                    : "  "
                )
              )
            ),
            jobCategory: getMatchingCategory(details?.job_category),
            qualifications: details?.eligibility_criteria?.qualifications || [],
            planSelected: getMatchingElement(
              details?.job_plan?.plan_id,
              plans,
              "plan_id"
            ),
            roundDetails: (isPublicJob
              ? details?.job_round_new
              : details?.job_rounds
            )?.map((item) => ({
              id: item?.round,
              name: item?.round_name,
              venueType: item?.venue,
              duration: item?.duration,
              is_hide: item?.is_hide,
            })),
            portalDetails: details?.job_portal_map_data?.map((item) => ({
              job_portal_code: item?.job_portal_code,
              job_portal_id: item?.job_portal_id,
              job_portal_job_id: item?.job_portal_job_id,
            })),
            bond_condition: details?.bond_condition
              ? details?.bond_condition
              : "NA",
            applyLink: details?.apply_link,
            postedLocation: details?.posted_job_location
              ? details?.posted_job_location
              : [],
            postedSkills: details?.posted_job_skills
              ? details?.posted_job_skills
              : [],
            vendorName: details?.vendor_name,
            vendorJobLink: details?.jd_link,
          });
          if (details?.job_location?.length > 0 && details?.work_from_home) {
            setWorkLocationModes({ wfh: "", hybrid: "hybrid" });
          } else if (details?.work_from_home) {
            setWorkLocationModes({ wfh: "wfh", hybrid: "" });
          } else {
            setWorkLocationModes({ wfh: "wfo", hybrid: "" });
          }

          setSelectedRounds(details?.job_rounds || []);
          setIsOffCampus(
            isThirdPartyJob ? true : details.hiring_type === "OFF CAMPUS"
          );
          setSelectedCollegesFromAPI(details.college);
          setSelectedOffCampusCourses(details?.eligibility_criteria?.courses);
          setGlobalStartDate(details?.apply_start_date);
          setGlobalEndDate(isThirdPartyJob ? formatDateObject(new Date(new Date().setDate(new Date().getDate() + 90))) : details?.apply_end_date);

          const temp = {};
          details?.eligibility_criteria &&
            details?.eligibility_criteria?.courses?.forEach((item) => {
              if (temp[item.degree_name] === undefined)
                temp[item.degree_name] = [{ ...item }];
              else temp[item.degree_name].push({ ...item });
            });
          setQualificationData(temp);
        } else FailureAlert(json.error);
        setLoading1(false);
      });
    }
  }, [jobId, plans]);

  useEffect(() => {
    setLoading(true);
    apiCall(
      `/api/education/job/get_specialization`,
      requestPraveshOptions
    ).then((json) => {
      if (json.success) {
        var map = {};
        for (let i = 0; i < json.data.length; i++) {
          var degree_name = json.data[i].degree.name;
          if (!map[degree_name]) {
            map[degree_name] = [];
            map[degree_name]["select_all"] = false;
          }
          let s = {};
          s["isChecked"] = false;
          s["degree"] = json.data[i].degree;
          s["specialization"] = json.data[i].specialization;
          map[degree_name].push(s);
        }
        setOffCampusAllCourses(map);
        setLoading(false);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPlans = async () => {
    const endPoint = `/api/admin/company/active_plans/?company_id=${btoa(
      cmpId
    )}`;

    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setPlans(res?.data?.data?.plans);
      } else {
        FailureAlert(res?.data?.error);
      }
    } catch (error) {
      throw error;
    }
  };

  // Finding Job Types
  // getting all plan types
  useEffect(() => {
    apiNiyuktiCall("/job/type/", requestNiyuktiOptions).then((json) => {
      if (json.success) setJobTypes(json.data);
      else FailureAlert(json.error);
    });

    if (plans?.length === 0) {
      getPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Employment Types
  useEffect(() => {
    apiNiyuktiCall("/job/emp_type/", requestNiyuktiOptions).then((json) => {
      if (json?.success) setEmpTypes(json?.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Active Years
  useEffect(() => {
    apiCall("/api/admin/active_year/", requestPraveshOptions).then((json) => {
      if (json.success) {
        let arr = [];
        json.data.forEach((year) => arr.push(year.year));
        setActiveYears(arr);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isdebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (value) => {
    // http://127.0.0.1:8000/api/education/skills?skill_type=1&skill_name=html&result_count=50
    if (value)
      apiCall(
        `/api/education/skills?&skill_name=${value}&result_count=50`,
        requestPraveshOptions
      ).then((json) => {
        if (json.success) setAllSkills(json.data.skills);
        else FailureAlert(json.error);
      });
  };

  const optimizedFn = useCallback(isdebounce(handleChange), []);

  // Finding Job Skills
  // useEffect(() => {
  //   apiCall("/api/education/skills", requestPraveshOptions).then((json) => {
  //     if (json.success) setAllSkills(json.data.skills);
  //     else FailureAlert(json.error);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Finding Job Segments
  useEffect(() => {
    apiNiyuktiCall("/job/segment/", requestNiyuktiOptions).then((json) => {
      if (json.success) setAllJobSegments(json.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Job Roles
  useEffect(() => {
    if (!selectedJobSegment) return;
    apiNiyuktiCall(
      `/job/role/?job_segment_id=${selectedJobSegment}`,
      requestNiyuktiOptions
    ).then((json) => {
      if (json.success) {
        let tmpArray = [];
        for (var i = 0; i < json.data.length; i++) {
          let obj = {
            id: parseInt(json.data[i].id),
            role: json.data[i].job_role_name,
          };
          tmpArray.push(obj);
        }
        setJobRoles(tmpArray);
      } else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobSegment]);

  // Finding Job Rounds
  useEffect(() => {
    apiNiyuktiCall("/job/round/", requestNiyuktiOptions).then((json) => {
      if (json.success) setAllRounds(json.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Job Portals
  useEffect(() => {
    apiPraveshCall(
      "/api/admin/get_vendor_portal_data/",
      requestPraveshOptions
    ).then((json) => {
      if (json.success) setJobPortals(json.data);
      else FailureAlert(json.error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Cities
  useEffect(() => {
    apiCall("/api/location/city/", requestPraveshOptions).then((json) => {
      setAllCities(json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Finding Degrees based on Qualifications
  useEffect(() => {
    if (graduationBool || postGraduationBool || phdBool) {
      let arr = [];
      if (graduationBool) arr.push(3);
      if (postGraduationBool) arr.push(4);
      if (phdBool) arr.push(5);
      apiCall(
        `/api/education/get_degree?education_level=${arr}`,
        requestPraveshOptions
      ).then((json) => {
        if (json.success) setAllDegrees(json.data);
        else FailureAlert(json.error);
      });
    } else {
      setAllDegrees([]);
    }
  }, [graduationBool, postGraduationBool, phdBool]);

  // Finding Degrees based on Qualifications - College Filter
  useEffect(() => {
    if (graduationBoolFilter || postGraduationBoolFilter || phdBoolFilter) {
      let arr = [];
      if (graduationBoolFilter) arr.push(3);
      if (postGraduationBoolFilter) arr.push(4);
      if (phdBoolFilter) arr.push(5);
      apiCall(
        `/api/education/get_degree?education_level=${arr}`,
        requestPraveshOptions
      ).then((json) => {
        if (json.success) setAllDegrees(json.data);
        else FailureAlert(json.error);
      });
    } else {
      setAllDegrees([]);
      setSelectedDegreesFilter([]);
    }
  }, [graduationBoolFilter, postGraduationBoolFilter, phdBoolFilter]);

  // Finding Courses based on Degrees
  useEffect(() => {
    if (selectedDegrees && selectedDegrees.length > 0) {
      let arr = [];
      selectedDegrees.forEach((s) => arr.push(s.id));
      apiCall(
        `/api/education/get_specialization?degree_id=${arr}`,
        requestPraveshOptions
      ).then((json) => {
        if (json.success) setAllCourses(json.data);
        else FailureAlert(json.error);
      });
    } else {
      setAllCourses([]);
    }
  }, [selectedDegrees]);

  // Call getAllColleges() if skills/courses ranks changes
  useEffect(() => {
    setNextUrl(null);
    setEnd(false);
    setCollegeList([]);
    getAllColleges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkills]);

  const onEditorStateChange = (s) => {
    setEditorState(s);
    setDescription(draftToHtml(convertToRaw(s.getCurrentContent())));
  };

  const jobTmcEditorState = (s) => {
    setJobTmcEditor(s);
    setJobTmc(draftToHtml(convertToRaw(s.getCurrentContent())));
  };

  const serviceBondEditorState = (s) => {
    setServiceBondEditor(s);
    setJobDetails({
      ...jobDetails,
      bond_condition: draftToHtml(convertToRaw(s.getCurrentContent())),
    });
  };

  const searchCollege = (val) => {
    let tempObj = {};
    Object.keys(collegeList).forEach((clg) => {
      if (clg.toLowerCase().includes(val.toLowerCase())) {
        tempObj[clg] = { ...collegeList[clg] };
      }
    });
    setFilteredCollegeList(tempObj);
  };

  const checkFilterDegrees = () => {
    if (selectedDegreesFilter.length > 0) {
      return `&degree=${selectedDegreesFilter.map((item) => item.id)}`;
    } else return "";
  };
  const checkFilterCourses = () => {
    if (selectedCoursesFilter.length > 0) {
      return `&specialization=${selectedCoursesFilter.map(
        (item) => item.specialization_id
      )}`;
    } else return "";
  };
  const checkFilterJobSegments = () => {
    if (selectedJobSegmentsFilter.length > 0) {
      return `&job_segment=${selectedJobSegmentsFilter.map((item) => item.id)}`;
    } else return "";
  };
  const checkFilterLocations = () => {
    if (selectedLocationsFilter.length > 0) {
      return `&city=${selectedLocationsFilter.map((item) => item.city_id)}`;
    } else return "";
  };
  const checkFilterMinCtc = () => {
    if (minCtcFilter) return `&min_ctc=${minCtcFilter}`;
    else return "";
  };
  const checkFilterQualifications = () => {
    if (graduationBoolFilter || postGraduationBoolFilter || phdBoolFilter) {
      let tempArr = [];
      if (graduationBoolFilter) tempArr.push(3);
      if (postGraduationBoolFilter) tempArr.push(4);
      if (phdBoolFilter) tempArr.push(5);
      return `&qualification_required=${tempArr}`;
    } else return "";
  };

  const getAllColleges = () => {
    const tempDegreeData = selectedCourses
      ? selectedCourses.map((item) => ({
          id: item.degree_id,
          name: item.degree_name,
        }))
      : [];
    const tempSpecData = selectedCourses
      ? selectedCourses.map((item) => ({
          id: item.specialization_id,
          name: item.specialization_name,
        }))
      : [];
    let skillsArrayforPost = [];
    if (selectedSkills) {
      selectedSkills.forEach((skill) => {
        let obj = {
          type: skill.type,
          skill_id: skill.skill_id,
          skill_name: skill.skill_name,
          skill_type: skill.skill_type,
          rating_name: "Expert",
          rating_value: 5,
          user_created: skill.user_created,
        };
        skillsArrayforPost.push(obj);
      });
    }
    const urlCollegeRank = nextUrl
      ? decodeURI(nextUrl)
      : url +
        `/api/company/new_college_rank/?admin=required&uuid=${collegeUUID}${checkFilterDegrees()}${checkFilterCourses()}${checkFilterJobSegments()}${checkFilterLocations()}${checkFilterMinCtc()}${checkFilterQualifications()}`;
    setIsApiCall(true);
    setCollegeLoading(true);
    const requestForAllColleges = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        company_id: cmpId,
        company_skills: skillsArrayforPost,
        company_courses: tempDegreeData,
        company_specialization: tempSpecData,
      }),
    };
    fetch(urlCollegeRank, requestForAllColleges)
      .then((res) => res.json())
      .then((res) => {
        if (res?.success) {
          let temp = {};
          res?.data?.data?.results?.forEach((data) => {
            temp[data?.college_name] = {
              college_name: data?.college_name,
              college_id: data?.college_id,
              college_logo: data?.college_logo,
              college_location: data?.college_location,
              preferences: [],
              select_all: false,
              apply_start_date: null,
              apply_end_date: null,
            };
          });
          if (res?.data?.data?.next) setNextUrl(res?.data?.data?.next);
          else setEnd(true);
          setApplyFiltersBool(false);
          setRemoveFiltersBool(false);
          setCollegeCount(res?.data?.data?.count);
          setSelectAllCollegeList(res?.data?.college_ids);
          setCollegeList({ ...collegeList, ...temp });
          setFilteredCollegeList({ ...filteredCollegeList, ...temp });
        }
        setCollegeLoading(false);
      })
      .catch((err) =>
        err instanceof TypeError ? console.log(err) : alert(err)
      );
    setIsApiCall(false);
  };

  // Load Colleges on Scroll - Backend Pagination
  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && !isApiCall) {
        getAllColleges();
      }
    }
  };

  // Particular college details and degrees
  const getDegreeAndCourses = (clg_id, clg_name) => {
    if (collegeList[clg_name].preferences.length === 0) {
      setCircularLoader(true);
      apiCall(
        `/api/company/new_college_rank/education/?college_id=${clg_id}`,
        requestPraveshOptions
      ).then((res) => {
        if (res.success && res.data) {
          let details = [];
          res.data.forEach((dets) => {
            const { degree_id, degree_name, specialization } = dets;
            const tempObj = { degree_id, degree_name, specialization };
            dets.specialization.forEach((spec) => {
              spec.selected = false;
            });
            dets.select_all = false;
            details.push(tempObj);
          });
          collegeList[clg_name].preferences = res.data;
          filteredCollegeList[clg_name].preferences = res.data;
          setCollegeList({ ...collegeList });
          setFilteredCollegeList({ ...filteredCollegeList });
          setCircularLoader(false);
        } else {
          FailureAlert("Error Occured");
        }
      });
    }
  };

  // ON CAMPUS
  const handleCollegeSelectSingleSpecialization = (
    event,
    college_name,
    degree_id
  ) => {
    var specialization_id = event.target.value;
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_name].preferences.filter(
      (data) => data.degree_id === parseInt(degree_id)
    )[0];
    var allSpecializationChecked = true;
    var allDegreesChecked = true;
    newDegreeObj.specialization.forEach((spec) => {
      if (spec.specialization_id === parseInt(specialization_id))
        spec.selected = !spec.selected;
      if (spec.selected === false) allSpecializationChecked = false;
    });
    newDegreeObj.select_all = allSpecializationChecked;
    newCollegeList[college_name].preferences.forEach((education) => {
      if (education.select_all === false) allDegreesChecked = false;
    });
    newCollegeList[college_name].select_all = allDegreesChecked;
    setCollegeList({ ...newCollegeList });
  };

  // ON CAMPUS
  const addSelectedCollege = (event, college_name) => {
    event.preventDefault();
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_name].preferences;
    var specialization_selected = false;
    newDegreeObj.forEach((degree) => {
      degree.specialization.forEach((spec) => {
        if (spec.selected) specialization_selected = true;
      });
    });
    if (specialization_selected) {
      let mySet = new Set(selectedCollegesNames);
      mySet.add(college_name);
      setSelectedCollegesNames(mySet);
      setSelectedCollegesNamesArr(Array.from(mySet));
      SuccessAlert(college_name + " Added Successfully");
    } else FailureAlert("Please Select Atleast One Course or Specialization");
  };

  // ON CAMPUS
  const removeCollege = (event, college_name) => {
    event.preventDefault();
    let mySet = new Set(selectedCollegesNames);
    mySet.delete(college_name);
    setSelectedCollegesNames(mySet);
    setSelectedCollegesNamesArr(Array.from(mySet));
    SuccessAlert(college_name + " Removed Successfully");
  };

  // Format for Colleges Added
  useEffect(() => {
    let tempClgArray = [];
    selectedCollegesNames.forEach((clg_name) => {
      let clg = collegeList[clg_name];
      let tempObj = {
        college_name: clg.college_name,
        college_id: clg.college_id,
        select_all: clg.select_all,
        apply_start_date: clg.apply_start_date,
        apply_end_date: clg.apply_end_date,
        preferences: [],
      };
      let arr = [];
      clg.preferences.forEach((p) => {
        if (p.select_all) arr.push(p);
        else {
          if (p.specialization.some((s) => s.selected)) {
            let obj = {
              degree_id: p.degree_id,
              degree_name: p.degree_name,
              specialization: p.specialization.filter((s) => s.selected),
            };
            arr.push(obj);
          }
        }
      });
      tempObj.preferences = arr;
      tempClgArray.push(tempObj);
    });
    setSelectedColleges(tempClgArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollegesNames]);

  // ON CAMPUS
  const updateCollegeListAllCourses = (college_name, value) => {
    var newCollegeList = collegeList;
    var newAllSelectVal = value;
    newCollegeList[college_name].select_all = newAllSelectVal;
    newCollegeList[college_name].preferences.forEach((education) => {
      education.specialization.forEach((spec) => {
        spec.selected = newAllSelectVal;
      });
      education.select_all = newAllSelectVal;
    });
    setCollegeList({ ...newCollegeList });
  };

  // ON CAMPUS
  const handleSelectAllCollegeCourses = (event) => {
    var key = event.target.value;
    var newCollegeList = collegeList;
    updateCollegeListAllCourses(key, !newCollegeList[key].select_all);
  };

  // ON CAMPUS
  const handleCollegeSelectAllSpecialization = (event, clg_name) => {
    var degree_id = event.target.value;
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[clg_name].preferences.filter(
      (data) => data.degree_id === parseInt(degree_id)
    )[0];
    var allDegreesChecked = true;
    newDegreeObj.specialization.forEach((spec) => {
      spec.selected = !newDegreeObj.select_all;
    });
    newDegreeObj.select_all = !newDegreeObj.select_all;
    newCollegeList[clg_name].preferences.forEach((education) => {
      if (education.select_all === false) allDegreesChecked = false;
    });
    newCollegeList[clg_name].select_all = allDegreesChecked;
    setCollegeList({ ...newCollegeList });
  };

  // ON CAMPUS
  const handleSelectStartDate = (event, clg_name) => {
    var newCollegeList = collegeList;
    newCollegeList[clg_name].apply_start_date = event.target.value;
    setCollegeList({ ...newCollegeList });
  };

  // ON CAMPUS
  const handleSelectEndDate = (event, clg_name) => {
    var newCollegeList = collegeList;
    newCollegeList[clg_name].apply_end_date = event.target.value;
    setCollegeList({ ...newCollegeList });
  };

  // OFF CAMPUS - PREVIOUS SELETED COURSES
  useEffect(() => {
    if (
      selectedOffCampusCourses &&
      offCampusAllCourses &&
      selectedOffCampusCourses.length > 0 &&
      Object.keys(offCampusAllCourses).length > 0
    ) {
      var temp = { ...offCampusAllCourses };
      selectedOffCampusCourses.forEach((c) => {
        if (temp[c.degree_name]) {
          let len = temp[c.degree_name].length;
          for (let i = 0; i < len; i++) {
            if (temp[c.degree_name][i].specialization.id === c.id)
              temp[c.degree_name][i].isChecked = true;
          }
        }
      });
      setOffCampusAllCourses(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffCampusCourses, loading]);

  // OFF CAMPUS
  const handleSelectAllOffCampusCourses = () => {
    var key = !isOffCampusAllCourses;
    var temp = { ...offCampusAllCourses };
    var dict = Object.keys(offCampusAllCourses);
    dict.forEach((item) => {
      temp[item].select_all = key;
      var len = temp[item].length;
      for (let i = 0; i < len; i++) {
        temp[item][i].isChecked = key;
      }
    });
    setIsOffCampusAllCourses(key);
    setOffCampusAllCourses(temp);
  };

  // OFF CAMPUS
  const handleSelectSingleOffCampusSpecialization = (item, course) => {
    var temp = { ...offCampusAllCourses };
    var len = offCampusAllCourses[course].length;
    var allSpecializationsChecked = true;
    for (let i = 0; i < len; i++) {
      if (item.specialization.id === temp[course][i].specialization.id)
        temp[course][i].isChecked = !temp[course][i].isChecked;
    }
    for (let i = 0; i < len; i++) {
      if (!temp[course][i].isChecked) {
        allSpecializationsChecked = false;
        setIsOffCampusAllCourses(false);
        break;
      }
    }
    temp[course].select_all = allSpecializationsChecked;
    var dict = Object.keys(temp);
    var flag = true;
    dict.forEach((item) => {
      if (!temp[item].select_all) {
        flag = false;
      }
    });
    setIsOffCampusAllCourses(flag);
    setOffCampusAllCourses(temp);
  };

  // OFF CAMPUS
  const selectAllOffCampusSpecializations = (degree_name) => {
    var temp = { ...offCampusAllCourses };
    var key = !temp[degree_name].select_all;
    var len = temp[degree_name].length;
    for (let i = 0; i < len; i++) {
      temp[degree_name][i].isChecked = key;
    }
    temp[degree_name].select_all = key;
    var dict = Object.keys(temp);
    var flag = true;
    dict.forEach((item) => {
      if (!temp[item].select_all) {
        flag = false;
      }
    });
    setIsOffCampusAllCourses(flag);
    setOffCampusAllCourses(temp);
  };

  const handleAddingVacancy = (e) => {
    if (!jobDetails?.planSelected) {
      FailureAlert("Select Atleast One Plan");
      return;
    }

    if (jobDetails?.planSelected?.available === "available") {
      setVacancy(e.target.value);
      // return
    } else if (
      parseInt(jobDetails?.planSelected?.available) < parseInt(e.target.value)
    ) {
      FailureAlert(
        `Vacancies can't be more than available(${jobDetails?.planSelected?.available}) under plan`
      );
    } else {
      setVacancy(e.target.value);
    }
  };

  const handleAddingEducationTypePercentage = () => {
    if (!minPercentage) {
      FailureAlert("Please Enter min percentage");
      return;
    }
    if (!educationTypeSelected) {
      FailureAlert("Please select education type");
      return;
    }

    let newQualArr = jobDetails?.qualifications;

    // for avoiding duplicacy
    let checkPreExisting = jobDetails?.qualifications?.filter(
      (item) => item?.education_type === educationTypeSelected?.education_type
    );

    //  if(!(checkPreExisting === -1)){
    //   FailureAlert("Already added Education Type");
    //   return
    // }

    if (checkPreExisting?.length > 0) {
      FailureAlert("Already added Education Type");
      return;
    }

    let newEdulevelCriteria = {
      percentage: parseInt(minPercentage),
      education_type: educationTypeSelected?.education_type,
      education_type_name: educationTypeSelected?.education_type_name,
    };

    newQualArr.push(newEdulevelCriteria);
    setJobDetails({ ...jobDetails, qualifications: newQualArr });
  };

  const handleDeleteEducationTypePercent = (index) => {
    const tempArrQualifications = jobDetails?.qualifications;
    tempArrQualifications?.splice(index, 1);
    setJobDetails({ ...jobDetails, qualifications: tempArrQualifications });
  };

  useEffect(() => {
    setEducationTypeSelected(null);
    setMinPercentage("");
  }, [jobDetails?.qualifications?.length]);

  //  for managing round details:

  const handleAddingRounds = () => {
    if (!roundDetails?.roundType) {
      FailureAlert("Please select Round");
      return;
    }

    let newRoundsArr = jobDetails?.roundDetails;

    // for avoiding duplicacy
    let checkPreExisting = jobDetails?.roundDetails?.findIndex(
      (item) => item?.id === roundDetails?.roundType?.id
    );

    if (!(checkPreExisting === -1)) {
      FailureAlert("Already added Round Type");
      return;
    }
    let newRound = {
      id: roundDetails?.roundType?.id,
      name: roundDetails?.roundType?.name,
      venueType: roundDetails?.venueType?.val,
      duration: roundDetails?.roundTime?.val,
    };

    newRoundsArr.push(newRound);
    setJobDetails((prev) => ({ ...prev, roundDetails: newRoundsArr }));
  };

  const hanleDeleteRound = (index, deletable) => {
    if (deletable) {
      const tempRoundArr = jobDetails?.roundDetails;
      tempRoundArr?.splice(index, 1);
      setJobDetails({ ...jobDetails, roundDetails: tempRoundArr });
    } else {
      FailureAlert("Students are already present in this round");
    }
  };

  const handleAddPortal = () => {
    if (!portalDetails?.jobPortalDetails) {
      FailureAlert("Please select Portal Code");
      return;
    }
    if (!portalDetails?.jobPortalJobId) {
      FailureAlert("Please add Id of the job you want to add");
      return;
    }
    let checkPreExisting = jobDetails?.portalDetails.findIndex(
      (item) =>
        item?.job_portal_id == portalDetails?.jobPortalDetails?.vendor_id
    );
    if (!(checkPreExisting === -1)) {
      FailureAlert("Already added Portal Code");
      setPortalDetails(initialPortalDetails);
      return;
    }

    let newPortal = {
      job_portal_id: portalDetails?.jobPortalDetails?.vendor_id,
      job_portal_code: portalDetails?.jobPortalDetails?.job_portal_code,
      job_portal_job_id: portalDetails?.jobPortalJobId,
    };
    setJobDetails((prev) => ({
      ...prev,
      portalDetails: [...prev.portalDetails, newPortal],
    }));
  };

  const hanleDeletePortal = (index) => {
    const tempPortalArr = jobDetails?.portalDetails;
    tempPortalArr?.splice(index, 1);
    setJobDetails({ ...jobDetails, portalDetails: tempPortalArr });
  };

  useEffect(() => {
    setRoundDetails(initialRoundDetails);
  }, [jobDetails?.roundDetails?.length]);
  useEffect(() => {
    setPortalDetails(initialPortalDetails);
  }, [jobDetails?.portalDetails?.length]);

  useEffect(() => {
    let offCampusSpecializations = [];
    let offCampusDegrees = [];

    var dict = Object.keys(offCampusAllCourses);
    dict.forEach((item) => {
      let flag = true;
      let degreeDetails = {};
      let degreeSpecs = [];
      let allspecSelectedCounter = 0;

      for (let i in offCampusAllCourses[item]) {
        if (offCampusAllCourses[item][i].isChecked) {
          ++allspecSelectedCounter;
          if (flag) {
            degreeDetails = {
              id: offCampusAllCourses[item][i].degree?.id,
              name: offCampusAllCourses[item][i].degree?.name,
              education_type:
                offCampusAllCourses[item][i].degree?.education_type,
              all_spec_selected: false,
              all_degree_selected: false,
              education_type_name:
                offCampusAllCourses[item][i].degree?.education_type_name,
              spec: [],
            };

            flag = false;
          }

          degreeSpecs.push(offCampusAllCourses[item][i].specialization);

          offCampusSpecializations.push(
            offCampusAllCourses[item][i].specialization
          );
        }
        if (
          allspecSelectedCounter > 0 &&
          offCampusAllCourses[item]?.length === parseInt(i) + 1
        ) {
          degreeDetails = {
            ...degreeDetails,
            all_spec_selected:
              allspecSelectedCounter === offCampusAllCourses[item]?.length
                ? true
                : false,
            spec: degreeSpecs,
          };
          offCampusDegrees?.push(degreeDetails);
        }
      }
    });

    setDegreesPayload(offCampusDegrees);
    setSpecsPayload(offCampusSpecializations);
  }, [offCampusAllCourses]);

  // Edit the previous job

  const editExistingJobs = (e) => {
    e.preventDefault();
    if (salaryType === "PAID" && minCtc > maxCtc) {
      FailureAlert("Salary Range is Incorrect...");
      return;
    }
    if (
      !resumeRequired &&
      !transcriptRequired &&
      !coverLetterRequired &&
      !idProofRequired
    ) {
      FailureAlert("Select atleast one Required Document to continue");
      return;
    }
    if (
      jobTypeName === "Internship" &&
      internshipSalaryType === "PAID" &&
      !stipend
    ) {
      FailureAlert("Enter Stipend for Internship...");
      return;
    }
    if (
      !allowRemote &&
      selectedCities.length <= 0 &&
      (workLocationModes?.wfh === "wfo" ||
        workLocationModes?.hybrid === "hybrid")
    ) {
      FailureAlert("Select atleast one City to continue...");
      return;
    }

    if (eligibleYears.length <= 0) {
      FailureAlert("Select atleast one Eligible Year to continue...");
      return;
    }
    if (selectedSkills.length <= 0) {
      FailureAlert("Select atleast one Skill to continue...");
      return;
    }
    if (!minWorkExp || !maxWorkExp) {
      FailureAlert("Select Work Experience to continue...");
      return;
    }
    if (parseInt(minWorkExp) > parseInt(maxWorkExp)) {
      FailureAlert("Select correct range for work Experience...");
      return;
    }
    if (jobDetails?.roundDetails?.length === 0) {
      FailureAlert("Select atleast one Round to continue...");
      return;
    }

    if (isOffCampus && specsPayload?.length <= 0) {
      FailureAlert("Select Atleast one Course/Specialization to continue...");
      return;
    }

    if (
      !selectAllColleges &&
      !isOffCampus &&
      !jobLakeBool &&
      selectedCollegesFromAPI?.length <= 0 &&
      selectedColleges.length <= 0
    ) {
      FailureAlert("Select Atleast one College to continue...");
      return;
    }

    if (!noEquity && !minEquity && !maxEquity) {
      FailureAlert("Enter Equity details");
      return;
    }

    if (!globalStartDate) {
      FailureAlert("Please select Global Apply start date");
      return;
    }

    if (!globalEndDate) {
      FailureAlert("Please select Global Apply End date");
      return;
    }

    if (!empTypeId) {
      FailureAlert("Please select Employment Type");
      return;
    }

    if (!selectedJobSegment && !jobRoleId) {
      FailureAlert("Please select job segment & job role");
      return;
    }
    if (!jobTmc && !data.job_tmc) {
      FailureAlert("Job Terms & Conditions can't be left blank !");
      return;
    }

    if (isThirdPartyJob && !jobDetails?.applyLink) {
      FailureAlert("Please enter a valid apply Link");
      return;
    }

    let docs = [];
    if (resumeRequired) docs.push("Resume");
    if (coverLetterRequired) docs.push("Cover Letter");
    if (transcriptRequired) docs.push("Transcript");
    if (idProofRequired)
      docs.push("ID Proof (e.g. Aadhar Card, PAN Card, etc.)");

    let educationLevels = [];
    if (graduationBool) educationLevels.push("Graduation");
    if (postGraduationBool) educationLevels.push("Post Graduation");
    if (phdBool) educationLevels.push("Ph.D");

    let skillsArray = [];
    uniqueArray(selectedSkills).forEach((skill) => {
      let obj = {
        type: skill.type,
        skill_id: skill.skill_id,
        skill_name: skill.skill_name,
        skill_type: skill.skill_type,
        rating_name: "Expert",
        rating_value: 5,
        user_created: skill.user_created,
      };
      skillsArray.push(obj);
    });

    let skillsEvaluationArray = [];
    selectedSkillsEvaluation.forEach((skill) => {
      let obj = {
        type: skill.type,
        skill_id: skill.skill_id,
        skill_name: skill.skill_name,
        skill_type: skill.skill_type,
        rating_name: "Expert",
        rating_value: 5,
        user_created: skill.user_created,
      };
      skillsEvaluationArray.push(obj);
    });

    let roundsArray = [];
    let roundCount = 1;
    jobDetails?.roundDetails?.forEach((round) => {
      let obj = {
        round_id: round.id,
        round_no: roundCount++,
        r_name: round.name,
        is_interviewed: true,
        venue: round?.venueType,
        duration: round?.duration,
      };
      roundsArray.push(obj);
    });

    let eligibility = {
      // ...data.eligibility_criteria,
      degrees_rank: uniqueArray(selectedDegrees),
      courses_rank: uniqueArray(selectedCourses),
      ...(isOffCampus && { degrees: degreesPayload }),
      ...(isOffCampus && { courses: specsPayload }),
      ...(!isOffCampus && { degrees: [] }),
      ...(!isOffCampus && { courses: [] }),
      skills: uniqueArray(skillsArray),
      key_skills_evaluation: skillsEvaluationArray,
      experience: {
        min: parseInt(minWorkExp),
        max: maxWorkExp > 10 ? 100 : parseInt(maxWorkExp),
        exp: `${minWorkExp}-${maxWorkExp} years`,
      },
      education_levels: educationLevels,
      graduation_years: eligibleYears.map((ey) => parseInt(ey)),
      qualifications: jobDetails?.qualifications,
    };
    let jobObject = {
      user_id: userId,
      company_id: cmpId,
      user_type_id: userTypeId || 5,
      service_type: serviceType || "POST_JOB",
      transaction_type: transactionType || "DEBIT",
      amount: amount,
      is_posted_by_admin: data.is_posted_by_admin,
      is_posted_by_college: data.is_posted_by_college,
      job_title: jobTitle,
      job_type: jobTypeId,
      job_status: "OPEN",
      job_duration_start: startDate,
      ...(internshipSalaryType && {
        internship_salary_type: internshipSalaryType,
      }),
      job_segment: selectedJobSegment,
      ...(jobTypeName === "Internship" &&
        internshipSalaryType === "PAID" && { stipend: stipend }),
      ...(jobTypeName === "Internship" &&
        internshipSalaryType !== "PAID" && { stipend: null }),
      ...(jobTypeName === "Internship" && { job_duration_end: endDate }),
      ...(jobTypeName === "Internship" && { ppo: ppoOffered }),
      ...(jobTypeName !== "Internship" && { job_duration_end: null }),
      ...(jobTypeName !== "Internship" && { ppo: false }),
      employment_type: empTypeId,
      job_description: description || data.job_description,
      job_role: jobRoleId || data.job_role,
      vacancy: parseInt(vacancy),
      invoicing_period: parseInt(invoicingPeriod),
      salary_type: salaryType ? salaryType : minCtc ? "PAID" : null,
      job_payment_type: jobPaymentType,
      auto_ai_assessment: isAiAssessment,
      jdvscvid: jdvscvid,
      ai_assessment_id: aiassessmentid,
      job_tmc: jobTmc || data.job_tmc,
      ...(salaryType === "PAID" && {
        ctc_min: minCtc ? minCtc : data?.ctc_min,
      }),
      ...(salaryType !== "PAID" && { ctc_min: 0 }),
      ...(salaryType === "PAID" && {
        ctc_max: maxCtc ? maxCtc : data?.ctc_max,
      }),
      ...(salaryType !== "PAID" && { ctc_max: 0 }),
      ...(salaryType === "PAID" && {
        salary_payment_type: paymentType
          ? paymentType
          : data?.salary_payment_type,
      }),
      ...(salaryType !== "PAID" && { salary_payment_type: "" }),
      ...(!noEquity && { equity_min: minEquity }),
      ...(noEquity && { equity_min: 0 }),
      ...(!noEquity && { equity_max: maxEquity }),
      ...(noEquity && { equity_max: 0 }),
      documents: docs,
      backlog: false,
      ...(!allowRemote && { job_location: uniqueArray(selectedCities) }),
      ...(allowRemote && { job_location: [] }),
      work_from_home:
        workLocationModes?.wfh === "wfh" ||
        workLocationModes?.hybrid === "hybrid"
          ? true
          : false,
      allow_remote: allowRemote,
      ...(serviceBond === "yes" && { service_bond: bondMonths }),
      ...(serviceBond === "no" && { service_bond: 0 }),
      ...(serviceBond === "yes" && { is_service_bond: true }),
      ...(serviceBond === "no" && { is_service_bond: false }),
      ...(isOffCampus && { hiring_type: "OFF CAMPUS" }),
      ...(!isOffCampus && { hiring_type: "ON CAMPUS" }),
      eligibility_criteria: eligibility,
      rounds: uniqueArray(roundsArray),
      job_portal_map_data: jobDetails?.portalDetails,
      apply_start_date: globalStartDate,
      apply_end_date: globalEndDate,
      bond_condition: jobDetails?.bond_condition
        ? jobDetails?.bond_condition
        : null,
      model_id: jobDetails?.planSelected?.model_id,
      plan_id: jobDetails?.planSelected?.plan_id || -1,
      posted_on_job_lake: jobLakeBool,
      is_ipo_job: isOffCampus ? ipoJobBool : false,
      is_public_job: isPublicJob ? true : false,
      is_third_party: isThirdPartyJob ? true : false,
      ...(isPublicJob && { public_job_id: jobId }),
      ...(isThirdPartyJob && jobId && { third_party_job_id: parseInt(jobId) }),
      ...(isThirdPartyJob && { apply_link: jobDetails?.applyLink }),
      ...(jobLakeBool && { college: [] }),
      ...(jobLakeBool && { college_list: [] }),
      ...(!jobLakeBool &&
        selectAllColleges && { college_list: selectAllCollegeList }),
      ...(!jobLakeBool && !selectAllColleges && { college_list: [] }),
      ...(!jobLakeBool && isOffCampus && { college: [] }),
      ...(!jobLakeBool &&
        !isOffCampus &&
        selectedColleges.length !== 0 && { college: selectedColleges }),
      ...(!jobLakeBool &&
        !isOffCampus &&
        selectedColleges.length === 0 && {
          college: selectedCollegesFromAPI?.map((clg) => ({
            ...clg,
          })),
        }),
    };

    if (jobDetails?.jobCategory) {
      jobObject = { ...jobObject, job_category: jobDetails?.jobCategory?.id };
    }
    const requestForJobEditing = {
      method: isPublicJob || isClone || isThirdPartyJob ? "POST" : "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(jobObject),
    };
    const checkSelectAllColleges = () => {
      if (selectAllColleges && !jobLakeBool) return `&is_select_all=${true}`;
      else return "";
    };
    setIsLoading(true);
    apiNiyuktiCall(
      isPublicJob || isClone || isThirdPartyJob
        ? `/job/company/job_post/`
        : `/job/company/job_post/?job_id=${btoa(
            jobId.toString()
          )}${checkSelectAllColleges()}`,
      requestForJobEditing
    )
      .then((res) => {
        if (res?.success) {
          SuccessAlert(res?.data?.message);
          if (isClone) {
            getCreditInfo();
          }
        } else {
          const getErrorResponse = (mesgObj) => {
            let responseError = "";
            if (mesgObj && Object.keys(mesgObj)?.length > 0) {
              for (let i in mesgObj) {
                responseError = mesgObj[i] ? mesgObj[i] : res?.error;
              }
            }
            return responseError;
          };
          FailureAlert(
            res?.error ? getErrorResponse(res?.verbose) : "Something went wrong"
          );
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        FailureAlert("Error in Job Posting!!");
      });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deps = [
    Object.keys(collegeList).length,
    Object.keys(filteredCollegeList).length,
    graduationBoolFilter,
    postGraduationBoolFilter,
    phdBoolFilter,
    minCtcFilter,
    selectedJobSegmentsFilter.length,
    selectedLocationsFilter.length,
    selectedDegreesFilter.length,
    selectedCoursesFilter.length,
    removeFiltersBool,
  ];

  useEffect(() => {
    if (
      Object.keys(collegeList).length === 0 &&
      Object.keys(filteredCollegeList).length === 0 &&
      applyFiltersBool
    ) {
      getAllColleges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Object.keys(collegeList).length,
    Object.keys(filteredCollegeList).length,
    applyFiltersBool,
  ]);

  const applyFilters = () => {
    setOpen(false);
    setApplyFiltersBool(true);
    setCollegeList({});
    setFilteredCollegeList({});
  };

  useEffect(() => {
    if (
      Object.keys(collegeList).length === 0 &&
      Object.keys(filteredCollegeList).length === 0 &&
      removeFiltersBool &&
      !graduationBoolFilter &&
      !postGraduationBoolFilter &&
      !phdBoolFilter &&
      minCtcFilter === null &&
      selectedDegreesFilter.length === 0 &&
      selectedCoursesFilter.length === 0 &&
      selectedLocationsFilter.length === 0 &&
      selectedJobSegmentsFilter.length === 0
    ) {
      getAllColleges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const removeFilters = () => {
    setOpen(false);
    setCollegeList({});
    setFilteredCollegeList({});
    setGraduationBoolFilter(false);
    setPostGraduationBoolFilter(false);
    setPhdBoolFilter(false);
    setSelectedDegreesFilter([]);
    setSelectedCoursesFilter([]);
    setSelectedLocationsFilter([]);
    setMinCtcFilter(null);
    setSelectedJobSegmentsFilter([]);
    setRemoveFiltersBool(true);
  };

  useEffect(() => {
    if (jobTypeName === "Internship" && ppoOffered) setSalaryType("PAID");
  }, [jobTypeName, ppoOffered]);

  useEffect(() => {
    if (!ppoOffered && jobTypeName === "Internship") setSalaryType("UNPAID");
  }, [ppoOffered, jobTypeName]);

  return !loading && !loading1 && data ? (
    <div className="post__job" style={{ width: "96%", marginLeft: "2%" }}>
      <Form
        className="post__job__form"
        id="post__job__form__details"
        onSubmit={(e) => {
          editExistingJobs(e);
        }}
      >
        {/* BASICS */}
        {isThirdPartyJob ? (
          <div
            style={{
              display: "flex",
              flexDirection: "col",
              justifyContent: "space-between",
              margintBottom: "20px",
            }}
          >
            {/* {jobDetails?.vendorName ?  */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button2 color="primary" variant="contained">
                {jobDetails?.vendorName}
              </Button2>
            </div>
            {/* : null} */}
            {/* {jobDetails?.vendorJobLink ?  */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button2
                color="secondary"
                style={{ cursor: "pointer" }}
                variant="contained"
                onClick={() => {
                  window.open(jobDetails?.vendorJobLink, "_blank");
                }}
              >
                Vendor Job Link
              </Button2>
            </div>
            {/* : null} */}
            {/* {applyLink ?  */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button2
                variant="conatined"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    applyLink.match(/^https?:/) ? applyLink : "//" + applyLink,
                    "_blank"
                  );
                }}
              >
                Career Page Link
              </Button2>
            </div>
            {/* :null} */}
          </div>
        ) : null}
        <div>
          <h4>Basics</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />

          <Form.Row style={{ alignItems: "center" }}>
            <Form.Group as={Col} controlId="jobCategory">
              <Form.Label>Job Category</Form.Label>
              <Autocomplete
                id="combo-box-demo"
                options={jobCategoryList}
                value={jobDetails?.jobCategory}
                getOptionLabel={(item) => item?.name}
                onChange={(e, newValue) => {
                  setJobDetails((prevData) => ({
                    ...prevData,
                    jobCategory: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label=""
                    variant="outlined"
                    style={{ backgroundColor: "white" }}
                  />
                )}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="planType">
              <Form.Label>Plan Type*</Form.Label>

              <Autocomplete
                id="select-plan-type"
                options={plans}
                getOptionLabel={(item) => item?.name}
                value={jobDetails?.planSelected}
                onChange={(e, item) => {
                  const plan = item ? item : null;
                  setJobDetails({ ...jobDetails, planSelected: plan });
                }}
                // disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Plan Type"
                    variant="outlined"
                    style={{ backgroundColor: "white" }}
                  />
                )}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="jobTitle">
              <Form.Label>Title*</Form.Label>
              <Form.Control
                name="jobTitle"
                type="text"
                placeholder="Job Title goes here...*"
                value={jobTitle}
                required
                onChange={(e) => {
                  setJobTitle(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          {/* Job Types, Start Date and End Date */}
          <Form.Row>
            <Form.Group as={Col} controlId="jobType">
              <Form.Label>Job Type </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setJobTypeId(JSON.parse(e.target.value).id);
                  setJobTypeName(JSON.parse(e.target.value).job_type);
                }}
              >
                <option
                  value={JSON.stringify({
                    id: jobTypeId,
                    job_type: jobTypeName,
                  })}
                >
                  --{jobTypeName}--
                </option>
                {jobTypes.map((e, key) => {
                  return (
                    <option key={key} value={JSON.stringify(e)}>
                      {" "}
                      {e.job_type}{" "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="startDate">
              <Form.Label>Start Date </Form.Label>
              <Form.Control
                name="startDate"
                type="date"
                placeholder="YYYY-MM-DD"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Form.Group>
            {jobTypeName === "Internship" ? (
              <Form.Group as={Col} controlId="endDate">
                <Form.Label>End Date </Form.Label>
                <Form.Control
                  name="endDate"
                  type="date"
                  placeholder="YYYY-MM-DD"
                  min={startDate ? startDate : ""}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
            ) : null}
          </Form.Row>
          {/* Internship payment type */}
          {jobTypeName === "Internship" && (
            <Form.Row>
              <Form.Group
                type="radio"
                id="internshipSalaryType"
                as={Col}
                required
              >
                <Form.Label style={{ marginRight: "20px" }}>
                  Internship Salary Type* :{" "}
                </Form.Label>
                <Form.Check
                  inline
                  checked={internshipSalaryType === "PAID" || stipend}
                  name="internshipSalaryType"
                  type="radio"
                  label="Paid"
                  onChange={(e) => {
                    setInternshipSalaryType("PAID");
                  }}
                />
                <Form.Check
                  inline
                  checked={internshipSalaryType === "UNPAID"}
                  name="internshipSalaryType"
                  type="radio"
                  label="Unpaid"
                  onChange={(e) => {
                    setInternshipSalaryType("UNPAID");
                  }}
                />
                <Form.Check
                  inline
                  checked={internshipSalaryType === "NOT DISCLOSED"}
                  name="internshipSalaryType"
                  type="radio"
                  label="Not disclose"
                  onChange={(e) => {
                    setInternshipSalaryType("NOT DISCLOSED");
                  }}
                />
              </Form.Group>
            </Form.Row>
          )}
          {/* Internship Stipend (if internshipSalaryType = 'PAID') */}

          {jobTypeName === "Internship" && internshipSalaryType === "PAID" && (
            <Form.Row>
              <Form.Group as={Col}>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  required
                  value={stipend || ""}
                  name="stipend"
                  type="text"
                  placeholder="Internship Stipend (in rupees)*"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setStipend(floatValue);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control value={"PER MONTH"} readOnly />
              </Form.Group>
            </Form.Row>
          )}
          {/* PPO offered only in case of Internship JOB TYPE */}
          {jobTypeName === "Internship" ? (
            <Form.Group type="radio" id="ppoOffered">
              <Form.Label style={{ marginRight: "20px" }}>
                PPO Offered :{" "}
              </Form.Label>
              <Form.Check
                inline
                checked={ppoOffered}
                name="ppoOffered"
                type="radio"
                label="Yes"
                onChange={(e) => {
                  setPpoOffered(!ppoOffered);
                }}
              />
              <Form.Check
                inline
                checked={!ppoOffered}
                name="ppoOffered"
                type="radio"
                label="No"
                onChange={(e) => {
                  setPpoOffered(!ppoOffered);
                }}
              />
            </Form.Group>
          ) : null}
          {/* Employment Type */}
          <Form.Group id="employment__type">
            <Form.Label>Job Employment Type:*</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={(e) => {
                setEmpTypeId(JSON.parse(e.target.value).id);
              }}
            >
              <option
                value={JSON.stringify({
                  id: data.employment_type,
                  job_type: data.employment_type_name,
                })}
              >
                --{data.employment_type_name}--
              </option>
              {empTypes.map((e, key) => {
                return (
                  <option key={key} value={JSON.stringify(e)}>
                    {" "}
                    {e.job_type}{" "}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <div id="hiring__location">
            <Form.Label>Job Segments* : </Form.Label>
            <Form.Group>
              <Autocomplete
                id="select-job-segment"
                options={allJobSegments}
                // value={selectedJobSegments}
                getOptionLabel={(item) => item.job_segment}
                onChange={(e, item) =>
                  item
                    ? setSelectedJobSegment(item.id)
                    : setSelectedJobSegment(null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Select Job Segment, Current: (${data.job_segment_name})`}
                    variant="outlined"
                  />
                )}
              />
            </Form.Group>
          </div>
        </div>
        {/* DETAILS */}
        <>
          <h4>Details</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />

          {/* Job PAYMENT Type */}
          <Form.Group type="radio" id="jobPaymentType" required>
            <Form.Label style={{ marginRight: "20px" }}>
              Job Payment Type:*
            </Form.Label>
            <Form.Check
              inline
              checked={jobPaymentType === "PAID"}
              name="jobPaymentType"
              type="radio"
              label="Paid"
              onChange={(e) => {
                setJobPaymentType("PAID");
              }}
            />
            <Form.Check
              inline
              checked={jobPaymentType === "UNPAID"}
              name="jobPaymentType"
              type="radio"
              label="Unpaid"
              onChange={(e) => {
                setJobPaymentType("UNPAID");
              }}
            />
          </Form.Group>
          
          {/* Invoicing Period */}
          <Form.Group id="job__vacancies">
              <Form.Label>Invoicing Period(in days):</Form.Label>
              <Form.Control
                name="jobInvoicing"
                type="number"
                min={0}
                max={500}
                onWheel={() => document.activeElement.blur()}
                value={invoicingPeriod || ""}
                onChange={(e) => {
                  setInvoicingPeriod(e.target.value);
                }}
              />
              <span id="vacancy__message">
                <i>
                  (Refer Agreement for this value. If no agreement, then, leave this empty)
                </i>
              </span>
            </Form.Group>
          
          {/* Job TERMS and CONDITIONS (tmc) */}
          <Form.Label> Job terms and conditions </Form.Label>
          <div className="editor">
            <Editor
              editorState={jobTmcEditor}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={jobTmcEditorState}
            />
          </div>
          {jobRoles.length > 0 ? (
            <Form.Group id="job__role__autocomplete">
              <Form.Label>Job Roles:*</Form.Label>
              <Autocomplete
                id="select-job-role"
                options={jobRoles}
                getOptionLabel={(item) => item.role}
                onChange={(e, item) =>
                  item ? setJobRoleId(item.id) : setJobRoleId(null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Select Job Role, Current: (${data.job_role_name})`}
                    variant="outlined"
                  />
                )}
              />
            </Form.Group>
          ) : null}
          {/* Job Description */}
          <Form.Label>Job Description</Form.Label>
          <div className="editor">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={onEditorStateChange}
            />
          </div>

          {!isPublicJob ? null : (
            <>
              <section style={{ display: "flex" }}>
                <h4>Additional Info:</h4>
                {toggleOtherInfo ? (
                  <ExpandMore
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setToggleOtherInfo(!toggleOtherInfo);
                    }}
                  />
                ) : (
                  <ExpandLess
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setToggleOtherInfo(!toggleOtherInfo);
                    }}
                  />
                )}
              </section>
              <hr style={{ width: "50%", marginLeft: "0" }} />
              {toggleOtherInfo ? null : (
                <>
                  <Form.Label>Roles & Responsibilities</Form.Label>
                  <div className="editor">
                    <Editor
                      editorState={jobDetails?.rolesResponsibilities}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      // onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                  <Form.Label>Service Bond Details</Form.Label>
                  <div className="editor">
                    <Editor
                      editorState={jobDetails?.serviceBondDetails}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      // onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                  <Form.Label>Perks & Benefits</Form.Label>
                  <div className="editor">
                    <Editor
                      editorState={jobDetails?.perksBenefits}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      // onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                  <Form.Group controlId="workTiming">
                    <section
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-start",
                      }}
                    >
                      <section
                        style={{
                          width: "30%",
                          // marginTop:20
                        }}
                      >
                        <Form.Label>Work Timing </Form.Label>
                        <Form.Control
                          name="workTiming"
                          type="text"
                          readOnly
                          placeholder="work timing"
                          value={jobDetails?.workTiming}
                          // onChange={(e) => {
                          //  setJobDetails({...jobDetails,workTiming:e.target.value});
                          // }}
                        />
                      </section>

                      <section style={{ width: "30%", marginLeft: 15 }}>
                        <Form.Label>Working Days </Form.Label>
                        <Form.Control
                          name="workingDays"
                          type="text"
                          readOnly
                          placeholder="Working Days"
                          value={jobDetails?.workingDays}
                          // onChange={(e) => {
                          //   setJobTitle(e.target.value);
                          // }}
                        />
                      </section>
                      <section style={{ width: "30%", marginLeft: 15 }}>
                        <Form.Label>Probation Period </Form.Label>
                        <Form.Control
                          name="probationPeriod"
                          type="text"
                          readOnly
                          placeholder="probation period"
                          value={jobDetails?.probationPeriod}
                          onChange={(e) => {
                            setJobTitle(e.target.value);
                          }}
                        />
                      </section>
                    </section>
                    <section
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-start",
                        marginTop: 10,
                      }}
                    >
                      <section style={{ width: "30%" }}>
                        <Form.Label>Travel Allowance </Form.Label>
                        <Form.Control
                          name="travel allowance"
                          type="text"
                          readOnly
                          placeholder="Travel Allowance"
                          value={jobDetails?.travelAllowances}
                          // onChange={(e) => {
                          //   setJobTitle(e.target.value);
                          // }}
                        />
                      </section>
                      <section style={{ width: "30%", marginLeft: 15 }}>
                        <Form.Label>Gender Preference </Form.Label>
                        <Form.Control
                          name="Gender Preference"
                          type="text"
                          readOnly
                          placeholder="gender preference"
                          value={jobDetails?.genderPreferences}
                          // onChange={(e) => {
                          //   setJobTitle(e.target.value);
                          // }}
                        />
                      </section>

                      <section style={{ width: "30%", marginLeft: 15 }}>
                        <Form.Label>Service Bond </Form.Label>
                        <Form.Control
                          name="serviceBond"
                          type="text"
                          readOnly
                          placeholder="service bond"
                          value={jobDetails?.serviceBond}
                          // onChange={(e) => {
                          //   setJobTitle(e.target.value);
                          // }}
                        />
                      </section>
                    </section>
                    <section
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <section style={{ width: "30%" }}>
                        <Form.Label>Working Days </Form.Label>
                        <Form.Control
                          name="workingDays"
                          type="text"
                          readOnly
                          placeholder="Working Days"
                          value={jobDetails?.workingDays}
                          // onChange={(e) => {
                          //   setJobTitle(e.target.value);
                          // }}
                        />
                      </section>
                    </section>
                  </Form.Group>
                  <hr style={{ width: "100%", marginLeft: "0" }} />
                </>
              )}
            </>
          )}

          {/* No. of positions open */}
          <section style={{ display: "flex" }}>
            <h4>Other Info:</h4>
          </section>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {isThirdPartyJob ? (
            <Form.Group id="job__vacancies">
              <Form.Label>Apply Link: </Form.Label>
              <Form.Control
                name="job"
                type="text"
                min={0}
                max={500}
                onWheel={() => document.activeElement.blur()}
                value={jobDetails?.applyLink || ""}
                onChange={(e) => {
                  setJobDetails({ ...jobDetails, applyLink: e.target.value });
                }}
              />
              <span id="vacancy__message">
                <i>
                  (This is url where candidates will be redirected on
                  getwork.org and student's OneApp.)
                </i>
              </span>
            </Form.Group>
          ) : null}
          <Form.Group id="job__vacancies">
            <Form.Label>Expected No. of vacancies: </Form.Label>
            <Form.Control
              name="jobVacancies"
              type="number"
              min={0}
              max={500}
              onWheel={() => document.activeElement.blur()}
              value={vacancy || ""}
              onChange={(e) => {
                handleAddingVacancy(e);
              }}
            />
            <span id="vacancy__message">
              <i>
                (This number can be approximate and will not be displayed to
                students)
              </i>
            </span>
          </Form.Group>
          {/* Paid Salary */}
          {jobTypeName === "Internship" && !ppoOffered ? null : (
            <Form.Group type="radio" id="isPaid">
              <Form.Label style={{ marginRight: "20px" }}>
                {ppoOffered && jobTypeName === "Internship"
                  ? "Salary after PPO*:"
                  : "Is Job Paid ?*"}
              </Form.Label>
              <Form.Check
                inline
                checked={salaryType === "PAID" || minCtc}
                name="isPaid"
                type="radio"
                label="Salary Paid"
                onChange={(e) => {
                  setSalaryType("PAID");
                }}
              />
              <Form.Check
                inline
                checked={salaryType === "UNPAID"}
                name="isPaid"
                type="radio"
                label="Salary Unpaid"
                onChange={(e) => {
                  setSalaryType("UNPAID");
                }}
              />
              <Form.Check
                inline
                checked={salaryType === "NOT DISCLOSED"}
                name="isPaid"
                type="radio"
                label="Not disclose"
                onChange={(e) => {
                  setSalaryType("NOT DISCLOSED");
                }}
              />
            </Form.Group>
          )}
          {/* CTC */}
          {salaryType === "PAID" || minCtc ? (
            <Form.Row id="is__paid__row">
              <Form.Label> Salary Range: * </Form.Label>
              <Form.Group as={Col}>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  required
                  name="minCTC"
                  type="text"
                  placeholder="Minimum CTC (in rupees)"
                  value={minCtc}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setMinCtc(floatValue);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  required
                  name="maxCTC"
                  type="text"
                  placeholder="Maximum CTC (in rupees)"
                  value={maxCtc}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setMaxCtc(floatValue);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                >
                  <option value={data.salary_payment_type}>
                    --{data.salary_payment_type}--
                  </option>
                  <option value="PER ANNUM">Per Annum</option>
                  <option value="PER MONTH">Per Month</option>
                  <option value="PER HOUR">Per Hour</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          ) : null}
          {/* Equity */}
          <div id="equity__row">
            <Form.Label>Equity Range* : </Form.Label>
            <Form.Check
              inline
              checked={noEquity}
              name="noEquity"
              type="checkbox"
              label="No Equity"
              onChange={(e) => {
                setNoEquity(!noEquity);
              }}
            />
            <Form.Group>
              <NumberFormat
                className="form-control"
                required
                disabled={noEquity}
                name="minCTC"
                type="text"
                placeholder="Minimum Equity*"
                onValueChange={(values) => {
                  const { floatValue } = values;
                  setMinEquity(floatValue);
                }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <NumberFormat
                className="form-control"
                required
                disabled={noEquity}
                name="maxCTC"
                type="text"
                placeholder="Maximum Equity*"
                onValueChange={(values) => {
                  const { floatValue } = values;
                  setMaxEquity(floatValue);
                }}
              />
            </Form.Group>
          </div>
          {/* Required Documents */}
          <div id="required__documents">
            <Form.Label>Required Documents* : </Form.Label>
            <Form.Group id="formGridCheckbox">
              <Form.Check
                type="checkbox"
                onChange={(e) => setResumeRequired(!resumeRequired)}
                checked={resumeRequired}
                label="Resume"
              />
              <Form.Check
                type="checkbox"
                onChange={(e) => setCoverLetterRequired(!coverLetterRequired)}
                checked={coverLetterRequired}
                label="Cover Letter"
              />
              <Form.Check
                type="checkbox"
                onChange={(e) => setTranscriptRequired(!transcriptRequired)}
                checked={transcriptRequired}
                label="Transcript"
              />
              <Form.Check
                type="checkbox"
                onChange={(e) => setIdProofRequired(!idProofRequired)}
                checked={idProofRequired}
                label="ID Proof (e.g. Aadhar Card, PAN Card, etc.)"
              />
            </Form.Group>
          </div>
          {/* Location for Posting */}

          <div id="hiring__location">
            <Form.Label>Work Mode</Form.Label>
            <Form.Group>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="workMode"
                  name="gender111"
                  value={workLocationModes?.wfh}
                  onChange={(e) => {
                    setWorkLocationModes({ hybrid: "", wfh: e.target.value });
                    if (e.target.value === "wfh") setSelectedCities([]);
                  }}
                >
                  <FormControlLabel
                    value="wfo"
                    control={<Radio />}
                    label={<span>{"Work From Office Only"}</span>}
                  />
                  <FormControlLabel
                    value="wfh"
                    control={<Radio />}
                    label={<span>{"Work From Home Only"}</span>}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="workMode"
                  name="hybrid"
                  value={workLocationModes?.hybrid}
                  onChange={(e) => {
                    setWorkLocationModes({ wfh: "", hybrid: e.target.value });
                  }}
                >
                  <FormControlLabel
                    value="hybrid"
                    control={<Radio />}
                    label={<span>{"Hybrid"}</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Form.Group>
          </div>
          {(workLocationModes?.wfh === "wfo" ||
            workLocationModes?.hybrid === "hybrid") && (
            <div id="hiring__location">
              <Form.Label>Where are you hiring for this position? *</Form.Label>
              <Form.Group>
                {!allowRemote ? (
                  <>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      id="select-cities"
                      options={allCities}
                      value={selectedCities}
                      getOptionLabel={(item) => item.city}
                      onChange={(e, item) =>
                        item ? setSelectedCities(item) : setSelectedCities([])
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Cities"
                          variant="outlined"
                        />
                      )}
                    />

                    {isThirdPartyJob ? (
                      <div id="hiring__location" style={{ marginTop: "10px" }}>
                        Posted Locations:
                        {jobDetails?.postedLocation?.map((item) => {
                          return (
                            <Chip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label={`${item}`}
                              // className={classes.chip}
                              style={{
                                // maxWidth: 250,
                                height: 30,
                                marginLeft: 10,
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                  </>
                ) : null}
                <Form.Check
                  type="checkbox"
                  onChange={(e) => setAllowRemote(!allowRemote)}
                  checked={allowRemote}
                  label="Remote Applications"
                  style={{ marginLeft: "4%" }}
                />
              </Form.Group>
            </div>
          )}

          {/* Service Bond */}
          <Form.Row>
            <Form.Group as={Col} type="radio" id="serviceBond">
              <Form.Label style={{ marginRight: "20px" }}>
                Service Bond* :
              </Form.Label>

              <Form.Check
                inline
                checked={serviceBond === "yes"}
                name="serviceBond"
                value="yes"
                type="radio"
                label="Yes"
                onChange={(e) => {
                  setServiceBond("yes");
                }}
              />
              <Form.Check
                inline
                checked={serviceBond === "no"}
                name="serviceBond"
                type="radio"
                value="no"
                label="No"
                onChange={(e) => {
                  setServiceBond("no");
                }}
              />
            </Form.Group>
            {serviceBond === "yes" ? (
              <Form.Group as={Col} controlId="bondMonths">
                <Form.Control
                  name="bondMonths"
                  type="number"
                  min={0}
                  max={50}
                  required
                  onWheel={() => document.activeElement.blur()}
                  placeholder="Number of Months* (max 50 Months)"
                  value={bondMonths}
                  onChange={(e) => {
                    setBondMonths(e.target.value);

                    if (e.target.value) {
                      let value = e.target.value
                        ? `${e.target.value} Months bond`
                        : " ";

                      setServiceBondEditor(
                        EditorState?.createWithContent(
                          ContentState?.createFromBlockArray(
                            convertFromHTML(value)
                          )
                        )
                      );

                      setJobDetails({ ...jobDetails, bond_condition: value });
                    }
                  }}
                />
              </Form.Group>
            ) : null}
          </Form.Row>
        </>
        <>
          {serviceBond === "yes" ? (
            <>
              <Form.Label> Service Bond Conditions* </Form.Label>
              <div className="editor">
                <Editor
                  editorState={serviceBondEditor}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  onEditorStateChange={serviceBondEditorState}
                />
              </div>
            </>
          ) : null}
        </>
        {/* PREFERENCES */}
        <>
          <h4>Preferences</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* Eligible Years */}
          <div id="eligible__years">
            <Form.Label>Eligible Graduation Years *</Form.Label>
            <Form.Group>
              <Autocomplete
                multiple
                filterSelectedOptions
                id="select-eligible-years"
                // defaultValue={data.eligibility_criteria.graduation_years ? data.eligibility_criteria.graduation_years : undefined}
                options={activeYears.sort()}
                value={eligibleYears}
                getOptionLabel={(item) => String(item)}
                onChange={(e, item) =>
                  item ? setEligibleYears(item) : setEligibleYears([])
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Eligible Graduation Years"
                    variant="outlined"
                  />
                )}
              />
            </Form.Group>
          </div>

          <Form.Label>
            Applicants Qualifications and Minimum Required Percentage
          </Form.Label>
          <Form.Group as={Col} controlId="qualifications">
            {jobDetails?.qualifications &&
              jobDetails?.qualifications?.length > 0 &&
              jobDetails?.qualifications?.map((item, index) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  onDelete={() => {
                    handleDeleteEducationTypePercent(index);
                  }}
                  deleteIcon={<CloseIcon />}
                  label={`${item?.education_type_name} with ${item?.percentage}%`}
                  // className={classes.chip}
                  style={{
                    maxWidth: 250,
                    marginBottom: 20,
                    height: 30,
                    marginLeft: 10,
                  }}
                />
              ))}
          </Form.Group>

          <Form.Row style={{ alignItems: "center" }}>
            <Form.Group as={Col} controlId="planType">
              <Autocomplete
                id="select-plan-type"
                options={educationType}
                value={educationTypeSelected}
                getOptionLabel={(item) => item?.education_type_name}
                onChange={(e, item) => {
                  const Edutype = item ? item : null;
                  setEducationTypeSelected(Edutype);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Level of Education"
                    variant="outlined"
                    style={{
                      backgroundColor: "white",
                    }}
                  />
                )}
              />
            </Form.Group>
            {/* <FormLabel>Minimum Percentage Required</FormLabel> */}
            <Form.Group as={Col} controlId="planType">
              <Form.Control
                type="number"
                min={1}
                max={100}
                value={minPercentage}
                placeholder="Minimum Percentage Required"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => setMinPercentage(e.target.value)}
              />
            </Form.Group>
          </Form.Row>
          <Form.Group as={Col} controlId="planType">
            <Button2
              variant="contained"
              onClick={() => {
                handleAddingEducationTypePercentage();
                // setJobDetails({...jobDetails,})
              }}
            >
              Add More Qualifications
            </Button2>
          </Form.Group>

          <div id="eligible__degrees">
            <Form.Label>Choose Skills* :</Form.Label>
            <Form.Group>
              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                id="select-job-skills"
                options={allSkills}
                value={selectedSkills}
                getOptionLabel={(item) => item.skill_name}
                // onChange={(e, item) =>
                //   item ? setSelectedSkills(item) : setSelectedSkills([])
                // }
                onInputChange={(e) => optimizedFn(e.target.value)}
                onChange={(e, item, reason) => {
                  if (
                    reason === "select-option" ||
                    reason === "create-option"
                  ) {
                    const lastObject = item[item.length - 1];
                    if (
                      item
                        .slice(0, -1)
                        .some(
                          (item) =>
                            item.skill_name ==
                            (lastObject?.skill_name || lastObject)
                        )
                    ) {
                      FailureAlert("Added Skill already present.");
                      return;
                    }
                  }
                  const skills = [];
                  item.map((skill) => {
                    if (typeof skill === "string") {
                      const customSkill = {
                        skill_id: -1,
                        skill_name: skill,
                        skill_type: null,
                        type: null,
                        user_created: true,
                      };
                      skills.push(customSkill);
                    } else {
                      skills.push(skill);
                    }
                  });
                  setSelectedSkills(skills);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Job Skills Required"
                    variant="outlined"
                  />
                )}
              />
              {isThirdPartyJob ? (
                <div style={{ marginTop: "10px" }}>
                  Posted Skills:
                  {jobDetails?.postedSkills?.map((item) => {
                    return (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        label={`${item}`}
                        // className={classes.chip}
                        style={{
                          // maxWidth: 250,
                          height: 30,
                          marginLeft: 10,
                        }}
                      />
                    );
                  })}
                </div>
              ) : null}
            </Form.Group>
          </div>

          <div id="eligible__degrees">
            <Form.Label>Choose Key Skills For Evaluation :</Form.Label>
            <Form.Group>
              {/* <Autocomplete
                multiple
                filterSelectedOptions
                id="select-job-skills"
                options={allSkills}
                value={selectedSkills}
                getOptionLabel={(item) => item.skill_name}
                onChange={(e, item) =>
                  item ? setSelectedSkills(item) : setSelectedSkills([])
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Job Skills Required"
                    variant="outlined"
                  />
                )}
              /> */}
              <Autocomplete
                value={selectedSkillsEvaluation}
                freeSolo
                multiple
                filterSelectedOptions
                id="select-job-skills"
                options={allSkills}
                getOptionLabel={(item) => item.skill_name}
                onInputChange={(e) => optimizedFn(e.target.value)}
                onChange={(e, item, reason) => {
                  if (
                    reason === "select-option" ||
                    reason === "create-option"
                  ) {
                    const lastObject = item[item.length - 1];
                    if (
                      item
                        .slice(0, -1)
                        .some(
                          (item) =>
                            item.skill_name ==
                            (lastObject?.skill_name || lastObject)
                        )
                    ) {
                      FailureAlert("Added Skill already present.");
                      return;
                    }
                  }
                  const skills = [];
                  item.map((skill) => {
                    if (typeof skill === "string") {
                      const customSkill = {
                        skill_id: -1,
                        skill_name: skill,
                        skill_type: null,
                        type: null,
                        user_created: true,
                      };
                      skills.push(customSkill);
                    } else {
                      skills.push(skill);
                    }
                  });
                  setSelectedSkillsEvaluation(skills);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Job Skills Required"
                    variant="outlined"
                  />
                )}
              />
              {/* {isThirdPartyJob ? (
                <div style={{ marginTop: "10px" }}>
                  Posted Skills:
                  {jobDetails?.postedSkills?.map((item) => {
                    return (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        label={`${item}`}
                        // className={classes.chip}
                        style={{
                          // maxWidth: 250,
                          height: 30,
                          marginLeft: 10,
                        }}
                      />
                    );
                  })}
                </div>
              ) : null} */}
            </Form.Group>
          </div>

          {/* Work Experience */}
          <Form.Row id="work__experience">
            <Form.Label id="work__experience__label">
              Work Experience* :
            </Form.Label>
            <Form.Group as={Col}>
              <Form.Label>Min Work Experience* :</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setMinWorkExp(JSON.parse(e.target.value).value);
                }}
              >
                <option
                  value={JSON.stringify({ name: "", value: `${minWorkExp}` })}
                >
                  --{(minWorkExp && `${minWorkExp} years`) || " "}--
                </option>
                {work_experiences.map((e, key) => {
                  return (
                    <option key={key} value={JSON.stringify(e)}>
                      {" "}
                      {e.name}{" "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Max Work Experience* :</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setMaxWorkExp(JSON.parse(e.target.value).value);
                }}
              >
                <option
                  value={JSON.stringify({ name: "", value: `${maxWorkExp}` })}
                >
                  --{(maxWorkExp && `${maxWorkExp} years`) || " "}--
                </option>
                {work_experiences.map((e, key) => {
                  return (
                    <option key={key} value={JSON.stringify(e)}>
                      {" "}
                      {e.name}{" "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </>
        {/* JOB PORTALS */}
        <>
          <h4>Job Portals</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* Job Portal */}
          <div id="job__rounds">
            <Form.Group as={Col} controlId="roundType">
              <Form.Label>Job Portal *</Form.Label>
              <Autocomplete
                id="select-plan-type"
                options={jobportals}
                value={portalDetails?.jobPortalDetails}
                getOptionLabel={(item) => item?.job_portal_code}
                onChange={(e, item) => {
                  const jobPortalDetails = item ? item : null;
                  setPortalDetails({
                    ...portalDetails,
                    jobPortalDetails: jobPortalDetails,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Job Portal Code"
                    variant="outlined"
                    style={{
                      backgroundColor: "white",
                    }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="planType">
              <Form.Label>Job ID *</Form.Label>
              <Form.Control
                type="text"
                value={portalDetails.jobPortalJobId}
                placeholder="Enter Job Id"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  setPortalDetails({
                    ...portalDetails,
                    jobPortalJobId: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </div>
        </>
        <Form.Group as={Col} controlId="planType">
          <Button2
            variant="contained"
            onClick={() => {
              handleAddPortal();
            }}
          >
            Add Portal
          </Button2>
        </Form.Group>
        <Form.Row>
          {jobDetails?.portalDetails?.length > 0 &&
            jobDetails?.portalDetails?.map((portal, index) => (
              <PortalCard
                portal={portal}
                index={index}
                totalLength={jobDetails?.portalDetails?.length}
                deleteRound={hanleDeletePortal}
              />
            ))}
        </Form.Row>

        {/* SELECTION CRITERIA */}
        <>
        <h4>AI Section</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* Auto AI Assessment */}
          <Form.Group type="radio" id="jobPaymentType" required>
            <Form.Label style={{ marginRight: "20px" }}>
              Auto AI Assessment:*
            </Form.Label>
            <Form.Check
              inline
              checked={isAiAssessment === true}
              name="isAiAssessment"
              type="radio"
              label="Yes"
              onChange={(e) => {
                setIsAiAssessment(true);
              }}
            />
            <Form.Check
              inline
              checked={isAiAssessment === false}
              name="isAiAssessment"
              type="radio"
              label="No"
              onChange={(e) => {
                setIsAiAssessment(false);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="planType">
              <Form.Label>JD vs CV Assist. Id</Form.Label>
              <Form.Control
                type="text"
                value={jdvscvid}
                placeholder="Enter JD vs CV Assist. Id"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  setJdVsCvid(e.target.value,
                  );
                }}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="planType">
              <Form.Label>AI Assessment Assist. Id</Form.Label>
              <Form.Control
                type="text"
                value={aiassessmentid}
                placeholder="Enter Assessment Assist. Id"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  setAiAssessmentId(e.target.value,
                  );
                }}
              />
            </Form.Group>

          
          <h4>Selection Criteria</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* Auto AI Assessment */}
          {/* <Form.Group type="radio" id="jobPaymentType" required>
            <Form.Label style={{ marginRight: "20px" }}>
              Auto AI Assessment:*
            </Form.Label>
            <Form.Check
              inline
              checked={isAiAssessment === true}
              name="isAiAssessment"
              type="radio"
              label="Yes"
              onChange={(e) => {
                setIsAiAssessment(true);
              }}
            />
            <Form.Check
              inline
              checked={isAiAssessment === false}
              name="isAiAssessment"
              type="radio"
              label="No"
              onChange={(e) => {
                setIsAiAssessment(false);
              }}
            />
          </Form.Group>
           */}
          <div id="job__rounds">
            <Form.Group as={Col} controlId="roundType">
              <Form.Label>Rounds *</Form.Label>
              <Autocomplete
                id="select-plan-type"
                options={allRounds}
                value={roundDetails?.roundType}
                getOptionLabel={(item) => item?.name}
                onChange={(e, item) => {
                  const roundType = item ? item : null;
                  setRoundDetails({ ...roundDetails, roundType: roundType });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Round"
                    variant="outlined"
                    style={{
                      backgroundColor: "white",
                    }}
                  />
                )}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="roundType">
              <Form.Label>Duration</Form.Label>

              <Autocomplete
                id="select-plan-type"
                options={roundtime}
                value={roundDetails?.roundTime}
                getOptionLabel={(item) => item?.label}
                onChange={(e, item) => {
                  const roundTime = item ? item : null;
                  setRoundDetails({ ...roundDetails, roundTime: roundTime });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Duration"
                    variant="outlined"
                    style={{ backgroundColor: "white" }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="roundType">
              <Form.Label>Venue</Form.Label>
              <Autocomplete
                id="select-plan-type"
                options={venueType}
                value={roundDetails?.venueType}
                getOptionLabel={(item) => item?.label}
                onChange={(e, item) => {
                  const roundVenue = item ? item : null;
                  setRoundDetails({ ...roundDetails, venueType: roundVenue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Venue"
                    variant="outlined"
                    style={{ backgroundColor: "white" }}
                  />
                )}
              />
            </Form.Group>
          </div>
        </>
        <Form.Group as={Col} controlId="planType">
          <Button2
            variant="contained"
            onClick={() => {
              handleAddingRounds();
            }}
          >
            Add Round
          </Button2>
        </Form.Group>
        <Form.Row>
          {jobDetails?.roundDetails?.length > 0 &&
            jobDetails?.roundDetails?.map((round, index) => (
              <RoundCard
                deletable={!round.is_hide}
                round={round}
                index={index}
                totalLength={jobDetails?.roundDetails?.length}
                deleteRound={hanleDeleteRound}
              />
            ))}
        </Form.Row>

        <>
          <h4>Colleges</h4>
          <hr style={{ width: "50%", marginLeft: "0" }} />
          {/* College/ Off Campus */}
          <Form.Group type="radio" id="isOffCampus">
            <Form.Label style={{ marginRight: "20px" }}>
              How do you want to hire?* :{" "}
            </Form.Label>
            <Form.Check
              inline
              checked={isOffCampus}
              name="isOffCampus"
              type="radio"
              label="OFF Campus"
              onChange={(e) => {
                setIsOffCampus(!isOffCampus);
                setJobLakeBool(false);
              }}
            />
            <Form.Check
              inline
              checked={!isOffCampus}
              name="isOffCampus"
              type="radio"
              label="ON Campus"
              onChange={(e) => {
                setIsOffCampus(!isOffCampus);
                setIpoJobBool(false);
              }}
            />
          </Form.Group>
          {/* Global Apply Start Date/ End Date */}
          <Form.Row>
            <Form.Group as={Col} controlId="globalStartDate">
              <Form.Label>Global Apply Start Date*</Form.Label>
              <Form.Control
                name="globalStartDate"
                type="date"
                placeholder="YYYY-MM-DD"
                value={globalStartDate}
                onChange={(e) => {
                  setGlobalStartDate(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="globalEndDate">
              <Form.Label>Global Apply End Date*</Form.Label>
              <Form.Control
                name="globalEndDate"
                type="date"
                placeholder="YYYY-MM-DD"
                value={globalEndDate}
                min={globalStartDate}
                onChange={(e) => {
                  setGlobalEndDate(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          {isOffCampus ? (
            <>
              <div id="job__lake">
                <Form.Label></Form.Label>
                <Form.Group id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => setIpoJobBool(!ipoJobBool)}
                    checked={ipoJobBool}
                    label="Is this an IPO Job?"
                  />
                </Form.Group>
              </div>

              <div id="all__courses__available">
                {isOffCampus && data?.eligibility_criteria?.courses ? (
                  <>
                    <h5 style={{ marginLeft: "10px" }}>
                      <b>Off Campus Hiring - Previously Selected Courses</b>
                    </h5>
                    <span style={{ fontStyle: "italics", fontSize: "13px" }}>
                      {Object.keys(qualificationData).map((item, ind) => (
                        <Fragment key={ind}>
                          <ul>
                            <li>
                              {" "}
                              <b>{item}&nbsp; </b>(
                              {qualificationData[item].map((item1, i) => (
                                <Fragment key={i}>
                                  {i !== qualificationData[item].length - 1 ? (
                                    <>
                                      {" "}
                                      {item1.name}
                                      {", "}{" "}
                                    </>
                                  ) : (
                                    <>{item1.name}</>
                                  )}
                                </Fragment>
                              ))}
                              )
                            </li>
                          </ul>
                        </Fragment>
                      ))}
                    </span>
                  </>
                ) : null}
                {isOffCampus && Object.keys(offCampusAllCourses).length > 0 && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <FormLabel className={classes.grid1} component="legend">
                          {" "}
                          <i>Courses* :</i>{" "}
                        </FormLabel>
                      </Grid>
                      <Grid
                        id="offCampusSelectAllCoursesSpan"
                        item
                        xs={9}
                        className={classes.grid2}
                      >
                        <FormControlLabel
                          label="Select All Courses"
                          control={
                            <Checkbox
                              name="checkboxes"
                              style={{ marginLeft: "10%" }}
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={handleSelectAllOffCampusCourses}
                              checked={isOffCampusAllCourses}
                            />
                          }
                        />
                        <img src={line} alt="OR Line" width="100%" />
                        <br />
                        <br />
                        {Object.keys(offCampusAllCourses).map(
                          (course, index) => {
                            return (
                              <Fragment key={index}>
                                <Box className={classes.shadowBox}>
                                  <Grid container spacing={3}>
                                    <Grid
                                      id="offCampusSelectAllSpecializationsSpan"
                                      item
                                      xs={10}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="checkboxes"
                                            checked={
                                              offCampusAllCourses[course]
                                                .select_all
                                            }
                                            onChange={(e) =>
                                              selectAllOffCampusSpecializations(
                                                course
                                              )
                                            }
                                            classes={{
                                              root: classes.radio,
                                              checked: classes.checked,
                                            }}
                                            inputProps={{
                                              "aria-label": "primary checkbox",
                                            }}
                                          />
                                        }
                                        label={course}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <IconButton
                                        color="primary"
                                        aria-label="add to shopping cart"
                                        data-toggle="collapse"
                                        data-target={`#${course}`}
                                        style={{
                                          cursor: "pointer",
                                          border: "none",
                                        }}
                                      >
                                        <ExpandMoreIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  <div className="collapse" id={course}>
                                    <Box
                                      className={classes.innerBox}
                                      id="offCampusSingleCourses"
                                    >
                                      {offCampusAllCourses[course].map(
                                        (i, index) => (
                                          <Fragment key={index}>
                                            {i.specialization ? (
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    name="checkboxes"
                                                    checked={i.isChecked}
                                                    onChange={(e) =>
                                                      handleSelectSingleOffCampusSpecialization(
                                                        i,
                                                        course
                                                      )
                                                    }
                                                    inputProps={{
                                                      "aria-label":
                                                        "primary checkbox",
                                                    }}
                                                    classes={{
                                                      root: classes.radio,
                                                      checked: classes.checked,
                                                    }}
                                                  />
                                                }
                                                label={i.specialization.name}
                                              />
                                            ) : null}
                                            <br />
                                          </Fragment>
                                        )
                                      )}
                                    </Box>
                                  </div>
                                </Box>
                                <br />
                              </Fragment>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </>
          ) : (
            <div>
              <>
                <div id="job__lake">
                  <Form.Label>Job Lake ?* : </Form.Label>
                  <Form.Group id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      onChange={(e) => setJobLakeBool(!jobLakeBool)}
                      checked={jobLakeBool}
                      label="Is Job Posted on Job Lake ?"
                    />
                  </Form.Group>
                </div>
                {selectedCollegesFromAPI &&
                selectedCollegesFromAPI?.length > 0 ? (
                  <>
                    {data.hiring_type === "ON CAMPUS" &&
                      localStorage.getItem("token") && (
                        <div className="row my-5 text-left pl-2">
                          <h6 style={{ marginLeft: "10px" }}>
                            <b>On Campus Hiring - College Information</b>
                          </h6>
                          <br />
                          <div className="col-md-12">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell> College </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {" "}
                                      Degrees{" "}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {" "}
                                      Specializations{" "}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {" "}
                                      Status{" "}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {" "}
                                      Apply_Start_Date{" "}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {" "}
                                      Apply_End_Date{" "}
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedCollegesFromAPI?.map(
                                    (colgObj, ind) => (
                                      <StyledTableRow key={ind}>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {colgObj?.preferences?.map(
                                            (prefObj, ind) => (
                                              <Fragment key={ind}>
                                                <ul>
                                                  {" "}
                                                  <li>
                                                    {" "}
                                                    {prefObj?.degree_name}{" "}
                                                  </li>{" "}
                                                </ul>
                                              </Fragment>
                                            )
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {colgObj.preferences.map((prefObj) =>
                                            prefObj.specialization.map(
                                              (spzl, ind) => (
                                                <Fragment key={ind}>
                                                  <ul>
                                                    <li>
                                                      {" "}
                                                      {spzl.specialization_name
                                                        ? spzl.specialization_name
                                                        : spzl.name}
                                                    </li>
                                                  </ul>
                                                </Fragment>
                                              )
                                            )
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.is_rejected === true &&
                                            colgObj.is_approved === false && (
                                              <>Rejected</>
                                            )}
                                          {colgObj.is_rejected === false &&
                                            colgObj.is_approved === true && (
                                              <>Accepted</>
                                            )}
                                          {colgObj.is_rejected === false &&
                                            colgObj.is_approved === false && (
                                              <>Pending</>
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.apply_start_date}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.apply_end_date}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                      )}

                    <h5 style={{ color: "red" }}>
                      WARNING:
                      <span
                        style={{
                          color: "#d6d60f",
                          fontSize: "18px",
                          marginLeft: "6px",
                        }}
                      >
                        Selecting/ Adding any new college will reset previous
                        data.
                        <br />
                        You will then be required to add all colleges again, if
                        required.
                      </span>
                    </h5>
                  </>
                ) : (
                  <h5 style={{ color: "blue", textAlign: "center" }}>
                    No College Selected Previously...
                  </h5>
                )}
              </>
              <div className={jobLakeBool ? "is-disabled" : ""}>
                <>
                  <div>
                    <br />
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          <SearchIcon />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="searchCollege"
                        type="text"
                        placeholder="Enter College Name..."
                        onChange={(e) => {
                          searchCollege(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </div>
                  {selectedCollegesNamesArr.length > 0 ? (
                    <div className="row my-2">
                      {selectedCollegesNamesArr.map((item, ind) => (
                        <span className="badge skill-chip mx-2" key={ind}>
                          {item}
                          <i
                            onClick={(e) => {
                              removeCollege(e, item);
                            }}
                            className="ml-3 fas fa-times"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </span>
                      ))}
                    </div>
                  ) : null}
                  <div
                    id="myid"
                    className="scrollY1"
                    onScroll={(e) => handleScroll(e)}
                  >
                    <div className="row mx-auto my-4">
                      <div className="college-list-box">
                        <div
                          className="row justify-content-center mt-4 mb-3 px-5"
                          style={{ fontSize: "18px" }}
                        >
                          <p className="text-white">
                            <strong>
                              Choose the Colleges where this job should be
                              posted{" "}
                            </strong>
                          </p>
                          <p className="mx-5" style={{ color: "#bdbdbd" }}>
                            Each College can have its own apply start date or
                            expiration date, or you can set global dates that
                            apply to all postings of this job.
                          </p>
                        </div>
                        <div className="row" id="filter__colleges__div">
                          <div className="card" id="filter__college__card">
                            <div
                              className="card-body"
                              id="filter__college__card__body"
                            >
                              <Typography id="filter__college__card__typography">
                                <b>
                                  We generate and show you college rankings as
                                  per your choosen preferences, you can still
                                  filter colleges as per courses whenever you
                                  want.&nbsp;
                                  <span
                                    onClick={() => {
                                      handleOpen();
                                    }}
                                    style={{
                                      fontSize: "16px",
                                      color: "#3282C4",
                                      cursor: "pointer",
                                    }}
                                  >
                                    FILTER COLLEGES
                                  </span>
                                </b>
                              </Typography>
                            </div>
                            <div style={{ marginLeft: "24px" }}>
                              {/* Education Level */}
                              {(graduationBoolFilter ||
                                postGraduationBoolFilter ||
                                phdBoolFilter) && (
                                <>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "10%",
                                        }}
                                      >
                                        {" "}
                                        Education Level
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <div className="selected text-left my-2">
                                        {graduationBoolFilter && (
                                          <span className="m-2 px-3 py-2 badge badge-dark">
                                            {" "}
                                            Graduation{" "}
                                          </span>
                                        )}
                                        {postGraduationBoolFilter && (
                                          <span className="m-2 px-3 py-2 badge badge-dark">
                                            {" "}
                                            Post Graduation{" "}
                                          </span>
                                        )}
                                        {phdBoolFilter && (
                                          <span className="m-2 px-3 py-2 badge badge-dark">
                                            {" "}
                                            Ph.D{" "}
                                          </span>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* Degrees */}
                              {selectedDegreesFilter.length > 0 && (
                                <>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "10%",
                                        }}
                                      >
                                        {" "}
                                        Degrees
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <div className="selected text-left my-2">
                                        {selectedDegreesFilter.map((deg) => (
                                          <span
                                            key={deg}
                                            className="m-2 px-3 py-2 badge badge-dark"
                                          >
                                            {" "}
                                            {deg.name}{" "}
                                          </span>
                                        ))}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* Courses */}
                              {selectedCoursesFilter.length > 0 && (
                                <>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "10%",
                                        }}
                                      >
                                        {" "}
                                        Courses
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <div className="selected text-left my-2">
                                        {selectedCoursesFilter.map((course) => (
                                          <span
                                            key={course}
                                            className="m-2 px-3 py-2 badge badge-dark"
                                          >
                                            {" "}
                                            {course.specialization_name ||
                                              course.degree_name}{" "}
                                          </span>
                                        ))}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* Location */}
                              {selectedLocationsFilter.length > 0 && (
                                <>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "10%",
                                        }}
                                      >
                                        {" "}
                                        College Location
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <div className="selected text-left my-2">
                                        {selectedLocationsFilter.map((loc) => (
                                          <span
                                            key={loc}
                                            className="m-2 px-3 py-2 badge badge-dark"
                                          >
                                            {" "}
                                            {loc.city}{" "}
                                          </span>
                                        ))}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* Minimum CTC */}
                              {minCtcFilter && (
                                <>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "10%",
                                        }}
                                      >
                                        {" "}
                                        Minimum CTC
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <div className="selected text-left my-2">
                                        <span className="m-2 px-3 py-2 badge badge-dark">
                                          {" "}
                                          (₹) {minCtcFilter}{" "}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* Job Segments */}
                              {selectedJobSegmentsFilter.length > 0 && (
                                <>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "10%",
                                        }}
                                      >
                                        {" "}
                                        Job Segments
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <div className="selected text-left my-2">
                                        {selectedJobSegmentsFilter.map(
                                          (jseg) => (
                                            <span
                                              key={jseg}
                                              className="m-2 px-3 py-2 badge badge-dark"
                                            >
                                              {" "}
                                              {jseg.job_segment}{" "}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div id="select__all__filtered__colleges">
                            <FormControlLabel
                              style={{ marginLeft: 20, marginTop: "-20px" }}
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    setSelectAllColleges(!selectAllColleges);
                                  }}
                                  checked={selectAllColleges}
                                  name="gilad"
                                  classes={{
                                    root: classes.radio,
                                    checked: classes.checked,
                                  }}
                                />
                              }
                              label={
                                <Typography className={classes.newLabel}>
                                  Select All Colleges &nbsp;
                                  {selectAllColleges &&
                                    "(No action can be performed on selecting this)"}
                                </Typography>
                              }
                            />
                          </div>
                          <div id="college__count__div">
                            <Typography className={classes.newLabel}>
                              <img
                                src={college_count}
                                alt="count"
                                height="25px"
                              />
                              {` Colleges Count: `} {collegeCount}
                            </Typography>
                          </div>
                        </div>
                        {!collegeLoading ? (
                          Object.keys(filteredCollegeList).length > 0 ? (
                            <div className="row">
                              {Object.keys(filteredCollegeList).map(
                                (college) => {
                                  let clg = filteredCollegeList[college];
                                  return (
                                    <div
                                      className="card college-item-box w-100 mx-5"
                                      key={clg.college_id}
                                      style={{
                                        borderRadius: "5px",
                                        marginBottom: "30px",
                                      }}
                                    >
                                      <div className="card-body p-2">
                                        <div className="row">
                                          <div className="col-2">
                                            <div>
                                              <img
                                                src={
                                                  clg?.college_logo
                                                    ? clg.college_logo
                                                    : college_placeholder
                                                }
                                                style={{
                                                  height: "90px",
                                                  width: "100px",
                                                }}
                                                className="img-fluid2"
                                                alt="logo"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-8 text-left">
                                            <div
                                              className="card-title mb-0"
                                              style={{ fontSize: "26px" }}
                                            >
                                              <strong>
                                                {clg.college_name}
                                              </strong>
                                            </div>
                                            <p
                                              className="mb-0"
                                              style={{
                                                fontSize: "20px",
                                                fontWeight: "500",
                                                color: "#4f4f4fa6",
                                              }}
                                            >
                                              {clg.college_location}
                                            </p>
                                          </div>
                                          <IconButton
                                            onClick={() => {
                                              getDegreeAndCourses(
                                                clg.college_id,
                                                clg.college_name
                                              );
                                            }}
                                            className="col-2 my-auto"
                                            style={{ cursor: "pointer" }}
                                            data-toggle="collapse"
                                            data-target={
                                              "#collapse" + clg.college_id
                                            }
                                          >
                                            <ExpandMoreIcon className="cp ml-2" />
                                          </IconButton>
                                        </div>
                                        <div
                                          className="collapse"
                                          id={"collapse" + clg.college_id}
                                        >
                                          <div
                                            className="card card-body"
                                            style={{ boxShadow: "none" }}
                                          >
                                            <div className="row">
                                              <div className="col-10 text-left"></div>
                                            </div>
                                            <div className="row">
                                              <span
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: "500",
                                                  color: "#4f4f4f",
                                                  marginLeft: "2rem",
                                                }}
                                              >
                                                Courses :{" "}
                                              </span>
                                              <div
                                                className="form-check"
                                                style={{ marginLeft: "1rem" }}
                                              >
                                                <Checkbox
                                                  name="checkboxes"
                                                  checked={
                                                    clg.select_all
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={
                                                    handleSelectAllCollegeCourses
                                                  }
                                                  value={college}
                                                  inputProps={{
                                                    "aria-label":
                                                      "primary checkbox",
                                                  }}
                                                  classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                  }}
                                                />{" "}
                                                Select All Courses
                                              </div>
                                            </div>
                                            <img
                                              src={line}
                                              alt="OR line"
                                              width="100%"
                                            />{" "}
                                            <br />
                                            {circularLoader === false ? (
                                              clg.preferences.length > 0 ? (
                                                clg.preferences.map(
                                                  (clg_edu, id) => {
                                                    return (
                                                      <div
                                                        className="row"
                                                        key={id}
                                                      >
                                                        <div
                                                          className="card dropdown-card-wrapper mx-3 onCmpSingleClgDegrees"
                                                          style={{
                                                            width: "100%",
                                                            height: "100%",
                                                          }}
                                                        >
                                                          <div className="card-body p-1">
                                                            <div className="row">
                                                              <div className="col-1 text-right">
                                                                <div className="form-check">
                                                                  <Checkbox
                                                                    name="checkboxes"
                                                                    checked={
                                                                      clg_edu.select_all
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleCollegeSelectAllSpecialization(
                                                                        e,
                                                                        college
                                                                      )
                                                                    }
                                                                    value={
                                                                      clg_edu.degree_id
                                                                    }
                                                                    inputProps={{
                                                                      "aria-label":
                                                                        "primary checkbox",
                                                                    }}
                                                                    classes={{
                                                                      root: classes.radio,
                                                                      checked:
                                                                        classes.checked,
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  paddingTop:
                                                                    "0.5rem",
                                                                }}
                                                                className="col-10 text-left"
                                                              >
                                                                <label
                                                                  className="form-check-label"
                                                                  htmlFor={
                                                                    clg_edu.degree_id
                                                                  }
                                                                >
                                                                  {
                                                                    clg_edu.degree_name
                                                                  }
                                                                </label>
                                                              </div>
                                                              <div className="icon-container">
                                                                <ExpandMoreIcon
                                                                  data-toggle="collapse"
                                                                  data-target={
                                                                    "#courses" +
                                                                    college +
                                                                    clg_edu.degree_id
                                                                  }
                                                                  style={{
                                                                    paddingTop:
                                                                      "0.5rem",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  className="ml-2"
                                                                />
                                                              </div>
                                                              <div className="row">
                                                                <div
                                                                  className="collapse"
                                                                  id={
                                                                    "courses" +
                                                                    college +
                                                                    clg_edu.degree_id
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  {clg_edu?.specialization.map(
                                                                    (
                                                                      spec,
                                                                      ind
                                                                    ) => (
                                                                      <Fragment
                                                                        key={
                                                                          ind
                                                                        }
                                                                      >
                                                                        <div
                                                                          className="row pl-5"
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            marginLeft:
                                                                              "2rem",
                                                                            alignItems:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          <div
                                                                            className="form-check"
                                                                            style={{
                                                                              paddingRight:
                                                                                "0.8rem",
                                                                            }}
                                                                          >
                                                                            <Checkbox
                                                                              name="checkboxes"
                                                                              checked={
                                                                                spec.selected
                                                                                  ? true
                                                                                  : false
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleCollegeSelectSingleSpecialization(
                                                                                  e,
                                                                                  college,
                                                                                  clg_edu.degree_id
                                                                                )
                                                                              }
                                                                              value={
                                                                                spec.specialization_id
                                                                              }
                                                                              inputProps={{
                                                                                "aria-label":
                                                                                  "primary checkbox",
                                                                              }}
                                                                              id={
                                                                                clg_edu.degree_name +
                                                                                spec.specialization_id
                                                                              }
                                                                              classes={{
                                                                                root: classes.radio,
                                                                                checked:
                                                                                  classes.checked,
                                                                              }}
                                                                            />
                                                                          </div>
                                                                          <label
                                                                            htmlFor={
                                                                              clg_edu.degree_name +
                                                                              spec.specialization_id
                                                                            }
                                                                            className="form-check-label"
                                                                          >
                                                                            {
                                                                              spec.specialization_name
                                                                            }
                                                                          </label>
                                                                        </div>
                                                                      </Fragment>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <>No Courses in this College</>
                                              )
                                            ) : (
                                              <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                marginTop="5%"
                                              >
                                                <CircularProgress
                                                  className={classes.progress}
                                                />
                                              </Box>
                                            )}
                                            <div
                                              style={{
                                                marginLeft: "3rem",
                                                marginRight: "2rem",
                                              }}
                                            >
                                              <div className="row mt-4 mb-2">
                                                <div className="col-6 text-left">
                                                  <p
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "500",
                                                      color: "#4f4f4f",
                                                    }}
                                                  >
                                                    {" "}
                                                    Apply Start Date :{" "}
                                                  </p>
                                                </div>
                                                <div className="col-6 text-left">
                                                  <Form.Control
                                                    type="date"
                                                    min={String(new Date())}
                                                    onChange={(e) => {
                                                      handleSelectStartDate(
                                                        e,
                                                        college
                                                      );
                                                    }}
                                                    className="form-control input-secondary input-small"
                                                  />
                                                </div>
                                              </div>
                                              <div className="row mb-2">
                                                <div className="col-6 text-left">
                                                  <p
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "500",
                                                      color: "#4f4f4f",
                                                    }}
                                                  >
                                                    {" "}
                                                    Apply End Date :{" "}
                                                  </p>
                                                </div>
                                                <div className="col-6 text-left">
                                                  <Form.Control
                                                    type="date"
                                                    min={String(new Date())}
                                                    onChange={(e) => {
                                                      handleSelectEndDate(
                                                        e,
                                                        college
                                                      );
                                                    }}
                                                    className="form-control input-secondary input-small"
                                                  />
                                                </div>
                                              </div>
                                              <button
                                                className="onCmpsAddCollegeButtonStyle"
                                                onClick={(e) =>
                                                  addSelectedCollege(e, college)
                                                }
                                              >
                                                Add College
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            <div id="no__college__data__div">
                              <img
                                src={no_college_data}
                                alt="No Data"
                                id="no__college__data__image"
                              />
                              <div
                                align="center"
                                id="no__college__data__div__div"
                              >
                                {" "}
                                No data Found. Please Change the filters to get
                                data !!
                              </div>
                            </div>
                          )
                        ) : (
                          <Box id="no__college__loader__box">
                            <CircularProgress />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </>
        <Button
          type="submit"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 4px",
            marginLeft: "40%",
            width: "152px",
            height: "36px",
            marginTop: "15px",
            padding: "0.35rem 1.25rem",
            paddingBottom: "2.2rem",
            borderRadius: "4px",
            color: "rgb(255, 255, 255)",
            background: "#2b9424",
            fontWeight: 500,
            fontSize: "20px",
            border: "none",
          }}
        >
          {" "}
          {isLoading ? (
            <CustomButtonCircularProgress />
          ) : isPublicJob || isThirdPartyJob ? (
            "POST JOB"
          ) : isClone ? (
            "Clone Job"
          ) : (
            "Edit Job"
          )}
        </Button>
        <br />
        <br />
      </Form>
      {
        <Modal show={open} onHide={handleClose} scrollable={true} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Filter the College Ranking</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginLeft: "5%", marginRight: "5%" }}>
            {/* Qualifications */}
            <div id="required__qualifications">
              <Form.Label>Qualifications : </Form.Label>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  type="checkbox"
                  onChange={(e) =>
                    setGraduationBoolFilter(!graduationBoolFilter)
                  }
                  checked={graduationBoolFilter}
                  label="Graduation"
                />
                <Form.Check
                  type="checkbox"
                  onChange={(e) =>
                    setPostGraduationBoolFilter(!postGraduationBoolFilter)
                  }
                  checked={postGraduationBoolFilter}
                  label="Post Graduation"
                />
                <Form.Check
                  type="checkbox"
                  onChange={(e) => setPhdBoolFilter(!phdBoolFilter)}
                  checked={phdBoolFilter}
                  label="Ph.D"
                />
              </Form.Group>
            </div>
            {/* Degrees */}
            {graduationBoolFilter ||
            postGraduationBoolFilter ||
            phdBoolFilter ? (
              <div id="eligible__degrees">
                <Form.Label>Degrees :</Form.Label>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-eligible-degrees"
                    options={allDegrees}
                    value={selectedDegreesFilter}
                    getOptionLabel={(item) => item.name}
                    onChange={(e, item) =>
                      item
                        ? setSelectedDegreesFilter(item)
                        : setSelectedDegreesFilter([])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Eligible Degrees"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
              </div>
            ) : null}
            {/* Courses */}
            {selectedDegreesFilter.length > 0 ? (
              <div id="eligible__degrees">
                <Form.Label>Courses :</Form.Label>
                <Form.Group>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="select-eligible-courses"
                    options={allCourses}
                    value={selectedCoursesFilter}
                    getOptionLabel={(item) =>
                      item.specialization_name || item.degree_name
                    }
                    onChange={(e, item) =>
                      item
                        ? setSelectedCoursesFilter(item)
                        : setSelectedCoursesFilter([])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Eligible Courses"
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
              </div>
            ) : null}
            {/* Location for Posting */}
            <div id="hiring__location">
              <Form.Label>College Location : </Form.Label>
              <Form.Group>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  id="select-cities"
                  options={allCities}
                  value={selectedLocationsFilter}
                  getOptionLabel={(item) => item.city}
                  onChange={(e, item) =>
                    item
                      ? setSelectedLocationsFilter(item)
                      : setSelectedLocationsFilter([])
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Cities"
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>
            </div>
            {/* Minimum CTC */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Form.Label style={{ width: "22%" }}> Minimum CTC : </Form.Label>
              <Form.Group style={{ width: "78%" }}>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="form-control"
                  required
                  value={minCtcFilter || 0}
                  name="minCTC"
                  type="text"
                  placeholder="Minimum CTC (in rupees)"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setMinCtcFilter(floatValue);
                  }}
                />
              </Form.Group>
            </div>
            {/* Job Segments for Filtering */}
            <div id="hiring__location">
              <Form.Label>Job Segments : </Form.Label>
              <Form.Group>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  id="select-job-segment"
                  options={allJobSegments}
                  value={selectedJobSegmentsFilter}
                  getOptionLabel={(item) => item.job_segment}
                  onChange={(e, item) =>
                    item
                      ? setSelectedJobSegmentsFilter(item)
                      : setSelectedJobSegmentsFilter([])
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Job Segments"
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(e) => removeFilters()}>
              Remove Filters
            </Button>
            <Button variant="primary" onClick={(e) => applyFilters()}>
              Apply Filters
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default EditJobSection;
