import { Typography } from "@material-ui/core";
import React from "react";
import CustomizedTabs from "./Candidates/CustomizedTabs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function ManageCandidates() {
  let jobId = new URLSearchParams(useLocation()?.search).get("job_id");

  return (
    <div>
      <Typography variant="h5" style={{ fontWeight: "600" }}>
        Source Candidates
      </Typography>

      <div>
        <CustomizedTabs jobId={jobId} />
      </div>
    </div>
  );
}

export default ManageCandidates;
