import React, { useState } from 'react';
import '../../css/UploadStudentJobApplyExcel.css';
import {apiCall} from '../../util/apiCall';
import { gifStyling } from '../../constants';
import {Button, Card, Form} from 'react-bootstrap';
import gif from '../../assets/loopingcircles-3.gif';
import sampleExcel from '../../assets/Company_Upload_Excel.xlsx';
import { SuccessAlert, FailureAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function AddCompaniesExcel() {    

    const [excel, setExcel] = useState(null);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const uploadExcel = (e) => {
        e.preventDefault();
        if(excel===null) { FailureAlert("Upload file..."); return; }
        const ext = excel.name.substring(excel.name.lastIndexOf('.') + 1);
        if(ext!=='xlsx' && ext!=='xls') { FailureAlert('Upload Excel File Only...') }
        else {
            const formData = new FormData();
            formData.append('file', excel);
            const requestOptionsPost = {
                method: 'POST',
                headers: { 'Authorization': `Token ${token}` },
                body: formData  
            };
            setLoading(true)
            apiCall('/api/admin/company_uploader/', requestOptionsPost)
              .then((res) => {
                if(res.success) {
                    setResponse(res.data)
                    SuccessAlert('Upload Successful...');
                    setExcel(null);
                }
                else FailureAlert(res.error)
                setLoading(false)
            })
        }
    }  

    return (
        !loading
        ?
        <div>
            <Card className="add__student__job__excel__card" style={{marginBottom:'10px'}}>
                <h3>Upload Companies</h3><br />
                <Form className="add__student__job__excel__form" id="add__student__job__excel__card__form" onSubmit={e=>{uploadExcel(e)}}>
                    <small>Upload File (Excel Format only)</small><br /><br />
                    <Form.File
                        type="file"
                        onChange={e=> { setExcel(e.target.files[0]) }}
                    /><br />
                    <Button type="submit" variant="primary">Upload Details</Button>
                </Form>
            </Card>
            <div className="add__student__job__response">
                <div style={{ width: "90%", margin: "auto" }}>
                    <h3>Instructions:</h3>
                    i. Excel sheet must include column names:{" "}
                    <b><br/>
                    <div id="info"><i>
                        1. Company Name, 2. Address, 3. POC First Name, 4. POC Last Name, 5. POC Designation, 
                        6. POC Email, 7. POC Phone, 8. Industry, 9. Year Founded, 10. Organization Type, 
                        11. Website, 12. Logo, 13. Current City, 14. Company Size (Optional)
                    </i></div>
                    </b>
                    <br />
                    ii. <a
                        href={sampleExcel}
                        style={{fontSize:'18px', color:'blue', backgroundColor:'transparent'}}    
                    >
                    Click here to download sample file.</a>
                    <br />
                    <br />
                </div>
                {
                    response
                    ?
                    <div>
                        <h6>Successful Entries: {response.new_user_created || 0}</h6>
                        <h6>Existing Entries: {response.existing_users || 0}</h6>
                        <br />
                    </div>
                    :
                    null
                }
            </div>
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default AddCompaniesExcel;