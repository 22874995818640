import React, { useState, useEffect } from 'react';
import {apiCall} from '../../util/apiCall';
import { headerCSS, cellCSS, gifStyling, requestPraveshOptions } from '../../constants';
import TableWithExport from '../TableWithExport';
import {Form, Col, Button} from 'react-bootstrap';
import { Select, MenuItem } from "@material-ui/core";
import '../../css/Miscellaneous.css';
import gif from '../../assets/loopingcircles-3.gif';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const token = localStorage.getItem('token');

function ActiveYears() {

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const [year, setYear] = useState('');
    // const [isActive, setIsActive] = useState(false);


    useEffect(() => {
        setLoading(true);
        apiCall('/api/admin/active_year/', requestPraveshOptions)
          .then(json => {
            if(json.success) {
                let tmpArray = []
                for (var i = 0; i < json.data.length; i++) {
                    let obj = {
                        id:         parseInt(json.data[i].id),
                        year:       json.data[i].year,
                        is_active:  json.data[i].is_active ? "Yes" : "No"
                    }
                    tmpArray.push(obj)
                }
                setLoading(false);
                setData(tmpArray);
            }
            else {
                FailureAlert(json.error)
            }    
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const columns = [
        { title: "Year",      field: "year",      headerStyle: headerCSS, cellStyle: cellCSS,
          editable: 'never',  type: 'numeric', },
        { title: "Active?",   field: "is_active", headerStyle: headerCSS, cellStyle: cellCSS,
          searchable: false,  lookup: { 'Yes': 'Yes', 'No': 'No'},        filterPlaceholder: 'Yes/No',
          editComponent: props => (
            <Select value={props.value} onChange={e => {props.onChange(e.target.value)}}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
            </Select>
          )  
        }
    ];


    const edit = {
        onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            updateRow(newData);
            setData([...dataUpdate]);
            resolve();
          }, 1000)
        })
    }

    const updateRow = (newData) => {
        const requestOptionsPatch = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ 
                is_active: newData.is_active==="Yes" ? true : false,
            })
        };
        apiCall(`/api/admin/active_year/${newData.id}/`, requestOptionsPatch)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.error); setCount(count+1);
            }
        })
    }


    const addNewYear = (e) => {
        e.preventDefault();
        const requestOptionsPost = {
             method: 'POST',
             headers: { 
                 'Content-Type': 'application/json',
                 'Authorization': `Token ${token}`
             },
             body: JSON.stringify({ 
                 year: parseInt(year),
             })
         };
        apiCall('/api/admin/active_year/', requestOptionsPost)
        .then((res) => {
            if(res.success) {
                SuccessAlert(res.data.message);
                setCount(count+1);
            }
            else {
                FailureAlert(res.verbose.year[0] || res.error);
            }
        })
        e.target.reset();
    }


    return (
        !loading
        ?
        <div className="add__year">
            <div className="add__year__form">
                <h4>Add New Year</h4>
                <Form onSubmit={e=>addNewYear(e)}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="year">
                            <Form.Control 
                                name="year" type="text" placeholder="Enter Year*"
                                required  pattern="[0-9]*" maxLength="4" minLength="4"
                                onChange={e=> {setYear(e.target.value)}}
                            />
                        </Form.Group>
                        <Button type="submit" variant="info">Add Year</Button>
                    </Form.Row>             
                </Form>    
            </div>
            <TableWithExport 
                title = "Active Years"
                data = {data}
                columns = {columns}
                fName = "Selected Years"
                editableObject = {edit}
            />
        </div>
        :
        <img 
          src={gif} 
          alt="Getwork Loading Gif..."
          style={gifStyling}
        />
    )
}

export default ActiveYears;