import { Dialog } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import UseAxiosApi from '../../Api/UseAxiosApi';
import gif from "../../assets/loopingcircles-3.gif";
import AddResumeTemplate from '../../components/DialogSection/AddResumeTemplate';
import TableWithExport from '../../components/TableWithExport';
import { cellCSS, gifStyling, headerCSS, praveshUrl } from '../../constants';
import { praveshAxiosPatchReq, praveshAxiosPostReq } from '../../util/apiCall';
import { FailureAlert, SuccessAlert } from '../../util/Notifications';

const useStyles = makeStyles((theme) => ({

    root1: {
        borderRadius: "8px",
        width: '80%',
        height: "100%",
        padding: '.5%',
        // paddingBottom: '5%',
    },
    imageSection: {
        display: 'flex',
        justifyContent: 'space-around'

    }


}))


const ResumeTemplates = () => {

    const columns = [

        {
            title: "Template ID",
            field: "template_id",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },
        {
            title: "Template Name",
            field: "template_name",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },
        {
            title: "Template Image",
            field: "template_image",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            render: (row) => (

                <img style={{ height: '4rem', cursor: 'pointer' }}
                    src={row?.template_image}
                    alt="template image"
                    onClick={() => {
                        setShowImage(true);
                        setImageDialogLink(row?.template_image)
                    }}
                />

            )
        },


        {
            title: "Template Path",
            field: "template_path",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
        },
        {
            title: "Profile Pic In Template",
            field: "is_image",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            render: (row) => (row?.is_image ? "Yes" : "No")
        },

        {
            title: "Template Status",
            field: "delete",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            render: (row) => (
                <Button
                    variant={row?.is_active ? "outline-success" : "outline-danger"
                    }
                    size="sm"
                    onClick={() => {
                        if (!statusLoading) {

                            changeTemplateStatus(row);
                        }

                    }}
                >
                    {row?.is_active ? "Active" : "Inactive"}
                </Button>
            )
        },
        {
            title: "Edit Template",
            field: "edit",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: true,
            render: (row) => (
                <Button
                    // variant={'btn-outline-primary'}
                    variant={'primary'}
                    size="sm"
                    onClick={() => {
                        if (!statusLoading) {

                            setTemplateData({
                                templateId: row?.template_id,
                                templateName: row?.template_name,
                                templateImage: row?.template_image,
                                templatePath: row?.template_path,
                                editflag: true,
                                profilePicFlag: row?.is_image ? 'yes' : 'no',

                            });
                            setOpenAddTemplate(true)
                        }
                    }}
                >
                    Edit
                </Button>
            )
        },


    ]

    const classes = useStyles();
    const [allRows, setAllRows] = useState([])
    const [showImage, setShowImage] = useState(false);
    const [openAddTemplate, setOpenAddTemplate] = useState(false);

    const initialTemplate = {
        templateId: '',
        templateName: '',
        templateFile: '',
        loading: false,
        templatePath: '',
        editflag: false,
        profilePicFlag: false,
    }
    const [templateData, setTemplateData] = useState(initialTemplate);
    const intialEditFlags = {
        templateName: false,
        templatePath: false,
        templateImage: false,
    }
    const [editflags, setEditFlags] = useState(intialEditFlags);
    const [imageDialogLink, setImageDialogLink] = useState(null);
    const getTemplatesUrl = `${praveshUrl}/api/admin/admin-resume-template/`;
    const [statusLoading, setStatusLoading] = useState(false)

    const callBackFn = (dataRes) => {

        let data = dataRes?.data;
        let tempArr = [];

        for (let i in data) {
            let obj = {};

            obj = {
                ...data[i],
            }
            tempArr.push(obj);
        }
        setAllRows(tempArr)
    }

    const changeTemplateStatus = async (rowData) => {

        const endPoint = `/api/admin/admin-resume-template/${rowData?.template_id}/`;
        setStatusLoading(true)

        try {
            const res = await praveshAxiosPatchReq(endPoint, { template_status: !(rowData?.is_active) });

            if (res?.data?.success) {

                let tempArr = [...allRows];
                let target = allRows?.find((item) => (item?.template_id === rowData?.template_id));
                let targetIndex = allRows?.findIndex((item) => (item?.template_id === rowData?.template_id));
                target = { ...target, is_active: !(rowData?.is_active) };
                tempArr?.splice(targetIndex, 1, target);
                setAllRows(tempArr)

            } else {
                FailureAlert("Some Error Occured!")
            }

        } finally {
            setStatusLoading(false);

        }
    }


    const handleEditTemplate = async () => {

        const endPoint = `/api/admin/admin-resume-template/${templateData?.templateId}/`;

        const formData = new FormData()
        editflags?.templateName && formData.append("template_name", templateData?.templateName)
        templateData?.templateFile && formData.append("template_image", templateData?.templateFile);
        editflags?.templatePath && formData.append("template_path", templateData?.templatePath);
        formData.append("is_image", templateData?.profilePicFlag === 'yes');

        setTemplateData((prevData) => ({ ...prevData, loading: true }))


        try {
            const res = await praveshAxiosPatchReq(endPoint, formData);
            if (res?.data?.success) {

                setOpenAddTemplate(false);
                let tempArr = [...allRows];
                let target = allRows?.find((item) => (item?.template_id === templateData?.templateId));
                let targetIndex = allRows?.findIndex((item) => (item?.template_id === templateData?.templateId));
                if (editflags?.templateName) target['template_name'] = templateData?.templateName;
                if (editflags?.templatePath) target['template_path'] = templateData?.templatePath;
                if (editflags?.templateImage) target['template_image'] = templateData?.tempImage;
                target.is_image = templateData?.profilePicFlag === 'yes';
                tempArr?.splice(targetIndex, 1, target);
                setAllRows(tempArr)

                SuccessAlert("Template Edited Successfully!");
            } else {

                FailureAlert("Some Error Occured!")
            }

        } finally {

            setTemplateData(() => initialTemplate);
            setEditFlags(intialEditFlags);

        }
    }

    const handlePostResumeTemplate = async () => {

        const endPoint = `/api/admin/admin-resume-template/`;

        const formData = new FormData()
        formData.append("template_name", templateData?.templateName)
        formData.append("template_image", templateData?.templateFile);
        formData.append("template_path", templateData?.templatePath);
        formData.append("is_image", templateData?.profilePicFlag === 'yes');
        setTemplateData((prevData) => ({ ...prevData, loading: true }))

        try {

            const res = await praveshAxiosPostReq(endPoint, formData,);
            const data = res?.data?.data?.data;
            if (res?.data?.success) {

                setOpenAddTemplate(false);
                SuccessAlert("Template Created Successfully!");
                setAllRows((prevData) => ([...prevData, {
                    template_name: templateData?.templateName,
                    template_image: templateData?.tempImage,
                    template_id: data?.template_id,
                    template_path: data?.template_path,
                    is_image: data?.is_image,
                }]))

            } else {
                FailureAlert("Some Error Occured!")
            }
        }
        finally {
            setTemplateData(() => initialTemplate);
        }
    }

    const { loading } = UseAxiosApi({ url: getTemplatesUrl, callBackFn: callBackFn });


    if (loading) {

        return (

            <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
        )
    }

    return (
        <>
            <div>
                <Button
                    onClick={() => {
                        setOpenAddTemplate(true)
                        setTemplateData(() => initialTemplate);
                    }}
                >Add Template</Button>
            </div>
            <TableWithExport
                title={"Resume Templates"}
                data={allRows ?? []}
                columns={columns ?? []}
                fName="Selected_data"

            />

            {
                showImage &&
                <Dialog
                    open={showImage}
                    onClose={() => setShowImage(false)}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'lg'}
                    maxHeight={'lg'}
                    PaperProps={{ classes: { root: classes.root1 } }}

                >
                    <section className={classes.imageSection}>
                        <img style={{
                            maxHeight: '600px'
                        }}
                            src={imageDialogLink}
                            alt="template image"

                        />

                    </section>

                </Dialog>

            }

            {
                openAddTemplate &&
                <AddResumeTemplate
                    open={openAddTemplate}
                    setOpen={setOpenAddTemplate}
                    setAllRows={setAllRows}
                    handleFormSubmission={templateData?.editflag ? handleEditTemplate : handlePostResumeTemplate}
                    setData={setTemplateData}
                    data={templateData}
                    setEdited={setEditFlags}
                />

            }
        </>

    )
}

export default ResumeTemplates