import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import gif from "../../assets/loopingcircles-3.gif";
import { DateSelector } from "../../components/common/DateSelector";
import TableWithExport from "../../components/TableWithExport";
import { cellCSS, gifStyling, headerCSS } from "../../constants";
import { praveshAxiosGetReq } from "../../util/apiCall";
import { FailureAlert } from "../../util/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import { CsvBuilder } from "filefy";
import CustomButtonCircularProgress from "../../components/loader";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  pageSection: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  tableSection: {
    display: "flex",
    flexDirection: "column",
  },
}));

const MarketingLeads = () => {
  const classes = useStyles();

  const [allLeadsData, setAllLeadsData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const [downloadAllFlag, setDownloadAllFlag] = useState(false);
  const [loadingAllData, setloadingAllData] = useState(false);

  const [isInitialCall, setInitialCall] = useState(true);

  const dateRange = [new Date(2021, 0, 1), new Date()];
  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
    }
  };

  const removeDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setCount(count + 1);
  };

  const handleDownloadAllData = () => {
    setDownloadAllFlag(true);
  };

  useEffect(() => {
    // if its initial call
    // and there is nothing in url then startdate and enddate should be today and t-30
    //  else get it from url
    if (isInitialCall) {
      let initialStartDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      );
      let initialEndDate = new Date();

      const queryParams = QueryString.parse(window.location.search);
      if (queryParams.from) {
        initialStartDate = queryParams.from;
      }
      if (queryParams.to) {
        initialEndDate = queryParams.to;
      }
      setStartDate(new Date(initialStartDate));
      setEndDate(new Date(initialEndDate));
    }
  }, []);

  const getExtraData = (extraDataObj) => {
    if (extraDataObj && Object.keys(extraDataObj)?.length > 0) {
      let tempArr = [];
      for (let i in extraDataObj) {
        tempArr?.push(
          <ul>
            <li>{`${i} : ${JSON.stringify(extraDataObj[i])}`}</li>
          </ul>
        );
      }
      return tempArr;
    } else {
      return "";
    }
  };

  const columns = [
    {
      title: "Applicant ID",
      field: "id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Name",
      field: "name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Phone",
      field: "phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "E-mail",
      field: "email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Resume",
      field: "resume",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          {row.resume ? (
            <Button
              style={{
                fontSize: 13,
              }}
              variant="outline-success"
              size="sm"
              onClick={() => window.open(row.resume)}
            >
              {" "}
              Resume
            </Button>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: "User Type",
      field: "user_type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Extra Data",
      field: "",
      headerStyle: {
        ...headerCSS,
        textAlign: "center",
      },
      cellStyle: { ...cellCSS, minWidth: "15rem" },
      filtering: true,
      searchable: true,
      render: (row) => getExtraData(row?.extra_data) ?? "",
    },
    {
      title: "UTM Source",
      field: "utm_source",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Medium",
      field: "utm_medium",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Campaign",
      field: "utm_campaign",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Content",
      field: "utm_content",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "UTM Term",
      field: "utm_term",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Linked User Id",
      field: "linked_user_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    // {
    //     title: "Linked User",
    //     field: "linked_user",
    //     headerStyle: headerCSS,
    //     cellStyle: cellCSS,
    //     filtering: true,
    //     searchable: true,
    // },
    {
      title: "Linked User Phone",
      field: "linked_user_phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    // {
    //     title: "Linked User Email",
    //     field: "linked_user_email",
    //     headerStyle: headerCSS,
    //     cellStyle: cellCSS,
    //     filtering: true,
    //     searchable: true,
    // },
    {
      title: "Whatsapp Notification",
      field: "whatsapp_notification",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Email Notification",
      field: "email_notification",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    // {
    //     title: "Extra Data",
    //     field: "extra_data",
    //     headerStyle: headerCSS,
    //     cellStyle: cellCSS,
    //     filtering: true,
    //     searchable: true,
    // },
    {
      title: "Is Active",
      field: "is_active",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Create Time",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },
    {
      title: "Update Time",
      field: "update_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: true,
      searchable: true,
    },

    // {
    //     title: "Applicant ID",
    //     field: "id",
    //     headerStyle: headerCSS,
    //     cellStyle: cellCSS,
    //     filtering: false,
    //     searchable: false,
    // },
  ];

  useEffect(() => {
    if (downloadAllFlag) {
      const getData = setTimeout(() => {
        getLeadsData();
      }, 500);

      return () => clearTimeout(getData);
    }
  }, [downloadAllFlag]);

  const getLeadsData = async () => {
    if (count === 0 && startDate === "") return;

    let dateString = "";
    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      dateString = `?start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }
    if (!downloadAllFlag) {
      setLoading(true);
    } else {
      setloadingAllData(true);
    }

    const endPoint = `/api/admin/marketing_leads/${dateString}`;

    try {
      const res = await praveshAxiosGetReq(endPoint);

      if (res?.data?.success) {
        setInitialCall(false);

        if (!downloadAllFlag) {
          let data = res?.data?.data;
          let tempArr = [];

          for (let i in data) {
            let obj = {};

            obj = {
              ...data[i],
              // linked_user: JSON.stringify(data[i]?.linked_user) };
              linked_user: `${
                data[i]?.linked_user?.first_name
                  ? data[i]?.linked_user?.first_name
                  : ""
              } ${
                data[i]?.linked_user?.last_name
                  ? data[i]?.linked_user?.last_name
                  : ""
              }`,
              linked_user_email: data[i]?.linked_user?.email,
              linked_user_phone: data[i]?.linked_user?.phone,
            };
            tempArr.push(obj);
          }

          // setAllLeadsData(res?.data?.data);
          setAllLeadsData(tempArr);
        } else {
          setDownloadAllFlag(false);
          const data = res?.data?.data;
          const firstObj = res?.data?.data?.[0];

          const columns = Object.keys(firstObj);

          const fileName = "All_Data";
          const builder = new CsvBuilder(fileName + ".csv");
          builder
            .setColumns(columns)
            .addRows(
              data.map((rowData) =>
                columns.map((columnDef) => rowData[columnDef])
              )
            )
            .exportFile();
        }
      } else {
        FailureAlert("Some Error Occured!");
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
      setDownloadAllFlag(false);
      setloadingAllData(false);
    }
  };
  useEffect(() => {
    getLeadsData();
  }, [startDate, count]);

  if (loading) {
    return (
      <>
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      </>
    );
  }

  return (
    <>
      <DateSelector
        dateRange={[startDate, endDate]}
        applyDateFilter={applyDateFilter}
        removeDateFilter={removeDateFilter}
      />
      <section className={classes.pageSection}>
        <Button
          style={{
            width: "12rem",
            height: "2.5rem",
          }}
          onClick={() => {
            handleDownloadAllData();
          }}
        >
          {loadingAllData ? (
            <CustomButtonCircularProgress />
          ) : (
            " Download All Data"
          )}
        </Button>
      </section>
      <TableWithExport
        title="Marketing Leads"
        data={allLeadsData}
        columns={columns}
        fName="Selected_Companies"
        disableTableDownload={true}
      />
    </>
  );
};

export default MarketingLeads;
