import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@material-ui/core";
import HireSmallCard from "./HireSmallCard";
import h1 from "../../../../assets/svg/Plan/h1.svg";
import h2 from "../../../../assets/svg/Plan/h2.svg";
import h4 from "../../../../assets/svg/Plan/h4.svg";
import { FailureAlert } from "../../../../util/Notifications";

function Hiring({planDetails,setPlanDetails,handleForward}) {

  const [noOfSlots,setNoOfSlots] = useState(0);

  useEffect(() => {

     let tempNoSlots= 0;

     for (let i in planDetails?.slot_list){

       let perSlotCategoryCount = 0;
      for(let j in planDetails?.slot_list[i]){

        if(planDetails?.slot_list[i][j]?.slotsBooked){

            perSlotCategoryCount = perSlotCategoryCount + (planDetails?.slot_list[i][j]?.slotsBooked);
        }
      }
       tempNoSlots = tempNoSlots + perSlotCategoryCount;
     }

     setNoOfSlots(tempNoSlots); 

  }, [planDetails]);


  
  return (
    <Box style={{ marginTop: 20, marginBottom: 20 }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: "600" }}>
            Intern Hiring
          </Typography>
        </Grid>

             {planDetails?.slot_list?.["Intern hiring"]?.map((val, index) => (
               <Grid item xs={6} md={3}>
                <HireSmallCard
                  type="internHiring"
                  index={index}
                  planDetails={planDetails}
                  setPlanDetails={setPlanDetails}
                  image={index === 0 ? h1 : h2}
                  title={val?.name}
                  subtitle={val?.details}
                  amount={val?.price_per_slot}
                  slots={val?.slotsBooked ? val?.slotsBooked : 0}
                />
               </Grid>
                ))}            

        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: "600" }}>
            Non-Tech Hiring
          </Typography>
        </Grid>
          {planDetails?.slot_list?.["Non-Tech hiring"]?.map((val, index) => (
               <Grid item xs={6} md={3}>
                <HireSmallCard
                  type="nonTechHiring"
                  index={index}
                  planDetails={planDetails}
                  setPlanDetails={setPlanDetails}
                  image={index === 0 ? h1 : index === 1 ? h2 : h4}
                  title={val?.name}
                  subtitle={val?.details}
                  amount={val?.price_per_slot}
                  slots={val?.slotsBooked ? val?.slotsBooked : 0}
                />
               </Grid>
                ))}    
 
        <Grid item md={12}>
          <Typography variant="body1" style={{ fontWeight: "600" }}>
            Tech Hiring
          </Typography>
        </Grid>
        {planDetails?.slot_list?.["Tech hiring"]?.map((val, index) => (
               <Grid item xs={6} md={3}>
                <HireSmallCard
                  type="techHiring"
                  index={index}
                  planDetails={planDetails}
                  setPlanDetails={setPlanDetails}
                  image={index === 0 ? h1 : index === 1 ? h2 : h4}
                  title={val?.name}
                  subtitle={val?.details}
                  amount={val?.price_per_slot}
                  slots={val?.slotsBooked ? val?.slotsBooked : 0}
                />
               </Grid>
         ))}       
       
        <Grid item xs={12}>
          <Button
            // color="primary"
            variant="contained"
            style={{ 
            boxShadow: "none", 
            borderRadius: "30px", 
            width: "20%",
            color:"white",
            backgroundColor:'#3F51B5' 
            }}
            onClick={() => {

              if(noOfSlots > 0){
                
                handleForward();
              }else{
                FailureAlert("Please select atleast one plan")
              }
              // createPlan();
            }}
          >
            Book Now
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Hiring;

const data = [
  {
    img: h1,
    title: "Intern Hiring",
    subtitle: "College Students",
    amount: "₹5,000/Slots",
  },
  {
    img: h2,
    title: "Non-Tech (Fresher)",
    subtitle: "0 - 1 Year Experienced",
    amount: "₹10,000/Slots",
  },
  // {
  //   img: h4,
  //   title: "Non-Tech (Experienced)",
  //   subtitle: "2+  Years Experienced",
  //   amount: "₹25,000/Slots",
  // },
  // {
  //   img: h3,
  //   title: "Tech. (Fresher)",
  //   subtitle: "0 - 1 Year Experienced",
  //   amount: "₹15,000/Slots",
  // },
  // {
  //   img: h5,
  //   title: "Tech. (Experienced)",
  //   subtitle: "2+  Years Experienced",
  //   amount: "₹45,000/Slots",
  // },
];

const secondData = [
  {
    img: h1,
    title: "Intern Hiring",
    subtitle: "College Students",
    amount: "₹5,000/Slots",
  },
  {
    img: h2,
    title: "Non-Tech (Fresher)",
    subtitle: "0 - 1 Year Experienced",
    amount: "₹10,000/Slots",
  },
  {
    img: h4,
    title: "Non-Techs",
    subtitle: "2+  Years Experienced",
    amount: "₹25,000/Slots",
  },
  // {
  //   img: h3,
  //   title: "Tech. (Fresher)",
  //   subtitle: "0 - 1 Year Experienced",
  //   amount: "₹15,000/Slots",
  // },
  // {
  //   img: h5,
  //   title: "Tech. (Experienced)",
  //   subtitle: "2+  Years Experienced",
  //   amount: "₹45,000/Slots",
  // },
];

const thirdData = [
  {
    img: h1,
    title: "Intern Hiring",
    subtitle: "College Students",
    amount: "₹5,000/Slots",
  },
  {
    img: h2,
    title: "Non-Tech (Fresher)",
    subtitle: "0 - 1 Year Experienced",
    amount: "₹10,000/Slots",
  },
  {
    img: h4,
    title: "Non-Tech ",
    subtitle: "2+  Years Experienced",
    amount: "₹25,000/Slots",
  },
  // {
  //   img: h3,
  //   title: "Tech. (Fresher)",
  //   subtitle: "0 - 1 Year Experienced",
  //   amount: "₹15,000/Slots",
  // },
  // {
  //   img: h5,
  //   title: "Tech. (Experienced)",
  //   subtitle: "2+  Years Experienced",
  //   amount: "₹45,000/Slots",
  // },
];
